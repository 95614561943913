import React from 'react';

import {ReactComponent as Send} from 'Images/sendMessage.svg';

import {GROUP_CHAT_TYPE} from 'store/actionTypes/userTypes';

class SendGroupChatMessage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.newMessageRef = React.createRef();
    }

    state = {
        newMessage: '',
        sendingMessage: false,
        translation: null,
    };

    componentDidMount() {
        const {isLargeScreen} = this.props;
        if (isLargeScreen) {
            this.newMessageRef.current.focus();
        }
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeChatRoomId !== prevProps.activeChatRoomId) {
            this.newMessageRef.current.focus();
        }
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter' && this.state.newMessage.trim()) {
            this.onSendGroupMessage(e);
        }
    };

    onSendGroupMessage = (e) => {
        const {sendGroupChatMessage, activeChatRoomId} = this.props;
        const {newMessage} = this.state;

        e && e.preventDefault();
        this.setState({sendingMessage: true});

        if (!this.state.sendingMessage) {
            sendGroupChatMessage({
                activeChatRoomId,
                newMessage,
                callback: () => {
                    this.setState({newMessage: '', sendingMessage: false});
                    this.newMessageRef.current.focus();

                    if (this.props.archivedChats) {
                        this.props.onArchiveChat(GROUP_CHAT_TYPE, this.props.activeChatRoomId, true);
                    }
                },
            });
        }
    };

    startConference = () => {
        this.setState({newMessage: "I've set up a Video Meeting room. Please click here to join it."}, () => {
            this.onSendGroupMessage();
            this.props.startConference();
        });
    };

    render() {
        const {translation} = this.state;
        return (
            <form autoComplete={'off'} className="chat-message-form" onSubmit={this.onSendGroupMessage}>
                <div className="new-comment-container">
                    <input
                        id="newMessage"
                        placeholder={translation?.chatsDropdown.newMessageInput}
                        value={this.state.newMessage}
                        onChange={this.handleChange('newMessage')}
                        onKeyDown={this.handleKeyDown}
                        ref={this.newMessageRef}
                    />
                    {
                        this.state.newMessage ? (
                            <button
                                onClick={this.onSendGroupMessage}
                                disabled={!this.state.newMessage.trim() || this.state.sendingMessage}
                                className="send-group-message"
                                type="button"
                            >
                                <Send className="primary-fill-svg" />
                            </button>
                        ) : null
                        // <button
                        //     onClick={this.startConference}
                        //     className="video-conference-button primary-fill-svg"
                        //     type="button"
                        // >
                        //     <OpenConferenceIcon />
                        // </button>
                    }
                    {/* <StartVideoChatTooltip newMessage={this.state.newMessage} /> */}
                </div>
            </form>
        );
    }
}

export default SendGroupChatMessage;
