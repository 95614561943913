export const defaultRegistrationFields = [
    {
        isEnabled: true,
        name: 'company',
        type: 'text',
        value: '',
        label: 'Company',
        labelKey: 'company',
        multiline: 0,
        validators: [],
        errorMessages: [],
    },
    {
        isEnabled: true,
        name: 'title',
        type: 'text',
        value: '',
        label: 'Title',
        labelKey: 'title',
        multiline: 0,
        validators: [],
        errorMessages: [],
    },
    {
        isEnabled: true,
        name: 'facebook',
        label: 'Facebook profile',
        type: 'text',
        value: '',
        labelKey: 'facebookProfile',
        multiline: 0,
        validators: ['isFacebookLink'],
        errorMessages: ['facebookLinkInvalid'],
    },
    {
        isEnabled: true,
        name: 'linkedin',
        type: 'text',
        value: '',
        labelKey: 'yourLinkedinProfile',
        label: 'Your Linkedin profile',
        multiline: 0,
        validators: ['isLinkedinLink'],
        errorMessages: ['linkedinLinkInvalid'],
    },
    {
        isEnabled: true,
        name: 'twitter',
        type: 'text',
        label: 'Your Twitter profile',
        value: '',
        labelKey: 'yourTwitterProfile',
        multiline: 0,
        validators: ['isLTwitterLink'],
        errorMessages: ['twitterLinkInvalid'],
    },
    {
        isEnabled: true,
        name: 'country',
        type: 'text',
        label: 'Country',
        value: '',
        labelKey: 'country',
        multiline: 0,
        validators: [],
        errorMessages: [],
    },
];
