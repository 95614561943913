import {personalAgenda as personalAgendaApi} from 'Api';
import * as personalAgendaActionTypes from '../actionTypes/personalAgendaActionTypes';
import {
    deserializeAgendaListByDate,
    serializeAgendaCustomEvent as serializeCustomEvent,
} from 'services/utils/normalizers';
import {createGroupChatFail, getGroupChats, openGroupChat} from './userActions';
import {formMeetingList, getAllItemList} from 'services/helpers/personalAgendaHelper';
import {openVideoConference} from './videoConferenceActions';

export const toggleAgendaCustomElement = (isOpened) => ({
    type: personalAgendaActionTypes.TOGGLE_AGENDA_CUSTOM_ELEMENT,
    payload: isOpened,
});

export const endListLoad = ({listByDate = [], error}) => ({
    type: personalAgendaActionTypes.END_LIST_LOAD,
    payload: {listByDate, error},
});

export const resetPersonalAgendaState = () => ({
    type: personalAgendaActionTypes.RESET_PERSONAL_AGENDA_STATE,
});

export const addToAgenda = (auditoriumId, displayProgramId, programId) => async (dispatch, getState) => {
    const {
        event: {eventId},
    } = getState();

    const {addProgram} = personalAgendaApi();

    try {
        const {personalAgenda} = await addProgram(eventId, auditoriumId, displayProgramId, programId);
        const listByDate = deserializeAgendaListByDate(personalAgenda);
        dispatch(endListLoad({listByDate}));
    } catch (error) {
        console.error(error);
    }
};

export const removeAgendaElement = (elementId) => async (dispatch, getState) => {
    const {
        event: {eventId},
    } = getState();

    const {remove} = personalAgendaApi();

    try {
        const {personalAgenda} = await remove(elementId, eventId);
        const listByDate = deserializeAgendaListByDate(personalAgenda);
        dispatch(endListLoad({listByDate}));
    } catch (error) {
        console.error(error);
    }
};

export const addCustomElement = (customEl) => async (dispatch, getState) => {
    const {
        event: {eventId},
    } = getState();

    const {addCustom} = personalAgendaApi();

    try {
        const {personalAgenda} = await addCustom(serializeCustomEvent(customEl), eventId);
        const listByDate = deserializeAgendaListByDate(personalAgenda);
        dispatch(endListLoad({listByDate}));
    } catch (error) {
        console.error(error);
    }
};

export const cancelMeeting = (agendaId) => async (dispatch) => {
    const {cancelMeeting: apiCancelMeeting} = personalAgendaApi();

    try {
        const {personalAgenda} = await apiCancelMeeting(agendaId);
        const listByDate = deserializeAgendaListByDate(personalAgenda);
        dispatch(endListLoad({listByDate}));
    } catch (error) {
        console.error(error);
    }
};

export const confirmMeeting = (agendaId) => async (dispatch) => {
    const {confirmMeeting: apiConfirmMeeting} = personalAgendaApi();

    try {
        const {personalAgenda} = await apiConfirmMeeting(agendaId);
        const listByDate = deserializeAgendaListByDate(personalAgenda);
        dispatch(endListLoad({listByDate}));
    } catch (error) {
        console.error(error);
    }
};

const getChatUserIdList = (chat, userId) =>
    chat.users.map((member) => member._id).filter((memberId) => memberId !== userId);

export const startMeeting = (agendaId) => async (dispatch, getState) => {
    try {
        const {
            user,
            event: {eventId},
            personalAgenda: {listByDate},
        } = getState();
        const {sendVideoConferenceMessage} = personalAgendaApi();
        const userId = user.data?._id;
        const groupChats = user.data?.groupChats;
        const allItemList = getAllItemList(listByDate);
        const meetingList = formMeetingList(allItemList);

        const {receiverId, receiverName, senderId, senderName, groupChatId} = meetingList.find(
            ({id}) => id === agendaId
        );
        const id = userId === receiverId ? senderId : receiverId;

        if (groupChatId) {
            const chat = groupChats.find(({chat}) => chat._id === groupChatId).chat;
            await sendVideoConferenceMessage(groupChatId);
            dispatch(openGroupChat(groupChatId));
            dispatch(openVideoConference(user, getChatUserIdList(chat, userId), groupChatId, true));
            return;
        }

        const formChatName = (receiverName, senderName) => `Meeting ${receiverName} & ${senderName}`;

        dispatch(
            createNewGroupChatAndOpenIt(
                {
                    event: eventId,
                    name: formChatName(receiverName, senderName),
                    users: [id],
                    owners: [id, userId],
                },
                agendaId
            )
        );
    } catch (error) {
        console.error(error);
    }
};

const createNewGroupChatAndOpenIt = (chatData, agendaId) => async (dispatch, getState) => {
    const {addChatToAgenda, createGroupChat, sendVideoConferenceMessage} = personalAgendaApi();

    try {
        const {user} = getState();
        const userId = user.data?._id;
        const data = await createGroupChat(chatData);

        const {personalAgenda} = await addChatToAgenda(data.groupChat._id, agendaId);
        const listByDate = deserializeAgendaListByDate(personalAgenda);
        dispatch(endListLoad({listByDate}));

        await sendVideoConferenceMessage(data.groupChat._id);
        dispatch(getGroupChats(data.groupChat._id));
        dispatch(openVideoConference(user, getChatUserIdList(chatData, userId), chatData._id, true));
    } catch (error) {
        dispatch(createGroupChatFail(error));
        console.error(error);
    }
};

export const requestAgenda = (eventId) => async (dispatch) => {
    dispatch({type: personalAgendaActionTypes.START_LIST_LOAD});
    const {request} = personalAgendaApi();

    try {
        const {personalAgenda} = await request(eventId);
        const listByDate = deserializeAgendaListByDate(personalAgenda);
        dispatch(endListLoad({listByDate}));
    } catch (error) {
        dispatch(endListLoad({error}));
        console.error(error);
    }
};
