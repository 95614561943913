import {
    GET_NEWS_START,
    GET_NEWS_SUCCESS,
    GET_NEWS_FAIL,
    DELETE_NEWS_START,
    DELETE_NEWS_SUCCESS,
    DELETE_NEWS_FAIL,
    CREATE_NEWS_START,
    CREATE_NEWS_SUCCESS,
    CREATE_NEWS_FAIL,
    UPDATE_NEWS_START,
    UPDATE_NEWS_SUCCESS,
    UPDATE_NEWS_FAIL,
    START_NEWS_START,
    START_NEWS_SUCCESS,
    START_NEWS_FAIL,
    STOP_NEWS_START,
    STOP_NEWS_SUCCESS,
    STOP_NEWS_FAIL,
} from '../actions/actionTypes';
import {Api} from '../../Api';

export const getNews = (eventId) => async (dispatch, getState) => {
    const {
        news: {isLoading},
    } = getState();

    dispatch({type: GET_NEWS_START});

    try {
        const {news} = await Api.request({
            url: `/event/${eventId}/hot-news`,
            method: 'get',
            loading: isLoading,
        });

        dispatch({
            type: GET_NEWS_SUCCESS,
            news,
        });
    } catch (error) {
        dispatch({type: GET_NEWS_FAIL});
    }
};

export const createNewsItem = (eventId, newsItemData) => async (dispatch, getState) => {
    const {
        news: {isLoading},
    } = getState();

    dispatch({type: CREATE_NEWS_START});

    try {
        const data = {
            event: eventId,
            ...newsItemData,
        };

        await Api.request({
            url: `/event/${eventId}/hot-news`,
            method: 'post',
            payload: data,
            loading: isLoading,
        });

        dispatch({type: CREATE_NEWS_SUCCESS});
        dispatch(getNews(eventId));
    } catch (error) {
        dispatch({type: CREATE_NEWS_FAIL});
    }
};

export const editNewsItem = (eventId, newsId, newsItemData) => async (dispatch, getState) => {
    const {
        news: {isLoading},
    } = getState();

    dispatch({type: UPDATE_NEWS_START});

    try {
        const data = {
            event: eventId,
            ...newsItemData,
        };
        await Api.request({
            url: `/event/${eventId}/hot-news/${newsId}`,
            method: 'put',
            payload: data,
            loading: isLoading,
        });

        dispatch({type: UPDATE_NEWS_SUCCESS});
        dispatch(getNews(eventId));
    } catch (error) {
        dispatch({type: UPDATE_NEWS_FAIL});
    }
};

export const deleteNewsItem = (eventId, newsId) => async (dispatch, getState) => {
    const {
        news: {isLoading},
    } = getState();

    dispatch({type: DELETE_NEWS_START});

    try {
        await Api.request({
            url: `/event/${eventId}/hot-news/${newsId}`,
            method: 'delete',
            loading: isLoading,
        });

        dispatch({type: DELETE_NEWS_SUCCESS});
        dispatch(getNews(eventId));
    } catch (error) {
        dispatch({type: DELETE_NEWS_FAIL});
    }
};

export const startNewsItem = (eventId, newsId) => async (dispatch, getState) => {
    const {
        news: {isLoading},
    } = getState();

    dispatch({type: START_NEWS_START});

    try {
        await Api.request({
            url: `/event/${eventId}/hot-news/${newsId}/start`,
            method: 'post',
            loading: isLoading,
        });

        dispatch({type: START_NEWS_SUCCESS});
        dispatch(getNews(eventId));
    } catch (error) {
        dispatch({type: START_NEWS_FAIL});
    }
};

export const stopNewsItem = (eventId, newsId) => async (dispatch, getState) => {
    const {
        news: {isLoading},
    } = getState();

    dispatch({type: STOP_NEWS_START});

    try {
        await Api.request({
            url: `/event/${eventId}/hot-news/${newsId}/stop`,
            method: 'post',
            loading: isLoading,
        });

        dispatch({type: STOP_NEWS_SUCCESS});
        dispatch(getNews(eventId));
    } catch (error) {
        dispatch({type: STOP_NEWS_FAIL});
    }
};
