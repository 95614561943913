import React from 'react';
import {Radio, withStyles} from '@material-ui/core';

const CustomRadio = withStyles((theme) => ({
    root: {
        '&$checked': {
            color: theme.palette.primary.main,
        },
    },
    checked: {},
}))((props) => <Radio color="default" {...props} />);

export default CustomRadio;
