import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Scrollbars} from 'react-custom-scrollbars';
import _ from 'lodash';

class ColoredScrollbars extends Component {
    scrollbars = React.createRef();

    componentDidMount() {
        if (this.props.scrolltochatid) {
            this.scrollChatIntoView();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.scrolltochatid !== this.props.scrolltochatid) {
            this.scrollChatIntoView();
        }
        if (this.props.usersentnewmessage && prevProps.usersentnewmessage !== this.props.usersentnewmessage) {
            this.scrollbars.current.scrollToBottom();
        }
        if (prevProps.chatid !== this.props.chatid) {
            this.scrollbars.current.scrollToBottom();
        }
    }

    state = {
        scrollBottom: true,
    };

    // we use this.props.scrollbottom property for chats, to know if we need to scroll
    // we use this.state.scrollBottom to know if the user has scrolled to top to see older messages so that we can stop the scrollbottom
    handleUpdate = (values) => {
        if (this.state.scrollBottom && this.props.scrollbottom) {
            this.scrollbars.current.scrollToBottom();
        }
    };

    scrollChatIntoView = () => {
        let activeChatElement = document.getElementById(this.props.scrolltochatid);
        if (activeChatElement) {
            activeChatElement.scrollIntoView();
        }
    };

    handleScroll = () => {
        let values = this.scrollbars.current.getValues();
        if (values.top !== 1 && this.props.scrollbottom) {
            this.setState({scrollBottom: false});
        } else {
            this.setState({scrollBottom: true});
        }
    };

    renderView = ({style, ...props}) => {
        let viewStyle = {
            padding: 10,
        };

        if (this.props.isRtlLanguage) {
            viewStyle = {...viewStyle, marginLeft: style.marginRight, marginRight: 0};
        }

        return <div className="box" style={{...style, ...viewStyle}} {...props} />;
    };

    renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `rgb(137,137,137)`,
            left: 0,
        };
        return <div className="grey-scroll" style={{...style, ...thumbStyle}} {...props} />;
    };

    render() {
        const omitProps = _.omit(this.props, ['isRtlLanguage', 'dispatch']);

        return (
            <Scrollbars
                ref={this.scrollbars}
                className="scrollbar"
                renderView={this.renderView}
                onScroll={this.handleScroll}
                renderThumbVertical={this.renderThumb}
                onUpdate={this.handleUpdate}
                {...omitProps}
            />
        );
    }
}

export default connect(
    (state) => ({
        isRtlLanguage: state.languages.isRtlLanguage,
    }),
    null
)(ColoredScrollbars);
