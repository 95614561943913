import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {getTimeslotData, setEventProtectedMenu} from 'store/actions';

import useTimeslotSockets from '../../useTimeslotSockets';

export const useZoomSessionDashboard = () => {
    const dispatch = useDispatch();
    const {auditoriumId, sessionId} = useParams();

    useEffect(() => {
        dispatch(
            getTimeslotData({
                auditoriumId,
                timeslotId: sessionId,
                callback: () => {},
            })
        );
        dispatch(setEventProtectedMenu());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, auditoriumId]);

    useTimeslotSockets({sessionId, isTimeslotSetup: false});
};
