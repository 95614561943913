import {createSelector} from 'reselect';

const exhibitorMarketplace = (state) => state.exhibitorMarketplace;

export const getProductList = createSelector(exhibitorMarketplace, (state) => state.productList);

export const getMarketplaceLoadState = createSelector(exhibitorMarketplace, (state) => state.loadState);

export const getMarketplaceIsCreating = createSelector(exhibitorMarketplace, (state) => state.isCreating);

export const getEditedProduct = createSelector(exhibitorMarketplace, (state) => state.editedProduct);

export const getCreatedProduct = createSelector(exhibitorMarketplace, (state) => state.createdProduct);

export const getDeletedProduct = createSelector(exhibitorMarketplace, (state) => state.deletedProduct);
