import React from 'react';
import './MatchingDialogNoteText.scss';

const MatchingDialogNoteText = () => {
    return (
        <div className="matching-dialog-note-text">
            <p className="matching-dialog-note-text__text">
                <span className="matching-dialog-note-text__text--highlight">NOTE: </span>
                You can find all your matches in the Networking section. All answers are editable in My Account menu
            </p>
        </div>
    );
};

export default MatchingDialogNoteText;
