import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Box, TextField} from '@material-ui/core';
import {
    ArrowBackIos as ArrowBackIosIcon,
    CloudDownload as CloudDownloadIcon,
    MailOutline as MailOutlineIcon,
    PhoneIphone as PhoneIphoneIcon,
    Save as SaveIcon,
    Web as WebIcon,
} from '@material-ui/icons';
import moment from 'moment';

import {ReactComponent as Trash} from 'Images/trash_icon.svg';
import Media from 'Routes/components/Media/Media';
import ColoredScrollbars from 'Routes/components/ColoredScrollbars/ColoredScrollbars';
import ChatButton from 'Routes/components/SideMenu/components/Networking/components/UsersList/components/UserPreviewPlate/components/ChatButton/ChatButton';
import {usePlatformTranslation} from 'services/hooks';
import {
    createPrivateChat,
    openPrivateChat,
    openWallet,
    getBusinessCard,
    postBusinessCard,
    deleteBusinessCardNote,
} from 'store/actions';
import {getEventId, getIsMobile, getIsUserLoggedIn, getUserLoading, getUserPrivateChats} from 'store/selectors';
import {getPrivateChatId} from 'services/utils';

import './BusinessCard.scss';

const logoHolderStyles = {
    position: 'relative',
    height: '100%',
    maxWidth: '240px',
    width: '100%',
    margin: '0 auto',
    marginBottom: '24px',
};

const logoStyles = {position: 'absolute', left: '0', top: '0', right: '0', bottom: '0'};

const BusinessCard = ({
    cardId,
    withHeader,
    setChatCard,
    isMobile,
    eventId,
    userLoggedIn,
    loading,
    privateChats,
    createPrivateChat,
    openPrivateChat,
    openWallet,
    getBusinessCard,
    postBusinessCard,
    deleteBusinessCardNote,
}) => {
    const {
        businessCard: {businessCard},
    } = usePlatformTranslation();

    const [card, setCard] = useState(null);
    const [notes, setNotes] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [noteValue, setNoteValue] = useState('');

    const handleOpenPrivateChat = () => {
        const targetUserId = card.cardOwnerId;
        const privateChatId = getPrivateChatId(privateChats, targetUserId);
        if (privateChatId) {
            openPrivateChat(privateChatId, targetUserId);
        } else {
            if (targetUserId !== userLoggedIn._id && !loading) {
                createPrivateChat(targetUserId, eventId);
            }
        }
    };

    const getCard = useCallback(
        () =>
            getBusinessCard({
                cardId,
                callback: ({businessCard, businessCardNotes}) => {
                    setCard({...businessCard, cardOwnerId: cardId});
                    setNotes(businessCardNotes.reverse());
                    setIsLoaded(true);
                    setChatCard({...businessCard, cardOwnerId: cardId});
                },
            }),
        [cardId, setChatCard, getBusinessCard]
    );

    useEffect(() => {
        getCard();
    }, [getCard]);

    const handleSaveNote = () => {
        if (!noteValue) return;
        postBusinessCard({
            cardId: card._id,
            payload: {text: noteValue},
            callback: () => {
                setNoteValue('');
                getCard();
            },
        });
    };

    const handleDeleteNote = (noteId) => deleteBusinessCardNote({cardId: card._id, noteId, callback: () => getCard()});

    const handleChangeNote = (value) => {
        setNoteValue(value.substring(0, 200));
    };

    const renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `rgb(137,137,137)`,
            left: 0,
        };
        return <div className="grey-scroll" style={{...style, ...thumbStyle}} {...props} />;
    };

    return (
        <Box height="100%">
            {isLoaded && (
                <div className="business-wallet-popup">
                    {withHeader && (
                        <div className="wallet-header">
                            <div className="d-flex">
                                <ArrowBackIosIcon
                                    onClick={() => {
                                        openWallet();
                                    }}
                                    style={{fill: '#777081', cursor: 'pointer'}}
                                />
                                {card && (
                                    <div className="wallet-header-titlebox">
                                        <div className="wallet-header-title">
                                            {card.first} {card.last}
                                        </div>
                                        <div className="wallet-header-subtitle">{card.designation}</div>
                                    </div>
                                )}
                            </div>
                            {card && (
                                <div className="d-flex">
                                    <div className={'connection '}>
                                        <ChatButton onClick={() => handleOpenPrivateChat()} />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <ColoredScrollbars className="scrollbar" renderThumbVertical={renderThumb}>
                        {card ? (
                            <div className="wallet-container">
                                <div className="BusinessCard" id="business-card-to-download">
                                    <div className="BusinessCard-container">
                                        <div className="BusinessCard-section">
                                            {card.first && (
                                                <span className="BusinessCard-title" style={{color: card.primaryColor}}>
                                                    {card.first}
                                                </span>
                                            )}
                                            {card.last && <span className="BusinessCard-title">{card.last}</span>}
                                            <div className="BusinessCard-title-divider"></div>
                                            {card.designation && (
                                                <span className="BusinessCard-subtitle">{card.designation}</span>
                                            )}
                                            {card.company && (
                                                <span className="BusinessCard-subtitle">{card.company}</span>
                                            )}
                                        </div>
                                        <div
                                            className="BusinessCard-section-divider"
                                            style={{backgroundColor: card.primaryColor}}
                                        ></div>
                                        <div
                                            className="BusinessCard-section"
                                            style={{backgroundColor: card.backgroundColor}}
                                        >
                                            {card.logo && (
                                                <div style={logoHolderStyles}>
                                                    <div style={logoStyles}>
                                                        <Media
                                                            media={{
                                                                src: `${process.env.REACT_APP_BUSINESS_CARD_FOLDER}${card.logo}`,
                                                                title: 'card logo',
                                                            }}
                                                            isAdaptable
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div>
                                                {card.mobile && (
                                                    <div
                                                        className="BusinessCard_contact"
                                                        style={{color: card.textIconColor}}
                                                    >
                                                        <PhoneIphoneIcon className="BusinessCard_contact-icon" />
                                                        <a href={`tel:${card.mobile}`}>{card.mobile}</a>
                                                    </div>
                                                )}
                                                {card.phone && (
                                                    <div
                                                        className="BusinessCard_contact"
                                                        style={{color: card.textIconColor}}
                                                    >
                                                        <PhoneIphoneIcon className="BusinessCard_contact-icon" />
                                                        <a href={`tel:${card.phone}`}>{card.phone}</a>
                                                    </div>
                                                )}
                                                {card.email && (
                                                    <div
                                                        className="BusinessCard_contact"
                                                        style={{color: card.textIconColor}}
                                                    >
                                                        <MailOutlineIcon className="BusinessCard_contact-icon" />
                                                        {/* <span> */}
                                                        <a href={`mailto:${card.email}`}>
                                                            {card.email.split('@').join('@ ')}
                                                        </a>
                                                        {/* </span> */}
                                                    </div>
                                                )}
                                                {card.website && (
                                                    <div
                                                        className="BusinessCard_contact"
                                                        style={{color: card.textIconColor}}
                                                    >
                                                        <WebIcon className="BusinessCard_contact-icon" />
                                                        <a href={card.website} target="blank">
                                                            {card.website}
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {!isMobile && (
                                    <a
                                        type="button"
                                        className="wallet-button wallet-inner-padding"
                                        download={true}
                                        target={'_blank'}
                                        href={`${process.env.REACT_APP_REQUESTS_API}/business-cards/${card._id}/download`}
                                        rel="noopener noreferrer"
                                    >
                                        <CloudDownloadIcon className="wallet-button-icon" />
                                        <span>Download</span>
                                    </a>
                                )}
                                <div className="notes">
                                    <div className="notes-title-wrapper">
                                        <h2 className="notes-title wallet-inner-padding">{businessCard.myNotes}</h2>
                                        {isMobile && (
                                            <SaveIcon className="wallet-button -colored" onClick={handleSaveNote} />
                                        )}
                                    </div>

                                    <div className="note -editable">
                                        {!isMobile && (
                                            <div className="note-header wallet-inner-padding">
                                                <div className="note-header-title"></div>
                                                <button
                                                    type="button"
                                                    className="wallet-button -colored"
                                                    onClick={() => handleSaveNote()}
                                                >
                                                    <SaveIcon className="wallet-button-icon" />
                                                    <span>{businessCard.save}</span>
                                                </button>
                                            </div>
                                        )}
                                        <TextField
                                            className="note-field"
                                            label={businessCard.addNewNote}
                                            id="filled-basic"
                                            variant="outlined"
                                            fullWidth={true}
                                            multiline={true}
                                            placeholder="Save your thoughts organized at one single space"
                                            value={noteValue}
                                            onChange={(e) => {
                                                handleChangeNote(e.target.value);
                                            }}
                                        />
                                    </div>
                                    {!!notes &&
                                        notes.map((note) => {
                                            return (
                                                <div className="note" key={note._id}>
                                                    <div className="note-header wallet-inner-padding">
                                                        <div className="note-header-title">
                                                            {moment(note.createdAt).format('lll')}
                                                        </div>
                                                        <button
                                                            type="button"
                                                            className="wallet-button"
                                                            onClick={() => {
                                                                handleDeleteNote(note._id);
                                                            }}
                                                        >
                                                            <Trash className="wallet-button-icon" />
                                                            <span>Delete</span>
                                                        </button>
                                                    </div>
                                                    <TextField
                                                        className="note-field"
                                                        id="filled-basic"
                                                        disabled={true}
                                                        variant="filled"
                                                        fullWidth={true}
                                                        multiline={true}
                                                        value={note.text}
                                                    />
                                                </div>
                                            );
                                        })}
                                </div>
                            </div>
                        ) : (
                            <p>User have no business card yet</p>
                        )}
                    </ColoredScrollbars>
                </div>
            )}
        </Box>
    );
};

export default connect(
    (state) => ({
        isMobile: getIsMobile(state),
        eventId: getEventId(state),
        userLoggedIn: getIsUserLoggedIn(state),
        loading: getUserLoading(state),
        privateChats: getUserPrivateChats(state),
    }),
    {
        createPrivateChat,
        openPrivateChat,
        openWallet,
        getBusinessCard,
        postBusinessCard,
        deleteBusinessCardNote,
    }
)(BusinessCard);
