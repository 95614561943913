import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as selectors from 'store/selectors';
import * as actions from 'store/actions';
import EventProfilePage from './EventProfilePage';

export default withRouter(
    connect(
        (state) => ({
            user: selectors.getUserData(state),
            eventRoles: selectors.getBooleanEventRolesList(state),
            event: selectors.getEventData(state),
            eventId: selectors.getEventId(state),
            eventSlug: selectors.getEventSlug(state),
            eventProfile: selectors.getEventProfile(state),
            language: selectors.getUserLanguage(state),
            userRoles: selectors.getUserRoles(state),
            accessManagement: selectors.getEventAccessManagement(state),
        }),
        {
            onRefreshUserUserData: actions.refreshUserData,
            getEventProfile: actions.getEventProfile,
            onGetGroupsList: actions.getGroupsList,
        }
    )(EventProfilePage)
);
