export const GET_EXHIBITOR_JOBS_START = 'EXHIBITOR_JOBS/GET_JOBS_START';
export const GET_EXHIBITOR_JOBS_SUCCESS = 'EXHIBITOR_JOBS/GET_JOBS_SUCCESS';
export const GET_EXHIBITOR_JOBS_FAIL = 'EXHIBITOR_JOBS/GET_JOBS_FAIL';

export const DELETE_EXHIBITOR_JOB_START = 'EXHIBITOR_JOBS/DELETE_JOB_START';
export const DELETE_EXHIBITOR_JOB_SUCCESS = 'EXHIBITOR_JOBS/DELETE_JOB_SUCCESS';
export const DELETE_EXHIBITOR_JOB_FAIL = 'EXHIBITOR_JOBS/DELETE_JOB_FAIL';

export const PUBLISH_EXHIBITOR_JOB_START = 'EXHIBITOR_JOBS/PUBLISH_JOB_START';
export const PUBLISH_EXHIBITOR_JOB_SUCCESS = 'EXHIBITOR_JOBS/PUBLISH_JOB_SUCCESS';
export const PUBLISH_EXHIBITOR_JOB_FAIL = 'EXHIBITOR_JOBS/PUBLISH_JOB_FAIL';

export const CREATE_EXHIBITOR_JOB = 'EXHIBITOR_JOBS/CREATE_JOB';
export const EDIT_EXHIBITOR_JOB = 'EXHIBITOR_JOBS/EDIT_JOB';
export const SET_EXHIBITOR_DELETED_JOB = 'EXHIBITOR_JOBS/SET_DELETED_JOB';
export const RESET_EXHIBITOR_DELETED_JOB = 'EXHIBITOR_JOBS/RESET_DELETED_JOB';
export const RESET_EXHIBITOR_ACTIVE_JOB = 'EXHIBITOR_JOBS/RESET_ACTIVE_JOB';

export const APPLY_EXHIBITOR_JOB_START = 'EXHIBITOR_JOBS/GET_JOBS_START';
export const APPLY_EXHIBITOR_JOB_FAIL = 'EXHIBITOR_JOBS/GET_JOBS_FAIL';
