import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import userReducer from './reducers/userReducer';
import eventReducer from './reducers/eventReducer';
import eventArchiveReducer from './reducers/eventArchiveReducer';
import exhibitorsReducer from './reducers/exhibitorsReducer';
import exhibitorReducer from './reducers/exhibitorReducer';
import invitationsReducer from './reducers/invitationsReducer';
import adminReducer from './reducers/adminReducer';
import layoutReducer from './reducers/layoutReducer';
import videoConference from './reducers/videoConference';
import etxSessionDashboard from './reducers/etxSessionDashboardReducer';
import sessionsReducer from './reducers/sessionReducer';
import organizerReducer from './reducers/organizerReducer';
import newsReducer from './reducers/newsReducer';
import buildingReducer from './reducers/buildingReducer';
import languagesReducer from './reducers/languagesReducer';
import interpretation from './reducers/interpretation';
import appliedJobsReducer from './reducers/appliedJobsReducer';
import eventsWithApplicationsReducer from './reducers/eventsWithApplicationsReducer';
import myJobApplicantsReducer from './reducers/myJobApplicants';
import exhibitorJobsReducer from './reducers/exhibitorJobsReducer';
import allParticipantsForJobsReducer from './reducers/allParticipantsForJobs';
import accountReducer from './reducers/accountReducer';
import loaderReducer from './reducers/loader';
import eventListingReducer from './reducers/eventListingReducer';
import timeslotReducer from './reducers/timeslotReducer';
import matchmakingReducer from './reducers/matchmakingReducer';
import meetingSetupReducer from './reducers/meetingSetupReducer';
import personalAgendaReducer from './reducers/personalAgendaReducer';
import businessCardReducer from './reducers/businessCardReducer';
import usersStatusesReducer from './reducers/usersStatusesReducer';
import exhibitorMarketplaceReducer from './reducers/exhibitorMarketplaceReducer';

import * as actionTypes from './actions/actionTypes';
import socialsPromotionReducer from './reducers/socialsPromotionReducer';
import wallReducer from './reducers/wallReducer';
import sessionListReducer from './reducers/sessionListReducer';
import socketConnections from './reducers/socketReducer';

const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

export const appReducer = combineReducers({
    socketConnections,
    user: userReducer,
    account: accountReducer,
    event: eventReducer,
    eventArchive: eventArchiveReducer,
    exhibitors: exhibitorsReducer,
    exhibitor: exhibitorReducer,
    exhibitorJobs: exhibitorJobsReducer,
    exhibitorMarketplace: exhibitorMarketplaceReducer,
    invitations: invitationsReducer,
    admin: adminReducer,
    layout: layoutReducer,
    videoConference,
    etxSessionDashboard,
    sessions: sessionsReducer,
    news: newsReducer,
    organizer: organizerReducer,
    building: buildingReducer,
    languages: languagesReducer,
    myJobApplicants: myJobApplicantsReducer,
    appliedJobs: appliedJobsReducer,
    eventsWithApplications: eventsWithApplicationsReducer,
    allParticipantsForJobs: allParticipantsForJobsReducer,
    interpretation,
    loader: loaderReducer,
    eventListing: eventListingReducer,
    timeslot: timeslotReducer,
    socialsPromotion: socialsPromotionReducer,
    wall: wallReducer,
    matchmaking: matchmakingReducer,
    sessionList: sessionListReducer,
    meetingSetup: meetingSetupReducer,
    personalAgenda: personalAgendaReducer,
    businessCard: businessCardReducer,
    usersStatuses: usersStatusesReducer,
});
const rootReducer = (state, action) => {
    if (action.type === actionTypes.LOGOUT_USER_SUCCESS) {
        //cleaning of redux state
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
