import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {FormControlLabel} from '@material-ui/core';
import {CloudDownload as CloudDownloadIcon} from '@material-ui/icons';

import SocialLoginButtons from '../SocialNetworkButtons';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import FullPageSpinner from '../FullPageSpinner';
import Spinner from '../Spinner';

import {whiteLabel} from 'config';
import {IS_MAIN_ENV, IS_SCA_ENV} from '../../../constants/shared';
import {getBackgroundImageUrl} from '../../../services/helpers/registrantionForm';
import {redirectToRegistrationOrContinueLogin} from '../../../services/utils/redirectToRegistrationOrContinueLogin';
import {getEmailParamFromUrl} from 'services/helpers/registrationFieldsHelper';
import {participantsRegistrationAccessUpdated, usersLoginAccessUpdated} from 'Api/socketApi';
import {getTextForBrandingTranslations} from 'services/helpers';

import './RegisterEvent.scss';

export default class RegisterEvent extends React.Component {
    state = {
        user: {
            fields: [
                {
                    name: 'first',
                    type: 'text',
                    value: '',
                    labelKey: 'inputFirstName',
                    multiline: 0,
                    validators: ['required'],
                    errorMessages: ['required']
                },
                {
                    name: 'last',
                    type: 'text',
                    labelKey: 'inputLastName',
                    value: '',
                    multiline: 0,
                    errorMessages: ['required'],
                    validators: ['required']
                },
                {
                    name: 'email',
                    type: 'text',
                    disabled: !!getEmailParamFromUrl(),
                    value: getEmailParamFromUrl(),
                    multiline: 0,
                    validators: ['required', 'isEmail'],
                    errorMessages: ['required', 'emailNotValid'],
                    labelKey: 'inputEmail'
                },
                {
                    name: 'password',
                    type: 'password',
                    value: '',
                    multiline: 0,
                    validators: ['required', 'minStringLength:8'],
                    errorMessages: ['required', 'passwordMinLength'],
                    labelKey: 'inputPassword'
                },
                {
                    name: 'passwordConfirmation',
                    type: 'password',
                    value: '',
                    multiline: 0,
                    validators: ['isPasswordMatch', 'required', 'minStringLength:8'],
                    errorMessages: ['passwordMismatch', 'required', 'passwordMinLength'],
                    labelKey: 'inputConfirmPassword'
                }
            ],
            agreeToPrivacyPolicy: false,
            file: '',
            imagePreviewUrl: ''
        },
        from: '/',
        disabled: true,
        imgError: false,
        imgErrorText: '',
        displayAgreeToPrivacyPolicyMessage: false,
        registerAsParticipant: false,
        registerAsExhibitor: false,
        translation: null
    };

    componentDidMount() {
        const {eventId, onSetEventPublicMenu, changeParticipantsRegistrationAccess, changeUsersLoginAccess} =
            this.props;

        onSetEventPublicMenu();
        window.scrollTo(0, 0);
        this.setRegistrationData();
        this.setLanguagesData();
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            let currentPassword = this.state.user.fields.find((field) => field.name === 'password');
            let currentPasswordValue = currentPassword.value;
            if (value !== currentPasswordValue) {
                return false;
            }
            return true;
        });

        participantsRegistrationAccessUpdated((err, data) => {
            if (data.eventId !== eventId) return;
            changeParticipantsRegistrationAccess(data);
        });
        usersLoginAccessUpdated((err, data) => {
            if (data.eventId !== eventId) return;
            changeUsersLoginAccess(data);
        });
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isPasswordMatch');
        this.props.onClearError();
    }

    componentDidUpdate(prevProps) {
        const {registerAsParticipant} = this.state;
        const {history, location, eventSlug, eventInfo, languages} = this.props;

        const query = new URLSearchParams(location.search);
        const registrationKey = query.get('registrationKey');
        const isRegistrationKeyValid = registrationKey && eventInfo.registrationKey === registrationKey;

        if (
            // TODO: this check is commented, exhibitors can register on SCA
            // (registerAsExhibitor && IS_SCA_ENV) ||
            registerAsParticipant &&
            eventInfo.hideParticipantsLink &&
            !isRegistrationKeyValid
        ) {
            history.push(`/event/${eventSlug}`);
        }
        if (prevProps.location.search !== location.search) {
            this.setRegistrationData();
        }
        if (prevProps.languages.eventLanguage !== languages.eventLanguage) {
            this.setLanguagesData();
            // this.setUpdatedLanguageFields();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.eventLanguage];

        this.setState({
            translation: translation
        });
    };

    setRegistrationData = () => {
        const query = new URLSearchParams(this.props.location.search);
        const registerAsExhibitor = query.get('registerAsExhibitor') === 'true';
        const registerAsParticipant = query.get('registerAsParticipant') === 'true';
        this.setState({
            registerAsExhibitor: registerAsExhibitor,
            registerAsParticipant: registerAsParticipant
        });

        // if the user landed on the registration page with a packageId in the URL
        // set the preselectedPackageId in the state of the React App
        // LOOK AT THE RedirectOnLogin COMPONENT => implemented it there
    };

    handleFieldChange = (index) => (e) => {
        let updatedFields = [...this.state.user.fields];
        updatedFields[index].value = e.target.value;
        this.setState({user: {...this.state.user, fields: updatedFields}});
    };

    handleCheckbox = (name) => (event) => {
        this.setState({
            user: {
                ...this.state.user,
                [name]: event.target.checked
            },
            displayAgreeToPrivacyPolicyMessage: !event.target.checked
        });
    };

    handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];

        let isValid = true;

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        let {translation} = this.state;
        if (!isValid) {
            this.setState({imgErrorText: translation?.errors.fileTooLarge});
        }

        isValid = (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') && isValid;
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            this.setState({imgErrorText: `${translation?.errors.imageFileNotSupported} jpeg, jpg or png.`});
        }

        reader.onloadend = () => {
            if (isValid) {
                this.setState({
                    user: {
                        ...this.state.user,
                        file: file,
                        imagePreviewUrl: reader.result
                    },
                    imgError: false,
                    imgErrorText: ''
                });
            } else {
                this.setState({imgError: true});
            }
        };
        reader.readAsDataURL(file);
    };

    handleRemoveImage = () => {
        this.setState({
            user: {
                ...this.state.user,
                file: '',
                imagePreviewUrl: ''
            },
            imgError: false,
            imgErrorText: ''
        });
    };

    handleRegister = () => {
        const {eventId} = this.props;
        let isValid = true;

        isValid = isValid && this.state.user.agreeToPrivacyPolicy;
        isValid = isValid && !this.state.imgError;

        if (isValid) {
            const formData = new FormData();
            this.state.user.fields.forEach((field) => {
                formData.append(field.name, field.value);
            });

            formData.append('consent', this.state.user.agreeToPrivacyPolicy);
            if (this.state.user.file) {
                formData.append('avatar', this.state.user.file, this.state.user.file.name, this.state.user.file.type);
            }

            this.props.onRegisterUser(formData, eventId);
        } else {
            if (!this.state.user.agreeToPrivacyPolicy) {
                this.setState({displayAgreeToPrivacyPolicyMessage: true});
            }
        }
    };

    render() {
        const {registerAsExhibitor, registerAsParticipant, translation, user} = this.state;
        const {fields, agreeToPrivacyPolicy, imagePreviewUrl, file} = user;
        const {
            eventId,
            eventInfo,
            loadingEvent,
            loadingUser,
            eventSlug,
            isRtlLanguage,
            eventLanguage,
            userRegistration
        } = this.props;

        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = <img src={imagePreviewUrl} alt="test" className="avatar" />;
        } else {
            $imagePreview = null;
        }
        if (loadingEvent && !eventInfo) {
            return (
                <div className="register-participant-step-1-spinner-container">
                    <FullPageSpinner />
                </div>
            );
        }
        let overlayColor = {};
        if (eventInfo.brandingData.overlayColorParticipantReg && !registerAsExhibitor) {
            overlayColor.background = eventInfo.brandingData.overlayColorParticipantReg;
        }
        if (eventInfo.brandingData.overlayColorExhibitorReg && registerAsExhibitor) {
            overlayColor.background = eventInfo.brandingData.overlayColorExhibitorReg;
        }

        const hideLogin = eventInfo?.hideLoginLink && IS_MAIN_ENV;

        return (
            <div>
                <div className="register-participant-step-1">
                    <div className="full-background-container p-relative d-flex">
                        <img
                            src={getBackgroundImageUrl(eventId, userRegistration?.image)}
                            className="full-background-img img-cover p-absolute w-100 h-100"
                            alt="background"
                        />
                        {!IS_SCA_ENV && (
                            <div className="full-background-overlay p-absolute w-100 h-100" style={overlayColor}></div>
                        )}
                        <div className="header-spacing-container">
                            <div className="form-wrapper w-100 h-100 d-flex justify-content-center align-items-center">
                                <div className="form-logo"></div>
                                {eventInfo ? (
                                    <div className="form-container d-flex">
                                        <div className={classNames('left-form', {isSca: IS_SCA_ENV})}>
                                            <div>
                                                <h1 className={whiteLabel}>
                                                    {userRegistration?.name
                                                        ? getTextForBrandingTranslations(
                                                              userRegistration.name,
                                                              eventLanguage
                                                          )
                                                        : ''}
                                                </h1>
                                                <p>
                                                    {userRegistration?.description
                                                        ? getTextForBrandingTranslations(
                                                              userRegistration.description,
                                                              eventLanguage,
                                                              false
                                                          )
                                                        : ''}
                                                </p>

                                                <SocialLoginButtons
                                                    registerAsExhibitor={registerAsExhibitor}
                                                    registerAsParticipant={registerAsParticipant}
                                                />
                                            </div>
                                        </div>
                                        <div className="right-form">
                                            <div className="form-body">
                                                <div
                                                    className={classNames('register-form-wrapper', {
                                                        isRtl: isRtlLanguage
                                                    })}
                                                >
                                                    <ValidatorForm ref="form" onSubmit={this.handleRegister}>
                                                        {fields.map((field, index) => {
                                                            return (
                                                                <TextValidator
                                                                    key={field.name}
                                                                    label={translation?.register?.[field.labelKey]}
                                                                    type={field.type}
                                                                    name={field.name}
                                                                    index={index}
                                                                    value={field.value}
                                                                    disabled={field?.disabled}
                                                                    variant="filled"
                                                                    onChange={this.handleFieldChange(index)}
                                                                    validators={field.validators}
                                                                    errorMessages={field.errorMessages.map(
                                                                        (el) => translation?.errors?.[el] || 'error'
                                                                    )}
                                                                    multiline={field.multiline > 0}
                                                                    rows={field.multiline}
                                                                    fullWidth={true}
                                                                    margin="normal"
                                                                    className="field-container"
                                                                />
                                                            );
                                                        })}
                                                        <label
                                                            htmlFor="file"
                                                            className={classNames('file ', {
                                                                [`error-label`]:
                                                                    this.state.imgErrorText || this.props.error
                                                            })}
                                                        >
                                                            <CloudDownloadIcon color="inherit" />
                                                            <p
                                                                className={classNames(
                                                                    {isRtl: isRtlLanguage},
                                                                    'file-label'
                                                                )}
                                                            >
                                                                {file
                                                                    ? translation?.register?.editImageText
                                                                    : translation?.register?.uploadImageText}
                                                            </p>
                                                        </label>
                                                        {this.state.imgErrorText && (
                                                            <p className="error-text">{this.state.imgErrorText}</p>
                                                        )}

                                                        <TextValidator
                                                            onChange={this.handleImageChange}
                                                            name="file"
                                                            type="file"
                                                            id="file"
                                                            className="d-none"
                                                        />

                                                        <div
                                                            className="imgPreview"
                                                            data-empty={$imagePreview ? 'true' : 'false'}
                                                        >
                                                            {file && (
                                                                <div>
                                                                    <span>{file.name}</span>
                                                                    <button
                                                                        type="button"
                                                                        onClick={this.handleRemoveImage}
                                                                    >
                                                                        {translation?.generalText?.remove}
                                                                    </button>
                                                                </div>
                                                            )}
                                                            {$imagePreview}
                                                        </div>

                                                        <div className="agree-terms">
                                                            <FormControlLabel
                                                                control={
                                                                    <CustomCheckbox
                                                                        checked={agreeToPrivacyPolicy}
                                                                        onChange={this.handleCheckbox(
                                                                            'agreeToPrivacyPolicy'
                                                                        )}
                                                                        value="agreeToPrivacyPolicy"
                                                                    />
                                                                }
                                                                label={
                                                                    <span
                                                                        className={classNames(
                                                                            {isRtl: isRtlLanguage},
                                                                            'agree-terms-message'
                                                                        )}
                                                                    >
                                                                        {translation?.register?.gdprTextFirst}
                                                                        <Link
                                                                            target="_blank"
                                                                            to={{
                                                                                pathname: `/event/${eventSlug}/terms-and-conditions/${eventLanguage}`
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                {translation?.register?.gdprTextTerms}
                                                                            </span>
                                                                        </Link>
                                                                        {translation?.register?.gdprTextSecond}
                                                                        <Link
                                                                            target="_blank"
                                                                            to={{
                                                                                pathname: `/event/${eventSlug}/cookies/${eventLanguage}`
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                {translation?.register?.gdprTextCookies}
                                                                            </span>
                                                                        </Link>{' '}
                                                                        {translation?.register?.gdprTextThird}
                                                                        <Link
                                                                            target="_blank"
                                                                            to={{
                                                                                pathname: `/event/${eventSlug}/privacy-policy/${eventLanguage}`
                                                                            }}
                                                                        >
                                                                            <span>
                                                                                {translation?.register?.gdprTextPrivacy}
                                                                            </span>
                                                                        </Link>
                                                                        .
                                                                    </span>
                                                                }
                                                            />
                                                            {this.state.displayAgreeToPrivacyPolicyMessage && (
                                                                <p className="error-text">
                                                                    {translation?.register?.gdprTextAgree}
                                                                </p>
                                                            )}
                                                        </div>
                                                        {this.props.error && (
                                                            <p className="error-text">
                                                                {this.props.error.includes('E11000 duplicate key error')
                                                                    ? translation?.register?.errorAlreadyHaveAccount
                                                                    : this.props.error}
                                                            </p>
                                                        )}
                                                        <button className="register-button" type="submit">
                                                            {translation?.register?.registerButton}
                                                        </button>
                                                    </ValidatorForm>
                                                    {!hideLogin && (
                                                        <p className="allready-have-account">
                                                            {translation?.register?.haveAccountText}
                                                            <span>
                                                                <Link
                                                                    className={classNames({isRtl: isRtlLanguage})}
                                                                    to={redirectToRegistrationOrContinueLogin(
                                                                        `/event/${eventSlug}/login`
                                                                    )}
                                                                >
                                                                    {translation?.register?.haveAccountLoginButton}
                                                                </Link>
                                                            </span>
                                                        </p>
                                                    )}
                                                </div>
                                                {(loadingUser || (loadingEvent && eventInfo)) && <Spinner />}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
