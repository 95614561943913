import React from 'react';
import classNames from 'classnames';

import {Link} from 'react-router-dom';
import {setCookiesAccepted} from 'services/helpers/setCookiesAccepted';
import {whiteLabel} from 'config';
import {useCookiesAccept, usePlatformTranslation} from 'services/hooks';

import './CookieBanner.scss';

const CookieBanner = ({platformLanguage, loggedIn}) => {
    const translation = usePlatformTranslation();
    const cookiesAccepted = useCookiesAccept();

    const acceptCookie = () => setCookiesAccepted(true);

    if (cookiesAccepted || loggedIn) {
        return null;
    }

    return (
        <div className={classNames('cookie-banner', {whiteLabel})}>
            <div className="cookie-banner__container">
                <p className="cookie-banner__text">
                    {translation?.footer.cookiesText}{' '}
                    <Link className="cookie-banner__link" to={{pathname: `/cookies/${platformLanguage}`}}>
                        {translation?.footer.cookiesLink}
                    </Link>
                </p>
                <div className="cookie-banner__accept-button" onClick={acceptCookie}>
                    {translation?.footer.cookiesButton}
                </div>
            </div>
        </div>
    );
};

export default CookieBanner;
