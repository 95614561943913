import React, {useCallback, useContext} from 'react';
import PropTypes from 'prop-types';

import {Switch} from '@material-ui/core';

import {ReactComponent as Trash} from 'Images/trash_icon.svg';

import {PARTICIPANT_TYPES, MEDIA_TYPES} from 'constants/organizer/sessions';
import {interpretationLanguageList} from 'constants/interpretation';

import {AbilityContext, Can} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

export const PermissionUser = ({
    user: {displayName, role, translationLanguage, userId},
    currentUser,
    isTogglePermissionAvailable,
    consumersList,
    sessions,
    room,
    timeslot
}) => {
    const ability = useContext(AbilityContext);
    const isInterpreterUser = role === PARTICIPANT_TYPES.interpreter;

    const consumerControls =
        sessions[userId]?.consumers.reduce(
            (acc, item) => {
                const consumerType = consumersList[item].appData.type;
                Object.assign(acc, {[consumerType]: consumersList[item].id});

                return acc;
            },
            {
                [MEDIA_TYPES.audio]: null,
                [MEDIA_TYPES.video]: null,
                [MEDIA_TYPES.consumerScreenShare]: null
            }
        ) || {};

    const isAudioPaused =
        sessions[userId]?.pausedConsumers && !sessions[userId]?.pausedConsumers[consumerControls[MEDIA_TYPES.audio]]
            ? true
            : false;

    const activeScreenSharedId = Object.values(consumersList).find(
        (consumer) => consumer?.appData.type === MEDIA_TYPES.consumerScreenShare
    )?.id;

    const handleToggleRemoteProducer = useCallback(
        (producerType) => () => room.toggleRemoteProducer(userId, producerType),
        [room, userId]
    );

    const handleKickUser = useCallback(() => room.kickParticipant(userId), [room, userId]);

    const displayTransactionLanguage = interpretationLanguageList.find(({code}) => {
        if (!translationLanguage) {
            return code === timeslot?.interpreters.find(({_id}) => _id === userId)?.interpretationLanguage;
        }

        return code === translationLanguage;
    })?.name;

    return (
        <div className="module-row">
            <div className="d-flex">
                <div className="avatar">{displayName?.charAt(0)}</div>
                <span className="avatar-name">{displayName}</span>
            </div>
            <div className="d-flex">
                <div className="module-action_column">
                    <Can
                        do={ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY}
                        on={ROLE_FEATURES_TYPES.ETX_SESSION_KICK_USER}
                        ability={ability}
                    >
                        <Trash className="kick-user-icon" onClick={handleKickUser} />
                    </Can>
                </div>
                {userId !== currentUser._id && !isInterpreterUser && isTogglePermissionAvailable && (
                    <>
                        <div
                            className="module-action_column"
                            onClick={handleToggleRemoteProducer(MEDIA_TYPES.consumerScreenShare)}
                        >
                            <Switch
                                checked={!!consumerControls[MEDIA_TYPES.consumerScreenShare]}
                                disabled={
                                    activeScreenSharedId &&
                                    activeScreenSharedId !== consumerControls[MEDIA_TYPES.consumerScreenShare]
                                }
                            />
                        </div>
                        <div className="module-action_column" onClick={handleToggleRemoteProducer(MEDIA_TYPES.audio)}>
                            <Switch checked={isAudioPaused} />
                        </div>
                        <div className="module-action_column" onClick={handleToggleRemoteProducer(MEDIA_TYPES.video)}>
                            <Switch checked={!!consumerControls[MEDIA_TYPES.video]} />
                        </div>
                    </>
                )}
                {isInterpreterUser && <div className="module-action_column">{displayTransactionLanguage}</div>}
            </div>
        </div>
    );
};

PermissionUser.propTypes = {
    user: PropTypes.object.isRequired,
    currentUser: PropTypes.object.isRequired,
    isTogglePermissionAvailable: PropTypes.bool,
    consumersList: PropTypes.object,
    sessions: PropTypes.object,
    room: PropTypes.object,
    timeslot: PropTypes.object
};
