import fileDownload from 'js-file-download';
import * as actionTypes from '../../../actionTypes/organizerActionTypes/eventBranding';
import {Api} from '../../../../Api';
import {getEvent} from '../../eventActions';
import {surveyQuestionActionTypes} from '../../../../constants/organizer/eventBranding';

export const saveEventBranding =
    ({eventId, data, successCallback, errorCallback}) =>
    async (dispatch) => {
        dispatch(saveEventBrandingRequest());

        try {
            await Api.request({
                url: `/event/${eventId}/branding`,
                method: 'post',
                payload: data,
            });

            dispatch(saveEventBrandingSuccess());
            if (successCallback) successCallback();
            dispatch(getEvent(eventId));
        } catch (error) {
            dispatch(saveEventBrandingFailed());
            if (errorCallback) errorCallback();
        }
    };

const saveEventBrandingRequest = () => ({
    type: actionTypes.SAVE_EVENT_BRANDING_REQUEST,
});

const saveEventBrandingSuccess = () => ({
    type: actionTypes.SAVE_EVENT_BRANDING_SUCCESS,
});

const saveEventBrandingFailed = () => ({
    type: actionTypes.SAVE_EVENT_BRANDING_FAIL,
});

const editBrandingImagesRequest = () => ({
    type: actionTypes.EDIT_BRANDING_IMAGES_REQUEST,
});

const editBrandingImagesSuccess = (data) => ({
    type: actionTypes.EDIT_BRANDING_IMAGES_SUCCESS,
    payload: data,
});

const editBrandingImagesFail = (error) => ({
    type: actionTypes.EDIT_BRANDING_IMAGES_FAIL,
    payload: error,
});

export const editBrandingImages = ({eventId, uploadUrl, formData, callback}) => {
    return async (dispatch) => {
        dispatch(editBrandingImagesRequest());

        try {
            const {brandingData} = await Api.request({
                method: 'post',
                url: `/event/${eventId}/${uploadUrl}`,
                payload: formData,
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });

            dispatch(editBrandingImagesSuccess(brandingData));
            dispatch(getEvent(eventId));

            callback && callback();
        } catch (error) {
            dispatch(editBrandingImagesFail(error));
        }
    };
};

const removeBrandingImagesStart = () => ({
    type: actionTypes.REMOVE_BRANDING_IMAGES_REQUEST,
});

const removeBrandingImagesSuccess = (data) => ({
    type: actionTypes.REMOVE_BRANDING_IMAGES_SUCCESS,
    payload: data,
});

const removeBrandingImagesFail = (error) => ({
    type: actionTypes.REMOVE_BRANDING_IMAGES_FAIL,
    payload: error,
});

export const removeBrandingImages = ({eventId, deleteUrl, callback, brandingProperty}) => {
    return async (dispatch) => {
        dispatch(removeBrandingImagesStart());
        try {
            await Api.request({
                method: 'delete',
                url: `/event/${eventId}/${deleteUrl}`,
                payload: {brandingProperty},
            });

            dispatch(removeBrandingImagesSuccess(brandingProperty));
            dispatch(getEvent(eventId));
            callback && callback();
        } catch (error) {
            dispatch(removeBrandingImagesFail(error));
        }
    };
};

export const editBrandingTranslation =
    ({eventId, data, successCallback, errorCallback}) =>
    async (dispatch) => {
        dispatch(editBrandingTranslationRequest());

        try {
            await Api.request({
                url: `/event/${eventId}/edit-branding-translation`,
                method: 'post',
                payload: data,
            });

            dispatch(editBrandingTranslationSuccess());
            if (successCallback) successCallback();
            dispatch(getEvent(eventId));
        } catch (error) {
            dispatch(editBrandingTranslationFailed());
            if (errorCallback) errorCallback();
        }
    };

const editBrandingTranslationRequest = () => ({
    type: actionTypes.EDIT_BRANDING_TRANSLATION_REQUEST,
});

const editBrandingTranslationSuccess = () => ({
    type: actionTypes.EDIT_BRANDING_TRANSLATION_SUCCESS,
});

const editBrandingTranslationFailed = () => ({
    type: actionTypes.EDIT_BRANDING_TRANSLATION_FAIL,
});

export const createSurveyQuestionForm = () => ({
    type: actionTypes.CREATE_SURVEY_QUESTION_FORM,
});

export const deleteSurveyQuestionForm = (payload) => ({
    type: actionTypes.DELETE_SURVEY_QUESTION_FORM,
    payload,
});

export const getServeyQuestions =
    ({eventId, callback}) =>
    async (dispatch, getState) => {
        const {
            organizer: {
                eventBranding: {
                    survey: {loading},
                },
            },
        } = getState();

        dispatch(getServeyQuestionsRequest());

        try {
            const {survey} = await Api.request({
                url: `/event/${eventId}/survey`,
                method: 'get',
                loading,
            });

            dispatch(getServeyQuestionsSuccess(survey));
            if (callback) callback();
        } catch (error) {
            dispatch(getServeyQuestionsFailed());
        }
    };

const getServeyQuestionsRequest = () => ({
    type: actionTypes.GET_SURVEY_QUESTIONS_REQUEST,
});

const getServeyQuestionsSuccess = (payload) => ({
    type: actionTypes.GET_SURVEY_QUESTIONS_SUCCESS,
    payload,
});

const getServeyQuestionsFailed = () => ({
    type: actionTypes.GET_SURVEY_QUESTIONS_FAIL,
});

export const changeServeyQuestion = (payload) => ({
    type: actionTypes.CHANGE_SURVEY_QUESTION,
    payload,
});

export const updateServeyQuestionStatus =
    ({action, surveyId, data, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
            organizer: {
                eventBranding: {
                    survey: {loading},
                },
            },
        } = getState();

        dispatch(updateServeyQuestionStatusRequest());

        let apiRequest = {};

        switch (action) {
            case surveyQuestionActionTypes.save:
                apiRequest = {method: 'post', url: `/event/${eventId}/survey`, payload: data, loading};
                break;
            case surveyQuestionActionTypes.update:
                apiRequest = {method: 'put', url: `/event/${eventId}/survey/${surveyId}`, payload: data, loading};
                break;
            case surveyQuestionActionTypes.delete:
                apiRequest = {method: 'delete', url: `/event/${eventId}/survey/${surveyId}`, loading};
                break;
            default:
                break;
        }

        try {
            const {survey} = await Api.request(apiRequest);

            dispatch(updateServeyQuestionStatusSuccess(survey));
            if (callback) callback();
            dispatch(getServeyQuestions({eventId}));
        } catch (error) {
            dispatch(updateServeyQuestionStatusFail());
        }
    };

const updateServeyQuestionStatusRequest = () => ({
    type: actionTypes.UPDATE_SURVEY_QUESTION_STATUS_REQUEST,
});

const updateServeyQuestionStatusSuccess = (payload) => ({
    type: actionTypes.UPDATE_SURVEY_QUESTION_STATUS_SUCCESS,
    payload,
});

const updateServeyQuestionStatusFail = () => ({
    type: actionTypes.UPDATE_SURVEY_QUESTION_STATUS_FAIL,
});

export const removeServeyQuestionOption = (payload) => ({
    type: actionTypes.REMOVE_SURVEY_QUESTION_OPTION,
    payload,
});

export const addServeyQuestionOption = (payload) => ({
    type: actionTypes.ADD_SURVEY_QUESTION_OPTION,
    payload,
});

export const changeServeyQuestionOption = (payload) => ({
    type: actionTypes.CHANGE_SURVEY_QUESTION_OPTION,
    payload,
});

export const postServeyThankYouMessage =
    ({data, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
            organizer: {
                eventBranding: {
                    survey: {loading},
                },
            },
        } = getState();

        dispatch(postThankYouMessageRequest());

        try {
            await Api.request({
                url: `/event/${eventId}/survey/thank-you-message`,
                method: 'post',
                payload: data,
                loading,
            });

            dispatch(postThankYouMessageSuccess());
            if (callback) callback();
            dispatch(getEvent(eventId));
        } catch (error) {
            dispatch(postThankYouMessageFail());
        }
    };

const postThankYouMessageRequest = () => ({
    type: actionTypes.POST_THANK_YOU_MESSAGE_REQUEST,
});

const postThankYouMessageSuccess = () => ({
    type: actionTypes.POST_THANK_YOU_MESSAGE_SUCCESS,
});

const postThankYouMessageFail = () => ({
    type: actionTypes.POST_THANK_YOU_MESSAGE_FAIL,
});

export const downloadSurveyDataStart = () => ({
    type: actionTypes.DOWNLOAD_SURVEY_DATA_START,
});

export const downloadSurveyDataSuccess = () => ({
    type: actionTypes.DOWNLOAD_SURVEY_DATA_SUCCESS,
});

const downloadSurveyDataFailed = () => ({
    type: actionTypes.DOWNLOAD_SURVEY_DATA_FAIL,
});

export const downloadSurveyData = () => async (dispatch, getState) => {
    const {
        event: {eventId, eventSlug},
    } = getState();

    dispatch(downloadSurveyDataStart());

    try {
        const data = await Api.request({
            url: `/event/${eventId}/survey/results-excel`,
            method: 'get',
            responseType: 'blob',
        });

        dispatch(downloadSurveyDataSuccess());

        fileDownload(data, `${eventSlug}_survey.xlsx`);
    } catch (error) {
        dispatch(downloadSurveyDataFailed());
    }
};
