import {Box, CircularProgress} from '@material-ui/core';
import React from 'react';
import ColoredScrollbars from '../../../../../../../../../../ColoredScrollbars/ColoredScrollbars';
import RemovePerson from '../../../../../../../../../../../../Images/person_remove.svg';
import AddPerson from '../../../../../../../../../../../../Images/person_add.svg';

const ChatMembers = ({
    loading = false,
    filteredUsers = [],
    usersToAdd = [],
    translation = {},
    handleRemoveUser = () => {},
    handleAddUser = () => {},
}) => {
    return (
        <div className="members-chat-wrapper">
            {loading ? (
                <Box display="flex" width="100" justifyContent="center" p={2} alignItems="center">
                    <CircularProgress />
                </Box>
            ) : (
                <ColoredScrollbars>
                    <ul className="members-list-event">
                        {filteredUsers.map((participant) => (
                            <React.Fragment key={participant._id}>
                                <li>
                                    <div
                                        className="user-avatar"
                                        style={{
                                            backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${participant.avatarSmall}')`,
                                        }}
                                    />
                                    <div className="chat-member-details">
                                        <p>
                                            {participant.first} {participant.last}
                                        </p>
                                        {participant.company !== '' ? <span>{participant.company}</span> : null}
                                    </div>
                                    {usersToAdd.includes(participant) ? (
                                        <button
                                            className="add-member member-control"
                                            onClick={() => handleRemoveUser(participant._id)}
                                            disabled={loading}
                                        >
                                            <img src={RemovePerson} alt="Remove Member" width="24" height="24" />
                                            <p>{translation?.generalText.remove}</p>
                                        </button>
                                    ) : (
                                        <button
                                            className="add-member member-control"
                                            onClick={() => handleAddUser(participant)}
                                        >
                                            <img src={AddPerson} alt="Add Member" width="24" height="24" />
                                            <p>{translation?.generalText.add}</p>
                                        </button>
                                    )}
                                </li>
                                <div className="user-wrapper-separator" />
                            </React.Fragment>
                        ))}

                        {!filteredUsers.length && <li>{translation?.chatsDropdown.newChatNoUsersMessage}</li>}
                    </ul>
                </ColoredScrollbars>
            )}
        </div>
    );
};

export default React.memo(ChatMembers);
