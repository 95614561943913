import {connect} from 'react-redux';
import * as selectors from 'store/selectors';
import MatchingDialogInitialPage from './MatchingDialogInitialPage';

export default connect(
    (state) => ({
        matchingForm: selectors.getMatchMakingForm(state),
        user: selectors.getUserData(state),
        eventId: selectors.getEventId(state),
    }),
    {}
)(MatchingDialogInitialPage);
