import {connect} from 'react-redux';
import * as selectors from 'store/selectors';
import * as actions from 'store/actions';
import EventProfile from './EventProfile';

export default connect(
    (state) => ({
        isLargeScreen: selectors.getIsLargeScreen(state),
        eventId: selectors.getEventId(state),
    }),
    {
        onSocetSetEnabeledEventMatchmaking: actions.socetSetEnabeledEventMatchmaking,
        onSocetSetEventHasMatchmaking: actions.socetSetEventHasMatchmaking,
    }
)(EventProfile);
