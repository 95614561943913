import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '&.isRtl': {
            direction: 'rtl',
        },
    },
    wrapper: {
        backgroundColor: 'var(--theme-background-default-color)',
    },
    icon: {
        color: theme.palette.primary.main,
    },
    summary: {
        minHeight: '48px !important',
    },
    summaryContent: {
        margin: '0 !important',
    },
    header: {
        position: 'relative',
        width: '85%',
        display: 'flex',
    },
    details: {
        padding: '0px 16px',
        alignItems: 'center',
    },
    chip: {
        '&:not(:last-child)': {
            marginRight: '8px',
        },
        '&.isRtl': {
            direction: 'rtl',
            '&:not(:last-child)': {
                marginRight: '0',
                marginLeft: '8px',
            },
        },
    },
    chipDeleteIcon: {
        '&.isRtl': {
            margin: '0 -6px 0 5px',
        },
    },
    chipDetails: {
        marginBottom: '8px',
        '&:not(:last-child)': {
            marginRight: '8px',
        },
    },
    chipslist: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    ExpendIcon: {
        '&.isRtl': {
            marginRight: 'inherit !important',
            marginLeft: '-12px !important',
        },
    },
    count: {
        position: 'absolute',
        top: '-50%',
        right: '-50px',
        transform: 'translateY(50%)',
        fontWeight: 500,
        fontSize: 14,
        lineHeight: '1.4em',
        '&.isRtl': {
            right: 'inherit',
            left: '-50px',
        },
    },
}));
