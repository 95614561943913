import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import cn from 'classnames';
import {Tabs, Tab} from '@material-ui/core';

import CloseTopNavButton from '../CloseTopNavButton/CloseTopNavButton';
import AgendaProgram from './components/AgendaProgram/AgendaProgram';
import AgendaMeetings from './components/AgendaMeetings/AgendaMeetings';

import {usePlatformTranslation} from '../../../../../../../services/hooks';
import './AgendaPopup.scss';

const useGettingDataFromReduxStore = () => {
    const event = useSelector((state) => state.event.data);
    const user = useSelector((state) => state.user.data);
    const isLargeScreen = useSelector((state) => state.layout.isLargeScreen);

    return {event, user, isLargeScreen};
};

export const AgendaPopup = ({offsetLeft, activeTab: _activeTab}) => {
    const {event, isLargeScreen} = useGettingDataFromReduxStore();
    const [activeTab, setActiveTab] = useState(_activeTab === 'program' ? 0 : 1);
    const {personalAgenda} = usePlatformTranslation();

    return (
        <div
            className={`${!isLargeScreen ? 'agenda-mobile' : ''} agenda-page dropdown-component agenda-dropdown`}
            style={{
                left: offsetLeft ? offsetLeft + 43 : 0,
                maxWidth: '680px',
                width: '100%',
                minWidth: '270px',
            }}
        >
            <CloseTopNavButton />

            {event && (
                <div className="auditoriums-program">
                    <Tabs className="tabs-wrapper" centered value={activeTab} onChange={(e, tab) => setActiveTab(tab)}>
                        <Tab label={personalAgenda.myProgram} className="tabs-wrapper__tab" />
                        <Tab label={personalAgenda.myMeetings} className="tabs-wrapper__tab" />
                    </Tabs>

                    <div className={cn('auditoriums-program-slider', {hide: activeTab === 1})}>
                        <AgendaProgram />
                    </div>

                    <div className={cn('auditoriums-program-slider', {hide: activeTab === 0})}>
                        <AgendaMeetings />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AgendaPopup;
