import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        float: 'right',
        flexDirection: 'column',
        '&.isRtl': {
            direction: 'rtl',
        },
    },
    searchInput: {
        fontFamily: 'Montserrat !important',
        fontSize: '16px !important',
    },
    inputContainer: {
        backgroundColor: '#fff !important',
    },
    clearAll: {
        fontSize: '16px',
    },
    filterLabel: {
        alignItems: 'center',
        fontWeight: 700,
    },
    filterLabelActive: {
        color: theme.palette.primary.main,
    },
    dropdownOpen: {
        transform: 'rotate(-180deg)',
        color: theme.palette.primary.main,
    },
    filtersList: {
        width: 280,
        position: 'absolute',
        zIndex: 200,
        overflowY: 'auto',
        overflowX: 'hidden',
        backgroundColor: 'rgba(255, 255, 255, 0)',
        top: 60,
        bottom: 10,
        right: 10,
        textAlign: 'left',
        '&.isRtl': {
            right: 'unset',

            '&.isDesktop': {
                bottom: 30,
            },
        },
    },

    selectItem: {
        '&:not(:last-child)': {
            marginBottom: '10px',
        },
    },
    checkbox: {
        margin: '0 0 5px 0',
    },
    accordion: {
        background: theme.palette.background.default,
    },
    summeryContent: {
        margin: '0 !important',
        minHeight: '48px',
    },
    accordionExpanded: {
        margin: '0 !important',
    },
    ExpendIcon: {
        '&.isRtl': {
            marginRight: 'inherit !important',
            marginLeft: '-12px !important',
        },
    },

    accordionDetails: {
        flexDirection: 'column',
    },
    controlleWrapper: {
        background: 'var(--theme-background-default-color)',
        display: 'flex',
        justifyContent: 'flex-end',
        height: '50px',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
        borderRadius: ' 0 0 6px 6px',
    },
    controlleBtn: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        letterSpacing: '0.0125em',
        textTransform: 'uppercase',
        color: ' #2B2B2B',
        '&:not(:last-child)': {
            marginLeft: '10px',
        },
        '&:disabled': {
            color: '#898989',
        },
    },
}));
