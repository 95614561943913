export const GET_OVERALL_REPORT_START = 'ORGANIZER_STATISTICS/GET_OVERALL_REPORT_START';
export const GET_OVERALL_REPORT_SUCCESS = 'ORGANIZER_STATISTICS/GET_OVERALL_REPORT_SUCCESS';
export const GET_OVERALL_REPORT_FAIL = 'ORGANIZER_STATISTICS/GET_OVERALL_REPORT_FAIL';

export const GET_WALLS_REPORT_START = 'ORGANIZER_STATISTICS/GET_WALLS_REPORT_START';
export const GET_WALLS_REPORT_SUCCESS = 'ORGANIZER_STATISTICS/GET_WALLS_REPORT_SUCCESS';
export const GET_WALLS_REPORT_FAIL = 'ORGANIZER_STATISTICS/GET_WALLS_REPORT_FAIL';

export const GET_AUDITORIUMS_REPORT_START = 'ORGANIZER_STATISTICS/GET_AUDITORIUMS_REPORT_START';
export const GET_AUDITORIUMS_REPORT_SUCCESS = 'ORGANIZER_STATISTICS/GET_AUDITORIUMS_REPORT_SUCCESS';
export const GET_AUDITORIUMS_REPORT_FAIL = 'ORGANIZER_STATISTICS/GET_AUDITORIUMS_REPORT_FAIL';
export const GHANGE_TIMESLOT_CURRENT_STAT_TYPE = 'ORGANIZER_STATISTICS/GHANGE_TIMESLOT_CURRENT_STAT_TYPE';
export const CLEAR_AUDITORIUM_TIMESLOTS = 'ORGANIZER_STATISTICS/CLEAR_AUDITORIUM_TIMESLOTS';

export const GET_POLLS_STATISTICS_START = 'ORGANIZER_STATISTICS/GET_POLLS_STATISTICS_START';
export const GET_POLLS_STATISTICS_SUCCESS = 'ORGANIZER_STATISTICS/GET_POLLS_STATISTICS_SUCCESS';
export const GET_POLLS_STATISTICS_FAIL = 'ORGANIZER_STATISTICS/GET_POLLS_STATISTICS_FAIL';
export const GHANGE_POLL_TIMESLOT_CURRENT_STAT_TYPE = 'ORGANIZER_STATISTICS/GHANGE_POLL_TIMESLOT_CURRENT_STAT_TYPE';

export const GET_EXHIBITORS_REPORT_START = 'ORGANIZER_STATISTICS/GET_EXHIBITORS_REPORT_START';
export const GET_EXHIBITORS_REPORT_SUCCESS = 'ORGANIZER_STATISTICS/GET_EXHIBITORS_REPORT_SUCCESS';
export const GET_EXHIBITORS_REPORT_FAIL = 'ORGANIZER_STATISTICS/GET_EXHIBITORS_REPORT_FAIL';
export const GHANGE_EXHIBITOR_CURRENT_STAT_TYPE = 'ORGANIZER_STATISTICS/GHANGE_EXHIBITOR_CURRENT_STAT_TYPE';
export const CHANGE_EXHIBITOR_PAGINATION_PAGE = 'ORGANIZER_STATISTICS/CHANGE_EXHIBITOR_PAGINATION_PAGE';
export const GET_EXHIBITOR_ATTENDEES = 'ORGANIZER_STATISTICS/GET_EXHIBITOR_ATTENDEES';

export const GET_NETWORKING_REPORT_START = 'ORGANIZER_STATISTICS/GET_NETWORKING_REPORT_START';
export const GET_NETWORKING_REPORT_SUCCESS = 'ORGANIZER_STATISTICS/GET_NETWORKING_REPORT_SUCCESS';
export const GET_NETWORKING_REPORT_FAIL = 'ORGANIZER_STATISTICS/GET_NETWORKING_REPORT_FAIL';
