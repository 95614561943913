import React from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {FormControlLabel} from '@material-ui/core';
import {CloudDownload as CloudDownloadIcon} from '@material-ui/icons';

import SocialNetworkButtons from '../SocialNetworkButtons';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import FullPageSpinner from '../FullPageSpinner';
import Spinner from '../Spinner';

import {getPlatformBackground, whiteLabel} from '../../../config';
import {IS_SCA_ENV} from '../../../constants/shared';

import './RegisterPlatform.scss';

class RegisterPlatform extends React.Component {
    state = {
        user: {
            fields: [
                {
                    name: 'first',
                    type: 'text',
                    value: '',
                    multiline: 0,
                    validators: ['required'],
                    errorMessages: ['required'],
                    labelKey: 'inputFirstName',
                },
                {
                    name: 'last',
                    type: 'text',
                    value: '',
                    multiline: 0,
                    validators: ['required'],
                    errorMessages: ['required'],
                    labelKey: 'inputLastName',
                },
                {
                    name: 'email',
                    type: 'text',
                    value: '',
                    multiline: 0,
                    validators: ['required', 'isEmail'],
                    errorMessages: ['required', 'emailNotValid'],
                    labelKey: 'inputEmail',
                },
                {
                    name: 'password',
                    type: 'password',
                    value: '',
                    multiline: 0,
                    validators: ['required', 'minStringLength:8'],
                    errorMessages: ['required', 'passwordMinLength'],
                    labelKey: 'inputPassword',
                },
                {
                    name: 'passwordConfirmation',
                    type: 'password',
                    value: '',
                    multiline: 0,
                    validators: ['isPasswordMatch', 'required', 'minStringLength:8'],
                    errorMessages: ['passwordMismatch', 'required', 'passwordMinLength'],
                    labelKey: 'inputConfirmPassword',
                },
            ],
            agreeToPrivacyPolicy: false,
            file: '',
            imagePreviewUrl: '',
        },
        disabled: true,
        imgError: false,
        imgErrorText: '',
        displayAgreeToPrivacyPolicyMessage: false,
        translation: null,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            let currentPassword = this.state.user.fields.find((field) => field.name === 'password');
            let currentPasswordValue = currentPassword.value;
            if (value !== currentPasswordValue) {
                return false;
            }
            return true;
        });
        this.props.onSetPlatformMenu();
        this.setLanguagesData();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.languages.eventLanguage !== this.props.languages.eventLanguage) {
            this.setLanguagesData();
        }
    }
    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.eventLanguage];
        this.setState({
            translation: translation,
        });
    };
    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isPasswordMatch');
        this.props.onClearError();
    }

    handleFieldChange = (index) => (e) => {
        let updatedFields = [...this.state.user.fields];
        updatedFields[index].value = e.target.value;
        this.setState({user: {...this.state.user, fields: updatedFields}}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({disabled: !isValid});
            });
        });
    };

    handleCheckbox = (name) => (event) => {
        this.setState({
            user: {
                ...this.state.user,
                [name]: event.target.checked,
            },
            displayAgreeToPrivacyPolicyMessage: !event.target.checked,
        });
    };

    handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        let isValid = true;
        const ImgSize = 2 * 1024 * 1024;

        isValid = file.size < ImgSize && isValid;
        if (!isValid) {
            this.setState({
                imgErrorText: this.state.translation?.errors?.fileTooLarge,
            });
        }

        isValid = (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') && isValid;
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            this.setState({
                imgErrorText: this.state.translation?.errors?.imageFileNotSupported,
            });
        }

        reader.onloadend = () => {
            if (isValid) {
                this.setState({
                    user: {
                        ...this.state.user,
                        file,
                        imagePreviewUrl: reader.result,
                    },
                    imgError: false,
                    imgErrorText: '',
                });
            } else {
                this.setState({imgError: true});
            }
        };
        reader.readAsDataURL(file);
    };

    handleRemoveImage = () => {
        this.setState({
            user: {
                ...this.state.user,
                file: '',
                imagePreviewUrl: '',
            },
            imgError: false,
            imgErrorText: '',
        });
    };

    handleRegister = () => {
        let isValid = true; // TODO - research why so tricky

        isValid = isValid && this.state.user.agreeToPrivacyPolicy;
        isValid = isValid && !this.state.imgError;

        if (isValid) {
            const formData = new FormData();
            this.state.user.fields.forEach((field) => {
                formData.append(field.name, field.value);
            });

            formData.append('consent', this.state.user.agreeToPrivacyPolicy);

            if (this.state.user.file) {
                formData.append('avatar', this.state.user.file, this.state.user.file.name, this.state.user.file.type);
            }
            this.props.onRegisterUser(formData);
        } else {
            if (!this.state.user.agreeToPrivacyPolicy) {
                this.setState({displayAgreeToPrivacyPolicyMessage: true});
            }
        }
    };

    render() {
        const {fields, agreeToPrivacyPolicy, imagePreviewUrl, file} = this.state.user;
        const {loadingUser, platformLanguage} = this.props;
        const {translation} = this.state;
        const {isRtlLanguage} = this.props.languages;
        let $imagePreview = null;
        if (imagePreviewUrl) {
            $imagePreview = <img src={imagePreviewUrl} alt="test" className="avatar" />;
        } else {
            $imagePreview = null;
        }
        if (loadingUser) {
            return (
                <div className="register-participant-step-1-spinner-container">
                    <FullPageSpinner />
                </div>
            );
        }
        const BackgroundImage = getPlatformBackground(whiteLabel);
        return (
            <div>
                <div className="register-participant-step-1">
                    <div className="full-background-container p-relative d-flex">
                        <img
                            src={BackgroundImage}
                            className="full-background-img img-cover p-absolute w-100 h-100"
                            alt="background"
                        />
                        {!IS_SCA_ENV && <div className="full-background-overlay p-absolute w-100 h-100"></div>}
                        <div className="header-spacing-container">
                            <div className="form-wrapper w-100 h-100 d-flex justify-content-center align-items-center">
                                <div className="form-logo"></div>
                                <div className="form-container d-flex">
                                    <div className="left-form">
                                        <SocialNetworkButtons
                                            registerAsExhibitor={false}
                                            registerAsParticipant={false}
                                        />
                                    </div>
                                    <div className="right-form">
                                        <div className="form-body">
                                            <p className={classNames('form-title', whiteLabel)}>
                                                {translation?.register?.registerText}
                                            </p>
                                            <div className="register-form-wrapper">
                                                <ValidatorForm ref="form" onSubmit={this.handleRegister}>
                                                    {fields.map((field, index) => {
                                                        return (
                                                            <TextValidator
                                                                key={field.name}
                                                                label={translation?.register?.[field.labelKey]}
                                                                type={field.type}
                                                                name={field.name}
                                                                index={index}
                                                                value={field.value}
                                                                variant="filled"
                                                                onChange={this.handleFieldChange(index)}
                                                                validators={field.validators}
                                                                errorMessages={field.errorMessages.map(
                                                                    (el) => translation?.errors?.[el] || 'error'
                                                                )}
                                                                multiline={field.multiline > 0}
                                                                rows={field.multiline}
                                                                fullWidth={true}
                                                                margin="normal"
                                                                className={'field-container'}
                                                            />
                                                        );
                                                    })}
                                                    <label
                                                        htmlFor="file"
                                                        className={classNames('file ', {
                                                            [`error-label`]:
                                                                this.state.imgErrorText || this.props.error,
                                                        })}
                                                    >
                                                        <CloudDownloadIcon color="inherit" />
                                                        <p className={classNames({isRtl: isRtlLanguage})}>
                                                            {file
                                                                ? translation?.register?.editImageText
                                                                : translation?.register?.uploadImageText}
                                                        </p>
                                                    </label>
                                                    {this.state.imgErrorText && (
                                                        <p className="error-text">{this.state.imgErrorText}</p>
                                                    )}

                                                    <TextValidator
                                                        onChange={this.handleImageChange}
                                                        name="file"
                                                        type="file"
                                                        id="file"
                                                        className="d-none"
                                                    />

                                                    <div
                                                        className="imgPreview"
                                                        data-empty={$imagePreview ? 'true' : 'false'}
                                                    >
                                                        {file && (
                                                            <div>
                                                                <span>{file.name}</span>
                                                                <button type="button" onClick={this.handleRemoveImage}>
                                                                    {translation?.generalText?.remove}
                                                                </button>
                                                            </div>
                                                        )}
                                                        {$imagePreview}
                                                    </div>

                                                    <div className="agree-terms">
                                                        <FormControlLabel
                                                            control={
                                                                <CustomCheckbox
                                                                    checked={agreeToPrivacyPolicy}
                                                                    onChange={this.handleCheckbox(
                                                                        'agreeToPrivacyPolicy'
                                                                    )}
                                                                    value="agreeToPrivacyPolicy"
                                                                />
                                                            }
                                                            label={
                                                                <span
                                                                    className={classNames(
                                                                        {isRtl: isRtlLanguage},
                                                                        'agree-terms-message'
                                                                    )}
                                                                >
                                                                    {translation?.register?.gdprTextFirst}
                                                                    <Link
                                                                        target="_blank"
                                                                        to={{
                                                                            pathname: `/terms-and-conditions/${platformLanguage}`,
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            {translation?.register?.gdprTextTerms}
                                                                        </span>
                                                                    </Link>
                                                                    {translation?.register?.gdprTextSecond}
                                                                    <Link
                                                                        target="_blank"
                                                                        to={{
                                                                            pathname: `/cookies/${platformLanguage}`,
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            {translation?.register?.gdprTextCookies}
                                                                        </span>
                                                                    </Link>{' '}
                                                                    {translation?.register?.gdprTextThird}
                                                                    <Link
                                                                        target="_blank"
                                                                        to={{
                                                                            pathname: `/privacy-policy/${platformLanguage}`,
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            {translation?.register?.gdprTextPrivacy}
                                                                        </span>
                                                                    </Link>
                                                                    .
                                                                </span>
                                                            }
                                                        />
                                                        {this.state.displayAgreeToPrivacyPolicyMessage && (
                                                            <p className="error-text">
                                                                {translation?.register?.gdprTextAgree}
                                                            </p>
                                                        )}
                                                    </div>
                                                    {this.props.error && (
                                                        <p className="error-text">
                                                            {this.props.error.includes('E11000 duplicate key error')
                                                                ? translation?.register?.errorAlreadyHaveAccount
                                                                : this.props.error}
                                                        </p>
                                                    )}
                                                    <button
                                                        className={classNames('register-button', {
                                                            disabled:
                                                                this.state.disabled ||
                                                                !this.state.user.agreeToPrivacyPolicy,
                                                        })}
                                                        type="submit"
                                                    >
                                                        {translation?.register?.registerButton}
                                                    </button>
                                                </ValidatorForm>
                                                <p className="allready-have-account">
                                                    {translation?.register?.haveAccountText}
                                                    <span>
                                                        <Link
                                                            className={classNames({isRtl: isRtlLanguage})}
                                                            to={{pathname: '/login'}}
                                                        >
                                                            {translation?.register?.haveAccountLoginButton}
                                                        </Link>
                                                    </span>
                                                </p>
                                            </div>
                                            {loadingUser && <Spinner />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegisterPlatform;
