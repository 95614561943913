import React, {Component} from 'react';
import ReactPlayer from 'react-player';

import VideoPlaceholder from '../../../../../Images/pages/platform-homepage/video.jpg';
import {ReactComponent as PlayIcon} from '../../../../../Images/pages/platform-homepage/play-icon.svg';
// TODO: change for SCA
import RightEffectImage from '../../../../../Images/pages/platform-homepage/animations/video_effects_right_frame.png';
import LeftEffectImage from '../../../../../Images/pages/platform-homepage/animations/video_effects_left_frame.png';

import './HomepageVideo.scss';

class HomepageVideo extends Component {
    startVideo = () => {
        this.setState({playing: !this.state.playing});
    };

    state = {
        playing: false,
    };

    render() {
        return (
            <section className={'onvent-video-presentation ' + (this.state.playing ? 'playing' : '')}>
                <div id="left-effects" className="left-effect">
                    <img src={LeftEffectImage} alt="" />
                </div>
                <div className="container">
                    <div className="video-container">
                        <div className="effect-image">
                            <div className="image-container">
                                <img src={VideoPlaceholder} alt="" />
                            </div>
                        </div>
                        <div className="main-image">
                            <div className="image-container">
                                <ReactPlayer
                                    url={'https://youtu.be/7qqY3R4mvXE'}
                                    playing={this.state.playing}
                                    className="react-player"
                                    controls={false}
                                    width="100%"
                                    height="100%"
                                    onContextMenu={(e) => e.preventDefault()}
                                />
                                <img src={VideoPlaceholder} onClick={this.startVideo} alt="" />
                            </div>
                        </div>
                        <div className="play-icon-container" onClick={this.startVideo}>
                            <PlayIcon />
                        </div>
                    </div>
                </div>
                <div id="right-effects" className="right-effect">
                    <img src={RightEffectImage} alt="" />
                </div>
            </section>
        );
    }
}

export default HomepageVideo;
