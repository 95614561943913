import React, {useEffect, useCallback} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {useDispatch, useSelector} from 'react-redux';
import {
    setAllChats,
    setEventChats,
    getEventChats,
    getAllChats,
} from '../../../../../../../../../../../store/actions/userActions';
import {usePlatformTranslation} from '../../../../../../../../../../../services/hooks';

const a11yProps = (index) => ({
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
});

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'relative',
        flexGrow: 1,
    },
    tab: {
        minWidth: 50,
        fontFamily: 'inherit',
        fontWeight: 'bold',
        fontSize: '12px',
    },
    tabSelected: {
        color: theme.palette.primary.main,
    },
    tabFullWidth: {
        flexBasis: 'auto',
        flexShrink: 0,
    },
}));

function ChatsTabs({handleChange, activeTab}) {
    const dispatch = useDispatch();
    const translation = usePlatformTranslation();
    const {eventId} = useSelector((state) => state.event);

    const setChatTab = useCallback(() => {
        if (activeTab === 0) {
            dispatch(setEventChats());
            dispatch(getEventChats(eventId));
        }
        if (activeTab === 1) {
            dispatch(setAllChats());
            dispatch(getAllChats());
        }
    }, [activeTab, dispatch, eventId]);

    useEffect(() => {
        setChatTab();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setChatTab]);

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Tabs
                value={activeTab}
                onChange={handleChange}
                variant="fullWidth"
                aria-label="chat types tabs"
                indicatorColor="primary"
            >
                <Tab
                    classes={{
                        root: classes.tab,
                        selected: classes.tabSelected,
                        fullWidth: classes.tabFullWidth,
                    }}
                    label={translation.chatsDropdown.eventChats}
                    {...a11yProps(0)}
                />
                <Tab
                    classes={{
                        root: classes.tab,
                        selected: classes.tabSelected,
                        fullWidth: classes.tabFullWidth,
                    }}
                    label={translation.chatsDropdown.allChats}
                    {...a11yProps(1)}
                />
            </Tabs>
        </div>
    );
}

export default ChatsTabs;
