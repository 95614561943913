import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilityStateFunction';

const initialMenu = {
    platformMenu: true,
    eventPublicMenu: false,
    eventProtectedMenu: false,
    adminMenu: false,
    currentPage: '',
};

const initialState = {
    menu: {...initialMenu},
    isMobile: false,
    isTablet: false,
    isLargeScreen: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_PLATFORM_MENU:
            return updateObject(state, {
                menu: {
                    ...state.menu,
                    platformMenu: true,
                    eventPublicMenu: false,
                    eventProtectedMenu: false,
                    adminMenu: false,
                },
            });
        case actionTypes.SET_EVENT_PUBLIC_MENU:
            return updateObject(state, {
                menu: {
                    ...state.menu,
                    platformMenu: false,
                    eventPublicMenu: true,
                    eventProtectedMenu: false,
                    adminMenu: false,
                },
            });

        case actionTypes.SET_EVENT_PROTECTED_MENU:
            return updateObject(state, {
                menu: {
                    ...state.menu,
                    platformMenu: false,
                    eventPublicMenu: false,
                    eventProtectedMenu: true,
                    adminMenu: false,
                },
            });
        case actionTypes.SET_ADMIN_MENU:
            return updateObject(state, {
                menu: {
                    ...state.menu,
                    platformMenu: false,
                    eventPublicMenu: false,
                    eventProtectedMenu: false,
                    adminMenu: true,
                },
            });
        case actionTypes.SET_IS_MOBILE:
            return updateObject(state, {
                isMobile: true,
                isTablet: false,
                isLargeScreen: false,
            });
        case actionTypes.SET_IS_TABLET:
            return updateObject(state, {
                isMobile: false,
                isTablet: true,
                isLargeScreen: false,
            });
        case actionTypes.SET_IS_LARGE_SCREEN:
            return updateObject(state, {
                isMobile: false,
                isTablet: false,
                isLargeScreen: true,
            });

        default:
            return state;
    }
};

export default reducer;
