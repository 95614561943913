import React from 'react';

import Svg from '../Svg/Svg';

import './Agenda.scss';

const IconAgenda = () => (
    <Svg className="IconAgenda">
        <path
            className="IconAgenda__el"
            d="M10 1h12a1 1 0 011 1v11a1 1 0 01-1 1h-2v1h2a2 2 0 002-2V2a2 2 0 00-2-2H10a2 2 0 00-2 2v4h1V2a1 1 0 011-1z"
        />
        <path d="M18 6h-1V4h-2v2H7V4H5v2H4c-1.11 0-1.99.9-1.99 2L2 21a2 2 0 002 2h14c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm1 15a1 1 0 01-1 1H4a1 1 0 01-1-1V8a1 1 0 011-1h14a1 1 0 011 1v13z" />
        <path d="M5 9h5v5H5zM17.12 15.06L16.06 14l-4.88 4.88-2.12-2.12L8 17.82 11.18 21l5.94-5.94z" />
    </Svg>
);

export default IconAgenda;
