import classNames from 'classnames';
import React from 'react';
import {connect} from 'react-redux';

import {ReactComponent as Arrow} from 'Images/showfloor-arrow-image.svg';

import './AuditoriumNavigation.scss';

const AUDITORIUM_NAME_LENGTH = 35;
class AuditoriumNavigation extends React.Component {
    state = {
        translation: null,
    };

    componentDidMount() {
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    getPreviousAuditoriumIndex = () => {
        const {auditoriums, auditoriumRoomIndex} = this.props;
        let previousAuditoriumIndex = auditoriumRoomIndex - 1;
        if (auditoriumRoomIndex === 0) {
            previousAuditoriumIndex = auditoriums.length - 1;
        }
        return previousAuditoriumIndex;
    };

    getNextAuditoriumIndex = () => {
        const {auditoriums, auditoriumRoomIndex} = this.props;
        let nextAuditoriumIndex = auditoriumRoomIndex + 1;
        if (auditoriumRoomIndex === auditoriums.length - 1) {
            nextAuditoriumIndex = 0;
        }
        return nextAuditoriumIndex;
    };

    limiteAuditoriumName = (name, length) => {
        if (name.length <= length) return name;
        return `${name.slice(0, length)}...`;
    };

    render() {
        const {currentTimeSlotIndex, auditoriumTimeslots, setActiveAuditoriumIndex, auditoriums} = this.props;
        const {translation} = this.state;
        const nextName = this.limiteAuditoriumName(
            auditoriums[this.getPreviousAuditoriumIndex()].name,
            AUDITORIUM_NAME_LENGTH
        );
        const prevName = this.limiteAuditoriumName(
            auditoriums[this.getNextAuditoriumIndex()].name,
            AUDITORIUM_NAME_LENGTH
        );
        const isRtl = this.props.languages.isRtlLanguage;
        return (
            <div
                className={classNames('auditoriums-navigation', {
                    'has-booth-wall': currentTimeSlotIndex !== -1 && auditoriumTimeslots[currentTimeSlotIndex].video,
                })}
            >
                <div className="button previous" onClick={setActiveAuditoriumIndex(this.getPreviousAuditoriumIndex())}>
                    <div className="button-container">
                        <div className="details">
                            <Arrow className="primary-stroke-svg" />
                        </div>
                        <div className={classNames('auditorium-name', {isRtl})}>
                            <p>
                                {translation?.auditorium.goTo}
                                <br />
                                {prevName}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="button next" onClick={setActiveAuditoriumIndex(this.getNextAuditoriumIndex())}>
                    <div className="button-container">
                        <div className="details">
                            <Arrow className="primary-stroke-svg" />
                        </div>
                        <div className={classNames('auditorium-name', {isRtl})}>
                            <p>
                                {translation?.auditorium.goTo}
                                <br />
                                {nextName}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect((state) => ({
    languages: state.languages,
}))(AuditoriumNavigation);
