import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import * as selectors from 'store/selectors';
import {SocialNetworkButtons} from './SocialNetworkButtons';

export default withRouter(
    connect((state) => ({
        eventId: selectors.getEventId(state),
        eventRegPackage: selectors.getEventRegPackage(state),
        languages: selectors.getLanguages(state),
        eventShortLink: selectors.getEventShortLink(state),
        userRegistration: selectors.getEventUserRegistration(state),
    }))(SocialNetworkButtons)
);
