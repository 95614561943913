import * as actionTypes from '../actionTypes/wallActionTypes';
import {updateObject} from '../utilityStateFunction';

const initialState = {
    loadingPosts: false,
    loading: false,
    wallType: null,
    posts: [],
    page: null,
    total: null,
};

const wallReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.WALL_GET_POSTS_START: {
            return updateObject(state, {
                loadingPosts: true,
            });
        }

        case actionTypes.WALL_GET_POSTS_SUCCESS: {
            return updateObject(state, {
                loadingPosts: false,
                posts: payload?.page === 1 ? payload?.posts : [...state.posts, ...payload?.posts],
                page: payload?.page,
                total: payload?.total,
                wallType: payload?.wallType,
            });
        }

        case actionTypes.WALL_UPDATE_POSTS: {
            return updateObject(state, {
                posts: payload,
            });
        }

        case actionTypes.WALL_DELETE_POST_TRIGGER: {
            return updateObject(state, {
                posts: state.posts.filter(({_id}) => _id !== payload.postId),
                total: state.total - 1,
            });
        }

        case actionTypes.WALL_GET_POSTS_FAIL: {
            return updateObject(state, {
                loadingPosts: false,
            });
        }

        case actionTypes.WALL_UPDATE_POST_ON_RECEIVE: {
            let lastPinedPostIndex;
            const updatedPosts = state.posts.filter((el, i) => {
                if (el.isPinned) {
                    lastPinedPostIndex = i + 1;
                }

                return el._id !== payload.post._id;
            });

            updatedPosts.splice(lastPinedPostIndex || 0, 0, payload.post);

            return updateObject(state, {
                posts: updatedPosts,
                total: state.total + 1,
            });
        }

        case actionTypes.WALL_UPDATE_COMMENT_ON_RECEIVE: {
            const updatedPosts = [...state.posts];
            const indexOfCommentedPost = updatedPosts.findIndex((post) => post._id === payload?.postId);
            if (indexOfCommentedPost !== -1) {
                const updatedComments = updatedPosts[indexOfCommentedPost].comments.filter(
                    (el) => el._id !== payload?.comment._id
                );
                updatedComments.push(payload?.comment);
                updatedPosts[indexOfCommentedPost].comments = updatedComments;
            }

            return updateObject(state, {
                posts: updatedPosts,
            });
        }

        case actionTypes.WALL_DELETE_COMMENT_TRIGGER: {
            const updatedPosts = [...state.posts];
            const indexOfPost = updatedPosts.findIndex((post) => post._id === payload?.postId);

            if (indexOfPost !== -1) {
                const updatedComments = updatedPosts[indexOfPost].comments.filter(
                    (comment) => comment._id !== payload?.commentId
                );
                updatedPosts[indexOfPost].comments = updatedComments;
            }

            return updateObject(state, {
                posts: updatedPosts,
            });
        }

        case actionTypes.WALL_LIKE_POST_TRIGGER: {
            const updatedPosts = [...state.posts];
            const indexOfPost = updatedPosts.findIndex((post) => post._id === payload?.postId);

            if (indexOfPost !== -1 && !updatedPosts[indexOfPost].likes.some((like) => like._id === payload?.like._id)) {
                updatedPosts[indexOfPost].likes.push(payload?.like);
            }

            return updateObject(state, {
                posts: updatedPosts,
            });
        }

        case actionTypes.WALL_UNLIKE_POST_TRIGGER: {
            const {data, currentUser} = payload;
            const updatedPosts = [...state.posts];
            const indexOfPost = updatedPosts.findIndex((post) => post._id === data?.postId);

            if (
                indexOfPost !== -1 &&
                updatedPosts[indexOfPost].likes.some((like) => like.user._id === currentUser?._id)
            ) {
                const updatedLikes = updatedPosts[indexOfPost].likes.filter(
                    (like) => like.user._id !== currentUser?._id
                );
                updatedPosts[indexOfPost].likes = updatedLikes;
            }

            return updateObject(state, {
                posts: updatedPosts,
            });
        }

        case actionTypes.WALL_PIN_POST_TRIGGER: {
            const updatedPosts = state.posts.filter((post) => post._id !== payload?.postId);
            const pinnedPost = state.posts.find((post) => post._id === payload?.postId);
            pinnedPost.isPinned = true;

            return updateObject(state, {
                posts: [pinnedPost, ...updatedPosts],
            });
        }
        case actionTypes.WALL_UNPIN_POST_TRIGGER: {
            const pinPost = state.posts.find((post) => post._id === payload?.postId);
            pinPost.isPinned = false;
            let lastPinedPostIndex;

            let postIndex = 0;

            const updatedPosts = state.posts.filter((post, i) => {
                if (new Date(pinPost.createdAt) < new Date(post?.createdAt)) {
                    postIndex = i;
                }

                if (post.isPinned) {
                    lastPinedPostIndex = i;
                }

                return post._id !== payload?.postId;
            });

            postIndex = lastPinedPostIndex > postIndex ? lastPinedPostIndex : postIndex;

            updatedPosts.splice(postIndex || 0, 0, pinPost);

            return updateObject(state, {
                posts: updatedPosts,
            });
        }

        case actionTypes.WALL_RESET_DATA: {
            return initialState;
        }
        default:
            return state;
    }
};

export default wallReducer;
