export const redirectToRegistrationOrContinueLogin = (link) => {
    if (
        window.location.search === '?registerAsParticipant=true' ||
        window.location.search === '?registerAsExhibitor=true'
    ) {
        return {pathname: link, search: window.location.search};
    }

    return {pathname: link};
};
