import {ZoomStreamControls} from './ZoomStreamControls';
import {connect} from 'react-redux';
import {sessionStreamStart, sessionStreamStop} from 'store/actions';
import {getTimeslotData, getTimeslotLoading} from 'store/selectors';

export default connect(
    (state) => ({
        session: getTimeslotData(state),
        isLoading: getTimeslotLoading(state),
    }),
    {
        sessionStreamStart,
        sessionStreamStop,
    }
)(ZoomStreamControls);
