import React from 'react';
import {Helmet} from 'react-helmet';
import {whiteLabel, whiteLabelsList, platformMetaTitle, platformMetaDescription} from './config';
import {mainAssets} from './assets/meta-assets/main';
import {scaAssets} from './assets/meta-assets/sca';
import {egyptPavilionAssets} from './assets/meta-assets/egypt-pavilion';
import {unctadAssets} from './assets/meta-assets/unctad';
import {eventFolder} from 'config';
import {socialShareLink} from 'constants/socialShareLink';
import {getEventId, getPromotionData} from 'store/selectors';
import {useSelector} from 'react-redux';

const assetsConfig = {
    [whiteLabelsList.main]: mainAssets,
    [whiteLabelsList.sca]: scaAssets,
    [whiteLabelsList.egyptPavilion]: egyptPavilionAssets,
    [whiteLabelsList.unctad]: unctadAssets,
};

const HTMLHead = () => {
    const eventId = useSelector(getEventId);
    const promotion = useSelector(getPromotionData);

    const imageUrl = promotion?.image ? `${eventFolder}${eventId}/${promotion.image}` : '';

    return (
        <Helmet
            key={`${promotion?.image}-${promotion?.caption}`}
            title={platformMetaTitle[whiteLabel] || platformMetaTitle.main}
            meta={[
                {
                    name: 'description',
                    content: promotion?.caption || platformMetaDescription.main,
                },
                {
                    itemprop: 'name',
                    content: promotion?.slug,
                },
                {
                    itemprop: 'description',
                    content: promotion?.caption || platformMetaDescription.main,
                },
                {
                    itemprop: 'image',
                    content: imageUrl,
                },
                {
                    name: 'twitter:card',
                    content: imageUrl ? 'summary_large_image' : 'summary',
                },
                {
                    nam: 'twitter:site',
                    content: '@publisher_handle',
                },
                {
                    name: 'twitter:title',
                    content: platformMetaTitle[whiteLabel] || platformMetaTitle.main,
                },
                {
                    name: 'twitter:description',
                    content: promotion?.caption || platformMetaDescription.main,
                },
                {
                    name: 'twitter:creator',
                    content: '@author_handle',
                },
                {
                    name: 'twitter:image:src',
                    content: promotion?.imageUrl,
                },
                {
                    property: 'og:title',
                    content: platformMetaTitle[whiteLabel] || platformMetaTitle.main,
                },
                {
                    property: 'og:type',
                    content: 'article',
                },
                {
                    property: 'og:url',
                    content: socialShareLink({eventId}),
                },
                {
                    property: 'og:image',
                    content: imageUrl,
                },
                {
                    property: 'og:description',
                    content: promotion?.caption || platformMetaDescription.main,
                },
                {
                    property: 'og:site_name',
                    content: promotion?.slug,
                },
                {charset: 'utf-8'},
                {
                    name: 'msapplication-TileColor',
                    content: '#da532c',
                },
                {
                    name: 'theme-color',
                    content: '#ffffff',
                },
                {
                    name: 'viewport',
                    content: 'width=device-width, initial-scale=1, shrink-to-fit=no',
                },
                {
                    name: 'google-site-verification',
                    content: '_a8iRwFHjInprbvWIVNC6sGDSynxiNatrLWujRdo7eU',
                },
            ]}
            link={[
                {
                    rel: 'apple-touch-icon',
                    sizes: '180x180',
                    href: assetsConfig[whiteLabel]?.AppleTouchIcon || assetsConfig.main.AppleTouchIcon,
                },
                {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '32x32',
                    href: assetsConfig[whiteLabel]?.FaviconLarge || assetsConfig.main.FaviconLarge,
                },
                {
                    rel: 'icon',
                    type: 'image/png',
                    sizes: '16x16',
                    href: assetsConfig[whiteLabel]?.FaviconSmall || assetsConfig.main.FaviconSmall,
                },
                {
                    rel: 'shortcut icon',
                    href: assetsConfig[whiteLabel]?.Favicon || assetsConfig.main.Favicon,
                },
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800&display=swap',
                },
                {
                    rel: 'preconnect',
                    href: 'https://fonts.gstatic.com',
                },
                {
                    rel: 'stylesheet',
                    href: 'https://fonts.googleapis.com/css2?family=Tajawal:wght@300;400;500;700;800&display=swap',
                },
            ]}
        ></Helmet>
    );
};

export default HTMLHead;
