import React from 'react';

const BusinessCardEditingIconArrow = () => (
    <svg width="148" height="161" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1 115.743c79.101 3.782 83.1-13.109 133.807-78.42m0 0l-20.374 1.294m20.374-1.293l3.085 19.507"
            stroke="var(--theme-primary-color)"
            strokeWidth="2"
        />
    </svg>
);

export default BusinessCardEditingIconArrow;
