import * as actionTypes from '../actionTypes/myJobApplicantsActionTypes';
import {Api} from '../../Api';

export const getMyJobApplicants = (exhibitorId) => async (dispatch, getState) => {
    const {
        myJobApplicants: {loading},
        event: {eventId},
    } = getState();

    dispatch(getMyJobsApplicantsStart());

    try {
        const {jobApplications} = await Api.request({
            url: `/event/${eventId}/exhibitor/${exhibitorId}/applications`,
            method: 'get',
            loading,
        });

        dispatch(getMyJobsApplicantsSuccess(jobApplications));
    } catch (error) {
        dispatch(getMyJobsApplicantsFail(error));
    }
};

export const getMyJobsApplicantsStart = () => {
    return {
        type: actionTypes.GET_MY_JOB_APPLICANTS_START,
    };
};
export const getMyJobsApplicantsSuccess = (jobs) => {
    return {
        type: actionTypes.GET_MY_JOB_APPLICANTS_SUCCESS,
        payload: jobs,
    };
};
export const getMyJobsApplicantsFail = () => {
    return {
        type: actionTypes.GET_MY_JOB_APPLICANTS_FAIL,
    };
};
