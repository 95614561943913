import React from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';

import InformDialog from '../../../../../../../InformDialog/InformDialog';
import IconContainer from '../../../../../../../IconContainer/IconContainer';

import {checkIfEventHasEnded} from '../../../../../../../../../services/utils';
import * as actions from '../../../../../../../../../store/actions/index';
import {ReactComponent as AuditoriumIcon} from '../../../../../../../../../Images/auditorium_icon.svg';
import {ReactComponent as ShowFloorIcon} from '../../../../../../../../../Images/showfloor_icon.svg';
import {ReactComponent as ArchiveIcon} from '../../../../../../../../../Images/archive_icon.svg';
import {ReactComponent as MenuDropdownIcon} from '../../../../../../../../../Images/arrow_down_grey.svg';
import {BUILDING_TYPES, IS_SCA_ENV} from '../../../../../../../../../constants/shared';
import {FALLBACK_TRANSLATION_SLUG} from '../../../../../../../../../constants/translations';

import './Rooms.scss';
import classNames from 'classnames';

class Rooms extends React.Component {
    state = {
        showVideo: false,
        auditoriumElementStyle: {},
        showFloorElementStyle: {},
        sliderContainerStyle: {},
        lobbyVideoContainerStyle: {},
        infoBoothContainerStyle: {},
        bannerContainerStyle: {},
        showNoShowfloorDialog: false,
        showNoShowfloorAccessDialog: false,
        showNoAuditoriumDialog: false,
        showNoAuditoriumAccessDialog: false,
        showNoBuildingDialog: false,
        showNoBuildingAccessDialog: false,
        translation: null,
        activeBuildingTab: null,
        activeBuildingType: null,
    };

    componentDidMount() {
        this.setLanguagesData();
        this.setDefaultTabs();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setDefaultTabs = () => {
        const {event, history} = this.props;
        const currentPathname = history?.location.pathname;
        const buildingIds = event.buildings.map((building) => building._id);
        buildingIds.forEach((buildingId) => {
            if (currentPathname.includes(buildingId)) this.setActiveBuilding(buildingId);
        });
        currentPathname.includes(BUILDING_TYPES.showfloor)
            ? this.setState({activeBuildingType: BUILDING_TYPES.showfloor})
            : this.setState({activeBuildingType: BUILDING_TYPES.auditorium});
    };

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    closeDialog = () => {
        this.setState({
            showVideo: false,
            showNoShowfloorDialog: false,
            showNoShowfloorAccessDialog: false,
            showNoAuditoriumDialog: false,
            showNoAuditoriumAccessDialog: false,
            showNoBuildingDialog: false,
            showNoBuildingAccessDialog: false,
        });
    };

    checkIfEventHasEnded = () => {
        // check if event has ended
        // if yes, redirect to the video archive page
        if (this.props.event) {
            return checkIfEventHasEnded(this.props.event, this.props.event.utcTimezone);
        }
    };

    handleOpenNoShowfloorDialog = () => {
        this.setState({showNoShowfloorDialog: true});
    };

    handleOpenNoShowfloorAccess = () => {
        this.setState({showNoShowfloorAccessDialog: true});
    };

    handleOpenNoAuditoriumDialog = () => {
        this.setState({showNoAuditoriumDialog: true});
    };
    handleOpenNoAuditoriumAccess = () => {
        this.setState({showNoAuditoriumAccessDialog: true});
    };

    handleRedirectToBuilding = (building, type) => {
        const {event, resourcesAccess} = this.props;

        if (building.restrictedAccess) {
            this.setState({showNoBuildingDialog: true});
        } else {
            if (event.hasAccessManagement && resourcesAccess) {
                // check if the user has access to the current building
                const hasAccessToBuilding = resourcesAccess.buildings.includes(building._id);
                if (hasAccessToBuilding) {
                    this.redirectToBuilding(building, type);
                } else {
                    this.setState({showNoBuildingAccessDialog: true});
                }
            } else {
                this.redirectToBuilding(building, type);
            }
        }
    };

    redirectToBuilding = (building, type) => {
        const {eventSlug} = this.props;
        this.props.onSetBuildingId(building._id);

        if (type === BUILDING_TYPES.auditorium) {
            const buildingAuditoriumProgramEnded = checkIfEventHasEnded(building, this.props.event.utcTimezone);
            if (buildingAuditoriumProgramEnded) {
                this.props.history.push(`/event/${eventSlug}/village/building/${building._id}/auditorium-archive`);
            } else {
                this.props.history.push(`/event/${eventSlug}/village/building/${building._id}/auditorium`);
            }
        } else {
            this.props.history.push(`/event/${eventSlug}/village/building/${building._id}/showfloor`);
        }
    };

    setActiveBuilding = (buildingId) => {
        this.setState({activeBuildingTab: buildingId});
    };

    render() {
        const {
            showNoShowfloorDialog,
            showNoShowfloorAccessDialog,
            showNoAuditoriumDialog,
            showNoAuditoriumAccessDialog,
            showNoBuildingDialog,
            showNoBuildingAccessDialog,
            translation,
            activeBuildingTab,
            activeBuildingType,
        } = this.state;
        const {event, eventSlug, resourcesAccess} = this.props;
        const eventHasEnded = this.checkIfEventHasEnded();
        const currentPathname = this.props.history?.location.pathname;

        const activeAuditorium = this.props.location.pathname.indexOf(`/${eventSlug}/auditorium`) > -1 ? true : false;
        const activeAuditoriumArchive =
            this.props.location.pathname.indexOf(`/${eventSlug}/auditorium-archive`) > -1 ? true : false;
        const activeShowfloor = this.props.location.pathname.indexOf(`/${eventSlug}/showfloor`) > -1 ? true : false;
        const activeEventArchive =
            this.props.location.pathname.indexOf(`/${eventSlug}/event-archive`) > -1 ? true : false;
        const fleshbackTranslation = this.props.languages.translations[FALLBACK_TRANSLATION_SLUG];

        return (
            <>
                <div id="rooms-dropdown" className="dropdown-component">
                    <div className="page-title">
                        <h3>{event.hasVillage ? translation?.hubDropdownTitle : translation?.roomsDropdown.title}</h3>
                    </div>

                    {event.hasVillage ? (
                        <div className="rooms-container">
                            {event.buildings?.map((building) => {
                                return (
                                    <div
                                        className={classNames('building-tab', {
                                            'building-tab--active': activeBuildingTab === building._id,
                                        })}
                                        onClick={() => {
                                            this.setActiveBuilding(building._id);
                                        }}
                                        key={building._id}
                                    >
                                        <div className="building-tab__info">
                                            <p>{building.name}</p>

                                            <IconContainer className="rooms-container__arrow-icon">
                                                <MenuDropdownIcon
                                                    className={classNames({
                                                        'primary-fill-svg building-tab__icon--active':
                                                            activeBuildingTab === building._id,
                                                    })}
                                                />
                                            </IconContainer>
                                        </div>

                                        {activeBuildingTab === building._id ? (
                                            <div className="building-subtabs">
                                                {building.type === BUILDING_TYPES.auditorium ||
                                                building.type === BUILDING_TYPES.mixed ? (
                                                    <div className="building-subtabs__item">
                                                        <AuditoriumIcon className="primary-fill-svg" />
                                                        <p
                                                            className={classNames('building-subtabs__text', {
                                                                'building-subtabs__text--active':
                                                                    currentPathname.includes(building._id) &&
                                                                    activeBuildingType === BUILDING_TYPES.auditorium,
                                                            })}
                                                            onClick={() =>
                                                                this.handleRedirectToBuilding(
                                                                    building,
                                                                    BUILDING_TYPES.auditorium
                                                                )
                                                            }
                                                        >
                                                            {translation?.roomsDropdown.auditoriumButton}
                                                        </p>
                                                    </div>
                                                ) : null}
                                                {building.type === BUILDING_TYPES.showfloor ||
                                                building.type === BUILDING_TYPES.mixed ? (
                                                    <div className="building-subtabs__item">
                                                        <ShowFloorIcon className="primary-fill-svg" />

                                                        <p
                                                            className={classNames('building-subtabs__text', {
                                                                'building-subtabs__text--active':
                                                                    currentPathname.includes(building._id) &&
                                                                    activeBuildingType === BUILDING_TYPES.showfloor,
                                                            })}
                                                            onClick={() =>
                                                                this.handleRedirectToBuilding(
                                                                    building,
                                                                    BUILDING_TYPES.showfloor
                                                                )
                                                            }
                                                        >
                                                            {translation?.roomsDropdown.showfloorButton}
                                                        </p>
                                                    </div>
                                                ) : null}
                                            </div>
                                        ) : null}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div className="rooms-container">
                            <div className="rooms-container__item">
                                {event.restrictAuditoriumAccess ? (
                                    <>
                                        <AuditoriumIcon />
                                        <p onClick={this.handleOpenNoAuditoriumDialog}>
                                            {translation?.roomsDropdown.auditoriumButton}
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        {event.hasAccessManagement &&
                                        resourcesAccess !== null &&
                                        resourcesAccess.auditoriums.length === 0 ? (
                                            <>
                                                <AuditoriumIcon />
                                                <p onClick={this.handleOpenNoAuditoriumAccess}>
                                                    {translation?.roomsDropdown.auditoriumButton}
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                {eventHasEnded ? (
                                                    <Link
                                                        className={classNames('room-link', {
                                                            'room-link--active': activeAuditoriumArchive,
                                                        })}
                                                        to={{pathname: `/event/${eventSlug}/auditorium-archive/`}}
                                                    >
                                                        <AuditoriumIcon />
                                                        <p className="room-link__text">
                                                            {translation?.roomsDropdown.auditoriumButton}
                                                        </p>
                                                    </Link>
                                                ) : (
                                                    <Link
                                                        className={classNames('room-link', {
                                                            'room-link--active': activeAuditorium,
                                                        })}
                                                        to={{pathname: `/event/${eventSlug}/auditorium/`}}
                                                    >
                                                        <AuditoriumIcon />
                                                        <p className="room-link__text">
                                                            {translation?.roomsDropdown.auditoriumButton}
                                                        </p>
                                                    </Link>
                                                )}
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="rooms-container__item">
                                {event.restrictShowfloorAccess ? (
                                    <>
                                        <ShowFloorIcon />
                                        <p onClick={this.handleOpenNoShowfloorDialog}>
                                            {translation?.roomsDropdown.showfloorButton}
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        {event.hasAccessManagement &&
                                        resourcesAccess !== null &&
                                        !resourcesAccess.showfloor ? (
                                            <>
                                                <ShowFloorIcon />
                                                <p onClick={this.handleOpenNoShowfloorAccess}>
                                                    {translation?.roomsDropdown.showfloorButton}
                                                </p>
                                            </>
                                        ) : (
                                            <Link
                                                className={classNames('room-link', {
                                                    'room-link--active': activeShowfloor,
                                                })}
                                                to={{pathname: `/event/${eventSlug}/showfloor/`}}
                                            >
                                                <ShowFloorIcon />
                                                <p className="room-link__text">
                                                    {translation?.roomsDropdown.showfloorButton}
                                                </p>
                                            </Link>
                                        )}
                                    </>
                                )}
                            </div>
                            {this.props.eventArchive && this.props.eventArchive.length > 0 && (
                                <div className="rooms-container__item">
                                    <Link
                                        className={classNames('room-link', {
                                            'room-link--active': activeEventArchive,
                                        })}
                                        to={{pathname: `/event/${eventSlug}/event-archive`}}
                                    >
                                        <ArchiveIcon />
                                        <p className="room-link__text">{translation?.roomsDropdown.archiveButton}</p>
                                    </Link>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {showNoShowfloorDialog && (
                    <InformDialog
                        open={showNoShowfloorDialog}
                        onClose={this.closeDialog}
                        dialogTitle={`${translation?.closedShowfloorDialog.title}`}
                        dialogDescription={`${translation?.closedShowfloorDialog.content}`}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {showNoShowfloorAccessDialog && (
                    <InformDialog
                        open={showNoShowfloorAccessDialog}
                        onClose={this.closeDialog}
                        dialogTitle={`${translation?.closedShowfloorDialog.title}`}
                        dialogDescription={`${translation?.closedShowfloorDialog.contentPackage}`}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {showNoAuditoriumDialog && (
                    <InformDialog
                        open={showNoAuditoriumDialog}
                        onClose={this.closeDialog}
                        dialogTitle={`${translation?.closedAuditoriumDialog.title}`}
                        dialogDescription={`${translation?.closedAuditoriumDialog.content}`}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {showNoAuditoriumAccessDialog && (
                    <InformDialog
                        open={showNoAuditoriumAccessDialog}
                        onClose={this.closeDialog}
                        dialogTitle={`${translation?.closedAuditoriumDialog.title}`}
                        dialogDescription={
                            IS_SCA_ENV
                                ? `${
                                      translation?.closedAuditoriumDialog?.scaContentPackage ||
                                      fleshbackTranslation?.closedAuditoriumDialog?.scaContentPackage
                                  }`
                                : `${translation?.closedAuditoriumDialog?.contentPackage}`
                        }
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {showNoBuildingDialog && (
                    <InformDialog
                        open={showNoBuildingDialog}
                        onClose={this.closeDialog}
                        dialogTitle={'Building information'}
                        dialogDescription={'Access to the Building has been restricted.'}
                        dialogButtonLabel={'OK'}
                    />
                )}
                {showNoBuildingAccessDialog && (
                    <InformDialog
                        open={showNoBuildingAccessDialog}
                        onClose={this.closeDialog}
                        dialogTitle={'Building information'}
                        dialogDescription={"Your access package doesn't have access to the Building."}
                        dialogButtonLabel={'OK'}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        event: state.event.data,
        eventSlug: state.event.eventSlug,
        loggedIn: state.user.loggedIn,
        eventArchive: state.eventArchive.data,
        resourcesAccess: state.user.resourcesAccess,
        languages: state.languages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogoutUser: () => dispatch(actions.logoutUser()),
        onSetBuildingId: (buildingId) => dispatch(actions.setBuildingId(buildingId)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Rooms));
