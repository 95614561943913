import {ControlButtons} from './ControlButtons';
import {connect} from 'react-redux';
import {getVideoCapture} from 'store/selectors';
import {joinSessionDashboard, clearCallState, toggleSelfVideo, toggleSelfAudio} from 'store/actions';

export default connect(
    (state) => ({
        capture: getVideoCapture(state),
    }),
    {
        joinSessionDashboard,
        clearCallState,
        toggleSelfVideo,
        toggleSelfAudio,
    }
)(ControlButtons);
