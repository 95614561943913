export const getFieldTranslatedLabel = (field, lang) => {
    if (!field) return '';
    if (!field.translations) return field.label;
    const translatedLabel =
        field.translations.find(({language}) => language === lang) ||
        field.translations.find((translation) => translation.default);

    return translatedLabel && lang ? translatedLabel.text : field.label;
};

export const getFieldTranslatedOptions = (options, lang) => {
    if (!options?.length) return [];

    options.forEach((option) => {
        if (!option.translations) return;

        option.value =
            option.translations.find(({language}) => language === lang)?.text ||
            option.translations.find((translation) => translation.default)?.text ||
            option.value;
    });

    return options;
};

export const getEmailParamFromUrl = () => {
    const url = new URL(document.location.href);
    const emailParam = url.searchParams.get('email');
    if (emailParam) return emailParam;
    return '';
};
