import {TextField} from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import {ReactComponent as Trash} from '../../../../../../../../../Images/trash_icon.svg';
import {Save as SaveIcon} from '@material-ui/icons';

const BusinessCardNotes = ({notes, noteValue, handleSaveNote, handleChangeNote, handleDeleteNote, businessCard}) => {
    return (
        <div className="notes">
            <h2 className="notes-title wallet-inner-padding">{businessCard.myNotes}</h2>

            <div className="note -editable">
                <div className="note-header wallet-inner-padding">
                    <div className="note-header-title"></div>
                    <button type="button" className="wallet-button -colored" onClick={() => handleSaveNote()}>
                        <SaveIcon className="wallet-button-icon" />
                        <span>{businessCard.save}</span>
                    </button>
                </div>
                <TextField
                    className="note-field"
                    label={businessCard.addNewNote}
                    id="filled-basic"
                    variant="outlined"
                    fullWidth={true}
                    multiline={true}
                    placeholder=""
                    value={noteValue}
                    onChange={(e) => {
                        handleChangeNote(e.target.value);
                    }}
                />
            </div>
            {!!notes &&
                notes.map((note) => {
                    return (
                        <div className="note" key={note._id}>
                            <div className="note-header wallet-inner-padding">
                                <div className="note-header-title">{moment(note.createdAt).format('lll')}</div>
                                <button
                                    type="button"
                                    className="wallet-button"
                                    onClick={() => {
                                        handleDeleteNote(note._id);
                                    }}
                                >
                                    <Trash className="wallet-button-icon" />
                                    <span>{businessCard.delete}</span>
                                </button>
                            </div>
                            <TextField
                                className="note-field"
                                id="filled-basic"
                                disabled={true}
                                variant="filled"
                                fullWidth={true}
                                multiline={true}
                                value={note.text}
                            />
                        </div>
                    );
                })}
        </div>
    );
};

export default BusinessCardNotes;
