import classNames from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';
import {normalizeTimeZone} from 'services/utils';
import {getEventInfo, getIsRtlLanguage} from 'store/selectors';

import './AuditoriumSingleDayProgram.scss';

const AuditoriumSingleDayProgram = ({auditorium, displayedDate}) => {
    const eventInfo = useSelector(getEventInfo);
    const isRtlLanguage = useSelector(getIsRtlLanguage);

    const currentProgram = auditorium.displayProgram.find((program) => program.date === displayedDate);

    const eventTimeZone = normalizeTimeZone(eventInfo?.utcTimezone);

    return (
        <div className="single-auditorium-program">
            <div className="auditorium-name">
                <h4 className="title">{auditorium.name}</h4>
                <span className="timezone">{eventTimeZone}</span>
            </div>
            <div className="auditorium-program-wrapper">
                <ul className="program-list-container">
                    {currentProgram.program.map((programEntry) => {
                        return (
                            <li key={programEntry._id} className="program-entry">
                                <p className={classNames('program-time', {isRtl: isRtlLanguage})}>
                                    {programEntry.start} - {programEntry.end}
                                </p>
                                <h4>{programEntry.title}</h4>
                                <p>{programEntry.description}</p>
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default AuditoriumSingleDayProgram;
