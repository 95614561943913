import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles(() => ({
    spinner: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#00000075',
    },
}));
