import React, {useState, useEffect, useCallback, useContext} from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import classNames from 'classnames';
import PermissionUser from './components/PermissionUser';

import {PARTICIPANT_TYPES} from 'constants/organizer/sessions';

import {AbilityContext, Can} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

import './PermissionsModule.scss';

const TABS_OPTIONS = {
    speakers: 'speakers',
    moderators: 'moderators',
    interpreters: 'interpreters'
};

export const PermissionsModule = ({joinedUsers, currentUser, userRoles, isKicked}) => {
    const ability = useContext(AbilityContext);

    const [activeTab, setActiveTab] = useState(TABS_OPTIONS.speakers);
    const [users, setUsers] = useState(null);

    const toggleModeratorPermission = ability.can(
        ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY,
        ROLE_FEATURES_TYPES.ETX_SESSION_TOGGLE_MODERATOR_MEDIA
    );
    const toggleSpeakerPermission = ability.can(
        ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY,
        ROLE_FEATURES_TYPES.ETX_SESSION_TOGGLE_SPEAKER_MEDIA
    );

    const renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `rgb(137,137,137)`,
            left: 0
        };
        return <div className="grey-scroll" style={{...style, ...thumbStyle}} {...props} />;
    };

    const usersByRole = useCallback(() => {
        const users = joinedUsers.reduce(
            (output, user) => {
                output[user.role] && output[user.role].push(user);

                return output;
            },
            {
                [PARTICIPANT_TYPES.speaker]: [],
                [PARTICIPANT_TYPES.moderator]: [],
                [PARTICIPANT_TYPES.interpreter]: []
            }
        );

        setUsers(users);
    }, [joinedUsers]);

    useEffect(() => {
        usersByRole();
    }, [usersByRole]);

    useEffect(() => {
        if (activeTab === TABS_OPTIONS.interpreters && !users[PARTICIPANT_TYPES.interpreter].length) {
            setActiveTab(TABS_OPTIONS.speakers);
        }
    }, [activeTab, users]);

    if (!users) return null;

    const renderParticipants = () => {
        switch (activeTab) {
            case TABS_OPTIONS.speakers:
                return [
                    PARTICIPANT_TYPES.speaker,
                    users[PARTICIPANT_TYPES.speaker]?.map((user) => {
                        return (
                            <PermissionUser
                                key={user.userId}
                                currentUser={currentUser}
                                user={user}
                                isTogglePermissionAvailable={toggleSpeakerPermission}
                            />
                        );
                    })
                ];
            case TABS_OPTIONS.moderators:
                return [
                    PARTICIPANT_TYPES.moderator,
                    users[PARTICIPANT_TYPES.moderator]?.map((user) => {
                        return (
                            <PermissionUser
                                key={user.userId}
                                currentUser={currentUser}
                                user={user}
                                isTogglePermissionAvailable={toggleModeratorPermission}
                            />
                        );
                    })
                ];
            case TABS_OPTIONS.interpreters:
                return [
                    PARTICIPANT_TYPES.interpreter,
                    users[PARTICIPANT_TYPES.interpreter]?.map((user) => {
                        return <PermissionUser key={user.userId} currentUser={currentUser} user={user} />;
                    })
                ];
            default:
                return [
                    PARTICIPANT_TYPES.speaker,
                    users[PARTICIPANT_TYPES.speaker]?.map((user) => {
                        return (
                            <PermissionUser
                                key={user.userId}
                                currentUser={currentUser}
                                user={user}
                                isTogglePermissionAvailable={toggleSpeakerPermission}
                            />
                        );
                    })
                ];
        }
    };

    const [roleName, roleList] = renderParticipants();

    if (isKicked) return null;

    const moduleHeader =
        activeTab === TABS_OPTIONS.interpreters ? (
            <div className="d-flex">
                <Can
                    do={ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY}
                    on={ROLE_FEATURES_TYPES.ETX_SESSION_KICK_USER}
                    ability={ability}
                >
                    <div className="module-action_column">kick</div>
                </Can>
                <div className="module-action_column">interpretation language</div>
            </div>
        ) : (
            <div className="d-flex">
                <Can
                    do={ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY}
                    on={ROLE_FEATURES_TYPES.ETX_SESSION_TOGGLE_SPEAKER_MEDIA}
                    ability={ability}
                >
                    <div className="module-action_column">kick</div>
                    <div className="module-action_column">screen sharing</div>
                    <div className="module-action_column">audio</div>
                    <div className="module-action_column">video</div>
                </Can>
            </div>
        );

    return (
        <div
            className={classNames('permissions-module', {
                withTopMargin: !userRoles.includes(PARTICIPANT_TYPES.organizer)
            })}
        >
            <div className="module-container">
                <div className="module-header">
                    <p
                        className={classNames('tab', {
                            '-active': activeTab === TABS_OPTIONS.speakers
                        })}
                        onClick={() => {
                            setActiveTab(TABS_OPTIONS.speakers);
                        }}
                    >
                        SPEAKERS
                    </p>
                    <p
                        className={classNames('tab', {
                            '-active': activeTab === TABS_OPTIONS.moderators
                        })}
                        onClick={() => {
                            setActiveTab(TABS_OPTIONS.moderators);
                        }}
                    >
                        MODERATORS
                    </p>
                    {!!users[PARTICIPANT_TYPES.interpreter]?.length && (
                        <p
                            className={classNames('tab', {
                                '-active': activeTab === TABS_OPTIONS.interpreters
                            })}
                            onClick={() => {
                                setActiveTab(TABS_OPTIONS.interpreters);
                            }}
                        >
                            interpreters
                        </p>
                    )}
                </div>
                <div className="module-body">
                    <div className="module-row -head">
                        <div>{roleName} NAME</div>
                        {moduleHeader}
                    </div>

                    <Scrollbars className="scrollbar" renderThumbVertical={renderThumb} width="100%" height="100%">
                        {!!joinedUsers.length && roleList}
                    </Scrollbars>
                </div>
            </div>
        </div>
    );
};

PermissionsModule.propTypes = {
    joinedUsers: PropTypes.array,
    currentUser: PropTypes.object,
    userRoles: PropTypes.array,
    isKicked: PropTypes.bool
};
