import React from 'react';
import {ExploreMoreEvents, UpcomingEvents, PastEvents, Spinner} from './components';
import classNames from 'classnames';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import {useStyles} from './MyEvents.style';
import {useSelector} from 'react-redux';
import {usePlatformTranslation} from '../../../../../../../services/hooks';

export default function MyEvents() {
    const style = useStyles();
    const [tabIndex, setTabIndex] = React.useState(0);
    const {eventsListing} = usePlatformTranslation();

    const tabProps = (index) => ({
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
        classes: {
            wrapper: style.text,
            selected: style.selected,
        },
    });

    const upcomingEventsProps = {
        exploreMore: () => setTabIndex(2),
    };
    const pastEventsProps = {
        exploreMore: () => setTabIndex(2),
    };
    const exploreMoreEventsProps = {
        exploreMore: () => setTabIndex(2),
    };

    return (
        <Box className={style.container}>
            <Spinner />
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={tabIndex}
                onChange={(e, index) => setTabIndex(index)}
                className={style.tabsContainer}
                classes={{
                    indicator: style.indicator,
                }}
            >
                <Tab label={eventsListing.upcomingEvents.toUpperCase()} {...tabProps(0)} />
                <Tab label={eventsListing.pastEvents.toUpperCase()} {...tabProps(1)} />
                <Tab label={eventsListing.exploreMoreEvents.toUpperCase()} {...tabProps(2)} />
            </Tabs>
            <TabPanel value={tabIndex} index={0}>
                <UpcomingEvents {...upcomingEventsProps} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <PastEvents {...pastEventsProps} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <ExploreMoreEvents {...exploreMoreEventsProps} />
            </TabPanel>
        </Box>
    );
}

function TabPanel({children, value, index, ...rest}) {
    const style = useStyles();
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className={classNames(style.tabContent, 'organizer-dashboard-container')}
            {...rest}
        >
            <div className={classNames(style.contentWrapper, {[style.rtl]: isRtlLanguage})}>
                {value === index && children}
            </div>
        </div>
    );
}
