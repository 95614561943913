import {createSelector} from 'reselect';

const event = (state) => state.event;

const eventArchive = (state) => state.eventArchive;

export const getEvent = createSelector(event, (state) => state);

export const getEventData = createSelector(event, (state) => state.data);

export const getEventArchiveData = createSelector(eventArchive, (state) => state.data);

export const getEventId = createSelector(event, (state) => state.eventId);

export const getEventLoading = createSelector(event, (state) => state.loading);

export const getEventListing = createSelector(event, (event) => event.data.listing);

export const getEventSlug = createSelector(event, (state) => state.eventSlug);

export const getEventInfo = createSelector(event, (state) => state.eventInfo);

export const getEventUserRegistration = createSelector(event, (state) => state.userRegistration);

export const getEventShortLink = createSelector(event, (state) => state.eventShortLink);

export const getEventRegPackage = createSelector(event, (state) => state.eventRegPackage);

export const getUserInitialEventId = createSelector(event, (state) => state.userInitialEventId);

export const getIsEventNotFound = createSelector(event, (state) => state.eventNotFound);

export const getEventLanguages = createSelector(event, (state) => state.eventLanguages);

export const getEventDefaultLanguage = createSelector(event, (state) => state.data.language);

export const getOnlineUsers = createSelector(event, (state) => state.onlineUsers);

export const getSideNavigationDetails = createSelector(event, (state) => state.sideNavigationDetails);

export const getActiveWallId = createSelector(event, (state) => state.sideNavigationDetails.activeWallId);

export const getEventParticipantFilters = createSelector(event, (state) => state.eventParticipantFilters);

export const getEventParticipantFiltersEventId = createSelector(event, (state) => state.eventParticipantFiltersEventId);

export const getEventExhibitorFilters = createSelector(event, (state) => state.eventExhibitorFilters);

export const getEventExhibitorFiltersEventId = createSelector(event, (state) => state.eventExhibitorFiltersEventId);

export const getUsersList = createSelector(event, (state) => state.usersList);

export const getActiveCampaignIntegration = createSelector(event, (state) => state.data.activeCampaignIntegration);

export const getOnDemandVideos = createSelector(event, (state) => state.onDemandVideos);

export const getEventTimeZone = createSelector(event, (state) => state.data?.utcTimezone);

export const getArchivedEvents = createSelector(eventArchive, (state) => state.data);

export const getEventArchiveLoading = createSelector(eventArchive, (state) => state.loading);

export const getEventProfile = createSelector(event, (state) => state.profile);

export const getEventOwner = createSelector(event, (state) => state.data?.owner);
