import React from 'react';
import {CircularProgress} from '@material-ui/core';
import {ALLOCATE_RESOURCES_PROCESSING_MESSAGE} from 'constants/organizer/sessions';
import {useStyles} from './ResourcesAllocationWaitingView.styles';

export function ResourcesAllocationWaitingView() {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <CircularProgress size={25} className={classes.spinner} />
            <p className={classes.text}>{ALLOCATE_RESOURCES_PROCESSING_MESSAGE}</p>
        </div>
    );
}
