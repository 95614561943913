import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './AuditoriumArchiveWrapper.scss';

export function AuditoriumArchiveWrapper({children, className}) {
    return <div className={classNames('auditorium-archive-page', {[className]: className})}>{children}</div>;
}

AuditoriumArchiveWrapper.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
