import React, {useEffect, useCallback, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {connect} from 'react-redux';
import {isIE, isYandex} from 'react-device-detect';
import {NotificationContainer} from 'react-notifications';
import throttle from 'lodash/throttle';
import 'react-notifications/lib/notifications.css';
import {withStyles} from '@material-ui/core';
import {isMacOs, isIOS} from 'react-device-detect';

import {YMInitializer} from 'react-yandex-metrika';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import TwitterConvTrkr from 'react-twitter-conversion-tracker';

import Routes from './Routes/Routes';
import AgendaCustomElementDialog from './Routes/components/AgendaCustomElementDialog/AgendaCustomElementDialog';
import PlatformNotAvailable from './Routes/components/PlatformNotAvailable';
import MeetingSetupDialog from './Routes/components/MeetingSetupDialog/MeetingSetupDialog';
import RingProvider from './Routes/components/RingProvider/RingProvider';
import RingContainer from './Routes/components/RingContainer';
import Spinner from './Routes/components/Spinner';

import {usePersonalAgenda, useUserRole, useBusinessCard, useUserStatuses, useCookiesAccept} from './services/hooks';
import {setLanguageDirection} from './services/helpers';

import * as actions from './store/actions';
import {IS_EGYPT_PAVILION_ENV, IS_SCA_ENV} from './constants/shared';
import {
    env,
    envList,
    yandexAnalyticsId,
    googleAnalyticsId,
    googleTagManagerId,
    googleAnalyticsMeasureId,
    facebookPixelId,
    twitterPixelId,
} from './config';

import './App.scss';

const cssVariables = (theme) => ({
    '@global': {
        ':root': {
            '--theme-primary-color': theme.palette.primary.main,
            '--theme-secondary-color': theme.palette.secondary.main,
            '--theme-success-color': theme.palette.success.main,
            '--theme-error-color': theme.palette.error.main,
            '--theme-warning-color': theme.palette.warning.main,
            '--theme-info-color': theme.palette.info.main,
            '--theme-disabled-color': theme.palette.disabled.main,
            '--theme-background-paper-color': theme.palette.background.paper,
            '--theme-background-default-color': theme.palette.background.default,
            '--theme-hover-color': theme.palette.action.hover,
            '--theme-active-color': theme.palette.action.active,
            '--theme-text-primary-color': theme.palette.text.primary,
            '--theme-text-dark-grey-color': theme.palette.text.darkGrey,
            '--theme-text-light-grey-color': theme.palette.text.lightGrey,
            '--theme-text-grey-secondary-color': theme.palette.text.secondary,
            '--theme-white-color': theme.palette.grey['0'],
            '--theme-light-grey-color': theme.palette.grey.A100,
            '--theme-text-grey-color': theme.palette.grey.A200,
            '--theme-first-grey-color': theme.palette.grey.A300,
            '--theme-second-grey-color': theme.palette.grey.A400,

            '--theme-text-secondary-color': '#808080',
            '--theme-text-color': '#1d1d1b',
            '--theme-online-avatar-mark-color': '#5ab453',
            '--theme-text-color-black': '#000',
            '--user-online-mark-color': '#5ab453',
            '--user-offline-mark-color': '#898989',
            '--user-busy-mark-color': '#e40e20',
            '--theme-border-grey-color': theme.palette.grey.A150,
        },
    },
});

function App({loaderState, onSetIsMobile, onSetIsTablet, onSetIsLargeScreen}) {
    const handleResize = throttle(() => {
        _checkIfIsMobile();
    }, 500);

    const [analyticsIntited, setAnalyticsInited] = useState(false);
    const {pathname} = useLocation();
    const cookiesAccepted = useCookiesAccept();

    const _checkIfIsMobile = useCallback(() => {
        if (window.innerWidth <= 1024) {
            let vhc = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vhc}px`);
            if (window.innerWidth <= 767) {
                onSetIsMobile();
            } else {
                onSetIsTablet();
            }
        } else {
            onSetIsLargeScreen();
        }
    }, [onSetIsLargeScreen, onSetIsMobile, onSetIsTablet]);

    useEffect(() => {
        setLanguageDirection(IS_SCA_ENV ? 'ar' : 'en');
        if (isMacOs || isIOS) document.body.classList.add('isIOS');
    }, []);

    useEffect(() => {
        _checkIfIsMobile();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [_checkIfIsMobile, handleResize]);

    useEffect(() => {
        if (env === envList.production && cookiesAccepted && !analyticsIntited) {
            const isEnabledTagManager = IS_SCA_ENV || IS_EGYPT_PAVILION_ENV;

            ReactGA.initialize(IS_SCA_ENV ? googleAnalyticsMeasureId : googleAnalyticsId);

            if (isEnabledTagManager) {
                TagManager.initialize({
                    gtmId: IS_SCA_ENV ? googleAnalyticsMeasureId : googleTagManagerId,
                });
            }

            if (IS_EGYPT_PAVILION_ENV) {
                ReactPixel.init(facebookPixelId);
                TwitterConvTrkr.init(twitterPixelId);
            }
            setAnalyticsInited(true);
        }
    }, [cookiesAccepted, analyticsIntited]);

    useUserRole();
    usePersonalAgenda();
    useBusinessCard();
    useUserStatuses();

    // set item in local storage to know where to redirect the user after successfull login as participant
    // we do this only if he is on a event page (lobby, auditorium, exhibitor etc.)
    // test message

    if (localStorage.getItem('token') === null) {
        localStorage.setItem('redirect', 'true');
    } else {
        localStorage.setItem('redirect', 'false');
    }
    if (pathname.includes('event/')) {
        localStorage.setItem('landingPage', pathname);
    } else {
        localStorage.setItem('landingPage', 'lobby');
    }
    if (isIE || isYandex) {
        return (
            <PlatformNotAvailable
                message={
                    'Event10X is not currently optimized for the browser you are using. Please access the platform using Google Chrome, Firefox, Safari or Microsoft Edge.'
                }
            />
        );
    }

    return (
        <RingProvider>
            <div className="App" id="app-container">
                {!!loaderState && <Spinner className="global-spinner" />}
                <NotificationContainer style={{zIndex: 9999}} />
                <Routes />
                <AgendaCustomElementDialog />
                <MeetingSetupDialog />
                <RingContainer />
                {env === envList.production && cookiesAccepted && IS_EGYPT_PAVILION_ENV && (
                    <YMInitializer
                        accounts={[yandexAnalyticsId]}
                        options={{
                            clickmap: true,
                            trackLinks: true,
                            accurateTrackBounce: true,
                            webvisor: true,
                        }}
                    />
                )}
            </div>
        </RingProvider>
    );
}

export default connect(
    (state) => ({
        loaderState: state.loader,
    }),
    {
        onSetIsMobile: actions.setIsMobile,
        onSetIsTablet: actions.setIsTablet,
        onSetIsLargeScreen: actions.setIsLargeScreen,
    }
)(withStyles(cssVariables)(App));
