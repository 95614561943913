import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router';

import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import {FullOverlay} from './FullOverlay';

export default withRouter(
    connect(
        (state) => ({
            user: selectors.getUserData(state),
            eventSlug: selectors.getEventSlug(state),
            eventId: selectors.getEventId(state),
            loggedIn: selectors.getIsUserLoggedIn(state),
        }),
        {
            setEventId: actions.setEventId,
            setEventSlug: actions.setEventSlug,
        }
    )(FullOverlay)
);
