//question
export const INIT_MATCHMAKING_QUESTION = 'INIT_MATCHMAKING_QUESTION';

export const CREATE_MATCHING_QUESTION_START = 'CREATE_MATCHING_QUESTION_START';
export const CREATE_MATCHING_QUESTION_SUCCESS = 'CREATE_MATCHING_QUESTION_SUCCESS';
export const CREATE_MATCHING_QUESTION_FAIL = 'CREATE_MATCHING_QUESTION_FAIL';

export const UPDATE_MATCHING_QUESTION_START = 'UPDATE_MATCHING_QUESTION_START';
export const UPDATE_MATCHING_QUESTION_SUCCESS = 'UPDATE_MATCHING_QUESTION_SUCCESS';
export const UPDATE_MATCHING_QUESTION_FAIL = 'UPDATE_MATCHING_QUESTION_FAIL';

export const DELETE_MATCHING_QUESTION_START = 'DELETE_MATCHING_QUESTION_START';
export const DELETE_MATCHING_QUESTION_SUCCESS = 'DELETE_MATCHING_QUESTION_SUCCESS';
export const DELETE_MATCHING_QUESTION_FAIL = 'DELETE_MATCHING_QUESTION_FAIL';

export const CHANGE_MATCHMAKINK_QUESTION = 'CHANGE_MATCHMAKINK_QUESTION';

export const REORDER_MATCHING_QUESTION_REQUEST_START = 'MATCHMAKING/REORDER_MATCHING_QUESTION_REQUEST_START';
export const REORDER_MATCHING_QUESTION_REQUEST_SUCCESS = 'MATCHMAKING/REORDER_MATCHING_QUESTION_REQUEST_SUCCESS';
export const REORDER_MATCHING_QUESTION_REQUEST_FAIL = 'MATCHMAKING/REORDER_MATCHING_QUESTION_REQUEST_FAIL';

export const REORDER_MATHCING_QUESTIONS = 'MATCHMAKING/REORDER_MATHCING_QUESTIONS';
// form
export const INIT_MATCHMAKING_FORM = 'INIT_MATCHMAKING_FORM';
export const RESET_MATCHMAKING_FORM = 'RESET_MATCHMAKING_FORM';

export const CREATE_MATCHMAKING_FORM_START = 'CREATE_MATCHMAKING_FORM_START';
export const CREATE_MATCHMAKING_FORM_SUCCESS = 'CREATE_MATCHMAKING_FORM_SUCCESS';
export const CREATE_MATCHMAKING_FORM_FAIL = 'CREATE_MATCHMAKING_FORM_FAIL';

export const UPDATE_MATCHMAKING_FORM_START = 'UPDATE_MATCHMAKING_FORM_START';
export const UPDATE_MATCHMAKING_FORM_SUCCESS = 'UPDATE_MATCHMAKING_FORM_SUCCESS';
export const UPDATE_MATCHMAKING_FORM_FAIL = 'UPDATE_MATCHMAKING_FORM_FAIL';

export const GET_MATCHMAKING_FORM_START = 'GET_MATCHMAKING_FORM_START';
export const GET_MATCHMAKING_FORM_SUCCESS = 'GET_MATCHMAKING_FORM_SUCCESS';
export const GET_MATCHMAKING_FORM_FAIL = 'GET_MATCHMAKING_FORM_FAIL';

//options
export const ADD_MATCHMAKINK_QUESTION_OPTION = 'ADD_MATCHMAKINK_QUESTION_OPTION';
export const REMOVE_MATCHMAKINK_QUESTION_OPTION = 'REMOVE_MATCHMAKINK_QUESTION_OPTION';
export const CHANGE_MATCHMAKINK_QUESTION_OPTION = 'CHANGE_MATCHMAKINK_QUESTION_OPTION';

export const CHANGE_MATCHING_WITH = 'CHANGE_MATCHING_WITH';
export const REMOVE_MATCHING_WITH = 'REMOVE_MATCHING_WITH';

export const REORDER_MATCHING_QUESTION_OPTIONS = 'MATCHMAKING/REORDER_MATCHING_QUESTION_OPTIONS';

//matched

export const SET_MATCHING_MATCHES = 'SET_MATCHING_MATCHES';
