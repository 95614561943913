export const tabsNames = {
    homepage: 'homepage',
    loginPage: 'loginPage',
    welcomeScreens: 'welcomeScreens',
    lobbyPage: 'lobbyPage',
    renders: 'renders',
    description: 'description',
    eventListing: 'eventListing',
    partners: 'partners',
    exhibitors: 'exhibitors',
    banners: 'banners',
    previousEvents: 'previousEvents',
    socials: 'socials',
    survey: 'survey',
};

export const navTabList = [
    {name: tabsNames.homepage, label: 'HOMEPAGE'},
    {name: tabsNames.loginPage, label: 'LOGIN'},
    {name: tabsNames.welcomeScreens, label: 'WELCOME SCREENS'},
    {name: tabsNames.lobbyPage, label: 'MAIN LOBBY'},
    {name: tabsNames.renders, label: 'RENDERS'},
    {name: tabsNames.survey, label: 'SURVEY'},
];

export const navSubTabsList = [
    {name: tabsNames.description, label: 'Description'},
    {name: tabsNames.eventListing, label: 'Event Listing'},
    {name: tabsNames.partners, label: 'Partners'},
    {name: tabsNames.exhibitors, label: 'Exhibitors'},
    {name: tabsNames.banners, label: 'Banners'},
    {name: tabsNames.previousEvents, label: 'Previous Events'},
    {name: tabsNames.socials, label: 'Social Media'},
];

export const surveyQuestionFieldTypes = {
    text: 'text',
    select: 'select',
    radio: 'radio',
    linearScale: 'linear-scale',
};

export const surveyQuestionTypeOptions = [
    {value: surveyQuestionFieldTypes.text, label: 'Text input'},
    {value: surveyQuestionFieldTypes.select, label: 'Dropdown'},
    {value: surveyQuestionFieldTypes.radio, label: 'Radio buttons'},
    {value: surveyQuestionFieldTypes.linearScale, label: 'Linear scale'},
];

export const surveyQuestionActionTypes = {
    save: 'save',
    update: 'update',
    hidden: 'hidden',
    delete: 'delete',
    question: 'question',
    questionType: 'questionType',
    validation: 'validation',
};
