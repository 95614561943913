import React from 'react';
import {Grid} from '@material-ui/core';

import LinkedinLoginIcon from '../../../Images/linkedin-login.svg';
import FacebookLoginIcon from '../../../Images/facebook-login.svg';
import GoogleLoginIcon from '../../../Images/google-login.svg';
import TwitterLoginIcon from '../../../Images/twitter-login.svg';
import {getRegisterLink} from '../../../routes';
import {IS_SCA_ENV} from '../../../constants/shared';
import {apiUrl} from 'config';

export class SocialNetworkButtons extends React.Component {
    state = {
        translation: null,
    };

    componentDidMount() {
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.eventLanguage !== this.props.languages.eventLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.eventLanguage];
        this.setState({
            translation: translation,
        });
    };

    render() {
        const {eventId, eventRegPackage, location, userRegistration, eventShortLink} = this.props;
        const {translation} = this.state;

        const isRegisterButton = location.pathname === getRegisterLink();
        const authorizePrefix = isRegisterButton ? translation?.register.registerButton : translation?.menu.logIn;

        const socialLinkQuery = `${eventShortLink ? `&shortLink=${eventShortLink}` : ''}${
            eventRegPackage ? `&packageId=${eventRegPackage}` : ''
        }`;

        if (IS_SCA_ENV) {
            return null;
        }

        return (
            <Grid container>
                <Grid item xs={12}>
                    <a
                        href={`${apiUrl}/auth/linkedin?eventId=${eventId}&userType=${userRegistration?.type}${socialLinkQuery}`}
                    >
                        <button className="social-btn linkedin-button">
                            <img src={LinkedinLoginIcon} width="13" height="26" alt="Facebook logo" />
                            <span>
                                <span className="only-login">{authorizePrefix} </span>
                                {translation?.programDropdown.with} Linkedin
                            </span>
                        </button>
                    </a>
                    <a
                        href={`${apiUrl}/auth/facebook?eventId=${eventId}&userType=${userRegistration?.type}${socialLinkQuery}`}
                    >
                        <button className="social-btn facebook-button">
                            <img src={FacebookLoginIcon} width="13" height="26" alt="Facebook logo" />
                            <span>
                                <span className="only-login">{authorizePrefix} </span>
                                {translation?.programDropdown.with} Facebook
                            </span>
                        </button>
                    </a>

                    <a
                        href={`${apiUrl}/auth/google?eventId=${eventId}&userType=${userRegistration?.type}${socialLinkQuery}`}
                    >
                        <button className="social-btn google-button">
                            <img src={GoogleLoginIcon} width="26" height="26" alt="Google logo" />
                            <span>
                                <span className="only-login">{authorizePrefix} </span>
                                {translation?.programDropdown.with} Google
                            </span>
                        </button>
                    </a>
                    <a
                        href={`${apiUrl}/auth/twitter?eventId=${eventId}&userType=${userRegistration?.type}${socialLinkQuery}`}
                    >
                        <button className="social-btn twitter-button">
                            <img src={TwitterLoginIcon} width="26" height="26" alt="Google logo" />
                            <span>
                                <span className="only-login">{authorizePrefix} </span>
                                {translation?.programDropdown.with} Twitter
                            </span>
                        </button>
                    </a>
                </Grid>
                <Grid item xs={12}>
                    <p className="or-text">or</p>
                </Grid>
            </Grid>
        );
    }
}
