import React from 'react';
import {MenuItem} from '@material-ui/core';
import {SelectValidator} from 'react-material-ui-form-validator';

import {countries} from '../../../constants/shared';

class SelectCountry extends React.Component {
    handleChange = (e) => {
        this.props.handleChange(e.target.name, e.target.value);
    };

    render() {
        const {field, translatedLabel} = this.props;
        return (
            <SelectValidator
                onChange={this.handleChange}
                name={field.name}
                variant="outlined"
                value={field.value}
                validators={field.validators}
                errorMessages={field.errorMessages}
                label={translatedLabel || field.label}
                className="field-container"
                fullWidth={true}
            >
                {countries.map((country) => {
                    return (
                        <MenuItem key={country.code} value={country.name}>
                            {country.name}
                        </MenuItem>
                    );
                })}
            </SelectValidator>
        );
    }
}

export default SelectCountry;
