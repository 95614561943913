import React from 'react';
import {useParams} from 'react-router-dom';
import {EventAvailable as EventAvailableIcon, PlayArrow as PlayArrowIcon} from '@material-ui/icons';
import StorageIcon from '@material-ui/icons/Storage';
import InfoTooltip from '../../../InfoTooltip/InfoTooltip';
import ResourcesAllocationWaitingView from '../../../ResourcesAllocationWaitingView';
import {
    ZOOM_SESSION_ACTION_TYPES,
    ALLOCATE_RESOURCES_SUCCESS_MESSAGE,
    ALLOCATE_RESOURCES_STATUSES,
    ALLOCATE_RESOURCES_FAILED_MESSAGE,
} from 'constants/organizer/sessions';

export const ZoomStreamControls = ({
    session,
    sessionStreamStart,
    sessionStreamStop,
    isLoading,
    openAllocateResourcesDialog,
}) => {
    const {auditoriumId} = useParams();

    const handleStreamBroadcasting = (action) => () =>
        action === ZOOM_SESSION_ACTION_TYPES.startStream
            ? sessionStreamStart(auditoriumId)
            : sessionStreamStop(auditoriumId);

    const resourcesAllocated = session?.resourcesStatus === ALLOCATE_RESOURCES_STATUSES.allocated;
    const resourcesFailed = session?.resourcesStatus === ALLOCATE_RESOURCES_STATUSES.failed;
    const isShownTooltip = resourcesAllocated || resourcesFailed;

    const allocationStatusUi = () => {
        switch (session?.resourcesStatus) {
            case ALLOCATE_RESOURCES_STATUSES.initial:
            case ALLOCATE_RESOURCES_STATUSES.failed:
                return (
                    <button type="button" className="button" onClick={openAllocateResourcesDialog}>
                        <StorageIcon className="button_icon" />
                        <span>ALLOCATE RESOURCES</span>
                    </button>
                );
            case ALLOCATE_RESOURCES_STATUSES.processing:
                return <ResourcesAllocationWaitingView />;
            case ALLOCATE_RESOURCES_STATUSES.holded:
            case ALLOCATE_RESOURCES_STATUSES.holding:
            case ALLOCATE_RESOURCES_STATUSES.allocated:
                return null;
            default:
                return null;
        }
    };

    const isResourcesHolded =
        session?.resourcesStatus === ALLOCATE_RESOURCES_STATUSES.holded ||
        session?.resourcesStatus === ALLOCATE_RESOURCES_STATUSES.holding;
    const isStreamNotPublished = !session?.streamPublished && !session?.streamStarted;

    return (
        <div className="buttons_group">
            {!isResourcesHolded && (
                <button
                    disabled={isLoading || !resourcesAllocated || isStreamNotPublished}
                    type="button"
                    className="button"
                    onClick={handleStreamBroadcasting(
                        session?.streamStarted
                            ? ZOOM_SESSION_ACTION_TYPES.stopStream
                            : ZOOM_SESSION_ACTION_TYPES.startStream
                    )}
                >
                    {session?.streamStarted ? (
                        <EventAvailableIcon className="button_icon" />
                    ) : (
                        <PlayArrowIcon className="button_icon" />
                    )}
                    <span>{`${session?.streamStarted ? 'STOP' : 'START'} STREAM`}</span>
                </button>
            )}
            {allocationStatusUi()}
            {isShownTooltip && (
                <InfoTooltip
                    placement="top"
                    text={resourcesAllocated ? ALLOCATE_RESOURCES_SUCCESS_MESSAGE : ALLOCATE_RESOURCES_FAILED_MESSAGE}
                />
            )}
        </div>
    );
};
