import React, {useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {avatarFolder} from 'config';

import {useStyles} from './VideoAvatar.styles';

export const VideoAvatar = ({member, isMinified, isSelfAvatar}) => {
    const classes = useStyles();
    const [imgLoaded, setImgLoaded] = useState(false);

    const getAvatarSrc = () => {
        if (isSelfAvatar && member.avatar) {
            return `${avatarFolder}${member.avatar}`;
        }

        return member.avatarUrl;
    };

    return (
        <div className={classNames(classes.avatar, {[classes.minified]: isMinified})}>
            {!!member && (
                <>
                    {!imgLoaded &&
                        `${member.first && !!member.first.length ? member.first[0] : ''}${
                            member.last && !!member.last.length ? member.last[0] : ''
                        }`.toUpperCase()}

                    <img
                        alt=""
                        className={classNames(classes.image, {[classes.imageSmall]: isMinified})}
                        onLoad={() => setImgLoaded(true)}
                        src={getAvatarSrc()}
                    />
                </>
            )}
        </div>
    );
};

VideoAvatar.propTypes = {
    member: PropTypes.object,
    isMinified: PropTypes.bool,
    isSelfAvatar: PropTypes.bool,
};
