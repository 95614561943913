import * as actionTypes from '../../actionTypes/eventListingTypes';
import {initialState} from './initialState';

const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.TURN_ON_SPINNER:
            return {
                ...state,
                loading: true,
            };
        case actionTypes.TOGGLE_EVENT_LISTING_SUCCESS:
            return {
                ...state,
                loading: false,
                toggleEventListing: payload,
            };
        case actionTypes.UPDATE_EVENT_LISTING_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                eventListingData: payload,
            };
        case actionTypes.SAVE_EVENT_TO_PERSONAL_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.DISCARD_EVENT_FROM_PERSONAL_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
            };
        case actionTypes.GET_ALL_LISTED_EVENTS_SUCCESS:
            return {
                ...state,
                loading: false,
                allListedEvents: payload.events,
                pagination: {
                    page: +payload.page,
                    pages: +payload.pages,
                },
            };
        case actionTypes.GET_ALL_LISTED_EVENTS_FAIL:
            return {
                ...state,
                loading: false,
                allListedEvents: null,
            };
        case actionTypes.GET_SAVED_EVENTS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                savedEventsList: payload,
            };
        case actionTypes.GET_SAVED_EVENTS_LIST_FAIL:
            return {
                ...state,
                loading: false,
                savedEventsList: null,
            };
        case actionTypes.GET_FIRST_FUTURE_EVENTS_FOR_HOME_PAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                firstFutureEventsForHomePage: payload,
            };
        case actionTypes.GET_EVENTS_LISTING_SUCCESS:
            return {
                ...state,
                loading: false,
                eventsDates: payload,
            };
        case actionTypes.GET_FIRST_FUTURE_EVENTS_FOR_HOME_PAGE_FAIL:
            return {
                ...state,
                loading: false,
                firstFutureEventsForHomePage: null,
            };
        case actionTypes.SET_EVENTS_LISTING_FILTERS:
            return {
                ...state,
                eventsFilters: payload,
            };
        case actionTypes.SET_EVENTS_LISTING_SEARCH:
            return {
                ...state,
                searchText: payload,
            };
        case actionTypes.TOGGLE_SHOW_PAST_EVENTS:
            return {
                ...state,
                showPastEvents: payload,
            };
        case actionTypes.PRE_SAVE_EVENTS:
            return {
                ...state,
                preSaveEvent: payload,
            };
        case actionTypes.RESET_EVENT_LISTING_PARAMS:
            return {
                ...state,
                pagination: initialState.pagination,
                searchText: initialState.searchText,
                showPastEvents: initialState.showPastEvents,
            };

        case actionTypes.TOGGLE_EVENT_LISTING_FAIL:
        case actionTypes.UPDATE_EVENT_LISTING_DATA_FAIL:
        case actionTypes.SAVE_EVENT_TO_PERSONAL_LIST_FAIL:
        case actionTypes.DISCARD_EVENT_FROM_PERSONAL_LIST_FAIL:
        case actionTypes.GET_EVENTS_LISTING_FAIL:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};

export default reducer;
