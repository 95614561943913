import React from 'react';

const SubtitlesIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 4L4 20C4 21.1 4.9 22 6 22L18 22C19.1 22 20 21.1 20 20L20 4C20 2.9 19.1 2 18 2L5.99999 2C4.89999 2 3.99999 2.9 4 4ZM18 4L18 20L6 20L6 4L18 4ZM10 18L10 16L12 16L12 18L10 18ZM14 18L14 10L16 10L16 18L14 18ZM14 8L14 6L16 6L16 8L14 8ZM10 14L10 6L12 6L12 14L10 14Z"
            fill="#9C231F"
        />
    </svg>
);

export default SubtitlesIcon;
