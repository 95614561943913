import React, {useState, useEffect} from 'react';
import {Dialog, DialogContent} from '@material-ui/core';
import {ArrowBackIos as ArrowBackIosIcon} from '@material-ui/icons';

import './ExhibitorBooth.scss';

const BoothProducts = ({opened, closeDialog, products, exhibitorId}) => {
    const [currentProduct, setcurrentProduct] = useState(0);

    const handleChangeProduct = (modificator) => {
        let res = currentProduct + modificator;
        if (res >= products.length) {
            res = 0;
        } else if (res < 0) {
            res = products.length - 1;
        }
        setcurrentProduct(res);
    };

    useEffect(() => {
        setcurrentProduct(0);
    }, [exhibitorId]);

    return (
        <Dialog
            open={opened}
            onClose={closeDialog}
            aria-labelledby="form-dialog-title"
            id="booth-files-dialog"
            className="booth-products-dialog-container booth-dialog"
        >
            <DialogContent className="booth-dialog-content">
                {!!products.length && (
                    <div className="content-container">
                        <div className="d-flex">
                            {products[currentProduct].image && (
                                <div className="booth-dialog_section -with-image">
                                    <img
                                        src={`${process.env.REACT_APP_EXHIBITORS_FOLDER}${exhibitorId}/${products[currentProduct].image}`}
                                        className="booth-dialog_image"
                                        alt={products[currentProduct].image}
                                    />
                                </div>
                            )}
                            <div className="booth-dialog_section -text-section">
                                <div className="">
                                    <p className="booth-dialog_title">{products[currentProduct].name}</p>
                                    <p className="booth-dialog_text">{products[currentProduct].description}</p>
                                </div>
                                <div className="booth-dialog_navigation-wrapper">
                                    <div className="booth-dialog_navigation">
                                        <div className="booth-dialog_pagination-wrapper">
                                            {products.length > 1 && (
                                                <>
                                                    <ArrowBackIosIcon
                                                        onClick={() => {
                                                            handleChangeProduct(-1);
                                                        }}
                                                        style={{fill: 'var(--theme-primary-color)', cursor: 'pointer'}}
                                                    />
                                                    <span className="booth-dialog_pagination">
                                                        {currentProduct + 1}/{products.length}
                                                    </span>
                                                    <ArrowBackIosIcon
                                                        onClick={() => {
                                                            handleChangeProduct(1);
                                                        }}
                                                        style={{
                                                            fill: 'var(--theme-primary-color)',
                                                            cursor: 'pointer',
                                                            transform: 'rotate(180deg)',
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </div>
                                        {products[currentProduct].link && (
                                            <a
                                                className="booth-dialog_button"
                                                target="blank"
                                                href={products[currentProduct].link}
                                            >
                                                see details
                                            </a>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default BoothProducts;
