import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilityStateFunction';

const initialState = {
    buildingId: null,
    data: null,
    loading: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_BUILDING_ID:
            return updateObject(state, {buildingId: action.payload});
        case actionTypes.GET_BUILDING_START:
            return updateObject(state, {loading: true});
        case actionTypes.GET_BUILDING_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.payload,
                buildingId: action.payload?._id,
            });
        case actionTypes.GET_BUILDING_FAIL:
            return updateObject(state, {loading: false});
        case actionTypes.CLEAR_BUILDING_DATA:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
