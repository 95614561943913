import React from 'react';
import {connect} from 'react-redux';

import V3BoothFront768 from '../../../Images/backgrounds/boothFront-v3_768.jpg';
import V3BoothFront900 from '../../../Images/backgrounds/boothFront-v3_900.jpg';
import V3BoothFront1080 from '../../../Images/backgrounds/boothFront-v3_1080.jpg';
import V3BoothFront1200 from '../../../Images/backgrounds/boothFront-v3_1200.jpg';
import V3BoothFront1440 from '../../../Images/backgrounds/boothFront-v3_1440.jpg';
import V3BoothFrontFull from '../../../Images/backgrounds/boothFront-v3.jpg';
import {ReactComponent as EmptyBoxIcon} from '../../../Images/empty-box-icon.svg';

import './ShowfloorNoExhibitor.scss';
class ShowfloorNoExhibitor extends React.Component {
    state = {
        translation: null,
    };

    componentDidMount() {
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    render() {
        const {isLargeScreen} = this.props;
        let V3boothFront;
        switch (true) {
            case window.innerHeight < 769:
                V3boothFront = V3BoothFront768;
                break;
            case window.innerHeight < 901:
                V3boothFront = V3BoothFront900;
                break;
            case window.innerHeight < 1081:
                V3boothFront = V3BoothFront1080;
                break;
            case window.innerHeight < 1201:
                V3boothFront = V3BoothFront1200;
                break;
            case window.innerHeight < 1441:
                V3boothFront = V3BoothFront1440;
                break;
            default:
                V3boothFront = V3BoothFrontFull;
        }
        const {translation} = this.state;

        return (
            <div className="no-exhibitors-wrapper">
                {isLargeScreen ? (
                    <>
                        <div className="no-exhibitors-wrapper__booth-preview-container">
                            <img src={V3boothFront} alt="Booth version 3" />
                        </div>
                        <p className="no-exhibitors-text">
                            <span>{translation?.showfloor.noExhibitorsTextFirst}</span>
                            <span>{translation?.showfloor.noExhibitorsTextSecond}</span>
                        </p>
                    </>
                ) : (
                    <>
                        <EmptyBoxIcon />
                        <p>{`${translation?.showfloor.noExhibitorsTextFirst}. ${translation?.showfloor.noExhibitorsTextSecond}`}</p>
                    </>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
    };
};

export default connect(mapStateToProps)(ShowfloorNoExhibitor);
