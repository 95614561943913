// types of abilities
export const ROLE_ABILITIES_TYPES = {
    COMMON: {
        MANAGE_ABILITY: 'manage',
        VIEW_ABILITY: 'view',
        CREATE_ABILITY: 'create',
        DELETE_ABILITY: 'delete',
        APPROVE_WALL_ABILITY: 'approve',
    },
    EXHIBITOR: {
        VIEW_WITH_DISABLED_EXHIBITORS_LOGIN: 'view with disabled exhibitors login',
        VIEW_USER_INFO: 'view user info',
        SET_USER_INFO: 'set user info',
        GET_INFO: 'get info',
        CHANGE_ACCESS_PACKAGE: 'change exhibitor access package',
        NAVIGATE: 'navigate to',
    },
    EXHIBITOR_REP: {
        NAVIGATE: 'navigate to',
    },
    PARTICIPANT: {
        VIEW_WITH_DISABLED_PARTICIPANTS_LOGIN: 'view with disabled participants login',
        SEND_STATISTICS: 'send statistic',
        GET_INFO: 'get info',
    },
    CO_ORGANIZER: {
        MANAGE_ABILITY: 'manage',
    },
};

// user features abilities
export const ROLE_FEATURES_TYPES = {
    ORGANIZER_DASHBOARD: 'organizer dashboard',
    ORGANIZER_DASHBOARD_NAVIGATION: 'organizer dashboard navigation',
    EXHIBITOR_DASHBOARD: 'exhibitor dashboard',
    EXHIBITOR_DASHBOARD_SUPPORT: 'exhibitor dashboard support',
    EXHIBITOR_BOOTH: 'exhibitor booth',
    BUILDING_LOBBY: 'building lobby',
    BUILDING_SHOWFLOOR_INFO_SCREEN: 'building showfloor info screen',
    LOBBY: 'lobby',
    CHAT_WITH_ORGANIZER_REP: 'chat with Organizer Representative',
    RESTRICT_AUDITORIUM_ACCESS: 'restrict auditorium access',
    RESTRICT_SHOWFLOOR_ACCESS: 'restrict showfloor access',
    UNREGISTER_EVENT: 'unregister event',
    PROCEED_TO_EVENT_SESSIONS: 'proceed to event sessions',
    CLOSED_WALL: 'closed wall',
    WALLS_SINGLE_POST: `wall's single post`,
    WALLS_COMMENT: `wall's comment`,
    WALL_APPROVE: 'wall approve post/comment',
    WALLS_PIN_MESSAGE: `wall's pin message`,
    BOOTH_WALL_PIN_MESSAGE: 'booth wall pin message',
    BOOTH_WALL_APPROVE: 'booth wall approve post/comment',
    VIDEO_WALL_APPROVE: 'video wall approve post/comment',
    EVENT_PROFILE: 'event profile',
    APPLIED_JOBS_TABLE: 'applied jobs table',
    ACCOUNT_PROFILE_INFO: 'account profile info',
    ACCESS_PACKAGE: 'user access package',
    ACCESS_PACKAGES_LIST: 'user access packages',
    STATISTICS: {
        ALL_BOOTH_STATISTICS: 'all booth statics',
        DOCUMENT_CLICKS: 'document clicks',
        LINK_CLICKS: 'link clicks',
    },
    EVENT_ARCHIVE: 'event archive',
    EVENT_CREATE: 'event create',
    VIEW_MATHCING: 'view mathcing',
    SESSION_LIST_DASHBOARD: 'session list dashboard',
    INTERPRETER_SESSION_LIST_BUTTON: 'interpreter session list button',
    MODERATOR_SESSION_LIST_BUTTON: 'moderator session list button',
    SPEAKER_SESSION_LIST_BUTTON: 'speaker session list button',
    // ETX SESSIONS
    ETX_SESSION_DASHBOARD: 'etx session dashboard',
    INTERPRETER_DEVICES_CHECK: 'interpreter devices check',
    INTERPRETER_AUDIO_STREAM_BUTTONS: 'interpreter audio stream buttons',
    ETX_SESSION_STREAM_CONTROLS: 'etx session stream controls',
    ETX_SESSION_VIDEO_STREAM: 'etx session video stream',
    ETX_SESSION_TOGGLE_SPEAKER_MEDIA: 'etx session toggle speaker media',
    ETX_SESSION_TOGGLE_MODERATOR_MEDIA: 'etx session toggle moderator media',
    ETX_SESSION_KICK_USER: 'etx session kick user',
};
