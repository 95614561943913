import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Tooltip} from '@material-ui/core';
import classNames from 'classnames';

import VideoAvatar from '../VideoAvatar/VideoAvatar';

const calculateGridSize = (total) => {
    if (!total) total = 1;
    const xSize = Math.ceil(Math.sqrt(total));
    const ySize = xSize * (xSize - 1) >= total ? xSize - 1 : xSize;
    return {
        width: `${100 / xSize}%`,
        height: `${100 / ySize}%`,
    };
};

const useStyles = makeStyles({
    playbacksList: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        flexWrap: 'wrap',
    },
    videoWrapper: {
        display: 'flex',
        maxHeight: '100%',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer',
    },
    videoWrapperSize: ({activeStreamsCount}) => calculateGridSize(activeStreamsCount),
    enlarged: {
        width: '100%',
        height: '100%',
    },
    video: {
        width: '100%',
        objectFit: 'cover',
    },
    videoEnlarged: {
        objectFit: 'contain',
    },
    videoOff: {
        border: '1px solid #fff',
    },
    videoOffPlate: {
        position: 'absolute',
        display: 'flex',
        zIndex: 100,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        backgroundColor: '#000',
    },
    hidden: {
        display: 'none',
    },
});

const VideoChatPlayerVideos = ({members, playbacks, activeStreamsCount, vidRefs, startVideo, forceUpdate}) => {
    const classes = useStyles({activeStreamsCount});
    const [enlargedStream, setEnlargedStream] = useState(null);

    const setRef = (el, i) => {};

    const handlePlateFocused = (memberId) => setEnlargedStream(!enlargedStream ? memberId : null);

    return (
        <div className={classNames(classes.playbacksList, activeStreamsCount === 0 && classes.hidden)}>
            {playbacks.map((pb, i) => {
                const isCameraOff = !pb.availableKinds.includes('video') ? classes.videoOff : null;
                const memberId = pb.configs.stream.split('_')[1];
                return (
                    <Tooltip
                        key={i}
                        title={
                            !enlargedStream
                                ? 'Click to view the original video size'
                                : 'Click to return to the default view'
                        }
                        placement="top"
                        arrow
                    >
                        <div
                            onClick={() => handlePlateFocused(memberId)}
                            className={classNames(
                                classes.videoWrapper,
                                !enlargedStream && classes.videoWrapperSize,
                                enlargedStream === memberId && classes.enlarged,
                                !!enlargedStream && enlargedStream !== memberId && classes.hidden,
                                (!pb.mediaStream || !pb.mediaStream.active) && classes.hidden
                            )}
                        >
                            <video
                                className={classNames(
                                    classes.video,
                                    isCameraOff && classes.videoOff,
                                    memberId === enlargedStream && classes.videoEnlarged
                                )}
                                ref={(el) => setRef(el, i)}
                                id={`pb-video-${i + 1}`}
                                playsInline={true}
                            ></video>
                            {isCameraOff && (
                                <div className={classes.videoOffPlate}>
                                    <VideoAvatar
                                        text={'name'}
                                        member={members.find((member) => member._id === memberId)}
                                    />
                                </div>
                            )}
                        </div>
                    </Tooltip>
                );
            })}
        </div>
    );
};

export default VideoChatPlayerVideos;
