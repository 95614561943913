import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './AuditoriumOverlayPlayer.scss';

export function AuditoriumOverlayPlayer({children, className, onClick}) {
    return (
        <div className={classNames('auditoriumOverlayPlayer', {[className]: className})} onClick={onClick}>
            {children}
        </div>
    );
}

AuditoriumOverlayPlayer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    onClick: PropTypes.func,
};
