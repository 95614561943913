import React from 'react';
import {usePlatformTranslation} from '../../../../../services/hooks';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../../../../store/actions';
import {Link} from 'react-router-dom';
import RenderEventList from '../../../MyAccount/components/AccountPagesScene/components/MyEvents/components/RenderEventList';
import {RouterConfig} from '../../../../../routerConfig';
import {useStyles} from './UpcomingEvents.style';
import {EVENTS_TYPE} from '../../../../../constants/eventListing';

export default function UpcomingEvents() {
    const dispatch = useDispatch();
    const style = useStyles();

    const {eventsListing} = usePlatformTranslation();
    const firstFutureEventsForHomePage = useSelector((state) => state.eventListing.firstFutureEventsForHomePage);

    React.useEffect(() => {
        dispatch(actions.getFirstFutureEventsForHomePage());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!firstFutureEventsForHomePage?.length) return null;

    return (
        <section className={style.container}>
            <h4 className={style.title}>{eventsListing.upcomingEvents}</h4>
            <RenderEventList data={firstFutureEventsForHomePage} isFromViewAll typeToLoad={EVENTS_TYPE.UPCOMING} />
            <div className={style.viewAllContainer}>
                <Link
                    to={{
                        pathname: RouterConfig.viewAll,
                    }}
                >
                    <span className={style.viewAll}>{eventsListing.viewAll}</span>
                </Link>
            </div>
        </section>
    );
}
