import React, {useEffect} from 'react';
import {GetApp as IconSave} from '@material-ui/icons';
import ReactExport from 'react-export-excel';
import Button from '../../../../../../Button/Button';
import AppliedJobsTable from './components/AppliedJobsTable';

import './AppliedJobs.scss';
import {usePlatformTranslation} from '../../../../../../../../services/hooks';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const excelFields = [
    {label: 'Job Title', value: 'jobTitle'},
    {label: 'Company Name', value: 'exhibitorCompany'},
    {label: 'Applied On', value: 'createdAt'},
    {label: 'Status', value: 'status'},
];

export const AppliedJobs = ({userId, appliedJobsList, eventId, eventName, getAppliedJobs}) => {
    const translation = usePlatformTranslation();

    useEffect(() => {
        getAppliedJobs(userId, eventId);
    }, [eventId, getAppliedJobs, userId]);

    return (
        <div className="AppliedJobs">
            <div className="AppliedJobs__titleRow">
                <h2 className="AppliedJobs__title">{eventName}</h2>

                <ExcelFile
                    filename={`Applied Jobs List ${eventName}`}
                    element={
                        <div className="AppliedJobs__downloadButton">
                            <Button title={translation?.appliedJobs?.downloadList} icon={<IconSave />} />
                        </div>
                    }
                >
                    <ExcelSheet data={appliedJobsList} name={`Applied Jobs List ${eventName}`}>
                        {excelFields.map((field, index) => (
                            <ExcelColumn key={index} label={field.label} value={field.value} />
                        ))}
                    </ExcelSheet>
                </ExcelFile>
            </div>

            <div className="AppliedJobs__table">
                <AppliedJobsTable />
            </div>
        </div>
    );
};
