import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import EventMenu from './EventMenu';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export default withRouter(
    connect(
        (state) => ({
            eventId: selectors.getEventId(state),
            eventSlug: selectors.getEventSlug(state),
            event: selectors.getEventData(state),
            eventArchive: selectors.getEventArchiveData(state),
            user: selectors.getUserData(state),
            sideNavigation: selectors.getSideNavigation(state),
            eventRoles: selectors.getUserEventRoles(state),
            resourcesAccess: selectors.getUserResourcesAccess(state),
            loggedIn: selectors.getIsUserLoggedIn(state),
            notReadChatMessages: selectors.getUserNotReadChatMessages(state),
            topNavigation: selectors.getUserTopNavigation(state),
            serverDown: selectors.getServerDown(state),
            sessionExpired: selectors.getSessionExpired(state),
            videoConferenceCompanionDisconnected: selectors.getCompanionDisconnected(state),
            isLargeScreen: selectors.getIsLargeScreen(state),
            buildingId: selectors.getBuildingId(state),
            languages: selectors.getLanguages(state),
            isRtlLanguage: selectors.getIsRtlLanguage(state),
            videoConference: selectors.getActiveCallTypeData(state),
            wallType: selectors.getWallType(state),
            activeWallId: selectors.getActiveWallId(state),
        }),
        {
            seeContacts: actions.topNavSeeContacts,
            seeProgram: actions.topNavSeeProgram,
            openWallet: actions.openWallet,
            seeChats: actions.topNavSeeChats,
            seeAgenda: actions.topNavSeeAgenda,
            seeMyEvents: actions.topNavSeeMyEvents,
            seeMyBooths: actions.topNavSeeMyBooths,
            seeNotifications: actions.topNavSeeNotifications,
            seeMyAccount: actions.topNavSeeMyAccount,
            onSetUserEventRoles: actions.setUserEventRoles,
            onSetNotReadChatMessages: actions.setNotReadChatMessages,
            getEventSuccess: actions.getEventSuccess,
            onGetEventArchive: actions.getEventArchive,
            onSetEventAccess: actions.setEventAccess,
            seeRooms: actions.bottomSeeRooms,
            changeWallStatus: actions.changeWallStatus,
            setIncomingCallUserId: actions.setIncomingCallUserId,
            setIncomingCallOffer: actions.setIncomingCallOffer,
            setIceCandidate: actions.setIceCandidate,
            setIceServers: actions.setIceServers,
            clearRemoteUserInfo: actions.clearRemoteUserInfo,
            videoConferenceCompanionStatusChange: actions.videoConferenceCompanionStatusChange,
        }
    )(EventMenu)
);
