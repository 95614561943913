import React, {useState, useEffect} from 'react';
import {Button, useTheme} from '@material-ui/core';
import {IconUpload} from 'Routes/components/Icons';
import {ReactComponent as RoundedArrowUpIcon} from 'Images/rounded-arrow-up.svg';
import EmptyBoxIcon from 'Images/EmptyBoxIcon';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';

import './UploadImage.scss';

function UploadImage({
    uploadBtnName,
    accept,
    removeBtnName,
    previewText,
    multiple,
    maxSize,
    setImage,
    removeImage,
    previewImgUrl,
    renderUploadPreiew,
    previouslyUploadedImage,
}) {
    const theme = useTheme();

    const [uploadedNote, setUploadedNote] = useState(null);
    const [error, setError] = useState('');

    useEffect(() => {
        if (previewImgUrl) {
            const splittedUrl = previewImgUrl.split('/');
            const title = splittedUrl[splittedUrl.length - 1];
            const img = {
                title,
                src: previewImgUrl,
                file: null,
            };
            setUploadedNote(img);
            setImage(img);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewImgUrl]);

    useEffect(() => {
        if (previouslyUploadedImage) {
            setUploadedNote({
                src: previouslyUploadedImage,
                file: null,
            });
        }
    }, [previouslyUploadedImage]);

    const handleChange = ({target}) => {
        try {
            if (!target?.files?.length) return;

            const image = target.files[0];
            const loadImage = new FileReader();

            loadImage.readAsDataURL(image);

            if (maxSize && image.size > maxSize) {
                setError('The image is too large to upload');
                return;
            }

            loadImage.onload = () => {
                setError('');
                const img = {
                    title: image.name,
                    src: loadImage.result,
                    file: image,
                };
                setUploadedNote(img);
                setImage(img);
            };

            loadImage.onerror = () => {
                setUploadedNote(null);
                setError('Something went wrong, please check the uploading file');
            };
        } catch (error) {
            setUploadedNote(null);
            setError('Something went wrong, please check the uploading file');
        }
    };

    const onRemoveImage = () => {
        setImage(null);
        setUploadedNote(null);
        removeImage && removeImage();
    };

    return (
        <div className={classNames('upload-image')}>
            <div className={classNames('upload-image__btn-container')}>
                <label className={classNames('upload-image__btn-container__upload-btn')}>
                    <IconUpload
                        className={classNames('upload-image__btn-container__upload-btn__icon', 'primary-fill-svg')}
                    />
                    <span className={classNames('upload-image__btn-container__upload-btn__text')}>{uploadBtnName}</span>
                    <input
                        className={classNames('upload-image__btn-container__upload-btn__file')}
                        type="file"
                        id="imageUpload"
                        onChange={handleChange}
                        accept={accept}
                        multiple={multiple}
                    />
                </label>
                <div>
                    <Button
                        className={classNames('upload-image__btn-container__remove-btn')}
                        onClick={onRemoveImage}
                        variant="outlined"
                        disabled={!uploadedNote}
                        startIcon={
                            <CloseIcon className={classNames('upload-image__btn-container__remove-btn__icon')} />
                        }
                    >
                        {removeBtnName}
                    </Button>
                </div>
            </div>
            <div className={classNames('upload-image__preview')}>
                {uploadedNote?.src ? (
                    <img className={classNames('upload-image__preview__img')} src={uploadedNote?.src} alt="preview" />
                ) : renderUploadPreiew ? (
                    renderUploadPreiew({label: 'imageUpload'})
                ) : (
                    <div className={classNames('upload-image__preview__previewEmpty')}>
                        <RoundedArrowUpIcon className={classNames('upload-image__preview__previewEmpty__arrow-icon')} />
                        <div>
                            <EmptyBoxIcon primary={theme.palette.primary.main} secondary={theme.palette.primary.main} />
                        </div>
                        <div className={classNames('upload-image__preview__previewEmpty__text')}>{previewText}</div>
                    </div>
                )}
            </div>
            <div className={classNames('upload-image__error')}>
                {error && (
                    <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {error}
                    </Alert>
                )}
            </div>
        </div>
    );
}

UploadImage.propTypes = {
    uploadBtnName: PropTypes.string,
    accept: PropTypes.string,
    removeBtnName: PropTypes.string,
    previewText: PropTypes.string,
    multiple: PropTypes.bool,
    maxSize: PropTypes.number,
    setImage: PropTypes.func.isRequired,
    removeImage: PropTypes.func,
    previewImgUrl: PropTypes.string,
    previouslyUploadedImage: PropTypes.string,
};

UploadImage.defaultProps = {
    uploadBtnName: 'UPLOAD IMAGE',
    accept: 'image/*',
    removeBtnName: 'Remove',
    previewText: 'Upload POST PICTURE',
    multiple: false,
    maxSize: 3072000,
};

export default UploadImage;
