const {COOKIES_ACCEPTED_KEY, COOKIES_ACCEPTED_EVENT} = require('constants/shared');

/**
 * @param {boolean} value
 */
export const setCookiesAccepted = (value) => {
    const event = new CustomEvent(COOKIES_ACCEPTED_EVENT, {detail: 'change cookiesAccepted'});

    localStorage.setItem(COOKIES_ACCEPTED_KEY, value);
    document.dispatchEvent(event);
};
