import * as actionTypes from './actionTypes';
import {Api} from '../../Api';

export const getEventArchive = (eventId) => async (dispatch, getState) => {
    const {
        eventArchive: {loading},
    } = getState();

    dispatch(getEventArchiveStart());

    try {
        const {archivedEvents} = await Api.request({
            url: `/event/${eventId}/archived-events`,
            method: 'get',
            loading,
        });

        dispatch(getEventArchiveSuccess(archivedEvents));
    } catch (error) {
        let errorMessage = "We couldn't retrieve the event arhchive. Please try again.";
        dispatch(getEventArchiveFail(errorMessage));
    }
};

const getEventArchiveStart = () => {
    return {
        type: actionTypes.GET_EVENT_ARCHIVE_START,
    };
};
const getEventArchiveSuccess = (eventArchiveData) => {
    return {
        type: actionTypes.GET_EVENT_ARCHIVE_SUCCESS,
        payload: eventArchiveData,
    };
};
const getEventArchiveFail = (errorMessage) => {
    return {
        type: actionTypes.GET_EVENT_ARCHIVE_FAIL,
        payload: errorMessage,
    };
};
