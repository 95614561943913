import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import * as actions from '../../../../../store/actions';

const NotApprovedAccount = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.setEventProtectedMenu());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="organizer-dashboard-page">
            <div className="smallContainer">
                <div className="dashboard-wrapper d-flex justify-content-center align-items-center">
                    <p>
                        Your organizer dashboard is pending approval. You will receive an email notification once it
                        gets approved.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default NotApprovedAccount;
