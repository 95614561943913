import React from 'react';
import {SelectValidator} from 'react-material-ui-form-validator';
import {MenuItem} from '@material-ui/core';

class SelectField extends React.Component {
    handleChange = (e) => {
        this.props.handleChange(e.target.name, e.target.value);
    };

    render() {
        const {field} = this.props;
        return (
            <SelectValidator
                onChange={this.handleChange}
                name={field.name}
                variant="outlined"
                value={field.value}
                validators={field.validators}
                errorMessages={field.errorMessages}
                label={field.label}
                className="field-container"
                fullWidth={true}
            >
                {field.options.map((option) => {
                    return (
                        <MenuItem key={option._id} value={option._id}>
                            {option.value}
                        </MenuItem>
                    );
                })}
            </SelectValidator>
        );
    }
}

export default SelectField;
