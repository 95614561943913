import React from 'react';
import {connect} from 'react-redux';
import {Lock as LockIcon} from '@material-ui/icons';

class NoBuildingAccess extends React.Component {
    render() {
        const {isLargeScreen} = this.props;
        return (
            <div className={`no-building-access ${!isLargeScreen ? 'mobile' : ''}`}>
                <LockIcon fontSize={'inherit'} />
                <span>Your package doesn't have access to this Building.</span>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLargeScreen: state.layout.isLargeScreen,
    };
};

export default connect(mapStateToProps)(NoBuildingAccess);
