export const UPLOAD_CUSTOM_LOGO_REQUEST = 'ORGANIZER_EVENT_SETUP/UPLOAD_CUSTOM_LOGO_REQUEST';
export const UPLOAD_CUSTOM_LOGO_SUCCESS = 'ORGANIZER_EVENT_SETUP/UPLOAD_CUSTOM_LOGO_SUCCESS';
export const UPLOAD_CUSTOM_LOGO_FAILED = 'ORGANIZER_EVENT_SETUP/UPLOAD_CUSTOM_LOGO_FAILED';

export const UPLOAD_WELCOME_VIDEO_REQUEST = 'ORGANIZER_EVENT_SETUP/UPLOAD_WELCOME_VIDEO_REQUEST';
export const UPLOAD_WELCOME_VIDEO_SUCCESS = 'ORGANIZER_EVENT_SETUP/UPLOAD_WELCOME_VIDEO_SUCCESS';
export const UPLOAD_WELCOME_VIDEO_FAILED = 'ORGANIZER_EVENT_SETUP/UPLOAD_WELCOME_VIDEO_FAILED';

export const UPLOAD_AUDITORIUM_NOTE_REQUEST = 'ORGANIZER_EVENT_SETUP/UPLOAD_AUDITORIUM_NOTE_REQUEST';
export const UPLOAD_AUDITORIUM_NOTE_SUCCESS = 'ORGANIZER_EVENT_SETUP/UPLOAD_AUDITORIUM_NOTE_SUCCESS';
export const UPLOAD_AUDITORIUM_NOTE_FAILED = 'ORGANIZER_EVENT_SETUP/UPLOAD_AUDITORIUM_NOTE_FAILED';

export const UPLOAD_EXHIBITORS_NOTE_REQUEST = 'ORGANIZER_EVENT_SETUP/UPLOAD_EXHIBITORS_NOTE_REQUEST';
export const UPLOAD_EXHIBITORS_NOTE_SUCCESS = 'ORGANIZER_EVENT_SETUP/UPLOAD_EXHIBITORS_NOTE_SUCCESS';
export const UPLOAD_EXHIBITORS_NOTE_FAILED = 'ORGANIZER_EVENT_SETUP/UPLOAD_EXHIBITORS_NOTE_FAILED';

export const UPDATE_CLOSE_MESSAGE_START = 'ORGANIZER_EVENT_SETUP/UPDATE_CLOSE_MESSAGE_START';
export const UPDATE_CLOSE_MESSAGE_SUCCESS = 'ORGANIZER_EVENT_SETUP/UPDATE_CLOSE_MESSAGE_SUCCESS';
export const UPDATE_CLOSE_MESSAGE_FAIL = 'ORGANIZER_EVENT_SETUP/UPDATE_CLOSE_MESSAGE_FAIL';

export const ADD_EVENT_REPRESENTATIVE_START = 'ADD_EVENT_REPRESENTATIVE_START';
export const ADD_EVENT_REPRESENTATIVE_SUCCESS = 'ADD_EVENT_REPRESENTATIVE_SUCCESS';
export const ADD_EVENT_REPRESENTATIVE_FAIL = 'ADD_EVENT_REPRESENTATIVE_FAIL';

export const REMOVE_EVENT_REPRESENTATIVE_START = 'REMOVE_EVENT_REPRESENTATIVE_START';
export const REMOVE_EVENT_REPRESENTATIVE_SUCCESS = 'REMOVE_EVENT_REPRESENTATIVE_SUCCESS';
export const REMOVE_EVENT_REPRESENTATIVE_FAIL = 'REMOVE_EVENT_REPRESENTATIVE_FAIL';
// CO_ORGANIZER

export const GET_CO_ORGANIZER_LIST_REQUEST = 'GET_CO_ORGANIZER_LIST_REQUEST';
export const GET_CO_ORGANIZER_LIST_SUCCESS = 'GET_CO_ORGANIZER_LIST_SUCCESS';
export const GET_CO_ORGANIZER_LIST_FAILED = 'GET_CO_ORGANIZER_LIST_FAILED';

export const GET_CO_ORGANIZER_SHORT_LIST_REQUEST = 'GET_CO_ORGANIZER_SHORT_LIST_REQUEST';
export const GET_CO_ORGANIZER_SHORT_LIST_SUCCESS = 'GET_CO_ORGANIZER_SHORT_LIST_SUCCESS';
export const GET_CO_ORGANIZER_SHORT_LIST_FAILED = 'GET_CO_ORGANIZER_SHORT_LIST_FAILED';

export const TOGGLE_CO_ORGANIZER_ACCESS_REQUEST = 'TOGGLE_CO_ORGANIZER_ACCESS_REQUEST';
export const TOGGLE_CO_ORGANIZER_ACCESS_SUCCESS = 'TOGGLE_CO_ORGANIZER_ACCESS_SUCCESS';
export const TOGGLE_CO_ORGANIZER_ACCESS_FAILED = 'TOGGLE_CO_ORGANIZER_ACCESS_FAILED';

export const DELETE_CO_ORGANIZER_REQUEST = 'DELETE_CO_ORGANIZER_REQUEST';
export const DELETE_CO_ORGANIZER_SUCCESS = 'DELETE_CO_ORGANIZER_SUCCESS';
export const DELETE_CO_ORGANIZER_FAILED = 'DELETE_CO_ORGANIZER_FAILED';

export const TOGGLE_CO_ORGANIZER_SUSPEND_REQUEST = 'TOGGLE_CO_ORGANIZER_SUSPEND_REQUEST';
export const TOGGLE_CO_ORGANIZER_SUSPEND_SUCCESS = 'TOGGLE_CO_ORGANIZER_SUSPEND_SUCCESS';
export const TOGGLE_CO_ORGANIZER_SUSPEND_FAILED = 'TOGGLE_CO_ORGANIZER_SUSPEND_FAILED';

export const ADD_CO_ORGANIZER_REQUEST = 'ADD_CO_ORGANIZER_REQUEST';
export const ADD_CO_ORGANIZER_SUCCESS = 'ADD_CO_ORGANIZER_SUCCESS';
export const ADD_CO_ORGANIZER_FAILED = 'ADD_CO_ORGANIZER_FAILED';
