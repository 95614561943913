import React, {useCallback} from 'react';
import {useSelector, connect} from 'react-redux';
import classNames from 'classnames';
import moment from 'moment';

import {IconTrash, IconAngleArrowRight} from '../../../../../../../../../../../Icons';
import Button from '../../../../../../../../../../../Button/Button';

import {getMonth} from '../../../../../../../../../../../../../services/utils';

import './DesktopAgendaProgramItem.scss';

const DesktopAgendaProgramItem = ({
    title,
    eventName,
    auditoriumName,
    startTime,
    endTime,
    timeZone,
    date,
    isProgram,
    isMeeting,
    onRemove,
    onMeetingStart,
    isRtlLanguage,
}) => {
    const platformLanguage = useSelector((state) => state.languages.platformLanguage);

    const getDateString = useCallback(
        (date) => {
            let currentDate = moment(date, 'Do MMM YYYY').startOf('day');
            let day = currentDate.date();
            let month = getMonth(date, platformLanguage, true);
            let year = currentDate.year();

            return `${day} ${month} ${year}`;
        },
        [platformLanguage]
    );

    return (
        <div className={classNames('DesktopAgendaProgramItem', {isRtl: isRtlLanguage})}>
            <div className="DesktopAgendaProgramItem__titleCol">
                <div className="DesktopAgendaProgramItem__title">{title}</div>

                {isProgram && (
                    <div className="DesktopAgendaProgramItem__contextGroup">
                        <div className="DesktopAgendaProgramItem__eventName">{eventName}, </div>
                        <div className="DesktopAgendaProgramItem__auditoriumName">{auditoriumName}</div>
                    </div>
                )}
            </div>

            <div className="DesktopAgendaProgramItem__metaCol">
                <div className="DesktopAgendaProgramItem__dateGroup">
                    <div className="DesktopAgendaProgramItem__date">{getDateString(date)}</div>

                    <div className="DesktopAgendaProgramItem__timezone">{timeZone}</div>
                </div>

                <div className="DesktopAgendaProgramItem__timeGroup">
                    <div className="DesktopAgendaProgramItem__timeItem">{startTime}-</div>
                    <div className="DesktopAgendaProgramItem__timeItem">{endTime}</div>
                </div>

                <div className="DesktopAgendaProgramItem__actionGroup">
                    <div className="DesktopAgendaProgramItem__actionItem">
                        <Button icon={<IconTrash />} onClick={onRemove} />
                    </div>

                    <div
                        className={classNames('DesktopAgendaProgramItem__actionItem', {
                            DesktopAgendaProgramItem__actionItem_hidden: !isMeeting,
                        })}
                    >
                        <Button icon={<IconAngleArrowRight />} onClick={onMeetingStart} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default connect((state) => ({
    isRtlLanguage: state.languages.isRtlLanguage,
}))(DesktopAgendaProgramItem);
