import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {DialogActions, DialogContent, Button} from '@material-ui/core';
import {DIALOG_TITLE_TEXT, TABS_INDEXS} from 'constants/matchMaking';
import './MatchingDialogRejectPage.scss';

const MatchingDialogRejectPage = ({onSkip, setDialogTitle, setTab, classes}) => {
    useEffect(() => {
        setDialogTitle(DIALOG_TITLE_TEXT.rejectPage);
    }, [setDialogTitle]);
    const handleCancel = () => setTab(TABS_INDEXS.questionPage);
    const handleClose = () => onSkip();
    return (
        <>
            <DialogContent className={classes?.conntent}>
                <div className="matching-dialog-reject-page">
                    <p className="matching-dialog-reject-page__text">
                        If you close the form before completion, we will not store any details and the Smart Matching
                        results will not be available to you.
                    </p>
                    <p className="matching-dialog-reject-page__text">
                        Your profile will not part of the Matchmaking process and no one will be matched with you
                    </p>
                </div>
            </DialogContent>
            <DialogActions className={classes?.actions}>
                <div className="matchmaking-dialog__action-tab">
                    <Button onClick={handleCancel} className={'matchmaking-dialog__action-tab-btn'} variant="outlined">
                        cancel
                    </Button>
                    <Button onClick={handleClose} className={'matchmaking-dialog__action-tab-btn'} variant="outlined">
                        close
                    </Button>
                </div>
            </DialogActions>
        </>
    );
};

MatchingDialogRejectPage.propTypes = {
    classes: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onSkip: PropTypes.func,
    setTab: PropTypes.func,
    setDialogTitle: PropTypes.func,
};

export default MatchingDialogRejectPage;
