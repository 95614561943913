import React from 'react';

import DesktopAgendaMeetingItem from './components/DesktopAgendaMeetingItem/DesktopAgendaMeetingItem';
import MobileAgendaMeetingItem from './components/MobileAgendaMeetingItem/MobileAgendaMeetingItem';

import {useMedia, usePlatformTranslation} from '../../../../../../../../../../../../../services/hooks';
import {checkIsConfirmed} from '../../../../../../../../../../../../../services/utils';
import {meetingStatuses} from '../../../../../../../../../../../../../constants/data';

const getStatusString = (isConfirmed, isRequested, {confirmed, requested}, {pending}) => {
    if (isConfirmed) {
        return confirmed;
    }

    if (isRequested) {
        return requested;
    }

    return pending;
};

const getStatus = (isConfirmed, isRequested) => {
    if (isConfirmed) {
        return meetingStatuses.confirmed;
    }

    if (isRequested) {
        return meetingStatuses.requested;
    }

    return meetingStatuses.pending;
};

const AgendaMeetingItem = ({
    id,
    title,
    receiverName,
    meetingStatus,
    timeZone,
    receiverId,
    userId,
    senderName,
    onCancel,
    onConfirm,
    onMeetingStart,
    ...rest
}) => {
    const isMobile = useMedia('(max-width: 640px)');
    const {meetings, meetingsDropdown} = usePlatformTranslation();

    const isCurrentUserReceiver = receiverId === userId;
    const isConfirmed = checkIsConfirmed(meetingStatus);
    const isRequested = isCurrentUserReceiver && !isConfirmed;

    const calculatedProps = {
        title: `Meeting with ${isCurrentUserReceiver ? senderName : receiverName}`,
        description: title,
        statusString: getStatusString(isConfirmed, isRequested, meetings, meetingsDropdown),
        status: getStatus(isConfirmed, isRequested),
        timezone: timeZone,
        isConfirmed,
        isRequested,
        onCancel: () => onCancel(id),
        onConfirm: () => onConfirm(id),
        onMeetingStart: () => onMeetingStart(id),
    };

    return isMobile ? (
        <MobileAgendaMeetingItem {...rest} {...calculatedProps} />
    ) : (
        <DesktopAgendaMeetingItem {...rest} {...calculatedProps} />
    );
};

export default AgendaMeetingItem;
