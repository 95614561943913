import React from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';

import {IconCircledPlus} from '../../../../../../../Icons';
import IconContainer from '../../../../../../../IconContainer/IconContainer';
import AgendaEmptyMessage from '../AgendaEmptyMessage/AgendaEmptyMessage';
import AgendaProgramList from './components/AgendaProgramList/AgendaProgramList';

import {usePersonalAgendaItems, usePlatformTranslation} from 'services/hooks';
import {getIsRtlLanguage} from 'store/selectors';
import {removeAgendaElement, startMeeting, toggleAgendaCustomElement} from 'store/actions';

import './AgendaProgram.scss';

const AgendaProgram = () => {
    const dispatch = useDispatch();
    const isRtlLanguage = useSelector(getIsRtlLanguage);
    const {programList, userId, isProgramEmpty} = usePersonalAgendaItems();
    const {personalAgenda} = usePlatformTranslation();

    return (
        <div className={classNames('AgendaProgram', {isRtl: isRtlLanguage})}>
            <div className="AgendaProgram__customAddHolder">
                <button className="AgendaProgram__customAdd" onClick={() => dispatch(toggleAgendaCustomElement(true))}>
                    <span className="AgendaProgram__customAddIcon">
                        <IconContainer>
                            <IconCircledPlus />
                        </IconContainer>
                    </span>

                    <span className="AgendaProgram__customAddTitle">{personalAgenda.addNewTask.uppercased}</span>
                </button>
            </div>

            {isProgramEmpty ? (
                <div className="AgendaProgram__emptyMessage">
                    <AgendaEmptyMessage title={personalAgenda.noProgram} />
                </div>
            ) : (
                <div className="AgendaProgram__list">
                    <AgendaProgramList
                        list={programList}
                        userId={userId}
                        onRemove={(...args) => dispatch(removeAgendaElement(...args))}
                        onMeetingStart={(...args) => dispatch(startMeeting(...args))}
                    />
                </div>
            )}
        </div>
    );
};

export default AgendaProgram;
