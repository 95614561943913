import React, {useMemo, useState} from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '@material-ui/core/Chip';
import {
    calculateChipsCountInHeader,
    normalizeChipLable,
    mergeCheckedFilters,
} from '../../../../../../../services/helpers';
import {useStyles} from './FilterChipsPopUp.styles';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {getIsRtlLanguage, getPlatformLanguage} from 'store/selectors';

const FilterChipsPopUp = ({filters, setFilters}) => {
    const classes = useStyles();
    const isRtlLanguage = useSelector(getIsRtlLanguage);
    const platformLanguage = useSelector(getPlatformLanguage);

    const [isExpanded, setIsExpanded] = useState(false);

    const mergedFilters = useMemo(() => {
        return mergeCheckedFilters(filters, '', platformLanguage);
    }, [filters, platformLanguage]);

    const showChipsInHeader = useMemo(() => calculateChipsCountInHeader(mergedFilters), [mergedFilters]);
    const chipsInDetails =
        mergedFilters.length > 2 && !isExpanded ? `+${mergedFilters.length - showChipsInHeader}` : '';
    const isChipsInDetails = mergedFilters.length > 2;

    const handleChange = (e, expanded) => {
        if (!isChipsInDetails) return;
        setIsExpanded(expanded);
    };

    const handleUncheck = (filterId, optionId) => {
        // TODO: creae helper to check last chip in action
        // clear if it is the last chip
        if (mergedFilters.length < 2) return setFilters([]);

        const updatedFilters = [...filters];

        updatedFilters.forEach((filter) => {
            if (filter._id !== filterId) return;

            filter.options.forEach((option) => {
                if (option._id !== optionId) return;

                option.isChecked = false;
            });
        });

        setFilters(updatedFilters);
    };

    if (!mergedFilters.length) {
        return null;
    }

    return (
        <div className={classNames(classes.root, {isRtl: isRtlLanguage})}>
            <Accordion square className={classes.wrapper} expanded={isExpanded} onChange={handleChange}>
                <AccordionSummary
                    classes={{
                        root: classes.summary,
                        content: classes.summaryContent,
                        expandIcon: classNames(classes.ExpendIcon, {
                            isRtl: isRtlLanguage,
                        }),
                    }}
                    expandIcon={
                        isChipsInDetails && (
                            <ExpandMoreIcon
                                disabled
                                className={classNames({[`${classes.icon}`]: isExpanded})}
                                data-testid="expand-button"
                            />
                        )
                    }
                >
                    <div className={classes.header}>
                        <>
                            {mergedFilters.slice(0, showChipsInHeader).map((option) => (
                                <Chip
                                    classes={{deleteIcon: classNames(classes.chipDeleteIcon, {isRtl: isRtlLanguage})}}
                                    key={option.optionId}
                                    className={classNames(classes.chip, {isRtl: isRtlLanguage})}
                                    label={normalizeChipLable(option.optionName)}
                                    onDelete={() => handleUncheck(option.filterId, option.optionId)}
                                />
                            ))}
                        </>
                        <p className={classNames(classes.count, {isRtl: isRtlLanguage})}>{`${chipsInDetails}`}</p>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <div className={classes.chipslist}>
                        {mergedFilters.slice(showChipsInHeader).map(({filterId, optionName, optionId}, i) => (
                            <Chip
                                key={i}
                                classes={{deleteIcon: classNames(classes.chipDeleteIcon, {isRtl: isRtlLanguage})}}
                                className={classNames(classes.chip, classes.chipDetails, {isRtl: isRtlLanguage})}
                                label={normalizeChipLable(optionName)}
                                onDelete={() => handleUncheck(filterId, optionId)}
                            />
                        ))}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    );
};

export default FilterChipsPopUp;
