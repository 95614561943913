import {useEffect, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {requestAllUsersStatuses, setUsersStatuses, updateOnlineUsers} from 'store/actions';
import {getUserData, getEventData, getActiveCallTypeData, getUsersStatuses} from 'store/selectors';
import {changeUserStatusToBusy, changeUserStatusToOnline, handleUserStatusUpdate} from 'Api/socketApi';
import useUniqRelatedUserIdList from './useUniqRelatedUserIdList';

const useUserStatuses = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUserData);
    const event = useSelector(getEventData);
    const statuses = useSelector(getUsersStatuses);
    const {isActive: isVideoConferenceActive} = useSelector(getActiveCallTypeData);
    const relatedUserIdList = useUniqRelatedUserIdList();
    const userId = user?._id;
    const eventId = event?._id;

    const isFirstRender = useRef(true);
    const relatedUserIdListRef = useRef(relatedUserIdList);
    relatedUserIdListRef.current = relatedUserIdList;
    const statusesRef = useRef(statuses);
    statusesRef.current = statuses;

    useEffect(() => {
        if (!userId || !eventId) {
            return;
        }

        dispatch(requestAllUsersStatuses(relatedUserIdList));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, eventId]);

    useEffect(() => {
        handleUserStatusUpdate(({id, status}) => {
            dispatch(updateOnlineUsers({id, status}));

            if (relatedUserIdListRef.current.includes(id)) {
                dispatch(setUsersStatuses({...statusesRef.current, [id]: status}));
            }
        });
    }, [dispatch]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        if (isVideoConferenceActive) {
            changeUserStatusToBusy(userId);
            return;
        }

        changeUserStatusToOnline(userId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVideoConferenceActive]);
};

export default useUserStatuses;
