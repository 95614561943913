import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getMediaCapture} from 'store/actions';
import {getActiveCallTypeData} from 'store/selectors';

export default function useUserMedia(selfVideoRef) {
    const dispatch = useDispatch();
    const {capture: mediaStream, isActive} = useSelector(getActiveCallTypeData);

    useEffect(() => {
        if (!selfVideoRef) return;
        if (isActive && !mediaStream) {
            dispatch(getMediaCapture());
        }
        if (selfVideoRef.current && mediaStream) selfVideoRef.current.srcObject = mediaStream;
    }, [dispatch, isActive, mediaStream, selfVideoRef]);

    return mediaStream;
}
