import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        padding: '0 !important',
    },
    listItem: {
        height: '60px',
    },
    listText: {
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: 16,
        textTransform: 'uppercase',
    },
    listIcon: {
        minWidth: 'auto',
    },
}));
