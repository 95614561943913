export const SOCKET_EVENTS = {
    // core
    CONNECT: 'connect',
    DISCONNECT: 'disconnect',
    RECONNECT_ATTEMPT: 'reconnect_attempt',
    // room connectors
    CONNECT_TO_EVENT: 'connect-to-event',
    CONNECT_TO_PRIVATE_CHAT: 'connect-to-private-chat',
    CONNECT_TO_GROUP_CHAT: 'connect-to-group-chat',
    CONNECT_TO_WALL: 'connect-to-wall',
    CONNECT_TO_USER_NOTIFICATIONS: 'connect-to-user-notifications',
    CONNECT_TO_BOOTH: 'connect-to-booth',
    CONNECT_TO_ADMIN_BOOTH: 'connect-admin-to-booth',
    CONNECT_TO_TIMESLOT: 'connect-to-timeslot',
    CONNECT_TO_TIMESLOT_CHAT: 'connect-to-timeslot-chat',
    CONNECT_TO_PROGRAM_POLLS: 'connect-to-program-polls',
    CONNECT_TO_BUILDING: 'connect-to-building',
    CONNECT_TO_EVENT_NEWS: 'connect-to-event-news',
    CONNECT_TO_EVENT_LOBBY_NEWS: 'connect-to-event-lobby-news',
    CONNECT_TO_BUILDING_NEWS: 'connect-to-building-news',
    CONNECT_TO_AUDITORIUM: 'connect-to-auditorium',
    // room disconnectors
    DISCONNECT_FROM_EVENT: 'disconnect-from-event',
    DISCONNECT_FROM_PRIVATE_CHAT: 'disconnect-from-private-chat',
    DISCONNECT_FROM_GROUP_CHAT: 'disconnect-from-group-chat',
    DISCONNECT_FROM_WALL: 'disconnect-from-wall',
    DISCONNECT_FROM_USER_NOTIFICATIONS: 'disconnect-from-user-notifications',
    DISCONNECT_FROM_BOOTH: 'disconnect-from-booth',
    DISCONNECT_FROM_ADMIN_BOOTH: 'disconnect-admin-from-booth',
    DISCONNECT_FROM_TIMESLOT: 'disconnect-from-timeslot',
    DISCONNECT_FROM_TIMESLOT_CHAT: 'disconnect-from-timeslot-chat',
    DISCONNECT_FROM_PROGRAM_POLLS: 'disconnect-from-program-polls',
    DISCONNECT_FROM_BUILDING: 'disconnect-from-building',
    DISCONNECT_FROM_EVENT_NEWS: 'disconnect-from-event-news',
    DISCONNECT_FROM_EVENT_LOBBY_NEWS: 'disconnect-from-event-lobby-news',
    DISCONNECT_FROM_BUILDING_NEWS: 'disconnect-from-building-news',
    DISCONNECT_FROM_AUDITORIUM: 'disconnect-from-auditorium',
    // one to one calls
    P2P_CALL_TO_USER: 'p2p-call-to-user',
    P2P_END_CALL: 'p2p-end-call',
    P2P_CALL_OFFER: 'p2p-call-offer',
    P2P_CALL_ANSWER: 'p2p-call-answer',
    P2P_CALL_CANDIDATE: 'p2p-call-candidate',
    P2P_ICE_SERVERS: 'ice-servers',
    P2P_INCOMING_CALL: 'incoming-call',
    P2P_VIDEO_ENABLED: 'p2p-call-video-enabled',
    P2P_VIDEO_DISABLED: 'p2p-call-video-disabled',
    // new message in Private chat
    NEW_PRIVATE_MESSAGE_SAVED: 'new-message-saved',
    // new message in Group chat
    NEW_GROUP_MESSAGE_SAVED: 'new-group-message-saved',
    GROUP_CHAT_UPDATED: 'group-chat-updated',
    // wall
    WALL_NEW_POST_SAVED: 'new-post-saved',
    WALL_CLOSED: 'wall-closed',
    WALL_OPENED: 'wall-opened',
    WALL_POST_DELETED: 'wall-post-deleted',
    WALL_NEW_POST_COMMENT_SAVED: 'new-post-comment-saved',
    WALL_NEW_PENDING_COMMENT_SAVED: 'new-pending-comment-saved',
    WALL_POST_COMMENT_DELETED: 'wall-post-comment-deleted',
    WALL_NEW_POST_LIKE_SAVED: 'new-post-like-saved',
    WALL_NEW_PENDING_POST_SAVED: 'new-pending-post-saved',
    WALL_POST_LIKE_REMOVED: 'post-like-removed',
    WALL_POST_PIN: 'post-pin',
    WALL_POST_UNPIN: 'post-unpin',
    // user notifications
    NEW_USER_NOTIFICATION: 'new-notification',
    NEW_FLASH_NOTIFICATION: 'new-flash-notification',
    BOOTH_PARTICIPANTS_UPDATED: 'booth-participants-updated',
    ALL_WALL_POST_DELETED: 'all-wall-posts-deleted',
    SEND_SERVER_TIMESTAMP: 'send-server-timestamp',
    CALL_USERS: 'call-users',
    RECEIVE_CALL_USERS: 'receive-call-users',
    // timeslot
    TIMESLOT_NEW_MESSAGE_SAVED: 'new-timeslot-message-saved',
    TIMESLOT_STREAM_STARTED: 'timeslot-stream-started',
    TIMESLOT_STREAM_STOPPED: 'timeslot-stream-stopped',
    TIMESLOT_RECORDING_STARTED: 'timeslot-recording-started',
    TIMESLOT_RECORDING_STOPPED: 'timeslot-recording-stopped',
    TIMESLOT_STREAM_PUBLISHED: 'timeslot-stream-published',
    TIMESLOT_INTERPRETER_STREAM_PUBLISHED: 'timeslot-interpreter-stream-published',
    TIMESLOT_INTERPRETER_STREAM_UNPUBLISHED: 'timeslot-interpreter-stream-unpublished',
    TIMESLOT_STREAM_UNPUBLISHED: 'timeslot-stream-unpublished',
    TIMESLOT_RESOURCES_ALLOCATED: 'timeslot-resources-allocated',
    TIMESLOT_RESOURCES_HOLD: 'timeslot-resources-hold',
    TIMESLOT_RESOURCES_HOLDING: 'timeslot-resources-holding',
    TIMESLOT_RESOURCES_ALLOCATION_FAILED: 'timeslot-resources-allocation-failed',
    // polls
    POLL_UPDATED: 'poll-updated',
    POLL_CREATED: 'poll-created',
    POLL_DELETED: 'poll-deleted',
    // user statuses
    USERS_STATUS_UPDATE: 'users-status-update',
    USERS_STATUS_CHANGE_TO_BUSY: 'change-to-busy',
    USERS_STATUS_CHANGE_TO_ONLINE: 'change-to-online',
    // hot news
    HOT_NEWS_STARTED: 'hot-news-started',
    HOT_NEWS_STOPPED: 'hot-news-stopped',
    // on-demand video
    NEW_ONDEMAND_VIDEO: 'new-on-demand-video',
    // participants registration
    HIDE_PARTICIPANT_LINK: 'hide-participant-link',
    // participants login
    HIDE_LOGIN_LINK: 'hide-login-link',
    // matchmaking
    EVENT_MATCHING_ENABLED_UPDATED: 'event-matching-enabled-updated',
    EVENT_HAS_MATCHING_UPDATED: 'event-has-matching-updated',
};
