import React from 'react';
import {connect} from 'react-redux';

import CreateEventIcon from 'Images/create-event.svg';
import EventsList from './components/EventsList/EventsList';

import './MyEventsMobile.scss';

class MyEventsMobile extends React.Component {
    state = {
        translation: null
    };

    componentDidMount() {
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation
        });
    };

    render() {
        const {eventId, redirectToCreateEvent, redirectToEvent, eventRolesList} = this.props;
        const {translation} = this.state;
        return (
            <div id="account-drop-mobile" className="my-events-mobile">
                <div className="my-events-mobile__title">
                    <h3>{translation?.myEventsDropdown.title}</h3>
                    <div className="my-events-mobile__create" onClick={redirectToCreateEvent}>
                        <img src={CreateEventIcon} width="20" height="20" alt="Create event" />
                        <span>{translation?.myEventsDropdown.createButtonMobile}</span>
                    </div>
                </div>
                {this.props.eventRolesList.length && (
                    <div className="my-events-mobile__list-wrapper">
                        <EventsList
                            eventRolesList={eventRolesList}
                            eventId={eventId}
                            redirectToEvent={redirectToEvent}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages
    };
};

export default connect(mapStateToProps)(MyEventsMobile);
