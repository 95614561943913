import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Slider from 'react-slick';
import classNames from 'classnames';

import * as actions from '../../../store/actions/index';
import {ReactComponent as Arrow} from '../../../Images/FeaturedExhibitorSliderArrow.svg';
import {usePlatformTranslation} from '../../../services/hooks';
import {Tooltip} from '@material-ui/core';

import './FeaturedExhibitorsSlider.scss';

const SampleNextArrow = ({className, style, onClick}) => (
    <div className={className} style={{...style}} onClick={onClick}>
        <Arrow />
    </div>
);

const SamplePrevArrow = ({className, style, onClick}) => (
    <div className={className} style={{...style}} onClick={onClick}>
        <Arrow />
    </div>
);

const FeaturedExhibitorsSlider = (props) => {
    const dispatch = useDispatch();
    const exhibitors = useSelector((state) => state.exhibitors.approvedExhibitors);

    const {activeExhibitorId, featuredExhibitors} = useSelector((state) => state.exhibitors);
    const {isRtlLanguage} = useSelector((state) => state.languages);

    const slider = useRef();

    const [sliderContainerStyles, setSliderContainerStyles] = useState({
        maxWidth: ((window.innerHeight - 315) * 132.7) / 100,
    });
    const translation = usePlatformTranslation();

    useEffect(() => {
        calculateSliderMaxWidth();
        window.addEventListener('resize', calculateSliderMaxWidth);

        return () => {
            window.removeEventListener('resize', calculateSliderMaxWidth);
        };
    }, [featuredExhibitors]);

    useEffect(() => {
        const activeElementIndex = featuredExhibitors.findIndex((exhibitor) => exhibitor._id === activeExhibitorId);
        if (activeElementIndex !== -1) {
            slider.current.slickGoTo(activeElementIndex);
        }
    }, [activeExhibitorId, featuredExhibitors]);

    const calculateSliderMaxWidth = () => {
        let realHeight = window.innerHeight - 315;

        if (window.innerHeight < 700) {
            realHeight = window.innerHeight - 210;
        }

        let sliderContainerStyles = {
            maxWidth: (realHeight * 165) / 100,
        };

        if (window.innerHeight < 700) {
            sliderContainerStyles = {
                maxWidth: (realHeight * 165) / 100,
            };
        }

        setSliderContainerStyles(sliderContainerStyles);
    };

    const sliderSettings = {
        dots: false,
        rtl: isRtlLanguage ? true : false,
        arrows: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        infinite: featuredExhibitors.length > 4,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        className: 'center',
        centerPadding: '0',
        focusOnSelect: true,
        responsive: [
            {
                breakpoint: 320,
                settings: {slidesToShow: 1},
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    infinite: featuredExhibitors.length > 2,
                },
            },
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 4,
                    infinite: featuredExhibitors.length > 3,
                },
            },
        ],
    };

    return (
        <div className="exhibitors-slider" style={sliderContainerStyles}>
            <div className={classNames('all-exhibitors-button', {isRtl: isRtlLanguage})}>
                <div className="text-container">
                    <p className="text" onClick={props.showExhibitorsOverlay}>
                        {translation?.showfloor.allExhibitorsButton}
                        <span>{exhibitors.length}</span>
                    </p>
                </div>
            </div>
            <p className={classNames('slider-title', {isRtl: isRtlLanguage})}>
                {translation?.showfloor.featuredExhibitorsText || 'FEATURED EXHIBITORS'}
            </p>
            <div className="slider-container">
                <Slider {...sliderSettings} ref={slider}>
                    {featuredExhibitors.map((exhibitor) => {
                        return (
                            <div
                                className={
                                    'featured-exhibitors-container ' +
                                    (activeExhibitorId === exhibitor._id ? 'active' : null)
                                }
                                key={exhibitor._id}
                                onClick={() => dispatch(actions.setActiveExhibitorId(exhibitor._id))}
                            >
                                <div className="logo-container">
                                    <p className="logo-container__title">{exhibitor.sponsorTitle}</p>
                                    <div className="logo-container__content">
                                        {exhibitor.booth.logo && (
                                            <div className="logo-container__image">
                                                <img
                                                    src={exhibitor.filesUrl + exhibitor.booth.logo}
                                                    alt={exhibitor.company}
                                                />
                                            </div>
                                        )}
                                        <Tooltip title={exhibitor.company} placement="top-start">
                                            <p className="logo-container__company">{exhibitor.company}</p>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </div>
    );
};

export default FeaturedExhibitorsSlider;
