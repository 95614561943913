import {nanoid} from 'nanoid';
import * as marketplaceActionTypes from '../actionTypes/exhibitorMarketplaceActionTypes';
import {Api} from 'Api';
import {normalizeProduct, normalizeProductList} from 'services/utils/normalizers';
import {createMarketplaceFormData} from 'services/helpers';

export const loadProductList = () => ({
    type: marketplaceActionTypes.LOAD_PRODUCT_LIST,
});

export const setProductList = ({productList = [], error}) => ({
    type: marketplaceActionTypes.SET_PRODUCT_LIST,
    payload: {productList, error},
});

export const createProduct = () => ({
    type: marketplaceActionTypes.CREATE_PRODUCT,
    payload: {id: nanoid()},
});

export const publishProduct = (product) => ({
    type: marketplaceActionTypes.PUBLISH_PRODUCT,
    payload: {product},
});

export const editProduct = (product) => ({
    type: marketplaceActionTypes.EDIT_PRODUCT,
    payload: {product},
});

export const deleteProduct = (product) => ({
    type: marketplaceActionTypes.DELETE_PRODUCT,
    payload: {product},
});

export const setDeletedProduct = (product) => ({
    type: marketplaceActionTypes.SET_DELETED_PRODUCT,
    payload: {product},
});

export const resetActiveProduct = () => ({
    type: marketplaceActionTypes.RESET_ACTIVE_PRODUCT,
});

export const resetDeletedProduct = () => ({
    type: marketplaceActionTypes.RESET_DELETED_PRODUCT,
});

export const requestProductReceiving = (exhibitorId) => async (dispatch, getState) => {
    const {
        event: {eventId},
    } = getState();
    try {
        const {products} = await Api.request({
            method: 'get',
            url: `/event/${eventId}/exhibitor/${exhibitorId}/products`,
        });
        dispatch(setProductList({productList: normalizeProductList(products, exhibitorId)}));
    } catch (error) {
        dispatch(setProductList({error}));
        console.warn(error);
    }
};

export const handleProductDelete = (exhibitorId) => async (dispatch, getState) => {
    const {
        event: {eventId},
        exhibitorMarketplace: {deletedProduct, editedProduct},
    } = getState();
    if (deletedProduct?.id === editedProduct?.id) {
        dispatch(resetActiveProduct());
    }
    dispatch(deleteProduct(deletedProduct));

    try {
        await Api.request({
            method: 'delete',
            url: `/event/${eventId}/exhibitor/${exhibitorId}/products/${deletedProduct.id}`,
        });
    } catch (error) {
        console.warn(error);
    }
};

export const handleProductPublish = (exhibitorId, product) => async (dispatch, getState) => {
    const formData = createMarketplaceFormData(product);
    const {
        event: {eventId},
        exhibitorMarketplace: {editedProduct},
    } = getState();

    if (!!editedProduct) {
        try {
            const res = await Api.request({
                method: 'put',
                url: `/event/${eventId}/exhibitor/${exhibitorId}/products/${product.id}`,
                payload: formData,
            });

            dispatch(publishProduct(normalizeProduct(res.product, exhibitorId)));
            return;
        } catch (error) {}
    }

    try {
        const {product} = await Api.request({
            method: 'post',
            url: `/event/${eventId}/exhibitor/${exhibitorId}/products`,
            payload: formData,
        });
        dispatch(publishProduct(normalizeProduct(product, exhibitorId)));
    } catch (error) {}
};
