import React from 'react';
import {CircularProgress} from '@material-ui/core';

import MainLogo from '../MainLogo/MainLogo';
import {getPlatformBackground, whiteLabel} from '../../../config';

import './FullPageSpinner.scss';

const FullPageSpinner = () => {
    const BackgroundImage = getPlatformBackground(whiteLabel);

    return (
        <div className="full-page-spinner" style={{backgroundImage: `url('${BackgroundImage}')`}}>
            <div className="full-page-spinner__logo-container">
                <MainLogo className="full-page-spinner__main-logo" />
            </div>
            <div className="full-page-spinner__homepage-overlay"></div>
            <div className="full-page-spinner__spinner-container">
                <CircularProgress className="full-page-spinner__spinner" />
            </div>
        </div>
    );
};

export default FullPageSpinner;
