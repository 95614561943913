import React from 'react';

export const ScaArabicContent = () => (
    <div className="header-spacing-container" dir="rtl">
        <div className="gdpr-container">
            <h1>اتفاقية ملفات تعريف الارتباط</h1>

            <p>
                تستخدم منصة الهيئة السعودية للمقاولين ("نحن"، أو "لنا"، أو "إيانا") ملفات تعريف الارتباط على
                https://www.sca-events.com ("الخدمة"). باستخدام الخدمة، فإنك توافق على استخدام ملفات تعريف الارتباط.
            </p>
            <p>
                توضح سياسة ملفات تعريف الارتباط الخاصة بنا ماهية ملفات تعريف الارتباط، وكيف نستخدم ملفات تعريف الارتباط،
                وكيف يمكن أن تستخدم الأطراف الثالثة التي قد نتشارك معها ملفات تعريف الارتباط في الخدمة، وخياراتك فيما
                يتعلق بملفات تعريف الارتباط ،والمزيد من المعلومات حول ملفات تعريف الارتباط.
            </p>

            <h2>كيف تستخدم منصة الهيئة السعودية للمقاولين ملفات تعريف الارتباط؟</h2>
            <p>
                ملفات تعريف الارتباط هي أجزاء صغيرة من النص يرسلها متصفح الويب الخاص بك عن طريق الموقع الإلكتروني الذي
                تزوره. يتم تخزين ملف تعريف الارتباط في متصفح الويب الخاص بك ويسمح للخدمة أو لطرف ثالث بالتعرف عليك
                وتسهيل زيارتك التالية وجعل الخدمة أكثر إفادة لك.
            </p>
            <p>يمكن أن تكون ملفات تعريف الارتباط ملفات تعريف ارتباط "دائمة" أو "جلسة".</p>

            <h2>كيف تستخدم منصة الهيئة السعودية للمقاولين ملفات تعريف الارتباط؟</h2>
            <p>عند استخدامك للخدمة والوصول إليها، قد نضع عددًا من ملفات تعريف الارتباط في متصفح الويب الخاص بك.</p>
            <p>
                نحن نستخدم ملفات تعريف الارتباط للأغراض التالية: لتمكين وظائف معينة للخدمة ولتوفير التحليلات ولتخزين
                تفضيلاتك ولتمكين تقديم الإعلانات، بما في ذلك الإعلانات السلوكية.
            </p>
            <p>
                نحن نستخدم ملفات تعريف الارتباط الخاصة بالجلسة والمستمرة على حد سواء على الخدمة ونستخدم أنواعًا مختلفة
                من ملفات تعريف الارتباط لتشغيل الخدمة.
            </p>
            <p>
                ملفات تعريف الارتباط الأساسية. قد نستخدم ملفات تعريف الارتباط الأساسية لمصادقة المستخدمين ومنع الاستخدام
                الاحتيالي لحسابات المستخدمين.
            </p>

            <h3>نستخدم الأنواع التالية من ملفات تعريف الارتباط:</h3>
            <ul>
                <li>
                    <p>
                        <b>الأداء والتقنية</b>
                    </p>
                    <p>
                        تساعدنا ملفات تعريف الارتباط في معرفة مدى جودة عمل مواقعنا. نستخدم أيضًا ملفات تعريف الارتباط
                        لفهم المنتجات والميزات والخدمات وتحسينها والبحث فيها، بما في ذلك إنشاء السجلات والتسجيل عند
                        وصولك إلى موقعنا الإلكتروني من أجهزة مختلفة، مثل جهاز الحساب المحمول للعمل أو الحساب الآلي
                        المحمول الخاص بك.
                    </p>
                    <p>
                        في بعض الصفحات، يمكن لمستخدمينا إدراج مقاطع فيديو من موقع YouTube.com. سيتم تشغيل مقاطع الفيديو
                        المتوفرة في صفحة قاعة منصة الهئية السعودية للمقاولين تلقائياً. من أجل توفير هذه الوظيفة، نستخدم
                        ملفات تعريف الارتباط الخاصة بطرف ثالث على يوتيوب. لن يخزن يوتيوب معلومات حول زوارنا، إلا إذا
                        قاموا بتشغيل الفيديو أو فتح صفحة قاعة استماع، وفقًا لدليل جوجل - يوتيوب{' '}
                        <a href="https://support.google.com/youtube/answer/171780?hl=en-GB">
                            {' '}
                            https://support.google.com/youtube/answer/171780?hl=en-GB
                        </a>
                    </p>
                </li>
                <li>
                    <p>
                        <b>إحصاءات - جوجل أناليتكس</b>
                    </p>
                    <p>
                        يستخدم موقعنا الإلكتروني جوجل أناليتكس، وهي خدمة تحليل ويب تابعة لشركة جوجل ("جوجل"). تعرف على
                        المزيد من شروط الخدمة وسياسة الخصوصية الخاصة بهم. يمكنك الانسحاب من هنا{' '}
                        <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>
                    </p>
                </li>
            </ul>

            <h2>ما هي اختياراتك بخصوص ملفات تعريف الارتباط؟</h2>
            <p>
                إذا كنت ترغب في حذف ملفات تعريف الارتباط أو إرشاد متصفح الويب الخاص بك إلى حذف ملفات تعريف الارتباط أو
                رفضها، فيرجى زيارة صفحات المساعدة في متصفح الويب لديك.
            </p>
            <p>
                مع ذلك، يرجى ملاحظة أنه إذا حذفت ملفات تعريف الارتباط أو رفضت قبولها، فقد لا تتمكن من استخدام جميع
                الميزات التي نقدمها، وقد لا تتمكن من تخزين تفضيلاتك، وقد لا يتم عرض بعض صفحاتنا بشكل صحيح.
            </p>
        </div>
    </div>
);
