import {useSelector} from 'react-redux';
import {FALLBACK_TRANSLATION_SLUG} from '../../constants/translations';
import {allTranslations} from '../../constants/translations/all-translations';

const useEventTranslation = (selector) => {
    const languages = useSelector((state) => state.languages);
    const eventTranslation = allTranslations[languages.eventLanguage].content;
    const fallbackTranslation = allTranslations[FALLBACK_TRANSLATION_SLUG].content;

    try {
        return selector(eventTranslation);
    } catch {
        try {
            return selector(fallbackTranslation);
        } catch {
            return '';
        }
    }
};

export default useEventTranslation;
