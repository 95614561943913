import React from 'react';

import IconContainer from '../../../../../../IconContainer/IconContainer';
import {IconProgram, IconAgenda} from '../../../../../../Icons';
import BottomMenuBottomSheet from './components/BottomMenuBottomSheet/BottomMenuBottomSheet';

import {usePlatformTranslation} from '../../../../../../../../services/hooks';
import './ScheduleBottomMenuBottomSheet.scss';

const Button = ({icon, title, ...rest}) => (
    <button className="ScheduleBottomMenuBottomSheet__button" {...rest}>
        <span className="ScheduleBottomMenuBottomSheet__buttonIcon">
            <IconContainer>{icon}</IconContainer>
        </span>

        <span className="ScheduleBottomMenuBottomSheet__buttonTitle">{title}</span>
    </button>
);

const ScheduleBottomMenuBottomSheet = ({onAgendaClick, onProgramClick}) => {
    const translation = usePlatformTranslation();

    return (
        <BottomMenuBottomSheet title={translation.personalAgenda.timetableMenu}>
            <div className="ScheduleBottomMenuBottomSheet">
                <div className="ScheduleBottomMenuBottomSheet__item">
                    <Button
                        title={translation.personalAgenda.personalAgenda}
                        icon={<IconAgenda />}
                        onClick={onAgendaClick}
                    />
                </div>

                <div className="ScheduleBottomMenuBottomSheet__item">
                    <Button title={translation.homepage.tabProgram} icon={<IconProgram />} onClick={onProgramClick} />
                </div>
            </div>
        </BottomMenuBottomSheet>
    );
};

export default ScheduleBottomMenuBottomSheet;
