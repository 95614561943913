import {connect} from 'react-redux';
import * as actions from '../../../../../../../../../../../store/actions';
import {BusinessWalletUser} from './BusinessWalletUser';

export default connect(
    (state) => ({
        privateChats: state.user.data.privateChats,
        eventId: state.event.eventId,
        loading: state.user.loading,
        userLoggedIn: state.user.data,
        isRtlLanguage: state.languages.isRtlLanguage,
    }),
    {
        onSendContactRequest: actions.sendContactRequest,
        onAcceptContactRequest: actions.acceptContactRequest,
        onCancelBusinessCardRequest: actions.cancelBusinessCardRequest,
        onDeclineContactRequest: actions.declineContactRequest,
        onOpenPrivateChat: actions.openPrivateChat,
        onCreatePrivateChat: actions.createPrivateChat,
        onOpenWallet: actions.openWallet,
    }
)(BusinessWalletUser);
