import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import cn from 'classnames';

import {ReactComponent as Fire} from '../../../Images/fire.svg';
import './HotNewsPopup.scss';

const HotNewsPopup = ({hotNews}) => {
    const [popupIsExpanded, setPopupIsExpanded] = useState(false);
    const sideMenuOpened = useSelector((state) => state.user.sideNavigation.isOpened);
    const handleTogglePopupExpanded = () => {
        setPopupIsExpanded(!popupIsExpanded);
    };

    if (Object.keys(hotNews).length === 0 || hotNews.constructor !== Object) {
        return null;
    }

    return (
        <div className={cn('hot_news_popup', {'-is-expanded': popupIsExpanded, '-side-opened': sideMenuOpened})}>
            {popupIsExpanded ? (
                <div className="expanded_container">
                    <div className="news_logo">
                        <Fire />
                        <span>news</span>
                    </div>
                    <p className="text-area">{hotNews.text}</p>
                    <button onClick={() => handleTogglePopupExpanded()}>minimize</button>
                </div>
            ) : (
                <div className="non_expanded_container" onClick={() => handleTogglePopupExpanded()}>
                    <Fire />
                    <span className="text">news</span>
                </div>
            )}
            <div className="expander"></div>
        </div>
    );
};

export default HotNewsPopup;
