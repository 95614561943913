import {connect} from 'react-redux';
import * as selectors from 'store/selectors';
import AccountGrid from './AccountGrid';

export default connect(
    (state) => ({
        hideMenu: selectors.getAccountNavigationHide(state),
    }),
    {}
)(AccountGrid);
