import React, {Children, cloneElement, useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {changeSideTab} from '../../../../../../../store/actions';
import {Tabs, Tab} from '@material-ui/core';

import AccountLeftSideTabsPanel from './components/Panel/Panel';

import {usePlatformTranslation} from '../../../../../../../services/hooks';
import './AccountLeftSideTabs.scss';

const getA11yProps = (index) => ({
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
});

const AccountLeftSideTabs = ({children, header = null, withComment = false}) => {
    const dispatch = useDispatch();

    const [value, setValue] = useState(0);
    const {businessCard} = usePlatformTranslation();

    const handleChange = useCallback(
        (event, newValue) => {
            setValue(newValue);
            dispatch(changeSideTab(newValue));
        },
        [dispatch]
    );

    return (
        <div className="AccountLeftSideTabs">
            <div className="AccountLeftSideTabs__switchGroup">
                <div className="AccountLeftSideTabs__switchList">
                    <Tabs
                        orientation="vertical"
                        variant="scrollable"
                        value={value}
                        onChange={handleChange}
                        aria-label="Vertical tabs example"
                        TabIndicatorProps={{
                            style: {left: '0', width: '4px', backgroundColor: 'var(--theme-primary-color)'},
                        }}
                    >
                        {Children.map(children, (child, index) =>
                            cloneElement(child, {
                                children: undefined,
                                disabled: child.props.isDisabled && index !== value,
                                ...getA11yProps(index),
                            })
                        )}
                    </Tabs>
                </div>

                {withComment && <div className="AccountLeftSideTabs__switchComment">* {businessCard.onceSaved}</div>}
            </div>

            <div className="AccountLeftSideTabs__content">
                {header && <div className="AccountLeftSideTabs__contentHeader">{header}</div>}

                {Children.map(children, (child, index) => (
                    <AccountLeftSideTabsPanel index={index} isActive={index === value}>
                        {child.props.children}
                    </AccountLeftSideTabsPanel>
                ))}
            </div>
        </div>
    );
};

const Item = ({title, isDisabled, ...rest}) => (
    <Tab label={title} className="AccountLeftSideTabs__button" disabled={isDisabled} {...rest} />
);

AccountLeftSideTabs.Item = Item;

export default AccountLeftSideTabs;
