import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilityStateFunction';

const initialState = {
    loading: false,
    data: null,
    exhibitorId: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EXHIBITOR_START:
            return updateObject(state, {loading: true});
        case actionTypes.GET_EXHIBITOR_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.payload,
                exhibitorId: action.payload._id,
            });
        case actionTypes.GET_EXHIBITOR_FAIL:
            return updateObject(state, {loading: false});
        case actionTypes.CLEAR_EXHIBITOR_DATA:
            return initialState;
        default:
            return state;
    }
};

export default reducer;
