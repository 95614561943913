import React, {Component} from 'react';
import {ValidatorForm} from 'react-material-ui-form-validator';

import TextField from 'Routes/components/TextField/TextField';
import RadioField from 'Routes/components/RadioField/RadioField';
import SelectField from 'Routes/components/SelectField/SelectField';
import CheckboxField from 'Routes/components/CheckboxField/CheckboxField';
import CheckboxInputField from 'Routes/components/CheckboxInputField/CheckboxInputField';
import SelectCountry from 'Routes/components/SelectCountry/SelectCountry';
import TextareaField from 'Routes/components/TextareaField/TextareaField';
import FileField from 'Routes/components/FileField/FileField';

import EditSectionContainer from '../../../../../EditSectionContainer';

import {Api} from 'Api';

import {facebookValidation, linkedinValidation, linkValidation, twitterValidation} from 'services/utils';
import {getFieldTranslatedLabel, getFieldTranslatedOptions} from 'services/helpers/registrationFieldsHelper';
import {AbilityContext} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';
import {CUSTOM_FIELD_TYPES, USER_ROLES} from 'constants/shared';
import {getOptionIdFromCustomRadio} from 'services/helpers/customFormHelpers';
import {getCustomFieldsErrorMessages, getCustomFieldsValidators} from 'services/helpers/customFieldsHelpers';

class EventProfilePage extends Component {
    state = {
        canUnregisterEvent: true,
        registrationType: '',
        user: {},
        fields: null,
        disabled: true,
        isParticipant: false
    };

    componentDidMount() {
        const {getEventProfile, userRoles, onGetGroupsList} = this.props;
        getEventProfile(() => {
            this.defineUserFieldsByRole();
        });
        this.getRegistrationType();
        this.addValidationRules();
        this.checkCanUnregisterEvent();

        const isParticipant = userRoles.includes(USER_ROLES.participant);
        const roleType = isParticipant ? USER_ROLES.participant : USER_ROLES.exhibitor;
        onGetGroupsList({userRole: roleType});
        this.setState({isParticipant});
    }

    componentDidUpdate(prevProps) {
        // force a state update if the user changed something in a participant or exhibitor
        if (
            (prevProps.eventRoles.isExhibitor === false &&
                this.props.eventRoles.isExhibitor === true &&
                !prevProps.eventRoles.exhibitorId) ||
            (prevProps.eventRoles.isParticipant === false &&
                this.props.eventRoles.isParticipant === true &&
                !prevProps.eventRoles.participantId) ||
            prevProps.event._id !== this.props.event._id ||
            prevProps.eventUsers !== this.props.eventUsers ||
            prevProps.accessManagement !== this.props.accessManagement
        ) {
            this.defineUserFieldsByRole();
        }
    }

    componentWillUnmount() {
        this.removeValidationRules();
    }

    getRegistrationType = async () => {
        try {
            const {registrationType} = await Api.request({
                method: 'get',
                url: `/users/get-registration-type`
            });

            this.setState({registrationType});
        } catch (error) {}
    };

    checkCanUnregisterEvent = () => {
        const ability = this.context;
        if (ability.cannot(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.UNREGISTER_EVENT)) {
            this.setState({canUnregisterEvent: false});
        }
    };

    addValidationRules = () => {
        ValidatorForm.addValidationRule('isFacebookLink', facebookValidation);
        ValidatorForm.addValidationRule('isLinkedinLink', linkedinValidation);
        ValidatorForm.addValidationRule('isLTwitterLink', twitterValidation);
        ValidatorForm.addValidationRule('isLink', linkValidation);
    };

    defineUserFieldsByRole = () => {
        const {isParticipant} = this.state;
        const {user, event} = this.props;
        const {participantsGroups, exhibitorsGroups} = this.props.accessManagement;

        const registatiomForms = isParticipant ? participantsGroups : exhibitorsGroups;
        const registrationFormId = user.eventRoles.find((role) => role.event._id === event._id)?.registrationForm;
        const registatiomForm = registatiomForms?.find((form) => form._id === registrationFormId);

        registatiomForm && this.setProfileFields(registatiomForm);
    };

    setProfileFields = (form) => {
        const {eventProfile, language} = this.props;
        let updatedFields = [];

        Object.keys(form.predefinedFields).forEach((key) => {
            const predefinedField = form.predefinedFields[key];
            if (predefinedField?.isEnabled) {
                let field = {
                    isEnabled: predefinedField.isEnabled,
                    name: key,
                    type: 'text',
                    value: eventProfile?.[key] || '',
                    label: getFieldTranslatedLabel(predefinedField, language),
                    multiline: 0,
                    validators: [],
                    errorMessages: []
                };
                if (predefinedField.isRequired) {
                    field.validators.push('required');
                    field.errorMessages.push('Field is required');
                }
                updatedFields.push(field);
            }
        });

        // custom registration fields
        form.customFields.forEach((extraField) => {
            if (!eventProfile) return;

            const profileField = eventProfile?.customFields.find(
                (field) => String(field.customFieldId) === String(extraField._id)
            );

            let value = '';
            if (extraField.type === CUSTOM_FIELD_TYPES.checkbox) {
                value = profileField?.values?.map(({optionId}) => optionId);
            } else if (extraField.type === CUSTOM_FIELD_TYPES.checkboxInput) {
                value = profileField?.values;
            } else {
                value = getOptionIdFromCustomRadio(extraField, profileField) || '';
            }

            const {isRequired, textValidation} = extraField;

            const field = {
                isEnabled: true,
                name: extraField._id,
                type: extraField.type,
                value: value,
                label: getFieldTranslatedLabel(extraField, language),
                multiline: extraField.type === CUSTOM_FIELD_TYPES.textArea ? 5 : 0,
                validators: getCustomFieldsValidators({isRequired, textValidation}),
                errorMessages: getCustomFieldsErrorMessages({isRequired, textValidation}),
                options: getFieldTranslatedOptions(extraField.options, language),
                fileValidations: extraField.fileValidations || [],
                checkboxInputs: profileField?.checkboxInputs || extraField?.checkboxInputs || []
            };
            updatedFields.push(field);
        });

        this.setState({fields: updatedFields});
    };

    removeValidationRules = () => {
        ValidatorForm.removeValidationRule('isFacebookLink');
        ValidatorForm.removeValidationRule('isLinkedinLink');
        ValidatorForm.removeValidationRule('isLTwitterLink');
        ValidatorForm.removeValidationRule('isLink');
    };

    handleRemoveUserDocument = async (documentId) => {
        try {
            await Api.request({method: 'delete', url: `/users/document/${documentId}`});
        } catch (error) {}
    };

    handleChange = (fieldName, fieldValue) => {
        let updatedFields = [...this.state.fields];

        let updatedFieldIndex = updatedFields.findIndex((field) => field.name === fieldName);

        updatedFields[updatedFieldIndex] = {
            ...updatedFields[updatedFieldIndex],
            value: fieldValue
        };

        this.setState({user: {...this.state.user}, fields: updatedFields}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({disabled: !isValid});
            });
        });
    };

    handleUpdateProfileInformation = async () => {
        const {language, eventRoles, eventId, eventProfile, getEventProfile, user} = this.props;
        const registrationFormId = user.eventRoles.find((el) => el.event._id === eventId)?.registrationForm;
        const formData = new FormData();

        this.state.fields.forEach((field) => {
            if (field.type === CUSTOM_FIELD_TYPES.file && field.value?.title) {
                formData.append(field.name, field.value.file, field.value.title);
                return;
            }

            if (field.type === CUSTOM_FIELD_TYPES.checkbox) {
                formData.append(field.name, JSON.stringify(field.value));
                return;
            }

            if (field.type === CUSTOM_FIELD_TYPES.checkboxInput) {
                formData.append(field.name, JSON.stringify(field.value));
                return;
            }

            formData.append(field.name, field.value);
        });
        formData.append('language', language);
        formData.append('registrationFormId', registrationFormId);

        try {
            const apiUrl = eventRoles.isExhibitor
                ? `/event/${eventId}/exhibitor/${eventProfile._id}`
                : `/event/${eventId}/participant/${eventProfile._id}`;

            await Api.request({
                url: apiUrl,
                method: 'put',
                payload: formData
            });

            getEventProfile();
            this.setState({disabled: true});
        } catch (error) {}
    };

    render() {
        const {user} = this.props;
        const {fields} = this.state;

        return (
            <>
                {!!fields?.length && (
                    <EditSectionContainer className="onvent-profile edit-user-information">
                        <ValidatorForm ref="form" onSubmit={this.handleUpdateProfileInformation}>
                            {fields.map((field) => {
                                if (field.name === 'country') {
                                    return (
                                        <SelectCountry
                                            field={field}
                                            handleChange={this.handleChange}
                                            key={field.name}
                                            value={field.value}
                                        />
                                    );
                                }
                                if (field.type === 'select') {
                                    return (
                                        <SelectField
                                            field={field}
                                            handleChange={this.handleChange}
                                            key={field.name}
                                            value={field.value}
                                        />
                                    );
                                }
                                if (field.type === 'radio') {
                                    return (
                                        <RadioField
                                            field={field}
                                            handleChange={this.handleChange}
                                            key={field.name}
                                            value={field.value}
                                        />
                                    );
                                }
                                if (field.type === 'checkbox') {
                                    return (
                                        <CheckboxField
                                            field={field}
                                            handleChange={this.handleChange}
                                            key={field.name}
                                        />
                                    );
                                }
                                if (field.type === 'checkboxInput') {
                                    return (
                                        <CheckboxInputField
                                            field={field}
                                            handleChange={this.handleChange}
                                            key={field.name}
                                        />
                                    );
                                }
                                if (field.type === 'text-area') {
                                    return (
                                        <TextareaField
                                            field={field}
                                            handleChange={this.handleChange}
                                            key={field.name}
                                            value={field.value}
                                            variant={'outlined'}
                                        />
                                    );
                                }
                                if (field.type === 'file') {
                                    return (
                                        <FileField
                                            field={field}
                                            handleChange={this.handleChange}
                                            deleteFile={this.handleRemoveUserDocument}
                                            key={field.name}
                                            value={field.value}
                                            userId={user._id}
                                            variant={'outlined'}
                                        />
                                    );
                                }
                                return (
                                    <TextField
                                        field={field}
                                        handleChange={this.handleChange}
                                        key={field.name}
                                        value={field.value}
                                        variant={'outlined'}
                                    />
                                );
                            })}
                        </ValidatorForm>
                        <div className="buttons-actions">
                            <button
                                className="update-button"
                                disabled={this.state.disabled}
                                onClick={this.handleUpdateProfileInformation}
                            >
                                Save
                            </button>
                        </div>
                    </EditSectionContainer>
                )}
            </>
        );
    }
}

EventProfilePage.contextType = AbilityContext;

export default EventProfilePage;
