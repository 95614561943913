import * as actionTypes from '../actionTypes/accountActionTypes';
import {Api} from 'Api';
import {refreshUserData} from './userActions';

export const changeSideTab = (id) => ({type: actionTypes.CHANGE_SIDE_TAB, payload: id});

export const setAccountBackLink = (linkData) => ({type: actionTypes.SET_ACCOUNT_BACK_LINK, payload: linkData});
export const resetAccountBackLink = () => ({type: actionTypes.RESET_ACCOUNT_BACK_LINK});
export const hideAccountNavigation = (payload) => ({type: actionTypes.HIDE_ACCOUNT_NAVIGATION, payload});

export const getProfileRegistrationType =
    ({callback}) =>
    async () => {
        try {
            const {registrationType} = await Api.request({
                method: 'get',
                url: `/users/get-registration-type`,
            });

            callback && callback(registrationType);
        } catch (error) {}
    };

export const unregisterFromEvent =
    ({userId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        try {
            await Api.request({
                method: 'delete',
                url: `/event/${eventId}/unregister-user/${userId}`,
            });

            callback && callback();
        } catch (error) {}
    };

export const updateUserAvatar =
    ({method, payload}) =>
    async (dispatch) => {
        try {
            const requestData = {
                method,
                url: '/users/me/avatar',
            };

            if (payload) {
                requestData.payload = payload;
            }

            await Api.request(requestData);

            dispatch(refreshUserData());
        } catch (error) {}
    };

export const changeUserLanguage = (language) => async (dispatch) => {
    try {
        await Api.request({
            method: 'put',
            url: '/users',
            payload: {language},
        });

        dispatch(refreshUserData());
    } catch (error) {}
};
