import React from 'react';
import classNames from 'classnames';
import ColoredScrollbars from '../../../../../ColoredScrollbars/ColoredScrollbars';
import CreateGroupChat from './components/CreateGroupChat/CreateGroupChat';
import PrivateChat from './components/PrivateChat';
import GroupChat from './components/GroupChat';
import ChatsHeader from './components/ChatsHeader/ChatsHeader';
import ChatsList from './components/ChatsList/ChatsList';
import PrivateChatHeader from './components/PrivateChat/components/PrivateChatHeader/PrivateChatHeader';
import GroupChatHeader from './components/GroupChat/components/GroupChatHeader/GroupChatHeader';
import DropdownActions from './components/DropdownActions/DropdownActions';
import MainChatHeader from './components/MainChatHeader/MainChatHeader';
import NoChatsInfo from './components/NoChatsInfo/NoChatsInfo';

import './Chats.scss';

class Chats extends React.Component {
    state = {
        openCreateGroupChat: false,
        close: false,
        timeAgoPrivateChat: '',
        translation: null,
    };

    componentDidMount() {
        // although we have the chats, we force a refresh
        this.props.onGetPrivateChats();
        this.props.onGetGroupChats();

        this.props.onGetOnlineUsers();

        this.interval = setInterval(() => this.forceUpdate(), 60000);

        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeChatRoomId !== this.props.activeChatRoomId && this.props.videoConference.isActive) {
            this.props.closeVideoConference();
        }
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    componentWillUnmount() {
        this.props.onCloseActiveChat();

        clearInterval(this.interval);
        this.props.closeVideoConference();
    }

    closeActiveChat = () => {
        this.props.onCloseActiveChat();
    };

    handleCloseCreateGroupChat = () => {
        this.setState({openCreateGroupChat: false});
    };

    handleOpenGroupChat = (groupChatId) => {
        const {onOpenGroupChat} = this.props;
        this.setState({openCreateGroupChat: false});
        onOpenGroupChat(groupChatId);
    };

    handleCreateNewChat = () => {
        this.setState({openCreateGroupChat: true});
    };

    closeComponent = () => {
        this.props.onCloseActiveChat();
        clearInterval(this.interval);

        // although we have the chats, we force a refresh
        this.props.onGetPrivateChats();
        this.props.onGetGroupChats();
        this.props.onGetAllChats();
        this.props.onGetEventChats(this.props.event._id);

        this.props.onGetOnlineUsers();
    };

    openCreateGroupChat = () => {
        this.setState({openCreateGroupChat: true});
    };

    closeCreateGroupChat = () => {
        this.setState({openCreateGroupChat: false});
    };

    render() {
        const {activeChatRoomId, event, resourcesAccess, isLargeScreen, isTablet, isMobile} = this.props;
        const {translation} = this.state;
        let restrictedNetwork = false;
        if (event?.hasAccessManagement && resourcesAccess !== null && !resourcesAccess.networking) {
            restrictedNetwork = true;
        }
        const totalChats = this.props.user?.groupChats.length + this.props.user?.privateChats.length;

        const expandedChat = (this.props.maximizeChats?.seeExpandedVideoConference || isTablet) && !isMobile;

        return (
            <div
                className={classNames(
                    'chat-page dropdown-component',
                    {'no-active-chat': !activeChatRoomId},
                    {expanded: expandedChat},
                    {'no-chats': totalChats === 0},
                    {isRtl: this.props.isRtlLanguage}
                )}
                data-close={this.state.close && 'true'}
            >
                {isLargeScreen && <DropdownActions />}
                {!isMobile && (
                    <MainChatHeader
                        restrictedNetwork={restrictedNetwork}
                        isOpenCreateGroupChat={this.state.openCreateGroupChat}
                        openCreateGroupChat={this.openCreateGroupChat}
                        closeCreateGroupChat={this.closeCreateGroupChat}
                        closeActiveChat={this.closeActiveChat}
                    />
                )}

                {isMobile && <ChatsHeader handleCreateNewChat={this.handleCreateNewChat} />}
                {isMobile && !this.props.isGroupChat && this.props.activeChatData && (
                    <PrivateChatHeader activeChatData={this.props.activeChatData} />
                )}
                {isMobile && this.props.isGroupChat && this.props.activeChatData && (
                    <GroupChatHeader activeChatData={this.props.activeChatData} />
                )}

                <div
                    className={classNames(
                        'chats-container',
                        {'chats-container--opened': activeChatRoomId},
                        {isRtl: this.props.isRtlLanguage}
                    )}
                    data-number-chats={totalChats}
                >
                    {this.state.openCreateGroupChat ? (
                        <CreateGroupChat handleClose={this.handleCloseCreateGroupChat} />
                    ) : (
                        <>
                            <div
                                className={classNames('chats-list-container', {isRtl: this.props.isRtlLanguage})}
                                data-number-chats={totalChats}
                            >
                                {!isMobile && <ChatsHeader handleCreateNewChat={this.handleCreateNewChat} />}
                                <ColoredScrollbars scrolltochatid={activeChatRoomId}>
                                    <ChatsList
                                        {...this.props}
                                        eventId={this.props.event._id}
                                        handleOpenGroupChat={this.handleOpenGroupChat}
                                    />
                                </ColoredScrollbars>
                            </div>
                            <div
                                className="opened-chat-container"
                                data-number-chats={
                                    this.props.user.groupChats.length + this.props.user.privateChats.length
                                }
                            >
                                {this.props.user.groupChats.length + this.props.user.privateChats.length === 0 ? (
                                    <NoChatsInfo />
                                ) : (
                                    <div className="opened-chat-wrapper">
                                        {!activeChatRoomId &&
                                            (this.props.maximizeChats.seeExpandedVideoConference || !isLargeScreen) && (
                                                <p className="no-active-chat-message">
                                                    {translation?.chatsDropdown.noOpenedChatText}
                                                </p>
                                            )}
                                        {activeChatRoomId && this.props.isGroupChat && (
                                            <GroupChat
                                                handleCreateNewChat={this.handleCreateNewChat}
                                                closeChats={this.closeComponent}
                                            />
                                        )}
                                        {activeChatRoomId && !this.props.isGroupChat && <PrivateChat />}
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default Chats;
