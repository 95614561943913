import React from 'react';
import {usePlatformTranslation} from '../../../../../../../../../services/hooks';

import './EmptyChat.scss';

const EmptyChat = () => {
    const translation = usePlatformTranslation();
    return (
        <div className="chat-wrapper empty-chat-conversion">
            <div>
                <p>
                    {translation?.chatsDropdown.noMessagesInChatTextFirst}
                    <br />
                    {translation?.chatsDropdown.noMessagesInChatTextSecond}
                </p>
            </div>
        </div>
    );
};

export default EmptyChat;
