import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
            backgroundColor: theme.palette.background.default,
        },
    },
    groupDateTitle: {
        width: '100%',
        fontWeight: '600',
        fontSize: 24,
        margin: '32px 0px 24px 24px',
        color: theme.palette.text.secondary,
        height: 60,
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            height: 'unset',
            margin: '10px',
            color: theme.palette.text.primary,
        },
    },
}));
