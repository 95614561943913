import React from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {DialogActions, DialogContent, FormControl} from '@material-ui/core';
import PropTypes from 'prop-types';
import {Controller, useForm} from 'react-hook-form';
import * as yup from 'yup';
import {CustomDialog, CustomDialogButton, CustomDialogContentText, CustomDialogTitle} from '../CustomDialogComponents';
import {CustomTextValidator} from '../CustomInputComponents';

const PASSWORD_MIN_SIZE = 8;
const PASSWORD_MIN_ERROR = `Minimum lenght is ${PASSWORD_MIN_SIZE} symbols`;

const schema = yup.object().shape({
    password: yup.string().min(PASSWORD_MIN_SIZE, PASSWORD_MIN_ERROR).required('New password is a required field'),
    passwordConfirmation: yup
        .string()
        .min(PASSWORD_MIN_SIZE, PASSWORD_MIN_ERROR)
        .oneOf([yup.ref('password')], 'Passwords must match')
        .required('Confirm password is a required field'),
});

export const ForceResetPassword = ({open, onUpdatePassword, isLargeScreen}) => {
    const {
        formState: {errors},
        handleSubmit,
        getValues,
        control,
    } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit',
    });

    const onSubmit = () => {
        const data = getValues();

        onUpdatePassword(data);
    };

    return (
        <CustomDialog fullScreen={!isLargeScreen} open={open} aria-labelledby="form-dialog-title">
            <CustomDialogTitle>Password reset</CustomDialogTitle>
            <DialogContent>
                <CustomDialogContentText>
                    To secure your account and continue using the system Please reset your password
                </CustomDialogContentText>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormControl fullWidth>
                        <Controller
                            control={control}
                            name="password"
                            render={({field}) => (
                                <CustomTextValidator
                                    isTextField
                                    {...field}
                                    label="New password"
                                    error={!!errors?.password}
                                    helperText={errors?.password?.message || ' '}
                                    variant="outlined"
                                    type="password"
                                    margin="normal"
                                />
                            )}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <Controller
                            control={control}
                            name="passwordConfirmation"
                            render={({field}) => (
                                <CustomTextValidator
                                    isTextField
                                    {...field}
                                    label="Confirm password"
                                    error={!!errors?.passwordConfirmation}
                                    helperText={errors?.passwordConfirmation?.message || ' '}
                                    variant="outlined"
                                    type="password"
                                    margin="normal"
                                />
                            )}
                        />
                    </FormControl>

                    <DialogActions>
                        <CustomDialogButton type="submit">RESET</CustomDialogButton>
                    </DialogActions>
                </form>
            </DialogContent>
        </CustomDialog>
    );
};

ForceResetPassword.propTypes = {
    open: PropTypes.bool,
    onUpdatePassword: PropTypes.func,
    isLargeScreen: PropTypes.bool,
};

ForceResetPassword.defaultProps = {
    open: false,
};
