import React from 'react';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import {useStyles} from './SocialMediaShareEvent.style';
import {closeIcon} from 'Images/pages/eventListing';
import SocialButtons from './components/SocialButtons';
import {socialShareLink} from 'constants/socialShareLink';
import PropTypes from 'prop-types';
import {usePlatformTranslation} from 'services/hooks';

function SocialMediaShareEvent({eventId, showSocialShareModal, onHideSocialShareModal, promotionData}) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const {socialMediaShare} = usePlatformTranslation();

    React.useEffect(() => {
        if (showSocialShareModal && (promotionData?.caption || promotionData?.image)) {
            setOpen(true);
            onHideSocialShareModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showSocialShareModal, promotionData]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    if (!eventId || (!promotionData?.caption && !promotionData?.image)) return null;

    const eventLink = socialShareLink({eventId});

    return (
        <>
            <IconButton
                className={classes.iconButton}
                onClick={handleOpen}
                aria-label="upload picture"
                component="span"
            >
                <ShareOutlinedIcon className={classes.shareIcon} />
            </IconButton>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div onClick={handleClose} className={classes.closeButton}>
                            <img src={closeIcon} alt={'closeIcon'} />
                        </div>
                        <h2 id="transition-modal-title">{socialMediaShare.shareItWithYourNetwork}</h2>
                        <div id="transition-modal-description" className={classes.socialButtonContainer}>
                            <SocialButtons
                                socialMediaShareTranslations={socialMediaShare}
                                link={eventLink}
                                handleClose={handleClose}
                            />
                        </div>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}

SocialMediaShareEvent.propTypes = {
    showSocialShareModal: PropTypes.bool,
    onHideSocialShareModal: PropTypes.func,
    eventId: PropTypes.string,
    promotionData: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
};

export default SocialMediaShareEvent;
