import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {AutoSizer, List, CellMeasurer, CellMeasurerCache, InfiniteLoader} from 'react-virtualized';
import classNames from 'classnames';
import {InputBase} from '@material-ui/core';
import PropTypes from 'prop-types';
import InfoIcon from '@material-ui/icons/Info';

import {AbilityContext} from 'permissionsConfig';

import Spinner from '../Spinner';
import SinglePost from './components/SinglePost';

import {ReactComponent as WallEmptyIcon} from 'Images/chat-default.svg';
import {ReactComponent as Plus} from 'Images/plus.svg';
import {
    postReceived,
    postDeleted,
    commentReceived,
    commentDeleted,
    postLikeReceived,
    postUnlikeReceived,
    postUnpinReceived,
    postPinReceived,
    commentApproved,
    postApproved,
} from 'Api/socketApi';
import {LIVE_WALL_SORT_METHODS, POST_STATUSES, WALL_TYPES} from 'constants/shared';
import {usePlatformTranslation} from 'services/hooks';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

import 'react-virtualized/styles.css';
import 'styles/wall.scss';
import './Wall.scss';

export const Wall = ({
    currentUser,
    event,
    isRtlLanguage,
    resourcesAccess,
    posts,
    total,
    wallType,
    isLoadingPosts,
    highlightPostsForUsers,
    isModerator,
    withModeration,
    toggleWall,
    activeWallId,
    classes,
    // actions
    onResetWallData,
    onResetActiveWallId,
    onAddWallPost,
    onAddWallPostComment,
    onGetWallPosts,
    onUpdatePostTrigger,
    onUpdateCommentTrigger,
    onDeletePostTrigger,
    onDeleteCommentsTrigger,
    onPinPostTrigger,
    onUnpinPostTrigger,
    onLikePostTrigger,
    onUnlikePostTrigger,
    onLikeWallPost,
    onUnlikeWallPost,
    onCloseActiveWall,
}) => {
    const [activeSortMethod, setActiveSortMethod] = useState(LIVE_WALL_SORT_METHODS.time);
    const [newPost, setNewPost] = useState('');
    const [currentPage, setCurrentPage] = useState(2);
    const translation = usePlatformTranslation();
    const currentActiveWallId = useRef();
    const listRef = useRef(null);
    const ability = useContext(AbilityContext);
    const [currentWallId, setCurrentWallId] = useState(null);
    const [cache, setCache] = useState();
    const abortController = useRef(null);

    useEffect(() => {
        setCache(
            new CellMeasurerCache({
                defaultHeight: 120,
                fixedWidth: true,
                minHeight: 100,
            })
        );
    }, [posts]);

    useEffect(() => {
        if (!activeWallId) return;
        const id = activeWallId;
        currentActiveWallId.current = id;

        setCurrentWallId(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeWallId]);

    useEffect(() => {
        return () => {
            currentActiveWallId.current = null;
            setCurrentWallId(null);
            onResetActiveWallId();
            onResetWallData();
            abortController.current && abortController.current.abort();
        };
    }, [onResetWallData, onResetActiveWallId]);

    useEffect(() => {
        abortController.current && abortController.current.abort();

        if (currentWallId) {
            abortController.current = new AbortController();
            onGetWallPosts({wallId: currentWallId, page: 1, signal: abortController.current.signal});
        }
        updateScroll(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWallId]);

    useEffect(() => {
        toggleWall(true);
    }, [event, currentPage, posts.length, toggleWall, isLoadingPosts, currentWallId]);

    const updateScroll = (scrollToTop) => {
        // Reset scroll position when user switch wall
        if (listRef.current) {
            setTimeout(() => {
                try {
                    if (scrollToTop) {
                        listRef.current.scrollToPosition(0);
                        listRef.current.recomputeRowHeights();
                    } else {
                        listRef.current.measureAllRows();
                    }
                } catch (error) {}
            }, 900);
        }
    };

    const updatedCommentsOnReceive = (data) => {
        if (
            !isModerator &&
            data?.comment?.status === POST_STATUSES.pending &&
            data?.comment?.user._id !== currentUser._id
        )
            return;

        onUpdateCommentTrigger(data);
        updateScroll();
    };

    const updatePostTrigger = (data) => {
        if (!isModerator && data?.post?.status === POST_STATUSES.pending && data?.post?.user._id !== currentUser._id)
            return;

        onUpdatePostTrigger(data);
        updateScroll();
    };

    useEffect(() => {
        postReceived((err, data) => {
            updatePostTrigger(data);
        });

        postApproved((err, data) => {
            updatePostTrigger(data);
        });

        postDeleted((err, data) => {
            onDeletePostTrigger(data);
            updateScroll();
        });

        commentReceived((err, data) => {
            updatedCommentsOnReceive(data);
        });

        commentApproved((err, data) => {
            updatedCommentsOnReceive(data);
        });

        commentDeleted((err, data) => {
            onDeleteCommentsTrigger(data);
            updateScroll();
        });

        postLikeReceived((err, data) => {
            onLikePostTrigger(data);
            updateScroll();
        });

        postPinReceived((err, data) => {
            onPinPostTrigger(data);
            updateScroll();
        });

        postUnpinReceived((err, data) => {
            onUnpinPostTrigger(data);
            updateScroll();
        });

        postUnlikeReceived((err, data) => {
            onUnlikePostTrigger(data, currentUser);
            updateScroll();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useLayoutEffect(() => {
        return () => {
            toggleWall(false);
            onCloseActiveWall();
        };
    }, [onCloseActiveWall, toggleWall]);

    const changeSortMethod = (sortMethod) => {
        setActiveSortMethod(sortMethod);
        setCurrentPage(1);
        onGetWallPosts({wallId: currentWallId, page: 1, sortBy: sortMethod});
    };

    const changeNewPost = (event) => {
        setNewPost(event.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            onAddPost();
        }
    };

    const onAddPost = () => {
        if (newPost.trim().length) {
            const callback = () => {
                setNewPost('');
                updateScroll(true);
            };

            onAddWallPost({wallId: currentWallId, post: newPost, callback});
        }
    };

    const addNewComment = (postId, comment) => {
        onAddWallPostComment({wallId: currentWallId, postId, comment});
    };

    const likePost = (postId) => {
        if (!currentUser) return;

        onLikeWallPost({wallId: currentWallId, postId});
    };

    const unlikePost = (postId) => {
        if (!currentUser) return;

        onUnlikeWallPost({wallId: currentWallId, postId});
    };

    const checkAccess = () => {
        if (!event.hasAccessManagement) return true;

        const accessToLiveWall =
            event.hasAccessManagement && resourcesAccess !== null ? resourcesAccess?.liveWall : true;
        const accessToVideoWall =
            event.hasAccessManagement && resourcesAccess !== null ? resourcesAccess?.videoWall : true;
        const accessToBoothWall =
            event.hasAccessManagement && resourcesAccess !== null ? resourcesAccess?.boothWall : true;

        switch (wallType) {
            case WALL_TYPES.liveWall:
                return accessToLiveWall;

            case WALL_TYPES.videoWall:
                return accessToVideoWall;

            case WALL_TYPES.boothWall:
                return accessToBoothWall;
            default:
                return false;
        }
    };

    const checkWallClosed = () => {
        const {closeLiveWall, closeVideoWall, closeBoothWall} = event;

        switch (wallType) {
            case WALL_TYPES.liveWall:
                return closeLiveWall;

            case WALL_TYPES.videoWall:
                return closeVideoWall;

            case WALL_TYPES.boothWall:
                return closeBoothWall;
            default:
                return false;
        }
    };

    const isOrganizerAccess = ability.can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.CLOSED_WALL);
    const hasAccess = isOrganizerAccess ? true : checkAccess();
    const isWallClosed = isOrganizerAccess ? false : checkWallClosed() || !hasAccess;

    const rowRenderer = ({
        key, // Unique key within array of rows
        index, // Index of row within collection
        parent,
        style,
    }) => {
        const post = posts[index];

        let allreadyLiked = false;
        let allreadyCommented = false;
        const hightlighPost = post.user && post.user._id && highlightPostsForUsers?.includes(post.user._id);
        if (post.likes && post.likes.length && currentUser) {
            allreadyLiked = post.likes.findIndex((like) => like.user._id === currentUser._id);
            allreadyLiked = allreadyLiked !== -1;
        }
        if (post.comments && post.comments.length && currentUser) {
            allreadyCommented = post.comments.findIndex((comment) => comment.user._id === currentUser._id);
            allreadyCommented = allreadyCommented !== -1;
        }

        return (
            <CellMeasurer cache={cache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
                {({measure}) => (
                    <div style={{...style}}>
                        <SinglePost
                            key={key}
                            style={style}
                            cache={cache}
                            measureVirtualListSize={measure}
                            rowIndex={index}
                            listRef={listRef?.current}
                            post={post}
                            type={wallType}
                            likePost={() => likePost(post._id)}
                            unlikePost={() => unlikePost(post._id)}
                            allreadyLiked={allreadyLiked}
                            allreadyCommented={allreadyCommented}
                            addComment={(comment) => addNewComment(post._id, comment)}
                            hightlighPost={hightlighPost}
                            wallClosed={isWallClosed}
                            isModerator={isModerator}
                            wallType={wallType}
                        />
                    </div>
                )}
            </CellMeasurer>
        );
    };

    if (isLoadingPosts && currentPage === 1) {
        return (
            <div className="wall-container">
                <div className="post-wrapper">
                    <div className="spinner-wrapper">
                        <Spinner />
                    </div>
                </div>
            </div>
        );
    }
    const loadMorePosts = () => {
        if (posts.length < total) {
            onGetWallPosts({wallId: currentWallId, page: currentPage, sortBy: activeSortMethod});
            setCurrentPage(currentPage + 1);
        }
    };

    const setListRowRef = (registerChild) => (list) => {
        listRef.current = list;
        registerChild(list);
    };

    return (
        <div className="wall-container">
            <div className={classNames('sort-container', {isRtl: isRtlLanguage})}>
                <span>{translation?.wall.sortBy}</span>
                <span
                    onClick={() => changeSortMethod(LIVE_WALL_SORT_METHODS.popularity)}
                    className={classNames('button', {
                        active: activeSortMethod === LIVE_WALL_SORT_METHODS.popularity,
                    })}
                >
                    {translation?.wall.popularity}
                </span>
                <span
                    onClick={() => changeSortMethod(LIVE_WALL_SORT_METHODS.time)}
                    className={classNames('button', {
                        active: activeSortMethod === LIVE_WALL_SORT_METHODS.time,
                    })}
                >
                    {translation?.wall.time}
                </span>
            </div>
            <div className="post-wrapper">
                {currentUser && (
                    <div className="add-new-post-container">
                        <div
                            className="user-avatar"
                            style={{
                                backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${
                                    currentUser ? currentUser.avatarSmall : ''
                                }')`,
                            }}
                        />
                        <InputBase
                            id="newPost"
                            multiline={true}
                            placeholder={translation?.wall.whatIsOnYourMind}
                            value={newPost}
                            onChange={changeNewPost}
                            onKeyDown={handleKeyDown}
                            disabled={isWallClosed}
                        />
                        <button className="add-new-post" onClick={onAddPost} disabled={!newPost.trim().length}>
                            <Plus className="primary-fill-svg" />
                        </button>
                    </div>
                )}

                <div className={classNames('custom-scroll-container', classes?.customScrollContainer)}>
                    {withModeration && (
                        <div className="wall" data-empty={!hasAccess || posts?.length === 0 ? 'true' : 'false'}>
                            <div className="wall__moderation-alert">
                                <InfoIcon className="wall__moderation-alert-icon" />
                                <p className="wall__moderation-alert-text">
                                    {translation?.wall?.postsAndCommentsOnThisWall}
                                </p>
                            </div>
                        </div>
                    )}
                    {hasAccess && !!posts?.length && (
                        <>
                            <InfiniteLoader
                                isRowLoaded={({index}) => !!posts[index]}
                                loadMoreRows={loadMorePosts}
                                rowCount={total}
                                minimumBatchSize={40}
                                threshold={30}
                            >
                                {({onRowsRendered, registerChild}) => (
                                    <AutoSizer>
                                        {({height, width}) => (
                                            <List
                                                className="wall-list"
                                                ref={setListRowRef(registerChild)}
                                                onRowsRendered={onRowsRendered}
                                                height={height - (withModeration ? 115 : 10)} //className wall has padding
                                                rowHeight={cache?.rowHeight || 120}
                                                deferredMeasurementCache={cache}
                                                rowCount={posts.length}
                                                rowRenderer={rowRenderer}
                                                width={width}
                                            />
                                        )}
                                    </AutoSizer>
                                )}
                            </InfiniteLoader>

                            {isLoadingPosts ? (
                                <div className={'absolute-spinner'}>
                                    <Spinner />
                                </div>
                            ) : null}
                        </>
                    )}
                    {(posts?.length === 0 || !hasAccess) && (
                        <div className="empty-wall">
                            <p>
                                {hasAccess
                                    ? translation?.wall.emptyWallText
                                    : "Your access package doesn't include access to the wall."}
                            </p>
                            <WallEmptyIcon className="primary-fill-svg" />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

Wall.propTypes = {
    currentUser: PropTypes.object,
    isModerator: PropTypes.bool,
    withModeration: PropTypes.bool,
    event: PropTypes.object,
    isRtlLanguage: PropTypes.bool,
    resourcesAccess: PropTypes.object,
    posts: PropTypes.arrayOf(PropTypes.object),
    wallType: PropTypes.string,
    isLoadingPosts: PropTypes.bool,
    highlightPostsForUsers: PropTypes.arrayOf(PropTypes.string),
    classes: PropTypes.shape({
        customScrollContainer: PropTypes.string,
    }),

    toggleWall: PropTypes.func,
    onGetWallPosts: PropTypes.func,
    onUpdateWallPosts: PropTypes.func,
    onUnlikeWallPost: PropTypes.func,
    onUpdatePostTrigger: PropTypes.func,
    onUpdateCommentTrigger: PropTypes.func,
    onDeletePostTrigger: PropTypes.func,
    onDeleteCommentsTrigger: PropTypes.func,
    onLikePostTrigger: PropTypes.func,
    onUnlikePostTrigger: PropTypes.func,
    onPinPostTrigger: PropTypes.func,
    onUnpinPostTrigger: PropTypes.func,
    onAddWallPost: PropTypes.func,
    onAddWallPostComment: PropTypes.func,
    onCloseActiveWall: PropTypes.func,
    onResetWallData: PropTypes.func,
};
