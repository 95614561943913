import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles({
    dialogTitleRoot: {
        padding: 24,
    },
    dialogTitle: {
        color: '#2B2B2B',
        fontWeight: '600',
        fontSize: '24px',
        fontFamily: 'Montserrat',
        margin: 0,
        letterSpacing: '0.005em',
    },
    contentText: {
        fontFamily: 'Montserrat',
        color: '#2b2b2b',
        fontSize: '18px',
        margin: 0,
    },
    dialogAction: {},
    button: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        color: '#2b2b2b',
        fontWeight: '700',
    },
    buttonContained: {
        color: '#fff',
    },
});
