import React, {useRef, useEffect} from 'react';
import {connect} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';

import {usePlatformTranslation} from '../../../../../../../services/hooks';
import BackIcon from '../../../../../../../Images/back.svg';

import './BackButton.scss';

const withTranslations =
    (Component) =>
    ({...props}) => {
        const t_backButton = usePlatformTranslation((translation) => translation.eventMenu.backButton);
        return <Component t_backButton={t_backButton} {...props} />;
    };

const BackButton = ({t_backButton}) => {
    const history = useHistory();
    const location = useLocation();
    const redirectDataRef = useRef(null);

    useEffect(() => {
        if (location.state) redirectDataRef.current = location.state;
    }, [location.state]);

    const handleClick = () => {
        if (redirectDataRef.current?.prevLocation) {
            return history.push({
                pathname: redirectDataRef.current.prevLocation,
                state: redirectDataRef.current,
            });
        }
        history.goBack();
    };

    return (
        <button onClick={handleClick} className="backButton">
            <img src={BackIcon} width="24" height="24" alt="Back" />
            <p>{t_backButton}</p>
        </button>
    );
};

export default withTranslations(
    connect(
        (state) => ({
            eventSlug: state.event.eventSlug,
            buildingId: state.building.buildingId,
        }),
        null
    )(BackButton)
);
