import {DialogContentText, withStyles} from '@material-ui/core';

const CustomDialogContentText = withStyles((theme) => ({
    root: {
        fontFamily: 'Montserrat',
        color: theme.palette.text.primary,
        fontSize: '18px',
    },
}))(DialogContentText);

export default CustomDialogContentText;
