import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {useInterpretator} from '../StreamingVideoContainer/StreamingVideoContainer.hooks';
import HlsVideoPlayer from '../AuditoriumVideoPlayer/components/HlsVideoPlayer';

const VideojsPlayer = ({sourceLink, settings, interpretationSourceLanguage, withInterpretation, className, muted}) => {
    const videoRef = useRef(null);

    const setInterpretationMediaStream = useInterpretator(
        videoRef.current,
        interpretationSourceLanguage,
        withInterpretation
    );

    React.useEffect(() => {
        const videoElement = videoRef.current;

        if (!videoElement) {
            return;
        }

        videoElement.onplay = () => {
            try {
                if (!videoElement.captureStream || !withInterpretation) return;
                const stream = videoElement.captureStream();
                stream.onactive = (mediaStreamEvent) => {
                    const audioTracks = mediaStreamEvent.currentTarget.getAudioTracks();
                    if (audioTracks.length) setInterpretationMediaStream(mediaStreamEvent.currentTarget);
                };
            } catch (e) {
                console.error('videoElement captureStream ERROR', e);
            }
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <HlsVideoPlayer
            width="100%"
            height="100%"
            muted={muted}
            className={classNames('video-js vjs-big-play-centered', {[className]: className})}
            autoPlay
            playerRef={videoRef}
            src={sourceLink}
        />
    );
};

VideojsPlayer.propTypes = {
    settings: PropTypes.object,
    interpretationSourceLanguage: PropTypes.string,
    withInterpretation: PropTypes.bool,
    className: PropTypes.string,
    muted: PropTypes.bool,
};

export default VideojsPlayer;
