import PrivateChat from './PrivateChat';
import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export default connect(
    (state) => ({
        user: selectors.getUserData(state),
        activeChatRoomId: selectors.getUserActiveChatRoomId(state),
        activeChatData: selectors.getUserActiveChatData(state),
        maximizeChats: selectors.getUserMaximizeChats(state),
        isMobile: selectors.getIsMobile(state),
        isLargeScreen: selectors.getIsLargeScreen(state),
        videoConference: selectors.getActiveCallTypeData(state),
        languages: selectors.getLanguages(state),
        isRtlLanguage: selectors.getIsRtlLanguage(state),
        incomingOneToOneCallUserId: selectors.getIncomingCallUserId(state),
        incomingCallOffer: selectors.getIncomingCallOffer(state),
    }),
    {
        onSetActiveChatData: actions.setActiveChatData,
        onUpdateActiveChatData: actions.updateActiveChatData,
        newPrivateChatMessage: actions.newPrivateChatMessage,
        readMessagesForPrivateChat: actions.readMessagesForPrivateChat,
        seeExpandedVideoConference: actions.expandedChatsOnVideoConference,
        onOpenWallet: actions.openWallet,
        openVideoRoom: actions.openVideoRoom,
        closeVideoRoom: actions.closeVideoRoom,
        setActiveVideoConferenceType: actions.setActiveVideoConferenceType,
        endOneToOneCall: actions.endOneToOneCall,
        startCall: actions.startCall,
        getPrivateChatMessages: actions.getPrivateChatMessages,
    }
)(PrivateChat);
