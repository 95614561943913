import {connect} from 'react-redux';
import * as selectors from 'store/selectors';
import AuditoriumWall from './AuditoriumWall';

export default connect(
    (state) => ({
        onDemandVideos: selectors.getOnDemandVideos(state),
        event: selectors.getEventData(state),
    }),
    null
)(AuditoriumWall);
