import React from 'react';
import {makeStyles} from '@material-ui/core';
import LinearScale from '../LinearScaleField/LinearScaleField';
import RadioGroup from '../RadioGroup/RadioGroup';
import Select from '../Select/Select';
import TextInput from '../TextInput/TextInput';
import {useSelector} from 'react-redux';
import classNames from 'classnames';

const TEXT_TYPE = 'text';
const SELECT_TYPE = 'select';
const RADIO_BUTTON_TYPE = 'radio';
const LINEAR_SCALE_TYPE = 'linear-scale';

const useStyles = makeStyles({
    root: {
        padding: '16px 0',
        borderBottom: '1px solid #CACBD0',
    },
    title: {
        fontSize: 16,
        fontWeight: 600,
        margin: 0,
        marginBottom: 24,
        textAlign: 'left',
        '&.isRtl': {
            textAlign: 'right',
        },
    },
});

const SurveyQuestion = (props) => {
    const {fieldData} = props;
    const {question} = fieldData;
    const classes = useStyles();
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);

    return (
        <div className={classes.root}>
            <h3 className={classNames(classes.title, {isRtl: isRtlLanguage})}>{question}</h3>

            {fieldData.questionType === TEXT_TYPE && <TextInput {...props} />}
            {fieldData.questionType === RADIO_BUTTON_TYPE && <RadioGroup {...props} />}
            {fieldData.questionType === LINEAR_SCALE_TYPE && <LinearScale {...props} />}
            {fieldData.questionType === SELECT_TYPE && <Select {...props} />}
        </div>
    );
};

export default SurveyQuestion;
