import * as meetingSetupActionTypes from '../actionTypes/meetingSetupActionTypes';
import {updateObject} from '../utilityStateFunction';
import {cloneDeep} from 'lodash';

const initialState = {
    isOpened: false,
    user: {},
};

const reducer = (state = cloneDeep(initialState), action) => {
    switch (action.type) {
        case meetingSetupActionTypes.TOGGLE_MEETING_SETUP:
            return updateObject(state, {isOpened: action.payload});
        case meetingSetupActionTypes.SET_MEETING_SETUP_USER:
            return updateObject(state, {user: action.payload});
        case meetingSetupActionTypes.RESET_MEETING_SETUP_STATE:
            return cloneDeep(initialState);

        default:
            return state;
    }
};

export default reducer;
