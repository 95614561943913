import React from 'react';

import MobileBusinessCardSetupPanel from '../MobileBusinessCardSetupPanel/MobileBusinessCardSetupPanel';
import BusinessCardColors from '../../../../../BusinessCardColors/BusinessCardColors';

import {usePlatformTranslation} from '../../../../../../../../../../../../../services/hooks';

const MobileBusinessCardColors = () => {
    const {businessCard: businessCardTranslation} = usePlatformTranslation();

    return (
        <MobileBusinessCardSetupPanel title={businessCardTranslation.colors}>
            <BusinessCardColors />
        </MobileBusinessCardSetupPanel>
    );
};

export default MobileBusinessCardColors;
