import * as actionTypes from '../../../actionTypes/eventActionTypes';
import {updateObject} from '../../../utilityStateFunction';
import {initialState} from '../initialState';

const editedBuilding = (state, {type, payload}) => {
    if (state === undefined) {
        return initialState.editBuilding;
    }
    switch (type) {
        case actionTypes.SET_EDITED_BUILDING:
            return updateObject(state.editBuilding, {
                editedBuilding: payload,
            });
        case actionTypes.SET_EDITED_BUILDING_ID:
            return updateObject(state.editBuilding, {
                editedBuildingId: payload,
            });
        case actionTypes.SET_EDITED_BUILDING_TAB:
            return updateObject(state.editBuilding, {
                editedBuildingTab: payload,
            });
        default:
            return state.editBuilding;
    }
};

export default editedBuilding;
