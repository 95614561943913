import React from 'react';

import './ScaArabicContent.scss';

export const ScaArabicContent = () => (
    <div className="header-spacing-container" dir="rtl">
        <div className="gdpr-container">
            <h1>الشروط و الأحكام</h1>
            <p>
                تحكم هذه الشروط والأحكام (التي يُشار إليها لاحقًا باسم "الشروط") وصولك واستخدامك لمنصة الفعاليات
                الافتراضية للهيئة السعودية للمقاولين (التي يُشار إليها لاحقًا باسم "المنصة") التي تنظمها الهيئة السعودية
                للمقاولين. دخولك إلى المنصة واستخدامها مشروط بقبولك وامتثالك لهذه الشروط. من خلال الوصول إلى المنصة أو
                استخدامها، فإنك توافق على الامتثال لهذه الشروط والالتزام بها.
            </p>
            <h2>1. الشروط الأساسية</h2>
            <ol>
                <li>
                    <p>
                        عن طريق الدعوة، تقدم الهيئة السعودية للمقاولين للمستخدمين المسجلين حصريًا الذين يبلغون السن
                        القانوني فرصة المشاركة في الفعاليات الافتراضية على المنصة. يخضع وقت ومدة هذه الفعاليات لتقدير
                        الهيئة السعودية للمقاولين وحدها. ولا يجوز لك استخدام المنصة إلا إذا كنت قادرًا على إبرام عقد
                        ملزم مع الهيئة السعودية للمقاولين. إذا كنت توافق على هذه الشروط وتستخدم المنصة نيابة عن شركة أو
                        أي كيان قانوني آخر، تقر وتضمن أنك مخول للقيام بذلك.
                    </p>
                </li>
                <li>
                    <p>
                        ي استخدام للمحتوى أو الخدمات يتجاوز ما هو معروض صراحة على المنصة يتطلب الموافقة المسبقة من
                        الهيئة السعودية للمقاولين.
                    </p>
                </li>
                <li>
                    <p>
                        عند التسجيل في المنصة، يتم منحك ترخيصًا غير حصري وغير قابل للتحويل لاستخدام البرامج المضمنة في
                        المنصة ("الترخيص"). يتم ترخيص البرنامج وأي واجهات ومحتوى وخطوط، ولم يتم بيعها لك من قبل الهيئة
                        السعودية للمقاولين ، للاستخدام فقط بموجب شروط هذا الترخيص. تحتفظ شركة إيفينتس 10 إكس المحدودة
                        والمرخصون التابعون لها بملكية برمجيات المنصة نفسها وتحتفظ بجميع الحقوق غير الممنوحة لك صراحةً.
                        ستحكم شروط هذا الترخيص، أي تحديثات برامج تقدمها شركة إيفينتس 10 إكس المحدودة.
                    </p>
                </li>
                <li>
                    <p>
                        عند التسجيل، فإنك تمنح الهيئة السعودية للمقاولين ترخيصًا عالميًا غير حصري وقابل للتحويل وقابل
                        للترخيص من الباطن لاستخدام المعلومات والمحتوى ومعالجتهما وتعديلهما ونسخهما ونشرهما وتوزيعهما
                        ومعالجتهما (الرسائل ومنشورات الحائط ورسائل الدردشة والعلامات التجارية والأعمال المحمية بحقوق
                        الطبع والنشر) التي تقدمها من خلال المنصة. يُمنح هذا الحق لنا دون أي موافقة أو تعويض أو دفعة أو
                        إشعار آخر، ويقتصر على ما يلي:
                    </p>
                    <ul>
                        <li>
                            <p>
                                قد يتم حذف هذا الترخيص عن طريق حذف محتوى معين من المنصة أو عن طريق الإغلاق أو الحساب مع
                                المنصة، باستثناء الحالات عندما (أ) تمت مشاركة المحتوى مع مستخدمين آخرين للمنصة وقاموا
                                بنسخه أو تخزينه أو مشاركته و (ب) يستغرق وقتًا معقولاً لإزالة النسخة الاحتياطية من
                                المنصة.
                            </p>
                        </li>
                        <li>
                            <p>
                                لن تدرج الهيئة السعودية للمقاولين المحتوى الخاص بك في إعلانات لخدمات أو بضائع الطرف
                                الثالث. ومع ذلك، منحت الهيئة السعودية للمقاولين الحق على وجه التحديد، دون الدفع لك أو
                                للآخرين، لتقديم أو نشر إعلانات بالقرب من المحتوى والمعلومات الخاصة بك، وقد تكون بيانات
                                ملفك الشخصي مرئية ومدرجة مع الإعلانات، كما هو مذكور في سياسة الخصوصية.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        تكون مسؤولًا عن استخدامك للمنصة وعن أي محتوى تنشره وأي عواقب تترتب على ذلك. توزع الهيئة السعودية
                        للمقاولين محتوى من إنشاء المستخدمين على مستخدمين آخرين فقط إذا كان هذا المحتوى لا ينتهك القانون
                        أو هذه الشروط. تحتفظ الهيئة السعودية للمقاولين بالحق في إزالة المحتوى الذي أنشأه المستخدمون دون
                        إشعار مسبق إذا كان هذا المحتوى ينتهك القانون أو هذه الشروط.
                    </p>
                </li>
                <li>
                    <p>
                        تقدم الهيئة السعودية للمقاولين الوصول إلى فعالية افتراضية بدون ضمانات فيما يتعلق بإبرام عقد عمل.
                        إذا أبرمت عقدًا من خلال المنصة، فلن تشارك الهيئة السعودية للمقاولين في هذا العقد، وبالتالي فهي
                        ليست شريكًا في هذا العقد. تكون مسؤولًا مسؤولية كاملة عن أي عقد تبرمه من خلال المنصة؛ لا يمكن
                        تحميل الهيئة السعودية للمقاولين المسؤولية عن أي خرق للعقد.
                    </p>
                </li>
            </ol>

            <h2>2. التسجيل</h2>
            <ol>
                <li>
                    <p>
                        يلزم التسجيل قبل استخدام المنصة. لا يمكنك التسجيل إلا إذا تمت دعوتك من قبل الهيئة السعودية
                        للمقاولين.
                    </p>
                </li>
                <li>
                    <p>
                        تتعهد وتقر بأن جميع المعلومات المقدمة عند التسجيل دقيقة وكاملة، وأنك تبلغ السن القانوني. خلال
                        فترة المشاركة النشطة في الفعالية الافتراضية، أنت ملزم بالإعلان عن أي تغيير في المعلومات المقدمة
                        عند التسجيل في الهيئة السعودية للمقاولين دون تأخير لا داعي له. يمكن للمستخدمين التسجيل مرة واحدة
                        فقط.
                    </p>
                </li>
                <li>
                    <p>
                        من خلال استكمال عملية التسجيل، فإنك تقدم عرضًا لعقد يتعلق باستخدام المنصة. تقبل الهيئة السعودية
                        للمقاولين هذا العرض عن طريق تنشيط حساب المستخدم للمشاركة في الفعالية الافتراضية. من خلال هذا
                        القبول، يبرم كل من الطرفين عقدًا.
                    </p>
                </li>
                <li>
                    <p>
                        عند التسجيل، يجب عليك إدخال كلمة مرور. تكون مسؤولًا عن حماية كلمة المرور هذه، وتوافق على عدم
                        الإفصاح عن كلمة المرور الخاصة بك إلي طرف ثالث. لن تفصح الهيئة السعودية للمقاولين عن كلمة المرور
                        إلي طرف ثالث ولن تطلب الهيئة السعودية للمقاولين كلمة مرور المستخدم في أي وقت. يجب عليك إخطار
                        الهيئة السعودية للمقاولين على الفور إذا علمت بخرق للأمان أو استخدام غير مصرح به لكلمة المرور
                        الخاصة بك.
                    </p>
                </li>
                <li>
                    <p>
                        إذا قمت بإنشاء حساب عبر وسائل التواصل الاجتماعي (فيسبوك أو تويتر أو جوجل أو لينكد إن)، فسيتعين
                        عليك التصريح لنا بالوصول إلى تسجيل الدخول المطلوب إلى وسائل التواصل الاجتماعي. بمنح حق الوصول
                        إلى حسابك على وسائل التواصل الاجتماعية، فإنك توافق على وصولنا وتخزيننا لبعض المعلومات فيما يتعلق
                        بك كما هو موضح في سياسة الخصوصية الخاصة بنا.{' '}
                    </p>
                </li>
            </ol>

            <h2>3. حقوق ومسؤوليات المستخدم</h2>
            <ol>
                <li>
                    <p>
                        يطلب منك تقديم معلومات دقيقة وكاملة وغير مضللة في ملف التعريف الخاص بك وفي أي اتصال مع مستخدمين
                        آخرين. لا يسمح لك باستخدام اسم مستعار أو اسم غير حقيقي.
                    </p>
                </li>
                <li>
                    <p>
                        عند استخدام المنصة، يجب عليك الامتثال لجميع التشريعات القابلة للتطبيق، واحترام جميع حقوق الأطراف
                        الثالثة. على وجه الخصوص، يحظر عليك:
                    </p>
                    <ol className="alpha-list">
                        <li>
                            <p>نشر محتوى مسيء أو تشهيري موجه إلى مستخدمين آخرين أو كيانات أخرى،</p>
                        </li>
                        <li>
                            <p>
                                استخدام أو ترويج أو توزيع أي مواد إباحية أو أي محتوى ينتهك أي تشريع معمول به لحماية
                                القصر،
                            </p>
                        </li>
                        <li>
                            <p>
                                استخدام أو تطوير الإجراءات المناهضة للمنافسة، بما في ذلك استطلاع رأي الجمهور التدريجي
                                (مثل مخططات السلسلة أو الهرمية)،
                            </p>
                        </li>
                        <li>
                            <p>
                                استخدام دون إذن أي محتوى محمي بموجب القانون (على سبيل المثال بموجب حقوق الطبع والنشر أو
                                العلامة التجارية أو براءة الاختراع أو براءة اختراع المنفعة أو قوانين براءات الاختراع
                                التصميمية) أو الإعلان أو الترويج أو توزيع أي بضائع أو خدمات محمية بموجب القانون،
                            </p>
                        </li>
                        <li>
                            <p>مزعج بشكل غير معقول (خاصة مع البريد المزعج) لأي مستخدم آخر،</p>
                        </li>
                        <li>
                            <p>القيام بالأنشطة التالية، حتى لو لم تكن ضد أي قانون:</p>
                            <ul>
                                <li>
                                    <p>الاتصال الجنسي الصريح أو الضمني،</p>
                                </li>
                                <li>
                                    <p>
                                        استخدام الآليات أو البرامج النصية أو البرامج بالاشتراك مع المنصة، ما لم تسمح
                                        الهيئة السعودية للمقاولين صراحةً بذلك،{' '}
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        الإجراءات التي قد تضعف البنية التحتية، لا سيما الإجراءات التي قد تفرط في تحميل
                                        البنية التحتية المذكورة، أو الإجراءات التي قد تضعف المنصة بأي طريقة أخرى.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                رفع إلى المنصة أي ملف يحتوي على أو يعيد التوجيه إلى فيروس أو دودة أو حصان طروادة أو أي
                                تقنية أو مكون ضار آخر يقوم بالوصول بشكل غير قانوني أو يقوم بتحميل المحتوى أو المعلومات
                                المخزنة داخل المنصة أو على أجهزة شركة إيفينتس 10 إكس المحدودة أو الشركة التابعة أو الطرف
                                الثالث.
                            </p>
                        </li>
                        <li>
                            <p>
                                فك أو التدخل أو القرصنة أو الهندسة العكسية أو التفكيك أو التعديل أو النسخ أو تعطيل وظائف
                                المنصة أو تكاملها أو ميزاتها أو أداءها.
                            </p>
                        </li>
                        <li>
                            <p>
                                الوصول إلى المنصة بقصد و / أو من أجل إنشاء تطبيق أو برنامج مشابه أو مماثل أو نسخ أي
                                ميزات أو رسومات أو أفكار أو صور أو مقاطع فيديو أو ملكية فكرية أو وظائف للخدمة.
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        يجوز لك الإبلاغ عن أي أنشطة لأي مستخدم آخر تنتهك القوانين القابلة للتطبيق و / أو أي من هذه
                        الشروط.
                    </p>
                </li>
            </ol>

            <h2>4. حقوق ومسؤوليات شركة إيفينتس 10 إكس المحدودة</h2>
            <ol>
                <li>
                    <p>
                        تكون جميع الحقوق والملكية والمصالح في المنصة (باستثناء المحتوى المقدم من قبل المستخدمين) وستظل
                        ملكية حصرية لشركة إيفينتس 10 إكس المحدودة و / أو مورديها. المنصة محمية بموجب حقوق الطبع والنشر
                        والعلامات التجارية والقوانين الأخرى. لا يوجد في الشروط أي شيء يمنحك الحق في استخدام اسم الهيئة
                        السعودية للمقاولين أو أي من علاماتها التجارية وشعاراتها وأسماء النطاق وميزات العلامة التجارية
                        المميزة الأخرى الخاصة بها.
                    </p>
                </li>
                <li>
                    <p>
                        يحق للهيئة السعودية للمقاولين ومورديها اتخاذ الإجراءات التالية في أي وقت إذا كان هناك دليل ملموس
                        على أن المستخدم يخالف القوانين واللوائح أو حقوق الأطراف الثالثة أو هذه الشروط، أو إذا كان لدى
                        الهيئة السعودية للمقاولين مصالح مشروعة أخرى:
                    </p>
                    <ol className="alpha-list">
                        <li>
                            <p>حذف محتوى أنشأه مستخدم.</p>
                        </li>
                        <li>
                            <p>تقييد و / أو منع الوصول إلى المنصة.</p>
                        </li>
                    </ol>
                </li>
            </ol>

            <h2>5. التغييرات على المنصة</h2>
            <p>
                تحتفظ الهيئة السعودية للمقاولين بالحق في تعديل الخدمات المقدمة على المنصة في أي وقت و / أو الحق في تقديم
                خدمات مختلفة عن تلك المقدمة في وقت تسجيل المستخدم.
            </p>

            <h2>6. روابط الطرف الثالث</h2>
            <p>
                قد تحتوي المنصة على روابط لخدمات وإعلانات الطرف الثالث (رابط طرف ثالث) لأطراف ثالثة ليست تحت سيطرة
                الهيئة السعودية للمقاولين. عند النقر فوق رابط طرف ثالث، لن تكون الهيئة السعودية للمقاولين مسؤولة عن
                تحذيرك بأنك غادرت المنصة. تقر وتقبل وتوافق على أنه (1) عند النقر على رابط الطرف الثالث، ستخضع حينئذٍ
                لشروط وسياسات الطرف الثالث؛ (2) روابط الطرف الثالث المقدمة فقط للتسهيل ولا تقوم الهيئة السعودية
                للمقاولين بمراجعة أو اعتماد أو مراقبة أو تأييد أو ضمان أو تقديم أي تعهدات؛ (3) تستخدم روابط طرف ثالث على
                مسؤوليتك الخاصة.
            </p>

            <h2>7. التواصل مع الآخرين</h2>
            <p>
                تتيح المنصة المراسلة وتبادل المعلومات بعدة طرق، مثل مشاركة الروابط أو إنشاء منصة معرض الشركة. قد يرى
                مستخدمون آخرون المعلومات والمحتوى الذي تشاركه.{' '}
            </p>

            <h2>8. خرق حقوق التأليف والنشر</h2>
            <p>
                إذا كنت مالكًا لحقوق الطبع والنشر، أو مخولًا بالتصرف نيابةً عن أحدهم، أو مخولًا بالتصرف بموجب أي حق حصري
                بموجب حقوق الطبع والنشر، فيرجى الإبلاغ عن انتهاكات حقوق الطبع والنشر المزعومة التي تحدث على المنصة أو من
                خلالها من خلال استكمال إشعار انتهاك حقوق النشر وتسليمه إلى الهيئة السعودية للمقاولين. عند استلام الإشعار
                على النحو الموضح أدناه، ستتخذ الهيئة السعودية للمقاولين أي إجراء تراه مناسبًا، وفقًا لتقديرها الوحيد،
                بما في ذلك إزالة المواد المعترض عليها من المنصة.
            </p>

            <h2>9. إنهاء العقد</h2>
            <p>
                سيستمر تطبيق هذه الشروط حتى يتم إنهاء العقد المبرم من قبلك أو من قبل الهيئة السعودية للمقاولين على النحو
                التالي.
            </p>
            <ol>
                <li>
                    <p>
                        يجوز لك إنهاء العقد مع الهيئة السعودية للمقاولين في أي وقت دون سبب. لإنهاء العقد، يجب عليك تسليم
                        إشعار إنهاء إلى الهيئة السعودية للمقاولين يتضمن اسمك المسجل وعنوان بريدك الإلكتروني الذي سجلت
                        به.
                    </p>
                </li>
                <li>
                    <p>
                        يجوز للهيئة السعودية للمقاولين إنهاء العقد في أي وقت لسبب وجيه. يتم تعريف السبب الوجيه على أنه
                        حدث يجعل من غير المقبول للهيئة السعودية للمقاولين الاستمرار في الاتفاقية حتى نهاية فترة الإنهاء،
                        بعد مراعاة الظروف الفردية للمستخدم والموازنة بين مصالح الهيئة السعودية للمقاولين مقابل مصالح
                        المستخدم. قد يشمل السبب الوجيه أيًا من الأحداث التالية:{' '}
                    </p>
                    <ol className="alpha-list">
                        <li>
                            <p>إذا أخفق المستخدم في الامتثال لأي أحكام قانونية قابلة للتطبيق،</p>
                        </li>
                        <li>
                            <p>
                                إذا خرق المستخدم التزامًا تعاقديًا، لا سيما الالتزام المنصوص عليه في البندين 2 و 3 من
                                هذه الشروط،
                            </p>
                        </li>
                        <li>
                            <p>
                                إذا تعرضت سمعة الخدمات المقدمة على المنصة لضرر بصورة جوهرية بسبب وجود المستخدم عبر
                                الإنترنت (على سبيل المثال، إذا تم اكتشافه بعد التسجيل أن المستخدم قد أدين بارتكاب جريمة
                                جنائية، وإذا كانت الإدانة المذكورة معروفة للمستخدمين الآخرين،)
                            </p>
                        </li>
                        <li>
                            <p>
                                ي حالة ترويج المستخدم لأية مجتمعات أو جمعيات (أو أي من أساليبها أو أنشطتها) التي تخضع
                                للمراقبة من قبل السلطات المسؤولة عن السلامة العامة أو حماية القصر.
                            </p>
                        </li>
                        <li>
                            <p>إذا تسبب المستخدم في ضرر لأي مستخدم آخر (مستخدمين آخرين).</p>
                        </li>
                    </ol>
                </li>
            </ol>

            <h2>10. المنصة متاحة "كما هي"</h2>
            <ul>
                <li>
                    <p>
                        دخولك واستخدامك للمنصة أو أي محتوى مستخدم يكون على مسؤوليتك الخاصة. تدرك وتوافق على أن الوصول
                        إلى المنصة واستخدامها متاح لك على أساس "كما هي" و "حسب توفرها". تسعى شركة إيفينتس 10 إكس
                        المحدودة جاهدة لتحقيق أعلى مستوى من توافر المنصة قدر الإمكان تقنيًا. ومع ذلك، قد تؤدي بعض
                        المشكلات مثل الصيانة أو الأمان أو السعة أو شبكات الاتصالات العامة أو انقطاع التيار الكهربائي أو
                        عيوب البرامج والأجهزة إلى تعطل قصير في توفر المنصة أو أجزاء منها. توافق على عدم تقديم أي مطالبة
                        تتعلق بتوفر المنصة.
                    </p>
                </li>
                <li>
                    <p>
                        إلى أقصى حد يسمح به القانون القابل للتطبيق، توفر الهيئة السعودية للمقاولين الوصول إلى المنصة
                        واستخدامها دون ضمانات من أي نوع فيما تتعلق بأي بيانات و / أو معلومات مقدمة أو متاحة من قبل أي
                        مستخدم على المنصة أو على أي مواقع خارجية مرتبطة بداخل المنصة. على وجه الخصوص، لا تضمن الهيئة
                        السعودية للمقاولين أو تقر أن البيانات و / أو المعلومات المذكورة صحيحة أو دقيقة، أو أنها تفي أو
                        تخدم أي غرض معين. لا يمكن تحميل الهيئة السعودية للمقاولين المسؤولية عن أي معلومات قد تعتمد عليها
                        أثناء استخدام المنصة.
                    </p>
                </li>
            </ul>

            <h2>11. تحديد المسؤولية</h2>
            <ul>
                <li>
                    <p>
                        إلى الحد الأقصى الذي يسمح به القانون القابل للتطبيق، لن تكون الهيئة السعودية للمقاولين مسؤولة عن
                        أي أضرار غير مباشرة أو عرضية أو خاصة أو تبعية أو تأديبية، أو أي خسائر في الأرباح أو العوائد،
                        سواء كانت غير مباشرة أو غير مباشرة، بحسن نية، أو خسائر أخرى غير محتملة، ناتجة عن وصولك إلى
                        المنصة.
                    </p>
                </li>
                <li>
                    <p>
                        مهما كانت الأسس القانونية، ستظل المسؤولية عن مطالبات التعويض عن الضرر التي تستند فقط إلى الإهمال
                        العادي ضد الهيئة السعودية للمقاولين (بما في ذلك وكلاؤها بالنيابة) موجودة فقط إذا خرقت الهيئة
                        السعودية للمقاولين التزامًا أساسياً / جوهريًا بموجب هذه الشروط. الالتزام الجوهري هو التزام يمكن
                        أن يتوقع المستخدم الوفاء به ، والذي يعد الوفاء به شرطًا مسبقًا للتنفيذ العادي للعقد. في حالة
                        حدوث هذا الخرق، يقتصر مبلغ المطالبات على التعويضات عن الأضرار النموذجية والمتوقعة.
                    </p>
                </li>
                <li>
                    <p>
                        لن يتم تطبيق الحدود بقدر أنه يتم تغطية التعويضات عن الأضرار من خلال تأمين المسؤولية التجارية
                        للهيئة السعودية للمقاولين ، بشرط أنه قد أجرت شركة التأمين الدفع إلى الهيئة السعودية للمقاولين.
                        وستحافظ الهيئة السعودية للمقاولين على التغطية التأمينية الموجودة في وقت إبرام هذا العقد.
                    </p>
                </li>
                <li>
                    <p>
                        لا تنطبق استثناءات وقيود المسؤولية المذكورة أعلاه في حالة افتراض ضمانات صريحة من قبل الهيئة
                        السعودية للمقاولين أو وكلائها بالنيابة، أو في حالة عدم توفر الميزات المتعهد بها.
                    </p>
                </li>
            </ul>

            <h2>12. التعويض</h2>
            <ol>
                <li>
                    <p>
                        يجب عليك تعويض الهيئة السعودية للمقاولين وإعفائها من جميع الإجراءات، بما في ذلك مطالبات التعويض
                        عن الضرر، التي أكدها المستخدمون الآخرون أو الأطراف الثالثة ضد الهيئة السعودية للمقاولين الناتجة
                        عن انتهاك حقوقهم من خلال المحتوى الذي نشرته على المنصة أو الطريقة التي استخدمت بها خدمات المنصة.
                        أنت تتحمل جميع التكاليف المعقولة التي تتكبدها الهيئة السعودية للمقاولين بسبب انتهاك حقوق الطرف
                        الثالث، بما في ذلك جميع تكاليف الدفاع القانوني المعقولة. لن تتأثر بموجب هذا جميع الحقوق الأخرى،
                        بما في ذلك مطالبات التعويض عن الضرر من قبل الهيئة السعودية للمقاولين ،. تتمتع بالحق في إثبات أن
                        الهيئة السعودية للمقاولين تكبدت رسومًا أقل من المطالبات المقدمة.
                    </p>
                </li>
                <li>
                    <p>لا تنطبق الالتزامات المذكورة أعلاه بقدر أنك لا تتحمل المسؤولية عن الانتهاك.</p>
                </li>
                <li>
                    <p>
                        في حالة انتهاك المحتوى الذي نشرته لأي حقوق لأي طرف ثالث، يجب عليك على نفقتك الخاصة ووفقًا لتقدير
                        الهيئة السعودية للمقاولين إما الحصول على الحق في استخدام المحتوى المذكور أو جعل المحتوى المذكور
                        خاليًا من أي انتهاك. في حالة انتهاكك لحقوق الطرف الثالث عند استخدام المنصة، يجب عليك التوقف عن
                        هذا الاستخدام إذا طلبت الهيئة السعودية للمقاولين القيام بذلك.
                    </p>
                </li>
            </ol>

            <h2>13. حماية البيانات</h2>
            <p>
                تقر الهيئة السعودية للمقاولين أن أي بيانات تقدمها على المنصة تعتبر مهمة للغاية بالنسبة لك، وبالتالي يجب
                أن تكون الهيئة السعودية للمقاولين حساسة بشكل خاص في التعامل مع هذه البيانات. يجب على الهيئة السعودية
                للمقاولين الامتثال لجميع الأحكام القانونية القابلة للتطبيق فيما يتعلق بحماية البيانات (وفقًا لتوجيهات
                حماية البيانات الأوروبية وأي تشريعات أخرى قابلة للتطبيق لحماية البيانات). ترد تفاصيل معالجة بياناتك في
                سياسة حماية البيانات المنفصلة.
            </p>

            <h2>14. الخصوصية</h2>
            <p>
                تخضع أي معلومات تقدمها إلى الهيئة السعودية للمقاولين لسياسة الخصوصية المنفصلة الخاصة بنا، والتي تحكم
                جمعنا لمعلوماتك واستخدامها. أنت تدرك أنه من خلال استخدامك للمنصة، فإنك توافق على جمع هذه المعلومات
                واستخدامها (على النحو المنصوص عليه في سياسة الخصوصية)، بما في ذلك نقل هذه المعلومات إلى الولايات المتحدة
                و / أو دول أخرى لتخزينها ومعالجتها واستخدامها.
            </p>

            <h2>15. ملفات تعريف الارتباط</h2>
            <p>
                مثل العديد من الخدمات الأخرى، نستخدم "ملفات تعريف الارتباط" لجمع المعلومات. ملفات تعريف الارتباط هي
                ملفات صغيرة يتم نقلها إلى جهاز الحاسب الآلي الخاص بك لأغراض حفظ السجلات. يمكن للمستخدمين التحكم في
                استخدام ملفات تعريف الارتباط على مستوى المتصفح الفردي. ولكن إذا اخترت تعطيل جميع ملفات تعريف الارتباط،
                فقد يحد ذلك من استخدامك لبعض الميزات أو وظائف خدماتنا. لمزيد من المعلومات التفصيلية حول ملفات تعريف
                الارتباط وكيفية استخدامها على موقعنا الإلكتروني، يرجى زيارة
                <a href="/cookies/">سياسة ملفات الارتباط</a>
            </p>

            <h2>16. الأحكام الختامية</h2>
            <ol>
                <li>
                    <p>
                        يجب أن تكون هذه الشروط وأي تعديلات لاحقة خطية حتى تكون سارية. لا توجد اتفاقيات ثانوية. تحل هذه
                        الشروط محل أي نسخة سابقة من هذه الشروط متفق عليها بينك وبين الهيئة السعودية للمقاولين.
                    </p>
                </li>
                <li>
                    <p>
                        تحتفظ الهيئة السعودية للمقاولين بالحق في مراجعة هذه الشروط في أي وقت. إذا كانت المراجعة جوهرية،
                        فيجب على الهيئة السعودية للمقاولين إرسال إشعار واجب إلى جميع المستخدمين. من خلال الاستمرار في
                        الوصول إلى المنصة أو استخدامها بعد أن تصبح هذه المراجعات فعالة، فإنك توافق على الالتزام بالشروط
                        المنقحة.
                    </p>
                </li>
                <li>
                    <p>
                        إذا كان أي حكم من هذه الشروط، لأي سبب من الأسباب، غير ساري المفعول و / أو غير قابل للإنفاذ، تظل
                        الأحكام المتبقية سارية وقابلة للإنفاذ إلى أقصى حد يسمح به القانون.
                    </p>
                </li>
                <li>
                    <p>
                        تخضع هذه الشروط حصريًا لقانون المملكة العربية السعودية. يجب أن يكون مكان الأداء والاختصاص
                        القضائي بموجب هذه الشروط وأي إجراءات ذات صلة هو الرياض، المملكة العربية السعودية. أنت توافق على
                        هذا المكان وتتنازل عن أي اعتراض.
                    </p>
                </li>
            </ol>
        </div>
    </div>
);
