import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './EditSectionContainer.scss';

export function EditSectionContainer({children, className}) {
    return <div className={classNames('edit-section-container', {[className]: className})}>{children}</div>;
}

EditSectionContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};
