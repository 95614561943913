import React from 'react';

import AccountLeftSideTabs from './components/AccountLeftSideTabs/AccountLeftSideTabs';
import MobileBusinessCardBuilder from './components/MobileBusinessCardBuilder/MobileBusinessCardBuilder';
import BusinessCardSetupHeader from './components/BusinessCardSetupHeader/BusinessCardSetupHeader';
import BusinessCardEditing from './components/BusinessCardEditing/BusinessCardEditing';
import BusinessCardColors from './components/BusinessCardColors/BusinessCardColors';
import SetupBusinessCardPreview from './components/SetupBusinessCardPreview/SetupBusinessCardPreview';
import BusinessCardLogoUploading from './components/BusinessCardLogoUploading/BusinessCardLogoUploading';

import {useBusinessCardData, useMedia, usePlatformTranslation} from 'services/hooks';

const BusinessCardBuilder = () => {
    const {businessCard, isReady} = useBusinessCardData();
    const {businessCard: businessCardTranslation} = usePlatformTranslation();
    const isTabDisabled = !(isReady && businessCard);
    const isMobile = useMedia('(max-width: 840px)');

    if (isMobile) {
        return <MobileBusinessCardBuilder />;
    }

    return (
        <AccountLeftSideTabs header={<BusinessCardSetupHeader />}>
            <AccountLeftSideTabs.Item title={businessCardTranslation.editText} isDisabled={isTabDisabled}>
                <BusinessCardEditing />
            </AccountLeftSideTabs.Item>

            <AccountLeftSideTabs.Item title={businessCardTranslation.colors} isDisabled={isTabDisabled}>
                <BusinessCardColors />
            </AccountLeftSideTabs.Item>

            <AccountLeftSideTabs.Item title={businessCardTranslation.branding} isDisabled={isTabDisabled}>
                <BusinessCardLogoUploading />
            </AccountLeftSideTabs.Item>

            <AccountLeftSideTabs.Item title={businessCardTranslation.cardPreview} isDisabled={isTabDisabled}>
                <SetupBusinessCardPreview />
            </AccountLeftSideTabs.Item>
        </AccountLeftSideTabs>
    );
};

export default BusinessCardBuilder;
