import {useEffect, useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {getUserRole} from '../helpers';
import {setUserRoles} from 'store/actions';
import {AbilityContext, updateAbility} from 'permissionsConfig';
import {USER_ROLES, USER_STATUSES} from 'constants/shared';
import {getUserData, getEventId} from 'store/selectors';

const useUserRole = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUserData);
    const eventId = useSelector(getEventId);
    const ability = useContext(AbilityContext);

    useEffect(() => {
        if (user && eventId) {
            const userRole = getUserRole(user, eventId);

            updateAbility(ability, userRole);

            dispatch(setUserRoles(userRole));
        } else if (user && user.isMasterAdmin) {
            dispatch(setUserRoles([USER_ROLES.masterAdmin]));
        } else if (user && user.organizerStatus !== USER_STATUSES.disabled) {
            updateAbility(ability, USER_ROLES.organizer);
            dispatch(setUserRoles([USER_ROLES.organizer]));
        }
    }, [ability, dispatch, eventId, user]);
};

export default useUserRole;
