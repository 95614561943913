import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {isMobile} from 'react-device-detect';

import CustomHeader from '../CustomHeader';
import MenuButtons from './components/MenuButtons';

import {whiteLabel, whiteLabelsList} from 'config';
import {IS_SCA_ENV} from 'constants/shared';
import {RouterConfig} from 'routerConfig';

import './PlatformMenu.scss';

/* eslint-disable */

export default class PlatformMenu extends React.Component {
    state = {
        mobileMenuVisible: false,
        headerScrolled: false,
    };

    componentDidMount() {
        window.addEventListener('scroll', this.setHeaderScrolled);
    }

    componentDidUpdate(prevProps) {
        const {
            user,
            eventId,
            eventNotFound,
            eventLoading,
            location: {pathname},
            setEventIdBySlug,
        } = this.props;

        const urlEventSlug = pathname.replace('/event/', '').split('/')[0];

        if (user && !eventId && urlEventSlug && !eventLoading && !eventNotFound) {
            setEventIdBySlug(urlEventSlug);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.setHeaderScrolled);
    }

    setHeaderScrolled = () => {
        if (window.scrollY > 0) {
            if (this.state.headerScrolled === false) {
                this.setState({headerScrolled: true});
            }
        } else {
            if (this.state.headerScrolled === true) {
                this.setState({headerScrolled: false});
            }
        }
    };

    toggleMenu = () => {
        this.setState({mobileMenuVisible: !this.state.mobileMenuVisible});
    };

    onHandleLogout = (e) => {
        e.preventDefault();
        this.props.onLogoutUser();
    };

    render() {
        const {mobileMenuVisible, headerScrolled} = this.state;
        const {className, coloredLogo, loggedIn, forcePrimaryText, location, languages, translation} = this.props;
        const topLinksContainerStyle = {height: '70px'};
        const isIframe = location.pathname.includes('iframe-registraion');
        const isViewAllPage = location.pathname.includes(RouterConfig.viewAll);
        if (!mobileMenuVisible) {
            topLinksContainerStyle.height = '0';
        }

        const linkToHomePage = () => {
            if (isViewAllPage) {
                return (
                    <Link to={{pathname: RouterConfig.root}} className={classNames({black: isViewAllPage})}>
                        <span>HOME</span>
                    </Link>
                );
            }
            return null;
        };

        return (
            <CustomHeader
                className={className}
                isRtlLanguage={languages.isRtlLanguage}
                headerScrolled={headerScrolled}
                isPlatformMenu
                menuWrapperChildren={
                    <MenuButtons
                        translation={translation}
                        loggedIn={loggedIn}
                        onHandleLogout={this.onHandleLogout}
                        isIframe={isIframe}
                        linkToHomePage={linkToHomePage}
                        isViewAllPage={isViewAllPage}
                    />
                }
                coloredLogo={headerScrolled || coloredLogo || isViewAllPage}
                linkTo={{pathname: RouterConfig.root}}
                forcePrimaryText={forcePrimaryText || isViewAllPage}
            >
                <div
                    className={classNames('platform mobile-menu-wrapper ', {
                        opened: mobileMenuVisible,
                        hide: location.pathname.includes('/welcome') && isMobile,
                    })}
                >
                    <div className="top-links-container" style={topLinksContainerStyle}>
                        {loggedIn ? (
                            <div className={classNames('menu-links', whiteLabel)}>
                                <a onClick={this.onHandleLogout}>
                                    <span>{translation?.menu.logOut}</span>
                                </a>
                            </div>
                        ) : (
                            <div className={classNames('menu-links', whiteLabel)}>
                                <Link to={{pathname: '/login'}}>
                                    <span>{translation?.menu.logIn}</span>
                                </Link>
                            </div>
                        )}
                    </div>
                    <div className="bottom-links-container">
                        <div className={classNames('menu-links', whiteLabel)}>
                            {loggedIn ? (
                                <>
                                    <a onClick={this.onHandleLogout} className="second-link">
                                        <span>{translation?.menu.logOut}</span>
                                    </a>
                                    {!IS_SCA_ENV && (
                                        <Link
                                            to={{pathname: '/create-event'}}
                                            className={classNames(`register`, {
                                                active: whiteLabel === whiteLabelsList.added,
                                            })}
                                        >
                                            <span>{translation?.menu?.createEvent}</span>
                                        </Link>
                                    )}
                                </>
                            ) : (
                                !isIframe && (
                                    <>
                                        {linkToHomePage()}

                                        <Link
                                            to={{pathname: '/login'}}
                                            className={classNames(
                                                `register` + (location.pathname === '/login' ? 'active' : ''),
                                                {black: isViewAllPage}
                                            )}
                                        >
                                            <span>{translation?.menu.logIn}</span>
                                        </Link>
                                        {!IS_SCA_ENV && (
                                            <Link
                                                to={{pathname: '/register'}}
                                                className={classNames(
                                                    `register`,
                                                    {black: isViewAllPage},
                                                    {
                                                        active: whiteLabel === whiteLabelsList.added,
                                                    }
                                                )}
                                            >
                                                <span>{translation?.menu?.createEvent}</span>
                                            </Link>
                                        )}
                                    </>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </CustomHeader>
        );
    }
}
