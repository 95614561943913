export const GET_AUDITORIUM_POLLS_START = 'ORGANIZER_AUDITORIUM_SETUP/GET_AUDITORIUM_POLLS_START';
export const GET_AUDITORIUM_POLLS_SUCCESS = 'ORGANIZER_AUDITORIUM_SETUP/GET_AUDITORIUM_POLLS_SUCCESS';
export const GET_AUDITORIUM_POLLS_FAIL = 'ORGANIZER_AUDITORIUM_SETUP/GET_AUDITORIUM_POLLS_FAIL';

export const UPDATE_AUDITORIUM_DETAILS_START = 'ORGANIZER_AUDITORIUM_SETUP/UPDATE_AUDITORIUM_DETAILS_START';
export const UPDATE_AUDITORIUM_DETAILS_SUCCESS = 'ORGANIZER_AUDITORIUM_SETUP/UPDATE_AUDITORIUM_DETAILS_SUCCESS';
export const UPDATE_AUDITORIUM_DETAILS_FAIL = 'ORGANIZER_AUDITORIUM_SETUP/UPDATE_AUDITORIUM_DETAILS_FAIL';

export const CREATE_AUDITORIUM_START = 'ORGANIZER_AUDITORIUM_SETUP/CREATE_AUDITORIUM_START';
export const CREATE_AUDITORIUM_SUCCESS = 'ORGANIZER_AUDITORIUM_SETUP/CREATE_AUDITORIUM_SUCCESS';
export const CREATE_AUDITORIUM_FAIL = 'ORGANIZER_AUDITORIUM_SETUP/CREATE_AUDITORIUM_FAIL';

export const DELETE_AUDITORIUM_START = 'ORGANIZER_AUDITORIUM_SETUP/DELETE_AUDITORIUM_START';
export const DELETE_AUDITORIUM_SUCCESS = 'ORGANIZER_AUDITORIUM_SETUP/DELETE_AUDITORIUM_SUCCESS';
export const DELETE_AUDITORIUM_FAIL = 'ORGANIZER_AUDITORIUM_SETUP/DELETE_AUDITORIUM_FAIL';

export const UPDATE_AUDITORIUM_THUMBNAIL_START = 'ORGANIZER_AUDITORIUM_SETUP/UPDATE_AUDITORIUM_THUMBNAIL_START';
export const UPDATE_AUDITORIUM_THUMBNAIL_SUCCESS = 'ORGANIZER_AUDITORIUM_SETUP/UPDATE_AUDITORIUM_THUMBNAIL_SUCCESS';
export const UPDATE_AUDITORIUM_THUMBNAIL_FAIL = 'ORGANIZER_AUDITORIUM_SETUP/UPDATE_AUDITORIUM_THUMBNAIL_FAIL';

export const DELETE_PROGRAM_TIMESLOT_REQUEST = 'ORGANIZER_AUDITORIUM_SETUP/DELETE_PROGRAM_TIMESLOT_REQUEST';
export const DELETE_PROGRAM_TIMESLOT_SUCCESS = 'ORGANIZER_AUDITORIUM_SETUP/DELETE_PROGRAM_TIMESLOT_SUCCESS';
export const DELETE_PROGRAM_TIMESLOT_FAIL = 'ORGANIZER_AUDITORIUM_SETUP/DELETE_PROGRAM_TIMESLOT_FAIL';
