import {
    CLOSE_VIDEO_CONFERENCE,
    VIDEO_CONFERENCE_READY,
    VIDEO_CONFERENCE_LOADING,
    VIDEO_CONFERENCE_SHARE_SCREEN_TOGGLED,
    VIDEO_CONFERENCE_CHANGE_PLAYBACKS,
    VIDEO_CONFERENCE_START_CALLING,
    VIDEO_CONFERENCE_STOP_CALLING,
} from '../../../actionTypes/videoConferenceActionTypes';
import {initialState} from '../initialState';
import {updateObject} from '../../../utilityStateFunction';

const groupHandler = (state, {type, payload}) => {
    if (state === undefined) {
        return initialState.group;
    }

    switch (type) {
        case VIDEO_CONFERENCE_CHANGE_PLAYBACKS:
        case VIDEO_CONFERENCE_SHARE_SCREEN_TOGGLED:
            return updateObject(state.group, {
                ...payload,
            });
        case VIDEO_CONFERENCE_LOADING:
            return updateObject(state.group, {
                isLoading: true,
                isActive: true,
            });
        case VIDEO_CONFERENCE_READY:
            return updateObject(state.group, {
                ...payload,
                isActive: true,
                isLoading: false,
            });
        case VIDEO_CONFERENCE_START_CALLING:
            return updateObject(state.group, {
                isCalling: true,
            });
        case VIDEO_CONFERENCE_STOP_CALLING:
            return updateObject(state.group, {
                isCalling: false,
            });
        case CLOSE_VIDEO_CONFERENCE:
            return initialState.group;
        default:
            return state.group;
    }
};

export default groupHandler;
