import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {Grid, FormControlLabel} from '@material-ui/core';
import {VpnKey as VpnKeyIcon, AccountCircle as AccountCircleIcon} from '@material-ui/icons';
import classNames from 'classnames';

import Spinner from '../Spinner';
import FullPageSpinner from '../FullPageSpinner';
import SocialLoginButtons from '../SocialNetworkButtons';
import RecoverPasswordDialog from '../RecoverPasswordDialog/RecoverPasswordDialog';
import {DarkCheckbox} from '../CustomCheckbox/CustomCheckbox';

import * as actions from '../../../store/actions/index';
import {getPlatformBackground, whiteLabel} from '../../../config';
import {IS_SCA_ENV} from '../../../constants/shared';
import {reactGAEgyptRegistationTrackingEvent} from 'services/helpers/trackingHelper';
import {withCookiesAccept} from 'services/hocs';

class LoginEvent extends React.Component {
    state = {
        email: '',
        password: '',
        disabled: true,
        showRecoverPasswordDialog: false,
        recoverPasswordServerMessage: '',
        rememberMe: true,
        translation: null,
    };

    componentDidMount() {
        const {onSetEventPublicMenu} = this.props;
        onSetEventPublicMenu();
        window.scrollTo(0, 0);
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.eventLanguage !== this.props.languages.eventLanguage) {
            this.setLanguagesData();
        }
    }

    // if we had an error from server on login attempt we clear that error
    componentWillUnmount() {
        this.props.onClearError();
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.eventLanguage];
        this.setState({
            translation: translation,
        });
    };

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleLogin = (e) => {
        e.preventDefault();

        let loginUserData = {
            email: this.state.email,
            password: this.state.password,
        };

        this.props.onLoginUser(loginUserData, this.props.eventLanguage);
    };

    handleRecoverPassword = () => {
        this.setState({showRecoverPasswordDialog: true});
    };

    handleCloseDialog = (message) => {
        this.setState({showRecoverPasswordDialog: false, recoverPasswordServerMessage: message});
    };

    getBackgroundImageUrl = () => {
        let {eventInfo} = this.props;
        let backgroundImage;

        let smallBackgroundImage =
            eventInfo.brandingData.filesUrl +
            (eventInfo.brandingData.loginBackground.resize1080
                ? eventInfo.brandingData.loginBackground.resize1080
                : eventInfo.brandingData.loginBackground);
        let mediumBackgroundImage =
            eventInfo.brandingData.filesUrl +
            (eventInfo.brandingData.loginBackground.resize2160
                ? eventInfo.brandingData.loginBackground.resize2160
                : eventInfo.brandingData.loginBackground);
        let originalBackgroundImage =
            eventInfo.brandingData.filesUrl +
            (eventInfo.brandingData.loginBackground.original
                ? eventInfo.brandingData.loginBackground.original
                : eventInfo.brandingData.loginBackground);

        if (window.innerHeight < 1081) {
            backgroundImage = smallBackgroundImage;
        } else if (window.innerHeight < 2161) {
            backgroundImage = mediumBackgroundImage;
        } else {
            backgroundImage = originalBackgroundImage;
        }

        return backgroundImage;
    };

    handleRegistrationClick = () => reactGAEgyptRegistationTrackingEvent(this.props.cookiesAccepted);

    render() {
        let {email, password, translation} = this.state;
        let {eventInfo, loadingUser, loadingEvent, isRtlLanguage} = this.props;

        let eventBrandingTranslation = null;
        if (eventInfo?.brandingTranslations?.length) {
            eventBrandingTranslation = eventInfo.brandingTranslations.find(
                (t) => t.language === this.props.languages.eventLanguage
            );
        }

        const loginDescription = eventBrandingTranslation
            ? eventBrandingTranslation.loginDescription
            : eventInfo.brandingData.loginDescription;

        if (loadingEvent && !eventInfo) {
            return (
                <div className="login-page-spinner-container">
                    <FullPageSpinner />
                </div>
            );
        }
        let overlayColor = {};
        if (eventInfo.brandingData.overlayColorLogin) {
            overlayColor.background = eventInfo.brandingData.overlayColorLogin;
        }
        const BackgroundImage = getPlatformBackground(whiteLabel);

        const socialLoginButtons = (
            <div className="left-column">
                <SocialLoginButtons registerAsParticipant />
            </div>
        );

        return (
            <div>
                <div className={classNames('login-page', {isRtl: isRtlLanguage})}>
                    <div className="full-background-container p-relative d-flex">
                        {eventInfo && eventInfo.brandingData && eventInfo.brandingData.loginBackground ? (
                            <img
                                src={this.getBackgroundImageUrl()}
                                className="full-background-img img-cover p-absolute w-100 h-100"
                                alt="background"
                            />
                        ) : (
                            <img
                                src={BackgroundImage}
                                className="full-background-img img-cover p-absolute w-100 h-100"
                                alt="background"
                            />
                        )}
                        {!IS_SCA_ENV && (
                            <div className="full-background-overlay p-absolute w-100 h-100" style={overlayColor}></div>
                        )}
                        <div className="header-spacing-container">
                            <div className="form-wrapper w-100 h-100 d-flex justify-content-center align-items-center">
                                {eventInfo ? (
                                    <div className="login-container">
                                        {socialLoginButtons}
                                        <div className="form-container">
                                            <Grid
                                                container
                                                justify="flex-end"
                                                className="login-popup"
                                                alignItems="center"
                                            >
                                                <Grid item xs={12}>
                                                    <div className="login-header">
                                                        <h1 className={classNames('page-title', whiteLabel)}>
                                                            {!!eventBrandingTranslation &&
                                                                eventBrandingTranslation.loginTitle}
                                                            {!eventBrandingTranslation &&
                                                                this.props.eventInfo.brandingData.loginTitle}
                                                        </h1>
                                                        <p className="login-description">
                                                            {loginDescription.split('\n').map((item, key) => {
                                                                return (
                                                                    <span key={key}>
                                                                        {item}
                                                                        <br />
                                                                    </span>
                                                                );
                                                            })}
                                                        </p>
                                                    </div>
                                                    <div className="login-body">
                                                        <ValidatorForm ref="form" onSubmit={this.handleLogin}>
                                                            <div className="email-wrapper">
                                                                <TextValidator
                                                                    label={translation?.login.inputEmail}
                                                                    onChange={this.handleChange}
                                                                    name="email"
                                                                    value={email}
                                                                    className="field-container"
                                                                    variant="filled"
                                                                    fullWidth
                                                                    validators={['required', 'isEmail']}
                                                                    errorMessages={[
                                                                        `${translation?.errors.required}`,
                                                                        `${translation?.errors.emailNotValid}`,
                                                                    ]}
                                                                />
                                                                <AccountCircleIcon className="icon" />
                                                            </div>
                                                            <div className="password-wrapper">
                                                                <TextValidator
                                                                    label={translation?.login.inputPassword}
                                                                    onChange={this.handleChange}
                                                                    name="password"
                                                                    value={password}
                                                                    className="field-container"
                                                                    variant="filled"
                                                                    type="password"
                                                                    fullWidth
                                                                    validators={['required', 'minStringLength:8']}
                                                                    errorMessages={[
                                                                        `${translation?.errors.required}`,
                                                                        `${translation?.errors.passwordMinLength}`,
                                                                    ]}
                                                                />
                                                                <VpnKeyIcon className="icon" />
                                                            </div>
                                                            {this.props.error && <p>{this.props.error}</p>}
                                                            <div className="recover-wrapper">
                                                                <div>
                                                                    <FormControlLabel
                                                                        style={{color: '#2B2B2B'}}
                                                                        control={
                                                                            <DarkCheckbox
                                                                                checked={this.state.rememberMe}
                                                                                onChange={() =>
                                                                                    this.setState({
                                                                                        rememberMe:
                                                                                            !this.state.rememberMe,
                                                                                    })
                                                                                }
                                                                            />
                                                                        }
                                                                        label={translation?.login.rememberMeButton}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <button
                                                                        className={whiteLabel}
                                                                        onClick={this.handleRecoverPassword}
                                                                        type="button"
                                                                    >
                                                                        {translation?.login.forgotPasswordButton}
                                                                    </button>
                                                                </div>
                                                                {this.state.recoverPasswordServerMessage && (
                                                                    <p className="server-message">
                                                                        {this.state.recoverPasswordServerMessage}
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <button
                                                                className={
                                                                    'login-submit-button ' +
                                                                    (this.state.disabled ? 'disabled' : '')
                                                                }
                                                                type="submit"
                                                            >
                                                                {translation?.login.loginButton}
                                                            </button>
                                                        </ValidatorForm>
                                                        {!eventInfo.hideParticipantsLink && (
                                                            <p className="uppercase to-register">
                                                                {translation?.login.noAccountText}
                                                                <span>
                                                                    <Link
                                                                        onClick={this.handleRegistrationClick}
                                                                        className={whiteLabel}
                                                                        to={{
                                                                            pathname: `/event/${this.props.eventSlug}/register`,
                                                                            search: '?registerAsParticipant=true',
                                                                        }}
                                                                    >
                                                                        {translation?.login.registerButton}
                                                                    </Link>
                                                                </span>
                                                            </p>
                                                        )}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            {(loadingUser || (loadingEvent && eventInfo)) && <Spinner />}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showRecoverPasswordDialog ? (
                    <RecoverPasswordDialog
                        opened={this.state.showRecoverPasswordDialog}
                        closeDialog={this.handleCloseDialog}
                    />
                ) : null}
            </div>
        );
    }
}

export default withCookiesAccept(
    connect(
        (state) => ({
            loggedIn: state.user.loggedIn,
            loadingUser: state.user.loading,
            error: state.user.error,
            user: state.user.data,
            eventInfo: state.event.eventInfo,
            eventId: state.event.eventId,
            eventSlug: state.event.eventSlug,
            loadingEvent: state.event.loading,
            languages: state.languages,
            isRtlLanguage: state.languages.isRtlLanguage,
            translation: state.languages.translations[state.languages.platformLanguage],
            eventLanguage: state.languages.eventLanguage,
        }),
        {
            onLoginUser: actions.loginUser,
            onClearError: actions.clearError,
            onSetEventPublicMenu: actions.setEventPublicMenu,
        }
    )(LoginEvent)
);
