import React, {useCallback, useEffect, useState} from 'react';

import ColoredScrollbars from '../ColoredScrollbars/ColoredScrollbars';
import ShowFloorFilter from './components/ShowFloorFilter/ShowFloorFilter';
import FilterChips from './components/FilterChips/FilterChips';

import {ReactComponent as Arrow} from '../../../Images/back.svg';
import {usePlatformTranslation} from '../../../services/hooks';
import {Tooltip} from '@material-ui/core';

import {sortByOrderNumber} from '../../../services/utils';
import {USER_ROLES} from 'constants/shared';
import {transformFiltersToApply} from 'services/helpers';

import './ExhibitorsOverlay.scss';

const noImageParagraphStyles = {justifyContent: 'flex-start'};

const noImageSpanStyles = {
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

const Exhibitor = ({exhibitor, activeExhibitorId, onSetActiveExhibitorId}) => {
    const [hasImageError, setHasImageError] = useState(false);
    const noImage = !exhibitor.booth.logo;
    if (noImage || hasImageError) {
        return (
            <div
                className={'single-exhibitor ' + (activeExhibitorId === exhibitor._id ? 'active' : '')}
                onClick={onSetActiveExhibitorId}
            >
                <Tooltip title={exhibitor.company} placement="top-start">
                    <div className="logo-container">
                        <div className="no-image">
                            <p style={noImageParagraphStyles}>
                                <span style={noImageSpanStyles}>{exhibitor.company}</span>
                            </p>
                        </div>
                    </div>
                </Tooltip>
            </div>
        );
    }

    return (
        <div
            className={'single-exhibitor ' + (activeExhibitorId === exhibitor._id ? 'active' : '')}
            onClick={onSetActiveExhibitorId}
        >
            <div className="logo-container">
                <img
                    src={exhibitor.filesUrl + exhibitor.booth.logo}
                    alt={exhibitor.company}
                    onError={() => setHasImageError(true)}
                />
            </div>
            <Tooltip title={exhibitor.company} placement="top-start">
                <p style={noImageSpanStyles}>{exhibitor.company}</p>
            </Tooltip>
        </div>
    );
};

const ExhibitorsOverlay = ({
    hideExhibitorsOverlay,
    showExhibitorsOverlay,
    exhibitorData,
    eventId,
    exhibitorsFilters,
    eventExhibitorFilters,
    filteredExhibitors,
    activeExhibitorId,
    exhibitors,
    onApplyExhibitorsFilters,
    onSetActiveExhibitorId,
    onSetExhibitorsFilters,
    onGetGroupsList,
    onGetEventFilters,
    eventExhibitorFiltersEventId,
}) => {
    const [search, setSearch] = useState('');
    const [isFilterOpened, setIsFilterOpened] = useState(false);
    const [searchedExhibitors, setSearchedExhibitors] = useState([]);
    const translation = usePlatformTranslation();

    useEffect(() => {
        if (!eventExhibitorFilters || eventId !== eventExhibitorFiltersEventId)
            onGetEventFilters({userType: USER_ROLES.exhibitor});
    }, [eventExhibitorFilters, onGetEventFilters, eventId, eventExhibitorFiltersEventId]);

    useEffect(() => {
        onGetGroupsList({userRole: USER_ROLES.exhibitor});
    }, [onGetGroupsList]);

    useEffect(() => {
        onApplyExhibitorsFilters({eventId, buildingId: exhibitorData?.building});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exhibitorData]);

    const searchExhibitors = useCallback(() => {
        if (!filteredExhibitors.length) return setSearchedExhibitors([]);
        if (!search) return setSearchedExhibitors(sortByOrderNumber(filteredExhibitors));

        const searchResult = filteredExhibitors.filter(
            ({company, phone, country}) =>
                company.toLowerCase().includes(search.toLowerCase()) ||
                (phone && phone.toLowerCase().includes(search.toLowerCase())) ||
                (country && country.toLowerCase().includes(search.toLowerCase()))
        );

        setSearchedExhibitors(sortByOrderNumber(searchResult));
    }, [search, filteredExhibitors]);

    useEffect(() => {
        searchExhibitors();
    }, [search, filteredExhibitors, searchExhibitors]);

    const handleFilterOpened = (value) => setIsFilterOpened(value);

    const handleSearch = (value) => setSearch(value);

    const setFilters = (filters) => {
        // to set filters for chips
        onSetExhibitorsFilters(filters);

        const filtersData = transformFiltersToApply(filters);

        onApplyExhibitorsFilters({eventId, filters: filtersData, buildingId: exhibitorData?.building});
    };

    return (
        <div className={'exhibitors-overlay ' + (showExhibitorsOverlay ? 'show' : '')}>
            <div className="exhibitors-container">
                <div className="close" onClick={hideExhibitorsOverlay}>
                    <Arrow />
                </div>
                <div className="exhibitors-header">
                    <h4 className="title">{translation?.showfloor.allExhibitorsButton}</h4>
                    <ShowFloorFilter
                        className="exhibitors-header__filter"
                        filters={eventExhibitorFilters}
                        setIsOpened={handleFilterOpened}
                        isOpened={isFilterOpened}
                        setSearch={handleSearch}
                        usingFilters={exhibitorsFilters}
                        applyFilter={setFilters}
                        users={exhibitors}
                    />
                </div>
                <FilterChips filters={exhibitorsFilters} setFilters={setFilters} />
                <div className="exhibitors-list-container">
                    <ColoredScrollbars>
                        <div className="exhibitors-list">
                            {searchedExhibitors.length ? (
                                searchedExhibitors.map((exhibitor) => (
                                    <Exhibitor
                                        key={exhibitor._id}
                                        exhibitor={exhibitor}
                                        activeExhibitorId={activeExhibitorId}
                                        onSetActiveExhibitorId={() => onSetActiveExhibitorId(exhibitor._id)}
                                    />
                                ))
                            ) : (
                                <span>No results</span>
                            )}
                        </div>
                    </ColoredScrollbars>
                </div>
            </div>
        </div>
    );
};

export default ExhibitorsOverlay;
