import {MyAccount} from './MyAccount';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions/index';

export default connect(
    (state) => ({
        event: state.event.data,
        isMobile: state.layout.isMobile,
        isTablet: state.layout.isTablet,
        isRtlLanguage: state.languages.isRtlLanguage,
    }),
    {
        onSetEventProtectedMenu: actions.setEventProtectedMenu,
        // getEventUsersList: actions.getEventUsersList,
    }
)(MyAccount);
