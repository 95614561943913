import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {openGroupChat, openPrivateChat, updateActiveChatTab} from '../../../../../../../../../store/actions';
import {getTimeAgoString} from '../../../../../../../../../services/utils';
import GroupChatPreview from '../GroupChatPreview/GroupChatPreview';
import PrivateChatPreview from '../PrivateChatPreview/PrivateChatPreview';
import {usePlatformTranslation} from '../../../../../../../../../services/hooks';

const PRIVATE_CHAT_TYPE = 'private';
const GROUP_CHAT_TYPE = 'group';

const ChatsList = ({handleOpenGroupChat, onGetGroupChats, eventId, context, ...props}) => {
    const [chats, setChats] = useState([]);
    const dispatch = useDispatch();
    const translation = usePlatformTranslation();

    const user = useSelector((state) => state.user);
    const {activeChatRoomId} = useSelector((state) => state.user.topNavigation);
    const {isLargeScreen} = useSelector((state) => state.layout);
    const {allChats, eventChats, archivedChats} = useSelector((state) => state.user.data);
    const platformLanguage = useSelector((state) => state.languages.platformLanguage);
    const isLoading = useSelector((state) => state.user.loading);

    const openFirstChatInList = useCallback(() => {
        if (!activeChatRoomId && chats.length) {
            const firstChatInList = chats[0];

            if (firstChatInList?.users) {
                const chatExists = user.data.groupChats.some((groupChat) => groupChat.chat._id === firstChatInList._id);

                if (!chatExists) return dispatch(updateActiveChatTab({eventChats, archivedChats}, eventId));

                // is a group chat and we should handle opening that type of chat
                dispatch(openGroupChat(firstChatInList._id));
            } else {
                // is a private chat and we should handle opening that type of chat
                dispatch(openPrivateChat(firstChatInList._id));
            }
        }
    }, [activeChatRoomId, archivedChats, chats, dispatch, eventChats, eventId, user.data.groupChats]);

    useEffect(() => {
        if (user.chats.archivedChats) sortChats(archivedChats);
        if (user.chats.allChats) sortChats(allChats);
        if (user.chats.eventChats) sortChats(eventChats);
    }, [allChats, archivedChats, eventChats, user]);

    useEffect(() => {
        if (isLargeScreen) {
            openFirstChatInList();
        }
    }, [isLargeScreen, openFirstChatInList]);

    const sortChats = (chats) => {
        if (!chats?.length) {
            setChats([]);
            return;
        }

        chats.sort(function (x, y) {
            let date1 = new Date(x.lastMessageAt);
            let date2 = new Date(y.lastMessageAt);
            return date2 - date1;
        });

        setChats(chats);
    };

    return (
        <div className="all-chats">
            {!isLoading && !chats?.length && <p className="no-chats">{translation.chatsDropdown.noChats}</p>}

            {chats?.length > 0 &&
                chats.map((chatData) => {
                    if (chatData.type === PRIVATE_CHAT_TYPE) {
                        return (
                            <PrivateChatPreview
                                key={chatData._id}
                                context={context}
                                privateChatData={chatData}
                                {...props}
                            />
                        );
                    } else if (chatData.type === GROUP_CHAT_TYPE) {
                        return (
                            <GroupChatPreview
                                key={chatData._id}
                                userId={user?.data?._id}
                                groupChatData={chatData}
                                timeAgoString={getTimeAgoString(chatData?.lastMessageAt, platformLanguage)}
                                activeChatRoomId={activeChatRoomId}
                                handleOpen={handleOpenGroupChat}
                            />
                        );
                    } else {
                        return null;
                    }
                })}
        </div>
    );
};

export default ChatsList;
