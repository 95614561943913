import {Footer} from './Footer';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getIsRtlLanguage} from '../../../store/selectors';

export default withRouter(
    connect((state) => ({
        isRtlLanguage: getIsRtlLanguage(state),
        platformLanguage: state.languages.platformLanguage,
        eventLanguage: state.languages.eventLanguage,
    }))(Footer)
);
