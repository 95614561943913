import classNames from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';
import './ProgramDropdownDay.scss';

const ProgramDropdownDay = ({date, weekday, isToday}) => {
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);

    return (
        <div className="program-dropdown-day">
            <div
                dir="ltr"
                className={classNames('program-dropdown-day__date-wrapper', {
                    'program-dropdown-day__date-wrapper--bold': isToday,
                })}
            >
                <span
                    dir="ltr"
                    className={classNames('program-dropdown-day__date', {isRtl: isRtlLanguage})}
                >{`${date}`}</span>
                <span className="program-dropdown-day__week-day">{`${weekday}`}</span>
            </div>
        </div>
    );
};

export default ProgramDropdownDay;
