import React from 'react';
import {connect} from 'react-redux';
import {isMobileSafari, isSafari, isOpera} from 'react-device-detect';
import ReactPlayer from 'react-player';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import {
    VolumeMute as VolumeMuteIcon,
    VolumeUp as VolumeUpIcon,
    Fullscreen as FullscreenIcon,
    FullscreenExit as FullscreenExitIcon,
    PlayArrow as PlayArrowIcon,
    Pause as PauseIcon,
    FastForward as FastForwardIcon,
    FastRewind as FastRewindIcon,
} from '@material-ui/icons';

import Wall from '../Wall';
import AuditoriumOverlayPlayer from '../AuditoriumOverlayPlayer';
import AuditoriumPageContainer from '../AuditoriumPageContainer';
import AuditoriumBanners from '../AuditoriumBanners/AuditoriumBanners';
import BreadcrumbsNavigation from '../BreadcrumbsNavigation';

import AuditoriumBackgroundImage from '../AuditoriumBackgroundImage/AuditoriumBackgroundImage';
import * as actions from 'store/actions';

class Auditorium extends React.Component {
    fullscreenTimerId = 0;

    state = {
        archivedEventId: null,
        archivedEventData: null,
        timeslotData: null,
        videoId: null,
        video: null,
        fullscreen: false,
        playing: true,
        muteVideo: false,
        currentMobileTab: 'video',
        translation: null,
    };

    componentDidMount() {
        this.props.onSetEventProtectedMenu();

        const {eventId} = this.props;
        if (!this.props.eventArchive) {
            this.props.onGetEventArchive(eventId);
        }

        this.setVideoData();

        document.addEventListener('webkitfullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('mozfullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('fullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('MSFullscreenChange', this.toggleFullScreenEscKey, false);

        this.setLanguagesData();
    }

    componentWillUnmount() {
        if (document.fullscreenElement) {
            document.exitFullscreen().then(this.exitFullscreen());
        }
        document.removeEventListener('webkitfullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('mozfullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('fullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('MSFullscreenChange', this.toggleFullScreenEscKey, false);
    }

    componentDidUpdate(prevProps) {
        const {videoId} = this.props.match.params;
        if (
            this.state.videoId !== videoId ||
            prevProps.event !== this.props.event ||
            prevProps.eventArchive !== this.props.eventArchive
        ) {
            this.setVideoData();
        }
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    toggleFullScreenEscKey = () => {
        let fullscreenElement =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullscreenElement ||
            document.msFullscreenElement;
        if (!fullscreenElement) {
            this.exitFullscreen();
        }
    };

    toggleFullScreen = () => {
        if (document.fullscreenElement) {
            document
                .exitFullscreen()
                .then(() => this.exitFullscreen())
                .catch((err) => console.error(err));
        } else {
            document.documentElement.requestFullscreen().then(() => this.startFullscreen());
        }
    };

    startFullscreen = () => {
        this.setState({fullscreen: true});
        this.hideVideoButtons();

        document.body.classList.add('fullscreen-video');
        document.getElementById('js-auditorium-page').classList.add('fullscreen');
        window.addEventListener('mousemove', this.mouseMove);
    };

    exitFullscreen = () => {
        this.setState({fullscreen: false});
        this.showVideoButtons();
        clearTimeout(this.fullscreenTimerId);

        document.body.classList.remove('fullscreen-video');
        document.getElementById('js-auditorium-page').classList.remove('fullscreen');
        window.removeEventListener('mousemove', this.mouseMove);
    };

    setVideoData = () => {
        const {archivedEventId} = this.props.match.params;
        const {videoId} = this.props.match.params;

        this.setState(
            {
                videoId: videoId,
                archivedEventId: archivedEventId,
            },
            () => this.loadCurrentVideo()
        );
    };

    loadCurrentVideo = () => {
        if (this.props.eventArchive !== null && this.props.event !== null) {
            const archivedEventData = this.props.eventArchive.find(
                (eventArchiveData) => eventArchiveData._id === this.state.archivedEventId
            );
            const currentTimeSlot = archivedEventData.videos.find((video) => video._id === this.state.videoId);

            this.setVideoWallData(currentTimeSlot);
            this.setState({
                video: currentTimeSlot,
                archivedEventData: archivedEventData,
                timeslotData: currentTimeSlot,
            });
        }
    };

    setVideoWallData = (currentTimeSlot) => {
        this.props.setHasVideoWall(true);

        // we set up the current time slot video Id
        const activeVideoWallId = currentTimeSlot.videoWall;

        let highlightUsers = [];

        if (currentTimeSlot.auditoriumSpeakers && currentTimeSlot.auditoriumSpeakers.length) {
            currentTimeSlot.auditoriumSpeakers.forEach((speaker) => {
                highlightUsers.push(speaker.user._id);
            });
        }

        // we set up the current wall Id to be the video Wall Id
        const wallData = {
            wallId: activeVideoWallId,
            highlightUsers,
        };
        this.props.setTimeSlotVideoWall(wallData);
        this.props.setActiveWall(wallData);

        // we open the sideBar to the videoWall
        this.props.seeVideoWall();
    };

    videoHasEnded = () => {
        this.setState({playing: false});
        this.exitFullscreen();
    };

    escFunction = (e) => {
        if (e.key === 'Escape') {
            this.exitFullscreen();
        }
    };

    mouseMove = () => {
        clearTimeout(this.fullscreenTimerId);
        this.showVideoButtons();
        this.fullscreenTimerId = setTimeout(this.hideVideoButtons, 3000);
    };

    showVideoButtons = () => {
        if (document.getElementById('videoButtons')) {
            document.getElementById('videoButtons').classList.remove('hide');
        }
    };

    hideVideoButtons = () => {
        if (document.getElementById('videoButtons')) {
            document.getElementById('videoButtons').classList.add('hide');
        }
    };

    skipForward = () => {
        this.player.seekTo(Math.floor(this.player.getCurrentTime()) + 30, 'seconds');
    };

    skipBackward = () => {
        this.player.seekTo(Math.floor(this.player.getCurrentTime()) - 30, 'seconds');
    };

    ref = (player) => {
        this.player = player;
    };

    handleChangeMobileTab = (tabName) => (e) => {
        this.setState({
            currentMobileTab: tabName,
        });
    };

    render() {
        const {event, isLargeScreen} = this.props;
        const {currentMobileTab, timeslotData, archivedEventData, translation} = this.state;

        const hasVideoWall = this.state.video && this.state.video.videoWall;
        const showWall =
            (hasVideoWall && isLargeScreen) || (hasVideoWall && !isLargeScreen && currentMobileTab === 'wall');
        const hideVideo = !isLargeScreen && currentMobileTab === 'wall';

        const hideFullscreenButton = isMobileSafari || isSafari || isOpera;

        return (
            <div className="position-background">
                <BreadcrumbsNavigation className={'white'} />
                {this.state.videoId ? (
                    <>
                        <AuditoriumPageContainer>
                            {archivedEventData && (
                                <>
                                    {/*SHOW BACKGROUND IMAGE ONLY ON LARGE SCREENS*/}
                                    {isLargeScreen && (
                                        <AuditoriumBackgroundImage
                                            filesUrl={this.props.event.brandingData.filesUrl}
                                            brandingData={this.props.event.brandingData}
                                        />
                                    )}

                                    {!isLargeScreen && (
                                        <div className="page-title">{translation?.auditoriumArchive.title}</div>
                                    )}

                                    {isLargeScreen && (
                                        <div className="slot-title">
                                            <p className={'title '}>
                                                {this.state.video.speakers.length ? (
                                                    <>
                                                        {this.state.video.speakers.map((speaker, index) => {
                                                            return (
                                                                <span key={index}>
                                                                    {speaker}
                                                                    {index === this.state.video.speakers.length - 1 ? (
                                                                        <span className="subtitle"> with </span>
                                                                    ) : (
                                                                        ', '
                                                                    )}
                                                                </span>
                                                            );
                                                        })}
                                                    </>
                                                ) : null}
                                                {this.state.video.title}
                                            </p>
                                        </div>
                                    )}
                                    {!isLargeScreen && (
                                        <div className="tabs-container">
                                            <div
                                                className={`button-tab tab ${
                                                    currentMobileTab === 'video' ? 'active' : ''
                                                }`}
                                                onClick={this.handleChangeMobileTab('video')}
                                            >
                                                {translation?.auditoriumArchive.stage}
                                            </div>
                                            {hasVideoWall && (
                                                <div
                                                    className={`button-tab tab ${
                                                        currentMobileTab === 'wall' ? 'active' : ''
                                                    }`}
                                                    onClick={this.handleChangeMobileTab('wall')}
                                                >
                                                    {translation?.auditoriumArchive.videWall}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className={`centerOfPage ${hideVideo ? 'hide' : ''}`}>
                                        <div className="relativeCenter">
                                            {isLargeScreen && (
                                                <AuditoriumBanners
                                                    filesUrl={event.brandingData.filesUrl}
                                                    brandingData={event.brandingData}
                                                />
                                            )}
                                            <div
                                                className={
                                                    'videoWrapper ' + (this.state.fullscreen ? 'fullscreen' : '')
                                                }
                                            >
                                                <div className="video-container">
                                                    <ReactPlayer
                                                        ref={this.ref}
                                                        url={this.state.video.url}
                                                        playing={this.state.playing}
                                                        className="react-player"
                                                        controls={false}
                                                        width="100%"
                                                        height="100%"
                                                        volume={this.state.muteVideo ? 0 : 1}
                                                        onEnded={this.videoHasEnded}
                                                        onContextMenu={(e) => e.preventDefault()}
                                                    />
                                                    <AuditoriumOverlayPlayer />
                                                </div>
                                                <div
                                                    className="video-actions-container"
                                                    id="videoButtons"
                                                    style={this.state.auditoriumButtonsStyle}
                                                >
                                                    <button
                                                        onClick={this.toggleFullScreen}
                                                        className={`${hideFullscreenButton ? 'd-none' : ''}`}
                                                    >
                                                        {this.state.fullscreen ? (
                                                            <Tooltip
                                                                title={translation?.auditoriumArchive.tooltipMinimize}
                                                            >
                                                                <FullscreenExitIcon fontSize="inherit" />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip
                                                                title={translation?.auditoriumArchive.tooltipFullscreen}
                                                            >
                                                                <FullscreenIcon fontSize="inherit" />
                                                            </Tooltip>
                                                        )}
                                                    </button>
                                                    <Tooltip title="-30 sec">
                                                        <button onClick={() => this.skipBackward()}>
                                                            <FastRewindIcon fontSize="inherit" />
                                                        </button>
                                                    </Tooltip>
                                                    <button
                                                        onClick={() => this.setState({playing: !this.state.playing})}
                                                    >
                                                        {this.state.playing ? (
                                                            <Tooltip
                                                                title={translation?.auditoriumArchive.tooltipPause}
                                                            >
                                                                <PauseIcon fontSize="inherit" />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title={translation?.auditoriumArchive.tooltipPlay}>
                                                                <PlayArrowIcon fontSize="inherit" />
                                                            </Tooltip>
                                                        )}
                                                    </button>
                                                    <Tooltip title="+30 sec">
                                                        <button onClick={() => this.skipForward()}>
                                                            <FastForwardIcon fontSize="inherit" />
                                                        </button>
                                                    </Tooltip>
                                                    <button
                                                        onClick={() =>
                                                            this.setState({muteVideo: !this.state.muteVideo})
                                                        }
                                                    >
                                                        {this.state.muteVideo ? (
                                                            <Tooltip
                                                                title={translation?.auditoriumArchive.tooltipUnmute}
                                                            >
                                                                <VolumeUpIcon fontSize="inherit" />
                                                            </Tooltip>
                                                        ) : (
                                                            <Tooltip title={translation?.auditoriumArchive.tooltipMute}>
                                                                <VolumeMuteIcon fontSize="inherit" />
                                                            </Tooltip>
                                                        )}
                                                    </button>
                                                </div>
                                            </div>
                                            {!isLargeScreen && (
                                                <div className="slot-title">
                                                    <p className="title ">{timeslotData && timeslotData.title}</p>
                                                    <p className="subtitle">
                                                        {archivedEventData && archivedEventData.eventName}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {showWall && (
                                <div className="booth-wall-container">
                                    <div className="booth-wall-header">{translation?.auditoriumArchive.videoWall}</div>
                                    <Wall />
                                </div>
                            )}
                        </AuditoriumPageContainer>
                    </>
                ) : null}
            </div>
        );
    }
}

export default connect(
    (state) => ({
        eventId: state.event.eventId,
        event: state.event.data,
        eventArchive: state.eventArchive.data,
        user: state.user.data,
        isLargeScreen: state.layout.isLargeScreen,
        languages: state.languages,
    }),
    {
        onGetEventArchive: actions.getEventArchive,
        closeSidebar: actions.sideNavClose,
        setHasVideoWall: actions.sideNavHasVideoWall,
        setTimeSlotVideoWall: actions.setTimeSlotVideoWall,
        setActiveWall: actions.setActiveWall,
        seeVideoWall: actions.sideNavSeeVideoWall,
        onSetEventProtectedMenu: actions.setEventProtectedMenu,
    }
)(Auditorium);
