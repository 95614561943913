import React from 'react';

import DesktopAgendaProgramItem from './components/DesktopAgendaProgramItem/DesktopAgendaProgramItem';
import MobileAgendaProgramItem from './components/MobileAgendaProgramItem/MobileAgendaProgramItem';

import {checkIsMeetingType, checkIsProgramType} from '../../../../../../../../../../../services/utils';
import {useMedia} from '../../../../../../../../../../../services/hooks';

const getTitle = (title, meetingName, speakers, isMeeting) => {
    if (isMeeting) {
        return `Meeting with ${meetingName}`;
    }

    if (!speakers) {
        return title;
    }

    return `${speakers} with ${title}`;
};

const AgendaProgramItem = ({
    id,
    type,
    title,
    speakers,
    receiverName,
    receiverId,
    senderName,
    userId,
    onMeetingStart,
    onRemove,
    ...rest
}) => {
    const isMobile = useMedia('(max-width: 640px)');
    const isMeeting = checkIsMeetingType(type);
    const isProgram = checkIsProgramType(type);
    const isCurrentUserReceiver = userId === receiverId;
    const meetingName = isCurrentUserReceiver ? senderName : receiverName;

    const additionalProps = {
        title: getTitle(title, meetingName, speakers, isMeeting),
        type,
        isProgram,
        isMeeting,
        onMeetingStart: () => onMeetingStart(id),
        onRemove: () => onRemove(id),
    };

    return isMobile ? (
        <MobileAgendaProgramItem {...rest} {...additionalProps} />
    ) : (
        <DesktopAgendaProgramItem {...rest} {...additionalProps} />
    );
};

export default AgendaProgramItem;
