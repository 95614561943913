import {createMuiTheme} from '@material-ui/core/styles';
import {mainPalette} from './mainTheme';

const unctadTheme = createMuiTheme({
    palette: {
        ...mainPalette.palette,
        primary: {
            main: '#039D7C',
        },
        secondary: {
            main: '#F28D20',
        },
        success: {
            main: '#039D7C',
        },
        warning: {
            main: '#FFC107',
        },
        action: {
            hover: '#ebf3ea',
            active: 'rgba(0, 88, 80, 0.35)',
        },
    },
});

export default unctadTheme;
