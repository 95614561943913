import {useSelector, useDispatch} from 'react-redux';

import {
    openPrivateChat,
    createPrivateChat,
    openWallet,
    sendContactRequest,
} from '../../../../../../../../../store/actions';
import {getPrivateChatId} from '../../../../../../../../../services/utils';

const useActions = () => {
    const dispatch = useDispatch();

    return {
        openPrivateChat: (chatId) => dispatch(openPrivateChat(chatId)),
        createPrivateChat: (targetUserId, eventId) => dispatch(createPrivateChat(targetUserId, eventId)),
        requestBussinessCard: (targetUserId) => dispatch(sendContactRequest(targetUserId)),
        openWallet: (walletView, walletTab, cardId) => dispatch(openWallet(walletView, walletTab, cardId)),
    };
};

export const useOpeningPrivateChat = (userId) => {
    const {openPrivateChat, createPrivateChat} = useActions();
    const privateChats = useSelector((state) => state.user.data.privateChats);
    const loading = useSelector((state) => state.user.loading);
    const userLoggedIn = useSelector((state) => state.user.data);
    const {eventId} = useSelector((state) => state.event);

    const privateChatId = getPrivateChatId(privateChats, userId);

    if (privateChatId) {
        return () => openPrivateChat(privateChatId);
    }

    if (userId !== userLoggedIn._id && !loading) {
        return () => createPrivateChat(userId, eventId);
    }
};

export const useBusinessCardOpening = (userId, openCardCallback) => {
    const {openWallet} = useActions();
    return () => {
        openWallet('card', '', userId);
        openCardCallback && openCardCallback();
    };
};

export const useRequestBussinessCard = (userId) => {
    const {requestBussinessCard} = useActions();
    return () => requestBussinessCard(userId);
};
