import React, {useState} from 'react';
import PropTypes from 'prop-types';
import InfoTooltip from 'Routes/components/InfoTooltip/InfoTooltip';
import {Button} from '@material-ui/core';
import {FILTER_TYPES} from 'constants/shared';
import MatchmakingDialog from 'Routes/components/MatchmakingBanner/MatchmakingDialog';
import {SHOW_TOOLTIP_MATCHING, TABS_INDEXS} from 'constants/matchMaking';
import {useCompletedMatchmaking} from 'services/hooks';

import './MatchMaking.scss';

const TOOLTIP_OPEN_DELAY = 3000;

const MatchMaking = ({setFilters, onSetCompleteMatching, userLoading, eventId, filterType, isLargeScreen}) => {
    const isMatchingTooltipSkippedEvents = JSON.parse(localStorage.getItem(SHOW_TOOLTIP_MATCHING)) || [];
    const completeMatching = useCompletedMatchmaking();

    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const handleCLoseDialog = (successPage) => {
        setIsDialogOpen(false);
        if (successPage) {
            onSetCompleteMatching({eventId, completeMatching: true});
        }
    };

    const handleAbcFilter = () => {
        setFilters([]);
    };

    const handleMatchesFilter = () => {
        if (!completeMatching) {
            setIsDialogOpen(true);
        } else {
            setFilters([], FILTER_TYPES.matches);
        }
    };

    const handleSetTooltipSkipped = () => {
        if (isMatchingTooltipSkippedEvents.includes(eventId)) return;
        const newMatchingSkippedEvents = [...isMatchingTooltipSkippedEvents, eventId];
        localStorage.setItem(SHOW_TOOLTIP_MATCHING, JSON.stringify(newMatchingSkippedEvents));
    };

    const isDisabled = userLoading;

    const timeOut = !completeMatching && !isMatchingTooltipSkippedEvents.includes(eventId) && TOOLTIP_OPEN_DELAY;

    return (
        <div className="matchmaking-network">
            <Button
                key={`abc ${filterType} ${isDisabled}`}
                className="matchmaking-network__btn"
                classes={{text: 'matchmaking-network__btn--text'}}
                color={'secondary'}
                variant={FILTER_TYPES.abc === filterType ? 'contained' : 'text'}
                onClick={handleAbcFilter}
                disabled={isDisabled}
            >
                ABC
            </Button>

            <Button
                key={`matches ${filterType} ${isDisabled}`}
                className="matchmaking-network__btn"
                classes={{text: 'matchmaking-network__btn--text'}}
                variant={FILTER_TYPES.matches === filterType ? 'contained' : 'text'}
                color="secondary"
                onClick={handleMatchesFilter}
                disabled={isDisabled}
            >
                MATCHES
                {isLargeScreen && (
                    <InfoTooltip
                        isDisabled={isDialogOpen}
                        timeOutEndCallback={handleSetTooltipSkipped}
                        timeOut={timeOut}
                        iconClassName="matchmaking-network__tooltip-icon-btn"
                        text={'Click on Matches to see who should connect with!'}
                    />
                )}
            </Button>
            {isDialogOpen && (
                <MatchmakingDialog
                    startPage={TABS_INDEXS.questionPage}
                    onClose={handleCLoseDialog}
                    open={isDialogOpen}
                />
            )}
        </div>
    );
};

MatchMaking.propTypes = {
    setFilters: PropTypes.func.isRequired,
    filterType: PropTypes.string.isRequired,
    userMatchmakingAnswers: PropTypes.array,
    eventId: PropTypes.string,
    userLoading: PropTypes.bool,
    onSetCompleteMatching: PropTypes.func,
    completeMatching: PropTypes.bool,
};

export default MatchMaking;
