import {agendaTypes, mediaTypes} from '../../constants/data';
import {emptyBusinessCardLogo, getImageLink} from 'services/helpers';

// Polls
const deserializePollOption = ({text, _id, ...rest}) => ({title: text, id: _id, ...rest});

export const deserializePoll = ({question, options, hidden, finished, _id, ...rest}) => ({
    id: _id,
    title: question,
    optionList: options.map(deserializePollOption),
    isHidden: hidden,
    isFinished: finished,
    isSaved: true,
    ...rest,
});

export const deserializePollList = (pollList) => pollList.map(deserializePoll);

const serializeCreatedPollOption = ({title}) => ({text: title});

export const serializeCreatedPoll = ({title, optionList, isHidden, isFinished, multiSelect, hideResults}) => ({
    question: title,
    options: optionList.map(serializeCreatedPollOption),
    hidden: isHidden,
    finished: isFinished,
    multiSelect,
    hideResults,
});

export const serializeUpdatedPollOption = ({title, id}) => {
    if (id) {
        return {text: title, _id: id};
    }

    return {text: title};
};

export const serializeUpdatedPoll = ({id, title, optionList, isHidden, isFinished, ...rest}) => ({
    _id: id,
    question: title,
    options: optionList.map(serializeUpdatedPollOption),
    hidden: isHidden,
    finished: isFinished,
    ...rest,
});

// Personal Agenda
const deserializeAgendaItem = ({
    _id,
    eventId = '',
    eventName = '',
    auditoriumId = '',
    auditoriumName = '',
    displayProgramId = '',
    programId = '',
    title,
    speakers = '',
    start,
    end,
    type,
    receiver = '',
    receiverName = '',
    userName = '',
    user,
    status,
    timeZoneString,
    groupChatId,
}) => ({
    id: _id,
    eventId,
    eventName,
    auditoriumId,
    auditoriumName,
    displayProgramId,
    timeslotId: programId,
    title,
    speakers,
    startTime: start,
    endTime: end,
    type,
    receiverId: receiver,
    receiverName,
    senderName: userName,
    senderId: user,
    meetingStatus: status,
    timeZone: timeZoneString,
    groupChatId,
});

const deserializeAgendaItemByDate = ({date, agenda}) => ({date, list: agenda.map(deserializeAgendaItem)});

export const deserializeAgendaListByDate = (list) => list.map(deserializeAgendaItemByDate);

export const serializeAgendaCustomEvent = ({
    title,
    date,
    startTime,
    endTime,
    timeZone,
    type = agendaTypes.custom,
    receiverId,
    eventId,
}) => {
    const data = {
        title,
        date,
        start: startTime,
        end: endTime,
        timeZone,
        type,
        receiver: receiverId,
    };

    return eventId ? {...data, eventId} : data;
};

export const normalizeTextByLength = (string, length) => {
    if (string.length < length) return string;
    const newString = `${string.slice(0, length)}`;
    return `${newString}...`;
};

export const normalizeMeetingSetupUser = ({_id, first, last, avatarSmall}) => ({
    id: _id,
    firstName: first,
    lastName: last,
    media: {type: mediaTypes.image, src: avatarSmall, srcSet: avatarSmall},
});

export const deserializeLogo = (logo) => {
    if (!logo) {
        return emptyBusinessCardLogo;
    }

    return {file: null, media: {title: logo, src: `${process.env.REACT_APP_BUSINESS_CARD_FOLDER}${logo}`}};
};

export const deserializeBusinessCard = (businessCard) => {
    if (!businessCard) {
        return null;
    }

    const {first, last, company, textIconColor, logo: _logo, _id, ...rest} = businessCard;
    const logo = deserializeLogo(_logo);

    delete rest.__v;
    delete rest.createdAt;
    delete rest.updatedAt;

    return {
        id: _id,
        firstName: first,
        lastName: last,
        secondaryColor: textIconColor,
        logo,
        companyName: company,
        ...rest,
    };
};

export const serializeBusinessCard = ({firstName, lastName, secondaryColor, id, companyName, ...rest}) => ({
    first: firstName,
    last: lastName,
    textIconColor: secondaryColor,
    company: companyName,
    ...rest,
});

export const normalizeProduct = ({name, _id, image = '', ...rest}, exhibitorId) => ({
    title: name,
    id: _id,
    media: {src: getImageLink(image, exhibitorId), title: image},
    ...rest,
});

export const normalizeProductList = (productList, exhibitorId) =>
    productList.map((product) => normalizeProduct(product, exhibitorId));
