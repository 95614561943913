import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as selectors from 'store/selectors';
import * as actions from 'store/actions';

import ExhibitorsOverlay from './ExhibitorsOverlay';

export default withRouter(
    connect(
        (state) => ({
            eventId: selectors.getEventId(state),
            exhibitorsFilters: selectors.getExhibitorsFilters(state),
            filteredExhibitors: selectors.getFilteredExhibitors(state),
            activeExhibitorId: selectors.getActiveExhibitorId(state),
            exhibitors: selectors.getApprovedExhibitors(state),
            exhibitorData: selectors.getExhibitorData(state),
            exhibitorsGroups: selectors.getAccessExhibitorsGroups(state),
            eventExhibitorFilters: selectors.getEventExhibitorFilters(state),
            eventExhibitorFiltersEventId: selectors.getEventExhibitorFiltersEventId(state),
        }),
        {
            onApplyExhibitorsFilters: actions.applyExhibitorsFilters,
            onSetActiveExhibitorId: actions.setActiveExhibitorId,
            onSetExhibitorsFilters: actions.setExhibitorsFilters,
            onGetGroupsList: actions.getGroupsList,
            onGetEventFilters: actions.getEventFilters,
        }
    )(ExhibitorsOverlay)
);
