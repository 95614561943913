import React from 'react';
import classNames from 'classnames';
import * as selectors from 'store/selectors';
import * as actions from 'store/actions';
import {useStyles} from './styles';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {ReactComponent as ArrowLeftIcon} from 'Images/arrow_left.svg';

export function TabPanel({children, setTabIndex, title, value, index, ...rest}) {
    const style = useStyles();
    const dispatch = useDispatch();
    const isRtlLanguage = useSelector(selectors.getIsRtlLanguage);
    const isLargeScreen = useSelector(selectors.getIsLargeScreen);

    const handleGoBack = () => {
        setTabIndex(-1);
        dispatch(actions.hideAccountNavigation(false));
    };

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            className={classNames(style.tabContent, 'organizer-dashboard-container')}
            {...rest}
        >
            <div className={classNames(style.contentWrapper, {[style.rtl]: isRtlLanguage})}>
                {!isLargeScreen && (
                    <div className={style.goBackWrapper} onClick={handleGoBack}>
                        <ArrowLeftIcon className={classNames(style.goBackIcon, {isRtl: isRtlLanguage})} />
                        <p className={style.goBackTitle}>{title}</p>
                    </div>
                )}

                {value === index && children}
            </div>
        </div>
    );
}

TabPanel.defaultProps = {
    title: '',
    setTabIndex: () => {},
};

TabPanel.propTypes = {
    children: PropTypes.node,
    value: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    setTabIndex: PropTypes.func,
    title: PropTypes.string,
};
