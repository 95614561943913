import React, {Component} from 'react';
import {Dialog} from '@material-ui/core';
import Slider from 'react-slick';

import {ReactComponent as SliderArrow} from '../../../../../../../Images/slider-arrow.svg';
import {ReactComponent as CloseSliderIcon} from '../../../../../../../Images/pages/platform-homepage/close-slider-icon.svg';

function NextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div className={className} style={{...style}} onClick={onClick}>
            <div className="arrow next">
                <SliderArrow />
            </div>
        </div>
    );
}

function PrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div className={className} style={{...style}} onClick={onClick}>
            <div className="arrow previous">
                <SliderArrow />
            </div>
        </div>
    );
}

class OnventLookFullScreenSlider extends Component {
    state = {
        isLandscape: window.orientation === 90,
    };

    componentDidMount() {
        window.addEventListener('orientationchange', this.setDeviceOrientation);
    }

    componentWillUnmount() {
        window.removeEventListener('orientationchange', this.setDeviceOrientation);
    }

    setDeviceOrientation = () => {
        this.setState({
            isLandscape: window.orientation === 90,
        });
    };

    render() {
        const {open, handleClose, slides} = this.props;
        const sliderSettings = {
            className: 'navigation-slider',
            dots: true,
            arrows: true,
            autoplay: false,
            fade: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />,
        };
        return (
            <Dialog fullScreen open={open} onClose={handleClose}>
                <div className={'fullscreen-slider-wrapper ' + (this.state.isLandscape ? 'landscape' : '')}>
                    <div className="fullscreen-slider-container">
                        <div onClick={handleClose} className="close-slider-button">
                            <CloseSliderIcon className="primary-fill-svg" />
                        </div>
                        <Slider {...sliderSettings}>
                            {slides.map((slide) => {
                                return (
                                    <div className="image-container" key={slide.image}>
                                        <img src={slide.image} srcSet={slide.imageSrcSet} alt="" />
                                        <p>Rotate device to see a fullscreen image</p>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default OnventLookFullScreenSlider;
