import {connect} from 'react-redux';
import * as selectors from 'store/selectors';

import ListMessages from './ListMessages';

export default connect(
    (state) => ({
        incomingOneToOneCallUserId: selectors.getIncomingCallUserId(state),
    }),
    null
)(ListMessages);
