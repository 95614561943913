import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import InfiniteLoader from 'react-window-infinite-loader';
import {FixedSizeList as List} from 'react-window';
import UserPreviewPlate from './components/UserPreviewPlate/UserPreviewPlate';
import {avatarFolder} from 'config';
import {
    checkIfIsContact,
    checkIfIsFriendConnectionReceived,
    checkIfIsFriendConnectionSent,
    checkIfIsOnline,
    formSocialList,
} from 'services/helpers';
import {FILTER_TYPES} from 'constants/shared';
import Spinner from 'Routes/components/Spinner';

import './UsersList.scss';
import {IconMatchingSearch} from 'Routes/components/Icons';
import useTheme from '@material-ui/core/es/styles/useTheme';

const UsersList = ({
    sideNavigation,
    onGetUserMatches,
    user,
    filterType,
    onlineUsers,
    onApplyParticipantsFilters,
    search,
    eventId,
    currentPage,
    setCurrentPage,
}) => {
    const theme = useTheme();
    const {sentRequests, receivedRequests, contacts} = user;

    const {
        participantFilters,
        filteredParticipants: {
            usersList,
            pagination: {page, perPage, total},
        },
        loading,
    } = sideNavigation;

    const hasNextPage = usersList.length < total;
    const itemCount = hasNextPage ? usersList.length + 1 : usersList.length;

    const getUselList = useCallback(() => {
        if (page !== currentPage) {
            if (filterType === FILTER_TYPES.abc) {
                onApplyParticipantsFilters({
                    eventId,
                    filter: participantFilters,
                    pagination: {page: currentPage, perPage},
                    search,
                });
            } else {
                onGetUserMatches({page: currentPage, perPage, search});
            }
        }
    }, [
        search,
        page,
        currentPage,
        filterType,
        onApplyParticipantsFilters,
        eventId,
        participantFilters,
        perPage,
        onGetUserMatches,
    ]);

    useEffect(() => {
        if (!loading) {
            getUselList();
        }
    }, [currentPage, getUselList, filterType, loading]);

    const loadMoreRows = useCallback(
        (startIndex, stopIndex) => {
            if (startIndex && startIndex === usersList.length && hasNextPage) {
                setCurrentPage(currentPage + 1);
            }
        },
        [currentPage, setCurrentPage, hasNextPage, usersList]
    );

    const isContact = useCallback((eventUser) => checkIfIsContact(eventUser, contacts), [contacts]);

    const isFriendConnectionSent = useCallback(
        (eventUser) => checkIfIsFriendConnectionSent(eventUser, sentRequests),
        [sentRequests]
    );

    const isFriendConnectionReceived = useCallback(
        (eventUser) => checkIfIsFriendConnectionReceived(eventUser, receivedRequests),
        [receivedRequests]
    );

    const isOnline = useCallback((eventUser) => checkIfIsOnline(eventUser, onlineUsers), [onlineUsers]);

    const rowRenderer = ({index, style}) => {
        const eventUser = {...usersList[index]};

        if (hasNextPage && index >= usersList.length) {
            return (
                <div key={eventUser.userId} style={style}>
                    <Spinner />
                </div>
            );
        }

        const userIsContact = isContact(eventUser);
        const userIsFriendConnectionSent = isFriendConnectionSent(eventUser);
        const userIsFriendConnectionReceived = isFriendConnectionReceived(eventUser);
        const canConnectToParticipant =
            !userIsContact && !userIsFriendConnectionSent && !userIsFriendConnectionReceived;

        return (
            <div className="networkig-userslist__user-plate" key={eventUser.userId} style={style}>
                <UserPreviewPlate
                    userId={eventUser.userId}
                    firstName={eventUser.first}
                    lastName={eventUser.last}
                    media={{
                        title: 'User avatar',
                        src: `${avatarFolder}${eventUser.avatarSmall}`,
                    }}
                    profession={eventUser.title}
                    company={eventUser.company}
                    socialList={formSocialList(eventUser)}
                    businessCardId={eventUser.businessCardId}
                    isCurrentUser={eventUser.userId === user._id}
                    isContact={userIsContact}
                    matchLevel={eventUser.matchLevel}
                    isFriendConnectionSent={userIsFriendConnectionSent}
                    canConnectToParticipant={canConnectToParticipant}
                    online={isOnline(eventUser)}
                />
            </div>
        );
    };

    if (!usersList.length && filterType === FILTER_TYPES.matches) {
        return (
            <div className="networkig-userslist">
                <div className="networkig-userslist__empty-matches">
                    <IconMatchingSearch
                        className="networkig-userslist__empty-matches-icon"
                        color={theme.palette.primary.main}
                    />
                    <div className="networkig-userslist__empty-matches-info">
                        <p className="networkig-userslist__empty-matches-info-text">
                            Thanks for trying matchmaking tool! We are searching for your best matches and will pair
                            your as soon as more participants will join the event.
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="networkig-userslist">
            <InfiniteLoader
                itemCount={itemCount}
                isItemLoaded={(index) => !!usersList[index]}
                loadMoreItems={loadMoreRows}
                threshold={2}
                minimumBatchSize={perPage}
            >
                {({onItemsRendered, ref}) => (
                    <List
                        className="networkig-userslist__virtualized-list"
                        ref={ref}
                        height={600}
                        itemSize={155}
                        itemCount={itemCount}
                        onItemsRendered={onItemsRendered}
                    >
                        {rowRenderer}
                    </List>
                )}
            </InfiniteLoader>
        </div>
    );
};

UsersList.propTypes = {
    sideNavigation: PropTypes.object,
    user: PropTypes.object,
    onlineUsers: PropTypes.object,
    onApplyParticipantsFilters: PropTypes.func,
    eventId: PropTypes.string,
    filterType: PropTypes.string,
    onGetUserMatches: PropTypes.func,
    search: PropTypes.string,
    currentPage: PropTypes.number,
    setCurrentPage: PropTypes.func,
};

export default React.memo(UsersList);
