import React, {PureComponent} from 'react';
import moment from 'moment/moment';
import {connect} from 'react-redux';

import AuditoriumSingleDayProgram from './components/AuditoriumSingleDayProgram/AuditoriumSingleDayProgram';

import {BUILDING_TYPES} from 'constants/shared';
import {getEventDaysArray, getMonth} from 'services/utils';

import './EventProgram.scss';

class EventProgram extends PureComponent {
    state = {
        eventDays: [],
        activeBuildingIdPerDay: []
    };

    componentDidMount() {
        const {eventInfo} = this.props;
        let eventDays = getEventDaysArray(this.props.eventInfo.eventStartDate, this.props.eventInfo.eventEndDate);

        let activeBuildingIdPerDay = [];
        // for each day, we initialize the buildingId with the first Building that has a program in that specific day
        // whenever a user clicks on a building, we modify the buildingId only for that specific day
        if (eventInfo.hasVillage) {
            const showfloorBuildings = eventInfo.buildings.filter(
                (building) => building.type === BUILDING_TYPES.auditorium || building.type === BUILDING_TYPES.mixed
            );

            eventDays.forEach((day, dayIndex) => {
                let buildingId = '';
                const buildingWithProgram = showfloorBuildings.find((building) => {
                    const isCurrentBuildingDayProgramEmpty = this.isCurrentBuildingDayProgramEmpty(building, dayIndex);
                    // if the building has a program in the current day (program is not empty)
                    // we found the first building with program
                    return !isCurrentBuildingDayProgramEmpty;
                });
                if (buildingWithProgram) {
                    buildingId = buildingWithProgram._id;
                }
                activeBuildingIdPerDay.push(buildingId);
            });
        }
        this.setState({
            eventDays: eventDays,
            activeBuildingIdPerDay: activeBuildingIdPerDay
        });
    }

    isCurrentDayProgramEmpty = (dayIndex) => {
        const {eventInfo} = this.props;
        const currentDayTimeslots = [];

        if (eventInfo.hasVillage) {
            const showfloorBuildings = eventInfo.buildings.filter(
                (building) => building.type === BUILDING_TYPES.auditorium || building.type === BUILDING_TYPES.mixed
            );
            showfloorBuildings.forEach((building) => {
                building.auditoriums.forEach((auditorium) => {
                    currentDayTimeslots.push(...auditorium.displayProgram[dayIndex].program);
                });
            });
        } else {
            eventInfo.auditoriums.forEach((auditorium) => {
                currentDayTimeslots.push(...auditorium.displayProgram[dayIndex].program);
            });
        }

        return currentDayTimeslots.length === 0;
    };

    isCurrentBuildingDayProgramEmpty = (building, dayIndex) => {
        const currentDayTimeslots = [];
        building.auditoriums.forEach((auditorium) => {
            currentDayTimeslots.push(...auditorium.displayProgram[dayIndex].program);
        });
        return currentDayTimeslots.length === 0;
    };

    isCurrentAuditoriumDayProgramEmpty = (auditorium, dayIndex) => {
        const currentDayTimeslots = auditorium.displayProgram[dayIndex].program;
        return currentDayTimeslots.length === 0;
    };

    setActiveBuildingPerDay = (dayIndex, buildingId) => (e) => {
        let updatedActiveBuildingIdPerDay = [...this.state.activeBuildingIdPerDay];
        updatedActiveBuildingIdPerDay[dayIndex] = buildingId;
        this.setState({
            activeBuildingIdPerDay: updatedActiveBuildingIdPerDay
        });
    };

    render() {
        const {eventInfo, translation, languages} = this.props;

        if (!eventInfo.showProgram) {
            return null;
        }

        let showfloorBuildings = [];
        if (eventInfo.hasVillage) {
            showfloorBuildings = eventInfo.buildings.filter(
                (building) => building.type === BUILDING_TYPES.auditorium || building.type === BUILDING_TYPES.mixed
            );
        }

        return (
            <div className="homepage-container">
                <div className="program-wrapper">
                    {this.props.breakpoint !== 'desktop' && (
                        <p className="event-description-container-title">PROGRAM</p>
                    )}
                    {this.state.eventDays.map((day, dayIndex) => {
                        const momentDay = moment(day).startOf('day');
                        const isCurrentDayProgramEmpty = this.isCurrentDayProgramEmpty(dayIndex);

                        return (
                            <div key={day} className="single-day-program-wrapper">
                                <div className="program-date">
                                    <div className="current-date">
                                        <div className="first">
                                            <span className="day">{momentDay.date()}</span>
                                        </div>
                                        <div className="second">
                                            <span className="month">
                                                {getMonth(day, languages.eventLanguage, true)}
                                            </span>
                                            <span className="year">{momentDay.year()}</span>
                                        </div>
                                    </div>
                                    {eventInfo.hasVillage && (
                                        <div className="buildings-list">
                                            {showfloorBuildings.map((building) => {
                                                const isCurrentBuildingDayProgramEmpty =
                                                    this.isCurrentBuildingDayProgramEmpty(building, dayIndex);
                                                if (isCurrentBuildingDayProgramEmpty) {
                                                    return null;
                                                }

                                                const isActiveBuilding =
                                                    building._id === this.state.activeBuildingIdPerDay[dayIndex];
                                                return (
                                                    <div
                                                        className={`name ${isActiveBuilding ? 'active' : ''}`}
                                                        key={building._id}
                                                        onClick={this.setActiveBuildingPerDay(dayIndex, building._id)}
                                                    >
                                                        <span>{building.name}</span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                                {isCurrentDayProgramEmpty ? (
                                    <div className="auditoriums-single-day-program-wrapper">
                                        <div className="single-auditorium-program">
                                            <p className="note">{eventInfo.brandingData.homepageNoProgramText}</p>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="auditoriums-single-day-program-wrapper">
                                        {eventInfo.hasVillage
                                            ? showfloorBuildings.map((building) => {
                                                  const isCurrentBuildingDayProgramEmpty =
                                                      this.isCurrentBuildingDayProgramEmpty(building, dayIndex);
                                                  if (isCurrentBuildingDayProgramEmpty) {
                                                      return null;
                                                  }
                                                  const isActiveBuilding =
                                                      building._id === this.state.activeBuildingIdPerDay[dayIndex];
                                                  return (
                                                      <div
                                                          className={`single-building-program ${
                                                              isActiveBuilding ? 'active' : ''
                                                          }`}
                                                          key={building._id}
                                                      >
                                                          <h3 className="building-name">{building.name}</h3>
                                                          {building.auditoriums.map((auditorium) => {
                                                              const isCurrentAuditoriumProgramEmpty =
                                                                  this.isCurrentAuditoriumDayProgramEmpty(
                                                                      auditorium,
                                                                      dayIndex
                                                                  );
                                                              if (isCurrentAuditoriumProgramEmpty) {
                                                                  return null;
                                                              }
                                                              return (
                                                                  <AuditoriumSingleDayProgram
                                                                      key={auditorium._id}
                                                                      auditorium={auditorium}
                                                                      displayedDate={day}
                                                                  />
                                                              );
                                                          })}
                                                      </div>
                                                  );
                                              })
                                            : eventInfo.auditoriums.map((auditorium) => {
                                                  return (
                                                      <AuditoriumSingleDayProgram
                                                          key={auditorium._id}
                                                          auditorium={auditorium}
                                                          displayedDate={day}
                                                      />
                                                  );
                                              })}
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
                {this.props.breakpoint === 'desktop' && (
                    <div
                        className="go-to-tab-button secondary-color"
                        onClick={this.props.setActiveProgramTabIndex(0, true)}
                        tabIndex={1}
                    >
                        <span>{translation?.homepage.tabProgramIntroButton}</span>
                    </div>
                )}
            </div>
        );
    }
}

export default connect(
    (state) => ({
        eventInfo: state.event.eventInfo
    }),
    null
)(EventProgram);
