import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Slider from 'react-slick';
import classNames from 'classnames';

import CloseTopNavButton from '../CloseTopNavButton/CloseTopNavButton';
import {NextProgramArrow, PrevProgramArrow} from './components/Arrows/Arrows';
import BuildingsTabs from './components/BuildingsTabs/BuildingsTabs';
import AuditoriumProgramTimeslotList from './components/AuditoriumProgramTimeslotList/AuditoriumProgramTimeslotList';

import {getEventDaysArray, getMonth, normalizeTimeZone} from 'services/utils';
import * as actions from 'store/actions/index';
import {AuditoriumArrow} from './components/AuditoriumArrow/AuditoriumArrow';
import './Program.scss';
import EmptyProgram from './components/EmptyProgram/EmptyProgram';
import ProgramDropdownDay from './components/ProgramDropdownDay/ProgramDropdownDay';
import {BUILDING_TYPES} from 'constants/shared';

const AIM_EVENT_SLUG = 'aim-digital-2020';

class Program extends React.Component {
    state = {
        nav1: null,
        nav2: null,
        eventAuditoriumBuildings: [],
        currentAuditoriumBuildingIndex: 0,
        translation: null,
    };

    componentDidMount() {
        const {event} = this.props;
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });

        if (event.hasVillage) {
            this.setEventAuditoriumBuildings();
        }

        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    setEventAuditoriumBuildings = () => {
        const {event, building} = this.props;
        const auditoriumBuildings = event.buildings.filter(
            (building) => building.type === BUILDING_TYPES.auditorium || building.type === BUILDING_TYPES.mixed
        );

        if (event?.slug === AIM_EVENT_SLUG) {
            auditoriumBuildings.reverse();
        }

        this.setState({
            eventAuditoriumBuildings: auditoriumBuildings,
        });

        // if we are on a building page, and the building is a conference type
        // by default we should always open the current building program tab
        const currentPathname = this.props.history?.location?.pathname;
        const buildingRoot = `/event/${event?.slug}/village/building/${building?._id}`;
        const isBuildingPage = currentPathname.includes(buildingRoot);
        if (isBuildingPage && building && building.type === 'conference') {
            let currentBuildingIndex = auditoriumBuildings.findIndex(
                (auditoriumBuilding) => auditoriumBuilding._id === building._id
            );
            if (currentBuildingIndex > 0) {
                this.setState({currentAuditoriumBuildingIndex: currentBuildingIndex});
            }
        }
    };

    getProgramDayString = (currentDay) => {
        let currentDate = moment(currentDay).startOf('day');
        let day = currentDate.date();
        let month = getMonth(currentDay, this.props.languages.platformLanguage, true);
        let year = currentDate.year();

        return `${day} ${month} ${year}`;
    };

    getDayLabelString = (currentDay) => {
        const today = moment(new Date()).startOf('day');
        const day = moment(currentDay).startOf('day');
        const diferenceInDays = day.diff(today, 'days');
        let daysOfTheWeek = [
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.monday,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.tuesday,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.wednesday,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.thursday,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.friday,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.saturday,
            this.props.languages.translations[this.props.languages.platformLanguage]?.time.sunday,
        ];

        let currentDayString = '';
        if (diferenceInDays === -1) {
            currentDayString = this.props.languages.translations[this.props.languages.platformLanguage]?.time.yesterday;
        } else if (diferenceInDays === 0) {
            currentDayString = this.props.languages.translations[this.props.languages.platformLanguage]?.time.today;
        } else if (diferenceInDays === 1) {
            currentDayString = this.props.languages.translations[this.props.languages.platformLanguage]?.time.tomorrow;
        } else {
            // currentDayString = day.format('dddd');
            currentDayString = daysOfTheWeek[day.isoWeekday() - 1];
        }
        return currentDayString;
    };

    goToTimeslotVideo = (timeslotLink) => (e) => {
        this.props.onCloseTopNav();
        this.props.history.push(timeslotLink);
    };

    goToPreviousSlide = () => this.slider2.slickPrev();

    goToNextSlide = () => this.slider2.slickNext();

    getDropdownWidth = (eventDays, numberOfAuditoriumsPlayingAtTheSameTime) => {
        let dropdownWidth = 600;
        if (eventDays && eventDays.length === 1) {
            if (numberOfAuditoriumsPlayingAtTheSameTime <= 1) {
                dropdownWidth = 360;
            } else {
                dropdownWidth = 560;
            }
        } else {
            if (numberOfAuditoriumsPlayingAtTheSameTime <= 1) {
                dropdownWidth = 540;
            } else {
                dropdownWidth = 560;
            }
        }
        return dropdownWidth;
    };

    getInitialSlide = () => {
        // open the program to the current day

        let currentDayIndex = 0;
        const today = moment(new Date());
        let eventDays = getEventDaysArray(
            this.props.event.eventStartDate,
            this.props.event.eventEndDate,
            this.props.languages.isRtlLanguage
        );
        eventDays.forEach((eventDay, index) => {
            const day = moment(new Date(eventDay));
            if (today.isSame(day, 'day')) {
                currentDayIndex = index;
            }
        });
        return currentDayIndex;
    };

    getSliderSettings1 = () => {
        const initialSlide = this.getInitialSlide();
        return {
            initialSlide: initialSlide,
            dots: false,
            arrows: false,
            autoplay: false,
            draggable: false,
            pauseOnHover: false,
            swipe: false,
            speed: 500,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false,
        };
    };

    getSliderSettings2 = (eventDays) => {
        const initialSlide = this.getInitialSlide();
        return {
            initialSlide: initialSlide,
            className: 'navigation-slider',
            dots: false,
            autoplay: false,
            draggable: true,
            pauseOnHover: false,
            swipe: true,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            focusOnSelect: true,
            nextArrow: (
                <NextProgramArrow
                    handleOnClick={this.goToNextSlide}
                    programDays={eventDays}
                    getProgramDayString={this.getProgramDayString}
                    getDayLabelString={this.getDayLabelString}
                />
            ),
            prevArrow: (
                <PrevProgramArrow
                    handleOnClick={this.goToPreviousSlide}
                    programDays={eventDays}
                    getProgramDayString={this.getProgramDayString}
                    getDayLabelString={this.getDayLabelString}
                />
            ),
        };
    };

    setBuildingIndex = (buildingIndex) => {
        this.setState({
            currentAuditoriumBuildingIndex: buildingIndex,
        });
    };

    render() {
        const {eventAuditoriumBuildings, currentAuditoriumBuildingIndex} = this.state;
        const {isLargeScreen, event, isRtlLanguage} = this.props;

        let eventDays = getEventDaysArray(
            this.props.event.eventStartDate,
            this.props.event.eventEndDate,
            isRtlLanguage
        );
        let sliderSettings1 = this.getSliderSettings1();
        let sliderSettings2 = this.getSliderSettings2(eventDays);

        const sliderSettings3 = {
            dots: false,
            arrows: true,
            autoplay: false,
            draggable: true,
            pauseOnHover: false,
            swipe: true,
            speed: 500,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            adaptiveHeight: false,
            nextArrow: <AuditoriumArrow isNext />,
            prevArrow: <AuditoriumArrow />,
        };

        const positionProp = isRtlLanguage ? 'right' : 'left';

        if (!this.props.event) {
            return (
                <div
                    className="auditoriums-program-dropdown  desktop-program-dropdown"
                    style={{
                        [positionProp]: this.props.offsetLeft + 43,
                    }}
                >
                    <CloseTopNavButton />
                </div>
            );
        }

        const eventTimeZone = normalizeTimeZone(event.utcTimezone);

        return (
            <div
                className="auditoriums-program-dropdown  desktop-program-dropdown"
                style={{
                    left: this.props.offsetLeft ? this.props.offsetLeft + 43 : 0,
                }}
            >
                <CloseTopNavButton />
                <div className="auditoriums-program-dropdown__wrapper">
                    {event.hasVillage && (
                        <BuildingsTabs
                            currentAuditoriumBuildingIndex={currentAuditoriumBuildingIndex}
                            eventAuditoriumBuildings={eventAuditoriumBuildings}
                            setBuildingIndex={this.setBuildingIndex}
                        />
                    )}
                    <div
                        className={classNames('auditoriums-program-dropdown__page-title page-title', {
                            isRtl: isRtlLanguage,
                        })}
                    >
                        <Slider
                            {...sliderSettings2}
                            asNavFor={this.state.nav1}
                            ref={(slider) => (this.slider2 = slider)}
                            swipeToSlide={true}
                            focusOnSelect={true}
                        >
                            {eventDays &&
                                eventDays.map((day, i) => {
                                    //in rtl slider is in reverse so we need to use reversed day value || need to be fixed or removed by rtl-friendly slider library TODO:
                                    const currentDay = !isRtlLanguage ? day : eventDays[eventDays.length - 1 - i];
                                    const weekday = moment(currentDay).startOf('day');
                                    return (
                                        <div key={currentDay}>
                                            <ProgramDropdownDay
                                                isToday
                                                date={this.getProgramDayString(currentDay)}
                                                weekday={this.getDayLabelString(weekday)}
                                            />
                                        </div>
                                    );
                                })}
                        </Slider>
                    </div>
                    <div className="auditoriums-program-dropdown__slider">
                        <div className="auditoriums-program-dropdown__timezone">
                            <h2>{`Timezone: ${eventTimeZone}`}</h2>
                        </div>
                        <Slider
                            {...sliderSettings1}
                            className="auditoriums-program-dropdown__slider--1"
                            asNavFor={this.state.nav2}
                            ref={(slider) => (this.slider1 = slider)}
                        >
                            {eventDays &&
                                eventDays.map((day, dayIndex) => {
                                    const currentDayTimeslots = [];

                                    let currentProgramAuditoriums = [];
                                    if (event.hasVillage) {
                                        currentProgramAuditoriums =
                                            eventAuditoriumBuildings[currentAuditoriumBuildingIndex]?.auditoriums;
                                    } else {
                                        currentProgramAuditoriums = event.auditoriums;
                                    }

                                    currentProgramAuditoriums &&
                                        currentProgramAuditoriums.forEach((auditorium) => {
                                            currentDayTimeslots.push(...auditorium.displayProgram[dayIndex].program);
                                        });
                                    if (currentDayTimeslots.length === 0) {
                                        return <EmptyProgram key={day} />;
                                    }
                                    return (
                                        <div key={day}>
                                            <div className="auditoriums-program-dropdown__program-slider-wrapper">
                                                <Slider {...sliderSettings3}>
                                                    {currentProgramAuditoriums.map((auditorium, auditoriumIndex) => {
                                                        if (
                                                            auditorium.displayProgram[dayIndex] &&
                                                            auditorium.displayProgram[dayIndex].program.length === 0
                                                        ) {
                                                            return null;
                                                        }
                                                        return (
                                                            <div key={auditorium._id}>
                                                                <div
                                                                    className={classNames(
                                                                        'auditoriums-program-dropdown__auditorium-name',
                                                                        {isRtl: isRtlLanguage}
                                                                    )}
                                                                >
                                                                    <p>{auditorium.name}</p>
                                                                </div>
                                                                <div>
                                                                    {auditorium.displayProgram[dayIndex] &&
                                                                    auditorium.displayProgram[dayIndex].program.length >
                                                                        0 ? (
                                                                        <AuditoriumProgramTimeslotList
                                                                            list={
                                                                                auditorium.displayProgram[dayIndex]
                                                                                    .program
                                                                            }
                                                                            auditoriumId={auditorium._id}
                                                                            displayProgramId={
                                                                                auditorium.displayProgram[dayIndex]._id
                                                                            }
                                                                            isLargeScreen={isLargeScreen}
                                                                        />
                                                                    ) : (
                                                                        <EmptyProgram />
                                                                    )}
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </Slider>
                                            </div>
                                        </div>
                                    );
                                })}
                        </Slider>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(
    connect(
        (state) => ({
            event: state.event.data,
            eventSlug: state.event.eventSlug,
            user: state.user.data,
            isLargeScreen: state.layout.isLargeScreen,
            building: state.building.data,
            languages: state.languages,
            isRtlLanguage: state.languages.isRtlLanguage,
        }),
        {
            onCloseTopNav: actions.topNavClose,
        }
    )(Program)
);
