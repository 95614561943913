import React from 'react';
import {NoResult} from '../';
import {usePlatformTranslation} from '../../../../../../../../../services/hooks';
import {EVENTS_TYPE} from '../../../../../../../../../constants/eventListing';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../../../../../../../../store/actions';
import {RenderEventList} from '../';

export default function PastEvents({exploreMore}) {
    const dispatch = useDispatch();
    const {eventsListing} = usePlatformTranslation();
    const eventsList = useSelector((state) => state.eventListing.savedEventsList);

    React.useEffect(() => {
        dispatch(actions.getSavedEventsList(EVENTS_TYPE.PAST));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {eventsList && !!eventsList.length ? (
                <RenderEventList data={eventsList} typeToLoad={EVENTS_TYPE.PAST} />
            ) : (
                <NoResult exploreMore={exploreMore} text={eventsListing.youHaveNoPastEvents} />
            )}
        </>
    );
}
