import React from 'react';
import './AuditorumResourcesOutput.scss';
import {Scrollbars} from 'react-custom-scrollbars';
import AttachmentIcon from '@material-ui/icons/Attachment';
import {useSelector} from 'react-redux';
import {eventFolder} from '../../../config';

const AuditorumResourcesOutput = ({documents, links}) => {
    const {eventId} = useSelector((state) => state.event);
    const renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `rgb(137,137,137)`,
            left: 0,
        };
        return <div className="grey-scroll" style={{...style, ...thumbStyle}} {...props} />;
    };

    return (
        <div className="resources-container">
            {true ? (
                <Scrollbars
                    className="scrollbar"
                    renderThumbVertical={renderThumb}
                    style={{width: '100%', height: '100%'}}
                >
                    {links.length > 0 && (
                        <>
                            <p className="title">links</p>
                            <ul className="resources-list ">
                                {links.map((link) => {
                                    return (
                                        <li className="resources-list-item" key={link._id}>
                                            <a href={link.link} className="link" target="blanks">
                                                <span>{link.displayName}</span>
                                                <AttachmentIcon className="icon" />
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </>
                    )}
                    {documents.length > 0 && (
                        <>
                            <p className="title">documents</p>
                            <ul className="resources-list ">
                                {documents.map((document) => {
                                    return (
                                        <li className="resources-list-item" key={document._id}>
                                            <a
                                                href={`${eventFolder}${eventId}/${document.file}`}
                                                className="link"
                                                target="blanks"
                                                rel="noopener noreferrer"
                                            >
                                                <span>{document.displayName}</span>
                                                <AttachmentIcon className="icon" />
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </>
                    )}
                </Scrollbars>
            ) : (
                <div className="poll">
                    <div className="poll_header">
                        <div className="poll_title">No resources yet</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AuditorumResourcesOutput;
