import {connect} from 'react-redux';
import {getJoinedUsers, getUserData, getUserRoles, getIsUserKicked} from 'store/selectors';
import {PermissionsModule} from './PermissionsModule';

export default connect((state) => ({
    joinedUsers: getJoinedUsers(state),
    currentUser: getUserData(state),
    userRoles: getUserRoles(state),
    isKicked: getIsUserKicked(state),
}))(PermissionsModule);
