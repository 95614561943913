import {Api} from '../../Api';
import * as actionTypes from '../actionTypes/eventsWithApplicationsActionTypes';

export const getEventsWithAppliedJobsStart = () => {
    return {
        type: actionTypes.GET_EVENT_WITH_APPLICATIONS_START,
    };
};
export const getEventsWithAppliedJobsSuccess = (event) => {
    return {
        type: actionTypes.GET_EVENT_WITH_APPLICATIONS_SUCCESS,
        payload: event,
    };
};
export const getEventsWithAppliedJobsFail = () => {
    return {
        type: actionTypes.GET_EVENT_WITH_APPLICATIONS_FAIL,
    };
};

export const getEventsWithAppliedJobs = (userId) => async (dispatch, getState) => {
    const {
        eventsWithApplications: {loading},
    } = getState();

    dispatch(getEventsWithAppliedJobsStart());

    try {
        const response = await Api.request({
            method: 'get',
            url: `/users/${userId}/events-with-applications`,
            loading,
        });

        dispatch(getEventsWithAppliedJobsSuccess(response));
    } catch (error) {
        dispatch(getEventsWithAppliedJobsFail(error));
    }
};
