import React from 'react';
import classNames from 'classnames';

import ColoredScrollbars from 'Routes/components/ColoredScrollbars/ColoredScrollbars';
import CloseTopNavButton from '../CloseTopNavButton/CloseTopNavButton';

import {getTimeAgoString, getSortedNotifications} from 'services/utils';
import {ReactComponent as EmptyNotificationsIcon} from 'Images/green-notifications.svg';
import 'styles/notifications.scss';

export class Notifications extends React.Component {
    state = {
        isActive: false,
        translation: null,
    };

    componentDidMount() {
        this.setLanguagesData();
        this.props.seeAllNotifications();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    addActiveClass() {
        this.setState({
            isActive: !this.state.isActive,
        });
    }

    getNotificationText = ({type, text}) => {
        let notificationText;
        switch (type) {
            case 'contactRequestReceived':
                notificationText =
                    this.props.languages.translations[this.props.languages.platformLanguage]?.notificationsDropdown
                        .contactRequestReceived;
                break;
            case 'contactRequestAccepted':
                notificationText =
                    this.props.languages.translations[this.props.languages.platformLanguage]?.notificationsDropdown
                        .contactRequestAccepted;
                break;
            case 'newBoothPost':
                notificationText =
                    this.props.languages.translations[this.props.languages.platformLanguage]?.notificationsDropdown
                        .newBoothPost;
                break;

            case 'personalAgendaItemUpdated':
            case 'personalAgendaItemDeleted':
            case 'personalAgendaMeetingAccepted':
            case 'personalAgendaMeetingRequested':
            case 'personalAgendaMeetingCancelled':
                notificationText = text;
                break;

            default:
        }
        return notificationText;
    };

    handleNotificationClick = (notification) => (e) => {
        switch (notification.type) {
            case 'newBoothPost':
                // redirect the user to his booth
                this.props.history.push(`/event/${this.props.eventSlug}/showfloor?boothId=${notification.objectId}`);
                break;
            case 'contactRequestAccepted':
                this.props.onOpenWallet('card', '', notification.user._id);
                // this.props.onOpenWallet()
                break;
            case 'contactRequestReceived':
                this.props.onOpenWallet('wallet', 'requests');
                break;

            case 'personalAgendaMeetingAccepted':
            case 'personalAgendaMeetingCancelled':
            case 'personalAgendaMeetingRequested':
                this.props.seeAgenda({activeTab: 'meetings'});
                break;

            case 'personalAgendaItemUpdated':
            case 'personalAgendaItemDeleted':
                this.props.seeAgenda();
                break;

            default:
                this.props.onOpenWallet();
                break;
        }
    };

    render() {
        let notifications = getSortedNotifications(this.props.notifications, this.props.user?._id);
        notifications = notifications.filter((el) => el.user !== null);
        const {mobile, offset, offsetWidth, isRtlLanguage} = this.props;
        const {translation} = this.state;

        const dropdownPosition = isRtlLanguage ? offset : offset - 323 + offsetWidth;

        return (
            <div
                className={classNames('notifications-page dropdown-component', {
                    'notifications-mobile': mobile,
                    isRtl: isRtlLanguage,
                })}
                style={{left: dropdownPosition}}
            >
                <CloseTopNavButton />
                <div className="page-title">
                    <h3>{translation?.notificationsDropdown.title}</h3>
                </div>
                {notifications.length > 0 ? (
                    <div
                        className={classNames('notifications-container', {
                            active: this.state.isActive,
                        })}
                        style={{height: notifications.length * 43 + 20}}
                    >
                        {this.state.isActive ? (
                            <ColoredScrollbars>
                                <ul className={`notifications-list ${this.state.isActive ? 'active' : ''}`}>
                                    {notifications.map((notification) => {
                                        const timeAgoString = getTimeAgoString(
                                            notification.createdAt,
                                            this.props.languages.platformLanguage
                                        );
                                        const notificationText = this.getNotificationText(notification);
                                        return (
                                            <li key={notification._id}>
                                                <div
                                                    className="single-notification-text"
                                                    onClick={this.handleNotificationClick(notification)}
                                                >
                                                    <div className="contacts-wrapper">
                                                        {notification.user ? (
                                                            <span className="user-name">
                                                                {notification.user.first} {notification.user.last}
                                                            </span>
                                                        ) : null}
                                                        <span>{notificationText}</span>
                                                    </div>
                                                    <div className="date-time">
                                                        <span>{timeAgoString}</span>
                                                    </div>
                                                </div>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </ColoredScrollbars>
                        ) : (
                            <ul className="notifications-list">
                                {notifications.map((notification) => {
                                    const timeAgoString = getTimeAgoString(
                                        notification.createdAt,
                                        this.props.languages.platformLanguage
                                    );
                                    const notificationText = this.getNotificationText(notification);
                                    return (
                                        <li key={notification._id}>
                                            <div
                                                className="single-notification-text"
                                                onClick={this.handleNotificationClick(notification)}
                                            >
                                                <div className="contacts-wrapper">
                                                    {notification.user ? (
                                                        <span className="user-name">
                                                            {notification.user.first} {notification.user.last}
                                                        </span>
                                                    ) : null}
                                                    <span>{notificationText}</span>
                                                </div>
                                                <div className="date-time">
                                                    <span>{timeAgoString}</span>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                ) : (
                    <div className="empty-notifications">
                        <p>{translation?.notificationsDropdown.noNotifications}</p>
                        <EmptyNotificationsIcon className="primary-fill-svg" />{' '}
                    </div>
                )}

                {notifications.length > 6 ? (
                    <div className="see-all-notifications">
                        <p onClick={this.addActiveClass.bind(this)}>
                            {this.state.isActive
                                ? translation?.notificationsDropdown.seeAll
                                : translation?.notificationsDropdown.hideAll}{' '}
                        </p>
                    </div>
                ) : null}
            </div>
        );
    }
}
