import * as actionTypes from '../actionTypes/socialsPromotionTypes';

const initialState = {
    data: null,
    error: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SOCIALS_PROMOTION_REQUEST_SUCCESS:
        case actionTypes.SOCIALS_PROMOTION_UPDATE_SUCCESS:
            return {
                ...state,
                data: action.payload,
                error: null,
            };

        case actionTypes.SOCIALS_PROMOTION_REQUEST_ERROR:
        case actionTypes.SOCIALS_PROMOTION_UPDATE_ERROR:
            return {
                ...state,
                data: null,
                error: action.payload,
            };
        default:
            return state;
    }
};

export default reducer;
