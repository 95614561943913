import React from 'react';

import FullPageSpinner from '../FullPageSpinner';
import NotAvailable from '../NotAvailable/NotAvailable';
import NotFound from 'Routes/components/NotFound';
import {transformLink} from 'services/utils';
import {RouterConfig} from 'routerConfig';

export default class EventPrivateComponent extends React.PureComponent {
    state = {
        eventSlug: this.props.match.params.eventSlug,
        buildingId: this.props.match.params.buildingId,
    };

    componentDidMount() {
        const {eventSlug, event, eventId} = this.props;

        if ((this.state.eventSlug && this.state.eventSlug !== eventSlug) || (event && event.slug !== eventSlug)) {
            // event && event.slug !== eventSlug  is a fix for navigating between events
            this.props.setEventSlug(this.state.eventSlug);
        }
        if (eventSlug && !eventId) {
            this.props.setEventIdBySlug(this.state.eventSlug);
        }
        if (!event && eventId) {
            this.props.onGetEvent(eventId);
        }

        if (event && event._id !== eventId) {
            this.props.onGetEvent(eventId);
        }

        // user lands directly on a building page, we must set the buildingId to the global store
        if (this.state.buildingId) {
            this.props.onSetBuildingId(this.state.buildingId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {eventId, loadingEvent, eventSlug, building, buildingId, loadingBuilding} = this.props;

        if (prevProps.eventSlug !== eventSlug && eventSlug && !loadingEvent) {
            this.props.setEventIdBySlug(eventSlug);
            this.setState({eventSlug});
        }
        if (prevProps.eventId !== eventId && eventId && !loadingEvent) {
            this.props.onGetEvent(eventId);
        }

        // if the user lands on a buildingPage
        // after we get the eventId and have the buildingId
        // get the building Data
        if (eventId && buildingId && !loadingBuilding && !building) {
            this.props.onGetBuilding(eventId, buildingId);
        }

        if (this.state.buildingId && this.state.buildingId !== this.props.match.params.buildingId) {
            this.setState({buildingId: this.props.match.params.buildingId});
        }

        // if user navigates to a new building
        // get the new buildingData
        if (eventId && prevProps.buildingId !== buildingId && !loadingBuilding) {
            if (buildingId) {
                this.props.onGetBuilding(eventId, buildingId);
            }
            if (this.state.buildingId) {
                this.props.onSetBuildingId(this.state.buildingId);
            }
        }
    }

    render() {
        const {event, buildingId, eventNotFound, building} = this.props;

        if (!event && transformLink(document.location.pathname) === RouterConfig.createEvent) {
            return this.props.children;
        }

        if (!event && eventNotFound) {
            return <NotFound />;
        }

        if (!event) {
            return <FullPageSpinner />;
        }
        if (event && event.owner.organizerStatus === 'disabled') {
            return <NotAvailable />;
        }

        // event with village setup cases

        // event is normal setup (no village) and the user lands on a /building route
        if (event && !event.hasVillage && this.state.buildingId) {
            return <NotAvailable />;
        }

        // event is village setup, user is on a building page but we don't have the building data yet
        if (event.hasVillage && (this.state.buildingId || buildingId) && (!building || building?._id !== buildingId)) {
            return <FullPageSpinner />;
        }
        return this.props.children;
    }
}
