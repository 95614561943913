import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

export const exhibitor = (can, cannot) => {
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.EXHIBITOR_DASHBOARD);
    cannot(ROLE_ABILITIES_TYPES.EXHIBITOR.VIEW_WITH_DISABLED_EXHIBITORS_LOGIN, ROLE_FEATURES_TYPES.EXHIBITOR_DASHBOARD);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.CHAT_WITH_ORGANIZER_REP);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.BOOTH_WALL_PIN_MESSAGE);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.BUILDING_SHOWFLOOR_INFO_SCREEN);
    can(ROLE_ABILITIES_TYPES.EXHIBITOR.SET_USER_INFO, ROLE_FEATURES_TYPES.ACCOUNT_PROFILE_INFO);
    can(ROLE_ABILITIES_TYPES.EXHIBITOR.CHANGE_ACCESS_PACKAGE, ROLE_FEATURES_TYPES.ACCESS_PACKAGE);
    can(ROLE_ABILITIES_TYPES.EXHIBITOR.VIEW_USER_INFO, ROLE_FEATURES_TYPES.ACCESS_PACKAGES_LIST);
    can(ROLE_ABILITIES_TYPES.EXHIBITOR.GET_INFO, ROLE_FEATURES_TYPES.EVENT_PROFILE);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.EVENT_PROFILE);
    can(ROLE_ABILITIES_TYPES.EXHIBITOR.NAVIGATE, ROLE_FEATURES_TYPES.EXHIBITOR_DASHBOARD);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.VIEW_MATHCING);
};

export const exhibitorRep = (can, cannot) => {
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.CHAT_WITH_ORGANIZER_REP);
    can(ROLE_ABILITIES_TYPES.EXHIBITOR_REP.NAVIGATE, ROLE_FEATURES_TYPES.EXHIBITOR_BOOTH);
};

export const organizerRep = (can, cannot) => {
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.CHAT_WITH_ORGANIZER_REP);
};

export const participant = (can, cannot) => {
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.EXHIBITOR_DASHBOARD);
    cannot(ROLE_ABILITIES_TYPES.PARTICIPANT.VIEW_WITH_DISABLED_PARTICIPANTS_LOGIN, ROLE_FEATURES_TYPES.BUILDING_LOBBY);
    cannot(ROLE_ABILITIES_TYPES.PARTICIPANT.VIEW_WITH_DISABLED_PARTICIPANTS_LOGIN, ROLE_FEATURES_TYPES.LOBBY);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.CHAT_WITH_ORGANIZER_REP);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.APPLIED_JOBS_TABLE);
    can(ROLE_ABILITIES_TYPES.PARTICIPANT.SEND_STATISTICS, ROLE_FEATURES_TYPES.STATISTICS.DOCUMENT_CLICKS);
    can(ROLE_ABILITIES_TYPES.PARTICIPANT.SEND_STATISTICS, ROLE_FEATURES_TYPES.STATISTICS.LINK_CLICKS);
    can(ROLE_ABILITIES_TYPES.PARTICIPANT.SEND_STATISTICS, ROLE_FEATURES_TYPES.STATISTICS.ALL_BOOTH_STATISTICS);
    can(ROLE_ABILITIES_TYPES.PARTICIPANT.GET_INFO, ROLE_FEATURES_TYPES.EVENT_ARCHIVE);
    can(ROLE_ABILITIES_TYPES.PARTICIPANT.GET_INFO, ROLE_FEATURES_TYPES.EVENT_PROFILE);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.EVENT_PROFILE);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.VIEW_MATHCING);
};

export const organizer = (can, cannot) => {
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.ORGANIZER_DASHBOARD);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.EXHIBITOR_DASHBOARD_SUPPORT);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.EXHIBITOR_DASHBOARD);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.CHAT_WITH_ORGANIZER_REP);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.RESTRICT_AUDITORIUM_ACCESS);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.RESTRICT_SHOWFLOOR_ACCESS);
    cannot(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.UNREGISTER_EVENT);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.PROCEED_TO_EVENT_SESSIONS);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.CLOSED_WALL);
    can(ROLE_ABILITIES_TYPES.COMMON.DELETE_ABILITY, ROLE_FEATURES_TYPES.WALLS_COMMENT);
    can(ROLE_ABILITIES_TYPES.COMMON.DELETE_ABILITY, ROLE_FEATURES_TYPES.WALLS_SINGLE_POST);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.WALLS_PIN_MESSAGE);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.BOOTH_WALL_PIN_MESSAGE);
    can(ROLE_ABILITIES_TYPES.PARTICIPANT.VIEW_WITH_DISABLED_PARTICIPANTS_LOGIN, ROLE_FEATURES_TYPES.LOBBY);
    can(ROLE_ABILITIES_TYPES.PARTICIPANT.VIEW_WITH_DISABLED_PARTICIPANTS_LOGIN, ROLE_FEATURES_TYPES.BUILDING_LOBBY);
    can(ROLE_ABILITIES_TYPES.COMMON.CREATE_ABILITY, ROLE_FEATURES_TYPES.EVENT_CREATE);
    can(ROLE_ABILITIES_TYPES.COMMON.APPROVE_WALL_ABILITY, ROLE_FEATURES_TYPES.WALL_APPROVE);
    can(ROLE_ABILITIES_TYPES.COMMON.APPROVE_WALL_ABILITY, ROLE_FEATURES_TYPES.VIDEO_WALL_APPROVE);
    can(ROLE_ABILITIES_TYPES.COMMON.APPROVE_WALL_ABILITY, ROLE_FEATURES_TYPES.BOOTH_WALL_APPROVE);
    cannot(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.VIEW_MATHCING);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_STREAM_CONTROLS);
    can(ROLE_ABILITIES_TYPES.COMMON.CREATE_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_VIDEO_STREAM);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_TOGGLE_SPEAKER_MEDIA);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_TOGGLE_MODERATOR_MEDIA);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_KICK_USER);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_DASHBOARD);
};

export const coOrganizer = (can, cannot) => {
    organizer(can, cannot);
    can(ROLE_ABILITIES_TYPES.CO_ORGANIZER.MANAGE_ABILITY, ROLE_FEATURES_TYPES.ORGANIZER_DASHBOARD_NAVIGATION);
};

export const interpreter = (can, cannot) => {
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.SESSION_LIST_DASHBOARD);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.INTERPRETER_SESSION_LIST_BUTTON);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.INTERPRETER_DEVICES_CHECK);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.INTERPRETER_AUDIO_STREAM_BUTTONS);
    cannot(ROLE_ABILITIES_TYPES.COMMON.CREATE_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_VIDEO_STREAM);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_STREAM_CONTROLS);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_DASHBOARD);
};

export const moderator = (can, cannot) => {
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.SESSION_LIST_DASHBOARD);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.MODERATOR_SESSION_LIST_BUTTON);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_STREAM_CONTROLS);
    can(ROLE_ABILITIES_TYPES.COMMON.CREATE_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_VIDEO_STREAM);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_TOGGLE_SPEAKER_MEDIA);
    can(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_KICK_USER);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_DASHBOARD);
};

export const speaker = (can, cannot) => {
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.SESSION_LIST_DASHBOARD);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.SPEAKER_SESSION_LIST_BUTTON);
    can(ROLE_ABILITIES_TYPES.COMMON.CREATE_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_VIDEO_STREAM);
    can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.ETX_SESSION_DASHBOARD);
};
