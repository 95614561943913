import React from 'react';

export const ArabicContent = () => (
    <div className="header-spacing-container" dir="rtl">
        <div className="gdpr-container">
            <h1>سياسة / إشعار الخصوصية</h1>
            <h4>تاريخ سريان المفعول: 24/07/2020</h4>

            <p>
                تنظم سياسة الخصوصية الخاصة بشركة إيفينتس 10 إكس المحدودة مسائل الخصوصية الخاصة بإيفينت 10 إكس (المنصة).
                باستخدام منصتنا، فإنك توافق على شروط معالجة بياناتك الشخصية أدناه.
            </p>

            <h2>التعريفات</h2>
            <p>
                تحمل المصطلحات الواردة أدناه المعاني المبينة قرين تلك المصطلحات في اللائحة (الاتحاد الأوروبي) 2016/679
                (اللائحة العامة لحماية البيانات):
            </p>
            <ul>
                <li>
                    <p>
                        <b>البيانات الشخصية </b>مصطلح يقصد به شخص طبيعي يمكن التعرف عليه يكون شخصًا يمكن تحديد هويته،
                        بشكل مباشر أو غير مباشر، ولا سيما بالرجوع إلى معرّف مثل الاسم ورقم التعريف وبيانات الموقع وما
                        إلى ذلك؛
                    </p>
                </li>
                <li>
                    <p>
                        <b>المعالجة </b>مصطلح يقصد به أي عملية أو مجموعة عمليات يتم إجراؤها على البيانات الشخصية، مثل
                        الجمع أو التسجيل أو التنظيم أو الهيكلة أو التخزين أو التكييف أو التدمير؛
                    </p>
                </li>
                <li>
                    <p>
                        <b>الشخص الذي تتعلق به البيانات (أو أنت) </b>هو شخص طبيعي محدد أو يمكن التعرف عليه ويمكن التعرف
                        عليه، بشكل مباشر أو غير مباشر، بناءً على بيانات شخصية معينة.
                    </p>
                </li>
            </ul>

            <h2>تم جمع البيانات</h2>
            <p>
                توافق على أن شركة إيفينتس 10 إكس المحدودة، وهي شركة إماراتية تقع مكاتبها في ذا هيف، مركز دبي المالي
                العالمي، دبي، دولة الإمارات العربية المتحدة (يشار إليها لاحقًا باسم "نحن"، أو "لنا"، أو "إيا") ستكون
                المراقب للبيانات لبياناتك الشخصية ويجوز لها جمع بياناتك الشخصية ومعالجتها واستخدامها وحفظها. تشمل
                البيانات الشخصية ما يلي:
            </p>
            <ol>
                <li>
                    <p>
                        بيانات التسجيل: اسمك واسم تسجيل الدخول وعنوان البريد الإلكتروني وكلمة المرور ومعرف حساب الطرف
                        الثالث (عند استخدامه للتسجيل) والصورة؛
                    </p>
                </li>
                <li>
                    <p> بيانات الملف الشخصي: اسم الشركة والعنوان وعنوانك والمسمى الوظيفي ورقم الهاتف الخاص بك،</p>
                </li>
                <li>
                    <p>بيانات تحرير الفواتير: الاسم واسم الشركة والعنوان ورقم الهاتف والعناصر المطلوبة،</p>
                </li>
                <li>
                    <p>
                        البيانات الفنية: ملفات السجل لتشمل عنوان بروتوكول الإنترنت لجهاز الحاسب الآلي الخاص بك، وإصدار
                        المتصفح والصفحات التي زرتها على موقعنا الإلكتروني،
                    </p>
                </li>
                <li>
                    <p>
                        بيانات الاتصال: الرسائل (بما في ذلك التي ترسلها عبر نموذج الاتصال) ومنشورات الحائط وتعليقات
                        الدردشة.
                    </p>
                </li>
            </ol>

            <h2>استخدام البيانات</h2>
            <p>نستخدم بياناتك للأغراض التالية:</p>
            <ol>
                <li>
                    <p>
                        لتقديم خدماتنا. تم تصميم منصتنا كبيئة يتفاعل فيها محترفو الأعمال مع بعضهم البعض خلال فعاليات
                        محددة. لهذا الغرض، نقوم بمعالجة بيانات التسجيل والملف الشخصي وتحرير الفواتير. على سبيل المثال،
                        قد تتم مشاركة أي بيانات ملف تعريف تقدمها لنا مع مستخدمين آخرين، وستكون بيانات ملفك الشخصي قابلة
                        للبحث من قبل مستخدمين آخرين.
                    </p>
                </li>
                <li>
                    <p>
                        التسويق. نحن نستخدم بيانات التسجيل والملف الشخصي لتعزيز خدمتنا ونمو شبكتنا فقط على منصتنا. على
                        سبيل المثال، قد نرسل لك رسائل بريد إلكتروني لإعلامك بالأحداث المستقبلية التي تُعقد على منصتنا.
                    </p>
                </li>
                <li>
                    <p>
                        عمل المنصة وتطويرها. نستخدم البيانات، بما في ذلك التعليقات العامة، لإجراء البحث والتطوير لمواصلة
                        تطوير منصتنا من أجل تزويدك أنت والآخرين بتجربة أفضل وأكثر سهولة وشخصية. لهذا الغرض، نستخدم
                        البيانات الفنية وبيانات الاتصال الخاصة بك.
                    </p>
                </li>
                <li>
                    <p>
                        الأمان. يجوز لنا استخدام بيانات التسجيل وبيانات الملف الشخصي وبيانات الاتصال والبيانات الفنية
                        إذا اعتقدنا أنها ضرورية لأغراض أمان وسلامة المنصة أو للتحقيق في احتيال محتمل أو انتهاكات أخرى
                        لشروطنا وسياسة الخصوصية هذه. على سبيل المثال، قد نستخدم ملفات السجل الخاصة بك للتحليل الفني
                        وكذلك للتقييم الإحصائي.
                    </p>
                </li>
                <li>
                    <p>
                        لغرض قانوني. يجوز لنا استخدام أي نوع من بياناتك الشخصية عندما تكون المعالجة مطلوبة بموجب القانون
                        القابل للتطبيق.
                    </p>
                </li>
            </ol>

            <h2>مشاركة البيانات</h2>
            <p>
                مقدمي الخدمة. يعمل مقدمو الخدمة لدينا بصفتهم معالج أو مراقب مقره في المنطقة الاقتصادية الأوروبية ولكن
                أيضًا في جميع أنحاء العالم الذين يقدمون - خدمات وتكنولوجيا المعلومات وخدمات إدارة النظام.
            </p>
            <ol>
                <li>
                    <p>
                        نستخدم ميكروسوفت أزور لاستضافة منصتنا. سياسة خصوصية خدمات أمازون ويب متاحة ضمن عنوان محدد موقع
                        الموارد المُوحّد (يو آر إل) هذا.{' '}
                        <a
                            href="https://www.microsoft.com/en-us/trust-center/privacy"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            يو آر إل
                        </a>
                    </p>
                </li>
                <li>
                    <p>
                        نتعهد بمصادر خارجية لمعالجة الدفع وجميع المعاملات لخدمات باي بال، لذا يرجى الرجوع إلى{' '}
                        <a
                            href="https://www.paypal.com/us/webapps/mpp/ua/legalhub-full"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            اتفاقية المستخدم
                        </a>{' '}
                        و{' '}
                        <a
                            href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            سياسة الخصوصية
                        </a>
                        . لا نقوم بتخزين أي تفاصيل للبطاقة المصرفية
                    </p>
                </li>
                <li>
                    <p>
                        تستخدم مواقعنا الإلكترونية جوجل أناليتكس، وهي خدمة لتحليل الويب تقدمها شركة جوجل ("جوجل").
                        يستخدم جوجل أناليتكس "ملفات تعريف الارتباط"، وهي ملفات نصية موضوعة على جهاز الحاسب الآلي الخاص
                        بك، لمساعدة الموقع الإلكتروني على تحليل كيفية استخدام المستخدمين للموقع. سيتم نقل المعلومات التي
                        تم إنشاؤها بواسطة ملف تعريف الارتباط حول استخدامك للموقع إلى جوجل وتخزينها على خوادم في الولايات
                        المتحدة. تم تنشيط المواقع الإلكترونية الخاصة بنا لإخفاء هوية بروتوكول الإنترنت، بحيث يتم اختصار
                        عنوان بروتوكول الإنترنت الخاص بك مسبقًا من قبل جوجل داخل الدول الأعضاء في الاتحاد الأوروبي أو في
                        الدول الموقعة الأخرى على معاهدة المنطقة الاقتصادية الأوروبية. سيتم إرسال عنوان بروتوكول الإنترنت
                        الكامل إلى خادم جوجل في الولايات المتحدة الأمريكية وسيتم اختصاره هناك بشكل استثنائي فقط. سيستخدم
                        جوجل هذه المعلومات لغرض تقييم استخدامك للموقع الإلكتروني، وتجميع التقارير عن نشاط الموقع
                        الإلكتروني لمشغلي المواقع الإلكترونية، وتقديم خدمات أخرى لمشغلي المواقع الإلكترونية فيما يتعلق
                        بنشاط الموقع الإلكتروني واستخدام الإنترنت. لن يربط جوجل عنوان بروتوكول الإنترنت المرسل بموجب
                        جوجل أناليتكس بالبيانات الأخرى التي يحتفظ بها جوجل. يمكنك منع تخزين ملفات تعريف الارتباط عن طريق
                        تحديد الإعدادات المناسبة على برنامج المتصفح الخاص بك، ولكن إذا قمت بذلك، فلن تكون جميع وظائف
                        موقعنا الإلكتروني صالحة للاستعمال إلى أقصى حد. يمكنك منع جوجل من تسجيل البيانات عن طريق تحميل
                        وتثبيت مكون إضافي للمتصفح متاح من خلال الرابط التالي:{' '}
                        <a
                            href="https://tools.google.com/dlpage/gaoptout?hl=en"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://tools.google.com/dlpage/gaoptout?hl=en.
                        </a>
                    </p>
                </li>
                <li>
                    <p>
                        نحن نستخدم ميل تشيمب التي تقدمها شركة روكت ساينس جروب لإرسال رسائل البريد الإلكتروني التسويقية
                        الخاصة بنا. سياسة خصوصية ميل تشيمب متاحة{' '}
                        <a href="https://mailchimp.com/legal/privacy/" target="_blank" rel="noopener noreferrer">
                            هنا
                        </a>
                        . تشارك ميل تشيمب في وتصدّق على امتثالها للاتحاد الأوروبي والولايات المتحدة. إطار عمل درع
                        الخصوصية وإطار عمل درع الخصوصية السويسري الأمريكي. تستخدم ميل تشيمب إشارات الويب - وهي تقنية
                        تسمح بتحديد ما إذا كان المستخدم قد فتح البريد الإلكتروني. لهذا الغرض، قد تجمع بعض البيانات
                        الشخصية مثل عنوان بروتوكول الإنترنت والبرامج وبيانات الأجهزة. لا يمكن إيقاف تشغيل إشارات الويب
                        الخاصة بالبريد الإلكتروني.
                    </p>
                </li>
                <li>
                    <p>
                        يجوز لنا مشاركة البيانات مع المقاولين ومقدمي الخدمات لدينا، الذين يتصرفون إما بصفتهم: (1) معالج
                        أو مراقب مقره في المنطقة الاقتصادية الأوروبية ولكن أيضًا في جميع أنحاء العالم الذين يقدمون -
                        الخدمات وخدمات إدارة تكنولوجيا المعلومات والنظام أو (2) يعملون بصفة معالج أو مراقبين مشتركين بما
                        في ذلك المحامين والمصرفيين ومدققي الحسابات وشركات التأمين.
                    </p>
                </li>
                <li>
                    <p>
                        نشارك البيانات مع منظمي الفعالية، الذين قد يعملون بصفتهم معالجين أو مراقبين مشتركين، في المنطقة
                        الاقتصادية الأوروبية.
                    </p>
                </li>
            </ol>
            <p>
                يجوز لنا مشاركة البيانات الشخصية مع أطراف ثالثة قد نختار بيع أو نقل أو دمج أجزاء من الأعمال أو الأصول
                لهم.
            </p>
            <p>
                لن نفصح أبدًا عن بياناتك الشخصية أو نبيعها إلى أطراف ثالثة لتمكينهم من تزويدك بمعلومات تتعلق بالبضائع أو
                الخدمات غير ذات الصلة.
            </p>
            <p>
                قد يتم نقل المعلومات التي نقدمها لك ومعالجتها على خوادمنا، أو خوادم مقدمي الطرف الثالث، والتي قد تكون
                موجودة خارج المنطقة الاقتصادية الأوروبية.
            </p>
            <p>
                فيما يتعلق بنقل البيانات الشخصية إلى طرف ثالث غير مسموح به صراحة بموجب القانون، فإنك توافق على أنه يجوز
                لنا، إذا لزم الأمر، نقل البيانات الشخصية إلى مكاتب إنفاذ القانون عندما يكون ذلك مناسبًا للدفاع ضد تهديد
                السلامة الحكومية والعامة أو مقاضاة عمل إجرامي. علاوة على ذلك، فإنك توافق على أنه يجوز لنا، إذا لزم الأمر
                وكان ذو صلة بإنفاذ القانون المشروع أو مصالح الملاحقة الجنائية، نقل البيانات الشخصية إلى طرف ثالث. لا يعد
                نقل البيانات ضروريًا ولن يحدث إذا كان من الممكن بدء إجراء قانوني أو إجراء لإنفاذ القانون أو قد تم بدئه
                بالفعل.
            </p>

            <h2>خيارات الخصوصية الخاصة بك</h2>
            <p>وفقًا للائحة العامة لحماية البيانات تتمتع بالحقوق التالية:</p>
            <ol>
                <li>
                    <p>
                        <b>الحق في التصحيح:</b> يحق لك طلب تصحيح أي بيانات غير صحيحة تخصك دون تأخير لا داعي له.
                    </p>
                </li>
                <li>
                    <p>
                        <b>الحق في تقييد المعالجة:</b> يمكنك تقييد استخدام البيانات الشخصية التي تمت معالجتها.
                    </p>
                </li>
                <li>
                    <p>
                        <b>حق الوصول:</b> يجوز لك طلب نسخة من البيانات الشخصية التي تم جمعها أثناء استخدام المنصة.
                    </p>
                </li>
                <li>
                    <p>
                        <b>الاعتراض على أو تقييد استخدام البيانات الشخصية:</b> يمكنك أن تطلب التوقف عن استخدام كل أو جزء
                        من البيانات الشخصية أو الحد من استخدامها عن طريق طلب محوها كما هو موضح أعلاه أو إرسال طلب إلينا.
                    </p>
                </li>
                <li>
                    <p>
                        <b>الحق في تقديم شكوى إلى سلطة إشرافية:</b> تتمتع بالحق في تقديم شكوى إلى سلطة إشرافية مختصة
                        بحماية البيانات.
                    </p>
                </li>
                <li>
                    <p>
                        <b>الحق في نقل البيانات:</b> يمكنك تلقي البيانات الشخصية بتنسيق قابل للقراءة آليًا عن طريق إرسال
                        الطلب المعني على الموقع الإلكتروني <a href="mailto:info@events10x.com">info@events10x.com</a>
                    </p>
                </li>
            </ol>

            <h2>ممارسة الحقوق</h2>
            <p>
                لممارسة حقك في الوصول، يحق لك الإرسال كطلب. بناء على طلب نحن نقدم المعلومات مجانًا. ومع ذلك، قد نفرض
                رسومًا معقولة إذا كان من الواضح أن الطلب لا أساس له من الصحة أو متكررًا أو مفرطًا. بدلاً من ذلك، قد نرفض
                الامتثال لطلبك في هذه الظروف.
            </p>
            <p>
                سنراجع الطلب ونعلن عنه في غضون شهر واحد من تاريخ تقديمه. قد يتم تمديد هذه الفترة لمدة شهرين آخرين، إذا
                لزم الأمر، على سبيل المثال، إذا كان طلبك معقدًا بشكل خاص أو قد قدمت عددًا من الطلبات. سنبلغك بأي من ذلك
                التمديد في غضون شهر واحد اعتبارًا من تاريخ استلام الطلب، مع ذكر أسباب التأخير. عند تقديم طلب بالوسائل
                الإلكترونية، يتم توفير المعلومات إلكترونيًا، إن أمكن، ما لم تطلب غير ذلك.
            </p>
            <p>
                عند تقديم طلب من قبل شخص مصرح له، يجب إرفاق الوكالة القانونية الموثقة بالطلب. في حالة وفاة الشخص الطبيعي
                يمارس ورثته / ورثتها حقوقه / حقوقها ويجب أن ترفق شهادة الورثة مع الطلب. يجب تأكيد الإرث من خلال الشهادة
                ذات الصلة، الصادرة في الاختصاص القضائي للشخص المتوفى.
            </p>
            <p>في حالة عدم وجود البيانات أو حظر توفيرها بموجب القانون، يتم رفض وصول الطرف الطالب إلى هذه البيانات.</p>

            <h2>الاحتفاظ </h2>
            <p>
                سنقوم بتخزين البيانات الشخصية طالما كانت ضرورية بشكل معقول لتحقيق الأغراض المنصوص عليها في سياسة
                الخصوصية والشروط والأحكام هذه، والتي تشمل (على سبيل المثال لا الحصر) الفترة التي يكون لديك خلالها حساب
                على منصتنا. سنحتفظ أيضًا بالبيانات الشخصية ونستخدمها عند الضرورة للامتثال للالتزامات القانونية وحل
                النزاعات وإنفاذ الاتفاقيات.
            </p>

            <h2>تحديد العمر</h2>
            <p>
                منصتنا ليست للمستخدمين الذين تقل أعمارهم عن 18 عامًا. نحن لا نعالج عن عمد أي بيانات شخصية من أشخاص تقل
                أعمارهم عن 18 عامًا، ويجب حذف أي من هذه البيانات فور اكتشافها. إذا علمت أن أي شخص يقل عمره عن 18 عامًا
                قد زودنا ببيانات شخصية، فيرجى الاتصال بنا على الموقع الإلكتروني{' '}
                <a href="mailto:info@events10x.com">info@events10x.com.</a>
            </p>

            <h2>تغييرات سياسة الخصوصية</h2>
            <p>
                قد نجري تعديلًا لسياسة الخصوصية هذه في المستقبل، وسنبلغك بأي من تلك التعديلات في المستقبل. يخضع استخدام
                البيانات التي نجمعها ونعالجها ونحفظها الآن لنسخة سياسة الخصوصية هذه السارية في الوقت الذي يقدم فيه
                المستخدمون هذه البيانات.
            </p>

            <h2>كيف تتواصل معنا</h2>
            <p>
                إذا كان لديك أي أسئلة أو طلب بخصوص سياستك، يرجى إرسال طلبك إلى الموقع الإلكتروني{' '}
                <a href="mailto:info@events10x.com">info@events10x.com.</a>
            </p>
        </div>
    </div>
);
