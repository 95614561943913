const timeStrings = {
    en: {
        justNow: 'just now',
        minute: 'minute',
        minutes: 'minutes',
        hour: 'hour',
        hours: 'hours',
        day: 'day',
        days: 'days',
        month: 'month',
        months: 'months',
        yesterday: 'yesterday',
        today: 'today',
        tomorrow: 'tomorrow',
        monday: 'monday',
        tuesday: 'tuesday',
        wednesday: 'wednesday',
        thursday: 'thursday',
        friday: 'friday',
        saturday: 'saturday',
        sunday: 'sunday',
        mondayShort: 'mon',
        tuesdayShort: 'tue',
        wednesdayShort: 'wed',
        thursdayShort: 'thu',
        fridayShort: 'fri',
        saturdayShort: 'sat',
        sundayShort: 'sun',
        january: 'january',
        february: 'february',
        march: 'march',
        april: 'april',
        may: 'may',
        june: 'june',
        july: 'july',
        august: 'august',
        september: 'september',
        october: 'october',
        november: 'november',
        december: 'december',
        januaryShort: 'jan',
        februaryShort: 'feb',
        marchShort: 'mar',
        aprilShort: 'apr',
        mayShort: 'may',
        juneShort: 'jun',
        julyShort: 'jul',
        augustShort: 'aug',
        septemberShort: 'sep',
        octoberShort: 'oct',
        novemberShort: 'nov',
        decemberShort: 'dec',
    },
    ar: {
        justNow: 'الآن',
        minute: 'دقيقة',
        minutes: 'دقائق',
        hour: 'ساعة',
        hours: 'ساعات',
        day: 'يوم',
        days: 'الأيام',
        month: 'الشهر',
        months: 'الشهور',
        yesterday: 'الأمس',
        today: 'اليوم',
        tomorrow: 'غداً',
        monday: 'الإثنين',
        tuesday: 'الثلاثاء',
        wednesday: 'الأربعاء',
        thursday: 'الخميس',
        friday: 'الجمعة',
        saturday: 'السبت',
        sunday: 'الأحد',
        mondayShort: 'الإثنين',
        tuesdayShort: 'الثلاثاء',
        wednesdayShort: 'الأربعاء',
        thursdayShort: 'الخميس',
        fridayShort: 'الجمعة',
        saturdayShort: 'السبت',
        sundayShort: 'الأحد',
        january: 'يناير',
        february: 'فبراير',
        march: 'مارس',
        april: 'أبريل',
        may: 'مايو',
        june: 'يونيو',
        july: 'يوليو',
        august: 'أغسطس',
        september: 'سبتمبر',
        october: 'أكتوبر',
        november: 'نوفمبر',
        december: 'ديسمبر',
        januaryShort: 'يناير',
        februaryShort: 'فبراير',
        marchShort: 'مارس',
        aprilShort: 'أبريل',
        mayShort: 'مايو',
        juneShort: 'يونيو',
        julyShort: 'يوليو',
        augustShort: 'أغسطس',
        septemberShort: 'سبتمبر',
        octoberShort: 'أكتوبر',
        novemberShort: 'نوفمبر',
        decemberShort: 'ديسمبر',
    },
    ch: {
        justNow: '刚才',
        minute: '分钟',
        minutes: '分钟',
        hour: '小时',
        hours: '小时',
        day: '天',
        days: '天',
        month: '月',
        months: '月',
        yesterday: '昨天',
        today: '今天',
        tomorrow: '明天',
        monday: '星期一',
        tuesday: '星期二',
        wednesday: '星期三',
        thursday: '星期四',
        friday: '星期五',
        saturday: '星期六',
        sunday: '星期日',
        mondayShort: '周一',
        tuesdayShort: '周二',
        wednesdayShort: '周三',
        thursdayShort: '周四',
        fridayShort: '周五',
        saturdayShort: '周六',
        sundayShort: '周日',
        january: '一月',
        february: '二月',
        march: '三月',
        april: '四月',
        may: '五月',
        june: '六月',
        july: '七月',
        august: '八月',
        september: '九月',
        october: '十月',
        november: '十一月',
        december: '十二月',
        januaryShort: '一月',
        februaryShort: '二月',
        marchShort: '三月',
        aprilShort: '四月',
        mayShort: '五月',
        juneShort: '六月',
        julyShort: '七月',
        augustShort: '八月',
        septemberShort: '九月',
        octoberShort: '十月',
        novemberShort: '十一月',
        decemberShort: '十二月',
    },
    de: {
        justNow: 'Soeben',
        minute: 'Minute',
        minutes: 'Minuten',
        hour: 'Stunde',
        hours: 'Stunden',
        day: 'Tag',
        days: 'Tage',
        month: 'Monat',
        months: 'Monat',
        yesterday: 'Gestern',
        today: 'Heute',
        tomorrow: 'Morgen',
        monday: 'Montag',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        thursday: 'Donnerstag',
        friday: 'Freitag',
        saturday: 'Samstag',
        sunday: 'Sonntag',
        mondayShort: 'Mo.',
        tuesdayShort: 'Di.',
        wednesdayShort: 'Mi.',
        thursdayShort: 'Do.',
        fridayShort: 'Fr.',
        saturdayShort: 'Sa.',
        sundayShort: 'So.',
        january: 'Januar',
        february: 'Februar',
        march: 'März',
        april: 'April',
        may: 'Mai',
        june: 'Juni',
        july: 'Juli',
        august: 'August',
        september: 'September',
        october: 'Oktober',
        november: 'November',
        december: 'Dezember',
        januaryShort: 'Jan.',
        februaryShort: 'Feb.',
        marchShort: 'März',
        aprilShort: 'Apr.',
        mayShort: 'Mai',
        juneShort: 'Jun.',
        julyShort: 'Jul.',
        augustShort: 'Aug.',
        septemberShort: 'Sept.',
        octoberShort: 'Okt.',
        novemberShort: 'Nov.',
        decemberShort: 'Dez.',
    },
    es: {
        justNow: 'justo ahora',
        minute: 'minuto',
        minutes: 'minutos',
        hour: 'hora',
        hours: 'horas',
        day: 'día',
        days: 'días',
        month: 'mes',
        months: 'meses',
        yesterday: 'ayer',
        today: 'hoy',
        tomorrow: 'mañana',
        monday: 'lunes',
        tuesday: 'martes',
        wednesday: 'miércoles',
        thursday: 'jueves',
        friday: 'viernes',
        saturday: 'sábado',
        sunday: 'domingo',
        mondayShort: 'lun',
        tuesdayShort: 'mar',
        wednesdayShort: 'mier',
        thursdayShort: 'jue',
        fridayShort: 'vier',
        saturdayShort: 'sab',
        sundayShort: 'dom',
        january: 'enero',
        february: 'febrero',
        march: 'marzo',
        april: 'abril',
        may: 'mayo',
        june: 'junio',
        july: 'julio',
        august: 'agosto',
        september: 'septiembre',
        october: 'octubre',
        november: 'noviembre',
        december: 'diciembre',
        januaryShort: 'ene',
        februaryShort: 'feb',
        marchShort: 'mar',
        aprilShort: 'abr',
        mayShort: 'may',
        juneShort: 'jun',
        julyShort: 'jul',
        augustShort: 'ago',
        septemberShort: 'sep',
        octoberShort: 'oct',
        novemberShort: 'nov',
        decemberShort: 'dic',
    },
    fr: {
        justNow: 'juste maintenant',
        minute: 'minute',
        minutes: 'minutes',
        hour: 'heure',
        hours: 'heures',
        day: 'jour',
        days: 'jours',
        month: 'mois',
        months: 'mois',
        yesterday: 'hier',
        today: "aujourd'hui",
        tomorrow: 'demain',
        monday: 'lundi',
        tuesday: 'mardi',
        wednesday: 'Mercredi',
        thursday: 'Jeudi',
        friday: 'Vendredi',
        saturday: 'samedi',
        sunday: 'dimanche',
        mondayShort: 'Lun',
        tuesdayShort: 'Mar',
        wednesdayShort: 'Mer',
        thursdayShort: 'Jeu',
        fridayShort: 'Ven',
        saturdayShort: 'Sam',
        sundayShort: 'Dim',
        january: 'janvier',
        february: 'février',
        march: 'Mars',
        april: 'avril',
        may: 'Mai',
        june: 'juin',
        july: 'juillet',
        august: 'août',
        september: 'septembre',
        october: 'octobre',
        november: 'novembre',
        december: 'décembre',
        januaryShort: 'jan',
        februaryShort: 'fév',
        marchShort: 'Mar',
        aprilShort: 'avr',
        mayShort: 'Mai',
        juneShort: 'jui',
        julyShort: 'jui',
        augustShort: 'aoû',
        septemberShort: 'sep',
        octoberShort: 'oct',
        novemberShort: 'nov',
        decemberShort: 'déc',
    },
    no: {
        justNow: 'akkurat nå',
        minute: 'minutt',
        minutes: 'minutter',
        hour: 'time',
        hours: 'timer',
        day: 'dag',
        days: 'dager',
        month: 'måned',
        months: 'måneder',
        yesterday: 'i går',
        today: 'i dag',
        tomorrow: 'i morgen',
        monday: 'mandag',
        tuesday: 'tirsdag',
        wednesday: 'onsdag',
        thursday: 'torsdag',
        friday: 'fredag',
        saturday: 'lørdag',
        sunday: 'søndag',
        mondayShort: 'man',
        tuesdayShort: 'tirs',
        wednesdayShort: 'ons',
        thursdayShort: 'tors',
        fridayShort: 'fred',
        saturdayShort: 'lør',
        sundayShort: 'søn',
        january: 'januar',
        february: 'februar',
        march: 'mars',
        april: 'april',
        may: 'mai',
        june: 'juni',
        july: 'juli',
        august: 'august',
        september: 'september',
        october: 'oktober',
        november: 'november',
        december: 'desember',
        januaryShort: 'jan',
        februaryShort: 'feb',
        marchShort: 'mars',
        aprilShort: 'april',
        mayShort: 'mai',
        juneShort: 'jun',
        julyShort: 'jul',
        augustShort: 'aug',
        septemberShort: 'sept',
        octoberShort: 'okt',
        novemberShort: 'nov',
        decemberShort: 'des',
    },
    pt: {
        justNow: 'agora mesmo',
        minute: 'minuto',
        minutes: 'minutos',
        hour: 'hora',
        hours: 'horas',
        day: 'dia',
        days: 'dias',
        month: 'mês',
        months: 'meses',
        yesterday: 'ontem',
        today: 'hoje',
        tomorrow: 'amanhã',
        monday: 'segunda-feira',
        tuesday: 'terça-feira',
        wednesday: 'quarta-feira',
        thursday: 'quinta-feira',
        friday: 'sexta-feira',
        saturday: 'sábado',
        sunday: 'domingo',
        mondayShort: 'seg',
        tuesdayShort: 'ter',
        wednesdayShort: 'qua',
        thursdayShort: 'qui',
        fridayShort: 'sex',
        saturdayShort: 'sab',
        sundayShort: 'dom',
        january: 'janeiro',
        february: 'fevereiro',
        march: 'março',
        april: 'abril',
        may: 'mai',
        june: 'junho',
        july: 'julho',
        august: 'agosto',
        september: 'setembro',
        october: 'outubro',
        november: 'novembro',
        december: 'dezembro',
        januaryShort: 'jan',
        februaryShort: 'fev',
        marchShort: 'mar',
        aprilShort: 'abr',
        mayShort: 'mai',
        juneShort: 'jun',
        julyShort: 'jul',
        augustShort: 'ago',
        septemberShort: 'set',
        octoberShort: 'out',
        novemberShort: 'nov',
        decemberShort: 'dez',
    },
    ru: {
        justNow: 'только что',
        minute: 'минута',
        minutes: 'минуты',
        hour: 'час',
        hours: 'часы',
        day: 'день',
        days: 'дни',
        month: 'месяц',
        months: 'месяцы',
        yesterday: 'вчера',
        today: 'сегодня',
        tomorrow: 'завтра',
        monday: 'Понедельник',
        tuesday: 'Вторник',
        wednesday: 'Среда',
        thursday: 'Четверг',
        friday: 'Пятница',
        saturday: 'Суббота',
        sunday: 'Воскресение',
        mondayShort: 'Пн',
        tuesdayShort: 'Вт ',
        wednesdayShort: 'Ср ',
        thursdayShort: 'Чт',
        fridayShort: 'Пт',
        saturdayShort: 'Сб',
        sundayShort: 'Вс',
        january: 'Январь',
        february: 'Февраль',
        march: 'Март',
        april: 'Апрель',
        may: 'Май',
        june: 'Июнь',
        july: 'Июль',
        august: 'Август',
        september: 'Сентябрь',
        october: 'Октябрь',
        november: 'Ноябрь',
        december: 'Декабрь',
        januaryShort: 'Янв ',
        februaryShort: 'Фев ',
        marchShort: 'Мар',
        aprilShort: 'Апр ',
        mayShort: 'Май',
        juneShort: 'Июнь',
        julyShort: 'Июль',
        augustShort: 'Авг ',
        septemberShort: 'Сен ',
        octoberShort: 'Окт ',
        novemberShort: 'Ноя ',
        decemberShort: 'Дек ',
    },
    tr: {
        justNow: 'şu anda',
        minute: 'dakika',
        minutes: 'dakika',
        hour: 'saat',
        hours: 'saat',
        day: 'gün',
        days: 'gün',
        month: 'ay',
        months: 'ay',
        yesterday: 'dün',
        today: 'bugün',
        tomorrow: 'yarın',
        monday: 'pazartesi',
        tuesday: 'salı',
        wednesday: 'çarşamba',
        thursday: 'perşembe',
        friday: 'cuma',
        saturday: 'cumartesi',
        sunday: 'pazar',
        mondayShort: 'pzt',
        tuesdayShort: 'sal',
        wednesdayShort: 'çrş',
        thursdayShort: 'per',
        fridayShort: 'cum',
        saturdayShort: 'cmt',
        sundayShort: 'paz',
        january: 'ocak',
        february: 'şubat',
        march: 'mart',
        april: 'nisan',
        may: 'may',
        june: 'haziran',
        july: 'temmuz',
        august: 'ağustos',
        september: 'eylül',
        october: 'ekim',
        november: 'kasım',
        december: 'aralık',
        januaryShort: 'oca',
        februaryShort: 'şub',
        marchShort: 'mar',
        aprilShort: 'nis',
        mayShort: 'may',
        juneShort: 'haz',
        julyShort: 'tem',
        augustShort: 'ağu',
        septemberShort: 'eyl',
        octoberShort: 'eki',
        novemberShort: 'kas',
        decemberShort: 'ara',
    },
};

export default timeStrings;
