export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SERVER_DOWN = 'SERVER_DOWN';
export const SERVER_UP = 'SERVER_UP';
export const SESSION_EXPIRED = 'SESSION_EXPIRED';
export const VIDEO_CONFERENCE_COMPANION_DISCONNECTED = 'VIDEO_CONFERENCE_COMPANION_DISCONNECTED';

export const SET_PLATFORM_MENU = 'SET_PLATFORM_MENU';
export const SET_EVENT_PUBLIC_MENU = 'SET_EVENT_PUBLIC_MENU';
export const SET_EVENT_PROTECTED_MENU = 'SET_EVENT_PROTECTED_MENU';
export const SET_ADMIN_MENU = 'SET_ADMIN_MENU';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_IS_TABLET = 'SET_IS_TABLET';
export const SET_IS_LARGE_SCREEN = 'SET_IS_LARGE_SCREEN';

export const TOP_NAV_SEE_CONTACTS = 'TOP_NAV_SEE_CONTACTS';
export const TOP_NAV_SEE_PROGRAM = 'TOP_NAV_SEE_PROGRAM';
export const TOP_NAV_SEE_WALLET = 'TOP_NAV_SEE_WALLET';
export const OPEN_WALLET = 'OPEN_WALLET';
export const TOP_NAV_SEE_CHATS = 'TOP_NAV_SEE_CHATS';
export const TOP_NAV_SEE_NOTIFICATIONS = 'TOP_NAV_SEE_NOTIFICATIONS';
export const TOP_NAV_SEE_MY_EVENTS = 'TOP_NAV_SEE_MY_EVENTS';
export const TOP_NAV_SEE_MY_BOOTHS = 'TOP_NAV_SEE_MY_BOOTHS';
export const TOP_NAV_SEE_MY_ACCOUNT = 'TOP_NAV_SEE_MY_ACCOUNT';
export const TOP_NAV_CLOSE = 'TOP_NAV_CLOSE';
export const BOTTOM_SEE_ROOMS = 'BOTTOM_SEE_ROOMS';
export const TOP_NAV_BOTTOM_SHEET = 'TOP_NAV_BOTTOM_SHEET';
export const EXPAND_CHATS = 'EXPAND_CHATS';

export const SIDE_NAV_SEE_SURVEY = 'SIDE_NAV_SEE_SURVEY';
export const SIDE_NAV_SEE_SOCIAL_MEDIA = 'SIDE_NAV_SEE_SOCIAL_MEDIA';
export const SIDE_NAV_SEE_INTERPRETATION = 'SIDE_NAV_SEE_INTERPRETATION';
export const SIDE_NAV_CLOSE_SURVEY = 'SIDE_NAV_CLOSE_SURVEY';
export const SIDE_NAV_SEE_NETWORKING = 'SIDE_NAV_SEE_NETWORKING';
export const SIDE_NAV_SEE_LIVE_WALL = 'SIDE_NAV_SEE_WALL';
export const SIDE_NAV_SEE_VIDEO_WALL = 'SIDE_NAV_SEE_VIDEO_WALL';
export const SIDE_NAV_CLOSE = 'SIDE_NAV_CLOSE';
export const SIDE_INFO_TAB = 'SIDE_INFO_TAB';

export const SET_PARTICIPANTS_FILTERS = 'SET_PARTICIPANT_FILTERS';
export const SET_FILTERED_PARTICIPANTS = 'SET_FILTERED_PARTICIPANTS';
export const SET_FILTERED_PARTICIPANTS_START = 'SET_FILTERED_PARTICIPANTS_START';
export const SET_FILTERED_PARTICIPANTS_FAIL = 'SET_FILTERED_PARTICIPANTS_FAIL';

export const CHANGE_WALL_TYPE = 'WALL/CHANGE_WALL_TYPE';
export const CHANGE_WALL_STATUS = 'WALL/CHANGE_WALL_STATUS';
export const TOGGLE_WALL = 'WALL/TOGGLE_WALL';
export const CLOSE_WALL = 'WALL/CLOSE_WALL';

export const SET_NOT_READ_CHAT_MESSAGES = 'SET_NOT_READ_CHAT_MESSAGES';

export const LOGIN_USER_START = 'LOGIN_USER_START';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAIL = 'LOGIN_USER_FAIL';

export const REGISTER_USER_START = 'REGISTER_USER_START';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAIL = 'REGISTER_USER_FAIL';

export const LOGOUT_USER_START = 'LOGOUT_USER_START';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOGOUT_USER_FAIL = 'LOGOUT_USER_FAIL';

export const GET_PARTICIPANTS_START = 'GET_PARTICIPANTS_START';
export const GET_PARTICIPANTS_SUCCESS = 'GET_PARTICIPANTS_SUCCESS';
export const GET_PARTICIPANTS_FAIL = 'GET_PARTICIPANTS_FAIL';

export const GET_EXHIBITOR_START = 'GET_EXHIBITOR_START';
export const GET_EXHIBITOR_SUCCESS = 'GET_EXHIBITOR_SUCCESS';
export const GET_EXHIBITOR_FAIL = 'GET_EXHIBITOR_FAIL';
export const CLEAR_EXHIBITOR_DATA = 'CLEAR_EXHIBITOR_DATA';

export const SEND_CONTACT_REQUEST_START = 'SEND_CONTACT_REQUEST_START';
export const SEND_CONTACT_REQUEST_SUCCESS = 'SEND_CONTACT_REQUEST_SUCCESS';
export const SEND_CONTACT_REQUEST_FAIL = 'SEND_CONTACT_REQUEST_FAIL';

export const ACCEPT_CONTACT_REQUEST_START = 'ACCEPT_CONTACT_REQUEST_START';
export const ACCEPT_CONTACT_REQUEST_SUCCESS = 'ACCEPT_CONTACT_REQUEST_SUCCESS';
export const ACCEPT_CONTACT_REQUEST_FAIL = 'ACCEPT_CONTACT_REQUEST_FAIL';

export const DECLINE_CONTACT_REQUEST_START = 'DECLINE_CONTACT_REQUEST_START';
export const DECLINE_CONTACT_REQUEST_SUCCESS = 'DECLINE_CONTACT_REQUEST_SUCCESS';
export const DECLINE_CONTACT_REQUEST_FAIL = 'DECLINE_CONTACT_REQUEST_FAIL';

export const GET_SENT_REQUESTS_START = 'GET_SENT_REQUESTS_START';
export const GET_SENT_REQUESTS_SUCCESS = 'GET_SENT_REQUESTS_SUCCESS';
export const GET_SENT_REQUESTS_FAIL = 'GET_SENT_REQUESTS_FAIL';

export const GET_RECEIVED_REQUESTS_START = 'GET_RECEIVED_REQUESTS_START';
export const GET_RECEIVED_REQUESTS_SUCCESS = 'GET_RECEIVED_REQUESTS_SUCCESS';
export const GET_RECEIVED_REQUESTS_FAIL = 'GET_RECEIVED_REQUESTS_FAIL';

export const GET_CONTACTS_START = 'GET_CONTACTS_START';
export const GET_CONTACTS_SUCCESS = 'GET_CONTACTS_SUCCESS';
export const GET_CONTACTS_FAIL = 'GET_CONTACTS_FAIL';

export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAIL = 'GET_NOTIFICATIONS_FAIL';

export const SET_ACTIVE_CHAT_DATA = 'SET_ACTIVE_CHAT_DATA';
export const UPDATE_ACTIVE_CHAT_DATA = 'UPDATE_ACTIVE_CHAT_DATA';
export const CLOSE_ACTIVE_CHAT = 'CLOSE_ACTIVE_CHAT';
export const OPEN_CHAT_BUSINESS_CARD = 'OPEN_CHAT_BUSINESS_CARD';
export const CLOSE_CHAT_BUSINESS_CARD = 'CLOSE_CHAT_BUSINESS_CARD';

export const GET_GROUP_CHATS_START = 'GET_GROUP_CHATS_START';
export const GET_GROUP_CHATS_SUCCESS = 'GET_GROUP_CHATS_SUCCESS';
export const GET_GROUP_CHATS__FAIL = 'GET_GROUP_CHATS__FAIL';

export const GET_PRIVATE_CHATS_START = 'GET_PRIVATE_CHATS_START';
export const GET_PRIVATE_CHATS_SUCCESS = 'GET_PRIVATE_CHATS_SUCCESS';
export const GET_PRIVATE_CHATS__FAIL = 'GET_PRIVATE_CHATS__FAIL';

export const GET_ALL_CHATS_START = 'GET_ALL_CHATS_START';
export const GET_ALL_CHATS_SUCCESS = 'GET_ALL_CHATS_SUCCESS';
export const GET_ALL_CHATS__FAIL = 'GET_ALL_CHATS__FAIL';

export const GET_EVENT_CHATS_START = 'GET_EVENT_CHATS_START';
export const GET_EVENT_CHATS_SUCCESS = 'GET_EVENT_CHATS_SUCCESS';
export const GET_EVENT_CHATS__FAIL = 'GET_EVENT_CHATS__FAIL';

export const GET_ARCHIVED_CHATS_START = 'GET_ARCHIVED_CHATS_START';
export const GET_ARCHIVED_CHATS_SUCCESS = 'GET_ARCHIVED_CHATS_SUCCESS';
export const GET_ARCHIVED_CHATS__FAIL = 'GET_ARCHIVED_CHATS__FAIL';

export const MARK_NOTIFICATION_AS_READ_START = 'MARK_NOTIFICATION_AS_READ_START';
export const MARK_NOTIFICATION_AS_READ_SUCCESS = 'MARK_NOTIFICATION_AS_READ_SUCCESS';
export const MARK_NOTIFICATION_AS_READ_FAIL = 'MARK_NOTIFICATION_AS_READ_FAIL';

export const GET_LIVE_WALL_START = 'GET_LIVE_WALL_START';
export const GET_LIVE_WALL_SUCCESS = 'GET_LIVE_WALL_SUCCESS';
export const GET_LIVE_WALL_FAIL = 'GET_LIVE_WALL_FAIL';

export const GET_PROGRAM_START = 'GET_PROGRAM_START';
export const GET_PROGRAM_SUCCESS = 'GET_PROGRAM_SUCCESS';
export const GET_PROGRAM_FAIL = 'GET_PROGRAM_FAIL';

export const CREATE_GROUP_CHAT_START = 'CREATE_GROUP_CHAT_START';
export const CREATE_GROUP_CHAT_SUCCESS = 'CREATE_GROUP_CHAT_SUCCESS';
export const CREATE_GROUP_CHAT_FAIL = 'CREATE_GROUP_CHAT_FAIL';

export const CREATE_PRIVATE_CHAT_START = 'CREATE_PRIVATE_CHAT_START';
export const CREATE_PRIVATE_CHAT_SUCCESS = 'CREATE_PRIVATE_CHAT_SUCCESS';
export const CREATE_PRIVATE_CHAT_FAIL = 'CREATE_PRIVATE_CHAT_FAIL';

export const OPEN_PRIVATE_CHAT = 'OPEN_PRIVATE_CHAT';
export const OPEN_GROUP_CHAT = 'OPEN_GROUP_CHAT';

export const SEND_MESSAGE_IN_PRIVATE_CHAT_START = 'SEND_MESSAGE_IN_PRIVATE_CHAT_START';
export const SEND_MESSAGE_IN_PRIVATE_CHAT_SUCCESS = 'SEND_MESSAGE_IN_PRIVATE_CHAT_SUCCESS';
export const SEND_MESSAGE_IN_PRIVATE_CHAT_FAIL = 'SEND_MESSAGE_IN_PRIVATE_CHAT_FAIL';

export const NEW_PRIVATE_CHAT_MESSAGE = 'NEW_PRIVATE_CHAT_MESSAGE';
export const NEW_GROUP_CHAT_MESSAGE = 'NEW_GROUP_CHAT_MESSAGE';

export const READ_MESSAGES_FOR_PRIVATE_CHAT = 'READ_MESSAGES_FOR_PRIVATE_CHAT';
export const READ_MESSAGES_FOR_GROUP_CHAT = 'READ_MESSAGES_FOR_GROUP_CHAT';
export const RESET_ACTIVE_CHAT_ID = 'RESET_ACTIVE_CHAT_ID';
export const SET_USER_CONNECTED_TO_NOTIFICATIONS = 'SET_USER_CONNECTED_TO_NOTIFICATIONS';

export const GET_EVENT_ARCHIVE_START = 'GET_EVENT_ARCHIVE_START';
export const GET_EVENT_ARCHIVE_SUCCESS = 'GET_EVENT_ARCHIVE_SUCCESS';
export const GET_EVENT_ARCHIVE_FAIL = 'GET_EVENT_ARCHIVE_FAIL';

export const API_CALL_INVITATIONS_START = 'API_CALL_INVITATIONS_START';
export const API_CALL_INVITATIONS_FAIL = 'API_CALL_INVITATIONS_FAIL';
export const GET_INVITATION_CODES_SUCCESS = 'GET_INVITATIONS_SUCCESS';
export const CHECK_INVITATION_CODE_SUCCESS = 'CHECK_INVITATION_CODE_SUCCESS';
export const CHECK_INVITATION_CODE_FAIL = 'CHECK_INVITATION_CODE_FAIL';
export const USE_INVITATION_CODE_SUCCESS = 'USE_INVITATION_CODE_SUCCESS';

export const CREATE_PAYMENT_INTENT_START = 'CREATE_PAYMENT_INTENT_START';
export const CREATE_PAYMENT_INTENT_SUCCESS = 'CREATE_PAYMENT_INTENT_SUCCESS';
export const CREATE_PAYMENT_INTENT_FAIL = 'CREATE_PAYMENT_INTENT_FAIL';

export const CANCEL_PAYMENT_INTENT = 'CANCEL_PAYMENT_INTENT';

export const SET_USER_EVENT_ROLES = 'SET_USER_EVENT_ROLES';
export const SET_EVENT_ACCESS = 'SET_EVENT_ACCESS';
export const SET_PRESELECTED_PACKAGE_ID = 'SET_PRESELECTED_PACKAGE_ID';
export const SET_USER_ROLES = 'SET_USER_ROLES';

export const TOP_NAV_ONVENT_PROFILE = 'TOP_NAV_ONVENT_PROFILE';
export const TOP_NAV_EVENT_PROFILE = 'TOP_NAV_EVENT_PROFILE';
export const EXPANDED_CHATS_COMPONENT = 'EXPANDED_CHATS_COMPONENT';

export const RESET_EVENT_PAYMENT_LOADING = 'RESET_EVENT_PAYMENT_LOADING';

export const TOP_NAV_SEE_AGENDA = 'TOP_NAV_SEE_AGENDA';

export const GET_NEWS_START = 'GET_NEWS_START';
export const GET_NEWS_SUCCESS = 'GET_NEWS_SUCCESS';
export const GET_NEWS_FAIL = 'GET_NEWS_FAIL';
export const CREATE_NEWS_START = 'CREATE_NEWS_START';
export const CREATE_NEWS_SUCCESS = 'CREATE_NEWS_SUCCESS';
export const CREATE_NEWS_FAIL = 'CREATE_NEWS_FAIL';
export const UPDATE_NEWS_START = 'UPDATE_NEWS_START';
export const UPDATE_NEWS_SUCCESS = 'UPDATE_NEWS_SUCCESS';
export const UPDATE_NEWS_FAIL = 'UPDATE_NEWS_FAIL';
export const DELETE_NEWS_START = 'DELETE_NEWS_START';
export const DELETE_NEWS_SUCCESS = 'DELETE_NEWS_SUCCESS';
export const DELETE_NEWS_FAIL = 'DELETE_NEWS_FAIL';
export const START_NEWS_START = 'START_NEWS_START';
export const START_NEWS_SUCCESS = 'START_NEWS_SUCCESS';
export const START_NEWS_FAIL = 'START_NEWS_FAIL';
export const STOP_NEWS_START = 'STOP_NEWS_START';
export const STOP_NEWS_SUCCESS = 'STOP_NEWS_SUCCESS';
export const STOP_NEWS_FAIL = 'STOP_NEWS_FAIL';

export const SET_BUILDING_ID = 'SET_BUILDING_ID';
export const GET_BUILDING_START = 'GET_BUILDING_START';
export const GET_BUILDING_SUCCESS = 'GET_BUILDING_SUCCESS';
export const GET_BUILDING_FAIL = 'GET_BUILDING_FAIL';

export const GET_BUILDING_TIMESLOT_START = 'GET_BUILDING_TIMESLOT_START';
export const GET_BUILDING_TIMESLOT_SUCCESS = 'GET_BUILDING_TIMESLOT_SUCCESS';
export const GET_BUILDING_TIMESLOT_FAIL = 'GET_BUILDING_TIMESLOT_FAIL';

export const CLEAR_BUILDING_DATA = 'CLEAR_BUILDING_DATA';

export const SET_EVENT_LANGUAGE = 'SET_EVENT_LANGUAGE';
export const SET_PLATFORM_LANGUAGE = 'SET_PLATFORM_LANGUAGE';

export const SET_ALL_CHATS_TAB = 'SET_ALL_CHATS_TAB';
export const SET_EVENT_CHATS_TAB = 'SET_EVENT_CHATS_TAB';
export const SET_ARCHIVE_TAB = 'SET_ARCHIVE_TAB';
export const SET_NOT_ARCHIVE_TAB = 'SET_NOT_ARCHIVE_TAB';
