import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {Link} from 'react-router-dom';
import {Grid, FormControlLabel} from '@material-ui/core';
import {AccountCircle as AccountCircleIcon, VpnKey as VpnKeyIcon} from '@material-ui/icons';

import Spinner from '../Spinner';
import SocialLoginButtons from '../SocialNetworkButtons';
import RecoverPasswordDialog from '../RecoverPasswordDialog/RecoverPasswordDialog';
import {DarkCheckbox} from '../CustomCheckbox/CustomCheckbox';

import * as actions from '../../../store/actions/index';
import {getPlatformBackground, whiteLabel} from '../../../config';
import {IS_SCA_ENV} from '../../../constants/shared';
import {reactGAEgyptRegistationTrackingEvent} from 'services/helpers/trackingHelper';
import {withCookiesAccept} from 'services/hocs';

class LoginPlatform extends React.Component {
    state = {
        email: '',
        password: '',
        disabled: true,
        showRecoverPasswordDialog: false,
        recoverPasswordServerMessage: '',
        rememberMe: true,
        translation: null,
    };

    componentDidMount() {
        this.props.onSetPlatformMenu();
        this.setLanguagesData();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.languages.eventLanguage !== this.props.languages.eventLanguage) {
            this.setLanguagesData();
        }
    }
    // if we had an error from server on login attempt we clear that error
    componentWillUnmount() {
        this.props.onClearError();
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.eventLanguage];
        this.setState({
            translation: translation,
        });
    };

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleLogin = (e) => {
        e.preventDefault();

        let loginUserData = {
            email: this.state.email,
            password: this.state.password,
        };

        this.props.onLoginUser(loginUserData, this.props.eventLanguage);
    };

    handleRecoverPassword = () => {
        this.setState({showRecoverPasswordDialog: true});
    };

    handleCloseDialog = (message) => {
        this.setState({showRecoverPasswordDialog: false, recoverPasswordServerMessage: message});
    };

    handleRegistrationClick = () => reactGAEgyptRegistationTrackingEvent(this.props.cookiesAccepted);

    render() {
        const {email, password, translation} = this.state;
        let {loadingUser} = this.props;
        const BackgroundImage = getPlatformBackground(whiteLabel);

        return (
            <div>
                <div className="login-page">
                    <div className="full-background-container p-relative d-flex">
                        <img
                            src={BackgroundImage}
                            className="full-background-img img-cover p-absolute w-100 h-100"
                            alt="background"
                        />
                        {!IS_SCA_ENV && <div className="full-background-overlay p-absolute w-100 h-100"></div>}
                        <div className="header-spacing-container">
                            <div className="form-wrapper w-100 h-100 d-flex justify-content-center align-items-center">
                                <div className="form-logo"></div>
                                <div className="login-container">
                                    <div className="left-column">
                                        <SocialLoginButtons registerAsExhibitor={false} registerAsParticipant={false} />
                                    </div>
                                    <div className="form-container">
                                        <Grid container justify="flex-end" className="login-popup" alignItems="center">
                                            <Grid item xs={12}>
                                                <div className="login-header">
                                                    <h1 className={classNames('page-title', whiteLabel)}>
                                                        {translation?.menu?.logIn}
                                                    </h1>
                                                    <p>{translation?.login?.pageTitle}</p>
                                                </div>
                                                <div className="login-body">
                                                    <ValidatorForm ref="form" onSubmit={this.handleLogin}>
                                                        <div className="email-wrapper">
                                                            <TextValidator
                                                                label={translation?.login?.inputEmail}
                                                                onChange={this.handleChange}
                                                                name="email"
                                                                value={email}
                                                                className="field-container"
                                                                variant="filled"
                                                                fullWidth
                                                                validators={['required', 'isEmail']}
                                                                errorMessages={[
                                                                    translation?.errors?.required,
                                                                    translation?.errors?.emailNotValid,
                                                                ]}
                                                            />
                                                            <AccountCircleIcon className="icon" />
                                                        </div>
                                                        <div className="password-wrapper">
                                                            <TextValidator
                                                                label={translation?.login?.inputPassword}
                                                                onChange={this.handleChange}
                                                                name="password"
                                                                value={password}
                                                                className="field-container"
                                                                variant="filled"
                                                                type="password"
                                                                fullWidth
                                                                validators={['required', 'minStringLength:8']}
                                                                errorMessages={[
                                                                    translation?.errors?.required,
                                                                    translation?.errors?.passwordMinLength,
                                                                ]}
                                                            />
                                                            <VpnKeyIcon className="icon" />
                                                        </div>
                                                        {this.props.error && <p>{`${this.props.error}`}</p>}
                                                        <div className="recover-wrapper">
                                                            <div>
                                                                <FormControlLabel
                                                                    style={{color: '#2B2B2B'}}
                                                                    control={
                                                                        <DarkCheckbox
                                                                            checked={this.state.rememberMe}
                                                                            onChange={() =>
                                                                                this.setState({
                                                                                    rememberMe: !this.state.rememberMe,
                                                                                })
                                                                            }
                                                                        />
                                                                    }
                                                                    label={translation?.login?.rememberMeButton}
                                                                />
                                                            </div>
                                                            <div>
                                                                <button
                                                                    className={whiteLabel}
                                                                    onClick={this.handleRecoverPassword}
                                                                    type="button"
                                                                >
                                                                    {translation?.login?.forgotPasswordButton}
                                                                </button>
                                                            </div>
                                                            {this.state.recoverPasswordServerMessage && (
                                                                <p className="server-message">
                                                                    {this.state.recoverPasswordServerMessage}
                                                                </p>
                                                            )}
                                                        </div>
                                                        <button
                                                            className={classNames('login-submit-button', {
                                                                disabled: this.state.disabled,
                                                            })}
                                                            type="submit"
                                                        >
                                                            {translation?.login?.loginButton}
                                                        </button>
                                                    </ValidatorForm>
                                                    <p className="uppercase to-register">
                                                        {translation?.login?.noAccountText}
                                                        <span>
                                                            <Link
                                                                onClick={this.handleRegistrationClick}
                                                                className={whiteLabel}
                                                                to={{pathname: '/register'}}
                                                            >
                                                                {translation?.login?.registerButton}
                                                            </Link>
                                                        </span>
                                                    </p>
                                                </div>
                                            </Grid>
                                        </Grid>
                                        {loadingUser && <Spinner />}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showRecoverPasswordDialog ? (
                    <RecoverPasswordDialog
                        opened={this.state.showRecoverPasswordDialog}
                        closeDialog={this.handleCloseDialog}
                    />
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedIn: state.user.loggedIn,
        loadingUser: state.user.loading,
        error: state.user.error,
        languages: state.languages,
        eventLanguage: state.languages.eventLanguage,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLoginUser: (user, eventLanguage) => dispatch(actions.loginUser(user, eventLanguage)),
        onClearError: () => dispatch(actions.clearError()),
        onSetPlatformMenu: () => dispatch(actions.setPlatformMenu()),
    };
};

export default withCookiesAccept(connect(mapStateToProps, mapDispatchToProps)(LoginPlatform));
