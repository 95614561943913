import {Api} from 'Api';
import * as actionTypes from '../actionTypes/sessionListTypes';

export const getSessionsListByRole =
    ({userRole, showTheNearest = false, orderBy, orderDirection, search}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
            sessionList: {pagination},
        } = getState();

        try {
            const {sessions, page, perPage, total} = await Api.request({
                url: `/event/${eventId}/${userRole}-sessions`,
                method: 'get',
                params: {
                    page: pagination.page,
                    perPage: pagination.perPage,
                    showTheNearest,
                    orderBy,
                    orderDirection,
                    search,
                },
            });

            const payload = {
                sessions,
                page,
                perPage,
                total,
            };

            dispatch({
                type: actionTypes.GET_SESSIONS_BY_ROLE_SUCCESS,
                payload,
            });
        } catch (error) {
            dispatch({type: actionTypes.GET_SESSIONS_BY_ROLE_FAIL, payload: error});
        }
    };

export const changeSessionListPage = (payload) => ({type: actionTypes.CHANGE_SESSION_LIST_PAGE, payload});

export const clearSessionList = () => ({type: actionTypes.CLEAR_SESSION_LIST});
