import connect from 'react-redux/es/connect/connect';
import {withRouter} from 'react-router';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import {Wall} from './Wall';

export default withRouter(
    connect(
        (state) => ({
            currentUser: selectors.getUserData(state),
            sideNavigation: selectors.getSideNavigation(state),
            sideNavigationDetails: selectors.getSideNavigationDetails(state),
            event: selectors.getEventData(state),
            languages: selectors.getLanguages(state),
            isRtlLanguage: selectors.getIsRtlLanguage(state),
            resourcesAccess: selectors.getResourcesAccess(state),
            posts: selectors.getWallPosts(state),
            total: selectors.getWallPostsTotal(state),
            wallPostsLoading: selectors.getWallPostsLoading(state),
            wallType: selectors.getWallType(state),
            isLoadingPosts: selectors.getWallPostsLoading(state),
            activeWallId: selectors.getActiveWallId(state),
        }),
        {
            changeWallType: actions.changeWallType,
            changeWallStatus: actions.changeWallStatus,
            toggleWall: actions.toggleWall,
            onGetWallPosts: actions.getWallPosts,
            onUpdatePostTrigger: actions.updatePostTrigger,
            onDeletePostTrigger: actions.deletePostTrigger,
            onUpdateCommentTrigger: actions.updateCommentTrigger,
            onDeleteCommentsTrigger: actions.deleteCommentsTrigger,
            onLikePostTrigger: actions.likePostTrigger,
            onUnlikePostTrigger: actions.unlikePostTrigger,
            onPinPostTrigger: actions.pinPostTrigger,
            onUnpinPostTrigger: actions.unpinPostTrigger,
            onLikeWallPost: actions.likeWallPost,
            onUnlikeWallPost: actions.unlikeWallPost,
            onAddWallPost: actions.addWallPost,
            onAddWallPostComment: actions.addWallPostComment,
            onCloseActiveWall: actions.closeActiveWall,
            onResetWallData: actions.resetWallData,
            onResetActiveWallId: actions.resetActiveWallId,
        }
    )(Wall)
);
