import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles({
    videoPlate: {
        width: '100%',
        height: '100%',
    },
    activeStreamsVideoPlate: {
        position: 'absolute',
        width: 50,
        height: 40,
        right: 0,
        top: 0,
        zIndex: 150,
        overflow: 'hidden',
        border: '1px solid #fff',
        backgroundColor: '#000',
    },
    video: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    userName: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hidden: {
        display: 'none',
    },
});
