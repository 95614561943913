import React, {useState, useCallback, useEffect} from 'react';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import ReactPlayer from 'react-player';
import {FormControlLabel} from '@material-ui/core';
import {useSelector} from 'react-redux';

import Button from '../Button/Button';
import {DefaultWhiteCheckbox} from '../CustomCheckbox/CustomCheckbox';

import {redirectToEventByRole, redirectToLastEvent, getUserEventIdBySlug} from '../../../services/helpers';
import {Api} from '../../../Api';

import './WelcomeVideo.scss';
import {eventFolder} from '../../../config';

export function WelcomeVideo({user, loggedIn, event, isMobile, topNavClose, getEvent, setEventSlug}) {
    const eventId = useSelector((state) => state.event.eventId);
    const history = useHistory();
    const {search} = useLocation();
    const {eventSlug} = useParams();
    const [checked, setChecked] = useState(false);

    useEffect(() => {
        if (loggedIn && !event) {
            const eventId = getUserEventIdBySlug(user, eventSlug);

            getEvent(eventId, () => setEventSlug(eventSlug));
        }
    }, [event, eventSlug, getEvent, loggedIn, setEventSlug, user]);

    const handleChange = ({target: {checked}}) => setChecked(checked);

    const handleSubmit = useCallback(async () => {
        const query = new URLSearchParams(search);
        const registerAsExhibitor = query.get('registerAsExhibitor') === 'true';
        const registerAsParticipant = query.get('registerAsParticipant') === 'true';
        const iframe = query.get('iframe') === 'true';

        if (checked) {
            try {
                await Api.request({
                    method: 'post',
                    url: '/users/toggle-welcome-note',
                    payload: {eventId, type: 'showWelcomeVideo'},
                });
            } catch (error) {}
        }

        if (eventSlug) {
            const userHasRoleInEvent = user.eventRoles.some((roles) => roles.event.slug === eventSlug);
            if (!userHasRoleInEvent) {
                // if he doesn't have any role, it means he is trying to register at this event
                if (registerAsExhibitor) {
                    history.push(`/event/${eventSlug}/exhibitor-registration`);
                } else if (registerAsParticipant) {
                    history.push(`/event/${eventSlug}/participant-registration${iframe ? '?iframe=true' : ''}`);
                } else {
                    history.push(`/event/${eventSlug}`);
                }
            } else {
                redirectToEventByRole(eventSlug, user, history);
            }
        } else {
            redirectToLastEvent(user, history, () => topNavClose());
        }
    }, [eventId, eventSlug, history, search, topNavClose, user, checked]);

    return (
        <div className="welcomeVideo">
            <ReactPlayer
                url={
                    event?.welcomeVideo
                        ? `${eventFolder}${event?._id}/${event?.welcomeVideo}`
                        : event?.welcomeVideoYoutube
                }
                width="100%"
                height="99%"
                className="welcomeVideo__media"
                playing
                stopOnUnmount={false}
            />
            <div className="welcomeVideo__banner">
                <div className="description">
                    <p className="text">By Entering the event, You will skip this video</p>
                    <FormControlLabel
                        labelPlacement={'end'}
                        classes={{
                            root: 'label-wrapper',
                            label: 'label-text',
                        }}
                        control={
                            <DefaultWhiteCheckbox
                                checked={checked}
                                onChange={handleChange}
                                name="showAgain"
                                color="default"
                            />
                        }
                        label="Don’t show me again"
                    />
                </div>
                <Button className="enterPlatformButton" title="Enter" onClick={handleSubmit} />
            </div>
        </div>
    );
}
