import React from 'react';
import * as yup from 'yup';
import _ from 'lodash';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {Dialog, Button, TextField} from '@material-ui/core';
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import {useAgendaDropdownOpening} from './hooks';
import {useMedia, usePlatformTranslation} from 'services/hooks';
import {getLocalTimeZone} from 'services/utils';
import {addCustomElement, toggleAgendaCustomElement} from 'store/actions';
import * as selectors from 'store/selectors';

import Form from '../Form/Form';
import FormGroup from '../FormGroup/FormGroup';
import TimeZoneSelect from '../TimeZoneSelect/TimeZoneSelect';
import {CustomKeyboardTimePicker} from '../CustomInputComponents';

import './AgendaCustomElementDialog.scss';

const AgendaCustomElementDialog = () => {
    const dispatch = useDispatch();
    const openAgendaDropdown = useAgendaDropdownOpening();
    const isAgendaCustomElementOpened = useSelector(selectors.isAgendaCustomElementOpened);

    const isMobile = useMedia('(max-width: 640px)');
    const {personalAgenda, generalText, errors: t_errors} = usePlatformTranslation();

    const schema = yup.object().shape({
        date: yup.date().required(t_errors?.required),
        timezone: yup.string().required(t_errors?.required),
        startTime: yup.date(t_errors?.required).required(t_errors?.required).typeError(t_errors?.required),
        endTime: yup
            .date(t_errors?.required)
            .min(yup.ref('startTime'), t_errors?.taskStartBeforeEnd)
            .required(t_errors?.required)
            .typeError(t_errors?.required),
        title: yup.string().required(t_errors?.required),
    });

    const {
        formState: {errors, isDirty},
        handleSubmit,
        getValues,
        control,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            title: '',
            date: moment().format('yyyy-MM-DD'),
            timezone: getLocalTimeZone(),
            startTime: moment(),
            endTime: moment().add({minute: 30}),
        },
        mode: 'all',
    });

    const handleClose = () => {
        dispatch(toggleAgendaCustomElement(false));
        openAgendaDropdown();
        reset();
    };

    const onSubmit = () => {
        const data = getValues();
        dispatch(
            addCustomElement({
                title: data.title,
                date: moment(data.date).format('yyyy-MM-DD'),
                startTime: moment(data.startTime).format('HH:mm'),
                endTime: moment(data.endTime).format('HH:mm'),
                timeZone: data.timezone,
            })
        );
        handleClose();
    };

    const isDisabled = !_.isEmpty(errors) || !isDirty;

    return (
        <Dialog fullScreen={isMobile} open={isAgendaCustomElementOpened} onClose={handleClose}>
            <div className="AgendaCustomElementDialog">
                <h2 className="AgendaCustomElementDialog__title">{personalAgenda.addNewTask.default}</h2>

                <div className="AgendaCustomElementDialog__form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Row>
                            <Controller
                                control={control}
                                label={personalAgenda.title}
                                name="title"
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        variant="outlined"
                                        fullWidth
                                        helperText={errors?.title?.message || ''}
                                        error={errors.title}
                                    />
                                )}
                            />
                        </Form.Row>

                        <Form.Row>
                            <Controller
                                control={control}
                                name="timezone"
                                render={({field}) => <TimeZoneSelect {...field} />}
                            />
                        </Form.Row>

                        <Form.Row>
                            <Controller
                                control={control}
                                name="date"
                                render={({field}) => (
                                    <TextField
                                        {...field}
                                        label={personalAgenda.startDate}
                                        type="date"
                                        variant="outlined"
                                        fullWidth
                                        helperText={errors?.date?.message || ''}
                                        error={errors.date}
                                    />
                                )}
                            />
                        </Form.Row>

                        <Form.Row>
                            <FormGroup verticalAlignment="top">
                                <FormGroup.HalfItem>
                                    <Controller
                                        control={control}
                                        name="startTime"
                                        render={({field}) => (
                                            <CustomKeyboardTimePicker
                                                {...field}
                                                label={personalAgenda.starts}
                                                fullWidth
                                                helperText={errors?.startTime?.message || ''}
                                                error={errors.startTime}
                                            />
                                        )}
                                    />
                                </FormGroup.HalfItem>

                                <FormGroup.HalfItem>
                                    <Controller
                                        control={control}
                                        name="endTime"
                                        render={({field}) => (
                                            <CustomKeyboardTimePicker
                                                {...field}
                                                label={personalAgenda.ends}
                                                helperText={errors?.endTime?.message || ''}
                                                error={errors.endTime}
                                                fullWidth
                                            />
                                        )}
                                    />
                                </FormGroup.HalfItem>
                            </FormGroup>
                        </Form.Row>

                        <Form.Row>
                            <FormGroup horizontalAlignment="right">
                                <FormGroup.NarrowItem>
                                    <Button onClick={handleClose}>{generalText.cancel}</Button>
                                </FormGroup.NarrowItem>

                                <FormGroup.NarrowItem>
                                    <Button disabled={isDisabled} type="submit">
                                        {personalAgenda.create}
                                    </Button>
                                </FormGroup.NarrowItem>
                            </FormGroup>
                        </Form.Row>
                    </form>
                </div>
            </div>
        </Dialog>
    );
};

export default AgendaCustomElementDialog;
