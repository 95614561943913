import React from 'react';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './CustomTextArea.scss';

const CustomTextArea = ({setText, textLength, label, rows, showTextLength, value}) => {
    const handleText = (text) => {
        if (text.length <= textLength) {
            setText(text);
        } else {
            setText(text.slice(0, textLength));
        }
    };

    return (
        <TextField
            className={classNames('custom-text-area')}
            label={label}
            multiline
            maxRows={rows * 2}
            minRows={rows}
            fullWidth={true}
            margin="normal"
            variant="outlined"
            onChange={(event) => handleText(event.target.value)}
            value={value || ''}
            InputProps={{
                endAdornment: showTextLength ? (
                    <div className={classNames('custom-text-area__length')}>
                        <p>{`${value?.length || 0}/${textLength}`}</p>
                    </div>
                ) : (
                    <></>
                ),
            }}
        />
    );
};

CustomTextArea.propTypes = {
    setText: PropTypes.func.isRequired,
    textLength: PropTypes.number,
    label: PropTypes.string,
    rows: PropTypes.number,
    showTextLength: PropTypes.bool,
    value: PropTypes.string,
};

CustomTextArea.defaultProps = {
    textLength: 180,
    label: 'Post caption',
    rows: 4,
    showTextLength: true,
};

export default CustomTextArea;
