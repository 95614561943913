import React from 'react';
import _ from 'lodash';

import VideoConference from '../VideoConference';
import EditGroupChatDialog from './components/EditGroupChat';
import SendGroupChatMessage from './components/SendGroupChatMessage';
import GroupChatMembers from './components/GroupChatMembers/GroupChatMembers';
import EmptyChat from '../EmptyChat/EmptyChat';
import ListMessages from '../ListMessages';
import GroupChatHeader from './components/GroupChatHeader/GroupChatHeader';

import {connectToGroupChat, groupMessageReceived, disconnectFromGroupChat} from 'Api/socketApi';
import {getPrivateChatId} from 'services/utils';
import {VIDEO_CONFERENCE_TYPES} from 'constants/shared';

import './GroupChat.scss';

class GroupChat extends React.Component {
    constructor(props) {
        super(props);
        this.newMessageRef = React.createRef();
    }

    state = {
        messages: [],
        displayChatMembers: false,
        editGroupChatDialog: false,
        chatMembers: [],
        chatName: '',
        isArchived: false,
        isOwner: false,
        userSentNewMessage: false,
        groupChatAvatarString: '',
        translation: null,
    };

    componentDidMount() {
        // get the chat members and check if current user is the owner. Save them to state
        this.getChatInitialData();

        // connect to chat By Socket
        connectToGroupChat({chatId: this.props.activeChatRoomId, userId: this.props.user._id});

        this.props.getEventUsersList();

        // load all the messages though API call. Save them to state.
        this.onGetGroupChatMessages();
        this.props.readMessagesForGroupChat(this.props.activeChatRoomId);

        // listen to socket for a new message and save it to state
        groupMessageReceived((err, data) => {
            if (!data || !data.message || data.chatId !== this.props.activeChatRoomId) return;
            const newMessage = data.message;
            this.setState({messages: [...this.state.messages, newMessage]});
            this.scrollToBottom();
            if (data.message.user._id === this.props.user._id) {
                this.setState(
                    {
                        userSentNewMessage: true,
                    },
                    () => {
                        this.setState({
                            userSentNewMessage: false,
                        });
                    },
                    1000
                );
            } else {
                this.setState({
                    userSentNewMessage: false,
                });
            }
        });

        this.interval = setInterval(() => this.forceUpdate(), 60000);

        this.setLanguagesData();
    }

    componentWillUnmount() {
        disconnectFromGroupChat({chatId: this.props.activeChatRoomId, userId: this.props.user._id});
        clearInterval(this.interval);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.activeChatRoomId !== prevProps.activeChatRoomId) {
            // disconnect from previous chat
            disconnectFromGroupChat({chatId: prevProps.activeChatRoomId, userId: this.props.user._id});

            // connect to new chat by Socket
            connectToGroupChat({chatId: this.props.activeChatRoomId, userId: this.props.user._id});

            this.props.readMessagesForGroupChat(this.props.activeChatRoomId);

            // get the chat members and check if current user is the owner. Save them to state
            this.getChatInitialData();

            // load all the messages though API call. Save them to state.
            this.onGetGroupChatMessages();

            // hide members
            this.setState({displayChatMembers: false});
            this.setState({editGroupChatDialog: false});
        }

        let previousGroupChat = prevProps.user.groupChats.find(
            (groupChat) => groupChat.chat._id === this.props.activeChatRoomId
        );

        if (previousGroupChat) {
            let previousGroupChatData = previousGroupChat.chat;
            let currentGroupChat = this.props.user.groupChats.find(
                (groupChat) => groupChat.chat._id === this.props.activeChatRoomId
            );
            let currentGroupChatData = currentGroupChat.chat;

            if (previousGroupChatData.users !== currentGroupChatData.users) {
                this.getChatInitialData();
            }
        }

        const {isActive} = this.props.videoConference;
        if (isActive && prevState.chatMembers.length !== this.state.chatMembers.length) {
            const {user, videoConference, activeChatRoomId} = this.props;
            const members = _.xorBy(this.state.chatMembers, prevState.chatMembers, '_id');
            this.props.changePlaybacks(videoConference, user, members, activeChatRoomId);
        }

        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    getChatInitialData = () => {
        let chatData = this.props.user.groupChats.find(
            (groupChat) => groupChat.chat._id === this.props.activeChatRoomId
        );

        if (!chatData) return;

        const isArchived = chatData.archived;
        const groupChatData = chatData.chat;
        let isOwner =
            groupChatData?.owners?.some((id) => id === this.props.user._id) ||
            groupChatData.owner === this.props.user._id;
        let groupChatAvatarString = groupChatData.users[0].first[0] + groupChatData.users[0].last[0];

        this.setState({
            chatMembers: groupChatData.users,
            chatName: groupChatData.name,
            groupChatAvatarString: groupChatAvatarString,
            isOwner,
            isArchived,
        });

        const data = {
            chatId: this.props.activeChatRoomId,
            chatMembers: groupChatData.users,
            chatName: groupChatData.name,
            groupChatAvatarString: groupChatAvatarString,
            isOwner,
            isArchived,
        };

        this.props.onSetActiveChatData(data);
    };

    onGetGroupChatMessages = () => {
        const {activeChatRoomId, getGroupChatMessages} = this.props;

        getGroupChatMessages({
            activeChatRoomId,
            callback: (messages) => {
                this.setState({messages});
                this.scrollToBottom();
            },
        });
    };

    handleCloseEditGroupChat = () => {
        this.setState({editGroupChatDialog: false, displayChatMembers: false});
        this.props.onUpdateActiveChatData({editGroupChatDialog: false, displayChatMembers: false});
    };

    handleOpenPrivateChat = (targetUserId) => {
        const {eventId} = this.props;
        const privateChatId = getPrivateChatId(this.props.user.privateChats, targetUserId);

        if (privateChatId) {
            this.props.onOpenPrivateChat(privateChatId);
        } else {
            if (targetUserId !== this.props.user._id) {
                this.props.onCreatePrivateChat(targetUserId, eventId);
            }
        }
    };

    scrollToBottom = () => {
        let messagesContainer = document.getElementById('chat-messages-container');
        if (messagesContainer) {
            messagesContainer.scrollTop = messagesContainer.scrollHeight;
        }
    };

    handleRemoveUserFromGroupChat = (userId) => {
        const {activeChatRoomId, onUpdateActiveChatData, removeUserFromGroupChat} = this.props;
        const {chatMembers} = this.state;

        removeUserFromGroupChat({
            activeChatRoomId,
            userId,
            callback: () => {
                const updatedMembers = chatMembers.filter((obj) => obj._id !== userId);
                this.setState({chatMembers: updatedMembers});
                onUpdateActiveChatData({chatMembers: updatedMembers});
            },
        });
    };

    closeChat = () => {
        this.props.onCloseActiveChat();
    };

    onLeaveGroupChat = (userId) => {
        const {activeChatRoomId, onUpdateActiveChatTab, eventChatsTab, eventId, user, leaveGroupChat} = this.props;
        const {chatMembers} = this.state;

        leaveGroupChat({
            activeChatRoomId,
            userId,
            callback: () => {
                const updatedMembers = chatMembers.filter((obj) => obj._id !== userId);
                this.setState({chatMembers: updatedMembers});
                disconnectFromGroupChat({chatId: activeChatRoomId, userId: user._id});
                clearInterval(this.interval);
                this.closeChat();
                onUpdateActiveChatTab({eventChats: eventChatsTab}, eventId);
            },
        });
    };

    openVideoConference = () => {
        const {user, activeChatRoomId, stopCalling, openVideoConference, setActiveVideoConferenceType} = this.props;
        setActiveVideoConferenceType(VIDEO_CONFERENCE_TYPES.group);
        stopCalling();
        const members = this.state.chatMembers.map((member) => member._id).filter((memberId) => memberId !== user._id);
        openVideoConference(user, members, activeChatRoomId, true);
    };

    handleShowMembers = () => {
        this.setState({
            displayChatMembers: !this.state.displayChatMembers,
            editGroupChatDialog: false,
        });
    };
    handleSettings = () => {
        this.setState({
            editGroupChatDialog: true,
            displayChatMembers: false,
        });
    };

    render() {
        const displayChatMembers = this.props?.activeChatData?.displayChatMembers || false;
        const editGroupChatDialog = this.props?.activeChatData?.editGroupChatDialog || false;

        return (
            <div className="single-chat-container">
                {this.props.videoConference.isActive && (
                    <VideoConference
                        members={this.state.chatMembers}
                        hidden={displayChatMembers || editGroupChatDialog}
                    />
                )}

                {!this.props.isMobile && this.props.activeChatData && (
                    <GroupChatHeader activeChatData={this.props.activeChatData} />
                )}

                {displayChatMembers ? (
                    <div className="chat-wrapper">
                        <GroupChatMembers
                            chatMembers={this.state.chatMembers}
                            handleRemoveUserFromGroupChat={this.handleRemoveUserFromGroupChat}
                            leaveGroupChat={this.onLeaveGroupChat}
                            handleOpenPrivateChat={this.handleOpenPrivateChat}
                            isOwner={this.state.isOwner}
                            {...this.props}
                        />
                    </div>
                ) : editGroupChatDialog ? (
                    <div className="chat-wrapper">
                        <EditGroupChatDialog
                            opened={editGroupChatDialog}
                            closeGroupChatDialog={this.handleCloseEditGroupChat}
                        />
                    </div>
                ) : this.state.messages.length > 0 ? (
                    <ListMessages
                        openVideoConference={this.openVideoConference}
                        languages={this.props.languages}
                        userId={this.props.user._id}
                        messages={this.state.messages}
                        userSentNewMessage={this.state.userSentNewMessage}
                        receivedMessagesForChatId={this.state.receivedMessagesForChatId}
                        isGroupChat
                    />
                ) : (
                    <EmptyChat />
                )}

                {!displayChatMembers && !editGroupChatDialog && (
                    <SendGroupChatMessage startConference={this.openVideoConference} />
                )}
            </div>
        );
    }
}

export default GroupChat;
