import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getBusinessCardLink} from '../../../../../../../../../../../../../routes';
import {setAccountBackLink} from '../../../../../../../../../../../../../store/actions/accountActions';

import './MobileBusinessCardSetupPanel.scss';

const MobileBusinessCardSetupPanel = ({title, children}) => {
    const dispatch = useDispatch();
    const eventSlug = useSelector((state) => state.event.eventSlug);
    const linkBack = getBusinessCardLink(eventSlug);
    useEffect(() => {
        dispatch(setAccountBackLink({link: linkBack, name: title}));
    });
    return (
        <div className="MobileBusinessCardSetupPanel">
            <div className="MobileBusinessCardSetupPanel__content">{children}</div>
        </div>
    );
};

export default MobileBusinessCardSetupPanel;
