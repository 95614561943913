import RegisterPlatform from './RegisterPlatform';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../../store/actions/index';

export default withRouter(
    connect(
        (state) => ({
            loggedIn: state.user.loggedIn,
            loadingUser: state.user.loading,
            error: state.user.error,
            platformLanguage: state.languages.platformLanguage,
            languages: state.languages,
        }),
        {
            onRegisterUser: actions.registerUser,
            onClearError: actions.clearError,
            onSetPlatformMenu: actions.setPlatformMenu,
        }
    )(RegisterPlatform)
);
