import React from 'react';

import BusinessCardPreview from '../BusinessCardPreview/BusinessCardPreview';

import {useBusinessCardData} from 'services/hooks';
import './SetupBusinessCardPreview.scss';

const SetupBusinessCardPreview = () => {
    const {businessCard} = useBusinessCardData();

    if (!businessCard) {
        return null;
    }

    return (
        <div className="SetupBusinessCardPreview">
            <BusinessCardPreview {...businessCard} />
        </div>
    );
};

export default SetupBusinessCardPreview;
