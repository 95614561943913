import React from 'react';

const IconMembers = () => {
    return (
        <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.8778 15.0345C15.0038 15.0345 15.9166 14.0891 15.9166 12.9229C15.9166 11.7567 15.0038 10.8113 13.8778 10.8113C12.7517 10.8113 11.8389 11.7567 11.8389 12.9229C11.8389 14.0891 12.7517 15.0345 13.8778 15.0345Z"
                fill="#2B2B2B"
            />
            <path
                d="M13.8786 15.6156C13.223 15.6156 12.6008 15.6616 12.0508 15.7882C12.0674 15.7997 12.0897 15.8112 12.1063 15.817C13.0508 16.3693 13.5286 17.2784 13.5286 18.527V19.252C13.5286 19.5339 13.4341 19.7928 13.2674 19.9999H17.2786C17.673 19.9999 18.0008 19.6662 18.0008 19.252V18.527C18.0008 16.1852 16.1452 15.6156 13.8786 15.6156Z"
                fill="#2B2B2B"
            />
            <path
                d="M4.12287 15.0345C5.24892 15.0345 6.16176 14.0891 6.16176 12.9229C6.16176 11.7567 5.24892 10.8113 4.12287 10.8113C2.99683 10.8113 2.08398 11.7567 2.08398 12.9229C2.08398 14.0891 2.99683 15.0345 4.12287 15.0345Z"
                fill="#2B2B2B"
            />
            <path
                d="M4.45586 18.5327C4.45586 17.2842 4.93364 16.3693 5.87808 15.8227C5.9003 15.8112 5.91697 15.7997 5.93919 15.7882C5.38919 15.6674 4.77253 15.6213 4.12808 15.6213C1.86142 15.6213 0.00585938 16.1852 0.00585938 18.5327V19.2577C0.00585938 19.6662 0.328082 20.0057 0.728082 20.0057H4.72253C4.56142 19.8043 4.46142 19.5396 4.46142 19.2577V18.5327H4.45586Z"
                fill="#2B2B2B"
            />
            <path
                d="M8.98908 15.0345C10.1151 15.0345 11.028 14.0891 11.028 12.9229C11.028 11.7567 10.1151 10.8113 8.98908 10.8113C7.86304 10.8113 6.9502 11.7567 6.9502 12.9229C6.9502 14.0891 7.86304 15.0345 8.98908 15.0345Z"
                fill="#2B2B2B"
            />
            <path
                d="M8.98941 15.6156C6.72274 15.6156 4.86719 16.1795 4.86719 18.527V19.252C4.86719 19.6605 5.18941 19.9999 5.58941 19.9999H12.3894C12.7839 19.9999 13.1116 19.6662 13.1116 19.252V18.527C13.1116 16.1852 11.2561 15.6156 8.98941 15.6156Z"
                fill="#2B2B2B"
            />
            <path
                d="M5.32227 7.4624C5.56115 7.68104 5.82227 7.88242 6.10004 8.05504L5.90004 10.0746C5.88893 10.1839 6.01115 10.253 6.09449 10.1897L7.88893 8.81453C8.26671 8.90659 8.66115 8.96988 9.06671 8.9929C10.4667 9.0792 11.8112 8.73398 12.8667 8.00901C12.9 7.98599 12.9278 7.96298 12.9612 7.94572C13.1556 7.80763 13.3334 7.65803 13.4945 7.50268C13.7 7.30705 13.8834 7.09416 14.0389 6.87552C14.1556 6.71441 14.2612 6.5418 14.35 6.36919C14.5889 5.89738 14.7334 5.3853 14.7667 4.8502C14.8389 3.63617 14.3278 2.4624 13.3389 1.54756C12.3667 0.649976 11.0334 0.103371 9.59449 0.0170648C9.28338 -0.000196455 8.97227 -0.000196455 8.66671 0.0228185C8.41671 0.0400797 8.16671 0.0746021 7.92227 0.126386C7.1056 0.28749 6.34449 0.6097 5.70004 1.07C4.6056 1.84675 3.96671 2.94572 3.89449 4.15976C3.82227 5.37379 4.32782 6.54756 5.32227 7.4624Z"
                fill="#2B2B2B"
            />
        </svg>
    );
};

export default IconMembers;
