import React from 'react';

import Svg from '../Svg/Svg';

import './Trash.scss';

const IconTrash = () => (
    <Svg className="IconTrash">
        <path d="M18.5338 3.59048H15.6622V1.68168C15.6622 1.29747 15.3592 1 14.9836 1C14.9472 1 14.9229 1.01245 14.911 1.02486C14.8988 1.01245 14.8745 1 14.8624 1H8.11331H8.06497H8.01636C7.64078 1 7.34997 1.29747 7.34997 1.68168V3.59051H4.46619C3.64211 3.59051 3 4.24732 3 5.09028V6.18101V6.54434C3 7.09663 3.44772 7.54434 4 7.54434H4.27231V21.5127C4.27231 22.3557 4.90228 23 5.72636 23H17.2737C18.0977 23 18.7398 22.3557 18.7398 21.5127V7.54434H19C19.5523 7.54434 20 7.09663 20 6.54434V6.18101V5.09028C20 4.24732 19.3576 3.59048 18.5338 3.59048ZM8.68278 2.36333H14.3172V3.59048H8.68278V2.36333ZM17.3949 21.5127C17.3949 21.5997 17.3584 21.6367 17.2737 21.6367H5.72636C5.64158 21.6367 5.60514 21.5996 5.60514 21.5127V7.54432H17.3949V21.5127ZM18.6551 6.18098H4.34494V5.09025C4.34494 5.00351 4.38139 4.95381 4.46617 4.95381H18.5338C18.6186 4.95381 18.6551 5.00351 18.6551 5.09025V6.18098ZM14.5566 19.5V14.5C14.5566 14.2239 14.3328 14 14.0566 14C13.7805 14 13.5566 14.2239 13.5566 14.5V19.5C13.5566 19.7761 13.7805 20 14.0566 20C14.3328 20 14.5566 19.7761 14.5566 19.5ZM9.55664 10.5V19.5C9.55664 19.7761 9.33278 20 9.05664 20C8.7805 20 8.55664 19.7761 8.55664 19.5V10.5C8.55664 10.2239 8.7805 10 9.05664 10C9.33278 10 9.55664 10.2239 9.55664 10.5Z" />
    </Svg>
);

export default IconTrash;
