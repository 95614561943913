export const YOUTUBE = 'Youtube';
export const ETX = 'ETX Engine';
export const LIVESTREAM = 'Livestream (M3U8)';
export const ZOOM = 'Zoom';
export const ETX_LIVE_SESSION = 'ETX Live Session';

export const streamingEngineTypes = [
    {
        value: YOUTUBE,
        label: YOUTUBE,
    },
    {
        value: ETX,
        label: ETX,
    },
    {
        value: LIVESTREAM,
        label: LIVESTREAM,
    },
    {
        value: ZOOM,
        label: 'RTMP (ZOOM)',
    },
    {
        value: ETX_LIVE_SESSION,
        label: ETX_LIVE_SESSION,
    },
];
