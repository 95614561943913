import React from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';
import CustomTable from '../../../../../../../../CustomTable';
import {usePlatformTranslation} from '../../../../../../../../../../services/hooks';

function AppliedJobsTable() {
    const translation = usePlatformTranslation();
    const jobsList = useSelector((state) => state.appliedJobs.jobsList);

    const columns = [
        {id: 'jobTitle', name: 'jobTitle', label: translation.appliedJobs.jobTitle, minWidth: 100, maxWidth: 244},
        {
            id: 'exhibitorCompany',
            name: 'exhibitorCompany',
            label: translation?.appliedJobs?.companyName,
            minWidth: 100,
            maxWidth: 220,
        },
        {
            id: 'createdAt',
            name: 'createdAt',
            label: translation?.appliedJobs?.appliedOn,
            minWidth: 100,
            maxWidth: 220,
            format: (value) => moment(value).format('DD MMM YYYY'),
        },
        {
            id: 'status',
            name: 'status',
            label: translation?.appliedJobs?.status,
            minWidth: 100,
            maxWidth: 170,
        },
    ];

    return <CustomTable data={jobsList} columns={columns} rowsPerPage={10} />;
}

export default AppliedJobsTable;
