import {createSelector} from 'reselect';

const languages = (state) => state.languages;
const translations = (state) => state.languages.translations;
const platformLanguage = (state) => state.languages.platformLanguage;
const eventLanguage = (state) => state.languages.eventLanguage;

export const getLanguages = createSelector(languages, (state) => state);

export const getOrganizerBrandingLanguage = createSelector(languages, (state) => state.organizerBrandingLanguage);

export const getTranslation = createSelector(
    translations,
    platformLanguage,
    (translations, platformLanguage) => translations[platformLanguage]
);

export const getEventTranslation = createSelector(
    translations,
    eventLanguage,
    (translations, eventLanguage) => translations[eventLanguage]
);

export const getEventLanguage = createSelector(languages, (state) => state.eventLanguage);

export const getIsRtlLanguage = createSelector(languages, (state) => state.isRtlLanguage);

export const getPlatformLanguage = createSelector(languages, (state) => state.platformLanguage);
