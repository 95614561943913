import * as actionTypes from '../../../actionTypes/organizerActionTypes/auditorium';
import {updateObject} from '../../../utilityStateFunction';
import {initialState} from '../initialState';

const auditoriumSetup = (state, {type, payload}) => {
    if (state === undefined) {
        return initialState.auditoriumSetup;
    }
    switch (type) {
        case actionTypes.UPDATE_AUDITORIUM_DETAILS_START:
        case actionTypes.GET_AUDITORIUM_POLLS_START:
            return updateObject(state.auditoriumSetup, {
                loading: true,
                error: null,
            });
        case actionTypes.UPDATE_AUDITORIUM_DETAILS_FAIL:
        case actionTypes.GET_AUDITORIUM_POLLS_FAIL:
            return updateObject(state.auditoriumSetup, {
                loading: false,
                error: payload,
            });
        case actionTypes.GET_AUDITORIUM_POLLS_SUCCESS:
            return updateObject(state.auditoriumSetup, {
                polls: payload.polls,
                loading: false,
                error: null,
            });
        case actionTypes.UPDATE_AUDITORIUM_DETAILS_SUCCESS: {
            return updateObject(state.auditoriumSetup, {
                loading: false,
                error: null,
            });
        }

        default:
            return state.auditoriumSetup;
    }
};

export default auditoriumSetup;
