import {cloneDeep} from 'lodash';
import * as businessCardActionTypes from '../actionTypes/businessCardActionTypes';
import {emptyBusinessCard, removeEmptyValuesFromObj} from 'services/helpers';
import {loadStates} from 'constants/data';
import {updateObject} from '../utilityStateFunction';

const initialState = {
    businessCard: emptyBusinessCard,
    wasChanged: false,
    isValid: true,
    loadState: '',
};

const reducer = (state = cloneDeep(initialState), action) => {
    switch (action.type) {
        case businessCardActionTypes.START_LOAD: {
            if (state.businessCard) {
                return updateObject(state, {wasChanged: false});
            }

            return updateObject(state, {loadState: loadStates.loading, wasChanged: false});
        }
        case businessCardActionTypes.END_LOAD: {
            const {businessCard, error} = action.payload;
            const loadState = error ? loadStates.error : loadStates.ready;
            return updateObject(state, {businessCard, loadState});
        }
        case businessCardActionTypes.CREATE: {
            const {businessCard} = action.payload;
            return updateObject(state, {businessCard});
        }
        case businessCardActionTypes.CHANGE_FIELD: {
            const {name, value} = action.payload;
            let businessCard = {...state.businessCard};
            businessCard = updateObject(businessCard, {[name]: value});
            return updateObject(state, {businessCard, wasChanged: true});
        }
        case businessCardActionTypes.FILL_UP_DATA_FROM_ACCOUNT: {
            const {userData, companyName} = action.payload;
            const {first: firstName, last: lastName, email} = userData;
            const userFields = {firstName, lastName, email, companyName};

            let businessCard = {...state.businessCard};
            businessCard = updateObject(businessCard, {...removeEmptyValuesFromObj(userFields)});
            return updateObject(state, {businessCard, wasChanged: true});
        }
        case businessCardActionTypes.UPLOAD_LOGO: {
            const {media, file} = action.payload;

            let businessCard = {...state.businessCard};
            businessCard = updateObject(businessCard, {logo: {media, file}});
            return updateObject(state, {businessCard, wasChanged: true});
        }
        case businessCardActionTypes.RESET_LOGO: {
            const {logo} = action.payload;
            let businessCard = {...state.businessCard};
            businessCard = updateObject(businessCard, {logo});
            return updateObject(state, {businessCard, wasChanged: true});
        }
        case businessCardActionTypes.SET_IS_FORM_VALID: {
            const {isValid} = action.payload;
            return updateObject(state, {isValid});
        }
        case businessCardActionTypes.RESET_BUSINESS_CARD:
            return cloneDeep(initialState);

        default:
            return state;
    }
};

export default reducer;
