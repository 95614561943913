import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import {
    Web as WebIcon,
    PhoneIphone as PhoneIphoneIcon,
    MailOutline as MailOutlineIcon,
    CloudDownload as CloudDownloadIcon,
    ArrowBackIos as ArrowBackIosIcon,
} from '@material-ui/icons';

import Media from '../../../../../../../Media/Media';

import {
    createPrivateChat,
    openPrivateChat,
    openWallet,
    getBusinessCard,
    postBusinessCard,
    deleteBusinessCardNote,
} from 'store/actions';
import {getEventId, getUserData, getUserLoading, getUserPrivateChats} from 'store/selectors';
import {getPrivateChatId} from 'services/utils';
import {usePlatformTranslation} from 'services/hooks';
import ChatButton from '../../../../../../../SideMenu/components/Networking/components/UsersList/components/UserPreviewPlate/components/ChatButton/ChatButton';
import BusinessCardNotes from '../BusinessCardNotes/BusinessCardNotes';

import './BusinessCard.scss';

const logoHolderStyles = {
    position: 'relative',
    height: '100%',
    maxWidth: '240px',
    width: '100%',
    margin: '0 auto',
    marginBottom: '24px',
};

const logoStyles = {position: 'absolute', left: '0', top: '0', right: '0', bottom: '0'};

const BusinessCard = ({
    cardId,
    withHeader,
    eventId,
    userLoggedIn,
    loading,
    privateChats,
    createPrivateChat,
    openPrivateChat,
    openWallet,
    getBusinessCard,
    postBusinessCard,
    deleteBusinessCardNote,
}) => {
    const {
        businessCard: {businessCard},
    } = usePlatformTranslation();

    const [card, setCard] = useState(null);
    const [notes, setNotes] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [noteValue, setNoteValue] = useState('');

    const handleOpenPrivateChat = () => {
        const targetUserId = card.cardOwnerId;
        const privateChatId = getPrivateChatId(privateChats, targetUserId);
        if (privateChatId) {
            openPrivateChat(privateChatId, targetUserId);
        } else {
            if (targetUserId !== userLoggedIn._id && !loading) {
                createPrivateChat(targetUserId, eventId);
            }
        }
    };

    const getCard = useCallback(
        () =>
            getBusinessCard({
                cardId,
                callback: ({businessCard, businessCardNotes}) => {
                    setCard({...businessCard, cardOwnerId: cardId});
                    setNotes(businessCardNotes.reverse());
                    setIsLoaded(true);
                },
            }),
        [cardId, getBusinessCard]
    );

    useEffect(() => {
        getCard();
    }, [getCard]);

    const handleSaveNote = useCallback(() => {
        if (!noteValue) return;

        postBusinessCard({
            cardId: card._id,
            payload: {text: noteValue},
            callback: () => {
                setNoteValue('');
                getCard();
            },
        });
    }, [postBusinessCard, card, getCard, noteValue]);

    const handleDeleteNote = useCallback(
        (noteId) => deleteBusinessCardNote({cardId: card._id, noteId, callback: () => getCard()}),
        [deleteBusinessCardNote, card, getCard]
    );

    const handleChangeNote = (value) => {
        setNoteValue(value.substring(0, 200));
    };

    const renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `rgb(137,137,137)`,
            left: 0,
        };
        return <div className="grey-scroll" style={{...style, ...thumbStyle}} {...props} />;
    };

    return (
        <div>
            {isLoaded && (
                <div className="business-wallet-popup">
                    {withHeader && (
                        <div className="wallet-header">
                            <div className="d-flex">
                                <ArrowBackIosIcon
                                    onClick={() => {
                                        openWallet();
                                    }}
                                    style={{fill: '#777081', cursor: 'pointer'}}
                                />
                                {card && (
                                    <div className="wallet-header-titlebox">
                                        <div className="wallet-header-title">
                                            {card.first} {card.last}
                                        </div>
                                        <div className="wallet-header-subtitle">{card.designation}</div>
                                    </div>
                                )}
                            </div>
                            {card && (
                                <div className="d-flex">
                                    <div className={'connection '}>
                                        <ChatButton onClick={() => handleOpenPrivateChat()} />
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <Scrollbars
                        className="scrollbar"
                        renderThumbVertical={renderThumb}
                        style={{width: '100%', height: 500}}
                    >
                        {card ? (
                            <div className="wallet-container">
                                <div className="card" id="business-card-to-download">
                                    <div className="card-container">
                                        <div className="card-section">
                                            {card.first && (
                                                <span className="card-title" style={{color: card.primaryColor}}>
                                                    {card.first}
                                                </span>
                                            )}
                                            {card.last && <span className="card-title">{card.last}</span>}
                                            <div className="card-title-divider"></div>
                                            {card.designation && (
                                                <span className="card-subtitle">{card.designation}</span>
                                            )}
                                            {card.company && <span className="card-subtitle">{card.company}</span>}
                                        </div>
                                        <div
                                            className="card-section-divider"
                                            style={{backgroundColor: card.primaryColor}}
                                        ></div>
                                        <div className="card-section" style={{backgroundColor: card.backgroundColor}}>
                                            {card.logo && (
                                                <div style={logoHolderStyles}>
                                                    <div style={logoStyles}>
                                                        <Media
                                                            media={{
                                                                src: `${process.env.REACT_APP_BUSINESS_CARD_FOLDER}${card.logo}`,
                                                                title: 'card logo',
                                                            }}
                                                            isAdaptable
                                                        />
                                                    </div>
                                                </div>
                                            )}
                                            <div>
                                                {card.mobile && (
                                                    <div className="card_contact" style={{color: card.textIconColor}}>
                                                        <PhoneIphoneIcon className="card_contact-icon" />
                                                        <a href={`tel:${card.mobile}`}>{card.mobile}</a>
                                                    </div>
                                                )}
                                                {card.phone && (
                                                    <div className="card_contact" style={{color: card.textIconColor}}>
                                                        <PhoneIphoneIcon className="card_contact-icon" />
                                                        <a href={`tel:${card.phone}`}>{card.phone}</a>
                                                    </div>
                                                )}
                                                {card.email && (
                                                    <div className="card_contact" style={{color: card.textIconColor}}>
                                                        <MailOutlineIcon className="card_contact-icon" />
                                                        <a href={`mailto:${card.email}`}>
                                                            {card.email.split('@').join('@ ')}
                                                        </a>
                                                    </div>
                                                )}
                                                {card.website && (
                                                    <div className="card_contact" style={{color: card.textIconColor}}>
                                                        <WebIcon className="card_contact-icon" />
                                                        <a href={card.website} target="blank">
                                                            {card.website}
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <a
                                    type="button"
                                    className="wallet-button wallet-inner-padding"
                                    download={true}
                                    target={'_blank'}
                                    href={`${process.env.REACT_APP_REQUESTS_API}/business-cards/${card._id}/download`}
                                    rel="noopener noreferrer"
                                >
                                    <CloudDownloadIcon className="wallet-button-icon" />
                                    <span>{businessCard.download}</span>
                                </a>
                                <BusinessCardNotes
                                    notes={notes}
                                    noteValue={noteValue}
                                    handleSaveNote={handleSaveNote}
                                    handleChangeNote={handleChangeNote}
                                    handleDeleteNote={handleDeleteNote}
                                    businessCard={businessCard}
                                />
                            </div>
                        ) : (
                            <p>User have no business card yet</p>
                        )}
                    </Scrollbars>
                </div>
            )}
        </div>
    );
};

export default connect(
    (state) => ({
        eventId: getEventId(state),
        userLoggedIn: getUserData(state),
        loading: getUserLoading(state),
        privateChats: getUserPrivateChats(state),
    }),
    {
        createPrivateChat,
        openPrivateChat,
        openWallet,
        getBusinessCard,
        postBusinessCard,
        deleteBusinessCardNote,
    }
)(BusinessCard);
