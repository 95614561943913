import {useState} from 'react';

const useFormFields = (initialState) => {
    const [fields, setValues] = useState(initialState);

    return [
        fields,
        function ({id, value}) {
            const fieldIndex = fields.findIndex((field) => field._id === id);
            const updatedField = {...fields[fieldIndex], value};
            let newFields = [...fields];
            newFields[fieldIndex] = updatedField;

            setValues([...newFields]);
        },
    ];
};

export default useFormFields;
