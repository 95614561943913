export const SESSION_LIST_HEAD_CELLS = [
    {
        id: 'session-name',
        numeric: false,
        disablePadding: false,
        label: 'session name',
        align: 'left',
    },
    {
        id: 'session-type',
        numeric: false,
        disablePadding: false,
        label: 'session type',
    },
    {
        id: 'timezone',
        numeric: false,
        disablePadding: false,
        label: 'timezone',
    },
    {
        id: 'date',
        numeric: false,
        disablePadding: false,
        label: 'date',
    },
    {
        id: 'starts',
        numeric: false,
        disablePadding: false,
        label: 'starts',
    },
    {
        id: 'ends',
        numeric: false,
        disablePadding: false,
        label: 'ends',
    },
    {
        id: 'join',
        numeric: false,
        disablePadding: false,
        label: 'join',
        align: 'center',
    },
];
