import React from 'react';
import PropTypes from 'prop-types';
import {useStyles} from './styles';
import * as selectors from 'store/selectors';
import {Tab} from '@material-ui/core';
import {useSelector} from 'react-redux';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export const CustomTab = ({tabIndex, label, ...props}) => {
    const style = useStyles();
    const isLargeScreen = useSelector(selectors.getIsLargeScreen);

    const tabProps = {
        id: `vertical-tab-${tabIndex}`,
        'aria-controls': `vertical-tabpanel-${tabIndex}`,
        classes: {
            wrapper: style.text,
            selected: style.selected,
            root: style.tabRoot,
        },
    };

    return (
        <div className={style.tabWrapper}>
            {!isLargeScreen && <ArrowForwardIosIcon className={style.arrowForward} />}
            <Tab label={label} {...tabProps} {...props} />
        </div>
    );
};

CustomTab.propTypes = {
    tabIndex: PropTypes.number.isRequired,
    label: PropTypes.string.isRequired,
};
