import moment from 'moment';
import timeStrings from 'constants/translations/time';
import {allTranslationList} from 'constants/translations/all-translations';
import {loadStates} from 'constants/data';
import {getUtcTimestamp, convertDateInTimeZone} from './date';

export const checkIfEventHasEnded = (event, utcTimezone) => {
    const now_utc = getUtcTimestamp(new Date());

    if (event !== null && event.auditoriums && event.auditoriums.length) {
        // create an array with all the timeslots end hours, from all auditoriums
        let eventTimeslotsEndHours = [];
        event.auditoriums.forEach((auditorium) => {
            auditorium.dailyProgram.forEach((auditoriumDayProgram) => {
                auditoriumDayProgram.program.forEach((timeslot) => {
                    eventTimeslotsEndHours.push(moment(`${auditoriumDayProgram.date} ${timeslot.end}`).toDate());
                });
            });
        });

        // we sort the array so that the first item in the array is the
        // latest hour from all auditoriums
        if (eventTimeslotsEndHours.length >= 2) {
            eventTimeslotsEndHours.sort((a, b) => moment(b).format('x') - moment(a).format('x'));
        }

        // if the organizer didn't add any timeslots to the program, we consider that the event didn't end
        if (eventTimeslotsEndHours.length === 0) return false;
        return now_utc > convertDateInTimeZone(eventTimeslotsEndHours[0], utcTimezone, 'x');
    }
};

export const checkIfAuditoriumProgramHasEnded = (event, utcTimezone, auditoriumIndex) => {
    const now_utc = getUtcTimestamp(new Date());
    if (event !== null) {
        // create an array with all the timeslots, for all days, of the current auditorium
        let auditoriumTimeslots = [];
        event.auditoriums[auditoriumIndex].dailyProgram.forEach((dayProgram) =>
            auditoriumTimeslots.push(...dayProgram.program)
        );

        // if there are no timslots, we always send the "auditoriumHasEnded" = true
        const auditoriumProgramLength = auditoriumTimeslots.length;
        if (auditoriumProgramLength === 0) return true;

        // if there are timeslots added
        const auditoriumProgramLastVideoIndex = auditoriumProgramLength - 1;
        const auditoriumProgramLastVideo = auditoriumTimeslots[auditoriumProgramLastVideoIndex];

        const auditoriumProgramEnd = `${auditoriumProgramLastVideo.date} ${auditoriumProgramLastVideo.end}`;
        return now_utc > convertDateInTimeZone(auditoriumProgramEnd, utcTimezone, 'x');
    }
};

export const getTimeslotStartTimestamp = (date, timeslot, utcTimezone) => {
    return convertDateInTimeZone(`${date} ${timeslot.start}`, utcTimezone, 'x');
};

export const getTimeslotEndTimestamp = (date, timeslot, utcTimezone) => {
    return convertDateInTimeZone(`${date} ${timeslot.end}`, utcTimezone, 'x');
};

// we expect to have as parameters the logged in user privateChats and the targetUserId with which we want to open a private chat
export const getPrivateChatId = (privateChats, targetUserId) => {
    let privateChat = privateChats.find(
        (privateChat) => privateChat.chat.user_1?._id === targetUserId || privateChat.chat.user_2?._id === targetUserId
    );

    // if we don't find a private chat we return false;
    // else we return the private chat ID
    return privateChat === undefined ? false : privateChat.chat._id;
};

export const getUserIdFromParticipantId = (eventParticipantsArray, participantId) => {
    const participant = eventParticipantsArray.find((participant) => participant._id === participantId);
    return participant?._id || null;
};

export const checkUserIsParticipant = (eventParticipantsArray, userId) => {
    const participantIndex = eventParticipantsArray.findIndex((participant) => {
        return participant.user._id === userId;
    });
    return participantIndex !== -1;
};

export const getParticipantId = (eventRoles, eventId) => {
    const currentEventRoleInfo = eventRoles.find(({event}) => event._id === eventId);

    return currentEventRoleInfo?.participant || null;
};

export const checkUserIsExhibitor = (eventExhibitorsArray, userId) => {
    const exhibitorIndex = eventExhibitorsArray.findIndex((exhibitor) => {
        return exhibitor.user._id === userId;
    });
    return exhibitorIndex !== -1;
};

export const checkUserIsOrganizer = (eventOwnerId, userId) => {
    return eventOwnerId === userId;
};

export const checkUserIsExhibitorRepresentative = (eventExhibitorsArray, userId) => {
    const exhibitorIndex = eventExhibitorsArray.findIndex((exhibitor) => {
        const booth = exhibitor.booth;
        const isMedalionRepresentative = booth.medalionRepresentatives.find(
            (representative) => representative.user === userId
        );
        const isLeftDeskRepresentative = booth.deskRepresentativeLeft.user === userId;
        const isRightDeskRepresentative = booth.deskRepresentativeRight.user === userId;

        return isMedalionRepresentative || isLeftDeskRepresentative || isRightDeskRepresentative;
    });
    return exhibitorIndex !== -1;
};

export const getTimeAgoString = (fromTime, language = 'en') => {
    let timeAgoString = '';

    let messageTime = new Date(fromTime);
    messageTime = messageTime.getTime();

    let currentTime = new Date();
    currentTime = currentTime.getTime();

    let timeAgoSeconds = Math.abs((currentTime - messageTime) / 1000);
    timeAgoSeconds = Math.floor(timeAgoSeconds);

    if (timeAgoSeconds < 60) {
        timeAgoString = timeStrings[`${language}`].justNow;
    } else if (timeAgoSeconds < 60 * 60) {
        let minutes = Math.floor(timeAgoSeconds / 60);
        timeAgoString =
            minutes + ' ' + (minutes > 1 ? timeStrings[`${language}`].minutes : timeStrings[`${language}`].minute);
    } else if (timeAgoSeconds < 60 * 60 * 24) {
        let hours = Math.floor(timeAgoSeconds / (60 * 60));
        timeAgoString = hours + ' ' + (hours > 1 ? timeStrings[`${language}`].hours : timeStrings[`${language}`].hour);
    } else if (timeAgoSeconds < 60 * 60 * 24 * 30) {
        let days = Math.floor(timeAgoSeconds / (60 * 60 * 24));
        timeAgoString = days + ' ' + (days > 1 ? timeStrings[`${language}`].days : timeStrings[`${language}`].day);
    } else if (timeAgoSeconds < 60 * 60 * 24 * 30 * 12) {
        let months = Math.floor(timeAgoSeconds / (60 * 60 * 24 * 30));
        timeAgoString =
            months + ' ' + (months > 1 ? timeStrings[`${language}`].months : timeStrings[`${language}`].month);
    } else {
        let years = Math.floor(timeAgoSeconds / (60 * 60 * 24 * 30 * 12));
        timeAgoString = years + ' ' + (years > 1 ? timeStrings[`${language}`].years : timeStrings[`${language}`].year);
    }
    return timeAgoString;
};

export const getNotReadChatMessages = (privateChats, groupChats) => {
    // {unread} is destructuring, we have the property unread on each chat

    let unreadPrivateChatMessages = privateChats.reduce((sum, {unread}) => sum + unread, 0);

    let unreadGroupChatMessages = groupChats.reduce((sum, {unread}) => sum + unread, 0);

    let totalMessagesUnread = unreadPrivateChatMessages + unreadGroupChatMessages;
    return totalMessagesUnread;
};

export const getSortedNotifications = (notifications, userId) => {
    let orderedNotifications = notifications.slice(0).reverse();
    let filteredNotifications = orderedNotifications.filter(
        (notification) =>
            notification.type !== 'newPrivateChatMessage' &&
            notification.type !== 'newGroupChatMessage' &&
            !(notification.type === 'newBoothPost' && userId === notification.user._id)
    );
    return filteredNotifications;
};

export const getVideoIdFromYoutubeUrl = (url = '') => {
    let ID = '';
    url = url.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] !== undefined) {
        ID = url[2].split(/[^0-9a-z_-]/i);
        ID = ID[0];
    } else {
        ID = url;
    }
    return ID;
};

export const urlifyString = (text) => {
    text = text.replace(/\//g, '/');
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const mailRegex = /\S+@\S+\.\S+/g;

    return text
        .replace(mailRegex, function (url) {
            return '<a href="mailto:' + url + '"  target="_blank">' + url + '</a>';
        })
        .replace(urlRegex, function (url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        });
};

export const sortExhibitors = (exhibitors) => {
    exhibitors.sort(function (x, y) {
        return x.company < y.company ? -1 : 1;
    });

    return exhibitors;
};

export const sortExhibitorsByOrder = (exhibitors) => {
    return [...exhibitors].sort((a, b) => {
        function value(el) {
            return Number(el) === 0 ? Infinity : Number(el);
        }

        return value(a.booth.orderNumber) - value(b.booth.orderNumber);
    });
};

export const getEventDaysArray = (eventStartDate, eventEndDate, reverse = false) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const startDate = moment(eventStartDate).toDate();
    const endDate = moment(eventEndDate).toDate();
    const numberOfEventDays = Math.round(Math.abs((endDate - startDate) / oneDay)) + 1;

    let eventDays = [];
    for (let i = 0; i < numberOfEventDays; i++) {
        let newDate = moment(eventStartDate).toDate();
        newDate.setDate(startDate.getDate() + i);
        const timezoneOffset = newDate.getTimezoneOffset() * 60000;
        const formatedDate = new Date(newDate - timezoneOffset).toISOString().slice(0, 10);
        eventDays.push(formatedDate);
    }
    return reverse ? eventDays.reverse() : eventDays;
};

export const getFormattedDate = (dateToFormat, fullMonth) => {
    let date = moment(dateToFormat).startOf('day');
    let monthsArray = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (fullMonth) {
        monthsArray = [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ];
    }
    const d = date.date();
    const m = monthsArray[date.month()];
    const y = date.year();

    return `${d < 9 ? `0${d}` : d} ${m} ${y}`;
};

export const getDayWithSuffix = (i) => {
    let j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + 'st';
    }
    if (j === 2 && k !== 12) {
        return i + 'nd';
    }
    if (j === 3 && k !== 13) {
        return i + 'rd';
    }
    return i + 'th';
};

export const getCurrencySymbol = (currencyCode) => {
    let eventCurrency = '';
    switch (currencyCode) {
        case 'eur':
            eventCurrency = '€';
            break;
        case 'usd':
            eventCurrency = '$';
            break;
        default:
            eventCurrency = currencyCode;
    }
    return eventCurrency;
};

export const getLanguageName = (languageCode) => {
    const currentLanguage = allTranslationList.find(({slug}) => slug === languageCode);
    return currentLanguage.title;
};

export const checkIsFunction = (func) => typeof func === 'function';

export const checkIsInteger = (num) => (num ^ 0) === num;

export const checkIsM3U8Format = (value = '') => new RegExp('.m3u8$', 'i').test(value);

export const checkIsYoutubeLink = (value = '') =>
    new RegExp('^(http(s)?://)?((w){3}.)?youtu(be|.be)?(.com)?/.+', 'i').test(value);

export const parseAspectRatio = (aspectRatio = '', separator = ':') => {
    const [widthRatio, heightRatio] = aspectRatio.split(separator);
    return [parseInt(widthRatio, 10), parseInt(heightRatio, 10)];
};

export const generateRandomHexColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

export const bindActionsToDispatch = (actions, dispatch) => {
    const bindedActions = {};

    for (let key in actions) {
        const _action = actions[key];
        bindedActions[key] = (...args) => dispatch(_action(...args));
    }

    return bindedActions;
};
export const transformLink = (link) => {
    let newLink = link;

    if (!newLink) return '';

    // remove '/' in the end for social media frame
    while (newLink.endsWith('/')) {
        newLink = newLink.slice(0, -1);
    }

    return newLink;
};

export const getTwitterScreenName = (link) => {
    const result = transformLink(link).split('/').pop();

    if (!result) return 'user-name'; //to prevent uncaught error in twitter widget need to return not empty string

    return result;
};

export const sortByOrderNumber = (array) => {
    array.sort((a, b) => {
        if (!a?.booth?.orderNumber && !b?.booth?.orderNumber) {
            return -1;
        }
        if (!a?.booth?.orderNumber) {
            return 1;
        }
        if (!b?.booth?.orderNumber) {
            return 0;
        }
        return a?.booth?.orderNumber - b?.booth?.orderNumber;
    });

    return array;
};

export const checkIsLoading = (stateString) => stateString === loadStates.loading;
export const checkIsReady = (stateString) => stateString === loadStates.ready;
export const checkIsError = (stateString) => stateString === loadStates.error;
