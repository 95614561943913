import {WelcomeVideo} from './WelcomeVideo';
import {connect} from 'react-redux';
import * as actions from '../../../store/actions';

export default connect(
    (state) => ({
        user: state.user.data,
        loggedIn: state.user.loggedIn,
        event: state.event.data,
        isMobile: state.layout.isMobile,
    }),
    {
        topNavClose: actions.topNavClose,
        getEvent: actions.getEvent,
        setEventSlug: actions.setEventSlug,
    }
)(WelcomeVideo);
