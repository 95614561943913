import React from 'react';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import {Lock as LockIcon} from '@material-ui/icons';

import Wall from '../../../Wall';
import Networking from '../Networking';
import Info from './components/Info';

import {AbilityContext} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

import './SideMenuMobile.scss';

class SideMenuMobile extends React.Component {
    state = {
        translation: null,
    };

    componentDidMount() {
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    render() {
        const {event, resourcesAccess, handleSeeNetworking, handleSeeLiveWall, handleSeeVideoWall, handleSeeInfoTab} =
            this.props;
        const ability = this.context;

        const accessToNetwork = event.hasAccessManagement && resourcesAccess !== null && resourcesAccess.networking;
        const isModerator = ability.can(
            ROLE_ABILITIES_TYPES.COMMON.APPROVE_WALL_ABILITY,
            ROLE_FEATURES_TYPES.WALL_APPROVE
        );

        const {translation} = this.state;

        return (
            <div id="wall-and-network-mobile" className="wall-and-networking-container open">
                <div>
                    <div className="tabs-container">
                        {this.props.sideNavigationDetails.hasVideoWall ? (
                            <>
                                {!this.props.sideNavigation.seeLiveWall && (
                                    <div className="live-wall-tab button-tab tab" onClick={handleSeeLiveWall}>
                                        {translation?.sideMenu.liveWall}
                                    </div>
                                )}
                                {!this.props.sideNavigation.seeVideoWall && (
                                    <div className="video-wall-tab button-tab tab" onClick={handleSeeVideoWall}>
                                        {translation?.sideMenu.videoWall}
                                    </div>
                                )}

                                {!this.props.sideNavigation.seeNetworking && (
                                    <div className="networking-tab button-tab tab " onClick={handleSeeNetworking}>
                                        {translation?.sideMenu.networking}
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                <div
                                    className={classNames('live-wall-tab', 'button-tab', 'tab ', {
                                        active: this.props.sideNavigation.seeLiveWall,
                                    })}
                                    onClick={handleSeeLiveWall}
                                >
                                    {translation?.sideMenu.liveWall}
                                </div>
                                <div
                                    className={classNames('networking-tab', 'button-tab', 'tab ', {
                                        active: this.props.sideNavigation.seeNetworking,
                                    })}
                                    onClick={
                                        resourcesAccess !== null && !accessToNetwork ? () => {} : handleSeeNetworking
                                    }
                                >
                                    {resourcesAccess !== null && !accessToNetwork ? (
                                        <Tooltip title="You don't have access to all the networking functionalities.">
                                            <div className="restricted-side-tab">
                                                <LockIcon fontSize="small" />
                                                {translation?.sideMenu.networking}
                                            </div>
                                        </Tooltip>
                                    ) : (
                                        <>{translation?.sideMenu.networking}</>
                                    )}
                                </div>
                                {event.representatives.length > 0 && (
                                    <div
                                        className={classNames('info-tab', 'button-tab', 'tab', {
                                            active: this.props.sideNavigation.seeInfoTab,
                                        })}
                                        onClick={handleSeeInfoTab}
                                    >
                                        {translation?.sideMenu.info}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div>
                        {this.props.sideNavigation.seeLiveWall && (
                            <Wall withModeration={event.liveWallModeration} isModerator={isModerator} />
                        )}
                        {this.props.sideNavigation.seeNetworking && <Networking />}
                        {this.props.sideNavigation.seeInfoTab && <Info />}
                    </div>
                </div>
            </div>
        );
    }
}

SideMenuMobile.contextType = AbilityContext;

export default SideMenuMobile;
