import React, {useRef} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import {FormControlLabel, Radio, RadioGroup, MenuItem} from '@material-ui/core';
import {ValidatorForm, SelectValidator} from 'react-material-ui-form-validator';

import {limitedInterpretationTargetLanguages, interpretationSourceLanguages} from '../../../constants/data';
import {useInterpretation} from '../../../store/reducers/interpretation';
import './AuditorumTranslationsOutput.scss';

const Thumb = ({style, ...props}) => (
    <div className="grey-scroll" style={{...style, backgroundColor: `rgb(137,137,137)`, left: 0}} {...props} />
);

const AuditorumTranslationsOutput = () => {
    const formRef = useRef();
    const [
        {interpretationTargetLocale, interpretationSourceLocale},
        {setTargetInterpretationLocale, setSourceInterpretationLocale},
    ] = useInterpretation();

    return (
        <div className="language-container">
            <Scrollbars className="scrollbar" renderThumbVertical={Thumb} style={{width: '100%', height: '100%'}}>
                <div className="options-container">
                    <div className="single-option-container">
                        <ValidatorForm ref={formRef} onSubmit={() => {}}>
                            <SelectValidator
                                onChange={(e) => setSourceInterpretationLocale(e.target.value)}
                                variant="outlined"
                                value={interpretationSourceLocale}
                                label="Language audio input"
                                fullWidth
                            >
                                {interpretationSourceLanguages.map(({title, slug}) => {
                                    return (
                                        <MenuItem key={slug} value={slug}>
                                            {title}
                                        </MenuItem>
                                    );
                                })}
                            </SelectValidator>
                        </ValidatorForm>
                    </div>
                </div>
                {interpretationSourceLocale ? (
                    <>
                        <p className="title">Subtitles</p>
                        <ul className="language-list ">
                            <RadioGroup
                                name="interpretation"
                                value={interpretationTargetLocale}
                                onChange={(e) => setTargetInterpretationLocale(e.target.value)}
                            >
                                {limitedInterpretationTargetLanguages.map(({title, locale}) => (
                                    <li className="language-list-item" key={locale}>
                                        <FormControlLabel label={title} value={locale} control={<Radio />} />
                                    </li>
                                ))}
                            </RadioGroup>
                        </ul>
                    </>
                ) : null}
            </Scrollbars>
        </div>
    );
};

export default AuditorumTranslationsOutput;
