export const GET_EXHIBITORS_START = 'ORGANIZER_EVENT_BOOTH/GET_EXHIBITORS_START';
export const GET_EXHIBITORS_SUCCESS = 'ORGANIZER_EVENT_BOOTH/GET_EXHIBITORS_SUCCESS';
export const GET_EXHIBITORS_FAIL = 'ORGANIZER_EVENT_BOOTH/GET_EXHIBITORS_FAIL';
export const SET_ACTIVE_EXHIBITOR_ID = 'ORGANIZER_EVENT_BOOTH/SET_ACTIVE_EXHIBITOR_ID';

export const CHANGE_SPONSOR_TITLE_REQUEST = 'ORGANIZER_EVENT_BOOTH/CHANGE_SPONSOR_TITLE_REQUEST';
export const CHANGE_SPONSOR_TITLE_SUCCESS = 'ORGANIZER_EVENT_BOOTH/CHANGE_SPONSOR_TITLE_SUCCESS';
export const CHANGE_SPONSOR_TITLE_FAIL = 'ORGANIZER_EVENT_BOOTH/CHANGE_SPONSOR_TITLE_FAIL';

export const SET_EXHIBITORS_FILTERS = 'SET_EXHIBITORS_FILTERS';
export const SET_FILTERED_EXHIBITORS = 'SET_FILTERED_EXHIBITORS';
export const SET_FILTERED_EXHIBITORS_FAIL = 'SET_FILTERED_EXHIBITORS_FAIL';

export const RESET_ORGANIZER_BOOTH_EXHIBITORS = 'ORGANIZER_EVENT_BOOTH/RESET_ORGANIZER_BOOTH_EXHIBITORS';
