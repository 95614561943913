export const PRIVATE_CHAT_TYPE = 'private-chat';
export const GROUP_CHAT_TYPE = 'group-chat';

export const GET_USER_MATCHING_ANSWERS_START = 'GET_USER_MATCHING_ANSWERS_START';
export const GET_USER_MATCHING_ANSWERS_SUCCESS = 'GET_USER_MATCHING_ANSWERS_SUCCESS';
export const GET_USER_MATCHING_ANSWERS_FAIL = 'GET_USER_MATCHING_ANSWERS_FAIL';

export const POST_MATCHING_ANSWERS_START = 'POST_MATCHING_ANSWERS_START';
export const POST_MATCHING_ANSWERS_SUCCESS = 'POST_MATCHING_ANSWERS_SUCCESS';
export const POST_MATCHING_ANSWERS_FAIL = 'POST_MATCHING_ANSWERS_FAIL';

export const GET_MATCHING_ANSWERS_START = 'GET_MATCHING_ANSWERS_START';
export const GET_MATCHING_ANSWERS_SUCCESS = 'GET_MATCHING_ANSWERS_SUCCESS';
export const GET_MATCHING_ANSWERS_FAIL = 'GET_MATCHING_ANSWERS_FAIL';

export const DELETE_MATCHING_ANSWERS_START = 'DELETE_MATCHING_ANSWERS_START';
export const DELETE_MATCHING_ANSWERS_SUCCESS = 'DELETE_MATCHING_ANSWERS_SUCCESS';
export const DELETE_MATCHING_ANSWERS_FAIL = 'DELETE_MATCHING_ANSWERS_FAIL';

export const GET_USER_MATCHES_START = 'GET_USER_MATCHES_START';
export const GET_USER_MATCHES_SUCCESS = 'GET_USER_MATCHES_SUCCESS';
export const GET_USER_MATCHES_FAIL = 'GET_USER_MATCHES_FAIL';

export const RECALCULATE_USER_MATCHES_START = 'RECALCULATE_USER_MATCHES_START';
export const RECALCULATE_USER_MATCHES_SUCCESS = 'RECALCULATE_USER_MATCHES_SUCCESS';
export const RECALCULATE_USER_MATCHES_FAIL = 'RECALCULATE_USER_MATCHES_FAIL';

export const SET_COMPLETE_MATCHING = 'SET_COMPLETE_MATCHING';

export const UPDATE_PASSWORD_START = 'UPDATE_PASSWORD_START';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = 'UPDATE_PASSWORD_FAIL';
