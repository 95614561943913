import React from 'react';

import Svg from '../Svg/Svg';

import './FilledLinkedin.scss';

const IconFilledLinkedin = () => (
    <Svg className="IconFilledLinkedin">
        <path d="M22.68 1.32C21.8.44 20.74 0 19.5 0h-15C3.26 0 2.2.44 1.32 1.32.44 2.2 0 3.26 0 4.5v15c0 1.24.44 2.3 1.32 3.18C2.2 23.56 3.26 24 4.5 24h15c1.24 0 2.3-.44 3.18-1.32.88-.88 1.32-1.94 1.32-3.18v-15c0-1.24-.44-2.3-1.32-3.18zM7.313 20.094h-3.61V9.25h3.61v10.844zM6.985 7.242c-.375.36-.87.54-1.485.54h-.016c-.593 0-1.075-.18-1.445-.54a1.788 1.788 0 01-.555-1.336c0-.541.19-.99.57-1.344.381-.354.873-.53 1.477-.53.604 0 1.089.176 1.453.53.365.355.553.803.563 1.344 0 .531-.187.977-.562 1.336zm13.312 12.852h-3.61v-5.797c0-1.635-.603-2.453-1.812-2.453-.458 0-.844.127-1.156.383a2.404 2.404 0 00-.703.93c-.073.187-.11.478-.11.874v6.062H9.297c.031-6.541.031-10.156 0-10.843h3.61v1.53C13.664 9.595 14.742 9 16.14 9c1.25 0 2.255.411 3.016 1.234.76.823 1.14 2.037 1.14 3.64v6.22z" />
    </Svg>
);

export default IconFilledLinkedin;
