import {connect} from 'react-redux';
import * as actions from '../../../../../../../../../../../store/actions';
import * as selectors from '../../../../../../../../../../../store/selectors';
import {VideoChatPlayer} from './VideoChatPlayer';

export default connect(
    (state) => ({
        user: selectors.getUserData(state),
        activeChatRoomId: selectors.getUserActiveChatRoomId(state),
        videoConference: selectors.getActiveCallTypeData(state),
    }),
    {
        closeVideoConference: actions.closeVideoConference,
        toggleScreen: actions.toggleScreen,
        toggleShareScreen: actions.toggleShareScreen,
    }
)(VideoChatPlayer);
