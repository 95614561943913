import {IconButton, Menu, MenuItem} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';

import {usePlatformTranslation} from '../../../../../../../../../../../../../services/hooks';
import {useStyles} from '../MoreMenu.styles';
import IconExport from '../../Icons/IconExport';

const ArchivedMoreMenu = () => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const user = useSelector((state) => state.user);
    const translation = usePlatformTranslation();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>

            <Menu
                id="chat-more-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.root}
            >
                <MenuItem className={classes.item} onClick={handleClose}>
                    <a
                        type="button"
                        download={true}
                        target={'_blank'}
                        href={`${process.env.REACT_APP_REQUESTS_API}/users/${user.data._id}/chats/archived/download`}
                        rel="noopener noreferrer"
                    >
                        <IconExport />
                        <span className={classes.itemText}>{translation.chatsDropdown.dowloadUsersList}</span>
                    </a>
                </MenuItem>
            </Menu>
        </>
    );
};

export default ArchivedMoreMenu;
