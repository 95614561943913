import * as actionTypes from '../actionTypes/organizerActionTypes/eventBoothOrder';
import {Api} from '../../Api';
import {sortExhibitors, sortExhibitorsByOrder} from '../../services/utils';

export const getExhibitors =
    ({eventId, privateBoothPreview, isFiltering, isSorting, isSortingByOrder, filterValues, sorting, pagination}) =>
    async (dispatch, getState) => {
        const {
            exhibitors: {loading},
        } = getState();

        dispatch(getExhibitorsStart());
        const paginationParams = pagination ? `?page=${pagination?.page}&perPage=${pagination?.perPage}&` : '';
        let apiUrl = `/event/${eventId}/exhibitors${paginationParams}`;

        if (isFiltering) {
            apiUrl = `event/${eventId}/exhibitors${paginationParams || '?'}email=${filterValues.email}&company=${
                filterValues.company
            }`;
        }

        if (isSorting) {
            apiUrl = `event/${eventId}/exhibitors${paginationParams || '?'}orderBy=${sorting.key}&orderDirection=${
                sorting.value
            }`;
        }

        if (isFiltering && isSorting) {
            apiUrl = `event/${eventId}/exhibitors${paginationParams || '?'}email=${filterValues.email}&company=${
                filterValues.company
            }&orderBy=${sorting.key}&orderDirection=${sorting.value}`;
        }

        try {
            const {exhibitors, page, perPage, total} = await Api.request({
                url: apiUrl,
                method: 'get',
                loading,
            });
            const sortedExhibitors = isSortingByOrder ? sortExhibitorsByOrder(exhibitors) : exhibitors;

            dispatch(
                getExhibitorsSuccess({
                    exhibitors: sortedExhibitors,
                    privateBoothPreview,
                    pagination: {page, perPage, total},
                })
            );
        } catch (error) {
            dispatch(getExhibitorsFail());
        }
    };

export const getBuildingExhibitors = (eventId, buildingId, privateBoothPreview) => async (dispatch, getState) => {
    const {
        exhibitors: {loading},
    } = getState();

    dispatch(getExhibitorsStart());

    try {
        const {building} = await Api.request({
            url: `/event/${eventId}/building/${buildingId}`,
            method: 'get',
            loading,
        });
        let exhibitors = building.exhibitors;
        // sort the exhibitors alphabetically before sending them to the reducer;
        let sortedExhibitors = sortExhibitors(exhibitors);
        dispatch(getExhibitorsSuccess({exhibitors: sortedExhibitors, privateBoothPreview}));
    } catch (error) {
        dispatch(getExhibitorsFail());
    }
};

export const getExhibitorsStart = () => {
    return {
        type: actionTypes.GET_EXHIBITORS_START,
    };
};
export const getExhibitorsSuccess = ({exhibitors, privateBoothPreview, pagination}) => ({
    type: actionTypes.GET_EXHIBITORS_SUCCESS,
    payload: {
        exhibitors,
        privateBoothPreview,
        pagination,
    },
});
export const getExhibitorsFail = () => {
    return {
        type: actionTypes.GET_EXHIBITORS_FAIL,
    };
};

export const setActiveExhibitorId = (exhibitorId) => {
    return {
        type: actionTypes.SET_ACTIVE_EXHIBITOR_ID,
        payload: exhibitorId,
    };
};

export const changeSponsorTitle =
    ({exhibitorId, data, callback}) =>
    async (dispatch, getState) => {
        const {
            exhibitors: {loading},
            event: {eventId},
        } = getState();

        dispatch(changeSponsorTitleRequest());

        try {
            const {exhibitor} = await Api.request({
                url: `/event/${eventId}/exhibitor/${exhibitorId}/set-booth-title`,
                method: 'put',
                payload: data,
                loading,
            });

            dispatch(changeSponsorTitleSuccess(exhibitor));
            if (callback) callback();
        } catch (error) {
            dispatch(changeSponsorTitleFail());
        }
    };

const changeSponsorTitleRequest = () => ({
    type: actionTypes.CHANGE_SPONSOR_TITLE_REQUEST,
});

const changeSponsorTitleSuccess = (payload) => ({
    type: actionTypes.CHANGE_SPONSOR_TITLE_SUCCESS,
    payload,
});

const changeSponsorTitleFail = () => ({
    type: actionTypes.CHANGE_SPONSOR_TITLE_FAIL,
});

export const setExhibitorsFilters = (filters = []) => {
    return {
        type: actionTypes.SET_EXHIBITORS_FILTERS,
        payload: filters,
    };
};

const setFilteredExhibitors = (exhibitors = []) => {
    return {
        type: actionTypes.SET_FILTERED_EXHIBITORS,
        payload: exhibitors,
    };
};

const setFilteredExhibitorsFail = () => {
    return {
        type: actionTypes.SET_FILTERED_EXHIBITORS_FAIL,
    };
};

export const applyExhibitorsFilters =
    ({eventId, filters, buildingId}) =>
    async (dispatch, getState) => {
        const {
            exhibitors: {loading},
        } = getState();

        try {
            const {exhibitors} = await Api.request({
                url: `/event/${eventId}/filter-exhibitors`,
                method: 'post',
                payload: {filters},
                loading,
                params: {buildingId},
            });

            dispatch(setFilteredExhibitors(exhibitors));
        } catch (error) {
            dispatch(setFilteredExhibitorsFail(error));
        }
    };

export const resetOrgBoothExhibitors = () => ({type: actionTypes.RESET_ORGANIZER_BOOTH_EXHIBITORS});
