import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classNames from 'classnames';
import {useParams} from 'react-router-dom';
import {Snackbar} from '@material-ui/core';

import Wall from '../Wall';
import VideoModule from '../SessionVideoRoomModules/VideoModule';
import ChatModule from '../SessionVideoRoomModules/ChatModule';
import PermissionsModule from '../SessionVideoRoomModules/PermissionsModule';
import KickPopup from './components/KickPopup';
import CheckUserMediaPopup from './components/CheckUserMediaPopup';

import {useSessionDashboard} from 'services/hooks/organizer/sessions/useSessionDashboard';

import './SessionDashboard.scss';

const TIME_WARNING = 10; // minutes

export function SessionDashboard({
    sessionData,
    capture,
    isFullScreen,
    isReady,
    isKicked,
    translation,
    sendChatMessage,
    setEventProtectedMenu,
    getLocalStream,
    clearSessionState,
    isClosingMode,
}) {
    const {auditoriumId, sessionId} = useParams();

    const [timeToEndMessage, setTimeToEndMessage] = useState('');

    useSessionDashboard();

    const checkTimeToEnd = useCallback(() => {
        if (!sessionData || !sessionData?.endTime) return;

        const curTime = moment().format('HH:mm');
        const endTime = moment.utc(sessionData.endTime, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
        const timeLeft = moment.duration(moment(endTime, 'HH:mm').diff(moment(curTime, 'HH:mm'))).asMinutes();

        if (timeLeft <= 0 && timeToEndMessage) {
            setTimeToEndMessage('');
        }
        if (timeLeft <= 0 && !timeToEndMessage) return;

        if (timeLeft <= TIME_WARNING) {
            setTimeToEndMessage(
                `${translation?.auditorium.slotEndsTextFirst} ${timeLeft} ${translation?.time.minutes}.`
            );
        }
    }, [sessionData, timeToEndMessage, translation]);

    useEffect(() => {
        clearSessionState();
        setEventProtectedMenu();

        if (!capture) getLocalStream();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getLocalStream, setEventProtectedMenu]);

    useEffect(() => {
        const interval = setInterval(() => {
            checkTimeToEnd();
        }, 5000);
        return () => {
            clearInterval(interval);
        };
    }, [checkTimeToEnd]);

    const sendMessage = useCallback(
        (text) => sendChatMessage({auditoriumId, timeslotId: sessionId, text}),
        [auditoriumId, sendChatMessage, sessionId]
    );

    return (
        <div className={classNames('session_dashboard_page', {isFullScreen})}>
            <div className="session_dashboard_page-container">
                <div className="session_dashboard_page-column">
                    {isReady && (
                        <>
                            <VideoModule videoCapture={capture} />
                            <PermissionsModule />
                        </>
                    )}
                </div>
                <div className="session_dashboard_page-column">
                    {isReady && !isKicked && (
                        <ChatModule messages={sessionData?.chat} users={sessionData?.users} sendMessage={sendMessage} />
                    )}
                </div>
            </div>

            {!isKicked && (
                <div className="booth-wall-container">
                    <div className="booth-wall-header withPadding">
                        <span>{translation?.sideMenu.videoWall}</span>
                    </div>
                    <Wall />
                </div>
            )}

            <KickPopup open={isKicked} />

            <CheckUserMediaPopup open={!isReady && !isClosingMode} />

            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={!!timeToEndMessage}
                classes={{
                    root: 'session_dashboard_page__snackbar',
                }}
                message={timeToEndMessage}
            />
        </div>
    );
}

SessionDashboard.propTypes = {
    sessionData: PropTypes.object,
    capture: PropTypes.object,
    isFullScreen: PropTypes.bool,
    isReady: PropTypes.bool,
    isKicked: PropTypes.bool,
    translation: PropTypes.object,
    sendChatMessage: PropTypes.func,
    setEventProtectedMenu: PropTypes.func,
    getLocalStream: PropTypes.func,
    clearSessionState: PropTypes.func,
    isClosingMode: PropTypes.bool,
};
