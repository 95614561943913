import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './EditUserInfoWrapper.scss';

export function EditUserInfoWrapper({children, className}) {
    return <div className={classNames('edit-user-information', {[className]: className})}>{children}</div>;
}

EditUserInfoWrapper.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string
};
