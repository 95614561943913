import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import Auditorium from './Auditorium';

export default connect(
    (state) => ({
        eventId: selectors.getEventId(state),
        eventSlug: selectors.getEventSlug(state),
        event: selectors.getEventData(state),
        user: selectors.getUserData(state),
        resourcesAccess: selectors.getResourcesAccess(state),
        isMobile: selectors.getIsMobile(state),
        isLargeScreen: selectors.getIsLargeScreen(state),
        languages: selectors.getLanguages(state),
        buildingId: selectors.getBuildingId(state),
        building: selectors.getBuildingData(state),
    }),
    {
        closeSidebar: actions.sideNavClose,
        closeTopNavigation: actions.topNavClose,
        setHasVideoWall: actions.sideNavHasVideoWall,
        setTimeSlotVideoWall: actions.setTimeSlotVideoWall,
        setActiveWall: actions.setActiveWall,
        seeVideoWall: actions.sideNavSeeVideoWall,
        seeLiveWall: actions.sideNavSeeLiveWall,
        onSetEventProtectedMenu: actions.setEventProtectedMenu,
        onGetTimeslotData: actions.getTimeslotData,
        onGetOnDemantVideos: actions.getOnDemantVideos,
        onResetTimeslotData: actions.resetTimeslotData,
    }
)(Auditorium);
