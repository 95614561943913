import React from 'react';
import PropTypes from 'prop-types';
import {useStyles} from './styles';
import {Tabs} from '@material-ui/core';
import * as selectors from 'store/selectors';
import * as actions from 'store/actions';
import {useDispatch, useSelector} from 'react-redux';

export const CustomTabs = ({tabIndex, setTabIndex, children}) => {
    const style = useStyles();
    const dispatch = useDispatch();
    const isLargeScreen = useSelector(selectors.getIsLargeScreen);

    const opentTab = (e, index) => {
        setTabIndex(index);
        if (!isLargeScreen) dispatch(actions.hideAccountNavigation(true));
    };
    return (
        <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabIndex}
            onChange={opentTab}
            className={style.tabsContainer}
            classes={{
                indicator: style.indicator,
            }}
        >
            {children}
        </Tabs>
    );
};

CustomTabs.propTypes = {
    tabIndex: PropTypes.number.isRequired,
    setTabIndex: PropTypes.func.isRequired,
    children: PropTypes.node,
};
