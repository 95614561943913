import React from 'react';
import {useDispatch} from 'react-redux';

import MobileBusinessCardSetupPanel from '../MobileBusinessCardSetupPanel/MobileBusinessCardSetupPanel';
import BusinessCardEditingForm from '../../../../../BusinessCardEditingForm/BusinessCardEditingForm';

import {changeBusinessCardField, setBusinessCardIsFormValid} from 'store/actions';
import {useBusinessCardData, usePlatformTranslation} from 'services/hooks';

const MobileBusinessCardEditing = () => {
    const dispatch = useDispatch();
    const {businessCard} = useBusinessCardData();
    const {businessCard: businessCardTranslation} = usePlatformTranslation();

    return (
        <div className="MobileBusinessCardEditing">
            <MobileBusinessCardSetupPanel title={businessCardTranslation.editText}>
                <BusinessCardEditingForm
                    businessCard={businessCard}
                    onChange={(...args) => dispatch(changeBusinessCardField(...args))}
                    setIsFormValid={(...args) => dispatch(setBusinessCardIsFormValid(...args))}
                />
            </MobileBusinessCardSetupPanel>
        </div>
    );
};

export default MobileBusinessCardEditing;
