import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogTitle, IconButton} from '@material-ui/core';
import MatchingDialogInitialPage from './components/MatchingDialogInitialPage';
import MatchingDialogQuestionsPage from './components/MatchingDialogQuestionsPage';
import MatchingDialogRejectPage from './components/MatchingDialogRejectPage';
import MatchingDialogMatchedPage from './components/MatchingDialogMatchedPage';
import {Pagination} from '@material-ui/lab';
import {TABS_INDEXS} from 'constants/matchMaking';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import Spinner from 'Routes/components/Spinner';

import './MatchmakingDialog.scss';

const pageStyleProps = {
    classes: {
        conntent: 'matchmaking-dialog__content-wrapper',
        actions: 'matchmaking-dialog__actions-wrapper',
    },
};

const MatchmakingDialog = ({
    onClose,
    loading,
    onGetMatchmakingForm,
    startPage,
    isLargeScreen,
    matchingQuestions,
    open,
}) => {
    const [dialogTitle, setDialogTitle] = useState('');
    const [tab, setTab] = useState(startPage);
    const [questionIndex, setQuestionIndex] = useState(0);
    const [answerData, setAnswerData] = useState([]);

    useEffect(() => {
        onGetMatchmakingForm();
    }, [onGetMatchmakingForm]);

    useEffect(() => {
        if (matchingQuestions?.length && !answerData.length) {
            setAnswerData(matchingQuestions.map(({_id}) => ({questionId: _id, optionIds: []})));
        }
    }, [matchingQuestions, answerData, setAnswerData]);

    const conctext = () => {
        if (loading) {
            return <Spinner />;
        }

        switch (tab) {
            case TABS_INDEXS.initialPage:
                return (
                    <MatchingDialogInitialPage setDialogTitle={setDialogTitle} setTab={setTab} {...pageStyleProps} />
                );

            case TABS_INDEXS.questionPage:
                return (
                    <MatchingDialogQuestionsPage
                        questionIndex={questionIndex}
                        setQuestionIndex={setQuestionIndex}
                        matchingQuestions={matchingQuestions}
                        setDialogTitle={setDialogTitle}
                        answerData={answerData}
                        setAnswerData={setAnswerData}
                        setTab={setTab}
                        {...pageStyleProps}
                    />
                );

            case TABS_INDEXS.rejectPage:
                return (
                    <MatchingDialogRejectPage
                        setDialogTitle={setDialogTitle}
                        onSkip={onClose}
                        setTab={setTab}
                        {...pageStyleProps}
                    />
                );

            case TABS_INDEXS.matchedPage:
                return (
                    <MatchingDialogMatchedPage
                        onClose={() => onClose(true)}
                        setDialogTitle={setDialogTitle}
                        setTab={setTab}
                        {...pageStyleProps}
                    />
                );

            default:
                break;
        }
    };

    const handleClickCross = () => {
        switch (tab) {
            case TABS_INDEXS.rejectPage:
                return onClose();

            case TABS_INDEXS.matchedPage:
                return onClose(true);

            case TABS_INDEXS.questionPage:
                return setTab(TABS_INDEXS.rejectPage);

            default:
                return setTab(TABS_INDEXS.rejectPage);
        }
    };

    return (
        <Dialog
            classes={{paperWidthSm: 'matchmaking-dialog__paper-width-sm'}}
            fullWidth
            className="matchmaking-dialog"
            fullScreen={!isLargeScreen}
            open={open}
        >
            <DialogTitle disableTypography className="matchmaking-dialog__title-wrapper">
                <div className="matchmaking-dialog__title-text-wrapper">
                    <h4 className="matchmaking-dialog__title">{dialogTitle}</h4>
                    <IconButton onClick={handleClickCross} className="matchmaking-dialog__title-close-btn">
                        <CloseIcon color="disabled" />
                    </IconButton>
                </div>

                {tab === TABS_INDEXS.questionPage && (
                    <div className="matchmaking-dialog__question-info-wrapper">
                        <Pagination
                            classes={{
                                ul: 'matchmaking-dialog__pagination-ul',
                                root: 'matchmaking-dialog__pagination-root',
                            }}
                            hideNextButton
                            hidePrevButton
                            page={questionIndex + 1}
                            count={matchingQuestions?.length}
                            renderItem={({page, selected}) => (
                                <span
                                    className={classNames('matchmaking-dialog__page-item', {
                                        'matchmaking-dialog__page-item--selected': selected,
                                    })}
                                >
                                    {page ? page : '...'}
                                </span>
                            )}
                        />
                        <span className="matchmaking-dialog__question-type">
                            {matchingQuestions[questionIndex]?.isRequired ? 'Required' : 'Optional'}
                        </span>
                    </div>
                )}
            </DialogTitle>
            {conctext()}
        </Dialog>
    );
};

export default MatchmakingDialog;

MatchmakingDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    isLargeScreen: PropTypes.bool,
    open: PropTypes.bool.isRequired,
    startPage: PropTypes.number,
    onGetMatchmakingForm: PropTypes.func,
};

MatchmakingDialog.defaultProps = {
    startPage: 0,
    matchingQuestions: [],
};
