import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {requestBusinessCard, resetBusinessCard} from 'store/actions';
import {getBusinessCardLoadState, getUserData} from 'store/selectors';
import {checkIsError, checkIsReady} from 'services/utils';

const useBusinessCard = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUserData);
    const loadState = useSelector(getBusinessCardLoadState);
    const userId = user?._id;

    const isReady = checkIsReady(loadState);
    const isError = checkIsError(loadState);

    useEffect(() => {
        dispatch(resetBusinessCard());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    useEffect(() => {
        if (!isReady && !isError && userId) {
            dispatch(requestBusinessCard());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, isReady, isError]);
};

export default useBusinessCard;
