import {Api} from 'Api';
import * as actionTypes from '../actionTypes/eventListingTypes';
import {EVENTS_TYPE} from 'constants/eventListing';
import {createFilterByDate} from 'services/helpers/listedEvent';

export const turnOnSpinner = () => async (dispatch) => {
    dispatch({
        type: actionTypes.TURN_ON_SPINNER,
    });
};

export const toggleEventListing = (eventId, callback) => async (dispatch) => {
    try {
        dispatch(turnOnSpinner());
        const response = await Api.request({
            method: 'post',
            url: `/event/${eventId}/toggle-listing`,
        });

        dispatch({
            type: actionTypes.TOGGLE_EVENT_LISTING_SUCCESS,
            payload: response,
        });

        callback();
    } catch (error) {
        dispatch({
            type: actionTypes.TOGGLE_EVENT_LISTING_FAIL,
        });
    }
};

export const updateEventListingData = (eventId, payload, callback) => async (dispatch) => {
    try {
        dispatch(turnOnSpinner());

        const response = await Api.request({
            method: 'post',
            url: `/event/${eventId}/listing`,
            payload,
        });

        dispatch({
            type: actionTypes.UPDATE_EVENT_LISTING_DATA_SUCCESS,
            payload: response,
        });

        callback();
    } catch (error) {
        dispatch({
            type: actionTypes.UPDATE_EVENT_LISTING_DATA_FAIL,
        });
    }
};

export const saveEventToPersonalList = (eventId, typeToLoad) => async (dispatch, getState) => {
    try {
        const {
            eventListing: {showPastEvents, eventsFilters, searchText},
        } = getState();
        dispatch(turnOnSpinner());

        const response = await Api.request({
            method: 'post',
            url: `/event/${eventId}/save`,
        });

        dispatch({
            type: actionTypes.SAVE_EVENT_TO_PERSONAL_LIST_SUCCESS,
            payload: response,
        });
        if (typeToLoad) {
            dispatch(getSavedEventsList(typeToLoad));
        }

        dispatch(
            getAllListedEvents({
                showPast: showPastEvents ? 'true' : '',
                search: searchText,
                months: createFilterByDate(eventsFilters),
            })
        );
    } catch (error) {
        dispatch({
            type: actionTypes.SAVE_EVENT_TO_PERSONAL_LIST_FAIL,
        });
    }
};

export const discardEventFromPersonalList = (eventId, typeToLoad) => async (dispatch, getState) => {
    try {
        const {
            eventListing: {showPastEvents, eventsFilters, searchText},
        } = getState();
        dispatch(turnOnSpinner());

        const response = await Api.request({
            method: 'post',
            url: `/event/${eventId}/discard`,
        });

        dispatch({
            type: actionTypes.DISCARD_EVENT_FROM_PERSONAL_LIST_SUCCESS,
            payload: response,
        });
        if (typeToLoad) {
            dispatch(getSavedEventsList(typeToLoad));
        }

        dispatch(
            getAllListedEvents({
                showPast: showPastEvents ? 'true' : '',
                search: searchText,
                months: createFilterByDate(eventsFilters),
            })
        );
    } catch (error) {
        dispatch({
            type: actionTypes.DISCARD_EVENT_FROM_PERSONAL_LIST_FAIL,
        });
    }
};

export const getAllListedEvents = (params, isLazyLoad) => async (dispatch, getState) => {
    try {
        const {
            eventListing: {allListedEvents},
        } = getState();

        dispatch(turnOnSpinner());

        let response = await Api.request({
            method: 'get',
            url: `/events/list`,
            params: {...params, perPage: 30},
        });

        if (isLazyLoad && allListedEvents && response?.events) {
            response.events = [...allListedEvents, ...response.events].filter(
                (v, i, a) => a.findIndex((t) => t.event === v.event) === i
            );
        }

        dispatch({
            type: actionTypes.GET_ALL_LISTED_EVENTS_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({
            type: actionTypes.GET_ALL_LISTED_EVENTS_FAIL,
        });
    }
};

export const getSavedEventsList = (type) => async (dispatch) => {
    try {
        dispatch(turnOnSpinner());

        const response = await Api.request({
            method: 'get',
            url: `/events/list/personal?type=${
                type && type === EVENTS_TYPE.PAST ? EVENTS_TYPE.PAST : EVENTS_TYPE.UPCOMING
            }`,
        });
        dispatch({
            type: actionTypes.GET_SAVED_EVENTS_LIST_SUCCESS,
            payload: response?.events,
        });
    } catch (error) {
        dispatch({
            type: actionTypes.GET_SAVED_EVENTS_LIST_FAIL,
        });
    }
};

export const getFirstFutureEventsForHomePage = () => async (dispatch) => {
    try {
        const {events} = await Api.request({
            method: 'get',
            url: `/events/list/homepage`,
        });

        dispatch({
            type: actionTypes.GET_FIRST_FUTURE_EVENTS_FOR_HOME_PAGE_SUCCESS,
            payload: events,
        });
    } catch (error) {
        dispatch({
            type: actionTypes.GET_FIRST_FUTURE_EVENTS_FOR_HOME_PAGE_FAIL,
        });
    }
};

export const getEventsListingDates = () => async (dispatch) => {
    try {
        dispatch(turnOnSpinner());

        const {dates} = await Api.request({
            method: 'get',
            url: `/events/listing/dates`,
        });

        dispatch({
            type: actionTypes.GET_EVENTS_LISTING_SUCCESS,
            payload: dates,
        });
    } catch (error) {
        dispatch({
            type: actionTypes.GET_EVENTS_LISTING_FAIL,
        });
    }
};

export const setEventsListingFilters = (filters) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_EVENTS_LISTING_FILTERS,
        payload: filters,
    });
};

export const setEventsListingSearch = (searchText) => (dispatch) => {
    dispatch({
        type: actionTypes.SET_EVENTS_LISTING_SEARCH,
        payload: searchText,
    });
};

export const toggleShowPastEvents =
    (showPast = false) =>
    (dispatch) => {
        dispatch({
            type: actionTypes.TOGGLE_SHOW_PAST_EVENTS,
            payload: showPast,
        });
    };

export const preSaveEvent = (id) => (dispatch) => {
    dispatch({
        type: actionTypes.PRE_SAVE_EVENTS,
        payload: id,
    });
};

export const resetEventListingParams = () => (dispatch) => {
    dispatch({
        type: actionTypes.RESET_EVENT_LISTING_PARAMS,
    });
};
