import React from 'react';
import {FullscreenExit as MinimizeScreenIcon} from '@material-ui/icons';
import {ReactComponent as FullscreenIcon} from 'Images/fullscreen.svg';

import {usePlatformTranslation} from 'services/hooks';

export function VideoPlayerFullScreenButton({fullScreenVideoConference, setFullScreenVideoConference}) {
    const {videoConferences} = usePlatformTranslation();

    return (
        <div
            className="conference-buttons-container"
            onClick={() => setFullScreenVideoConference(!fullScreenVideoConference)}
        >
            <button className="toggle-fullscreen">
                {fullScreenVideoConference && (
                    <div>
                        <MinimizeScreenIcon />
                        <span>{videoConferences.minimizeScreen}</span>
                    </div>
                )}
                {!fullScreenVideoConference && (
                    <div>
                        <FullscreenIcon />
                        <span>{videoConferences.fullScreen}</span>
                    </div>
                )}
            </button>
        </div>
    );
}
