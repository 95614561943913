import _ from 'lodash';
import * as actionTypes from '../../../actionTypes/adminActionTypes';
import {updateObject} from '../../../utilityStateFunction';
import {initialState} from '../initialState';

const organizersList = (state = initialState, {type, payload}) => {
    if (state === undefined) {
        return initialState.organizersList;
    }

    switch (type) {
        case actionTypes.GET_ONLINE_USERS_ADMIN_START:
        case actionTypes.CHANGE_ORGANIZER_STATUS_REQUEST:
        case actionTypes.GET_ORGANIZERS_START:
            return updateObject(state.organizersList, {
                loading: true,
            });

        case actionTypes.CHANGE_ORGANIZER_STATUS_FAIL:
        case actionTypes.GET_ORGANIZERS_FAIL:
        case actionTypes.GET_ONLINE_USERS_ADMIN_FAIL:
            return updateObject(state.organizersList, {
                loading: false,
            });

        case actionTypes.CHANGE_ORGANIZER_STATUS_SUCCESS:
            const updatedOrganizers = state.organizersList.organizers.map((organizer) =>
                organizer._id === payload._id ? {...state.organizerDetails.organizers, ...payload} : organizer
            );

            return updateObject(state.organizersList, {
                loading: false,
                organizers: updatedOrganizers,
            });

        case actionTypes.GET_ORGANIZERS_SUCCESS: {
            const {organizers, page, perPage, pages, total} = payload;
            return updateObject(state.organizersList, {
                loading: false,
                organizers: _.uniqBy([...state.organizersList.organizers, ...organizers], '_id'),
                pagination: {page, perPage, pages, total},
            });
        }
        case actionTypes.GET_ONLINE_USERS_ADMIN_SUCCESS: {
            return updateObject(state.organizersList, {
                loading: false,
                onlineUsersCount: payload,
            });
        }
        case actionTypes.CHANGE_ORGANIZERS_LIST_PAGE: {
            const {page} = payload;
            return updateObject(state.organizersList, {
                pagination: updateObject(state.organizersList.pagination, {
                    page,
                }),
            });
        }
        default:
            return state.organizersList;
    }
};

export default organizersList;
