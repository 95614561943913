import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './SessionDashboardBlock.scss';

export const SessionDashboardBlock = ({title, children, fullWidth, bordered}) => (
    <div className={classNames('session-dashboard-block', {fullWidth, bordered})}>
        <div className="session-dashboard-block__container">
            <div className="session-dashboard-block__header">
                <span>{title}</span>
            </div>
            <div className="session-dashboard-block__body">{children}</div>
        </div>
    </div>
);

SessionDashboardBlock.propTypes = {
    title: PropTypes.string,
    children: PropTypes.node,
    fullWidth: PropTypes.bool,
    bordered: PropTypes.bool,
};
