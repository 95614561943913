import React, {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {KeyboardArrowDownOutlined, VideocamOffTwoTone} from '@material-ui/icons';
import {Select, MenuItem, FormControl} from '@material-ui/core';

import {getVideoCapture, getMediaCaptureError} from 'store/selectors';
import {MEDIA_TYPES} from 'constants/organizer/sessions';
import {useActiveDevice} from 'services/hooks/organizer/sessions/useActiveDevice';
import {handleCanPlay} from 'services/helpers';

import {useStyles} from './VideoStream.styles';

export function VideoStream() {
    const classes = useStyles();
    const capture = useSelector(getVideoCapture);
    const isMediaCaptureError = useSelector(getMediaCaptureError);
    const selfVideoRef = useRef();

    useEffect(() => {
        if (selfVideoRef?.current) selfVideoRef.current.srcObject = capture;
    }, [capture]);

    const {devicesOptions, activeVideoDeviceId, handleChangeDevice} = useActiveDevice({mediaType: MEDIA_TYPES.video});

    return (
        <>
            <div className={classes.topWrapper}>
                {isMediaCaptureError ? (
                    <VideocamOffTwoTone className={classes.noVideoIcon} />
                ) : (
                    <video
                        className={classes.video}
                        ref={selfVideoRef}
                        muted
                        autoPlay
                        playsInline
                        onCanPlay={() => handleCanPlay(selfVideoRef)}
                    />
                )}
            </div>
            <div className={classes.bottomWrapper}>
                <p className={classes.title}>Camera</p>
                {capture && (
                    <FormControl className={classes.deviceSelect}>
                        <Select
                            onChange={handleChangeDevice}
                            value={activeVideoDeviceId || ''}
                            variant="standard"
                            IconComponent={KeyboardArrowDownOutlined}
                            disableUnderline
                            classes={{
                                root: classes.selectedValue,
                                icon: classes.arrowDownIcon,
                            }}
                        >
                            {devicesOptions.map(({deviceId, label}) => (
                                <MenuItem value={deviceId} key={deviceId}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </div>
        </>
    );
}
