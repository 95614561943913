export const WALL_GET_POSTS_START = 'WALL_GET_POSTS_START';
export const WALL_GET_POSTS_SUCCESS = 'WALL_GET_POSTS_SUCCESS';
export const WALL_GET_POSTS_FAIL = 'WALL_GET_POSTS_FAIL';

export const WALL_LIKE_POST_START = 'WALL_LIKE_POST_START';
export const WALL_LIKE_POST_SUCCESS = 'WALL_LIKE_POST_SUCCESS';
export const WALL_LIKE_POST_FAIL = 'WALL_LIKE_POST_FAIL';

export const WALL_UNLIKE_POST_START = 'WALL_UNLIKE_POST_START';
export const WALL_UNLIKE_POST_SUCCESS = 'WALL_UNLIKE_POST_SUCCESS';
export const WALL_UNLIKE_POST_FAIL = 'WALL_UNLIKE_POST_FAIL';

export const WALL_ADD_POST_START = 'WALL_ADD_POST_START';
export const WALL_ADD_POST_SUCCESS = 'WALL_ADD_POST_SUCCESS';
export const WALL_ADD_POST_FAIL = 'WALL_ADD_POST_FAIL';

export const WALL_ADD_COMMENT_START = 'WALL_ADD_COMMENT_START';
export const WALL_ADD_COMMENT_SUCCESS = 'WALL_ADD_COMMENT_SUCCESS';
export const WALL_ADD_COMMENT_FAIL = 'WALL_ADD_COMMENT_FAIL';

export const WALL_APPROVE_POST_START = 'WALL_APPROVE_POST_START';
export const WALL_APPROVE_POST_SUCCESS = 'WALL_APPROVE_POST_SUCCESS';
export const WALL_APPROVE_POST_FAIL = 'WALL_APPROVE_POST_FAIL';

export const WALL_APPROVE_COMMENT_START = 'WALL_APPROVE_COMMENT_START';
export const WALL_APPROVE_COMMENT_SUCCESS = 'WALL_APPROVE_COMMENT_SUCCESS';
export const WALL_APPROVE_COMMENT_FAIL = 'WALL_APPROVE_COMMENT_FAIL';

export const WALL_UPDATE_POSTS = 'WALL_UPDATE_POSTS';
export const WALL_UPDATE_POST_ON_RECEIVE = 'WALL_UPDATE_POST_ON_RECEIVE';
export const WALL_DELETE_POST_TRIGGER = 'WALL_DELETE_POST_TRIGGER';
export const WALL_UPDATE_COMMENT_ON_RECEIVE = 'WALL_UPDATE_COMMENT_ON_RECEIVE';
export const WALL_DELETE_COMMENT_TRIGGER = 'WALL_DELETE_COMMENT_TRIGGER';
export const WALL_LIKE_POST_TRIGGER = 'WALL_LIKE_POST_TRIGGER';
export const WALL_UNLIKE_POST_TRIGGER = 'WALL_UNLIKE_POST_TRIGGER';
export const WALL_PIN_POST_TRIGGER = 'WALL_PIN_POST_TRIGGER';
export const WALL_UNPIN_POST_TRIGGER = 'WALL_UNPIN_POST_TRIGGER';

export const WALL_DELETE_POST_REQUEST = 'WALL_DELETE_POST_REQUEST';
export const WALL_DELETE_POST_SUCCESS = 'WALL_DELETE_POST_SUCCESS';
export const WALL_DELETE_POST_FAIL = 'WALL_DELETE_POST_FAIL';

export const WALL_RESET_DATA = 'WALL_RESET_DATA';
