import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector, batch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {Replay} from '@material-ui/icons';
import {CustomDialog, CustomDialogContentText} from 'Routes/components/CustomDialogComponents';

import {getVideoCapture} from 'store/selectors';
import {disableScreenShare, clearCallState} from 'store/actions';

import {useStyles} from './KickPopup.styles';

export const KickPopup = ({open}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const history = useHistory();
    const capture = useSelector(getVideoCapture);

    const onLeaveSessionDashboard = () => {
        capture && capture.getTracks().forEach((track) => track.stop());

        batch(() => {
            dispatch(clearCallState());
            dispatch(disableScreenShare());
        });

        history.goBack();
    };

    return (
        <CustomDialog
            open={open}
            classes={{
                paper: classes.wrapper,
            }}
        >
            <p className={classes.title}>Session ended</p>
            <CustomDialogContentText>
                You have been kicked from this session and won't be able to rejoin. For any questions, please contact
                event organizer.
            </CustomDialogContentText>
            <div className={classes.buttonWrapper}>
                <Button
                    variant="contained"
                    startIcon={<Replay />}
                    className={classes.button}
                    color="primary"
                    onClick={onLeaveSessionDashboard}
                >
                    Leave
                </Button>
            </div>
        </CustomDialog>
    );
};

KickPopup.propTypes = {
    open: PropTypes.bool.isRequired,
};

KickPopup.defaultProps = {
    open: false,
};
