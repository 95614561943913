import React from 'react';
import PropTypes from 'prop-types';

export const EndedVideoQA = ({imageUrl, translation, isShown}) => {
    if (!isShown) return <div></div>;
    return (
        <div className="placeholder-container">
            {imageUrl ? (
                <img src={imageUrl} alt="current program" />
            ) : (
                <p>
                    {translation?.videoPlayer.videoEndedTextFirst}
                    <br />
                    {translation?.videoPlayer.videoEndedTextSecond}
                </p>
            )}
        </div>
    );
};

EndedVideoQA.defaultProps = {
    isShown: true,
};

EndedVideoQA.propTypes = {
    imageUrl: PropTypes.string,
    translation: PropTypes.object,
    isShown: PropTypes.bool,
};
