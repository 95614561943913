import MainPlatformBackground from './Images/platform-background/background.jpg';
import ScaPlatformBackground from './Images/platform-background/sca-platform-background-green.jpg';
import UnctadPlatformBackground from './Images/platform-background/unctad-platform-backgroung.jpg';
import EgyptPlatformBackground from './Images/platform-background/egypt-pavilion-platform-background.jpg';

import mainThumbnail from './Images/organizer/recordingThumbnails/mainThumbnail.png';
import scaThumbnail from './Images/organizer/recordingThumbnails/scaThumbnail.png';
import unctadThumbnail from './Images/organizer/recordingThumbnails/unctadThumbnail.png';
import egyptPavilionThumbnail from './Images/organizer/recordingThumbnails/egyptPavilionThumbnail.png';

require('dotenv').config();

export const envList = {
    develop: 'develop',
    stage: 'stage',
    production: 'production',
};

export const whiteLabelsList = {
    main: 'main',
    careers: 'careers',
    sca: 'sca',
    added: 'added',
    unctad: 'unctad',
    egyptPavilion: 'egypt-pavilion',
};

//folders
export const apiUrl = process.env.REACT_APP_REQUESTS_API;
export const socketApiUrl = process.env.REACT_APP_SOCKET_API;
export const eventFolder = process.env.REACT_APP_EVENT_FOLDER;
export const sessionFolder = process.env.REACT_APP_SESSION_FOLDER;
export const filesFolder = process.env.REACT_APP_FILES_FOLDER;
export const listingLogosFolder = `${process.env.REACT_APP_FILES_FOLDER}logos/`;
export const avatarFolder = process.env.REACT_APP_AVATAR_FOLDER;
export const videoEngineSocketUrl = process.env.REACT_APP_VIDEO_ENGINE;

export const env = process.env.REACT_APP_ENV || envList.develop;

export const whiteLabel = process.env.REACT_APP_WHITE_LABEL || whiteLabelsList.main;

export const sentryDsn =
    process.env.REACT_APP_SENTRY_DSN || 'https://d163241bbf244d308e4397c6334f19d8@sentry-event10x.softermii.co/4';

export const googleAnalyticsId = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || '';

export const googleTagManagerId = process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '';

export const googleAnalyticsMeasureId = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID || '';

export const facebookPixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID || '';

export const twitterPixelId = process.env.REACT_APP_TWITTER_PIXEL_ID || '';

export const yandexAnalyticsId = Number(process.env.REACT_APP_YANDEX_ANALYTICS_ID) || '';

export const footerSocialLinksConfig = {
    main: {
        twitter: 'https://twitter.com/events10x',
        facebook: 'https://www.facebook.com/events10x',
        instagram: 'https://www.instagram.com/events10x/?hl=en',
        linkedin: 'https://www.linkedin.com/company/events-10x/',
        youtube: 'https://www.youtube.com/channel/UCIQ374l_rUhgL1JaWEHCjKw?view_as=subscriber',
    },
    added: {
        twitter: 'https://twitter.com/AbuDhabiDED',
        facebook: 'https://www.facebook.com/AbuDhabiDED',
        instagram: 'https://www.instagram.com/abudhabided/',
        linkedin: 'https://www.linkedin.com/company/abudhabided',
        youtube: 'https://www.youtube.com/channel/UCwkX9GBK85TwoUH4XDFVWFQ',
    },
    sca: {
        twitter: 'https://twitter.com/SCA2030',
        facebook: 'https://www.facebook.com/SCA2030F',
        linkedin: 'https://www.linkedin.com/company/sca2030/',
        youtube: 'https://www.youtube.com/channel/UCNWAjpcKW3EJfdtLpXmmuqQ',
    },
    unctad: {
        twitter: 'https://twitter.com/unctadwif',
        facebook: 'https://www.facebook.com/UNCTAD',
        linkedin: 'https://www.linkedin.com/company/unctad',
        youtube: 'https://www.youtube.com/user/UNCTADInvestment',
    },
    [whiteLabelsList.egyptPavilion]: {
        twitter: 'https://twitter.com/EgyptExpo2020',
        facebook: 'https://www.facebook.com/EgyptExpo2020',
        linkedin: 'https://www.linkedin.com/company/egypt-expo-2020/',
        youtube: 'https://www.youtube.com/channel/UC1EVAN1GSiO_E9pDAbQ4T2Q',
        snapchat: 'https://www.snapchat.com/add/egyptexpo2020',
        tiktok: 'https://www.tiktok.com/@egyptexpo2020?lang=en',
    },
};

export const getInfoLinks = (platformLanguage, whiteLabel) => {
    const config = {
        main: {
            privacyPolicy: `/privacy-policy/${platformLanguage}`,
            cookies: `/cookies/${platformLanguage}`,
            termsAndConditions: `/terms-and-conditions/${platformLanguage}`,
        },
    };
    return config[whiteLabel] || config.main;
};

export const platformFooterConfig = {
    added: {
        isHighlightRectangleText: false,
    },
    sca: {
        isHighlightRectangleText: false,
    },
    unctad: {
        isHighlightRectangleText: false,
    },
    [whiteLabelsList.egyptPavilion]: {
        isHighlightRectangleText: false,
    },
};

export const platformBackground = {
    main: MainPlatformBackground,
    sca: ScaPlatformBackground,
    unctad: UnctadPlatformBackground,
    [whiteLabelsList.egyptPavilion]: EgyptPlatformBackground,
};

export const getPlatformBackground = (whiteLabel) => platformBackground[whiteLabel] || platformBackground.main;

export const platformMetaTitle = {
    [whiteLabelsList.main]: 'EVENTS10X - Your Future Virtual Venue',
    [whiteLabelsList.sca]: 'Saudi Contractors Authority – Events',
    [whiteLabelsList.unctad]: 'World Investment Forum – Investing in Sustainable Development',
    [whiteLabelsList.egyptPavilion]: 'Egypt Pavilion in Expo 2020 – Legacy Empowering the Future',
};

export const platformMetaDescription = {
    [whiteLabelsList.main]:
        'A Platform that Connects the World, Embraces Innovation and Provides Quality Services in the Events Industry. We Harness Futuristic Capabilities and Integrate Technologies to Optimise our Clients’ Rewards. For the Age of Connectivity, we Strive to deliver Accessible, Sustainable and Resilient Services. Here at Events10X, Seamlessly Intertwine Hybrid and Virtual Events to Connect People around the World.',
};

export const recordingsThumbnails = {
    [whiteLabelsList.main]: mainThumbnail,
    [whiteLabelsList.sca]: scaThumbnail,
    [whiteLabelsList.unctad]: unctadThumbnail,
    [whiteLabelsList.egyptPavilion]: egyptPavilionThumbnail,
};
