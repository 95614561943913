import React, {useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {isMobile} from 'react-device-detect';

import VideoAvatar from '../../../VideoAvatar';

import {getUserData, getActiveCallTypeData} from 'store/selectors';
import {useUserMedia, useMobileSelfVideo} from 'services/hooks';
import {useStyles} from './SelfVideo.styles';

export const SelfVideo = ({videoEnabled}) => {
    const classes = useStyles({isMobile});
    const user = useSelector(getUserData);
    const {shareScreenCapture, isParticipantStreamActive, isScreenShared} = useSelector(getActiveCallTypeData);
    const selfVideoRef = useRef();

    useUserMedia(selfVideoRef);

    const [width, rotated] = useMobileSelfVideo(videoEnabled); // mobile self video rotation fix

    const handleCanPlay = () => selfVideoRef.current.play();

    useEffect(() => {
        if (isScreenShared && selfVideoRef.current.srcObject !== shareScreenCapture) {
            selfVideoRef.current.srcObject = shareScreenCapture;
        }
    }, [isScreenShared, shareScreenCapture]);

    return (
        <div className={classNames(classes.videoPlate, {[classes.activeStreamsVideoPlate]: isParticipantStreamActive})}>
            {!videoEnabled && (
                <div className={classes.userName}>
                    <VideoAvatar member={user} />
                </div>
            )}
            <video
                className={classNames(classes.video, {
                    [classes.hidden]: !videoEnabled,
                    [classes.rotated]: rotated,
                })}
                ref={selfVideoRef}
                muted
                autoPlay
                playsInline
                onCanPlay={handleCanPlay}
                style={{
                    width: `${width}%`,
                }}
            />
        </div>
    );
};
