import React from 'react';

import Svg from '../Svg/Svg';

import './Schedule.scss';

const IconSchedule = () => (
    <Svg className="IconSchedule">
        <path
            className="IconSchedule__el"
            d="M10 1h12a1 1 0 011 1v11a1 1 0 01-1 1h-2v1h2a2 2 0 002-2V2a2 2 0 00-2-2H10a2 2 0 00-2 2v4h1V2a1 1 0 011-1z"
        />
        <path d="M18 6h-1V4h-2v2H7V4H5v2H4c-1.11 0-1.99.9-1.99 2L2 21a2 2 0 002 2h14c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm1 15a1 1 0 01-1 1H4a1 1 0 01-1-1V8a1 1 0 011-1h14a1 1 0 011 1v13z" />
        <path d="M10.5 10H9v6l5.25 3.15.75-1.23-4.5-2.67V10z" />
    </Svg>
);

export default IconSchedule;
