import React from 'react';
import classNames from 'classnames';

import './EventsList.scss';

const EventsList = ({eventId, redirectToEvent, eventRolesList = []}) => {
    return (
        <ul className="events-list">
            {eventRolesList.map((eventRole) => {
                return (
                    <li
                        className={classNames('events-list__item ', {
                            'events-list__item--active': eventId === eventRole.event._id,
                        })}
                        key={eventRole.event._id}
                        onClick={redirectToEvent(eventRole.event.slug, eventRole.event._id)}
                    >
                        <span>{eventRole.event.name}</span>
                    </li>
                );
            })}
        </ul>
    );
};

export default EventsList;
