import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import AuditoriumWallNavigation from './AuditoriumWallNavigation';

export default connect(
    (state) => ({
        onDemandVideos: selectors.getOnDemandVideos(state),
        timeslotPolls: selectors.getTimeslotPolls(state),
        timslotLoading: selectors.getTimeslotLoading(state),
        isLargeScreen: selectors.getIsLargeScreen(state),
    }),
    {
        addOnDemandVideo: actions.addOnDemandVideo,
        getTimeslotPolls: actions.getTimeslotPolls,
        setActiveWall: actions.setActiveWall,
    }
)(AuditoriumWallNavigation);
