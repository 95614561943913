import generalInfoHandler from './handlers/generalInfoHandler';
import oneToOneHandler from './handlers/oneToOneHandler';
import groupHandler from './handlers/groupHandler';

const reducer = (state, action) => ({
    generalInfo: generalInfoHandler(state, action),
    oneToOne: oneToOneHandler(state, action),
    group: groupHandler(state, action),
});

export default reducer;
