import React from 'react';
import classNames from 'classnames';

import ColoredScrollbars from '../../../../../../../ColoredScrollbars/ColoredScrollbars';
import CloseTopNavButton from '../../../CloseTopNavButton/CloseTopNavButton';
import SingleUserContact from './components/SingleUserContact/SingleUserContact';

import '../../../../../../../../../styles/contacts.scss';
import {ReactComponent as ContactsGreenIcon} from '../../../../../../../../../Images/phone-book-green.svg';

import './Contacts.scss';

export class Contacts extends React.Component {
    componentDidMount() {
        const {mobile} = this.props;
        if (mobile) {
            if (document.querySelector('.received-requests-wrapper')) {
                const heightReceivedRequests = document.querySelector('.received-requests-wrapper').clientHeight;
                if (document.querySelector('.already-contacts-wrapper')) {
                    document
                        .querySelector('.already-contacts-wrapper')
                        .setAttribute('style', `height: calc(100vh - 160px - ${heightReceivedRequests}px) !important`);
                }
            }
        }
    }

    render() {
        const {mobile} = this.props;
        let pendingRequests = 0;
        let orderedContacts = [];
        if (this.props.user) {
            pendingRequests = this.props.user.receivedRequests.filter((request) => request.status === 'pending').length;

            // order the contacts alphabetically
            orderedContacts = [...this.props.user.contacts];
            orderedContacts.sort(function (x, y) {
                if (x.user.first === y.user.first) {
                    return x.user.last < y.user.last ? -1 : 1;
                }
                return x.user.first < y.user.first ? -1 : 1;
            });
        }

        return (
            <div className={classNames('contacts-page', {'contacts-mobile': mobile})} style={{left: this.props.offset}}>
                <CloseTopNavButton />
                <div className="page-title">
                    <h3>Your contacts</h3>
                </div>

                {this.props.user.receivedRequests.length + this.props.user.contacts.length > 0 ? (
                    <div>
                        <div className="custom-scroll-container networking-wrapper">
                            {this.props.user.receivedRequests.filter((request) => request.status === 'pending').length >
                            0 ? (
                                <h2 className="received-requests-title" style={{marginBottom: '8px'}}>
                                    Contact Requests
                                </h2>
                            ) : null}

                            {this.props.user.receivedRequests.length > 0 ? (
                                <div
                                    className="received-requests-wrapper"
                                    data-height={pendingRequests > 3 ? 'true' : 'false'}
                                >
                                    {pendingRequests > 3 ? (
                                        <ColoredScrollbars>
                                            {this.props.user.receivedRequests.map((request) => {
                                                if (request.status === 'pending') {
                                                    return (
                                                        <div className="received-request-container" key={request._id}>
                                                            <SingleUserContact
                                                                key={request._id}
                                                                id={request._id}
                                                                user={request.user}
                                                                friendConnectionReceived={true}
                                                                requestId={request._id}
                                                            />
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </ColoredScrollbars>
                                    ) : (
                                        <div>
                                            {this.props.user.receivedRequests.map((request) => {
                                                if (request.status === 'pending') {
                                                    return (
                                                        <div className="received-request-container" key={request._id}>
                                                            <SingleUserContact
                                                                key={request._id}
                                                                id={request._id}
                                                                user={request.user}
                                                                friendConnectionReceived={true}
                                                                requestId={request._id}
                                                            />
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </div>
                                    )}
                                </div>
                            ) : null}

                            {orderedContacts.length > 6 ? (
                                <div className="already-contacts-wrapper">
                                    <ColoredScrollbars>
                                        {orderedContacts.map((contact) => {
                                            return (
                                                <SingleUserContact
                                                    key={contact._id}
                                                    id={contact._id}
                                                    user={contact.user}
                                                    isContact={true}
                                                />
                                            );
                                        })}
                                    </ColoredScrollbars>
                                </div>
                            ) : (
                                <div className="contacts-fixed-members">
                                    {orderedContacts.map((contact) => {
                                        if (contact) {
                                            return (
                                                <SingleUserContact
                                                    key={contact._id}
                                                    id={contact._id}
                                                    user={contact.user}
                                                    isContact={true}
                                                />
                                            );
                                        }

                                        return null;
                                    })}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className="empty-contacts">
                        <p>
                            You don't have any contacts yet. Please visit the networking section to start building your
                            list of contacts.
                        </p>
                        <ContactsGreenIcon className="primary-fill-svg primary-stroke-svg" />
                    </div>
                )}
            </div>
        );
    }
}
