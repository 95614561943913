import React, {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import classNames from 'classnames';
import {Radio, RadioGroup, FormControlLabel, FormControl, Button} from '@material-ui/core';
import {INTERPRETATION_LANGUAGES} from 'constants/shared';
import {getInterpretation, getTimeslotData} from 'store/selectors';
import {setActiveInterpretationLanguage} from 'store/actions';
import {useStyles} from './Interpretation.style';

import {BackIcon} from 'Images/pages/interpretation';

const INTERPRETATION_LANGUAGES_OPTIONS = {
    [INTERPRETATION_LANGUAGES.ARABIC]: 'Arabic',
    [INTERPRETATION_LANGUAGES.CHINESE]: 'Chinese',
    [INTERPRETATION_LANGUAGES.ENGLISH]: 'English',
    [INTERPRETATION_LANGUAGES.FRENCH]: 'French',
    [INTERPRETATION_LANGUAGES.RUSSIAN]: 'Russian',
    [INTERPRETATION_LANGUAGES.SPANISH]: 'Spanish',
};

function Interpretation({back}) {
    const dispatch = useDispatch();
    const style = useStyles();
    const interpretation = useSelector(getInterpretation);
    const timeslot = useSelector(getTimeslotData);

    const onChange = useCallback(({target: {value}}) => dispatch(setActiveInterpretationLanguage(value)), [dispatch]);

    return (
        <div className={classNames(style.container)}>
            <div className={classNames(style.btnWrapper)}>
                <Button className={classNames(style.btn)} onClick={back} startIcon={<BackIcon />}>
                    Interpretation
                </Button>
            </div>

            <FormControl component="fieldset" className={style.formControl}>
                <RadioGroup
                    className={classNames(style.radioGroup)}
                    name="radio-buttons-group"
                    value={interpretation?.activeLanguage}
                    onChange={onChange}
                >
                    <FormControlLabel
                        value="default"
                        control={<Radio />}
                        label="Default"
                        classes={{
                            root: style.labelRoot,
                            label: style.label,
                        }}
                    />
                    {!!timeslot?.interpreters?.length &&
                        timeslot.interpreters.map(({_id, interpretationLanguage}) => (
                            <FormControlLabel
                                key={_id}
                                value={interpretationLanguage}
                                control={<Radio />}
                                label={INTERPRETATION_LANGUAGES_OPTIONS[interpretationLanguage]}
                                classes={{
                                    root: style.labelRoot,
                                    label: style.label,
                                }}
                                disabled={!interpretation[interpretationLanguage]}
                            />
                        ))}
                </RadioGroup>
            </FormControl>
        </div>
    );
}

export default Interpretation;
