import {socialsSlug} from 'constants/data';
import {FRIEND_CONNECTION_REQUEST_STATUS} from 'constants/shared';

export const checkIfIsContact = (eventUser, contacts) => {
    const isContact = contacts.find((contact) => contact.user._id === eventUser.userId);
    return isContact !== undefined;
};

export const checkIfIsFriendConnectionSent = (eventUser, sentRequests) => {
    const isFriendConnectionSent = sentRequests.find(
        (request) =>
            request.user._id === eventUser.userId && request.status === FRIEND_CONNECTION_REQUEST_STATUS.pending
    );
    return isFriendConnectionSent !== undefined;
};

export const checkIfIsFriendConnectionReceived = (eventUser, receivedRequests) => {
    const isFriendConnectionReceived = receivedRequests.find(
        (request) =>
            request.user._id === eventUser.userId &&
            (request.status === FRIEND_CONNECTION_REQUEST_STATUS.pending ||
                request.status === FRIEND_CONNECTION_REQUEST_STATUS.rejected)
    );

    return isFriendConnectionReceived !== undefined ? isFriendConnectionReceived._id : false;
};

export const checkIfIsOnline = (eventUser, onlineUsers) => {
    if (onlineUsers && onlineUsers.length) {
        return onlineUsers.findIndex((user) => user.userId === eventUser.userId) > -1;
    }
    return false;
};

export const formSocialList = (eventUser) => {
    const {facebook, twitter, linkedin} = eventUser;
    const socialList = [];
    if (facebook) socialList.push({slug: socialsSlug.facebook, link: facebook});
    if (twitter) socialList.push({slug: socialsSlug.twitter, link: twitter});
    if (linkedin) socialList.push({slug: socialsSlug.linkedin, link: linkedin});

    return socialList;
};
