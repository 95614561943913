import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import _ from 'lodash';
import classNames from 'classnames';

import {usePlatformTranslation} from 'services/hooks';
import * as actions from 'store/actions';
import {getIsRtlLanguage} from 'store/selectors';

import PrimaryColorSwitch from '../../../../../../../PrimaryColorSwitch/PrimaryColorSwitch';
import {useStyles} from './EventsHeader.styles';

import FilterChips from '../../../../../../../ExhibitorsOverlay/components/FilterChips/FilterChips';
import Filter from '../../../../../../../Filter';

export default function EventsHeader() {
    const classes = useStyles();

    const dispatch = useDispatch();
    const translation = usePlatformTranslation();
    const {eventsFilters, eventsDates} = useSelector((state) => state.eventListing);
    const isRtlLanguage = useSelector(getIsRtlLanguage);
    const [checked, setChecked] = useState(false);
    const [isFilterOpened, setIsFilterOpened] = useState(false);
    const [search, setSearch] = useState('');
    const [datesFilters, setDatesFilters] = useState([]);

    useEffect(() => {
        if (!eventsDates || !eventsDates.length) return;

        const filtereventsDates = eventsDates.map((field) => {
            const options = [];

            field.months.forEach((month) => options.push({_id: month, value: month, isChecked: false}));

            return {
                _id: field.year,
                label: field.year,
                options,
            };
        });

        setDatesFilters(filtereventsDates);
    }, [eventsDates, search]);

    const toggleSwitch = () => {
        setChecked(!checked);
        dispatch(actions.toggleShowPastEvents(!checked));
    };

    const openFilter = (value) => setIsFilterOpened(value);

    const onSearch = (value) => {
        const clearSpace = value.replace(/\s+/g, ' ').trim();
        setSearch(clearSpace);
        dispatch(actions.setEventsListingSearch(clearSpace));
    };

    const applyFilters = (filters) => {
        dispatch(actions.setEventsListingFilters(filters));

        // TODO: add to filter component
        const filtersOptions = {};

        filters.forEach((filter) => {
            const selectedOptions = _.omitBy(filter.options, (value) => !value);

            if (Object.keys(selectedOptions).length) filtersOptions[filter._id] = Object.keys(selectedOptions);
        });
    };

    return (
        <div className={classes.container}>
            <div className={classes.row}>
                <h2 className={classes.title}>{translation.eventsListing.eventsList}</h2>
                <div className={classes.filterSection}>
                    <div className={classNames(classes.switch, {isRtl: isRtlLanguage})}>
                        <PrimaryColorSwitch color="primary" onChange={toggleSwitch} checked={checked} />
                        {translation.eventsListing.showPastEvents}
                    </div>
                    {datesFilters.length ? (
                        <Filter
                            className={classes.filter}
                            filterFields={datesFilters}
                            searchInputText={translation?.eventsListing.searchEvents}
                            setIsOpened={openFilter}
                            isOpened={isFilterOpened}
                            setSearch={onSearch}
                            usingFilters={eventsFilters}
                            applyFilter={applyFilters}
                        />
                    ) : null}
                </div>
            </div>
            <div className={classes.row}>
                {eventsFilters && <FilterChips filters={eventsFilters} setFilters={applyFilters} withLabels />}
            </div>
        </div>
    );
}
