import {IS_CAREERS_ENV} from '../../constants/shared';
import {
    getAppliedJobsLink,
    getMyEventsLink,
    getBusinessCardLink,
    getEventProfileLink,
    getProfileLink,
} from '../../routes';

export const getAccountNavigationMenu = (translantion) => {
    const menu = [
        {
            title: translantion?.platformProfile || 'PLATFORM PROFILE',
            linkFunction: getProfileLink,
        },
        {
            title: translantion?.eventProfile || 'EVENT PROFILE',
            linkFunction: getEventProfileLink,
        },
        {
            title: translantion?.businessCard || 'BUSINESS CARD',
            linkFunction: getBusinessCardLink,
        },
        {
            title: translantion?.appliedJobs || 'APPLIED JOBS',
            linkFunction: getAppliedJobsLink,
            hidden: !IS_CAREERS_ENV,
        },
        {
            title: translantion?.myEvents || 'MY EVENTS',
            linkFunction: getMyEventsLink,
            hidden: false,
        },
    ];

    return menu;
};
