import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

const AuditoriumMobileTabs = ({
    currentTab,
    menuButtons,
    // redux-state
    isLargeScreen,
}) => {
    if (isLargeScreen) {
        return null;
    }

    return (
        <div className="tabs-container">
            {menuButtons.map((menuButton) => {
                if (menuButton.hide) return null;

                return (
                    <div
                        key={menuButton.name}
                        className={classNames(`button-tab tab`, {active: currentTab === menuButton.value})}
                        onClick={menuButton.action}
                    >
                        {menuButton.name}
                    </div>
                );
            })}
        </div>
    );
};

AuditoriumMobileTabs.propTypes = {
    menuButtons: PropTypes.arrayOf(PropTypes.object),
    currentTab: PropTypes.string,
    // redux
    isLargeScreen: PropTypes.bool,
};

export default AuditoriumMobileTabs;
