import connect from 'react-redux/es/connect/connect';
import {approveWallComment, approveWallPost, deleteWallPost, pinWallPost, deleteWallPostComment} from 'store/actions';
import SinglePost from './SinglePost';

export default connect(
    (state) => ({
        eventId: state.event.eventId,
        userAvatarSmall: state.user.data && state.user.data.avatarSmall,
        loggedIn: state.user.loggedIn,
        user: state.user.data,
        activeWallId: state.event.sideNavigationDetails.activeWallId,
        sideNavigationDetails: state.event.sideNavigationDetails,
        isVideoWall: state.user.sideNavigation.seeVideoWall,
        isMobile: state.layout.isMobile,
        languages: state.languages,
        isRtlLanguage: state.languages.isRtlLanguage,
    }),
    {
        onApproveWallComment: approveWallComment,
        onApproveWallPost: approveWallPost,
        deleteWallPost,
        pinWallPost,
        deleteWallPostComment,
    }
)(SinglePost);
