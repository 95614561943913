import React, {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {useSelector, useDispatch} from 'react-redux';

import Media from 'Routes/components/Media/Media';
import ChatButton from './components/ChatButton/ChatButton';
import UserPreviewPlateSocial from './components/UserPreviewPlateSocial/UserPreviewPlateSocial';
import UserPreviewPlateInteraction from './components/UserPreviewPlateInteraction/UserPreviewPlateInteraction';

import {usePlatformTranslation, useUserStatus} from 'services/hooks';
import {getPrivateChatId} from 'services/utils';
import {useBusinessCardOpening, useRequestBussinessCard} from './UserPreviewPlate.hooks';
import {formDesignation, formBusinessCardShape, checkIsActionsRowShowed} from './UserPreviewPlate.helpers';
import {createPrivateChat, openPrivateChat, setMeetingSetupUser, toggleMeetingSetup} from 'store/actions';
import {MATCHING_LEVELS} from 'constants/matchMaking';
import {handleUserStatusUpdate} from 'Api/socketApi';
import {userStatuses} from 'constants/data';
import {normalizeMeetingSetupUser} from 'services/utils/normalizers';

import './UserPreviewPlate.scss';

const UserPreviewPlate = ({
    userId,
    media,
    firstName = '',
    lastName,
    profession,
    company,
    socialList = [],
    businessCardId,
    isCurrentUser = false,
    isContact,
    isFriendConnectionSent,
    canConnectToParticipant,
    matchLevel,
    online,
    openChatCallback,
    openCardCallback,
    readOnly,
    className,
}) => {
    const dispatch = useDispatch();
    const translation = usePlatformTranslation();
    const {eventId} = useSelector((state) => state.event);
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);
    const privateChats = useSelector((state) => state.user.data.privateChats);
    const {getUserStatus} = useUserStatus();
    const openBusinessCard = useBusinessCardOpening(userId, openCardCallback);
    const requestBussinessCard = useRequestBussinessCard(userId);

    const [isOnline, setIsOnline] = useState(online);

    const isActionsRowShowed = checkIsActionsRowShowed(
        isCurrentUser,
        canConnectToParticipant,
        businessCardId,
        socialList
    );

    const handleMeetingClick = useCallback(() => {
        dispatch(
            setMeetingSetupUser(
                normalizeMeetingSetupUser({_id: userId, first: firstName, last: lastName, avatarSmall: media.src})
            )
        );
        dispatch(toggleMeetingSetup(true));
    }, [dispatch, firstName, lastName, media.src, userId]);

    const handleOpenPrivateChat = useCallback(() => {
        const privateChatId = getPrivateChatId(privateChats, userId);

        openChatCallback && openChatCallback();

        if (privateChatId) dispatch(openPrivateChat(privateChatId));
        else dispatch(createPrivateChat(userId, eventId));
    }, [dispatch, eventId, openChatCallback, privateChats, userId]);

    useEffect(() => {
        handleUserStatusUpdate((user) => {
            const onlineStatus = user.status === userStatuses.online;
            if (user.id === userId && onlineStatus !== isOnline) {
                setIsOnline(onlineStatus);
            }
        });
    }, [userId, isOnline, setIsOnline]);

    return (
        <div
            className={classNames(
                'UserPreviewPlate',
                `UserPreviewPlate_satus-${getUserStatus(userId)}`,
                {
                    isRtl: isRtlLanguage,
                },
                className
            )}
        >
            {readOnly && <div className="UserPreviewPlate__overlay" />}
            <div className="UserPreviewPlate__contentRow">
                <div
                    className={classNames(
                        'UserPreviewPlate__mediaCol',
                        {
                            isRtl: isRtlLanguage,
                        },
                        {online: isOnline}
                    )}
                >
                    <div className="UserPreviewPlate__mediaHolder">
                        <Media media={media} isAdaptable />
                    </div>
                </div>

                <div className="UserPreviewPlate__infoCol">
                    <div className="UserPreviewPlate__fullName">
                        {firstName} {lastName}
                    </div>

                    <div className="UserPreviewPlate__designation">{formDesignation(profession, company)}</div>
                </div>

                {!isCurrentUser && (
                    <div key={`${userId}_chat_btn`} className="UserPreviewPlate__chatCol">
                        <ChatButton onClick={handleOpenPrivateChat} />
                    </div>
                )}
            </div>
            <div className="UserPreviewPlate__matching-row">
                {matchLevel && <p className="UserPreviewPlate__matching-row-title">{MATCHING_LEVELS[matchLevel]}</p>}
            </div>
            {(isActionsRowShowed || readOnly) && (
                <div className="UserPreviewPlate__actionsRow">
                    <div className="UserPreviewPlate__socialList">
                        {socialList.map(({slug, link}) => (
                            <div key={slug} className="UserPreviewPlate__socialItem">
                                <UserPreviewPlateSocial slug={slug} link={link} />
                            </div>
                        ))}
                    </div>

                    <div className="UserPreviewPlate__interactionGroup">
                        {!isCurrentUser && (
                            <div className="UserPreviewPlate__interactionItem">
                                <UserPreviewPlateInteraction
                                    title={translation.meetings.newMeeting}
                                    onClick={handleMeetingClick}
                                />
                            </div>
                        )}

                        {businessCardId && (
                            <div className="UserPreviewPlate__interactionItem">
                                <UserPreviewPlateInteraction
                                    {...formBusinessCardShape(isContact, isFriendConnectionSent, translation)}
                                    isDisabled={isFriendConnectionSent}
                                    onClick={
                                        isContact || isFriendConnectionSent ? openBusinessCard : requestBussinessCard
                                    }
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserPreviewPlate;
