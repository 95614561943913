import React from 'react';
import PropTypes from 'prop-types';
import {clipboardIcon, facebookIcon, linkedInIcon, mailIcon, twitterIcon} from 'Images/pages/eventListing';
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useStyles} from './SocialButtons.style';
import classNames from 'classnames';
import {Snackbar} from '@material-ui/core';

function SocialButtons({link, handleClose, socialMediaShareTranslations}) {
    const style = useStyles();
    const [successSnackbarOpened, toggleSuccessSnackbar] = React.useState(false);
    const handleOpenSuccessSnackbar = () => {
        toggleSuccessSnackbar(true);
    };
    const handleCloseSnackbar = () => toggleSuccessSnackbar(false);

    return (
        <>
            <div className={classNames(style.socialButtonContainer)}>
                <div onClick={handleClose} className={classNames(style.socialButton)}>
                    <LinkedinShareButton url={`${link}`}>
                        <div>
                            <img className={classNames(style.socialIcon)} src={linkedInIcon} alt={`linkedInIcon`} />
                        </div>
                        <div
                            className={classNames(style.socialButtonText)}
                        >{`${socialMediaShareTranslations?.linkedIn}`}</div>
                    </LinkedinShareButton>
                </div>
                <div onClick={handleClose} className={classNames(style.socialButton)}>
                    <TwitterShareButton url={`${link}`}>
                        <div>
                            <img className={classNames(style.socialIcon)} src={twitterIcon} alt={`twitterIcon`} />
                        </div>
                        <div
                            className={classNames(style.socialButtonText)}
                        >{`${socialMediaShareTranslations?.twitter}`}</div>
                    </TwitterShareButton>
                </div>
                <div onClick={handleClose} className={classNames(style.socialButton)}>
                    <FacebookShareButton url={`${link}`}>
                        <div>
                            <img className={classNames(style.socialIcon)} src={facebookIcon} alt={`facebookIcon`} />
                        </div>
                        <div
                            className={classNames(style.socialButtonText)}
                        >{`${socialMediaShareTranslations?.facebook}`}</div>
                    </FacebookShareButton>
                </div>
                <div onClick={handleClose} className={classNames(style.socialButton)}>
                    <EmailShareButton url={`${link}`}>
                        <div>
                            <img className={classNames(style.socialIcon)} src={mailIcon} alt={`mailIcon`} />
                        </div>
                        <div
                            className={classNames(style.socialButtonText)}
                        >{`${socialMediaShareTranslations?.email}`}</div>
                    </EmailShareButton>
                </div>
                <CopyToClipboard text={`${link}`} onCopy={handleOpenSuccessSnackbar}>
                    <div className={classNames(style.socialButton)}>
                        <div>
                            <img className={classNames(style.socialIcon)} src={clipboardIcon} alt={`clipboardIcon`} />
                        </div>
                        <div
                            className={classNames(style.socialButtonText)}
                        >{`${socialMediaShareTranslations?.clipboard}`}</div>
                    </div>
                </CopyToClipboard>
            </div>
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={successSnackbarOpened}
                handleClose={handleCloseSnackbar}
                autoHideDuration={3000}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                className="snackbar"
                message={<span id="message-id">{'Link copied'}</span>}
            />
        </>
    );
}

SocialButtons.propTypes = {
    handleClose: PropTypes.func.isRequired,
    link: PropTypes.string.isRequired,
    socialMediaShareTranslations: PropTypes.object,
};

export default SocialButtons;
