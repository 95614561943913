import * as actionTypes from '../actionTypes/organizerActionTypes/eventBoothOrder';
import {updateObject} from '../utilityStateFunction';
import {sortByOrderNumber} from '../../services/utils';
import {EXHIBITORS_STATUS_LIST} from 'constants/organizer/eventExhibitors';

const initialState = {
    loading: false,
    exhibitors: [],
    featuredExhibitors: [],
    approvedExhibitors: [],
    activeExhibitorId: null,
    pagination: {page: null, perPage: null, total: null},
    exhibitorsFilters: [],
    filteredExhibitors: [],
};

const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.GET_EXHIBITORS_START:
        case actionTypes.CHANGE_SPONSOR_TITLE_REQUEST:
            return updateObject(state, {loading: true});
        case actionTypes.GET_EXHIBITORS_SUCCESS: {
            const {exhibitors, privateBoothPreview, pagination = initialState.pagination} = payload;

            let approvedExhibitors = exhibitors.filter(
                (exhibitor) => exhibitor.status === EXHIBITORS_STATUS_LIST.APPROVED
            );
            if (privateBoothPreview) approvedExhibitors.push(privateBoothPreview);
            let featuredExhibitors = approvedExhibitors.filter((exhibitor) => exhibitor.isFeatured);

            const searchParams = new URLSearchParams(document.location?.search);
            const exhibitorId = searchParams.get('boothId');

            return updateObject(state, {
                loading: false,
                exhibitors,
                featuredExhibitors: sortByOrderNumber(featuredExhibitors),
                approvedExhibitors,
                pagination,
                activeExhibitorId: exhibitorId || sortByOrderNumber(approvedExhibitors)?.[0]?._id,
            });
        }
        case actionTypes.CHANGE_SPONSOR_TITLE_SUCCESS: {
            const {_id, sponsorTitle} = payload;
            const exhibitors = state.exhibitors.map((exhibitor) => {
                if (exhibitor._id === _id) {
                    return {
                        ...exhibitor,
                        sponsorTitle,
                    };
                } else return exhibitor;
            });

            return updateObject(state, {
                exhibitors,
                loading: false,
            });
        }
        case actionTypes.GET_EXHIBITORS_FAIL:
        case actionTypes.CHANGE_SPONSOR_TITLE_FAIL:
            return updateObject(state, {loading: false});
        case actionTypes.SET_ACTIVE_EXHIBITOR_ID:
            return updateObject(state, {activeExhibitorId: payload});

        case actionTypes.SET_EXHIBITORS_FILTERS:
            return updateObject(state, {exhibitorsFilters: payload});
        case actionTypes.SET_FILTERED_EXHIBITORS:
            return updateObject(state, {filteredExhibitors: payload});

        case actionTypes.RESET_ORGANIZER_BOOTH_EXHIBITORS: {
            return initialState;
        }
        default:
            return state;
    }
};

export default reducer;
