import {createMuiTheme} from '@material-ui/core/styles';
import {mainPalette} from './mainTheme';

const scaTheme = createMuiTheme({
    palette: {
        ...mainPalette.palette,
        primary: {
            main: '#32862B',
        },
        secondary: {
            main: '#005850',
        },
        success: {
            main: '#005850',
        },
        action: {
            hover: '#ebf3ea',
            active: 'rgba(0, 88, 80, 0.35)',
        },
    },

    breakpoints: {
        ...mainPalette.breakpoints,
    },
});

export default scaTheme;
