import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import Networking from './Networking';

export default connect(
    (state) => ({
        user: selectors.getUserData(state),
        eventId: selectors.getEventId(state),
        onlineUsers: selectors.getOnlineUsers(state),
        sideNavigation: selectors.getSideNavigation(state),
        resourcesAccess: selectors.getResourcesAccess(state),
        userLoading: selectors.getUserLoading(state),
        participantsGroups: selectors.getAccessParticipantsGroups(state),
        eventParticipantFilters: selectors.getEventParticipantFilters(state),
        eventParticipantFiltersEventId: selectors.getEventParticipantFiltersEventId(state),
    }),
    {
        onGetOnlineUsers: actions.getOnlineUsers,
        onApplyParticipantsFilters: actions.applyParticipantsFilters,
        onSetParticipantsFilters: actions.setParticipantsFilters,
        closeSidebar: actions.sideNavClose,
        setActiveWall: actions.setActiveWall,
        seeInfoTab: actions.sideNavSeeInfoTab,
        seeSurvey: actions.sideNavSeeSurvey,
        onGetGroupsList: actions.getGroupsList,
        onGetUserMatches: actions.getUserMatches,
        onRecalculateUserMatches: actions.recalculateUserMatches,
        onGetEventFilters: actions.getEventFilters,
    }
)(Networking);
