export const GET_ORGANIZERS_START = 'ADMIN/GET_ORGANIZERS_START';
export const GET_ORGANIZERS_SUCCESS = 'ADMIN/GET_ORGANIZERS_SUCCESS';
export const GET_ORGANIZERS_FAIL = 'ADMIN/GET_ORGANIZERS_FAIL';

export const CHANGE_ORGANIZERS_LIST_PAGE = 'ADMIN/CHANGE_ORGANIZERS_LIST_PAGE';
export const CHANGE_EVENTS_LIST_PAGE = 'ADMIN/CHANGE_EVENTS_LIST_PAGE';

export const CHANGE_ORGANIZER_STATUS_REQUEST = 'ADMIN/CHANGE_ORGANIZER_STATUS_REQUEST';
export const CHANGE_ORGANIZER_STATUS_SUCCESS = 'ADMIN/CHANGE_ORGANIZER_STATUS_SUCCESS';
export const CHANGE_ORGANIZER_STATUS_FAIL = 'ADMIN/CHANGE_ORGANIZER_STATUS_FAIL';

export const DELETE_ORGANIZER_START = 'ADMIN/DELETE_ORGANIZER_START';
export const DELETE_ORGANIZER_SUCCESS = 'ADMIN/DELETE_ORGANIZER_SUCCESS';
export const DELETE_ORGANIZER_FAIL = 'ADMIN/DELETE_ORGANIZER_FAIL';

export const GET_ONLINE_USERS_ADMIN_START = 'ADMIN/GET_ONLINE_USERS_ADMIN_START';
export const GET_ONLINE_USERS_ADMIN_SUCCESS = 'ADMIN/GET_ONLINE_USERS_ADMIN_SUCCESS';
export const GET_ONLINE_USERS_ADMIN_FAIL = 'ADMIN/GET_ONLINE_USERS_ADMIN_FAIL';
