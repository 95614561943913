import {Button, withStyles} from '@material-ui/core';

const CustomDialogButton = withStyles((theme) => ({
    root: {
        fontFamily: 'Montserrat',
        fontSize: '16px',
        fontWeight: '700',
        '&.MuiButton-text': {
            color: theme.palette.text.primary,
        },
        '&.Mui-disabled': {
            color: theme.palette.disabled.main,
        },
    },
}))(Button);

export default CustomDialogButton;
