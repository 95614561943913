import React from 'react';
import {Route} from 'react-router-dom';

import EventPublicComponent from '../EventPublicComponent';

const EventPublicRoute = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        render={(props) => {
            return (
                <EventPublicComponent {...props}>
                    <Component {...props} />
                </EventPublicComponent>
            );
        }}
    />
);

export default EventPublicRoute;
