import React from 'react';

export const EnglishContent = () => (
    <div className="header-spacing-container">
        <div className="gdpr-container">
            <h1>Cookies Policy</h1>
            <h4>Last updated: 24.07.2020</h4>

            <p>
                Event10X (“us”, “we”, or “our”) uses cookies on https://www.event10x.com (the “Service”). By using the
                Service, you consent to the use of cookies.
            </p>
            <p>
                Our Cookies Policy explains what cookies are, how we use cookies, how third-parties we may partner with
                may use cookies on the Service, your choices regarding cookies and further information about cookies.
            </p>

            <h2>What are cookies?</h2>
            <p>
                Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is
                stored in your web browser and allows the Service or a third-party to recognize you and make your next
                visit easier and the Service more useful to you.
            </p>
            <p>Cookies can be “persistent” or “session” cookies.</p>

            <h2>How Event10X uses cookies?</h2>
            <p>When you use and access the Service, we may place a number of cookies files in your web browser.</p>
            <p>
                We use cookies for the following purposes: to enable certain functions of the Service, to provide
                analytics, to store your preferences, to enable advertisements delivery, including behavioral
                advertising.
            </p>
            <p>
                We use both session and persistent cookies on the Service and we use different types of cookies to run
                the Service.
            </p>
            <p>
                Essential cookies. We may use essential cookies to authenticate users and prevent fraudulent use of user
                accounts.
            </p>

            <h3>We use the following types of cookies:</h3>
            <ul>
                <li>
                    <p>
                        <b>Performance and technical</b>
                    </p>
                    <p>
                        Cookies help us learn how well our sites work. We also use cookies to understand, improve, and
                        research products, features, and services, including to create logs and record when you access
                        our website from different devices, such as your work computer or notebook.
                    </p>
                    <p>
                        On some pages, our users can embed videos from YouTube.com. The videos available in Event10X
                        auditorium page will play automatically. In order to make this functionality available we use
                        Youtube third party cookies. YouTube won’t store information about our visitors, unless they
                        play the video or open auditorium page, according to the Google-Youtube guide:{' '}
                        <a href="https://support.google.com/youtube/answer/171780?hl=en-GB">
                            {' '}
                            https://support.google.com/youtube/answer/171780?hl=en-GB
                        </a>
                    </p>
                </li>
                <li>
                    <p>
                        <b>Statistics - Google Analytics</b>
                    </p>
                    <p>
                        Our website uses third party Google Analytics, a web analysis service of Google, Inc.
                        (“Google”). Learn more from their Terms of Service and Privacy Policy. You can opt-out here{' '}
                        <a href="https://tools.google.com/dlpage/gaoptout">https://tools.google.com/dlpage/gaoptout</a>
                    </p>
                </li>
            </ul>

            <h2>What are your choices regarding cookies?</h2>
            <p>
                If you’d like to delete cookies or instruct your web browser to delete or refuse cookies, please visit
                the help pages of your web browser.
            </p>
            <p>
                Please note, however, that if you delete cookies or refuse to accept them, you might not be able to use
                all of the features we offer, you may not be able to store your preferences, and some of our pages might
                not display properly.
            </p>
        </div>
    </div>
);
