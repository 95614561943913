import React from 'react';
import {Redirect} from 'react-router-dom';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {Button} from '@material-ui/core';

import PlatformMenu from '../PlatformMenu';

import HomepageBackground from '../../../Images/backgrounds/homepage-background.jpg';
import {Api} from '../../../Api';

export class PasswordRecovery extends React.Component {
    state = {
        token: '',
        password: '',
        passwordConfirmation: '',
        disabled: true,
    };

    componentDidMount() {
        // get the token from url, save it to state so that we can send it with the request
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token');

        this.setState({token: token});

        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
    }
    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleResetPassword = async () => {
        try {
            const {token} = await Api.request({
                method: 'post',
                url: '/auth/reset-password',
                payload: {password: this.state.password, token: this.state.token},
            });

            localStorage.setItem('token', token);
            this.props.onAutoLoginWithToken();
        } catch (error) {}
    };

    render() {
        return (
            <div>
                <PlatformMenu />
                {this.props.loggedIn ? <Redirect to={'/'} /> : null}
                <div className="homepage login-page register-participant password-recovery-page">
                    <div className="event-container" style={{backgroundImage: `url('${HomepageBackground}')`}}>
                        <div className="homepage-overlay">
                            <div className="smallContainer">
                                <div className="single-event-container">
                                    <div className="right-form">
                                        <p>Please enter new password:</p>
                                        <div className="register-form-wrapper">
                                            <ValidatorForm ref="form" onSubmit={this.handleResetPassword}>
                                                <TextValidator
                                                    label="Password"
                                                    onChange={this.handleChange}
                                                    name="password"
                                                    value={this.state.password}
                                                    variant="outlined"
                                                    type="password"
                                                    fullWidth
                                                    validators={['required', 'minStringLength:8']}
                                                    errorMessages={[
                                                        'Field is required',
                                                        'Password must have min 8 characters',
                                                    ]}
                                                />
                                                <TextValidator
                                                    label="Confirm Password"
                                                    onChange={this.handleChange}
                                                    name="passwordConfirmation"
                                                    value={this.state.passwordConfirmation}
                                                    variant="outlined"
                                                    type="password"
                                                    fullWidth
                                                    validators={['isPasswordMatch', 'required', 'minStringLength:8']}
                                                    errorMessages={[
                                                        'Password mismatch',
                                                        'Field is required',
                                                        'Password must have min 8 characters',
                                                    ]}
                                                />
                                            </ValidatorForm>
                                            <Button
                                                onClick={this.handleResetPassword}
                                                color="primary"
                                                className="change-password-btn"
                                                disabled={this.state.disabled}
                                                variant="outlined"
                                            >
                                                Change password
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
