import SocialMediaShareEvent from './SocialMediaShareEvent';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import {connect} from 'react-redux';

export default connect(
    (state) => ({
        showSocialShareModal: selectors.getVisibilitySocialShareModal(state),
        eventId: selectors.getEventId(state),
        promotionData: selectors.getPromotionData(state),
    }),
    {
        onHideSocialShareModal: actions.hideSocialShareModal,
    }
)(SocialMediaShareEvent);
