import React from 'react';

const IconExport = () => (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.001 6H10.001V0H4.00098V6H0.000976562L7.00098 13L14.001 6ZM0.000976562 15V17H14.001V15H0.000976562Z"
            fill="#2B2B2B"
        />
    </svg>
);

export default IconExport;
