export const initialState = {
    loading: false,
    toggleEventListing: false,
    eventListingData: null,
    allListedEvents: null,
    savedEventsList: null,
    firstFutureEventsForHomePage: null,
    pagination: {
        page: 1,
        pages: 1,
    },
    eventsDates: null,
    eventsFilters: null,
    searchText: '',
    showPastEvents: false,
    preSaveEvent: null,
};
