import {useSelector} from 'react-redux';
import * as selectors from 'store/selectors';

const useCompletedMatchmaking = () => {
    const eventId = useSelector(selectors.getEventId);
    const user = useSelector(selectors.getUserData);

    const completeMatching = user.eventRoles.find((eventRole) => eventRole.event._id === eventId)?.completeMatching;

    return !!completeMatching;
};

export default useCompletedMatchmaking;
