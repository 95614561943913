import {nanoid} from 'nanoid';

import * as actionTypes from '../actionTypes/timeslotActionTypes';
import {updateObject} from '../utilityStateFunction';
import {INTERPRETATION_LANGUAGES, DEFAULT_INTERPRETATION_LANGUAGE} from 'constants/shared';
import {formEmptyPoll} from 'constants/timeslot';
import {deserializePoll, deserializePollList} from 'services/utils/normalizers';

const initialState = {
    loading: false,
    data: null,
    etxSessionToken: null,
    recordedZoomSessions: null,
    interpretation: {
        activeLanguage: DEFAULT_INTERPRETATION_LANGUAGE,
        default: DEFAULT_INTERPRETATION_LANGUAGE,
        [INTERPRETATION_LANGUAGES.ARABIC]: false,
        [INTERPRETATION_LANGUAGES.CHINESE]: false,
        [INTERPRETATION_LANGUAGES.ENGLISH]: false,
        [INTERPRETATION_LANGUAGES.FRENCH]: false,
        [INTERPRETATION_LANGUAGES.RUSSIAN]: false,
        [INTERPRETATION_LANGUAGES.SPANISH]: false
    },
    allowedUsers: [],
    polls: []
};

const timeslotReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.GET_EVENT_USERS_LIST_EXCLUDED_ROLES_START:
        case actionTypes.GET_TIMESLOT_DATA_REQUEST:
        case actionTypes.SESSION_STREAM_START_REQUEST:
        case actionTypes.SESSION_STREAM_STOP_REQUEST:
        case actionTypes.SESSION_STREAM_ALLOCATE_RESOURCES_REQUEST:
        case actionTypes.GET_TIMESLOT_POLLS_REQUEST:
        case actionTypes.SAVE_POLL_START:
        case actionTypes.DELETE_POLL_START: {
            return updateObject(state, {
                loading: true
            });
        }

        case actionTypes.GET_EVENT_USERS_LIST_EXCLUDED_ROLES_FAIL:
        case actionTypes.SESSION_STREAM_START_FAIL:
        case actionTypes.SESSION_STREAM_STOP_FAIL:
        case actionTypes.SESSION_STREAM_ALLOCATE_RESOURCES_FAIL:
        case actionTypes.GET_TIMESLOT_POLLS_FAIL:
        case actionTypes.SAVE_POLL_FAIL:
        case actionTypes.DELETE_POLL_FAIL: {
            return updateObject(state, {
                loading: false
            });
        }
        case actionTypes.UPDATE_TIMESLOT_DATA: {
            return updateObject(state, {
                loading: false,
                data: updateObject(state.data, {
                    ...payload
                })
            });
        }
        case actionTypes.SESSION_STREAM_START_SUCCESS:
        case actionTypes.SESSION_STREAM_STOP_SUCCESS:
        case actionTypes.SESSION_STREAM_ALLOCATE_RESOURCES_SUCCESS: {
            return updateObject(state, {
                data: payload,
                loading: false
            });
        }
        case actionTypes.GET_TIMESLOT_DATA_SUCCESS: {
            const activeInterpreters = payload.interpreters.reduce((acc, item) => {
                if (item.streamPublished) {
                    acc[item.interpretationLanguage] = true;
                }

                return acc;
            }, {});

            return updateObject(state, {
                data: payload,
                loading: false,
                interpretation: updateObject(state.interpretation, {
                    ...activeInterpreters
                })
            });
        }
        case actionTypes.GET_ZOOM_STREAM_RECORDINGS_SUCCESS: {
            return updateObject(state, {
                recordedZoomSessions: payload,
                loading: false
            });
        }
        case actionTypes.GET_AUTH_TOKEN_FOR_VIDEO_ENGINE_SUCCESS: {
            return updateObject(state, {
                etxSessionToken: payload
            });
        }
        case actionTypes.SET_AVAILABLE_INTERPRETATION_LANGUAGE: {
            return updateObject(state, {
                interpretation: updateObject(state.interpretation, {
                    [payload]: true
                })
            });
        }
        case actionTypes.REMOVE_AVAILABLE_INTERPRETATION_LANGUAGE: {
            return updateObject(state, {
                interpretation: updateObject(state.interpretation, {
                    [payload]: false
                })
            });
        }
        case actionTypes.SET_ACTIVE_INTERPRETATION_LANGUAGE: {
            return updateObject(state, {
                interpretation: updateObject(state.interpretation, {
                    activeLanguage: payload
                })
            });
        }

        case actionTypes.GET_EVENT_USERS_LIST_EXCLUDED_ROLES_SUCCESS: {
            return updateObject(state, {
                allowedUsers: payload,
                loading: false
            });
        }

        case actionTypes.GET_TIMESLOT_POLLS_SUCCESS: {
            const {polls, toDeserialize} = payload;
            return updateObject(state, {
                polls: toDeserialize ? deserializePollList(polls) : polls,
                loading: false
            });
        }
        case actionTypes.CREATE_EMPTY_POLL: {
            return updateObject(state, {
                polls: [...state.polls, formEmptyPoll(nanoid())]
            });
        }
        case actionTypes.DELETE_POLL_SUCCESS: {
            return updateObject(state, {
                polls: state.polls.filter((poll) => poll.id !== payload),
                loading: false
            });
        }
        case actionTypes.CHANGE_POLL_VISIBILITY: {
            return updateObject(state, {
                polls: state.polls.map((poll) => ({
                    ...poll,
                    ...(poll.id === payload && {isSaved: false, isHidden: !poll.isHidden})
                }))
            });
        }
        case actionTypes.CLOSE_POLL: {
            return updateObject(state, {
                polls: state.polls.map((poll) => ({
                    ...poll,
                    ...(poll.id === payload && {isSaved: false, isFinished: !poll.isFinished})
                }))
            });
        }
        case actionTypes.TOGGLE_POLL_MULTISELECT: {
            return updateObject(state, {
                polls: state.polls.map((poll) => ({
                    ...poll,
                    ...(poll.id === payload && {isSaved: false, multiSelect: !poll.multiSelect})
                }))
            });
        }
        case actionTypes.TOGGLE_POLL_RESULTS_VISIBILITY: {
            return updateObject(state, {
                polls: state.polls.map((poll) => ({
                    ...poll,
                    ...(poll.id === payload && {isSaved: false, hideResults: !poll.hideResults})
                }))
            });
        }
        case actionTypes.CHANGE_POLL_OPTION_TITLE: {
            const {pollId, optionId, title} = payload;
            const updatedPolls = state.polls.map((poll) =>
                poll.id === pollId
                    ? {
                          ...poll,
                          isSaved: false,
                          optionList: poll.optionList.map((option) =>
                              option.id === optionId ? {...option, title} : option
                          )
                      }
                    : poll
            );

            return updateObject(state, {
                polls: updatedPolls
            });
        }
        case actionTypes.CHANGE_POLL_TITLE: {
            const {pollId, title} = payload;
            return updateObject(state, {
                polls: state.polls.map((poll) => ({
                    ...poll,
                    ...(poll.id === pollId && {isSaved: false, title})
                }))
            });
        }
        case actionTypes.SAVE_POLL_SUCCESS: {
            const {pollId, updatedPoll} = payload;
            return updateObject(state, {
                polls: state.polls.map((poll) => (poll.id === pollId ? updatedPoll : poll)),
                loading: false
            });
        }
        case actionTypes.ADD_NEW_POLL_OPTION: {
            const updatedPolls = state.polls.map((poll) =>
                poll.id === payload
                    ? {
                          ...poll,
                          optionList: [...poll.optionList, {id: nanoid(), title: ''}]
                      }
                    : poll
            );

            return updateObject(state, {
                polls: updatedPolls
            });
        }
        case actionTypes.REMOVE_POLL_OPTION: {
            const {pollId, optionId} = payload;
            const updatedPolls = state.polls.map((poll) =>
                poll.id === pollId
                    ? {
                          ...poll,
                          isSaved: false,
                          optionList: poll.optionList.filter((option) => option.id !== optionId)
                      }
                    : poll
            );

            return updateObject(state, {
                polls: updatedPolls
            });
        }
        case actionTypes.SOCKET_POLL_CREATE: {
            let needDeserializePoll = false;
            const isExisting = state.polls.find((poll) => {
                if (poll.hasOwnProperty('id')) {
                    needDeserializePoll = true;
                    return poll.id === payload._id;
                }
                return poll._id === payload._id;
            });
            const poll = needDeserializePoll ? deserializePoll(payload) : payload;
            return updateObject(state, {
                polls: isExisting ? state.polls : [...state.polls, poll]
            });
        }
        case actionTypes.SOCKET_POLL_DELETE: {
            const newPolls = state.polls.filter((poll) => poll._id !== payload);
            return updateObject(state, {
                polls: newPolls
            });
        }
        case actionTypes.SOCKET_POLL_UPDATE: {
            const newPolls = state.polls.map((poll) => {
                if (poll.hasOwnProperty('id')) {
                    return poll.id === payload._id ? deserializePoll(payload) : poll;
                }
                return poll._id === payload._id ? payload : poll;
            });

            return updateObject(state, {
                polls: newPolls
            });
        }

        case actionTypes.RESET_TIMESLOT_DATA: {
            return updateObject(state, {
                data: null
            });
        }

        default:
            return state;
    }
};

export default timeslotReducer;
