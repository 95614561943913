import * as actionTypes from '../actionTypes/allParticipantsForJobsActionTypes';
import {Api} from 'Api';

export const getAllParticipants = () => async (dispatch, getState) => {
    const {
        event: {eventId},
    } = getState();

    dispatch(getAllParticipantsRequest());

    try {
        const {participants} = await Api.request({method: 'get', url: `/event/${eventId}/participants`});

        dispatch(getAllParticipantsSuccess(participants));
    } catch (error) {
        dispatch(getAllParticipantsFail());
    }
};

const getAllParticipantsRequest = () => ({
    type: actionTypes.GET_ALL_PARTICIPANTS_REQUEST,
});

const getAllParticipantsSuccess = (payload) => ({
    type: actionTypes.GET_ALL_PARTICIPANTS_SUCCESS,
    payload,
});

const getAllParticipantsFail = () => ({
    type: actionTypes.GET_ALL_PARTICIPANTS_FAIL,
});
