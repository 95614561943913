import {useSelector} from 'react-redux';
import {getEventData, getExhibitorsList, getUserData, getUsersList} from 'store/selectors';
import {formEventLevelUserIdList, formPlatformLevelUserIdList} from 'services/helpers';

const useUniqRelatedUserIdList = () => {
    const user = useSelector(getUserData);
    const event = useSelector(getEventData);
    const eventUsers = useSelector(getUsersList);
    const exhibitors = useSelector(getExhibitorsList);
    const userId = user?._id;

    const platformLevelUserIdList = formPlatformLevelUserIdList(user, userId);
    const eventLevelUserIdList = formEventLevelUserIdList(event, eventUsers, exhibitors);
    const relatedUserIdList = [...platformLevelUserIdList, ...eventLevelUserIdList];
    const relatedUserIdListWithoutCurrentUserId = relatedUserIdList.filter((id) => id !== userId);
    const uniqRelatedUserIdList = [...new Set(relatedUserIdListWithoutCurrentUserId)];

    return uniqRelatedUserIdList;
};

export default useUniqRelatedUserIdList;
