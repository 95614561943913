import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Session from './components/Session';

import {PARTICIPANT_TYPES} from 'constants/organizer/sessions';
import {useParticipantSharedScreen} from 'services/hooks/organizer/sessions/useParticipantSharedScreen';

import {useStyles} from './VideoPlayerVideoRoom.styles';

export const VideoPlayerVideoRoom = ({sessions, client, dominantSpeakerOrder, isFullScreen}) => {
    const classes = useStyles();
    const isParticipantScreenShareEnabled = useParticipantSharedScreen();

    const activeStreamsCount = dominantSpeakerOrder.length || Object.values(sessions)?.length;

    if (!Object.values(sessions).length) return null;

    return (
        <div
            className={classNames(classes.playbacksList, {
                [classes.hidden]: !activeStreamsCount,
                [classes.isFullScreen]: isFullScreen
            })}
        >
            {client.role !== PARTICIPANT_TYPES.interpreter ? (
                Object.values(sessions).map((session) => (
                    <Session
                        key={session.userId}
                        session={session}
                        activeStreamsCount={isParticipantScreenShareEnabled ? 1 : activeStreamsCount}
                        isParticipantScreenShareEnabled={isParticipantScreenShareEnabled}
                    />
                ))
            ) : (
                <Session session={sessions} activeStreamsCount={1} />
            )}
        </div>
    );
};

VideoPlayerVideoRoom.propTypes = {
    sessions: PropTypes.object,
    client: PropTypes.object,
    dominantSpeakerOrder: PropTypes.arrayOf(PropTypes.object),
    isFullScreen: PropTypes.bool
};

VideoPlayerVideoRoom.defaultProps = {
    sessions: {},
    client: {}
};
