import React from 'react';
import {ReactComponent as Loader} from 'Images/matching-loader.svg';
import './MatchingCalculationLoader.scss';

const MatchingCalculationLoader = () => {
    return (
        <div className="matching-calculation-loader">
            <Loader />
            <p className="matching-calculation-loader__text">
                Matching algorithm in action. Give us a minute while we are curating your best matches!
            </p>
        </div>
    );
};

export default MatchingCalculationLoader;
