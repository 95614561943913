import React, {useCallback, useContext} from 'react';
import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {Stop as StopIcon, PlayArrow as PlayArrowIcon} from '@material-ui/icons';
import {AbilityContext} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

export const StreamControls = ({
    timeslot,
    isAudioOff,
    mediaSession,
    sessionStreamStart,
    sessionStreamStop,
    toggleSelfAudio,
}) => {
    const {auditoriumId} = useParams();
    const ability = useContext(AbilityContext);

    const handleAuditoriumStream = useCallback(
        () => (timeslot?.streamStarted ? sessionStreamStop(auditoriumId) : sessionStreamStart(auditoriumId)),
        [auditoriumId, sessionStreamStart, sessionStreamStop, timeslot]
    );

    const handleInterpretationStream = useCallback(() => {
        mediaSession._audioProducer.track.enabled = isAudioOff;
        toggleSelfAudio();
    }, [isAudioOff, mediaSession, toggleSelfAudio]);

    const isInterpreterAccess = ability.can(
        ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY,
        ROLE_FEATURES_TYPES.INTERPRETER_AUDIO_STREAM_BUTTONS
    );

    const controls = isInterpreterAccess ? (
        <button type="button" className="button" onClick={handleInterpretationStream}>
            {isAudioOff ? <PlayArrowIcon className="button_icon" /> : <StopIcon className="button_icon" />}
            <span>{isAudioOff ? 'START' : 'STOP'} INTERPRETATION</span>
        </button>
    ) : (
        <button type="button" className="button" onClick={handleAuditoriumStream}>
            {timeslot?.streamStarted ? <StopIcon className="button_icon" /> : <PlayArrowIcon className="button_icon" />}
            <span>{timeslot?.streamStarted ? 'STOP' : 'START'} STREAM</span>
        </button>
    );

    return <div className="buttons_group">{controls}</div>;
};

StreamControls.propTypes = {
    timeslot: PropTypes.object,
    isAudioOff: PropTypes.bool,
    mediaSession: PropTypes.object,
    sessionStreamStart: PropTypes.func,
    sessionStreamStop: PropTypes.func,
    toggleSelfAudio: PropTypes.func,
};
