import React from 'react';
import {Link} from 'react-router-dom';
import {MenuItem, Select} from '@material-ui/core';

import EditSectionContainer from '../../../../../EditSectionContainer';
import ConfirmDialog from '../../../../../../../ConfirmDialog/ConfirmDialog';
import DeleteMyAccount from '../DeleteMyAccountDialog/DeleteMyAccountDialog';
import EditUserInformationMobileDialog from './components/EditUserInformationMobileDialog/EditUserInformationMobileDialog';
import EditExhibitorContactPersonInformationMobileDialog from './components/EditExhibitorContactPersonInformationMobileDialog/EditExhibitorContactPersonInformationMobileDialog';
import EditParticipantInformationMobileDialog from './components/EditParticipantInformationMobileDialog/EditParticipantInformationMobileDialog';
import ChangePasswordMobileDialog from './components/ChangePasswordMobileDialog/ChangePasswordMobileDialog';

import ArrowRightIcon from 'Images/arrow_right.svg';
import {ReactComponent as EditIcon} from 'Images/edit-image.svg';

import {Api} from 'Api';
import {AbilityContext} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';
import {allTranslationList} from 'constants/translations/all-translations';

import './MyAccountMobile.scss';

class MyAccountMobile extends React.Component {
    state = {
        editUserDialog: false,
        editParticipantDialog: false,
        editExhibitorContactPersonDialog: false,
        changeUserPasswordDialog: false,
        deleteMyAccountDialog: false,
        unregisterFromEventDialog: false,
        changePasswordServerMessage: '',
        imgErrorText: '',
        canUnregisterEvent: true,
        registrationType: ''
    };

    componentDidMount() {
        const {getEventProfile} = this.props;
        const ability = this.context;

        const eventProfileAccess = ability.can(
            ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY,
            ROLE_FEATURES_TYPES.EVENT_PROFILE
        );

        this.getRegistrationType();

        if (eventProfileAccess) getEventProfile();
        this.checkCanUnregisterEvent();
        this._handleSeeOnventProfile();
    }

    checkCanUnregisterEvent = () => {
        const ability = this.context;
        if (ability.cannot(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.UNREGISTER_EVENT)) {
            this.setState({canUnregisterEvent: false});
        }
    };

    handleOpenEditUserDialog = () => {
        this.setState({editUserDialog: true});
    };

    handleOpenEditEventProfileDialog = () => {
        const {eventProfile} = this.props;
        const ability = this.context;
        const exhibitorAccess = ability.can(
            ROLE_ABILITIES_TYPES.EXHIBITOR.SET_USER_INFO,
            ROLE_FEATURES_TYPES.ACCOUNT_PROFILE_INFO
        );

        if (exhibitorAccess && eventProfile) {
            this.handleOpenEditExhibitorContactPersonDialog();
        } else if (!exhibitorAccess && eventProfile) {
            this.handleOpenEditParticipantDialog();
        }
    };

    handleOpenEditParticipantDialog = () => {
        this.setState({editParticipantDialog: true});
    };

    handleOpenEditExhibitorContactPersonDialog = () => {
        this.setState({editExhibitorContactPersonDialog: true});
    };

    handleChangePassword = () => {
        this.setState({changeUserPasswordDialog: true});
    };

    handleDeleteUser = () => {
        this.setState({deleteMyAccountDialog: true});
    };

    handleUnregisterFromEvent = () => {
        this.setState({
            unregisterFromEventDialog: true
        });
    };

    handleCloseDialogUnregisterFromEvent = () => {
        this.setState({
            unregisterFromEventDialog: false
        });
    };

    handleCloseDialog = (serverMessage) => {
        let message = '';
        if (serverMessage) {
            message = serverMessage;
        }
        this.setState({
            editUserDialog: false,
            deleteMyAccountDialog: false,
            editParticipantDialog: false,
            editExhibitorContactPersonDialog: false,
            changeUserPasswordDialog: false,
            unregisterFromEventDialog: false,
            changePasswordServerMessage: message
        });
    };

    handleRemoveUserAvatar = async () => {
        try {
            await Api.request({
                method: 'delete',
                url: '/users/me/avatar'
            });

            this.props.onRefreshUserUserData();
        } catch (error) {}
    };

    unregisterFromEvent = async () => {
        const {eventSlug, eventId, user, onRefreshUserUserData, history} = this.props;

        try {
            await Api.request({
                method: 'delete',
                url: `/event/${eventId}/unregister-user/${user._id}`
            });

            onRefreshUserUserData().then((response) => {
                history.push(`/event/${eventSlug}`);
            });
        } catch (error) {}
    };

    handleImageChange = async (e) => {
        e.preventDefault();
        let file = e.target.files[0];

        let isValid = true;

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        if (!isValid) {
            this.setState({imgErrorText: 'File too large. 2Mb max file size.'});
        }
        isValid = (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') && isValid;
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            this.setState({
                imgErrorText: this.props.translation?.errors.imageFileNotSupported
            });
        }

        if (isValid) {
            this.setState({imgErrorText: ''});
            const formData = new FormData();

            formData.append('avatar', file, file.name, file.type);

            try {
                await Api.request({
                    method: 'post',
                    url: '/users/me/avatar',
                    payload: formData
                });

                this.props.onRefreshUserUserData();
            } catch (error) {
                this.props.onRefreshUserUserData();
            }
        }
    };

    getRegistrationType = async () => {
        try {
            const {registrationType} = await Api.request({
                method: 'get',
                url: `/users/get-registration-type`
            });

            this.setState({registrationType});
        } catch (error) {}
    };

    handleChangeUserLanguage = async (e) => {
        const defaultLanguage = e.target.value;

        try {
            await Api.request({
                method: 'put',
                url: '/users',
                payload: {language: defaultLanguage}
            });

            this.props.onRefreshUserUserData();
        } catch (error) {}
    };

    _handleSeeOnventProfile = () => {
        this.props.seeOnventProfile();
    };

    _handleSeeEventProfile = () => {
        this.props.seeEventProfile();
    };

    render() {
        const {user, event, profileTabs, translation, platformLanguage, eventProfile: eventProfileData} = this.props;
        const {
            editExhibitorContactPersonDialog,
            editParticipantDialog,
            unregisterFromEventDialog,
            changeUserPasswordDialog,
            deleteMyAccountDialog,
            editUserDialog
        } = this.state;
        let accessPackageData = null;
        const ability = this.context;
        const exhibitorAccess = ability.can(
            ROLE_ABILITIES_TYPES.EXHIBITOR.SET_USER_INFO,
            ROLE_FEATURES_TYPES.ACCOUNT_PROFILE_INFO
        );
        const isEventProfileShown = ability.cannot(
            ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY,
            ROLE_FEATURES_TYPES.EVENT_PROFILE
        );

        if (event.paidEvent) {
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === event.slug);
            const userEventAccessPackageId = exhibitorAccess
                ? userRolesInEvent.exhibitorAccessPackage
                : userRolesInEvent.accessPackage;
            accessPackageData = (exhibitorAccess ? event.exhibitorAccessPackages : event.accessPackages).find(
                (accessPackage) => accessPackage._id === userEventAccessPackageId
            );
        }

        return (
            <>
                <div className="tabs-container">
                    <div>
                        <div
                            className={`tab ${profileTabs.seeOnventProfile ? 'active' : ''}`}
                            onClick={this._handleSeeOnventProfile}
                        >
                            <h3>Platform profile</h3>
                        </div>
                        {event.owner._id !== user._id && (
                            <div
                                className={`tab ${profileTabs.seeEventProfile ? 'active' : ''}`}
                                onClick={this._handleSeeEventProfile}
                            >
                                <h3>Event profile</h3>
                            </div>
                        )}
                    </div>
                </div>
                {profileTabs.seeOnventProfile ? (
                    <EditSectionContainer className="onvent-profile">
                        <div>
                            <div className="profile-list">
                                <div>
                                    <div
                                        className="avatar"
                                        style={{
                                            backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${this.props.user.avatarBig}')`
                                        }}
                                    ></div>
                                    <div className="user-details">
                                        <h3 className="my-account-mobile__user-name">
                                            {user.first} {user.last}
                                        </h3>
                                        <p>
                                            <span>e-mail: </span>
                                            {user.email}
                                        </p>
                                    </div>
                                    <div>
                                        <button onClick={this.handleOpenEditUserDialog}>
                                            <EditIcon className="primary-fill-svg" />
                                        </button>
                                    </div>
                                </div>
                                {(this.state.registrationType === '' || this.state.registrationType === 'email') && (
                                    <div onClick={this.handleChangePassword}>
                                        <h4>{translation?.changePassword.title}</h4>
                                        <img src={ArrowRightIcon} width="24" height="24" alt="Arrow right" />
                                    </div>
                                )}

                                <div onClick={this.handleDeleteUser}>
                                    <h4>{translation?.myAccountPage.deleteAccountButton}</h4>
                                    <img src={ArrowRightIcon} width="24" height="24" alt="Arrow right" />
                                </div>

                                <div>
                                    <h4>{translation?.myAccountPage.platformLanguage}</h4>
                                    <Select
                                        onChange={this.handleChangeUserLanguage}
                                        value={user.language}
                                        variant="outlined"
                                    >
                                        {allTranslationList.map(({slug, title}) => {
                                            return (
                                                <MenuItem key={slug} value={slug}>
                                                    {title}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className="user-profile-information">
                                <div>
                                    {this.state.changePasswordServerMessage ? (
                                        <p className="server-message">
                                            {this.state.changePasswordServerMessage
                                                ? this.state.changePasswordServerMessage
                                                : ''}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="gdpr-links">
                                <p>
                                    {translation?.myAccountPage.reviewPlatformGdprTextFirst}{' '}
                                    {translation?.generalText.eventUpperETX}{' '}
                                    {translation?.myAccountPage.reviewPlatformGdprTextSecond}:
                                </p>
                                <ul>
                                    <li>
                                        <Link to={{pathname: `/privacy-policy/${platformLanguage}`}} target={'_blank'}>
                                            {translation?.myAccountPage.privacyPolicyLink}
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={{pathname: `/cookies/${platformLanguage}`}} target={'_blank'}>
                                            Cookies
                                        </Link>
                                    </li>
                                    <li>
                                        <Link
                                            to={{pathname: `/terms-and-conditions/${platformLanguage}`}}
                                            target={'_blank'}
                                        >
                                            {translation?.myAccountPage.termsAndConditionsLink}
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </EditSectionContainer>
                ) : null}

                {event.owner._id !== user._id && profileTabs.seeEventProfile && (
                    <EditSectionContainer className="event-profile">
                        {isEventProfileShown && eventProfileData && (
                            <div className="edit-participant-info-container">
                                <button onClick={this.handleOpenEditEventProfileDialog}>
                                    <img src={EditIcon} width="24" height="24" alt="Edit" />
                                </button>
                                <ul>
                                    <li>
                                        <p className="profile-company-name">
                                            <span>Title: </span>
                                            {eventProfileData.title || '-'}
                                        </p>
                                    </li>
                                    <li>
                                        <p className="profile-company-name">
                                            <span>Company: </span>
                                            {eventProfileData.company || '-'}
                                        </p>
                                    </li>
                                    <li>
                                        <p>
                                            <span>Phone: </span>
                                            {eventProfileData.phone || '-'}
                                        </p>
                                    </li>
                                    {eventProfileData.socialMediaLinks.map((social) => {
                                        return (
                                            <li key={social._id}>
                                                <p>
                                                    <span>{social.platform}: </span>
                                                    {social.link}
                                                </p>
                                            </li>
                                        );
                                    })}
                                    {event.paidEvent && accessPackageData && (
                                        <li>
                                            <p>
                                                <span>Event Package: </span>
                                                {accessPackageData.name}
                                            </p>
                                        </li>
                                    )}
                                </ul>
                            </div>
                        )}
                        {this.state.canUnregisterEvent && (
                            <div onClick={this.handleUnregisterFromEvent} className="change-password-btn">
                                <h4>Unregister from event</h4>
                                <img src={ArrowRightIcon} width="24" height="24" alt="Arrow right" />
                            </div>
                        )}
                        <div className="gdpr-links">
                            <p>Review our Event Privacy Policy:</p>
                            <ul>
                                <li>
                                    <Link
                                        to={{pathname: `/event/${this.props.eventSlug}/event-privacy-policy`}}
                                        target={'_blank'}
                                    >
                                        Privacy notice / policy
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </EditSectionContainer>
                )}

                {editUserDialog && (
                    <EditUserInformationMobileDialog
                        opened={editUserDialog}
                        closeDialog={this.handleCloseDialog}
                        handleImageChange={this.handleImageChange}
                        handleRemoveUserAvatar={this.handleRemoveUserAvatar}
                        imgErrorText={this.state.imgErrorText}
                    />
                )}

                {deleteMyAccountDialog && (
                    <DeleteMyAccount opened={deleteMyAccountDialog} closeDialog={this.handleCloseDialog} mobile />
                )}

                {changeUserPasswordDialog && (
                    <ChangePasswordMobileDialog
                        opened={changeUserPasswordDialog}
                        closeDialog={this.handleCloseDialog}
                    />
                )}

                {!exhibitorAccess && editParticipantDialog && (
                    <EditParticipantInformationMobileDialog
                        opened={editParticipantDialog}
                        closeDialog={this.handleCloseDialog}
                        participant={eventProfileData}
                        event={event}
                    />
                )}

                {exhibitorAccess && editExhibitorContactPersonDialog && (
                    <EditExhibitorContactPersonInformationMobileDialog
                        opened={editExhibitorContactPersonDialog}
                        closeDialog={this.handleCloseDialog}
                        exhibitor={eventProfileData}
                        event={event}
                    />
                )}

                {unregisterFromEventDialog && (
                    <ConfirmDialog
                        open={unregisterFromEventDialog}
                        closeConfirm={this.handleCloseDialogUnregisterFromEvent}
                        dialogTitle={'Unregister from current event'}
                        dialogDescription={`Please confirm that you want to unregister from event '${this.props.event.name}'`}
                        handleConfirm={this.unregisterFromEvent}
                        dialogConfirmButtonLabel={'Confirm'}
                        mobile
                    />
                )}
            </>
        );
    }
}

MyAccountMobile.contextType = AbilityContext;

export default MyAccountMobile;
