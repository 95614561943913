import React from 'react';

import StartVideoChatTooltip from '../../../StartVideoChatTooltip/StartVideoChatTooltip';

import {ReactComponent as Send} from 'Images/sendMessage.svg';
import {ReactComponent as OpenConference} from 'Images/videoConferenceButton.svg';
import {PRIVATE_CHAT_TYPE} from 'store/actionTypes/userTypes';
import {checkIfIsOnline} from 'services/helpers';

class SendPrivateChatMessage extends React.PureComponent {
    constructor(props) {
        super(props);
        this.newMessageRef = React.createRef();
    }
    state = {
        newMessage: '',
        sendingMessage: false,
        translation: null,
    };

    componentDidMount() {
        const {isLargeScreen} = this.props;
        if (isLargeScreen) {
            this.newMessageRef.current.focus();
        }
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.activeChatRoomId !== prevProps.activeChatRoomId) {
            this.setState({newMessage: ''});
            this.newMessageRef.current.focus();
        }
        if (
            !prevProps.joinConference &&
            this.props.joinConference &&
            this.props.lastChatMessage !== "I've set up a Video Meeting room. Please click here to join it."
        ) {
            this.setState({newMessage: "I've set up a Video Meeting room. Please click here to join it."}, () =>
                this.onSendPrivateMessage()
            );
        }
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter' && this.state.newMessage.trim()) {
            this.onSendPrivateMessage(e);
        }
    };

    onSendPrivateMessage = (e) => {
        const {sendMessageInPrivateChat, activeChatRoomId} = this.props;
        const {newMessage} = this.state;

        if (e) {
            e.preventDefault();
        }

        this.setState({sendingMessage: true});
        if (!this.state.sendingMessage) {
            sendMessageInPrivateChat(activeChatRoomId, newMessage, () => {
                this.setState({newMessage: '', sendingMessage: false});
                if (this.newMessageRef.current) {
                    this.newMessageRef.current.focus();
                }

                // to check if the chat was previously archived
                // case: archive -> delete -> send message -> should be unarchived
                const privateChat = this.props.privateChats.find(
                    (chat) => chat.chat._id === this.props.activeChatRoomId
                );
                if (privateChat.archived) {
                    return this.props.onArchiveChat(PRIVATE_CHAT_TYPE, this.props.activeChatRoomId, true);
                }

                // in order to get updated list of event chats if it is new chat
                const chatExists = this.props.eventChats?.some((chat) => chat._id === this.props.activeChatRoomId);

                if (!chatExists) {
                    this.props.onUpdateActiveChatTab({eventChats: this.props.eventChatsTab}, this.props.eventId);
                }
            });
        }
    };

    render() {
        const {translation} = this.state;
        const {activeChatData, onlineUsers, incomingCallUserId} = this.props;
        const userId = activeChatData?.privateChatPartnerData?._id;
        const disableToCall = !(checkIfIsOnline(onlineUsers, userId) || incomingCallUserId === userId);

        return (
            <form autoComplete={'off'} className="chat-message-form" onSubmit={this.onSendPrivateMessage}>
                <div className="new-comment-container">
                    <input
                        id="newMessage"
                        placeholder={translation?.chatsDropdown.newMessageInput}
                        value={this.state.newMessage}
                        onChange={this.handleChange('newMessage')}
                        onKeyDown={this.handleKeyDown}
                        ref={this.newMessageRef}
                    />
                    {this.state.newMessage ? (
                        <button
                            onClick={this.onSendPrivateMessage}
                            disabled={!this.state.newMessage.trim() || this.state.sendingMessage}
                            type="button"
                        >
                            <Send className="primary-fill-svg" />
                        </button>
                    ) : (
                        <button
                            onClick={this.props.startConference}
                            className="video-conference-button primary-fill-svg"
                            type="button"
                            disabled={disableToCall}
                        >
                            <OpenConference />
                        </button>
                    )}
                    <StartVideoChatTooltip newMessage={this.state.newMessage} isUserOffline={disableToCall} />
                </div>
            </form>
        );
    }
}

export default SendPrivateChatMessage;
