export const GET_EVENT_START = 'EVENT/GET_EVENT_START';
export const GET_EVENT_SUCCESS = 'EVENT/GET_EVENT_SUCCESS';
export const GET_EVENT_FAIL = 'EVENT/GET_EVENT_FAIL';

export const CREATE_EVENT_START = 'EVENT/CREATE_EVENT_START';
export const CREATE_EVENT_SUCCESS = 'EVENT/CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAIL = 'EVENT/CREATE_EVENT_FAIL';

export const UPDATE_EVENT_START = 'UPDATE_EVENT_START';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAIL = 'UPDATE_EVENT_FAIL';

export const UPDATE_EVENT_DATA = 'EVENT/UPDATE_EVENT_DATA';

export const DELETE_EVENT_REQUEST = 'EVENT/DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'EVENT/DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAIL = 'EVENT/DELETE_EVENT_FAIL';

export const SET_EVENT_ID = 'EVENT/SET_EVENT_ID';
export const SET_EVENT_SLUG = 'EVENT/SET_EVENT_SLUG';

export const SET_EVENT_ID_BY_SLUG_START = 'EVENT/SET_EVENT_ID_BY_SLUG_START';
export const SET_EVENT_ID_BY_SLUG_SUCCESS = 'EVENT/SET_EVENT_ID_BY_SLUG_SUCCESS';
export const SET_EVENT_ID_BY_SLUG_FAIL = 'EVENT/SET_EVENT_ID_BY_SLUG_FAIL';
export const SET_EVENT_NOT_FOUND = 'EVENT/SET_EVENT_NOT_FOUND';

export const REGISTER_PARTICIPANT_START = 'EVENT/REGISTER_PARTICIPANT_START';
export const REGISTER_PARTICIPANT_SUCCESS = 'EVENT/REGISTER_PARTICIPANT_SUCCESS';
export const REGISTER_PARTICIPANT_FAIL = 'EVENT/REGISTER_PARTICIPANT_FAIL';

export const REGISTER_EXHIBITOR_START = 'EVENT/REGISTER_EXHIBITOR_START';
export const REGISTER_EXHIBITOR_SUCCESS = 'EVENT/REGISTER_EXHIBITOR_SUCCESS';
export const REGISTER_EXHIBITOR_FAIL = 'EVENT/REGISTER_EXHIBITOR_FAIL';

export const GET_ONLINE_USERS_START = 'EVENT/GET_ONLINE_USERS_START';
export const GET_ONLINE_USERS_SUCCESS = 'EVENT/GET_ONLINE_USERS_SUCCESS';
export const GET_ONLINE_USERS_FAIL = 'EVENT/GET_ONLINE_USERS_FAIL';

export const UPDATE_ONLINE_USERS = 'EVENT/UPDATE_ONLINE_USERS';

export const LIKE_POST_START = 'EVENT/LIKE_POST_START';
export const LIKE_POST_SUCCESS = 'EVENT/LIKE_POST_SUCCESS';
export const LIKE_POST_FAIL = 'EVENT/LIKE_POST_FAIL';

export const UNLIKE_POST_START = 'EVENT/UNLIKE_POST_START';
export const UNLIKE_POST_SUCCESS = 'EVENT/UNLIKE_POST_SUCCESS';
export const UNLIKE_POST_FAIL = 'EVENT/UNLIKE_POST_FAIL';

export const GET_EVENT_INFO_START = 'EVENT/GET_EVENT_INFO_START';
export const GET_EVENT_INFO_SUCCESS = 'EVENT/GET_EVENT_INFO_SUCCESS';
export const GET_EVENT_INFO_FAIL = 'EVENT/GET_EVENT_INFO_FAIL';

export const GET_EVENT_USER_REGISTRATION_START = 'GET_EVENT_USER_REGISTRATION_START';
export const GET_EVENT_USER_REGISTRATION_SUCCESS = 'GET_EVENT_USER_REGISTRATION_SUCCESS';
export const GET_EVENT_USER_REGISTRATION_FAIL = 'GET_EVENT_USER_REGISTRATION_FAIL';

export const SET_EVENT_SHORT_LINK = 'SET_EVENT_SHORT_LINK';
export const SET_EVENT_REG_PACKAGE = 'SET_EVENT_REG_PACKAGE';

export const SIDE_NAV_HAS_VIDEO_WALL = 'EVENT/SIDE_NAV_HAS_VIDEO_WALL';
export const SET_ACTIVE_WALL = 'EVENT/SET_ACTIVE_WALL';
export const CLOSE_ACTIVE_WALL = 'EVENT/CLOSE_ACTIVE_WALL';
export const SET_TIME_SLOT_VIDEO_WALL_ID = 'EVENT/SET_TIME_SLOT_VIDEO_WALL_ID';

export const NEW_FLASH_NOTIFICATION = 'EVENT/NEW_FLASH_NOTIFICATION';
export const CLOSE_FLASH_NOTIFICATION = 'EVENT/CLOSE_FLASH_NOTIFICATION';

export const PARTICIPANT_REGISTRATION_ACCESS_CHANGED = 'EVENT/PARTICIPANT_REGISTRATION_ACCESS_CHANGED';
export const USERS_LOGIN_ACCESS_CHANGED = 'EVENT/USERS_LOGIN_ACCESS_CHANGED';

export const ADD_CUSTOM_USERS_START = 'EVENT/ADD_CUSTOM_USERS_START';
export const ADD_CUSTOM_USERS_SUCCESS = 'EVENT/ADD_CUSTOM_USERS_SUCCESS';
export const ADD_CUSTOM_USERS_FAIL = 'EVENT/ADD_CUSTOM_USERS_FAIL';

export const ADD_SPEAKER_START = 'EVENT/ADD_SPEAKER_START';
export const ADD_SPEAKER_SUCCESS = 'EVENT/ADD_SPEAKER_SUCCESS';
export const ADD_SPEAKER_FAIL = 'EVENT/ADD_SPEAKER_FAIL';

export const GET_CUSTOM_USERS_START = 'EVENT/GET_CUSTOM_USERS_START';
export const GET_CUSTOM_USERS_SUCCESS = 'EVENT/GET_CUSTOM_USERS_SUCCESS';
export const GET_CUSTOM_USERS_FAIL = 'EVENT/GET_CUSTOM_USERS_FAIL';

export const DELETE_CUSTOM_USERS_START = 'EVENT/DELETE_CUSTOM_USERS_START';
export const DELETE_CUSTOM_USERS_SUCCESS = 'EVENT/DELETE_CUSTOM_USERS_SUCCESS';
export const DELETE_CUSTOM_USERS_FAIL = 'EVENT/DELETE_CUSTOM_USERS_FAIL';

export const ADD_TIME_SLOT_START = 'EVENT/ADD_TIME_SLOT_START';
export const ADD_TIME_SLOT_SUCCESS = 'EVENT/ADD_TIME_SLOT_SUCCESS';
export const ADD_TIME_SLOT_FAIL = 'EVENT/ADD_TIME_SLOT_FAIL';

export const CREATE_TIME_SLOT_START = 'CREATE_TIME_SLOT_START';
export const CREATE_TIME_SLOT_SUCCESS = 'CREATE_TIME_SLOT_SUCCESS';
export const CREATE_TIME_SLOT_FAIL = 'CREATE_TIME_SLOT_FAIL';

export const ADD_TIME_SLOT_PROGRAM_START = 'ADD_TIME_SLOT_PROGRAM_START';
export const ADD_TIME_SLOT_PROGRAM_SUCCESS = 'ADD_TIME_SLOT_PROGRAM_SUCCESS';
export const ADD_TIME_SLOT_PROGRAM_FAIL = 'ADD_TIME_SLOT_PROGRAM_FAIL';

export const REMOVE_SPEAKER_START = 'EVENT/REMOVE_SPEAKER_START';
export const REMOVE_SPEAKER_SUCCESS = 'EVENT/REMOVE_SPEAKER_SUCCESS';
export const REMOVE_SPEAKER_FAIL = 'EVENT/REMOVE_SPEAKER_FAIL';

export const SET_EDITED_BUILDING = 'EVENT/SET_EDITED_BUILDING';
export const SET_EDITED_BUILDING_ID = 'EVENT/SET_EDITED_BUILDING_ID';
export const SET_EDITED_BUILDING_TAB = 'EVENT/SET_EDITED_BUILDING_TAB';

export const SET_ORGANIZER_BRANDING_LANGUAGE = 'EVENT/SET_ORGANIZER_BRANDING_LANGUAGE';

export const GET_EVENT_USERS_LIST_REQUEST = 'EVENT/GET_EVENT_USERS_LIST_REQUEST';
export const GET_EVENT_USERS_LIST_SUCCESS = 'EVENT/GET_EVENT_USERS_LIST_SUCCESS';
export const GET_EVENT_USERS_LIST_FAIL = 'EVENT/GET_EVENT_USERS_LIST_FAIL';

export const GET_EVENT_PROFILE_REQUEST = 'EVENT/GET_EVENT_PROFILE_REQUEST';
export const GET_EVENT_PROFILE_SUCCESS = 'EVENT/GET_EVENT_PROFILE_SUCCESS';
export const GET_EVENT_PROFILE_FAIL = 'EVENT/GET_EVENT_PROFILE_FAIL';

export const SET_USER_INITIAL_EVENT_ID = 'EVENT/SET_USER_INITIAL_EVENT_ID';

export const SET_UPDATED_AUDTITORIUM_IN_EVENT = 'SET_UPDATED_AUDTITORIUM_IN_EVENT';
export const SET_UPDATED_AUDTITORIUM_IN_EVENT_HUB = 'SET_UPDATED_AUDTITORIUM_IN_EVENT_HUB';

export const HIDE_SOCIAL_SHARE_MODAL = 'HIDE_SOCIAL_SHARE_MODAL';

export const SET_ENABELED_EVENT_MATCHMAKING_START = 'SET_ENABELED_EVENT_MATCHMAKING_START';
export const SET_ENABELED_EVENT_MATCHMAKING_SUCCESS = 'SET_ENABELED_EVENT_MATCHMAKING_SUCCESS';
export const SET_ENABELED_EVENT_MATCHMAKING_FAIL = 'SET_ENABELED_EVENT_MATCHMAKING_FAIL';

export const SOCET_SET_ENABELED_EVENT_MATCHMAKING = 'SOCET_SET_ENABELED_EVENT_MATCHMAKING';
export const SOCET_SET_EVENT_HAS_MATCHMAKING = 'SOCET_SET_EVENT_HAS_MATCHMAKING';

export const GET_EVENT_FILTERS_START = 'EVENT/GET_EVENT_FILTERS_START';
export const GET_EVENT_FILTERS_PARTICIPANT_SUCCESS = 'EVENT/GET_EVENT_FILTERS_PARTICIPANT_SUCCESS';
export const GET_EVENT_FILTERS_EXHIBITOR_SUCCESS = 'EVENT/GET_EVENT_FILTERS_EXHIBITOR_SUCCESS';
export const GET_EVENT_FILTERS_FAIL = 'EVENT/GET_EVENT_FILTERS_FAIL';

export const UPDATE_ACTIVE_CAMPAIGN_INTEGRATION_START = 'EVENT/UPDATE_ACTIVE_CAMPAIGN_INTEGRATION_START';
export const UPDATE_ACTIVE_CAMPAIGN_INTEGRATION_SUCCESS = 'EVENT/UPDATE_ACTIVE_CAMPAIGN_INTEGRATION_SUCCESS';
export const UPDATE_ACTIVE_CAMPAIGN_INTEGRATION_FAIL = 'EVENT/UPDATE_ACTIVE_CAMPAIGN_INTEGRATION_FAIL';

export const GET_ON_DEMANT_VIDEOS_START = 'GET_ON_DEMANT_VIDEOS_START';
export const GET_ON_DEMANT_VIDEOS_SUCCESS = 'GET_ON_DEMANT_VIDEOS_SUCCESS';
export const GET_ON_DEMANT_VIDEOS_FAIL = 'GET_ON_DEMANT_VIDEOS_FAIL';

export const ADD_ON_DEMANT_VIDEO = 'EVENT/ADD_ON_DEMANT_VIDEO';

export const RESET_ACTIVE_WALL_ID = 'EVENT/RESET_ACTIVE_WALL_ID';
