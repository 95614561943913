import React from 'react';
import {useDispatch} from 'react-redux';

import ProgramDropdownList from '../../../ProgramDropdownList/ProgramDropdownList';
import AuditoriumProgramTimeslot from './components/AuditoriumProgramTimeslot/AuditoriumProgramTimeslot';
import {addToAgenda} from 'store/actions';
import {usePersonalAgendaItems} from 'services/hooks';

import './AuditoriumProgramTimeslotList.scss';

const checkIsInAgenda = (allTimeslotsIdList, id) => allTimeslotsIdList.includes(id);

const AuditoriumProgramTimeslotList = ({list, auditoriumId, displayProgramId}) => {
    const dispatch = useDispatch();
    const {allTimeslotsIdList} = usePersonalAgendaItems();

    return (
        <ProgramDropdownList>
            {list.map(({_id, speakers, title, start, end}) => (
                <ProgramDropdownList.Item key={_id}>
                    <AuditoriumProgramTimeslot
                        speakersString={speakers}
                        title={title}
                        startTime={start}
                        endTime={end}
                        isAdded={checkIsInAgenda(allTimeslotsIdList, _id)}
                        onAdd={() => dispatch(addToAgenda(auditoriumId, displayProgramId, _id))}
                    />
                </ProgramDropdownList.Item>
            ))}
        </ProgramDropdownList>
    );
};

export default AuditoriumProgramTimeslotList;
