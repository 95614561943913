import React from 'react';
import {useUserStatus} from 'services/hooks';

const withUserStatus = (Component) => {
    return (props) => {
        const {getUserStatus} = useUserStatus();
        return <Component {...props} getUserStatus={getUserStatus} />;
    };
};

export default withUserStatus;
