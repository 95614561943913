import React from 'react';

import Svg from '../Svg/Svg';

import './BusinessCard.scss';

const IconBusinessCard = () => (
    <Svg className="IconBusinessCard">
        <path d="M21.25 23.016H2.75A2.752 2.752 0 010 20.266v-13.5a2.752 2.752 0 012.75-2.75h6a.75.75 0 010 1.5h-6c-.689 0-1.25.56-1.25 1.25v13.5c0 .689.561 1.25 1.25 1.25h18.5c.689 0 1.25-.561 1.25-1.25v-13.5c0-.69-.561-1.25-1.25-1.25h-6a.75.75 0 010-1.5h6A2.752 2.752 0 0124 6.766v13.5a2.752 2.752 0 01-2.75 2.75z" />
        <path d="M14.25 8h-4.5C8.785 8 8 7.215 8 6.25v-2.5C8 2.785 8.785 2 9.75 2h4.5c.965 0 1.75.785 1.75 1.75v2.5C16 7.215 15.215 8 14.25 8zm-4.5-4.5a.25.25 0 00-.25.25v2.5c0 .138.112.25.25.25h4.5a.25.25 0 00.25-.25v-2.5a.25.25 0 00-.25-.25h-4.5z" />
        <path d="M20.25 11h-5.5c-.414 0-.75-.448-.75-1s.336-1 .75-1h5.5c.414 0 .75.448.75 1s-.336 1-.75 1z" />
        <path d="M20.25 15h-5.5c-.414 0-.75-.448-.75-1s.336-1 .75-1h5.5c.414 0 .75.448.75 1s-.336 1-.75 1z" />
        <path d="M20.25 19h-5.5c-.414 0-.75-.448-.75-1s.336-1 .75-1h5.5c.414 0 .75.448.75 1s-.336 1-.75 1z" />
        <path d="M10.25 19h-5.5c-.414 0-.75-.448-.75-1s.336-1 .75-1h5.5c.414 0 .75.448.75 1s-.336 1-.75 1z" />
        <path d="M7.5 9C5.56 9 4 10.56 4 12.5S5.56 16 7.5 16s3.5-1.56 3.5-3.5S9.44 9 7.5 9zm0 5c-.83 0-1.5-.67-1.5-1.5S6.67 11 7.5 11s1.5.67 1.5 1.5S8.33 14 7.5 14z" />
    </Svg>
);

export default IconBusinessCard;
