import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles({
    buttons: {
        display: 'flex',
        justifyContent: 'space-around',
        position: 'absolute',
        width: '100%',
        zIndex: 200,
        bottom: 5,
    },
    button: {
        color: '#fff',
        cursor: 'pointer',
        fontSize: '45px',
    },
    buttonWrapper: {
        padding: 0,
    },
    buttonEndCall: {
        color: 'red',
    },
});
