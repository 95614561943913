import {whiteLabel, whiteLabelsList} from '../config';
import {allTranslations} from './translations/all-translations';

export const timeZonesListWithUTC = [
    {label: 'International Date Line West', utc: 'Etc/GMT+12'},
    {label: 'Midway Island, Samoa', utc: 'Pacific/Pago_Pago'},
    {label: 'Hawaii', utc: 'Pacific/Honolulu'},
    {label: 'Pacific Time (US & Canada)', utc: 'America/Los_Angeles'},
    {label: 'Alaska', utc: 'America/Anchorage'},
    {label: 'Tijuana, Baja California', utc: 'America/Tijuana'},
    {label: 'Arizona', utc: 'America/Phoenix'},
    {label: 'Chihuahua, La Paz, Mazatlan', utc: 'America/Chihuahua'},
    {label: 'Mountain Time (US & Canada)', utc: 'America/Denver'},
    {label: 'Central America', utc: 'America/Guatemala'},
    {label: 'Central Time (US & Canada)', utc: 'America/Chicago'},
    {label: 'Bogota, Lima, Quito, Rio Branco', utc: 'America/Bogota'},
    {label: 'Eastern Time (US & Canada)', utc: 'America/New_York'},
    {label: 'Indiana (East)', utc: 'America/Indianapolis'},
    {label: 'Atlantic Time (Canada)', utc: 'America/Halifax'},
    {label: 'Caracas, La Paz', utc: 'America/Caracas'},
    {label: 'Manaus', utc: 'America/La_Paz'},
    {label: 'Santiago', utc: 'America/Santiago'},
    {label: 'Newfoundland', utc: 'America/St_Johns'},
    {label: 'Brasilia', utc: 'America/Sao_Paulo'},
    {label: 'Buenos Aires, Georgetown', utc: 'America/Buenos_Aires'},
    {label: 'Greenland', utc: 'America/Godthab'},
    {label: 'Montevideo', utc: 'America/Montevideo'},
    {label: 'Cape Verde Is.', utc: 'Atlantic/Cape_Verde'},
    {label: 'Azores', utc: 'Atlantic/Azores'},
    {label: 'Casablanca, Monrovia, Reykjavik', utc: 'Africa/Casablanca'},
    {label: 'Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London', utc: 'Europe/Dublin'},
    {label: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna', utc: 'Europe/Berlin'},
    {label: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague', utc: 'Europe/Budapest'},
    {label: 'Brussels, Copenhagen, Madrid, Paris', utc: 'Europe/Paris'},
    {label: 'Sarajevo, Skopje, Warsaw, Zagreb', utc: 'Europe/Warsaw'},
    {label: 'West Central Africa', utc: 'Africa/Lagos'},
    {label: 'Amman', utc: 'Asia/Amman'},
    {label: 'Athens, Bucharest, Istanbul', utc: 'Europe/Bucharest'},
    {label: 'Beirut', utc: 'Asia/Beirut'},
    {label: 'Cairo', utc: 'Africa/Cairo'},
    {label: 'Harare, Pretoria', utc: 'Africa/Harare'},
    {label: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius', utc: 'Europe/Kiev'},
    {label: 'Jerusalem', utc: 'Asia/Jerusalem'},
    {label: 'Minsk', utc: 'Europe/Minsk'},
    {label: 'Windhoek', utc: 'Africa/Windhoek'},
    {label: 'Kuwait, Riyadh, Baghdad', utc: 'Asia/Riyadh'},
    {label: 'Moscow, St. Petersburg, Volgograd', utc: 'Europe/Moscow'},
    {label: 'Nairobi', utc: 'Africa/Nairobi'},
    {label: 'Tbilisi', utc: 'Asia/Tbilisi'},
    {label: 'Tehran', utc: 'Asia/Tehran'},
    {label: 'Abu Dhabi, Muscat', utc: 'Asia/Dubai'},
    {label: 'Baku', utc: 'Asia/Baku'},
    {label: 'Yerevan', utc: 'Asia/Yerevan'},
    {label: 'Kabul', utc: 'Asia/Kabul'},
    {label: 'Yekaterinburg', utc: 'Asia/Yekaterinburg'},
    {label: 'Islamabad, Karachi, Tashkent', utc: 'Asia/Karachi'},
    {label: 'Sri Jayawardenapura', utc: 'Asia/Colombo'},
    {label: 'Chennai, Kolkata, Mumbai, New Delhi', utc: 'Asia/Calcutta'},
    {label: 'Kathmandu', utc: 'Asia/Katmandu'},
    {label: 'Almaty, Novosibirsk', utc: 'Asia/Almaty'},
    {label: 'Astana, Dhaka', utc: 'Asia/Dhaka'},
    {label: 'Yangon (Rangoon)', utc: 'Asia/Rangoon'},
    {label: 'Bangkok, Hanoi, Jakarta', utc: 'Asia/Bangkok'},
    {label: 'Krasnoyarsk', utc: 'Asia/Krasnoyarsk'},
    {label: 'Beijing, Chongqing, Hong Kong, Urumqi', utc: 'Asia/Shanghai'},
    {label: 'Kuala Lumpur, Singapore', utc: 'Asia/Singapore'},
    {label: 'Irkutsk, Ulaan Bataar', utc: 'Asia/Irkutsk'},
    {label: 'Perth', utc: 'Australia/Perth'},
    {label: 'Taipei', utc: 'Asia/Taipei'},
    {label: 'Osaka, Sapporo, Tokyo', utc: 'Asia/Tokyo'},
    {label: 'Seoul', utc: 'Asia/Seoul'},
    {label: 'Yakutsk', utc: 'Asia/Yakutsk'},
    {label: 'Adelaide', utc: 'Australia/Adelaide'},
    {label: 'Darwin', utc: 'Australia/Darwin'},
    {label: 'Brisbane', utc: 'Australia/Brisbane'},
    {label: 'Canberra, Melbourne, Sydney', utc: 'Australia/Sydney'},
    {label: 'Hobart', utc: 'Australia/Hobart'},
    {label: 'Guam, Port Moresby', utc: 'Pacific/Port_Moresby'},
    {label: 'Vladivostok', utc: 'Asia/Vladivostok'},
    {label: 'Magadan, Solomon Is., New Caledonia', utc: 'Asia/Magadan'},
    {label: 'Auckland, Wellington', utc: 'Pacific/Auckland'},
    {label: 'Fiji, Kamchatka, Marshall Is.', utc: 'Pacific/Fiji'},
    {label: "Nuku'alofa", utc: 'Pacific/Tongatapu'}
];

export const countries = [
    {name: 'Afghanistan', code: 'AF'},
    {name: 'Albania', code: 'AL'},
    {name: 'Algeria', code: 'DZ'},
    {name: 'Andorra', code: 'AD'},
    {name: 'Angola', code: 'AO'},
    {name: 'Antigua & Barbuda', code: 'AG'},
    {name: 'Argentina', code: 'AR'},
    {name: 'Armenia', code: 'AM'},
    {name: 'Australia', code: 'AU'},
    {name: 'Austria', code: 'AT'},
    {name: 'Azerbaijan', code: 'AZ'},
    {name: 'Bahamas', code: 'BS'},
    {name: 'Bahrain', code: 'BH'},
    {name: 'Bangladesh', code: 'BD'},
    {name: 'Barbados', code: 'BB'},
    {name: 'Belarus', code: 'BY'},
    {name: 'Belgium', code: 'BE'},
    {name: 'Belize', code: 'BZ'},
    {name: 'Benin', code: 'BJ'},
    {name: 'Bhutan', code: 'BT'},
    {name: 'Bolivia', code: 'BO'},
    {name: 'Bosnia & Herzegovina', code: 'BA'},
    {name: 'Botswana', code: 'BW'},
    {name: 'Brazil', code: 'BR'},
    {name: 'Brunei', code: 'BN'},
    {name: 'Bulgaria', code: 'BG'},
    {name: 'Burkina Faso', code: 'BF'},
    {name: 'Burundi', code: 'BI'},
    {name: 'Cabo Verde', code: 'CV'},
    {name: 'Cambodia', code: 'KH'},
    {name: 'Cameroon', code: 'CM'},
    {name: 'Canada', code: 'CA'},
    {name: 'Central African Rep.', code: 'CF'},
    {name: 'Chad', code: 'TD'},
    {name: 'Chile', code: 'CL'},
    {name: 'China', code: 'CN'},
    {name: 'Colombia', code: 'CO'},
    {name: 'Comoros', code: 'KM'},
    {name: 'Congo', code: 'CG'},
    {name: 'Costa Rica', code: 'CR'},
    {name: "Cote D'Ivoire", code: 'CI'},
    {name: 'Croatia', code: 'HR'},
    {name: 'Cuba', code: 'CU'},
    {name: 'Cyprus', code: 'CY'},
    {name: 'Czech Republic', code: 'CZ'},
    {name: "Democratic People's Republic of Korea", code: 'KP'},
    {name: 'Democratic Republic of the Congo', code: 'CD'},
    {name: 'Denmark', code: 'DK'},
    {name: 'Djibouti', code: 'DJ'},
    {name: 'Dominica', code: 'DM'},
    {name: 'Dominican Republic', code: 'DO'},
    {name: 'Ecuador', code: 'EC'},
    {name: 'Egypt', code: 'EG'},
    {name: 'El Salvador', code: 'SV'},
    {name: 'Equatorial Guinea', code: 'GQ'},
    {name: 'Eritrea', code: 'ER'},
    {name: 'Estonia', code: 'EE'},
    {name: 'Eswatini', code: 'SZ'},
    {name: 'Ethiopia', code: 'ET'},
    {name: 'Fiji', code: 'FJ'},
    {name: 'Finland', code: 'FI'},
    {name: 'France', code: 'FR'},
    {name: 'Gabon', code: 'GA'},
    {name: 'Gambia (Republic of The)', code: 'GM'},
    {name: 'Georgia', code: 'GE'},
    {name: 'Germany', code: 'DE'},
    {name: 'Ghana', code: 'GH'},
    {name: 'Greece', code: 'GR'},
    {name: 'Grenada', code: 'GD'},
    {name: 'Guatemala', code: 'GT'},
    {name: 'Guinea', code: 'GN'},
    {name: 'Guinea-Bissau', code: 'GW'},
    {name: 'Guyana', code: 'GY'},
    {name: 'Haiti', code: 'HT'},
    {name: 'Honduras', code: 'HN'},
    {name: 'Hungary', code: 'HU'},
    {name: 'Iceland', code: 'IS'},
    {name: 'India', code: 'IN'},
    {name: 'Indonesia', code: 'ID'},
    {name: 'Iran  (Islamic Republic of)', code: 'IR'},
    {name: 'Iraq', code: 'IQ'},
    {name: 'Ireland', code: 'IE'},
    {name: 'Israel', code: 'IL'},
    {name: 'Italy', code: 'IT'},
    {name: 'Jamaica', code: 'JM'},
    {name: 'Japan', code: 'JP'},
    {name: 'Jordan', code: 'JO'},
    {name: 'Kazakhstan', code: 'KZ'},
    {name: 'Kenya', code: 'KE'},
    {name: 'Kiribati', code: 'KI'},
    {name: 'Kuwait', code: 'KW'},
    {name: 'Kyrgyzstan', code: 'KG'},
    {name: 'Lao People’s Democratic Republic', code: 'LA'},
    {name: 'Latvia', code: 'LV'},
    {name: 'Lebanon', code: 'LB'},
    {name: 'Lesotho', code: 'LS'},
    {name: 'Liberia', code: 'LR'},
    {name: 'Libya', code: 'LY'},
    {name: 'Liechtenstein', code: 'LI'},
    {name: 'Lithuania', code: 'LT'},
    {name: 'Luxembourg', code: 'LU'},
    {name: 'Macedonia', code: 'MK'},
    {name: 'Madagascar', code: 'MG'},
    {name: 'Malawi', code: 'MW'},
    {name: 'Malaysia', code: 'MY'},
    {name: 'Maldives', code: 'MV'},
    {name: 'Mali', code: 'ML'},
    {name: 'Malta', code: 'MT'},
    {name: 'Marshall Islands', code: 'MH'},
    {name: 'Mauritania', code: 'MR'},
    {name: 'Mauritius', code: 'MU'},
    {name: 'Mexico', code: 'MX'},
    {name: 'Micronesia (Federated States of)', code: 'FM'},
    {name: 'Monaco', code: 'MC'},
    {name: 'Mongolia', code: 'MN'},
    {name: 'Montenegro', code: 'ME'},
    {name: 'Morocco', code: 'MA'},
    {name: 'Mozambique', code: 'MZ'},
    {name: 'Myanmar', code: 'MM'},
    {name: 'Namibia', code: 'NA'},
    {name: 'Nauru', code: 'NR'},
    {name: 'Nepal', code: 'NP'},
    {name: 'Netherlands', code: 'NL'},
    {name: 'New Zealand', code: 'NZ'},
    {name: 'Nicaragua', code: 'NI'},
    {name: 'Niger', code: 'NE'},
    {name: 'Nigeria', code: 'NG'},
    {name: 'North Macedonia', code: 'NU'},
    {name: 'Norway', code: 'NO'},
    {name: 'Oman', code: 'OM'},
    {name: 'Pakistan', code: 'PK'},
    {name: 'Palau', code: 'PW'},
    {name: 'Panama', code: 'PA'},
    {name: 'Papua New Guinea', code: 'PG'},
    {name: 'Paraguay', code: 'PY'},
    {name: 'Peru', code: 'PE'},
    {name: 'Philippines', code: 'PH'},
    {name: 'Poland', code: 'PL'},
    {name: 'Portugal', code: 'PT'},
    {name: 'Qatar', code: 'QA'},
    {name: 'Republic of Korea', code: 'KR'},
    {name: 'Republic of Moldova', code: 'MD'},
    {name: 'Romania', code: 'RO'},
    {name: 'Russian Federation', code: 'RU'},
    {name: 'Rwanda', code: 'RW'},
    {name: 'Saint Kitts & Nevis', code: 'KN'},
    {name: 'Saint Lucia', code: 'LC'},
    {name: 'Saint Vincent and the Grenadines', code: 'VC'},
    {name: 'Samoa', code: 'WS'},
    {name: 'San Marino', code: 'SM'},
    {name: 'Sao Tome & Principe', code: 'ST'},
    {name: 'Saudi Arabia', code: 'SA'},
    {name: 'Senegal', code: 'SN'},
    {name: 'Serbia', code: 'RS'},
    {name: 'Seychelles', code: 'SC'},
    {name: 'Sierra Leone', code: 'SL'},
    {name: 'Singapore', code: 'SG'},
    {name: 'Slovakia', code: 'SK'},
    {name: 'Slovenia', code: 'SI'},
    {name: 'Solomon Islands', code: 'SB'},
    {name: 'Somalia', code: 'SO'},
    {name: 'South Africa', code: 'ZA'},
    {name: 'South Sudan', code: 'SS'},
    {name: 'Spain', code: 'ES'},
    {name: 'Sri Lanka', code: 'LK'},
    {name: 'State of Palestine', code: 'PS'},
    {name: 'Sudan', code: 'SD'},
    {name: 'Suriname', code: 'SR'},
    {name: 'Sweden', code: 'SE'},
    {name: 'Switzerland', code: 'CH'},
    {name: 'Syrian Arab Republic', code: 'SY'},
    {name: 'Tajikistan', code: 'TJ'},
    {name: 'Thailand', code: 'TH'},
    {name: 'Timor-Leste', code: 'TL'},
    {name: 'Togo', code: 'TG'},
    {name: 'Tonga', code: 'TO'},
    {name: 'Trinidad & Tobago', code: 'TT'},
    {name: 'Tunisia', code: 'TN'},
    {name: 'Turkey', code: 'TR'},
    {name: 'Turkmenistan', code: 'TM'},
    {name: 'Tuvalu', code: 'TV'},
    {name: 'Uganda', code: 'UG'},
    {name: 'Ukraine', code: 'UA'},
    {name: 'United Arab Emirates', code: 'AE'},
    {name: 'United Kingdom of Great Britain and Northern Ireland', code: 'GB'},
    {name: 'United Republic of Tanzania', code: 'TZ'},
    {name: 'United States of America', code: 'US'},
    {name: 'Uruguay', code: 'UY'},
    {name: 'Uzbekistan', code: 'UZ'},
    {name: 'Vanuatu', code: 'VU'},
    {name: 'Venezuela, Bolivarian Republic of', code: 'VE'},
    {name: 'Viet Nam', code: 'VN'},
    {name: 'Yemen', code: 'YE'},
    {name: 'Zambia', code: 'ZM'},
    {name: 'Zimbabwe', code: 'ZW'}
];

export const stripeCurrencies = [
    {currencyCode: 'usd', minimumAmmount: 1},
    {currencyCode: 'eur', minimumAmmount: 1}
];

export const monthKeyList = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december'
];

export const DEFAULT_MEDIA_KINDS = ['audio', 'video'];

// TODO: separate addition roles and base roles

export const USER_ADDITIONS_ROLES = {
    organizerRep: 'organizerRep',
    exhibitorRep: 'exhibitorRep',
    coOrganizer: 'co-organizer',
    speaker: 'speaker',
    interpreter: 'interpreter',
    moderator: 'moderator'
};

export const USER_ROLES = {
    organizer: 'organizer',
    exhibitor: 'exhibitor',
    participant: 'participant',
    organizerRep: 'organizerRep', // representative
    exhibitorRep: 'exhibitorRep', // representative
    masterAdmin: 'masterAdmin',
    coOrganizer: 'co-organizer',
    speaker: 'speaker',
    interpreter: 'interpreter',
    moderator: 'moderator'
};

export const USER_STATUSES = {
    new: 'new',
    approved: 'approved',
    pending: 'pending',
    disabled: 'disabled',
    active: 'active'
};

export const EVENT_STATUSES = {
    public: 'public',
    pending: 'pending'
    // TODO: add all event statuses
};

export const LIVE_WALL_SORT_METHODS = {
    popularity: 'popularity',
    time: 'time'
};

export const PAGINATION_DIRECTIONS = {
    prev: 'previous',
    next: 'next'
};

export const MANAGE_BOOTH_BUTTON_TYPES = {
    style: 'style',
    banners: 'banners',
    files: 'files',
    links: 'links',
    representatives: 'representatives',
    video: 'video',
    marketplace: 'marketplace',
    jobs: 'jobs'
};

export const IS_CAREERS_ENV = whiteLabel === whiteLabelsList.careers;
export const IS_SCA_ENV = whiteLabel === whiteLabelsList.sca;
export const IS_MAIN_ENV = whiteLabel === whiteLabelsList.main;
export const IS_UNCTAD_ENV = whiteLabel === whiteLabelsList.unctad;
export const IS_EGYPT_PAVILION_ENV = whiteLabel === whiteLabelsList.egyptPavilion;

export const DEFAULT_LANGUAGES = [
    {
        language: allTranslations.en.slug
    },
    {
        language: allTranslations.ar.slug
    }
];

export const POST_STATUSES = {
    pending: 'pending',
    approved: 'approved'
};

export const ACCESS_PACKAGE_GROUP_TYPES = {
    exhibitor: 'exhibitor',
    participant: 'participant'
};

export const WALL_TYPES = {
    liveWall: 'liveWall',
    boothWall: 'boothWall',
    videoWall: 'videoWall'
};

export const CUSTOM_FIELD_TYPES = {
    text: 'text',
    select: 'select',
    checkbox: 'checkbox',
    textArea: 'text-area',
    radio: 'radio',
    checkboxInput: 'checkboxInput',
    file: 'file'
};

export const VIDEO_CONFERENCE_TYPES = {
    oneToOne: 'oneToOne',
    group: 'group'
};

export const LOCAL_STORAGE_AUDITORIUM_INDEX_KEY = 'auditoriumIndex';

export const FILTER_TYPES = {
    abc: 'abc',
    matches: 'matches'
};

export const FRIEND_CONNECTION_REQUEST_STATUS = {
    pending: 'pending',
    rejected: 'rejected'
    //TODO: add all statuses
};

export const INTERPRETATION_LANGUAGES = {
    ARABIC: 'ar',
    CHINESE: 'ch',
    ENGLISH: 'en',
    FRENCH: 'fr',
    RUSSIAN: 'ru',
    SPANISH: 'es'
};

export const BUILDING_TYPES = {
    auditorium: 'conference',
    showfloor: 'showfloor',
    mixed: 'mixed'
};

export const DEFAULT_INTERPRETATION_LANGUAGE = 'default';

export const COOKIES_ACCEPTED_KEY = 'cookiesAccepted';
export const COOKIES_ACCEPTED_EVENT = 'cookiesAccepted';

export const TEMP_EVENT_INFO_DATA_KEY = 'tempEventInfoDataKey';
