import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    summary: {
        position: 'relative',
        minHeight: '60px !important',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
        webkitBoxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
        mozBoxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
    },
    reset: {
        margin: '0 !important',
    },
    summaryContent: {
        fontWeight: 600,
        fontSize: 16,
    },
    details: {
        alignItems: 'center',
        flexDirection: 'column',
        padding: 0,
    },
    column: {
        flexBasis: '50%',
        padding: 16,
        fontSize: 14,
        fontWeight: '500',
    },
    columnHead: {
        color: '#777081',
        background: '#EFEEF1',
        textTransform: 'uppercase',
    },
    rowWrapper: {
        display: 'flex',
        width: '100%',
        borderBottom: '1px solid #CACBD0;',
    },
    tableTitle: {
        textOverflow: ' ellipsis',
        whiteSpace: ' nowrap',
        overflow: ' hidden',
        width: ' 60%',
    },
    statusChip: {
        position: 'absolute',
        right: '50px',
        top: '50%',
        transform: 'translateY(-100%)',
        fontWeight: 500,
        fontSize: 12,
        padding: '4px 7px',
        borderRadius: '6px',
        background: '#E6E6E6',
        '&.isRtl': {
            left: '60px',
            right: 'inherit',
        },
    },
    statusGood: {
        background: '#5AB453',
    },
    statusBad: {
        background: theme.palette.primary.main,
        color: theme.palette.grey['0'],
    },
    statusWarn: {
        background: '#ffe083',
    },
}));
