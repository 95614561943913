import React, {Component} from 'react';

import {ReactComponent as AccountIcon} from '../../../../../Images/pages/platform-homepage/account-icon.svg';
import {ReactComponent as DirectionsIcon} from '../../../../../Images/pages/platform-homepage/directions-icon.svg';
import {ReactComponent as EventNoteIcon} from '../../../../../Images/pages/platform-homepage/event-note-icon.svg';
import {ReactComponent as HelpIcon} from '../../../../../Images/pages/platform-homepage/help-icon.svg';
import {ReactComponent as VoiceIcon} from '../../../../../Images/pages/platform-homepage/voice-icon.svg';
import {ReactComponent as WorkIcon} from '../../../../../Images/pages/platform-homepage/work-icon.svg';

class PlatformCards extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cards: [
                {
                    icon: () => <DirectionsIcon className="primary-fill-svg" />,
                    header: 'Corporate Meetings',
                    text:
                        'Designed to provide an efficient platform for corporate meetings to take place in a secured digital environment. We aim to provide dependable and superior conference meetings with our high-resolution visuals and quality service. Ideal for home office workers, remote staff, and international teams. Login from anywhere and make meetings available on-demand afterwards.',
                },
                {
                    icon: () => <AccountIcon className="primary-fill-svg" />,
                    header: 'Membership Hub',
                    text:
                        'Offer your members online access to all your events as well as the other members. 24/7/365 access. Build an archive of relevant presentations and past events – and offer your members a service which is accessible anytime from anywhere.',
                },
                {
                    icon: () => <EventNoteIcon className="primary-fill-svg" />,
                    header: 'Fairs & Expos',
                    text:
                        'Excellent branding, leads generation, and networking opportunities for your job/career fair, trade show, or industry expo. Companies from various esteemed industries showcasing their newly launched products and services.',
                },
                {
                    icon: () => <HelpIcon className="primary-fill-svg" />,
                    header: 'Launching New Products',
                    text:
                        'A feature that provides a sturdy platform for companies to present and showcase their newly launched products in front of a diverse audience keen on learning more.',
                },
                {
                    icon: () => <WorkIcon className="primary-fill-svg" />,
                    header: 'Project Tour',
                    text:
                        'Must-have features are delivered through this platform where the attendees can efficiently comprehend the projects or products through the integrated software. This innovative application provides the user with a convenient and efficient experience where participants have the ability to discover a fully configured system aligned with best practices.',
                },
                {
                    icon: () => <VoiceIcon className="primary-fill-svg" />,
                    header: 'Online Roadshow',
                    text:
                        'A diverse series of meetings from across different cities in which top executives have the opportunity to converse with current or potential investors in a secured virtual setting. It comprises of an online prospectus which integrates video and audio systems for speakers who can easily access their presentations or media through synchronized slides present for the viewers.',
                },
            ],
        };
    }

    render() {
        const {cards} = this.state;

        return (
            <div className="cards-container">
                {cards.map((card) => {
                    const Image = card.icon;
                    return (
                        <div className="single-card-container" key={card.header}>
                            <div className="card">
                                <Image />
                                <h5>{card.header}</h5>
                                <p>{card.text}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

export default PlatformCards;
