import React, {useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import MatchingDialogNoteText from './components/MatchingDialogNoteText';
import {CircularProgress, DialogActions, DialogContent} from '@material-ui/core';
import MatchingDialogEmptyPage from './components/MatchingDialogEmptyPage';
import UserPreviewPlate from 'Routes/components/SideMenu/components/Networking/components/UsersList/components/UserPreviewPlate/UserPreviewPlate';
import ColoredScrollbars from 'Routes/components/ColoredScrollbars/ColoredScrollbars';
import {DIALOG_TITLE_TEXT} from 'constants/matchMaking';
import {avatarFolder} from 'config';
import {
    checkIfIsContact,
    checkIfIsFriendConnectionReceived,
    checkIfIsFriendConnectionSent,
    checkIfIsOnline,
    formSocialList,
} from 'services/helpers';

import './MatchingDialogMatchedPage.scss';

const COUNT_OF_USER_PREVIEW = 3;

const MatchingDialogMatchedPage = ({
    classes,
    user,
    onlineUsers,
    setDialogTitle,
    matches,
    eventLoading,
    userLoading,
    onGetOnlineUsers,
    onClose,
}) => {
    const {sentRequests, receivedRequests, contacts} = user;

    useEffect(() => {
        if (matches?.length) {
            setDialogTitle(DIALOG_TITLE_TEXT.matchedPage);
        } else {
            setDialogTitle(DIALOG_TITLE_TEXT.matchedPageEmpty);
        }
    }, [setDialogTitle, matches]);

    useEffect(() => {
        onGetOnlineUsers();
    }, [onGetOnlineUsers]);

    const isContact = useCallback((user) => checkIfIsContact(user, contacts), [contacts]);

    const isFriendConnectionSent = useCallback(
        (user) => checkIfIsFriendConnectionSent(user, sentRequests),
        [sentRequests]
    );

    const isFriendConnectionReceived = useCallback(
        (user) => checkIfIsFriendConnectionReceived(user, receivedRequests),
        [receivedRequests]
    );

    const isOnline = useCallback((user) => checkIfIsOnline(user, onlineUsers), [onlineUsers]);

    return (
        <>
            <DialogContent className={classes?.conntent}>
                <div className="matching-dialog-matched-page">
                    {eventLoading || userLoading ? (
                        <div className="matching-dialog-matched-page__loader-wrapper">
                            <CircularProgress />
                        </div>
                    ) : matches?.length ? (
                        <div className="matching-dialog-matched-page__matches-wrapper">
                            <ColoredScrollbars className="scrollbar">
                                {matches.slice(0, COUNT_OF_USER_PREVIEW).map((user) => {
                                    const userIsContact = isContact(user);
                                    const userIsFriendConnectionSent = isFriendConnectionSent(user);
                                    const userIsFriendConnectionReceived = isFriendConnectionReceived(user);

                                    const canConnectToParticipant =
                                        !userIsContact &&
                                        !userIsFriendConnectionSent &&
                                        !userIsFriendConnectionReceived;

                                    return (
                                        <div key={user.userId} className="matching-dialog-matched-page__matches-plate">
                                            <UserPreviewPlate
                                                userId={user.userId}
                                                firstName={user.first}
                                                lastName={user.last}
                                                media={{
                                                    title: 'User avatar',
                                                    src: `${avatarFolder}${user.avatarSmall}`,
                                                }}
                                                profession={user.title}
                                                company={user.company}
                                                socialList={formSocialList(user)}
                                                businessCardId={user.businessCardId}
                                                isCurrentUser={user.userId === user._id}
                                                isContact={userIsContact}
                                                isFriendConnectionSent={userIsFriendConnectionSent}
                                                canConnectToParticipant={canConnectToParticipant}
                                                online={isOnline(user)}
                                                matchLevel={user.matchLevel}
                                                openChatCallback={onClose}
                                                openCardCallback={onClose}
                                            />
                                        </div>
                                    );
                                })}
                                {matches && matches?.length < COUNT_OF_USER_PREVIEW && (
                                    <div className="matching-dialog-matched-page__empty-plate">
                                        <p className="matching-dialog-matched-page__empty-plate-text">
                                            Oh snap! We can’t find more matches for you at the moment. Please check back
                                            again when more participants have joined
                                        </p>
                                    </div>
                                )}
                            </ColoredScrollbars>
                        </div>
                    ) : (
                        <MatchingDialogEmptyPage />
                    )}
                </div>
            </DialogContent>
            <DialogActions className={classes?.actions}>
                <MatchingDialogNoteText />
            </DialogActions>
        </>
    );
};

MatchingDialogMatchedPage.propTypes = {
    classes: PropTypes.object.isRequired,
    setDialogTitle: PropTypes.func.isRequired,
    user: PropTypes.object,
    onlineUsers: PropTypes.array,
    onGetOnlineUsers: PropTypes.func,
    onClose: PropTypes.func,
};

export default MatchingDialogMatchedPage;
