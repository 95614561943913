export const MAX_CHIPS_LENGTH = 15;
export const calculateChipsCountInHeader = (chips) => {
    let chipLength = 0;
    const count = chips.slice(0, 2).reduce((count, el, i, arr) => {
        if (chipLength >= MAX_CHIPS_LENGTH || (el.optionName.length + arr[i + 1] >= MAX_CHIPS_LENGTH && i !== 0))
            return count;

        chipLength += el.optionName.length;
        return count + 1;
    }, 0);
    return count;
};

export const normalizeChipLable = (optionName, isRtl = false) => {
    if (MAX_CHIPS_LENGTH < optionName.length) {
        return isRtl ? `...${optionName.slice(0, MAX_CHIPS_LENGTH)}` : `${optionName.slice(0, MAX_CHIPS_LENGTH)}...`;
    }
    return optionName;
};

export const mergeFilters = (filters, filterId = '', platformLanguage = 'en') => {
    const options = [];

    if (!filters.length) return options;

    filters.forEach((filter) => {
        filter.options.forEach((option) => {
            options.push({
                filterId: filter._id,
                filterName: filter.label,
                optionId: option._id,
                optionName: getFilterTranslation(option, platformLanguage) || option.value,
                isChecked: option.isChecked,
            });
        });
    });

    if (filterId.length) return options.filter((option) => option.filterId === filterId);

    return options;
};

export const mergeCheckedFilters = (filters, filterId = '', platformLanguage) => {
    const options = mergeFilters(filters, filterId, platformLanguage);

    return options.filter((option) => option.isChecked);
};

export const filterCountries = (filters, filterId = '') => {
    const options = mergeFilters(filters, filterId);

    return options.filter((option) => option.isChecked);
};

export const getFilterTranslation = (obj, platformLanguage) => {
    if (!obj.translations) return;
    return (
        obj.translations.find(({language}) => language === platformLanguage)?.text ||
        obj.translations?.find((translations) => translations?.default)?.text
    );
};
