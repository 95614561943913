import React from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';

import {ConfirmedActionGroup, PendingActionGroup} from './components/ActionGroups/ActionGroups';

import './DesktopAgendaMeetingItem.scss';

const DesktopAgendaMeetingItem = ({
    title,
    description,
    statusString,
    status,
    date,
    timezone,
    startTime,
    endTime,
    isConfirmed,
    isRequested,
    onCancel,
    onConfirm,
    onMeetingStart,
    isRtlLanguage,
}) => (
    <div
        className={classNames('DesktopAgendaMeetingItem', `DesktopAgendaMeetingItem_status-${status}`, {
            isRtl: isRtlLanguage,
        })}
    >
        <div className="DesktopAgendaMeetingItem__titleCol">
            <div className="DesktopAgendaMeetingItem__title">{title}</div>

            <div className="DesktopAgendaMeetingItem__description">{description}</div>

            <div className="DesktopAgendaMeetingItem__status">{statusString}</div>
        </div>

        <div className="DesktopAgendaMeetingItem__metaCol">
            <div className="DesktopAgendaMeetingItem__dateGroup">
                <div className="DesktopAgendaMeetingItem__date">{date}</div>

                <div className="DesktopAgendaMeetingItem__timezone">{timezone}</div>
            </div>

            <div className="DesktopAgendaMeetingItem__timeGroup">
                <div className="DesktopAgendaMeetingItem__timeItem">{startTime}-</div>
                <div className="DesktopAgendaMeetingItem__timeItem">{endTime}</div>
            </div>

            <div className="DesktopAgendaMeetingItem__actionGroup">
                {isConfirmed ? (
                    <ConfirmedActionGroup onCancel={onCancel} onMeetingStart={onMeetingStart} />
                ) : (
                    <PendingActionGroup isRequested={isRequested} onCancel={onCancel} onConfirm={onConfirm} />
                )}
            </div>
        </div>
    </div>
);

export default connect((state) => ({
    isRtlLanguage: state.languages.isRtlLanguage,
}))(DesktopAgendaMeetingItem);
