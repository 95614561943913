import React from 'react';

import V1BoothFrontNoMarket768 from '../../../../../Images/backgrounds/boothFront-no-market-v1_768.jpg';
import V1BoothFrontNoMarket900 from '../../../../../Images/backgrounds/boothFront-no-market-v1_900.jpg';
import V1BoothFrontNoMarket1080 from '../../../../../Images/backgrounds/boothFront-no-market-v1_1080.jpg';
import V1BoothFrontNoMarket1200 from '../../../../../Images/backgrounds/boothFront-no-market-v1_1200.jpg';
import V1BoothFrontNoMarket1440 from '../../../../../Images/backgrounds/boothFront-no-market-v1_1440.jpg';
import V1BoothFrontNoMarketFull from '../../../../../Images/backgrounds/boothFront-no-market-v1.jpg';

import V2BoothFrontNoMarket768 from '../../../../../Images/backgrounds/boothFront-no-market-v2_768.jpg';
import V2BoothFrontNoMarket900 from '../../../../../Images/backgrounds/boothFront-no-market-v2_900.jpg';
import V2BoothFrontNoMarket1080 from '../../../../../Images/backgrounds/boothFront-no-market-v2_1080.jpg';
import V2BoothFrontNoMarket1200 from '../../../../../Images/backgrounds/boothFront-no-market-v2_1200.jpg';
import V2BoothFrontNoMarket1440 from '../../../../../Images/backgrounds/boothFront-no-market-v2_1440.jpg';
import V2BoothFrontNoMarketFull from '../../../../../Images/backgrounds/boothFront-no-market-v2.jpg';

import V3BoothFrontNoMarket768 from '../../../../../Images/backgrounds/boothFront-no-market-v3_768.jpg';
import V3BoothFrontNoMarket900 from '../../../../../Images/backgrounds/boothFront-no-market-v3_900.jpg';
import V3BoothFrontNoMarket1080 from '../../../../../Images/backgrounds/boothFront-no-market-v3_1080.jpg';
import V3BoothFrontNoMarket1200 from '../../../../../Images/backgrounds/boothFront-no-market-v3_1200.jpg';
import V3BoothFrontNoMarket1440 from '../../../../../Images/backgrounds/boothFront-no-market-v3_1440.jpg';
import V3BoothFrontNoMarketFull from '../../../../../Images/backgrounds/boothFront-no-market-v3.jpg';

import CustomBoothNotReady768 from '../../../../../Images/backgrounds/boothFront-c1_768.jpg';
import CustomBoothNotReady900 from '../../../../../Images/backgrounds/boothFront-c1_900.jpg';
import CustomBoothNotReady1080 from '../../../../../Images/backgrounds/boothFront-c1_1080.jpg';
import CustomBoothNotReady1200 from '../../../../../Images/backgrounds/boothFront-c1_1200.jpg';
import CustomBoothNotReady1440 from '../../../../../Images/backgrounds/boothFront-c1_1440.jpg';
import CustomBoothNotReadyFull from '../../../../../Images/backgrounds/boothFront-c1.jpg';
import classNames from 'classnames';

import './BoothFullPreview.scss';

const BoothFullPreview = (props) => {
    let V1boothFront;
    let V2boothFront;
    let V3boothFront;
    let CustomBoothNotReady;

    switch (true) {
        case window.innerHeight < 769:
            V1boothFront = V1BoothFrontNoMarket768;
            V2boothFront = V2BoothFrontNoMarket768;
            V3boothFront = V3BoothFrontNoMarket768;
            CustomBoothNotReady = CustomBoothNotReady768;
            break;
        case window.innerHeight < 901:
            V1boothFront = V1BoothFrontNoMarket900;
            V2boothFront = V2BoothFrontNoMarket900;
            V3boothFront = V3BoothFrontNoMarket900;
            CustomBoothNotReady = CustomBoothNotReady900;
            break;
        case window.innerHeight < 1081:
            V1boothFront = V1BoothFrontNoMarket1080;
            V2boothFront = V2BoothFrontNoMarket1080;
            V3boothFront = V3BoothFrontNoMarket1080;
            CustomBoothNotReady = CustomBoothNotReady1080;
            break;
        case window.innerHeight < 1201:
            V1boothFront = V1BoothFrontNoMarket1200;
            V2boothFront = V2BoothFrontNoMarket1200;
            V3boothFront = V3BoothFrontNoMarket1200;
            CustomBoothNotReady = CustomBoothNotReady1200;
            break;
        case window.innerHeight < 1441:
            V1boothFront = V1BoothFrontNoMarket1440;
            V2boothFront = V2BoothFrontNoMarket1440;
            V3boothFront = V3BoothFrontNoMarket1440;
            CustomBoothNotReady = CustomBoothNotReady1440;
            break;
        default:
            V1boothFront = V1BoothFrontNoMarketFull;
            V2boothFront = V2BoothFrontNoMarketFull;
            V3boothFront = V3BoothFrontNoMarketFull;
            CustomBoothNotReady = CustomBoothNotReadyFull;
    }

    const CustomBooth = props.exhibitor.filesUrl + props.exhibitor.booth.customBoothImages?.original;

    return (
        <div className={classNames('booth-preview-container', props.className)}>
            {props.exhibitor.booth.boothType === 'v1' && (
                <img src={V1boothFront} style={props.moveLeft ? props.moveLeft : null} alt="Booth version 1" />
            )}
            {props.exhibitor.booth.boothType === 'v2' && (
                <img src={V2boothFront} style={props.moveLeft ? props.moveLeft : null} alt="Booth version 2" />
            )}
            {props.exhibitor.booth.boothType === 'v3' && (
                <img src={V3boothFront} style={props.moveLeft ? props.moveLeft : null} alt="Booth version 3" />
            )}
            {props.exhibitor.booth.boothType === 'custom' && (
                <>
                    {props.exhibitor.booth.customBoothImages && props.exhibitor.booth.customBoothImages.original ? (
                        <img src={CustomBooth} alt="Booth custom" />
                    ) : (
                        <img
                            src={CustomBoothNotReady}
                            style={props.moveLeft ? props.moveLeft : null}
                            alt="Booth not ready"
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default BoothFullPreview;
