import React from 'react';
import classNames from 'classnames';

import VideoConference from '../VideoConference';
import SendPrivateChatMessage from './components/SendPrivateChatMessage';
import PrivateChatHeader from './components/PrivateChatHeader/PrivateChatHeader';
import ChatBusinessCard from './components/ChatBusinessCard/ChatBusinessCard';
import ListMessages from '../ListMessages';
import EmptyChat from '../EmptyChat/EmptyChat';

import {
    connectToPrivateChat,
    privateMessageReceived,
    disconnectFromPrivateChat,
    p2pCallToUser,
    endCall,
} from 'Api/socketApi';
import {VIDEO_CONFERENCE_TYPES} from 'constants/shared';
import {getTimeAgoString} from 'services/utils';

class PrivateChat extends React.Component {
    constructor(props) {
        super(props);
        this.newMessageRef = React.createRef();
    }

    state = {
        messages: [],
        chatName: '',
        avatarSrc: '',
        showVideoConference: false,
        fullScreenVideoConference: false,
        chatPartnerId: false,
        partnerId: null,
        userSentNewMessage: false,
        receivedMessagesForChatId: '',
        timeAgoString: '',
        capture: null,
        playback: null,
        selfPlayback: null,
        privateChatPartnerData: null,
        translation: null,
        isArchived: false,
        openBusinessCard: false,
    };

    componentDidMount() {
        const {activeChatRoomId, user, readMessagesForPrivateChat} = this.props;

        // TODO: check implementation, not necessary condition
        if (this.props.activeChatRoomId) {
            connectToPrivateChat({chatId: activeChatRoomId, userId: user._id});

            this.onGetPrivateChatMessages(activeChatRoomId);
            readMessagesForPrivateChat(activeChatRoomId);
            privateMessageReceived((err, data) => {
                this.setState({messages: [...this.state.messages, data.message]});
                if (data.message.user._id === user._id) {
                    this.setState(
                        {userSentNewMessage: true},
                        () => {
                            this.setState({
                                userSentNewMessage: false,
                            });
                        },
                        1000
                    );
                } else {
                    this.setState({userSentNewMessage: false});
                }
            });

            this.setChatName();
        }
    }

    componentWillUnmount() {
        const {
            activeChatRoomId,
            user,
            videoConference: {isActive, companionId},
            closeVideoRoom,
            endOneToOneCall,
        } = this.props;

        if (activeChatRoomId) {
            disconnectFromPrivateChat({chatId: activeChatRoomId, userId: user._id});
        }

        if (isActive) {
            endCall({from: user._id, to: companionId});
            endOneToOneCall();
            closeVideoRoom();
        }
    }

    componentDidUpdate(prevProps) {
        const {
            activeChatRoomId,
            user,
            languages,
            readMessagesForPrivateChat,
            videoConference: {isActive, companionId},
            closeVideoRoom,
        } = this.props;

        if (activeChatRoomId && activeChatRoomId !== prevProps.activeChatRoomId) {
            // disconnect from previous chat
            disconnectFromPrivateChat({chatId: prevProps.activeChatRoomId, userId: user._id});

            if (isActive) {
                endCall({from: user._id, to: companionId});
                closeVideoRoom();
            }

            // connect to new chat
            connectToPrivateChat({chatId: activeChatRoomId, userId: user._id});

            readMessagesForPrivateChat(activeChatRoomId);
            this.setChatName();

            // get the new messages for the current private chat
            this.onGetPrivateChatMessages(activeChatRoomId);
            this.setState({showVideoConference: false});
        }
        if (prevProps.languages.platformLanguage !== languages.platformLanguage) {
            this.setLanguagesData();
        }
        if (
            user.archivedChats &&
            (!prevProps.user.archivedChats || prevProps.user.archivedChats.length !== user.archivedChats.length)
        ) {
            this.setChatName();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    checkVisibilityBusinessCardButton = () => {
        const ableOpenBC = this.props.user.contacts.find((contact) => {
            return contact.user._id === this.state.chatPartnerId && contact.user.businessCard;
        });

        this.props.onUpdateActiveChatData({showBusinessCardButton: !!ableOpenBC});
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    handleOpenBusinessCard = (value) => {
        this.setState({openBusinessCard: value});
    };

    onGetPrivateChatMessages = (roomId) => {
        const {getPrivateChatMessages} = this.props;

        getPrivateChatMessages({
            roomId,
            callback: ({messages, chatPartnerId}) => {
                this.setState({messages, chatPartnerId}, () => {
                    this.setState({receivedMessagesForChatId: roomId});
                    this.checkVisibilityBusinessCardButton();
                });
            },
        });
    };

    setChatName = () => {
        let privateChatData = this.props.user.privateChats.find(
            (privateChat) => privateChat.chat._id === this.props.activeChatRoomId
        );

        if (!privateChatData) return;

        const isArchived = privateChatData?.archived || false;

        let privateChatPartnerData = null;
        if (this.props.user._id === privateChatData.chat.user_1._id) {
            privateChatPartnerData = privateChatData.chat.user_2;
        } else {
            privateChatPartnerData = privateChatData.chat.user_1;
        }

        let timeAgoString = getTimeAgoString(privateChatData.chat.lastMessageAt, this.props.languages.platformLanguage);
        const chatName = privateChatPartnerData.first + ' ' + privateChatPartnerData.last;
        const avatarSrc = privateChatPartnerData.avatarSmall;

        this.setState({
            chatName,
            avatarSrc,
            timeAgoString,
            privateChatPartnerData,
            isArchived,
        });

        const data = {
            chatId: this.props.activeChatRoomId,
            chatName,
            avatarSrc,
            timeAgoString,
            privateChatPartnerData,
            isArchived,
            showBusinessCardButton: false,
        };
        this.props.onSetActiveChatData(data);
    };

    openVideoConference = async () => {
        const {
            user,
            activeChatRoomId,
            isLargeScreen,
            openVideoRoom,
            setActiveVideoConferenceType,
            incomingOneToOneCallUserId,
            incomingCallOffer,
            startCall,
        } = this.props;

        const currentChat = user.privateChats.find((privateChat) => privateChat.chat._id === activeChatRoomId);
        const companionId =
            currentChat.chat.user_1._id === user._id ? currentChat.chat.user_2._id : currentChat.chat.user_1._id;

        setActiveVideoConferenceType(VIDEO_CONFERENCE_TYPES.oneToOne);

        openVideoRoom({companionId});

        if (
            !incomingOneToOneCallUserId ||
            incomingOneToOneCallUserId !== companionId ||
            companionId !== incomingCallOffer.userId
        ) {
            startCall();
            p2pCallToUser({from: user._id, to: companionId});
        }

        this.setState({showVideoConference: true});
        if (isLargeScreen && !this.props.maximizeChats.seeExpandedVideoConference) {
            // only if is on a Large screen and the chat is not expanded
            // we want to expand it
            this.props.seeExpandedVideoConference();
        }
    };

    render() {
        let lastChatMessage;
        if (this.state.messages.length) {
            lastChatMessage = this.state.messages[this.state.messages.length - 1].text;
        }

        return (
            <div className={classNames('single-chat-container', {isRtl: this.props.isRtlLanguage})}>
                {this.props.videoConference.isActive && this.state.privateChatPartnerData && (
                    <VideoConference members={[this.state.privateChatPartnerData]} />
                )}

                {!this.props.isMobile && this.props.activeChatData && (
                    <PrivateChatHeader activeChatData={this.props.activeChatData} />
                )}

                {this.props.activeChatData?.openBusinessCard && (
                    <ChatBusinessCard
                        cardId={this.state.chatPartnerId}
                        handleCloseBusinessCard={() => this.handleOpenBusinessCard(false)}
                    />
                )}
                {!this.props.activeChatData?.openBusinessCard && this.state.messages.length > 0 && (
                    <ListMessages
                        languages={this.props.languages}
                        userId={this.props.user._id}
                        partnerId={this.state.privateChatPartnerData?._id}
                        messages={this.state.messages}
                        userSentNewMessage={this.state.userSentNewMessage}
                        receivedMessagesForChatId={this.state.receivedMessagesForChatId}
                        openVideoConference={this.openVideoConference}
                    />
                )}
                {!this.props.activeChatData?.openBusinessCard && this.state.messages.length < 0 && <EmptyChat />}
                {!this.props.activeChatData?.openBusinessCard && (
                    <SendPrivateChatMessage
                        joinConference={this.state.showVideoConference}
                        lastChatMessage={lastChatMessage}
                        startConference={this.openVideoConference}
                    />
                )}
            </div>
        );
    }
}

export default PrivateChat;
