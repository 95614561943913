import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import EventPrivateComponent from './EventPrivateComponent';

export default withRouter(
    connect(
        (state) => ({
            eventId: selectors.getEventId(state),
            eventSlug: selectors.getEventSlug(state),
            event: selectors.getEventData(state),
            loadingEvent: selectors.getEventLoading(state),
            buildingId: selectors.getBuildingId(state),
            building: selectors.getBuildingData(state),
            loadingBuilding: selectors.getBuildingLoading(state),
            eventNotFound: selectors.getIsEventNotFound(state),
        }),
        {
            onGetEvent: actions.getEvent,
            setEventId: actions.setEventId,
            setEventSlug: actions.setEventSlug,
            setEventIdBySlug: actions.setEventIdBySlug,
            onSetBuildingId: actions.setBuildingId,
            onGetBuilding: actions.getBuilding,
        }
    )(EventPrivateComponent)
);
