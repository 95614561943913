import React from 'react';
import PropTypes from 'prop-types';
import './MatchingRadio.scss';
import {FormControlLabel, Radio, RadioGroup} from '@material-ui/core';

const MatchingRadio = ({className, onChange, question, value}) => {
    return (
        <>
            <RadioGroup
                aria-label={question.title}
                name={question._id}
                value={value}
                onChange={onChange}
                className={className}
            >
                {question.options.map((option) => {
                    return (
                        <FormControlLabel
                            classes={{label: 'matching-dialog-questions-page__form-label'}}
                            value={option._id}
                            control={<Radio color="primary" />}
                            label={option.title}
                            key={option._id}
                        />
                    );
                })}
            </RadioGroup>
        </>
    );
};

MatchingRadio.propTypes = {
    onChange: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
    value: PropTypes.string.isRequired,
    className: PropTypes.string,
};

MatchingRadio.defaultProps = {
    value: '',
};

export default MatchingRadio;
