import {useSelector} from 'react-redux';
import {
    getCreatedProduct,
    getDeletedProduct,
    getEditedProduct,
    getMarketplaceLoadState,
    getProductList,
} from 'store/selectors';
import {checkIsError, checkIsLoading, checkIsReady} from 'services/utils';

const useMarketplace = () => {
    const productList = useSelector(getProductList);
    const createdProduct = useSelector(getCreatedProduct);
    const editedProduct = useSelector(getEditedProduct);
    const deletedProduct = useSelector(getDeletedProduct);
    const loadState = useSelector(getMarketplaceLoadState);

    const isReady = checkIsReady(loadState);
    const isError = checkIsError(loadState);
    const isLoading = checkIsLoading(loadState);

    return {
        isError,
        isReady,
        isLoading,
        productList,
        editedProduct,
        createdProduct,
        deletedProduct,
        activeProduct: editedProduct || createdProduct,
        isEmpty: productList.length === 0 && !createdProduct,
    };
};

export default useMarketplace;
