import React from 'react';

import {Link} from 'react-router-dom';
import classNames from 'classnames';

import {ReactComponent as TwitterIconFooter} from '../../../Images/twitter-footer.svg';
import {ReactComponent as FacebookIconFooter} from '../../../Images/facebook-footer.svg';
import {ReactComponent as InstagramIconFooter} from '../../../Images/instagram-footer.svg';
import {ReactComponent as LinkedinIconFooter} from '../../../Images/linkedin-footer.svg';
import {ReactComponent as YoutubeIconFooter} from '../../../Images/youtube-footer.svg';
import {ReactComponent as SnapchatIconFooter} from '../../../Images/snapchat-footer.svg';
import {ReactComponent as TiktokIconFooter} from '../../../Images/tiktok-footer-filled.svg';
import MainFooterLogo from '../../../Images/branding/logo-white.png';
import ScaFooterLogo from '../../../Images/branding/sca/header-logo/sca-white-logo.svg';
import UnctadLogoImage from '../../../Images/branding/unctad/unctad-white-logo.svg';
import EgyptLogoImage from '../../../Images/branding/egypt/header-logo/egypt-pavilion-white-logo.svg';
import {whiteLabel, footerSocialLinksConfig, getInfoLinks, whiteLabelsList} from '../../../config';
import {usePlatformTranslation} from '../../../services/hooks';

const linksIcons = {
    twitter: <TwitterIconFooter />,
    facebook: <FacebookIconFooter />,
    linkedin: <LinkedinIconFooter />,
    instagram: <InstagramIconFooter />,
    youtube: <YoutubeIconFooter />,
    snapchat: <SnapchatIconFooter />,
    tiktok: <TiktokIconFooter />,
};

const footerLogoConfig = {
    main: MainFooterLogo,
    sca: ScaFooterLogo,
    unctad: UnctadLogoImage,
    [whiteLabelsList.egyptPavilion]: EgyptLogoImage,
};

export const Footer = ({
    isRtlLanguage,
    platformLanguage,
    eventLanguage,
    match: {
        params: {eventSlug},
    },
}) => {
    const translation = usePlatformTranslation((translation) => translation.footer);

    const getSocialLinks = footerSocialLinksConfig[whiteLabel]
        ? footerSocialLinksConfig[whiteLabel]
        : footerSocialLinksConfig.main;

    const FooterLogo = footerLogoConfig[whiteLabel] || footerLogoConfig.main;
    const infoLinks = getInfoLinks(platformLanguage, whiteLabel);
    return (
        <footer>
            <div className="footer-container">
                <div className="links-container">
                    <div className="inner-links">
                        <ul>
                            <li>
                                {eventSlug ? (
                                    <Link
                                        to={{
                                            pathname: `/event/${eventSlug}/privacy-policy/${eventLanguage}`,
                                        }}
                                    >
                                        {translation.privacyPolicy}
                                    </Link>
                                ) : (
                                    <Link
                                        to={{
                                            pathname: infoLinks.privacyPolicy,
                                        }}
                                    >
                                        {translation.privacyPolicy}
                                    </Link>
                                )}
                            </li>
                            <li>
                                {eventSlug ? (
                                    <Link
                                        to={{
                                            pathname: `/event/${eventSlug}/cookies/${eventLanguage}`,
                                        }}
                                    >
                                        {translation.cookies}
                                    </Link>
                                ) : (
                                    <Link to={{pathname: infoLinks.cookies}}>{translation.cookies}</Link>
                                )}
                            </li>
                            <li>
                                {eventSlug ? (
                                    <Link
                                        to={{
                                            pathname: `/event/${eventSlug}/terms-and-conditions/${eventLanguage}`,
                                        }}
                                    >
                                        {translation.termsAndConditions}
                                    </Link>
                                ) : (
                                    <Link
                                        to={{
                                            pathname: infoLinks.termsAndConditions,
                                        }}
                                    >
                                        {translation.termsAndConditions}
                                    </Link>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div className={classNames('social-buttons-wrapper', {isRtl: isRtlLanguage})}>
                        <p>{translation.socialLinksTitle}</p>
                        <ul>
                            {Object.keys(getSocialLinks).map((network) => {
                                return (
                                    <li key={network}>
                                        <a
                                            href={getSocialLinks[network]}
                                            target="_blank"
                                            className="social-buttons-wrapper__link"
                                            rel="noopener noreferrer"
                                        >
                                            {linksIcons[network]}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
                <div className="logo-wrapper">
                    <img src={FooterLogo} height="44" alt="Footer Logo" />
                    <span>&copy; {new Date().getFullYear()}</span>
                </div>
            </div>
        </footer>
    );
};
