import {Api} from '../../../../Api';
import {customFormActionTypes} from '../../../../constants/customForm';
import * as actionTypes from '../../../actionTypes/organizerActionTypes/eventBranding';
import {getEvent} from '../../eventActions';

export const createCustomForm = () => ({
    type: actionTypes.CREATE_CUSTOM_FORM,
});

export const deleteCustomForm = (payload) => ({
    type: actionTypes.DELETE_CUSTOM_FORM,
    payload,
});

export const setCustomFormFields = (payload) => ({
    type: actionTypes.SET_CUSTOM_FORM_FIELDS,
    payload,
});

export const changeCustomFormField = (payload) => ({
    type: actionTypes.CHANGE_CUSTOM_FORM_FIELD,
    payload,
});

export const updateCustomFormFieldStatus = ({
    action,
    saveUrl,
    updateUrl,
    deleteUrl,
    data,
    callback,
    fieldId,
    language,
}) => async (dispatch, getState) => {
    const {
        organizer: {
            customForm: {loading},
        },
    } = getState();

    dispatch(updateCustomFormFieldStatusRequest());

    let apiRequest = {};
    const isDelete = action === customFormActionTypes.delete;
    switch (action) {
        case customFormActionTypes.save:
            apiRequest = {method: 'post', url: saveUrl, payload: data, loading, errorObj: {show: true}};
            break;
        case customFormActionTypes.update:
            apiRequest = {method: 'put', url: updateUrl, payload: data, loading, errorObj: {show: true}};
            break;
        case customFormActionTypes.delete:
            apiRequest = {method: 'delete', url: deleteUrl, loading, errorObj: {show: true}};
            break;
        default:
            break;
    }

    try {
        const {customField} = await Api.request(apiRequest);
        dispatch(updateCustomFormFieldStatusSuccess(customField, fieldId, isDelete, language));

        callback && callback();
    } catch (error) {
        dispatch(updateCustomFormFieldStatusFail());
    }
};

const updateCustomFormFieldStatusRequest = () => ({
    type: actionTypes.UPDATE_CUSTOM_FORM_FIELD_STATUS_REQUEST,
});

const updateCustomFormFieldStatusSuccess = (customField, fieldId, isDelete, language) => ({
    type: actionTypes.UPDATE_CUSTOM_FORM_FIELD_STATUS_SUCCESS,
    payload: {customField, fieldId, isDelete, language},
});

const updateCustomFormFieldStatusFail = () => ({
    type: actionTypes.UPDATE_CUSTOM_FORM_FIELD_STATUS_FAIL,
});

export const removeCustomFormFieldOption = (payload) => ({
    type: actionTypes.REMOVE_CUSTOM_FORM_FIELD_OPTION,
    payload,
});

export const addCustomFormFieldOption = (payload) => ({
    type: actionTypes.ADD_CUSTOM_FORM_FIELD_OPTION,
    payload,
});

export const changeCustomFormFieldOption = (payload) => ({
    type: actionTypes.CHANGE_CUSTOM_FORM_FIELD_OPTION,
    payload,
});

export const postServeyThankYouMessage = ({data, callback}) => async (dispatch, getState) => {
    const {
        event: {eventId},
        organizer: {
            eventBranding: {
                survey: {loading},
            },
        },
    } = getState();

    dispatch(postThankYouMessageRequest());

    try {
        await Api.request({
            url: `/event/${eventId}/survey/thank-you-message`,
            method: 'post',
            payload: data,
            loading,
        });

        dispatch(postThankYouMessageSuccess());
        if (callback) callback();
        dispatch(getEvent(eventId));
    } catch (error) {
        dispatch(postThankYouMessageFail());
    }
};

const postThankYouMessageRequest = () => ({
    type: actionTypes.POST_THANK_YOU_MESSAGE_REQUEST,
});

const postThankYouMessageSuccess = () => ({
    type: actionTypes.POST_THANK_YOU_MESSAGE_SUCCESS,
});

const postThankYouMessageFail = () => ({
    type: actionTypes.POST_THANK_YOU_MESSAGE_FAIL,
});
