import React from 'react';
import cn from 'classnames';

import './UserPreviewPlateInteraction.scss';

const UserPreviewPlateInteraction = ({title, variant = 'default', isDisabled, ...rest}) => (
    <button
        dangerouslySetInnerHTML={{__html: title}}
        className={cn('UserPreviewPlateInteraction', `UserPreviewPlateInteraction_variant-${variant}`)}
        disabled={isDisabled}
        {...rest}
    />
);

export default UserPreviewPlateInteraction;
