import {createSelector} from 'reselect';

const organizer = (state) => state.organizer;

export const getOrganizer = createSelector(organizer, (state) => state);

export const getOrganizerUploadCustomLogoLoading = createSelector(
    organizer,
    (state) => state.eventSetup.uploadCustomLogoLoading
);

export const getAuditoriumPolls = createSelector(organizer, (state) => state.auditoriumSetup.polls);

export const getEditedBuilding = createSelector(organizer, (state) => state.editBuilding.editedBuilding);

export const getEditedBuildingId = createSelector(organizer, (state) => state.editBuilding.editedBuildingId);

export const getEventAccessManagement = createSelector(organizer, (state) => state.eventAccessManagement);

export const getEventBranding = createSelector(organizer, (state) => state.eventBranding);
