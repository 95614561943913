import {useEffect, useCallback, useState} from 'react';
import {useDispatch, useSelector, batch} from 'react-redux';

import {
    p2pCallCandidateRequest,
    p2pCallOfferRequest,
    p2pCallAnswerRequest,
    p2pCallAnswerReceived,
    participantHasLeft,
    participantVideoEnabled,
    participantVideoDisabled,
    callUsers,
} from 'Api/socketApi';
import {
    getActiveCallTypeData,
    getUserId,
    getIncomingCallOffer,
    getPeerConnection,
    getUserActiveChatData,
    getEventId,
} from 'store/selectors';
import {
    setParticipantStreamStatus,
    clearRemoteUserInfo,
    sessionExpired,
    videoConferenceCompanionStatusChange,
    setPeerConnection,
    handleReceivedAnswer,
    endOneToOneCall,
    getCompanionStream,
} from 'store/actions';
import {SIGNALING_STATE_TYPES} from 'constants/organizer/sessions';
import {eventStatistics} from 'Api';

const usePeerConnection = (videoRef) => {
    const dispatch = useDispatch();
    const {capture, companionId, iceCandidates, isCalling, iceServers} = useSelector(getActiveCallTypeData);
    const peers = useSelector(getPeerConnection);
    const incomingCallOffer = useSelector(getIncomingCallOffer);
    const userId = useSelector(getUserId);
    const activeChatData = useSelector(getUserActiveChatData);
    const eventId = useSelector(getEventId);

    const [isParticipantCameraOff, setIsParticipantCameraOff] = useState(false);

    const createPeerConnection = useCallback(
        (companionId, iceServers) => {
            const pc = new RTCPeerConnection({iceServers});

            pc.onicecandidate = ({candidate}) => {
                if (candidate) {
                    p2pCallCandidateRequest({from: userId, to: companionId, candidate});
                }
            };

            pc.ontrack = (e) => {
                videoRef.current.onloadedmetadata = () => {
                    // called when the first frame is rendered.
                    const {oneToOneCalls} = eventStatistics(eventId);
                    oneToOneCalls(eventId);
                    dispatch(setParticipantStreamStatus(true));
                };

                dispatch(getCompanionStream(e.streams[0]));
            };

            pc.oniceconnectionstatechange = ({currentTarget: {iceConnectionState}}) => {
                let clearTimer;
                const isInternetConnectionOn = window.navigator.onLine;

                if (iceConnectionState === SIGNALING_STATE_TYPES.disconnected) {
                    clearTimer = setTimeout(
                        () =>
                            batch(() => {
                                dispatch(clearRemoteUserInfo());
                                dispatch(
                                    !isInternetConnectionOn ? sessionExpired() : videoConferenceCompanionStatusChange()
                                );
                            }),
                        15000
                    );
                }

                if (iceConnectionState === SIGNALING_STATE_TYPES.connected) clearTimeout(clearTimer);
            };

            dispatch(setPeerConnection({companionId, pc}));

            return pc;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [userId, videoRef, iceCandidates]
    );

    const call = useCallback(
        async (companionId, peers, iceServers) => {
            if (peers?.companionId === companionId || peers?.isEnded) {
                return;
            }

            const pc = createPeerConnection(companionId, iceServers);

            capture.getTracks().forEach((t) => pc.addTrack(t, capture));

            const offer = await pc.createOffer();
            await pc.setLocalDescription(offer);

            p2pCallOfferRequest({from: userId, to: companionId, offer});

            dispatch(setPeerConnection({companionId, pc}));

            callUsers(companionId);
        },
        [capture, createPeerConnection, dispatch, userId]
    );

    const callOfferRespond = useCallback(
        async (data) => {
            if (peers?.companionId !== data.userId || isCalling) {
                const pc = createPeerConnection(data.userId, data.iceServers);

                capture.getTracks().forEach((t) => pc.addTrack(t, capture));

                await pc.setRemoteDescription({
                    type: data.offer.type,
                    sdp: data.offer.sdp,
                });

                iceCandidates.length && iceCandidates.forEach(async (candidate) => await pc.addIceCandidate(candidate));

                const answer = await pc.createAnswer();
                await pc.setLocalDescription(answer);

                p2pCallAnswerRequest({from: userId, to: data.userId, answer});

                dispatch(setPeerConnection({companionId: data.userId, pc}));
            }

            if (peers?.pc && peers?.companionId === data.userId && !isCalling) {
                const pc = peers.pc;

                await pc.setRemoteDescription({
                    type: data.offer.type,
                    sdp: data.offer.sdp,
                });

                const answer = await pc.createAnswer();
                await pc.setLocalDescription(answer);

                p2pCallAnswerRequest({from: userId, to: data.userId, answer});

                dispatch(setPeerConnection({companionId: data.userId, pc}));
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [capture, createPeerConnection, userId, peers, iceCandidates, activeChatData]
    );

    useEffect(() => {
        p2pCallAnswerReceived((err, data) => dispatch(handleReceivedAnswer(data)));
        participantHasLeft((err, data) => dispatch(endOneToOneCall(data)));
        participantVideoEnabled(() => setIsParticipantCameraOff(false));
        participantVideoDisabled(() => setIsParticipantCameraOff(true));
    }, [dispatch]);

    useEffect(() => {
        if (incomingCallOffer && capture) {
            callOfferRespond(incomingCallOffer);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [capture, incomingCallOffer]);

    useEffect(() => {
        if (iceServers && capture) call(companionId, peers, iceServers);
    }, [call, capture, companionId, iceServers, peers]);

    return {isCameraOff: isParticipantCameraOff};
};

export default usePeerConnection;
