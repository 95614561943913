import React from 'react';

import Media from '../../../../../../../../../../../Media/Media';
import IconArrowTop from './components/IconArrowTop/IconArrowTop';

import {usePlatformTranslation} from '../../../../../../../../../../../../../services/hooks';

const BusinessCardLogoUploadingPreview = ({media}) => {
    const {businessCard} = usePlatformTranslation();

    if (!media) {
        return (
            <div className="BusinessCardLogoUploading__preview">
                <div className="BusinessCardLogoUploading__previewEmpty">
                    <div className="BusinessCardLogoUploading__previewArrow">
                        <IconArrowTop />
                    </div>

                    <div className="BusinessCardLogoUploading__previewText">{businessCard.uploadImage}</div>
                </div>
            </div>
        );
    }

    return (
        <div className="BusinessCardLogoUploading__preview">
            <Media media={media} isAdaptable />
        </div>
    );
};

export default BusinessCardLogoUploadingPreview;
