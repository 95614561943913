import classNames from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';
import clsx from 'clsx';
import {getTimeAgoString} from 'services/utils';
import {useUserStatus} from 'services/hooks';

import './PrivateChatPreview.scss';

const PrivateChatPreview = ({activeChatRoomId, onOpenPrivateChat, privateChatData, languages}) => {
    const user = useSelector((state) => state.user.data);
    const timeAgoString = getTimeAgoString(privateChatData.lastMessageAt, languages.platformLanguage);
    const chat = privateChatData;
    let privateChatPartnerData;
    if (user._id === chat.user_1._id) {
        privateChatPartnerData = chat.user_2;
    } else {
        privateChatPartnerData = chat.user_1;
    }

    const chatName = privateChatPartnerData.first + ' ' + privateChatPartnerData.last.charAt(0) + '.';

    let lastMessageText = privateChatData.lastMessageText;

    if (lastMessageText) {
        lastMessageText = lastMessageText.replace(/'/g, '');
    }

    const {getUserStatus} = useUserStatus();

    const handleOpenPrivateChat = (privateChatId) => {
        onOpenPrivateChat(privateChatId);
    };

    return (
        <div
            className={classNames(
                'private-chat',
                'user__status',
                `user__status_${getUserStatus(privateChatPartnerData?._id)}`
            )}
            color={chat._id === activeChatRoomId ? 'primary' : 'default'}
            id={chat._id}
            key={chat._id}
            onClick={() => handleOpenPrivateChat(chat._id)}
        >
            <div
                className="avatar"
                style={{
                    backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${privateChatPartnerData.avatarSmall}')`,
                }}
            >
                {privateChatData.unread !== 0 ? (
                    <div className="online-button">
                        <span>{privateChatData.unread}</span>
                    </div>
                ) : null}
            </div>
            <div className="chat-name">
                <p className="private-chat-name">{chatName}</p>
                <p
                    className={clsx('last-message', activeChatRoomId && 'last-message--opened')}
                    dangerouslySetInnerHTML={{__html: lastMessageText}}
                />
            </div>
            <div>
                <p className="time-ago">{timeAgoString}</p>
            </div>
            <div className="all-chats__user-wrapper-separator" />
        </div>
    );
};

export default PrivateChatPreview;
