import React, {useEffect, useState, useCallback, useRef} from 'react';
import {useSelector} from 'react-redux';
import {Scrollbars} from 'react-custom-scrollbars';

import Spinner from '../Spinner';
import SinglePoll from './components/SinglePoll/SinglePoll';

import './AuditorumPollsOutput.scss';
import {usePlatformTranslation} from 'services/hooks';
import {getUserId, getEventId} from 'store/selectors';

export const AuditorumPollsOutput = ({auditoriumId, programId, timeslotPolls, onGetTimeslotPolls, isLoading}) => {
    const eventId = useSelector(getEventId);
    const userId = useSelector(getUserId);
    const [polls, setPolls] = useState([]);
    const pollsRef = useRef(polls);
    pollsRef.current = polls;
    const translation = usePlatformTranslation();

    const getTimeslotPolls = useCallback(() => {
        if (!isLoading) onGetTimeslotPolls({auditoriumId, timeslotId: programId, toDeserialize: false});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onGetTimeslotPolls, auditoriumId, programId]);

    useEffect(() => {
        getTimeslotPolls();
    }, [getTimeslotPolls]);

    const setTimeslotPolls = useCallback(() => {
        if (!timeslotPolls || !timeslotPolls.length) return;

        setPolls(timeslotPolls);
    }, [timeslotPolls]);

    useEffect(() => {
        setTimeslotPolls();
    }, [setTimeslotPolls]);

    const renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `rgb(137,137,137)`,
            left: 0,
        };
        return <div className="grey-scroll" style={{...style, ...thumbStyle}} {...props} />;
    };

    return (
        <div className="polls-container">
            {isLoading ? (
                <Spinner />
            ) : polls.some((i) => !i.hidden) ? (
                <Scrollbars
                    className="scrollbar"
                    renderThumbVertical={renderThumb}
                    style={{width: '100%', height: '100%'}}
                >
                    {polls.map((poll) => {
                        return poll.hidden ? null : (
                            <SinglePoll
                                key={poll._id}
                                poll={poll}
                                userId={userId}
                                programId={programId}
                                eventId={eventId}
                            />
                        );
                    })}
                </Scrollbars>
            ) : (
                <div className="poll">
                    <div className="poll_header">
                        <div className="poll_title">{translation.polls.noPollsActive}</div>
                    </div>
                </div>
            )}
        </div>
    );
};
