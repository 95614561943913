import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
    },
    button: {
        fontWeight: '800',
        fontSize: 18,
        color: theme.palette.secondary.main,
        background: theme.palette.grey[0],
        border: `1px solid ${theme.palette.secondary.main}`,
        textTransform: 'uppercase',
        boxSizing: 'border-box',
        borderRadius: '8px 8px 0px 0px',
        position: 'relative',
        minHeight: 38,
        minWidth: 39,
        height: 38,
        width: 39,
        lineHeight: 0,
        marginRight: 5,
        '& .MuiButton-label': {
            minWidth: 39,
            width: 190,
        },
        '& svg path': {
            fill: theme.palette.secondary.main,
        },
    },
    active: {
        color: theme.palette.grey[0],
        background: theme.palette.secondary.main,
        height: 'auto',
        width: 'auto',
        '& $endIcon svg path': {
            fill: theme.palette.grey[0],
        },
        '&:hover, &:focus': {
            color: theme.palette.grey[0],
            background: theme.palette.secondary.main,
        },
    },
    endIcon: {
        transform: 'rotate(90deg)',
        '& svg path': {
            fill: theme.palette.secondary.main,
        },
        '[dir="rtl"] &': {
            marginRight: 5,
        },
    },
    arrow: {
        color: theme.palette.secondary.main,
        fontSize: 22,
    },
    tooltip: {
        border: `1px solid ${theme.palette.secondary.main}`,
        backgroundColor: theme.palette.grey[0],
        textTransform: 'uppercase',
        color: theme.palette.text.primary,
    },
    tooltipTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: '500',
        fontSize: 14,
    },
    tooltipIcon: {
        marginRight: 7,
    },
    hide: {
        display: 'none',
    },
    label: {
        transform: 'rotate(-180deg) translateZ(0)',
    },
}));
