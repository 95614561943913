import React, {useEffect, useState} from 'react';
import {Box, CircularProgress, makeStyles} from '@material-ui/core';
import {useSelector} from 'react-redux';
import {usePlatformTranslation} from 'services/hooks';
import {Api} from 'Api';
import SurveyForm from './components/SurveyForm/SurveyForm';
import SurveyHeader from './components/SurveyHeader/SurveyHeader';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background: theme.palette.background.default,
        flexDirection: 'column',
        position: 'relative',
        height: 'calc(100vh - 51px)',
    },
    content: {
        flexGrow: 1,
    },
    text: {
        fontSize: 16,
    },
}));

const Survey = () => {
    const classes = useStyles();
    const t_noSurvey = usePlatformTranslation((translation) => translation.survey.noSurvey);
    const {eventId} = useSelector((state) => state.event);
    const thankMessage = useSelector((state) => state.event.data.surveyThankYouMessage);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [surveyPassed, setSurveyPasses] = useState(false);

    const [fieldsData, setFieldsData] = useState(null); // all data
    const [fields, setFields] = useState(null); // for answers

    useEffect(() => {
        const getSurvey = async () => {
            try {
                setLoading(true);
                const {survey} = await Api.request({url: `/event/${eventId}/survey`, method: 'get', loading: false});

                setSurveyPasses(!survey.length);
                setFieldsData(survey);

                const formFields = survey.map((field) => ({_id: field._id, value: ''}));
                setFields(formFields);

                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(true);
            }
        };
        getSurvey();
    }, [eventId]);

    const onPassSurvey = () => {
        setSurveyPasses(true);
    };

    return (
        <div className={classes.root}>
            <SurveyHeader />
            <div className={classes.content}>
                {loading && (
                    <Box display="flex" width="100" justifyContent="center" p={2} alignItems="center">
                        <CircularProgress />
                    </Box>
                )}
                {!loading && (error || !fieldsData) && <p className={classes.text}>{t_noSurvey}</p>}
                {!loading &&
                    !error &&
                    fieldsData &&
                    fields &&
                    (!surveyPassed ? (
                        <SurveyForm onPassSurvey={onPassSurvey} fieldsData={fieldsData} formFields={fields} />
                    ) : (
                        <p className={classes.text}>{thankMessage}</p>
                    ))}
            </div>
        </div>
    );
};

export default Survey;
