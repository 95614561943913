import {USER_ROLES} from '../../constants/shared';

//TODO: fix it on backend
const normalizeRoles = (userRoles) => [...new Set(userRoles)]; //here we remove dublicates

export const getUserRole = (user, eventId) => {
    if (!user.eventRoles) return null;

    const eventRole = user.eventRoles.find(({event: {_id}}) => _id === eventId);

    return normalizeRoles(eventRole?.roles);
};

export const isUserCoOrganizer = (user, eventId) => {
    if (!user || !eventId) return false;
    const eventRoles = getUserRole(user, eventId);

    return eventRoles.includes(USER_ROLES.coOrganizer);
};
