import React from 'react';
import {TextValidator} from 'react-material-ui-form-validator';

const TextareaField = ({field, handleChange, variant}) => (
    <TextValidator
        className="field-container"
        label={field.label}
        name={field.name}
        value={field.value}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        validators={field.validators}
        errorMessages={field.errorMessages}
        margin="normal"
        variant={variant || 'filled'}
        rows={6}
        multiline
        fullWidth
    />
);

export default TextareaField;
