import React, {useCallback, useEffect, useState} from 'react';

import BusinessWalletUser from '../BusinessWalletUser';

import {usePlatformTranslation, useUserCompany} from 'services/hooks';

const BusinessWalletCategory = ({users, filter, category}) => {
    const [usersList, setUsersList] = useState(users);
    const [usersFiltered, setUsersFiltered] = useState(false);
    const {getCompanyByUserId} = useUserCompany();
    const translation = usePlatformTranslation();

    const filterByName = useCallback(
        (users) => {
            if (!filter) return users;
            return users.filter((user) => {
                const name = `${user.user.first} ${user.user.last}`;
                return name.toLowerCase().includes(filter.toLowerCase());
            });
        },
        [filter]
    );

    useEffect(() => {
        const filteredByName = filterByName(users);
        setUsersList(filteredByName);
        setUsersFiltered(filteredByName);
    }, [filterByName, users, category]);

    if (!usersList?.length || !usersFiltered) {
        return <span className="wallet_user">{translation.businessWallet.empty}</span>;
    }

    return usersList.map(({_id, user, businessCard}) => (
        <BusinessWalletUser
            key={_id}
            id={_id}
            user={user}
            company={getCompanyByUserId(user._id)}
            businessCardId={businessCard}
            category={category}
            requestId={_id}
            friendConnectionReceived
        />
    ));
};

export default BusinessWalletCategory;
