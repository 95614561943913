import React from 'react';

const IconSettings = () => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.1401 10.94C17.1801 10.64 17.2001 10.33 17.2001 10C17.2001 9.68003 17.1801 9.36002 17.1301 9.06002L19.1601 7.48002C19.3401 7.34002 19.3901 7.07002 19.2801 6.87002L17.3601 3.55002C17.2401 3.33002 16.9901 3.26002 16.7701 3.33002L14.3801 4.29002C13.8801 3.91002 13.3501 3.59002 12.7601 3.35002L12.4001 0.810024C12.3601 0.570024 12.1601 0.400024 11.9201 0.400024H8.08011C7.84011 0.400024 7.65011 0.570024 7.61011 0.810024L7.25011 3.35002C6.66011 3.59002 6.12011 3.92002 5.63011 4.29002L3.24011 3.33002C3.02011 3.25002 2.77011 3.33002 2.65011 3.55002L0.74011 6.87002C0.62011 7.08002 0.66011 7.34002 0.86011 7.48002L2.89011 9.06002C2.84011 9.36002 2.80011 9.69002 2.80011 10C2.80011 10.31 2.82011 10.64 2.87011 10.94L0.84011 12.52C0.66011 12.66 0.61011 12.93 0.72011 13.13L2.64011 16.45C2.76011 16.67 3.01011 16.74 3.23011 16.67L5.62011 15.71C6.12011 16.09 6.65011 16.41 7.24011 16.65L7.60011 19.19C7.65011 19.43 7.84011 19.6 8.08011 19.6H11.9201C12.1601 19.6 12.3601 19.43 12.3901 19.19L12.7501 16.65C13.3401 16.41 13.8801 16.09 14.3701 15.71L16.7601 16.67C16.9801 16.75 17.2301 16.67 17.3501 16.45L19.2701 13.13C19.3901 12.91 19.3401 12.66 19.1501 12.52L17.1401 10.94ZM10.0001 13.6C8.02011 13.6 6.40011 11.98 6.40011 10C6.40011 8.02002 8.02011 6.40002 10.0001 6.40002C11.9801 6.40002 13.6001 8.02002 13.6001 10C13.6001 11.98 11.9801 13.6 10.0001 13.6Z"
                fill="#2B2B2B"
            />
        </svg>
    );
};

export default IconSettings;
