import {useSelector} from 'react-redux';
import {getUsersStatuses} from 'store/selectors';
import {userStatuses} from 'constants/data';

const useUserStatus = () => {
    const statuses = useSelector(getUsersStatuses);

    return {
        getUserStatus: (userId) => statuses[userId] || userStatuses.offline,
    };
};

export default useUserStatus;
