import React, {useEffect} from 'react';
import PropTypes from 'prop-types';

function LinkedInBadge({url}) {
    useEffect(() => {
        let profile = url;
        if (profile.endsWith('/')) {
            profile.slice(0, -1);
        }
        profile = profile.split('/').pop();
        let customIframe = document.createElement('iframe');
        customIframe.src = 'about:blank';
        let badge = `<html>
        <head>
        <link rel="stylesheet" href="style.css"/>
        <style>
        .profile-badge--width-330{
            width: 315px !important;
            padding-top:15px;
        }
        </style>
        </head>
        <body>
            <div
            style='overflow: hidden; width: 100%;'
            class="badge-base LI-profile-badge"
            data-locale="en_US"
            data-size="large"
            data-theme="dark"
            data-type="HORIZONTAL"
            data-vanity="${profile}"
            data-version="v1"
        >
            <a class="badge-base__link LI-simple-link" href='https://ua.linkedin.com/in/${profile}?trk=profile-badge'> </a>
        </div>
        <script async defer src='https://platform.linkedin.com/badges/js/profile.js'></script>
        </body>
        </html>`;
        customIframe.srcdoc = badge;
        customIframe.style = `
        overflow: hidden;
        height: 305px;
        width: 315px;
        padding: 0;
        margin: 10px 0;
        border: none;
        outline: none;`;
        let body = window.document.getElementById('linkedInBadge');
        body.insertBefore(customIframe, body.firstChild);
    }, [url]);

    return (
        <div>
            <div id="linkedInBadge"></div>
        </div>
    );
}

LinkedInBadge.propTypes = {
    url: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};

export default LinkedInBadge;
