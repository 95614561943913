import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, AppBar, makeStyles, Typography} from '@material-ui/core';

import ChatsTabs from './components/ChatsTabs/ChatsTabs';
import {moreMenuType} from './chatsTypes';
import {setNotArchivedChats} from '../../../../../../../../../store/actions/userActions';
import ArchivedMoreMenu from './components/ChatsMoreMenu/components/ArchiveMoreMenu/ArchiveMoreMenu';
import MainMoreMenu from './components/ChatsMoreMenu/components/MainMoreMenu/MainMoreMenu';
import {ReactComponent as CloseIcon} from '../../../../../../../../../Images/close.svg';
import {ReactComponent as Back} from '../../../../../../../../../Images/back.svg';
import Button from '../../../../../../../Button/Button';
import {usePlatformTranslation} from '../../../../../../../../../services/hooks';
import classNames from 'classnames';

const useStyles = makeStyles(() => ({
    root: {
        background: '#fff',
        position: 'relative',
        zIndex: 'inherit',
    },
    title: {
        fontWeight: '600',
        textTransform: 'uppercase',
        color: '#777081',
    },
    chatsHeader: {
        paddingLeft: '16px',
        '&.isRtl': {
            paddingLeft: '0',
            paddingRight: '16px',
        },
    },
}));

const ChatsHeader = ({handleCreateNewChat}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {isMobile} = useSelector((state) => state.layout);
    const {archivedChats} = useSelector((state) => state.user.chats);
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);

    const [activeChatTab, setActiveChatTab] = useState(0);
    const translation = usePlatformTranslation();

    const handleChangeChatTab = (event, newValue) => {
        setActiveChatTab(newValue);
    };

    const setMainPage = () => {
        dispatch(setNotArchivedChats());
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="transparent">
                {archivedChats ? (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        className={classNames(classes.chatsHeader, {isRtl: isRtlLanguage})}
                    >
                        <Button onClick={setMainPage} icon={isMobile ? <CloseIcon /> : <Back />} />
                        <Typography className={classes.title} data-testid="archived-chats-tab">
                            {translation.chatsDropdown.archivedChats}
                        </Typography>
                        <ArchivedMoreMenu />
                    </Box>
                ) : (
                    <Box display="flex" justifyContent="space-between">
                        <ChatsTabs handleChange={handleChangeChatTab} activeTab={activeChatTab} />
                        <MainMoreMenu handleCreateNewChat={handleCreateNewChat} type={moreMenuType.main} />
                    </Box>
                )}
            </AppBar>
        </div>
    );
};

export default ChatsHeader;
