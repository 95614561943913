import {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {getTimeslotDataSuccess} from 'store/actions';

import {
    connectToTimeslot,
    disconnectFromTimeslot,
    timeslotStreamPublished,
    timeslotStreamUnpublished,
    timeslotResourcesAllocated,
    timeslotResourcesFailed,
    timeslotResourcesHold,
    timeslotResourcesHolding,
    timeslotStreamStopped,
} from 'Api/socketApi';

const useTimeslotSockets = ({sessionId, isTimeslotSetup, isActiveTimeslot}) => {
    const dispatch = useDispatch();

    const sessionStatusUpdated = useCallback(() => {
        timeslotStreamPublished((err, data) => dispatch(getTimeslotDataSuccess(data)));
        timeslotStreamUnpublished((err, data) => dispatch(getTimeslotDataSuccess(data)));
        timeslotResourcesAllocated((err, data) => dispatch(getTimeslotDataSuccess(data)));
        timeslotResourcesFailed((err, data) => dispatch(getTimeslotDataSuccess(data)));
        timeslotResourcesHold((err, data) => dispatch(getTimeslotDataSuccess(data)));
        timeslotResourcesHolding((err, data) => dispatch(getTimeslotDataSuccess(data)));
        timeslotStreamStopped((err, data) => dispatch(getTimeslotDataSuccess(data)));
    }, [dispatch]);

    useEffect(() => {
        if (isTimeslotSetup && !isActiveTimeslot) return;
        connectToTimeslot({timeslotId: sessionId});

        return () => {
            disconnectFromTimeslot({timeslotId: sessionId});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, isTimeslotSetup, isActiveTimeslot]);

    useEffect(() => {
        sessionStatusUpdated();
    }, [sessionStatusUpdated]);
};

export default useTimeslotSockets;
