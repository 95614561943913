import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {UnfoldMore as UnfoldMoreIcon, UnfoldLess as UnfoldLessIcon, Close as CloseIcon} from '@material-ui/icons';
import {expandedChatsOnVideoConference, topNavSeeChats} from '../../../../../../../../../store/actions';

import './DropdownActions.scss';

const DropdownActions = () => {
    const dispatch = useDispatch();
    const {topNavigation, maximizeChats} = useSelector((state) => state.user);

    const openChats = () => {
        dispatch(topNavSeeChats());
    };

    const openConference = () => {
        dispatch(expandedChatsOnVideoConference());
    };

    return (
        <div className="dropdown-actions-chats">
            <div>
                {topNavigation.seeChats && (
                    <div className="close-dropdown close" onClick={openChats}>
                        <CloseIcon />
                    </div>
                )}
                <div className="close-dropdown expand" onClick={openConference}>
                    {maximizeChats.seeExpandedVideoConference ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
                </div>
            </div>
        </div>
    );
};

export default DropdownActions;
