import {initialState} from '../initialState';
import * as actionTypes from '../../../actionTypes/organizerActionTypes/accessManagement';
import {sliceHelper, updateObject} from '../../../utilityStateFunction';
import {ACCESS_PACKAGE_GROUP_TYPES} from 'constants/shared';

const reducer = (state, {type, payload}) => {
    if (state === undefined) {
        return initialState.eventAccessManagement;
    }
    switch (type) {
        case actionTypes.UPDATE_ACCESS_RESOURCES_START:
        case actionTypes.GET_GROUPS_LIST_START:
        case actionTypes.ADD_ACCESS_GROUP_START:
        case actionTypes.UPDATE_ACCESS_GROUP_START:
        case actionTypes.SET_ACCESS_GROUP_IMAGE_START:
        case actionTypes.RESET_ACCESS_GROUP_IMAGE_START:
        case actionTypes.DUPLICATE_ACCESS_GROUP_START:
        case actionTypes.DELETE_ACCESS_GROUP_START:
        case actionTypes.SET_DEFAULT_ACCESS_GROUP_START:
            return updateObject(state.eventAccessManagement, {
                loading: true,
            });

        case actionTypes.GET_GROUPS_PARTICIPANTS_LIST_SUCCESS:
            return updateObject(state.eventAccessManagement, {
                participantsGroups: payload,
                loading: false,
            });

        case actionTypes.GET_GROUPS_EXHIBITORS_LIST_SUCCESS:
            return updateObject(state.eventAccessManagement, {
                exhibitorsGroups: payload,
                loading: false,
            });

        case actionTypes.SET_SELECTED_ACCESS_GROUP:
            return updateObject(state.eventAccessManagement, {
                selectedGroup: payload,
            });

        case actionTypes.UPDATE_ACCESS_RESOURCES_SUCCESS: {
            const {packages, groupType, formId} = payload;
            const isParticipant = groupType === ACCESS_PACKAGE_GROUP_TYPES.participant;

            const groups = isParticipant
                ? state.eventAccessManagement.participantsGroups
                : state.eventAccessManagement.exhibitorsGroups;

            const groupIndex = groups.findIndex((group) => group._id === formId);
            const group = groups.find((group) => group._id === formId);

            const updatedGroup = {...group, accessPackages: packages};
            const updatedGroupsArr = sliceHelper(groups, groupIndex, updatedGroup);

            const newGroups = isParticipant
                ? {participantsGroups: updatedGroupsArr}
                : {exhibitorsGroups: updatedGroupsArr};

            return updateObject(state.eventAccessManagement, {
                loading: false,
                selectedGroup: updatedGroup,
                ...newGroups,
            });
        }
        case actionTypes.RESET_ACCESS_GROUP_IMAGE_SUCCESS:
        case actionTypes.SET_ACCESS_GROUP_IMAGE_SUCCESS: {
            const {form, groupType} = payload;

            const isParticipant = groupType === ACCESS_PACKAGE_GROUP_TYPES.participant;

            const groups = isParticipant
                ? state.eventAccessManagement.participantsGroups
                : state.eventAccessManagement.exhibitorsGroups;

            const groupIndex = groups.findIndex((group) => group._id === form._id);
            const group = groups[groupIndex];

            const updatedGroup = {...group, image: form.image};
            const updatedGroupsArr = sliceHelper(groups, groupIndex, updatedGroup);

            const newGroups = isParticipant
                ? {participantsGroups: updatedGroupsArr}
                : {exhibitorsGroups: updatedGroupsArr};

            return updateObject(state.eventAccessManagement, {
                loading: false,
                selectedGroup: updatedGroup,
                ...newGroups,
            });
        }

        case actionTypes.UPDATE_ACCESS_RESOURCES_FAIL:
        case actionTypes.GET_GROUPS_LIST_FAIL:
        case actionTypes.ADD_ACCESS_GROUP_FAIL:
        case actionTypes.UPDATE_ACCESS_GROUP_FAIL:
        case actionTypes.SET_ACCESS_GROUP_IMAGE_FAIL:
        case actionTypes.RESET_ACCESS_GROUP_IMAGE_FAIL:
        case actionTypes.DUPLICATE_ACCESS_GROUP_FAIL:
        case actionTypes.DELETE_ACCESS_GROUP_FAIL:
        case actionTypes.SET_DEFAULT_ACCESS_GROUP_FAIL:
            return updateObject(state.eventAccessManagement, {
                loading: true,
            });

        default:
            return state.eventAccessManagement;
    }
};

export default reducer;
