export function getExtention(fileName, formatsArray) {
    let i = fileName.lastIndexOf('.');
    let extension = fileName.slice(i);
    let error = null;

    if (i === -1) {
        return false;
    }
    if (!formatsArray.some((format) => format === extension.toLowerCase())) {
        error = `Please provide image with one of these extensions: ${formatsArray.join(', ')}`;
    }

    return error;
}

export function getSize(file, maxSizeMB) {
    // check if field is empty
    if (file === undefined) {
        return;
    }
    let FileSize = file.size / 1000000; // in MB

    let error;
    if (FileSize > maxSizeMB) {
        error = `File size exceeds ${maxSizeMB} MB`;
    } else {
        error = null;
    }
    return error;
}
