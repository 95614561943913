import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Menu, MenuItem, IconButton} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import IconExport from '../../Icons/IconExport';
import IconArchive from '../../Icons/IconArchive';
import {useStyles} from '../MoreMenu.styles';
import {
    setArchivedChats,
    getArchivedChats,
    closeActiveChat,
} from '../../../../../../../../../../../../../store/actions/userActions';
import {usePlatformTranslation} from '../../../../../../../../../../../../../services/hooks';
import {MenuItemNewChat} from '../MenuItems';

const MainMoreMenu = ({handleCreateNewChat}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {isMobile} = useSelector((state) => state.layout);
    const translation = usePlatformTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const {
        chats: {eventChats},
        data: {_id},
    } = useSelector((state) => state.user);

    const {eventId} = useSelector((state) => state.event);

    const openArchivedChats = () => {
        if (isMobile) {
            dispatch(closeActiveChat());
        }

        dispatch(setArchivedChats());
        dispatch(getArchivedChats());
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleNewChatsClick = () => {
        if (isMobile) {
            dispatch(closeActiveChat());
        }
        handleCreateNewChat();
        handleClose();
    };

    return (
        <>
            <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>

            <Menu
                id="chat-more-menu"
                aria-label="more-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.root}
            >
                <MenuItemNewChat onClick={handleNewChatsClick} />
                <MenuItem className={classes.item} onClick={openArchivedChats}>
                    <IconArchive />
                    <span className={classes.itemText}>{translation.chatsDropdown.archivedChats}</span>
                </MenuItem>
                <MenuItem className={classes.item} onClick={handleClose}>
                    <a
                        type="button"
                        download={true}
                        target={'_blank'}
                        href={`${process.env.REACT_APP_REQUESTS_API}/users/${_id}/chats/${
                            eventChats ? eventId + '/' : ''
                        }download`}
                        rel="noopener noreferrer"
                    >
                        <IconExport />
                        <span className={classes.itemText}>{translation.chatsDropdown.dowloadUsersList}</span>
                    </a>
                </MenuItem>
            </Menu>
        </>
    );
};

export default MainMoreMenu;
