import React from 'react';
import {useStyles} from './NoResult.style';
import EmptyBoxIcon from '../../../../../../../../../Images/EmptyBoxIcon';
import {usePlatformTranslation} from '../../../../../../../../../services/hooks';
import useTheme from '@material-ui/core/es/styles/useTheme';

export default function NoResult({exploreMore, text}) {
    const theme = useTheme();
    const style = useStyles();
    const {eventsListing} = usePlatformTranslation();

    return (
        <div className={style.container}>
            <div className={style.images}>
                <div>
                    <EmptyBoxIcon primary={theme.palette.primary.main} secondary={theme.palette.secondary.main} />
                </div>
            </div>
            <div className={style.footer}>
                <div className={style.text}>{`${text}`}</div>
                <div
                    onClick={() => {
                        exploreMore && exploreMore();
                    }}
                    className={style.link}
                >
                    {`${eventsListing.exploreMoreEvents}`}
                </div>
            </div>
        </div>
    );
}
