import React, {forwardRef} from 'react';
import {usePlatformTranslation} from '../../../../../../../../../../../../../services/hooks';
import {useStyles} from '../MoreMenu.styles';
import clsx from 'clsx';
import {MenuItem} from '@material-ui/core';
import IconExport from '../../Icons/IconExport';

const MenuItemExport = forwardRef(({onClick, className, isArchived, chatId, chatType, ...props}, ref) => {
    const classes = useStyles();
    const translation = usePlatformTranslation();
    return (
        <MenuItem
            ref={ref}
            className={clsx(classes.item, className)}
            disabled={!!isArchived}
            onClick={onClick}
            {...props}
        >
            <a
                type="button"
                className="wallet-button wallet-inner-padding"
                download={true}
                target={'_blank'}
                href={`${process.env.REACT_APP_REQUESTS_API}/${chatType}-chat/${chatId}/download`}
                rel="noopener noreferrer"
            >
                <IconExport />
                <span className={classes.itemText}>{translation.chatsDropdown.exportChat}</span>
            </a>
        </MenuItem>
    );
});

export default MenuItemExport;
