import React from 'react';
import moment from 'moment';
import {Lock as LockIcon} from '@material-ui/icons';

import AuditoriumPageContainer from '../AuditoriumPageContainer';
import AuditorumsSlider from '../AuditoriumsSlider/AuditoriumsSlider';
import AuditoriumBackgroundImage from '../AuditoriumBackgroundImage/AuditoriumBackgroundImage';
import AuditoriumVideoPlayer from '../AuditoriumVideoPlayer';
import AuditoriumBanners from '../AuditoriumBanners/AuditoriumBanners';
import AuditoriumNavigation from '../AuditoriumNavigation/AuditoriumNavigation';
import AuditoriumNavigationMobile from '../AuditoriumNavigationMobile/AuditoriumNavigationMobile';
import AuditoriumSlotTitle from '../AuditoriumSlotTitle/AuditoriumSlotTitle';
import AuditoriumSnackbar from '../AuditoriumSnackbar/AuditoriumSnackbar';
import BreadcrumbsNavigation from '../BreadcrumbsNavigation';
import NoBuildingAccess from '../NoBuildingAccess/NoBuildingAccess';

import {connectToAuditorium, disconnectToAuditorium} from 'Api/socketApi';
import {
    checkIfEventHasEnded,
    checkIfAuditoriumProgramHasEnded,
    getUtcTimestamp,
    getTimeslotStartTimestamp,
    getTimeslotEndTimestamp,
} from 'services/utils';

import WelcomeScreensDialog from '../WelcomeScreensDialog/WelcomeScreensDialog';
import AuditoriumNextVideo from './components/AuditoriumNextVideo';
import {sessionFolder} from 'config';
import {ETX, ZOOM, ETX_LIVE_SESSION} from 'constants/data/streaming-engine';
import {SESSION_TYPES} from 'constants/data/session-type';
import {LOCAL_STORAGE_AUDITORIUM_INDEX_KEY} from 'constants/shared';
import {generatePath} from 'react-router';
import {RouterConfig} from 'routerConfig';
import AuditoriumWallNavigation from './components/AuditoriumWallNavigation';
import {wallTabsList} from 'constants/auditorium';

const getStorageAuditoriumIndex = () => localStorage.getItem(LOCAL_STORAGE_AUDITORIUM_INDEX_KEY);

class Auditorium extends React.Component {
    eventHasEndedTimerId = 0;

    state = {
        auditoriumRoomIndex: 0,
        auditoriumTimeslots: [],
        currentTimeSlotIndex: -1,
        auditorumSpeachLanguage: null,
        isInterpretationEnabled: null,
        videoStreamingUrl: '',
        videoStreamingStartAt: 0,
        viewVideoWall: false,
        totalSecondsTillNextVideo: null,
        totalSecondsTillCurrentTimeSlotEnds: null,
        checkedVideoDuration: false,
        auditoriumProgramHasEnded: false,
        auditoriumHasVideos: true,
        currentTab: wallTabsList.WALL,
        translation: null,
        showAuditoriumNote: false,
    };

    componentDidMount() {
        const {eventId, onGetOnDemantVideos, onSetEventProtectedMenu, closeTopNavigation, user, event, building} =
            this.props;
        const dataSource = building || event;
        const storageAuditoriumIndex = getStorageAuditoriumIndex();

        onSetEventProtectedMenu();
        onGetOnDemantVideos();

        let getAuditoriumIndex = storageAuditoriumIndex === null ? 0 : +storageAuditoriumIndex;

        connectToAuditorium({
            userId: user._id,
            eventId,
            auditoriumId: dataSource.auditoriums[getAuditoriumIndex]?._id,
        });

        this.setTimeslots();

        // check if event has ended and also set up a timer that checks every 10 seconds if the event has ended
        this.checkIfEventHasEnded();
        this.startEventHasEndedCountdown();
        closeTopNavigation();
        window.addEventListener('beforeunload', this.clearEventHasEndedCountdown);

        // window.addEventListener("blur", this.tabBlured);
        this.setShowAuditoriumNote();
        this.setLanguagesData();

        this.handleGetTimeSlot();
    }

    componentDidUpdate(prevProps) {
        const {languages} = this.props;

        if (prevProps.languages.platformLanguage !== languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    componentWillUnmount() {
        const {closeSidebar, user, eventId, event, building} = this.props;
        const dataSource = building || event;
        const storageAuditoriumIndex = getStorageAuditoriumIndex();

        this.clearEventHasEndedCountdown();
        closeSidebar();
        window.removeEventListener('beforeunload', this.clearEventHasEndedCountdown);

        let getAuditoriumIndex = storageAuditoriumIndex === null ? 0 : +storageAuditoriumIndex;

        disconnectToAuditorium({
            userId: user._id,
            eventId,
            auditoriumId: dataSource.auditoriums[getAuditoriumIndex]?._id,
        });
        this.setState({auditoriumRoomIndex: +storageAuditoriumIndex});
    }

    setTimeslots = (updateTimeslot) => {
        const storageAuditoriumIndex = getStorageAuditoriumIndex();

        return this.setState({auditoriumRoomIndex: +storageAuditoriumIndex}, () =>
            this.setAuditoriumTimeslots(updateTimeslot)
        );
    };

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    setShowAuditoriumNote = () => {
        const userRolesInEvent = this.props.user.eventRoles.find((role) => role.event.slug === this.props.event.slug);
        const showAuditoriumNote = userRolesInEvent?.event?.showAuditoriumNote;

        if (showAuditoriumNote) {
            this.setState({
                showAuditoriumNote,
            });
        }
    };

    tabBlured = () => {
        // if the user moves to another tab, we clear all the countdowns
        this.clearEventHasEndedCountdown();
        // only if he leaves the tab we want to liste the 'focus' on tab event
        // if we liste to 'focus' in componentDidMount we fire the loadCurrentTimeSlot function 2 times and all the countdowns speed 2x
        // if the user moves to another tab, we have to trigger the loadCurrentTimeslot function when he focuses back to it
        // the loadCurrentTimeslot function also reRegisteres all the countodowns
        window.addEventListener('focus', this.loadCurrentTimeSlot);
    };

    // fallback for last time slots without video
    // once every 10 seconds we check if the event has ended
    startEventHasEndedCountdown = () => {
        this.eventHasEndedTimerId = setInterval(this.checkIfEventHasEnded, 10000);
    };

    clearEventHasEndedCountdown = () => clearInterval(this.eventHasEndedTimerId);

    handleGetTimeSlot = (auditoriumIndex) => {
        const {onGetTimeslotData, event, building, buildingId} = this.props;
        const storageAuditoriumIndex = getStorageAuditoriumIndex();
        let getAuditoriumIndex;

        if (!auditoriumIndex) {
            getAuditoriumIndex = !storageAuditoriumIndex ? 0 : +storageAuditoriumIndex;
        } else {
            getAuditoriumIndex = auditoriumIndex;
        }

        const currentTimeStamp = getUtcTimestamp(new Date());

        const currentAuditorium = building
            ? event.buildings.find((item) => item._id === buildingId)?.auditoriums[getAuditoriumIndex]
            : event.auditoriums[getAuditoriumIndex];

        let auditoriumTimeslots = [];

        currentAuditorium.dailyProgram.forEach((dayProgram) => {
            const currentProgramDay = dayProgram.date;
            dayProgram.program.forEach((timeslot) => {
                timeslot.date = currentProgramDay;
                auditoriumTimeslots.push(timeslot);
            });
        });

        const currentTimeSlotIndex = auditoriumTimeslots.findIndex((timeSlot) => {
            const timeSlotStartTimestamp = getTimeslotStartTimestamp(timeSlot.date, timeSlot, event.utcTimezone);
            const timeSlotEndTimestamp = getTimeslotEndTimestamp(timeSlot.date, timeSlot, event.utcTimezone);
            return currentTimeStamp >= timeSlotStartTimestamp && currentTimeStamp < timeSlotEndTimestamp;
        });

        let timeslotId = auditoriumTimeslots[currentTimeSlotIndex]?._id;

        if (!timeslotId && auditoriumTimeslots.length) {
            const futureTimeslot = auditoriumTimeslots.find((timeSlot) => {
                const timeSlotStartTimestamp = getTimeslotStartTimestamp(timeSlot.date, timeSlot, event.utcTimezone);

                return moment(timeSlotStartTimestamp, 'x').isAfter();
            });
            timeslotId = futureTimeslot?._id;
        }

        if (timeslotId) {
            onGetTimeslotData({
                auditoriumId: currentAuditorium?._id,
                timeslotId,
                callback: (timeslot) => this.setAuditoriumTimeslots(timeslot),
            });
        }
    };

    setAuditoriumTimeslots = (updateTimeslot) => {
        // this function will run at componentDidMount or when the user navigates through auditoriums
        // create an array with all timeslots of the current auditorium
        // make sure to also add to each timeslot the day when it runs
        const {event, building} = this.props;
        const dataSource = building || event;
        const currentAuditorium = dataSource.auditoriums[this.state.auditoriumRoomIndex];
        let auditorumSpeachLanguage = currentAuditorium.interpretationSourceLanguage;
        let isInterpretationEnabled = currentAuditorium.isInterpretationEnabled;

        let auditoriumTimeslots = [];
        currentAuditorium.dailyProgram.forEach((dayProgram) => {
            const currentProgramDay = dayProgram.date;
            dayProgram.program.forEach((timeslot) => {
                timeslot.date = currentProgramDay;
                if (timeslot._id === updateTimeslot?._id) {
                    updateTimeslot.date = currentProgramDay;
                    auditoriumTimeslots.push(updateTimeslot);
                }
                auditoriumTimeslots.push(timeslot);
            });
        });

        // after we have set up the timeslots for the auditorium, we trigger the loadCurrentTimeslot
        // so that we make sure we change the current timeslot properly
        this.setState(
            {
                auditorumSpeachLanguage,
                isInterpretationEnabled,
                auditoriumTimeslots: auditoriumTimeslots,
            },
            () => {
                this.loadCurrentTimeSlot();
            }
        );
    };

    checkIfEventHasEnded = () => {
        // check if event has ended
        // if yes, redirect to the video archive page
        const {event, building, buildingId, history, eventSlug} = this.props;
        const dataSource = building || event;

        if (event) {
            let eventHasEnded = checkIfEventHasEnded(dataSource, event.utcTimezone);
            if (eventHasEnded) {
                this.clearEventHasEndedCountdown();

                history.replace(
                    generatePath(
                        building
                            ? RouterConfig.event.village.buildingAuditoriumArchive
                            : RouterConfig.event.auditoriumArchive,
                        {eventSlug, buildingId}
                    )
                );
            }
        }
    };

    checkIfAuditoriumProgramHasEnded = () => {
        const {event, building} = this.props;
        const dataSource = building || event;

        this.setState({auditoriumProgramHasEnded: false}, () => {
            if (event) {
                let auditoriumProgramHasEnded = checkIfAuditoriumProgramHasEnded(
                    dataSource,
                    event.utcTimezone,
                    this.state.auditoriumRoomIndex
                );
                if (auditoriumProgramHasEnded) {
                    this.setState({auditoriumProgramHasEnded: true});
                }
            }
        });
    };

    checkIfAuditoriumHasVideos = () => {
        const {event, building} = this.props;
        const dataSource = building || event;

        if (event) {
            const currentAuditorium = dataSource.auditoriums[this.state.auditoriumRoomIndex];
            let allAuditoriumTimeslots = [];

            // we create an array with all the timeslots (for each day, we push the timeslots to allAuditoriumTimeslots)
            // if at least one timeslot has Videos, it means that the auditoriumHasVideos = true
            currentAuditorium.dailyProgram.forEach((dayProgram) => allAuditoriumTimeslots.push(...dayProgram.program));
            const programHasVideo = allAuditoriumTimeslots.filter(
                ({video, session, streamingEngine}) =>
                    video || !!session || streamingEngine === ZOOM || streamingEngine === ETX_LIVE_SESSION
            );

            this.setState({auditoriumHasVideos: programHasVideo.length > 0});
        }
    };

    loadCurrentTimeSlot = (videoDuration) => {
        this.checkIfEventHasEnded();
        this.checkIfAuditoriumProgramHasEnded();
        this.checkIfAuditoriumHasVideos();
        let currentTimeStamp = getUtcTimestamp(new Date());

        const {auditoriumTimeslots, auditoriumRoomIndex} = this.state;
        const {event, building, setHasVideoWall, setTimeSlotVideoWall, setActiveWall, seeVideoWall} = this.props;
        const dataSource = building || event;

        // first we check if we have a current running video
        // save the index in the program
        let currentTimeSlotIndex = auditoriumTimeslots.findIndex((timeSlot) => {
            let timeSlotStartTimestamp = getTimeslotStartTimestamp(timeSlot.date, timeSlot, event.utcTimezone);
            let timeSlotEndTimestamp = getTimeslotEndTimestamp(timeSlot.date, timeSlot, event.utcTimezone);
            const milisecondsFromStart = currentTimeStamp - timeSlotStartTimestamp;
            const secondsFromStart = Math.floor(milisecondsFromStart / 1000);
            if (currentTimeStamp >= timeSlotStartTimestamp && currentTimeStamp < timeSlotEndTimestamp) {
                // if this current Timeslot has video, we set up the video Url
                if (timeSlot.video && !timeSlot.isLiveStreaming) {
                    const videoUrl =
                        timeSlot.streamingEngine === ETX ? `${sessionFolder}${timeSlot.video}` : timeSlot.video;
                    this.setState({
                        videoStreamingUrl: videoUrl,
                        videoStreamingStartAt: secondsFromStart,
                    });
                } else if (timeSlot.video && timeSlot.isLiveStreaming) {
                    let videoUrl = timeSlot.video;
                    this.setState({videoStreamingUrl: videoUrl});
                } else if (
                    !timeSlot.video &&
                    timeSlot.session?.link &&
                    timeSlot.isLiveStreaming &&
                    timeSlot.session?.type === SESSION_TYPES.recorded
                ) {
                    let videoUrl = `${sessionFolder}${timeSlot.session.link}`;
                    this.setState({
                        videoStreamingUrl: videoUrl,
                        videoStreamingStartAt: secondsFromStart,
                    });
                } else if (
                    timeSlot.video &&
                    !timeSlot.isLiveStreaming &&
                    timeSlot.session?.type === SESSION_TYPES.recorded
                ) {
                    let videoUrl = `${sessionFolder}${timeSlot.session.video}`;
                    this.setState({
                        videoStreamingUrl: videoUrl,
                        videoStreamingStartAt: secondsFromStart,
                    });
                }
                return timeSlot;
            }

            return null;
        });

        if (currentTimeSlotIndex !== -1) {
            const timeSlot = auditoriumTimeslots[currentTimeSlotIndex];
            // we set up a timer for the snackbar that will open 10, 5, 2 minutes before the timeslotEnds
            // totalSecondsTillCurrentTimeSlotEnds will be used in AuditoriumSnackbar
            let timeSlotEndTimestamp = getTimeslotEndTimestamp(timeSlot.date, timeSlot, event.utcTimezone);
            let videoWillEndInNextSeconds = timeSlotEndTimestamp - currentTimeStamp;

            videoWillEndInNextSeconds = Math.floor(videoWillEndInNextSeconds / 1000);
            this.setState({totalSecondsTillCurrentTimeSlotEnds: videoWillEndInNextSeconds});
        } else {
            this.setState({totalSecondsTillCurrentTimeSlotEnds: null});
        }

        // if we don't have an active timeslot at this time, search if we have a next timeslot to set up the timer
        // we also display the timer if the current timeslot has no video added
        const timeslot = auditoriumTimeslots[currentTimeSlotIndex];

        if (currentTimeSlotIndex === -1 || !(timeslot.video || timeslot.isLiveStreaming)) {
            for (const timeSlot of auditoriumTimeslots) {
                let timeSlotStartTimestamp = getTimeslotStartTimestamp(timeSlot.date, timeSlot, event.utcTimezone);

                const isSessionStreamingEngine =
                    timeSlot.streamingEngine === ZOOM || timeSlot.streamingEngine === ETX_LIVE_SESSION;

                if (
                    currentTimeStamp < timeSlotStartTimestamp &&
                    (timeSlot.video || timeSlot.imageUrl || !!timeSlot.session || isSessionStreamingEngine)
                ) {
                    let nextVideoWillStartInNextSeconds = timeSlotStartTimestamp - currentTimeStamp;
                    nextVideoWillStartInNextSeconds = nextVideoWillStartInNextSeconds / 1000;
                    let highlightUsers = [];
                    timeSlot.speakers.forEach((speaker) => {
                        highlightUsers.push(speaker?.user?._id);
                    });
                    const wallData = {
                        wallId: timeSlot?.videoWall,
                        highlightUsers,
                    };
                    this.props.setTimeSlotVideoWall(wallData);
                    this.props.setActiveWall(wallData);
                    this.setState({
                        totalSecondsTillNextVideo: nextVideoWillStartInNextSeconds,
                    });

                    break;
                }
            }
        } else {
            // if we have a time slot currently running
            // we set up the sideMenu to have videoWall
            setHasVideoWall(true);
            this.setState({videoEnded: false});

            // we set up the current time slot video Id
            const timeSlot = auditoriumTimeslots[currentTimeSlotIndex];
            const activeVideoWallId = timeSlot.videoWall;
            // we save in state the current user Id's we need to highlight in the wall posts
            let highlightUsers = [];
            timeSlot.speakers.forEach((speaker) => {
                highlightUsers.push(speaker?.user?._id);
            });

            // we set up the current wall Id to be the video Wall Id
            const wallData = {
                wallId: activeVideoWallId,
                highlightUsers: highlightUsers,
            };
            setTimeSlotVideoWall(wallData);
            setActiveWall(wallData);

            // we open the sideBar to the videoWall
            seeVideoWall();

            this.props.onGetTimeslotData({
                auditoriumId: dataSource.auditoriums[auditoriumRoomIndex]?._id,
                timeslotId: timeSlot._id,
            });
        }

        this.setState({currentTimeSlotIndex: currentTimeSlotIndex});
    };

    setActiveAuditoriumIndex = (auditoriumIndex) => (e) => {
        const {eventId, user, event, building, closeSidebar, onResetTimeslotData} = this.props;
        const dataSource = building || event;

        localStorage.setItem(LOCAL_STORAGE_AUDITORIUM_INDEX_KEY, auditoriumIndex);

        connectToAuditorium({
            userId: user._id,
            eventId,
            auditoriumId: dataSource.auditoriums[auditoriumIndex]._id,
        });

        this.handleGetTimeSlot(auditoriumIndex);
        onResetTimeslotData();
        this.handleChangeTab(wallTabsList.WALL);
        closeSidebar();
        this.setState({auditoriumRoomIndex: auditoriumIndex, currentTimeSlotIndex: -1}, () =>
            this.setAuditoriumTimeslots()
        );
    };

    handleChangeTab = (tabName) => {
        this.setState({
            currentTab: tabName,
        });
    };

    render() {
        const {event, resourcesAccess, isLargeScreen, building, buildingId} = this.props;
        const dataSource = building || event;
        const auditoriums = dataSource.auditoriums;
        const {
            auditoriumTimeslots,
            currentTimeSlotIndex,
            auditoriumRoomIndex,
            totalSecondsTillCurrentTimeSlotEnds,
            totalSecondsTillNextVideo,
            auditoriumProgramHasEnded,
            currentTab,
            translation,
        } = this.state;

        const currentAuditorium = auditoriums[auditoriumRoomIndex];

        let hasAccessToCurrentAuditorium = true;

        if (
            building &&
            event.hasAccessManagement &&
            resourcesAccess !== null &&
            !resourcesAccess.buildings.includes(building._id)
        ) {
            return <NoBuildingAccess />;
        }

        if (event.hasAccessManagement && resourcesAccess) {
            hasAccessToCurrentAuditorium = building
                ? resourcesAccess.buildings.includes(buildingId)
                : resourcesAccess.auditoriums.includes(auditoriums[auditoriumRoomIndex]._id);
        }

        const hideVideo = !isLargeScreen && currentTab !== wallTabsList.VIDEO;

        const isSessionStreamingEngine =
            auditoriumTimeslots[currentTimeSlotIndex]?.streamingEngine === ZOOM ||
            auditoriumTimeslots[currentTimeSlotIndex]?.streamingEngine === ETX_LIVE_SESSION;

        const showVideo =
            currentTimeSlotIndex !== -1 &&
            auditoriumTimeslots[currentTimeSlotIndex] &&
            (auditoriumTimeslots[currentTimeSlotIndex]?.video ||
                auditoriumTimeslots[currentTimeSlotIndex]?.session ||
                isSessionStreamingEngine);

        const brandingDataSource = building || event.brandingData;

        return (
            <div className="position-background auditorium-page-wrapper">
                <BreadcrumbsNavigation className={'white auditorium-breadcrumbs'} />
                {this.props.event.owner._id !== this.props.user._id &&
                    this.state.showAuditoriumNote &&
                    this.props.event.auditoriumNoteEnabled &&
                    this.props.event.auditoriumNote && (
                        <WelcomeScreensDialog noteMedia={this.props.event.auditoriumNote} type="showAuditoriumNote" />
                    )}
                {totalSecondsTillCurrentTimeSlotEnds && hasAccessToCurrentAuditorium && (
                    <AuditoriumSnackbar
                        totalSecondsTillCurrentTimeSlotEnds={totalSecondsTillCurrentTimeSlotEnds}
                        loadCurrentTimeSlot={this.loadCurrentTimeSlot}
                        currentTimeSlotIndex={currentTimeSlotIndex}
                    />
                )}
                <AuditoriumPageContainer>
                    {!isLargeScreen && <div className="page-title">{translation?.roomsDropdown.auditoriumButton}</div>}
                    <AuditoriumWallNavigation
                        currentTimeSlot={auditoriumTimeslots[currentTimeSlotIndex]}
                        currentTab={this.state.currentTab}
                        auditorium={auditoriums[auditoriumRoomIndex]}
                        isInterpretationEnabled={this.state.isInterpretationEnabled}
                        onChangeTab={this.handleChangeTab}
                    />
                    {/*SHOW BACKGROUND IMAGE ONLY ON LARGE SCREENS*/}
                    {isLargeScreen && (
                        <AuditoriumBackgroundImage
                            filesUrl={this.props.event.brandingData.filesUrl}
                            brandingData={brandingDataSource}
                        />
                    )}
                    {hasAccessToCurrentAuditorium && currentTimeSlotIndex !== -1 && isLargeScreen && (
                        <AuditoriumSlotTitle auditoriumRoomIndex={auditoriumRoomIndex} />
                    )}
                    {auditoriums.length > 1 && (
                        <AuditorumsSlider
                            auditoriums={auditoriums}
                            activeAuditoriumIndex={auditoriumRoomIndex}
                            onSetActiveAuditoriumIndex={this.setActiveAuditoriumIndex}
                        />
                    )}
                    <div className={`centerOfPage ${hideVideo ? 'hide' : ''}`}>
                        <div className="relativeCenter">
                            <AuditoriumBanners
                                filesUrl={event.brandingData.filesUrl}
                                brandingData={brandingDataSource}
                            />
                            {auditoriums.length > 1 && isLargeScreen && (
                                <AuditoriumNavigation
                                    currentTimeSlotIndex={currentTimeSlotIndex}
                                    auditoriumTimeslots={auditoriumTimeslots}
                                    setActiveAuditoriumIndex={this.setActiveAuditoriumIndex}
                                    auditoriums={auditoriums}
                                    auditoriumRoomIndex={auditoriumRoomIndex}
                                />
                            )}
                            {hasAccessToCurrentAuditorium ? (
                                <>
                                    {showVideo ? (
                                        <AuditoriumVideoPlayer
                                            videoStreamingUrl={this.state.videoStreamingUrl}
                                            videoStreamingStartAt={this.state.videoStreamingStartAt}
                                            interpretationSourceLanguage={
                                                currentAuditorium?.interpretationSourceLanguage
                                            }
                                            withInterpretation={currentAuditorium?.isInterpretationEnabled}
                                            updateTimeslots={this.setTimeslots}
                                        />
                                    ) : (
                                        <AuditoriumNextVideo
                                            currentTimeSlotIndex={currentTimeSlotIndex}
                                            auditoriumTimeslots={auditoriumTimeslots}
                                            auditoriumProgramHasEnded={auditoriumProgramHasEnded}
                                            auditoriumHasVideos={this.state.auditoriumHasVideos}
                                            totalSecondsTillNextVideo={totalSecondsTillNextVideo}
                                            loadCurrentTimeSlot={this.loadCurrentTimeSlot}
                                        />
                                    )}
                                </>
                            ) : (
                                <div className="next-video">
                                    <div className="next-video-container restricted">
                                        <p>
                                            <span>{translation?.auditorium.noAccess}</span>
                                            <LockIcon />
                                        </p>
                                    </div>
                                </div>
                            )}
                            {!isLargeScreen && (
                                <div className="slot-mobile-details">
                                    <AuditoriumSlotTitle auditoriumRoomIndex={auditoriumRoomIndex} />
                                    {auditoriums.length > 1 && !isLargeScreen && (
                                        <AuditoriumNavigationMobile
                                            currentTimeSlotIndex={currentTimeSlotIndex}
                                            auditoriumTimeslots={auditoriumTimeslots}
                                            setActiveAuditoriumIndex={this.setActiveAuditoriumIndex}
                                            auditoriums={auditoriums}
                                            auditoriumRoomIndex={auditoriumRoomIndex}
                                            timezoneName={event.timezoneName}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </AuditoriumPageContainer>
            </div>
        );
    }
}

export default Auditorium;
