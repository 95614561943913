import React from 'react';
import {useSelector} from 'react-redux';
import {Button, Dialog, DialogActions, DialogTitle, DialogContent} from '@material-ui/core';

import {usePlatformTranslation} from '../../../../../services/hooks';
import TwitterLogo from '../../../../../Images/ri_twitter.svg';
import FacebookLogo from '../../../../../Images/ri_facebook.svg';
import LinkedinLogo from '../../../../../Images/ri_linkedin.svg';
import LinkIcon from '../../../../../Images/links.svg';

import './BoothLinks.scss';

const socialIcons = {
    Facebook: () => <img src={FacebookLogo} alt="Facebook" className="link-icon" />,
    Twitter: () => <img src={TwitterLogo} alt="Twitter" className="link-icon" />,
    LinkedIn: () => <img src={LinkedinLogo} alt="LinkedIn" className="link-icon" />,
};

const BoothLinks = ({opened, closeDialog, onClickOnLink}) => {
    const translation = usePlatformTranslation();

    const socialLinks = useSelector((state) => state.exhibitor.data.booth.socialMediaLinks);
    const links = useSelector((state) => state.exhibitor.data.booth.additionalLinks);

    return (
        <Dialog open={opened} onClose={closeDialog} aria-labelledby="form-dialog-title" className="booth-dialog">
            <DialogContent className="booth-dialog-content">
                <DialogTitle id="form-dialog-title">{translation?.showfloor.boothLinksDialogTitle}:</DialogTitle>
                <div className="content-container">
                    {!(links.length || socialLinks.some((link) => link.link)) && (
                        <p className="title">No links added</p>
                    )}
                    {socialLinks.some((link) => link.link) && (
                        <>
                            <p className="title">{translation?.exhibitorDashboard.linksTab.social.title}:</p>

                            {socialLinks.map((link) => {
                                const Icon = socialIcons[link.platform];
                                return (
                                    link.link && (
                                        <p key={link.platform} onClick={onClickOnLink}>
                                            <a href={link.link} target="_blank" rel="noopener noreferrer">
                                                <Icon />
                                                <span className="link">{link.title ? link.title : link.link}</span>
                                            </a>
                                        </p>
                                    )
                                );
                            })}
                        </>
                    )}

                    {links.length > 0 && (
                        <>
                            <p className="title">{translation?.showfloor.boothLinksDialogOtherLinksTitle}</p>
                            {links.map((link) => (
                                <p key={link} onClick={onClickOnLink}>
                                    <a href={link.link} target="_blank" rel="noopener noreferrer">
                                        <img src={LinkIcon} alt="custom-link" className="link-icon link-icon--custom" />
                                        <span className="link">{link.title ? link.title : link.link}</span>
                                    </a>
                                </p>
                            ))}
                        </>
                    )}
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeDialog} color="secondary" className="booth-cancel-button">
                    {translation?.generalText.close}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default BoothLinks;
