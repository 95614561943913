import {useState} from 'react';
// import {useState, useLayoutEffect} from 'react';
// import {generateRandomHexColor} from '../../Utils';

// export const usePlaceholderColor = () => {
//     const [color, setColor] = useState('#fff');

//     useLayoutEffect(() => setColor(generateRandomHexColor()), []);

//     return color;
// };

export const useIsPlaceholderShowed = (media) => {
    const [isShowed, setIsShowed] = useState(!media?.src);
    return [isShowed, () => setIsShowed(true)];
};
