export const initialState = {
    organizersList: {
        loading: false,
        organizers: [],
        pagination: {
            page: 1,
            perPage: 10,
            pages: null,
            total: null,
        },
        onlineUsersCount: 0,
    },
    organizerDetails: {
        loading: false,
        events: [],
        pagination: {
            page: 1,
            perPage: 10,
            pages: null,
            total: null,
        },
    },
};
