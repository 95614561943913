import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../../../../store/actions';
import {AdminMenu} from './AdminMenu';

export default withRouter(
    connect(null, {
        onLogoutUser: actions.logoutUser,
    })(AdminMenu)
);
