import {connect} from 'react-redux';

import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import {Info} from './Info';

export default connect(
    (state) => ({
        event: selectors.getEventData(state),
        onlineUsers: selectors.getOnlineUsers(state),
    }),
    {
        onGetOnlineUsers: actions.getOnlineUsers,
    }
)(Info);
