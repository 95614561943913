import {connect} from 'react-redux';
import * as selectors from 'store/selectors';
import * as actions from 'store/actions';
import {EventArchive} from './EventArchive';

export default connect(
    (state) => ({
        eventId: selectors.getEventId(state),
        event: selectors.getEventData(state),
        loadingEvent: state.event.loading,
        eventArchive: state.eventArchive.data,
        loadingEventArchive: state.eventArchive.loading,
        user: selectors.getUserData(state),
        languages: selectors.getLanguages(state),
        isRtlLanguage: state.languages.isRtlLanguage,
    }),
    {
        onGetEventArchive: actions.getEventArchive,
        onSetEventProtectedMenu: actions.setEventProtectedMenu,
        closeTopNavigation: actions.topNavClose,
    }
)(EventArchive);
