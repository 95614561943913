import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import hark from 'hark';

import VideoAvatar from '../VideoAvatar';

import {handleCanPlay} from 'services/helpers';
import {getIsCameraOff, getScreenShareCapture, getIsScreenShared} from 'store/selectors';

import {useStyles} from './VideoPlayerSelfVideo.styles';

export const VideoPlayerSelfVideo = ({videoCapture, user, sessions}) => {
    const isCameraOff = useSelector(getIsCameraOff);
    const shareScreenCapture = useSelector(getScreenShareCapture);
    const isScreenShared = useSelector(getIsScreenShared);
    const selfVideoRef = useRef();
    const screenVideoRef = useRef();
    const [isActiveSpeaker, setIsActiveSpeaker] = useState(false);
    const classes = useStyles({isActiveSpeaker});
    const activeStreamsCount = Object.values(sessions)?.length;

    useEffect(() => {
        if (selfVideoRef?.current) selfVideoRef.current.srcObject = videoCapture;

        if (screenVideoRef?.current && shareScreenCapture) screenVideoRef.current.srcObject = shareScreenCapture;
    }, [isScreenShared, shareScreenCapture, videoCapture]);

    useEffect(() => {
        if (videoCapture) {
            const speechEvents = hark(videoCapture, {});

            speechEvents.on('speaking', () => {
                if (activeStreamsCount) setIsActiveSpeaker(true);
            });

            speechEvents.on('stopped_speaking', () => setIsActiveSpeaker(false));
        }
    }, [activeStreamsCount, videoCapture]);

    return (
        <div className={classNames(classes.videoPlate, {[classes.activeStreamsVideoPlate]: activeStreamsCount})}>
            {isCameraOff && !isScreenShared && (
                <div className={classes.userName}>
                    <VideoAvatar member={user} isMinified={!!activeStreamsCount} isSelfAvatar />
                </div>
            )}
            <video
                className={classNames(classes.video, {[classes.hidden]: isCameraOff || isScreenShared})}
                ref={selfVideoRef}
                muted
                autoPlay
                playsInline
                onCanPlay={() => handleCanPlay(selfVideoRef)}
            />
            <video
                className={classNames(classes.video, {[classes.hidden]: !isScreenShared})}
                ref={screenVideoRef}
                muted
                autoPlay
                playsInline
                onCanPlay={() => handleCanPlay(screenVideoRef)}
            />
        </div>
    );
};

VideoPlayerSelfVideo.propTypes = {
    videoCapture: PropTypes.object.isRequired,
    user: PropTypes.object,
};
