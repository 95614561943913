import AuditoriumArhive from './AuditoriumArhive';
import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export default connect(
    (state) => ({
        eventId: selectors.getEventId(state),
        event: selectors.getEventData(state),
        platformLanguage: selectors.getPlatformLanguage(state),
        buildingId: selectors.getBuildingId(state),
        building: selectors.getBuildingData(state),
        resourcesAccess: selectors.getResourcesAccess(state),
    }),
    {
        closeTopNavigation: actions.topNavClose,
        setHasVideoWall: actions.sideNavHasVideoWall,
        onSetEventProtectedMenu: actions.setEventProtectedMenu,
    }
)(AuditoriumArhive);
