import React, {useEffect, useRef, useCallback} from 'react';
import PropTypes from 'prop-types';

import {receiveCallUsers} from 'Api/socketApi';

import ring from 'assets/audio/ring.mp3';

export const RingContainer = ({
    user,
    notReadChatMessages,
    videoConference: {isActive},
    startCalling,
    setNotReadChatMessages,
}) => {
    const missedCalls = notReadChatMessages || 0;

    const audio = useRef(false);

    const getCalls = useCallback(() => {
        receiveCallUsers((userIds) => {
            if (userIds.includes(user?._id) && !isActive) {
                let newCall = missedCalls + 1;
                startCalling();
                setNotReadChatMessages(newCall);
            }
        });
    }, [user, isActive, missedCalls, startCalling, setNotReadChatMessages]);

    useEffect(() => {
        getCalls();
    }, [getCalls]);

    return <audio ref={audio} src={ring} hidden />;
};

RingContainer.propTypes = {
    user: PropTypes.object,
    notReadChatMessages: PropTypes.number,
    videoConference: PropTypes.object,
    startCalling: PropTypes.func,
    setNotReadChatMessages: PropTypes.func,
};
