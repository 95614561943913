import React from 'react';

import IconContainer from '../../../../../../../IconContainer/IconContainer';
import {IconAgenda} from '../../../../../../../Icons';

import './AgendaEmptyMessage.scss';

const AgendaEmptyMessage = ({title = ''}) => (
    <div className="AgendaEmptyMessage">
        <div className="AgendaEmptyMessage__el">{title}</div>

        <div className="AgendaEmptyMessage__icon">
            <IconContainer size="xxlarge">
                <IconAgenda />
            </IconContainer>
        </div>
    </div>
);

export default AgendaEmptyMessage;
