import {Api} from 'Api';
import {deserializePoll, deserializePollList, serializeCreatedPoll} from 'services/utils/normalizers';
import * as actionTypes from '../actionTypes/timeslotActionTypes';
import {createRoom} from './sessionActions';

export const setTimeslotData =
    ({auditoriumId, timeslotId, data}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        try {
            await Api.request({
                method: 'put',
                url: `/event/${eventId}/auditoriums/${auditoriumId}/program/${timeslotId}`,
                payload: data,
                errorObj: {show: true},
            });

            dispatch(getTimeslotData({auditoriumId, timeslotId}));
        } catch (error) {
            dispatch(getTimeslotDataFail());
            Api.handleError(error, {show: true, message: error?.data?.error === 'string' ? error?.data?.error : ''});
        }
    };

export const getTimeslotData =
    ({auditoriumId, timeslotId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        dispatch(getTimeslotDataRequest());

        try {
            const {timeslot} = await Api.request({
                url: `/event/${eventId}/auditorium/${auditoriumId}/timeslot/${timeslotId}?full=true`,
                method: 'get',
                loading: false,
            });

            dispatch(getTimeslotDataSuccess(timeslot));

            callback && callback(timeslot);
        } catch (error) {
            dispatch(getTimeslotDataFail());
        }
    };
const getTimeslotDataRequest = () => ({type: actionTypes.GET_TIMESLOT_DATA_REQUEST});
export const getTimeslotDataSuccess = (payload) => ({type: actionTypes.GET_TIMESLOT_DATA_SUCCESS, payload});
const getTimeslotDataFail = () => ({type: actionTypes.GET_TIMESLOT_DATA_FAIL});

export const deleteTimeslot =
    ({date, auditoriumId, timeslot, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        dispatch(deleteTimeslotRequest());

        try {
            await Api.request({
                method: 'delete',
                url: `event/${eventId}/auditoriums/${auditoriumId}/program/${timeslot}`,
                payload: {date},
            });

            dispatch(deleteTimeslotSuccess());
            callback && callback();
        } catch (error) {
            dispatch(deleteTimeslotFail());
        }
    };

const deleteTimeslotRequest = () => ({
    type: actionTypes.DELETE_TIMESLOT_REQUEST,
});

const deleteTimeslotSuccess = () => ({
    type: actionTypes.DELETE_TIMESLOT_SUCCESS,
});

const deleteTimeslotFail = () => ({
    type: actionTypes.DELETE_TIMESLOT_FAIL,
});

export const sessionStreamStart = (auditoriumId) => async (dispatch, getState) => {
    const {
        event: {eventId},
        timeslot: {
            loading,
            data: {_id},
        },
    } = getState();

    dispatch(sessionStreamStartRequest());

    try {
        const {timeslot} = await Api.request({
            url: `/event/${eventId}/auditorium/${auditoriumId}/timeslot/${_id}/start`,
            method: 'post',
            loading,
        });

        dispatch(sessionStreamStartSuccess(timeslot));
    } catch (error) {
        dispatch(sessionStreamStartFail());
    }
};

const setMeetingLinkStart = () => ({
    type: actionTypes.SET_MEETING_LINK_REQUEST,
});

const setMeetingLinkSuccess = (poll = {}) => ({
    type: actionTypes.SET_MEETING_LINK_SUCCESS,
    payload: poll,
});

const setMeetingLinkFail = (error) => ({
    type: actionTypes.SET_MEETING_LINK_FAIL,
    payload: error,
});

export const setMeetingLink = ({auditoriumId, meetingLink = ''}) => {
    return async (dispatch, getState) => {
        const {
            event: {eventId},
            timeslot: {
                data: {_id: timeslotId, date},
            },
        } = getState();

        dispatch(setMeetingLinkStart());
        try {
            const {meetingLink: updatedLink} = await Api.request({
                method: 'put',
                url: `/event/${eventId}/auditoriums/${auditoriumId}/program/${timeslotId}/meeting-link`,
                payload: {meetingLink, date},
            });

            return dispatch(setMeetingLinkSuccess(updatedLink));
        } catch (error) {
            dispatch(setMeetingLinkFail());
        }
    };
};

const sessionStreamStartRequest = () => ({type: actionTypes.SESSION_STREAM_START_REQUEST});
const sessionStreamStartSuccess = (payload) => ({type: actionTypes.SESSION_STREAM_START_SUCCESS, payload});
const sessionStreamStartFail = () => ({type: actionTypes.SESSION_STREAM_START_FAIL});

export const sessionStreamStop = (auditoriumId) => async (dispatch, getState) => {
    const {
        event: {eventId},
        timeslot: {
            loading,
            data: {_id},
        },
    } = getState();

    dispatch(sessionStreamStopRequest());

    try {
        const {timeslot} = await Api.request({
            url: `/event/${eventId}/auditorium/${auditoriumId}/timeslot/${_id}/stop`,
            method: 'post',
            loading,
        });

        dispatch(sessionStreamStopSuccess(timeslot));
    } catch (error) {
        dispatch(sessionStreamStopFail());
    }
};

const sessionStreamStopRequest = () => ({type: actionTypes.SESSION_STREAM_STOP_REQUEST});
const sessionStreamStopSuccess = (payload) => ({type: actionTypes.SESSION_STREAM_STOP_SUCCESS, payload});
const sessionStreamStopFail = () => ({type: actionTypes.SESSION_STREAM_STOP_FAIL});

export const sessionStreamAllocateResources =
    ({auditoriumId, viewersCount, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
            timeslot: {
                loading,
                data: {_id},
            },
        } = getState();

        dispatch(sessionStreamAllocateResourcesRequest());

        try {
            const {timeslot} = await Api.request({
                url: `event/${eventId}/auditorium/${auditoriumId}/timeslot/${_id}/allocate-resources`,
                method: 'post',
                loading,
                payload: {
                    viewersCount,
                },
                errorObj: {show: true},
            });

            dispatch(sessionStreamAllocateResourcesSuccess(timeslot));

            callback && callback();
        } catch (error) {
            dispatch(sessionStreamAllocateResourcesFail());
            Api.handleError(error, {show: true, message: error?.error});
        }
    };

const sessionStreamAllocateResourcesRequest = () => ({
    type: actionTypes.SESSION_STREAM_ALLOCATE_RESOURCES_REQUEST,
});
const sessionStreamAllocateResourcesSuccess = (payload) => ({
    type: actionTypes.SESSION_STREAM_ALLOCATE_RESOURCES_SUCCESS,
    payload,
});
const sessionStreamAllocateResourcesFail = () => ({type: actionTypes.SESSION_STREAM_ALLOCATE_RESOURCES_FAIL});

// ZOOM SESSIONS
export const getZoomStreamRecordings =
    ({auditoriumId, timeslotId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        dispatch(getZoomStreamRecordingsRequest());

        try {
            const {recordings} = await Api.request({
                url: `/event/${eventId}/auditorium/${auditoriumId}/timeslot/${timeslotId}/recordings`,
                method: 'get',
            });

            dispatch(getZoomStreamRecordingsSuccess(recordings));

            callback && callback();
        } catch (error) {
            dispatch(getZoomStreamRecordingsFail());
        }
    };

const getZoomStreamRecordingsRequest = () => ({type: actionTypes.GET_ZOOM_STREAM_RECORDINGS_REQUEST});
const getZoomStreamRecordingsSuccess = (payload) => ({
    type: actionTypes.GET_ZOOM_STREAM_RECORDINGS_SUCCESS,
    payload,
});
const getZoomStreamRecordingsFail = () => ({type: actionTypes.GET_ZOOM_STREAM_RECORDINGS_FAIL});

export const updateTimeSlotData = (payload) => ({type: actionTypes.UPDATE_TIMESLOT_DATA, payload});

const uploadSessionThumbnailImageRequest = () => ({type: actionTypes.UPLOAD_SESSION_THUMBNAIL_IMAGE_REQUEST});

const uploadSessionThumbnailImageSuccess = () => ({
    type: actionTypes.UPLOAD_SESSION_THUMBNAIL_IMAGE_SUCCESS,
});

const uploadSessionThumbnailImageFail = () => ({type: actionTypes.UPLOAD_SESSION_THUMBNAIL_IMAGE_FAIL});

export const uploadSessionThumbnailImage =
    ({auditoriumId, timeslotId, data, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        dispatch(uploadSessionThumbnailImageRequest());

        try {
            await Api.request({
                url: `/event/${eventId}/auditorium/${auditoriumId}/timeslot/${timeslotId}/thumbnail`,
                method: 'post',
                payload: data,
            });

            dispatch(uploadSessionThumbnailImageSuccess());

            callback && callback();
        } catch (error) {
            dispatch(uploadSessionThumbnailImageFail());
        }
    };

// ETX Sessions
export const getAuthTokenForVideoEngine =
    ({auditoriumId, timeslotId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        dispatch(getAuthTokenForVideoEngineRequest());

        try {
            const {token} = await Api.request({
                url: `event/${eventId}/auditorium/${auditoriumId}/timeslot/${timeslotId}/auth-token`,
                method: 'get',
            });

            dispatch(getAuthTokenForVideoEngineSuccess(token));

            callback && callback();

            dispatch(createRoom({token, timeslotId}));
        } catch (error) {
            dispatch(getAuthTokenForVideoEngineFail());
        }
    };
const getAuthTokenForVideoEngineRequest = () => ({type: actionTypes.GET_AUTH_TOKEN_FOR_VIDEO_ENGINE_REQUEST});
const getAuthTokenForVideoEngineSuccess = (payload) => ({
    type: actionTypes.GET_AUTH_TOKEN_FOR_VIDEO_ENGINE_SUCCESS,
    payload,
});
const getAuthTokenForVideoEngineFail = () => ({type: actionTypes.GET_AUTH_TOKEN_FOR_VIDEO_ENGINE_FAIL});

// Interpretations
export const setAvailableInterpretationLanguage = (payload) => ({
    type: actionTypes.SET_AVAILABLE_INTERPRETATION_LANGUAGE,
    payload,
});

export const removeAvailableInterpretationLanguage = (payload) => ({
    type: actionTypes.REMOVE_AVAILABLE_INTERPRETATION_LANGUAGE,
    payload,
});

export const setActiveInterpretationLanguage = (payload) => ({
    type: actionTypes.SET_ACTIVE_INTERPRETATION_LANGUAGE,
    payload,
});

const getEventUsersListExcludeRolesStart = () => ({
    type: actionTypes.GET_EVENT_USERS_LIST_EXCLUDED_ROLES_START,
});

const getEventUsersListExcludeRolesSuccess = (data) => ({
    type: actionTypes.GET_EVENT_USERS_LIST_EXCLUDED_ROLES_SUCCESS,
    payload: data,
});

const getEventUsersListExcludeRolesFail = (error) => ({
    type: actionTypes.GET_EVENT_USERS_LIST_EXCLUDED_ROLES_FAIL,
    payload: error,
});

export const getEventUsersListExcludeRoles = ({excludeRoles = ''}) => {
    return async (dispatch, getState) => {
        dispatch(getEventUsersListExcludeRolesStart());
        try {
            const {
                event: {eventId},
            } = getState();

            const {users} = await Api.request({
                method: 'get',
                url: `/event/${eventId}/users/list`,
                loading: false,
                params: {excludeRoles},
            });
            dispatch(getEventUsersListExcludeRolesSuccess(users));

            return users;
        } catch (error) {
            dispatch(getEventUsersListExcludeRolesFail());
        }
    };
};

// POLLS
// TODO: add polls to timeslot request and remove
const getTimeslotPollsRequest = () => ({
    type: actionTypes.GET_TIMESLOT_POLLS_REQUEST,
});
const getTimeslotPollsSuccess = ({polls, toDeserialize = true}) => ({
    type: actionTypes.GET_TIMESLOT_POLLS_SUCCESS,
    payload: {polls, toDeserialize},
});
const getTimeslotPollsFail = () => ({type: actionTypes.GET_TIMESLOT_POLLS_FAIL});

export const getTimeslotPolls =
    ({auditoriumId, timeslotId, toDeserialize = true}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        dispatch(getTimeslotPollsRequest());

        try {
            const {polls} = await Api.request({
                url: `event/${eventId}/polls/auditoriums/${auditoriumId}/program/${timeslotId}`,
                method: 'get',
                loading: false,
            });

            dispatch(getTimeslotPollsSuccess({polls, toDeserialize}));
        } catch (error) {
            dispatch(getTimeslotPollsFail());
        }
    };

export const setTimeslotPolls = ({polls, timeslot, timeslotId}) => ({
    type: actionTypes.SET_TIMESLOT_POLLS,
    payload: {timeslotId, list: deserializePollList(polls.filter(({_id}) => timeslot.polls.includes(_id)))},
});

export const createEmptyPoll = () => ({
    type: actionTypes.CREATE_EMPTY_POLL,
});

const savePollStart = () => ({
    type: actionTypes.SAVE_POLL_START,
});

const savePollSuccess = ({pollId, updatedPoll = {}}) => ({
    type: actionTypes.SAVE_POLL_SUCCESS,
    payload: {pollId, updatedPoll},
});

const savePollFail = (error) => ({
    type: actionTypes.SAVE_POLL_FAIL,
    payload: error,
});

export const savePoll = ({auditoriumId, poll, pollId}) => {
    return async (dispatch, getState) => {
        const {
            event: {eventId},
            timeslot: {
                data: {_id: timeslotId},
            },
        } = getState();

        dispatch(savePollStart());
        const serializedPoll = serializeCreatedPoll(poll);

        try {
            let res;
            if (poll.isCreated) {
                res = await Api.request({
                    method: 'post',
                    url: `/event/${eventId}/polls/auditoriums/${auditoriumId}/program/${timeslotId}`,
                    payload: serializedPoll,
                });
            } else {
                res = await Api.request({
                    method: 'put',
                    url: `/event/${eventId}/polls/${pollId}/auditoriums/${auditoriumId}/program/${timeslotId}`,
                    payload: serializedPoll,
                });
            }

            const updatedPoll = deserializePoll(res.poll);
            return dispatch(savePollSuccess({pollId, updatedPoll}));
        } catch (error) {
            dispatch(savePollFail());
        }
    };
};

const deletePollStart = () => ({
    type: actionTypes.DELETE_POLL_START,
});

const deletePollSuccess = (pollId) => ({
    type: actionTypes.DELETE_POLL_SUCCESS,
    payload: pollId,
});

const deletePollFail = (error) => ({
    type: actionTypes.DELETE_POLL_FAIL,
    payload: error,
});

export const deletePoll = ({auditoriumId, pollId, isCreated = false}) => {
    return async (dispatch, getState) => {
        const {
            event: {eventId},
            timeslot: {
                data: {_id: timeslotId},
            },
        } = getState();

        dispatch(deletePollStart());

        if (isCreated) return dispatch(deletePollSuccess(pollId));

        try {
            await Api.request({
                method: 'delete',
                url: `/event/${eventId}/polls/${pollId}/auditoriums/${auditoriumId}/program/${timeslotId}`,
            });

            dispatch(deletePollSuccess(pollId));
        } catch (error) {
            dispatch(deletePollFail());
        }
    };
};

export const changePollTitle = ({pollId, title}) => ({
    type: actionTypes.CHANGE_POLL_TITLE,
    payload: {pollId, title},
});

export const changePollOptionTitle = ({pollId, optionId, title}) => {
    return {
        type: actionTypes.CHANGE_POLL_OPTION_TITLE,
        payload: {pollId, optionId, title},
    };
};

export const addNewPollOption = (pollId) => ({
    type: actionTypes.ADD_NEW_POLL_OPTION,
    payload: pollId,
});

export const removePollOption = ({pollId, optionId}) => ({
    type: actionTypes.REMOVE_POLL_OPTION,
    payload: {pollId, optionId},
});

export const changePollVisibility = (pollId) => ({type: actionTypes.CHANGE_POLL_VISIBILITY, payload: pollId});

export const closePoll = (pollId) => ({type: actionTypes.CLOSE_POLL, payload: pollId});

export const togglePollMultiselect = (pollId) => ({type: actionTypes.TOGGLE_POLL_MULTISELECT, payload: pollId});

export const togglePollResultsVisibility = (pollId) => ({
    type: actionTypes.TOGGLE_POLL_RESULTS_VISIBILITY,
    payload: pollId,
});

export const resetTimeslotData = () => ({type: actionTypes.RESET_TIMESLOT_DATA});

export const socketPollCreate = (newPoll) => ({
    type: actionTypes.SOCKET_POLL_CREATE,
    payload: newPoll,
});

export const socketPollDelete = (pollId) => ({
    type: actionTypes.SOCKET_POLL_DELETE,
    payload: pollId,
});

export const socketPollUpdate = (updatedPoll) => ({
    type: actionTypes.SOCKET_POLL_UPDATE,
    payload: updatedPoll,
});

export const selectPollOption =
    ({pollId, optionId, programId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        try {
            const {
                poll: {options},
            } = await Api.request({
                url: `/event/${eventId}/polls/${pollId}/vote/${optionId}`,
                method: 'post',
                payload: {programId},
                loading: false,
            });

            callback && callback(options);
        } catch (error) {}
    };
