import React from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import {Tabs, Tab} from '@material-ui/core';

const CustomTab = withStyles((theme) => ({
    root: {
        color: theme.palette.primary.main,
    },
}))(Tab);

const BuildingsTabs = ({setBuildingIndex, eventAuditoriumBuildings, currentAuditoriumBuildingIndex}) => {
    const handleChangeBuildingTab = (e, buildingIndex) => {
        setBuildingIndex(buildingIndex);
    };

    return (
        <div>
            <Tabs
                value={currentAuditoriumBuildingIndex}
                onChange={handleChangeBuildingTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
            >
                {eventAuditoriumBuildings.map((building) => {
                    return <CustomTab label={building.name} key={building._id} />;
                })}
            </Tabs>
        </div>
    );
};

export default BuildingsTabs;
