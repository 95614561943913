import React, {useState} from 'react';
import {generatePath} from 'react-router';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import classNames from 'classnames';
import {Accordion, AccordionActions, AccordionDetails, AccordionSummary, IconButton} from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {isMobile} from 'react-device-detect';
import {RouterConfig} from '../../../routerConfig';
import {getEventCardDate, getTextFromTranslation} from '../../../services/helpers/listedEvent';
import * as actions from '../../../store/actions';
import {getIsRtlLanguage, getEventLanguage, getEventId} from '../../../store/selectors';

import {ReactComponent as ExpandMoreIcon} from '../../../Images/arrow_down_grey.svg';

import ListedEventCardSaveBtn from '../ListedEventCard/components/ListedEventCardSaveBtn';
import ListedEventCardShareBtn from '../ListedEventCard/components/ListedEventCardShareBtn';

import './EventListedCardProfile.scss';

const EventListedCardProfile = ({cardData, typeToLoad}) => {
    const dispatch = useDispatch();
    const isRtlLanguage = useSelector(getIsRtlLanguage);
    const eventLanguage = useSelector(getEventLanguage);
    const eventId = useSelector(getEventId);

    const [isExpended, setIsExpended] = useState(false);
    const title = getTextFromTranslation(cardData.title, eventLanguage);
    const description = getTextFromTranslation(cardData.description, eventLanguage);
    const eventDate = getEventCardDate(cardData.date, eventLanguage);
    const history = useHistory();

    const handleExpend = () => {
        setIsExpended((state) => !state);
    };
    const handleUnsave = (e) => {
        e.stopPropagation();
        dispatch(actions.discardEventFromPersonalList(cardData.event, typeToLoad));
    };
    const handleSave = () => {
        dispatch(actions.saveEventToPersonalList(cardData.event, typeToLoad));
    };
    const eventSlug = cardData?.slug;
    const redirect = () => {
        if (cardData.event === eventId) return;

        dispatch(actions.setUserInitialEventId(eventId));
        history.push({
            pathname: generatePath(RouterConfig.event.homepage, {eventSlug}),
            state: {eventSlug},
        });
    };
    return (
        <Accordion expanded={isExpended} onChange={handleExpend} square className="event-listed-card-profile">
            <AccordionSummary
                className="event-listed-card-profile__summary"
                expandIcon={<ExpandMoreIcon className="event-listed-card-profile__expand-icon primary-fill-svg" />}
                classes={{
                    content: 'event-listed-card-profile__summary--content',
                }}
            >
                <div onClick={redirect} className="event-listed-card-profile__summary-wrapper">
                    <h4 className="event-listed-card-profile__title">{title}</h4>
                    <div className="event-listed-card-profile__summary-info-wrapper">
                        <span className={classNames('event-listed-card-profile__date', {isRtl: isRtlLanguage})}>
                            {eventDate}
                        </span>
                        {cardData.featured && (
                            <span
                                className={classNames('event-listed-card-profile__featured-flag', {
                                    isRtl: isRtlLanguage,
                                    isMobile,
                                })}
                            >
                                FEATURED
                            </span>
                        )}
                    </div>
                </div>
                {!isExpended && cardData.saved && (
                    <IconButton
                        onClick={handleUnsave}
                        className={classNames('event-listed-card-profile__unsafe-icon', {isRtl: isRtlLanguage})}
                    >
                        <RemoveCircleOutlineIcon className="primary-fill-svg" />
                    </IconButton>
                )}
            </AccordionSummary>
            <AccordionDetails className="event-listed-card-profile__details">
                <p className="event-listed-card-profile__details-text">{description}</p>
            </AccordionDetails>
            <AccordionActions className="event-listed-card-profile__action-wrapper">
                <ListedEventCardShareBtn shareData={cardData} />
                <ListedEventCardSaveBtn
                    onSave={handleSave}
                    onUnsave={handleUnsave}
                    isSaved={cardData.saved}
                    preSaveEvent={() => dispatch(actions.preSaveEvent({event: cardData.event, eventSlug}))}
                    redirect={redirect}
                />
            </AccordionActions>
        </Accordion>
    );
};

export default EventListedCardProfile;
