import * as actionTypes from './actionTypes';
import {Api} from '../../Api';

export const apiCallInvitationStart = () => {
    return {
        type: actionTypes.API_CALL_INVITATIONS_START,
    };
};
export const apiCallInvitationFail = (error) => {
    return {
        type: actionTypes.API_CALL_INVITATIONS_FAIL,
        payload: error,
    };
};

export const getInvitationCodes = (eventId) => async (dispatch, getState) => {
    const {
        invitations: {loading},
    } = getState();

    dispatch(apiCallInvitationStart());

    try {
        const response = await Api.request({
            url: `/event/${eventId}/invitation-codes`,
            method: 'get',
            loading,
        });

        let invitations = [];
        if (response) {
            invitations = response.invitationCodes;
        }
        dispatch(getInvitationCodesSuccess(invitations));
    } catch (error) {
        dispatch(apiCallInvitationFail('getInvitationCodes'));
    }
};

const getInvitationCodesSuccess = (invitations) => {
    return {
        type: actionTypes.GET_INVITATION_CODES_SUCCESS,
        payload: invitations,
    };
};

export const addSingleInvitationCode = (codeData) => async (dispatch, getState) => {
    const {
        invitations: {loading},
        event: {eventId},
    } = getState();

    dispatch(apiCallInvitationStart());

    try {
        await Api.request({
            url: `/event/${eventId}/create-invitation-code`,
            method: 'post',
            payload: codeData,
            loading,
        });

        dispatch(getInvitationCodes(codeData.eventId));
    } catch (error) {
        dispatch(apiCallInvitationFail('deleteInvitationCode'));
    }
};

export const addMultipleInvitationCodes = (codeData) => async (dispatch, getState) => {
    const {
        invitations: {loading},
        event: {eventId},
    } = getState();

    dispatch(apiCallInvitationStart());

    try {
        await Api.request({
            url: `/event/${eventId}/generate-invitation-codes`,
            method: 'post',
            payload: codeData,
            loading,
        });

        dispatch(getInvitationCodes(codeData.eventId));
    } catch (error) {
        dispatch(apiCallInvitationFail('deleteInvitationCode'));
    }
};

export const deleteInvitationCode = (eventId, codeId) => async (dispatch, getState) => {
    const {
        invitations: {loading},
    } = getState();

    dispatch(apiCallInvitationStart());

    try {
        await Api.request({
            url: `/event/${eventId}/invitation-code/${eventId}/${codeId}`,
            method: 'delete',
            loading,
        });

        dispatch(getInvitationCodes(eventId));
    } catch (error) {
        dispatch(apiCallInvitationFail('deleteInvitationCode'));
    }
};

export const checkInvitationCode = (eventId, invitationCode) => async (dispatch, getState) => {
    const {
        invitations: {loading},
    } = getState();

    dispatch(apiCallInvitationStart());

    try {
        const data = {
            eventId: eventId,
            code: invitationCode,
        };

        const response = await Api.request({
            url: `/event/${eventId}/check-invitation-code`,
            method: 'post',
            payload: data,
            loading,
        });

        if (response && response.isCodeValid) {
            dispatch(checkInvitationCodeSuccess(response.isCodeValid, null));
        } else {
            dispatch(checkInvitationCodeFail(response.isCodeValid, response.message));
        }
    } catch (error) {
        dispatch(apiCallInvitationFail('checkInvitationCode'));
    }
};

const checkInvitationCodeSuccess = (isValid, errorMessage) => {
    return {
        type: actionTypes.CHECK_INVITATION_CODE_SUCCESS,
        payload: {isValid, errorMessage},
    };
};

const checkInvitationCodeFail = (isValid, errorMessage) => {
    return {
        type: actionTypes.CHECK_INVITATION_CODE_SUCCESS,
        payload: {isValid, errorMessage},
    };
};
