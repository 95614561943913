import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {makeStyles} from '@material-ui/core';

import {usePlatformTranslation} from 'services/hooks';
import {sideNavCloseSurvey, setActiveWall} from 'store/actions';
import {getEventData} from 'store/selectors';
import BackButton from 'Routes/components/BackButton/BackButton';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        boxShadow: '0px 5px 5px -5px rgba(0, 0, 0, 0.2)',
    },
    title: {
        textTransform: 'uppercase',
        color: theme.palette.text.darkGray,
        fontSize: '16px',
        fontWeight: 700,
    },
}));

const SurveyHeader = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {sideMenu} = usePlatformTranslation();
    const event = useSelector(getEventData);

    const closeSurveyTab = () => {
        const wallData = {
            wallId: event.liveWall._id,
            highlightUsers: [],
        };

        dispatch(setActiveWall(wallData));
        dispatch(sideNavCloseSurvey());
    };

    return (
        <div className={classes.root}>
            <BackButton onClickBack={closeSurveyTab} />
            <p className={classes.title}>{sideMenu?.survey}</p>
        </div>
    );
};

export default SurveyHeader;
