import React from 'react';
import Slider from 'react-slick/lib';

import NotificationPresentationStarts from '../NotificationPresentationStarts/NotificationPresentationStarts';
import BoothVideoDialog from '../BoothVideoDialog';
import BoothProductsDialog from '../BoothProductsDialog/BoothProductsDialog';
import BoothJobsDialog from '../BoothJobsDialog/BoothJobsDialog';
import BoothFullPreview from './components/BoothFullPreview/BoothFullPreview';
import BoothDocumentsDialog from './components/BoothDocumentsDialog/BoothDocumentsDialog';
import BoothLinksDialog from './components/BoothLinksDialog/BoothLinksDialog';

import {getUserIdFromParticipantId, getVideoIdFromYoutubeUrl, getPrivateChatId, getParticipantId} from 'services/utils';
import {AbilityContext} from 'permissionsConfig';

import {connectToBooth, disconnectFromBooth} from 'Api/socketApi';

import {ReactComponent as Arrow} from 'Images/showfloor-arrow-image.svg';
import FacebookLogo from 'Images/ri_facebook.svg';
import LinkedinLogo from 'Images/ri_linkedin.svg';
import TwitterLogo from 'Images/ri_twitter.svg';
import GenericLinkLogo from 'Images/ri_global.svg';
import NoVideoImage from 'assets/no-video.png';
import {ReactComponent as MarketplaceIcon} from 'Images/marketplace-inherit.svg';
import PlayIcon from 'Images/play.svg';

import './ExhibitorBooth.scss';

import {IS_CAREERS_ENV} from 'constants/shared';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';
import classNames from 'classnames';

class ExhibitorBooth extends React.Component {
    constructor(props) {
        super(props);
        this.companyHeading = React.createRef();
    }

    initialShow = {
        links: false,
        documents: false,
        video: false,
        products: false,
        jobs: false,
    };

    state = {
        exhibitor: null,
        exhibitorVideoId: '',
        companyNameFontSize: 40,
        hasFacebookSocialLink: false,
        hasLinkedinSocialLink: false,
        hasTwitterSocialLink: false,
        show: {...this.initialShow},
        linksContainerStyle: {},
        documentsContainerStyle: {},
        companyNameStyle: {},
        productsStyle: {},
        logoContainerStyle: {},
        videoContainerStyle: {},
        deskLeftStyle: {},
        deskRightStyle: {},
        medalionRepresentativesContainer: {},
        singleMedalion: {},
        socialLogoStyles: {},
    };

    componentDidMount() {
        const {activeExhibitorId, user, onGetExhibitor, onGetExhibitorJobs, getEventUsersList} = this.props;

        onGetExhibitor(activeExhibitorId);
        if (IS_CAREERS_ENV) onGetExhibitorJobs(activeExhibitorId);

        // emit socket event to let know that we have a visitor on booth
        connectToBooth({exhibitorId: activeExhibitorId, userId: user._id});

        getEventUsersList();

        this.setupBeforeUnloadListener();
        this.setActiveElementsPositions();
        window.addEventListener('resize', this.setActiveElementsPositions);
    }

    componentDidUpdate(prevProps) {
        const {exhibitor} = this.props;
        if (prevProps.loadingExhibitor === true && this.props.loadingExhibitor === false) {
            // we got the exhibitor
            // we can now set up the booth Data
            this.handleSetUpBoothData();
        }

        if (exhibitor) {
            window.addEventListener('resize', () => {
                const fontSize = this.getCompanyNameFontSize(exhibitor.company);

                this.setState({
                    companyNameFontSize: fontSize,
                });
            });
        }

        if (prevProps.activeExhibitorId !== this.props.activeExhibitorId) {
            // if we changed the activeExhibitorId get the exhibitor data
            this.props.onGetExhibitor(this.props.activeExhibitorId);
            if (IS_CAREERS_ENV) this.props.onGetExhibitorJobs(this.props.activeExhibitorId);
            disconnectFromBooth({exhibitorId: prevProps.activeExhibitorId, userId: this.props.user._id});
            connectToBooth({exhibitorId: this.props.activeExhibitorId, userId: this.props.user._id});
        }
    }

    handleSetUpBoothData = () => {
        // get the exhibitor data from the event
        const exhibitorData = this.props.exhibitor;
        const boothData = exhibitorData.booth;

        // set the active Wall to the booth Wall of this exhibitor
        // set up the array of users that can moderate posts and comments
        // set up the array of users that we want to highlight the posts for
        // in the booth wall, we need to highlight the representatives posts

        let highlightUsers = [];
        let canModerateChat = [];

        if (exhibitorData.user) {
            canModerateChat.push(exhibitorData.user);
        }

        boothData.medalionRepresentatives.forEach((representative) => {
            let userId = getUserIdFromParticipantId(this.props.eventUsersList, representative.user._id);

            if (userId) {
                highlightUsers.push(userId);
            }

            // add additional representatives to overseer users
            canModerateChat.push(representative.user);
        });

        if (boothData.deskRepresentativeLeft.representative) {
            let userId = getUserIdFromParticipantId(
                this.props.eventUsersList,
                boothData.deskRepresentativeLeft.representative
            );

            if (userId) {
                highlightUsers.push(userId);
            }
        }

        if (boothData.deskRepresentativeRight.representative) {
            let userId = getUserIdFromParticipantId(
                this.props.eventUsersList,
                boothData.deskRepresentativeRight.representative
            );

            if (userId) {
                highlightUsers.push(userId);
            }
        }

        const wallData = {
            wallId: exhibitorData.booth.boothWall,
            highlightUsers: highlightUsers,
            canModerateChat: canModerateChat,
        };

        this.props.setActiveWall(wallData);

        let hasFacebookLink =
            exhibitorData.booth.socialMediaLinks.findIndex((socialLink) => socialLink.platform === 'Facebook') !== -1;
        let hasLinkedinLink =
            exhibitorData.booth.socialMediaLinks.findIndex((socialLink) => socialLink.platform === 'LinkedIn') !== -1;
        let hasTwitterLink =
            exhibitorData.booth.socialMediaLinks.findIndex((socialLink) => socialLink.platform === 'Twitter') !== -1;

        let exhibitorVideoId = getVideoIdFromYoutubeUrl(exhibitorData.booth.video);

        // save in state the exhibitor data (including booth data)
        const fontSize = this.getCompanyNameFontSize(exhibitorData.company);

        this.setState({
            exhibitor: exhibitorData,
            exhibitorVideoId: exhibitorVideoId,
            hasFacebookSocialLink: hasFacebookLink,
            hasLinkedinSocialLink: hasLinkedinLink,
            hasTwitterSocialLink: hasTwitterLink,
            companyNameFontSize: fontSize,
        });
    };

    setupBeforeUnloadListener = () => {
        window.addEventListener('beforeunload', (ev) => {
            ev.preventDefault();
            const participant = this.props.eventUsersList.find((participant) => {
                return participant._id === this.props.user._id;
            });
            if (participant) {
                disconnectFromBooth({exhibitorId: this.state.exhibitor._id, userId: participant._id});
            }
        });
    };

    componentWillUnmount() {
        const {activeExhibitorId, user, onClearActiveExhibitorData} = this.props;

        // emit socket event to let know that a visitor has left the booth
        disconnectFromBooth({exhibitorId: activeExhibitorId, userId: user._id});
        onClearActiveExhibitorData();
        window.removeEventListener('resize', this.setActiveElementsPositions);
    }

    // TODO - refactor to TRANSFORM:TRANSLATE && use vw and vh as percentaget values
    setActiveElementsPositions = () => {
        // FontSize no less than 14 and not more than 40
        let realHeight = window.innerHeight - 315;

        if (window.innerHeight < 700) {
            realHeight = window.innerHeight - 210;
        }

        let linksContainerStyle = {
            width: (realHeight * 15.755) / 100,
            height: (realHeight * 9.003) / 100,
            left: (-realHeight * 5.78778) / 100,
            top: (realHeight * 10.209) / 100,
        };

        let documentsContainerStyle = {
            width: (realHeight * 20.718) / 100,
            height: (realHeight * 44.115) / 100,
            left: (realHeight * 27.797) / 100,
            top: (realHeight * 16.086) / 100,
        };

        let companyNameStyle = {
            width: (realHeight * 52.2) / 100,
            height: (realHeight * 9.965) / 100,
            left: (realHeight * 2.4) / 100,
            top: (-realHeight * 38.32) / 100,
        };
        let productsStyle = {
            width: (realHeight * 20.4) / 100,
            height: (realHeight * 35) / 100,
            left: (realHeight * 62.5) / 100,
            top: (realHeight * 25.5) / 100,
            // background: 'seagreen',
            // opacity: 0.6,
        };

        let logoContainerStyle = {
            width: (realHeight * 19.839) / 100,
            height: (realHeight * 10.808) / 100,
            left: (-realHeight * 22.347) / 100,
            top: (realHeight * 28.855) / 100,
        };

        let videoContainerStyle = {
            width: (realHeight * 32.828) / 100,
            height: (realHeight * 18.165) / 100,
            left: (realHeight * 61.84) / 100,
            top: (-realHeight * 15.398) / 100,
        };

        let deskLeftStyle = {
            width: (realHeight * 16.0771) / 100,
            height: (realHeight * 22.508) / 100,
            left: (-realHeight * 42.9) / 100,
            top: (realHeight * 6.59163) / 100,
        };

        let deskRightStyle = {
            width: (realHeight * 16.0771) / 100,
            height: (realHeight * 22.508) / 100,
            left: (-realHeight * 23.63) / 100,
            top: (realHeight * 6.59163) / 100,
        };

        let medalionRepresentativesContainer = {
            left: (-realHeight * 79.903) / 100,
            top: 0,
        };

        let singleMedalion = {
            width: (realHeight * 9.227) / 100,
            height: (realHeight * 9.227) / 100,
            marginBottom: (realHeight * 2.3) / 100,
        };

        let socialLogoStyles = {
            width: (realHeight * 15.755) / 100,
            height: (realHeight * 9.003) / 100,
        };

        this.setState({
            linksContainerStyle,
            documentsContainerStyle,
            logoContainerStyle,
            videoContainerStyle,
            companyNameStyle,
            productsStyle,
            deskLeftStyle,
            deskRightStyle,
            medalionRepresentativesContainer,
            singleMedalion,
            socialLogoStyles,
        });
    };

    showLinks = () => {
        this.setState({show: {...this.initialShow, links: true}});
    };
    showDocuments = () => {
        this.setState({show: {...this.initialShow, documents: true}});
    };
    showProducts = () => {
        this.setState({show: {...this.initialShow, products: true}});
    };

    showVideo = () => {
        this.setState({show: {...this.initialShow, video: true}});
        this.sendStatistics('videoViews', 'participantId');
    };

    showJobs = () => {
        this.setState({show: {...this.initialShow, jobs: true}});
    };

    closeDialog = () => {
        this.setState({show: {...this.initialShow}});
    };

    handleClickOnLink = () => {
        this.sendStatistics('linkClicks', 'participantId');
    };

    handleClickOnDocument = () => {
        this.sendStatistics('documentClicks', 'participantId');
    };

    handleClickOnRepresentativeChat = () => {
        this.sendStatistics('representativeChatClicks', 'userId');
    };

    sendStatistics = async (actionType, idFieldName) => {
        const {eventId, user, eventRoles, sendExhibitorStatistic} = this.props;
        const {exhibitor} = this.state;
        const ability = this.context;

        const isParticipantAccess = ability.can(
            ROLE_ABILITIES_TYPES.PARTICIPANT.SEND_STATISTICS,
            ROLE_FEATURES_TYPES.STATISTICS.ALL_BOOTH_STATISTICS
        );

        if (!isParticipantAccess) return;

        const participantId = getParticipantId(eventRoles, eventId);

        const dataToSend = {
            [idFieldName]: actionType === 'representativeChatClicks' ? user._id : participantId,
            actionType,
        };

        sendExhibitorStatistic({exhibitorId: exhibitor._id, payload: dataToSend});
    };

    handleOpenChatWithRepresentative = (userId) => {
        const {eventId} = this.props;
        const privateChatId = getPrivateChatId(this.props.user.privateChats, userId);
        // do not open chat with myself
        if (this.props.user._id !== userId) {
            this.handleClickOnRepresentativeChat();
            if (privateChatId) {
                this.props.onOpenPrivateChat(privateChatId);
            } else {
                if (userId !== this.props.user._id && !this.props.loading) {
                    if (userId !== this.props.user._id) {
                        this.props.onCreatePrivateChat(userId, eventId);
                    }
                }
            }
        }
    };

    getTextWidth = (text, font) => {
        // re-use canvas object for better performance
        let canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement('canvas'));
        let context = canvas.getContext('2d');
        context.font = `${font}px Montserrat`;
        context.textAlign = 'left';
        let metrics = context.measureText(text);
        return metrics.width;
    };

    getCompanyNameFontSize = (companyName) => {
        const {width} = this.state.companyNameStyle;
        const minFontSize = 13;
        const maxFontSize = 40;
        let headingSize = width;
        if (this.companyHeading.current) {
            headingSize = this.companyHeading.current.clientWidth;
        }

        let fontSize = 40;
        if (companyName.length > 4) {
            for (let i = maxFontSize; i >= minFontSize; i--) {
                let longestWordWidth = this.getTextWidth(companyName, i);

                if (longestWordWidth < headingSize) {
                    break;
                }
                fontSize = i - 1;
            }
        }
        return fontSize;
    };

    render() {
        const {
            exhibitor,
            hasFacebookSocialLink,
            hasLinkedinSocialLink,
            hasTwitterSocialLink,
            companyNameFontSize,
            linksContainerStyle,
            socialLogoStyles,
            documentsContainerStyle,
            companyNameStyle,
            videoContainerStyle,
            exhibitorVideoId,
            deskLeftStyle,
            deskRightStyle,
            medalionRepresentativesContainer,
            logoContainerStyle,
            singleMedalion,
            show,
        } = this.state;
        const {exhibitorJobs, translation} = this.props;

        const sliderSettingsBooth = {
            dots: false,
            arrows: false,
            autoplay: true,
            draggable: false,
            pauseOnHover: false,
            swipe: false,
            infinite: true,
            speed: 750,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        let nrOfBoothLinks = 0;

        if (exhibitor) {
            if (hasFacebookSocialLink) {
                nrOfBoothLinks++;
            }
            if (hasLinkedinSocialLink) {
                nrOfBoothLinks++;
            }
            if (hasTwitterSocialLink) {
                nrOfBoothLinks++;
            }
            if (exhibitor.booth.links.length > 0) {
                nrOfBoothLinks++;
            }
        }

        return (
            <>
                {exhibitor && (
                    <div className="single-booth-participant-view">
                        {exhibitor.products.length > 0 && (
                            <button
                                className="single-booth-participant-view__button-marketplace exhibitor-booth-button-marketplace"
                                onClick={this.showProducts}
                            >
                                <span className="exhibitor-booth-button-marketplace__title">
                                    {this.props.translation.marketplace.title.uppercased}
                                </span>
                                <MarketplaceIcon
                                    className="exhibitor-booth-button-marketplace__icon"
                                    width="20"
                                    height="20"
                                />
                            </button>
                        )}
                        {exhibitorJobs && exhibitorJobs.length > 0 && (
                            <button
                                className="single-booth-participant-view__button-marketplace exhibitor-booth-button-marketplace"
                                onClick={this.showJobs}
                            >
                                <span className="exhibitor-booth-button-marketplace__title">
                                    {translation.sideMenu.jobOpenings}
                                </span>
                                <MarketplaceIcon
                                    className="exhibitor-booth-button-marketplace__icon"
                                    width="20"
                                    height="20"
                                />
                            </button>
                        )}
                        <div
                            className="single-booth-participant-view__navigation exhibitor-booth-navigation exhibitor-booth-navigation--previous button"
                            onClick={this.props.previousExhibitor}
                        >
                            <div className="exhibitor-booth-navigation__wrapper">
                                <div className="exhibitor-booth-navigation__details exhibitor-booth-navigation__details--previous">
                                    <Arrow className="exhibitor-booth-navigation__arrow exhibitor-booth-navigation__arrow--previous primary-stroke-svg" />
                                </div>
                                <div className="exhibitor-booth-navigation__logo-container exhibitor-booth-navigation__logo-container--previous">
                                    {this.props.previousExhibitorHasLogo ? (
                                        <img
                                            className="exhibitor-booth-navigation__logo-container-img"
                                            src={this.props.previousExhibitorLogo}
                                            alt="previous"
                                        />
                                    ) : (
                                        <p>{this.props.previousExhibitorCompany}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div
                            className="single-booth-participant-view__navigation exhibitor-booth-navigation exhibitor-booth-navigation--next  button next"
                            onClick={this.props.nextExhibitor}
                        >
                            <div className="exhibitor-booth-navigation__wrapper">
                                <div className="exhibitor-booth-navigation__details exhibitor-booth-navigation__details--next">
                                    <Arrow className="exhibitor-booth-navigation__arrow exhibitor-booth-navigation__arrow--next primary-stroke-svg" />
                                </div>
                                <div className="exhibitor-booth-navigation__logo-container exhibitor-booth-navigation__logo-container--next">
                                    {this.props.nextExhibitorHasLogo ? (
                                        <img
                                            className="exhibitor-booth-navigation__logo-container-img"
                                            src={this.props.nextExhibitorLogo}
                                            alt="next"
                                        />
                                    ) : (
                                        <p>{this.props.nextExhibitorCompany}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <BoothFullPreview
                            className="single-booth-participant-view__booth-preview-container"
                            exhibitor={exhibitor}
                        />
                        <div className="single-booth-participant-view__links-wrapper">
                            {nrOfBoothLinks > 0 ? (
                                <div
                                    className="single-booth-participant-view__show-links clickable-element"
                                    onClick={this.showLinks}
                                    style={linksContainerStyle}
                                >
                                    {nrOfBoothLinks > 1 ? (
                                        <Slider {...sliderSettingsBooth}>
                                            {hasFacebookSocialLink && (
                                                <div className="single-booth-participant-view__show-links-img-wrapper">
                                                    <img
                                                        className="single-booth-participant-view__show-links-img"
                                                        src={FacebookLogo}
                                                        alt="Facebook"
                                                        style={socialLogoStyles}
                                                    />
                                                </div>
                                            )}
                                            {hasLinkedinSocialLink && (
                                                <div className="single-booth-participant-view__show-links-img-wrapper">
                                                    <img
                                                        className="single-booth-participant-view__show-links-img"
                                                        src={LinkedinLogo}
                                                        alt="LinkedIn"
                                                        style={socialLogoStyles}
                                                    />
                                                </div>
                                            )}
                                            {hasTwitterSocialLink && (
                                                <div className="single-booth-participant-view__show-links-img-wrapper">
                                                    <img
                                                        className="single-booth-participant-view__show-links-img"
                                                        src={TwitterLogo}
                                                        alt="Twitter"
                                                        style={socialLogoStyles}
                                                    />
                                                </div>
                                            )}
                                            {exhibitor.booth.links.length > 0 ? (
                                                <div className="single-booth-participant-view__show-links-img-wrapper">
                                                    <img
                                                        className="single-booth-participant-view__show-links-img"
                                                        src={GenericLinkLogo}
                                                        alt="Generic Link"
                                                        style={socialLogoStyles}
                                                    />
                                                </div>
                                            ) : null}
                                        </Slider>
                                    ) : (
                                        <>
                                            {hasFacebookSocialLink && (
                                                <div className="single-booth-participant-view__show-links-img-wrapper">
                                                    <img
                                                        className="single-booth-participant-view__show-links-img"
                                                        src={FacebookLogo}
                                                        alt="Facebook"
                                                        style={socialLogoStyles}
                                                    />
                                                </div>
                                            )}
                                            {hasLinkedinSocialLink && (
                                                <div className="single-booth-participant-view__show-links-img-wrapper">
                                                    <img
                                                        className="single-booth-participant-view__show-links-img"
                                                        src={LinkedinLogo}
                                                        alt="LinkedIn"
                                                        style={socialLogoStyles}
                                                    />
                                                </div>
                                            )}
                                            {hasTwitterSocialLink && (
                                                <div className="single-booth-participant-view__show-links-img-wrapper">
                                                    <img
                                                        className="single-booth-participant-view__show-links-img"
                                                        src={TwitterLogo}
                                                        alt="Twitter"
                                                        style={socialLogoStyles}
                                                    />
                                                </div>
                                            )}
                                            {exhibitor.booth.links.length > 0 ? (
                                                <div className="single-booth-participant-view__show-links-img-wrapper">
                                                    <img
                                                        className="single-booth-participant-view__show-links-img"
                                                        src={GenericLinkLogo}
                                                        alt="Generic Link"
                                                        style={socialLogoStyles}
                                                    />
                                                </div>
                                            ) : null}
                                        </>
                                    )}
                                </div>
                            ) : null}

                            {exhibitor.booth.documents.length > 0 && (
                                <div
                                    className="single-booth-participant-view__show-documents"
                                    onClick={this.showDocuments}
                                    style={documentsContainerStyle}
                                />
                            )}
                            {exhibitor.company && (
                                <div
                                    ref={this.companyHeading}
                                    className="single-booth-participant-view__company-name-wrapper"
                                    style={companyNameStyle}
                                >
                                    <h2
                                        style={{fontSize: `${companyNameFontSize}px`}}
                                        className="single-booth-participant-view__company-name"
                                    >
                                        {exhibitor.company}
                                    </h2>
                                </div>
                            )}

                            <div
                                className="single-booth-participant-view__show-video exhibitor-booth-show-video"
                                style={videoContainerStyle}
                            >
                                {exhibitor.booth.video ? (
                                    <div
                                        className="exhibitor-booth-show-video__image-preview-video"
                                        style={{
                                            backgroundImage: `url('https://img.youtube.com/vi/${exhibitorVideoId}/0.jpg')`,
                                        }}
                                        onClick={this.showVideo}
                                    >
                                        <div
                                            className="exhibitor-booth-show-video__play-button"
                                            style={{
                                                backgroundImage: `url('${PlayIcon}')`,
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        className="exhibitor-booth-show-video__image-preview-video no-video"
                                        style={{
                                            backgroundImage: `url('${
                                                exhibitor.booth.videoImage
                                                    ? exhibitor.filesUrl + exhibitor.booth.videoImage
                                                    : NoVideoImage
                                            }')`,
                                        }}
                                    ></div>
                                )}
                            </div>
                            {exhibitor.booth.deskRepresentativeLeft.user && (
                                <div
                                    className={classNames(
                                        'single-booth-participant-view__desk-representative',
                                        'exhibitor-booth-desk-representative',
                                        {
                                            'exhibitor-booth-desk-representative--no-hover':
                                                exhibitor.booth.deskRepresentativeLeft.user._id === this.props.user._id,
                                        }
                                    )}
                                    onClick={() =>
                                        this.handleOpenChatWithRepresentative(
                                            exhibitor.booth.deskRepresentativeLeft.user._id
                                        )
                                    }
                                    style={deskLeftStyle}
                                >
                                    <img
                                        className="exhibitor-booth-desk-representative__img"
                                        src={exhibitor.booth.deskRepresentativeLeft.deskAvatarUrl}
                                        alt="avatar"
                                    />
                                    {exhibitor.booth.deskRepresentativeLeft.user._id !== this.props.user._id ? (
                                        <div className="exhibitor-booth-desk-representative__hovering-container"></div>
                                    ) : null}
                                </div>
                            )}
                            {exhibitor.booth.deskRepresentativeRight.user && (
                                <div
                                    className={classNames(
                                        'single-booth-participant-view__desk-representative',
                                        'exhibitor-booth-desk-representative',
                                        {
                                            'exhibitor-booth-desk-representative--no-hover':
                                                exhibitor.booth.deskRepresentativeRight.user._id ===
                                                this.props.user._id,
                                        }
                                    )}
                                    style={deskRightStyle}
                                    onClick={() =>
                                        this.handleOpenChatWithRepresentative(
                                            exhibitor.booth.deskRepresentativeRight.user._id
                                        )
                                    }
                                >
                                    <img
                                        className="exhibitor-booth-desk-representative__img"
                                        src={exhibitor.booth.deskRepresentativeRight.deskAvatarUrl}
                                        alt="avatar"
                                    />
                                    {exhibitor.booth.deskRepresentativeRight.user._id !== this.props.user._id ? (
                                        <div className="exhibitor-booth-desk-representative__hovering-container" />
                                    ) : null}
                                </div>
                            )}

                            <div
                                className="single-booth-participant-view__medalion-representatives exhibitor-booth-medalion-representatives"
                                style={medalionRepresentativesContainer}
                            >
                                {exhibitor.booth.medalionRepresentatives.map((representative) => {
                                    let sameUser = this.props.user._id === representative.user._id;
                                    let representativeData = this.props.eventUsersList.find(
                                        (participant) => participant._id === representative.user._id
                                    );
                                    if (!representativeData) {
                                        representativeData = this.props.exhibitors.find(
                                            (exhibitor) => exhibitor.user._id === representative.user._id
                                        );
                                    }

                                    if (representativeData) {
                                        return (
                                            <div
                                                key={representativeData._id}
                                                onClick={() =>
                                                    this.handleOpenChatWithRepresentative(representative.user._id)
                                                }
                                                style={singleMedalion}
                                                className="exhibitor-booth-medalion-representatives__wrapper"
                                            >
                                                <div
                                                    className={classNames(
                                                        'exhibitor-booth-medalion-representatives__image-container',
                                                        {
                                                            'exhibitor-booth-medalion-representatives__image-container--no-hover':
                                                                sameUser,
                                                        }
                                                    )}
                                                    style={{
                                                        backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${representativeData.avatarSmall}')`,
                                                    }}
                                                />
                                            </div>
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                            <div className="single-booth-participant-view__logo" style={logoContainerStyle}>
                                {exhibitor.booth.logo && (
                                    <div
                                        key={`logo-${exhibitor?._id}`}
                                        className="single-booth-participant-view__logo-img"
                                        style={{
                                            backgroundImage: `url("${exhibitor.filesUrl}${exhibitor.booth.logo}")`,
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                        <BoothLinksDialog
                            opened={show.links}
                            closeDialog={this.closeDialog}
                            onClickOnLink={this.handleClickOnLink}
                        />

                        <BoothDocumentsDialog
                            opened={show.documents}
                            closeDialog={this.closeDialog}
                            documents={exhibitor.booth.documents}
                            filesUrl={exhibitor.filesUrl}
                            onClickOnDocument={this.handleClickOnDocument}
                        />

                        <BoothVideoDialog
                            opened={show.video}
                            closeDialog={this.closeDialog}
                            videoUrl={exhibitor.booth.video}
                        />
                        <BoothProductsDialog
                            opened={show.products}
                            closeDialog={this.closeDialog}
                            products={exhibitor.products}
                            exhibitorId={exhibitor._id}
                        />
                        <BoothJobsDialog
                            opened={show.jobs}
                            closeDialog={this.closeDialog}
                            onApplyToJob={this.props.onApplyToJob}
                            jobs={exhibitorJobs}
                            exhibitorId={exhibitor._id}
                        />
                    </div>
                )}
                <NotificationPresentationStarts />
            </>
        );
    }
}

ExhibitorBooth.contextType = AbilityContext;

export default ExhibitorBooth;
