import {nanoid} from 'nanoid';
import {USER_ADDITIONS_ROLES} from './shared';

export const emptyPollShape = {
    title: '',
    optionList: [
        {id: nanoid(), title: ''},
        {id: nanoid(), title: ''},
    ],
    isHidden: true,
    multiSelect: false,
    hideResults: false,
    isFinished: false,
    isCreated: true,
    isSaved: false,
};

export const formEmptyPoll = (id) => {
    return {...emptyPollShape, id};
};

export const TIMESLOT_SPEAKERS_HINT_TEXTS = {
    [USER_ADDITIONS_ROLES.speaker]:
        'The Speaker needs to be registered at the Event in order to add him to this timeslot.',
    [USER_ADDITIONS_ROLES.moderator]:
        'The Moderator needs to be registered at the Event in order to add him to this timeslot.',
    [USER_ADDITIONS_ROLES.interpreter]:
        'The Interpreter needs to be registered at the Event in order to add him to this timeslot. Add the interpreters with their interpretation language.',
};
