import * as actionTypes from './actionTypes';
import * as notificationTypes from '../actionTypes/notificationTypes';
import * as userActionsTypes from '../actionTypes/userTypes';
import * as matchingActions from '../actions/matchmakingActions';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import ReactPixel from 'react-facebook-pixel';
import TwitterConvTrkr from 'react-twitter-conversion-tracker';
import {NotificationManager} from 'react-notifications';
import {setLanguageDirection} from 'services/helpers';
import {allTranslations} from 'constants/translations/all-translations';
import {IS_SCA_ENV, IS_EGYPT_PAVILION_ENV, USER_ROLES, COOKIES_ACCEPTED_KEY} from 'constants/shared';
import {getEventInfo} from './eventActions';
import {clearCallState, disableScreenShare} from './sessionActions';
import {Api} from 'Api';
import {
    env,
    envList,
    facebookPixelId,
    googleAnalyticsId,
    googleAnalyticsMeasureId,
    googleTagManagerId,
    twitterPixelId,
} from 'config';
import {saveEventToPersonalList} from './eventListingActions';
import {generatePath} from 'react-router';
import {RouterConfig} from 'routerConfig';
import {createBrowserHistory} from 'history';
import {setCookiesAccepted} from 'services/helpers/setCookiesAccepted';

const history = createBrowserHistory({forceRefresh: true});
const isEnabledTagManager = IS_SCA_ENV || IS_EGYPT_PAVILION_ENV;

export const saveEventAfterLogin = (dispatch, preSaveEvent) => {
    if (preSaveEvent) {
        dispatch(saveEventToPersonalList(preSaveEvent.event));
        setTimeout(() => {
            history.push(generatePath(RouterConfig.event.myEvents, {eventSlug: preSaveEvent.eventSlug}));
        }, 1000);
    }
};

export const clearError = () => {
    return {
        type: actionTypes.CLEAR_ERROR,
    };
};

export const serverDown = () => {
    return {
        type: actionTypes.SERVER_DOWN,
    };
};
export const serverUp = () => {
    return {
        type: actionTypes.SERVER_UP,
    };
};

export const sessionExpired = () => {
    return {
        type: actionTypes.SESSION_EXPIRED,
    };
};

export const videoConferenceCompanionStatusChange = (payload) => ({
    type: actionTypes.VIDEO_CONFERENCE_COMPANION_DISCONNECTED,
    payload,
});

export const loginUser = (user, language) => async (dispatch, getState) => {
    const {
        user: {loading},
        eventListing: {preSaveEvent},
    } = getState();

    dispatch(loginUserStart());

    try {
        const {token} = await Api.request({
            url: 'users/login',
            method: 'POST',
            payload: user,
            loading,
        });

        localStorage.setItem('token', token);

        await Api.request({
            url: '/users',
            method: 'put',
            payload: {
                language,
            },
        });

        dispatch(refreshUserData());
        saveEventAfterLogin(dispatch, preSaveEvent);
    } catch (error) {
        let errorMessage = 'Error. Please try again.';
        if (error?.response) {
            errorMessage = error?.response?.data?.error;
        }
        dispatch(loginUserFail(errorMessage));
    }
};

export const autoLoginWithToken = () => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    let userToken = localStorage.getItem('token');
    let url_string = window.location.href;
    let url = new URL(url_string);
    // check if we have a token url parameter (from social login/register)
    // if we have one, we always use it
    let tokenUrl = url.searchParams.get('token');

    // if we are on the password recovery page, we don't use the URL token
    if (tokenUrl && url.pathname && !url.pathname.includes('password-recovery')) {
        localStorage.setItem('token', tokenUrl);
        userToken = tokenUrl;
    }

    if (userToken) {
        dispatch(loginUserStart());

        try {
            const {user} = await Api.request({
                url: '/users/me',
                method: 'get',
                loading,
            });

            const defaultPlatformLanguage = user.language;
            dispatch(setPlatformLanguage(defaultPlatformLanguage));
            dispatch(loginUserSuccess(user));
        } catch (error) {
            let errorMessage = 'Error. Please try again.';
            if (error?.response) {
                errorMessage = error.response.data.error;
            }
            dispatch(loginUserFail(errorMessage));
        }
    }
};

export const loginUserStart = () => {
    return {
        type: actionTypes.LOGIN_USER_START,
    };
};

export const loginUserSuccess = (user) => {
    if (!localStorage.getItem(COOKIES_ACCEPTED_KEY)) {
        setCookiesAccepted(true);
    }
    return {
        type: actionTypes.LOGIN_USER_SUCCESS,
        payload: user,
    };
};
export const loginUserFail = (err) => {
    return {
        type: actionTypes.LOGIN_USER_FAIL,
        payload: err,
    };
};

export const refreshUserData = () => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(loginUserStart());

    try {
        const {user} = await Api.request({
            url: '/users/me',
            method: 'get',
            loading,
        });

        const defaultPlatformLanguage = user.language;
        dispatch(setPlatformLanguage(defaultPlatformLanguage));
        dispatch(loginUserSuccess(user));
    } catch (error) {
        let errorMessage = '';
        if (error && error.response && error.response.data) {
            errorMessage = error.response.data.error;
        }
        dispatch(loginUserFail(errorMessage));
    }
};

export const registerUser = (user) => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(registerUserStart());
    const headers = {
        'content-type': 'multipart/form-data',
    };

    try {
        const {token, user: userData} = await Api.request({
            url: '/users',
            method: 'post',
            payload: user,
            loading,
            headers,
        });

        localStorage.setItem('token', token);

        if (!localStorage.getItem(COOKIES_ACCEPTED_KEY)) {
            setCookiesAccepted(true);

            if (env === envList.production) {
                ReactGA.initialize(IS_SCA_ENV ? googleAnalyticsMeasureId : googleAnalyticsId);

                isEnabledTagManager &&
                    TagManager.initialize({
                        gtmId: IS_SCA_ENV ? googleAnalyticsMeasureId : googleTagManagerId,
                    });

                if (IS_EGYPT_PAVILION_ENV) {
                    ReactPixel.init(facebookPixelId);
                    TwitterConvTrkr.init(twitterPixelId);
                }
            }
        }

        dispatch(registerUserSuccess(userData));
    } catch (error) {
        let errorMessage = '';
        if (error.response && error.response.data) {
            errorMessage = error.response.data.error;
        }
        dispatch(registerUserFail(errorMessage));
    }
};

export const registerUserStart = () => {
    return {
        type: actionTypes.REGISTER_USER_START,
    };
};
export const registerUserSuccess = (user) => {
    return {
        type: actionTypes.REGISTER_USER_SUCCESS,
        payload: user,
    };
};
export const registerUserFail = (err) => {
    return {
        type: actionTypes.REGISTER_USER_FAIL,
        payload: err,
    };
};

export const logoutUser = () => (dispatch, getState) => {
    const {
        event,
        etxSessionDashboard: {isActive}, // TODO: change to videoConference in group calls branch
    } = getState();
    const usingLanguage = IS_SCA_ENV ? allTranslations.ar.slug : event?.data?.language || allTranslations.en.slug;

    dispatch(logoutUserStart());
    localStorage.removeItem('auditoriumIndex');
    localStorage.removeItem('token');

    if (isActive) {
        dispatch(clearCallState());
        dispatch(disableScreenShare());
    }

    // check to not have the token saved in the localStorage
    if (!localStorage.getItem('token')) {
        dispatch(logoutUserSuccess());
        dispatch(setEventLanguage(usingLanguage));
        if (event.eventId) dispatch(getEventInfo(event.eventId));
    } else {
        dispatch(logoutUserFail());
    }
};

export const logoutUserStart = () => {
    return {
        type: actionTypes.LOGOUT_USER_START,
    };
};
export const logoutUserSuccess = () => {
    return {
        type: actionTypes.LOGOUT_USER_SUCCESS,
    };
};
export const logoutUserFail = () => {
    return {
        type: actionTypes.LOGOUT_USER_FAIL,
    };
};

export const sendContactRequest = (targetUserId) => async (dispatch, getState) => {
    const {
        user: {loading},
        event: {eventId},
    } = getState();

    dispatch(sendContactRequestStart());

    try {
        await Api.request({
            url: '/users/send-contact-request',
            method: 'post',
            payload: {
                targetUserId,
                eventId,
            },
            loading,
        });

        dispatch(sendContactRequestSuccess());
        dispatch(getSentRequests());
    } catch (error) {
        dispatch(sendContactRequestFail(error));
    }
};

export const sendContactRequestStart = () => {
    return {
        type: actionTypes.SEND_CONTACT_REQUEST_START,
    };
};
export const sendContactRequestSuccess = () => {
    return {
        type: actionTypes.SEND_CONTACT_REQUEST_SUCCESS,
    };
};
export const sendContactRequestFail = () => {
    return {
        type: actionTypes.SEND_CONTACT_REQUEST_FAIL,
    };
};

export const cancelBusinessCardRequest = (requestId) => async (dispatch) => {
    try {
        await Api.request({
            url: '/users/withdraw-contact-request',
            method: 'post',
            payload: {requestId},
        });

        dispatch(getSentRequests());
        dispatch(getContacts());
    } catch (error) {}
};

export const acceptContactRequest = (requestId, eventId) => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(acceptContactRequestStart());

    try {
        await Api.request({
            url: '/users/accept-contact-request',
            method: 'post',
            payload: {
                requestId,
                eventId,
            },
            loading,
        });

        dispatch(acceptContactRequestSuccess());
        dispatch(getReceivedRequests());
        dispatch(getContacts());
        dispatch(getPrivateChats());
    } catch (error) {
        dispatch(acceptContactRequestFail(error));
    }
};

export const getUsersBusinessCard = (userId) => async () => {
    try {
        await Api.request({
            url: `business-cards/users/${userId}`,
            method: 'get',
        });
    } catch (error) {}
};

export const acceptContactRequestStart = () => {
    return {
        type: actionTypes.ACCEPT_CONTACT_REQUEST_START,
    };
};
export const acceptContactRequestSuccess = () => {
    return {
        type: actionTypes.ACCEPT_CONTACT_REQUEST_SUCCESS,
    };
};
export const acceptContactRequestFail = () => {
    return {
        type: actionTypes.ACCEPT_CONTACT_REQUEST_FAIL,
    };
};

export const setParticipantsFilters = (filters = []) => {
    return {
        type: actionTypes.SET_PARTICIPANTS_FILTERS,
        payload: filters,
    };
};

const setFilteredParticipants = (data) => {
    return {
        type: actionTypes.SET_FILTERED_PARTICIPANTS,
        payload: data,
    };
};

const setFilteredParticipantsFail = () => {
    return {
        type: actionTypes.SET_FILTERED_PARTICIPANTS_FAIL,
    };
};

const setFilteredParticipantsStart = () => ({
    type: actionTypes.SET_FILTERED_PARTICIPANTS_START,
});

export const applyParticipantsFilters =
    ({eventId, filter = {}, pagination = {}, search = ''}) =>
    async (dispatch) => {
        dispatch(setFilteredParticipantsStart());

        try {
            const data = await Api.request({
                url: `/event/${eventId}/networking-users`,
                method: 'post',
                payload: {filter, pagination, search},
                loading: true,
            });
            dispatch(setFilteredParticipants(data));
        } catch (error) {
            dispatch(setFilteredParticipantsFail(error));
        }
    };

export const declineContactRequest = (requestId) => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(declineContactRequestStart());

    try {
        await Api.request({
            url: '/users/reject-contact-request',
            method: 'post',
            payload: {requestId},
            loading,
        });

        dispatch(declineContactRequestSuccess());
        dispatch(getReceivedRequests());
        dispatch(getContacts());
    } catch (error) {
        dispatch(declineContactRequestFail(error));
    }
};

export const declineContactRequestStart = () => {
    return {
        type: actionTypes.DECLINE_CONTACT_REQUEST_START,
    };
};
export const declineContactRequestSuccess = () => {
    return {
        type: actionTypes.DECLINE_CONTACT_REQUEST_SUCCESS,
    };
};
export const declineContactRequestFail = () => {
    return {
        type: actionTypes.DECLINE_CONTACT_REQUEST_FAIL,
    };
};

export const newNotification = (notification) => {
    switch (notification.type) {
        case notificationTypes.CONTACT_REQUEST_RECEIVED:
            return (dispatch) => {
                dispatch(getReceivedRequests());
                dispatch(getNotifications());
            };
        case notificationTypes.CONTACT_REQUEST_ACCEPTED:
            return (dispatch) => {
                dispatch(getContacts());
                dispatch(getSentRequests());
                dispatch(getNotifications());
            };
        default:
            return async (dispatch, getState) => {
                const {
                    user: {loading},
                } = getState();

                try {
                    const {user} = await Api.request({
                        url: '/users/me',
                        method: 'get',
                        loading,
                    });
                    dispatch(loginUserSuccess(user));
                } catch (error) {
                    dispatch(loginUserFail(error));
                }
            };
    }
};

export const getSentRequests = () => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(getSentRequestsStart());

    try {
        const {sentRequests} = await Api.request({
            url: '/users/me/sent-requests',
            method: 'get',
            loading,
        });

        dispatch(getSentRequestsSuccess(sentRequests));
    } catch (error) {
        dispatch(getSentRequestsFail(error));
    }
};

export const getSentRequestsStart = () => {
    return {
        type: actionTypes.GET_SENT_REQUESTS_START,
    };
};
export const getSentRequestsSuccess = (sentRequests) => {
    return {
        type: actionTypes.GET_SENT_REQUESTS_SUCCESS,
        payload: sentRequests,
    };
};
export const getSentRequestsFail = () => {
    return {
        type: actionTypes.GET_SENT_REQUESTS_FAIL,
    };
};

export const getReceivedRequests = () => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(getReceivedRequestsStart());

    try {
        const {receivedRequests} = await Api.request({
            url: '/users/me/received-requests',
            method: 'get',
            loading,
        });

        dispatch(getReceivedRequestsSuccess(receivedRequests));
    } catch (error) {
        dispatch(getReceivedRequestsFail(error));
    }
};

export const getReceivedRequestsStart = () => {
    return {
        type: actionTypes.GET_RECEIVED_REQUESTS_START,
    };
};
export const getReceivedRequestsSuccess = (receivedRequests) => {
    return {
        type: actionTypes.GET_RECEIVED_REQUESTS_SUCCESS,
        payload: receivedRequests,
    };
};
export const getReceivedRequestsFail = () => {
    return {
        type: actionTypes.GET_RECEIVED_REQUESTS_FAIL,
    };
};

export const setNotReadChatMessages = (numberOfNotReadMessages) => {
    return {
        type: actionTypes.SET_NOT_READ_CHAT_MESSAGES,
        payload: numberOfNotReadMessages,
    };
};

export const getContacts = () => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(getContactsStart());

    try {
        const {contacts} = await Api.request({
            url: '/users/me/contacts',
            method: 'get',
            loading,
        });

        dispatch(getContactsSuccess(contacts));
    } catch (error) {
        dispatch(getContactsFail(error));
    }
};

export const getContactsStart = () => {
    return {
        type: actionTypes.GET_CONTACTS_START,
    };
};
export const getContactsSuccess = (contacts) => {
    return {
        type: actionTypes.GET_CONTACTS_SUCCESS,
        payload: contacts,
    };
};
export const getContactsFail = () => {
    return {
        type: actionTypes.GET_CONTACTS_FAIL,
    };
};

export const getNotifications = () => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(getNotificationsStart());

    try {
        const {notifications} = await Api.request({
            url: '/users/me/notifications',
            method: 'get',
            loading,
        });

        dispatch(getNotificationsSuccess(notifications));
    } catch (error) {
        dispatch(getNotificationsFail(error));
    }
};

export const seeAllNotifications = () => async (dispatch) => {
    try {
        await Api.request({
            method: 'post',
            url: '/users/me/see-all-notifications',
            loading: false,
        });

        dispatch(getNotifications());
    } catch (error) {}
};

export const getNotificationsStart = () => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_START,
    };
};
export const getNotificationsSuccess = (notifications) => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_SUCCESS,
        payload: notifications,
    };
};
export const getNotificationsFail = () => {
    return {
        type: actionTypes.GET_NOTIFICATIONS_FAIL,
    };
};

export const getAllChats = () => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(getAllChatsStart());

    try {
        const {chats} = await Api.request({
            url: '/chats',
            method: 'get',
            loading,
        });

        dispatch(getAllChatsSuccess(chats));
    } catch (error) {
        dispatch(getAllChatsFail(error));
    }
};

export const getAllChatsStart = () => {
    return {
        type: actionTypes.GET_ALL_CHATS_START,
    };
};
export const getAllChatsSuccess = (allChats) => {
    return {
        type: actionTypes.GET_ALL_CHATS_SUCCESS,
        payload: allChats,
    };
};
export const getAllChatsFail = () => {
    return {
        type: actionTypes.GET_ALL_CHATS__FAIL,
    };
};

export const getEventChats = (eventId) => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(getEventChatsStart());

    try {
        const {chats} = await Api.request({
            url: `/chats/${eventId}`,
            method: 'get',
            loading,
        });

        dispatch(getEventChatsSuccess(chats || []));
    } catch (error) {
        dispatch(getEventChatsFail(error));
    }
};

export const getEventChatsStart = () => {
    return {
        type: actionTypes.GET_EVENT_CHATS_START,
    };
};
export const getEventChatsSuccess = (eventChats) => {
    return {
        type: actionTypes.GET_EVENT_CHATS_SUCCESS,
        payload: eventChats,
    };
};
export const getEventChatsFail = () => {
    return {
        type: actionTypes.GET_EVENT_CHATS__FAIL,
    };
};

export const getArchivedChats = (openChatIdAfterFetchingTheChats) => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(getArchivedChatsStart());

    try {
        const {chats} = await Api.request({
            url: '/chats/archived',
            method: 'get',
            loading,
        });

        dispatch(getArchivedChatsSuccess(chats));
        if (openChatIdAfterFetchingTheChats) {
            dispatch(openPrivateChat(openChatIdAfterFetchingTheChats));
        }
    } catch (error) {
        dispatch(getArchivedChatsFail(error));
    }
};

export const getArchivedChatsStart = () => {
    return {
        type: actionTypes.GET_ARCHIVED_CHATS_START,
    };
};
export const getArchivedChatsSuccess = (archivedChats) => {
    return {
        type: actionTypes.GET_ARCHIVED_CHATS_SUCCESS,
        payload: archivedChats,
    };
};
export const getArchivedChatsFail = () => {
    return {
        type: actionTypes.GET_ARCHIVED_CHATS__FAIL,
    };
};

export const getGroupChats = (openChatIdAfterFetchingTheChats) => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(getGroupChatsStart());

    try {
        const {chats} = await Api.request({
            url: '/group-chats/me',
            method: 'get',
            loading,
        });

        dispatch(getGroupChatsSuccess(chats));
        if (openChatIdAfterFetchingTheChats) {
            dispatch(openGroupChat(openChatIdAfterFetchingTheChats));
        }
    } catch (error) {
        dispatch(getGroupChatsFail(error));
    }
};

export const getGroupChatsStart = () => {
    return {
        type: actionTypes.GET_GROUP_CHATS_START,
    };
};
export const getGroupChatsSuccess = (groupChats) => {
    return {
        type: actionTypes.GET_GROUP_CHATS_SUCCESS,
        payload: groupChats,
    };
};
export const getGroupChatsFail = () => {
    return {
        type: actionTypes.GET_GROUP_CHATS__FAIL,
    };
};

export const getPrivateChats = (openChatIdAfterFetchingTheChats) => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(getPrivateChatsStart());

    try {
        const {chats} = await Api.request({
            url: '/private-chats/me',
            method: 'get',
            loading,
        });

        dispatch(getPrivateChatsSuccess(chats));
        if (openChatIdAfterFetchingTheChats) {
            dispatch(openPrivateChat(openChatIdAfterFetchingTheChats));
        }
    } catch (error) {
        dispatch(getPrivateChatsFail(error));
    }
};

export const getPrivateChatsStart = () => {
    return {
        type: actionTypes.GET_PRIVATE_CHATS_START,
    };
};
export const getPrivateChatsSuccess = (privateChats) => {
    return {
        type: actionTypes.GET_PRIVATE_CHATS_SUCCESS,
        payload: privateChats,
    };
};
export const getPrivateChatsFail = () => {
    return {
        type: actionTypes.GET_GROUP_CHATS__FAIL,
    };
};

export const openPrivateChat = (privateChatId) => {
    return {
        type: actionTypes.OPEN_PRIVATE_CHAT,
        privateChatId,
    };
};

export const openGroupChat = (groupChatId) => {
    return {
        type: actionTypes.OPEN_GROUP_CHAT,
        payload: groupChatId,
    };
};

export const resetActiveChatId = () => {
    return {
        type: actionTypes.RESET_ACTIVE_CHAT_ID,
    };
};

export const setActiveChatData = (activeChatData) => {
    return {
        type: actionTypes.SET_ACTIVE_CHAT_DATA,
        payload: activeChatData,
    };
};

export const updateActiveChatData = (activeChatData) => {
    return {
        type: actionTypes.UPDATE_ACTIVE_CHAT_DATA,
        payload: activeChatData,
    };
};

export const closeActiveChat = () => {
    return {
        type: actionTypes.CLOSE_ACTIVE_CHAT,
    };
};

export const openChatBusinessCard = () => {
    return {
        type: actionTypes.OPEN_CHAT_BUSINESS_CARD,
    };
};

export const closeChatBusinessCard = () => {
    return {
        type: actionTypes.CLOSE_CHAT_BUSINESS_CARD,
    };
};

export const markNotificationAsRead = (notificationId) => (dispatch) => {
    dispatch(markNotificationAsReadStart());
    dispatch(markNotificationAsReadSuccess(notificationId));
};

export const markNotificationAsReadStart = () => {
    return {
        type: actionTypes.MARK_NOTIFICATION_AS_READ_START,
    };
};
export const markNotificationAsReadSuccess = (notificationId) => {
    return {
        type: actionTypes.MARK_NOTIFICATION_AS_READ_SUCCESS,
        payload: notificationId,
    };
};
export const markNotificationAsReadFail = () => {
    return {
        type: actionTypes.MARK_NOTIFICATION_AS_READ_FAIL,
    };
};

export const createGroupChat = (chatData) => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(createGroupChatStart());

    try {
        const {groupChat} = await Api.request({
            url: '/group-chat',
            method: 'post',
            payload: chatData,
            loading,
        });

        dispatch(getGroupChats(groupChat._id));
    } catch (error) {
        dispatch(createGroupChatFail(error));
    }
};

export const createGroupChatStart = () => {
    return {
        type: actionTypes.CREATE_GROUP_CHAT_START,
    };
};
export const createGroupChatSuccess = (newGroupChatId) => {
    return {
        type: actionTypes.CREATE_GROUP_CHAT_SUCCESS,
        payload: newGroupChatId,
    };
};
export const createGroupChatFail = () => {
    return {
        type: actionTypes.CREATE_GROUP_CHAT_FAIL,
    };
};

export const createPrivateChat = (targetUserId, eventId) => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(createPrivateChatStart());

    try {
        const {privateChat} = await Api.request({
            url: '/private-chat',
            method: 'post',
            payload: {
                targetUserId,
                eventId,
            },
            loading,
        });

        dispatch(getPrivateChats(privateChat._id));
    } catch (error) {
        dispatch(createPrivateChatFail(error));
    }
};

export const createPrivateChatStart = () => {
    return {
        type: actionTypes.CREATE_PRIVATE_CHAT_START,
    };
};
export const createPrivateChatSuccess = (newPrivateChat) => {
    return {
        type: actionTypes.CREATE_PRIVATE_CHAT_SUCCESS,
        payload: newPrivateChat,
    };
};
export const createPrivateChatFail = () => {
    return {
        type: actionTypes.CREATE_PRIVATE_CHAT_FAIL,
    };
};

export const getPrivateChatMessages =
    ({roomId, callback}) =>
    async () => {
        try {
            const {messages, chatPartnerId} = await Api.request({
                method: 'get',
                url: `/private-chat/${roomId}/messages`,
            });

            callback && callback({messages, chatPartnerId});
        } catch (error) {
            console.error(error);
        }
    };

export const sendMessageInPrivateChat = (chatId, message, callback) => async (dispatch, getState) => {
    const {
        user: {loading},
    } = getState();

    dispatch(sendMessageInPrivateChatStart());

    try {
        await Api.request({
            url: `/private-chat/${chatId}/messages`,
            method: 'post',
            payload: {
                text: message,
            },
            loading,
        });

        dispatch(sendMessageInPrivateChatSuccess());

        callback && callback();
    } catch (error) {
        dispatch(sendMessageInPrivateChatFail(error));
    }
};

export const sendGroupChatMessage =
    ({activeChatRoomId, newMessage, callback}) =>
    async () => {
        try {
            await Api.request({
                method: 'post',
                url: `/group-chat/${activeChatRoomId}/messages`,
                payload: {text: newMessage},
            });

            callback && callback();
        } catch (error) {}
    };

export const sendMessageInPrivateChatStart = () => {
    return {
        type: actionTypes.SEND_MESSAGE_IN_PRIVATE_CHAT_START,
    };
};
export const sendMessageInPrivateChatSuccess = () => {
    return {
        type: actionTypes.SEND_MESSAGE_IN_PRIVATE_CHAT_SUCCESS,
    };
};
export const sendMessageInPrivateChatFail = () => {
    return {
        type: actionTypes.SEND_MESSAGE_IN_PRIVATE_CHAT_FAIL,
    };
};

export const archiveChat =
    (chatType, chatId, isArchived = false) =>
    async (dispatch) => {
        const activeTab = {
            archivedChats: !isArchived ? true : false,
        };

        try {
            await Api.request({url: `/${chatType}/${chatId}`, method: 'patch', payload: {archived: !isArchived}});

            NotificationManager.info(`Chat was ${isArchived ? 'unarchived' : 'archived'}`);
            await Promise.all([dispatch(getPrivateChats()), dispatch(getGroupChats())]);

            await dispatch(updateActiveChatTab(activeTab));
            await dispatch(closeActiveChat());
        } catch (error) {
            NotificationManager.error('Error on archiving the chat');
        }
    };

export const deleteChat = (chatType, chatId) => async (dispatch) => {
    try {
        await Api.request({
            url: `/${chatType}/${chatId}`,
            method: 'delete',
        });

        NotificationManager.info('Chat was deleted');

        dispatch(getPrivateChats());
        dispatch(getGroupChats());
    } catch (error) {}
};

export const getGroupChatMessages =
    ({activeChatRoomId, callback}) =>
    async () => {
        try {
            const {messages} = await Api.request({
                method: 'get',
                url: `/group-chat/${activeChatRoomId}/messages`,
            });

            callback && callback(messages);
        } catch (error) {}
    };

export const removeUserFromGroupChat =
    ({activeChatRoomId, userId, callback}) =>
    async () => {
        try {
            await Api.request({
                method: 'delete',
                url: `/group-chat/${activeChatRoomId}/remove-user`,
                payload: {targetUserId: userId},
            });

            callback && callback();
        } catch (error) {}
    };

export const leaveGroupChat =
    ({activeChatRoomId, userId, callback}) =>
    async () => {
        try {
            await Api.request({
                method: 'delete',
                url: `/group-chat/${activeChatRoomId}/leave`,
                payload: {targetUserId: userId},
            });

            callback && callback();
        } catch (error) {}
    };

export const getGroupChatData =
    ({activeChatRoomId, callback}) =>
    async () => {
        try {
            const {groupChat} = await Api.request({
                method: 'get',
                url: `/group-chat/${activeChatRoomId}`,
                loading: true,
            });

            callback && callback(groupChat);
        } catch (error) {}
    };

export const updateGroupChat =
    ({activeChatRoomId, usersToAdd, chatName, chat, callback}) =>
    async () => {
        const addUsersPromises = usersToAdd.map(async (userToAdd) => {
            try {
                await Api.request({
                    method: 'post',
                    url: `/group-chat/${activeChatRoomId}/add-user`,
                    payload: {targetUserId: userToAdd._id},
                    loading: true,
                });
            } catch (error) {}
        });
        await Promise.all(addUsersPromises).catch((err) => {});

        // update chat name
        if (chatName !== chat.name) {
            try {
                await Api.request({
                    method: 'put',
                    url: `/group-chat/${activeChatRoomId}`,
                    payload: {name: chatName},
                });
            } catch (error) {}
        }

        callback && callback();
    };

export const updateActiveChatTab =
    ({eventChats = false, archivedChats = false} = {}, eventId = '') =>
    (dispatch) => {
        // update the event/archive chats list in case corresponding tab is active
        if (archivedChats) {
            dispatch(setArchivedChats());
            return dispatch(getArchivedChats());
        }

        if (eventChats && eventId) {
            dispatch(setEventChats(eventId));
            return dispatch(getEventChats(eventId));
        }

        // update the all chats list by default
        dispatch(setAllChats());
        return dispatch(getAllChats());
    };

export const topNavSeeContacts = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_CONTACTS,
    };
};

export const topNavSeeProgram = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_PROGRAM,
    };
};

export const topNavSeeWallet = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_WALLET,
    };
};

export const topNavSeeAgenda = (activeTab = 'program') => {
    return {
        type: actionTypes.TOP_NAV_SEE_AGENDA,
        payload: {activeTab},
    };
};

export const openWallet = (walletView = 'wallet', walletTab = 'contacts', cardId = '') => ({
    type: actionTypes.OPEN_WALLET,
    walletView,
    walletTab,
    cardId,
});

export const topNavSeeChats = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_CHATS,
    };
};

export const openScheduleBottomSheet = () => ({type: actionTypes.TOP_NAV_BOTTOM_SHEET});

export const topNavSeeNotifications = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_NOTIFICATIONS,
    };
};

export const topNavSeeMyEvents = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_MY_EVENTS,
    };
};

export const topNavSeeMyBooths = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_MY_BOOTHS,
    };
};

export const topNavSeeMyAccount = () => {
    return {
        type: actionTypes.TOP_NAV_SEE_MY_ACCOUNT,
    };
};

export const topNavClose = () => {
    return {
        type: actionTypes.TOP_NAV_CLOSE,
    };
};

export const bottomSeeRooms = () => {
    return {
        type: actionTypes.BOTTOM_SEE_ROOMS,
    };
};

export const sideNavSeeSocialMedia = () => {
    return {
        type: actionTypes.SIDE_NAV_SEE_SOCIAL_MEDIA,
    };
};
export const sideNavSeeInterpretation = () => {
    return {
        type: actionTypes.SIDE_NAV_SEE_INTERPRETATION,
    };
};

export const sideNavSeeSurvey = () => {
    return {
        type: actionTypes.SIDE_NAV_SEE_SURVEY,
    };
};

export const sideNavCloseSurvey = () => {
    return {
        type: actionTypes.SIDE_NAV_CLOSE_SURVEY,
    };
};

export const sideNavSeeNetworking = () => {
    return {
        type: actionTypes.SIDE_NAV_SEE_NETWORKING,
    };
};

export const sideNavSeeLiveWall = () => {
    return {
        type: actionTypes.SIDE_NAV_SEE_LIVE_WALL,
    };
};

export const sideNavSeeVideoWall = () => {
    return {
        type: actionTypes.SIDE_NAV_SEE_VIDEO_WALL,
    };
};

export const sideNavSeeInfoTab = () => {
    return {
        type: actionTypes.SIDE_INFO_TAB,
    };
};

export const sideNavClose = () => {
    return {
        type: actionTypes.SIDE_NAV_CLOSE,
    };
};

export const topNavOnventProfile = () => {
    return {
        type: actionTypes.TOP_NAV_ONVENT_PROFILE,
    };
};

export const topNavEventProfile = () => {
    return {
        type: actionTypes.TOP_NAV_EVENT_PROFILE,
    };
};

export const expandedChatsOnVideoConference = () => {
    return {
        type: actionTypes.EXPANDED_CHATS_COMPONENT,
    };
};

export const setUserEventRoles = (rolesData) => {
    return {
        type: actionTypes.SET_USER_EVENT_ROLES,
        payload: rolesData,
    };
};

export const newPrivateChatMessage = (data) => {
    return {
        type: actionTypes.NEW_PRIVATE_CHAT_MESSAGE,
        payload: data,
    };
};

export const newGroupChatMessage = (data) => {
    return {
        type: actionTypes.NEW_GROUP_CHAT_MESSAGE,
        payload: data,
    };
};

export const readMessagesForPrivateChat = (chatId) => {
    return {
        type: actionTypes.READ_MESSAGES_FOR_PRIVATE_CHAT,
        payload: chatId,
    };
};

export const readMessagesForGroupChat = (data) => {
    return {
        type: actionTypes.READ_MESSAGES_FOR_GROUP_CHAT,
        payload: data,
    };
};

export const setUserConnectedToNotifications = () => {
    return {
        type: actionTypes.SET_USER_CONNECTED_TO_NOTIFICATIONS,
    };
};

export const createPaymentIntent = (data, role) => async (dispatch, getState) => {
    const {
        user: {loading},
        event: {eventId},
    } = getState();

    dispatch(createPaymentIntentStart());

    try {
        let url = `/event/${eventId}/ngenius/create-payment-intent`;
        if (role && role === USER_ROLES.exhibitor) {
            url = `/event/${eventId}/ngenius/create-exhibitor-payment-intent`;
        }

        const {paymentPageUrl} = await Api.request({
            url,
            method: 'post',
            payload: data,
            loading,
        });

        dispatch(createPaymentIntentSuccess(paymentPageUrl));
    } catch (error) {
        dispatch(createPaymentIntentFail(error));
    }
};

export const resetEventPaymentLoading = () => ({type: actionTypes.RESET_EVENT_PAYMENT_LOADING});

export const createPaymentIntentStart = () => {
    return {
        type: actionTypes.CREATE_PAYMENT_INTENT_START,
    };
};
export const createPaymentIntentSuccess = (clientSecret) => {
    return {
        type: actionTypes.CREATE_PAYMENT_INTENT_SUCCESS,
        payload: clientSecret,
    };
};
export const createPaymentIntentFail = () => {
    return {
        type: actionTypes.CREATE_PAYMENT_INTENT_FAIL,
    };
};

export const cancelPaymentIntent = () => {
    return {
        type: actionTypes.CANCEL_PAYMENT_INTENT,
    };
};

export const setEventAccess = (resourcesAccess) => {
    return {
        type: actionTypes.SET_EVENT_ACCESS,
        payload: resourcesAccess,
    };
};
export const setPreselectedPackageId = (packageId) => {
    return {
        type: actionTypes.SET_PRESELECTED_PACKAGE_ID,
        payload: packageId,
    };
};

export const setEventLanguage = (language) => {
    // this function is also called from getEventInfo in eventActions
    // after we get the eventInfo we immediatelly set the default language that the organizer chose
    setLanguageDirection(language);

    return {
        type: actionTypes.SET_EVENT_LANGUAGE,
        payload: language,
    };
};

export const setPlatformLanguage = (language) => {
    setLanguageDirection(language);

    return {
        type: actionTypes.SET_PLATFORM_LANGUAGE,
        payload: language,
    };
};

export const setEventChats = () => {
    return {
        type: actionTypes.SET_EVENT_CHATS_TAB,
    };
};

export const setAllChats = () => {
    return {
        type: actionTypes.SET_ALL_CHATS_TAB,
    };
};

export const setArchivedChats = () => {
    return {
        type: actionTypes.SET_ARCHIVE_TAB,
    };
};

export const setNotArchivedChats = () => {
    return {
        type: actionTypes.SET_NOT_ARCHIVE_TAB,
    };
};

export const setUserRoles = (userRoles) => ({
    type: actionTypes.SET_USER_ROLES,
    payload: userRoles,
});

export const changeWallType = (type) => ({
    type: actionTypes.CHANGE_WALL_TYPE,
    payload: type,
});

export const changeWallStatus = (isClosed) => ({
    type: actionTypes.CHANGE_WALL_STATUS,
    payload: isClosed,
});

export const toggleWall = (isMounted) => ({
    type: actionTypes.TOGGLE_WALL,
    payload: isMounted,
});

export const closeWall = () => ({
    type: actionTypes.CLOSE_WALL,
});

export const sendPasswordResetEmail = (email, callback) => async () => {
    try {
        const {message} = await Api.request({
            method: 'post',
            url: '/auth/recover-password',
            payload: {email},
        });
        NotificationManager.info(message);
        callback && callback();
    } catch (error) {
        callback && callback();
    }
};

const getUserMatchingAnswersStart = () => ({
    type: userActionsTypes.GET_USER_MATCHING_ANSWERS_START,
});

const getUserMatchingAnswersSuccess = (data) => ({
    type: userActionsTypes.GET_USER_MATCHING_ANSWERS_SUCCESS,
    payload: data,
});

const getUserMatchingAnswersFail = (error) => ({
    type: userActionsTypes.GET_USER_MATCHING_ANSWERS_FAIL,
    payload: error,
});

export const getUserMatchingAnswers = (matchingFormId) => {
    return async (dispatch, getState) => {
        dispatch(getUserMatchingAnswersStart());
        try {
            const {
                event: {eventId},
            } = getState();

            const {matchingAnswer} = await Api.request({
                url: `/event/${eventId}/questionnaires/${matchingFormId}/answer`,
                method: 'get',
            });

            dispatch(getUserMatchingAnswersSuccess(matchingAnswer));
        } catch (error) {
            dispatch(getUserMatchingAnswersFail(error));
        }
    };
};

const postMatchingAnswersStart = () => ({
    type: userActionsTypes.POST_MATCHING_ANSWERS_START,
});

const postMatchingAnswersSuccess = (data) => ({
    type: userActionsTypes.POST_MATCHING_ANSWERS_SUCCESS,
    payload: data,
});

const postMatchingAnswersFail = (error) => ({
    type: userActionsTypes.POST_MATCHING_ANSWERS_FAIL,
    payload: error,
});

export const postMatchingAnswers = ({matchingFormId, data, callback}) => {
    return async (dispatch, getState) => {
        dispatch(postMatchingAnswersStart());
        try {
            const {
                event: {eventId},
            } = getState();
            const {
                matches,
                answer: {answers},
            } = await Api.request({
                url: `/event/${eventId}/questionnaires/${matchingFormId}/answer`,
                method: 'post',
                payload: data,
            });
            dispatch(matchingActions.setMatchingMatches(matches));
            dispatch(postMatchingAnswersSuccess(answers));
            callback && callback();
        } catch (error) {
            dispatch(postMatchingAnswersFail(error));
        }
    };
};

const deleteMatchingAnswersStart = () => ({
    type: userActionsTypes.DELETE_MATCHING_ANSWERS_START,
});

const deleteMatchingAnswersSuccess = (eventId) => ({
    type: userActionsTypes.DELETE_MATCHING_ANSWERS_SUCCESS,
    payload: eventId,
});

const deleteMatchingAnswersFail = (error) => ({
    type: userActionsTypes.DELETE_MATCHING_ANSWERS_FAIL,
    payload: error,
});

export const deleteMatchingAnswers = () => {
    return async (dispatch, getState) => {
        dispatch(deleteMatchingAnswersStart());
        try {
            const {
                event: {eventId},
                matchmaking: {matchingForm},
            } = getState();

            await Api.request({
                url: `/event/${eventId}/questionnaires/${matchingForm._id}/answer`,
                method: 'delete',
            });

            dispatch(deleteMatchingAnswersSuccess(eventId));
            dispatch(setCompleteMatching({eventId, completeMatching: false}));
        } catch (error) {
            dispatch(deleteMatchingAnswersFail(error));
        }
    };
};

const getUserMatchesStart = () => ({
    type: userActionsTypes.GET_USER_MATCHES_START,
});

const getUserMatchesSuccess = (data) => ({
    type: userActionsTypes.GET_USER_MATCHES_SUCCESS,
    payload: data,
});

const getUserMatchesFail = (error) => ({
    type: userActionsTypes.GET_USER_MATCHES_FAIL,
    payload: error,
});

export const getUserMatches = ({page = 1, perPage = 50, matchLevels = '', search = ''}) => {
    return async (dispatch, getState) => {
        dispatch(getUserMatchesStart());
        try {
            const {
                event: {eventId},
            } = getState();
            const {success, matches, ...pagination} = await Api.request({
                url: `/event/${eventId}/matches`,
                method: 'get',
                params: {page, perPage, matchLevels, search},
                loading: false,
            });

            dispatch(getUserMatchesSuccess({pagination, matches}));
        } catch (error) {
            dispatch(getUserMatchesFail(error));
        }
    };
};

const recalculateUserMatchesStart = () => ({
    type: userActionsTypes.RECALCULATE_USER_MATCHES_START,
});

const recalculateUserMatchesSuccess = (data) => ({
    type: userActionsTypes.RECALCULATE_USER_MATCHES_SUCCESS,
    payload: data,
});

const recalculateUserMatchesFail = (error) => ({
    type: userActionsTypes.RECALCULATE_USER_MATCHES_FAIL,
    payload: error,
});

export const recalculateUserMatches = ({callback}) => {
    return async (dispatch, getState) => {
        dispatch(recalculateUserMatchesStart());
        try {
            const {
                event: {eventId},
            } = getState();

            await Api.request({
                url: `/event/${eventId}/matches`,
                method: 'post',
                loading: false,
            });

            dispatch(recalculateUserMatchesSuccess());
            callback && callback();
        } catch (error) {
            dispatch(recalculateUserMatchesFail(error));
        }
    };
};

export const setCompleteMatching = (payload) => ({
    type: userActionsTypes.SET_COMPLETE_MATCHING,
    payload,
});

const updatePasswordStart = () => ({
    type: userActionsTypes.UPDATE_PASSWORD_START,
});

const updatePasswordSuccess = (data) => ({
    type: userActionsTypes.UPDATE_PASSWORD_SUCCESS,
    payload: data,
});

const updatePasswordFail = (error) => ({
    type: userActionsTypes.UPDATE_PASSWORD_FAIL,
    payload: error,
});

export const updatePassword = (data) => async (dispatch) => {
    dispatch(updatePasswordStart());
    try {
        await Api.request({
            url: `/users/me/update-password`,
            method: 'post',
            payload: data,
        });

        dispatch(updatePasswordSuccess());
        dispatch(refreshUserData());
    } catch (error) {
        dispatch(updatePasswordFail(error));
    }
};

export const sendHomepageContactForm =
    ({payload, callback, errorCallback}) =>
    async () => {
        try {
            await Api.request({
                method: 'post',
                url: '/contact-form-message',
                payload,
                loading: true,
            });

            callback && callback();
        } catch (error) {
            errorCallback && errorCallback();
        }
    };
