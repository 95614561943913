import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
    },
    spinner: {
        marginRight: 10,
        color: theme.palette.primary.main,
    },
    text: {
        color: theme.palette.text.primary,
        fontSize: 16,
        fontWeight: 500,
    },
}));
