import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import NextVideoTimer from './NextVideoTimer';

export default connect(
    (state) => ({
        transition: selectors.getTranslation(state),
    }),
    {
        onResetTimeslotData: actions.resetTimeslotData,
    }
)(NextVideoTimer);
