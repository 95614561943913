import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
    },
    micIndicator: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '0.0015em',
        margin: '0 25px 0 0',
    },
    canvasContainer: {
        width: 200,
        height: 4,
        background: theme.palette.grey.A150,
        borderRadius: '5px',
        marginLeft: 15,
        position: 'relative',
    },
    canvas: {
        position: 'absolute',
        top: 0,
        left: 0,
    },
    deviceSelect: {
        width: 180,
        '& > .MuiInputBase-root': {
            //TODO: refactor
            height: '100%',
        },
    },
    selectedValue: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
        paddingTop: 0,
        paddingBottom: 0,
    },
    arrowDownIcon: {
        color: theme.palette.text.primary,
    },
}));
