import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {Dialog, DialogContent, DialogTitle, Button} from '@material-ui/core';
import {ArrowBackIos as ArrowBackIosIcon} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import {getCurrencySymbol} from 'services/utils';
import {usePlatformTranslation} from 'services/hooks';
import {USER_ROLES} from 'constants/shared';
import {getUserRoles, getIsRtlLanguage} from 'store/selectors';

import {useStyles} from './BoothJobsDialog.styles';

const BoothJobs = ({opened, closeDialog, jobs, exhibitorId, onApplyToJob}) => {
    const isRtlLanguage = useSelector(getIsRtlLanguage);
    const currentRoles = useSelector(getUserRoles);
    const [currentJob, setCurrentJob] = useState(0);
    const classes = useStyles({isRtlLanguage});
    const {boothDashboard} = usePlatformTranslation();

    const handleChangeProduct = (modificator) => {
        let res = currentJob + modificator;
        if (res >= jobs.length) {
            res = 0;
        } else if (res < 0) {
            res = jobs.length - 1;
        }
        setCurrentJob(res);
    };

    useEffect(() => {
        setCurrentJob(0);
    }, [exhibitorId]);

    return (
        <Dialog
            open={opened}
            onClose={closeDialog}
            aria-labelledby="form-dialog-title"
            id="booth-files-dialog"
            className="booth-jobs-dialog-container booth-dialog"
        >
            {!!jobs.length && (
                <>
                    <DialogTitle
                        classes={{
                            root: classes.title,
                        }}
                    >
                        <p>{jobs[currentJob].title}</p>
                        {closeDialog ? (
                            <IconButton
                                aria-label="close"
                                className={classNames(classes.closeButton, {isRtl: isRtlLanguage})}
                                onClick={closeDialog}
                            >
                                <CloseIcon />
                            </IconButton>
                        ) : null}
                    </DialogTitle>
                    <DialogContent className={classes.root}>
                        <div className="booth-dialog_section -text-section">
                            <div className={classes.details}>
                                <p className={classes.boothDialogText}>{jobs[currentJob].description}</p>
                                <div className="booth-dialog_salary-range">
                                    <span>{boothDashboard.jobsDialog.salaryRange}</span>{' '}
                                    {getCurrencySymbol(jobs[currentJob].currency)}
                                    {jobs[currentJob].salaryMin} - {getCurrencySymbol(jobs[currentJob].currency)}
                                    {jobs[currentJob].salaryMax}
                                </div>
                                <div className="booth-dialog_location">
                                    <span>{boothDashboard.jobsDialog.location}</span> {jobs[currentJob].location}
                                </div>
                                <div className="booth-dialog_industry">
                                    <span>{boothDashboard.jobsDialog.industry}</span> {jobs[currentJob].industry}
                                </div>
                                <div className="booth-dialog_working-time">
                                    {jobs[currentJob].fullTime ? (
                                        <span>{boothDashboard.jobsDialog.fullTime}</span>
                                    ) : null}
                                    {jobs[currentJob].fullTime && jobs[currentJob].partTime ? <span> / </span> : null}
                                    {jobs[currentJob].partTime ? (
                                        <span>{boothDashboard.jobsDialog.partTime}</span>
                                    ) : null}
                                </div>
                            </div>
                            <div className={classNames(classes.navigationWrapper, {isRtl: isRtlLanguage})}>
                                <div className="booth-dialog_navigation">
                                    <div className={classes.paginationWrapper}>
                                        {jobs.length > 1 && (
                                            <>
                                                <ArrowBackIosIcon
                                                    onClick={() => {
                                                        handleChangeProduct(isRtlLanguage ? 1 : -1);
                                                    }}
                                                    className={classes.navArrow}
                                                    style={{
                                                        transform: `rotate(${isRtlLanguage ? 180 : 0}deg)`,
                                                    }}
                                                />
                                                <span className={classNames(classes.boothDialogPagination)}>
                                                    {currentJob + 1}/{jobs.length}
                                                </span>
                                                <ArrowBackIosIcon
                                                    onClick={() => {
                                                        handleChangeProduct(isRtlLanguage ? -1 : 1);
                                                    }}
                                                    className={classes.navArrow}
                                                    style={{
                                                        transform: `rotate(${isRtlLanguage ? 0 : 180}deg)`,
                                                    }}
                                                />
                                            </>
                                        )}
                                    </div>
                                    {jobs[currentJob].link && (
                                        <a className="booth-dialog_button" target="blank" href={jobs[currentJob].link}>
                                            {boothDashboard.jobsDialog.seeDetails}
                                        </a>
                                    )}
                                </div>
                                <div className={classes.applyContainer}>
                                    <Button
                                        key={`${jobs[currentJob]?._id}`}
                                        type="button"
                                        className={classes.applyButton}
                                        onClick={() => onApplyToJob(exhibitorId, jobs[currentJob]._id)}
                                        disabled={
                                            !currentRoles.includes(USER_ROLES.exhibitor)
                                                ? jobs[currentJob].alreadyApplied
                                                : true
                                        }
                                    >
                                        <span>{boothDashboard.jobsDialog.applyToJob}</span>
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                </>
            )}
        </Dialog>
    );
};

export default BoothJobs;
