import {cloneDeep} from 'lodash';
import * as usersStatusesActionTypes from '../actionTypes/usersStatusesActionTypes';
import {updateObject} from '../utilityStateFunction';

const initialState = {
    statuses: {},
};

const reducer = (state = cloneDeep(initialState), action) => {
    switch (action.type) {
        case usersStatusesActionTypes.SET_USERS_STATUSES: {
            const {statuses} = action.payload;
            return updateObject(state, statuses);
        }
        case usersStatusesActionTypes.RESET_USERS_STATUSES_STATE:
            return cloneDeep(initialState);

        default:
            return state;
    }
};

export default reducer;
