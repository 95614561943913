import React from 'react';

const IconArrowTop = () => (
    <svg width="40" height="79" viewBox="0 0 40 79" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M39 78C4.8 53.9742 8.6 45.6387 8.6 2M8.6 2L16.675 9.13325M8.6 2L1 9.13325"
            stroke="currentColor"
            strokeWidth="2"
        />
    </svg>
);

export default IconArrowTop;
