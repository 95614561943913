import React, {useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {CloudDownload as CloudDownloadIcon} from '@material-ui/icons';

import {AbilityContext} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';
import {getUserEventRoles, getEventId} from 'store/selectors';
import {sendExhibitorStatistic} from 'store/actions';
import {getParticipantId} from 'services/utils';

const Files = ({exhibitor}) => {
    const dispatch = useDispatch();
    const eventRoles = useSelector(getUserEventRoles);
    const eventId = useSelector(getEventId);
    const ability = useContext(AbilityContext);

    const sendDocumentClickStatistic = () => {
        const participantAccess = ability.can(
            ROLE_ABILITIES_TYPES.PARTICIPANT.SEND_STATISTICS,
            ROLE_FEATURES_TYPES.STATISTICS.DOCUMENT_CLICKS
        );

        if (participantAccess) {
            const participantId = getParticipantId(eventRoles, eventId);
            const dataToSend = {participantId, actionType: 'documentClicks'};

            dispatch(sendExhibitorStatistic({exhibitorId: exhibitor._id, payload: dataToSend}));
        }
    };

    return (
        <ul className="exhibitor-assets">
            {exhibitor.booth.documents.map((document) => {
                return (
                    <li key={document._id} onClick={sendDocumentClickStatistic}>
                        <a href={exhibitor.filesUrl + document.file} target="_blank" rel="noopener noreferrer">
                            <span className="label">{document.displayName}</span>
                            <CloudDownloadIcon />
                        </a>
                    </li>
                );
            })}
        </ul>
    );
};

export default Files;
