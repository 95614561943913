import React from 'react';
import {ExpandMore as ExpandMoreIcon} from '@material-ui/icons';

import classNames from 'classnames';
import {whiteLabel} from '../../../../../config';
import {DEFAULT_LANGUAGES, IS_SCA_ENV} from '../../../../../constants/shared';

export default class PlatformLanguage extends React.Component {
    state = {
        showLanguageList: false,
    };

    constructor(props) {
        super(props);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        const {eventInfo} = this.props;

        document.addEventListener('mousedown', this.handleClickOutside);

        if (eventInfo?.language) {
            this.props.onSetPlatformLanguage(eventInfo.language);
            this.props.onSetEventLanguage(eventInfo.language);
        }
    }

    componentDidUpdate(prevProps) {
        const {eventInfo} = this.props;

        if (eventInfo?.language && prevProps?.eventInfo?.language !== eventInfo?.language) {
            this.props.onSetPlatformLanguage(eventInfo.language);
            this.props.onSetEventLanguage(eventInfo.language);
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (this.props.target === 'exhibitorsOverlay') {
                this.props.hideExhibitorsOverlay();
            } else if (this.props.target === 'auditoriumsSlider') {
                this.props.hideAuditoriumsSlider();
            } else {
                this.setState({
                    showLanguageList: false,
                });
            }
        }
    }

    toggleShowLanguageList = () => {
        this.setState({
            showLanguageList: !this.state.showLanguageList,
        });
    };

    handleChangeLanguage = (language) => (e) => {
        this.props.onSetPlatformLanguage(language);
        this.props.onSetEventLanguage(language);
        this.setState({
            showLanguageList: false,
        });
    };

    render() {
        const {eventLanguages, languages, forcePrimaryText, isEventLanding} = this.props;
        const {showLanguageList} = this.state;
        const ScaLanguages = IS_SCA_ENV && !isEventLanding ? DEFAULT_LANGUAGES : eventLanguages;
        const usingLanguages = eventLanguages.length > 1 ? eventLanguages : ScaLanguages;
        const eventHasTranslations = usingLanguages.length > 1;

        // if the event has just one language, don't display the select language dropdown
        if (!eventHasTranslations) {
            return null;
        }

        return (
            <div
                className={classNames('select-language-container', {
                    'select-language-container--primary-text': forcePrimaryText,
                })}
                ref={this.setWrapperRef}
            >
                <div className="selected-language" onClick={this.toggleShowLanguageList}>
                    {languages.eventLanguage} <ExpandMoreIcon />
                </div>
                {showLanguageList && (
                    <div className="languages-list-container">
                        <ul>
                            {usingLanguages.map(({language}) => {
                                return (
                                    <li
                                        key={language}
                                        value={language}
                                        onClick={this.handleChangeLanguage(language)}
                                        className={classNames(
                                            {active: language === languages.eventLanguage},
                                            whiteLabel
                                        )}
                                    >
                                        {language}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
        );
    }
}
