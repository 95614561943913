import moment from 'moment';
import {getMonth} from '../utils';

export const getEventCardDate = (date, eventLanguage) => {
    return `${moment(date).format('DD')} ${getMonth(date, eventLanguage, false, true)} ${moment(date).format('YYYY')}`;
};

export const getTextFromTranslation = (translationsArray = [], languageSlug = 'en', defaultLanguage = 'en') => {
    if (translationsArray.length === 0) return '';
    const getTranslations = (lang) => translationsArray.find(({language}) => language === lang);
    let result = getTranslations(languageSlug);
    result = result || getTranslations(defaultLanguage);
    return result?.text || '';
};

export const createFilterByDate = (filters) => {
    if (filters && filters.length) {
        return filters.flatMap(({label, options}) => {
            const year = moment().year(label).format('YYYY');

            return options.reduce((acc, option) => {
                if (option.isChecked) {
                    const month = moment().month(option.value).format('MM');
                    acc.push(`${year}-${month}`);
                }
                return acc;
            }, []);
        });
    } else {
        return '';
    }
};
