import React from 'react';
import moment from 'moment/moment';
import {ReactComponent as SliderArrow} from '../../../../../../../../../Images/slider-arrow.svg';
import './Arrows.scss';
import ProgramDropdownDay from '../ProgramDropdownDay/ProgramDropdownDay';

export const NextProgramArrow = (props) => {
    const {handleOnClick, currentSlide, programDays, getProgramDayString, getDayLabelString} = props;
    if (currentSlide === programDays.length - 1) {
        return null;
    } else {
        const currentDay = moment(programDays[currentSlide + 1]).startOf('day');
        return (
            <div onClick={handleOnClick}>
                <div className="program-arrow">
                    <div className="program-arrow__arrow program-arrow__arrow--next">
                        <div className="program-arrow__program-dropdown-day">
                            <ProgramDropdownDay
                                date={getProgramDayString(programDays[currentSlide + 1])}
                                weekday={getDayLabelString(currentDay)}
                            />
                        </div>
                        <SliderArrow className="program-arrow__icon program-arrow__icon--next" />
                    </div>
                </div>
            </div>
        );
    }
};

export const PrevProgramArrow = (props) => {
    const {handleOnClick, currentSlide, programDays, getProgramDayString, getDayLabelString} = props;

    if (currentSlide === 0) {
        return null;
    } else {
        const currentDay = moment(programDays[currentSlide - 1]).startOf('day');
        return (
            <div onClick={handleOnClick}>
                <div className="program-arrow">
                    <div className="program-arrow__arrow program-arrow__arrow--prev">
                        <SliderArrow className="program-arrow__icon" />
                        <ProgramDropdownDay
                            date={getProgramDayString(programDays[currentSlide - 1])}
                            weekday={getDayLabelString(currentDay)}
                        />
                    </div>
                </div>
            </div>
        );
    }
};
