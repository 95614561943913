import React from 'react';
import moment from 'moment';
import {connect} from 'react-redux';
import {Snackbar} from '@material-ui/core';

import {getUtcTimestamp, getTimeslotStartTimestamp, getEventDaysArray} from '../../../services/utils';

class NotificationPresentationStarts extends React.Component {
    componentDidMount() {
        this.setNewPresentationTimer();
    }

    componentWillUnmount() {
        this.clearSnackbarCountdown();
    }

    state = {
        numberOfSecondsToShowSnackbarBeforePresentationStarts: 300,
        totalSecondsTillNextProgramTimeSlots: [],
        titlesNextProgramTimeSlots: [],
        snackbarTimerId: null,
        snackbar: {
            open: false,
            message: '',
        },
    };

    componentDidUpdate(prevProps) {
        if (prevProps.event === null && this.props.event !== null) {
            this.setNewPresentationTimer();
        }
        // check for when the user moves from one event to another
        if (prevProps.event && prevProps.event._id !== this.props.event._id) {
            this.clearSnackbarCountdown();
            this.setNewPresentationTimer();
        }
    }

    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                message: '',
            },
        });
    };

    setNewPresentationTimer = () => {
        const {event} = this.props;

        // check if today is actually a day between eventStart and eventEnd
        const eventDays = getEventDaysArray(event.eventStartDate, event.eventEndDate);
        let currentTime = new Date();
        const currentDay = moment(currentTime).format('YYYY-MM-DD');

        // get the currentDayIndex if the eventDays;
        const currentDayIndex = eventDays.indexOf(currentDay);
        // if today is not in the program, we don't do anything
        if (currentDayIndex === -1) {
            return null;
        }

        let currentTimeStamp = getUtcTimestamp(currentTime);

        // sanity check to make sure we have the event data
        if (event !== null) {
            let programDate = eventDays[currentDayIndex];
            let updatedTotalSecondsTillNextProgramTimeSlots = [];
            let updatedTitlesNextProgramTimeSlots = [];

            let allEventAuditoriums = [];

            if (event.hasVillage) {
                const conferenceBuildings = event.buildings.filter((building) => building.type === 'conference');
                conferenceBuildings.forEach((building) => {
                    allEventAuditoriums = [...allEventAuditoriums, ...building.auditoriums];
                });
            } else {
                allEventAuditoriums = event.auditoriums;
            }

            allEventAuditoriums.forEach((auditorium, index) => {
                // if the event is village setup,
                // in the notification we also have to display in which building
                // the next timeslot will be
                let buildingName = '';
                if (event.hasVillage) {
                    const currentBuilding = event.buildings.find((building) => building._id === auditorium.building);
                    buildingName = currentBuilding?.name;
                }

                if (auditorium.dailyProgram[currentDayIndex]) {
                    let program = auditorium.dailyProgram[currentDayIndex].program;
                    // get the first timeSlot that starts in at least numberOfSecondsToShowSnackbarBeforePresentationStarts
                    let nextTimeSlot = program.find((timeSlot) => {
                        let timeSlotStartTimestamp = getTimeslotStartTimestamp(
                            programDate,
                            timeSlot,
                            event.utcTimezone
                        );
                        if (
                            currentTimeStamp <
                                timeSlotStartTimestamp -
                                    this.state.numberOfSecondsToShowSnackbarBeforePresentationStarts * 1000 &&
                            timeSlot.video
                        ) {
                            return timeSlot;
                        }
                        return null;
                    });

                    // if we have a timeslot that has a video and starts in at least numberOfSecondsToShowSnackbarBeforePresentationStarts
                    // we save in array the number of seconds untill that presentation starts
                    if (nextTimeSlot) {
                        let nextTimeSlotStartTimestamp = getTimeslotStartTimestamp(
                            programDate,
                            nextTimeSlot,
                            event.utcTimezone
                        );
                        let milisecondsUntillNextVideo = nextTimeSlotStartTimestamp - currentTimeStamp;
                        let secondsUntillNextVideo = Math.floor(milisecondsUntillNextVideo / 1000);
                        updatedTotalSecondsTillNextProgramTimeSlots[index] = secondsUntillNextVideo;
                        updatedTitlesNextProgramTimeSlots[index] = {
                            title: nextTimeSlot.title,
                            auditoriumName: auditorium.name,
                            buildingName: buildingName,
                        };
                    }
                }
            });

            this.setState({
                totalSecondsTillNextProgramTimeSlots: updatedTotalSecondsTillNextProgramTimeSlots,
                titlesNextProgramTimeSlots: updatedTitlesNextProgramTimeSlots,
            });
            this.startSnackbarCountdown();
        }
    };

    startSnackbarCountdown = () => {
        let snackbarTimerId = setInterval(this.snackbarCountdown, 1000);
        this.setState({snackbarTimerId: snackbarTimerId});
    };

    snackbarCountdown = () => {
        let message = '';
        // create the message
        this.state.totalSecondsTillNextProgramTimeSlots.forEach((secondsTillNextVideo, index) => {
            if (secondsTillNextVideo === this.state.numberOfSecondsToShowSnackbarBeforePresentationStarts) {
                // Notification message examples:
                // hasVilalge: In 5 minutes in Building 1: Auditorium will start BMW Main Presentation && in Building2: ...
                // default: In 5 minutes in Auditorium will start BMW Main Presentation   & in Auditorium 2 ...

                const minutes = this.state.numberOfSecondsToShowSnackbarBeforePresentationStarts / 60;
                const buildingName = this.state.titlesNextProgramTimeSlots[index].buildingName;
                const auditoriumName = this.state.titlesNextProgramTimeSlots[index].auditoriumName;
                const timeslotTitle = this.state.titlesNextProgramTimeSlots[index].title;

                // if we the message is an empty string, it means that we are at the start of a sentence
                if (message.length === 0) {
                    if (buildingName) {
                        message = `In ${minutes} minutes in ${buildingName}: ${auditoriumName} will start ${timeslotTitle}`;
                    } else {
                        message = `In ${minutes} minutes in ${auditoriumName} will start ${timeslotTitle}`;
                    }
                } else {
                    // if the message is not empty, we allready have a "Notification" in it
                    // and we just have to concatenate the next one.
                    if (buildingName) {
                        message += ` & in ${buildingName}: ${auditoriumName} will start ${timeslotTitle}`;
                    } else {
                        message += ` & in ${auditoriumName} will start ${timeslotTitle}`;
                    }
                }
            }
        });

        // if we have a message, open the snackbar
        if (message.length) {
            message += '.';
            this.setState({
                snackbar: {open: true, message: message},
            });
        }

        // always remove 1 second after each itteration
        let updatedTotalSecondsTillNextProgramTimeSlots = [...this.state.totalSecondsTillNextProgramTimeSlots];
        updatedTotalSecondsTillNextProgramTimeSlots.forEach((secondsTillNextVideo, index) => {
            if (secondsTillNextVideo) {
                updatedTotalSecondsTillNextProgramTimeSlots[index] =
                    updatedTotalSecondsTillNextProgramTimeSlots[index] - 1;
            }
        });

        this.setState({
            totalSecondsTillNextProgramTimeSlots: updatedTotalSecondsTillNextProgramTimeSlots,
        });

        // after we show the snackbar,
        // we clear the interval because we don't need it anymore
        // we check if we have a next video presentation to set up immediatelly the next timer
        if (message.length) {
            this.clearSnackbarCountdown();
            this.setNewPresentationTimer();
        }
    };

    clearSnackbarCountdown = () => {
        clearInterval(this.state.snackbarTimerId);
    };

    render() {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                key={this.state.snackbar.message}
                open={this.state.snackbar.open}
                onClose={this.handleCloseSnackbar}
                autoHideDuration={10000}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{this.state.snackbar.message}</span>}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        user: state.user.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationPresentationStarts);
