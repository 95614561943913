import {serializeBusinessCard} from 'services/utils/normalizers';

const DEFAULT_PRIMARY_COLOR = 'var(--theme-primary-color)';
const DEFAULT_SECONDARY_COLOR = '#FFF';
const DEFAULT_BACKGROUND_COLOR = '#F2F2F2';

export const removeEmptyValuesFromObj = (obj = {}) =>
    Object.fromEntries(Object.entries(obj).filter(([_, value]) => value));
export const emptyBusinessCardLogo = {file: null, media: null};
export const emptyBusinessCard = {
    firstName: '',
    lastName: '',
    designation: '',
    mobile: '',
    phone: '',
    email: '',
    website: '',
    companyName: '',
    primaryColor: DEFAULT_PRIMARY_COLOR,
    secondaryColor: DEFAULT_SECONDARY_COLOR,
    backgroundColor: DEFAULT_BACKGROUND_COLOR,
    logo: emptyBusinessCardLogo,
};

const appendLogo = (formData, {media, file}) => {
    if (file) {
        formData.append('logo', file, media.title);
        return formData;
    }

    formData.append('logo', media?.title || '');
    return formData;
};

export const businessCardFormData = (businessCard = {}) => {
    const {logo, ...restBusinessCard} = serializeBusinessCard(businessCard);
    const formData = new FormData();

    for (const key in restBusinessCard) {
        formData.append(key, businessCard[key] || '');
    }

    appendLogo(formData, logo);

    return formData;
};
