import classNames from 'classnames';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {usePlatformTranslation} from '../../../../../services/hooks';
import Filter from '../../../Filter';
import {useStyles} from './ShowFilter.styles';

const COUNTRY_ID = 'country-filter-id';

const ShowFloorFilter = (props) => {
    const {filters, users, usingFilters, className} = props;

    const classes = useStyles();
    const translation = usePlatformTranslation();
    const {isRtlLanguage} = useSelector((state) => state.languages);

    const [filterFields, setFilterFields] = useState([]);

    useEffect(() => {
        if (!users.length || !filters) return;

        if (!_.isEmpty(usingFilters)) return setFilterFields(usingFilters);

        // add _id for country filter to select all options related to the country field
        const participantsFiltersList = filters.map((field) => ({
            ...field,
            options: field.options.map((option) => ({...option, isChecked: false})),
            ...(field.isCountry && {_id: COUNTRY_ID}),
        }));

        setFilterFields(participantsFiltersList);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, users, usingFilters]);

    return (
        <div className={classNames(classes.root, className, {isRtl: isRtlLanguage})}>
            <Filter filterFields={filterFields} searchInputText={translation?.networking.searchInput} {...props} />
        </div>
    );
};

export default React.memo(ShowFloorFilter);
