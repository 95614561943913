import React from 'react';

import './BottomMenuBottomSheet.scss';

const BottomMenuBottomSheet = ({title, children}) => (
    <div className="BottomMenuBottomSheet">
        <div className="BottomMenuBottomSheet__title">{title}</div>

        <div className="BottomMenuBottomSheet__content">{children}</div>
    </div>
);

export default BottomMenuBottomSheet;
