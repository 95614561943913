import {MyAccount} from './MyAccount';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../../../../../../store/actions/index';

export default withRouter(
    connect(
        (state) => ({
            user: state.user.data,
            event: state.event.data,
            eventSlug: state.event.eventSlug,
            loggedIn: state.user.loggedIn,
            isTablet: state.layout.isTablet,
            languages: state.languages,
            isRtlLanguage: state.languages.isRtlLanguage,
        }),
        {
            onLogoutUser: actions.logoutUser,
            closeTopNavigation: actions.topNavClose,
        }
    )(MyAccount)
);
