import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        minHeight: 'calc(100vh - 116px)',
    },
    tabsContainer: {
        borderRight: '1px solid #CACBD0', // TODO: color
        minWidth: 250,
    },
    indicator: {
        border: 'none',
        outline: 'none',
        background: theme.palette.primary.main,
        cursor: 'pointer',
        width: 4,
        left: 0,
    },
    text: {
        textAlign: 'left',
        alignItems: 'start',
        color: '#2B2B2B',
        fontSize: 16,
        fontWeight: '700',
    },
    selected: {
        background: theme.palette.background.paper,
    },
    tabContent: {
        padding: 24,
        width: '100%',
    },
    contentWrapper: {
        filter: 'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2))', // TODO: color
        background: theme.palette.background.paper,
        borderRadius: 6,
        height: 'calc(100vh - 164px)',
        overflowX: 'hidden',
        overflowY: 'auto',
        border: '1px solid #CACBD0', // TODO: color
    },
    rtl: {
        direction: 'rtl',
    },
    hideElement: {
        display: 'none',
    },
}));
