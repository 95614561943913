import React from 'react';
import {connect} from 'react-redux';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

import CustomCancelButton from 'Routes/components/CustomCancelButton';
import EditUserInfoWrapper from 'Routes/components/EditUserInfoWrapper';
import * as actions from 'store/actions';
import {Api} from 'Api';

class EditUserInformation extends React.Component {
    state = {
        user: {
            firstName: '',
            lastName: ''
        },
        disabled: true,
        translation: null
    };

    componentDidMount() {
        this.setState({
            user: {
                firstName: this.props.user.first,
                lastName: this.props.user.last
            }
        });
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation
        });
    };

    handleChange = (e) => {
        this.setState(
            {
                user: {
                    ...this.state.user,
                    [e.target.name]: e.target.value
                }
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleUpdateUserInformation = async () => {
        try {
            await Api.request({
                method: 'put',
                url: '/users',
                payload: {first: this.state.user.firstName, last: this.state.user.lastName}
            });

            this.props.onRefreshUserUserData();
            this.props.closeDialog();
        } catch (error) {}
    };

    render() {
        let {firstName, lastName} = this.state.user;
        let {translation} = this.state;
        return (
            <Dialog
                open={this.props.opened}
                onClose={() => this.props.closeDialog('')}
                aria-labelledby="form-dialog-title"
            >
                <EditUserInfoWrapper>
                    <DialogContent>
                        <DialogTitle id="form-dialog-title" className="form-dialog-title">
                            {translation?.myAccountPage.editUserDialogTitle}
                        </DialogTitle>
                        <ValidatorForm ref="form" onSubmit={this.handleUpdateUserInformation}>
                            <TextValidator
                                label={translation?.myAccountPage.firstName}
                                onChange={this.handleChange}
                                name="firstName"
                                value={firstName}
                                variant="outlined"
                                fullWidth
                                required
                                validators={['required']}
                                errorMessages={[`${translation?.errors.required}`]}
                            />
                            <TextValidator
                                label={translation?.myAccountPage.lastName}
                                onChange={this.handleChange}
                                name="lastName"
                                value={lastName}
                                variant="outlined"
                                fullWidth
                                required
                                validators={['required']}
                                errorMessages={[`${translation?.errors.required}`]}
                            />
                        </ValidatorForm>
                    </DialogContent>
                    <DialogActions className="buttons-actions">
                        <CustomCancelButton
                            text={translation?.generalText.close}
                            onClick={() => this.props.closeDialog('')}
                        />
                        <button
                            className="update-button"
                            type="submit"
                            disabled={this.state.disabled}
                            onClick={this.handleUpdateUserInformation}
                        >
                            {translation?.generalText.update}
                        </button>
                    </DialogActions>
                </EditUserInfoWrapper>
            </Dialog>
        );
    }
}

export default connect(
    (state) => ({
        user: state.user.data,
        languages: state.languages
    }),
    {
        onRefreshUserUserData: actions.refreshUserData
    }
)(EditUserInformation);
