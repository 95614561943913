import {useDispatch} from 'react-redux';
import {useCallback, useEffect} from 'react';
import {handlePollCreate, handlePollDelete, handlePollUpdate} from 'Api/socketApi';
import {socketPollCreate, socketPollDelete, socketPollUpdate} from 'store/actions';

const useTimeslotPollsSockets = (options = {}) => {
    const {useCreateSocket = true} = options;
    const dispatch = useDispatch();

    const updateSockets = useCallback(() => {
        handlePollCreate(({poll}) => {
            useCreateSocket && dispatch(socketPollCreate(poll));
        });

        handlePollDelete(({pollId}) => {
            dispatch(socketPollDelete(pollId));
        });

        handlePollUpdate(({poll}) => {
            dispatch(socketPollUpdate(poll));
        });
    }, [dispatch, useCreateSocket]);

    useEffect(() => {
        updateSockets();
    }, [updateSockets]);
};

export default useTimeslotPollsSockets;
