import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Snackbar} from '@material-ui/core';

import * as actions from '../../../store/actions/index';

class AuditoriumSnackbar extends React.Component {
    snackbarTimerId = null;

    state = {
        totalSecondsTillCurrentTimeSlotEnds: null,
        snackbar: {
            open: false,
            message: '',
        },
        translation: null,
    };

    componentDidMount() {
        if (this.props.totalSecondsTillCurrentTimeSlotEnds) {
            this.clearSnackbarCountdown();
            this.startSnackbarCountdown();
        }
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        let timeslotChanged =
            prevProps.currentTimeSlotIndex !== this.props.currentTimeSlotIndex &&
            this.props.totalSecondsTillCurrentTimeSlotEnds;
        if (
            prevProps.totalSecondsTillCurrentTimeSlotEnds !== this.props.totalSecondsTillCurrentTimeSlotEnds ||
            timeslotChanged
        ) {
            this.clearSnackbarCountdown();
            this.startSnackbarCountdown();
        }

        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    componentWillUnmount() {
        this.clearSnackbarCountdown();
    }

    startSnackbarCountdown = () => {
        this.setState(
            {
                totalSecondsTillCurrentTimeSlotEnds: this.props.totalSecondsTillCurrentTimeSlotEnds,
            },
            () => {
                this.snackbarTimerId = setInterval(this.snackbarCountdown, 1000);
            }
        );
    };

    snackbarCountdown = () => {
        const {totalSecondsTillCurrentTimeSlotEnds, translation} = this.state;
        if (totalSecondsTillCurrentTimeSlotEnds !== 0) {
            switch (totalSecondsTillCurrentTimeSlotEnds) {
                case 600:
                    this.setState({
                        snackbar: {
                            open: true,
                            message: `${translation?.auditorium.slotEndsTextFirst} 10 ${translation?.time.minutes}.`,
                        },
                        totalSecondsTillCurrentTimeSlotEnds: totalSecondsTillCurrentTimeSlotEnds - 1,
                    });
                    break;
                case 300:
                    this.setState({
                        snackbar: {
                            open: true,
                            message: `${translation?.auditorium.slotEndsTextFirst} 5 ${translation?.time.minutes}.`,
                        },
                        totalSecondsTillCurrentTimeSlotEnds: totalSecondsTillCurrentTimeSlotEnds - 1,
                    });
                    break;
                case 60:
                    this.setState({
                        snackbar: {
                            open: true,
                            message: `${translation?.auditorium.slotEndsTextFirst} 1 ${translation?.time.minute}.`,
                        },
                        totalSecondsTillCurrentTimeSlotEnds: totalSecondsTillCurrentTimeSlotEnds - 1,
                    });
                    break;
                default:
                    this.setState({
                        totalSecondsTillCurrentTimeSlotEnds: totalSecondsTillCurrentTimeSlotEnds - 1,
                    });
            }
        } else {
            this.setState({
                totalSecondsTillCurrentTimeSlotEnds: null,
            });

            this.clearSnackbarCountdown();
            this.props.loadCurrentTimeSlot();
        }
    };

    handleCloseSnackbar = () => {
        this.setState({
            snackbar: {
                open: false,
                message: '',
            },
        });
    };

    clearSnackbarCountdown = () => {
        if (this.snackbarTimerId) {
            clearInterval(this.snackbarTimerId);
            this.snackbarTimerId = null;
        }
    };

    render() {
        return (
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                key={this.state.snackbar.message}
                open={this.state.snackbar.open}
                onClose={this.handleCloseSnackbar}
                autoHideDuration={10000}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                message={<span id="message-id">{this.state.snackbar.message}</span>}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setEventId: (eventId) => dispatch(actions.setEventId(eventId)),
        setEventSlug: (eventSlug) => dispatch(actions.setEventSlug(eventSlug)),
        onCloseTopNav: () => dispatch(actions.topNavClose()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuditoriumSnackbar));
