import {connect} from 'react-redux';

import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import {ChangePasswordDialog} from './ChangePasswordDialog';

export default connect(
    (state) => ({
        user: selectors.getUserData(state),
        languages: selectors.getLanguages(state),
    }),
    {
        onRefreshUserUserData: actions.refreshUserData,
    }
)(ChangePasswordDialog);
