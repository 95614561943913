export const checkIsLink = (value = '') =>
    new RegExp(
        '(http|ftp|https):\\/\\/[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-\\.,@?^=%&amp;:/~\\+#]*[\\w\\-\\@?^=%&amp;/~\\+#])?',
        'i'
    ).test(value);

export const linkValidation = (value) => {
    let match = checkIsLink(value);

    return !value || match;
};
