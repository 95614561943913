import React, {useContext} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Link as LinkIcon} from '@material-ui/icons';
import classNames from 'classnames';

import {AbilityContext} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';
import {getUserEventRoles, getEventId} from 'store/selectors';
import {sendExhibitorStatistic} from 'store/actions';
import {getParticipantId} from 'services/utils';

import useStyles from './Links.styles';

const Links = ({exhibitor}) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const eventRoles = useSelector(getUserEventRoles);
    const eventId = useSelector(getEventId);
    const ability = useContext(AbilityContext);

    const sendLinkClickStatistic = () => {
        const participantAccess = ability.can(
            ROLE_ABILITIES_TYPES.PARTICIPANT.SEND_STATISTICS,
            ROLE_FEATURES_TYPES.STATISTICS.LINK_CLICKS
        );

        if (participantAccess) {
            const participantId = getParticipantId(eventRoles, eventId);
            const dataToSend = {participantId, actionType: 'linkClicks'};

            dispatch(sendExhibitorStatistic({exhibitorId: exhibitor._id, payload: dataToSend}));
        }
    };

    return (
        <ul className="exhibitor-assets">
            {exhibitor.booth.socialMediaLinks.map((link) => (
                <li key={link.platform} onClick={sendLinkClickStatistic}>
                    <a className={classes.linkStyles} href={link.link} target="_blank" rel="noopener noreferrer">
                        <span className={classes.linkTextStyles}>
                            <span className={classNames(classes.linkLabelStyles, 'label')}>{link.platform}: </span>
                            <span className={classNames(classes.linkElementStyles, 'link')}>{link.link}</span>
                        </span>

                        <span className={classes.linklIconStyles}>
                            <LinkIcon />
                        </span>
                    </a>
                </li>
            ))}
            {exhibitor.booth.links.map((link) => (
                <li key={link} onClick={sendLinkClickStatistic}>
                    <a className={classes.linkStyles} href={link} target="_blank" rel="noopener noreferrer">
                        <span className={classNames(classes.linkElementStyles, 'link')}>{link}</span>

                        <span className={classes.linklIconStyles}>
                            <LinkIcon />
                        </span>
                    </a>
                </li>
            ))}
            {exhibitor.booth.additionalLinks.map((link) => (
                <li key={link._id} onClick={sendLinkClickStatistic}>
                    <a className={classes.linkStyles} href={link.link} target="_blank" rel="noopener noreferrer">
                        <span className={classes.linkTextStyles}>
                            <span className={classNames(classes.linkLabelStyles, 'label')}>{`${link.title}: `}</span>
                            <span className={classNames(classes.linkElementStyles, 'link')}>{link.link}</span>
                        </span>

                        <span className={classes.linklIconStyles}>
                            <LinkIcon />
                        </span>
                    </a>
                </li>
            ))}
        </ul>
    );
};

export default Links;
