import React from 'react';
import cn from 'classnames';

import Button from '../../../../../../../../../../../../../../../Button/Button';
import {
    IconCross,
    IconCheck,
    IconAngleArrowRight,
    IconCircledMinus,
} from '../../../../../../../../../../../../../../../Icons';

export const ConfirmedActionGroup = ({onCancel, onMeetingStart}) => (
    <>
        <div className="MobileAgendaMeetingItem__actionItem">
            <Button icon={<IconCross />} onClick={onCancel} />
        </div>

        <div className="MobileAgendaMeetingItem__actionItem">
            <Button icon={<IconAngleArrowRight />} onClick={onMeetingStart} />
        </div>
    </>
);

export const PendingActionGroup = ({isRequested, onCancel, onConfirm}) => (
    <>
        <div className="MobileAgendaMeetingItem__actionItem">
            {isRequested ? (
                <Button icon={<IconCircledMinus />} onClick={onCancel} />
            ) : (
                <Button icon={<IconCross />} onClick={onCancel} />
            )}
        </div>

        <div
            className={cn('MobileAgendaMeetingItem__actionItem', {
                MobileAgendaMeetingItem__actionItem_hidden: !isRequested,
            })}
        >
            <Button icon={<IconCheck />} onClick={onConfirm} />
        </div>
    </>
);
