import React, {Component} from 'react';

import TopMainImage from '../../../../../Images/pages/platform-homepage/top-background-animation.jpg';

import {ReactComponent as RectangleMain} from '../../../../../Images/pages/platform-homepage/animations/rectangle/main-rectangle.svg';
import {ReactComponent as RectangleSub} from '../../../../../Images/pages/platform-homepage/animations/rectangle/sub-rectangle.svg';

import {whiteLabel} from '../../../../../config';
import {homepagePlatformConfig, siteConfig} from '../../config/config.homepagePlatform';
import {redirectToLastEvent} from '../../../../../services/helpers';

class AnimatedTopSection extends Component {
    state = {
        initialTimeoutRef: null,
        firstTimeoutRef: null,
        secondTimeoutRef: null,
        sectionClasses: '',
    };
    FirstFrameLogo = homepagePlatformConfig[whiteLabel].FirstFrameLogo;
    SecondFrameLogo = homepagePlatformConfig[whiteLabel].SecondFrameLogo;
    SubLogo = homepagePlatformConfig[whiteLabel].SubLogo;
    SecondMainImage = homepagePlatformConfig[whiteLabel].SecondMainImage;

    componentDidMount() {
        const body = document.body;
        body.classList.remove('show-all-page');
        body.classList.add('no-scroll');
        body.classList.add('platform-homepage');
        const initialTimeoutRef = window.setTimeout(this.startFirstAnimation, 1500);
        this.setState({initialTimeoutRef: initialTimeoutRef});
    }

    componentWillUnmount() {
        const body = document.body;
        body.classList.remove('no-scroll');
        body.classList.remove('platform-homepage');
        if (this.state.initialTimeoutRef) {
            window.clearTimeout(this.state.initialTimeoutRef);
        }
        if (this.state.firstTimeoutRef) {
            window.clearTimeout(this.state.firstTimeoutRef);
        }
        if (this.state.secondTimeoutRef) {
            window.clearTimeout(this.state.secondTimeoutRef);
        }
    }

    startFirstAnimation = () => {
        if (!this.state.sectionClasses.includes('animate')) {
            const firstTimeoutRef = window.setTimeout(this.startSecondAnimation, 1600);
            this.setState({sectionClasses: 'animate', firstTimeoutRef: firstTimeoutRef});
        }
    };

    startSecondAnimation = () => {
        this.setState({sectionClasses: 'animate second'});
    };

    startThirdAnimation = (e) => {
        const {loggedIn, user, onCloseTopNav, history, eventData} = this.props;
        e.stopPropagation();

        if (loggedIn) {
            redirectToLastEvent(user, history, onCloseTopNav, eventData);
        } else {
            if (!this.state.sectionClasses.includes('third')) {
                const secondTimeoutRef = window.setTimeout(this.startFourthAnimation, 1600);
                this.setState({sectionClasses: 'animate second third', secondTimeoutRef: secondTimeoutRef});
            }
        }
    };
    startFourthAnimation = () => {
        const body = document.body;
        body.classList.add('show-all-page');
        this.setState({sectionClasses: 'animate second third fourth'});
    };

    render() {
        const bannerLink = siteConfig[whiteLabel] || siteConfig.main;
        const {translation} = this.props;
        return (
            <section className={`animated-top-section ${this.state.sectionClasses}`}>
                <img src={TopMainImage} className="full-image first" alt="" />
                <img src={this.SecondMainImage} className="full-image second" alt="" />
                <div className="image-gradient-overlay-black"></div>

                <div className={`${homepagePlatformConfig[whiteLabel].gradientClass}`}></div>
                <div className="section-heading">
                    <div className="elements-container">
                        <div
                            style={homepagePlatformConfig[whiteLabel].logoContainer.style}
                            className="logo-animation-container"
                        >
                            <div className="green">
                                <img
                                    src={this.FirstFrameLogo.logo}
                                    id="white-logo"
                                    style={{
                                        //because it cant load in time from scss (for animation)
                                        left: '50%',
                                        position: 'absolute',
                                        transform: 'translateX(-50%)',
                                        ...this.FirstFrameLogo.logoStyle,
                                    }}
                                    alt=""
                                />
                            </div>

                            <div className="purple">
                                <img style={this.SecondFrameLogo.logoStyle} src={this.SecondFrameLogo.logo} alt="" />
                            </div>
                            <div style={this.SubLogo.wrapperStyle} className="full-logo">
                                <this.SubLogo.logo />
                            </div>
                        </div>
                        <div
                            className="start-button"
                            style={homepagePlatformConfig[whiteLabel].startBtn.wraperStyle}
                            onClick={this.startThirdAnimation}
                        >
                            <span>{translation?.landingPage?.animatedSectionButton}</span>
                        </div>
                    </div>
                    <div className="titles-container">
                        <div style={homepagePlatformConfig[whiteLabel].title.titleCoverStyle} className="first-title">
                            <div
                                style={homepagePlatformConfig[whiteLabel].title.imgCoverStyle}
                                className="img-container"
                            >
                                <RectangleMain className="primary-fill-svg" />
                            </div>
                            <h1 style={homepagePlatformConfig[whiteLabel].title.textStyle}>
                                {homepagePlatformConfig[whiteLabel].title.text}
                            </h1>
                        </div>
                        <div className="second-title">
                            <div
                                className="img-container"
                                style={homepagePlatformConfig[whiteLabel].secondTitle.imgCoverStyle}
                            >
                                <RectangleSub className="secondary-fill-svg" />
                            </div>
                            <h2 style={homepagePlatformConfig[whiteLabel].secondTitle.textStyle}>
                                {homepagePlatformConfig[whiteLabel].secondTitle.text}
                            </h2>
                        </div>
                    </div>
                </div>
                {homepagePlatformConfig[whiteLabel].Banner.show && (
                    <div className="banner" style={homepagePlatformConfig[whiteLabel].Banner.containerWrapper}>
                        {homepagePlatformConfig[whiteLabel].Banner.firstParagraph(
                            translation?.landingPage?.youAreNowInsideSca
                        )}
                        <p>
                            {homepagePlatformConfig[whiteLabel].Banner.secondParagraph(
                                translation?.landingPage?.toVisitTheWebsite
                            )}
                            <a
                                href={bannerLink}
                                className={homepagePlatformConfig[whiteLabel].Banner.linkClass}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {translation?.landingPage?.hereText}
                            </a>
                        </p>
                    </div>
                )}
            </section>
        );
    }
}

export default AnimatedTopSection;
