import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AuditorumPollsOutput from '../../../AuditorumPollsOutput';
import AuditorumResourcesOutput from '../../../AuditorumResourcesOutput/AuditorumResourcesOutput';
import AuditorumTranslationsOutput from '../../../AuditorumTranslationsOutput/AuditorumTranslationsOutput';
import Wall from '../../../Wall';

import {AbilityContext} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

import VerticalWallButtons from 'Routes/components/VerticalWallButtons';
import Interpretation from 'Routes/components/SideMenu/components/Interpretation';
import GreaterArrow from 'Images/greater-arrow.svg';
import {wallTabsList} from 'constants/auditorium';
import {usePlatformTranslation} from 'services/hooks';
import BackToLiveBtn from 'Routes/components/BackToLiveBtn';
import OnDemandTab from '../OnDemandTab';
import './AuditoriumWall.scss';

const AuditoriumWall = ({
    // props
    activeButtonIndex,
    menuButtons,
    isWallClosed,
    handleOpenWall,
    handleCloseWall,
    hidePollsTab,
    currentTimeSlot,
    currentTab,
    auditorium,
    onChangeTab,
    isOnDemandShowing,
    // redux
    event,
    onDemandVideos,
}) => {
    const translation = usePlatformTranslation();

    const ability = useContext(AbilityContext);

    const isModerator = ability.can(
        ROLE_ABILITIES_TYPES.COMMON.APPROVE_WALL_ABILITY,
        ROLE_FEATURES_TYPES.VIDEO_WALL_APPROVE
    );

    const withModeration = auditorium?.moderateVideoWall && event.videoWallModeration;

    return (
        <div className={classNames('auditorium-wall-container', {[`close`]: isWallClosed})}>
            {!isWallClosed && (
                <div className="close-auditorium-wall" onClick={() => handleCloseWall(true)}>
                    <img src={GreaterArrow} alt="Close button" />
                </div>
            )}
            {isOnDemandShowing && <BackToLiveBtn />}
            <div className="auditorium-wall-header">
                <span
                    onClick={handleOpenWall}
                    className={classNames('walls-tab', {'-is-active': currentTab === wallTabsList.WALL})}
                >
                    {translation?.auditorium.videoWallButton}
                </span>
                {!hidePollsTab && (
                    <span
                        onClick={() => onChangeTab(wallTabsList.POLLS)}
                        className={classNames('walls-tab', {'-is-active': currentTab === wallTabsList.POLLS})}
                    >
                        {translation?.polls.polls}
                    </span>
                )}
                <div className="auditorium-wall-header__side-buttons-group">
                    <VerticalWallButtons
                        buttons={menuButtons}
                        activeIndex={activeButtonIndex}
                        allClose={!isWallClosed}
                    />
                </div>
            </div>
            {currentTab === wallTabsList.WALL && (
                <Wall
                    classes={{customScrollContainer: 'auditorium-wall-container__wall-custom-scroll-container'}}
                    withModeration={withModeration}
                    isModerator={isModerator}
                />
            )}
            {currentTab === wallTabsList.POLLS && (
                <AuditorumPollsOutput auditoriumId={auditorium._id} programId={currentTimeSlot._id} />
            )}
            {currentTab === wallTabsList.RESOURCES && (
                <AuditorumResourcesOutput documents={currentTimeSlot.documents} links={currentTimeSlot.links} />
            )}
            {currentTab === wallTabsList.TRANSLATIONS && <AuditorumTranslationsOutput />}

            {currentTab === wallTabsList.INTERPRETER && <Interpretation back={() => handleOpenWall()} />}
            {currentTab === wallTabsList.ON_DEMAND && (
                <OnDemandTab goBack={() => handleOpenWall()} onDemandVideos={onDemandVideos} />
            )}
        </div>
    );
};

AuditoriumWall.propTypes = {
    activeButtonIndex: PropTypes.number,
    menuButtons: PropTypes.arrayOf(PropTypes.object),
    isWallClosed: PropTypes.bool,
    handleOpenWall: PropTypes.func,
    handleCloseWall: PropTypes.func,
    hidePollsTab: PropTypes.bool,
    currentTimeSlot: PropTypes.object,
    currentTab: PropTypes.string,
    auditorium: PropTypes.object,
    onChangeTab: PropTypes.func,
    isOnDemandShowing: PropTypes.bool,
    event: PropTypes.object,
    onDemandVideos: PropTypes.array,
};

export default AuditoriumWall;
