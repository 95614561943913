import React from 'react';

const SocialMediaIcon = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6 -3.88814e-07L4 -2.59209e-07L4 4L-3.53818e-07 4L-2.94849e-07 6L4 6L4 10L-1.76909e-07 10L-1.17939e-07 12L4 12L4 16L6 16L6 12L10 12L10 16L12 16L12 12L16 12L16 10L12 10L12 6L16 6L16 4L12 4L12 -7.77627e-07L10 -6.48023e-07L10 4L6 4L6 -3.88814e-07ZM10 6L10 10L6 10L6 6L10 6Z"
            fill="#9C231F"
        />
    </svg>
);

export default SocialMediaIcon;
