import React from 'react';
import {useStyles} from '../MoreMenu.styles';
import clsx from 'clsx';
import {usePlatformTranslation} from '../../../../../../../../../../../../../services/hooks';
import IconDelete from '../../Icons/IconDelete';
import {MenuItem} from '@material-ui/core';

const MenuItemDelete = React.forwardRef(({onClick = () => {}, className, ...props}, ref) => {
    const classes = useStyles();
    const translation = usePlatformTranslation();
    return (
        <MenuItem ref={ref} className={clsx(classes.item, className)} onClick={onClick} {...props}>
            <IconDelete />
            <span className={classes.itemText}>{translation.generalText.delete}</span>
        </MenuItem>
    );
});

// const MenuItemDelete = ({onClick = () => {}, className, ...props}) => {
//     const classes = useStyles();
//     const translation = usePlatformTranslation();
//     return (
//         <MenuItem className={clsx(classes.item, className)} onClick={onClick} {...props}>
//             <IconDelete />
//             <span className={classes.itemText}>{translation.generalText.delete}</span>
//         </MenuItem>
//     );
// };

export default MenuItemDelete;
