import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Dialog, DialogContent} from '@material-ui/core';
import {KeyboardArrowUp as KeyboardArrowUpIcon} from '@material-ui/icons';

import * as actions from '../../../../../store/actions';
import './ExhibitorsListMobile.scss';

const Exhibitor = ({exhibitor, activeExhibitorId, onClick}) => {
    const [hasImageError, setHasImageError] = useState(false);
    const hasImage = exhibitor.booth.logo && !hasImageError;

    return (
        <li className={'single-exhibitor ' + (activeExhibitorId === exhibitor._id ? 'active' : '')} onClick={onClick}>
            {hasImage && (
                <div className="logo-container">
                    <img
                        src={exhibitor.filesUrl + exhibitor.booth.logo}
                        alt={exhibitor.company}
                        onError={() => setHasImageError(true)}
                    />
                </div>
            )}
            <span>{exhibitor.company}</span>
        </li>
    );
};

class ExhibitorsListMobile extends React.Component {
    state = {
        translation: null,
    };

    componentDidMount() {
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    setActiveExhibitorId = (exhibitorId) => (e) => {
        this.props.onSetActiveExhibitorId(exhibitorId);
        this.props.onClose();
    };

    render() {
        const {translation} = this.state;
        return (
            <Dialog
                fullScreen
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="form-dialog-title"
                className="exhibitors-list-dialog booth-dialog"
            >
                <DialogContent className="booth-dialog-content">
                    <h4 onClick={this.props.onClose}>
                        <span>{translation?.showfloor.allExhibitorsButton}</span>
                        <KeyboardArrowUpIcon />
                    </h4>
                    <ul>
                        {this.props.exhibitors.map((exhibitor) => (
                            <Exhibitor
                                key={exhibitor._id}
                                exhibitor={exhibitor}
                                activeExhibitorId={this.props.activeExhibitorId}
                                onClick={this.setActiveExhibitorId(exhibitor._id)}
                            />
                        ))}
                    </ul>
                </DialogContent>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        exhibitors: state.exhibitors.approvedExhibitors,
        activeExhibitorId: state.exhibitors.activeExhibitorId,
        languages: state.languages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSetActiveExhibitorId: (exhibitorId) => dispatch(actions.setActiveExhibitorId(exhibitorId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitorsListMobile);
