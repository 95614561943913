import React, {useState, useEffect} from 'react';
import {Scrollbars} from 'react-custom-scrollbars';
import {useSelector} from 'react-redux';
import cn from 'classnames';

import './ProgramDropdownList.scss';

const BOTTOM_MENU_HEIGHT = 56;

const calculateMaxHeight = (listEl) => {
    const pageHeight = document.documentElement.clientHeight;
    const {top: listTopCoodinate} = listEl.getBoundingClientRect();
    return pageHeight - listTopCoodinate - BOTTOM_MENU_HEIGHT;
};

const ScrollWrapper = ({children, isLargeScreen}) => {
    if (isLargeScreen) {
        return <Scrollbars>{children}</Scrollbars>;
    }

    return children;
};

const ProgramDropdownList = ({children}) => {
    const isLargeScreen = useSelector((state) => state.layout.isLargeScreen);
    const [maxHeight, setMaxHeight] = useState(null);
    const [listEl, setListEl] = useState(null);

    const adaptScreen = (listEl, isLargeScreen) => {
        if (isLargeScreen || !listEl) {
            setMaxHeight(null);
            return;
        }

        setMaxHeight(calculateMaxHeight(listEl));
    };

    useEffect(() => {
        adaptScreen(listEl, isLargeScreen);
    }, [listEl, isLargeScreen]);

    useEffect(() => {
        const handleResize = () => adaptScreen(listEl, isLargeScreen);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [listEl, isLargeScreen]);

    return (
        <div
            className={cn('ProgramDropdownList', {
                ProgramDropdownList_notLargeScreen: !isLargeScreen,
            })}
            style={{maxHeight: maxHeight ? `${maxHeight}px` : 'none'}}
            ref={setListEl}
        >
            <ScrollWrapper isLargeScreen={isLargeScreen}>
                <div className="ProgramDropdownList__element">{children}</div>
            </ScrollWrapper>
        </div>
    );
};

ProgramDropdownList.Item = ({children}) => <div className="ProgramDropdownList__item">{children}</div>;
export default ProgramDropdownList;
