import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './MyAccountDropdownComponent.scss';

export function MyAccountDropdownComponent({children, style, className}) {
    return (
        <div
            id="account-drop"
            className={classNames('my-account-component dropdown-component', {[className]: className})}
            style={style}
        >
            {children}
        </div>
    );
}

MyAccountDropdownComponent.propTypes = {
    children: PropTypes.node,
    style: PropTypes.object,
    className: PropTypes.string
};
