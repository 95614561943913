import React, {useMemo} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Chip} from '@material-ui/core';
import {mergeCheckedFilters} from '../../../../../services/helpers';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {getIsRtlLanguage, getPlatformLanguage} from 'store/selectors';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > *': {
            margin: theme.spacing(0.5),
        },
    },
    chipDeleteIcon: {
        '&.isRtl': {
            margin: '0 -6px 0 5px',
        },
    },
}));

const FilterChips = ({filters = [], setFilters, withLabels = false}) => {
    const classes = useStyles();
    const isRtlLanguage = useSelector(getIsRtlLanguage);
    const platformLanguage = useSelector(getPlatformLanguage);

    const mergedFilters = useMemo(() => {
        return mergeCheckedFilters(filters, '', platformLanguage);
    }, [filters, platformLanguage]);

    const handleUncheck = (filterId, optionId) => {
        // TODO: creae helper to check last chip in action
        // clear if it is the last chip
        if (mergedFilters.length < 2) return setFilters([]);

        const updatedFilters = [...filters];

        updatedFilters.forEach((filter) => {
            if (filter._id !== filterId) return;

            filter.options.forEach((option) => {
                if (option._id !== optionId) return;

                option.isChecked = false;
            });
        });

        setFilters(updatedFilters);
    };

    return (
        <div className={classes.root}>
            {mergedFilters.length > 0 &&
                mergedFilters.map(({optionId, optionName, filterId, isChecked, filterName}) => (
                    <React.Fragment key={optionId}>
                        {isChecked && (
                            <Chip
                                classes={{deleteIcon: classNames(classes.chipDeleteIcon, {isRtl: isRtlLanguage})}}
                                label={`${optionName}${withLabels ? ` ${filterName}` : ''}`}
                                onDelete={() => handleUncheck(filterId, optionId)}
                            />
                        )}
                    </React.Fragment>
                ))}
        </div>
    );
};

export default FilterChips;
