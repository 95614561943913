import {TextField} from '@material-ui/core';
import React from 'react';
import ChatMembers from '../EditGroupChat/components/ChatMembers';
import UsersToAddList from '../EditGroupChat/components/UsersToAddList';

const AddUserList = ({
    translation,
    searchValue,
    onSearch,
    isLoading,
    filteredUsers,
    usersToAdd,
    onAddUser,
    onRemoveUser,
}) => (
    <>
        <TextField
            id="search"
            label={translation?.chatsDropdown.newChatSearchInput}
            type="search"
            margin="normal"
            value={searchValue}
            onChange={onSearch}
            variant="outlined"
            fullWidth
        />
        <ChatMembers
            loading={isLoading}
            filteredUsers={filteredUsers}
            usersToAdd={usersToAdd}
            translation={translation}
            handleRemoveUser={onRemoveUser}
            handleAddUser={onAddUser}
        />
        <UsersToAddList usersToAdd={usersToAdd} handleRemoveUser={onRemoveUser} translation={translation} />
    </>
);

export default AddUserList;
