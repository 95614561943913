import AndroidChromeSmall from './android-chrome-192x192.png';
import AndroidChromeLarge from './android-chrome-512x512.png';
import AppleTouchIcon from './apple-touch-icon.png';
import Favicon from './favicon.ico';
import FaviconSmall from './favicon-16x16.png';
import FaviconLarge from './favicon-32x32.png';

export const egyptPavilionAssets = {
    AndroidChromeSmall,
    AndroidChromeLarge,
    AppleTouchIcon,
    Favicon,
    FaviconSmall,
    FaviconLarge,
};
