import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';

import {connectToGroupChat, disconnectFromGroupChat, handleGroupChatUpdate} from 'Api/socketApi';
import {usePlatformTranslation} from 'services/hooks';
import * as actions from 'store/actions';
import clsx from 'clsx';

import './GroupChatPreview.scss';

const GroupChatPreview = ({groupChatData, activeChatRoomId, timeAgoString, userId, handleOpen}) => {
    const dispatch = useDispatch();
    const chat = groupChatData;
    const translation = usePlatformTranslation();

    useEffect(() => {
        connectToGroupChat({chatId: chat._id, userId});

        handleGroupChatUpdate(({groupChat}) => {
            if (groupChat._id !== chat._id) {
                return;
            }

            dispatch(actions.getGroupChats());
        });

        return () => disconnectFromGroupChat({chatId: chat._id, userId});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, chat._id]);

    return (
        <div
            color={chat._id === activeChatRoomId ? 'primary' : 'default'}
            key={chat._id}
            className="group-chat-button"
            id={chat._id}
            onClick={() => handleOpen(chat._id, chat.users, chat.owner)}
        >
            <div className="avatar">
                {chat.users.length ? (
                    <span>
                        {chat.users[0].first[0]}
                        {chat.users[0].last[0]}
                    </span>
                ) : null}
                {groupChatData?.unread !== 0 ? (
                    <div className="online-button">
                        <span>{groupChatData.unread}</span>
                    </div>
                ) : null}
            </div>
            <div className="chat-details">
                {chat.name ? (
                    <p className="group-chat-name">{chat.name}</p>
                ) : (
                    <div>
                        {chat.users.length ? (
                            <p className="group-chat-name">
                                {chat.users.map((user) => {
                                    return (
                                        <span key={user._id}>
                                            {user.first} {user.last},{' '}
                                        </span>
                                    );
                                })}
                            </p>
                        ) : null}
                    </div>
                )}
                {chat.users.length ? (
                    <span className="members-count">{` ${chat.users.length} ${translation.chatsDropdown.participantsCount}`}</span>
                ) : null}
                <p
                    className={clsx('last-message', activeChatRoomId && 'last-message--opened')}
                    dangerouslySetInnerHTML={{__html: groupChatData.lastMessageText}}
                />
            </div>
            <div>
                <p className="time-ago">{timeAgoString}</p>
            </div>
            <div className="all-chats__user-wrapper-separator" />
        </div>
    );
};

export default GroupChatPreview;
