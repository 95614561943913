import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

import AccountProfile from './components/AccountProfile';
import BusinessCardBuilder from './components/BusinessCardBuilder/BusinessCardBuilder';
import EventProfile from './components/EventProfile';
import AppliedJobsSwitch from './components/AppliedJobsSwitch/AppliedJobsSwitch';
import MyEvents from './components/MyEvents';

import {getProfileLink} from '../../../../../routes';
import AppliedJobsSwitchMobile from './components/AppliedJobsSwitchMobile/AppliedJobsSwitchMobile';
import {useSelector} from 'react-redux';
import MyEventsMobile from './components/MyEventsMobile';

const AccountPagesScene = ({eventSlug}) => {
    const isLargeScreen = useSelector((state) => state.layout.isLargeScreen);
    return (
        <Switch>
            <Route path="/event/:eventSlug/my-account/profile" component={AccountProfile} exact />
            <Route path="/event/:eventSlug/my-account/event-profile" component={EventProfile} exact />
            <Route path="/event/:eventSlug/my-account/business-card" component={BusinessCardBuilder} />
            <Route
                path="/event/:eventSlug/my-account/applied-jobs"
                component={isLargeScreen ? AppliedJobsSwitch : AppliedJobsSwitchMobile}
            />
            <Route
                path="/event/:eventSlug/my-account/my-events"
                component={isLargeScreen ? MyEvents : MyEventsMobile}
            />
            <Redirect to={getProfileLink(eventSlug)} />
        </Switch>
    );
};

export default AccountPagesScene;
