import React from 'react';
import {connect} from 'react-redux';
import Slider from 'react-slick';
import {Lock as LockIcon} from '@material-ui/icons';

import DetectOutsideClick from '../DetectOutsideClick/DetectOutsideClick';

import {ReactComponent as Arrow} from '../../../Images/FeaturedExhibitorSliderArrow.svg';
import {ReactComponent as CloseSlidersArrow} from '../../../Images/close-auditoriums-slider.svg';
import {eventFolder} from '../../../config';
import './AuditoriumsSlider.scss';

function SampleNextArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div className={className} style={{...style}} onClick={onClick}>
            <Arrow />
        </div>
    );
}

function SamplePrevArrow(props) {
    const {className, style, onClick} = props;
    return (
        <div className={className} style={{...style}} onClick={onClick}>
            <Arrow />
        </div>
    );
}

class AuditoriumsSlider extends React.Component {
    componentDidMount() {
        this.calculateSliderMaxWidth();
        window.addEventListener('resize', this.calculateSliderMaxWidth);
        this.slider = React.createRef();
        this.setLanguagesData();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateSliderMaxWidth);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    state = {
        sliderContainerStyles: null,
        showAuditoriumsSlider: true,
        slidesToShow: 1,
        sliderPadding: {
            padding: '32px 80px 40px',
        },
        translation: null,
    };

    calculateSliderMaxWidth = () => {
        let width = 0;
        let slidesToShow = 1;
        let sliderPadding = {
            padding: '32px 80px 40px',
        };

        if (window.innerWidth < 1251) {
            slidesToShow = this.props.auditoriums.length < 3 ? this.props.auditoriums.length : 2;
            width =
                (this.props.auditoriums.length < 3 ? this.props.auditoriums.length : 2) * 136 +
                (this.props.auditoriums.length < 3 ? 64 : 80);
            sliderPadding = {
                padding: this.props.auditoriums.length < 3 ? '15px 32px 15px' : '15px 40px 15px',
            };
        } else if (window.innerWidth < 1441) {
            slidesToShow = this.props.auditoriums.length < 4 ? this.props.auditoriums.length : 3;
            width =
                (this.props.auditoriums.length < 4 ? this.props.auditoriums.length : 3) * 136 +
                (this.props.auditoriums.length < 4 ? 64 : 80);
            sliderPadding = {
                padding: this.props.auditoriums.length < 4 ? '15px 32px 15px' : '15px 40px 15px',
            };
        } else {
            slidesToShow = this.props.auditoriums.length < 5 ? this.props.auditoriums.length : 4;
            width =
                (this.props.auditoriums.length < 5 ? this.props.auditoriums.length : 4) * 166 +
                (this.props.auditoriums.length < 5 ? 64 : 160);
            sliderPadding = {
                padding: this.props.auditoriums.length < 5 ? '32px 32px 40px' : '32px 80px 40px',
            };
        }

        this.setState({
            sliderContainerStyles: {
                width: width,
            },
            slidesToShow: slidesToShow,
            sliderPadding: sliderPadding,
        });
    };

    showAuditoriumsSlider = (e) => {
        this.setState({showAuditoriumsSlider: !this.state.showAuditoriumsSlider}, () => {
            if (this.slider.current) {
                this.slider.current.slickGoTo(this.props.activeAuditoriumIndex, true);
            }
        });
    };

    hideAuditoriumsSlider = () => {
        this.setState({showAuditoriumsSlider: false});
    };

    getAuditoriumSliderElement = (auditorium, index) => {
        const {eventId, event, resourcesAccess} = this.props;
        let hasAccessToCurrentAuditorium = true;

        if (event.hasAccessManagement && resourcesAccess && !event.hasVillage) {
            hasAccessToCurrentAuditorium = resourcesAccess.auditoriums.includes(auditorium._id);
        }
        return (
            <div
                className={'single-auditorium-container ' + (hasAccessToCurrentAuditorium ? '' : 'restricted')}
                key={auditorium._id}
            >
                <div
                    className={
                        'auditorium-details-container ' + (this.props.activeAuditoriumIndex === index ? 'active' : '')
                    }
                    onClick={this.props.onSetActiveAuditoriumIndex(index)}
                >
                    {!hasAccessToCurrentAuditorium && <LockIcon color={'disabled'} />}
                    {auditorium.image ? <img src={`${eventFolder}${eventId}/${auditorium.image}`} alt="logo" /> : null}
                    <div className="image-filter"></div>
                    <p className="auditorium-name">{auditorium.name}</p>
                    <p className="auditorium-description">{auditorium.description}</p>
                </div>
            </div>
        );
    };

    render() {
        const {translation} = this.state;
        let sliderSettings = {
            dots: false,
            arrows: true,
            nextArrow: <SampleNextArrow />,
            prevArrow: <SamplePrevArrow />,
            infinite: true,
            speed: 500,
            slidesToShow: this.state.slidesToShow,
            slidesToScroll: 1,
            className: 'center',
            focusOnSelect: true,
        };

        return (
            <div className="auditoriums-popup" style={this.state.sliderContainerStyles}>
                <div className="popup-wrapper">
                    {this.state.showAuditoriumsSlider ? (
                        <div className="toggle-auditoriums-button close" onClick={this.showAuditoriumsSlider}>
                            <p>
                                <CloseSlidersArrow />
                            </p>
                        </div>
                    ) : (
                        <div className="toggle-auditoriums-button" onClick={this.showAuditoriumsSlider}>
                            <p>
                                {translation?.auditorium.allRooms} <span>{this.props.auditoriums.length}</span>
                            </p>
                        </div>
                    )}

                    {this.state.showAuditoriumsSlider && (
                        <DetectOutsideClick
                            hideAuditoriumsSlider={this.hideAuditoriumsSlider}
                            target="auditoriumsSlider"
                        >
                            <div className="slider-container has-slider" style={this.state.sliderPadding}>
                                <Slider {...sliderSettings} ref={this.slider}>
                                    {this.props.auditoriums.map((auditorium, index) => {
                                        return (
                                            <div key={auditorium._id}>
                                                {this.getAuditoriumSliderElement(auditorium, index)}
                                            </div>
                                        );
                                    })}
                                </Slider>
                            </div>
                        </DetectOutsideClick>
                    )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        resourcesAccess: state.user.resourcesAccess,
        languages: state.languages,
    };
};

export default connect(mapStateToProps)(AuditoriumsSlider);
