export const getAccountLink = (eventSlug) => `/event/${eventSlug}/my-account`;
export const getProfileLink = (eventSlug) => `${getAccountLink(eventSlug)}/profile`;
export const getEventProfileLink = (eventSlug) => `${getAccountLink(eventSlug)}/event-profile`;
export const getBusinessCardLink = (eventSlug, slug) => {
    const link = `${getAccountLink(eventSlug)}/business-card`;
    return slug ? `${link}/${slug}` : link;
};
export const getBusinessCardEditLink = (eventSlug) => getBusinessCardLink(eventSlug, 'edit');
export const getBusinessCardColorsLink = (eventSlug) => getBusinessCardLink(eventSlug, 'colors');
export const getBusinessCardBrandingLink = (eventSlug) => getBusinessCardLink(eventSlug, 'branding');
export const getBusinessCardPreviewLink = (eventSlug) => getBusinessCardLink(eventSlug, 'preview');

export const getRegisterLink = () => '/register';

export const getEventLandingLink = (eventSlug) => `/event/${eventSlug}`;
export const getEventRegistrationLink = (eventSlug) => `/event/${eventSlug}/register`;
export const getEventParticipantRegistrationLink = ({eventSlug, isLoggedIn, shortLink, packageId}) => {
    let baseLink = `/event/${eventSlug}/participant-registration`;

    if (isLoggedIn && shortLink && packageId) return `${baseLink}/${shortLink}/package/${packageId}`;

    if (isLoggedIn && shortLink) return `${baseLink}/${shortLink}`;

    if (isLoggedIn) return baseLink;

    return `/event/${eventSlug}/register?registerAsParticipant=true`;
};
// TODO: combine exhibitor and participant
export const getEventExhibitorRegistrationLink = ({eventSlug, isLoggedIn, shortLink, packageId}) => {
    let baseLink = `/event/${eventSlug}/exhibitor-registration`;

    if (isLoggedIn && shortLink && packageId) return `${baseLink}/${shortLink}/package/${packageId}`;

    if (isLoggedIn && shortLink) return `${baseLink}/${shortLink}`;

    if (isLoggedIn) return baseLink;

    return `/event/${eventSlug}/register?registerAsExhibitor=true`;
};

export const getExhibitorBooth = (eventSlug) => `/event/${eventSlug}/exhibitor-dashboard/booth`;
export const getExhibitorJobs = (eventSlug) => `/event/${eventSlug}/exhibitor-dashboard/exhibitor-jobs`;
export const getExhibitorStatistics = (eventSlug) => `/event/${eventSlug}/exhibitor-dashboard/exhibitor-booth-view`;
export const getAppliedJobsLink = (eventSlug) => `${getAccountLink(eventSlug)}/applied-jobs`;

export const getMyEventsLink = (eventSlug) => `/event/${eventSlug}/my-account/my-events`;
