import {createSelector} from 'reselect';

const timeslot = (state) => state.timeslot;

export const getTimeslotLoading = createSelector(timeslot, (state) => state.loading);

export const getTimeslotError = createSelector(timeslot, (state) => state.error);

export const getTimeslotData = createSelector(timeslot, (state) => state.data);

export const getTimeslotWallId = createSelector(timeslot, (state) => state.data?.videoWall);

export const getTimeslotZoomRecordings = createSelector(timeslot, (state) => state.recordedZoomSessions);

export const getResourcesStatus = createSelector(timeslot, (state) => state.data.resourcesStatus);

export const getETXSessionToken = createSelector(timeslot, (state) => state.etxSessionToken);

export const getInterpretation = createSelector(timeslot, (state) => state.interpretation);

export const getAllowedUser = createSelector(timeslot, (state) => state.allowedUsers);

export const getTimeslotPolls = createSelector(timeslot, (state) => state.polls);
