import React from 'react';
import cn from 'classnames';

import {IconTrash, IconAngleArrowRight} from '../../../../../../../../../../../Icons';
import Button from '../../../../../../../../../../../Button/Button';

import './MobileAgendaProgramItem.scss';

const MobileAgendaProgramItem = ({
    title,
    eventName,
    auditoriumName,
    startTime,
    endTime,
    timeZone,
    date,
    isMeeting,
    onRemove,
    onMeetingStart,
}) => (
    <div className="MobileAgendaProgramItem">
        <div className="MobileAgendaProgramItem__mainRow">
            <div className="MobileAgendaProgramItem__title">{title}</div>

            <div className="MobileAgendaProgramItem__timeGroup">
                <div className="MobileAgendaProgramItem__timeItem">{startTime}-</div>
                <div className="MobileAgendaProgramItem__timeItem">{endTime}</div>
            </div>

            <div className="MobileAgendaProgramItem__actionGroup">
                <div className="MobileAgendaProgramItem__actionItem">
                    <Button icon={<IconTrash />} onClick={onRemove} />
                </div>

                <div
                    className={cn('MobileAgendaProgramItem__actionItem', {
                        MobileAgendaProgramItem__actionItem_hidden: !isMeeting,
                    })}
                >
                    <Button icon={<IconAngleArrowRight />} onClick={onMeetingStart} />
                </div>
            </div>
        </div>

        <div className="MobileAgendaProgramItem__metaRow">
            {eventName && <div className="MobileAgendaProgramItem__metaItem">{eventName}</div>}
            {auditoriumName && <div className="MobileAgendaProgramItem__metaItem">{auditoriumName}</div>}
            <div className="MobileAgendaProgramItem__metaItem">{date}</div>
            <div className="MobileAgendaProgramItem__metaItem">{timeZone}</div>
        </div>
    </div>
);

export default MobileAgendaProgramItem;
