import React from 'react';

import './EnglishContent.scss';

export const EnglishContent = () => (
    <div className="header-spacing-container">
        <div className="gdpr-container">
            <h1>Terms</h1>
            <h4>Effective date: 24.07.2020</h4>
            <p>
                These Terms and Conditions (subsequently called “Terms”) govern your access and use of the virtual event
                platform Event10X (subsequently called “Platform”) organized by Events10x Ltd. Your access to and use of
                the Platform are conditioned on your acceptance of and compliance with these Terms. By accessing or
                using the Platform, you agree to comply with and to be bound by these Terms.
            </p>
            <h2>1. Basic Terms</h2>
            <ol>
                <li>
                    <p>
                        By invitation, Events10x Ltd offers registered users exclusively of legal age the opportunity to
                        participate in virtual events on the Platform. The time and duration of these events are at the
                        sole discretion of Events10x Ltd. You may use the Platform only if you are able to form a
                        binding contract with Events10x Ltd. If you are accepting these Terms and using the Platform on
                        behalf of a company or other legal entity, you represent and warrant that you are authorized to
                        do so.
                    </p>
                </li>
                <li>
                    <p>
                        Any usage of content or services going beyond what is explicitly offered on the Platform
                        requires the previous approval of Events10x Ltd.
                    </p>
                </li>
                <li>
                    <p>
                        Upon registration with Platform, you are granted a non-exclusive and non-transferable license to
                        use software included as the Platform (“License”). The software and any interfaces, content,
                        fonts are licensed, not sold, to you by Events10x Ltd, for use only under the terms of this
                        License. Events10x Ltd and its licensors retain ownership of the Platform software itself and
                        reserves all rights not expressly granted to you. The terms of this License, it will govern any
                        software updates provided by Events10x Ltd.
                    </p>
                </li>
                <li>
                    <p>
                        Upon registration you grant to Events10x Ltd non-exclusive transferable sublicensable worldwide
                        license to use process, modify, copy, publish, distribute and process information and content
                        (messages, wall posts, chat messages, trademarks and copyright protectable works) that you
                        provide through the Platform. This right is granted to us without any further consent,
                        compensation, payment or notice, and limited to the following:
                    </p>
                    <ul>
                        <li>
                            <p>
                                This license may be deleted by specific content deleting from the Platform or by closing
                                or account with Platform, except cases when (a) the content was shared with other users
                                of the Platform and they copied, stored or shared it and (b) it takes reasonable time to
                                remove the backup from Platform.
                            </p>
                        </li>
                        <li>
                            <p>
                                Events10x Ltd shall not include your content in advertisements for third party services
                                or goods. However, Events10x Ltd specifically granted right, without payment to you or
                                others, to serve or past advertisements near your content and information, and your
                                profile data may be visible and included with ads, as noted in the Privacy Policy.
                            </p>
                        </li>
                    </ul>
                </li>
                <li>
                    <p>
                        You are responsible for your use of the Platform, for any content you post, and for any
                        consequences thereof. Events10x Ltd distributes user-generated content to other users only if
                        this content does not violate the law or these Terms. Events10x Ltd reserves the right to remove
                        user-generated content without prior notice if this content violates the law or these Terms.
                    </p>
                </li>
                <li>
                    <p>
                        Events10x Ltd offers access to a virtual event with no guarantees regarding entering into an
                        employment contract. If you do enter into a contract through the Platform, Events10x Ltd is not
                        involved in this contract, and is thus not a partner in this contract. You are fully responsible
                        for any contract you enter into through the Platform; Events10x Ltd cannot be held liable for
                        any breach of contract.
                    </p>
                </li>
            </ol>

            <h2>2. Registration</h2>
            <ol>
                <li>
                    <p>
                        Registration is required before using the Platform. You may register only if invited by
                        Events10x Ltd. Previously registered users that have not been invited to an event can be
                        excluded from participation by Events10x Ltd.
                    </p>
                </li>
                <li>
                    <p>
                        You warrant and represent that all information provided at registration is accurate and
                        complete, and that you are of legal age. During the period of active participation in the
                        virtual event, you are obligated to announce any change in the information provided at
                        registration to Events10x Ltd without undue delay. Users can register only once.
                    </p>
                </li>
                <li>
                    <p>
                        By completing the registration process, you submit an offer for a contract concerning the usage
                        of the Platform. Events10x Ltd accepts this offer by activating the user account for
                        participation in the virtual event. Through this acceptance, both parties enter into a contract.
                    </p>
                </li>
                <li>
                    <p>
                        At registration, you must enter a password. You are responsible for safeguarding this password,
                        and agree not to disclose your password to any third party. Events10x Ltd shall not disclose the
                        password to any third party and Events10x Ltd shall not ask for a user’s password at any time.
                        You must notify Events10x Ltd immediately if you become aware of a breach of security or
                        unauthorized use of your password.
                    </p>
                </li>
                <li>
                    <p>
                        If you create an account through social media (Facebook, Tweeter, Google, LinkedIn) you will
                        have to authorize us to access, the requested social media sign-on. By granting access to your
                        social media account, you consent to our access and store certain information regarding you as
                        described in our Privacy Policy.{' '}
                    </p>
                </li>
            </ol>

            <h2>3. User Rights and Responsibilities</h2>
            <ol>
                <li>
                    <p>
                        You are required to provide accurate, complete, and non-misleading information within your
                        Platform profile and within any communication with other users. You are not allowed to use a
                        pseudonym or an alias.
                    </p>
                </li>
                <li>
                    <p>
                        When using the platform, You must comply with all applicable legislation, and respect all
                        third-party rights. In particular, you are prohibited from:
                    </p>
                    <ol className="alpha-list">
                        <li>
                            <p>
                                propagating offensive or defamatory content directed at other users or at other
                                entities,
                            </p>
                        </li>
                        <li>
                            <p>
                                using, promoting, or distributing any pornographic materials or any content that
                                violates any applicable legislation for the protection of minors,
                            </p>
                        </li>
                        <li>
                            <p>
                                using or cultivating anti-competitive actions, including progressive canvassing (e.g.
                                chain or pyramid schemes),
                            </p>
                        </li>
                        <li>
                            <p>
                                using without authorization any content protected by law (e.g. by copyright, trademark,
                                patent, utility patent, or design patent laws), or advertising, promoting, or
                                distributing any goods or services protected by law,
                            </p>
                        </li>
                        <li>
                            <p>unreasonably annoying (particularly with spam) any other user,</p>
                        </li>
                        <li>
                            <p>conducting the following activities, even if they are not against any law: </p>
                            <ul>
                                <li>
                                    <p>explicit or implicit sexual communication,</p>
                                </li>
                                <li>
                                    <p>
                                        usage of mechanisms, scripts, or software in combination with the Platform,
                                        unless explicitly allowed by Events10x Ltd,
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        actions which may impair infrastructure, particularly actions which may overload
                                        said infrastructure, or actions which may impair the Platform in any other
                                        manner.
                                    </p>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <p>
                                upload to the Platform any file that contains or redirects to a virus, worm, trojan
                                horse, or other harmful technology or component that unlawfully accesses alternatively,
                                downloads content or information stored within the Platform or on the hardware of
                                Events10x Ltd, affiliate, or any third party
                            </p>
                        </li>
                        <li>
                            <p>
                                decompile, interfere with, hack, reverse engineer, disassemble, modify, copy, or disrupt
                                the functionality, integrity, features, or performance of the Platform
                            </p>
                        </li>
                        <li>
                            <p>
                                access the Platform with intent and/or in order to create a comparable or similar
                                application or software or copy any features, graphics, ideas, images, videos,
                                intellectual property or functions of the service
                            </p>
                        </li>
                    </ol>
                </li>
                <li>
                    <p>
                        You may report any activities of any other user which violate applicable laws and/or any of
                        these Terms.
                    </p>
                </li>
            </ol>

            <h2>4. Events10x Ltd Rights and Responsibilities</h2>
            <ol>
                <li>
                    <p>
                        All right, title, and interest in and to the Platform (excluding content provided by users) are
                        and will remain the exclusive property of Events10x Ltd and/or its suppliers. The Platform is
                        protected by copyright, trademark, and other laws. Nothing in the Terms gives you a right to use
                        the Events10x Ltd name or any of its trademarks, logos, domain names, and other distinctive
                        brand features.
                    </p>
                </li>
                <li>
                    <p>
                        Events10x Ltd and its suppliers are entitled to take the following actions at any time if there
                        is concrete evidence that a user is breaking laws and regulations, the rights of third parties,
                        or these Terms, or if Events10x Ltd has other legitimate interests:{' '}
                    </p>
                    <ol className="alpha-list">
                        <li>
                            <p>deletion of user-generated content.</p>
                        </li>
                        <li>
                            <p>limitation and/or blocking of access to the Platform.</p>
                        </li>
                    </ol>
                </li>
            </ol>

            <h2>5. Changes to the Platform</h2>
            <p>
                Events10x Ltd reserves the right to modify at any time the services offered on the Platform and/or the
                right to offer services different from those offered at the time of a user’s registration.
            </p>

            <h2>6. Third party links</h2>
            <p>
                The Platform may contain links to third-party services and advertisements (third party link) for third
                parties that are not under the control of Events10x Ltd. When you click on a Third Party Link the
                Events10x Ltd shall not be responsible to warn you that you have left Platform. You acknowledge, accepts
                and agree that (1) upon clicking on the Third Party Link you will then be subject to the third party
                terms and policies; (2) Third Party Links provided only as a convenience and Events10x Ltd does not
                review, approve, monitor, endorse, warrant, or make any representations; (3) you are using third party
                links at your own risk
            </p>

            <h2>7. Communication with others</h2>
            <p>
                The Platform allows messaging and sharing of information in many ways, such as links sharing or creating
                of company exhibition platform. Information and content that you share may be seen by other users.{' '}
            </p>

            <h2>8. Copyright beach</h2>
            <p>
                If you are a copyright owner, or are authorized to act on behalf of one, or authorized to act under any
                exclusive right under copyright, please report alleged copyright infringements taking place on or
                through the Platform by completing notice of copyright infringement and delivering it to Events10x Ltd .
                Upon receipt of the notice as described below, Events10x Ltd will take whatever action, in its sole
                discretion, it deems appropriate, including removal of the challenged material from the Platform.
            </p>

            <h2>9. Contract Termination</h2>
            <p>
                These Terms will continue to apply until the contract formed is terminated by either you or Events10x
                Ltd as follows.
            </p>
            <ol>
                <li>
                    <p>
                        You may terminate the contract with Events10x Ltd at any time without cause. To terminate the
                        contract, you must deliver a termination notice to Events10x Ltd which includes your registered
                        name and the email address you registered with.
                    </p>
                </li>
                <li>
                    <p>
                        Events10x Ltd may terminate the contract at any time upon good cause. A good cause is defined as
                        an event which makes it unacceptable for Events10x Ltd to continue the agreement to the end of
                        the termination period, after taking into account a user’s individual circumstances and weighing
                        the interests of Events10x Ltd against the user’s interests. Good cause may include any of the
                        following events:{' '}
                    </p>
                    <ol className="alpha-list">
                        <li>
                            <p>If the user fails to comply with any applicable legal provisions,</p>
                        </li>
                        <li>
                            <p>
                                If the user breaches a contractual obligation, in particular an obligation set forth in
                                Sections 2 and 3 of these Terms,
                            </p>
                        </li>
                        <li>
                            <p>
                                If the reputation of the services offered on the Platform is substantially impaired by
                                the online presence of the user (e.g. if it is discovered after registration that the
                                user has been convicted of a criminal offense, and if said conviction is known to other
                                users,)
                            </p>
                        </li>
                        <li>
                            <p>
                                If the user promotes any communities or associations (or any of their methods or
                                activities) which are under surveillance by authorities responsible for public safety or
                                the protection of minors
                            </p>
                        </li>
                        <li>
                            <p>If the user causes harm to any other user(s).</p>
                        </li>
                    </ol>
                </li>
            </ol>

            <h2>10. The Platform is Available “As-Is”</h2>
            <ul>
                <li>
                    <p>
                        Your access to and use of the Platform or any user content is at your own risk. You understand
                        and agree that access to and use of the Platform is provided to you on an “as-is” and “as
                        available” basis. Events10x Ltd strives for as high of a Platform availability as technically
                        possible. However, issues such as maintenance, security, capacity, public telecommunication
                        networks, power failure, or software and hardware defects may lead to short failures in the
                        availability of the Platform or parts thereof. You agree not to make any claim concerning
                        availability of the Platform.
                    </p>
                </li>
                <li>
                    <p>
                        To the maximum extent permitted by applicable law, Events10x Ltd provides access to and use of
                        the Platform without warranties of any kind regarding any data and/or information provided or
                        made available by any user on the Platform or on any external websites linked to within the
                        Platform. In particular, Events10x Ltd does not warrant or represent that said data and/or
                        information is true or accurate, or that it fulfills or serves any particular purpose. Events10x
                        Ltd cannot be held liable for any information that you may have depended upon while using the
                        Platform.
                    </p>
                </li>
            </ul>

            <h2>11. Limitation of Liability</h2>
            <ul>
                <li>
                    <p>
                        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, Events10x Ltd SHALL NOT BE LIABLE FOR ANY
                        INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS OR
                        REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOOD-WILL, OR OTHER
                        INTANGIBLE LOSSES, RESULTING FROM YOUR ACCESS TO THE PLATFORM.
                    </p>
                </li>
                <li>
                    <p>
                        Whatever the legal grounds, liability for damage claims based solely on ordinary negligence
                        against Events10x Ltd (including its vicarious agents) shall exist only if Events10x Ltd
                        breaches a basic/cardinal obligation under these Terms. A cardinal obligation is an obligation
                        that the user can expect to be fulfilled, and whose fulfillment is a prerequisite to the
                        ordinary execution of the contract. In the event of such a breach, the amount of claims are
                        limited to typical and foreseeable damages.
                    </p>
                </li>
                <li>
                    <p>
                        Limits shall not apply to the extent damages are covered by Events10x Ltd’s business liability
                        insurance, providing that the insurance company has effected payment to Events10x Ltd. Events10x
                        Ltd will maintain the insurance coverage existing at the time this contract is concluded.
                    </p>
                </li>
                <li>
                    <p>
                        The above liability exclusions and limitations shall not apply in the event of the assumption of
                        express guarantees by Events10x Ltd or its vicarious agents, or in the event that promised
                        features are lacking.
                    </p>
                </li>
            </ul>

            <h2>12. Indemnity</h2>
            <ol>
                <li>
                    <p>
                        You shall indemnify and exempt Events10x Ltd from all actions, including damage claims, asserted
                        by other users or third parties against Events10x Ltd resulting from an infringement of their
                        rights by the content you posted on the Platform or the manner in which you used the Platform
                        services. You assume all reasonable costs Events10x Ltd incurs due to an infringement of third
                        party rights, including all reasonable legal defense costs. All other rights, including damage
                        claims by Events10x Ltd , are hereby unaffected. You have the right to prove that Events10x Ltd
                        incurred lesser charges than claims made.
                    </p>
                </li>
                <li>
                    <p>
                        The aforementioned obligations shall not apply to the extent you are not responsible for the
                        infringement.
                    </p>
                </li>
                <li>
                    <p>
                        In the event that your posted content infringes any rights of any third party, you shall at your
                        own expenses and at Events10x Ltd ‘s discretion, either obtain the right to use said content or
                        render said content free of any infringement. In the event that you infringe third-party rights
                        when using the Platform, you shall discontinue such use if so requested by Events10x Ltd.
                    </p>
                </li>
            </ol>

            <h2>13. Data Protection</h2>
            <p>
                Events10x Ltd recognizes that any data you provide on the Platform is extremely important to you, and
                Events10x Ltd shall therefore be particularly sensitive in handling such data. Events10x Ltd shall
                comply with all applicable legal provisions regarding data protection (as per the European Data
                Protection Directives and any other applicable data protection legislation). Details on the treatment of
                your data are set forth in the separate Data Protection Policy.
            </p>

            <h2>14. Privacy</h2>
            <p>
                Any information that you provide to Events10x Ltd is subject to our separate{' '}
                <a href="/privacy-policy/">Privacy Policy</a>, which governs our collection and use of your information.
                You understand that through your use of the Platform, you consent to the collection and use (as set
                forth in the <a href="/privacy-policy/">Privacy Policy</a>) of this information, including the transfer
                of this information to the United States and/or other countries for storage, processing and use.
            </p>

            <h2>15. Cookies</h2>
            <p>
                Like many other services, we use “cookies” to collect information. Cookies are a small files that
                transferred to your computer for record-keeping purposes. Users can control the use of cookies at the
                individual browser level. But if you choose to disable all cookies, it may limit your use of certain
                features or functionality of our services. For more detailed information on cookies and how they are
                used on our website, please visit our <a href="/cookies/">Cookie Policy.</a>
            </p>

            <h2>16. Final Provisions</h2>
            <ol>
                <li>
                    <p>
                        These Terms and any subsequent amendments must be in writing to be valid. No secondary
                        agreements exist. These Terms supersede and replace any prior version of these Terms agreed upon
                        between you and Events10x Ltd.
                    </p>
                </li>
                <li>
                    <p>
                        Events10x Ltd reserves the right to revise these Terms at any time. If the revision is material,
                        Events10x Ltd shall give due notice to all users. By continuing to access or use the Platform
                        after those revisions become effective, you agree to be bound by the revised Terms.
                    </p>
                </li>
                <li>
                    <p>
                        If any provision of these Terms is, for any reason, invalid and/or unenforceable, the remaining
                        provisions shall continue to be valid and enforceable to the fullest extent permitted by law.
                    </p>
                </li>
                <li>
                    <p>
                        These terms are exclusively governed by the law of United Arab Emirates. The place of
                        performance and jurisdiction under these Terms and any related actions shall be Dubai, United
                        Arab Emirates. You consent to this venue and waive any objection.
                    </p>
                </li>
            </ol>
        </div>
    </div>
);
