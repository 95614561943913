import React from 'react';

class AuditoriumBanners extends React.Component {
    render() {
        const {filesUrl, brandingData} = this.props;
        return (
            <>
                <div className="clickable-element no-hover" id="bannerLeft">
                    {brandingData.lobbyBanner ? (
                        <img src={filesUrl + brandingData.lobbyBanner} alt="Logo" className="auditorium-banner-image" />
                    ) : null}
                </div>
                <div className="clickable-element no-hover" id="bannerRight">
                    {brandingData.lobbyBanner ? (
                        <img src={filesUrl + brandingData.lobbyBanner} alt="Logo" className="auditorium-banner-image" />
                    ) : null}
                </div>
            </>
        );
    }
}

export default AuditoriumBanners;
