import {Client} from 'streaming-client-sdk';
import {PARTICIPANT_TYPES} from '../../constants/organizer/sessions';

export const isOrganizer = (user, eventSlug) => {
    let isOrg = false;
    if (user?.data?.eventRoles) {
        isOrg = !!user.data.eventRoles.find((r) => {
            return r.event.slug === eventSlug && r.roles.includes(PARTICIPANT_TYPES.organizer);
        });
        if (isOrg && localStorage.sessionToken) localStorage.removeItem('sessionToken');
    }

    return isOrg;
};

export const isBrowserSupported = (DetectRTC) => {
    const isWebrtcAndUserMedia = DetectRTC.isWebRTCSupported && DetectRTC.isGetUserMediaSupported;

    if (DetectRTC.browser.name === 'Edge') {
        return isWebrtcAndUserMedia && DetectRTC.browser.version >= 79;
    }

    return isWebrtcAndUserMedia;
};

export const createClient = async ({authToken, timeslotId, gatewayName, sessionKey}) => {
    try {
        const client = new Client({authToken});

        // await client.init(`wss://${gatewayName}/media/${timeslotId}-${sessionKey}/`);

        await client.init(process.env.REACT_APP_VIDEO_ENGINE); // Notice: for development purposes only

        return client;
    } catch (error) {}
};

export const handleCanPlay = (videoRef) => videoRef?.current.play();

export const checkIsCameraOff = (videoCapture) => !videoCapture?.getVideoTracks()[0].enabled;

export const calculateGridSize = (total) => {
    if (!total) total = 1;
    const xSize = Math.ceil(Math.sqrt(total));
    const ySize = xSize * (xSize - 1) >= total ? xSize - 1 : xSize;

    return {
        width: `${100 / xSize}%`,
        height: `${100 / ySize}%`
    };
};

function volumeAudioProcess(event) {
    const buf = event.inputBuffer.getChannelData(0);
    const bufLength = buf.length;
    let sum = 0;
    let x;

    for (let i = 0; i < bufLength; i++) {
        x = buf[i];
        if (Math.abs(x) >= this.clipLevel) {
            this.clipping = true;
            this.lastClip = window.performance.now();
        }
        sum += x * x;
    }

    const rms = Math.sqrt(sum / bufLength);

    this.volume = Math.max(rms, this.volume * this.averaging);
}

export function createAudioMeter(audioContext, clipLevel, averaging, clipLag) {
    const processor = audioContext.createScriptProcessor(512);
    processor.onaudioprocess = volumeAudioProcess;
    processor.clipping = false;
    processor.lastClip = 0;
    processor.volume = 0;
    processor.clipLevel = clipLevel || 0.98;
    processor.averaging = averaging || 0.95;
    processor.clipLag = clipLag || 750;

    processor.connect(audioContext.destination);

    processor.checkClipping = function () {
        if (!this.clipping) return false;
        if (this.lastClip + this.clipLag < window.performance.now()) this.clipping = false;
        return this.clipping;
    };

    processor.shutdown = function () {
        this.disconnect();
        this.onaudioprocess = null;
    };

    return processor;
}
