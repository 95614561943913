import {connect} from 'react-redux';
import * as actions from 'store/actions';
import {getTimeslotData, getEventId} from 'store/selectors';

import {Thumbnail} from './Thumbnail';

export default connect(
    (state) => ({
        eventId: getEventId(state),
        timeslot: getTimeslotData(state),
    }),
    {
        onUploadSessionThumbnailImage: actions.uploadSessionThumbnailImage,
    }
)(Thumbnail);
