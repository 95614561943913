import React from 'react';
import {TextValidator} from 'react-material-ui-form-validator';
import {Checkbox, FormGroup, FormControlLabel, FormControl, FormLabel} from '@material-ui/core';

class CheckboxField extends React.Component {
    state = {
        selectedOptions: [],
    };

    componentDidMount() {
        this.setState({
            selectedOptions: this.props.field.value || [],
        });
    }

    handleChange = (e) => {
        const {field} = this.props;
        let updatedOptions = [...this.state.selectedOptions];

        if (e.target.checked) {
            updatedOptions.push(e.target.value);
        } else {
            updatedOptions = updatedOptions.filter((option) => option !== e.target.value);
        }

        this.setState({
            selectedOptions: updatedOptions,
        });
        this.props.handleChange(field.name, updatedOptions);
    };

    render() {
        const {field} = this.props;
        return (
            <>
                <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">{field.label}</FormLabel>
                    <FormGroup
                        aria-label={field.label}
                        name={field.name}
                        value={field.value}
                        className="registration-radio-group"
                    >
                        {field.options.map((option) => {
                            return (
                                <FormControlLabel
                                    value={option._id}
                                    control={<Checkbox onChange={this.handleChange} />}
                                    label={option.value}
                                    checked={this.state.selectedOptions.includes(option._id)}
                                    key={option._id}
                                />
                            );
                        })}
                    </FormGroup>
                </FormControl>
                <TextValidator
                    key={field.name}
                    label={field.label}
                    type="text"
                    name={field.name}
                    value={field.value}
                    variant="outlined"
                    onChange={this.handleChange}
                    validators={field.validators}
                    errorMessages={field.errorMessages}
                    multiline={field.multiline > 0}
                    rows={field.multiline}
                    fullWidth={true}
                    margin="normal"
                    className="field-container hidden"
                />
            </>
        );
    }
}

export default CheckboxField;
