import React from 'react';
import classNames from 'classnames';

import IconContainer from '../IconContainer/IconContainer';

import './Button.scss';

const Button = React.forwardRef(
    (
        {component: Component = 'button', title, icon, iconSize, isDisabled, isExpanded, className, onClick, ...rest},
        ref
    ) => (
        <Component
            className={classNames('', {
                Button: !className || !className.includes('fullyCustomized'),
                Button_disabled: isDisabled,
                Button_expanded: isExpanded,
                Button_noTitle: !title,
                [className]: className,
            })}
            onClick={isDisabled ? () => {} : onClick}
            {...rest}
            ref={ref}
        >
            {icon && (
                <span className="Button__icon">
                    <IconContainer size={iconSize}>{icon}</IconContainer>
                </span>
            )}

            {title && <span className="Button__title">{title}</span>}
        </Component>
    )
);

export default Button;
