import React from 'react';
import {connect} from 'react-redux';
import cn from 'classnames';

import * as actions from 'store/actions';
import {getPrivateChatId} from 'services/utils';
import {withUserStatus} from 'services/hocs';
import ChatButton from '../../../SideMenu/components/Networking/components/UsersList/components/UserPreviewPlate/components/ChatButton/ChatButton';

import './Representatives.scss';

class Representatives extends React.Component {
    componentDidMount() {
        this.props.onGetOnlineUsers();
    }

    handleOpenPrivateChat = (userId) => (e) => {
        const {eventId} = this.props;
        const privateChatId = getPrivateChatId(this.props.privateChats, userId);

        if (privateChatId) {
            this.props.onOpenPrivateChat(privateChatId);
        } else {
            if (userId !== this.props.userLoggedIn._id && !this.props.loading) {
                this.props.onCreatePrivateChat(userId, eventId);
            }
        }
    };

    checkIfIsOnline = (eventUser) => {
        let isOnline = false;
        if (this.props.onlineUsers && this.props.onlineUsers.length) {
            isOnline = this.props.onlineUsers.findIndex((user) => user.userId === eventUser.user._id) > -1;
        }
        return isOnline;
    };

    render() {
        const {exhibitor, userLoggedIn, getUserStatus} = this.props;

        let exhibitorRepresentatives = [...exhibitor.booth.medalionRepresentatives];
        if (exhibitor.booth.deskRepresentativeLeft.user) {
            exhibitorRepresentatives.push(exhibitor.booth.deskRepresentativeLeft);
        }
        if (exhibitor.booth.deskRepresentativeRight.user) {
            exhibitorRepresentatives.push(exhibitor.booth.deskRepresentativeRight);
        }

        return (
            <ul className="exhibitor-representatives">
                {exhibitorRepresentatives.map((representative) => {
                    return (
                        <li key={representative.user._id}>
                            <div
                                className={cn(
                                    'exhibitor-representatives__user-container',
                                    'user__status',
                                    `user__status_${getUserStatus(
                                        representative?.user?.user?._id || representative?.user?._id
                                    )}`
                                )}
                            >
                                <div
                                    className="exhibitor-representatives__participant-avatar"
                                    style={{
                                        background: `center / cover no-repeat url('${process.env.REACT_APP_AVATAR_FOLDER}${representative.user.avatarSmall}')`,
                                    }}
                                />
                                <div className="exhibitor-representatives__user-details">
                                    <div>
                                        <div className="user-name">
                                            <p className="user-name-top">
                                                {representative.user.first} {representative.user.last}
                                            </p>
                                            <p>{exhibitor.company} representative</p>
                                        </div>
                                    </div>

                                    <div
                                        className={
                                            'connection ' + (this.props.friendConnectionReceived ? null : 'no-action')
                                        }
                                    >
                                        {representative.user._id !== userLoggedIn._id && (
                                            <ChatButton
                                                onClick={this.handleOpenPrivateChat(representative.user._id)}
                                                disabled={this.props.loading}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="exhibitor-representatives__user-wrapper-separator" />
                        </li>
                    );
                })}
            </ul>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        event: state.event.data,
        eventId: state.event.eventId,
        onlineUsers: state.event.onlineUsers,
        privateChats: state.user.data.privateChats,
        loading: state.user.loading,
        userLoggedIn: state.user.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetOnlineUsers: () => dispatch(actions.getOnlineUsers()),
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onCreatePrivateChat: (targetUserId, eventId) => dispatch(actions.createPrivateChat(targetUserId, eventId)),
    };
};

export default withUserStatus(connect(mapStateToProps, mapDispatchToProps)(Representatives));
