import React, {PureComponent} from 'react';

import SingleRepresentative from './components/SingleRepresentative/SingleRepresentative';

import './Info.scss';

export class Info extends PureComponent {
    componentDidMount() {
        this.props.onGetOnlineUsers();
    }

    checkIfIsOnline = (eventUser) => {
        let isOnline = false;
        if (this.props.onlineUsers && this.props.onlineUsers.length) {
            isOnline = this.props.onlineUsers.findIndex((user) => user.userId === eventUser.user._id) > -1;
        }
        return isOnline;
    };

    render() {
        const {event} = this.props;
        return (
            <div className="info-tab">
                <div className="info-tab__wrapper">
                    <div className="info-tab__scroll-container">
                        <ul className="representative-list">
                            {event.representatives.map((user) => {
                                const isOnline = this.checkIfIsOnline(user);
                                return <SingleRepresentative key={user._id} user={user} isOnline={isOnline} />;
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}
