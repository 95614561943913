import React, {useEffect} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {generatePath} from 'react-router';

import EventPrivateComponent from '../EventPrivateComponent';
import FullPageSpinner from '../FullPageSpinner';
import NotApprovedAccount from './components/NotApprovedAccount/NotApprovedAccount';
import {USER_ROLES, USER_STATUSES} from 'constants/shared';
import {RouterConfig} from 'routerConfig';
import {useUserRole, useUnregisteredUser} from 'services/hooks';
import {transformLink} from 'services/utils';

export const PrivateRoute = ({
    component: Component,
    loggedIn,
    loadingUser,
    userRoles,
    userData,
    eventSlug,
    topNavClose,
    setEventIdBySlug,
    ...rest
}) => {
    useEffect(() => {
        if (!userRoles && eventSlug) setEventIdBySlug(eventSlug);
    }, [eventSlug, setEventIdBySlug, userRoles]);

    useUserRole();

    // converve integretion
    const query = new URLSearchParams(rest.location.search);
    const token = query.get('token');
    if (token) {
        localStorage.setItem('token', token);
        query.delete('token');
        const link = rest.location.pathname + '?' + query.toString();
        document.location = link;
    }

    const isUnregisteredUser = useUnregisteredUser();

    return (
        <Route
            {...rest}
            render={(props) => {
                const getEventSlug = eventSlug || props.match.params?.eventSlug;
                const isApprovedOrg = userData?.organizerStatus === USER_STATUSES.approved;

                if ((!loggedIn && !loadingUser) || (loggedIn && !userData)) {
                    return (
                        <Redirect
                            to={{
                                pathname: getEventSlug
                                    ? generatePath(RouterConfig.event.login, {eventSlug: getEventSlug})
                                    : RouterConfig.login,
                                state: {from: props.location.pathname},
                            }}
                        />
                    );
                } else if (isUnregisteredUser) {
                    return (
                        <Redirect
                            to={{
                                pathname: generatePath(RouterConfig.event.homepage, {eventSlug: getEventSlug}),
                                state: {from: props.location.pathname},
                            }}
                        />
                    );
                }

                if (!loggedIn && loadingUser) {
                    return <FullPageSpinner />;
                }

                if (!userRoles) return;

                for (const role of userRoles) {
                    const isNotAdmin =
                        USER_ROLES[role] &&
                        USER_ROLES[role] !== USER_ROLES.masterAdmin &&
                        USER_ROLES[role] !== USER_ROLES.organizer;

                    switch (true) {
                        case isNotAdmin:
                            return (
                                <EventPrivateComponent {...props}>
                                    <Component {...props} />
                                </EventPrivateComponent>
                            );
                        case USER_ROLES[role] && USER_ROLES[role] === USER_ROLES.organizer: {
                            if (transformLink(document.location.pathname) === RouterConfig.createEvent) {
                                return (
                                    <EventPrivateComponent {...props}>
                                        <Component {...props} />
                                    </EventPrivateComponent>
                                );
                            }

                            return (
                                <EventPrivateComponent {...props}>
                                    {!isApprovedOrg ? <NotApprovedAccount {...props} /> : <Component {...props} />}
                                </EventPrivateComponent>
                            );
                        }
                        case USER_ROLES[role] && USER_ROLES[role] === USER_ROLES.masterAdmin:
                            return <Component {...props} />;
                        default: {
                            return <FullPageSpinner />;
                        }
                    }
                }
            }}
        />
    );
};
