import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {requestAgenda} from 'store/actions';
import {getEventId, getUserData} from '../../store/selectors';

const usePersonalAgenda = () => {
    const dispatch = useDispatch();
    const user = useSelector(getUserData);
    const eventId = useSelector(getEventId);
    const userId = user?._id;

    useEffect(() => {
        if (userId && eventId) {
            dispatch(requestAgenda(eventId));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, eventId]);
};

export default usePersonalAgenda;
