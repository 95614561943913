import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    topWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '22px 0 40px 0',
    },
    video: {
        width: 400,
        height: 300,
        borderRadius: '6px',
    },
    bottomWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 15,
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '30px',
        letterSpacing: '0.0015em',
        margin: '0 25px 0 0',
    },
    deviceSelect: {
        width: 180,
        '& > .MuiInputBase-root': {
            //TODO: refactor
            height: '100%',
        },
    },
    selectedValue: {
        color: theme.palette.text.primary,
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
        paddingTop: 0,
        paddingBottom: 0,
    },
    arrowDownIcon: {
        color: theme.palette.text.primary,
    },
    noVideoIcon: {
        width: 150,
        height: 150,
        fill: theme.palette.primary.main,
        color: theme.palette.primary.main,
    },
}));
