import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {useSelector} from 'react-redux';
import {useStyles} from './style';

export default function Spinner() {
    const style = useStyles();
    const loading = useSelector((state) => state.eventListing.loading);

    return (
        <>
            {loading ? (
                <div className={style.spinner}>
                    <CircularProgress />
                </div>
            ) : null}
        </>
    );
}
