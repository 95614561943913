import {Button, Dialog, DialogActions, DialogContent, FormControlLabel} from '@material-ui/core';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Api} from '../../../Api';
import * as actions from '../../../store/actions';
import {DefaultWhiteCheckbox} from '../CustomCheckbox/CustomCheckbox';
import ReactPlayer from 'react-player';
import './WelcomeScreensDialog.scss';
import {usePlatformTranslation} from '../../../services/hooks';
import {eventFolder} from '../../../config';

const WelcomeScreensDialog = ({noteMedia, type, isImg = true}) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(true);
    const [notShowAgain, setNotShowAgain] = useState(false);
    const {_id: eventId} = useSelector((state) => state.event.data);
    const eventDialogNote = `${eventFolder}${eventId}/${noteMedia}`;
    const translation = usePlatformTranslation();

    const handleCheckbox = (e) => {
        setNotShowAgain(e.target.checked);
    };

    const handleSubmit = async () => {
        if (notShowAgain) {
            try {
                await Api.request({
                    method: 'post',
                    url: '/users/toggle-welcome-note',
                    payload: {eventId, type},
                });

                setOpen(false);
                dispatch(actions.refreshUserData());
            } catch (error) {}
        }

        handleClose();
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            className="WelcomeScreensDialog"
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            maxWidth="xl"
        >
            <DialogContent className="WelcomeScreensDialog__content">
                {isImg ? (
                    <img className="WelcomeScreensDialog__image" src={eventDialogNote} alt="preview" />
                ) : (
                    <ReactPlayer url={eventDialogNote} width="100%" height="100%" playing stopOnUnmount={false} />
                )}
            </DialogContent>
            <DialogActions className="WelcomeScreensDialog__actions">
                <div className="WelcomeScreensDialog__info">
                    <p>
                        {`${translation?.welcomeScreens.byProceeding}${
                            type === 'showAuditoriumNote'
                                ? translation?.welcomeScreens.auditorium
                                : translation?.welcomeScreens.exhibition
                        }${translation?.welcomeScreens.area}
                        `}
                    </p>
                    <FormControlLabel
                        control={
                            <DefaultWhiteCheckbox
                                checked={notShowAgain}
                                name="notShowAgain"
                                onClick={handleCheckbox}
                                color="default"
                            />
                        }
                        label={translation?.welcomeScreens.dontShowAgain}
                    />
                </div>

                <Button className="WelcomeScreensDialog__button" autoFocus onClick={handleSubmit}>
                    {translation?.welcomeScreens.enter}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default WelcomeScreensDialog;
