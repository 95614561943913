import React from 'react';
import PropTypes from 'prop-types';
import {CircularProgress} from '@material-ui/core';
import classNames from 'classnames';

import './Spinner.scss';

export const Spinner = ({message, className, messageClassName}) => (
    <div className={classNames('spinner', {[className]: className})}>
        <CircularProgress />
        {message && <p className={messageClassName}>{message}</p>}
    </div>
);

Spinner.propTypes = {
    message: PropTypes.string,
    className: PropTypes.string,
    messageClassName: PropTypes.string,
};
