import * as businessCardActionTypes from '../actionTypes/businessCardActionTypes';
import {Api} from 'Api';
import {deserializeBusinessCard, serializeBusinessCard} from 'services/utils/normalizers';
import {businessCardFormData} from 'services/helpers';

export const startLoad = () => ({
    type: businessCardActionTypes.START_LOAD,
});

export const endLoad = ({businessCard = null, error}) => ({
    type: businessCardActionTypes.END_LOAD,
    payload: {businessCard, error},
});

export const createBusinessCard = ({businessCard}) => ({
    type: businessCardActionTypes.CREATE,
    payload: {businessCard},
});

export const changeBusinessCardField = ({name, value}) => ({
    type: businessCardActionTypes.CHANGE_FIELD,
    payload: {name, value},
});

export const uploadBusinessCardLogo = ({name, src, file}) => ({
    type: businessCardActionTypes.UPLOAD_LOGO,
    payload: {media: {title: name, src}, file},
});

export const resetBusinessCardLogo = ({logo}) => ({
    type: businessCardActionTypes.RESET_LOGO,
    payload: {logo},
});

export const fillUpBusinessCardDataFromAccount = (userData, companyName) => ({
    type: businessCardActionTypes.FILL_UP_DATA_FROM_ACCOUNT,
    payload: {userData, companyName},
});

export const resetBusinessCard = () => ({
    type: businessCardActionTypes.RESET_BUSINESS_CARD,
});

export const setBusinessCardIsFormValid = (isValid) => ({
    type: businessCardActionTypes.SET_IS_FORM_VALID,
    payload: {isValid},
});

export const requestBusinessCard = () => async (dispatch) => {
    dispatch(startLoad());

    try {
        const {businessCard} = await Api.request({
            method: 'get',
            url: `/users/me/businessCard`,
        });
        dispatch(endLoad({businessCard: deserializeBusinessCard(businessCard)}));
    } catch (error) {
        console.warn(error);
        dispatch(endLoad({error}));
    }
};

export const requestBusinessCardUpdating = () => async (dispatch, getState) => {
    dispatch(startLoad());

    try {
        const {
            businessCard: {businessCard},
        } = getState();

        const data = businessCardFormData(serializeBusinessCard(businessCard));

        if (!businessCard.id) {
            const {businessCard} = await Api.request({
                method: 'post',
                url: `/business-cards`,
                payload: data,
            });
            dispatch(endLoad({businessCard: deserializeBusinessCard(businessCard)}));
            return;
        }

        const {newBusinessCard} = await Api.request({
            method: 'put',
            url: `/business-cards/${businessCard.id}`,
            payload: data,
        });
        dispatch(endLoad({businessCard: deserializeBusinessCard(newBusinessCard)}));
    } catch (error) {
        console.warn(error);
        dispatch(endLoad({error}));
    }
};

export const getBusinessCard =
    ({cardId, callback}) =>
    async () => {
        try {
            const {businessCard, businessCardNotes} = await Api.request({
                method: 'get',
                url: `business-cards/users/${cardId}`,
            });

            callback && callback({businessCard, businessCardNotes});
        } catch (error) {}
    };

export const postBusinessCard =
    ({cardId, payload, callback}) =>
    async () => {
        try {
            await Api.request({
                method: 'post',
                url: `business-cards/${cardId}`,
                payload,
            });

            callback && callback();
        } catch (error) {}
    };

export const deleteBusinessCardNote =
    ({cardId, noteId, callback}) =>
    async () => {
        try {
            await Api.request({
                method: 'delete',
                url: `business-cards/${cardId}/notes/${noteId}`,
            });

            callback && callback();
        } catch (error) {}
    };
