import React, {useState} from 'react';
import {makeStyles, MenuItem, Select} from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        height: 40,
    },
}));

const CustomSelect = ({fieldData, changeField}) => {
    const classes = useStyles();
    const [value, setValue] = useState(null);

    const onSelect = (event) => {
        setValue(event.target.value);
        changeField({id: fieldData._id, value: event.target.value});
    };

    return (
        <Select
            className={classes.root}
            onChange={onSelect}
            variant="outlined"
            size="small"
            value={value}
            align="left"
            fullWidth
        >
            {fieldData.options.map((option) => (
                <MenuItem key={option._id} value={option.value}>
                    {option.value}
                </MenuItem>
            ))}
        </Select>
    );
};

export default CustomSelect;
