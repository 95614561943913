import {MyEvents} from './MyEvents';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export default withRouter(
    connect(
        (state) => ({
            user: selectors.getUserData(state),
            eventId: selectors.getEventId(state),
            eventSlug: selectors.getEventSlug(state),
            isLargeScreen: selectors.getIsLargeScreen(state),
            languages: selectors.getLanguages(state),
            isRtlLanguage: selectors.getIsRtlLanguage(state),
        }),
        {
            setEventId: actions.setEventId,
            setEventSlug: actions.setEventSlug,
            onCloseTopNav: actions.topNavClose,
            clearBuildingData: actions.clearBuildingData,
        }
    )(MyEvents)
);
