import {ReactComponent as DafaultLevel} from 'Images/level-low.svg';
import {ReactComponent as MediumLevel} from 'Images/level-medium.svg';
import {ReactComponent as HighLevel} from 'Images/level-high.svg';

export const MATCHING_QUESTION_TYPES = {
    radio: {
        label: 'SINGLE SELECT',
        value: 'radio',
        tooltip: 'Participants chooses one option from the menu',
    },
    checkbox: {
        label: 'MULTI SELECT',
        value: 'checkbox',
        tooltip: 'Participants chooses more than one option from the menu',
    },
};

export const MATCHING_WEIGHT_TYPES = {
    normal: {
        label: 'DEFAULT',
        value: 'normal',
        icon: DafaultLevel,
        tooltip:
            'Use this question importance for standard questions that will equally influence the matching score between participants',
    },
    medium: {
        label: 'MEDIUM',
        value: 'medium',
        icon: MediumLevel,
        tooltip: 'Use this question importance for questions that have a higher importance than default questions',
    },
    high: {
        label: 'HIGH',
        value: 'high',
        icon: HighLevel,
        tooltip:
            'Use this question importance for questions that strictly determine whether participants will match (e.g., used for categories of particpants)',
    },
};

export const MATCHING_LEVELS = {
    low: 'low match',
    medium: 'medium match',
    high: 'high match',
};

export const TABS_INDEXS = {
    initialPage: 0,
    rejectPage: 1,
    questionPage: 2,
    matchedPage: 3,
};

export const DIALOG_TITLE_TEXT = {
    rejectPage: 'Closing matchmaking form',
    matchedPage: 'Here are your top matches',
    matchedPageEmpty: 'Congrats, you made it! We are searching for your best matches',
};

export const MATCHMAKING__SELECT_TYPES = {
    singleSelect: 'Single Select',
    multiSelect: 'Multi select',
};

export const MATCHMAKING__QUESTION_TYPES = {
    required: 'Required',
    optional: 'Optional',
};

export const MATCHING_SKIPPED_KEY = 'matchingBannerClosed';

export const SHOW_TOOLTIP_MATCHING = 'showTooltipMatching';
