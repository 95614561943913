import React from 'react';

import EventAuditoriumImage768 from '../../../Images/backgrounds/auditorium_768.jpg';
import EventAuditoriumImage900 from '../../../Images/backgrounds/auditorium_900.jpg';
import EventAuditoriumImage1080 from '../../../Images/backgrounds/auditorium_1080.jpg';
import EventAuditoriumImage1200 from '../../../Images/backgrounds/auditorium_1200.jpg';
import EventAuditoriumImage1440 from '../../../Images/backgrounds/auditorium_1440.jpg';
import EventAuditoriumImageFull from '../../../Images/backgrounds/auditorium.jpg';
import SCAEventAuditoriumImageFull from '../../../Images/backgrounds/sca-auditorium-background.jpg';

import {IS_SCA_ENV} from '../../../constants/shared';

import './AuditoriumBackgroundImage.scss';

const AuditoriumBackgroundImage = (props) => {
    let AuditoriumImage;
    const {filesUrl, brandingData} = props;
    const customAuditoriumRender = Boolean(
        brandingData && 'auditoriumRender' in brandingData && brandingData.auditoriumRender
    );

    switch (true) {
        case customAuditoriumRender:
            AuditoriumImage = `${filesUrl}${brandingData.auditoriumRender.original}`;
            break;
        case IS_SCA_ENV:
            AuditoriumImage = SCAEventAuditoriumImageFull;
            break;
        case window.innerHeight < 769:
            AuditoriumImage = EventAuditoriumImage768;
            break;
        case window.innerHeight < 901:
            AuditoriumImage = EventAuditoriumImage900;
            break;
        case window.innerHeight < 1081:
            AuditoriumImage = EventAuditoriumImage1080;
            break;
        case window.innerHeight < 1201:
            AuditoriumImage = EventAuditoriumImage1200;
            break;
        case window.innerHeight < 1441:
            AuditoriumImage = EventAuditoriumImage1440;
            break;
        default:
            AuditoriumImage = EventAuditoriumImageFull;
    }

    return <img className="background-render" src={AuditoriumImage} width="6000" height="3000" alt="Auditorium" />;
};

export default AuditoriumBackgroundImage;
