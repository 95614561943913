import React from 'react';

import VideojsPlayer from '../../VideojsPlayer/VideojsPlayer';

const getSettingsBySourceLink = (sourceLink) => ({
    autoplay: true,
    controls: true,
    sources: [
        {
            src: sourceLink,
            type: 'application/x-mpegURL',
        },
    ],
});

const AuditoriumVideojsPlayer = ({sourceLink, interpretationSourceLanguage, withInterpretation}) => (
    <VideojsPlayer
        settings={getSettingsBySourceLink(sourceLink)}
        interpretationSourceLanguage={interpretationSourceLanguage}
        withInterpretation={withInterpretation}
    />
);

export default AuditoriumVideojsPlayer;
