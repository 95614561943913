import React from 'react';
import {useSelector} from 'react-redux';
import {Button, makeStyles} from '@material-ui/core';
import {ValidatorForm} from 'react-material-ui-form-validator';
import {NotificationManager} from 'react-notifications';

import {usePlatformTranslation} from '../../../../../../../services/hooks';
import useFormFields from '../../../../../../../services/hooks/useFormFields';
import SurveyQuestion from '../SurveyQuestion/SurveyQuestion';
import {Api} from '../../../../../../../Api';
import ColoredScrollbars from '../../../../../ColoredScrollbars/ColoredScrollbars';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        background: theme.palette.background.default,
        flexDirection: 'column',
        position: 'relative',
        paddingBottom: 100,
        '&.isRtl': {
            direction: 'rtl',
        },
    },
    content: {
        flexGrow: 1,
    },
    message: {
        fontSize: 16,
    },
    submitBtn: {
        background: theme.palette.primary.main,
        marginTop: '24px',
        fontSize: '16px',
        lineHeight: '20px',
        letterSpacing: '0.0125em',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        color: theme.palette.grey['0'],
        height: '44px',
        borderRadius: '8px',
        '&:hover': {
            background: theme.palette.primary.main,
        },
    },
    rtlScrollbar: {
        '& > div:first-child': {
            marginLeft: '0 !important',
            marginRight: '-19px !important',
        },
        '& > div:last-child': {
            right: 'auto !important',
            left: '2px !important',
        },
    },
}));

const SurveyForm = ({fieldsData, formFields, onPassSurvey}) => {
    const classes = useStyles();
    const {generalText} = usePlatformTranslation();
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);
    const {eventId} = useSelector((state) => state.event);
    const [fields, changeField] = useFormFields(formFields);

    const onSubmit = async () => {
        const answers = fields.map((field) => ({surveyId: field._id, answer: field.value}));

        try {
            await Api.request({method: 'post', url: `/event/${eventId}/survey/answer`, payload: answers});
            onPassSurvey();
        } catch (err) {
            NotificationManager.error('Error on survey submit');
        }
    };

    return (
        <ColoredScrollbars className={isRtlLanguage ? classes.rtlScrollbar : ''}>
            <ValidatorForm className={classNames(classes.form, {isRtl: isRtlLanguage})} onSubmit={onSubmit}>
                {fieldsData.map((data) => (
                    <SurveyQuestion key={data._id} fieldData={data} changeField={changeField} />
                ))}

                <Button
                    className={classes.submitBtn}
                    variant="contained"
                    fullWidth
                    disabled={!fields.every((field) => field.value)}
                    onClick={onSubmit}
                >
                    {generalText.submit}
                </Button>
            </ValidatorForm>
        </ColoredScrollbars>
    );
};

export default SurveyForm;
