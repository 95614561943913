import React, {forwardRef} from 'react';
import {usePlatformTranslation} from '../../../../../../../../../../../../../services/hooks';
import {useStyles} from '../MoreMenu.styles';
import clsx from 'clsx';
import {MenuItem} from '@material-ui/core';
import IconMembers from '../../Icons/IconMembers';

const MenuItemMembers = forwardRef(({onClick, className, ...props}, ref) => {
    const classes = useStyles();
    const translation = usePlatformTranslation();
    return (
        <MenuItem ref={ref} className={clsx(classes.item, className)} onClick={onClick} {...props}>
            <IconMembers />
            <span className={classes.itemText}>{translation?.chatsDropdown.members}</span>
        </MenuItem>
    );
});

export default MenuItemMembers;
