import React from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';

import HighlightRectangleText from '../../../HighlightRectangleText/HighlightRectangleText';

import {ReactComponent as TwitterIconFooter} from '../../../../../Images/twitter-footer-filled.svg';
import {ReactComponent as FacebookIconFooter} from '../../../../../Images/facebook-footer-filled.svg';
import {ReactComponent as LinkedinIconFooter} from '../../../../../Images/linkedin-footer-filled.svg';
import {ReactComponent as InstagramIconFooter} from '../../../../../Images/instagram-footer-filled.svg';
import {ReactComponent as YoutubeIconFooter} from '../../../../../Images/youtube-footer-filled.svg';
import {ReactComponent as SnapchatIconFooter} from '../../../../../Images/snapchat-footer-filled.svg';
import {ReactComponent as TiktokIconFooter} from '../../../../../Images/tiktok-footer-filled.svg';
import {whiteLabel, platformFooterConfig, footerSocialLinksConfig, getInfoLinks} from '../../../../../config';
import classNames from 'classnames';
import {usePlatformTranslation} from '../../../../../services/hooks';

const linksIcons = {
    twitter: <TwitterIconFooter />,
    facebook: <FacebookIconFooter />,
    linkedin: <LinkedinIconFooter />,
    instagram: <InstagramIconFooter />,
    youtube: <YoutubeIconFooter />,
    snapchat: <SnapchatIconFooter />,
    tiktok: <TiktokIconFooter />,
};

export function PlatformFooter() {
    const translation = usePlatformTranslation((state) => state.footer);
    const platformLanguage = useSelector((state) => state.languages.platformLanguage);
    const isHighlightRectangleText = platformFooterConfig[whiteLabel]
        ? platformFooterConfig[whiteLabel].isHighlightRectangleText
        : true;

    const getSocialLinks = footerSocialLinksConfig[whiteLabel]
        ? footerSocialLinksConfig[whiteLabel]
        : footerSocialLinksConfig.main;

    const infoLinks = getInfoLinks(platformLanguage, whiteLabel);

    return (
        <footer className={classNames('platform-footer-wrapper', whiteLabel)}>
            <div className="platform-footer-container">
                <div className="links-container">
                    <div className="social-buttons-wrapper">
                        <ul>
                            {Object.keys(getSocialLinks).map((network) => {
                                return (
                                    <li key={network}>
                                        <a
                                            className={classNames(
                                                'social-buttons-wrapper__link',
                                                `social-buttons-wrapper__link--${whiteLabel}`
                                            )}
                                            href={getSocialLinks[network]}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {linksIcons[network]}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    <div className="inner-links">
                        <ul>
                            <li>
                                <Link className={whiteLabel} to={{pathname: infoLinks.privacyPolicy}}>
                                    {translation.privacyPolicy}
                                </Link>
                            </li>
                            <li>
                                <Link className={whiteLabel} to={{pathname: infoLinks.cookies}}>
                                    {translation.cookies}
                                </Link>
                            </li>
                            <li>
                                <Link className={whiteLabel} to={{pathname: infoLinks.termsAndConditions}}>
                                    {translation.termsAndConditions}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                {isHighlightRectangleText && (
                    <p className="title">
                        <HighlightRectangleText>Events10X brings the Wow</HighlightRectangleText>
                    </p>
                )}
            </div>
        </footer>
    );
}
