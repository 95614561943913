export const wallTabsList = {
    TRANSLATIONS: 'translations',
    RESOURCES: 'resources',
    SURVEY: 'survey',
    INTERPRETER: 'interpreter',
    POLLS: 'polls',
    WALL: 'wall',
    ON_DEMAND: 'onDemand',
    VIDEO: 'video',
};
