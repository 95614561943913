import React from 'react';

import ProgramDropdownList from '../../../../../ProgramDropdownList/ProgramDropdownList';
import AgendaMeetingItem from './components/AgendaMeetingItem/AgendaMeetingItem';

const AgendaMeetingList = ({list, ...rest}) => (
    <ProgramDropdownList>
        {list.map((agenda) => (
            <ProgramDropdownList.Item key={agenda.id}>
                <AgendaMeetingItem {...agenda} {...rest} />
            </ProgramDropdownList.Item>
        ))}
    </ProgramDropdownList>
);

export default AgendaMeetingList;
