import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        margin: '0 auto',
        maxWidth: 1284,
        padding: '0 20px',
    },
    title: {
        fontSize: '42px',
        lineHeight: '42px',
        color: ' #2B2B2B',
    },
    viewAllContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 100,
    },
    viewAll: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '8px 8px 8px 0px',
        padding: '9px 17px',
        color: theme.palette.grey['0'],
        fontWeight: 'bold',
        fontSize: 16,
        letterSpacing: '0.0125em',
        textTransform: 'uppercase',
    },
}));
