import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import AuditoriumVideoPlayer from './AuditoriumVideoPlayer';

export default connect(
    (state) => ({
        translation: selectors.getTranslation(state),
        currentTimeSlot: selectors.getTimeslotData(state),
        interpretation: selectors.getInterpretation(state),
        userId: selectors.getUserId(state),
    }),
    {
        updateTimeSlotData: actions.updateTimeSlotData,
        getTimeslotDataSuccess: actions.getTimeslotDataSuccess,
        setAvailableInterpretationLanguage: actions.setAvailableInterpretationLanguage,
        removeAvailableInterpretationLanguage: actions.removeAvailableInterpretationLanguage,
        setActiveInterpretationLanguage: actions.setActiveInterpretationLanguage,
    }
)(AuditoriumVideoPlayer);
