import React, {useEffect, useState, useMemo} from 'react';
import classNames from 'classnames';
import {IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MatchmakingDialog from './MatchmakingDialog';
import {MATCHING_SKIPPED_KEY} from 'constants/matchMaking';
import {useCompletedMatchmaking} from 'services/hooks';
import './MatchmakingBanner.scss';

const WAIT_TIME_TO_OPEN = 3000;
const WAIT_TIME_TO_CLOSE = 6000;

const MatchmakingBanner = ({eventId, userLoading, onSetCompleteMatching, sideNavigation}) => {
    const isMatchingSkippedEvents = useMemo(() => JSON.parse(localStorage.getItem(MATCHING_SKIPPED_KEY)) || [], []);
    const completeMatching = useCompletedMatchmaking();

    const [isSkipped, setIsSkipped] = useState(isMatchingSkippedEvents.includes(eventId));
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isMatchBannerOpen, setIsMatchingBannerOpen] = useState();

    const handleSkip = () => {
        setIsSkipped(true);
        const newMatchingSkippedEvents = [...isMatchingSkippedEvents, eventId];
        localStorage.setItem(MATCHING_SKIPPED_KEY, JSON.stringify(newMatchingSkippedEvents));
    };

    const handleOpenDialog = () => {
        setIsOpenDialog(true);
    };

    const handleCloseDialog = (successPage) => {
        setIsOpenDialog(false);
        if (successPage) {
            setIsMatchingBannerOpen(false);
            onSetCompleteMatching({eventId, completeMatching: true});
        }
    };

    useEffect(() => {
        if (completeMatching && isMatchBannerOpen) {
            setIsMatchingBannerOpen(false);
        }
    }, [completeMatching, isMatchBannerOpen]);

    useEffect(() => {
        if (isMatchingSkippedEvents.includes(eventId) || completeMatching || userLoading) return;
        const openTimer = setTimeout(() => setIsMatchingBannerOpen(true), WAIT_TIME_TO_OPEN);

        return () => {
            clearTimeout(openTimer);
        };
    }, [isMatchingSkippedEvents, completeMatching, setIsMatchingBannerOpen, eventId, userLoading]);

    useEffect(() => {
        if (!isSkipped) return;

        const closeTimer = setTimeout(() => setIsMatchingBannerOpen(false), WAIT_TIME_TO_CLOSE);

        return () => {
            clearTimeout(closeTimer);
        };
    }, [isSkipped, setIsMatchingBannerOpen]);

    if (!isMatchBannerOpen) return null;

    return (
        <div
            className={classNames(
                'matchmaking-banner',
                {'matchmaking-banner--skipped': isSkipped},
                {'matchmaking-banner--full-width': !sideNavigation.isOpened}
            )}
        >
            {!isSkipped ? (
                <>
                    <div className="matchmaking-banner__info">
                        <IconButton onClick={handleSkip} className="matchmaking-banner__icon-btn">
                            <CloseIcon className="matchmaking-banner__close-icon" />
                        </IconButton>
                        <p className="matchmaking-banner__text">
                            Make a meaningful connection, try out Matching feature!
                        </p>
                    </div>
                    <button onClick={handleOpenDialog} className="matchmaking-banner__open-dialog-btn">
                        Try MATCHMAKING
                    </button>
                </>
            ) : (
                <p className={classNames('matchmaking-banner__text', {'matchmaking-banner__text--skipped': isSkipped})}>
                    You can always fill the Matchmaking form in Networking under Matches
                </p>
            )}
            {isOpenDialog && (
                <MatchmakingDialog open={isOpenDialog} onClose={handleCloseDialog} onOpen={handleOpenDialog} />
            )}
        </div>
    );
};

export default MatchmakingBanner;
