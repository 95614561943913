import * as actionTypes from '../actionTypes/exhibitorJobsActionTypes';
import {updateObject} from '../utilityStateFunction';
import {findJobIndex} from 'services/helpers';

const initialState = {
    loading: false,
    jobsList: [],
    error: false,
    editedJob: null,
    createdJob: null,
    isCreating: false,
    deletedJob: null,
};

const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.APPLY_EXHIBITOR_JOB_START:
        case actionTypes.GET_EXHIBITOR_JOBS_START:
        case actionTypes.DELETE_EXHIBITOR_JOB_START:
        case actionTypes.PUBLISH_EXHIBITOR_JOB_START:
            return updateObject(state, {loading: true});
        case actionTypes.GET_EXHIBITOR_JOBS_SUCCESS:
            return updateObject(state, {
                loading: false,
                error: false,
                jobsList: payload,
            });
        case actionTypes.GET_EXHIBITOR_JOBS_FAIL:
        case actionTypes.APPLY_EXHIBITOR_JOB_FAIL:
        case actionTypes.DELETE_EXHIBITOR_JOB_FAIL:
        case actionTypes.PUBLISH_EXHIBITOR_JOB_FAIL:
            return updateObject(state, {loading: false, error: true});
        case actionTypes.PUBLISH_EXHIBITOR_JOB_SUCCESS:
            return updateObject(state, {
                loading: false,
                error: false,
                jobsList: payload,
                createdJob: null,
            });

        case actionTypes.CREATE_EXHIBITOR_JOB: {
            const {_id} = payload;
            return updateObject(state, {
                editedJob: null,
                createdJob: {title: '', _id},
            });
        }

        case actionTypes.EDIT_EXHIBITOR_JOB: {
            const {job} = payload;
            return updateObject(state, {editedJob: job, createdJob: null});
        }

        case actionTypes.DELETE_EXHIBITOR_JOB_SUCCESS: {
            const {job} = payload;
            let {jobList} = state;
            const index = findJobIndex(jobList, job);

            jobList = [...jobList.slice(0, index), ...jobList.slice(index + 1)];
            return updateObject(state, {
                jobList,
                deletedJob: null,
            });
        }

        case actionTypes.RESET_EXHIBITOR_ACTIVE_JOB: {
            return updateObject(state, {createdJob: null, editedJob: null});
        }

        case actionTypes.SET_EXHIBITOR_DELETED_JOB: {
            const {job} = payload;
            return updateObject(state, {deletedJob: job});
        }

        case actionTypes.RESET_EXHIBITOR_DELETED_JOB: {
            return updateObject(state, {deletedJob: null});
        }

        default:
            return state;
    }
};

export default reducer;
