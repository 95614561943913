import React from 'react';
import classNames from 'classnames';
import {useSelector} from 'react-redux';

import Button from '../../../../../../../../../Button/Button';
import {IconCircledPlus, IconCircledCheck} from '../../../../../../../../../Icons';

import {useMedia, usePlatformTranslation} from '../../../../../../../../../../../services/hooks';

import './AuditoriumProgramTimeslot.scss';

const getButtonTitle = (isAdded, isMobile, {toAgenda, attending}) => {
    if (isMobile) {
        return '';
    }

    return isAdded ? attending : toAgenda;
};

const AuditoriumProgramTimeslot = ({speakersString, title, startTime, endTime, isAdded, onAdd}) => {
    const isMobile = useMedia('(max-width: 640px)');
    const isLargeScreen = useSelector((state) => state.layout.isLargeScreen);
    const {program} = usePlatformTranslation();
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);

    return (
        <div
            className={classNames('AuditoriumProgramTimeslot', {
                AuditoriumProgramTimeslot_notLargeScreen: !isLargeScreen,
                isRtl: isRtlLanguage,
            })}
        >
            <div className="AuditoriumProgramTimeslot__titleGroup">
                {speakersString && <div className="AuditoriumProgramTimeslot__speakers">{speakersString} with </div>}

                <div className="AuditoriumProgramTimeslot__title">{title}</div>
            </div>

            <div className="AuditoriumProgramTimeslot__actionGroup">
                <div className="AuditoriumProgramTimeslot__timeGroup">
                    <div className="AuditoriumProgramTimeslot__timeItem">{startTime}-</div>
                    <div className="AuditoriumProgramTimeslot__timeItem">{endTime}</div>
                </div>

                <div
                    className={classNames('AuditoriumProgramTimeslot__action', {
                        AuditoriumProgramTimeslot__action_disabled: isAdded,
                    })}
                >
                    <Button
                        title={getButtonTitle(isAdded, isMobile, program)}
                        icon={isAdded ? <IconCircledCheck /> : <IconCircledPlus />}
                        isDisabled={isAdded}
                        onClick={onAdd}
                    />
                </div>
            </div>
        </div>
    );
};

export default AuditoriumProgramTimeslot;
