import React from 'react';
import cn from 'classnames';
import {Link} from 'react-router-dom';

import IconContainer from '../../../../../../../IconContainer/IconContainer';
import {IconBusinessCard} from '../../../../../../../Icons';

import {getBusinessCardLink} from '../../../../../../../../../routes';
import {usePlatformTranslation} from '../../../../../../../../../services/hooks';
import './MyAccountDropdownLink.scss';

const MyAccountDropdownLink = ({title, icon, link, isDesktop, onClick}) => (
    <div className={cn('MyAccountDropdownLink', {MyAccountDropdownLink_notDesktop: !isDesktop})}>
        <Link className="MyAccountDropdownLink__element" to={link} onClick={onClick}>
            <span className="MyAccountDropdownLink__icon">
                <IconContainer>{icon}</IconContainer>
            </span>

            <span className="MyAccountDropdownLink__title">{title}</span>
        </Link>
    </div>
);

export const BusinessCardDropdownLink = ({eventSlug, ...rest}) => {
    const {businessCard} = usePlatformTranslation();

    return (
        <MyAccountDropdownLink
            title={businessCard.businessCard.default}
            icon={<IconBusinessCard />}
            link={getBusinessCardLink(eventSlug)}
            {...rest}
        />
    );
};

export default MyAccountDropdownLink;
