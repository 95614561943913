import {CUSTOM_FIELD_TYPES, USER_ROLES} from 'constants/shared';

export const prepareApplicantsForTable = (applicantsList) => {
    return applicantsList.map((applicant) => {
        const {_id, participant, userName, jobTitle, createdAt, status, rating} = applicant;

        return {
            _id,
            name: userName,
            email: participant.user.email,
            jobTitle,
            applicationDate: createdAt,
            status,
            rating: rating || 0,

            ...participant.customFields
                .map((field) => ({
                    [field.label]: field.values.lenght ? field.values.join(',') : field.value,
                }))
                .reduce((obj, item) => {
                    return {...obj, ...item};
                }, {}),
        };
    });
};

export const statusesValue = {
    new_application: 'new_application',
    under_review: 'under_review',
    short_listed: 'short_listed',
    rejected: 'rejected',
};

export const statuses = [
    {value: statusesValue.new_application, key: 'New Application'},
    {value: statusesValue.under_review, key: 'Under Review'},
    {value: statusesValue.short_listed, key: 'Short Listed'},
    {value: statusesValue.rejected, key: 'Rejected'},
];

export const prepareFileList = (userDetails, accessManagement) => {
    const defaultReturn = {documentList: [], userId: undefined};

    if (!userDetails) return defaultReturn;
    const eventRole = userDetails.user.eventRoles.find((role) => role.event === userDetails.event);

    const registrationForm = eventRole.roles.includes(USER_ROLES.participant)
        ? accessManagement.participantsGroups.find(({_id}) => _id === eventRole.registrationForm)
        : accessManagement.exhibitorsGroups.find(({_id}) => _id === eventRole.registrationForm);

    if (!registrationForm) return defaultReturn;

    const fileFields = registrationForm.customFields.reduce((acc, field) => {
        if (field.type === CUSTOM_FIELD_TYPES.file) {
            acc.push(field._id);
        }
        return acc;
    }, []);

    return {
        documentList: userDetails?.customFields.filter((field) => fileFields.includes(field.customFieldId)),
        userId: userDetails?.user._id,
    };
};
