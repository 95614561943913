import React from 'react';
import PropTypes from 'prop-types';

import {ReactComponent as TooltipArrow} from 'Images/tooltip-arrow.svg';
import {usePlatformTranslation} from 'services/hooks';
import './StartVideoChatTooltip.scss';

const StartVideoChatTooltip = ({newMessage, isUserOffline}) => {
    const {videoConferences} = usePlatformTranslation();
    if (newMessage) return null;

    const message = isUserOffline ? 'User is offline now' : videoConferences.clickToStart;

    return (
        <div className="tooltip-container">
            <div className="tooltip">
                <p>{message}</p>
                <div className="triangle">
                    <TooltipArrow />
                </div>
            </div>
        </div>
    );
};

StartVideoChatTooltip.propTypes = {
    newMessage: PropTypes.string,
    isUserOffline: PropTypes.bool,
};

export default StartVideoChatTooltip;
