import React, {Component} from 'react';

import RectangleWhite from '../../../../../../../Images/pages/platform-homepage/animations/rectangle-white.png';

class WhiteRectangleText extends Component {
    render() {
        return (
            <span style={{backgroundImage: `url(${RectangleWhite})`}} className="white-rectangle-background">
                {this.props.children}
            </span>
        );
    }
}

export default WhiteRectangleText;
