import {ReactComponent as BoothStyleDefaultIcon} from '../../Images/paint-palette-grey.svg';
import {ReactComponent as BoothStyleIcon} from '../../Images/paint-palette.svg';
import {ReactComponent as BannerDefaultIcon} from '../../Images/trifolded-map.svg';
import {ReactComponent as BannerIcon} from '../../Images/trifolded-map-green.svg';
import {ReactComponent as FilesDefaultIcon} from '../../Images/folder.svg';
import {ReactComponent as FilesIcon} from '../../Images/folder-green.svg';
import {ReactComponent as LinksDefaultIcon} from '../../Images/link.svg';
import {ReactComponent as LinksIcon} from '../../Images/link-green.svg';
import {ReactComponent as AvatarsDefaultIcon} from '../../Images/user.svg';
import {ReactComponent as AvatarsIcon} from '../../Images/user-green.svg';
import {ReactComponent as VideoDefaultIcon} from '../../Images/video.svg';
import {ReactComponent as VideoIcon} from '../../Images/video-green.svg';
import {ReactComponent as MarketplaceDefaultIcon} from '../../Images/marketplace-default.svg';
import {ReactComponent as MarketplaceIcon} from '../../Images/marketplace.svg';

import {MANAGE_BOOTH_BUTTON_TYPES, IS_CAREERS_ENV} from '../../constants/shared';

export const getManageBoothButtons = (translation) => [
    {
        type: MANAGE_BOOTH_BUTTON_TYPES.style,
        label: translation?.exhibitorDashboard.tabStyle,
        img: BoothStyleDefaultIcon,
        activeImg: BoothStyleIcon,
    },
    {
        type: MANAGE_BOOTH_BUTTON_TYPES.banners,
        label: translation?.exhibitorDashboard.tabLogo,
        img: BannerDefaultIcon,
        activeImg: BannerIcon,
    },
    {
        type: MANAGE_BOOTH_BUTTON_TYPES.files,
        label: translation?.exhibitorDashboard.tabFiles,
        img: FilesDefaultIcon,
        activeImg: FilesIcon,
    },
    {
        type: MANAGE_BOOTH_BUTTON_TYPES.links,
        label: translation?.exhibitorDashboard.tabLinks,
        img: LinksDefaultIcon,
        activeImg: LinksIcon,
    },
    {
        type: MANAGE_BOOTH_BUTTON_TYPES.representatives,
        label: translation?.exhibitorDashboard.tabAvatars,
        img: AvatarsDefaultIcon,
        activeImg: AvatarsIcon,
    },
    {
        type: MANAGE_BOOTH_BUTTON_TYPES.video,
        label: translation?.exhibitorDashboard.tabVideo,
        img: VideoDefaultIcon,
        activeImg: VideoIcon,
    },
    {
        type: IS_CAREERS_ENV ? MANAGE_BOOTH_BUTTON_TYPES.jobs : MANAGE_BOOTH_BUTTON_TYPES.marketplace,
        label: IS_CAREERS_ENV ? translation?.exhibitorDashboard.tabJobs : translation?.marketplace.title.default,
        img: MarketplaceDefaultIcon,
        activeImg: MarketplaceIcon,
    },
];
