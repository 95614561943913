import * as actionTypes from '../../../actionTypes/organizerActionTypes/eventSetup';
import {initialState} from '../initialState';
import {updateObject} from '../../../utilityStateFunction';

const eventSetup = (state, {type, payload}) => {
    if (state === undefined) {
        return initialState.eventSetup;
    }
    switch (type) {
        case actionTypes.UPLOAD_CUSTOM_LOGO_REQUEST:
            return updateObject(state.eventSetup, {
                uploadCustomLogoLoading: true,
            });
        case actionTypes.UPLOAD_CUSTOM_LOGO_SUCCESS:
            return updateObject(state.eventSetup, {
                uploadCustomLogoLoading: false,
            });
        case actionTypes.UPLOAD_CUSTOM_LOGO_FAILED:
            return updateObject(state.eventSetup, {
                uploadCustomLogoLoading: false,
            });
        case actionTypes.UPLOAD_WELCOME_VIDEO_REQUEST:
            return updateObject(state.eventSetup, {
                uploadWelcomeVideoLoading: true,
            });
        case actionTypes.UPLOAD_WELCOME_VIDEO_SUCCESS:
            return updateObject(state.eventSetup, {
                uploadWelcomeVideoLoading: false,
            });
        case actionTypes.UPLOAD_WELCOME_VIDEO_FAILED:
            return updateObject(state.eventSetup, {
                uploadWelcomeVideoLoading: false,
            });
        case actionTypes.UPLOAD_AUDITORIUM_NOTE_REQUEST:
            return updateObject(state.eventSetup, {
                uploadAuditoriumNoteLoading: true,
            });
        case actionTypes.UPLOAD_AUDITORIUM_NOTE_SUCCESS:
            return updateObject(state.eventSetup, {
                uploadAuditoriumNoteLoading: false,
            });
        case actionTypes.UPLOAD_AUDITORIUM_NOTE_FAILED:
            return updateObject(state.eventSetup, {
                uploadAuditoriumNoteLoading: false,
            });
        case actionTypes.UPLOAD_EXHIBITORS_NOTE_REQUEST:
            return updateObject(state.eventSetup, {
                uploadExhibitorsNoteLoading: true,
            });
        case actionTypes.UPLOAD_EXHIBITORS_NOTE_SUCCESS:
            return updateObject(state.eventSetup, {
                uploadExhibitorsNoteLoading: false,
            });
        case actionTypes.UPLOAD_EXHIBITORS_NOTE_FAILED:
            return updateObject(state.eventSetup, {
                uploadExhibitorsNoteLoading: false,
            });

        case actionTypes.UPDATE_CLOSE_MESSAGE_START:
            return updateObject(state.eventSetup, {
                closeMessageLoading: true,
            });
        case actionTypes.UPDATE_CLOSE_MESSAGE_SUCCESS:
            return updateObject(state.eventSetup, {
                closeMessageLoading: false,
            });

        case actionTypes.UPDATE_CLOSE_MESSAGE_FAIL:
            return updateObject(state.eventSetup, {
                closeMessageLoading: false,
            });

        // CO_ORGANIZER
        case actionTypes.GET_CO_ORGANIZER_LIST_REQUEST:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: true,
                },
            });
        case actionTypes.GET_CO_ORGANIZER_LIST_SUCCESS:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    coOrganizersList: payload,
                    loading: false,
                },
            });
        case actionTypes.GET_CO_ORGANIZER_LIST_FAILED:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    coOrganizersList: null,
                    loading: false,
                },
            });

        case actionTypes.GET_CO_ORGANIZER_SHORT_LIST_REQUEST:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: true,
                },
            });
        case actionTypes.GET_CO_ORGANIZER_SHORT_LIST_SUCCESS:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    coOrganizersShortList: payload,
                    loading: false,
                },
            });
        case actionTypes.GET_CO_ORGANIZER_SHORT_LIST_FAILED:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    coOrganizersShortList: null,
                    loading: false,
                },
            });

        case actionTypes.TOGGLE_CO_ORGANIZER_ACCESS_REQUEST:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: true,
                },
            });
        case actionTypes.TOGGLE_CO_ORGANIZER_ACCESS_SUCCESS:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: false,
                },
            });
        case actionTypes.TOGGLE_CO_ORGANIZER_ACCESS_FAILED:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: false,
                },
            });

        case actionTypes.DELETE_CO_ORGANIZER_REQUEST:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: true,
                },
            });
        case actionTypes.DELETE_CO_ORGANIZER_SUCCESS:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: false,
                },
            });
        case actionTypes.DELETE_CO_ORGANIZER_FAILED:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: false,
                },
            });

        case actionTypes.TOGGLE_CO_ORGANIZER_SUSPEND_REQUEST:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: true,
                },
            });
        case actionTypes.TOGGLE_CO_ORGANIZER_SUSPEND_SUCCESS:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: false,
                },
            });
        case actionTypes.TOGGLE_CO_ORGANIZER_SUSPEND_FAILED:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: false,
                },
            });

        case actionTypes.ADD_CO_ORGANIZER_REQUEST:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: true,
                },
            });

        case actionTypes.ADD_CO_ORGANIZER_SUCCESS:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    coOrganizerIsAdded: payload,
                    loading: false,
                },
            });

        case actionTypes.ADD_CO_ORGANIZER_FAILED:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    coOrganizerIsAdded: 'FAILED',
                    loading: false,
                },
            });
        case actionTypes.ADD_EVENT_REPRESENTATIVE_START:
        case actionTypes.REMOVE_EVENT_REPRESENTATIVE_START:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: true,
                    error: null,
                },
            });
        case actionTypes.ADD_EVENT_REPRESENTATIVE_SUCCESS:
        case actionTypes.REMOVE_EVENT_REPRESENTATIVE_SUCCESS:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: false,
                    error: null,
                },
            });
        case actionTypes.ADD_EVENT_REPRESENTATIVE_FAIL:
        case actionTypes.REMOVE_EVENT_REPRESENTATIVE_FAIL:
            return updateObject(state.eventSetup, {
                team: {
                    ...state.eventSetup.team,
                    loading: false,
                    error: payload,
                },
            });
        default:
            return state.eventSetup;
    }
};

export default eventSetup;
