import React from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import {Switch} from '@material-ui/core';

const ColorSwitch = withStyles((theme) => ({
    switchBase: {
        color: theme.palette.text.primary,
    },
}))(Switch);

const PrimaryColorSwitch = (props) => {
    return <ColorSwitch color="primary" {...props} />;
};
export default PrimaryColorSwitch;
