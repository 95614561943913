import React, {Fragment, useState} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useStyles} from './VideoAvatar.styles';

export const VideoAvatar = ({member, isMinified}) => {
    const classes = useStyles();
    const [imgLoaded, setImgLoaded] = useState(false);

    const getAvatarSrc = () => {
        if (member.avatarSmall && member.avatarSmall !== 'default_avatar.png')
            return process.env.REACT_APP_AVATAR_FOLDER + member.avatarSmall;
        return member.avatarUrl;
    };

    return (
        <div className={classNames(classes.avatar, {[classes.minified]: isMinified})}>
            {!!member && (
                <Fragment>
                    {!imgLoaded &&
                        `${member.first && !!member.first.length ? member.first[0] : ''}${
                            member.last && !!member.last.length ? member.last[0] : ''
                        }`.toUpperCase()}

                    <img
                        alt="avatar"
                        className={classNames(classes.image, {[classes.hidden]: !imgLoaded})}
                        onLoad={() => setImgLoaded(true)}
                        src={getAvatarSrc()}
                    />
                </Fragment>
            )}
        </div>
    );
};

VideoAvatar.propTypes = {
    member: PropTypes.object,
    isMinified: PropTypes.bool,
};
