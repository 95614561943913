export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties,
    };
};

export const sliceHelper = (arr, itemIndex, value) => {
    return [...arr.slice(0, itemIndex), value, ...arr.slice(itemIndex + 1)];
};
