import React, {useContext} from 'react';
import {useSelector} from 'react-redux';

import VideoStream from './components/VideoStream';
import AudioStream from './components/AudioStream';
import ErrorMessage from './components/ErrorMessage';

import {AbilityContext} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';
import {getMediaCaptureError} from 'store/selectors';

import {useStyles} from './DevicesControls.styles';

export function DevicesControls() {
    const classes = useStyles();
    const ability = useContext(AbilityContext);
    const isMediaCaptureError = useSelector(getMediaCaptureError);

    const isInterpreterAccess = ability.can(
        ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY,
        ROLE_FEATURES_TYPES.INTERPRETER_DEVICES_CHECK
    );

    return (
        <div className={classes.wrapper}>
            {!isInterpreterAccess && <VideoStream />}
            <AudioStream />
            {isMediaCaptureError && <ErrorMessage />}
        </div>
    );
}
