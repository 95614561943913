export const TURN_ON_SPINNER = 'EVENT_LISTING/TURN_ON_SPINNER';

export const TOGGLE_EVENT_LISTING_SUCCESS = 'EVENT_LISTING/TOGGLE_EVENT_LISTING_SUCCESS';
export const TOGGLE_EVENT_LISTING_FAIL = 'EVENT_LISTING/TOGGLE_EVENT_LISTING_FAIL';

export const UPDATE_EVENT_LISTING_DATA_SUCCESS = 'EVENT_LISTING/UPDATE_EVENT_LISTING_DATA_SUCCESS';
export const UPDATE_EVENT_LISTING_DATA_FAIL = 'EVENT_LISTING/UPDATE_EVENT_LISTING_DATA_FAIL';

export const SAVE_EVENT_TO_PERSONAL_LIST_SUCCESS = 'EVENT_LISTING/SAVE_EVENT_TO_PERSONAL_LIST_SUCCESS';
export const SAVE_EVENT_TO_PERSONAL_LIST_FAIL = 'EVENT_LISTING/SAVE_EVENT_TO_PERSONAL_LIST_FAIL';

export const DISCARD_EVENT_FROM_PERSONAL_LIST_SUCCESS = 'EVENT_LISTING/DISCARD_EVENT_FROM_PERSONAL_LIST_SUCCESS';
export const DISCARD_EVENT_FROM_PERSONAL_LIST_FAIL = 'EVENT_LISTING/DISCARD_EVENT_FROM_PERSONAL_LIST_FAIL';

export const GET_ALL_LISTED_EVENTS_SUCCESS = 'EVENT_LISTING/GET_ALL_LISTED_EVENTS_SUCCESS';
export const GET_ALL_LISTED_EVENTS_FAIL = 'EVENT_LISTING/GET_ALL_LISTED_EVENTS_FAIL';

export const GET_SAVED_EVENTS_LIST_SUCCESS = 'EVENT_LISTING/GET_SAVED_EVENTS_LIST_SUCCESS';
export const GET_SAVED_EVENTS_LIST_FAIL = 'EVENT_LISTING/GET_SAVED_EVENTS_LIST_FAIL';

export const GET_FIRST_FUTURE_EVENTS_FOR_HOME_PAGE_SUCCESS =
    'EVENT_LISTING/GET_FIRST_FUTURE_EVENTS_FOR_HOME_PAGE_SUCCESS';
export const GET_FIRST_FUTURE_EVENTS_FOR_HOME_PAGE_FAIL = 'EVENT_LISTING/GET_FIRST_FUTURE_EVENTS_FOR_HOME_PAGE_FAIL';

export const GET_EVENTS_LISTING_SUCCESS = 'GET_EVENTS_LISTING_SUCCESS';
export const GET_EVENTS_LISTING_FAIL = 'GET_EVENTS_LISTING_FAIL';

export const SET_EVENTS_LISTING_FILTERS = 'SET_EVENTS_LISTING_FILTERS';
export const SET_EVENTS_LISTING_SEARCH = 'SET_EVENTS_LISTING_SEARCH';
export const TOGGLE_SHOW_PAST_EVENTS = 'TOGGLE_SHOW_PAST_EVENTS';

export const PRE_SAVE_EVENTS = 'EVENT_LISTING/PRE_SAVE_EVENTS';

export const RESET_EVENT_LISTING_PARAMS = 'RESET_EVENT_LISTING_PARAMS';
