import {makeStyles, TextField} from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import {TextValidator} from 'react-material-ui-form-validator';
import {useSelector} from 'react-redux';
import {getIsRtlLanguage} from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    root: {
        '&.isRtl': {
            direction: 'rtl',
            '& > label': {
                left: 'unset',
                right: '25px',
                '&.MuiInputLabel-shrink': {
                    right: '8px',
                },
            },
            '& > .MuiInputBase-root': {
                '& > fieldset': {
                    transform: 'scale(-1, 1) !important',
                    '& > legend': {
                        direction: 'ltr',
                    },
                },
            },
        },
    },
}));

const CustomTextValidator = ({isTextField = false, withRtl, className, ...props}) => {
    const classes = useStyles();
    const isRtlLanguage = useSelector(getIsRtlLanguage);

    return isTextField ? (
        <TextField className={classNames(classes.root, {isRtl: withRtl && isRtlLanguage}, className)} {...props} />
    ) : (
        <TextValidator className={classNames(classes.root, {isRtl: withRtl && isRtlLanguage}, className)} {...props} />
    );
};

export default CustomTextValidator;
