import React from 'react';
import PropTypes from 'prop-types';
import {DialogActions, DialogContent} from '@material-ui/core';
import {CustomDialog, CustomDialogButton, CustomDialogContentText, CustomDialogTitle} from '../CustomDialogComponents';

const InformDialog = ({open, dialogTitle, dialogDescription, onClose, dialogButtonLabel, classes}) => {
    return (
        <CustomDialog className={classes.dialog} open={open} onClose={onClose}>
            <CustomDialogTitle className={classes.title}>{dialogTitle}</CustomDialogTitle>
            <DialogContent classes={{root: classes.content}}>
                <CustomDialogContentText className={classes.contentText}>{dialogDescription}</CustomDialogContentText>
            </DialogContent>
            <DialogActions classes={{root: classes.actions}}>
                <CustomDialogButton className={classes.button} onClick={onClose}>
                    {dialogButtonLabel}
                </CustomDialogButton>
            </DialogActions>
        </CustomDialog>
    );
};

InformDialog.propTypes = {
    open: PropTypes.bool,
    dialogTitle: PropTypes.string,
    dialogDescription: PropTypes.string,
    onClose: PropTypes.func,
    dialogButtonLabel: PropTypes.string,
    classes: PropTypes.shape({
        dialog: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        contentText: PropTypes.string,
        actions: PropTypes.string,
        button: PropTypes.string,
    }),
};

InformDialog.defaultProps = {
    classes: {
        dialog: '',
        title: '',
        content: '',
        contentText: '',
        actions: '',
        button: '',
    },
};

export default InformDialog;
