import React from 'react';

const InterpreterMenuIcon = ({color}) => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M17.7008 11H16.4008L12.3008 22H13.2008L14.3008 19H19.7008L20.8008 22H21.7008L17.7008 11ZM14.7008 18L17.0008 11.7L19.3008 18H14.7008Z"
            fill={color ? color : '#D62E2F'}
        />
        <path
            d="M9.1 13.1004L9.5 12.7004C11.2 10.8004 12.4 8.70039 13.1 6.30039L13.2 5.90039H16V4.90039H9V2.90039H8V4.90039H1V5.90039H12.4L12.2 6.60039C11.5 8.60039 10.4 10.5004 8.9 12.1004L8.5 12.6004L8.1 12.2004C7.2 11.2004 6.5 10.2004 5.9 9.00039H5C5.7 10.3004 6.5 11.6004 7.5 12.7004L7.8 13.1004L2.7 18.1004L3.5 18.8004L8.5 13.8004L11.4 16.7004L11.8 15.7004L9.1 13.1004Z"
            fill={color ? color : '#D62E2F'}
        />
    </svg>
);

export default InterpreterMenuIcon;
