import {isSafari, isMobile} from 'react-device-detect';

export const openUrlInNewTab = (url) => {
    if (isSafari && !isMobile) {
        let button = document.createElement('button');
        button.style = 'display: none';
        button.onclick = () => {
            window.open(url, '_blank');
        };
        document.body.appendChild(button);
        //TODO: for safari >13v
        setTimeout(() => {
            button.click();
            document.body.removeChild(button);
        }, 1500);
    } else {
        window.open(url, '_blank');
    }
};
