import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../../store/actions';
import {
    getEventId,
    getEventLoading,
    getIsEventNotFound,
    getIsUserLoggedIn,
    getLanguages,
    getTranslation,
    getUserData,
} from '../../../store/selectors';
import PlatformMenu from './PlatformMenu';

export default withRouter(
    connect(
        (state) => ({
            eventNotFound: getIsEventNotFound(state),
            eventLoading: getEventLoading(state),
            loggedIn: getIsUserLoggedIn(state),
            user: getUserData(state),
            eventId: getEventId(state),
            languages: getLanguages(state),
            translation: getTranslation(state),
        }),
        {
            onLogoutUser: actions.logoutUser,
            setEventIdBySlug: actions.setEventIdBySlug,
        }
    )(PlatformMenu)
);
