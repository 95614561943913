import Menu from './Menu';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../../store/actions';

export default withRouter(
    connect(
        (state) => ({
            loggedIn: state.user.loggedIn,
            user: state.user.data,
            layout: state.layout,
            eventId: state.event.eventId,
            userConnectedToNotifications: state.user.connectedToNotifications,
            activeChatRoomId: state.user.topNavigation.activeChatRoomId,
            event: state.event.data,
            building: state.building.data,
            buildingId: state.building.buildingId,
        }),
        {
            onSetUserConnectedToNotifications: actions.setUserConnectedToNotifications,
            onNewNotification: actions.newNotification,
            onFlashNotificationReceived: actions.newFlashNotification,
            newPrivateChatMessage: actions.newPrivateChatMessage,
            newGroupChatMessage: actions.newGroupChatMessage,
            onGetPrivateChats: actions.getPrivateChats,
            onGetGroupChats: actions.getGroupChats,
            onGetEvent: actions.getEvent,
            onGetExhibitors: actions.getExhibitors,
            onCloseTopNav: actions.topNavClose,
            onRefreshUserUserData: actions.refreshUserData,
            onLogoutUser: actions.logoutUser,
            resetEventPaymentLoading: actions.resetEventPaymentLoading,
            onGetBuildingExhibitors: actions.getBuildingExhibitors,
            onUpdateEventData: actions.updateEventData,
        }
    )(Menu)
);
