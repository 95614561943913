import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import {eventFolder} from 'config';
import {socialShareLink} from 'constants/socialShareLink';

function SocialsPromotion({promotion, eventId}) {
    if (!promotion || !eventId) {
        return null;
    }

    const {caption, image, slug} = promotion;

    const imageUrl = image ? `${eventFolder}${eventId}/${image}` : '';

    return (
        <>
            <Helmet
                encodeSpecialCharacters={false}
                defer={true}
                meta={[
                    {
                        name: 'description',
                        content: caption,
                    },
                    {
                        itemprop: 'name',
                        content: slug,
                    },
                    {
                        itemprop: 'description',
                        content: caption,
                    },
                    {
                        itemprop: 'image',
                        content: imageUrl,
                    },
                    {
                        name: 'twitter:card',
                        content: 'summary_large_image',
                    },
                    {
                        nam: 'twitter:site',
                        content: '@publisher_handle',
                    },
                    {
                        name: 'twitter:title',
                        content: slug,
                    },
                    {
                        name: 'twitter:description',
                        content: caption,
                    },
                    {
                        name: 'twitter:creator',
                        content: '@author_handle',
                    },
                    {
                        name: 'twitter:image:src',
                        content: imageUrl,
                    },
                    {
                        property: 'og:title',
                        content: slug,
                    },
                    {
                        property: 'og:type',
                        content: 'article',
                    },
                    {
                        property: 'og:url',
                        content: socialShareLink({eventId, eventSlug: slug}),
                    },
                    {
                        property: 'og:image',
                        content: imageUrl,
                    },
                    {
                        property: 'og:description',
                        content: caption,
                    },
                    {
                        property: 'og:site_name',
                        content: slug,
                    },
                ]}
            ></Helmet>
        </>
    );
}

SocialsPromotion.propTypes = {
    promotion: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
    promotionError: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
    getSocialsPromotion: PropTypes.func,
};

export default SocialsPromotion;
