import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import SideMenuMobile from './SideMenuMobile';

export default connect(
    (state) => ({
        event: selectors.getEventData(state),
        eventId: selectors.getEventId(state),
        sideNavigation: selectors.getSideNavigation(state),
        sideNavigationDetails: selectors.getSideNavigationDetails(state),
        resourcesAccess: selectors.getResourcesAccess(state),
        languages: selectors.getLanguages(state),
    }),
    {
        seeNetworking: actions.sideNavSeeNetworking,
        seeLiveWall: actions.sideNavSeeLiveWall,
        seeVideoWall: actions.sideNavSeeVideoWall,
        closeSidebar: actions.sideNavClose,
        setActiveWall: actions.setActiveWall,
        seeInfoTab: actions.sideNavSeeInfoTab,
    }
)(SideMenuMobile);
