import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {FullscreenExit as MinimizeScreenIcon} from '@material-ui/icons';

import OneToOneCallRoom from './components/OneToOneCallRoom';
import VideoChatPlayer from './components/VideoChatPlayer';
import VideoConferenceSpinner from './components/VideoConferenceSpinner/VideoConferenceSpinner';

import {ReactComponent as FullscreenIcon} from 'Images/fullscreen.svg';
import {usePlatformTranslation} from 'services/hooks';
import {getActiveCallTypeData, getVideoConferenceActiveType} from 'store/selectors';
import {VIDEO_CONFERENCE_TYPES} from 'constants/shared';

import './VideoConference.scss';

export const VideoConference = ({members, hidden}) => {
    const videoConference = useSelector(getActiveCallTypeData);
    const activeCallType = useSelector(getVideoConferenceActiveType);
    const [fullScreenVideoConference, setFullScreenVideoConference] = useState(false);
    const {videoConferences} = usePlatformTranslation();
    const videoRoom = {
        [VIDEO_CONFERENCE_TYPES.oneToOne]: (
            <OneToOneCallRoom participant={members[0]} isFullScreen={fullScreenVideoConference} />
        ),
        [VIDEO_CONFERENCE_TYPES.group]: <VideoChatPlayer members={members} />,
    };

    return (
        <div className={classNames('video-conference-container', {fullscreen: fullScreenVideoConference, hidden})}>
            <div className="position-relative">
                {!videoConference.isLoading && videoRoom[activeCallType]}
                {videoConference.isLoading && <VideoConferenceSpinner />}
                <div
                    className="conference-buttons-container"
                    onClick={() => setFullScreenVideoConference(!fullScreenVideoConference)}
                >
                    <button className="toggle-fullscreen">
                        {fullScreenVideoConference && (
                            <div>
                                <MinimizeScreenIcon />
                                <span>{videoConferences.minimizeScreen}</span>
                            </div>
                        )}
                        {!fullScreenVideoConference && (
                            <div>
                                <FullscreenIcon />
                                <span>{videoConferences.fullScreen}</span>
                            </div>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

VideoConference.propTypes = {
    members: PropTypes.arrayOf(PropTypes.object).isRequired,
    hidden: PropTypes.bool,
};
