import React from 'react';
import classNames from 'classnames';

import {Cancel as CancelIcon} from '@material-ui/icons';
import SocialLinks from 'Routes/components/SocialLinks/SocialLinks';

import {getPrivateChatId} from 'services/utils';
import AcceptRequestIcon from 'Images/add-button.svg';
import {ReactComponent as BusinessCardIcon} from 'Images/business_card.svg';
import {ReactComponent as BcRequestIcon} from 'Images/bc_request.svg';
import {usePlatformTranslation, useUserStatus} from 'services/hooks';
import {CATEGORIES} from 'constants/businessWallet';
import ChatButton from 'Routes/components/SideMenu/components/Networking/components/UsersList/components/UserPreviewPlate/components/ChatButton/ChatButton';

import './BusinessWalletUser.scss';

export const BusinessWalletUser = ({
    requestId,
    user,
    privateChats,
    company,
    loading,
    eventId,
    category,
    isRtlLanguage,
    onAcceptContactRequest,
    onDeclineContactRequest,
    onOpenPrivateChat,
    onCreatePrivateChat,
    onCancelBusinessCardRequest,
    onOpenWallet,
    userLoggedIn,
    friendConnectionReceived,
    businessCardId,
}) => {
    const translation = usePlatformTranslation();
    const {getUserStatus} = useUserStatus();
    const SOCIALS_IS_DISABLED_TEMPORARY = true;

    const handleOpenPrivateChat = () => {
        const privateChatId = getPrivateChatId(privateChats, user._id);

        if (privateChatId) {
            onOpenPrivateChat(privateChatId);
        } else {
            if (user._id !== userLoggedIn._id && !loading) {
                onCreatePrivateChat(user._id, eventId);
            }
        }
    };

    const handleAcceptFriendRequest = () => {
        onAcceptContactRequest(requestId, eventId);
    };

    const handleDeclineFriendRequest = () => {
        onDeclineContactRequest(requestId);
    };

    const handleCancelBusinessCardRequest = () => {
        onCancelBusinessCardRequest(requestId);
    };

    const handleOpenBusinessCard = () => {
        onOpenWallet('card', '', user._id);
    };

    return (
        <div className={classNames('wallet_user', {isRtl: isRtlLanguage})}>
            <div
                className={classNames(
                    'wallet_user__user-container wallet_user-section',
                    'user__status',
                    `user__status_${getUserStatus(user?._id)}`
                )}
            >
                <div className="d-flex">
                    <div
                        className={classNames('wallet_user__participant-avatar', {iRtl: isRtlLanguage})}
                        style={{
                            background: `center / cover no-repeat url('${process.env.REACT_APP_AVATAR_FOLDER}${user.avatarSmall}')`,
                        }}
                    />
                    <div className="">
                        <div className="wallet_user-name">
                            {user.first} {user.last}
                        </div>
                        {company && <div className="wallet_user-company">{company}</div>}
                    </div>
                </div>

                <div className={classNames('connection', {'no-action': !friendConnectionReceived})}>
                    <ChatButton onClick={handleOpenPrivateChat} disabled={loading} />
                </div>
            </div>
            <div className={classNames('wallet_user-section', {isRtl: isRtlLanguage})}>
                <div className="d-flex">
                    {SOCIALS_IS_DISABLED_TEMPORARY || (
                        <div className="wallet_user-socials">
                            <SocialLinks restrictedNetwork={false} user={user} eventId={eventId} />
                            <img
                                className="wallet_user-icon"
                                src={AcceptRequestIcon}
                                width="22"
                                height="22"
                                alt="Accept Request"
                            />
                        </div>
                    )}
                </div>
                <div className="d-flex">
                    {category === CATEGORIES.contacts && businessCardId && (
                        <button type="button" className="wallet-button -colored" onClick={handleOpenBusinessCard}>
                            <BusinessCardIcon className="wallet-button-icon" />
                            <span>{translation.businessWallet.openCard}</span>
                        </button>
                    )}
                    {category === CATEGORIES.requests && (
                        <>
                            <button type="button" className="wallet-button" onClick={handleDeclineFriendRequest}>
                                <CancelIcon className="wallet-button-icon" />
                                <span>{translation.businessWallet.reject}</span>
                            </button>
                            <button
                                type="button"
                                className="wallet-button -colored"
                                onClick={handleAcceptFriendRequest}
                            >
                                <BcRequestIcon className="wallet-button-icon" />
                                <span>{translation.businessWallet.approve}</span>
                            </button>
                        </>
                    )}
                    {category === CATEGORIES.pendings && (
                        <button type="button" className="wallet-button" onClick={handleCancelBusinessCardRequest}>
                            <CancelIcon className="wallet-button-icon" />
                            <span>{translation.businessWallet.cancelRequest}</span>
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};
