export const getCustomFieldsValidators = ({isRequired, textValidation}) => {
    const validators = [];

    if (isRequired) validators.push('required');

    if (!textValidation) return validators;

    const {validation, min, max} = textValidation;
    if (!validation) return validators;

    if (min) validators.push(`minStringLength: ${min}`);
    if (max) validators.push(`maxStringLength: ${max}`);

    return validators;
};

export const getCustomFieldsErrorMessages = ({isRequired, textValidation}) => {
    const errorMessages = [];

    if (isRequired) errorMessages.push('Field is required');

    if (!textValidation) return errorMessages;

    const {validation, min, max} = textValidation;
    if (!validation) return errorMessages;

    if (min) errorMessages.push(`Minimum length is ${min} symbols`);
    if (max) errorMessages.push(`Maximum length is ${max} symbols`);

    return errorMessages;
};
