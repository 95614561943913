export const CLOSE_VIDEO_CONFERENCE = 'VIDEO_CONFERENCE/CLOSE_VIDEO_CONFERENCE';
export const VIDEO_CONFERENCE_READY = 'VIDEO_CONFERENCE/VIDEO_CONFERENCE_READY';
export const VIDEO_CONFERENCE_LOADING = 'VIDEO_CONFERENCE/VIDEO_CONFERENCE_LOADING';
export const VIDEO_CONFERENCE_SHARE_SCREEN_TOGGLED = 'VIDEO_CONFERENCE/VIDEO_CONFERENCE_SHARE_SCREEN_TOGGLED';
export const VIDEO_CONFERENCE_CHANGE_PLAYBACKS = 'VIDEO_CONFERENCE/VIDEO_CONFERENCE_CHANGE_PLAYBACKS';
export const VIDEO_CONFERENCE_START_CALLING = 'VIDEO_CONFERENCE/VIDEO_CONFERENCE_START_CALLING';
export const VIDEO_CONFERENCE_STOP_CALLING = 'VIDEO_CONFERENCE/VIDEO_CONFERENCE_STOP_CALLING';

export const SET_ACTIVE_VIDEO_CONFERENCE_TYPE = 'VIDEO_CONFERENCE/SET_ACTIVE_VIDEO_CONFERENCE_TYPE';
export const SET_INCOMING_CALL_USER_ID = 'VIDEO_CONFERENCE/SET_INCOMING_CALL_USER_ID';

export const OPEN_VIDEO_ROOM = 'VIDEO_CONFERENCE/OPEN_VIDEO_ROOM';
export const CLOSE_VIDEO_ROOM = 'VIDEO_CONFERENCE/CLOSE_VIDEO_ROOM';
export const TOGGLE_SCREEN_SHARE = 'VIDEO_CONFERENCE/TOGGLE_SCREEN_SHARE';
export const SET_INCOMING_CALL_OFFER = 'VIDEO_CONFERENCE/SET_INCOMING_CALL_OFFER';
export const SET_ICE_CANDIDATE = 'VIDEO_CONFERENCE/SET_ICE_CANDIDATE';
export const SET_ICE_SERVERS = 'VIDEO_CONFERENCE/SET_ICE_SERVERS';
export const SET_PARTICIPANT_STREAM_STATUS = 'VIDEO_CONFERENCE/SET_PARTICIPANT_STREAM_STATUS';
export const CLEAR_REMOTE_USER_INFO = 'VIDEO_CONFERENCE/CLEAR_REMOTE_USER_INFO';
export const SET_PEER_CONNECTION = 'VIDEO_CONFERENCE/SET_PEER_CONNECTION';
export const START_CALL = 'VIDEO_CONFERENCE/START_CALL';
export const END_CALL = 'VIDEO_CONFERENCE/END_CALL';
export const RECEIVE_CALL_ANSWER = 'VIDEO_CONFERENCE/RECEIVE_CALL_ANS';
export const GET_COMPANION_STREAM = 'VIDEO_CONFERENCE/GET_COMPANION_STREAM';

export const GET_MEDIA_CAPTURE_REQUEST = 'VIDEO_CONFERENCE/GET_MEDIA_CAPTURE_REQUEST';
export const GET_MEDIA_CAPTURE_SUCCESS = 'VIDEO_CONFERENCE/GET_MEDIA_CAPTURE_SUCCESS';
export const GET_MEDIA_CAPTURE_FAIL = 'VIDEO_CONFERENCE/GET_MEDIA_CAPTURE_FAIL';

export const GET_SCREEN_SHARE_CAPTURE_REQUEST = 'VIDEO_CONFERENCE/GET_SCREEN_SHARE_CAPTURE_REQUEST';
export const GET_SCREEN_SHARE_CAPTURE_SUCCESS = 'VIDEO_CONFERENCE/GET_SCREEN_SHARE_CAPTURE_SUCCESS';
export const GET_SCREEN_SHARE_CAPTURE_FAIL = 'VIDEO_CONFERENCE/GET_SCREEN_SHARE_CAPTURE_FAIL';
