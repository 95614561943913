import React, {useRef, useEffect} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Mic as MicIcon, KeyboardArrowDownOutlined} from '@material-ui/icons';
import {Select, MenuItem, FormControl} from '@material-ui/core';

import {getVideoCapture} from 'store/selectors';
import {createAudioMeter} from 'services/helpers/sessionHelper';
import {MEDIA_TYPES} from 'constants/organizer/sessions';
import {useActiveDevice} from 'services/hooks/organizer/sessions/useActiveDevice';

import {useStyles} from './AudioStream.styles';

const CANVAS_WIDTH = 200;
const CANVAS_HEIGHT = 4;

export function AudioStream({theme}) {
    const classes = useStyles();
    const canvasRef = useRef();
    const capture = useSelector(getVideoCapture);

    useEffect(() => {
        if (capture) {
            const canvas = canvasRef.current;
            const audioContext = new AudioContext();
            const mediaStreamSource = audioContext.createMediaStreamSource(capture);

            // Create a new volume meter and connect it.
            const meter = createAudioMeter(audioContext);
            mediaStreamSource.connect(meter);

            const canvasContext = canvas.getContext('2d');

            drawLoop();

            function drawLoop() {
                // clear the background
                canvasContext.clearRect(0, 0, CANVAS_WIDTH, CANVAS_HEIGHT);

                canvasContext.fillStyle = theme.palette.primary.main;

                // draw a bar based on the current volume
                canvasContext.fillRect(0, 0, meter.volume * CANVAS_WIDTH * 1.4, CANVAS_HEIGHT);

                // set up the next visual callback
                window.requestAnimationFrame(drawLoop);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [capture]);

    const {devicesOptions, activeAudioDeviceId, handleChangeDevice} = useActiveDevice({mediaType: MEDIA_TYPES.audio});

    return (
        <div className={classes.wrapper}>
            <div className={classes.micIndicator}>
                <p className={classes.title}>Microphone</p>
                <MicIcon />
                <div className={classes.canvasContainer}>
                    <canvas ref={canvasRef} className={classes.canvas} width="200" height="4" />
                </div>
            </div>
            {capture && (
                <FormControl className={classes.deviceSelect}>
                    <Select
                        onChange={handleChangeDevice}
                        value={activeAudioDeviceId || ''}
                        variant="standard"
                        IconComponent={KeyboardArrowDownOutlined}
                        disableUnderline
                        classes={{
                            root: classes.selectedValue,
                            icon: classes.arrowDownIcon,
                        }}
                    >
                        {devicesOptions.map(({deviceId, label}) => (
                            <MenuItem value={deviceId} key={deviceId}>
                                {label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </div>
    );
}

AudioStream.propTypes = {
    theme: PropTypes.object,
};
