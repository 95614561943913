import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import MainLogo from '../MainLogo/MainLogo';
import PlatformLanguage from './components/PlatformLanguage';

import './CustomHeader.scss';

export function CustomHeader({
    children,
    menuWrapperChildren,
    className,
    isPlatformMenu,
    headerScrolled,
    isRtlLanguage,
    coloredLogo,
    linkTo,
    forcePrimaryText,
    isEventLanding,
    hidePlatformLanguage,
}) {
    return (
        <header className={classNames('outside-event-header', {[className]: className})}>
            <div
                className={classNames('platform menu-wrapper ', {
                    platform: isPlatformMenu,
                    scrolled: headerScrolled,
                    isRtl: isRtlLanguage,
                })}
            >
                <div className="brand-logo">
                    <Link to={linkTo}>
                        <MainLogo colored={coloredLogo} />
                    </Link>
                </div>
                {!hidePlatformLanguage && (
                    <PlatformLanguage forcePrimaryText={forcePrimaryText} isEventLanding={isEventLanding} />
                )}
                {menuWrapperChildren}
            </div>
            {children}
        </header>
    );
}

CustomHeader.propTypes = {
    menuWrapperChildren: PropTypes.node.isRequired,
    linkTo: PropTypes.object.isRequired,
    className: PropTypes.string,
    isPlatformMenu: PropTypes.bool,
    headerScrolled: PropTypes.bool,
    isRtlLanguage: PropTypes.bool,
    coloredLogo: PropTypes.bool,
    forcePrimaryText: PropTypes.bool,
    isEventLanding: PropTypes.bool,
    hidePlatformLanguage: PropTypes.bool,
};
