import React from 'react';

import {CustomDialog} from 'Routes/components/CustomDialogComponents';
import ControlButtons from './components/ControlButtons';
import DevicesControls from './components/DevicesControls';

import {useStyles} from './CheckUserMediaPopup.styles';

export const CheckUserMediaPopup = ({open}) => {
    const classes = useStyles();

    return (
        <CustomDialog
            open={open}
            classes={{
                paper: classes.wrapper,
            }}
            maxWidth="md"
        >
            <DevicesControls />
            <ControlButtons />
        </CustomDialog>
    );
};
