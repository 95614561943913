import classNames from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';
import ShareIcon from '@material-ui/icons/Share';
import {Button, Snackbar} from '@material-ui/core';
import './ListedEventCardShareBtn.scss';
import {usePlatformTranslation} from '../../../../../services/hooks';
import Popover from '@material-ui/core/Popover';
import Share from '../../../MyAccount/components/AccountPagesScene/components/MyEvents/components/Share';

const ListedEventCardShareBtn = ({className, shareData}) => {
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);

    const {eventsListing} = usePlatformTranslation();

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenPopover = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const [successSnackbarOpened, toggleSuccessSnackbar] = React.useState(false);
    const handleOpenSuccessSnackbar = () => {
        toggleSuccessSnackbar(true);
        handleClose();
    };
    const handleCloseSnackbar = () => toggleSuccessSnackbar(false);

    return (
        <>
            <Button
                aria-describedby={id}
                onClick={handleOpenPopover}
                variant="text"
                color="secondary"
                classes={{
                    startIcon: classNames('listed-event-card-share-btn__startIcon', {isRtl: isRtlLanguage}),
                }}
                className={classNames('listed-event-card-share-btn', {
                    isRtl: isRtlLanguage,
                    className,
                })}
                startIcon={<ShareIcon />}
            >
                {eventsListing.share.toUpperCase()}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Share
                    onClose={handleClose}
                    shareData={shareData}
                    handleOpenSuccessSnackbar={handleOpenSuccessSnackbar}
                />
            </Popover>
            <Snackbar
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                open={successSnackbarOpened}
                onClose={handleCloseSnackbar}
                autoHideDuration={3000}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                className="snackbar"
                message={<span id="message-id">{'Link copied'}</span>}
            />
        </>
    );
};

export default ListedEventCardShareBtn;
