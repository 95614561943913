import {PrivateRoute} from './PrivateRoute';
import {connect} from 'react-redux';
import {topNavClose, setEventIdBySlug} from 'store/actions';
import {getUserRoles, getUserData, getIsUserLoggedIn, getUserLoading, getEventSlug} from 'store/selectors';

export default connect(
    (state) => ({
        loggedIn: getIsUserLoggedIn(state),
        loadingUser: getUserLoading(state),
        userRoles: getUserRoles(state),
        userData: getUserData(state),
        eventSlug: getEventSlug(state),
    }),
    {topNavClose, setEventIdBySlug}
)(PrivateRoute);
