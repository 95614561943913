import handleEditBuilding from './handlers/editedBuilding';
import handleStatistics from './handlers/statistics';
import handleEventSetup from './handlers/eventSetup';
import handleAuditoriumSetup from './handlers/auditoriumSetup';
import handleEventBranding from './handlers/eventBranding';
import handleAccessManagement from './handlers/eventAccessManagement';
import handleCustomForm from './handlers/customForm';

const reducer = (state, action) => ({
    editBuilding: handleEditBuilding(state, action),
    eventStatistics: handleStatistics(state, action),
    eventSetup: handleEventSetup(state, action),
    eventBranding: handleEventBranding(state, action),
    auditoriumSetup: handleAuditoriumSetup(state, action),
    eventAccessManagement: handleAccessManagement(state, action),
    customForm: handleCustomForm(state, action),
});

export default reducer;
