import React from 'react';
import {connect} from 'react-redux';

import HeaderMenu from '../HeaderMenu';
import Footer from '../Footer';
import classNames from 'classnames';
import {IS_SCA_ENV} from '../../../constants/shared';

import './EventPrivacyPolicy.scss';

class EventPrivacyPolicy extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className={classNames('gdpr-page', {isSca: IS_SCA_ENV})}>
                <HeaderMenu className="white" coloredLogo disabledLanguageSelector />
                <div className="header-spacing-container">
                    <div className="gdpr-container">
                        <h1>Event Privacy notice/policy</h1>
                        <p>
                            {this.props.eventInfo.brandingData.eventPrivacyPolicy &&
                                this.props.eventInfo.brandingData.eventPrivacyPolicy.split('\n').map((item, key) => {
                                    return (
                                        <span key={key}>
                                            {item}
                                            <br />
                                        </span>
                                    );
                                })}
                        </p>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        eventInfo: state.event.eventInfo,
    };
};

export default connect(mapStateToProps)(EventPrivacyPolicy);
