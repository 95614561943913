import MainLogoImage from '../../../../Images/branding/logo-half-white.png';
import MainLogoImageColored from '../../../../Images/branding/logo-colored.png';
import AddedLogoImage from '../../../../Images/branding/added/added-logo-white.svg';
import AddedLogoImageColored from '../../../../Images/branding/added/added-logo-colored.svg';
import ScaLogoImage from '../../../../Images/branding/sca/header-logo/sca-white-logo.svg';
import ScaLogoImageColored from '../../../../Images/branding/sca/header-logo/sca-colored-logo.svg';
import UnctadLogoImage from '../../../../Images/branding/unctad/unctad-white-logo.svg';
import UnctadLogoImageColored from '../../../../Images/branding/unctad/unctad-colored-logo.svg';
import EgyptLogoImage from '../../../../Images/branding/egypt/header-logo/egypt-pavilion-white-logo.svg';
import EgyptLogoImageColored from '../../../../Images/branding/egypt/header-logo/egypt-pavilion-colored-logo.svg';
import {whiteLabelsList} from 'config';

export const LOGO_TYPES = {
    white: 'white',
    colored: 'colored',
};

export const logoConfig = {
    main: {white: MainLogoImage, colored: MainLogoImageColored},
    added: {white: AddedLogoImage, colored: AddedLogoImageColored},
    sca: {white: ScaLogoImage, colored: ScaLogoImageColored},
    unctad: {white: UnctadLogoImage, colored: UnctadLogoImageColored},
    [whiteLabelsList.egyptPavilion]: {white: EgyptLogoImage, colored: EgyptLogoImageColored},
};
