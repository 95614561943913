import {createSelector} from 'reselect';

const exhibitorJobs = (state) => state.exhibitorJobs;
const myJobApplicants = (state) => state.myJobApplicants;

export const getExhibitorJobsList = createSelector(exhibitorJobs, (state) => state.jobsList);
export const getExhibitorJobsLoading = createSelector(exhibitorJobs, (state) => state.loading);
export const getExhibitorJobsError = createSelector(exhibitorJobs, (state) => state.error);
export const getExhibitorEditedJob = createSelector(exhibitorJobs, (state) => state.editedJob);
export const getExhibitorCreatedJob = createSelector(exhibitorJobs, (state) => state.createdJob);
export const getExhibitorDeletedJob = createSelector(exhibitorJobs, (state) => state.deletedJob);
export const getMyJobApplicants = createSelector(myJobApplicants, (state) => state);
export const getMyJobApplicantsList = createSelector(myJobApplicants, (state) => state.list);
