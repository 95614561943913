import {LIVE_WALL_SORT_METHODS} from 'constants/shared';
import {Api} from 'Api';
import * as actionTypes from '../actionTypes/wallActionTypes';

const approveWallCommentStart = () => ({
    type: actionTypes.WALL_APPROVE_COMMENT_START,
});

const approveWallCommentSuccess = (data) => ({
    type: actionTypes.WALL_APPROVE_COMMENT_SUCCESS,
    payload: data,
});

const approveWallCommentFail = (error) => ({
    type: actionTypes.WALL_APPROVE_COMMENT_FAIL,
    payload: error,
});

export const approveWallComment =
    ({wallId, postId, commentId}) =>
    async (dispatch, getState) => {
        const {
            event: {loading, eventId},
        } = getState();
        dispatch(approveWallCommentStart());
        try {
            await Api.request({
                url: `/event/${eventId}/live-wall/${wallId}/post/${postId}/comment/${commentId}/approve`,
                method: 'post',
                loading,
            });
            dispatch(approveWallCommentSuccess());
        } catch (error) {
            dispatch(approveWallCommentFail(error));
        }
    };

const approveWallPostStart = () => ({
    type: actionTypes.WALL_APPROVE_POST_START,
});

const approveWallPostSuccess = (data) => ({
    type: actionTypes.WALL_APPROVE_POST_SUCCESS,
    payload: data,
});

const approveWallPostFail = (error) => ({
    type: actionTypes.WALL_APPROVE_POST_FAIL,
    payload: error,
});

export const approveWallPost =
    ({wallId, postId}) =>
    async (dispatch, getState) => {
        const {
            event: {loading, eventId},
        } = getState();

        dispatch(approveWallPostStart());
        try {
            await Api.request({
                url: `/event/${eventId}/live-wall/${wallId}/post/${postId}/approve`,
                method: 'post',
                loading,
            });

            dispatch(approveWallPostSuccess());
        } catch (error) {
            dispatch(approveWallPostFail(error));
        }
    };

const getWallPostsStart = () => ({
    type: actionTypes.WALL_GET_POSTS_START,
});

const getWallPostsSuccess = (data) => ({
    type: actionTypes.WALL_GET_POSTS_SUCCESS,
    payload: data,
});

const getWallPostsFail = (error) => ({
    type: actionTypes.WALL_GET_POSTS_FAIL,
    payload: error,
});

export const getWallPosts =
    ({wallId, page = 1, sortBy = LIVE_WALL_SORT_METHODS.time, signal}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
            wall: {loadingPosts},
        } = getState();
        dispatch(getWallPostsStart());

        try {
            const res = await Api.request({
                url: `/event/${eventId}/live-wall/${wallId}/posts`,
                method: 'get',
                loading: loadingPosts,
                params: {
                    page,
                    perPage: 40,
                    sortBy,
                },
                signal,
            });

            dispatch(getWallPostsSuccess(res));
        } catch (error) {
            dispatch(getWallPostsFail(error));
        }
    };

export const updateWallPosts = (updatedPosts) => ({
    type: actionTypes.WALL_UPDATE_POSTS,
    payload: updatedPosts,
});

const likeWallPostStart = () => ({
    type: actionTypes.WALL_LIKE_POST_START,
});

const likeWallPostSuccess = (data) => ({
    type: actionTypes.WALL_LIKE_POST_SUCCESS,
    payload: data,
});

const likeWallPostFail = (error) => ({
    type: actionTypes.WALL_LIKE_POST_FAIL,
    payload: error,
});

export const likeWallPost =
    ({wallId, postId}) =>
    async (dispatch, getState) => {
        const {
            event: {loading, eventId},
        } = getState();

        dispatch(likeWallPostStart());
        try {
            await Api.request({
                url: `/event/${eventId}/live-wall/${wallId}/post/${postId}/like`,
                method: 'post',
                loading,
            });

            dispatch(likeWallPostSuccess());
        } catch (error) {
            dispatch(likeWallPostFail(error));
        }
    };

const unlikeWallPostStart = () => ({
    type: actionTypes.WALL_LIKE_POST_START,
});

const unlikeWallPostSuccess = (data) => ({
    type: actionTypes.WALL_LIKE_POST_SUCCESS,
    payload: data,
});

const unlikeWallPostFail = (error) => ({
    type: actionTypes.WALL_LIKE_POST_FAIL,
    payload: error,
});

export const unlikeWallPost =
    ({wallId, postId}) =>
    async (dispatch, getState) => {
        const {
            event: {loading, eventId},
        } = getState();

        dispatch(unlikeWallPostStart());
        try {
            await Api.request({
                url: `/event/${eventId}/live-wall/${wallId}/post/${postId}/like`,
                method: 'delete',
                loading,
            });

            dispatch(unlikeWallPostSuccess());
        } catch (error) {
            dispatch(unlikeWallPostFail(error));
        }
    };

const addWallPostStart = () => ({
    type: actionTypes.WALL_ADD_POST_START,
});

const addWallPostSuccess = (data) => ({
    type: actionTypes.WALL_ADD_POST_SUCCESS,
    payload: data,
});

const addWallPostFail = (error) => ({
    type: actionTypes.WALL_ADD_POST_FAIL,
    payload: error,
});

export const addWallPost =
    ({wallId, post, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {loading, eventId},
        } = getState();

        dispatch(addWallPostStart());

        try {
            await Api.request({
                url: `/event/${eventId}/live-wall/${wallId}`,
                method: 'post',
                loading,
                payload: {text: post},
            });

            dispatch(addWallPostSuccess());

            callback && callback();
        } catch (error) {
            dispatch(addWallPostFail(error));
        }
    };

export const deleteWallPost =
    ({postId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {
                eventId,
                sideNavigationDetails: {activeWallId},
            },
        } = getState();

        try {
            dispatch(deleteWallPostRequest());
            await Api.request({
                method: 'delete',
                url: `/event/${eventId}/live-wall/${activeWallId}/post/${postId}`,
                loading: false,
            });

            dispatch(deleteWallPostSuccess(postId));
            callback && callback();
        } catch (error) {
            dispatch(deleteWallPostFail());
        }
    };
const deleteWallPostRequest = () => ({type: actionTypes.WALL_DELETE_POST_REQUEST});
const deleteWallPostSuccess = (payload) => ({type: actionTypes.WALL_DELETE_POST_SUCCESS, payload});
const deleteWallPostFail = () => ({type: actionTypes.WALL_DELETE_POST_FAIL});

export const pinWallPost =
    ({postId, pinActionType, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {
                loading,
                eventId,
                sideNavigationDetails: {activeWallId},
            },
        } = getState();

        try {
            await Api.request({
                method: 'post',
                url: `/event/${eventId}/live-wall/${activeWallId}/post/${postId}/${pinActionType}`,
                loading,
            });

            callback && callback();
        } catch (error) {}
    };

const addWallPostCommentStart = () => ({
    type: actionTypes.WALL_ADD_COMMENT_START,
});

const addWallPostCommentSuccess = (data) => ({
    type: actionTypes.WALL_ADD_COMMENT_SUCCESS,
    payload: data,
});

const addWallPostCommentFail = (error) => ({
    type: actionTypes.WALL_ADD_COMMENT_FAIL,
    payload: error,
});

export const addWallPostComment =
    ({wallId, postId, comment}) =>
    async (dispatch, getState) => {
        const {
            event: {loading, eventId},
        } = getState();

        dispatch(addWallPostCommentStart());
        try {
            await Api.request({
                url: `/event/${eventId}/live-wall/${wallId}/post/${postId}/comment`,
                method: 'post',
                loading,
                payload: {text: comment},
            });

            dispatch(addWallPostCommentSuccess());
        } catch (error) {
            dispatch(addWallPostCommentFail(error));
        }
    };

export const deleteWallPostComment =
    ({postId, commentId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {
                loading,
                eventId,
                sideNavigationDetails: {activeWallId},
            },
        } = getState();

        try {
            await Api.request({
                method: 'delete',
                url: `/event/${eventId}/live-wall/${activeWallId}/post/${postId}/comment/${commentId}`,
                loading,
            });

            callback && callback();
        } catch (error) {}
    };

export const updatePostTrigger = (payload) => ({
    type: actionTypes.WALL_UPDATE_POST_ON_RECEIVE,
    payload,
});

export const updateCommentTrigger = (payload) => ({
    type: actionTypes.WALL_UPDATE_COMMENT_ON_RECEIVE,
    payload,
});

export const deletePostTrigger = (payload) => ({
    type: actionTypes.WALL_DELETE_POST_TRIGGER,
    payload,
});

export const deleteCommentsTrigger = (payload) => ({
    type: actionTypes.WALL_DELETE_COMMENT_TRIGGER,
    payload,
});

export const likePostTrigger = (payload) => ({
    type: actionTypes.WALL_LIKE_POST_TRIGGER,
    payload,
});

export const unlikePostTrigger = (data, currentUser) => ({
    type: actionTypes.WALL_UNLIKE_POST_TRIGGER,
    payload: {data, currentUser},
});

export const pinPostTrigger = (payload) => ({
    type: actionTypes.WALL_PIN_POST_TRIGGER,
    payload,
});

export const unpinPostTrigger = (payload) => ({
    type: actionTypes.WALL_UNPIN_POST_TRIGGER,
    payload,
});

export const resetWallData = () => ({
    type: actionTypes.WALL_RESET_DATA,
});
