import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {MenuItem, Select, Grid, Button} from '@material-ui/core';
import classNames from 'classnames';

import ConfirmDialog from '../../../../../ConfirmDialog/ConfirmDialog';
import EditSectionContainer from '../../../EditSectionContainer';
import MyAccountMobile from './components/MyAccountMobile';
import EditUserInformationDialog from './components/EditUserInformationDialog/EditUserInformationDialog';
import DeleteMyAccountDialog from './components/DeleteMyAccountDialog/DeleteMyAccountDialog';
import ChangePasswordDialog from './components/ChangePasswordDialog';

import {allTranslationList, allTranslations} from 'constants/translations/all-translations';
import {ReactComponent as EditIcon} from 'Images/edit-image.svg';

import {AbilityContext} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

import './AccountProfile.scss';

class AccountProfile extends Component {
    state = {
        editUserDialog: false,
        changeUserPasswordDialog: false,
        deleteMyAccountDialog: false,
        unregisterFromEventDialog: false,
        changePasswordServerMessage: '',
        imgErrorText: '',
        canUnregisterEvent: true,
        registrationType: '',
        translation: null
    };

    componentDidMount() {
        this.getRegistrationType();

        this.checkCanUnregisterEvent();
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation
        });
    };

    getRegistrationType = () => {
        const {getProfileRegistrationType} = this.props;

        getProfileRegistrationType({callback: (registrationType) => this.setState({registrationType})});
    };

    onUnregisterFromEvent = () => {
        const {eventSlug, unregisterFromEvent, user, onRefreshUserUserData, history} = this.props;

        unregisterFromEvent({
            userId: user.id,
            callback: () =>
                onRefreshUserUserData().then((response) => {
                    history.push(`/event/${eventSlug}`);
                })
        });
    };

    checkCanUnregisterEvent = () => {
        const ability = this.context;
        if (ability.cannot(ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY, ROLE_FEATURES_TYPES.UNREGISTER_EVENT)) {
            this.setState({canUnregisterEvent: false});
        }
    };

    handleOpenEditUserDialog = () => {
        this.setState({editUserDialog: true});
    };

    handleChangePassword = () => {
        this.setState({changeUserPasswordDialog: true});
    };

    handleDeleteUser = () => {
        this.setState({deleteMyAccountDialog: true});
    };

    handleUnregisterFromEvent = () => {
        this.setState({
            unregisterFromEventDialog: true
        });
    };

    handleCloseDialogUnregisterFromEvent = () => {
        this.setState({
            unregisterFromEventDialog: false
        });
    };

    handleCloseDialog = (serverMessage) => {
        let message = '';
        if (serverMessage) {
            message = serverMessage;
        }
        this.setState({
            editUserDialog: false,
            deleteMyAccountDialog: false,
            changeUserPasswordDialog: false,
            unregisterFromEventDialog: false,
            changePasswordServerMessage: message
        });
    };

    handleRemoveUserAvatar = () => this.props.updateUserAvatar({method: 'delete'});

    handleImageChange = async (e) => {
        const {updateUserAvatar} = this.props;

        e.preventDefault();
        let file = e.target.files[0];

        let isValid = true;

        isValid = file.size < 2 * 1024 * 1024 && isValid;
        if (!isValid) {
            this.setState({imgErrorText: 'File too large. 2Mb max file size.'});
        }

        isValid = (file.type === 'image/png' || file.type === 'image/jpg' || file.type === 'image/jpeg') && isValid;
        if (file.type !== 'image/png' && file.type !== 'image/jpg' && file.type !== 'image/jpeg') {
            this.setState({
                imgErrorText: this.props.translation?.errors.imageFileNotSupported
            });
        }

        if (isValid) {
            this.setState({imgErrorText: ''});
            const formData = new FormData();

            formData.append('avatar', file, file.name, file.type);

            updateUserAvatar({method: 'post', payload: formData});
        }
    };

    handleChangeUserLanguage = ({target: {value}}) => this.props.changeUserLanguage(value);

    render() {
        const {
            user,
            isMobile,
            isTablet,
            languages: {platformLanguage},
            isRtlLanguage
        } = this.props;
        const {translation} = this.state;

        if (isMobile || isTablet) {
            return <MyAccountMobile />;
        }

        return (
            <>
                <EditSectionContainer className="onvent-profile">
                    <Grid container alignItems="center" className="smallContainer">
                        <div className="edit-section-header">
                            <h3>
                                {translation?.generalText.eventUpperETX}{' '}
                                {translation?.myAccountPage.platformProfileSectionTitleTextSecond}
                            </h3>
                            <button onClick={this.handleOpenEditUserDialog}>
                                <EditIcon className="primary-fill-svg" />
                            </button>
                        </div>
                        <Grid item xs={12} className="edit-user-personal-info-container">
                            <div className={classNames('image-container', {isRtl: isRtlLanguage})}>
                                <div
                                    className="avatar"
                                    style={{
                                        backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${this.props.user.avatarBig}')`
                                    }}
                                ></div>
                                <label htmlFor="file" className="select-image"></label>
                                <div className="upload-text-container">
                                    <span>{translation?.myAccountPage.uploadImage}</span>
                                </div>

                                <input
                                    onChange={this.handleImageChange}
                                    name="file"
                                    type="file"
                                    id="file"
                                    className="d-none"
                                />
                                <button
                                    className="remove-avatar"
                                    onClick={this.handleRemoveUserAvatar}
                                    disabled={user.avatarBig === 'default_avatar.png' ? true : false}
                                >
                                    {translation?.myAccountPage.removeImage}
                                </button>
                                <span className="image-error">{this.state.imgErrorText}</span>
                            </div>
                            <div className="user-profile-information">
                                <span>
                                    {user.first} {user.last}
                                </span>
                                <p>
                                    <span>e-mail: </span>
                                    {user.email}
                                </p>
                                <div>
                                    {(this.state.registrationType === '' ||
                                        this.state.registrationType === 'email') && (
                                        <Button
                                            onClick={this.handleChangePassword}
                                            type="button"
                                            color="primary"
                                            variant="outlined"
                                            className="change-password-btn"
                                        >
                                            {translation?.myAccountPage.changePasswordButton}
                                        </Button>
                                    )}
                                    <Button
                                        onClick={this.handleDeleteUser}
                                        type="button"
                                        color="secondary"
                                        variant="outlined"
                                        className="change-password-btn"
                                    >
                                        {translation?.myAccountPage.deleteAccountButton}
                                    </Button>
                                    {this.state.changePasswordServerMessage ? (
                                        <p className="server-message">
                                            {this.state.changePasswordServerMessage
                                                ? this.state.changePasswordServerMessage
                                                : ''}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                        </Grid>

                        <div className="select-language-container">
                            <span>{translation?.myAccountPage.platformLanguage}</span>
                            <Select onChange={this.handleChangeUserLanguage} value={user.language} variant="outlined">
                                {allTranslationList.map(({slug, title}) => {
                                    return (
                                        <MenuItem key={slug} value={slug}>
                                            {title}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </div>

                        <div className="gdpr-links">
                            <p>
                                {translation?.myAccountPage.reviewPlatformGdprTextFirst}{' '}
                                {platformLanguage !== allTranslations.ar.slug && translation?.generalText.eventUpperETX}{' '}
                                {translation?.myAccountPage.reviewPlatformGdprTextSecond}
                            </p>
                            <ul>
                                <li>
                                    <Link to={{pathname: `/privacy-policy/${platformLanguage}`}} target="_blank">
                                        {translation?.myAccountPage.privacyPolicyLink}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{pathname: `/cookies/${platformLanguage}`}} target="_blank">
                                        {translation?.myAccountPage.cookiesLink}
                                    </Link>
                                </li>
                                <li>
                                    <Link to={{pathname: `/terms-and-conditions/${platformLanguage}`}} target="_blank">
                                        {translation?.myAccountPage.termsAndConditionsLink}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </Grid>
                </EditSectionContainer>

                {this.state.editUserDialog && (
                    <EditUserInformationDialog
                        opened={this.state.editUserDialog}
                        closeDialog={this.handleCloseDialog}
                    />
                )}
                {this.state.deleteMyAccountDialog && (
                    <DeleteMyAccountDialog
                        opened={this.state.deleteMyAccountDialog}
                        closeDialog={this.handleCloseDialog}
                    />
                )}
                {this.state.changeUserPasswordDialog && (
                    <ChangePasswordDialog
                        opened={this.state.changeUserPasswordDialog}
                        closeDialog={this.handleCloseDialog}
                    />
                )}
                {this.state.unregisterFromEventDialog && (
                    <ConfirmDialog
                        open={this.state.unregisterFromEventDialog}
                        closeConfirm={this.handleCloseDialogUnregisterFromEvent}
                        dialogTitle={'Unregister from current event'}
                        dialogDescription={`Please confirm that you want to unregister from event '${this.props.event.name}'`}
                        handleConfirm={this.onUnregisterFromEvent}
                        dialogConfirmButtonLabel={'Confirm'}
                    />
                )}
            </>
        );
    }
}

AccountProfile.contextType = AbilityContext;

export default AccountProfile;
