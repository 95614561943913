import {nanoid} from 'nanoid';
import * as actionTypes from '../actionTypes/exhibitorJobsActionTypes';
import {Api} from '../../Api';
import {prepareJobData} from 'services/helpers';

export const getExhibitorJobs = (exhibitorId) => async (dispatch, getState) => {
    const {
        appliedJobs: {loading},
        event: {eventId},
    } = getState();

    dispatch(getJobsStart());

    try {
        const {jobs} = await Api.request({
            url: `/event/${eventId}/exhibitor/${exhibitorId}/jobs`,
            method: 'get',
            loading,
        });

        dispatch(getJobsSuccess(jobs));
    } catch (error) {
        dispatch(getJobsSuccess(error));
    }
};

export const deleteExhibitorJob = (exhibitorId) => async (dispatch, getState) => {
    const {
        event: {eventId},
        exhibitorJobs: {deletedJob, editedJob},
    } = getState();
    dispatch(deleteJobStart());

    try {
        await Api.request({method: 'delete', url: `/event/${eventId}/exhibitor/${exhibitorId}/jobs/${deletedJob._id}`});

        if (deletedJob?._id === editedJob?._id) {
            dispatch(resetExhibitorActiveJob());
        }
        dispatch(deleteJobSuccess(deletedJob));
        dispatch(getExhibitorJobs(exhibitorId));
    } catch (error) {
        dispatch(deleteJobFail());
        console.warn(error);
    }
};

export const publishExhibitorJob = (exhibitorId, job) => async (dispatch, getState) => {
    const {
        event: {eventId},
        exhibitorJobs: {editedJob},
    } = getState();

    dispatch(publishExhibitorJobStart());

    if (!!editedJob) {
        try {
            const preparedData = prepareJobData(job);
            const {jobs} = await Api.request({
                method: 'put',
                url: `/event/${eventId}/exhibitor/${exhibitorId}/jobs/${job._id}`,
                payload: preparedData,
            });
            dispatch(publishExhibitorJobSuccess(jobs));
        } catch (error) {
            dispatch(publishExhibitorJobFail());
        }
        return;
    }

    try {
        const {jobs} = await Api.request({
            method: 'post',
            url: `/event/${eventId}/exhibitor/${exhibitorId}/jobs`,
            payload: job,
        });
        dispatch(publishExhibitorJobSuccess(jobs));
    } catch (error) {
        dispatch(publishExhibitorJobFail());
    }
};

export const applyToExhibitorJob = (exhibitorId, jobId) => async (dispatch, getState) => {
    const {
        appliedJobs: {loading},
        event: {eventId},
    } = getState();

    dispatch(applyToJobStart());

    try {
        const {jobs} = await Api.request({
            url: `/event/${eventId}/exhibitor/${exhibitorId}/jobs/${jobId}/apply`,
            method: 'post',
            loading,
        });

        dispatch(getJobsSuccess(jobs));
        dispatch(getExhibitorJobs(exhibitorId));
    } catch (error) {
        dispatch(applyToJobFail(error));
    }
};

export const applyToJobFail = () => {
    return {
        type: actionTypes.APPLY_EXHIBITOR_JOB_FAIL,
    };
};

export const applyToJobStart = () => {
    return {
        type: actionTypes.APPLY_EXHIBITOR_JOB_START,
    };
};

export const getJobsStart = () => {
    return {
        type: actionTypes.GET_EXHIBITOR_JOBS_START,
    };
};
export const getJobsSuccess = (event) => {
    return {
        type: actionTypes.GET_EXHIBITOR_JOBS_SUCCESS,
        payload: event,
    };
};
export const getJobsFail = () => {
    return {
        type: actionTypes.GET_EXHIBITOR_JOBS_FAIL,
    };
};

export const deleteJobStart = () => ({
    type: actionTypes.DELETE_EXHIBITOR_JOB_START,
});

export const deleteJobSuccess = (job) => ({
    type: actionTypes.DELETE_EXHIBITOR_JOB_SUCCESS,
    payload: {job},
});

export const deleteJobFail = () => ({
    type: actionTypes.DELETE_EXHIBITOR_JOB_FAIL,
});

export const publishExhibitorJobStart = () => ({
    type: actionTypes.PUBLISH_EXHIBITOR_JOB_START,
});

export const publishExhibitorJobSuccess = (event) => {
    return {
        type: actionTypes.PUBLISH_EXHIBITOR_JOB_SUCCESS,
        payload: event,
    };
};

export const publishExhibitorJobFail = () => ({
    type: actionTypes.PUBLISH_EXHIBITOR_JOB_FAIL,
});

export const resetExhibitorActiveJob = () => ({
    type: actionTypes.RESET_EXHIBITOR_ACTIVE_JOB,
});

export const createExhibitorJob = () => ({
    type: actionTypes.CREATE_EXHIBITOR_JOB,
    payload: {_id: nanoid()},
});

export const editExhibitorJob = (job) => ({
    type: actionTypes.EDIT_EXHIBITOR_JOB,
    payload: {job},
});

export const setExhibitorDeletedJob = (job) => ({
    type: actionTypes.SET_EXHIBITOR_DELETED_JOB,
    payload: {job},
});

export const resetExhibitorDeletedJob = () => ({
    type: actionTypes.RESET_EXHIBITOR_DELETED_JOB,
});
