import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import {ForceResetPassword} from './ForceResetPassword';

export default connect(
    (state) => ({
        user: selectors.getUserData(state),
        isLargeScreen: selectors.getIsLargeScreen(state),
    }),
    {
        onUpdatePassword: actions.updatePassword,
    }
)(ForceResetPassword);
