import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilityStateFunction';

const initialState = {
    loading: false,
    error: false,
    errorMessage: '',
    data: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EVENT_ARCHIVE_START:
            return updateObject(state, {
                loading: true,
                error: false,
                errorMessage: '',
            });
        case actionTypes.GET_EVENT_ARCHIVE_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.payload,
            });
        case actionTypes.GET_EVENT_ARCHIVE_FAIL:
            return updateObject(state, {
                loading: false,
                error: true,
                errorMessage: action.payload,
            });

        default:
            return state;
    }
};

export default reducer;
