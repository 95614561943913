import React from 'react';
import {useSelector} from 'react-redux';

import BusinessCard from './BusinessCard';

const BusinessCardContainer = ({cardId}) => {
    const walletCardId = useSelector((state) => state.user.topNavigation.walletConfig.cardId);

    return <BusinessCard cardId={cardId || walletCardId} withHeader={!cardId} />;
};

export default BusinessCardContainer;
