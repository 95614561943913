import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import Lobby from './Lobby';

export default connect(
    (state) => ({
        eventId: selectors.getEventId(state),
        eventSlug: selectors.getEventSlug(state),
        loadingEvent: selectors.getEventLoading(state),
        event: selectors.getEventData(state),
        user: selectors.getUserData(state),
        eventRoles: selectors.getBooleanEventRolesList(state),
        resourcesAccess: selectors.getResourcesAccess(state),
        isLargeScreen: selectors.getIsLargeScreen(state),
        languages: selectors.getLanguages(state),
        sideNavigationDetails: selectors.getSideNavigationDetails(state),
        buildingId: selectors.getBuildingId(state),
        building: selectors.getBuildingData(state),
    }),
    {
        setHasVideoWall: actions.sideNavHasVideoWall,
        onLogoutUser: actions.logoutUser,
        setActiveWall: actions.setActiveWall,
        seeLiveWall: actions.sideNavSeeLiveWall,
        onSetEventProtectedMenu: actions.setEventProtectedMenu,
        onSocetSetEnabeledEventMatchmaking: actions.socetSetEnabeledEventMatchmaking,
        onSocetSetEventHasMatchmaking: actions.socetSetEventHasMatchmaking,
        onGetSocialsPromotion: actions.getSocialsPromotion,
        onGetWallPosts: actions.getWallPosts,
    }
)(Lobby);
