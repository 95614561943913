import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        minHeight: 'calc(100vh - 116px)',
        backgroundColor: theme.palette.background.default,
        [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
            minHeight: 'unset',
        },
    },
    arrowForward: {
        position: 'absolute',
        zIndex: 1,
        top: '50%',
        transform: 'translateY(-50%)',
        right: 22,
        fill: theme.palette.grey.A400,
        '[dir="rtl"] &': {
            right: 'unset',
            left: 22,
        },
    },
    tabWrapper: {
        width: '100%',
        position: 'relative',
    },
    tabsContainer: {
        borderRight: '1px solid #CACBD0',
        minWidth: 250,
        '[dir="rtl"] &': {
            borderRight: 'none',
            borderLeft: '1px solid #CACBD0',
        },
        [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
            borderRight: 'none',
            width: '100%',
        },
    },
    indicator: {
        border: 'none',
        outline: 'none',
        background: theme.palette.primary.main,
        cursor: 'pointer',
        width: 4,
        left: 0,
    },
    text: {
        textAlign: 'left',
        alignItems: 'start',
        color: theme.palette.text.primary,
        fontSize: 16,
        fontWeight: '700',
    },
    tabRoot: {
        width: '100%',
        [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
            opacity: '1 !important',
            width: '100%',
            maxWidth: 'unset',
            height: 60,
            borderBottom: '1px solid #CACBD0',
            background: theme.palette.background.paper,
        },
    },
    selected: {
        background: theme.palette.background.paper,
    },
    tabContent: {
        padding: 24,
        width: '100%',
        [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
            padding: '0',
        },
    },
    contentWrapper: {
        filter: 'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2))',
        background: theme.palette.background.paper,
        borderRadius: 6,
        height: 'calc(100vh - 164px)',
        overflowX: 'hidden',
        overflowY: 'auto',
        border: '1px solid #CACBD0',
        [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
            border: 'none',
            height: '100%',
            borderRadius: 0,
            filter: 'none',
        },
    },
    rtl: {
        direction: 'rtl',
    },
    hideElement: {
        display: 'none',
    },

    goBackWrapper: {
        backgroundColor: theme.palette.background.paper,
        borderLeft: ` 4px solid ${theme.palette.secondary.main} `,
        justifyContent: 'flex-start',

        padding: '14px 16px',
        height: '48px',
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
        boxShadow: ' 0px 1px 10px rgba(0, 0, 0, 0.08), 0px 2px 4px rgba(0, 0, 0, 0.2)',
    },

    goBackTitle: {
        margin: 0,
        fontWeight: 600,
        fontSize: 16,
        color: theme.palette.text.primary,
        textTransform: 'uppercase',
        userSelect: 'none',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },

    goBackIcon: {
        fill: theme.palette.text.primary,
        marginRight: 12,
        '&.isRtl': {
            margin: 0,
            marginLeft: 12,
        },
    },
}));
