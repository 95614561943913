import * as actionTypes from '../actionTypes/myJobApplicantsActionTypes';
import {updateObject} from '../utilityStateFunction';

const initialState = {loadState: '', list: [], loading: false};

const myJobApplicantsReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.GET_MY_JOB_APPLICANTS_START:
            return updateObject(state, {loading: true});
        case actionTypes.GET_MY_JOB_APPLICANTS_SUCCESS:
            return updateObject(state, {
                loading: false,
                list: payload,
            });
        case actionTypes.GET_MY_JOB_APPLICANTS_FAIL:
            return updateObject(state, {loading: false});

        default:
            return state;
    }
};

export default myJobApplicantsReducer;
