import {connect} from 'react-redux';
import * as selectors from 'store/selectors';
import * as actions from 'store/actions';
import MatchmakingDialog from './MatchmakingDialog';

export default connect(
    (state) => ({
        isLargeScreen: selectors.getIsLargeScreen(state),
        matchingQuestions: selectors.getMatchMakingFormQuestions(state),
        loading: selectors.getMatchMakingFormLoading(state),
    }),
    {
        onGetMatchmakingForm: actions.getMatchmakingForm,
    }
)(MatchmakingDialog);
