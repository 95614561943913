import React from 'react';

import Svg from '../Svg/Svg';

import './Mobile.scss';

const IconMobile = () => (
    <Svg className="IconMobile">
        <path d="M15.5 1h-8A2.5 2.5 0 005 3.5v17A2.5 2.5 0 007.5 23h8a2.5 2.5 0 002.5-2.5v-17A2.5 2.5 0 0015.5 1zm-4 21c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zm4.5-4H7V4h9v14z" />
    </Svg>
);

export default IconMobile;
