export const INVITATION_TYPES = {
    speakerInvitation: 'speaker-invitation',
    moderatorInvitation: 'moderator-invitation',
};

export const PARTICIPANT_TYPES = {
    organizer: 'organizer',
    speaker: 'speaker',
    moderator: 'moderator',
    interpreter: 'interpreter',
    mixer: 'mixer',
};

export const VIDEO_ROOM_EVENTS = {
    SESSION_JOINED: 'session_joined',
    SESSION_LEFT: 'session_left',
    PRODUCER_TOGGLE: 'producer_toggle',
};

export const MS_EVENTS = {
    TRANSPORT: {
        CREATED: 'transport_created',
        DESTROYED: 'transport_destroyed',
    },
    CONSUMER: {
        CREATED: 'consumer_created',
        DESTROYED: 'consumer_destroyed',
        PAUSED: 'consumer_paused',
        RESUMED: 'consumer_resumed',
    },
    PRODUCER: {
        CREATED: 'producer_created',
        DESTROYED: 'producer_destroyed',
        PAUSED: 'producer_paused',
        RESUMED: 'producer_resumed',
    },
};

export const PROFILE_STATUSES = {
    new: 'new',
    approved: 'approved',
    pending: 'pending',
};

export const MEDIA_TYPES = {
    audio: 'audio',
    video: 'video',
    shareScreen: 'shareScreen',
    consumerScreenShare: 'screenshare',
};

export const MEDIA_DEVICES_KINDS = {
    audioinput: 'audioinput',
    videoinput: 'videoinput',
    audiooutput: 'audiooutput',
    videooutput: 'videooutput',
};

export const ZOOM_SESSION_ACTION_TYPES = {
    startStream: 'startStream',
    stopStream: 'stopStream',
    startStreamRecord: 'startStreamRecord',
    stopStreamRecord: 'stopStreamRecord',
    allocatedResources: 'allocatedResources',
    holdResources: 'holdResources',
};

export const ALLOCATE_RESOURCES_STATUSES = {
    holded: 'holded',
    holding: 'holding',
    allocated: 'allocated',
    processing: 'processing',
    failed: 'failed',
    initial: null,
};

export const ZOOM_SESSION_SETUP_INCOMPLETE_INFO_MESSAGE =
    'Please proceed to session dashboard page to finish configuration set up';

export const ZOOM_SESSION_SETUP_INFO_MESSAGE = 'Session dashboard page will be available after saving time slot';

export const ZOOM_SESSION_STREAM_URLS_DISABLED_MESSAGE = {
    [ALLOCATE_RESOURCES_STATUSES.initial]: 'RTMP (ZOOM) links will be generated after resources allocation',
    [ALLOCATE_RESOURCES_STATUSES.processing]: 'RTMP (ZOOM) links will be generated after resources allocation',
    [ALLOCATE_RESOURCES_STATUSES.holded]: 'Resources has been holded',
    [ALLOCATE_RESOURCES_STATUSES.failed]: 'Resources allocation has failed',
};

export const ZOOM_SESSION_NO_ACTIVE_STREAM_MESSAGE =
    'There is no active stream yet, please configure you streaming endpoint using session links info';

export const ZOOM_SESSION_HOLDED_RESOURCES_MESSAGE = 'Streaming resources has been released. Thank you!';

export const ZOOM_SESSION_STREAM_FINISHED_MESSAGE = 'Stream has been finished. Thank you!';

export const EXPECTED_PARTICIPANTS_OPTIONS = [
    {value: 1000, label: '0-1000'},
    {value: 2000, label: '1000-2000'},
    {value: 3000, label: '2000-3000'},
    {value: 4000, label: '3000-4000'},
    {value: 5000, label: '4000-5000'},
    {value: 6000, label: '5000-6000'},
    {value: 7000, label: '6000-7000'},
    {value: 8000, label: '7000-8000'},
    {value: 9000, label: '8000-9000'},
    {value: 10000, label: '9000-10000'},
];

export const ALLOCATE_RESOURCES_DIALOG_TEXTS = {
    allocate: `To prepare application for a stable stream in auditorium for every person who participate in this
	time slot, please, select the excepted range of participants.`,
    hold: 'Are you sure you wans to hold session resources? This action cannot be undone.',
    warningMessage: 'Please note that resources allocation is a one time operation per session!',
};

export const ALLOCATE_RESOURCES_SUCCESS_MESSAGE = 'Your resources has been allocated';

export const ALLOCATE_RESOURCES_PROCESSING_MESSAGE = 'Your resources are being allocated. Please wait.';

export const ALLOCATE_RESOURCES_FAILED_MESSAGE = 'Resources allocation failed. Please try again.';

export const ALLOCATE_RESOURCES_HOLDED_MESSAGE = 'Streaming resources has been released. Thank you!';

export const GET_MEDIA_CAPTURE_ERROR_MESSAGE =
    'Please check browser access to your outputs or they are used by another software.';

// one to one calls
export const SIGNALING_STATE_TYPES = {
    stable: 'stable',
    closed: 'closed',
    connected: 'connected',
    disconnected: 'disconnected',
};

export const ETX_SESSION_SETUP_INFO_MESSAGE =
    'Session dashboard page will be available after saving time slot and allocating resources';

export const ETX_SESSION_SETUP_INCOMPLETE_INFO_MESSAGE =
    'Please allocate server resources to finish configuration set up';

export const ETX_SESSION_SETUP_ALLOCATION_FAILED = 'Resources allocation has failed';

export const ETX_SESSION_KICKED_USER_ERROR_MESSAGE = 'Not Allowed, was kicked once';

export const BUTTON_TYPES = {
    sound: 'sound',
    video: 'video',
    shareScreen: 'shareScreen',
    stopCall: 'stopCall',
};
