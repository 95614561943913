import {useSelector} from 'react-redux';
import {getListByDate, getUserId} from '../../store/selectors';
import {filterPendingMeetings, formMeetingList, getAllItemList} from '../helpers/personalAgendaHelper';

const usePersonalAgendaItems = () => {
    const userId = useSelector(getUserId);
    const listByDate = useSelector(getListByDate);

    const allItemList = getAllItemList(listByDate);
    const programList = filterPendingMeetings(allItemList);
    const meetingList = formMeetingList(allItemList);

    return {
        userId,
        programList,
        meetingList,
        allTimeslotsIdList: allItemList.map(({timeslotId}) => timeslotId),
        isProgramEmpty: programList.length === 0,
        isMeetingsEmpty: meetingList.length === 0,
    };
};

export default usePersonalAgendaItems;
