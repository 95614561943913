import * as actionTypes from '../actionTypes/appliedJobsActionTypes';
import {updateObject} from '../utilityStateFunction';

const initialState = {
    loading: false,
    jobsList: [],
};

const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.GET_JOBS_START:
            return updateObject(state, {loading: true});
        case actionTypes.GET_JOBS_SUCCESS:
            return updateObject(state, {
                loading: false,
                jobsList: payload,
            });
        case actionTypes.GET_JOBS_FAIL:
            return updateObject(state, {loading: false});

        default:
            return state;
    }
};

export default reducer;
