import React from 'react';
import classNames from 'classnames';

import Spinner from '../Spinner';
import ExhibitorBooth from '../ExhibitorBooth';
import FeaturedExhibitorsSlider from '../FeaturedExhibitorsSlider/FeaturedExhibitorsSlider';
import ExhibitorsOverlay from '../ExhibitorsOverlay';
import Wall from '../Wall';
import ShowfloorNoExhibitors from '../ShowfloorNoExhibitors/ShowfloorNoExhibitors';
import DetectOutsideClick from '../DetectOutsideClick/DetectOutsideClick';
import NotificationPresentationStarts from '../NotificationPresentationStarts/NotificationPresentationStarts';
import ExhibitorsOverlayMobile from '../ExhibitorsOverlayMobile/ExhibitorsOverlayMobile';
import ExhibitorBoothMobile from '../ExhibitorBoothMobile';
import WelcomeScreensDialog from '../WelcomeScreensDialog/WelcomeScreensDialog';
import NoBuildingAccess from '../NoBuildingAccess/NoBuildingAccess';

import {Api} from 'Api';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

import './Showfloor.scss';

export class Showfloor extends React.Component {
    state = {
        previousExhibitorLogoUrl: '',
        nextExhibitorLogoUrl: '',
        previousExhibitorCompany: '',
        nextExhibitorCompany: '',
        showExhibitorsOverlay: false,
        currentMobileTab: 'booth',
        currentSubTab: 'representatives',
        translation: null,
        showExhibitionNote: false,
    };

    componentDidMount() {
        const {
            onSetEventProtectedMenu,
            closeTopNavigation,
            onGetExhibitors,
            onGetBuildingExhibitors,
            loadingExhibitors,
            eventId,
            buildingId,
        } = this.props;

        onSetEventProtectedMenu();

        // make sure we close the top navigation when the user lands on this page
        closeTopNavigation();

        // get the exhibitors
        if (!loadingExhibitors) {
            buildingId
                ? onGetBuildingExhibitors(eventId, buildingId, this.getExhibitorPrivateBoothPreview())
                : onGetExhibitors({
                      eventId,
                      privateBoothPreview: this.getExhibitorPrivateBoothPreview(),
                      isSortingByOrder: true,
                  });
        }

        this.setShowExhibitorsNote();
        this.setLanguagesData();
    }

    componentWillUnmount() {
        // reset the active exhibitor ID when the user navigates to another page
        this.props.onSetActiveExhibitorId(null);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loadingExhibitors && !this.props.loadingExhibitors) {
            // we got the event exhibitors
            // if we want to load an exact exhibitor, we access the query parameter and skip the random functionality
            let exhibitorId;
            if (this.props.location.search.length) {
                exhibitorId = this.props.location.search.split('?')[1].split('=')[1];
            }
            // if we also reloaded the page using a socket notification (the exhibitor changed something in the booth)
            // we don't want to reload the page with a random exhibitor
            if (this.props.activeExhibitorId) {
                exhibitorId = this.props.activeExhibitorId;
                // trigger the reload data event for that exhibitor
                this.props.onGetExhibitor(this.props.activeExhibitorId);
            }
            // if we have this exhibitorId in our array of EXHIBITORS we load it
            // else we load the first one from the FEATURED EXHIBITORS ARRAY
            // this way we make sure we don't break the app if the user tried to load an Id that doesn't exist
            let validExhibitorIdIndex = this.props.exhibitors.findIndex((exhibitor) => exhibitor._id === exhibitorId);
            if (validExhibitorIdIndex !== -1) {
                this.props.onSetActiveExhibitorId(exhibitorId);
            } else {
                // set up a first exhibitor from the featured ones and save it in the store
                let featuredExhibitorsLength = this.props.featuredExhibitors.length;
                if (featuredExhibitorsLength) {
                    this.props.onSetActiveExhibitorId(this.props.featuredExhibitors[0]._id);
                } else {
                    this.props.exhibitors[0] && this.props.onSetActiveExhibitorId(this.props.exhibitors[0]._id);
                }
            }
            if (this.props.eventUsers.length && this.props.user) {
                const participantInformation = this.props.eventUsers.find(
                    (participant) => participant._id === this.props.user._id
                );
                if (participantInformation && !participantInformation.showfloorInfoSeen) {
                    this.handleSeeInfoScreen();
                    setTimeout(this.showExhibitorsOverlay, 3000);
                }
            }
            if (this.props.exhibitors && this.props.user) {
                const exhibitorInformation = this.props.exhibitors.find(
                    (exhibitor) => exhibitor.user._id === this.props.user._id
                );
                if (exhibitorInformation && !exhibitorInformation.showfloorInfoSeen) {
                    const isExhibitor = true;
                    this.handleSeeInfoScreen(isExhibitor);
                    setTimeout(this.showExhibitorsOverlay, 3000);
                }
            }
        }
        if (prevProps.activeExhibitorId !== this.props.activeExhibitorId) {
            this.setActiveExhibitorSiblingLogos();
            this.hideExhibitorsOverlay();
        }

        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    setShowExhibitorsNote = () => {
        const userRolesInEvent = this.props.user.eventRoles.find((role) => role.event.slug === this.props.event.slug);
        const showExhibitionNote = userRolesInEvent?.event?.showExhibitionNote;

        if (showExhibitionNote) {
            this.setState({
                showExhibitionNote,
            });

            this.props.onRefreshUserUserData();
        }
    };

    handleSeeInfoScreen = async (isExhibitor) => {
        const {eventId, onGetExhibitors, getEventUsersList} = this.props;

        try {
            await Api.request({
                method: 'put',
                url: `/event/${eventId}/showfloor-info-seen`,
            });

            if (isExhibitor) {
                onGetExhibitors({eventId, privateBoothPreview: this.getExhibitorPrivateBoothPreview()});
            } else getEventUsersList();
        } catch (error) {}
    };

    getCurrentExhibitorIndex = () => {
        return this.props.exhibitors.findIndex((exhibitor) => exhibitor._id === this.props.activeExhibitorId);
    };

    getPreviousExhibitorIndex = (currentExhibitorIndex) => {
        return currentExhibitorIndex === 0 ? this.props.exhibitors.length - 1 : currentExhibitorIndex - 1;
    };

    getNextExhibitorIndex = (currentExhibitorIndex) => {
        return currentExhibitorIndex === this.props.exhibitors.length - 1 ? 0 : currentExhibitorIndex + 1;
    };

    setActiveExhibitorSiblingLogos = () => {
        let currentExhibitorIndex = this.getCurrentExhibitorIndex();
        let previousExhibitorIndex = this.getPreviousExhibitorIndex(currentExhibitorIndex);
        let nextExhibitorIndex = this.getNextExhibitorIndex(currentExhibitorIndex);

        let previousExhibitor = this.props.exhibitors?.[`${previousExhibitorIndex}`];
        let nextExhibitor = this.props.exhibitors?.[`${nextExhibitorIndex}`];

        let previousExhibitorLogoUrl = previousExhibitor?.filesUrl + previousExhibitor?.booth?.logo;
        let nextExhibitorLogoUrl = nextExhibitor?.filesUrl + nextExhibitor?.booth?.logo;

        this.setState({
            previousExhibitorLogoUrl,
            nextExhibitorLogoUrl,
            previousExhibitorCompany: previousExhibitor?.company,
            nextExhibitorCompany: nextExhibitor?.company,
            previousExhibitorHasLogo: previousExhibitor?.booth?.logo,
            nextExhibitorHasLogo: nextExhibitor?.booth?.logo,
        });
    };

    previousExhibitor = () => {
        let currentExhibitorIndex = this.getCurrentExhibitorIndex();
        let previousExhibitorIndex = this.getPreviousExhibitorIndex(currentExhibitorIndex);
        let previousExhibitorId = this.props.exhibitors[previousExhibitorIndex]._id;
        this.props.onSetActiveExhibitorId(previousExhibitorId);
    };

    getExhibitorPrivateBoothPreview = () => {
        return this.props.allExhibitors.exhibitors.find(
            (exhibitor) =>
                exhibitor.user._id === this.props.user._id &&
                exhibitor.isPreviewEnabled &&
                exhibitor.status !== 'approved'
        );
    };

    nextExhibitor = () => {
        let currentExhibitorIndex = this.getCurrentExhibitorIndex();
        let nextExhibitorIndex = this.getNextExhibitorIndex(currentExhibitorIndex);
        let nextExhibitorId = this.props.exhibitors[nextExhibitorIndex]._id;
        this.props.onSetActiveExhibitorId(nextExhibitorId);
    };

    showExhibitorsOverlay = () => this.setState({showExhibitorsOverlay: true});

    hideExhibitorsOverlay = () => this.setState({showExhibitorsOverlay: false});

    handleChangeMobileTab = (tabName) => (e) => {
        this.setState({
            currentMobileTab: tabName,
        });
    };

    checkIsModerator = (allExhibitors, user) => {
        const ability = this.context;
        const {event} = this.props;
        const activeExhibitor = allExhibitors.exhibitors.find((ell) => ell._id === allExhibitors.activeExhibitorId);

        if (!activeExhibitor) return;

        const {exhibitorRepresentative} = user.eventRoles?.find((ell) => ell.event._id === event._id);
        const isRepresentative = exhibitorRepresentative?.includes(activeExhibitor._id);

        const isOwner = activeExhibitor.user._id === user._id || isRepresentative;

        const accessToAprove = ability.can(
            ROLE_ABILITIES_TYPES.COMMON.APPROVE_WALL_ABILITY,
            ROLE_FEATURES_TYPES.BOOTH_WALL_APPROVE
        );

        return isOwner || accessToAprove;
    };

    render() {
        const {event, resourcesAccess, buildingId, isLargeScreen, allExhibitors, user} = this.props;
        const {currentMobileTab, translation} = this.state;

        if (
            buildingId &&
            event.hasAccessManagement &&
            resourcesAccess !== null &&
            !resourcesAccess.buildings.includes(buildingId)
        ) {
            return <NoBuildingAccess />;
        }

        if (event.hasAccessManagement && resourcesAccess !== null && !resourcesAccess.showfloor) {
            return <div>{translation?.closedShowfloorDialog.contentPackage}</div>;
        }

        const accessToBoothWall =
            event.hasAccessManagement && resourcesAccess !== null ? resourcesAccess?.boothWall : true;
        const showWall = accessToBoothWall && (isLargeScreen || (!isLargeScreen && currentMobileTab === 'wall'));

        const isModerator = this.checkIsModerator(allExhibitors, user);

        return (
            <>
                <div
                    className={
                        'showfloor-page event-mobile-page ' +
                        (!this.props.user.showfloorInfoSeen ? 'show-info-screen' : '')
                    }
                    style={{height: '100vh'}}
                >
                    {this.props.event.owner._id !== this.props.user._id &&
                        this.state.showExhibitionNote &&
                        this.props.event.exhibitorsNoteEnabled &&
                        this.props.event.exhibitorsNote && (
                            <WelcomeScreensDialog
                                noteMedia={this.props.event.exhibitorsNote}
                                type="showExhibitionNote"
                                isImg={this.props.event.exhibitorsNoteType === 'image'}
                            />
                        )}
                    {this.props.loadingExhibitors && <Spinner />}
                    {this.props.exhibitors.length ? (
                        <>
                            {isLargeScreen ? (
                                <div
                                    className={classNames('booth', {
                                        'booth--fullwidth': !accessToBoothWall,
                                    })}
                                >
                                    {this.props.activeExhibitorId && (
                                        <ExhibitorBooth
                                            previousExhibitor={this.previousExhibitor}
                                            nextExhibitor={this.nextExhibitor}
                                            previousExhibitorLogo={this.state.previousExhibitorLogoUrl}
                                            previousExhibitorCompany={this.state.previousExhibitorCompany}
                                            previousExhibitorHasLogo={this.state.previousExhibitorHasLogo}
                                            nextExhibitorLogo={this.state.nextExhibitorLogoUrl}
                                            nextExhibitorCompany={this.state.nextExhibitorCompany}
                                            nextExhibitorHasLogo={this.state.nextExhibitorHasLogo}
                                        />
                                    )}
                                    <FeaturedExhibitorsSlider showExhibitorsOverlay={this.showExhibitorsOverlay} />
                                    <DetectOutsideClick
                                        hideExhibitorsOverlay={this.hideExhibitorsOverlay}
                                        target="exhibitorsOverlay"
                                    >
                                        <ExhibitorsOverlay
                                            hideExhibitorsOverlay={this.hideExhibitorsOverlay}
                                            showExhibitorsOverlay={this.state.showExhibitorsOverlay}
                                        />
                                    </DetectOutsideClick>
                                </div>
                            ) : (
                                <div className="booth-mobile-header">
                                    <ExhibitorsOverlayMobile
                                        hideExhibitorsOverlay={this.hideExhibitorsOverlay}
                                        openExhibitorsOverlay={this.showExhibitorsOverlay}
                                        showExhibitorsOverlay={this.state.showExhibitorsOverlay}
                                    />
                                    <div className="tabs-container">
                                        <div
                                            className={`button-tab tab ${currentMobileTab === 'booth' ? 'active' : ''}`}
                                            onClick={this.handleChangeMobileTab('booth')}
                                        >
                                            {translation?.sideMenu.booth}
                                        </div>
                                        <div
                                            className={`button-tab tab ${currentMobileTab === 'wall' ? 'active' : ''}`}
                                            onClick={this.handleChangeMobileTab('wall')}
                                        >
                                            {translation?.sideMenu.boothWall}
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!isLargeScreen && currentMobileTab === 'booth' && this.props.activeExhibitorId && (
                                <ExhibitorBoothMobile />
                            )}
                            {showWall && (
                                <div className="booth-wall-container">
                                    <div className="booth-wall-header">
                                        <span>{translation?.sideMenu.boothWall}</span>
                                    </div>
                                    <Wall withModeration={event.boothWallModeration} isModerator={isModerator} />
                                </div>
                            )}
                        </>
                    ) : (
                        <ShowfloorNoExhibitors />
                    )}
                </div>
                <NotificationPresentationStarts />
            </>
        );
    }
}
