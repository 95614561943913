import moment from 'moment';
import fileDownload from 'js-file-download';
import * as actionTypes from '../../../actionTypes/organizerActionTypes/statistics';
import {Api} from 'Api';
import {
    BUILDING_IDS,
    AUDITORIUMS_STATS_TYPES,
    POLLS_STATS_TYPES,
    EXHIBITORS_STATS_TYPES,
} from 'constants/organizer/eventStatistics';
import {boothVisitorsUpdated, connectAdminToBooth, disconnectAdminFromBooth} from 'Api/socketApi';

export const getOverallReport = (eventId) => async (dispatch, getState) => {
    const {
        organizer: {
            eventStatistics: {
                overall: {loadingStatistics},
            },
        },
    } = getState();

    dispatch(getOverallReportStart());

    try {
        const {report} = await Api.request({
            url: `/event/${eventId}/statistics/overall-report`,
            method: 'get',
            loading: loadingStatistics,
        });

        const totalsStatistics = [
            {name: 'Event Participants', value: report.participantsCount},
            {name: 'Online Users', value: report.eventOnlineUsersCount},
            {name: 'Exhibitors', value: report.exhibitorsCount},
            {name: 'Sessions', value: report.sessionsCount},
            {name: 'Representatives Chat Views', value: report.repChatsCount},
            {name: 'Booth Visits', value: report.totalBoothVisitors},
            {name: 'Auditorium Visitors', value: report.auditoriumVisitors},
            {name: 'Unregistered Users', value: report.unregisteredUsersCount || 0},
        ];

        const eventActivityStatistics = [
            {name: 'Documents Views', value: report.totalDocumentViews},
            {name: 'Booth Links Clicks', value: report.totalLinkClicks},
            {name: 'Booth Video Views', value: report.totalVideoViews},
            {name: 'One to One Calls', value: report.oneToOneCallsCount},
            //TODO: uncomment when group calls will be ready {name: 'Group Video View', value: report.groupCallsCount},
        ];

        const payload = {
            totalsStatistics,
            eventActivityStatistics,
            reportRawData: report,
        };

        dispatch(getOverallReportSuccess(payload));
    } catch (error) {
        dispatch(getOverallReportFail(error));
    }
};

const getOverallReportStart = () => ({
    type: actionTypes.GET_OVERALL_REPORT_START,
});

const getOverallReportSuccess = (payload) => ({
    type: actionTypes.GET_OVERALL_REPORT_SUCCESS,
    payload,
});

const getOverallReportFail = (payload) => ({
    type: actionTypes.GET_OVERALL_REPORT_FAIL,
    payload,
});

export const getWallsReport = (eventId, selectedBuildingId) => async (dispatch, getState) => {
    const {
        organizer: {
            eventStatistics: {
                walls: {loadingStatistics},
            },
        },
    } = getState();

    dispatch(getWallsReportStart());

    try {
        let apiUrl = `/event/${eventId}/statistics/walls-report`;
        if (selectedBuildingId !== BUILDING_IDS.ALL) {
            apiUrl = `/event/${eventId}/statistics/walls-report?buildingId=${selectedBuildingId}`;
        }

        const {report} = await Api.request({
            url: apiUrl,
            method: 'get',
            loading: loadingStatistics,
        });

        const allWallsStatistics = [
            {name: 'Live Wall Posts', value: report.liveWallTotalPostsCount},
            {name: 'Live Wall Likes', value: report.liveWallTotalLikesCount},
            {name: 'Live Wall Comments', value: report.liveWallTotalCommentsCount},
            {name: 'Live Wall Top Post Likes', value: report.liveWallTopPostLikesCount},
            {name: 'Live Wall Top Post Comments', value: report.liveWallTopPostCommentsCount},
            {name: 'Booth Walls Posts', value: report.boothWallTotalPostsCount},
            {name: 'Booth Walls Likes', value: report.boothWallTotalLikesCount},
            {name: 'Booth Walls Comments', value: report.boothWallTotalCommentsCount},
            {name: 'Booth Walls Top Post Likes', value: report.boothWallTopPostLikesCount},
            {name: 'Booth Walls Top Post Comments', value: report.boothWallTopPostCommentsCount},
            {name: 'Video Walls Posts', value: report.videoWallTotalPostsCount},
            {name: 'Video Walls Likes', value: report.videoWallTotalLikesCount},
            {name: 'Video Walls Comments', value: report.videoWallTotalCommentsCount},
            {name: 'Video Walls Top Post Likes', value: report.videoWallTopPostLikesCount},
            {name: 'Video Walls Top Post Comments', value: report.videoWallTopPostCommentsCount},
        ];
        const liveWallsStatistics = [
            {name: 'Posts', value: report.liveWallTotalPostsCount},
            {name: 'Likes', value: report.liveWallTotalLikesCount},
            {name: 'Comments', value: report.liveWallTotalCommentsCount},
            {name: 'Top post likes', value: report.liveWallTopPostLikesCount},
            {name: 'Top post comments', value: report.liveWallTopPostCommentsCount},
        ];

        const boothWallsStatistics = [
            {name: 'Posts', value: report.boothWallTotalPostsCount},
            {name: 'Likes', value: report.boothWallTotalLikesCount},
            {name: 'Comments', value: report.boothWallTotalCommentsCount},
            {name: 'Top post likes', value: report.boothWallTopPostLikesCount},
            {name: 'Top post comments', value: report.boothWallTopPostCommentsCount},
        ];

        const videoWallsStatistics = [
            {name: 'Posts', value: report.videoWallTotalPostsCount},
            {name: 'Likes', value: report.videoWallTotalLikesCount},
            {name: 'Comments', value: report.videoWallTotalCommentsCount},
            {name: 'Top post likes', value: report.videoWallTopPostLikesCount},
            {name: 'Top post comments', value: report.videoWallTopPostCommentsCount},
        ];

        const payload = {
            errorStatistics: '',
            allWallsStatistics,
            liveWallsStatistics,
            boothWallsStatistics,
            videoWallsStatistics,
            reportRawData: report,
        };

        dispatch(getWallsReportSuccess(payload));
    } catch (error) {
        dispatch(getWallsReportFail(error));
    }
};

const getWallsReportStart = () => ({
    type: actionTypes.GET_WALLS_REPORT_START,
});

const getWallsReportSuccess = (payload) => ({
    type: actionTypes.GET_WALLS_REPORT_SUCCESS,
    payload,
});

const getWallsReportFail = (payload) => ({
    type: actionTypes.GET_WALLS_REPORT_FAIL,
    payload,
});

export const getAuditoriumsReport =
    ({eventId, selectedBuildingId, auditoriumId, pagination: {page: getPage, perPage: getPerPage}}) =>
    async (dispatch, getState) => {
        const {
            organizer: {
                eventStatistics: {
                    auditoriums: {loadingStatistics},
                },
            },
        } = getState();

        dispatch(getAuditoriumsReportStart());

        try {
            const isBuildingSelected = selectedBuildingId ? `buildingId=${selectedBuildingId}&` : '';
            let apiUrl = `/event/${eventId}/statistics/auditoriums-report?${isBuildingSelected}auditoriumId=${auditoriumId}&page=${getPage}&perPage=${getPerPage}`;

            const {report, page, perPage, pages, total} = await Api.request({
                url: apiUrl,
                method: 'get',
                loading: loadingStatistics,
            });

            const auditoriumTimeslots = report.length
                ? report.map((timeslot) => {
                      const statistics = [
                          {name: 'Online Users', value: timeslot.statistics.auditoriumOnlineUsersCount},
                          {name: 'Session Attendees', value: timeslot.statistics.sessionAttendeesCount},
                          {name: 'Posts', value: timeslot.statistics.totalPostsCount},
                          {name: 'Likes', value: timeslot.statistics.totalLikesCount},
                          {name: 'Comments', value: timeslot.statistics.totalCommentsCount},
                          {name: 'Top Post Likes', value: timeslot.statistics.topPostLikesCount},
                          {name: 'Top Post Comments', value: timeslot.statistics.topPostCommentsCount},
                      ];
                      return {
                          ...timeslot,
                          statistics,
                      };
                  })
                : report;

            const usersLists = auditoriumTimeslots.map(({_id, sessionAttendeesUsers, auditoriumOnlineUsers}) => ({
                _id,
                [AUDITORIUMS_STATS_TYPES.sessionAttendeesCount]: sessionAttendeesUsers,
                [AUDITORIUMS_STATS_TYPES.auditoriumOnlineUsersCount]: auditoriumOnlineUsers,
            }));

            const getIndividualTypes = auditoriumTimeslots.map(({_id}) => ({
                _id,
                currentType: AUDITORIUMS_STATS_TYPES.auditoriumOnlineUsersCount,
            }));

            const payload = {
                auditoriumTimeslots,
                usersLists,
                individualTypes: getIndividualTypes,
                pagination: {
                    page,
                    perPage,
                    pages,
                    total,
                },
            };

            dispatch(getAuditoriumsReportSuccess(payload));
        } catch (error) {
            dispatch(getAuditoriumsReportFail(error));
        }
    };

const getAuditoriumsReportStart = () => ({
    type: actionTypes.GET_AUDITORIUMS_REPORT_START,
});

const getAuditoriumsReportSuccess = (payload) => ({
    type: actionTypes.GET_AUDITORIUMS_REPORT_SUCCESS,
    payload,
});

const getAuditoriumsReportFail = (payload) => ({
    type: actionTypes.GET_AUDITORIUMS_REPORT_FAIL,
    payload,
});

export const changeAuditoriumTimeslotStatType = (payload) => ({
    type: actionTypes.GHANGE_TIMESLOT_CURRENT_STAT_TYPE,
    payload,
});

export const clearAuditoriumTimeslots = () => ({type: actionTypes.CLEAR_AUDITORIUM_TIMESLOTS});

export const getExhibitorsReport =
    ({eventId, selectedBuildingId, currentPage, perPage}) =>
    async (dispatch, getState) => {
        const {
            organizer: {
                eventStatistics: {
                    exhibitors: {pages: pagesCount, loadingStatistics},
                },
            },
        } = getState();

        if (pagesCount !== 0 && currentPage > Math.ceil(pagesCount)) return;

        dispatch(getExhibitorsReportStart());

        try {
            let apiUrl = `/event/${eventId}/statistics/exhibitors-report?&page=${currentPage}&perPage=${perPage}`;

            if (selectedBuildingId !== BUILDING_IDS.ALL) {
                apiUrl = `/event/${eventId}/statistics/exhibitors-report?buildingId=${selectedBuildingId}&page=${currentPage}&perPage=${perPage}`;
            }

            const {report, page, pages} = await Api.request({
                url: apiUrl,
                method: 'get',
                loading: loadingStatistics,
            });

            const updatedExhibitors = report.length
                ? report.map((exhibitor) => {
                      const statistics = [
                          {
                              name: EXHIBITORS_STATS_TYPES.attendeesNowUsersCount,
                              value: 0,
                          },
                          {
                              name: EXHIBITORS_STATS_TYPES.linkClicksCount,
                              value: exhibitor.statistics.linkClicksCount,
                          },
                          {
                              name: EXHIBITORS_STATS_TYPES.documentClicksCount,
                              value: exhibitor.statistics.documentClicksCount,
                          },
                          {
                              name: EXHIBITORS_STATS_TYPES.videoViewsCount,
                              value: exhibitor.statistics.videoViewsCount,
                          },
                          {name: EXHIBITORS_STATS_TYPES.visitorsCount, value: exhibitor.statistics.visitorsCount},
                          {
                              name: 'Posts',
                              value: exhibitor.statistics.totalPostsCount,
                          },
                          {
                              name: 'Likes',
                              value: exhibitor.statistics.totalLikesCount,
                          },
                          {
                              name: 'Comments',
                              value: exhibitor.statistics.totalCommentsCount,
                          },
                          {
                              name: 'Top Post Likes',
                              value: exhibitor.statistics.topPostLikesCount,
                          },
                          {
                              name: 'Top Post Comments',
                              value: exhibitor.statistics.topPostCommentsCount,
                          },
                      ];
                      connectAdminToBooth({exhibitorId: exhibitor._id});
                      return {
                          ...exhibitor,
                          statistics,
                      };
                  })
                : report;

            const payload = {
                data: updatedExhibitors,
                page,
                pages,
            };

            dispatch(getExhibitorsReportSuccess(payload));

            boothVisitorsUpdated((err, {exhibitorId, boothOnlineUsers}) => {
                dispatch(getExhibitorAttendees({attendeesNow: {exhibitorId, boothOnlineUsers}}));
            });
        } catch (error) {
            dispatch(getExhibitorsReportFail(error));
        }
    };

const getExhibitorsReportStart = () => ({
    type: actionTypes.GET_EXHIBITORS_REPORT_START,
});

const getExhibitorsReportSuccess = (payload) => ({
    type: actionTypes.GET_EXHIBITORS_REPORT_SUCCESS,
    payload,
});

const getExhibitorsReportFail = (payload) => ({
    type: actionTypes.GET_EXHIBITORS_REPORT_FAIL,
    payload,
});

export const changeExhibitorStatType = (payload) => ({
    type: actionTypes.GHANGE_EXHIBITOR_CURRENT_STAT_TYPE,
    payload,
});

export const changeExhibitorPaginationPage = (page) => ({
    type: actionTypes.CHANGE_EXHIBITOR_PAGINATION_PAGE,
    payload: {page},
});

const getExhibitorAttendees = (payload) => ({
    type: actionTypes.GET_EXHIBITOR_ATTENDEES,
    payload,
});

export const getPollsStatistics = (eventId, buildingId, auditoriumId) => async (dispatch, getState) => {
    const {
        organizer: {
            eventStatistics: {
                polls: {loadingStatistics},
            },
        },
    } = getState();

    dispatch(getPollsStatisticsStart());

    try {
        const isBuildingSelected = buildingId !== 'all' ? `buildingId=${buildingId}&` : '';
        const isAuditoriumSelected = auditoriumId !== 'all' ? `auditoriumId=${auditoriumId}` : '';
        const apiUrl = `/event/${eventId}/statistics/polls-report?${isBuildingSelected}${isAuditoriumSelected}`;

        const {report} = await Api.request({
            url: apiUrl,
            method: 'get',
            loading: loadingStatistics,
        });

        const pollWallsStatistics = [];
        const individualTypes = [];
        report.forEach((wallReport, index) => {
            wallReport.dailyProgram.forEach((dProgram) => {
                dProgram.program.forEach((pr) => {
                    pollWallsStatistics.push({
                        _id: wallReport._id,
                        slotTitle: wallReport.name,
                        date: dProgram.date,
                        start: pr.start,
                        end: pr.end,
                        polls: pr.polls.map((poll) => {
                            individualTypes.push({
                                _id: poll._id,
                                currentType: POLLS_STATS_TYPES.pollData,
                            });
                            return {
                                ...poll,
                                options: poll.options.map((option) => ({
                                    text: option.text,
                                    votes: option.votes.length,
                                })),
                                statistics: [
                                    {name: 'Poll Time Start', value: moment(poll.publishDate).format('h:mm a')},
                                    {name: 'Poll Time End', value: moment(poll.closeDate).format('h:mm a')},
                                    {name: 'Number of Participants', value: poll.numberParticipants},
                                    {name: 'Poll Data', value: ''},
                                ],
                            };
                        }),
                    });
                    pr.polls.forEach((poll) => {});
                });
            });
        });

        const payload = {
            pollWallsStatistics,
            individualTypes,
        };

        dispatch(getPollsStatisticsSuccess(payload));
    } catch (error) {
        dispatch(getPollsStatisticsFail(error));
    }
};

const getPollsStatisticsStart = () => ({
    type: actionTypes.GET_POLLS_STATISTICS_START,
});

const getPollsStatisticsSuccess = (payload) => ({
    type: actionTypes.GET_POLLS_STATISTICS_SUCCESS,
    payload,
});

const getPollsStatisticsFail = (payload) => ({
    type: actionTypes.GET_POLLS_STATISTICS_FAIL,
    payload,
});

export const changePollTimeslotStatType = (payload) => ({
    type: actionTypes.GHANGE_POLL_TIMESLOT_CURRENT_STAT_TYPE,
    payload,
});

export const disconnectAdminFromBoothRequest = () => (dispatch, getState) => {
    const {
        organizer: {
            eventStatistics: {
                exhibitors: {data},
            },
        },
    } = getState();

    data.forEach(({_id}) => disconnectAdminFromBooth({exhibitorId: _id}));
};

export const getNetworkingReport = () => async (dispatch, getState) => {
    const {
        event: {eventId},
        organizer: {
            eventStatistics: {
                networking: {loadingStatistics},
            },
        },
    } = getState();

    dispatch(getNetworkingReportStart());

    try {
        const {
            report: {statistics, topPostsUsers},
        } = await Api.request({
            url: `/event/${eventId}/statistics/networking-report`,
            method: 'get',
            loading: loadingStatistics,
        });

        const statisticsArray = [];

        for (let key in statistics) {
            const result = {};
            result.name = key;
            result.value = statistics[key];

            statisticsArray.push(result);
        }

        const payload = {
            statistics: statisticsArray,
            topPostsUsers,
        };

        dispatch(getNetworkingReportSuccess(payload));
    } catch (error) {
        dispatch(getNetworkingReportFail(error));
    }
};

const getNetworkingReportStart = () => ({
    type: actionTypes.GET_NETWORKING_REPORT_START,
});

const getNetworkingReportSuccess = (payload) => ({
    type: actionTypes.GET_NETWORKING_REPORT_SUCCESS,
    payload,
});

const getNetworkingReportFail = (payload) => ({
    type: actionTypes.GET_NETWORKING_REPORT_FAIL,
    payload,
});

export const getEventUserReport = () => {
    return async (dispatch, getState) => {
        try {
            const {
                event: {eventId, eventSlug},
            } = getState();
            const data = await Api.request({
                url: `/event/${eventId}/export-users`,
                method: 'get',
                responseType: 'blob',
            });
            fileDownload(data, `${eventSlug}_users.xlsx`);
        } catch (error) {}
    };
};
