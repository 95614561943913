import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import * as actions from '../../../../../store/actions';
import {TEMP_EVENT_INFO_DATA_KEY} from '../../../../../constants/shared';

class RedirectOnLogin extends React.PureComponent {
    componentDidMount() {
        const query = new URLSearchParams(this.props.location.search);

        // TODO: fix
        const preselectedPackageId = query.get('packageId');

        if (preselectedPackageId) {
            this.props.onSetPreselectedPackageId(preselectedPackageId);
        }
    }

    componentDidUpdate(prevProps) {
        const {loggedIn, user, event, eventSlug, history} = this.props;

        // immediately after the user logged in, we redirect him to the appropriate page
        if (prevProps.loggedIn === false && loggedIn === true) {
            this.handleRedirect();
        }

        if (prevProps.loggedIn === true && loggedIn === false) {
            this.handleLogoutRedirect();
        }

        // if he just registered at a new event (participant / exhibitor / organizer), we redirect him
        if (prevProps.user && user && prevProps.user.eventRoles.length < user.eventRoles.length) {
            const welcomeVideoEnabled = event?.welcomeVideoEnabled;
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);
            const displayWelcomeVideo = userRolesInEvent?.event?.showWelcomeVideo;
            const welcomeVideoExist = event?.welcomeVideo || event?.welcomeVideoYoutube;
            const isNotAdminUser =
                !user.isMasterAdmin && userRolesInEvent && !userRolesInEvent.roles.includes('organizer');

            if (welcomeVideoEnabled && displayWelcomeVideo && isNotAdminUser && welcomeVideoExist) {
                history.push(`/event/${eventSlug}/welcome`);
            } else this.redirectToLastEvent();
        }

        if (prevProps.eventSlug !== eventSlug && user && loggedIn) {
            this.handleRedirect();
        }
    }

    handleRedirect = () => {
        const {
            user,
            location: {pathname, search},
            history,
            eventSlug
        } = this.props;
        const URL = pathname;

        const query = new URLSearchParams(search);
        const registerAsExhibitor = query.get('registerAsExhibitor') === 'true';
        const registerAsParticipant = query.get('registerAsParticipant') === 'true';
        const registrationKey = query.get('registrationKey');
        const iframe = query.get('iframe') === 'true';

        // on platform homepage, if he has a role at an event I redirect him to that event based on his role
        // if he doesn't have any role at any event, I redirect him to create event

        if (URL === '/') {
            const totalEventsUserRegistered = user.eventRoles.length;
            if (user.isMasterAdmin) {
                history.push('/dashboard');
            } else if (totalEventsUserRegistered === 0) {
                history.push('/create-event');
            } else {
                this.redirectToLastEvent();
            }
        }

        const userHasRoleInEvent = user.eventRoles.some((roles) => roles.event?.slug === eventSlug);
        // if he is on an event homepage, and has a role at that event, I redirect him to the event
        // had to take into account that the page can end with '/' or not
        if (URL === `/event/${eventSlug}` || URL === `/event/${eventSlug}/`) {
            if (userHasRoleInEvent) {
                this.redirectToEventByRole(eventSlug);
            }
            // if he doesn't have any role, It means that he is just a user that wants to register at the event, read the presentation etc.
            // I don't redirect him anywhere
        }

        if (
            URL === `/event/${eventSlug}/participant-registration` ||
            URL === `/event/${eventSlug}/participant-registration/` ||
            URL === `/event/${eventSlug}/exhibitor-registration/` ||
            URL === `/event/${eventSlug}/exhibitor-registration/`
        ) {
            if (userHasRoleInEvent) {
                this.redirectToEventByRole(eventSlug);
            }
            // if he doesn't have any role, It means that he is just a user that wants to finishe the registration at the event
            // I don't redirect him anywhere
        }

        // if the user is on a log in page (platform or user level)
        if (URL.includes('/login') || URL.includes('/register')) {
            // he logs in from an event page (/event/eventSlug/login)
            // he creates an account from an event page (/event/eventSlug/register)

            if (eventSlug) {
                const userHasRoleInEvent = user.eventRoles.some((roles) => roles.event.slug === eventSlug);

                if (!userHasRoleInEvent) {
                    // if he doesn't have any role, it means he is trying to register at this event
                    if (registerAsExhibitor) {
                        history.push(`/event/${eventSlug}/exhibitor-registration`);
                    } else if (registerAsParticipant) {
                        history.push(
                            `/event/${eventSlug}/participant-registration${iframe ? '?iframe=true' : ''}${
                                registrationKey ? `?registrationKey=${registrationKey}` : ''
                            }`
                        );
                    } else {
                        history.push(`/event/${eventSlug}`);
                    }
                } else {
                    this.redirectToEventByRole(eventSlug);
                }
            } else {
                // he logs in at platform level (I don't have an eventSlug in URL);
                // he registers at platform level, it will get redirected to /create-event because he doesn't have any roles;
                const totalEventsUserRegistered = user.eventRoles.length;

                if (user.isMasterAdmin) {
                    history.push('/dashboard');
                } else if (totalEventsUserRegistered === 0) {
                    history.push('/create-event');
                } else {
                    this.redirectToLastEvent();
                }
            }
        }
    };

    handleLogoutRedirect = () => {
        const URL = this.props.location.pathname;
        if (URL === '/create-event' || URL === '/create-event/') {
            this.props.history.push('/');
        }
    };

    redirectToLastEvent = () => {
        const {user, isMobile} = this.props;
        const totalEventsUserRegistered = user.eventRoles.length;
        const lastEventRegistered = user.eventRoles[totalEventsUserRegistered - 1];
        const eventRoles = lastEventRegistered.roles;
        const eventSlug = lastEventRegistered.event.slug;
        const displayWelcomeVideo = lastEventRegistered?.event?.showWelcomeVideo;
        const isNotAdminUser = !user.isMasterAdmin && !user.isOrganizer;
        const welcomeVideoEnabled = lastEventRegistered?.event?.welcomeVideoEnabled;
        const welcomeVideoExist =
            lastEventRegistered?.event?.welcomeVideo || lastEventRegistered?.event?.welcomeVideoYoutube;

        // all the users that login from mobile, are redirected to lobby
        if (displayWelcomeVideo && isNotAdminUser && welcomeVideoExist && welcomeVideoEnabled) {
            this.props.history.push(`/event/${eventSlug}/welcome`);
        } else {
            // all the users that login from mobile, are redirected to lobby
            if (isMobile) {
                this.props.history.push(`/event/${eventSlug}/lobby`);
            } else {
                if (eventRoles.includes('organizer')) {
                    this.props.onCloseTopNav();
                    this.props.history.push(`/event/${eventSlug}/dashboard`);
                } else if (eventRoles.includes('exhibitor')) {
                    this.props.history.push(`/event/${eventSlug}/exhibitor-dashboard`);
                } else if (eventRoles.includes('exhibitorRep')) {
                    this.props.history.push(`/event/${eventSlug}/lobby`);
                } else {
                    this.props.history.push(`/event/${eventSlug}/lobby`);
                }
            }
        }
    };

    redirectToEventByRole = (eventSlug) => {
        const {user, event, history, isMobile, eventInfo} = this.props;
        const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);

        //get eventInfo data from session storage after redirect from social media login
        const tempEventEvent = JSON.parse(sessionStorage.getItem(TEMP_EVENT_INFO_DATA_KEY));
        sessionStorage.setItem(TEMP_EVENT_INFO_DATA_KEY, 'null');

        const eventData = event || eventInfo || tempEventEvent;
        const welcomeVideoEnabled = eventData?.welcomeVideoEnabled;
        const displayWelcomeVideo = userRolesInEvent?.event?.showWelcomeVideo;
        const isNotAdminUser = !user.isMasterAdmin && !userRolesInEvent.roles.includes('organizer');
        const welcomeVideoExist = eventData?.welcomeVideo || eventData?.welcomeVideoYoutube;

        if (welcomeVideoEnabled && displayWelcomeVideo && isNotAdminUser && welcomeVideoExist) {
            history.push(`/event/${eventSlug}/welcome`);
        } else {
            // all the users that login from mobile, are redirected to lobby
            if (isMobile) {
                this.props.history.push(`/event/${eventSlug}/lobby`);
            } else {
                if (userRolesInEvent.roles.includes('organizer')) {
                    this.props.history.push(`/event/${eventSlug}/dashboard`);
                } else if (userRolesInEvent.roles.includes('exhibitor')) {
                    this.props.history.push(`/event/${eventSlug}/exhibitor-dashboard`);
                } else if (userRolesInEvent.roles.includes('exhibitorRep')) {
                    this.props.history.push(`/event/${eventSlug}/lobby`);
                } else {
                    this.props.history.push(`/event/${eventSlug}/lobby`);
                }
            }
        }
    };

    render() {
        return null;
    }
}

export default withRouter(
    connect(
        (state) => ({
            user: state.user.data,
            loggedIn: state.user.loggedIn,
            eventSlug: state.event.eventSlug,
            event: state.event?.data,
            eventInfo: state.event?.eventInfo,
            isMobile: state.layout.isMobile
        }),
        {
            onCloseTopNav: actions.topNavClose,
            onSetPreselectedPackageId: actions.setPreselectedPackageId
        }
    )(RedirectOnLogin)
);
