import {connect} from 'react-redux';

import * as selectors from 'store/selectors';
import * as actions from 'store/actions';
import {Showfloor} from './Showfloor';
import {AbilityContext} from 'permissionsConfig';

Showfloor.contextType = AbilityContext;

export default connect(
    (state) => ({
        eventId: selectors.getEventId(state),
        loadingEvent: selectors.getEventLoading(state),
        event: selectors.getEventData(state),
        eventUsers: selectors.getUsersList(state),
        user: selectors.getUserData(state),
        resourcesAccess: selectors.getResourcesAccess(state),
        exhibitors: selectors.getApprovedExhibitors(state),
        allExhibitors: selectors.getExhibitors(state),
        featuredExhibitors: selectors.getFeaturedExhibitors(state),
        loadingExhibitors: selectors.getExhibitorsLoading(state),
        activeExhibitorId: selectors.getActiveExhibitorId(state),
        activeChatRoomId: selectors.getUserActiveChatRoomId(state),
        isLargeScreen: selectors.getIsLargeScreen(state),
        languages: selectors.getLanguages(state),
        isRtlLanguage: selectors.getIsRtlLanguage(state),
        eventUsersList: selectors.getUsersList(state),
        buildingId: selectors.getBuildingId(state),
        building: selectors.getBuildingData(state),
        translation: selectors.getTranslation(state),
    }),
    {
        onGetExhibitors: actions.getExhibitors,
        onGetBuildingExhibitors: actions.getBuildingExhibitors,
        onGetExhibitor: actions.getExhibitor,
        getEventUsersList: actions.getEventUsersList,
        onSetActiveExhibitorId: actions.setActiveExhibitorId,
        closeTopNavigation: actions.topNavClose,
        onRefreshUserUserData: actions.refreshUserData,
        onSetEventProtectedMenu: actions.setEventProtectedMenu,
    }
)(Showfloor);
