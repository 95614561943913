import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {makeStyles} from '@material-ui/core/styles';

function CustomTableHeader({order, orderBy, onRequestSort, columns, withSorting}) {
    const createSortHandler = (property) => (event) => (withSorting ? onRequestSort(event, property) : false);

    const useStyles = makeStyles(() => ({
        cellRoot: {
            fontFamily: 'Montserrat',
            fontSize: '16px',
            textTransform: 'uppercase',
            color: '#777081', // TODO: color
            backgroundColor: '#EFEEF1', // TODO: color
        },
        label: {
            cursor: withSorting ? 'pinter' : 'default',
        },
        icon: {
            fontSize: '26px',
        },
    }));

    const classes = useStyles();

    return (
        <TableHead>
            <TableRow>
                {columns.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        style={{minWidth: column.minWidth, maxWidth: column.maxWidth}}
                        variant="head"
                        className={classes.cellRoot}
                    >
                        <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'desc'}
                            onClick={createSortHandler(column.id)}
                            IconComponent={ExpandMoreIcon}
                            classes={{icon: classes.icon, root: classes.label}}
                            hideSortIcon={!withSorting}
                        >
                            {column.label}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

CustomTableHeader.propTypes = {
    columns: PropTypes.array.isRequired,
    numSelected: PropTypes.number,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number,
    withSorting: PropTypes.bool,
};

export default CustomTableHeader;
