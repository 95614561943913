import React from 'react';

import FormGroup from '../../../../../../../../../FormGroup/FormGroup';
import BusinessCardLogoUploadingPreview from './components/Preview/Preview';

import {useMedia, usePlatformTranslation} from '../../../../../../../../../../../services/hooks';

const UploadingField = ({businessCard}) => {
    const isTablet = useMedia('(max-width: 960px)');
    const {businessCard: businessCardTranslation, marketplace, errors} = usePlatformTranslation();

    if (isTablet) {
        return (
            <div className="BusinessCardLogoUploading__field">
                <BusinessCardLogoUploadingPreview media={businessCard.logo.media} />

                <div className="BusinessCardLogoUploading__uploadedInfo">
                    <div className="BoothMarketplaceImageField__uploadedKey">{marketplace.uploaded}:</div>

                    <div className="BoothMarketplaceImageField__uploadedValue">
                        {businessCard.logo.media?.title || errors.noFile}
                    </div>
                </div>

                <div className="BusinessCardLogoUploading__fieldComment">{businessCardTranslation.uploadMessage}</div>
            </div>
        );
    }

    return (
        <div className="BusinessCardLogoUploading__field">
            <FormGroup verticalAlignment="top">
                <FormGroup.HalfItem>
                    <BusinessCardLogoUploadingPreview media={businessCard.logo.media} />
                </FormGroup.HalfItem>

                <FormGroup.HalfItem>
                    <div className="BusinessCardLogoUploading__fieldComment">
                        {businessCardTranslation.uploadMessage}
                    </div>

                    <div className="BusinessCardLogoUploading__uploadedInfo">
                        <div className="BoothMarketplaceImageField__uploadedKey">{marketplace.uploaded}:</div>

                        <div className="BoothMarketplaceImageField__uploadedValue">
                            {businessCard.logo.media?.title || errors.noFile}
                        </div>
                    </div>
                </FormGroup.HalfItem>
            </FormGroup>
        </div>
    );
};

export default UploadingField;
