import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import {isMobileSafari, isSafari, isOpera, isIOS} from 'react-device-detect';
import ReactPlayer from 'react-player';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import {
    VolumeMute as VolumeMuteIcon,
    VolumeUp as VolumeUpIcon,
    Fullscreen as FullscreenIcon,
    FullscreenExit as FullscreenExitIcon,
    PlayArrow as PlayArrowIcon,
    Pause as PauseIcon,
    FastForward as FastForwardIcon,
    FastRewind as FastRewindIcon
} from '@material-ui/icons';

import {ReactComponent as SliderArrow} from 'Images/slider-arrow.svg';

import AuditoriumOverlayPlayer from '../AuditoriumOverlayPlayer';
import AuditoriumPageContainer from '../AuditoriumPageContainer';
import AuditoriumBackgroundImage from '../AuditoriumBackgroundImage/AuditoriumBackgroundImage';
import AuditoriumBanners from '../AuditoriumBanners/AuditoriumBanners';
import BreadcrumbsNavigation from '../BreadcrumbsNavigation';
import NoBuildingAccess from '../NoBuildingAccess/NoBuildingAccess';

import {ETX, ZOOM, ETX_LIVE_SESSION} from 'constants/data/streaming-engine';
import {sessionFolder} from 'config';
import {SESSION_TYPES} from 'constants/data/session-type';
import {generatePath} from 'react-router';
import {RouterConfig} from 'routerConfig';
import AuditoriumWallNavigation from '../Auditorium/components/AuditoriumWallNavigation';
import {wallTabsList} from 'constants/auditorium';

const RECORDINGS_SLIDER_DIRECTIONS = {
    PREV: 'previous',
    NEXT: 'next'
};

class AuditoriumArhiveSingleVideo extends React.Component {
    fullscreenTimerId = 0;

    isRedirectedOnDemandRef = React.createRef(false);

    state = {
        timeSlotId: null,
        timeSlot: null,
        timeSlotIndex: null,
        auditorumSpeachLanguage: null,
        isInterpretationEnabled: null,
        fullscreen: false,
        playing: true,
        muteVideo: false,
        currentTab: wallTabsList.WALL,
        translation: null,
        avcoreRecords: [],
        zoomRecords: false,
        recordsActiveIndex: 0
    };

    componentDidMount() {
        const {onSetEventProtectedMenu, onGetOnDemantVideos, location, match} = this.props;

        onSetEventProtectedMenu();
        onGetOnDemantVideos();

        const {timeslotId} = match.params;
        const isRedirectedOnDemand = location.state?.isRedirectedOnDemand || this.isRedirectedOnDemandRef.current;

        if (isRedirectedOnDemand) {
            this.isRedirectedOnDemandRef.current = isRedirectedOnDemand;
            this.setState({currentTab: wallTabsList.ON_DEMAND});
        }

        this.setState({
            timeSlotId: timeslotId
        });

        this.loadCurrentTimeSlot(timeslotId);

        document.addEventListener('webkitfullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('mozfullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('fullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('MSFullscreenChange', this.toggleFullScreenEscKey, false);

        this.setLanguagesData();
    }

    componentWillUnmount() {
        this.props.closeSidebar();
        if (document.fullscreenElement) {
            document.exitFullscreen().then(this.exitFullscreen());
        }
        document.removeEventListener('webkitfullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('mozfullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('fullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('MSFullscreenChange', this.toggleFullScreenEscKey, false);
    }

    componentDidUpdate(prevProps) {
        const {timeslotId} = this.props.match.params;
        if (this.state.timeSlotId !== timeslotId) {
            this.setState({
                timeSlotId: timeslotId
            });
            this.loadCurrentTimeSlot(timeslotId);
        }

        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation
        });
    };

    toggleFullScreenEscKey = () => {
        let fullscreenElement =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullscreenElement ||
            document.msFullscreenElement;
        if (!fullscreenElement) {
            this.exitFullscreen();
        }
    };

    toggleFullScreen = () => {
        if (document.fullscreenElement) {
            document
                .exitFullscreen()
                .then(() => this.exitFullscreen())
                .catch((err) => console.error(err));
        } else {
            document.documentElement.requestFullscreen().then(() => this.startFullscreen());
        }
    };

    startFullscreen = () => {
        this.setState({fullscreen: true});
        this.hideVideoButtons();

        document.body.classList.add('fullscreen-video');
        document.getElementById('js-auditorium-page').classList.add('fullscreen');
        window.addEventListener('mousemove', this.mouseMove);
    };

    exitFullscreen = () => {
        this.setState({fullscreen: false});
        this.showVideoButtons();
        clearTimeout(this.fullscreenTimerId);

        document.body.classList.remove('fullscreen-video');
        document.getElementById('js-auditorium-page').classList.remove('fullscreen');
        window.removeEventListener('mousemove', this.mouseMove);
    };

    handleChangeTab = (tabName) => {
        this.setState({
            currentTab: tabName
        });
    };

    loadCurrentTimeSlot = (timeslotId) => {
        const {
            event,
            building,
            buildingId,
            getZoomStreamRecordings,
            setHasVideoWall,
            setActiveWall,
            setTimeSlotVideoWall,
            seeVideoWall,
            history
        } = this.props;
        const dataSource = building || event;

        if (event === null) return;

        let auditorumID = null;
        let auditorumData = null;
        let auditorumSpeachLanguage = null;
        let isInterpretationEnabled = null;

        const allAuditoriumsProgram = dataSource.auditoriums.reduce((acc, auditorium) => {
            const auditoriumPrograms = auditorium.dailyProgram.reduce((acc, dayProgram) => {
                dayProgram.program.forEach((item) => {
                    if (item._id === timeslotId) {
                        auditorumData = auditorium;
                        auditorumID = auditorium._id;
                        auditorumSpeachLanguage = auditorium.interpretationSourceLanguage;
                        isInterpretationEnabled = auditorium.isInterpretationEnabled;
                    }
                });
                return [...acc, ...dayProgram.program];
            }, []);
            return [...acc, ...auditoriumPrograms];
        }, []);

        let currentTimeSlot = allAuditoriumsProgram.find((timeSlot) => timeSlot._id === timeslotId);
        let currentTimeSlotIndex = allAuditoriumsProgram.findIndex((timeSlot) => timeSlot._id === timeslotId);

        if (!currentTimeSlot) {
            history.push(
                generatePath(building ? RouterConfig.event.village.buildingLobby : RouterConfig.event.lobby, {
                    eventSlug: event.slug,
                    buildingId
                })
            );
            return;
        }

        if (currentTimeSlot?.session) {
            this.loadSessionRecords(currentTimeSlot.session._id);
            this.setState({zoomRecords: false});
        } else if (currentTimeSlot.streamingEngine === ZOOM || currentTimeSlot.streamingEngine === ETX_LIVE_SESSION) {
            getZoomStreamRecordings({
                auditoriumId: auditorumID,
                timeslotId,
                callback: () => this.setState({zoomRecords: true})
            });
        } else {
            this.setState({zoomRecords: false});
        }

        this.setState({
            timeSlot: currentTimeSlot,
            timeSlotIndex: currentTimeSlotIndex,
            auditorumID,
            auditorum: auditorumData,
            auditorumSpeachLanguage,
            isInterpretationEnabled,
            avcoreRecords: []
        });

        // we set up the sideMenu to have videoWall
        setHasVideoWall(true);

        // we set up the current time slot video Id
        const activeVideoWallId = currentTimeSlot.videoWall;

        let highlightUsers = [];
        currentTimeSlot.speakers.forEach((speaker) => {
            if (_.isString(speaker)) highlightUsers.push(speaker);
            else if (speaker && speaker.user && speaker.user._id) highlightUsers.push(speaker.user._id);
        });

        // we set up the current wall Id to be the video Wall Id
        const wallData = {
            wallId: activeVideoWallId,
            highlightUsers
        };
        setTimeSlotVideoWall(wallData);
        setActiveWall(wallData);

        // we open the sideBar to the videoWall
        seeVideoWall();
    };

    loadSessionRecords = (sessionId) => {
        const {getSessionRecordings} = this.props;

        const recordings = getSessionRecordings(sessionId);

        if (recordings) {
            const records = recordings.map((r) => r.url);
            this.setState({avcoreRecords: records});
        }
    };

    videoHasEnded = (length) => {
        const {recordsActiveIndex} = this.state;
        const lastIndex = length - 1;

        this.setState({
            recordsActiveIndex: recordsActiveIndex < lastIndex ? recordsActiveIndex + 1 : recordsActiveIndex
        });

        if (recordsActiveIndex === lastIndex) {
            this.exitFullscreen();
            this.setState({playing: false});
        }
    };

    escFunction = (e) => {
        if (e.key === 'Escape') this.exitFullscreen();
    };

    mouseMove = () => {
        clearTimeout(this.fullscreenTimerId);
        this.showVideoButtons();
        this.fullscreenTimerId = setTimeout(this.hideVideoButtons, 3000);
    };

    showVideoButtons = () => {
        if (document.getElementById('videoButtons')) {
            document.getElementById('videoButtons').classList.remove('hide');
        }
    };

    hideVideoButtons = () => {
        if (document.getElementById('videoButtons')) {
            document.getElementById('videoButtons').classList.add('hide');
        }
    };

    skipForward = () => {
        this.player.seekTo(Math.floor(this.player.getCurrentTime()) + 30, 'seconds');
    };

    skipBackward = () => {
        this.player.seekTo(Math.floor(this.player.getCurrentTime()) - 30, 'seconds');
    };

    ref = (player) => {
        this.player = player;
    };

    handleChangeRecording = (direction) => () => {
        const {recordsActiveIndex} = this.state;

        this.setState({
            recordsActiveIndex:
                direction === RECORDINGS_SLIDER_DIRECTIONS.NEXT ? recordsActiveIndex + 1 : recordsActiveIndex - 1
        });
    };

    render() {
        const {event, building, isLargeScreen, resourcesAccess, recordedZoomSessions} = this.props;
        const {currentTab, timeSlot, translation, zoomRecords, playing, recordsActiveIndex} = this.state;

        const hideVideo = !isLargeScreen && currentTab !== wallTabsList.VIDEO;

        // 31.08.2020 currently the fullscreen api is not supported on Safari iPhone
        const hideFullscreenButton = isMobileSafari || isSafari || isOpera || isIOS;
        const recordedVideo =
            this.state.timeSlot?.session?.link && this.state.timeSlot?.session?.type === SESSION_TYPES.recorded
                ? `${sessionFolder}${this.state.timeSlot.session.link}`
                : null;
        const videoUrl =
            this.state.timeSlot?.streamingEngine === ETX && !this.state.timeSlot?.isLiveStreaming
                ? `${sessionFolder}${this.state.timeSlot?.video}`
                : this.state.timeSlot?.video;

        let records = [];

        if (timeSlot && !zoomRecords) {
            records = _.compact([videoUrl || null, recordedVideo, ...this.state.avcoreRecords]);
        } else if (timeSlot && zoomRecords) {
            records = recordedZoomSessions.map(({link}) => link);
        }

        if (
            event.hasAccessManagement &&
            resourcesAccess !== null &&
            !resourcesAccess.buildings.includes(building._id)
        ) {
            return <NoBuildingAccess />;
        }

        return (
            <>
                <div className="position-background">
                    <BreadcrumbsNavigation className={'white'} />
                    {this.state.timeSlotId && (
                        <>
                            <AuditoriumPageContainer>
                                {isLargeScreen && (
                                    <AuditoriumBackgroundImage
                                        filesUrl={event.brandingData.filesUrl}
                                        brandingData={building || event.brandingData}
                                    />
                                )}

                                {!isLargeScreen && (
                                    <div className="page-title">{translation?.auditoriumArchive.title}</div>
                                )}
                                {isLargeScreen && (
                                    <div className="slot-title">
                                        <p className={'title '}>
                                            {this.state.timeSlot?.speakersExtended?.length ? (
                                                <>
                                                    {this.state.timeSlot.speakersExtended.map((speaker, index) => {
                                                        return (
                                                            <span key={speaker._id}>
                                                                {speaker.fullName}
                                                                {index ===
                                                                this.state.timeSlot?.speakersExtended?.length - 1 ? (
                                                                    <span className="subtitle">
                                                                        {' '}
                                                                        {translation?.auditoriumArchive.with}{' '}
                                                                    </span>
                                                                ) : (
                                                                    ', '
                                                                )}
                                                            </span>
                                                        );
                                                    })}
                                                </>
                                            ) : null}
                                            {this.state.timeSlot.title}
                                        </p>
                                    </div>
                                )}
                                <AuditoriumWallNavigation
                                    isOnDemandShowing={this.isRedirectedOnDemandRef.current}
                                    currentTimeSlot={this.state.timeSlot}
                                    currentTab={currentTab}
                                    auditorium={this.state.auditorum}
                                    isInterpretationEnabled={this.state.isInterpretationEnabled}
                                    onChangeTab={this.handleChangeTab}
                                    isArchive
                                />

                                <div className={`centerOfPage ${hideVideo ? 'hide' : ''}`}>
                                    <div className="relativeCenter">
                                        {isLargeScreen && (
                                            <AuditoriumBanners
                                                filesUrl={event.brandingData.filesUrl}
                                                brandingData={event.brandingData}
                                            />
                                        )}
                                        <div className={'videoWrapper ' + (this.state.fullscreen ? 'fullscreen' : '')}>
                                            <div className="video-container">
                                                {records.length > 1 && (
                                                    <div className="records-slider">
                                                        <div
                                                            className={classNames('arrow previous', {
                                                                disabled: recordsActiveIndex === 0
                                                            })}
                                                            onClick={this.handleChangeRecording(
                                                                RECORDINGS_SLIDER_DIRECTIONS.PREV
                                                            )}
                                                        >
                                                            <SliderArrow />
                                                        </div>
                                                        <div
                                                            className={classNames('arrow next', {
                                                                disabled: recordsActiveIndex === records.length - 1
                                                            })}
                                                            onClick={this.handleChangeRecording(
                                                                RECORDINGS_SLIDER_DIRECTIONS.NEXT
                                                            )}
                                                        >
                                                            <SliderArrow />
                                                        </div>
                                                    </div>
                                                )}
                                                {!!records.length ? (
                                                    <ReactPlayer
                                                        ref={this.ref}
                                                        url={records[recordsActiveIndex]}
                                                        playing={playing}
                                                        className="react-player"
                                                        controls={false}
                                                        width="100%"
                                                        height="100%"
                                                        volume={this.state.muteVideo ? 0 : 1}
                                                        onEnded={() => this.videoHasEnded(records.length)}
                                                        onContextMenu={(e) => e.preventDefault()}
                                                    />
                                                ) : (
                                                    <div className="placeholder-container empty-recordings">
                                                        <p>The Recording is being processed. Please check it back.</p>
                                                    </div>
                                                )}
                                                <AuditoriumOverlayPlayer />
                                            </div>
                                            <div
                                                className="video-actions-container"
                                                id="videoButtons"
                                                style={this.state.auditoriumButtonsStyle}
                                            >
                                                <button
                                                    onClick={this.toggleFullScreen}
                                                    className={`${hideFullscreenButton ? 'd-none' : ''}`}
                                                >
                                                    {this.state.fullscreen ? (
                                                        <Tooltip title={translation?.auditoriumArchive.tooltipMinimize}>
                                                            <FullscreenExitIcon fontSize="inherit" />
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip
                                                            title={translation?.auditoriumArchive.tooltipFullscreen}
                                                        >
                                                            <FullscreenIcon fontSize="inherit" />
                                                        </Tooltip>
                                                    )}
                                                </button>
                                                <Tooltip title="-30 sec">
                                                    <button onClick={() => this.skipBackward()}>
                                                        <FastRewindIcon fontSize="inherit" />
                                                    </button>
                                                </Tooltip>
                                                <button onClick={() => this.setState({playing: !this.state.playing})}>
                                                    {this.state.playing ? (
                                                        <Tooltip title={translation?.auditoriumArchive.tooltipPause}>
                                                            <PauseIcon fontSize="inherit" />
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title={translation?.auditoriumArchive.tooltipPlay}>
                                                            <PlayArrowIcon fontSize="inherit" />
                                                        </Tooltip>
                                                    )}
                                                </button>
                                                <Tooltip title="+30 sec">
                                                    <button onClick={() => this.skipForward()}>
                                                        <FastForwardIcon fontSize="inherit" />
                                                    </button>
                                                </Tooltip>
                                                <button
                                                    onClick={() => this.setState({muteVideo: !this.state.muteVideo})}
                                                >
                                                    {this.state.muteVideo ? (
                                                        <Tooltip title={translation?.auditoriumArchive.tooltipUnmute}>
                                                            <VolumeUpIcon fontSize="inherit" />
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title={translation?.auditoriumArchive.tooltipMute}>
                                                            <VolumeMuteIcon fontSize="inherit" />
                                                        </Tooltip>
                                                    )}
                                                </button>
                                            </div>
                                        </div>
                                        {!isLargeScreen && (
                                            <div className="slot-title">
                                                <p className="title ">{timeSlot.title}</p>
                                                <p className="subtitle">Subtitle</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </AuditoriumPageContainer>
                        </>
                    )}
                </div>
            </>
        );
    }
}

export default AuditoriumArhiveSingleVideo;
