import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import classNames from 'classnames';

import VideojsPlayer from '../VideojsPlayer/VideojsPlayer';

import {getInterpretation, getTimeslotData} from 'store/selectors';
import {ETX_LIVE_SESSION} from 'constants/data/streaming-engine';

import './StreamingVideoContainer.scss';

const StreamingVideoContainer = ({
    sessionId,
    sessionKey,
    gatewayName,
    muted = false,
    className,
    interpretationSourceLanguage,
    withInterpretation
}) => {
    const interpretation = useSelector(getInterpretation);
    const timeslot = useSelector(getTimeslotData);

    const defaultDomain = 'event10x-video-nightly.softermii.co';

    let sourceLink = `https://${gatewayName || defaultDomain}/hls/${sessionId}.m3u8`;

    if (timeslot?.streamingEngine === ETX_LIVE_SESSION && interpretation.activeLanguage !== 'default') {
        sourceLink = `https://${gatewayName || defaultDomain}/hls/${sessionId}_translated_${
            interpretation.activeLanguage
        }.m3u8`;
    }

    return (
        <div className={classNames('module-video_container', 'VideoContainer', className)}>
            <VideojsPlayer
                withInterpretation={withInterpretation}
                interpretationSourceLanguage={interpretationSourceLanguage}
                className="video VideoContainer__video"
                muted={muted}
                sourceLink={sourceLink}
            />
        </div>
    );
};

StreamingVideoContainer.propTypes = {
    sessionId: PropTypes.string.isRequired,
    sessionKey: PropTypes.string.isRequired,
    gatewayName: PropTypes.string.isRequired,
    muted: PropTypes.bool,
    className: PropTypes.string,
    interpretationSourceLanguage: PropTypes.string,
    withInterpretation: PropTypes.bool
};

export default StreamingVideoContainer;
