import React from 'react';
import {useSelector} from 'react-redux';
import {create} from 'jss';
import rtl from 'jss-rtl';
import {StylesProvider, jssPreset, createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';

const jss = create({plugins: [...jssPreset().plugins, rtl()]});

export function RTLWrapper({children}) {
    const isRtl = useSelector((state) => state.languages.isRtlLanguage);
    const theme = createMuiTheme({
        direction: isRtl ? 'rtl' : 'ltr',
    });

    return (
        <StylesProvider jss={jss}>
            <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
        </StylesProvider>
    );
}
