import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: 'none',
        boxShadow: '0px 14px 24px rgba(32, 15, 54, 0.25)',
        borderRadius: 6,
        padding: theme.spacing(2, 4, 3),
        position: 'relative',
    },
    iconButton: {
        width: 20,
        height: 20,
        minWidth: 20,
        minHeight: 20,
        color: theme.palette.grey.A400,
    },
    closeButton: {
        position: 'absolute',
        top: 0,
        right: 0,
        padding: 7,
        cursor: 'pointer',
    },
    shareIcon: {
        width: 20,
    },
}));
