import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import 'react-tabs/style/react-tabs.css';

import HighlightRectangleText from '../HighlightRectangleText/HighlightRectangleText';
import OnventLookSlider from './components/OnventLookSlider/OnventLookSlider';
import HomepageVideo from './components/HomepageVideo/HomepageVideo';
import OnventTestimonialsSlider from './components/OnventTestimonialsSlider/OnventTestimonialsSlider';
import PlatformCards from './components/PlatformCards/PlatformCards';
import ContactForm from './components/ContactForm';
import AnimatedTopSection from './components/AnimatedTopSection';
import PlatformFooter from './components/PlatformFooter';

import {ReactComponent as ImageBlack} from 'Images/pages/platform-homepage/event10x-footer-image.svg';
import * as actions from 'store/actions';
import {whiteLabel} from 'config';
import {homepagePlatformConfig} from './config/config.homepagePlatform';
import MainRectangleText from './components/MainRectangleText/MainRectangleText';
import SubRectangleText from './components/SubRectangleText/SubRectangleText';
import UpcomingEvents from './components/UpcomingEvents';

import './HomepagePlatform.scss';

const HomepagePlatform = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(actions.setPlatformMenu());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const TopImage = homepagePlatformConfig[whiteLabel].TopImage;
    const TopImageMobile = homepagePlatformConfig[whiteLabel].TopImageMobile;
    const TopImageTablet = homepagePlatformConfig[whiteLabel].TopImageTablet;
    const FooterImage = homepagePlatformConfig[whiteLabel].FooterImage;

    return (
        <div className="platform-homepage">
            <AnimatedTopSection />
            <div className="sections-wrapper">
                <section className="top-section">
                    <img
                        src={TopImage}
                        srcSet={`${TopImage} 1366w, ${TopImageTablet} 1024w, ${TopImageMobile} 768w`}
                        className="full-image"
                        alt=""
                    />
                    <div className="image-gradient-overlay-purple"></div>
                    <div className={`section-heading section-heading--${whiteLabel}`}>
                        <h1>
                            <MainRectangleText>{homepagePlatformConfig[whiteLabel].title.text}</MainRectangleText>
                        </h1>
                        <h2>
                            <SubRectangleText>{homepagePlatformConfig[whiteLabel].secondTitle.text}</SubRectangleText>
                        </h2>
                    </div>
                    {homepagePlatformConfig[whiteLabel].isBanner && (
                        <div className="banner mobile">
                            <p>
                                You are now inside EVENTS <span className="red">1</span>0<span className="red">X</span>{' '}
                                Virtual Venue.{' '}
                            </p>
                            <p>
                                To visit the Website, please click{' '}
                                <a
                                    href="https://www.events10x.com/"
                                    className="red"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    HERE
                                </a>
                            </p>
                        </div>
                    )}
                </section>
                <UpcomingEvents />
                {homepagePlatformConfig[whiteLabel].onlyFooter ? (
                    <section className="onvent-form">
                        <img src={FooterImage} className="full-image" alt="" />
                        <div className="full-image-overlay" />
                        <div className="container">
                            <PlatformFooter />
                        </div>
                    </section>
                ) : (
                    <>
                        <section className="onvent-look">
                            <div className="container">
                                <h3>
                                    <span className="before-highlight-text">Check out</span>{' '}
                                    <HighlightRectangleText>NEW Events10X</HighlightRectangleText>
                                </h3>
                            </div>
                        </section>
                        <OnventLookSlider />

                        <section className="onvent-video-presentation-title">
                            <div className="container">
                                <h3>
                                    <span className="before-highlight-text">Events10X - online events that your</span>{' '}
                                    <HighlightRectangleText>participants will love.</HighlightRectangleText> Here is
                                    why:
                                </h3>
                            </div>
                        </section>
                        <HomepageVideo />

                        <section className="onvent-testimonials">
                            <div className="container">
                                <h3>
                                    <span className="before-highlight-text">Online events - </span>
                                    <HighlightRectangleText>it's all about people</HighlightRectangleText>...
                                </h3>
                            </div>
                        </section>
                        <OnventTestimonialsSlider />

                        <section className="onvent-cards">
                            <div className="container">
                                <h3>
                                    <span className="before-highlight-text">Events10X brings</span>{' '}
                                    <HighlightRectangleText>the WOW</HighlightRectangleText> to...
                                </h3>
                                <PlatformCards />
                            </div>
                        </section>

                        <section className="onvent-form">
                            <ImageBlack className="full-image" />
                            {/* <FooterImage /> */}
                            <div className="full-image-overlay" />
                            <div className="container">
                                <h3>
                                    <span className="before-highlight-text">
                                        Interested in organizing an online event?
                                    </span>{' '}
                                    <HighlightRectangleText>Get in touch</HighlightRectangleText>
                                </h3>
                                <ContactForm />
                                <PlatformFooter />
                            </div>
                        </section>
                    </>
                )}
            </div>
        </div>
    );
};

export default HomepagePlatform;
