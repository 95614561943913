import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import {makeStyles} from '@material-ui/core/styles';

import CustomTableHeader from './components/CustomTableHeader';
import RTLWrapper from './components/RTLWrapper';

import {getComparator, stableSort} from './helpers';
import {usePlatformTranslation} from '../../../services/hooks';

const useStyles = makeStyles({
    root: {
        width: '100%',
        boxSizing: 'border-box',
        filter: 'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2))',
        borderRadius: '6px',
    },
    tableContainer: {
        maxHeight: '600px',
    },
    cellRoot: {
        fontFamily: 'Montserrat',
        color: '#2B2B2B',
        textTransform: 'capitalize',
    },
    caption: {
        direction: 'ltr',
    },
});

function CustomTable({
    data,
    columns,
    rowsPerPage,
    rowsPerPageOptions,
    component,
    withSorting,
    sortOrder,
    sortOrderBy,
    withRowHover,
}) {
    const translation = usePlatformTranslation();

    const isRtl = useSelector((state) => state.languages.isRtlLanguage);
    const [page, setPage] = useState(0);
    const [perPage, setRowsPerPage] = useState(rowsPerPage);
    const [order, setOrder] = useState(sortOrder);
    const [orderBy, setOrderBy] = useState(sortOrderBy);
    const classes = useStyles({isRtl});

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    return (
        <Paper
            classes={{
                root: classes.root,
            }}
        >
            <TableContainer
                classes={{
                    root: classes.tableContainer,
                }}
            >
                <Table stickyHeader aria-label="sticky table">
                    <CustomTableHeader
                        columns={columns}
                        order={order}
                        orderBy={orderBy}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                        withSorting={withSorting}
                    />
                    <TableBody>
                        {data.length ? (
                            stableSort(data, getComparator(order, orderBy))
                                .slice(page * perPage, page * perPage + perPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover={withRowHover} tabIndex={-1} key={row._id || row.id}>
                                            {columns.map((column) => {
                                                const value =
                                                    column.name === 'status'
                                                        ? translation.appliedJobs.statusList[row[column.name]]
                                                        : row[column.name];
                                                return (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{minWidth: column.minWidth, maxWidth: column.maxWidth}}
                                                        className={classes.cellRoot}
                                                    >
                                                        {typeof column.render === 'function'
                                                            ? column.render(row)
                                                            : column.format
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })
                        ) : (
                            <TableRow />
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <RTLWrapper>
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component={component}
                    count={data.length}
                    rowsPerPage={perPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    classes={{
                        caption: classes.caption,
                    }}
                    labelRowsPerPage={`Rows per page${isRtl ? '' : ':'}`}
                />
            </RTLWrapper>
        </Paper>
    );
}

CustomTable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
    rowsPerPageOptions: PropTypes.array,
    component: PropTypes.string,
    sortOrder: PropTypes.string,
    sortOrderBy: PropTypes.string,
    withSorting: PropTypes.bool,
    withRowHover: PropTypes.bool,
};

CustomTable.defaultProps = {
    data: [],
    columns: [],
    rowsPerPageOptions: [10, 25, 100],
    component: 'div',
    sortOrder: 'asc',
    sortOrderBy: '',
    withSorting: true,
    withRowHover: true,
};

export default CustomTable;
