import classNames from 'classnames';
import React from 'react';
import {ReactComponent as SliderArrow} from '../../../../../../../../../Images/slider-arrow.svg';
import './AuditoriumArrow.scss';

export function AuditoriumArrow({onClick, isNext = false}) {
    return (
        <div onClick={onClick}>
            <div
                className={classNames(
                    'programAuditoriumArrow',
                    isNext ? 'programAuditoriumArrow--next' : 'programAuditoriumArrow--prev'
                )}
            >
                <div>
                    <SliderArrow
                        className={classNames('programAuditoriumArrow__arrowIcon', {
                            'programAuditoriumArrow__arrowIcon--next': isNext,
                        })}
                    />
                </div>
            </div>
        </div>
    );
}
