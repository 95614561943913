import SendPrivateChatMessage from './SendPrivateChatMessage';
import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export default connect(
    (state) => ({
        eventChats: selectors.getUserEventChats(state),
        privateChats: selectors.getUserPrivateChats(state),
        eventId: selectors.getEventId(state),
        activeChatRoomId: selectors.getUserActiveChatRoomId(state),
        isLargeScreen: selectors.getIsLargeScreen(state),
        languages: selectors.getLanguages(state),
        archivedChatsTab: selectors.getUserArchivedChats(state),
        eventChatsTab: selectors.getUserEventChats(state),
        onlineUsers: selectors.getOnlineUsers(state),
        activeChatData: selectors.getUserActiveChatData(state),
        incomingCallUserId: selectors.getIncomingCallUserId(state),
    }),
    {
        onArchiveChat: actions.archiveChat,
        onUpdateActiveChatTab: actions.updateActiveChatTab,
        sendMessageInPrivateChat: actions.sendMessageInPrivateChat,
    }
)(SendPrivateChatMessage);
