import React, {useEffect} from 'react';
import classNames from 'classnames';

import AccountNavigationMenu from './components/AccountNavigationMenu/AccountNavigationMenu';
import AccountPagesScene from './components/AccountPagesScene/AccountPagesScene';
import AccountGrid from './components/AccountGrid';
import AccountNavigationMenuMobile from './components/AccountNavigationMenuMobile/AccountNavigationMenuMobile';

export function MyAccount({event, isMobile, isTablet, isRtlLanguage, onSetEventProtectedMenu}) {
    const notDesktop = isMobile || isTablet;

    useEffect(() => {
        document.body.classList.add('sticky-menu');
        onSetEventProtectedMenu();

        return () => document.body.classList.remove('sticky-menu');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            id={notDesktop ? 'my-account-mobile' : ''}
            className={classNames('my-account-page', {
                'event-mobile-page': notDesktop,
                isRtl: isRtlLanguage,
            })}
        >
            <AccountGrid
                menu={
                    notDesktop ? (
                        <AccountNavigationMenuMobile eventSlug={event.slug} />
                    ) : (
                        <AccountNavigationMenu eventSlug={event.slug} />
                    )
                }
                pagesScene={<AccountPagesScene eventSlug={event.slug} />}
            />
        </div>
    );
}
