import React from 'react';
import {useSelector} from 'react-redux';
import cn from 'classnames';

import Media from '../../../Media/Media';
import {Wrapper} from './components/Components/Components';

import {useIsPlaceholderShowed} from './hooks';
import './UserPreview.scss';

const UserPreview = ({firstName = '', lastName, media, status, isInteractive, ...rest}) => {
    const [isPlaceholderShowed, handleImageError] = useIsPlaceholderShowed(media);
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);

    return (
        <div
            className={cn('UserPreview', `UserPreview_satus-${status}`, {
                UserPreview_interactive: isInteractive,
                isRtl: isRtlLanguage,
            })}
        >
            <Wrapper isInteractive={isInteractive} {...rest}>
                <span className="UserPreview__mediaGroup">
                    <span className="UserPreview__media">
                        {isPlaceholderShowed ? (
                            firstName[0]
                        ) : (
                            <Media media={media} onError={handleImageError} isAdaptable />
                        )}
                    </span>

                    <span className="UserPreview__activityIndicator" />
                </span>

                <span className="UserPreview__details">
                    <span className="UserPreview__fullName">
                        {firstName} {lastName}
                    </span>
                </span>
            </Wrapper>
        </div>
    );
};

export default UserPreview;
