import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import UsersList from './UsersList';

export default connect(
    (state) => ({
        sideNavigation: selectors.getSideNavigation(state),
        user: selectors.getUserData(state),
        eventId: selectors.getEventId(state),
    }),
    {
        onApplyParticipantsFilters: actions.applyParticipantsFilters,
        onGetUserMatches: actions.getUserMatches,
    }
)(UsersList);
