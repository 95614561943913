import React from 'react';

const PollIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3 5L3 19C3 20.1 3.9 21 5 21L19 21C20.1 21 21 20.1 21 19L21 5C21 3.9 20.1 3 19 3L5 3C3.9 3 3 3.9 3 5ZM19 5L19 19L5 19L5 5L19 5ZM10 17L10 15L17 15L17 17L10 17ZM7 13L7 11L17 11L17 13L7 13ZM13 9L13 7L17 7L17 9L13 9Z"
            fill="#9C231F"
        />
    </svg>
);

export default PollIcon;
