import {useSelector} from 'react-redux';
import {createAction} from '../../services/redux';
import {useBindingActionsToDispatch} from '../../services/hooks';

// Types
const SET_TARGET_LOCALE = 'INTERPRETATION/SET_TARGET_LOCALE';
const RESET_TARGET_LOCALE = 'INTERPRETATION/RESET_TARGET_LOCALE';
const SET_SOURCE_LOCALE = 'INTERPRETATION/SET_SOURCE_LOCALE';
const RESET_SOURCE_LOCALE = 'INTERPRETATION/RESET_SOURCE_LOCALE';

// Actions
const actions = {
    setTargetLocale: (targetLocale) => createAction(SET_TARGET_LOCALE, {targetLocale}),
    resetTargetLocale: () => createAction(RESET_TARGET_LOCALE),
    setSourceLocale: (sourceLocale) => createAction(SET_SOURCE_LOCALE, {sourceLocale}),
    resetSourceLocale: () => createAction(RESET_SOURCE_LOCALE),
};

const initialState = {
    targetLocale: '',
    sourceLocale: '',
};

const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case SET_TARGET_LOCALE: {
            return {...state, targetLocale: payload.targetLocale};
        }

        case RESET_TARGET_LOCALE: {
            return {...state, targetLocale: ''};
        }

        case SET_SOURCE_LOCALE: {
            return {...state, sourceLocale: payload.sourceLocale};
        }

        case RESET_SOURCE_LOCALE: {
            return {...state, sourceLocale: ''};
        }

        default:
            return state;
    }
};

export const useInterpretation = () => {
    const {targetLocale, sourceLocale} = useSelector((state) => state.interpretation);
    const {setTargetLocale, resetTargetLocale, setSourceLocale, resetSourceLocale} = useBindingActionsToDispatch(
        actions
    );

    return [
        {interpretationTargetLocale: targetLocale, interpretationSourceLocale: sourceLocale},
        {
            setTargetInterpretationLocale: setTargetLocale,
            setSourceInterpretationLocale: setSourceLocale,
            resetTargetInterpretationLocale: resetTargetLocale,
            resetSourceInterpretationLocale: resetSourceLocale,
        },
    ];
};

export default reducer;
