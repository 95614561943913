import React from 'react';
import {useSelector} from 'react-redux';

import RemovePerson from '../../../../../../../../../../../Images/person_remove.svg';
import ChatNowICon from '../../../../../../../../../../../Images/chat_ico.svg';
import {usePlatformTranslation} from '../../../../../../../../../../../services/hooks';
import ColoredScrollbars from '../../../../../../../../../ColoredScrollbars/ColoredScrollbars';

import './GroupChatMembers.scss';

const GroupChatMembers = ({
    chatMembers,
    loading,
    user,
    isOwner,
    handleRemoveUserFromGroupChat,
    handleOpenPrivateChat,
}) => {
    const {usersList} = useSelector((state) => state.event);
    const translation = usePlatformTranslation();

    return (
        <div className="chats-members-container">
            <div>
                <ColoredScrollbars>
                    <ul className="group-chat-members-container">
                        {chatMembers.map((chatMember) => {
                            const userData = usersList.find(({_id}) => _id === chatMember._id);
                            return (
                                <React.Fragment key={chatMember._id}>
                                    <li>
                                        <div
                                            className="user-avatar"
                                            style={{
                                                backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${chatMember.avatarSmall}')`,
                                            }}
                                        ></div>
                                        <div className="chat-member-details">
                                            <p>
                                                {chatMember.first} {chatMember.last}
                                            </p>
                                            {userData && (
                                                <span>
                                                    {userData.title} {userData.title && userData.company ? 'at' : null}{' '}
                                                    {userData.company}
                                                </span>
                                            )}
                                        </div>

                                        {chatMember._id !== user._id && isOwner && (
                                            <button
                                                className="manage-chat"
                                                onClick={() => handleRemoveUserFromGroupChat(chatMember._id)}
                                                disabled={loading}
                                            >
                                                <img src={RemovePerson} alt="Remove Member" width="24" height="24" />
                                                <p>{translation?.chatsDropdown.newChatRemoveUserButton}</p>
                                            </button>
                                        )}

                                        {chatMember._id !== user._id && (
                                            <button
                                                className="manage-chat"
                                                onClick={() => handleOpenPrivateChat(chatMember._id)}
                                                disabled={loading}
                                            >
                                                <img src={ChatNowICon} alt="Chat" width="24" height="24" />
                                                <p>{translation?.chatsDropdown.membersChatButton}</p>
                                            </button>
                                        )}
                                    </li>
                                    <div className="group-chat-members-container__user-wrapper-separator" />
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </ColoredScrollbars>
            </div>
        </div>
    );
};

export default GroupChatMembers;
