import _ from 'lodash';
import * as actionTypes from '../actionTypes/organizerActionTypes/sessions';
import {updateObject} from '../utilityStateFunction';
import {PARTICIPANT_TYPES} from 'constants/organizer/sessions';

const initialState = {
    isLoading: false,
    isMediaCaptureError: false,
    room: null,
    client: null,
    mediaSession: null,
    sessions: {},
    consumers: {},
    pausedConsumers: {},
    joinedUsers: [],
    mediaInputSources: [],
    constrains: {
        audio: {
            deviceId: 'default'
        },
        video: {
            deviceId: 'default',
            width: {min: 1280, max: 3840},
            height: {min: 720, max: 2160}
        }
    },
    capture: null,
    shareScreenCapture: null,
    chat: [],
    isActive: false,
    isReady: false,
    isKicked: false,
    isScreenShared: false,
    isVideoPaused: false,
    isAudioPaused: false,
    isFullScreen: false,
    isClosingMode: false,
    isDisconnected: false,
    dominantSpeakerOrder: []
};

const etxSessionDashboardReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.JOIN_SESSION_DASHBOARD: {
            return updateObject(state, {isReady: true});
        }
        case actionTypes.CREATE_ROOM_SUCCESS: {
            const {room, client} = payload;

            return updateObject(state, {room, client});
        }
        case actionTypes.SET_CLIENT:
        case actionTypes.SET_ROOM:
        case actionTypes.SET_MEDIA_SESSION: {
            const {mediaSession, currentUser} = payload;

            if (!mediaSession || !currentUser) return state;

            return updateObject(state, {mediaSession, joinedUsers: [...state.joinedUsers, currentUser]});
        }
        case actionTypes.JOIN_ROOM: {
            const {sessions: joinedSessions} = payload;

            const sessions = {...state.sessions};
            const consumers = {...state.consumers};

            for (const session of joinedSessions) {
                if (session.role !== PARTICIPANT_TYPES.interpreter) {
                    sessions[session.userId] = {
                        ...session,
                        consumers: session.consumers.map((consumer) => {
                            consumers[consumer.id] = consumer;
                            return consumer.id;
                        })
                    };
                }
            }

            return updateObject(state, {consumers, sessions, joinedUsers: joinedSessions});
        }
        case actionTypes.ADD_SESSION: {
            const {session} = payload;
            const sessions = {...state.sessions};
            const consumers = {...state.consumers};

            if (session.role !== PARTICIPANT_TYPES.interpreter) {
                sessions[session.userId] = {
                    ...session,
                    consumers: session.consumers.map((consumer) => {
                        consumers[consumer.id] = consumer;
                        return consumer.id;
                    })
                };
            }

            return updateObject(state, {consumers, sessions, joinedUsers: [...state.joinedUsers, session]});
        }
        case actionTypes.REMOVE_SESSION: {
            const {session} = payload;
            const sessions = {...state.sessions};
            delete sessions[session.userId];
            const joinedUsers = state.joinedUsers.filter(({userId}) => userId !== session.userId);

            return updateObject(state, {sessions, joinedUsers});
        }
        case actionTypes.ADD_CONSUMER: {
            const {consumer, userId} = payload;

            const session = state.sessions[userId];

            const consumers = {...state.consumers, [consumer.id]: consumer};

            const newConsumers = [...session.consumers, consumer.id];
            const newSession = {...session, consumers: newConsumers};
            const sessions = updateObject(state.sessions, {[newSession.userId]: newSession});

            return updateObject(state, {consumers, sessions});
        }
        case actionTypes.CLOSE_CONSUMER: {
            const {userId, consumerId} = payload;

            const consumers = {...state.consumers};
            delete consumers[consumerId];
            const newState = {...state, consumers};

            const session = state.sessions[userId];
            if (!session) return newState;

            const newConsumers = [...session.consumers].filter((consumer) => consumer !== consumerId);
            const newSession = {...session, consumers: newConsumers};
            const sessions = updateObject(state.sessions, {[newSession.userId]: newSession});

            return updateObject(state, {...newState, sessions});
        }
        case actionTypes.PAUSE_CONSUMER: {
            const {userId, consumerId} = payload;

            const session = state.sessions[userId];
            if (!session) return state;

            const pausedConsumers = {
                ...session.pausedConsumers,
                [consumerId]: consumerId
            };

            const newSession = {...session, pausedConsumers};
            const sessions = updateObject(state.sessions, {[newSession.userId]: newSession});

            return updateObject(state, {sessions});
        }
        case actionTypes.RESUME_CONSUMER: {
            const {userId, consumerId} = payload;

            const session = state.sessions[userId];
            if (!session) return state;

            const pausedConsumers = {...session.pausedConsumers};
            delete pausedConsumers[consumerId];

            const newSession = {...session, pausedConsumers};
            const sessions = updateObject(state.sessions, {[newSession.userId]: newSession});

            return updateObject(state, {sessions});
        }
        case actionTypes.NEW_DOMINANT_SPEAKER_ORDER: {
            return updateObject(state, {dominantSpeakerOrder: payload});
        }
        case actionTypes.CLEAR_CALL_STATE: {
            const isReconnect = payload;
            return {
                ...initialState,
                chat: isReconnect ? state.chat : [],
                isClosingMode: !isReconnect,
                isActive: isReconnect,
                isReady: isReconnect,
                isVideoPaused: isReconnect ? state.isVideoPaused : false,
                isAudioPaused: isReconnect ? state.isAudioPaused : false,
                constrains: isReconnect ? state.constrains : initialState.constrains,
                isDisconnected: isReconnect
            };
        }
        case actionTypes.CREATE_ROOM_FAIL: {
            return updateObject(state, {
                isKicked: payload,
                isActive: false
            });
        }
        case actionTypes.SET_MEDIA_DEVICES_LIST: {
            return updateObject(state, {...payload});
        }
        case actionTypes.GET_LOCAL_STREAM_SUCCESS: {
            return updateObject(state, {capture: payload, isActive: true});
        }
        case actionTypes.GET_SCREEN_SHARE_STREAM_SUCCESS: {
            return updateObject(state, {shareScreenCapture: payload});
        }
        case actionTypes.VIDEO_CONFERENCE_FULL_SCREEN_TOGGLED: {
            return updateObject(state, {
                isFullScreen: !state.isFullScreen
            });
        }
        case actionTypes.GET_CHAT_MESSAGES_SUCCESS: {
            return updateObject(state, {
                chat: payload
            });
        }
        case actionTypes.SEND_CHAT_MESSAGE_SUCCESS:
        case actionTypes.RECIEVE_CHAT_MESSAGE: {
            return updateObject(state, {
                chat: _.uniqBy([...state.chat, payload], '_id')
            });
        }
        case actionTypes.TOGGLE_SELF_VIDEO: {
            return updateObject(state, {isVideoPaused: payload ?? !state.isVideoPaused});
        }
        case actionTypes.TOGGLE_SELF_AUDIO: {
            return updateObject(state, {isAudioPaused: payload ?? !state.isAudioPaused});
        }
        case actionTypes.TOGGLE_SCREEN_SHARE: {
            return updateObject(state, {isScreenShared: payload ?? !state.isScreenShared});
        }
        case actionTypes.GET_LOCAL_STREAM_FAIL: {
            return updateObject(state, {
                isMediaCaptureError: true
            });
        }
        case actionTypes.CLEAR_SESSION_STATE:
            return initialState;
        case actionTypes.SET_DISCONNECTED_STATE: {
            return updateObject(state, {
                isDisconnected: payload
            });
        }
        default:
            return state;
    }
};

export default etxSessionDashboardReducer;
