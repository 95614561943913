import React from 'react';
import {useDispatch} from 'react-redux';

import AgendaMeetingList from './components/AgendaMeetingList/AgendaMeetingList';
import AgendaEmptyMessage from '../AgendaEmptyMessage/AgendaEmptyMessage';

import {usePersonalAgendaItems, usePlatformTranslation} from 'services/hooks';
import {cancelMeeting, confirmMeeting, startMeeting} from 'store/actions';
import './AgendaMeetings.scss';

const AgendaMeetings = () => {
    const dispatch = useDispatch();
    const {userId, meetingList, isMeetingsEmpty} = usePersonalAgendaItems();
    const {personalAgenda} = usePlatformTranslation();

    if (isMeetingsEmpty) {
        return <AgendaEmptyMessage title={personalAgenda.noMeetings} />;
    }

    return (
        <div className="AgendaMeetings">
            <AgendaMeetingList
                list={meetingList}
                userId={userId}
                onCancel={(...args) => dispatch(cancelMeeting(...args))}
                onConfirm={(...args) => dispatch(confirmMeeting(...args))}
                onMeetingStart={(...args) => dispatch(startMeeting(...args))}
            />
        </div>
    );
};

export default AgendaMeetings;
