import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles({
    buttons: {
        display: 'flex',
        justifyContent: 'space-around',
        position: 'absolute',
        width: '100%',
        zIndex: 200,
        bottom: 5
    },
    button: {
        color: '#fff',
        cursor: 'pointer',
        fontSize: '45px',
        background: 'transparent',
        border: 'none'
    },
    buttonEndCall: {
        color: 'red'
    },
    buttonDisabled: {
        cursor: 'initial',
        opacity: 0.5,
        '& svg': {
            cursor: 'initial'
        }
    }
});
