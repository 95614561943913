import React from 'react';
import HomepageBackground from '../../../Images/backgrounds/homepage-background.jpg';

import './PlatformNotAvailable.scss';

export const PlatformNotAvailable = ({
    message = ' Events10X is not currently optimized for mobile usage. Events10X apps coming soon. Please access your Event using a PC or Mac!',
}) => (
    <div className="platform-not-available" style={{backgroundImage: `url('${HomepageBackground}')`}}>
        <div className="platform-not-available__text-container">
            <p>
                <span>Your account is active. </span>
                {message}
            </p>
        </div>
    </div>
);
