import * as actionTypes from '../actionTypes/accountActionTypes';
import {updateObject} from '../utilityStateFunction';

const initialState = {
    leftSideTabs: {
        activeTabId: 0,
    },
    backLink: {
        name: null,
        link: null,
    },
    hideNavigation: false,
};

const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.CHANGE_SIDE_TAB:
            return updateObject(state, {leftSideTabs: {...state.leftSideTabs, activeTabId: payload}});
        case actionTypes.SET_ACCOUNT_BACK_LINK:
            return updateObject(state, {backLink: payload});
        case actionTypes.RESET_ACCOUNT_BACK_LINK:
            return updateObject(state, {backLink: initialState.backLink});
        case actionTypes.HIDE_ACCOUNT_NAVIGATION:
            return updateObject(state, {hideNavigation: payload});

        default:
            return state;
    }
};

export default reducer;
