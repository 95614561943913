import {SessionDashboard} from './SessionDashboard';
import {connect} from 'react-redux';
import {sendChatMessage, setEventProtectedMenu, getLocalStream, clearSessionState} from 'store/actions';
import * as selectors from 'store/selectors';

export default connect(
    (state) => ({
        sessionData: selectors.getSessionData(state),
        sessions: selectors.getRoomSessions(state),
        isFullScreen: selectors.getFullScreen(state),
        isReady: selectors.getDashboardReady(state),
        isKicked: selectors.getIsUserKicked(state),
        translation: selectors.getTranslation(state),
        capture: selectors.getVideoCapture(state),
        isClosingMode: selectors.getIsClosingMode(state),
    }),
    {
        sendChatMessage,
        setEventProtectedMenu,
        getLocalStream,
        clearSessionState,
    }
)(SessionDashboard);
