import React from 'react';

const EmptyBoxIcon = ({primary, secondary}) => (
    <svg width="196" height="165" viewBox="0 0 196 165" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M91.2297 28.5649V81.9522L70.0023 94.189L62.8177 98.3041L35.712 113.79V60.4024L62.8177 44.8085L70.0023 40.6935L91.2297 28.5649Z"
            fill="#777081"
        />
        <path
            opacity="0.5"
            d="M91.2297 145.735L115.5 155.001L171.5 123.001L152.754 103.855L146.658 102.555L91.2297 145.735Z"
            fill="#CACBD0"
        />
        <path
            d="M63.4708 76.3211L56.2862 72.206L35.712 60.4023V113.79L56.2862 125.593L63.4708 129.817L91.2297 145.735V92.2398L63.4708 76.3211Z"
            fill={primary ? primary : '#32862B'}
        />
        <path
            d="M118.989 44.4837L111.695 40.2603L91.2297 28.5649V81.9522L111.695 93.7559L118.989 97.8709L146.747 113.79V60.4024L118.989 44.4837Z"
            fill="#898989"
        />
        <path
            d="M146.747 60.4023V113.79L125.52 126.026L118.227 130.141L91.2297 145.735V92.2398L118.227 76.6459L125.52 72.5309L146.747 60.4023Z"
            fill={secondary ? secondary : '#005850'}
        />
        <path
            d="M35.712 60.4023L56.504 72.3143L63.6886 76.4294L91.2298 92.2398L66.9586 119.153L11.4409 87.316L35.712 60.4023Z"
            fill="#CACBD0"
        />
        <path d="M91.2297 28.5647L119.022 20.2314L174.539 52.0689L146.747 60.4021L91.2297 28.5647Z" fill="#CACBD0" />
        <path d="M91.2297 28.5648L57.6392 19.3052L2.12152 51.1426L35.712 60.4022L91.2297 28.5648Z" fill="#CACBD0" />
        <path
            d="M146.747 60.4023L125.955 72.3143L118.771 76.4294L91.2297 92.2398L114.56 120.153L169.969 88.3151L146.747 60.4023Z"
            fill="#CACBD0"
        />
        <path
            d="M91.2297 81.9521L100.047 87.0418L91.2297 92.2398L82.4121 87.0418L91.2297 81.9521Z"
            fill={secondary ? secondary : '#005850'}
        />
    </svg>
);

export default EmptyBoxIcon;
