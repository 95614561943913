import React, {useEffect} from 'react';
import {useLocation} from 'react-router';
import FullPageSpinner from '../FullPageSpinner';
import NotAvailable from '../NotAvailable/NotAvailable';
import NotFound from '../NotFound';

import {TEMP_EVENT_INFO_DATA_KEY, USER_ROLES, USER_STATUSES} from '../../../constants/shared';

export const EventPublicComponent = ({
    match: {params},
    location,
    eventSlug,
    event,
    eventId,
    eventInfo,
    userRegistration,
    loadingEvent,
    userLoading,
    eventNotFound,
    loggedIn,
    children,
    setEventSlug,
    onGetEventInfo,
    onGetEventUserRegistration,
    setEventIdBySlug,
    onSetEventShortLink,
    onSetEventRegPackage,
    eventShortLink,
    eventRegPackage
}) => {
    const {formId, packageId} = params;
    const {search} = useLocation();
    const query = new URLSearchParams(search);
    const registerAsExhibitor = query.get('registerAsExhibitor') === 'true';

    useEffect(() => {
        if (location.state?.eventSlug && loggedIn) return;

        if ((params?.eventSlug && !eventSlug) || (event && event?.slug !== eventSlug)) {
            setEventSlug(params?.eventSlug);
        }
    }, [event, eventSlug, params.eventSlug, setEventSlug, location, loggedIn]);

    useEffect(() => {
        // in this case we disable default requests for public pages because we were redirected to listed event
        if (eventId && (location.state?.eventSlug || location.state?.eventSlug === eventSlug || !eventSlug)) return;

        if (eventSlug) {
            setEventIdBySlug(eventSlug);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventSlug]);

    useEffect(() => {
        if (!eventInfo && !loadingEvent && eventId) {
            //set eventInfo data to session storage for redirect from social media login
            const callback = (eventInfo) => {
                const value = loggedIn || userLoading ? null : JSON.stringify(eventInfo);
                sessionStorage.setItem(TEMP_EVENT_INFO_DATA_KEY, value);
            };

            onGetEventInfo(eventId, callback);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventId]);

    useEffect(() => {
        if (!eventShortLink && formId) onSetEventShortLink(formId);
        if (!eventRegPackage && packageId) onSetEventRegPackage(packageId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formId]);

    useEffect(() => {
        if (!userRegistration && eventId) {
            // TODO: refactor
            const userRole =
                registerAsExhibitor ||
                location?.pathname.includes('exhibitor-registration') ||
                formId?.includes('-exhib')
                    ? USER_ROLES.exhibitor
                    : USER_ROLES.participant;

            onGetEventUserRegistration({eventId, userRole, shortLink: formId, packageId});
        }
    }, [
        eventId,
        formId,
        location.pathname,
        loggedIn,
        onGetEventUserRegistration,
        packageId,
        registerAsExhibitor,
        userRegistration
    ]);

    const isEventNotFound =
        (eventInfo &&
            !location.state?.eventSlug &&
            (eventInfo.owner.organizerStatus === USER_STATUSES.pending ||
                eventInfo.status === USER_STATUSES.pending)) ||
        eventNotFound;

    const uiValidationRules = {
        isLoadingEvent: !eventInfo && !eventNotFound,
        isEventNotFound,
        isEventNotAvailable: eventInfo && eventInfo.owner.organizerStatus === USER_STATUSES.disabled
    };

    switch (true) {
        case uiValidationRules.isLoadingEvent:
            return <FullPageSpinner />;
        case uiValidationRules.isEventNotFound:
            return <NotFound />;
        case uiValidationRules.isEventNotAvailable:
            return <NotAvailable />;
        default:
            return children;
    }
};
