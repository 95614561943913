import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {
    StopScreenShare as StopScreenShareIcon,
    ScreenShare as ScreenShareIcon,
    CallEnd as CallEndIcon,
    Mic as MicIcon,
    MicOff as MicOffIcon,
    Videocam as VideocamIcon,
    VideocamOff as VideocamOffIcon,
} from '@material-ui/icons';
import {Button} from '@material-ui/core';
import {isSafari} from 'react-device-detect';

import {getActiveCallTypeData, getIsMobile} from 'store/selectors';

import {useStyles} from './VideoChatButtons.styles';

export const VideoChatButtons = ({
    soundEnabled,
    videoEnabled,
    toggleSound,
    toggleVideo,
    close,
    toggleScreenShare,
    isLoading,
}) => {
    const classes = useStyles();
    const videoConference = useSelector(getActiveCallTypeData);
    const isMobile = useSelector(getIsMobile);

    return (
        <div className={`${classes.buttons} video-conference-buttons`}>
            {soundEnabled && <MicIcon className={classes.button} onClick={toggleSound} />}
            {!soundEnabled && <MicOffIcon className={classes.button} onClick={toggleSound} />}
            <CallEndIcon className={`${classes.button} ${classes.buttonEndCall}`} onClick={close} />

            {!videoConference.isScreenShared && (
                <>
                    {videoEnabled && (
                        <Button className={classes.buttonWrapper} disabled={isLoading}>
                            <VideocamIcon className={classes.button} onClick={toggleVideo} />
                        </Button>
                    )}
                    {!videoEnabled && <VideocamOffIcon className={classes.button} onClick={toggleVideo} />}
                </>
            )}

            {!videoConference.isScreenShared && !isMobile && !isSafari && (
                <ScreenShareIcon className={classes.button} onClick={() => toggleScreenShare(true)} />
            )}
            {videoConference.isScreenShared && !isMobile && !isSafari && (
                <StopScreenShareIcon className={classes.button} onClick={() => toggleScreenShare(false)} />
            )}
        </div>
    );
};

VideoChatButtons.propTypes = {
    soundEnabled: PropTypes.bool,
    videoEnabled: PropTypes.bool,
    toggleSound: PropTypes.func,
    toggleVideo: PropTypes.func,
    close: PropTypes.func,
    toggleScreenShare: PropTypes.func,
    isLoading: PropTypes.bool,
};
