import React from 'react';

import {GET_MEDIA_CAPTURE_ERROR_MESSAGE} from 'constants/organizer/sessions';
import {useStyles} from './ErrorMessage.styles';

export function ErrorMessage() {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <p className={classes.text}>{GET_MEDIA_CAPTURE_ERROR_MESSAGE}</p>
        </div>
    );
}
