import React from 'react';
import {connect} from 'react-redux';
import {ValidatorForm} from 'react-material-ui-form-validator';

import TextField from '../../../../../../../../../TextField/TextField';
import RadioField from '../../../../../../../../../RadioField/RadioField';
import SelectField from '../../../../../../../../../SelectField/SelectField';
import CheckboxField from '../../../../../../../../../CheckboxField/CheckboxField';
import SelectCountry from '../../../../../../../../../SelectCountry/SelectCountry';

import CustomCancelButton from 'Routes/components/CustomCancelButton';
import EditUserInfoWrapper from 'Routes/components/EditUserInfoWrapper';

import * as actions from 'store/actions/index';
import {Api} from 'Api';
import {getFieldTranslatedLabel} from 'services/helpers/registrationFieldsHelper';

class EditExhibitorContactPersonInformationMobile extends React.Component {
    state = {
        user: {},
        fields: [
            {
                isEnabled: true,
                name: 'company',
                type: 'text',
                value: '',
                label: 'Company',
                multiline: 0,
                validators: ['required'],
                errorMessages: ['Field is required']
            },
            {
                isEnabled: true,
                name: 'title',
                type: 'text',
                value: '',
                label: 'Title',
                multiline: 0,
                validators: [],
                errorMessages: []
            },
            {
                isEnabled: true,
                name: 'phone',
                type: 'text',
                value: '',
                label: 'Mobile',
                multiline: 0,
                validators: ['matchRegexp:^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$'],
                errorMessages: ['Please enter a valid phone number. E.g.: +447517663928']
            },
            {
                isEnabled: true,
                name: 'facebook',
                type: 'text',
                value: '',
                label: 'Facebook profile',
                multiline: 0,
                validators: ['isFacebookLink'],
                errorMessages: ['Please enter a valid link (https://facebook.com/)']
            },
            {
                isEnabled: true,
                name: 'linkedin',
                type: 'text',
                value: '',
                label: 'Your Linkedin profile',
                multiline: 0,
                validators: ['isLinkedinLink'],
                errorMessages: ['Please enter a valid link (https://linkedin.com/)']
            },
            {
                isEnabled: true,
                name: 'twitter',
                type: 'text',
                value: '',
                label: 'Your Twitter profile',
                multiline: 0,
                validators: ['isLTwitterLink'],
                errorMessages: ['Please enter a valid link (https://twitter.com/)']
            },
            {
                isEnabled: true,
                name: 'country',
                type: 'text',
                value: '',
                label: 'Country',
                multiline: 0,
                validators: [],
                errorMessages: []
            },
            {
                isEnabled: true,
                name: 'website',
                type: 'text',
                value: '',
                label: 'Website',
                multiline: 0,
                validators: ['isLink'],
                errorMessages: ['Please enter a valid link (https://site.com)']
            }
        ],
        disabled: true
    };

    setProfileFields = () => {
        const {
            event: {data: eventInfo},
            language,
            exhibitor
        } = this.props;

        let updatedFields = [...this.state.fields];
        updatedFields.forEach((field) => {
            let eventInfoField = eventInfo.exhibitorPredefinedRegistrationFields[field.name];
            field.label = getFieldTranslatedLabel(eventInfoField, language);
            field.isEnabled = eventInfoField.isEnabled;
            field.value = exhibitor[field.name];
            if (eventInfoField.isRequired) {
                field.validators.push('required');
                field.errorMessages.push('Field is required');
            }
        });
        eventInfo.exhibitorRegistrationFields.forEach((extraField) => {
            const exhibitorField = exhibitor.customFields.find(
                (f) => String(f.customFieldId) === String(extraField._id)
            );
            updatedFields.push({
                isEnabled: true,
                name: extraField._id,
                type: extraField.type,
                value: extraField.type === 'checkbox' ? exhibitorField.values || [] : exhibitorField.value || '',
                label: extraField.label,
                multiline: 0,
                validators: extraField.isRequired ? ['required'] : [],
                errorMessages: extraField.isRequired ? ['Field is required'] : [],
                options: extraField.options
            });
        });

        let enabledUpdatedFields = updatedFields.filter((field) => field.isEnabled);
        this.setState({
            fields: enabledUpdatedFields
        });
    };

    componentDidMount() {
        this.addValidationRules();
        // update the required fields with the settings from the current event
        this.setProfileFields();
    }

    componentWillUnmount() {
        // remove rule when it is not needed
        this.removeValidationRules();
    }

    removeValidationRules = () => {
        ValidatorForm.removeValidationRule('isFacebookLink');
        ValidatorForm.removeValidationRule('isLinkedinLink');
        ValidatorForm.removeValidationRule('isLTwitterLink');
        ValidatorForm.removeValidationRule('isLink');
    };

    addValidationRules = () => {
        ValidatorForm.addValidationRule('isFacebookLink', (value) => {
            let rule = new RegExp('^(http|https)://(www.)?facebook.com/', 'i');
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isLinkedinLink', (value) => {
            let rule = new RegExp('^(http|https)://(www.)?linkedin.com/', 'i');
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isLTwitterLink', (value) => {
            let rule = new RegExp('^(http|https)://(www.)?twitter.com/', 'i');
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                return false;
            }
            return true;
        });
        ValidatorForm.addValidationRule('isLink', (value) => {
            let rule = new RegExp(
                '(http|ftp|https):\\/\\/[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-\\.,@?^=%&amp;:/~\\+#]*[\\w\\-\\@?^=%&amp;/~\\+#])?',
                'i'
            );
            let match = rule.test(value);

            if (value.length === 0) {
                match = true;
            }
            if (!match) {
                this.setState({newLinkError: true});
                return false;
            }
            this.setState({newLinkError: false});
            return true;
        });
    };

    handleFieldChange = (fieldName, fieldValue) => {
        let updatedFields = [...this.state.fields];
        let updatedFieldIndex = updatedFields.findIndex((field) => field.name === fieldName);
        updatedFields[updatedFieldIndex].value = fieldValue;
        this.setState({user: {...this.state.user}, fields: updatedFields}, () => {
            this.refs.form.isFormValid().then((isValid) => {
                this.setState({disabled: !isValid});
            });
        });
    };

    handleUpdateExhibitorContactPersonInformation = async () => {
        const {user, fields} = this.state;
        const {eventId, onGetEvent, closeDialog, exhibitor} = this.props;

        fields.forEach((field) => {
            user[field.name] = field.value;
        });

        try {
            await Api.request({
                method: 'put',
                url: `/event/${eventId}/exhibitor/${exhibitor._id}`,
                payload: user
            });

            onGetEvent(eventId);
            closeDialog();
        } catch (error) {}
    };

    render() {
        const {opened} = this.state.user;
        const {fields} = this.state;
        return (
            <EditUserInfoWrapper className={opened ? 'opened' : ''}>
                <div>
                    <div className="page-title">
                        <p>Edit Company Contact Person Details</p>
                    </div>
                    <div className="content-wrapper">
                        <div className="form-wrapper">
                            <ValidatorForm ref="form" onSubmit={this.handleUpdateExhibitorContactPersonInformation}>
                                {fields.map((field) => {
                                    if (field.name === 'country') {
                                        return (
                                            <SelectCountry
                                                field={field}
                                                handleChange={this.handleFieldChange}
                                                key={field.name}
                                            />
                                        );
                                    }
                                    if (field.type === 'select') {
                                        return (
                                            <SelectField
                                                field={field}
                                                handleChange={this.handleFieldChange}
                                                key={field.name}
                                            />
                                        );
                                    }
                                    if (field.type === 'radio') {
                                        return (
                                            <RadioField
                                                field={field}
                                                handleChange={this.handleFieldChange}
                                                key={field.name}
                                            />
                                        );
                                    }
                                    if (field.type === 'checkbox') {
                                        return (
                                            <CheckboxField
                                                field={field}
                                                handleChange={this.handleFieldChange}
                                                key={field.name}
                                            />
                                        );
                                    }
                                    return (
                                        <TextField
                                            field={field}
                                            handleChange={this.handleFieldChange}
                                            key={field.name}
                                            variant={'outlined'}
                                        />
                                    );
                                })}
                            </ValidatorForm>
                        </div>
                    </div>
                    <div className="buttons-actions">
                        <CustomCancelButton text="Close" onClick={() => this.props.closeDialog('')} />
                        <button
                            type="submit"
                            className="update-button"
                            disabled={this.state.disabled}
                            onClick={this.handleUpdateExhibitorContactPersonInformation}
                        >
                            Update
                        </button>
                    </div>
                </div>
            </EditUserInfoWrapper>
        );
    }
}

export default connect(
    (state) => ({
        eventId: state.event.eventId,
        event: state.event,
        language: state.user.data.language
    }),
    {
        onGetEvent: actions.getEvent
    }
)(EditExhibitorContactPersonInformationMobile);
