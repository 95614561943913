import React, {useState} from 'react';
import {useDispatch} from 'react-redux';

import Button from '../../../../../../../Button/Button';
import {IconEdit, IconCross} from '../../../../../../../Icons';
import BusinessCardLogoUploadingField from './components/UploadingField/UploadingField';

import {useBusinessCardData, usePlatformTranslation} from 'services/hooks';
import {resetBusinessCardLogo, uploadBusinessCardLogo} from 'store/actions';
import {emptyBusinessCardLogo} from 'services/helpers';

import './BusinessCardLogoUploading.scss';

const BusinessCardLogoUploading = () => {
    const dispatch = useDispatch();
    const {businessCard} = useBusinessCardData();
    const {businessCard: businessCardTranslation, generalText} = usePlatformTranslation();
    const [inputEl, setInputEl] = useState();

    const handleChange = () => {
        const image = inputEl.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(inputEl.files[0]);

        reader.onload = (e) => {
            dispatch(uploadBusinessCardLogo({name: image.name, src: e.target.result, file: image}));
        };
    };

    const handleReset = () => {
        inputEl.value = '';
        dispatch(resetBusinessCardLogo({logo: emptyBusinessCardLogo}));
    };

    if (!businessCard) {
        return null;
    }

    const hasImage = businessCard.logo.media;

    return (
        <div className="BusinessCardLogoUploading">
            <h2 className="BusinessCardLogoUploading__title">{businessCardTranslation.companyLogo}</h2>

            <div className="BusinessCardLogoUploading__actionGroup">
                <div className="BusinessCardLogoUploading__actionUpload">
                    <label className="BusinessCardLogoUploading__fileInputLabel">
                        <Button title={generalText.upload} icon={<IconEdit />} component="span" isDisabled={hasImage} />

                        <input
                            className="BusinessCardLogoUploading__fileInput"
                            type="file"
                            ref={setInputEl}
                            disabled={hasImage}
                            onChange={handleChange}
                        />
                    </label>
                </div>

                <div className="BusinessCardLogoUploading__actionRemove">
                    <Button
                        title={generalText.remove}
                        icon={<IconCross />}
                        isDisabled={!hasImage}
                        onClick={handleReset}
                    />
                </div>
            </div>

            <BusinessCardLogoUploadingField businessCard={businessCard} />
        </div>
    );
};

export default BusinessCardLogoUploading;
