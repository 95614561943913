import React from 'react';

import Svg from '../Svg/Svg';

import './Program.scss';

const IconProgram = () => (
    <Svg className="IconProgram">
        <path
            className="IconProgram__el"
            d="M16.097 1.828H19.7c1.268 0 2.3 1.025 2.3 2.286v17.601A2.295 2.295 0 0119.7 24H4.3A2.295 2.295 0 012 21.715V4.114a2.295 2.295 0 012.3-2.286h3.602A2.9 2.9 0 019.9 1.031h.414a1.894 1.894 0 013.37 0h.413a2.9 2.9 0 012 .797zm-5.46.14H9.9a1.955 1.955 0 00-1.95 1.782h8.098a1.955 1.955 0 00-1.951-1.781h-.734a.471.471 0 01-.455-.344.945.945 0 00-1.818 0 .471.471 0 01-.455.344zM19.511 7h-7.024a.494.494 0 00-.488.5c0 .276.218.5.488.5h7.024c.27 0 .488-.224.488-.5s-.218-.5-.488-.5zm-7.011 2h2.998a.5.5 0 110 1h-2.998a.5.5 0 110-1zm-.013 3a.494.494 0 00-.488.5c0 .276.218.5.488.5h7.024c.27 0 .488-.224.488-.5s-.218-.5-.488-.5h-7.024zm.013 2h2.998a.5.5 0 110 1h-2.998a.5.5 0 110-1zm-5.002 5H4.501a.5.5 0 100 1h2.998a.5.5 0 100-1zM12 17.5c0-.276.218-.5.488-.5h7.024c.27 0 .488.224.488.5s-.218.5-.488.5h-7.024a.494.494 0 01-.488-.5zm3.5 1.5h-3a.5.5 0 100 1h3a.5.5 0 100-1zM8.051 6.168a.543.543 0 01.785 0 .586.586 0 010 .81L6.071 9.832a.545.545 0 01-.785 0l-1.123-1.16a.586.586 0 010-.81.543.543 0 01.785 0l.73.755 2.374-2.45zM7.412 12H4.588a.588.588 0 00-.588.588v2.824c0 .325.263.588.588.588h2.824A.588.588 0 008 15.412v-2.824A.588.588 0 007.412 12zm-.588 2.823H5.176v-1.646h1.648v1.646z"
        />
    </Svg>
);

export default IconProgram;
