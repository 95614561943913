import {createSelector} from 'reselect';

const businessCard = (state) => state.businessCard;

export const getBusinessCard = createSelector(businessCard, (state) => state.businessCard);

export const getBusinessCardWasChanged = createSelector(businessCard, (state) => state.wasChanged);

export const getBusinessCardIsValid = createSelector(businessCard, (state) => state.isValid);

export const getBusinessCardLoadState = createSelector(businessCard, (state) => state.loadState);
