import * as actionTypes from '../actionTypes/eventActionTypes';
import {Api} from 'Api';
import {setEventLanguage, refreshUserData, saveEventAfterLogin} from './userActions';
import {EVENT_STATUSES, USER_ROLES} from 'constants/shared';
import {getSessions} from './sessionActions';
import {updateTimeSlotData} from './timeslotActions';

export const createEvent = (eventData, callback) => async (dispatch, getState) => {
    const {
        event: {loading}
    } = getState();
    dispatch(createEventStart());

    try {
        const {event} = await Api.request({
            url: `/event`,
            method: 'post',
            payload: eventData,
            loading
        });

        dispatch(createEventSuccess(event));
        if (callback) callback();
    } catch (error) {
        dispatch(createEventFail(error));
    }
};

export const createEventStart = () => {
    return {
        type: actionTypes.CREATE_EVENT_START
    };
};
export const createEventSuccess = (event) => {
    return {
        type: actionTypes.CREATE_EVENT_SUCCESS,
        payload: event
    };
};
export const createEventFail = () => {
    return {
        type: actionTypes.CREATE_EVENT_FAIL
    };
};

export const setEventId = (eventId) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_EVENT_ID,
            payload: eventId
        });
    };
};

export const setEventSlug = (eventSlug) => {
    return (dispatch) => {
        dispatch({
            type: actionTypes.SET_EVENT_SLUG,
            payload: eventSlug
        });
    };
};

export const setEventIdBySlug = (eventSlug) => async (dispatch, getState) => {
    const {
        event: {loading}
    } = getState();

    dispatch(setEventIdBySlugStart());

    try {
        const {
            event: {slug, _id}
        } = await Api.request({
            url: `/event-slug/${eventSlug}`,
            method: 'get',
            loading
        });

        dispatch(setEventIdBySlugSuccess({eventId: _id, eventSlug: slug}));
    } catch (error) {
        if (error && error.status === 404) {
            dispatch(setEventNotFound(error));
        }
        dispatch(setEventIdBySlugFail(error));
    }
};

export const setEventIdBySlugStart = () => {
    return {
        type: actionTypes.SET_EVENT_ID_BY_SLUG_START
    };
};
export const setEventIdBySlugSuccess = ({eventId, eventSlug}) => ({
    type: actionTypes.SET_EVENT_ID_BY_SLUG_SUCCESS,
    payload: {eventId, eventSlug}
});
export const setEventIdBySlugFail = () => {
    return {
        type: actionTypes.SET_EVENT_ID_BY_SLUG_FAIL
    };
};
export const setEventNotFound = () => {
    return {
        type: actionTypes.SET_EVENT_NOT_FOUND
    };
};
export const registerParticipant = (eventId, user, language, registrationKey) => async (dispatch, getState) => {
    const {
        event: {loading},
        eventListing: {preSaveEvent}
    } = getState();

    dispatch(registerParticipantStart());

    try {
        await Api.request({
            url: '/users',
            method: 'put',
            payload: {language}
        });

        dispatch(refreshUserData());

        const url = registrationKey
            ? `/event/${eventId}/register-participant?registrationKey=${registrationKey}`
            : `/event/${eventId}/register-participant`;

        const response = await Api.request({
            url,
            method: 'post',
            payload: user,
            loading
        });

        dispatch(registerParticipantSuccess(response));
        saveEventAfterLogin(dispatch, preSaveEvent);
    } catch (error) {
        dispatch(registerParticipantFail(error));
    }
};

export const registerParticipantStart = () => {
    return {
        type: actionTypes.REGISTER_PARTICIPANT_START
    };
};
export const registerParticipantSuccess = (participant) => {
    return {
        type: actionTypes.REGISTER_PARTICIPANT_SUCCESS,
        payload: participant
    };
};
export const registerParticipantFail = () => {
    return {
        type: actionTypes.REGISTER_PARTICIPANT_FAIL
    };
};

export const hideSocialShareModal = () => {
    return {
        type: actionTypes.HIDE_SOCIAL_SHARE_MODAL
    };
};

export const registerExhibitor = (eventId, user, language) => async (dispatch, getState) => {
    const {
        event: {loading}
    } = getState();

    dispatch(registerExhibitorStart());

    try {
        await Api.request({
            url: '/users',
            method: 'put',
            payload: {language}
        });

        dispatch(refreshUserData());

        const response = await Api.request({
            url: `/event/${eventId}/register-exhibitor`,
            method: 'post',
            payload: user,
            loading
        });

        dispatch(getEvent(eventId, () => dispatch(registerExhibitorSuccess(response))));
    } catch (error) {
        dispatch(registerExhibitorFail(error));
    }
};

export const registerExhibitorStart = () => {
    return {
        type: actionTypes.REGISTER_EXHIBITOR_START
    };
};
export const registerExhibitorSuccess = (exhibitor) => {
    return {
        type: actionTypes.REGISTER_EXHIBITOR_SUCCESS,
        payload: exhibitor
    };
};
export const registerExhibitorFail = () => {
    return {
        type: actionTypes.REGISTER_EXHIBITOR_FAIL
    };
};

export const getOnlineUsers = () => async (dispatch, getState) => {
    const {
        event: {loading}
    } = getState();

    dispatch(getOnlineUsersStart());

    try {
        const {users} = await Api.request({
            url: `/event/get-online-users`,
            method: 'get',
            loading
        });

        dispatch(getOnlineUsersSuccess(users));
    } catch (error) {
        dispatch(getOnlineUsersFail(error));
    }
};

export const getOnlineUsersStart = () => {
    return {
        type: actionTypes.GET_ONLINE_USERS_START
    };
};
export const getOnlineUsersSuccess = (users) => {
    return {
        type: actionTypes.GET_ONLINE_USERS_SUCCESS,
        payload: users
    };
};
export const getOnlineUsersFail = () => {
    return {
        type: actionTypes.GET_ONLINE_USERS_FAIL
    };
};
export const updateOnlineUsers = (payload) => ({
    type: actionTypes.UPDATE_ONLINE_USERS,
    payload
});

export const likePost = (auditoriumId, postId) => async (dispatch, getState) => {
    const {
        event: {loading, eventId}
    } = getState();

    dispatch(likePostStart());

    try {
        const response = await Api.request({
            url: `/event/${eventId}/live-wall/${auditoriumId}/post/${postId}/like`,
            method: 'post',
            loading
        });

        dispatch(likePostSuccess(response));
    } catch (error) {
        dispatch(likePostFail(error));
    }
};

export const likePostStart = () => {
    return {
        type: actionTypes.LIKE_POST_START
    };
};
export const likePostSuccess = (liveWall) => {
    return {
        type: actionTypes.LIKE_POST_SUCCESS,
        payload: liveWall
    };
};
export const likePostFail = () => {
    return {
        type: actionTypes.LIKE_POST_FAIL
    };
};

export const unlikePost = (auditoriumId, postId) => async (dispatch, getState) => {
    const {
        event: {loading, eventId}
    } = getState();

    dispatch(unlikePostStart());

    try {
        const response = await Api.request({
            url: `/event/${eventId}/live-wall/${auditoriumId}/post/${postId}/like`,
            method: 'delete',
            loading
        });

        dispatch(unlikePostSuccess(response));
    } catch (error) {
        dispatch(unlikePostFail(error));
    }
};

export const unlikePostStart = () => {
    return {
        type: actionTypes.UNLIKE_POST_START
    };
};
export const unlikePostSuccess = (liveWall) => {
    return {
        type: actionTypes.UNLIKE_POST_SUCCESS,
        payload: liveWall
    };
};
export const unlikePostFail = () => {
    return {
        type: actionTypes.UNLIKE_POST_FAIL
    };
};

export const saveTimeSlot =
    ({data, auditoriumId, timeSlotId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId}
        } = getState();

        try {
            const {data: timeslotData} = await Api.request({
                method: 'put',
                url: `/event/${eventId}/auditoriums/${auditoriumId}/program/${timeSlotId}`,
                payload: data,
                loading: false
            });

            dispatch(getEvent(eventId));
            dispatch(getSessions(eventId));
            dispatch(updateTimeSlotData(timeslotData));

            callback && callback();
        } catch (e) {}
    };

export const getEvent = (eventId, callback) => async (dispatch, getState) => {
    const {
        event: {loading}
    } = getState();

    dispatch(getEventStart());

    try {
        const {event} = await Api.request({
            url: `/event/${eventId}`,
            method: 'get',
            loading
        });

        dispatch(getEventSuccess(event));
        if (callback) callback(event);
    } catch (error) {
        dispatch(getEventFail(error));
    }
};

export const getEventStart = () => {
    return {
        type: actionTypes.GET_EVENT_START
    };
};
export const getEventSuccess = (event) => {
    return {
        type: actionTypes.GET_EVENT_SUCCESS,
        payload: event
    };
};
export const getEventFail = () => {
    return {
        type: actionTypes.GET_EVENT_FAIL
    };
};

export const getEventInfo = (eventId, callback) => async (dispatch, getState) => {
    const {
        user,
        event: {loading}
    } = getState();

    dispatch(getEventInfoStart());

    try {
        const {event} = await Api.request({
            url: `/event-info/${eventId}`,
            method: 'get',
            loading
        });

        const defaultEventLanguage = event.language;

        // make sure we switching language only for event landing pages
        if (event.status === EVENT_STATUSES.public && !user.data) {
            dispatch(setEventLanguage(defaultEventLanguage));
        }

        dispatch(getEventInfoSuccess(event));

        callback && callback(event);
    } catch (error) {
        dispatch(getEventInfoFail(error));
    }
};

export const getEventInfoStart = () => {
    return {
        type: actionTypes.GET_EVENT_INFO_START
    };
};
export const getEventInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_EVENT_INFO_SUCCESS,
        payload: data
    };
};
export const getEventInfoFail = () => {
    return {
        type: actionTypes.GET_EVENT_INFO_FAIL
    };
};

export const getEventUserRegistration =
    ({eventId, userRole = USER_ROLES.participant, shortLink, callback, packageId}) =>
    async (dispatch, getState) => {
        const {
            event: {loading}
        } = getState();

        dispatch(getEventUserRegistrationStart());

        // TODO: refactor
        try {
            let data;

            if (shortLink) {
                const url = `/event/${eventId}/registration-form/${shortLink}${
                    packageId ? `/package/${packageId}` : ''
                }`;
                const response = await Api.request({
                    url,
                    method: 'get',
                    loading
                });

                data = response.registrationForm;
            } else {
                const url = `/event/${eventId}/registration-form/get-by-filter`;
                const response = await Api.request({
                    url,
                    method: 'get',
                    loading,
                    params: {
                        type: userRole,
                        default: true,
                        showOnRegistration: true,
                        isActive: true
                    }
                });

                data = response.registrationForms[0]; // TODO: fix , api need to response with obj not with array with one item
            }

            dispatch(getEventUserRegistrationSuccess(data));
            callback && callback();
        } catch (error) {
            dispatch(getEventUserRegistrationFail(error));
        }
    };

export const getEventUserRegistrationStart = () => {
    return {
        type: actionTypes.GET_EVENT_USER_REGISTRATION_START
    };
};
export const getEventUserRegistrationSuccess = (data) => {
    return {
        type: actionTypes.GET_EVENT_USER_REGISTRATION_SUCCESS,
        payload: data
    };
};
export const getEventUserRegistrationFail = () => {
    return {
        type: actionTypes.GET_EVENT_USER_REGISTRATION_FAIL
    };
};
export const setEventShortLink = (shortLink) => {
    return {
        type: actionTypes.SET_EVENT_SHORT_LINK,
        payload: shortLink
    };
};
export const setEventRegPackage = (packageId) => {
    return {
        type: actionTypes.SET_EVENT_REG_PACKAGE,
        payload: packageId
    };
};
export const sideNavHasVideoWall = (booleanValue) => {
    return {
        type: actionTypes.SIDE_NAV_HAS_VIDEO_WALL,
        payload: booleanValue
    };
};

/**
 * @param {string} wallData.wallId
 * @param {array} wallData.highlightUsers
 * @param {bool} wallData.canModerateChat
 */
export const setActiveWall = (wallData) => {
    return {
        type: actionTypes.SET_ACTIVE_WALL,
        payload: wallData
    };
};

export const closeActiveWall = () => {
    return {
        type: actionTypes.CLOSE_ACTIVE_WALL
    };
};

export const setTimeSlotVideoWall = (wallData) => {
    return {
        type: actionTypes.SET_TIME_SLOT_VIDEO_WALL_ID,
        payload: wallData
    };
};

export const newFlashNotification = (flashNotification) => {
    return {
        type: actionTypes.NEW_FLASH_NOTIFICATION,
        payload: flashNotification
    };
};

export const closeFlashNotification = () => {
    return {
        type: actionTypes.CLOSE_FLASH_NOTIFICATION
    };
};

export const changeParticipantsRegistrationAccess = (payload) => ({
    type: actionTypes.PARTICIPANT_REGISTRATION_ACCESS_CHANGED,
    payload
});

export const changeUsersLoginAccess = (payload) => ({
    type: actionTypes.USERS_LOGIN_ACCESS_CHANGED,
    payload
});

export const deleteEvent =
    ({callback}) =>
    async (dispatch, getState) => {
        const {
            event: {loading, eventId}
        } = getState();

        dispatch(deleteEventRequest());

        try {
            await Api.request({
                method: 'delete',
                url: `/event/${eventId}`,
                loading
            });

            dispatch(deleteEventSuccess());

            if (callback) callback();
        } catch (error) {
            dispatch(deleteEventFail());
        }
    };

const deleteEventRequest = () => ({
    type: actionTypes.DELETE_EVENT_REQUEST
});

const deleteEventSuccess = () => ({
    type: actionTypes.DELETE_EVENT_SUCCESS
});

const deleteEventFail = () => ({
    type: actionTypes.DELETE_EVENT_FAIL
});

export const notifyTimeSlotUser =
    ({role, userId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId}
        } = getState();

        try {
            const {success} = await Api.request({
                method: 'post',
                url: `/event/${eventId}/${role}/${userId}/notify`
            });

            callback && callback(success);
        } catch (error) {
            console.log(`notify ${role} error`, error);
        }
    };

export const getEventUsersList = (callback) => async (dispatch, getState) => {
    const {
        event: {eventId}
    } = getState();

    dispatch(getEventUsersListRequest());

    try {
        const {users} = await Api.request({
            method: 'get',
            url: `/event/${eventId}/users/list`
        });

        dispatch(getEventUsersListSuccess(users));

        if (callback) callback();
    } catch (error) {
        dispatch(getEventUsersListFail());
    }
};

const getEventUsersListRequest = () => ({
    type: actionTypes.GET_EVENT_USERS_LIST_REQUEST
});

const getEventUsersListSuccess = (payload) => ({
    type: actionTypes.GET_EVENT_USERS_LIST_SUCCESS,
    payload
});

const getEventUsersListFail = () => ({
    type: actionTypes.GET_EVENT_USERS_LIST_FAIL
});

export const getEventProfile = (callback) => async (dispatch, getState) => {
    const {
        event: {eventId}
    } = getState();

    dispatch(getEventProfileRequest());

    try {
        const {profile} = await Api.request({
            method: 'get',
            url: `/event/${eventId}/profile`
        });

        dispatch(getEventProfileSuccess(profile));

        if (callback) callback();
    } catch (error) {
        dispatch(getEventProfileFail());
    }
};

const getEventProfileRequest = () => ({
    type: actionTypes.GET_EVENT_PROFILE_REQUEST
});

const getEventProfileSuccess = (payload) => ({
    type: actionTypes.GET_EVENT_PROFILE_SUCCESS,
    payload
});

const getEventProfileFail = () => ({
    type: actionTypes.GET_EVENT_PROFILE_FAIL
});

export const setUserInitialEventId = (payload) => ({
    type: actionTypes.SET_USER_INITIAL_EVENT_ID,
    payload
});

export const setUpdatedAudtitoriumInEvent = (payload) => ({
    type: actionTypes.SET_UPDATED_AUDTITORIUM_IN_EVENT,
    payload
});

export const setUpdatedAudtitoriumInEventHub = (payload) => ({
    type: actionTypes.SET_UPDATED_AUDTITORIUM_IN_EVENT_HUB,
    payload
});

const updateEventStart = () => ({
    type: actionTypes.UPDATE_EVENT_START
});

const updateEventSuccess = (event = {}) => ({
    type: actionTypes.UPDATE_EVENT_SUCCESS,
    payload: event
});

const updateEventFail = (error) => ({
    type: actionTypes.UPDATE_EVENT_FAIL,
    payload: error
});

export const updateEvent = (payload, eventId, callback) => {
    return async (dispatch) => {
        dispatch(updateEventStart());
        try {
            const {event} = await Api.request({
                method: 'post',
                url: `/event/${eventId}`,
                payload,
                loading: true
            });
            dispatch(updateEventSuccess(event));
            callback && callback();
        } catch (error) {
            dispatch(updateEventFail());
        }
    };
};

const setEnabledEventMatchmakingStart = () => ({
    type: actionTypes.SET_ENABELED_EVENT_MATCHMAKING_START
});

const setEnabledEventMatchmakingSuccess = (data) => ({
    type: actionTypes.SET_ENABELED_EVENT_MATCHMAKING_SUCCESS,
    payload: data
});

const setEnabledEventMatchmakingFail = (error) => ({
    type: actionTypes.SET_ENABELED_EVENT_MATCHMAKING_FAIL,
    payload: error
});

export const setEnabledEventMatchmaking = (value) => {
    return async (dispatch, getState) => {
        dispatch(setEnabledEventMatchmakingStart());
        try {
            const {
                event: {eventId}
            } = getState();
            await Api.request({
                url: `/event/${eventId}/update-matching`,
                method: 'post',
                payload: {
                    matchingEnabled: value
                }
            });

            dispatch(setEnabledEventMatchmakingSuccess(value));
        } catch (error) {
            dispatch(setEnabledEventMatchmakingFail(error));
        }
    };
};

export const socetSetEnabeledEventMatchmaking = (payload) => ({
    type: actionTypes.SOCET_SET_ENABELED_EVENT_MATCHMAKING,
    payload
});

export const socetSetEventHasMatchmaking = (payload) => ({
    type: actionTypes.SOCET_SET_EVENT_HAS_MATCHMAKING,
    payload
});

const getEventFiltersStart = () => ({
    type: actionTypes.GET_EVENT_FILTERS_START
});

const getEventFiltersParticipantSuccess = (data) => ({
    type: actionTypes.GET_EVENT_FILTERS_PARTICIPANT_SUCCESS,
    payload: data
});

const getEventFiltersExhibitorSuccess = (filters) => ({
    type: actionTypes.GET_EVENT_FILTERS_EXHIBITOR_SUCCESS,
    payload: filters
});

const getEventFiltersFail = (error) => ({
    type: actionTypes.GET_EVENT_FILTERS_FAIL,
    payload: error
});

export const getEventFilters =
    ({userType = USER_ROLES.participant}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading}
        } = getState();

        dispatch(getEventFiltersStart());
        try {
            const {filter} = await Api.request({
                method: 'get',
                url: `/event/${eventId}/event-filter?type=${userType}`,
                loading
            });

            dispatch(
                userType === USER_ROLES.participant
                    ? getEventFiltersParticipantSuccess({filter, eventId})
                    : getEventFiltersExhibitorSuccess(filter)
            );
        } catch (error) {
            dispatch(getEventFiltersFail());
        }
    };

export const updateEventData = (eventObj) => ({type: actionTypes.UPDATE_EVENT_DATA, payload: eventObj});

const updateActiveCampaignIntegrationStart = () => ({
    type: actionTypes.UPDATE_ACTIVE_CAMPAIGN_INTEGRATION_START
});

const updateActiveCampaignIntegrationSuccess = (data) => ({
    type: actionTypes.UPDATE_ACTIVE_CAMPAIGN_INTEGRATION_SUCCESS,
    payload: data
});

const updateActiveCampaignIntegrationFail = (error) => ({
    type: actionTypes.UPDATE_ACTIVE_CAMPAIGN_INTEGRATION_FAIL,
    payload: error
});

export const updateActiveCampaignIntegration = ({payload, callback}) => {
    return async (dispatch, getState) => {
        try {
            dispatch(updateActiveCampaignIntegrationStart());
            const {
                event: {eventId}
            } = getState();
            const {activeCampaignIntegration} = await Api.request({
                url: `/event/${eventId}/active-campaign-integration`,
                method: 'put',
                payload,
                errorObj: {show: true}
            });

            dispatch(updateActiveCampaignIntegrationSuccess(activeCampaignIntegration));
            callback && callback();
        } catch (error) {
            dispatch(updateActiveCampaignIntegrationFail(error));
        }
    };
};

const getOnDemantVideosStart = () => ({
    type: actionTypes.GET_ON_DEMANT_VIDEOS_START
});

const getOnDemantVideosSuccess = (data) => ({
    type: actionTypes.GET_ON_DEMANT_VIDEOS_SUCCESS,
    payload: data
});

const getOnDemantVideosFail = (error) => ({
    type: actionTypes.GET_ON_DEMANT_VIDEOS_FAIL,
    payload: error
});

export const getOnDemantVideos = () => {
    return async (dispatch, getState) => {
        dispatch(getOnDemantVideosStart());
        try {
            const {
                event: {eventId}
            } = getState();
            const {onDemandVideos} = await Api.request({
                url: `/event/${eventId}/on-demand-videos`,
                method: 'get'
            });

            dispatch(getOnDemantVideosSuccess(onDemandVideos));
        } catch (error) {
            dispatch(getOnDemantVideosFail(error));
        }
    };
};

export const addOnDemandVideo = (payload) => ({type: actionTypes.ADD_ON_DEMANT_VIDEO, payload});

export const resetActiveWallId = () => ({type: actionTypes.RESET_ACTIVE_WALL_ID});
