import {createSelector} from 'reselect';

const sessions = (state) => state.sessions;

export const getSessions = createSelector(sessions, (state) => state);

export const getSessionsList = createSelector(sessions, (state) => state.sessionsList.sessions);

export const getSessionsListIsLoading = createSelector(sessions, (state) => state.sessionsList.isLoading);

export const getZoomSessionData = createSelector(sessions, (state) => state.zoomSessionDashboard.session);

export const getZoomSessionLoading = createSelector(sessions, (state) => state.zoomSessionDashboard.isLoading);

export const getSessionsListData = createSelector(sessions, (state) => state.sessionsList);
