import React from 'react';
import {connect} from 'react-redux';
import {KeyboardArrowDown as KeyboardArrowDownIcon} from '@material-ui/icons';

import ExhibitorsListMobile from './components/ExhibitorsListMobile/ExhibitorsListMobile';

import './ExhibitorsOverlayMobile.scss';
class ExhibitorsOverlayMobile extends React.Component {
    state = {
        showExhibitorsOverlay: false,
        translation: null,
    };

    componentDidMount() {
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    openExhibitorsOverlay = () => {
        this.setState({
            showExhibitorsOverlay: true,
        });
    };

    closeExhibitorsOverlay = () => {
        this.setState({
            showExhibitorsOverlay: false,
        });
    };

    render() {
        const {translation} = this.state;
        return (
            <div className="exhibitors-overlay-mobile">
                <div className="exhibitors-container">
                    <h4 className="title" onClick={this.openExhibitorsOverlay}>
                        <span>{translation?.showfloor.allExhibitorsButton}</span>
                        <KeyboardArrowDownIcon />
                    </h4>
                </div>
                <ExhibitorsListMobile open={this.state.showExhibitorsOverlay} onClose={this.closeExhibitorsOverlay} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ExhibitorsOverlayMobile);
