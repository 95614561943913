import React from 'react';
import {TextValidator} from 'react-material-ui-form-validator';

class TextField extends React.Component {
    handleChange = (e) => {
        this.props.handleChange(e.target.name, e.target.value);
    };

    render() {
        const {field, translatedLabel} = this.props;
        return (
            <TextValidator
                key={field.name}
                label={translatedLabel || field.label}
                type={field.type}
                name={field.name}
                value={field.value}
                variant={this.props.variant || 'filled'}
                onChange={this.handleChange}
                validators={field.validators}
                errorMessages={field.errorMessages}
                multiline={field.multiline > 0}
                rows={field.multiline}
                fullWidth={true}
                margin="normal"
                className="field-container"
            />
        );
    }
}

export default TextField;
