import React from 'react';

import Media from '../../../../../../../Media/Media';
import IconContainer from '../../../../../../../IconContainer/IconContainer';
import {IconMobile, IconPhone, IconEmail, IconLayout} from '../../../../../../../Icons';

import {parseAspectRatio} from '../../../../../../../../../services/utils';
import {useMedia} from '../../../../../../../../../services/hooks';

import './BusinessCardPreview.scss';

const BUSINESS_CARD_ASPECT_RATIO = '7:4';
const LOGO_ASPECT_RATIO = '7:4';

const getPaddingTop = (aspectRatio) => {
    parseAspectRatio(aspectRatio);

    const [widthRatio, heightRatio] = parseAspectRatio(aspectRatio);

    return `${(heightRatio / widthRatio) * 100}%`;
};

const formContactList = ({mobile, phone, email, website}) => {
    const list = [];
    if (mobile) list.push({value: mobile, icon: <IconMobile />});
    if (phone) list.push({value: phone, icon: <IconPhone />});
    if (email) list.push({value: email, icon: <IconEmail />});
    if (website) list.push({value: website, icon: <IconLayout />});

    return list;
};

const formColorStyles = ({primaryColor, secondaryColor, backgroundColor}) => ({
    '--primary-color': primaryColor,
    '--secondary-color': secondaryColor,
    '--background-color': backgroundColor,
});

const getIconSize = (isDesktop, isMobile) => {
    if (isDesktop) {
        return 'xlarge';
    }

    if (isMobile) {
        return 'medium';
    }

    return 'large';
};

const Contact = ({icon, value}) => {
    const isDesktop = useMedia('(min-width: 1100px)');
    const isMobile = useMedia('(max-width: 840px)');

    return (
        <div className="BusinessCardPreview__contact">
            <div className="BusinessCardPreview__contactIcon">
                <IconContainer size={getIconSize(isDesktop, isMobile)}>{icon}</IconContainer>
            </div>

            <div className="BusinessCardPreview__contactValue">{value}</div>
        </div>
    );
};

const BusinessCardPreview = ({
    firstName,
    lastName,
    designation,
    companyName,
    mobile,
    phone,
    email,
    website,
    logo,
    primaryColor,
    secondaryColor,
    backgroundColor,
}) => (
    <div
        className="BusinessCardPreview"
        style={{
            ...formColorStyles({primaryColor, secondaryColor, backgroundColor}),
            paddingTop: getPaddingTop(BUSINESS_CARD_ASPECT_RATIO),
        }}
    >
        <div className="BusinessCardPreview__content">
            <div className="BusinessCardPreview__col BusinessCardPreview__col_general">
                <div className="BusinessCardPreview__group">
                    <div className="BusinessCardPreview__name BusinessCardPreview__name_highlighted">{firstName}</div>
                    <div className="BusinessCardPreview__name">{lastName}</div>

                    {(designation || companyName) && <div className="BusinessCardPreview__separator" />}
                    {designation && <div className="BusinessCardPreview__designation">{designation}</div>}
                    {companyName && <div className="BusinessCardPreview__companyName">{companyName}</div>}
                </div>
            </div>

            <div className="BusinessCardPreview__col BusinessCardPreview__col_highlighted">
                <div className="BusinessCardPreview__group BusinessCardPreview__group_withVerticalDirection">
                    {logo.media && (
                        <div className="BusinessCardPreview__logoPositioner">
                            <div
                                className="BusinessCardPreview__logoHolder"
                                style={{paddingTop: getPaddingTop(LOGO_ASPECT_RATIO)}}
                            >
                                <div className="BusinessCardPreview__logo">
                                    <Media media={logo.media} isAdaptable />
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="BusinessCardPreview__contactList">
                        {formContactList({email, mobile, phone, website}).map(({icon, value}, index) => (
                            <div key={index} className="BusinessCardPreview__contactItem">
                                <Contact icon={icon} value={value} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export {LOGO_ASPECT_RATIO as BUSINESS_LOGO_ASPECT_RATIO, getPaddingTop};
export default BusinessCardPreview;
