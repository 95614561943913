import React, {useEffect, useCallback, useState} from 'react';

import UsersList from './components/UsersList';
import FilterChipsPopUp from './components/FilterChipsPopUp/FilterChipsPopUp';
import ShowFloorFilter from '../../../ExhibitorsOverlay/components/ShowFloorFilter/ShowFloorFilter';
import {usePlatformTranslation} from '../../../../../services/hooks';
import Spinner from '../../../Spinner';
import {FILTER_TYPES, USER_ROLES} from 'constants/shared';
import {useShowMatchMaking} from 'services/helpers';
import MatchMaking from './components/MatchMaking';
import MatchingCalculationLoader from './components/MatchingCalculationLoader/MatchingCalculationLoader';
import {transformFiltersToApply} from 'services/helpers';
import classNames from 'classnames';

import './Networking.scss';
import {eventHasMatchingUpdated, matchmakingEnabledUpdated} from 'Api/socketApi';

export const Networking = ({
    eventId,
    sideNavigation,
    eventParticipantFilters,
    eventParticipantFiltersEventId,
    onGetOnlineUsers,
    onApplyParticipantsFilters,
    onSetParticipantsFilters,
    onGetUserMatches,
    onRecalculateUserMatches,
    userLoading,
    onGetGroupsList,
    onGetEventFilters,
}) => {
    const {
        participantFilters,
        filteredParticipants: {usersList, pagination},
        loading,
    } = sideNavigation;

    const [isFilterOpened, setIsFilterOpened] = useState(false);
    const [search, setSearch] = useState();
    const [filterType, setFilterType] = useState(FILTER_TYPES.abc);
    const [currentPage, setCurrentPage] = useState(pagination.page || 1);

    const showMatchMaking = useShowMatchMaking();
    const t_filter = usePlatformTranslation((translation) => translation.filter);

    useEffect(() => {
        matchmakingEnabledUpdated((err, data) => {
            if (data.eventId !== eventId || data.matchingEnabled) return;
            setFilterType(FILTER_TYPES.abc);
            onApplyParticipantsFilters({eventId});
        });
        eventHasMatchingUpdated((err, data) => {
            if (data.eventId !== eventId || data.hasMatching) return;
            setFilterType(FILTER_TYPES.abc);
            onApplyParticipantsFilters({eventId});
        });
    }, [eventId, onApplyParticipantsFilters]);

    useEffect(() => {
        if (!eventParticipantFilters || eventId !== eventParticipantFiltersEventId) {
            onGetEventFilters({userType: USER_ROLES.participant});
        }
    }, [eventParticipantFilters, eventParticipantFiltersEventId, eventId, onGetEventFilters]);

    useEffect(() => {
        onGetGroupsList({userRole: USER_ROLES.participant});
    }, [onGetGroupsList]);

    useEffect(() => {
        onGetOnlineUsers();
        onApplyParticipantsFilters({eventId});
    }, [onGetOnlineUsers, onApplyParticipantsFilters, eventId]);

    const handleFilterOpened = (value) => setIsFilterOpened(value);

    const handleSearch = useCallback(
        (value) => {
            switch (filterType) {
                case FILTER_TYPES.matches: {
                    const callback = () => onGetUserMatches({page: 1, search: value});
                    onRecalculateUserMatches({callback});
                    return;
                }

                case FILTER_TYPES.abc: {
                    onApplyParticipantsFilters({eventId, search: value});
                    return;
                }

                default:
                    return;
            }
        },
        [filterType, eventId, onApplyParticipantsFilters, onRecalculateUserMatches, onGetUserMatches]
    );

    useEffect(() => {
        // this if is to prevent  function call on first rener
        if (search !== undefined) handleSearch(search);
        //  here we use useEffect because of react bug (or not corect code) of not updating function scope .
        //  In this case not updating scope of handleSearch() with new "filterType" state
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const setFilters = (filters, filteringType = FILTER_TYPES.abc) => {
        // to set filters for chips
        setCurrentPage(1);
        onSetParticipantsFilters(filters);
        if (filteringType !== filterType) setFilterType(filteringType);

        if (filteringType === FILTER_TYPES.matches) {
            setFilterType(filteringType);
            const callback = () => onGetUserMatches({page: 1, search});
            onRecalculateUserMatches({callback});
            return;
        }

        const filtersData = transformFiltersToApply(filters);

        onApplyParticipantsFilters({eventId, filter: filtersData, search});
    };

    const LoaderComponent = filterType === FILTER_TYPES.abc ? Spinner : MatchingCalculationLoader;

    const isLoading = (userLoading || loading) && currentPage === 1;

    return (
        <div className="networking-container">
            <div className="participants-page">
                <div>
                    {showMatchMaking && <MatchMaking filterType={filterType} setFilters={setFilters} />}
                    <div className="search-user-container">
                        <ShowFloorFilter
                            className="participants-header__filter"
                            filters={eventParticipantFilters}
                            setIsOpened={handleFilterOpened}
                            isOpened={isFilterOpened}
                            setSearch={setSearch}
                            usingFilters={participantFilters}
                            applyFilter={setFilters}
                            users={usersList}
                            tooltipText={t_filter?.tooltip}
                        />
                    </div>

                    {!isFilterOpened && <FilterChipsPopUp filters={participantFilters} setFilters={setFilters} />}

                    <div
                        className={classNames('custom-scroll-container networking-wrapper', {
                            withMatching: showMatchMaking,
                        })}
                    >
                        {isLoading ? (
                            <LoaderComponent />
                        ) : (
                            <UsersList
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                search={search}
                                filterType={filterType}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default React.memo(Networking);
