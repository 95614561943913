import React, {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import VideoChatButtons from '../VideoChatButtons';

import ParticipantVideo from './components/ParticipantVideo';
import SelfVideo from './components/SelfVideo';

import {enableSelfVideo, disableSelfVideo, endCall} from 'Api/socketApi';
import {getActiveCallTypeData, getUserId, getIceCandidates} from 'store/selectors';
import {
    closeVideoRoom,
    toggleScreenShare,
    endOneToOneCall,
    getScreenShareCapture,
    getMediaCapture,
} from 'store/actions';
import {stopLocalStream} from 'services/helpers';

import {MEDIA_TYPES} from 'constants/organizer/sessions';
import {Box} from '@material-ui/core';

export function OneToOneCallRoom({participant, isFullScreen}) {
    const dispatch = useDispatch();
    const [soundEnabled, setSoundEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [callEnded, setCallEnded] = useState(false);
    const {isLoading, capture, shareScreenCapture, isParticipantStreamActive, isScreenShared} =
        useSelector(getActiveCallTypeData);
    const iceCandidates = useSelector(getIceCandidates);
    const userId = useSelector(getUserId);

    const handleCloseVideoRoom = useCallback(() => {
        if (isParticipantStreamActive && iceCandidates.length) {
            endCall({from: userId, to: participant._id});
            dispatch(endOneToOneCall());
        }

        setCallEnded(true);
        dispatch(closeVideoRoom());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [participant._id, userId, isParticipantStreamActive, iceCandidates]);

    const handleToggleMedia = useCallback(
        (mediaKind) => () => {
            const enabled = mediaKind === MEDIA_TYPES.audio ? soundEnabled : videoEnabled;
            const setEnabled = mediaKind === MEDIA_TYPES.audio ? setSoundEnabled : setVideoEnabled;
            if (!capture) return;
            capture.getTracks().forEach((track) => {
                if (track.kind === mediaKind) track.enabled = !enabled;
            });

            if (mediaKind === MEDIA_TYPES.video && enabled) {
                disableSelfVideo({from: userId, to: participant._id});
            } else if (mediaKind === MEDIA_TYPES.video && !enabled) {
                enableSelfVideo({from: userId, to: participant._id});
            }

            setEnabled(!enabled);
        },
        [capture, participant._id, soundEnabled, userId, videoEnabled]
    );

    const handleToggleScreenShare = useCallback(() => {
        if (!isScreenShared) {
            dispatch(getScreenShareCapture({soundEnabled, videoEnabled}));
        } else {
            stopLocalStream(shareScreenCapture);
            videoEnabled && dispatch(getMediaCapture(true));
        }

        dispatch(toggleScreenShare());
    }, [dispatch, isScreenShared, shareScreenCapture, soundEnabled, videoEnabled]);

    return (
        <Box height="100%">
            <ParticipantVideo
                isParticipantStreamActive={isParticipantStreamActive}
                participant={participant}
                callEnded={callEnded}
                isFullScreen={isFullScreen}
            />
            <SelfVideo videoEnabled={videoEnabled} />
            <VideoChatButtons
                soundEnabled={soundEnabled}
                videoEnabled={videoEnabled}
                toggleSound={handleToggleMedia(MEDIA_TYPES.audio)}
                toggleVideo={handleToggleMedia(MEDIA_TYPES.video)}
                toggleScreenShare={handleToggleScreenShare}
                isLoading={isLoading}
                close={handleCloseVideoRoom}
            />
        </Box>
    );
}

OneToOneCallRoom.propTypes = {
    participant: PropTypes.object.isRequired,
    isFullScreen: PropTypes.bool.isRequired,
};
