import React from 'react';

const ChatIcon = ({secondary, primary}) => (
    <svg width="76" height="40" viewBox="0 0 76 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_d)">
            <path
                d="M45.121 30.5C40.9569 26.5 34.5372 28.1666 32.1082 29.5L35.2312 10.5L45.121 30.5Z"
                fill={primary ?? '#DDAD02'}
            />
            <path
                d="M37.313 18C37.313 22.7787 35.132 27.0682 31.674 30C28.7378 32.4894 24.8808 34 20.6565 34C11.4574 34 4 26.8365 4 18C4 9.16343 11.4574 2 20.6565 2C27.0342 2 32.5748 5.4432 35.3734 10.5C36.6115 12.737 37.313 15.2898 37.313 18Z"
                fill={primary ?? '#DDAD02'}
            />
            <path
                d="M30.8788 5.50008C35.043 9.50008 41.4627 7.83348 43.8917 6.50015L40.7686 25.5001L30.8788 5.50008Z"
                fill={secondary ?? '#987702'}
            />
            <path
                d="M38.687 18C38.687 13.2213 40.8679 8.93181 44.3259 6.00004C47.2622 3.51059 51.1192 2.00005 55.3434 2.00005C64.5426 2.00004 71.9999 9.16348 71.9999 18C71.9999 26.8366 64.5426 34 55.3435 34C48.9657 34 43.4252 30.5568 40.6265 25.5C39.3884 23.263 38.687 20.7102 38.687 18Z"
                fill={secondary ?? '#987702'}
            />
        </g>
        <defs>
            <filter
                id="filter0_d"
                x="0"
                y="0"
                width="75.9999"
                height="40"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default ChatIcon;
