import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    descriptionText: {
        marginBottom: 20,
        fontSize: 16,
        color: theme.palette.text.primary,
    },
    warningText: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 25,
        fontSize: 12,
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    warningIcon: {
        marginRight: 10,
    },
    form: {
        padding: 0,
    },
    participantsSelect: {
        width: '50%',
        marginBottom: 25,
    },
}));
