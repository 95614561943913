import {
    SpeechTranslationConfig,
    TranslationRecognizer,
    AudioConfig,
    CancellationReason,
} from 'microsoft-cognitiveservices-speech-sdk';
import {limitedInterpretationTargetLanguages} from '../../../constants/data';

const defaultTargetLocales = limitedInterpretationTargetLanguages.map(({locale}) => locale);

// eslint-disable-next-line
const LIMITERRX = new RegExp(/[^\.,!\?]+[\.,!\?]+/g);
const DELAY_ON_CONNECT = 1000;
const MAX_TRYS_TO_CONNECT = 5;

const createSpeechTranslationConfig = (sourceLocale, targetLocales) => {
    const speechTranslationConfig = SpeechTranslationConfig.fromSubscription(
        '07a4c22b309940209ad438c4b7bcfce7',
        'southeastasia'
    );

    speechTranslationConfig.speechRecognitionLanguage = sourceLocale;
    targetLocales.forEach((targetLocale) => speechTranslationConfig.addTargetLanguage(targetLocale));

    return speechTranslationConfig;
};

export const getRecognizerControls = ({
    mediaStream,
    sourceLocale,
    targetLocales = defaultTargetLocales,
    onRecognized = () => {},
    onRecognizing = () => {},
}) => {
    const speechTranslationConfig = createSpeechTranslationConfig(sourceLocale, targetLocales);
    const audioConfig = AudioConfig.fromStreamInput(mediaStream);
    const translator = new TranslationRecognizer(speechTranslationConfig, audioConfig);

    const start = () => {
        console.log('start recognition', sourceLocale, translator.speechRecognitionLanguage);
        try {
            translator.recognizing = (s, e) => {
                console.log(`[recognizing] ${e.result.text}`);
                console.log(translator.speechRecognitionLanguage);
                onRecognizing(e.result.translations);
            };

            translator.recognized = (s, e) => {
                console.log(`[recognized] ${e.result.text}`);
                console.log(translator.speechRecognitionLanguage);
                onRecognized(e.result.translations);
            };

            translator.canceled = (s, e) => {
                console.log(`CANCELED: Reason=${e.reason}`);
                if (e.reason === CancellationReason.Error) {
                    console.log(`CANCELED: ErrorCode=${e.errorCode}`);
                    console.log(`CANCELED: ErrorDetails=${e.errorDetails}`);
                    console.log('CANCELED: Did you update the subscription info?');
                }
                translator.stopContinuousRecognitionAsync();
            };

            translator.sessionStopped = (s, e) => {
                console.log('\n    Session stopped event.', s, e);
            };

            translator.sessionStarted = (s, e) => {
                console.log('\n    Session started event.', s, e);
            };
            translator.startContinuousRecognitionAsync();
        } catch (err) {
            console.error(err);
        }
    };

    const stop = () => {
        console.log('stop recognition');
        translator.dispose(false);
    };

    return [start, stop];
};

export const limitTranslatoin = (originText, callback) => {
    const result = originText.match(LIMITERRX).join(' ');
    return callback(result);
};

export const awaitActiveMediaStream = (mediaStream) => {
    return new Promise((res, rej) => {
        let count = 0;
        const interval = setInterval(() => {
            count++;
            if (count >= MAX_TRYS_TO_CONNECT) {
                clearInterval(interval);
                rej();
            }
            if (mediaStream.active) {
                clearInterval(interval);
                res();
            }
        }, DELAY_ON_CONNECT);
    });
};
