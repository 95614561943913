export const loadStates = {
    loading: 'loading',
    ready: 'ready',
    error: 'error',
};

export const mediaTypes = {
    image: 'image',
    video: 'video',
};

export const agendaTypes = {
    meeting: 'meeting',
    program: 'program',
    custom: 'custom',
};

export const meetingStatuses = {
    pending: 'pending',
    confirmed: 'confirmed',
    requested: 'requested',
};

export const userStatuses = {
    online: 'online',
    offline: 'offline',
    busy: 'busy',
};

export const socialsSlug = {
    linkedin: 'linkedin',
    facebook: 'facebook',
    twitter: 'twitter',
};

export {default as interpretationSourceLanguages} from './interpretation-source-languages.json';
export {default as interpretationTargetLanguages} from './interpretation-target-languages.json';
export {default as limitedInterpretationTargetLanguages} from './limited-interpretation-target-languages.json';
export {default as mimoTypeByExtenstion} from './mimo-type-by-extension';
export * from './registration-default-fields';
export * from './registration-user-custom-fields';
