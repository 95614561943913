import SocialsPromotion from './SocialsPromotion';
import {connect} from 'react-redux';
import * as actions from 'store/actions/socialsPromotionActions';
import {getPromotionData, getPromotionError} from 'store/selectors';

export default connect(
    (state) => ({
        promotion: getPromotionData(state),
        promotionError: getPromotionError(state),
    }),
    {
        getSocialsPromotion: actions.getSocialsPromotion,
    }
)(SocialsPromotion);
