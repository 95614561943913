import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        marginTop: 100,
        padding: '0 30px',
        minHeight: 'calc(100vh - 514px)',
        marginBottom: 40,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            marginTop: 60,
        },
    },
}));
