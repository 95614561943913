import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from '../../../../../store/actions';
import {useStyles} from './ViewAllEvents.style';
import RenderEventList from '../../../MyAccount/components/AccountPagesScene/components/MyEvents/components/RenderEventList';
import Footer from '../../../Footer';

export default function ViewAllEvents() {
    const dispatch = useDispatch();
    const style = useStyles();
    const allListedEvents = useSelector((state) => state.eventListing.allListedEvents);

    React.useEffect(() => {
        dispatch(actions.getAllListedEvents());
        dispatch(actions.getEventsListingDates());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className={style.container}>
                <RenderEventList data={allListedEvents} isInfiniteScroll showSearch isFromViewAll />
            </div>
            <Footer />
        </>
    );
}
