import React from 'react';
import {TextValidator} from 'react-material-ui-form-validator';
import {Radio, RadioGroup, FormControlLabel, FormControl, FormLabel} from '@material-ui/core';

class RadioField extends React.Component {
    handleChange = (e) => {
        this.props.handleChange(e.target.name, e.target.value);
    };

    render() {
        const {field} = this.props;
        return (
            <>
                <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">{field.label}</FormLabel>
                    <RadioGroup
                        aria-label={field.label}
                        name={field.name}
                        value={field.value}
                        onChange={this.handleChange}
                        className="registration-radio-group"
                    >
                        {field.options.map((option) => {
                            return (
                                <FormControlLabel
                                    value={option._id}
                                    control={<Radio />}
                                    label={option.value}
                                    key={option._id}
                                />
                            );
                        })}
                    </RadioGroup>
                </FormControl>
                <TextValidator
                    key={field.name}
                    label={field.label}
                    type="text"
                    name={field.name}
                    value={field.value}
                    variant="outlined"
                    onChange={this.handleChange}
                    validators={field.validators}
                    errorMessages={field.errorMessages}
                    multiline={field.multiline > 0}
                    rows={field.multiline}
                    fullWidth={true}
                    margin="normal"
                    className="field-container hidden"
                />
            </>
        );
    }
}

export default RadioField;
