import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
    StopScreenShare as StopScreenShareIcon,
    ScreenShare as ScreenShareIcon,
    CallEnd as CallEndIcon,
    Mic as MicIcon,
    MicOff as MicOffIcon,
    Videocam as VideocamIcon,
    VideocamOff as VideocamOffIcon
} from '@material-ui/icons';

import {BUTTON_TYPES} from 'constants/organizer/sessions';
import {useParticipantSharedScreen} from 'services/hooks/organizer/sessions/useParticipantSharedScreen';

import {useStyles} from './VideoPlayerButtons.styles';

export const VideoPlayerButtons = ({
    withCallEndIcon,
    onStopCall,
    mediaSession,
    localStream,
    isScreenShared,
    isMuted,
    isVideoPaused,
    getLocalStream,
    getScreenShareStream,
    toggleSelfVideo,
    toggleSelfAudio,
    toggleScreenShareVideo,
    disableScreenShare
}) => {
    const classes = useStyles();
    const isParticipantScreenShareEnabled = useParticipantSharedScreen();

    const onToggleSound = () => {
        toggleSelfAudio();
        mediaSession.isMuted() ? mediaSession.unmuteMic() : mediaSession.muteMic();
    };

    const onToggleVideo = () => {
        const videoTrack = localStream.getVideoTracks()[0];
        toggleSelfVideo();

        isVideoPaused ? getLocalStream() : mediaSession.disableVideo(videoTrack);
    };

    const onShareScreen = () => {
        toggleScreenShareVideo();

        isScreenShared ? disableScreenShare() : getScreenShareStream();
    };

    const buttons = [
        {
            type: BUTTON_TYPES.sound,
            onClick: onToggleSound
        },
        {
            type: BUTTON_TYPES.video,
            onClick: onToggleVideo
        },
        {
            type: BUTTON_TYPES.shareScreen,
            onClick: onShareScreen,
            disabled: isParticipantScreenShareEnabled
        },
        {
            type: BUTTON_TYPES.stopCall,
            btnClassName: classes.buttonEndCall,
            onClick: withCallEndIcon ? onStopCall : false
        }
    ];

    const renderBtnIcon = (type) => {
        let Icon;
        switch (type) {
            case BUTTON_TYPES.shareScreen: {
                Icon = !isScreenShared ? ScreenShareIcon : StopScreenShareIcon;
                break;
            }
            case BUTTON_TYPES.sound: {
                Icon = !isMuted ? MicIcon : MicOffIcon;
                break;
            }
            case BUTTON_TYPES.video: {
                Icon = !isVideoPaused ? VideocamIcon : VideocamOffIcon;
                break;
            }
            case BUTTON_TYPES.stopCall:
            default: {
                Icon = CallEndIcon;
                break;
            }
        }
        return <Icon className={classes.button} />;
    };

    return (
        <div className={classNames(classes.buttons, 'video-conference-buttons')}>
            {buttons.map((item) => {
                if (item.type === BUTTON_TYPES.stopCall && !withCallEndIcon) return null;

                return (
                    <button
                        key={item.type}
                        className={classNames(classes.button, {
                            [item.btnClassName]: item.btnClassName,
                            [classes.buttonDisabled]: item.disabled
                        })}
                        onClick={item.onClick}
                        disabled={item.disabled}
                    >
                        {renderBtnIcon(item.type)}
                    </button>
                );
            })}
        </div>
    );
};

VideoPlayerButtons.propTypes = {
    withCallEndIcon: PropTypes.bool,
    onStopCall: PropTypes.func,
    mediaSession: PropTypes.object.isRequired,
    localStream: PropTypes.object.isRequired,
    shareScreenCapture: PropTypes.object,
    isScreenShared: PropTypes.bool,
    isMuted: PropTypes.bool,
    isVideoPaused: PropTypes.bool,
    getLocalStream: PropTypes.func,
    getScreenShareStream: PropTypes.func,
    toggleSelfVideo: PropTypes.func,
    toggleSelfAudio: PropTypes.func,
    toggleScreenShareVideo: PropTypes.func,
    disableScreenShare: PropTypes.func
};

VideoPlayerButtons.defaultProps = {
    withCallEndIcon: true
};
