import * as actionTypes from '../actionTypes/socialsPromotionTypes';
import {Api} from '../../Api';

export const getSocialsPromotion = (eventId) => async (dispatch) => {
    try {
        const {socialMedia} = await Api.request({
            url: `/event/${eventId}/social-media`,
            method: 'get',
        });

        dispatch({
            type: actionTypes.SOCIALS_PROMOTION_REQUEST_SUCCESS,
            payload: socialMedia,
        });
    } catch (error) {
        dispatch({
            type: actionTypes.SOCIALS_PROMOTION_REQUEST_ERROR,
            payload: error,
        });
    }
};

export const putSocialsPromotion = (eventId, data) => async (dispatch) => {
    try {
        const {socialMedia} = await Api.request({
            url: `/event/${eventId}/social-media`,
            method: 'put',
            payload: data,
            headers: {
                'content-type': 'multipart/form-data',
            },
        });

        dispatch({
            type: actionTypes.SOCIALS_PROMOTION_UPDATE_SUCCESS,
            payload: socialMedia,
        });
    } catch (error) {
        dispatch({
            type: actionTypes.SOCIALS_PROMOTION_UPDATE_ERROR,
            payload: error,
        });
    }
};
