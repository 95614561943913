import ReactGA from 'react-ga';
import {envList, env, googleAnalyticsId} from 'config';
import {IS_EGYPT_PAVILION_ENV} from 'constants/shared';

/**
 * @param {boolean} cookiesAccepted
 */
export const reactGAEgyptRegistationTrackingEvent = (cookiesAccepted) => {
    if (!IS_EGYPT_PAVILION_ENV || env !== envList.production || !cookiesAccepted) return;
    ReactGA.initialize(googleAnalyticsId);
    ReactGA.event({
        category: 'Registration',
        action: 'User click on register',
        label: 'User click on register to event',
    });
};
