import {Api} from 'Api';
import {createClient} from 'services/helpers';
import * as actionTypes from '../actionTypes/organizerActionTypes/sessions';
import {MEDIA_DEVICES_KINDS, ETX_SESSION_KICKED_USER_ERROR_MESSAGE, MEDIA_TYPES} from 'constants/organizer/sessions';

export const getSessions = (eventId) => async (dispatch, getState) => {
    const {
        sessions: {
            sessionsList: {isLoading}
        }
    } = getState();

    dispatch({type: actionTypes.GET_SESSIONS_START});

    try {
        const {sessions} = await Api.request({
            url: `/sessions/events/${eventId}`,
            method: 'get',
            loading: isLoading
        });

        dispatch({
            type: actionTypes.GET_SESSIONS_SUCCESS,
            sessions
        });
    } catch (error) {
        dispatch({type: actionTypes.GET_SESSIONS_FAIL});
    }
};

export const createSession = (data, callback) => async (dispatch, getState) => {
    const {
        sessions: {
            sessionsList: {isLoading}
        }
    } = getState();

    dispatch({type: actionTypes.CREATE_SESSIONS_START});
    const formData = new FormData();

    formData.append('name', data.name);
    formData.append('event', data.event);
    formData.append('type', data.type);

    if (data.file) {
        formData.append('link', data.file, data.fileName, data.file.type);
    }

    try {
        await Api.request({
            url: `event/${data.event}/sessions`,
            method: 'post',
            payload: formData,
            loading: isLoading,
            headers: {'Content-Type': 'multipart/form-data'}
        });

        dispatch({type: actionTypes.CREATE_SESSIONS_SUCCESS});
        callback && callback();
    } catch (error) {
        dispatch({type: actionTypes.CREATE_SESSIONS_FAIL});
    }
};

export const updateSession = (sessionId, data, callback) => async (dispatch, getState) => {
    const {
        sessions: {
            sessionsList: {isLoading}
        }
    } = getState();

    dispatch({type: actionTypes.UPDATE_SESSIONS_START});
    const formData = new FormData();

    formData.append('name', data.name);
    formData.append('event', data.event);
    formData.append('type', data.type);

    if (data.file) {
        formData.append('link', data.file, data.fileName, data.file.type);
    }

    try {
        await Api.request({
            url: `event/${data.event}/sessions/${sessionId}`,
            method: 'put',
            payload: formData,
            loading: isLoading,
            headers: {'Content-Type': 'multipart/form-data'}
        });

        dispatch({type: actionTypes.UPDATE_SESSIONS_SUCCESS});
        callback && callback();
    } catch (error) {
        dispatch({type: actionTypes.UPDATE_SESSIONS_FAIL});
    }
};

export const deleteSession = (eventId, sessionId) => async (dispatch, getState) => {
    const {
        sessions: {
            sessionsList: {isLoading}
        }
    } = getState();

    dispatch({type: actionTypes.DELETE_SESSIONS_START});

    try {
        await Api.request({
            url: `event/${eventId}/sessions/${sessionId}`,
            method: 'delete',
            loading: isLoading
        });

        dispatch({type: actionTypes.DELETE_SESSIONS_SUCCESS});
    } catch (error) {
        dispatch({type: actionTypes.DELETE_SESSIONS_FAIL});
    }
};

export const getSessionData =
    ({sessionId, callback}) =>
    async (dispatch, getState) => {
        const {
            etxSessionDashboard: {isLoading}
        } = getState();

        dispatch(getSessionDataStart());

        try {
            const userToken = localStorage.sessionToken;
            const headers = {};
            if (userToken) headers.common['Authorization'] = `Bearer ${userToken}`;

            const {session} = await Api.request({
                url: `/sessions/${sessionId}`,
                method: 'get',
                loading: isLoading,
                headers
            });

            dispatch(getSessionDataSuccess({session}));

            if (callback) callback(session);
        } catch (error) {
            dispatch(getSessionDataFail());
        }
    };

const getSessionDataStart = () => ({type: actionTypes.GET_SESSION_DATA_START});
export const getSessionDataSuccess = (payload) => ({type: actionTypes.GET_SESSION_DATA_SUCCESS, payload});
const getSessionDataFail = () => ({type: actionTypes.GET_SESSION_DATA_FAIL});

export const getSessionRecordings = (sessionId) => async (dispatch) => {
    try {
        const {recordings} = await Api.request({
            method: 'get',
            url: `/sessions/${sessionId}/records`
        });

        return recordings;
    } catch (error) {}
};

export const getChatMessages =
    ({auditoriumId, timeslotId}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},

            etxSessionDashboard: {isLoading}
        } = getState();

        dispatch(getChatMessagesRequest());

        try {
            const userToken = localStorage.sessionToken;
            const headers = {};
            if (userToken) headers.common['Authorization'] = `Bearer ${userToken}`;

            const {chat} = await Api.request({
                url: `/event/${eventId}/auditorium/${auditoriumId}/timeslot/${timeslotId}/chat`,
                method: 'get',
                loading: isLoading,
                headers
            });

            dispatch(getChatMessagesSuccess(chat));
        } catch (error) {
            dispatch(getChatMessagesFail());
        }
    };

const getChatMessagesRequest = () => ({type: actionTypes.GET_CHAT_MESSAGES_REQUEST});
const getChatMessagesSuccess = (payload) => ({type: actionTypes.GET_CHAT_MESSAGES_SUCCESS, payload});
const getChatMessagesFail = () => ({type: actionTypes.GET_CHAT_MESSAGES_FAIL});

export const sendChatMessage =
    ({auditoriumId, timeslotId, text}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},

            etxSessionDashboard: {isLoading}
        } = getState();

        dispatch({type: actionTypes.SEND_CHAT_MESSAGE_START});

        try {
            const userToken = localStorage.sessionToken;
            const headers = {};
            if (userToken) headers.common['Authorization'] = `Bearer ${userToken}`;

            const {message} = await Api.request({
                url: `/event/${eventId}/auditorium/${auditoriumId}/timeslot/${timeslotId}/chat`,
                method: 'post',
                payload: {text},
                loading: isLoading,
                headers
            });

            dispatch({type: actionTypes.SEND_CHAT_MESSAGE_SUCCESS, payload: message});
        } catch (error) {
            dispatch({type: actionTypes.SEND_CHAT_MESSAGE_FAIL});
        }
    };

export const receiveChatMessage = (payload) => ({
    type: actionTypes.RECIEVE_CHAT_MESSAGE,
    payload
});

export const toggleSessionFullScreen = () => ({type: actionTypes.VIDEO_CONFERENCE_FULL_SCREEN_TOGGLED});

export const clearCallState = (isReconnect) => async (dispatch, getState) => {
    const {
        etxSessionDashboard: {room, client, capture, constrains}
    } = getState();

    try {
        if (!isReconnect) {
            room && (await room.leave());
            client && client.close();
        }

        capture.getTracks().forEach((track) => track.stop());
    } catch (error) {}

    dispatch({
        type: actionTypes.CLEAR_CALL_STATE,
        payload: isReconnect
    });

    isReconnect && dispatch(getLocalStream(constrains, isReconnect));
};

export const setClient = (client) => ({
    type: actionTypes.SET_CLIENT,
    payload: {client}
});

export const setRoom = (room) => ({
    type: actionTypes.SET_ROOM,
    payload: {room}
});

export const setMediaSession = (mediaSession) => (dispatch, getState) => {
    const {
        user: {
            data: {first, last, _id}
        }
    } = getState();

    const currentUser = {
        displayName: `${first} ${last}`,
        role: mediaSession.role,
        userId: _id
    };

    return dispatch({
        type: actionTypes.SET_MEDIA_SESSION,
        payload: {mediaSession, currentUser}
    });
};

export const addConsumer = (consumer, userId) => ({
    type: actionTypes.ADD_CONSUMER,
    payload: {consumer, userId}
});

export const closeConsumer = ({userId, consumerId}) => ({
    type: actionTypes.CLOSE_CONSUMER,
    payload: {userId, consumerId}
});

export const pauseConsumer = ({userId, consumerId}) => ({
    type: actionTypes.PAUSE_CONSUMER,
    payload: {userId, consumerId}
});

export const resumeConsumer = ({userId, consumerId}) => ({
    type: actionTypes.RESUME_CONSUMER,
    payload: {userId, consumerId}
});

export const removeSession = (session) => ({
    type: actionTypes.REMOVE_SESSION,
    payload: {session}
});

export const addSession = (session) => ({
    type: actionTypes.ADD_SESSION,
    payload: {session}
});

export const getLocalStream = (constrains, isReconnect) => async (dispatch, getState) => {
    const {
        etxSessionDashboard: {constrains: initialConstrains, mediaSession, isReady, isAudioPaused, isVideoPaused},
        timeslot: {etxSessionToken, data}
    } = getState();

    try {
        dispatch(getLocalStreamRequest());

        console.info('Getting access to user media devices');

        const mediaDevices = await navigator.mediaDevices.enumerateDevices();

        const mediaInputSources = mediaDevices.filter(
            (device) => device.kind === MEDIA_DEVICES_KINDS.audioinput || device.kind === MEDIA_DEVICES_KINDS.videoinput
        );

        const getConstraints = {...initialConstrains, ...constrains};

        dispatch(setMediaDevicesList({mediaInputSources, constrains: getConstraints}));

        const localStream = await navigator.mediaDevices.getUserMedia(getConstraints);

        if (isReconnect) {
            localStream.getTracks().forEach((track) => {
                if (track.kind === MEDIA_TYPES.audio) {
                    track.enabled = !isAudioPaused;
                } else {
                    track.enabled = !isVideoPaused;
                }
            });
        }

        dispatch(getLocalStreamSuccess(localStream));

        isReconnect && dispatch(createRoom({token: etxSessionToken, timeslotId: data?._id}));

        if (isReady && mediaSession) {
            const newVideoTrack = localStream.getVideoTracks()[0];

            await mediaSession.enableVideo(newVideoTrack);
        }
    } catch (err) {
        console.error('Error opening video camera.', err);
        dispatch(getLocalStreamFail());
    }
};

const getLocalStreamRequest = () => ({type: actionTypes.GET_LOCAL_STREAM_REQUEST});
const getLocalStreamSuccess = (payload) => ({type: actionTypes.GET_LOCAL_STREAM_SUCCESS, payload});
const getLocalStreamFail = () => ({type: actionTypes.GET_LOCAL_STREAM_FAIL});

export const getScreenShareStream = () => async (dispatch, getState) => {
    const {
        etxSessionDashboard: {mediaSession}
    } = getState();

    try {
        dispatch(getScreenShareStreamRequest());

        console.info('Getting access to screen media');

        const screenShareStream = await navigator.mediaDevices.getDisplayMedia({
            audio: false,
            video: {
                displaySurface: 'monitor',
                logicalSurface: true,
                resizeMode: 'crop-and-scale',
                cursor: true,
                width: {max: 3840},
                height: {max: 2160},
                frameRate: 30
            }
        });

        screenShareStream.oninactive = () => dispatch(toggleScreenShareVideo(false));

        dispatch(getScreenShareStreamSuccess(screenShareStream));

        const newVideoTrack = screenShareStream.getVideoTracks()[0];

        await mediaSession.enableShare(newVideoTrack);
    } catch (err) {
        console.error('Error getting screen capture.', err);
        dispatch(toggleScreenShareVideo(false));
        dispatch(getScreenShareStreamFail());
    }
};

export const disableScreenShare = () => async (dispatch, getState) => {
    const {
        etxSessionDashboard: {mediaSession, shareScreenCapture}
    } = getState();

    try {
        const videoTrack = shareScreenCapture.getVideoTracks()[0];

        await mediaSession.disableShare(videoTrack);

        shareScreenCapture.getVideoTracks().forEach((track) => track.stop());
    } catch (error) {}
};

const getScreenShareStreamRequest = () => ({type: actionTypes.GET_SCREEN_SHARE_STREAM_REQUEST});
const getScreenShareStreamSuccess = (payload) => ({type: actionTypes.GET_SCREEN_SHARE_STREAM_SUCCESS, payload});
const getScreenShareStreamFail = () => ({type: actionTypes.GET_SCREEN_SHARE_STREAM_FAIL});

const setMediaDevicesList = (payload) => ({type: actionTypes.SET_MEDIA_DEVICES_LIST, payload});

export const createRoom =
    ({token, timeslotId}) =>
    async (dispatch, getState) => {
        const {
            etxSessionDashboard: {isKicked},
            timeslot: {
                data: {gatewayName, sessionKey}
            }
        } = getState();

        if (isKicked) return;

        try {
            dispatch(createRoomRequest());

            const client = await createClient({authToken: token, timeslotId, gatewayName, sessionKey});

            await client.authenticate();

            const room = await client.getRoom();

            dispatch(createRoomSuccess({client, room}));
        } catch (error) {
            const isUserKicked = error?.data === ETX_SESSION_KICKED_USER_ERROR_MESSAGE;
            dispatch(createRoomFail(isUserKicked));
        }
    };
const createRoomRequest = () => ({type: actionTypes.CREATE_ROOM_REQUEST});
const createRoomSuccess = (payload) => ({type: actionTypes.CREATE_ROOM_SUCCESS, payload});
export const createRoomFail = (payload) => ({type: actionTypes.CREATE_ROOM_FAIL, payload});

export const joinRoom = (sessions) => ({
    type: actionTypes.JOIN_ROOM,
    payload: {sessions}
});

export const joinSessionDashboard = () => ({type: actionTypes.JOIN_SESSION_DASHBOARD});

export const toggleSelfVideo = (payload) => ({type: actionTypes.TOGGLE_SELF_VIDEO, payload});

export const toggleSelfAudio = (payload) => ({type: actionTypes.TOGGLE_SELF_AUDIO, payload});

export const toggleScreenShareVideo = (payload) => ({type: actionTypes.TOGGLE_SCREEN_SHARE, payload});

export const clearSessionState = () => ({type: actionTypes.CLEAR_SESSION_STATE});

export const setDisconnectedState = (payload) => ({type: actionTypes.SET_DISCONNECTED_STATE, payload});

export const newDominantSpeakerOrder = (payload) => ({type: actionTypes.NEW_DOMINANT_SPEAKER_ORDER, payload});
