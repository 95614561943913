import axios from 'axios';
import {NotificationManager} from 'react-notifications';
import {apiUrl} from '../config';
import store from '../store/store';
import * as actions from '../store/actions';

class Api {
    constructor(baseURL) {
        this.adapter = axios.create({
            baseURL,
        });
    }

    request = async ({
        url,
        method = 'get',
        payload,
        loading,
        headers = {},
        errorObj = {show: false, message: ''},
        params,
        responseType = 'json',
        signal,
    }) => {
        try {
            const accessToken = localStorage.getItem('token');
            if (loading === undefined) store.dispatch(actions.setLoaderOn());

            const request = {
                url,
                method,
                data: payload,
                params,
                headers: {
                    common: {},
                    ...headers,
                },
                responseType,
                signal,
            };

            if (accessToken) request.headers.common.Authorization = `Bearer ${accessToken}`;

            const response = await this.adapter.request(request).catch(({response}) => {
                this.handleError(response, request, errorObj);
                throw response;
            });

            if (store.getState().loader > 0) store.dispatch(actions.setLoaderOff());
            return Promise.resolve(response.data);
        } catch (err) {
            return Promise.reject(err);
        }
    };

    setAccessToken = (token) => {
        localStorage.setItem('token', token, 365);
    };

    setRefreshToken = (token) => {
        // set expiration date to 30 days
        let date = new Date();
        var minutes = 60;
        date.setTime(date.getTime() + minutes * 24 * (59400 * 30));

        localStorage.setItem('token', token, {expires: date});
    };

    handleError = async (err, request, customError) => {
        if (store.getState().loader > 0) store.dispatch(actions.setLoaderOff());

        if (err?.statusText === 'Network Error' || err?.status === 502) {
            store.dispatch(actions.serverDown());
        } else if (err && err.status === 401) {
            store.dispatch(actions.sessionExpired());
            setTimeout(() => {
                store.dispatch(actions.logoutUser());
                localStorage.removeItem('token');
            }, 5000);
        }

        if (err?.status === 500 || err?.status === 403) {
            NotificationManager.error(
                typeof err?.data?.error === 'string' ? err?.data?.error : 'Server Error',
                'Error',
                5000,
                null,
                true
            );
        } else if (err?.status === 422) {
            NotificationManager.error(
                typeof err?.data?.error === 'string' ? err?.data?.error : 'User already exists',
                'Server Error',
                5000,
                null,
                true
            );
        } else if (customError?.show) {
            const serverErrorMessage = typeof err?.data?.error === 'string' ? err?.data?.error : 'Server Error';
            const errorMessage = customError.message ? customError.message : serverErrorMessage;

            NotificationManager.error(errorMessage, 'Error', 5000, null, true);
        }
    };
}

export default new Api(apiUrl);
