import React from 'react';
import cn from 'classnames';

import Button from '../../../../../../../../../../../../../../../Button/Button';
import {
    IconCross,
    IconCheck,
    IconAngleArrowRight,
    IconCircledMinus,
} from '../../../../../../../../../../../../../../../Icons';

export const ConfirmedActionGroup = ({onCancel, onMeetingStart}) => (
    <>
        <div className="DesktopAgendaMeetingItem__actionItem">
            <Button icon={<IconCross className="primary-fill-svg" />} onClick={onCancel} />
        </div>

        <div className="DesktopAgendaMeetingItem__actionItem">
            <Button icon={<IconAngleArrowRight className="primary-fill-svg" />} onClick={onMeetingStart} />
        </div>
    </>
);

export const PendingActionGroup = ({isRequested, onCancel, onConfirm}) => (
    <>
        <div className="DesktopAgendaMeetingItem__actionItem">
            {isRequested ? (
                <Button icon={<IconCircledMinus className="primary-fill-svg" />} onClick={onCancel} />
            ) : (
                <Button icon={<IconCross className="primary-fill-svg" />} onClick={onCancel} />
            )}
        </div>

        <div
            className={cn('DesktopAgendaMeetingItem__actionItem', {
                DesktopAgendaMeetingItem__actionItem_hidden: !isRequested,
            })}
        >
            <Button icon={<IconCheck className="primary-fill-svg" />} onClick={onConfirm} />
        </div>
    </>
);
