import * as actionTypes from './actionTypes';
import {Api} from 'Api';

export const getExhibitor = (exhibitorId) => async (dispatch, getState) => {
    const {
        exhibitor: {loading},
        event: {eventId},
    } = getState();

    dispatch(getExhibitorStart());

    try {
        const {exhibitor} = await Api.request({
            url: `/event/${eventId}/exhibitor/${exhibitorId}`,
            method: 'get',
            loading,
        });

        dispatch(getExhibitorSuccess(exhibitor));
    } catch (error) {
        dispatch(getExhibitorFail());
    }
};

export const getExhibitorStart = () => {
    return {
        type: actionTypes.GET_EXHIBITOR_START,
    };
};
export const getExhibitorSuccess = (exhibitor) => {
    return {
        type: actionTypes.GET_EXHIBITOR_SUCCESS,
        payload: exhibitor,
    };
};
export const getExhibitorFail = () => {
    return {
        type: actionTypes.GET_EXHIBITOR_FAIL,
    };
};
export const clearExhibitorData = () => ({
    type: actionTypes.CLEAR_EXHIBITOR_DATA,
});

export const sendExhibitorStatistic =
    ({exhibitorId, payload, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        try {
            await Api.request({
                method: 'post',
                url: `/event/${eventId}/exhibitor/${exhibitorId}/booth-statistics`,
                payload,
            });

            callback && callback();
        } catch (error) {}
    };

export const getBoothStatistics =
    ({callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
            exhibitor: {exhibitorId},
        } = getState();

        try {
            const {boothStatistics} = await Api.request({
                method: 'get',
                url: `/event/${eventId}/exhibitor/${exhibitorId}/booth-statistics`,
            });

            callback && callback(boothStatistics);
        } catch (error) {}
    };

export const updateBoothLogo =
    ({exhibitorId, method, payload, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        try {
            const requestData = {
                method,
                url: `/event/${eventId}/exhibitor/${exhibitorId}/booth/logo`,
            };

            if (payload) {
                requestData.payload = payload;
                requestData.headers = {
                    'content-type': 'multipart/form-data',
                };
            }

            await Api.request(requestData);

            dispatch(getExhibitor(exhibitorId));
            callback && callback();
        } catch (error) {}
    };

export const updateBoothBanner =
    ({exhibitorId, method, payload, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        try {
            const requestData = {
                method,
                url: `/event/${eventId}/exhibitor/${exhibitorId}/booth/banner`,
            };

            if (payload) {
                requestData.payload = payload;
                requestData.headers = {
                    'content-type': 'multipart/form-data',
                };
            }

            await Api.request(requestData);

            dispatch(getExhibitor(exhibitorId));
            callback && callback();
        } catch (error) {}
    };

export const updateBoothDocument =
    ({exhibitorId, method, payload, documentId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        try {
            const url = documentId
                ? `/event/${eventId}/exhibitor/${exhibitorId}/booth/file/${documentId}`
                : `/event/${eventId}/exhibitor/${exhibitorId}/booth/file`;

            const requestData = {
                method,
                url,
                loading: true,
            };

            if (payload) {
                requestData.payload = payload;
                requestData.headers = {
                    'content-type': 'multipart/form-data',
                };
            }

            await Api.request(requestData);

            dispatch(getExhibitor(exhibitorId));
            callback && callback();
        } catch (error) {}
    };

export const addBoothLink =
    ({exhibitorId, payload, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        try {
            await Api.request({
                method: 'post',
                url: `/event/${eventId}/exhibitor/${exhibitorId}/booth/links`,
                payload,
                loading: true,
            });

            dispatch(getExhibitor(exhibitorId));
            callback && callback();
        } catch (error) {}
    };

export const removeBoothLink =
    ({exhibitorId, linkId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        try {
            await Api.request({
                method: 'delete',
                url: `/event/${eventId}/exhibitor/${exhibitorId}/booth/links/${linkId}`,
                loading: true,
            });

            dispatch(getExhibitor(exhibitorId));
            callback && callback();
        } catch (error) {}
    };

export const setSocialMediaLinks =
    ({exhibitorId, payload, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        try {
            await Api.request({
                method: 'put',
                url: `/event/${eventId}/exhibitor/${exhibitorId}/booth-social-media-links`,
                payload,
                loading: true,
            });

            dispatch(getExhibitor(exhibitorId));
            callback && callback();
        } catch (error) {}
    };

export const removeDeskRepresentative = (positionType) => async (dispatch, getState) => {
    const {
        event: {eventId},
        exhibitor: {exhibitorId},
    } = getState();

    try {
        await Api.request({
            method: 'delete',
            url: `/event/${eventId}/exhibitor/${exhibitorId}/booth/${positionType}`,
        });

        dispatch(getExhibitor(exhibitorId));
    } catch (error) {}
};

export const removeExhibitorMedalionRepresentative = (userId) => async (dispatch, getState) => {
    const {
        event: {eventId},
        exhibitor: {exhibitorId},
    } = getState();

    try {
        await Api.request({
            method: 'delete',
            url: `/event/${eventId}/exhibitor/${exhibitorId}/remove-representative`,
            payload: {userId},
        });

        dispatch(getExhibitor(exhibitorId));
    } catch (error) {}
};

export const saveBoothRepresentative =
    ({position, payload, headers, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
            exhibitor: {exhibitorId},
        } = getState();

        try {
            const requestData = {
                method: 'post',
                url: `/event/${eventId}/exhibitor/${exhibitorId}/booth/${position}`,
                payload,
            };

            if (headers) {
                requestData.headers = {
                    'content-type': 'multipart/form-data',
                };
            }

            await Api.request(requestData);

            dispatch(getExhibitor(exhibitorId));

            callback && callback();
        } catch (error) {}
    };

export const addMedalionEventRepresentative =
    ({userId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
            exhibitor: {exhibitorId},
        } = getState();

        try {
            await Api.request({
                method: 'post',
                url: `/event/${eventId}/exhibitor/${exhibitorId}/add-representative`,
                payload: {userId},
            });

            dispatch(getExhibitor(exhibitorId));

            callback && callback();
        } catch (error) {}
    };

export const updateBooth =
    ({payload, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
            exhibitor: {exhibitorId},
        } = getState();

        try {
            await Api.request({
                method: 'put',
                url: `/event/${eventId}/exhibitor/${exhibitorId}/booth`,
                payload,
            });

            dispatch(getExhibitor(exhibitorId));

            callback && callback();
        } catch (error) {}
    };

export const updateBoothVideoImage =
    ({payload, method, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
            exhibitor: {exhibitorId},
        } = getState();

        try {
            const requestData = {
                method,
                url: `/event/${eventId}/exhibitor/${exhibitorId}/booth/video-image`,
            };

            if (payload) {
                requestData.payload = payload;
                requestData.headers = {
                    'content-type': 'multipart/form-data',
                };
            }

            await Api.request(requestData);

            dispatch(getExhibitor(exhibitorId));

            callback && callback();
        } catch (error) {}
    };

// JobApplicants
export const requestJobApplicantsRatingChanging =
    ({applicationId, rating}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
            exhibitor: {exhibitorId},
        } = getState();

        await Api.request({
            method: 'post',
            url: `/event/${eventId}/exhibitor/${exhibitorId}/job-applications/${applicationId}/rating`,
            payload: {rating},
        });
    };

export const requestJobApplicantsStatusChanging =
    ({applicationId, status}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
            exhibitor: {exhibitorId},
        } = getState();

        await Api.request({
            method: 'post',
            url: `/event/${eventId}/exhibitor/${exhibitorId}/job-applications/${applicationId}/status`,
            payload: {status},
        });
    };
