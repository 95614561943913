import SessionsList from './SessionsList';
import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export default connect(
    (state) => ({
        event: selectors.getEventData(state),
        sessionList: selectors.getSessionList(state),
        sessionListPagination: selectors.getSessionListPagination(state),
        sessionListError: selectors.getSessionListError(state),
        slug: selectors.getEventSlug(state),
        eventTimeZone: selectors.getEventTimeZone(state),
        isRtlLanguage: selectors.getIsRtlLanguage(state)
    }),
    {
        onSetEventProtectedMenu: actions.setEventProtectedMenu,
        getSessionsListByRole: actions.getSessionsListByRole,
        changeSessionListPage: actions.changeSessionListPage,
        clearSessionList: actions.clearSessionList,
        getBuilding: actions.getBuilding
    }
)(SessionsList);
