import React, {useEffect, useContext} from 'react';
import {connect} from 'react-redux';

import * as actions from '../../../store/actions';
import {Api} from '../../../Api';
import {getPrivateChatId} from '../../../services/utils';
import {AbilityContext} from '../../../permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from '../../../constants/ability';

const OpenChatWithOrganizerRepresentative = ({
    eventId,
    eventRoles,
    eventProfile,
    user,
    event,
    onOpenPrivateChat,
    getPrivateChatsAndOpenPrivateChat,
    getEventProfile,
}) => {
    const ability = useContext(AbilityContext);

    useEffect(() => {
        const isNotOrganizer = ability.can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.EVENT_PROFILE);

        if (!eventProfile && isNotOrganizer) getEventProfile();
    }, [ability, eventProfile, eventRoles, getEventProfile]);

    const handleOpenPrivateChatWithRepresentative = async () => {
        let userData = null;
        if (eventRoles.isOrganizer) {
            userData = {_id: user._id};
        } else userData = eventProfile;
        let userPrivateChats = user.privateChats;

        // we check if we have allready talked with an organizer representative
        let representativeChatId = null;

        event.representatives.forEach((representative) => {
            let privateChatId = getPrivateChatId(userPrivateChats, representative.user._id);
            if (privateChatId) {
                representativeChatId = privateChatId;
            }
        });

        // if we allready talked with one, we open the same private chat with him
        if (representativeChatId) {
            onOpenPrivateChat(representativeChatId);
        } else if (userData) {
            // if we didn't talk with a representative, we make an API call to the server
            // to alocate us a random representative

            let data;

            if (eventRoles.isExhibitor) {
                data = {exhibitorId: userData._id};
            } else if (eventRoles.isOrganizer) {
                data = {organizerId: userData._id};
            } else {
                data = {participantId: userData._id};
            }

            try {
                const {privateChatId: newOrganizerChatId} = await Api.request({
                    method: 'post',
                    url: `/event/${eventId}/chat-with-organizer-representative`,
                    payload: data,
                    loading: true,
                });

                getPrivateChatsAndOpenPrivateChat(newOrganizerChatId);
            } catch (error) {}
        }
    };

    return <div onClick={handleOpenPrivateChatWithRepresentative} className="w-100 h-100" />;
};

export default connect(
    (state) => ({
        eventId: state.event.eventId,
        user: state.user.data,
        eventRoles: state.user.eventRoles,
        event: state.event.data,
        exhibitors: state.exhibitors,
        eventProfile: state.event.profile,
    }),
    {
        getPrivateChatsAndOpenPrivateChat: actions.getPrivateChats,
        onOpenPrivateChat: actions.openPrivateChat,
        getEventProfile: actions.getEventProfile,
    }
)(OpenChatWithOrganizerRepresentative);
