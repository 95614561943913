import {makeStyles} from '@material-ui/core/styles';
import {calculateGridSize} from 'services/helpers';

export const useStyles = makeStyles((theme) => ({
    videoPaused: {
        visibility: 'hidden'
    },
    videoWrapper: {
        display: 'flex',
        maxHeight: '100%',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        width: '100%'
    },
    videoWrapperHidden: {
        height: 0
    },
    isActiveSpeaker: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        border: '4px solid yellow',
        zIndex: 11
    },
    videoWrapperSize: ({activeStreamsCount}) => calculateGridSize(activeStreamsCount),
    enlarged: {
        width: '100%',
        height: '100%'
    },
    video: {
        width: '100%',
        objectFit: 'cover'
    },
    videoEnlarged: {
        objectFit: 'contain'
    },
    videoOff: {
        border: '1px solid #fff'
    },
    videoOffPlate: {
        position: 'absolute',
        display: 'flex',
        zIndex: 100,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        backgroundColor: '#000'
    },
    videoHidden: {
        display: 'none'
    },
    audioStream: {
        display: 'none',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 1
    },
    userInfo: {
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            marginRight: 5
            // color: theme.palette.primary.main
        }
    },
    userName: {
        color: theme.palette.grey[0],
        marginLeft: 10,
        fontSize: 18,
        fonWeight: 500
    }
}));
