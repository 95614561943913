export const GET_TIMESLOT_DATA_REQUEST = 'TIMESLOT/GET_TIMESLOT_DATA_REQUEST';
export const GET_TIMESLOT_DATA_SUCCESS = 'TIMESLOT/GET_TIMESLOT_DATA_SUCCESS';
export const GET_TIMESLOT_DATA_FAIL = 'TIMESLOT/GET_TIMESLOT_DATA_FAIL';

export const RESET_TIMESLOT_DATA = 'TIMESLOT/RESET_TIMESLOT_DATA';

export const SESSION_STREAM_START_REQUEST = 'TIMESLOT/SESSION_STREAM_START_REQUEST';
export const SESSION_STREAM_START_SUCCESS = 'TIMESLOT/SESSION_STREAM_START_SUCCESS';
export const SESSION_STREAM_START_FAIL = 'TIMESLOT/SESSION_STREAM_START_FAIL';

export const SESSION_STREAM_STOP_REQUEST = 'TIMESLOT/SESSION_STREAM_STOP_REQUEST';
export const SESSION_STREAM_STOP_SUCCESS = 'TIMESLOT/SESSION_STREAM_STOP_SUCCESS';
export const SESSION_STREAM_STOP_FAIL = 'TIMESLOT/SESSION_STREAM_STOP_FAIL';

export const SESSION_STREAM_ALLOCATE_RESOURCES_REQUEST = 'TIMESLOT/SESSION_STREAM_ALLOCATE_RESOURCES_REQUEST';
export const SESSION_STREAM_ALLOCATE_RESOURCES_SUCCESS = 'TIMESLOT/SESSION_STREAM_ALLOCATE_RESOURCES_SUCCESS';
export const SESSION_STREAM_ALLOCATE_RESOURCES_FAIL = 'TIMESLOT/SESSION_STREAM_ALLOCATE_RESOURCES_FAIL';

export const GET_ZOOM_STREAM_RECORDINGS_REQUEST = 'TIMESLOT/GET_ZOOM_STREAM_RECORDINGS_REQUEST';
export const GET_ZOOM_STREAM_RECORDINGS_SUCCESS = 'TIMESLOT/GET_ZOOM_STREAM_RECORDINGS_SUCCESS';
export const GET_ZOOM_STREAM_RECORDINGS_FAIL = 'TIMESLOT/GET_ZOOM_STREAM_RECORDINGS_FAIL';

export const UPDATE_TIMESLOT_DATA = 'TIMESLOT/UPDATE_TIMESLOT_DATA';

export const DELETE_TIMESLOT_REQUEST = 'TIMESLOT/DELETE_TIMESLOT_REQUEST';
export const DELETE_TIMESLOT_SUCCESS = 'TIMESLOT/DELETE_TIMESLOT_SUCCESS';
export const DELETE_TIMESLOT_FAIL = 'TIMESLOT/DELETE_TIMESLOT_FAIL';

export const UPLOAD_SESSION_THUMBNAIL_IMAGE_REQUEST = 'TIMESLOT/UPLOAD_SESSION_THUMBNAIL_IMAGE_REQUEST';
export const UPLOAD_SESSION_THUMBNAIL_IMAGE_SUCCESS = 'TIMESLOT/UPLOAD_SESSION_THUMBNAIL_IMAGE_SUCCESS';
export const UPLOAD_SESSION_THUMBNAIL_IMAGE_FAIL = 'TIMESLOT/UPLOAD_SESSION_THUMBNAIL_IMAGE_FAIL';

export const SET_MEETING_LINK_REQUEST = 'TIMESLOT/SET_MEETING_LINK_REQUEST';
export const SET_MEETING_LINK_SUCCESS = 'TIMESLOT/SET_MEETING_LINK_SUCCESS';
export const SET_MEETING_LINK_FAIL = 'TIMESLOT/SET_MEETING_LINK_FAIL';

export const GET_AUTH_TOKEN_FOR_VIDEO_ENGINE_REQUEST = 'TIMESLOT/GET_AUTH_TOKEN_FOR_VIDEO_ENGINE_REQUEST';
export const GET_AUTH_TOKEN_FOR_VIDEO_ENGINE_SUCCESS = 'TIMESLOT/GET_AUTH_TOKEN_FOR_VIDEO_ENGINE_SUCCESS';
export const GET_AUTH_TOKEN_FOR_VIDEO_ENGINE_FAIL = 'TIMESLOT/GET_AUTH_TOKEN_FOR_VIDEO_ENGINE_FAIL';

export const SET_AVAILABLE_INTERPRETATION_LANGUAGE = 'TIMESLOT/SET_AVAILABLE_INTERPRETATION_LANGUAGE';
export const REMOVE_AVAILABLE_INTERPRETATION_LANGUAGE = 'TIMESLOT/REMOVE_AVAILABLE_INTERPRETATION_LANGUAGE';
export const SET_ACTIVE_INTERPRETATION_LANGUAGE = 'TIMESLOT/SET_ACTIVE_INTERPRETATION_LANGUAGE';

export const GET_EVENT_USERS_LIST_EXCLUDED_ROLES_START = 'TIMESLOT/GET_EVENT_USERS_LIST_EXCLUDED_ROLES_START';
export const GET_EVENT_USERS_LIST_EXCLUDED_ROLES_SUCCESS = 'TIMESLOT/GET_EVENT_USERS_LIST_EXCLUDED_ROLES_SUCCESS';
export const GET_EVENT_USERS_LIST_EXCLUDED_ROLES_FAIL = 'TIMESLOT/GET_EVENT_USERS_LIST_EXCLUDED_ROLES_FAIL';

export const GET_TIMESLOT_POLLS_REQUEST = 'TIMESLOT/GET_TIMESLOT_POLLS_REQUEST';
export const GET_TIMESLOT_POLLS_SUCCESS = 'TIMESLOT/GET_TIMESLOT_POLLS_SUCCESS';
export const GET_TIMESLOT_POLLS_FAIL = 'TIMESLOT/GET_TIMESLOT_POLLS_FAIL';

export const CREATE_EMPTY_POLL = 'AUDITORIUM_POLLS/CREATE_EMPTY_POLL';

export const LOAD_TIMESLOT_POLLS_START = 'AUDITORIUM_POLLS/LOAD_TIMESLOT_POLLS_START';
export const LOAD_TIMESLOT_POLLS_SUCCESS = 'AUDITORIUM_POLLS/LOAD_TIMESLOT_POLLS_SUCCESS';
export const LOAD_TIMESLOT_POLLS_FAIL = 'AUDITORIUM_POLLS/LOAD_TIMESLOT_POLLS_FAIL';

export const SAVE_POLL_START = 'AUDITORIUM_POLLS/SAVE_POLL_START';
export const SAVE_POLL_SUCCESS = 'AUDITORIUM_POLLS/SAVE_POLL_SUCCESS';
export const SAVE_POLL_FAIL = 'AUDITORIUM_POLLS/SAVE_POLL_FAIL';

export const DELETE_POLL_START = 'AUDITORIUM_POLLS/DELETE_POLL_START';
export const DELETE_POLL_SUCCESS = 'AUDITORIUM_POLLS/DELETE_POLL_SUCCESS';
export const DELETE_POLL_FAIL = 'AUDITORIUM_POLLS/DELETE_POLL_FAIL';

export const SET_TIMESLOT_POLLS = 'AUDITORIUM_POLLS/SET_TIMESLOT_POLLS';

export const CHANGE_POLL_TITLE = 'AUDITORIUM_POLLS/CHANGE_POLL_TITLE';
export const CHANGE_POLL_OPTION_TITLE = 'AUDITORIUM_POLLS/CHANGE_POLL_OPTION_TITLE';
export const ADD_NEW_POLL_OPTION = 'AUDITORIUM_POLLS/ADD_NEW_POLL_OPTION';
export const REMOVE_POLL_OPTION = 'AUDITORIUM_POLLS/REMOVE_POLL_OPTION';
export const SAVE_POLL = 'AUDITORIUM_POLLS/SAVE_POLL';
export const CHANGE_POLL_VISIBILITY = 'AUDITORIUM_POLLS/CHANGE_POLL_VISIBILITY';
export const CLOSE_POLL = 'AUDITORIUM_POLLS/CLOSE_POLL';
export const SET_POLL = 'AUDITORIUM_POLLS/SET_POLL';
export const TOGGLE_POLL_MULTISELECT = 'AUDITORIUM_POLLS/TOGGLE_POLL_MULTISELECT';
export const TOGGLE_POLL_RESULTS_VISIBILITY = 'AUDITORIUM_POLLS/TOGGLE_POLL_RESULTS_VISIBILITY';

export const SOCKET_POLL_CREATE = 'AUDITORIUM_POLLS/SOCKET_POLL_CREATE';
export const SOCKET_POLL_DELETE = 'AUDITORIUM_POLLS/SOCKET_POLL_DELETE';
export const SOCKET_POLL_UPDATE = 'AUDITORIUM_POLLS/SOCKET_POLL_UPDATE';
