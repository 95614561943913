import React from 'react';
import {TextValidator} from 'react-material-ui-form-validator';
import {Checkbox, FormGroup, FormControlLabel, FormControl, FormLabel} from '@material-ui/core';

import './CheckboxInputField.scss';

// TODO: check implementstion, maybe it needs refactoring for optimization
class CheckboxInputField extends React.Component {
    state = {
        selectedOptions: [],
    };

    componentDidMount() {
        this.setState({
            selectedOptions: this.props.field.value || [],
        });
    }

    handleChange = (e) => {
        const {field} = this.props;
        let updatedOptions = [...this.state.selectedOptions];

        if (e.target.checked) {
            updatedOptions.push({optionId: e.target.value, text: ''});
        } else {
            updatedOptions = updatedOptions.filter((option) => option.optionId !== e.target.value);
        }

        this.setState({
            selectedOptions: updatedOptions,
        });

        this.props.handleChange(field.name, updatedOptions);
    };

    handleChangeText = (e) => {
        const {field} = this.props;

        const updatedOptions = this.state.selectedOptions.map((option) => {
            return option.optionId === e.target.id ? {optionId: option.optionId, text: e.target.value} : option;
        });

        this.setState({
            selectedOptions: updatedOptions,
        });

        this.props.handleChange(field.name, updatedOptions);
    };

    render() {
        const {field} = this.props;
        const {selectedOptions} = this.state;
        return (
            <>
                <FormControl component="fieldset" fullWidth={true}>
                    <FormLabel component="legend">{field.label}</FormLabel>
                    <FormGroup
                        aria-label={field.label}
                        name={field.name}
                        value={field.value}
                        className="registration-radio-group"
                    >
                        {field.options.map((option) => {
                            return (
                                <div key={`${option._id}`} className={'fieldWrapper'}>
                                    <FormControlLabel
                                        value={option._id}
                                        control={<Checkbox onChange={this.handleChange} />}
                                        label={option.value}
                                        checked={
                                            selectedOptions &&
                                            selectedOptions.some(
                                                (selectedOption) => selectedOption?.optionId === option._id
                                            )
                                        }
                                        key={`${option._id}`}
                                    />
                                    {selectedOptions &&
                                        selectedOptions
                                            .filter((selectedOption) => selectedOption?.optionId === option._id)
                                            .map((selectedOption) => {
                                                return (
                                                    <TextValidator
                                                        label={option.value}
                                                        type="text"
                                                        name={option.value}
                                                        id={selectedOption.optionId}
                                                        value={selectedOption.text}
                                                        variant="outlined"
                                                        onChange={this.handleChangeText}
                                                        validators={field.validators}
                                                        errorMessages={field.errorMessages}
                                                        multiline={field.multiline > 0}
                                                        rows={field.multiline}
                                                        margin="normal"
                                                        className="fieldContainer"
                                                        key={`${selectedOption.optionId}`}
                                                    />
                                                );
                                            })}
                                </div>
                            );
                        })}
                    </FormGroup>
                </FormControl>
                <TextValidator
                    key={`${field.label}/${field.name}`}
                    label={field.label}
                    type="text"
                    name={field.name}
                    value={field.value}
                    variant="outlined"
                    onChange={this.handleChange}
                    validators={field.validators}
                    errorMessages={field.errorMessages}
                    multiline={field.multiline > 0}
                    rows={field.multiline}
                    fullWidth={true}
                    margin="normal"
                    className="field-container hidden"
                />
            </>
        );
    }
}

export default CheckboxInputField;
