import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as actions from '../../../store/actions/index';
import * as selectors from '../../../store/selectors';
import RegisterEvent from './RegisterEvent';

export default withRouter(
    connect(
        (state) => ({
            loadingUser: selectors.getUserLoading(state),
            error: selectors.getUserError(state),
            languages: selectors.getLanguages(state),
            isRtlLanguage: selectors.getIsRtlLanguage(state),
            eventLanguage: selectors.getEventLanguage(state),
            eventId: selectors.getEventId(state),
            eventSlug: selectors.getEventSlug(state),
            eventInfo: selectors.getEventInfo(state),
            loadingEvent: selectors.getEventLoading(state),
            loggedIn: selectors.getIsUserLoggedIn(state),
            userRegistration: selectors.getEventUserRegistration(state),
        }),
        {
            onRegisterUser: actions.registerUser,
            onClearError: actions.clearError,
            onSetEventPublicMenu: actions.setEventPublicMenu,
            changeParticipantsRegistrationAccess: actions.changeParticipantsRegistrationAccess,
            changeUsersLoginAccess: actions.changeUsersLoginAccess,
            onGetEventUserRegistration: actions.getEventUserRegistration,
        }
    )(RegisterEvent)
);
