/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useLocation} from 'react-router';
import {Link} from 'react-router-dom';

import {whiteLabel, whiteLabelsList} from 'config';
import {IS_SCA_ENV} from 'constants/shared';

export function MenuButtons({translation, loggedIn, onHandleLogout, isIframe, linkToHomePage, isViewAllPage}) {
    const {pathname} = useLocation();

    return (
        <>
            {loggedIn ? (
                <div className={classNames('menu-links', whiteLabel)}>
                    <a
                        onClick={onHandleLogout}
                        className={classNames({
                            colorRed: pathname.includes('/welcome'),
                        })}
                    >
                        <span>{translation?.menu.logOut}</span>
                    </a>
                    {!pathname.includes('/create-event') ||
                        !pathname.includes('/welcome') ||
                        (!IS_SCA_ENV && (
                            <Link
                                to={{pathname: '/create-event'}}
                                className={classNames(`registeractive`, {
                                    active: whiteLabel === whiteLabelsList.added,
                                })}
                            >
                                <span>{translation?.menu?.createEvent}</span>
                            </Link>
                        ))}
                </div>
            ) : (
                !isIframe && (
                    <div className={classNames('menu-links', whiteLabel)}>
                        {linkToHomePage()}
                        {pathname !== `/login` && (
                            <Link className={classNames({black: isViewAllPage})} to={{pathname: '/login'}}>
                                <span>{translation?.menu.logIn}</span>
                            </Link>
                        )}
                        {!IS_SCA_ENV && (
                            <Link
                                to={{pathname: '/register'}}
                                className={classNames(
                                    `register`,
                                    {
                                        active: whiteLabel === whiteLabelsList.added,
                                    },
                                    {black: isViewAllPage}
                                )}
                            >
                                <span>{translation?.menu?.createEvent}</span>
                            </Link>
                        )}
                    </div>
                )
            )}
        </>
    );
}

MenuButtons.propTypes = {
    translation: PropTypes.object,
    loggedIn: PropTypes.bool,
    isIframe: PropTypes.bool,
    isViewAllPage: PropTypes.bool,
    onHandleLogout: PropTypes.func,
    linkToHomePage: PropTypes.func,
};
