import React from 'react';
import {whiteLabel} from '../../../../../config';

import {mobileRectangle} from '../../config/config.homepagePlatform';

const MainRectangleText = (props) => {
    return (
        <div>
            <span
                style={{
                    backgroundImage: `url(${mobileRectangle[whiteLabel].main}`,
                }}
                className="purple-rectangle-background"
            >
                {props.children}
            </span>
        </div>
    );
};

export default MainRectangleText;
