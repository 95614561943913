import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from 'store/actions';
import AccountProfile from './AccountProfile';

export default withRouter(
    connect(
        (state) => ({
            user: state.user.data,
            eventRoles: state.user.eventRoles,
            event: state.event.data,
            eventId: state.event.eventId,
            eventSlug: state.event.eventSlug,
            isMobile: state.layout.isMobile,
            isTablet: state.layout.isTablet,
            languages: state.languages,
            isRtlLanguage: state.languages.isRtlLanguage,
        }),
        {
            onRefreshUserUserData: actions.refreshUserData,
            getProfileRegistrationType: actions.getProfileRegistrationType,
            unregisterFromEvent: actions.unregisterFromEvent,
            updateUserAvatar: actions.updateUserAvatar,
            changeUserLanguage: actions.changeUserLanguage,
        }
    )(AccountProfile)
);
