import {isMobile} from 'react-device-detect';
import * as actionTypes from '../actionTypes/videoConferenceActionTypes';
import {eventStatistics} from 'Api/event10x';
import {stopLocalStream, replaceStreamTracks} from 'services/helpers';

// GROUP CALLS
const videoConferenceReady = (payload) => ({
    type: actionTypes.VIDEO_CONFERENCE_READY,
    payload,
});

const videConferenceLoading = () => ({
    type: actionTypes.VIDEO_CONFERENCE_LOADING,
});

export const startCalling = () => ({
    type: actionTypes.VIDEO_CONFERENCE_START_CALLING,
});

export const stopCalling = () => ({
    type: actionTypes.VIDEO_CONFERENCE_STOP_CALLING,
});

export const closeVideoConference = () => (dispatch, getState) => {
    const {
        videoConference: {group: videoConferenceData},
    } = getState();

    const closePlayback = (playback) => {
        try {
            if (!playback) return;

            playback.close();
        } catch (err) {}
    };

    videoConferenceData.playbacks.forEach((playback) => closePlayback(playback));
    if (videoConferenceData.selfPlayback) closePlayback(videoConferenceData.selfPlayback);
    if (videoConferenceData.capture) videoConferenceData.capture.close();

    dispatch(closeConference());
};

const closeConference = () => ({
    type: actionTypes.CLOSE_VIDEO_CONFERENCE,
});

export const openVideoConference = (user, otherMembers, chatId, isGroupCall = false) => {
    return async (dispatch, getState) => {
        const {
            event: {eventId},
            videoConference: {group: videoConferenceData},
        } = getState();

        const {oneToOneCalls, groupCalls} = eventStatistics(eventId);
        const statisticRequest = isGroupCall ? groupCalls : oneToOneCalls;
        statisticRequest();

        if (videoConferenceData.isActive) dispatch(closeVideoConference());
        dispatch(videConferenceLoading());

        dispatch(videoConferenceReady());
    };
};

export const toggleShareScreen = (payload) => ({
    type: actionTypes.VIDEO_CONFERENCE_SHARE_SCREEN_TOGGLED,
    payload,
});

export const toggleScreen = (videoConference, isShare) => {
    return async (dispatch) => {
        try {
            dispatch(toggleShareScreen());
        } catch (err) {
            console.error(err);
        }
    };
};

const updatePlaybacks = (payload) => ({
    type: actionTypes.VIDEO_CONFERENCE_CHANGE_PLAYBACKS,
    payload,
});

export const changePlaybacks = (videoConference, user, members, chatId) => {
    return async (dispatch) => {
        dispatch(updatePlaybacks({playbacks: [...videoConference.playbacks]}));
    };
};

export const setActiveVideoConferenceType = (payload) => ({
    type: actionTypes.SET_ACTIVE_VIDEO_CONFERENCE_TYPE,
    payload,
});

// ONE TO ONE CALLS
export const openVideoRoom = (payload) => ({type: actionTypes.OPEN_VIDEO_ROOM, payload});

export const closeVideoRoom = () => (dispatch, getState) => {
    const {
        videoConference: {
            oneToOne: {capture, shareScreenCapture, peerConnection},
        },
    } = getState();

    stopLocalStream(capture);
    stopLocalStream(shareScreenCapture);

    peerConnection?.pc && peerConnection.pc.close();

    return dispatch({type: actionTypes.CLOSE_VIDEO_ROOM});
};

export const toggleScreenShare = (payload) => ({type: actionTypes.TOGGLE_SCREEN_SHARE, payload});

export const setIceServers = (payload) => ({type: actionTypes.SET_ICE_SERVERS, payload});

export const setIncomingCallUserId = (payload) => (dispatch, getState) => {
    const {
        user: {activeChatData},
        videoConference: {
            oneToOne: {isActive, incomingCallOffer},
        },
    } = getState();

    // prevent call from third person
    const validation = {
        ignoreOnActiveCall: Boolean(
            isActive && activeChatData?.privateChatPartnerData && activeChatData.privateChatPartnerData._id !== payload
        ),
        ignoreOnBg: Boolean(incomingCallOffer && incomingCallOffer.userId !== payload),
    };

    if (validation.ignoreOnActiveCall || validation.ignoreOnBg) {
        return;
    }

    return dispatch({
        type: actionTypes.SET_INCOMING_CALL_USER_ID,
        payload,
    });
};

export const setIncomingCallOffer = (payload) => (dispatch, getState) => {
    const {
        user: {activeChatData},
        videoConference: {
            oneToOne: {isActive, incomingCallOffer},
        },
    } = getState();

    // prevent call from third person
    const validation = {
        ignoreOnActiveCall: Boolean(
            isActive &&
                activeChatData?.privateChatPartnerData &&
                activeChatData.privateChatPartnerData._id !== payload.userId
        ),
        ignoreOnBg: Boolean(incomingCallOffer && incomingCallOffer.userId !== payload.userId),
    };

    if (validation.ignoreOnActiveCall || validation.ignoreOnBg) {
        return;
    }

    return dispatch({
        type: actionTypes.SET_INCOMING_CALL_OFFER,
        payload,
    });
};

export const setIceCandidate =
    ({userId, candidate}) =>
    async (dispatch, getState) => {
        const {
            videoConference: {
                oneToOne: {peerConnection, iceCandidates},
            },
        } = getState();

        if (peerConnection && peerConnection.companionId === userId && !peerConnection.isEnded) {
            const pc = peerConnection.pc;
            iceCandidates.forEach(async (candidate) => await pc.addIceCandidate(candidate));
        }

        return dispatch({
            type: actionTypes.SET_ICE_CANDIDATE,
            payload: candidate,
        });
    };

export const setParticipantStreamStatus = (payload) => ({
    type: actionTypes.SET_PARTICIPANT_STREAM_STATUS,
    payload,
});

export const clearRemoteUserInfo = (payload) => (dispatch, getState) => {
    const {
        user: {activeChatData},
        videoConference: {
            oneToOne: {isActive},
        },
    } = getState();

    // prevent end call if third person tried to connect
    if (
        payload &&
        activeChatData?.privateChatPartnerData &&
        isActive &&
        activeChatData.privateChatPartnerData._id !== payload.userId
    ) {
        return;
    }

    return dispatch({
        type: actionTypes.CLEAR_REMOTE_USER_INFO,
    });
};

export const setPeerConnection = (payload) => ({type: actionTypes.SET_PEER_CONNECTION, payload});

export const endOneToOneCall = (payload) => async (dispatch, getState) => {
    const {
        user: {activeChatData},
        videoConference: {
            oneToOne: {peerConnection},
        },
    } = getState();

    if (!peerConnection) {
        return;
    }

    // prevent end call if third person tried to connect
    if (
        payload &&
        activeChatData?.privateChatPartnerData &&
        activeChatData.privateChatPartnerData._id !== payload.userId
    ) {
        return;
    }

    const pc = peerConnection.pc;

    if (!pc) return;

    pc.close();

    dispatch(setParticipantStreamStatus(false));
    dispatch(clearRemoteUserInfo(payload));

    return dispatch({type: actionTypes.END_CALL, payload});
};

export const handleReceivedAnswer = (payload) => async (dispatch, getState) => {
    const {
        videoConference: {
            oneToOne: {peerConnection},
        },
    } = getState();

    if (peerConnection && peerConnection.companionId === payload.userId && !peerConnection.isEnded) {
        const pc = peerConnection.pc;

        try {
            await pc.setRemoteDescription({
                type: payload.answer.type,
                sdp: payload.answer.sdp,
            });

            dispatch(
                setPeerConnection({
                    companionId: peerConnection.companionId,
                    pc,
                })
            );
            return dispatch({type: actionTypes.RECEIVE_CALL_ANSWER, payload});
        } catch (error) {}
    }
};

export const startCall = () => ({type: actionTypes.START_CALL});

export const getMediaCapture = (isReplaceTracks) => async (dispatch, getState) => {
    const {
        videoConference: {
            oneToOne: {peerConnection},
        },
    } = getState();

    try {
        dispatch(getMediaCaptureRequest());

        console.info('Getting access to user media devices');

        const videoConstrains = {
            width: {min: 270, max: 1280},
            height: {min: 480, max: 720},
        };

        if (isMobile) Object.assign(videoConstrains, {facingMode: 'user'});

        const stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: videoConstrains,
        });

        dispatch(getMediaCaptureSuccess(stream));

        isReplaceTracks && (await replaceStreamTracks({stream, peer: peerConnection?.pc}));
    } catch (err) {
        console.error('Error opening video camera.', err);
        dispatch(getMediaCaptureFail());
    }
};

const getMediaCaptureRequest = () => ({type: actionTypes.GET_MEDIA_CAPTURE_REQUEST});
const getMediaCaptureSuccess = (payload) => ({type: actionTypes.GET_MEDIA_CAPTURE_SUCCESS, payload});
const getMediaCaptureFail = () => ({type: actionTypes.GET_MEDIA_CAPTURE_FAIL});

export const getScreenShareCapture =
    ({soundEnabled, videoEnabled}) =>
    async (dispatch, getState) => {
        const {
            videoConference: {
                oneToOne: {peerConnection},
            },
        } = getState();

        try {
            dispatch(getScreenShareCaptureRequest());

            console.info('Getting access to screen media');

            const stream = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    displaySurface: 'monitor',
                    logicalSurface: true,
                    cursor: true,
                    width: {max: 1920},
                    height: {max: 1080},
                    frameRate: {max: 30},
                },
                audio: soundEnabled,
            });

            stream.oninactive = () => {
                stopLocalStream(stream);
                dispatch(toggleScreenShare(false));
                videoEnabled && dispatch(getMediaCapture(true));
            };

            dispatch(getScreenShareCaptureSuccess(stream));

            await replaceStreamTracks({stream, peer: peerConnection?.pc});
        } catch (err) {
            console.error('Error getting screen capture.', err);
            dispatch(getScreenShareCaptureFail());
        }
    };

const getScreenShareCaptureRequest = () => ({type: actionTypes.GET_SCREEN_SHARE_CAPTURE_REQUEST});
const getScreenShareCaptureSuccess = (payload) => ({type: actionTypes.GET_SCREEN_SHARE_CAPTURE_SUCCESS, payload});
const getScreenShareCaptureFail = () => ({type: actionTypes.GET_SCREEN_SHARE_CAPTURE_FAIL});

export const getCompanionStream = (payload) => ({type: actionTypes.GET_COMPANION_STREAM, payload});
