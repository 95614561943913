import {connect} from 'react-redux';
import * as selectors from 'store/selectors';
import * as actions from 'store/actions';
import MatchmakingBanner from './MatchmakingBanner';

export default connect(
    (state) => ({
        eventId: selectors.getEventId(state),
        userLoading: selectors.getUserLoading(state),
        isMatchBannerOpen: selectors.getIsMatchBannerOpen(state),
        sideNavigation: selectors.getSideNavigation(state),
    }),
    {
        onSetCompleteMatching: actions.setCompleteMatching,
    }
)(MatchmakingBanner);
