import {connect} from 'react-redux';
import Village from './Village';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export default connect(
    (state) => ({
        eventId: selectors.getEventId(state),
        eventSlug: selectors.getEventSlug(state),
        loadingEvent: selectors.getEventLoading(state),
        event: selectors.getEventData(state),
        user: selectors.getUserData(state),
        resourcesAccess: selectors.getResourcesAccess(state),
        isLargeScreen: selectors.getIsLargeScreen(state),
    }),
    {
        setHasVideoWall: actions.sideNavHasVideoWall,
        onLogoutUser: actions.logoutUser,
        setActiveWall: actions.setActiveWall,
        seeLiveWall: actions.sideNavSeeLiveWall,
        onSetEventProtectedMenu: actions.setEventProtectedMenu,
        onSetBuildingId: actions.setBuildingId,
    }
)(Village);
