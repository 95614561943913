import React from 'react';
import {generatePath} from 'react-router';
import {Link} from 'react-router-dom';
import Slider from 'react-slick';
import classNames from 'classnames';

import SideMenu from '../SideMenu';
import OpenChatWithOrganizerRepresentative from '../OpenChatWithOrganizerRepresentative/OpenChatWithOrganizerRepresentative';
import NotificationPresentationStarts from '../NotificationPresentationStarts/NotificationPresentationStarts';
import FullOverlay from '../FullOverlay';
import BoothVideoDialog from '../BoothVideoDialog';
import InformDialog from '../InformDialog/InformDialog';

import PlayIcon from 'Images/play.svg';
import InfoBoothIcon from 'Images/contour3.svg';
import {checkIfEventHasEnded, getVideoIdFromYoutubeUrl} from 'services/utils';
import {
    connectToEventLobbyNews,
    connectToBuildingNews,
    connectToEventNews,
    disconnectFromEvent,
    disconnectFromEventLobbyNews,
    disconnectFromEventNews,
    disconnectFromNotifications,
    disconnectFromBuildingNews,
    eventHasMatchingUpdated,
    hotNewsStarted,
    hotNewsStopped,
    matchmakingEnabledUpdated
} from 'Api/socketApi';
import {getLobbyBackground} from 'services/utils';
import {isHaveSocialMedia} from 'services/helpers';
import NoVideoImage from 'assets/no-video.png';

import HotNewsPopup from '../HotNewsPopup/HotNewsPopup';
import NoBuildingAccess from '../NoBuildingAccess/NoBuildingAccess';
import {IS_SCA_ENV, LOCAL_STORAGE_AUDITORIUM_INDEX_KEY} from 'constants/shared';
import {FALLBACK_TRANSLATION_SLUG} from 'constants/translations';
import {AbilityContext} from 'permissionsConfig';
import {RouterConfig} from 'routerConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';
import MatchmakingBanner from '../MatchmakingBanner';

import './Lobby.scss';

const LOBBY_ROOM_LINK_TYPES = {
    auditorium: 'auditorium',
    auditoriumArchive: 'auditoriumArchive',
    showfloor: 'showfloor'
};

class Lobby extends React.Component {
    state = {
        showVideo: false,
        auditoriumElementStyle: {},
        showFloorElementStyle: {},
        sliderContainerStyle: {},
        lobbyVideoContainerStyle: {},
        infoBoothContainerStyle: {},
        bannerContainerStyle: {},
        hotNews: {},
        showNoShowfloorDialog: false,
        showNoShowfloorAccessDialog: false,
        showNoAuditoriumDialog: false,
        showNoAuditoriumAccessDialog: false
    };

    componentDidMount() {
        const {
            onSetEventProtectedMenu,
            setHasVideoWall,
            onGetSocialsPromotion,
            onSocetSetEnabeledEventMatchmaking,
            onSocetSetEventHasMatchmaking,
            eventId,
            building,
            sideNavigationDetails
        } = this.props;

        onSetEventProtectedMenu();

        this.handleSeeLiveWall();
        localStorage.removeItem(LOCAL_STORAGE_AUDITORIUM_INDEX_KEY);
        setHasVideoWall(false);
        this.setActiveElementsPositions();
        window.addEventListener('resize', this.setActiveElementsPositions);

        connectToEventNews({eventId: this.props.eventId});
        connectToEventLobbyNews({eventId: this.props.eventId});

        building && connectToBuildingNews(eventId, building._id);
        onGetSocialsPromotion(eventId);
        this.setLanguagesData();

        hotNewsStarted(({newsItem}) => {
            this.setState({
                hotNews: newsItem
            });
        });

        hotNewsStopped(({newsItem}) => {
            this.setState({
                hotNews: {}
            });
        });
        // socket
        matchmakingEnabledUpdated((err, data) => {
            if (data.eventId !== eventId) return;
            onSocetSetEnabeledEventMatchmaking(data.matchingEnabled);
        });
        eventHasMatchingUpdated((err, data) => {
            if (data.eventId !== eventId) return;
            onSocetSetEventHasMatchmaking(data.hasMatching);
        });

        this.updateLivePost(sideNavigationDetails?.activeWallId);
    }

    componentWillUnmount() {
        const {eventId, building} = this.props;

        window.removeEventListener('resize', this.setActiveElementsPositions);
        disconnectFromEventNews({eventId: this.props.eventId});
        disconnectFromEventLobbyNews({eventId: this.props.eventId});
        building && disconnectFromBuildingNews(eventId, building._id);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.event._id !== this.props.event._id) {
            this.handleSeeLiveWall();
        }

        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
        if (prevProps.sideNavigationDetails?.activeWallId !== this.props.sideNavigationDetails?.activeWallId) {
            this.updateLivePost(this.props.sideNavigationDetails?.activeWallId);
        }
    }

    updateLivePost = (wallId) => {
        wallId && this.props.onGetWallPosts({wallId, page: 1});
    };

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation
        });
    };

    handleSeeLiveWall = () => {
        const wallData = {
            wallId: this.props.event.liveWall._id,
            highlightUsers: []
        };

        this.props.setActiveWall(wallData);
        this.props.seeLiveWall();
    };

    handleOpenLobbyVideo = () => {
        this.setState({showVideo: true});
    };

    handleOpenNoShowfloorDialog = () => {
        this.setState({showNoShowfloorDialog: true});
    };

    handleOpenNoShowfloorAccess = () => {
        this.setState({showNoShowfloorAccessDialog: true});
    };

    handleOpenNoAuditoriumDialog = () => {
        this.setState({showNoAuditoriumDialog: true});
    };

    handleOpenNoAuditoriumAccess = () => {
        this.setState({showNoAuditoriumAccessDialog: true});
    };

    closeDialog = () => {
        this.setState({
            showVideo: false,
            showNoShowfloorDialog: false,
            showNoShowfloorAccessDialog: false,
            showNoAuditoriumDialog: false,
            showNoAuditoriumAccessDialog: false
        });
    };

    setActiveElementsPositions = () => {
        const realHeight = window.innerHeight - 70;

        let showfloorElementStyle = {
            width: (realHeight * 39.1) / 100,
            height: (realHeight * 33.2) / 100,
            left: (realHeight * 34.48) / 100,
            top: (-realHeight * 2.3) / 100
        };

        let auditoriumElementStyle = {
            width: (realHeight * 39.1) / 100,
            height: (realHeight * 33.2) / 100,
            left: (-realHeight * 29.6) / 100,
            top: (-realHeight * 2.3) / 100
        };

        let sliderContainerStyle = {
            width: (realHeight * 19.723) / 100,
            height: (realHeight * 10.9573) / 100,
            left: (realHeight * 2.3068) / 100,
            top: (-realHeight * 12.8027) / 100
        };

        let lobbyVideoContainerStyle = {
            width: (realHeight * 23.06) / 100,
            height: (realHeight * 12.91) / 100,
            left: (-realHeight * 71.39) / 100,
            top: (-realHeight * 10.95) / 100
        };

        let infoBoothContainerStyle = {
            backgroundImage: `url('${InfoBoothIcon}')`,
            width: (realHeight * 21.683) / 100,
            height: (realHeight * 38.06) / 100,
            left: (-realHeight * 66.32) / 100,
            top: (realHeight * 15.916) / 100
        };

        let bannerContainerStyle = {
            width: (realHeight * 17.993) / 100,
            height: (realHeight * 33.79469) / 100,
            left: (-realHeight * 86.01534) / 100,
            top: (realHeight * 19.26182) / 100
        };

        this.setState({
            auditoriumElementStyle: auditoriumElementStyle,
            showfloorElementStyle: showfloorElementStyle,
            sliderContainerStyle: sliderContainerStyle,
            lobbyVideoContainerStyle: lobbyVideoContainerStyle,
            infoBoothContainerStyle: infoBoothContainerStyle,
            bannerContainerStyle: bannerContainerStyle
        });
    };

    checkIfEventHasEnded = () => {
        const {event, building} = this.props;

        return checkIfEventHasEnded(building || event, event.utcTimezone);
    };

    handleLogoutUser = () => {
        // if user logged out we should also disconnect him from notifications
        const {user, event} = this.props;
        disconnectFromNotifications({userId: user._id});
        disconnectFromEvent({userId: user._id, eventId: event._id});
        this.props.onLogoutUser();
    };

    generateLobbyRoomLink = (type) => {
        const {building, eventSlug} = this.props;
        let path;

        switch (type) {
            case LOBBY_ROOM_LINK_TYPES.auditorium:
                path = building ? RouterConfig.event.village.buildingAuditorium : RouterConfig.event.auditorium;
                break;
            case LOBBY_ROOM_LINK_TYPES.auditoriumArchive:
                path = building
                    ? RouterConfig.event.village.buildingAuditoriumArchive
                    : RouterConfig.event.auditoriumArchive;
                break;
            case LOBBY_ROOM_LINK_TYPES.showfloor:
                path = building ? RouterConfig.event.village.buildingShowfloor : RouterConfig.event.showfloor;
                break;
            default:
                path = '';
        }

        return generatePath(path, {
            eventSlug,
            buildingId: building?._id
        });
    };

    render() {
        const {
            event,
            building,
            eventSlug,
            resourcesAccess,
            isLargeScreen,
            languages,
            match: {params}
        } = this.props;
        const {
            showNoShowfloorDialog,
            showNoShowfloorAccessDialog,
            showNoAuditoriumDialog,
            showNoAuditoriumAccessDialog,
            showVideo,
            translation
        } = this.state;

        const brandingTranslation = event.brandingTranslations?.find(
            (translation) => translation.language === languages.eventLanguage
        );
        const fleshbackTranslation = this.props.languages.translations[FALLBACK_TRANSLATION_SLUG];

        let sliderSettings = {
            dots: false,
            arrows: false,
            autoplay: true,
            draggable: false,
            pauseOnHover: false,
            swipe: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        let lobbyVideoId = null;
        const ability = this.context;
        const brandingDataSource = building || event.brandingData;
        const eventHasEnded = this.checkIfEventHasEnded();
        const matchingAbility = ability.can(
            ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY,
            ROLE_FEATURES_TYPES.VIEW_MATHCING
        );
        const showMatchingBanner = matchingAbility && event?.hasMatching && event?.matchingEnabled;
        const isBuildingLobby = params?.buildingId;

        if (
            building &&
            event.hasAccessManagement &&
            resourcesAccess !== null &&
            !resourcesAccess.buildings.includes(building._id)
        ) {
            return <NoBuildingAccess />;
        }

        if (brandingDataSource.lobbyVideoUrl) {
            lobbyVideoId = getVideoIdFromYoutubeUrl(brandingDataSource.lobbyVideoUrl);
        }

        let EventLobyImage;
        if ('lobbyRender' in brandingDataSource && brandingDataSource.lobbyRender) {
            EventLobyImage = `${event.brandingData.filesUrl}${brandingDataSource.lobbyRender.original}`;
        } else {
            EventLobyImage = getLobbyBackground(event.language);
        }

        return (
            <>
                <div className="lobby position-background">
                    <SideMenu hideInterpretation hideSocialMedia={!isHaveSocialMedia(event)} />
                    {isLargeScreen && <HotNewsPopup hotNews={this.state.hotNews} />}
                    {showMatchingBanner && <MatchmakingBanner />}

                    <div
                        className="lobby__page"
                        style={{
                            backgroundImage: `url('${EventLobyImage}')`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover'
                        }}
                    >
                        <div className="centerOfPage">
                            <div className="relativeCenter">
                                <div
                                    className="lobby__room lobby-room clickable-element"
                                    style={this.state.showfloorElementStyle}
                                >
                                    {event.hasVillage && !isBuildingLobby ? (
                                        <div
                                            className={classNames('lobby-room__room-wrapper relativeCenter', {
                                                isSca: IS_SCA_ENV
                                            })}
                                        >
                                            <Link
                                                className="lobby-room__link"
                                                to={{
                                                    pathname: generatePath(RouterConfig.event.village.root, {
                                                        eventSlug
                                                    })
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={classNames('lobby-room__room-wrapper relativeCenter', {
                                                isSca: IS_SCA_ENV
                                            })}
                                        >
                                            {event.restrictShowfloorAccess &&
                                            !ability.can(
                                                ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY,
                                                ROLE_FEATURES_TYPES.RESTRICT_SHOWFLOOR_ACCESS
                                            ) ? (
                                                <div
                                                    onClick={this.handleOpenNoShowfloorDialog}
                                                    className=" lobby-room__show-info-dialog-button"
                                                />
                                            ) : event.hasAccessManagement &&
                                              resourcesAccess !== null &&
                                              !resourcesAccess.showfloor ? (
                                                <div
                                                    onClick={this.handleOpenNoShowfloorAccess}
                                                    className="lobby-room__show-info-dialog-button"
                                                />
                                            ) : (
                                                <Link
                                                    className="lobby-room__link"
                                                    to={{
                                                        pathname: this.generateLobbyRoomLink(
                                                            LOBBY_ROOM_LINK_TYPES.showfloor
                                                        )
                                                    }}
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div
                                    className="lobby__room lobby-room clickable-element"
                                    style={this.state.auditoriumElementStyle}
                                >
                                    {event.hasVillage && !isBuildingLobby ? (
                                        <div
                                            className={classNames('lobby-room__room-wrapper relativeCenter', {
                                                isSca: IS_SCA_ENV
                                            })}
                                        >
                                            <Link
                                                className="lobby-room__link"
                                                to={{
                                                    pathname: generatePath(RouterConfig.event.village.root, {
                                                        eventSlug
                                                    })
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className={classNames('lobby-room__room-wrapper relativeCenter', {
                                                isSca: IS_SCA_ENV
                                            })}
                                        >
                                            {event.restrictAuditoriumAccess &&
                                            !ability.can(
                                                ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY,
                                                ROLE_FEATURES_TYPES.RESTRICT_AUDITORIUM_ACCESS
                                            ) ? (
                                                <div
                                                    onClick={this.handleOpenNoAuditoriumDialog}
                                                    className="lobby-room__show-info-dialog-button"
                                                />
                                            ) : (
                                                <>
                                                    {event.hasAccessManagement &&
                                                    !event.hasVillage &&
                                                    resourcesAccess !== null &&
                                                    resourcesAccess.auditoriums.length === 0 ? (
                                                        <div
                                                            onClick={this.handleOpenNoAuditoriumAccess}
                                                            className="lobby-room__show-info-dialog-button"
                                                        />
                                                    ) : (
                                                        <Link
                                                            className="lobby-room__link"
                                                            to={{
                                                                pathname: this.generateLobbyRoomLink(
                                                                    eventHasEnded
                                                                        ? LOBBY_ROOM_LINK_TYPES.auditoriumArchive
                                                                        : LOBBY_ROOM_LINK_TYPES.auditorium
                                                                )
                                                            }}
                                                        />
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                                {ability.can(
                                    ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY,
                                    ROLE_FEATURES_TYPES.CHAT_WITH_ORGANIZER_REP
                                ) && (
                                    <div
                                        className="lobby__info-booth"
                                        style={
                                            this.props.event.representatives.length
                                                ? this.state.infoBoothContainerStyle
                                                : null
                                        }
                                    >
                                        <OpenChatWithOrganizerRepresentative />
                                    </div>
                                )}
                                <div
                                    className="lobby__show-lobby-video show-lobby-video clickable-element"
                                    style={this.state.lobbyVideoContainerStyle}
                                >
                                    {brandingDataSource.lobbyVideoUrl ? (
                                        <div
                                            className="show-lobby-video__image-preview-video"
                                            style={{
                                                backgroundImage: `url('https://img.youtube.com/vi/${lobbyVideoId}/0.jpg')`
                                            }}
                                            onClick={this.handleOpenLobbyVideo}
                                        >
                                            <div
                                                className="show-lobby-video__play-button"
                                                style={{backgroundImage: `url('${PlayIcon}')`}}
                                            />
                                        </div>
                                    ) : (
                                        <div
                                            className="show-lobby-video__image-preview-video--no-video"
                                            style={{backgroundImage: `url('${NoVideoImage}')`}}
                                        />
                                    )}
                                </div>
                                <BoothVideoDialog
                                    opened={showVideo}
                                    closeDialog={this.closeDialog}
                                    videoUrl={brandingDataSource.lobbyVideoUrl}
                                />
                                {brandingDataSource.lobbyLogos.length > 0 && (
                                    <div className="lobby__slider-logos" style={this.state.sliderContainerStyle}>
                                        <Slider {...sliderSettings}>
                                            {brandingDataSource.lobbyLogos.map((lobbyLogo) => {
                                                return (
                                                    <div
                                                        className="lobby__slider-logos-img-wrapper"
                                                        key={lobbyLogo._id}
                                                    >
                                                        <img
                                                            className="lobby__slider-logos-img"
                                                            src={event.brandingData.filesUrl + lobbyLogo.image}
                                                            alt="Logo"
                                                        />
                                                    </div>
                                                );
                                            })}
                                        </Slider>
                                    </div>
                                )}
                                <div
                                    className="lobby__banner clickable-element no-hover"
                                    style={this.state.bannerContainerStyle}
                                >
                                    {brandingDataSource.lobbyBanner ? (
                                        <img
                                            src={
                                                this.props.event.brandingData.filesUrl + brandingDataSource.lobbyBanner
                                            }
                                            alt="Logo"
                                            className="lobby__banner-image"
                                        />
                                    ) : null}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showNoShowfloorDialog && (
                    <InformDialog
                        open={showNoShowfloorDialog}
                        onClose={this.closeDialog}
                        dialogTitle={`${translation?.closedShowfloorDialog.title}`}
                        dialogDescription={`${translation?.closedShowfloorDialog.content}`}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {showNoShowfloorAccessDialog && (
                    <InformDialog
                        open={showNoShowfloorAccessDialog}
                        onClose={this.closeDialog}
                        dialogTitle={`${translation?.closedShowfloorDialog.title}`}
                        dialogDescription={`${translation?.closedShowfloorDialog.contentPackage}`}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {showNoAuditoriumDialog && (
                    <InformDialog
                        open={showNoAuditoriumDialog}
                        onClose={this.closeDialog}
                        dialogTitle={`${translation?.closedAuditoriumDialog.title}`}
                        dialogDescription={`${translation?.closedAuditoriumDialog.content}`}
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {showNoAuditoriumAccessDialog && (
                    <InformDialog
                        open={showNoAuditoriumAccessDialog}
                        onClose={this.closeDialog}
                        dialogTitle={`${translation?.closedAuditoriumDialog.title}`}
                        dialogDescription={
                            IS_SCA_ENV
                                ? `${
                                      translation?.closedAuditoriumDialog?.scaContentPackage ||
                                      fleshbackTranslation?.closedAuditoriumDialog?.scaContentPackage
                                  }`
                                : `${translation?.closedAuditoriumDialog?.contentPackage}`
                        }
                        dialogButtonLabel={`${translation?.closedShowfloorDialog.button}`}
                    />
                )}
                {!this.props.event.participantsLogin &&
                    ability.cannot(
                        ROLE_ABILITIES_TYPES.PARTICIPANT.VIEW_WITH_DISABLED_PARTICIPANTS_LOGIN,
                        ROLE_FEATURES_TYPES.LOBBY
                    ) && (
                        <FullOverlay
                            title="Event closed now"
                            text={
                                brandingTranslation?.participantCloseMessage ||
                                'You have successfully registered for this event. The event is currently closed.'
                            }
                            buttonText="Log out"
                            handleClick={this.handleLogoutUser}
                        />
                    )}
                <NotificationPresentationStarts />
            </>
        );
    }
}

Lobby.contextType = AbilityContext;

export default Lobby;
