import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
    linkStyles: {
        display: 'inline-flex',
        alignItems: 'center',
        maxWidth: '100%',
    },
    linkTextStyles: {
        flex: '1 1 auto',
        display: 'inline-flex',
        overflow: 'hidden',
    },
    linklIconStyles: {
        flex: '0 0 auto',
        height: '24px',
        marginLeft: '12px',
    },
    linkLabelStyles: {
        flex: '0 0 auto',
        marginRight: '8px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '250px',
    },
    linkElementStyles: {
        flex: '1 1 auto',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
});

export default useStyles;
