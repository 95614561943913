import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {ValidatorForm} from 'react-material-ui-form-validator';

import * as actions from '../../../store/actions';

export const useAgendaDropdownOpening = () => {
    const dispatch = useDispatch();
    return () => dispatch(actions.topNavSeeAgenda());
};

export const useAdditionValidationSetting = (startTime) => {
    useEffect(() => {
        ValidatorForm.addValidationRule('isCustomEndLaterThanStart', (value) => value > startTime);
        return () => ValidatorForm.removeValidationRule('isCustomEndLaterThanStart');
    }, [startTime]);
};
