import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {TabPanel, PanelWrapper, CustomTabs, CustomTab} from '../../../../../CustomTabPanel';
import {usePlatformTranslation} from '../../../../../../../services/hooks';
import EventProfilePage from './components/EventProfilePage';
import MyMatchmaking from './components/MyMatchmaking';
import {useShowMatchMaking} from 'services/helpers';
import {eventHasMatchingUpdated, matchmakingEnabledUpdated} from 'Api/socketApi';

const EventProfile = ({isLargeScreen, eventId, onSocetSetEnabeledEventMatchmaking, onSocetSetEventHasMatchmaking}) => {
    const defaultTabIndex = isLargeScreen ? 0 : -1;
    const [tabIndex, setTabIndex] = React.useState(defaultTabIndex);
    const {matchmaking: t_matchmaking, myAccountPage: _myAccountPage} = usePlatformTranslation();

    const showTabPanel = isLargeScreen ? true : tabIndex < 0;
    const showMatching = useShowMatchMaking();

    useEffect(() => {
        matchmakingEnabledUpdated((err, data) => {
            if (data.eventId !== eventId) return;
            onSocetSetEnabeledEventMatchmaking(data.matchingEnabled);
            setTabIndex(0);
        });
        eventHasMatchingUpdated((err, data) => {
            if (data.eventId !== eventId) return;
            onSocetSetEventHasMatchmaking(data.hasMatching);
            setTabIndex(0);
        });
    }, [eventId, onSocetSetEnabeledEventMatchmaking, onSocetSetEventHasMatchmaking]);

    return (
        <PanelWrapper>
            {showTabPanel && (
                <CustomTabs tabIndex={tabIndex} setTabIndex={setTabIndex}>
                    <CustomTab label={_myAccountPage.eventProfileSectionTitle} tabIndex={tabIndex} />
                    {showMatching && <CustomTab label={t_matchmaking.matchmaking} tabIndex={tabIndex} />}
                </CustomTabs>
            )}
            <TabPanel
                setTabIndex={setTabIndex}
                title={_myAccountPage.eventProfileSectionTitle}
                value={tabIndex}
                index={0}
            >
                <EventProfilePage />
            </TabPanel>
            {showMatching && (
                <TabPanel setTabIndex={setTabIndex} title={t_matchmaking.matchmaking} value={tabIndex} index={1}>
                    <MyMatchmaking />
                </TabPanel>
            )}
        </PanelWrapper>
    );
};

EventProfile.propTypes = {
    isLargeScreen: PropTypes.bool,
    eventId: PropTypes.string,
    onSocetSetEnabeledEventMatchmaking: PropTypes.func,
    onSocetSetEventHasMatchmaking: PropTypes.func,
};

export default EventProfile;
