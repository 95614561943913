import React from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';

import EditUserInfoWrapper from 'Routes/components/EditUserInfoWrapper';

import * as actions from 'store/actions';
import {Api} from 'Api';
import CustomCancelButton from 'Routes/components/CustomCancelButton';


class EditUserInformationMobile extends React.Component {
    state = {
        user: {
            firstName: '',
            lastName: ''
        },
        disabled: true,
        translation: null
    };

    componentDidMount() {
        this.setState({
            user: {
                firstName: this.props.user.first,
                lastName: this.props.user.last
            }
        });
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation
        });
    };

    handleChange = (e) => {
        this.setState(
            {
                user: {
                    ...this.state.user,
                    [e.target.name]: e.target.value
                }
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleUpdateUserInformation = async () => {
        try {
            await Api.request({
                method: 'put',
                url: '/users',
                payload: {
                    first: this.state.user.firstName,
                    last: this.state.user.lastName
                }
            });

            this.props.onRefreshUserUserData();
            this.props.closeDialog();
        } catch (error) {}
    };

    render() {
        const {opened, user, handleImageChange, handleRemoveUserAvatar, imgErrorText} = this.props;
        let {firstName, lastName} = this.state.user;
        let {translation} = this.state;
        return (
            <EditUserInfoWrapper className={`small-padding-top ${opened ? 'opened' : ''}`}>
                <div>
                    <div className="page-title">
                        <p>{translation?.myAccountPage.editUserDialogTitle}</p>
                    </div>
                    <div className="content-wrapper">
                        <div className="form-wrapper">
                            <div>
                                <div className="image-container">
                                    <div
                                        className="avatar"
                                        style={{
                                            backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${user.avatarBig}')`
                                        }}
                                    />
                                    <div className="upload-new-image">
                                        <div className="upload-container">
                                            <label htmlFor="file" className="select-image"></label>
                                            <input
                                                onChange={handleImageChange}
                                                name="file"
                                                type="file"
                                                id="file"
                                                className="d-none"
                                            />
                                            <span>{translation?.myAccountPage.uploadImage}</span>
                                        </div>
                                        <button
                                            className="remove-image"
                                            onClick={handleRemoveUserAvatar}
                                            disabled={user.avatarBig === 'default_avatar.png'}
                                        >
                                            <span>{translation?.myAccountPage.removeImage}</span>
                                        </button>
                                        <span className="image-error">{imgErrorText}</span>
                                    </div>
                                </div>
                            </div>
                            <ValidatorForm ref="form" onSubmit={this.handleUpdateUserInformation}>
                                <TextValidator
                                    label={translation?.myAccountPage.firstName}
                                    onChange={this.handleChange}
                                    name="firstName"
                                    value={firstName}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    validators={['required']}
                                    errorMessages={[`${translation?.errors.required}`]}
                                />
                                <TextValidator
                                    label={translation?.myAccountPage.lastName}
                                    onChange={this.handleChange}
                                    name="lastName"
                                    value={lastName}
                                    variant="outlined"
                                    fullWidth
                                    required
                                    validators={['required']}
                                    errorMessages={[`${translation?.errors.required}`]}
                                />
                            </ValidatorForm>
                        </div>
                    </div>
                    <div className="buttons-actions">
                        <CustomCancelButton
                            text={translation?.generalText.close}
                            onClick={() => this.props.closeDialog('')}
                        />
                        <button
                            className="update-button"
                            type="submit"
                            disabled={this.state.disabled}
                            onClick={this.handleUpdateUserInformation}
                        >
                            {translation?.generalText.update}
                        </button>
                    </div>
                </div>
            </EditUserInfoWrapper>
        );
    }
}

export default connect(
    (state) => ({
        user: state.user.data,
        languages: state.languages
    }),
    {
        onRefreshUserUserData: actions.refreshUserData
    }
)(EditUserInformationMobile);
