import React from 'react';
import {withStyles} from '@material-ui/core/styles/index';
import {Checkbox} from '@material-ui/core';

// TODO: colors fix
const STYLES = {
    primary: {
        color: 'var(--theme-primary-color)',
        padding: 0,
        '&$checked': {
            color: 'var(--theme-primary-color)',
        },
    },
    primaryGrey: {
        padding: 0,
        '&$checked': {
            color: 'var(--theme-primary-color)',
        },
    },
    dark: {
        color: '#2B2B2B',
        padding: 0,
        '&$checked': {
            color: '#2B2B2B',
        },
    },
    white: {
        color: '#fff',
        padding: 0,
    },
};

const CustomCheckbox = withStyles({
    root: STYLES.primary,
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const DarkCheckbox = withStyles({
    root: STYLES.dark,
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const PrimaryGreyCheckbox = withStyles({
    root: STYLES.primaryGrey,
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export const DefaultWhiteCheckbox = withStyles({
    root: STYLES.white,
    checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default CustomCheckbox;
