import _ from 'lodash';
import {ADD_SOCKET_CONNECTION, REMOVE_SOCKET_CONNECTION} from '../actionTypes/socketActionTypes';

const socketConnections = (state = [], {type, payload}) => {
    switch (type) {
        case ADD_SOCKET_CONNECTION:
            return (state = _.uniqBy([...state, payload], 'connectionName'));
        case REMOVE_SOCKET_CONNECTION:
            const updatedState = state.filter((connection) => connection.connectionName !== payload.connectionName);
            return (state = updatedState);
        default:
            return state;
    }
};

export default socketConnections;
