import React from 'react';

import ProgramDropdownList from '../../../../../ProgramDropdownList/ProgramDropdownList';
import AgendaProgramItem from '../AgendaProgramItem/AgendaProgramItem';

const AgendaProgramList = ({list, ...rest}) => (
    <ProgramDropdownList>
        {list.map((agenda) => (
            <ProgramDropdownList.Item key={agenda.id}>
                <AgendaProgramItem {...agenda} {...rest} />
            </ProgramDropdownList.Item>
        ))}
    </ProgramDropdownList>
);

export default AgendaProgramList;
