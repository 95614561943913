import React, {useState, useEffect, useRef} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import useForceUpdate from 'use-force-update';

import VideoChatButtons from '../VideoChatButtons';

import VideoChatPlayerVideoPlates from './components/VideoChatPlayerVideoPlates/VideoChatPlayerVideoPlates';
import VideoChatPlayerSelfVideo from './components/VideoChatPlayerSelfVideo/VideoChatPlayerSelfVideo';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        background: '#000',
        height: '100%',
    },
});

export const VideoChatPlayer = ({videoConference, closeVideoConference, toggleScreen, toggleShareScreen, members}) => {
    const {capture, playbacks, selfPlayback} = videoConference;
    const classes = useStyles();
    const forceUpdate = useForceUpdate();

    const [soundEnabled, setSoundEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const vidRefs = useRef([]);
    const selfVidRef = useRef(null);

    const toggleSound = () => {
        if (!capture) return;
        capture.mediaStream.getAudioTracks().forEach((track) => (track.enabled = !soundEnabled));
        setSoundEnabled(!soundEnabled);
    };

    const removeVideoTracks = () => {
        if (!capture) return;
        capture.mediaStream.getVideoTracks().forEach((track) => {
            track.stop();
            capture.removeTrack(track);
        });
    };

    const toggleVideo = async () => {
        try {
            if (videoEnabled) removeVideoTracks();
            else {
                setIsLoading(true);
                await toggleScreen(videoConference, false);
            }
            setIsLoading(false);

            setVideoEnabled(!videoEnabled);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const close = () => closeVideoConference();

    const startVideo = async (videoRef, playback) => {
        try {
        } catch (err) {
            console.error('startVideo', err);
        }
    };

    const toggleScreenShare = (isShare) => {
        if (isShare) {
            toggleScreen(videoConference, isShare);
        } else {
            if (videoEnabled) return toggleScreen(videoConference, isShare);
            removeVideoTracks();
            toggleShareScreen({isScreenShared: false});
        }
    };

    useEffect(() => {
        if (!capture) return;
        if (!soundEnabled) capture.mediaStream.getAudioTracks().forEach((track) => (track.enabled = false));
    }, [capture]); // eslint-disable-line react-hooks/exhaustive-deps
    const activeStreamsCount = playbacks.reduce(
        (count, pb) => (pb.mediaStream && pb.mediaStream.active ? ++count : count),
        0
    );
    return (
        <div className={classes.root}>
            <VideoChatPlayerSelfVideo
                startVideo={startVideo}
                activeStreamsCount={activeStreamsCount}
                selfVidRef={selfVidRef}
                selfPlayback={selfPlayback}
            />
            <VideoChatPlayerVideoPlates
                members={members}
                playbacks={playbacks}
                vidRefs={vidRefs}
                activeStreamsCount={activeStreamsCount}
                startVideo={startVideo}
                forceUpdate={forceUpdate}
            />
            <VideoChatButtons
                soundEnabled={soundEnabled}
                videoEnabled={videoEnabled}
                toggleSound={toggleSound}
                toggleVideo={toggleVideo}
                toggleScreenShare={toggleScreenShare}
                isLoading={isLoading}
                close={close}
            />
        </div>
    );
};
