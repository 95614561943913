import {agendaTypes, meetingStatuses} from '../../constants/data';
import {getFormattedDate} from '../utils';

export const getAllItemList = (listByDate) =>
    listByDate.reduce(
        (resultList, {date, list}) => [...resultList, ...list.map((item) => ({...item, date: getFormattedDate(date)}))],
        []
    );

export const formMeetingList = (allItemList) => allItemList.filter(({type}) => type === agendaTypes.meeting);

export const filterPendingMeetings = (allItemList) =>
    allItemList.filter(
        ({type, meetingStatus}) => type !== agendaTypes.meeting || meetingStatus === meetingStatuses.confirmed
    );
