import React from 'react';

const BackIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5947 2.66998C15.9884 2.28713 16.6055 2.25706 17.0346 2.59982C17.547 3.0092 17.5844 3.77524 17.1141 4.23251L9.12627 12L17.1141 19.7675C17.5844 20.2248 17.547 20.9908 17.0346 21.4002C16.6055 21.7429 15.9884 21.7129 15.5947 21.33L6 12L15.5947 2.66998Z"
            fill="#898989"
        />
    </svg>
);

export default BackIcon;
