import React from 'react';
import {Button} from '@material-ui/core';

import {usePlatformTranslation} from '../../../../../services/hooks';
import {useStyles} from './ActionButtons.styles';

const ActionButtons = ({isDiscardDisabled = false, handleDiscard, isApplyDisabled = false, handleApply}) => {
    const classes = useStyles();
    const translation = usePlatformTranslation();

    //this key is for iOS to update layout of btn on disable/able
    return (
        <div key={`${isDiscardDisabled}${isApplyDisabled}`} className={classes.controlleWrapper}>
            <Button disabled={isDiscardDisabled} onClick={handleDiscard} className={classes.controlleBtn}>
                {`${translation?.filter?.discard}`}
            </Button>
            <Button disabled={isApplyDisabled} onClick={handleApply} className={classes.controlleBtn}>
                {`${translation?.filter?.apply}`}
            </Button>
        </div>
    );
};

export default ActionButtons;
