import React, {PureComponent} from 'react';
import cn from 'classnames';
import {connect} from 'react-redux';

import {getPrivateChatId} from 'services/utils';
import {withUserStatus} from 'services/hocs';
import * as actions from 'store/actions';
import ChatButton from 'Routes/components/SideMenu/components/Networking/components/UsersList/components/UserPreviewPlate/components/ChatButton/ChatButton';

class SingleRepresentative extends PureComponent {
    handleOpenPrivateChat = () => {
        const targetUserId = this.props.user.user._id;
        const privateChatId = getPrivateChatId(this.props.privateChats, targetUserId);
        const {eventId} = this.props;

        if (privateChatId) {
            this.props.onOpenPrivateChat(privateChatId);
        } else {
            if (targetUserId !== this.props.userLoggedIn._id && !this.props.loading) {
                this.props.onCreatePrivateChat(targetUserId, eventId);
            }
        }
    };

    render() {
        const {user, getUserStatus} = this.props;
        let userLoggedIn = this.props.userLoggedIn;

        return (
            <li>
                <div
                    className={cn(
                        'user-container',
                        'user__status',
                        `user__status_${getUserStatus(user?.user?.id || user?._id)}`
                    )}
                >
                    <div className="container-avatar">
                        <div
                            className="participant-avatar"
                            style={{
                                backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${user.user.avatarSmall}')`,
                            }}
                        />
                    </div>
                    <div className="user-details">
                        <div className="user-name">
                            <p className="user-name-top">
                                {user.user.first} {user.user.last}
                            </p>
                            <p>
                                {user.title} {user.title && user.company ? 'at' : null} {user.company}
                            </p>
                        </div>
                        <div
                            className={cn('connection', {
                                'no-action': !this.props.friendConnectionReceived,
                            })}
                        >
                            {user.user._id !== userLoggedIn._id && (
                                <ChatButton onClick={this.handleOpenPrivateChat} disabled={this.props.loading} />
                            )}
                        </div>
                    </div>
                </div>
            </li>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        privateChats: state.user.data.privateChats,
        loading: state.user.loading,
        userLoggedIn: state.user.data,
        eventId: state.event.eventId,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onCreatePrivateChat: (targetUserId, eventId) => dispatch(actions.createPrivateChat(targetUserId, eventId)),
    };
};

export default withUserStatus(connect(mapStateToProps, mapDispatchToProps)(SingleRepresentative));
