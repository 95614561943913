import {userStatuses} from 'constants/data';

export const formPlatformLevelUserIdList = (user) => {
    if (!user) {
        return [];
    }

    const privateChatUserIdList =
        user?.privateChats
            .reduce((acc, {chat}) => {
                if (chat.user_1 && chat.user_2) acc.push([chat.user_1, chat.user_2]);
                return acc;
            }, [])
            .flatMap((users) => users.map(({_id}) => _id)) || [];

    const groupChatUserIdList =
        user?.groupChats.map(({chat}) => chat.users).flatMap((users) => users.map(({_id}) => _id)) || [];

    const contactUserIdList = user?.contacts.map(({user}) => user?._id) || [];

    return [...privateChatUserIdList, ...groupChatUserIdList, ...contactUserIdList];
};

export const formEventLevelUserIdList = (event, eventUsers, exhibitors) => {
    if (!event) {
        return [];
    }
    let participantIdList = [];
    let exhibitorIdList = [];
    if (event) {
        eventUsers.forEach(({user}) => (user ? participantIdList.push(user._id) : null));
        exhibitors.forEach(({user}) => (user ? exhibitorIdList.push(user._id) : null));
    }

    return [...participantIdList, ...exhibitorIdList, event?.owner._id];
};

export const normalizeUserStatusesList = (userStatusesList, status) =>
    Object.fromEntries(userStatusesList.map(({userId}) => [userId, status]));

export const getRelatedUsersStatuses = (allStatuses, relatedUserIdList) => {
    const statuses = {};
    relatedUserIdList.forEach((id) => (statuses[id] = allStatuses[id] || userStatuses.offline));
    return statuses;
};
