import React from 'react';
import {useSelector} from 'react-redux';

import ColoredScrollbars from '../../../../../../../ColoredScrollbars/ColoredScrollbars';
import ChatMessage from '../ChatMessage/ChatMessage';
import {getTimeAgoString} from 'services/utils';
import {checkIfIsOnline} from 'services/helpers';
import {getOnlineUsers} from 'store/selectors';

import './ListMessages.scss';

const ListMessages = ({
    messages,
    languages,
    userId,
    partnerId,
    openVideoConference,
    receivedMessagesForChatId,
    userSentNewMessage,
    isGroupChat,
    incomingOneToOneCallUserId,
}) => {
    const onlineUsers = useSelector(getOnlineUsers);
    const isUserOnline = checkIfIsOnline(onlineUsers, partnerId) || incomingOneToOneCallUserId === partnerId;

    return (
        <div className="chat-wrapper">
            <div>
                <ul
                    className="live-wall chat-messages-list"
                    id="chat-messages-container"
                    data-length={messages.length >= 9 ? 'maximum-responsive' : ''}
                >
                    <ColoredScrollbars
                        scrollbottom="true"
                        usersentnewmessage={`${userSentNewMessage}`}
                        chatid={receivedMessagesForChatId}
                    >
                        <li className="empty-space-container"></li>
                        {messages.map((message, index) => {
                            const timeAgoString = getTimeAgoString(message.createdAt, languages.platformLanguage);

                            let showUserDetails = true;

                            // if we have consecutive messages from the same user we don't display theire details all over again
                            if (index > 0 && messages[index - 1].user._id === message.user._id) {
                                showUserDetails = false;
                            }

                            return (
                                <li
                                    key={message._id}
                                    className={userId === message.user._id ? 'message me' : 'message'}
                                >
                                    <ChatMessage
                                        showUserDetails={showUserDetails}
                                        message={message}
                                        openVideoConference={openVideoConference}
                                        isGroupChat={isGroupChat}
                                        isUserOnline={isUserOnline}
                                    />
                                    <div className="time-wrapper">
                                        <p className="message-text">
                                            <small>{timeAgoString}</small>
                                        </p>
                                    </div>
                                </li>
                            );
                        })}
                    </ColoredScrollbars>
                </ul>
            </div>
        </div>
    );
};

export default ListMessages;
