import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';

import PlatformMenu from '../PlatformMenu';
import HeaderMenu from '../HeaderMenu';
import Footer from '../Footer';
import EnglishContent from './components/EnglishContent';
import ArabicContent from './components/ArabicContent';

import {allTranslations} from '../../../constants/translations/all-translations';

import ScaArabicContent from './components/ScaArabicContent';
import EgyptEnglishContent from './components/EgyptEnglishContent';
import {IS_EGYPT_PAVILION_ENV, IS_SCA_ENV} from '../../../constants/shared';
import {useSelector} from 'react-redux';
import {getEventLanguage} from '../../../store/selectors';

function PrivacyPolicy({match: {params}}) {
    const eventLanguage = useSelector(getEventLanguage);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const displayLanguage = IS_SCA_ENV ? eventLanguage : params?.language;
    const displayContent = displayLanguage === allTranslations.ar.slug ? <ArabicContent /> : <EnglishContent />;
    const scaDisplayContent = displayLanguage === allTranslations.ar.slug ? <ScaArabicContent /> : <EnglishContent />;

    return (
        <div className="gdpr-page">
            {params.eventSlug ? (
                <HeaderMenu className={'white'} coloredLogo disabledLanguageSelector />
            ) : (
                <PlatformMenu className={'white'} coloredLogo forcePrimaryText />
            )}
            {IS_SCA_ENV ? scaDisplayContent : IS_EGYPT_PAVILION_ENV ? <EgyptEnglishContent /> : displayContent}
            <Footer />
        </div>
    );
}

export default withRouter(PrivacyPolicy);
