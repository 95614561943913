import {createMuiTheme} from '@material-ui/core/styles';
import '../index.scss';

export const mainPalette = {
    palette: {
        primary: {
            main: '#D62E2F',
        },
        secondary: {
            main: '#9C231F',
        },
        success: {
            main: '#646464',
        },
        error: {
            main: '#B00020',
        },
        warning: {
            main: '#FC9725',
        },
        info: {
            main: '#D6EBF0',
        },
        disabled: {
            main: '#908E8A',
        },
        grey: {
            0: '#FFFFFF',
            A100: '#EFEEF1',
            A150: '#CACBD0',
            A200: '#808080',
            A400: '#646464',
        },
        text: {
            primary: '#211E16',
            secondary: '#777081',
            darkGrey: '#2B2B2B',
            lightGrey: '#898989',
        },
        background: {
            paper: '#FFFFFF',
            default: '#F5F5F5',
        },
        action: {
            hover: '#fbeaea',
            active: 'rgba(169, 19, 20, 0.35)',
        },
    },
    typography: {
        fontFamily: '"Montserrat", sans-serif',
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
            // TODO: add mobile and clean up default material values
            tablet: 768,
            laptop: 1024,
            desktop: 1280,
        },
    },
};

const mainTheme = createMuiTheme(mainPalette);

export default mainTheme;
