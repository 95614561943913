import {connect} from 'react-redux';

import {BoothVideoDialog} from './BoothVideoDialog';

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
    };
};

export default connect(mapStateToProps)(BoothVideoDialog);
