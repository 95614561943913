import React from 'react';

const BusinessCardEditingIconBox = () => (
    <svg width="173" height="136" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M89.23 9.564v53.387L68.002 75.188l-7.184 4.115L33.712 94.79V41.4l27.106-15.593 7.184-4.116L89.23 9.564z"
            fill="#777081"
        />
        <path opacity=".5" d="M89.23 126.734L113.5 136l56-32-18.746-19.146-6.096-1.3-55.428 43.18z" fill="#CACBD0" />
        <path
            d="M61.47 57.32l-7.184-4.115-20.574-11.804V94.79l20.574 11.803 7.185 4.224 27.759 15.918V73.239L61.47 57.32z"
            fill="var(--theme-primary-color)"
        />
        <path
            d="M116.989 25.483l-7.294-4.224L89.23 9.564v53.387l20.465 11.804 7.294 4.115 27.758 15.919V41.4l-27.758-15.918z"
            fill="#898989"
        />
        <path
            d="M144.747 41.401V94.79l-21.227 12.236-7.293 4.115-26.997 15.594V73.239l26.997-15.594 7.293-4.115L144.747 41.4z"
            fill="var(--theme-secondary-color)"
        />
        <path
            d="M33.712 41.401l20.792 11.912 7.185 4.115 27.54 15.81-24.27 26.914L9.44 68.315l24.271-26.914zM89.23 9.564l27.792-8.333 55.517 31.837-27.792 8.333L89.23 9.564z"
            fill="#CACBD0"
        />
        <path
            d="M89.23 9.564L55.64.304.121 32.142l33.59 9.26L89.23 9.563zM144.747 41.401l-20.792 11.912-7.184 4.115-27.541 15.81 23.33 27.914 55.409-31.838-23.222-27.913z"
            fill="#CACBD0"
        />
        <path d="M89.23 62.951l8.817 5.09-8.817 5.198-8.818-5.198 8.818-5.09z" fill="var(--theme-secondary-color)" />
    </svg>
);

export default BusinessCardEditingIconBox;
