import React from 'react';
import {Tooltip} from '@material-ui/core';
import {usePlatformTranslation} from 'services/hooks';
import BackButtonIcon from 'Images/back_btn.svg';
import ArrowLeft from 'Images/arrow_left.svg';
import {ChatIcon} from 'Images/chat';
import useTheme from '@material-ui/core/es/styles/useTheme';
import './MainChatHeader.scss';

const MainChatHeader = ({
    isOpenCreateGroupChat,
    restrictedNetwork,
    openCreateGroupChat,
    closeCreateGroupChat,
    closeActiveChat,
}) => {
    const translation = usePlatformTranslation();
    const theme = useTheme();

    return (
        <div className="create-chat">
            {isOpenCreateGroupChat && (
                <>
                    <img
                        className="back-button desktop"
                        src={BackButtonIcon}
                        alt="Back Button"
                        width="24"
                        height="24"
                        onClick={closeCreateGroupChat}
                    />
                    <img
                        className="back-button mobile"
                        src={ArrowLeft}
                        alt="Back Button"
                        width="24"
                        height="24"
                        onClick={closeCreateGroupChat}
                    />
                </>
            )}
            <div>
                <p className="dropdown-title">
                    {!isOpenCreateGroupChat && (
                        <>
                            <img
                                className="close-active-chat-button mobile"
                                src={ArrowLeft}
                                alt="Back Button"
                                width="24"
                                height="24"
                                onClick={closeActiveChat}
                            />
                        </>
                    )}
                    <span>{translation?.chatsDropdown.title}</span>
                </p>
                {!isOpenCreateGroupChat && (
                    <button onClick={openCreateGroupChat} disabled={restrictedNetwork}>
                        <ChatIcon primary={theme.palette.primary.main} secondary={theme.palette.secondary.main} />
                        <div className={'button-text'}>
                            <p>
                                {restrictedNetwork ? (
                                    <Tooltip title="Restricted">
                                        <span>{translation?.chatsDropdown.groupChat}</span>
                                    </Tooltip>
                                ) : (
                                    <span>{translation?.chatsDropdown.groupChat}</span>
                                )}
                            </p>{' '}
                        </div>
                    </button>
                )}
            </div>
        </div>
    );
};

export default MainChatHeader;
