import React from 'react';
import {ReactComponent as ProgramActiveIcon} from '../../../../../../../../../Images/checklist-green.svg';
import {usePlatformTranslation} from '../../../../../../../../../services/hooks';
import './EmptyProgram.scss';

const EmptyProgram = () => {
    const translation = usePlatformTranslation();
    return (
        <div className="empty-program__program-entry">
            <div className="empty-program__program-container empty-program__program-container--is-empty">
                <div className="empty-program__empty-program">
                    <p className="empty-program__info-text">{translation?.programDropdown.noProgram}</p>
                    <ProgramActiveIcon className="empty-program__icon primary-fill-svg" />
                </div>
            </div>
        </div>
    );
};

export default EmptyProgram;
