import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../../store/actions';
import HeaderMenu from './HeaderMenu';

export default withRouter(
    connect(
        (state) => ({
            loggedIn: state.user.loggedIn,
            user: state.user.data,
            eventSlug: state.event.eventSlug,
            languages: state.languages,
            eventId: state.event.eventId,
            eventInfo: state.event.eventInfo,
            isRtlLanguage: state.languages.isRtlLanguage,
        }),
        {
            onLogoutUser: actions.logoutUser,
            onSetEventLanguage: actions.setEventLanguage,
            changeParticipantsRegistrationAccess: actions.changeParticipantsRegistrationAccess,
            changeUsersLoginAccess: actions.changeUsersLoginAccess,
        }
    )(HeaderMenu)
);
