import {VideoModule} from './VideoModule';
import {connect} from 'react-redux';
import * as selectors from 'store/selectors';
import {toggleSessionFullScreen} from 'store/actions';

export default connect(
    (state) => ({
        user: selectors.getUserData(state),
        sessionData: selectors.getSessionData(state),
        mediaSession: selectors.getMediaSession(state),
        isKicked: selectors.getIsUserKicked(state),
        isFullScreen: selectors.getFullScreen(state),
    }),
    {toggleSessionFullScreen}
)(VideoModule);
