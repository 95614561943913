import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        width: '500px',
        paddingTop: '0',
        direction: ({isRtlLanguage}) => (isRtlLanguage ? 'rtl' : 'ltr'),

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },

    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
        width: 50,
        '&.isRtl': {
            left: theme.spacing(1),
        },
    },

    boothDialogPagination: {
        paddingTop: 5,
    },

    title: {
        fontSize: '24px',
        fonWeight: '600',
        color: '#000', // TODO: color
        textTransform: 'capitalize',
        paddingBottom: '0',
    },

    applyContainer: {
        right: theme.spacing(2),
        bottom: theme.spacing(2),
    },

    applyButton: {
        color: theme.palette.primary.main,
        fontWeight: '300',
        paddingTop: 0,
    },

    details: {
        margin: '0 16px 20px 16px',
    },

    controls: {
        marginLeft: '15px',
    },

    boothDialogText: {
        whiteSpace: 'pre-line',
    },

    navigationWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '0 0 0 16px',

        '&.isRtl': {
            margin: '0 16px 0 0',
        },
    },

    paginationWrapper: {
        display: 'flex',
        alignItems: 'center',
    },

    navArrow: {
        fill: theme.palette.primary.main,
        cursor: 'pointer',
    },
}));
