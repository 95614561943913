import React from 'react';
import {Dialog, makeStyles} from '@material-ui/core';
import classNames from 'classnames';
import {useSelector} from 'react-redux';
import {getIsRtlLanguage} from '../../../store/selectors';

const useStyles = makeStyles((theme) => ({
    root: {
        '&.isRtl': {
            direction: 'rtl !important',
        },
    },
}));

const CustomDialog = ({className, withRtl, disabledClosing, ...props}) => {
    const classes = useStyles();
    const isRtlLanguage = useSelector(getIsRtlLanguage);

    return (
        <Dialog
            {...props}
            className={classNames(classes.root, {isRtl: withRtl && isRtlLanguage}, className)}
            disableBackdropClick={disabledClosing}
            disableEscapeKeyDown={disabledClosing}
        />
    );
};

export default CustomDialog;
