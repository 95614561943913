export const createActionType = (serviceName, action) => `[${serviceName}]: ${action}`;

export const createAsyncActionTypes = (service, operation) => ({
    START: createActionType(`${service} ${operation}`, 'Request'),
    SUCCESS: createActionType(`${service} ${operation}`, 'Success'),
    FAIL: createActionType(`${service} ${operation}`, 'Failure'),
});

export const createAction = (type, payload) => ({type, payload});

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties,
    };
};

export const createAsyncAction = (actionTypes, transformParams = (params) => params) => {
    const actionCreator = (params, callback = () => {}) => ({
        type: actionTypes.START,
        payload: transformParams(params),
        onSuccess: callback,
    });

    return actionCreator;
};
