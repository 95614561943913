import _ from 'lodash';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {allTranslations} from '../../constants/translations/all-translations';

export const FALLBACK_TRANSLATION_SLUG = 'en';

const usePlatformTranslation = (selector = (translations) => translations) => {
    const languages = useSelector((state) => state.languages);
    const eventTranslation = allTranslations[languages.platformLanguage].content;
    const fallbackTranslation = allTranslations[FALLBACK_TRANSLATION_SLUG].content;

    const mixedTranslations = useMemo(() => _.merge(_.cloneDeep(fallbackTranslation), _.cloneDeep(eventTranslation)), [
        eventTranslation,
        fallbackTranslation,
    ]);

    try {
        return selector(mixedTranslations) || selector(fallbackTranslation);
    } catch {
        try {
            return selector(fallbackTranslation);
        } catch {
            return '';
        }
    }
};

export default usePlatformTranslation;
