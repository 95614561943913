import {Accordion, AccordionDetails, AccordionSummary} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classNames from 'classnames';
import React from 'react';
import {useSelector} from 'react-redux';
import {usePlatformTranslation} from '../../../services/hooks';
import {statusesValue} from '../ExhibitorDashboard/components/JobApplicantsSwitch/MyJobApplicants/MyJobApplicants.helpers';
import {useStyles} from './MobileTable.styles';

/**
 * [MobileTable description]
 * @param  {Array} columns Array of objects : {id: String, name: String, label: String} 
                            where name is using as key in items of array "fields",  
 * @param  {String} titleKey key in columns that will be shown as title
 * @param  {Array} fields  data to show
 * @param  {Boolean} isWithStatusChip show status Chip
 */

const MobileTable = ({titleKey, columns, fields, className, isWithStatusChip = false}) => {
    const translation = usePlatformTranslation();
    const isRtl = useSelector((state) => state.languages.isRtlLanguage);
    const classes = useStyles();
    return (
        <div className={classNames(classes.root, className)}>
            {fields.map((row, i) => (
                <Accordion key={i} classes={{expanded: classes.reset}} square>
                    <AccordionSummary
                        classes={{
                            expanded: classes.summaryExpanded,
                            content: classNames(classes.reset, classes.summaryContent),
                        }}
                        className={classes.summary}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <p className={classes.tableTitle}>{row[titleKey]}</p>
                        {isWithStatusChip && (
                            <p
                                className={classNames(
                                    classes.statusChip,
                                    {isRtl: isRtl},
                                    {[`${classes.statusGood}`]: row.status === statusesValue.short_listed},
                                    {[`${classes.statusWarn}`]: row.status === statusesValue.under_review},
                                    {[`${classes.statusBad}`]: row.status === statusesValue.rejected}
                                )}
                            >
                                {translation.appliedJobs.statusList[row.status]}
                            </p>
                        )}
                    </AccordionSummary>
                    <AccordionDetails className={classes.details}>
                        {columns.map((column) => {
                            if (column.name === titleKey || (column.name === 'status' && isWithStatusChip)) {
                                return null;
                            }
                            const value = row[column.name];
                            return (
                                <div key={column.id} className={classes.rowWrapper}>
                                    <div className={classNames(classes.column, classes.columnHead)}>
                                        <p className={classes.reset}>{column.label}</p>
                                    </div>
                                    <div className={classes.column}>
                                        <p className={classes.reset}>
                                            {typeof column.render === 'function'
                                                ? column.render(row)
                                                : column.format
                                                ? column.format(value)
                                                : value}
                                        </p>
                                    </div>
                                </div>
                            );
                        })}
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
};

export default MobileTable;
