import React from 'react';

const IconDelete = () => (
    <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.5266 3.13649H11.9204V1.35165C11.9204 0.992383 11.6455 0.714233 11.3046 0.714233C11.2715 0.714233 11.2495 0.725871 11.2387 0.737481C11.2277 0.725871 11.2056 0.714233 11.1946 0.714233H5.06936H5.02549H4.98137C4.64051 0.714233 4.37659 0.992383 4.37659 1.35165V3.13652H1.75937C1.01147 3.13652 0.428711 3.75069 0.428711 4.53891V5.55881V5.89855C0.428711 6.41497 0.835041 6.83361 1.33627 6.83361H1.58341V19.8949C1.58341 20.6831 2.15515 21.2857 2.90306 21.2857H13.383C14.1309 21.2857 14.7136 20.6831 14.7136 19.8949V6.83361H14.9497C15.451 6.83361 15.8573 6.41497 15.8573 5.89855V5.55881V4.53891C15.8573 3.75069 15.2743 3.13649 14.5266 3.13649ZM5.58619 1.98904H10.6998V3.13649H5.58619V1.98904ZM13.493 19.8949C13.493 19.9762 13.4599 20.0108 13.383 20.0108H2.90306C2.82611 20.0108 2.79304 19.9762 2.79304 19.8949V6.83358H13.493V19.8949ZM14.6367 5.55878H1.64933V4.53888C1.64933 4.45777 1.68241 4.4113 1.75935 4.4113H14.5266C14.6036 4.4113 14.6367 4.45777 14.6367 4.53888V5.55878ZM10.9171 18.0129V13.3376C10.9171 13.0794 10.7139 12.8701 10.4633 12.8701C10.2127 12.8701 10.0095 13.0794 10.0095 13.3376V18.0129C10.0095 18.2711 10.2127 18.4804 10.4633 18.4804C10.7139 18.4804 10.9171 18.2711 10.9171 18.0129ZM6.37928 9.59734V18.0129C6.37928 18.2711 6.17611 18.4804 5.92549 18.4804C5.67488 18.4804 5.47171 18.2711 5.47171 18.0129V9.59734C5.47171 9.33913 5.67488 9.12981 5.92549 9.12981C6.17611 9.12981 6.37928 9.33913 6.37928 9.59734Z"
            fill="#2B2B2B"
        />
    </svg>
);

export default IconDelete;
