import React from 'react';
import PropTypes from 'prop-types';
import {UploadImage} from 'Routes/components/OrganizerDashboard/components/EventBranding/components/BrandingHomepage/components/HomepageSocialsPromotion/components';
import {ReactComponent as UploadIcon} from 'Images/upload-file.svg';
import SessionDashboardBlock from '../SessionDashboardBlock';
import {eventFolder} from 'config';
import './Thumbnail.scss';

export const Thumbnail = ({timeslot, eventId, auditoriumId, sessionId, onUploadSessionThumbnailImage}) => {
    const uploadImage = (data) => {
        const formData = new FormData();
        formData.append('image', data?.file ? data.file : '');
        onUploadSessionThumbnailImage({auditoriumId, timeslotId: sessionId, data: formData});
    };

    const renderUploadPreiew = ({label}) => (
        <div className="thumbnail-preview">
            <label htmlFor={label} className="thumbnail-preview__label">
                <UploadIcon className="secondary-fill-svg" />
                <span className="thumbnail-preview__text">Click here to upload</span>
            </label>
        </div>
    );

    const uploadedThumbnail = timeslot?.thumbnail ? `${eventFolder}${eventId}/${timeslot?.thumbnail}` : null;

    return (
        <SessionDashboardBlock title="THUMBNAIL IMAGE">
            <UploadImage
                setImage={uploadImage}
                renderUploadPreiew={renderUploadPreiew}
                previouslyUploadedImage={uploadedThumbnail}
            />
        </SessionDashboardBlock>
    );
};

Thumbnail.propTypes = {
    timeslot: PropTypes.object,
    eventId: PropTypes.string,
    auditoriumId: PropTypes.string,
    sessionId: PropTypes.string,
    onUploadSessionThumbnailImage: PropTypes.func,
};
