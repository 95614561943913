import React from 'react';
import {ChromePicker} from 'react-color';

import './ColorPicker.scss';

const ColorPicker = ({getColor = ({hex}) => hex, onChange, ...rest}) => {
    const handleChange = (data) => onChange(getColor(data));
    return <ChromePicker className="ColorPicker" onChangeComplete={handleChange} {...rest} />;
};

export default ColorPicker;
