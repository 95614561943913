import {makeStyles} from '@material-ui/core/styles';
export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: 'calc(100vh - 115px)',
        background: theme.palette.background.paper,
    },
    backBtn: {
        width: '100%',
        height: 48,
        textAlign: 'left',
        paddingTop: 6,
        boxShadow: '0px 5px 5px -5px rgba(0, 0, 0, 0.2)',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
    },
    button: {
        justifyContent: 'start',
    },
    tab: {
        width: 105,
        minWidth: 105,
        textTransform: 'uppercase',
    },
    hide: {
        display: 'none',
    },
    title: {
        fontSize: '16px',
        fontWeight: 700,
        textTransform: 'uppercase',
    },
}));
