import React, {useContext, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {NavLink, useHistory} from 'react-router-dom';
import classNames from 'classnames';
import * as selectors from 'store/selectors';

import {usePlatformTranslation} from '../../../../../services/hooks';
import {AbilityContext} from '../../../../../permissionsConfig';
import {getAccountNavigationMenu} from '../../../../../services/helpers/myAccount';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from '../../../../../constants/ability';

import {ReactComponent as MenuDropdownIcon} from '../../../../../Images/menu-dropdown-icon.svg';
import {ReactComponent as ArrowRightIcon} from '../../../../../Images/arrow_right.svg';
import {ReactComponent as ArrowLeftIcon} from '../../../../../Images/arrow_left.svg';
import {ReactComponent as DoneIcon} from '../../../../../Images/done.svg';

import './AccountNavigationMenuMobile.scss';
import {resetAccountBackLink} from '../../../../../store/actions/accountActions';

const AccountNavigationMenuMobile = ({eventSlug}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const ability = useContext(AbilityContext);
    const [isOpen, setIsOpen] = useState(false);

    const isRtlLanguage = useSelector(selectors.getIsRtlLanguage);
    const backLink = useSelector(selectors.getAccountNavigationBackLink);
    const hide = useSelector(selectors.getAccountNavigationHide);

    const accountNavigationMenu = usePlatformTranslation(
        (translation) => translation.myAccountPage.accountNavigationMenu
    );

    const menu = useMemo(() => getAccountNavigationMenu(accountNavigationMenu), [accountNavigationMenu]);
    const ActiveTab = menu.find((el) => el.linkFunction(eventSlug) === document.location.pathname);

    const isProfileShown = ability.can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.EVENT_PROFILE);
    const isAppliedJobsHidden = !ability.can(
        ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY,
        ROLE_FEATURES_TYPES.APPLIED_JOBS_TABLE
    );

    const handleClose = () => setIsOpen(false);
    const handleClickSelect = () => setIsOpen((state) => !state);
    const handleGoBack = () => {
        history.push(backLink.link);
        dispatch(resetAccountBackLink());
    };

    return (
        <div className={classNames('account-navigation-menu-mobile', {hide})}>
            {backLink.name ? (
                <div
                    className="account-navigation-menu-mobile__select-tab account-navigation-menu-mobile__select-tab--back"
                    onClick={handleGoBack}
                >
                    <ArrowLeftIcon
                        className={classNames('account-navigation-menu-mobile__arrow-back', {isRtl: isRtlLanguage})}
                    />
                    <p className="account-navigation-menu-mobile__select-tab-title account-navigation-menu-mobile__select-tab-title--back">
                        {backLink.name}
                    </p>
                </div>
            ) : (
                <div
                    className={classNames('account-navigation-menu-mobile__select-tab', {
                        'account-navigation-menu-mobile__select-tab--active': isOpen,
                    })}
                    onClick={handleClickSelect}
                >
                    <p className="account-navigation-menu-mobile__select-tab-title">{ActiveTab?.title}</p>
                    <MenuDropdownIcon
                        className={classNames('account-navigation-menu-mobile__down-arrow', {
                            'account-navigation-menu-mobile__down-arrow--active': isOpen,
                        })}
                    />
                </div>
            )}

            {isOpen && (
                <div className="account-navigation-menu-mobile__dropdown">
                    {menu.map(({title, linkFunction, hidden}, index) => {
                        const validationRules = {
                            appliedJobsHidden: isAppliedJobsHidden && title === accountNavigationMenu?.appliedJobs,
                            profileHidden: !isProfileShown && title === accountNavigationMenu?.eventProfile,
                            itemHidden: hidden,
                        };

                        if (Object.values(validationRules).some((rule) => Boolean(rule))) return null;
                        const isActive = document.location.pathname === linkFunction(eventSlug);

                        return (
                            <NavLink
                                key={index}
                                to={linkFunction(eventSlug)}
                                className="account-navigation-menu-mobile__dropdown-item"
                                activeClassName="account-navigation-menu-mobile__dropdown-item--active"
                                onClick={handleClose}
                            >
                                {title}
                                {isActive ? (
                                    <DoneIcon className="account-navigation-menu-mobile__dropdown-arrow account-navigation-menu-mobile__dropdown-arrow--active" />
                                ) : (
                                    <ArrowRightIcon className="account-navigation-menu-mobile__dropdown-arrow" />
                                )}
                            </NavLink>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default AccountNavigationMenuMobile;
