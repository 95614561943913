import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    footer: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: '600',
        marginTop: 60,
    },
    text: {
        color: theme.palette.text.hint,
    },
    link: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
        textAlign: 'center !important',
        marginTop: 10,
    },
    box: {
        width: 226,
        height: 195,
    },
    images: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
    },
}));
