import {connect} from 'react-redux';
import {getAppliedJobs} from '../../../../../../../../store/actions';
import {AppliedJobs} from './AppliedJobs';

export default connect(
    (state) => ({
        userId: state.user.data._id,
        appliedJobsList: state.appliedJobs.jobsList,
    }),
    {
        getAppliedJobs,
    }
)(AppliedJobs);
