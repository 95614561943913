import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import {redirectToLastEvent} from 'services/helpers';

import {getPlatformBackground, whiteLabel} from 'config';
import {usePlatformTranslation} from 'services/hooks';
import {RouterConfig} from 'routerConfig';

import './NotFound.scss';

export const NotFound = ({loggedIn, user, topNavClose, setEventId, setEventSlug, getEventInfo, setEventPublicMenu}) => {
    const history = useHistory();
    const location = useLocation();
    const [isErrorBoundary, setIsErrorBoundary] = useState(false);
    const translation = usePlatformTranslation();
    const BackgroundImage = getPlatformBackground(whiteLabel);
    const errorBoundaryText = 'Something goes wrong';

    useEffect(() => {
        window.scrollTo(0, 0);
        setEventPublicMenu();

        if (history?.location?.pathname.includes('error')) {
            setIsErrorBoundary(true);
        }
    }, [history, setEventPublicMenu]);

    const handleRedirect = () => {
        if (!loggedIn) history.push(RouterConfig.root);

        if (loggedIn) {
            const totalEventsUserRegistered = user?.eventRoles?.length;
            const lastEventRegistered = user?.eventRoles[totalEventsUserRegistered - 1];

            // set routing state to users last event to avoid default redirect on public page
            history.replace({...location, state: {eventSlug: lastEventRegistered.event.slug}});
            getEventInfo(lastEventRegistered.event._id, () => {
                setEventId(lastEventRegistered.event._id);
                setEventSlug(lastEventRegistered.event.slug);
                redirectToLastEvent(user, history, topNavClose);
            });
        }

        setEventId(null);
        redirectToLastEvent(user, history, topNavClose);
    };

    return (
        <div>
            <div className="homepage">
                <div className="event-container" style={{backgroundImage: `url('${BackgroundImage}')`}}>
                    <div className="homepage-overlay">
                        <div className="smallContainer">
                            <div className="not-found-page">
                                <h1 className="not-found-page__error-title">{isErrorBoundary ? '500' : '404'}</h1>
                                <p className="not-found-page__error-text">
                                    {isErrorBoundary
                                        ? errorBoundaryText
                                        : `${translation?.notFound?.notFoundDescription}`}
                                </p>
                                <button
                                    className="not-found-page__link"
                                    onClick={handleRedirect}
                                >{`${translation?.notFound?.notFoundHomepage}`}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
