import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utilityStateFunction';

const initialState = {
    loading: false,
    error: null,
    invitationCodes: [],
    isInvitationCodeValid: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.API_CALL_INVITATIONS_START:
            return updateObject(state, {
                loading: true,
            });
        case actionTypes.API_CALL_INVITATIONS_FAIL:
            return updateObject(state, {
                loading: false,
                error: action.payload,
            });
        case actionTypes.GET_INVITATION_CODES_SUCCESS:
            return updateObject(state, {
                loading: false,
                error: null,
                invitationCodes: action.payload,
            });
        case actionTypes.CHECK_INVITATION_CODE_SUCCESS:
            return updateObject(state, {
                loading: false,
                error: null,
                isInvitationCodeValid: action.payload,
            });
        case actionTypes.CHECK_INVITATION_CODE_FAIL:
            return updateObject(state, {
                loading: false,
                error: null,
                isInvitationCodeValid: action.payload,
            });
        default:
            return state;
    }
};

export default reducer;
