import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles({
    playbacksList: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        flexWrap: 'wrap',
    },
    hidden: {
        display: 'none',
    },
    isFullScreen: {
        height: 'calc(100% - 48px)',
    },
});
