import React, {forwardRef} from 'react';
import {usePlatformTranslation} from '../../../../../../../../../../../../../services/hooks';
import {useStyles} from '../MoreMenu.styles';
import clsx from 'clsx';
import {MenuItem} from '@material-ui/core';
import IconSettings from '../../Icons/IconSettings';

const MenuItemSettings = forwardRef(({onClick, className, ...props}, ref) => {
    const classes = useStyles();
    const translation = usePlatformTranslation();
    return (
        <MenuItem ref={ref} className={clsx(classes.item, className)} onClick={onClick} {...props}>
            <IconSettings />
            <span className={classes.itemText}>{translation?.chatsDropdown.chatSettingsButton}</span>
        </MenuItem>
    );
});

export default MenuItemSettings;
