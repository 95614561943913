import React, {useState, useEffect, useCallback, useContext} from 'react';
import {generatePath} from 'react-router';
import {Scrollbars} from 'react-custom-scrollbars';

import MyEventsMobile from './components/MyEventsMobile/MyEventsMobile';
import MyAccountDropdownComponent from '../MyAccountDropdownComponent';

import {IS_SCA_ENV} from 'constants/shared';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';
import {AbilityContext} from 'permissionsConfig';
import {RouterConfig} from 'routerConfig';

export function MyEvents({
    user,
    eventId,
    languages,
    setEventSlug,
    setEventId,
    onCloseTopNav,
    clearBuildingData,
    eventSlug,
    isLargeScreen,
    isRtlLanguage,
    history
}) {
    const [translation, setTranslation] = useState(null);
    const ability = useContext(AbilityContext);

    const setLanguagesData = useCallback(() => {
        const translation = languages.translations[languages.platformLanguage];
        setTranslation(translation);
    }, [languages.platformLanguage, languages.translations]);

    useEffect(() => {
        setLanguagesData();
    }, [setLanguagesData]);

    const redirectToEvent = useCallback(
        (newEventSlug, eventId) => (e) => {
            if (newEventSlug !== eventSlug) {
                const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === newEventSlug);

                clearBuildingData();

                // all the users that are on mobile, are redirected to lobby
                if (isLargeScreen) {
                    if (userRolesInEvent.roles.includes('organizer')) {
                        history.push(generatePath(RouterConfig.organizerDashboard.root, {eventSlug: newEventSlug}));
                    } else if (userRolesInEvent.roles.includes('exhibitor')) {
                        history.push(generatePath(RouterConfig.event.exhibitorDashboard, {eventSlug: newEventSlug}));
                    } else if (userRolesInEvent.roles.includes('exhibitorRep')) {
                        history.push(generatePath(RouterConfig.event.lobby, {eventSlug: newEventSlug}));
                    } else {
                        history.push(generatePath(RouterConfig.event.lobby, {eventSlug: newEventSlug}));
                    }
                } else {
                    history.push(generatePath(RouterConfig.event.lobby, {eventSlug: newEventSlug}));
                }

                setEventSlug(newEventSlug);
                setEventId(eventId);
                onCloseTopNav();
            }
        },
        [eventSlug, history, isLargeScreen, onCloseTopNav, setEventId, setEventSlug, user.eventRoles, clearBuildingData]
    );

    const redirectToCreateEvent = () => history.push(generatePath(RouterConfig.createEvent));

    const eventRolesList = user.eventRoles.reduceRight((arr, lastEventRole) => {
        // don't display events where the organizer is "not approved",
        // unless the current user is actually the organizer of the event
        if (
            lastEventRole.event.owner &&
            lastEventRole.event.owner.organizerStatus !== 'approved' &&
            !lastEventRole.roles.includes('organizer')
        )
            return arr;
        if (!arr.find((addedEventRole) => addedEventRole.event._id === lastEventRole.event._id))
            arr.push(lastEventRole);
        return arr;
    }, []);

    if (!isLargeScreen) {
        return (
            <MyEventsMobile
                eventRolesList={eventRolesList}
                redirectToCreateEvent={redirectToCreateEvent}
                redirectToEvent={redirectToEvent}
                user={user}
                eventId={eventId}
            />
        );
    }

    const positionProp = isRtlLanguage ? 'right' : 'left';

    const createEventAccess = IS_SCA_ENV
        ? ability.can(ROLE_ABILITIES_TYPES.COMMON.CREATE_ABILITY, ROLE_FEATURES_TYPES.EVENT_CREATE)
        : true;

    return (
        <MyAccountDropdownComponent style={{[positionProp]: 127}}>
            <div className="page-title">
                <h3>{translation?.myEventsDropdown.title}</h3>
            </div>
            <div className="myaccount-container" style={{minHeight: '180px', boxSizing: 'border-box'}}>
                <Scrollbars autoHeight autoHeightMax={360}>
                    <ul className="my-events-list">
                        {eventRolesList.map((eventRole) => {
                            return (
                                <li
                                    className={'row ' + (eventId === eventRole.event._id ? 'active' : '')}
                                    key={eventRole.event._id}
                                    onClick={redirectToEvent(eventRole.event.slug, eventRole.event._id)}
                                >
                                    <span style={{overflow: 'hidden'}}>{eventRole.event.name}</span>
                                </li>
                            );
                        })}
                        {createEventAccess && (
                            <li className="row" onClick={redirectToCreateEvent}>
                                <span>{`+ ${translation?.myEventsDropdown.createButtonDesktop}`}</span>
                            </li>
                        )}
                    </ul>
                </Scrollbars>
            </div>
        </MyAccountDropdownComponent>
    );
}
