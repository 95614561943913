export const GET_SESSIONS_START = 'SESSIONS/GET_SESSIONS_START';
export const GET_SESSIONS_SUCCESS = 'SESSIONS/GET_SESSIONS_SUCCESS';
export const GET_SESSIONS_FAIL = 'SESSIONS/GET_SESSIONS_FAIL';

export const CREATE_SESSIONS_START = 'SESSIONS/CREATE_SESSIONS_START';
export const CREATE_SESSIONS_SUCCESS = 'SESSIONS/CREATE_SESSIONS_SUCCESS';
export const CREATE_SESSIONS_FAIL = 'SESSIONS/CREATE_SESSIONS_FAIL';

export const UPDATE_SESSIONS_START = 'SESSIONS/UPDATE_SESSIONS_START';
export const UPDATE_SESSIONS_SUCCESS = 'SESSIONS/UPDATE_SESSIONS_SUCCESS';
export const UPDATE_SESSIONS_FAIL = 'SESSIONS/UPDATE_SESSIONS_FAIL';

export const DELETE_SESSIONS_START = 'SESSIONS/DELETE_SESSIONS_START';
export const DELETE_SESSIONS_SUCCESS = 'SESSIONS/DELETE_SESSIONS_SUCCESS';
export const DELETE_SESSIONS_FAIL = 'SESSIONS/DELETE_SESSIONS_FAIL';

export const GET_SESSION_DATA_START = 'SESSIONS/GET_SESSION_DATA_START';
export const GET_SESSION_DATA_SUCCESS = 'SESSIONS/GET_SESSION_DATA_SUCCESS';
export const GET_SESSION_DATA_FAIL = 'SESSIONS/GET_SESSION_DATA_FAIL';

export const GET_CHAT_MESSAGES_REQUEST = 'SESSIONS/GET_CHAT_MESSAGES_REQUEST';
export const GET_CHAT_MESSAGES_SUCCESS = 'SESSIONS/GET_CHAT_MESSAGES_SUCCESS';
export const GET_CHAT_MESSAGES_FAIL = 'SESSIONS/GET_CHAT_MESSAGES_FAIL';

export const SEND_CHAT_MESSAGE_START = 'SESSIONS/SEND_CHAT_MESSAGE_START';
export const SEND_CHAT_MESSAGE_SUCCESS = 'SESSIONS/SEND_CHAT_MESSAGE_SUCCESS';
export const SEND_CHAT_MESSAGE_FAIL = 'SESSIONS/SEND_CHAT_MESSAGE_FAIL';

export const RECIEVE_CHAT_MESSAGE = 'SESSIONS/RECIEVE_CHAT_MESSAGE';

export const VIDEO_CONFERENCE_LOADING = 'SESSIONS/VIDEO_CONFERENCE_LOADING';
export const CLOSE_VIDEO_CONFERENCE = 'SESSIONS/CLOSE_VIDEO_CONFERENCE';

export const VIDEO_CONFERENCE_FULL_SCREEN_TOGGLED = 'SESSIONS/VIDEO_CONFERENCE_FULL_SCREEN_TOGGLED';

export const SET_CLIENT = 'ETX_SESSION/SET_CLIENT';
export const SET_ROOM = 'ETX_SESSION/SET_ROOM';
export const JOIN_ROOM = 'ETX_SESSION/JOIN_ROOM';
export const SET_MEDIA_SESSION = 'ETX_SESSION/SET_MEDIA_SESSION';
export const CLOSE_CONSUMER = 'ETX_SESSION/CLOSE_CONSUMER';
export const PAUSE_CONSUMER = 'ETX_SESSION/PAUSE_CONSUMER';
export const RESUME_CONSUMER = 'ETX_SESSION/RESUME_CONSUMER';
export const ADD_CONSUMER = 'ETX_SESSION/ADD_CONSUMER';
export const ADD_SESSION = 'ETX_SESSION/ADD_SESSION';
export const REMOVE_SESSION = 'ETX_SESSION/REMOVE_SESSION';
export const CLEAR_CALL_STATE = 'ETX_SESSION/CLEAR_CALL_STATE';
export const TOGGLE_SELF_VIDEO = 'ETX_SESSION/TOGGLE_SELF_VIDEO';
export const TOGGLE_SELF_AUDIO = 'ETX_SESSION/TOGGLE_SELF_AUDIO';
export const TOGGLE_SCREEN_SHARE = 'ETX_SESSION/TOGGLE_SCREEN_SHARE';
export const CLEAR_SESSION_STATE = 'ETX_SESSION/CLEAR_SESSION_STATE';
export const SET_DISCONNECTED_STATE = 'ETX_SESSION/SET_DISCONNECTED_STATE';

export const GET_LOCAL_STREAM_REQUEST = 'ETX_SESSION/GET_LOCAL_STREAM_REQUEST';
export const GET_LOCAL_STREAM_SUCCESS = 'ETX_SESSION/GET_LOCAL_STREAM_SUCCESS';
export const GET_LOCAL_STREAM_FAIL = 'ETX_SESSION/GET_LOCAL_STREAM_FAIL';

export const GET_SCREEN_SHARE_STREAM_REQUEST = 'ETX_SESSION/GET_SCREEN_SHARE_STREAM_REQUEST';
export const GET_SCREEN_SHARE_STREAM_SUCCESS = 'ETX_SESSION/GET_SCREEN_SHARE_STREAM_SUCCESS';
export const GET_SCREEN_SHARE_STREAM_FAIL = 'ETX_SESSION/GET_SCREEN_SHARE_STREAM_FAIL';

export const SET_MEDIA_DEVICES_LIST = 'ETX_SESSION/SET_MEDIA_DEVICES_LIST';
export const JOIN_SESSION_DASHBOARD = 'ETX_SESSION/JOIN_SESSION_DASHBOARD';

export const CREATE_ROOM_REQUEST = 'ETX_SESSION/CREATE_ROOM_REQUEST';
export const CREATE_ROOM_SUCCESS = 'ETX_SESSION/CREATE_ROOM_SUCCESS';
export const CREATE_ROOM_FAIL = 'ETX_SESSION/CREATE_ROOM_FAIL';

export const NEW_DOMINANT_SPEAKER_ORDER = 'ETX_SESSION/NEW_DOMINANT_SPEAKER_ORDER';
