import * as actionTypes from '../../../actionTypes/organizerActionTypes/eventSetup';
import {Api} from '../../../../Api';
import {getEvent} from '../../eventActions';
import {updateEventData} from 'store/actions';

export const uploadCustomLogo =
    ({eventId, data, callback}) =>
    async (dispatch, getState) => {
        const {
            organizer: {
                eventSetup: {uploadCustomLogoLoading},
            },
        } = getState();

        dispatch(uploadCustomLogoRequest());

        try {
            await Api.request({
                url: `/event/${eventId}/custom-logo`,
                method: 'put',
                payload: data,
                loading: uploadCustomLogoLoading,
            });

            dispatch(uploadCustomLogoSuccess());
            callback();
        } catch (error) {
            dispatch(uploadCustomLogoFailed());
        }
    };

const uploadCustomLogoRequest = () => ({
    type: actionTypes.UPLOAD_CUSTOM_LOGO_REQUEST,
});

const uploadCustomLogoSuccess = () => ({
    type: actionTypes.UPLOAD_CUSTOM_LOGO_SUCCESS,
});

const uploadCustomLogoFailed = () => ({
    type: actionTypes.UPLOAD_CUSTOM_LOGO_FAILED,
});

export const uploadWelcomeVideo =
    ({data, callback}) =>
    async (dispatch, getState) => {
        const {
            organizer: {
                eventSetup: {uploadWelcomeVideoLoading},
            },
            event: {eventId},
        } = getState();

        dispatch(uploadWelcomeVideoRequest());

        try {
            const {eventObj} = await Api.request({
                url: `/event/${eventId}/welcome-video`,
                method: 'put',
                payload: data,
                loading: uploadWelcomeVideoLoading,
            });

            dispatch(uploadWelcomeVideoSuccess());
            dispatch(updateEventData(eventObj));
            callback();
        } catch (error) {
            dispatch(uploadWelcomeVideoFailed());
        }
    };

const uploadWelcomeVideoRequest = () => ({
    type: actionTypes.UPLOAD_WELCOME_VIDEO_REQUEST,
});

const uploadWelcomeVideoSuccess = () => ({
    type: actionTypes.UPLOAD_WELCOME_VIDEO_SUCCESS,
});

const uploadWelcomeVideoFailed = () => ({
    type: actionTypes.UPLOAD_WELCOME_VIDEO_FAILED,
});

export const uploadAuditoriumNote =
    ({eventId, data, callback}) =>
    async (dispatch, getState) => {
        const {
            organizer: {
                eventSetup: {uploadAuditoriumNoteLoading},
            },
        } = getState();

        dispatch(uploadAuditoriumNoteRequest());

        try {
            const {eventObj} = await Api.request({
                url: `/event/${eventId}/auditorium-note`,
                method: 'put',
                payload: data,
                loading: uploadAuditoriumNoteLoading,
            });

            dispatch(uploadAuditoriumNoteSuccess());
            dispatch(updateEventData(eventObj));
            callback();
        } catch (error) {
            dispatch(uploadAuditoriumNoteFailed());
        }
    };

const uploadAuditoriumNoteRequest = () => ({
    type: actionTypes.UPLOAD_AUDITORIUM_NOTE_REQUEST,
});

const uploadAuditoriumNoteSuccess = () => ({
    type: actionTypes.UPLOAD_AUDITORIUM_NOTE_SUCCESS,
});

const uploadAuditoriumNoteFailed = () => ({
    type: actionTypes.UPLOAD_AUDITORIUM_NOTE_FAILED,
});

export const uploadExhibitorsNote =
    ({eventId, data, callback}) =>
    async (dispatch, getState) => {
        const {
            organizer: {
                eventSetup: {uploadExhibitorsNoteLoading},
            },
        } = getState();

        dispatch(uploadExhibitorsNoteRequest());

        try {
            const {eventObj} = await Api.request({
                url: `/event/${eventId}/exhibitors-note`,
                method: 'put',
                payload: data,
                loading: uploadExhibitorsNoteLoading,
            });

            dispatch(uploadExhibitorsNoteSuccess());
            dispatch(updateEventData(eventObj));
            callback();
        } catch (error) {
            dispatch(uploadExhibitorsNoteFailed());
        }
    };

const uploadExhibitorsNoteRequest = () => ({
    type: actionTypes.UPLOAD_EXHIBITORS_NOTE_REQUEST,
});

const uploadExhibitorsNoteSuccess = () => ({
    type: actionTypes.UPLOAD_EXHIBITORS_NOTE_SUCCESS,
});

const uploadExhibitorsNoteFailed = () => ({
    type: actionTypes.UPLOAD_EXHIBITORS_NOTE_FAILED,
});

const updateCloseMessageStart = () => ({
    type: actionTypes.UPDATE_CLOSE_MESSAGE_START,
});

const updateCloseMessageSuccess = (data) => ({
    type: actionTypes.UPDATE_CLOSE_MESSAGE_SUCCESS,
    payload: data,
});

const updateCloseMessageFail = (error) => ({
    type: actionTypes.UPDATE_CLOSE_MESSAGE_FAIL,
    payload: error,
});

export const updateCloseMessage = (data) => {
    return async (dispatch, getState) => {
        dispatch(updateCloseMessageStart());
        try {
            const {
                event: {eventId},
            } = getState();
            const {eventObj} = await Api.request({
                method: 'post',
                url: `/event/${eventId}/edit-branding-translation`,
                payload: data,
            });

            dispatch(updateCloseMessageSuccess());
            dispatch(updateEventData(eventObj));
        } catch (error) {
            dispatch(updateCloseMessageFail());
        }
    };
};
// ----------------------------------------------------------------            CO_ORGANIZER

export const getCoOrganizersList = (eventId) => async (dispatch) => {
    dispatch({type: actionTypes.GET_CO_ORGANIZER_LIST_REQUEST});

    try {
        const {coOrganizers} = await Api.request({
            method: 'get',
            url: `/event/${eventId}/co-organizers`,
        });

        dispatch({
            type: actionTypes.GET_CO_ORGANIZER_LIST_SUCCESS,
            payload: coOrganizers,
        });
    } catch (error) {
        dispatch({type: actionTypes.GET_CO_ORGANIZER_LIST_FAILED});
    }
};

export const getCoOrganizersShortList = (eventId, text) => async (dispatch) => {
    dispatch({type: actionTypes.GET_CO_ORGANIZER_SHORT_LIST_REQUEST});

    try {
        const url = `/event/${eventId}/users-short-list${text ? `?search=${text}` : ''}`;
        const response = await Api.request({
            method: 'get',
            url,
        });

        dispatch({
            type: actionTypes.GET_CO_ORGANIZER_SHORT_LIST_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({type: actionTypes.GET_CO_ORGANIZER_SHORT_LIST_FAILED});
    }
};

export const toggleCoOrganizerAccess = (eventId, userId, name) => async (dispatch) => {
    dispatch({type: actionTypes.TOGGLE_CO_ORGANIZER_ACCESS_REQUEST});

    try {
        await Api.request({
            method: 'put',
            url: `/event/${eventId}/co-organizers/${userId}/toggle-access`,
            payload: {functional: name},
        });

        dispatch({type: actionTypes.TOGGLE_CO_ORGANIZER_ACCESS_SUCCESS});
        dispatch(getCoOrganizersList(eventId));
    } catch (error) {
        dispatch({type: actionTypes.TOGGLE_CO_ORGANIZER_ACCESS_FAILED});
    }
};

export const deleteCoOrganizer = (eventId, selectedUser) => async (dispatch) => {
    dispatch({type: actionTypes.DELETE_CO_ORGANIZER_REQUEST});

    try {
        await Api.request({
            method: 'delete',
            url: `/event/${eventId}/co-organizers/${selectedUser}`,
        });

        dispatch({type: actionTypes.DELETE_CO_ORGANIZER_SUCCESS});
        dispatch(getCoOrganizersList(eventId));
        dispatch(getCoOrganizersShortList(eventId));
    } catch (error) {
        dispatch({type: actionTypes.DELETE_CO_ORGANIZER_FAILED});
    }
};

export const toggleCoOrganizerSuspend = (eventId, selectedUser) => async (dispatch) => {
    dispatch({type: actionTypes.TOGGLE_CO_ORGANIZER_SUSPEND_REQUEST});

    try {
        await Api.request({
            method: 'put',
            url: `/event/${eventId}/co-organizers/${selectedUser}/toggle-suspend`,
        });

        dispatch({type: actionTypes.TOGGLE_CO_ORGANIZER_SUSPEND_SUCCESS});
        dispatch(getCoOrganizersList(eventId));
        dispatch(getCoOrganizersShortList(eventId));
    } catch (error) {
        dispatch({type: actionTypes.TOGGLE_CO_ORGANIZER_SUSPEND_FAILED});
    }
};

export const addCoOrganizer = (eventId, id, setError, callBack) => async (dispatch) => {
    dispatch({type: actionTypes.ADD_CO_ORGANIZER_REQUEST});

    try {
        await Api.request({
            method: 'post',
            url: `/event/${eventId}/co-organizers/${id}`,
        });
        dispatch({
            type: actionTypes.ADD_CO_ORGANIZER_SUCCESS,
            payload: 'SUCCESS',
        });
        callBack && callBack();
        dispatch(getCoOrganizersList(eventId));
        dispatch(getCoOrganizersShortList(eventId));
    } catch (error) {
        callBack && callBack();
        setError(error?.data?.error);
        dispatch({type: actionTypes.ADD_CO_ORGANIZER_FAILED});
    }
};

export const addCoOrganizerSuccess = (payload) => (dispatch) => {
    dispatch({
        type: actionTypes.ADD_CO_ORGANIZER_SUCCESS,
        payload: payload,
    });
};

// ----Reception----
const onAddEventRepresentativeStart = () => ({
    type: actionTypes.ADD_EVENT_REPRESENTATIVE_START,
});

const onAddEventRepresentativeSuccess = (survey = {}) => ({
    type: actionTypes.ADD_EVENT_REPRESENTATIVE_SUCCESS,
    payload: survey,
});

const onAddEventRepresentativeFail = (error) => ({
    type: actionTypes.ADD_EVENT_REPRESENTATIVE_FAIL,
    payload: error,
});

export const onAddEventRepresentative = (participantId, eventId, callback) => async (dispatch) => {
    dispatch(onAddEventRepresentativeStart());

    try {
        await Api.request({
            method: 'post',
            url: `/event/${eventId}/add-representative`,
            payload: {eventId, participantId},
        });
        callback && callback();
        dispatch(getEvent(eventId));
        dispatch(onAddEventRepresentativeSuccess());
    } catch (error) {
        dispatch(onAddEventRepresentativeFail(error));
    }
};

const onRemoveEventRepresentativeStart = () => ({
    type: actionTypes.REMOVE_EVENT_REPRESENTATIVE_START,
});

const onRemoveEventRepresentativeSuccess = (survey = {}) => ({
    type: actionTypes.REMOVE_EVENT_REPRESENTATIVE_SUCCESS,
    payload: survey,
});

const onRemoveEventRepresentativeFail = (error) => ({
    type: actionTypes.REMOVE_EVENT_REPRESENTATIVE_FAIL,
    payload: error,
});

export const onRemoveEventRepresentative = (participantId, eventId) => {
    return async (dispatch) => {
        dispatch(onRemoveEventRepresentativeStart());
        try {
            await Api.request({
                method: 'delete',
                url: `/event/${eventId}/remove-representative`,
                payload: {eventId, participantId},
            });

            dispatch(getEvent(eventId));
            dispatch(onRemoveEventRepresentativeSuccess());
        } catch (error) {
            dispatch(onRemoveEventRepresentativeFail(error));
        }
    };
};
