import React from 'react';
import ReactPlayer from 'react-player';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import {
    VolumeMute as VolumeMuteIcon,
    VolumeUp as VolumeUpIcon,
    Cached as CachedIcon,
    Fullscreen as FullscreenIcon,
    FullscreenExit as FullscreenExitIcon,
} from '@material-ui/icons';

import AuditoriumOverlayPlayer from 'Routes/components/AuditoriumOverlayPlayer';

class Video extends React.Component {
    fullscreenTimerId = 0;
    state = {
        fullscreen: false,
        showRefreshButton: false,
        muteVideo: true,
    };

    componentDidMount() {
        window.addEventListener('resize', this.setActiveElementsPositions);
        document.addEventListener('webkitfullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('mozfullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('fullscreenchange', this.toggleFullScreenEscKey, false);
        document.addEventListener('MSFullscreenChange', this.toggleFullScreenEscKey, false);
    }

    componentWillUnmount() {
        if (document.fullscreenElement) {
            document.exitFullscreen().then(this.exitFullscreen());
        }

        window.removeEventListener('resize', this.setActiveElementsPositions);

        document.removeEventListener('webkitfullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('mozfullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('fullscreenchange', this.toggleFullScreenEscKey, false);
        document.removeEventListener('MSFullscreenChange', this.toggleFullScreenEscKey, false);
    }

    toggleFullScreenEscKey = () => {
        let fullscreenElement =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullscreenElement ||
            document.msFullscreenElement;
        if (!fullscreenElement) {
            this.exitFullscreen();
        }
    };

    toggleFullScreen = () => {
        if (document.fullscreenElement) {
            document
                .exitFullscreen()
                .then(() => this.exitFullscreen())
                .catch((err) => console.error(err));
        } else {
            document.documentElement.requestFullscreen().then(() => this.startFullscreen());
        }
    };

    startFullscreen = () => {
        this.setState({fullscreen: true});
        this.hideVideoButtons();

        document.body.classList.add('fullscreen-video');
        window.addEventListener('mousemove', this.mouseMove);
    };

    exitFullscreen = () => {
        this.setState({fullscreen: false});
        this.showVideoButtons();
        clearTimeout(this.fullscreenTimerId);

        document.body.classList.remove('fullscreen-video');
        window.removeEventListener('mousemove', this.mouseMove);
    };

    videoHasEnded = () => {
        this.exitFullscreen();
        this.setState({videoEnded: true, muteVideo: false});
    };

    mouseMove = () => {
        clearTimeout(this.fullscreenTimerId);
        this.showVideoButtons();
        this.fullscreenTimerId = setTimeout(this.hideVideoButtons, 3000);
    };

    showVideoButtons = () => {
        if (document.getElementById('videoButtons')) {
            document.getElementById('videoButtons').classList.remove('hide');
        }
    };

    hideVideoButtons = () => {
        if (document.getElementById('videoButtons')) {
            document.getElementById('videoButtons').classList.add('hide');
        }
    };

    refreshPage = () => {
        window.location.reload(true);
    };

    isPlayingError = () => {
        this.setState({
            showRefreshButton: true,
        });
    };

    render() {
        const {exhibitor, translation} = this.props;

        return (
            <div className={'videoWrapper ' + (this.state.fullscreen ? 'fullscreen' : '')}>
                <div className="video-container">
                    <ReactPlayer
                        url={exhibitor.booth.video}
                        playing={true}
                        className="react-player"
                        controls={false}
                        width="100%"
                        height="100%"
                        volume={this.state.muteVideo ? 0 : 1}
                        muted={this.state.muteVideo}
                        onReady={this.handleVideoReady}
                        onContextMenu={(e) => e.preventDefault()}
                        onEnded={this.videoHasEnded}
                        onDuration={this.handleVideoDuration}
                        onError={this.isPlayingError}
                        loop={true}
                    />
                    <AuditoriumOverlayPlayer />
                </div>
                {this.state.showRefreshButton ? (
                    <div className="booth-video-error-container">
                        <p onClick={this.refreshPage}>{translation?.videoPlayer.videoNotPlayingError}</p>
                        <button onClick={this.refreshPage}>
                            <CachedIcon fontSize="inherit" />
                        </button>
                    </div>
                ) : null}
                <div className="video-actions-container" id="videoButtons">
                    <button onClick={this.toggleFullScreen}>
                        {this.state.fullscreen ? (
                            <Tooltip title={translation?.videoPlayer.tooltipMinimize}>
                                <FullscreenExitIcon fontSize="inherit" />
                            </Tooltip>
                        ) : (
                            <Tooltip title={translation?.videoPlayer.tooltipFullscreen}>
                                <FullscreenIcon fontSize="inherit" />
                            </Tooltip>
                        )}
                    </button>
                    <button onClick={() => this.setState({muteVideo: !this.state.muteVideo})}>
                        {this.state.muteVideo ? (
                            <Tooltip title={translation?.videoPlayer.tooltipUnmute}>
                                <VolumeMuteIcon fontSize="inherit" />
                            </Tooltip>
                        ) : (
                            <Tooltip title={translation?.videoPlayer.tooltipMute}>
                                <VolumeUpIcon fontSize="inherit" />
                            </Tooltip>
                        )}
                    </button>
                </div>
            </div>
        );
    }
}

export default Video;
