import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Scrollbars} from 'react-custom-scrollbars';
import classNames from 'classnames';

import BusinessWalletCategory from './components/BusinessWalletCategory/BusinessWalletCategory';

import {openWallet} from '../../../../../../../../../store/actions';
import {usePlatformTranslation} from '../../../../../../../../../services/hooks';
import {CATEGORIES} from '../../../../../../../../../constants/businessWallet';

import './BusinessWallet.scss';

export const BusinessWallet = ({activeTab}) => {
    const dispatch = useDispatch();
    let {contacts, receivedRequests: requests, sentRequests: pendings} = useSelector((state) => state.user.data);
    const [filter, setFilter] = useState('');
    const {businessWallet} = usePlatformTranslation();

    const filterByStatus = (users) => {
        return users.filter((user) => {
            if (!user.hasOwnProperty('status')) return user;
            return user.status === 'pending';
        });
    };

    const renderThumb = ({style, ...props}) => {
        const thumbStyle = {
            backgroundColor: `rgb(137,137,137)`,
            left: 0,
        };
        return <div className="grey-scroll" style={{...style, ...thumbStyle}} {...props} />;
    };

    let users;

    switch (activeTab) {
        case CATEGORIES.contacts:
            users = contacts;
            break;
        case CATEGORIES.requests:
            users = requests;
            break;
        case CATEGORIES.pendings:
            users = pendings;
            break;
        default:
            users = contacts;
    }

    contacts = filterByStatus(contacts);
    requests = filterByStatus(requests);
    pendings = filterByStatus(pendings);
    return (
        <>
            <div className="business-wallet">
                <div
                    className={classNames('business-wallet__tab', {'is-active': activeTab === CATEGORIES.contacts})}
                    onClick={() => {
                        dispatch(openWallet('wallet', CATEGORIES.contacts));
                    }}
                >
                    <span>{businessWallet.myContacts}</span>
                </div>
                <div
                    className={classNames('business-wallet__tab', {'is-active': activeTab === CATEGORIES.requests})}
                    onClick={() => {
                        dispatch(openWallet('wallet', CATEGORIES.requests));
                    }}
                >
                    <span>{businessWallet.requests}</span>
                    {!!requests.length && <span className="business-wallet__tab-count">{requests.length}</span>}
                </div>
                <div
                    className={classNames('business-wallet__tab', {'is-active': activeTab === CATEGORIES.pendings})}
                    onClick={() => {
                        dispatch(openWallet('wallet', CATEGORIES.pendings));
                    }}
                >
                    <span>{businessWallet.pendings}</span>
                </div>
            </div>
            <div className="wallet_search-container">
                <input
                    className="wallet_search"
                    placeholder={businessWallet.searchByName}
                    type="search"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
            </div>
            <Scrollbars className="scrollbar" renderThumbVertical={renderThumb} style={{width: '100%'}}>
                <div className="wallet_list">
                    <BusinessWalletCategory category={activeTab} filter={filter} users={users} />
                </div>
            </Scrollbars>
        </>
    );
};
