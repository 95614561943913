/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useLocation, generatePath} from 'react-router';
import {Link} from 'react-router-dom';

import {getEventRegistrationLink} from 'routes';
import {redirectToRegistrationOrContinueLogin} from 'services/utils/redirectToRegistrationOrContinueLogin';
import {whiteLabel} from 'config';
import {RouterConfig} from 'routerConfig';

export function HeaderButtons({
    translation,
    isLoginButtonShowed,
    isRegisterButtonShowed,
    eventSlug,
    loggedIn,
    onHandleLogout,
}) {
    const {pathname} = useLocation();

    return (
        <>
            {loggedIn ? (
                <div className={classNames('menu-links', whiteLabel)}>
                    <a
                        onClick={onHandleLogout}
                        className={classNames({
                            colorRed: pathname.includes('/welcome'),
                        })}
                    >
                        <span>{translation?.menu.logOut}</span>
                    </a>
                </div>
            ) : (
                <div className={classNames('menu-links', whiteLabel)}>
                    {isLoginButtonShowed && (
                        <Link
                            to={redirectToRegistrationOrContinueLogin(
                                generatePath(RouterConfig.event.login, {eventSlug})
                            )}
                        >
                            <span>{translation?.menu.logIn}</span>
                        </Link>
                    )}

                    {isRegisterButtonShowed && (
                        <Link to={getEventRegistrationLink(eventSlug)}>
                            <span>{translation?.menu.participantRegistration}</span>
                        </Link>
                    )}
                </div>
            )}
        </>
    );
}

HeaderButtons.propTypes = {
    translation: PropTypes.object,
    isLoginButtonShowed: PropTypes.bool,
    isRegisterButtonShowed: PropTypes.bool,
    eventSlug: PropTypes.string,
    loggedIn: PropTypes.bool,
    onHandleLogout: PropTypes.func,
};
