import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {useSelector} from 'react-redux';

import MobileBusinessCardMenu from './components/MobileBusinessCardMenu/MobileBusinessCardMenu';
import MobileBusinessCardEditing from './components/MobileBusinessCardEditing/MobileBusinessCardEditing';
import MobileBusinessCardColors from './components/MobileBusinessCardColors/MobileBusinessCardColors';
import MobileBusinessCardBranding from './components/MobileBusinessCardBranding/MobileBusinessCardBranding';
import MobileBusinessCardPreview from './components/MobileBusinessCardPreview/MobileBusinessCardPreview';

import {getBusinessCardLink} from '../../../../../../../../../../../routes';

const MobileBusinessCardScene = () => {
    const eventSlug = useSelector((state) => state.event?.eventSlug);

    return (
        <Switch>
            <Route path="/event/:eventSlug/my-account/business-card" component={MobileBusinessCardMenu} exact />
            <Route path="/event/:eventSlug/my-account/business-card/edit" component={MobileBusinessCardEditing} exact />
            <Route
                path="/event/:eventSlug/my-account/business-card/colors"
                component={MobileBusinessCardColors}
                exact
            />
            <Route
                path="/event/:eventSlug/my-account/business-card/branding"
                component={MobileBusinessCardBranding}
                exact
            />
            <Route
                path="/event/:eventSlug/my-account/business-card/preview"
                component={MobileBusinessCardPreview}
                exact
            />

            <Redirect to={getBusinessCardLink(eventSlug)} />
        </Switch>
    );
};

export default MobileBusinessCardScene;
