import {MenuItem} from '@material-ui/core';
import React, {forwardRef} from 'react';
import {usePlatformTranslation} from '../../../../../../../../../../../../../services/hooks';
import {useStyles} from '../MoreMenu.styles';
import clsx from 'clsx';
import IconArchive from '../../Icons/IconArchive';
// React.forwardRef((props, ref) =>
const MenuItemArhive = forwardRef(({onClick, className, isArchived, ...props}, ref) => {
    const classes = useStyles();
    const translation = usePlatformTranslation();

    return (
        <MenuItem ref={ref} className={clsx(classes.item, className)} onClick={onClick} {...props}>
            <IconArchive />
            <span className={classes.itemText}>
                {!isArchived ? translation.chatsDropdown.archive : translation.chatsDropdown.unarchive}
            </span>
        </MenuItem>
    );
});

export default MenuItemArhive;
