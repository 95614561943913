import {loadStates} from 'constants/data';
import * as actionTypes from '../actionTypes/allParticipantsForJobsActionTypes';

const initialState = {loadState: '', list: []};

const allParticipantsForJobsReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.GET_ALL_PARTICIPANTS_REQUEST: {
            return {
                ...state,
                loadState: loadStates.loading,
            };
        }
        case actionTypes.GET_ALL_PARTICIPANTS_SUCCESS: {
            return {
                ...state,
                list: payload,
                loadState: loadStates.ready,
            };
        }
        case actionTypes.GET_ALL_PARTICIPANTS_FAIL: {
            return {
                ...state,
                loadState: loadStates.ready,
            };
        }
        default:
            return state;
    }
};

export default allParticipantsForJobsReducer;
