import * as actionTypes from '../actionTypes/eventActionTypes';
import * as accessManagementActionTypes from '../actionTypes/organizerActionTypes/accessManagement';
import {sliceHelper, updateObject} from '../utilityStateFunction';
import {cloneDeep} from 'lodash';

const initialState = {
    loading: false,
    eventId: null,
    eventSlug: null,
    eventNotFound: false,
    profile: null,
    data: null,
    eventInfo: null,
    userRegistration: null,
    eventShortLink: null,
    eventRegPackage: null,
    userInitialEventId: null, // used for backwards browser navigation
    eventLanguages: [],
    sideNavigationDetails: {
        hasVideoWall: false,
        activeWallId: null,
        currentTimeSlotVideoWallId: null,
        highlightPostsForUsers: [],
        currentTimeSlotVideoWallSpeakers: [],
    },
    snackbar: {
        open: false,
        text: '',
    },
    onlineUsers: [],
    customLists: [],
    usersList: [],
    registrantsList: null,
    initialCustomLogo: null,
    eventParticipantFilters: null,
    eventParticipantFiltersEventId: null,
    eventExhibitorFilters: null,
    eventExhibitorFiltersEventId: null,
    onDemandVideos: [],
};

const reducer = (state = initialState, action) => {
    let newState = cloneDeep(state);
    switch (action.type) {
        case actionTypes.SET_EVENT_ID:
            return updateObject(state, {eventId: action.payload});
        case actionTypes.SET_EVENT_SLUG:
            return updateObject(state, {eventSlug: action.payload});

        case actionTypes.UPDATE_ACTIVE_CAMPAIGN_INTEGRATION_START:
        case actionTypes.GET_ON_DEMANT_VIDEOS_START:
        case actionTypes.SET_ENABELED_EVENT_MATCHMAKING_START:
        case actionTypes.CREATE_EVENT_START:
        case actionTypes.GET_EVENT_START:
        case actionTypes.GET_EVENT_INFO_START:
        case actionTypes.UPDATE_EVENT_START:
        case actionTypes.LIKE_POST_START:
        case actionTypes.UNLIKE_POST_START:
        case actionTypes.ADD_SPEAKER_START:
        case actionTypes.REMOVE_SPEAKER_START:
        case actionTypes.DELETE_EVENT_REQUEST:
        case actionTypes.GET_EVENT_PROFILE_REQUEST:
        case actionTypes.GET_EVENT_FILTERS_START:
        case accessManagementActionTypes.ADD_ACCESS_GROUP_START:
        case accessManagementActionTypes.UPDATE_ACCESS_GROUP_START:
        case accessManagementActionTypes.DUPLICATE_ACCESS_GROUP_START:
        case accessManagementActionTypes.DELETE_ACCESS_GROUP_START:
        case accessManagementActionTypes.SET_DEFAULT_ACCESS_GROUP_START:
        case accessManagementActionTypes.ADD_ACCESS_PACKAGE_START:
        case accessManagementActionTypes.UPDATE_ACCESS_PACKAGE_START:
        case accessManagementActionTypes.DELETE_ACCESS_PACKAGE_START:
        case accessManagementActionTypes.ADD_CUSTOM_LIST_START:
        case accessManagementActionTypes.GET_CUSTOM_LISTS_START:
        case accessManagementActionTypes.DELETE_CUSTOM_LIST_START:
        case accessManagementActionTypes.GET_REGISTRANTS_LIST_START:
        case accessManagementActionTypes.TOGGLE_BADGE_FIELD_START:
        case accessManagementActionTypes.UPLOAD_BADGE_IMAGE_START:
            return updateObject(state, {loading: true});

        case actionTypes.CREATE_EVENT_SUCCESS:
            return updateObject(state, {
                loading: false,
                eventId: action.payload._id,
                eventSlug: action.payload.slug,
            });
        case accessManagementActionTypes.GET_CUSTOM_LISTS_SUCCESS:
            return updateObject(state, {
                loading: false,
                customLists: action.payload,
            });
        case accessManagementActionTypes.GET_REGISTRANTS_LIST_SUCCESS:
            return updateObject(state, {
                loading: false,
                registrantsList: action.payload,
            });
        case actionTypes.GET_EVENT_PROFILE_SUCCESS:
            return updateObject(state, {
                loading: false,
                profile: action.payload,
            });

        case actionTypes.UPDATE_ACTIVE_CAMPAIGN_INTEGRATION_FAIL:
        case actionTypes.GET_ON_DEMANT_VIDEOS_FAIL:
        case actionTypes.SET_ENABELED_EVENT_MATCHMAKING_FAIL:
        case actionTypes.CREATE_EVENT_FAIL:
        case actionTypes.SET_EVENT_ID_BY_SLUG_FAIL:
        case actionTypes.GET_EVENT_FAIL:
        case actionTypes.UPDATE_EVENT_FAIL:
        case actionTypes.GET_EVENT_INFO_FAIL:
        case actionTypes.LIKE_POST_FAIL:
        case actionTypes.UNLIKE_POST_FAIL:
        case actionTypes.ADD_SPEAKER_FAIL:
        case actionTypes.REMOVE_SPEAKER_FAIL:
        case actionTypes.DELETE_EVENT_FAIL:
        case actionTypes.GET_EVENT_PROFILE_FAIL:
        case actionTypes.GET_EVENT_FILTERS_FAIL:
        case accessManagementActionTypes.ADD_ACCESS_GROUP_FAIL:
        case accessManagementActionTypes.UPDATE_ACCESS_GROUP_FAIL:
        case accessManagementActionTypes.DUPLICATE_ACCESS_GROUP_FAIL:
        case accessManagementActionTypes.DELETE_ACCESS_GROUP_FAIL:
        case accessManagementActionTypes.SET_DEFAULT_ACCESS_GROUP_FAIL:
        case accessManagementActionTypes.ADD_ACCESS_PACKAGE_FAIL:
        case accessManagementActionTypes.UPDATE_ACCESS_PACKAGE_FAIL:
        case accessManagementActionTypes.DELETE_ACCESS_PACKAGE_FAIL:
        case accessManagementActionTypes.ADD_CUSTOM_LIST_SUCCESS:
        case accessManagementActionTypes.ADD_CUSTOM_LIST_FAIL:
        case accessManagementActionTypes.GET_CUSTOM_LISTS_FAIL:
        case accessManagementActionTypes.DELETE_CUSTOM_LIST_SUCCESS:
        case accessManagementActionTypes.DELETE_CUSTOM_LIST_FAIL:
        case accessManagementActionTypes.GET_REGISTRANTS_LIST_FAIL:
        case accessManagementActionTypes.TOGGLE_BADGE_FIELD_FAIL:
        case accessManagementActionTypes.UPLOAD_BADGE_IMAGE_FAIL:
            return updateObject(state, {loading: false});
        case actionTypes.GET_ONLINE_USERS_SUCCESS:
            return updateObject(state, {
                onlineUsers: action.payload,
            });
        case actionTypes.UPDATE_ONLINE_USERS: {
            const {id, status} = action.payload;
            const onlineUsers =
                status === 'online'
                    ? [...state.onlineUsers, {userId: id}]
                    : state.onlineUsers.filter(({userId}) => userId !== id);

            return updateObject(state, {
                onlineUsers,
            });
        }
        case actionTypes.GET_EVENT_USERS_LIST_SUCCESS:
            return updateObject(state, {usersList: action.payload});
        case actionTypes.SET_EVENT_ID_BY_SLUG_START:
            return updateObject(state, {loading: true, eventNotFound: false});
        case actionTypes.SET_EVENT_ID_BY_SLUG_SUCCESS:
            const {eventId, eventSlug} = action.payload;

            return updateObject(state, {
                loading: false,
                eventId,
                eventSlug,
            });
        case actionTypes.SET_EVENT_NOT_FOUND:
            return updateObject(state, {eventNotFound: true});
        case actionTypes.GET_EVENT_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.payload,
                eventId: action.payload._id,
            });
        case actionTypes.UPDATE_EVENT_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: action.payload,
                eventId: action.payload._id,
            });

        case actionTypes.UPDATE_EVENT_DATA:
            return updateObject(state, {
                data: action.payload,
            });
        case accessManagementActionTypes.TOGGLE_BADGE_FIELD_SUCCESS:
            const {
                payload: {badgeCustomizationFields},
            } = action;

            return updateObject(state, {
                loading: false,
                data: updateObject(state.data, {badgeCustomizationFields}),
                eventId: action.payload._id,
            });
        case accessManagementActionTypes.UPLOAD_BADGE_IMAGE_SUCCESS:
            const {
                payload: {badgeCustomizationImages},
            } = action;

            return updateObject(state, {
                loading: false,
                data: updateObject(state.data, {badgeCustomizationImages}),
                eventId: action.payload._id,
            });
        case actionTypes.GET_EVENT_INFO_SUCCESS:
            return updateObject(state, {
                loading: false,
                eventInfo: action.payload,
                eventId: action.payload._id,
                eventLanguages: action.payload.brandingTranslations.map(({_id, language}) => ({_id, language})),
                initialCustomLogo: action.payload.customLogo,
            });
        case actionTypes.GET_EVENT_USER_REGISTRATION_SUCCESS:
            return updateObject(state, {
                loading: false,
                userRegistration: action.payload,
            });
        case actionTypes.SET_EVENT_SHORT_LINK:
            return updateObject(state, {
                loading: false,
                eventShortLink: action.payload,
            });
        case actionTypes.SET_EVENT_REG_PACKAGE:
            return updateObject(state, {
                loading: false,
                eventRegPackage: action.payload,
            });
        case actionTypes.LIKE_POST_SUCCESS:
            return updateObject(state, {
                loading: false,
            });
        case actionTypes.UNLIKE_POST_SUCCESS:
            return updateObject(state, {
                loading: false,
            });
        case actionTypes.ADD_SPEAKER_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: {...state.data, speakers: [...state.data.speakers, action.payload]},
            });
        case actionTypes.REMOVE_SPEAKER_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: {...state.data, speakers: state.data.speakers.filter((speaker) => speaker !== action.payload)},
            });
        case accessManagementActionTypes.ADD_ACCESS_PACKAGE_SUCCESS:
            newState.data.accessPackages = [...newState.data.accessPackages, action.payload];
            newState.loading = false;
            return updateObject(state, newState);
        case accessManagementActionTypes.UPDATE_ACCESS_PACKAGE_SUCCESS: {
            let updatedPackageIndex = newState.data.accessPackages.findIndex(
                (accessPackage) => accessPackage._id === action.payload._id
            );
            if (updatedPackageIndex !== -1) {
                newState.data.accessPackages[updatedPackageIndex] = action.payload;
            }
            newState.loading = false;
            return updateObject(state, newState);
        }
        case accessManagementActionTypes.DELETE_ACCESS_PACKAGE_SUCCESS:
            return updateObject(state, {
                loading: false,
            });
        case actionTypes.SET_ACTIVE_WALL:
            return updateObject(state, {
                sideNavigationDetails: {
                    ...state.sideNavigationDetails,
                    activeWallId: action.payload.wallId,
                    highlightPostsForUsers: action.payload.highlightUsers,
                    canModerateChat: action.payload.canModerateChat,
                },
            });
        case actionTypes.CLOSE_ACTIVE_WALL:
            return updateObject(state, {
                sideNavigationDetails: initialState.sideNavigationDetails,
            });

        case actionTypes.SIDE_NAV_HAS_VIDEO_WALL:
            return updateObject(state, {
                sideNavigationDetails: {...state.sideNavigationDetails, hasVideoWall: action.payload},
            });
        case actionTypes.SET_TIME_SLOT_VIDEO_WALL_ID:
            return updateObject(state, {
                sideNavigationDetails: {
                    ...state.sideNavigationDetails,
                    currentTimeSlotVideoWallId: action.payload.wallId,
                    currentTimeSlotVideoWallSpeakers: action.payload.highlightUsers,
                },
            });

        case actionTypes.NEW_FLASH_NOTIFICATION:
            return updateObject(state, {
                snackbar: {
                    open: true,
                    text: action.payload.notification.text,
                },
            });
        case actionTypes.CLOSE_FLASH_NOTIFICATION:
            return updateObject(state, {
                snackbar: {
                    open: false,
                    text: '',
                },
            });

        case actionTypes.GET_CUSTOM_USERS_START:
            return updateObject(state, {loading: true});
        case actionTypes.GET_CUSTOM_USERS_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: {...state.data, customUsers: action.payload},
            });
        case actionTypes.GET_CUSTOM_USERS_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.ADD_CUSTOM_USERS_START:
            return updateObject(state, {loading: true});
        case actionTypes.ADD_CUSTOM_USERS_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: {...state.data, customUsers: [...state.data.customUsers, action.payload]},
            });
        case actionTypes.ADD_CUSTOM_USERS_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.DELETE_CUSTOM_USERS_START:
            return updateObject(state, {loading: true});
        case actionTypes.DELETE_CUSTOM_USERS_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: {
                    ...state.data,
                    customUsers: state.data.customUsers.filter((list) => list._id !== action.payload.listId),
                },
            });

        case actionTypes.DELETE_CUSTOM_USERS_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.PARTICIPANT_REGISTRATION_ACCESS_CHANGED:
            const {hideParticipantsLink} = action.payload;

            return updateObject(state, {
                eventInfo: updateObject(state.eventInfo, {
                    hideParticipantsLink,
                }),
            });
        case actionTypes.USERS_LOGIN_ACCESS_CHANGED:
            const {hideLoginLink} = action.payload;

            return updateObject(state, {
                eventInfo: updateObject(state.eventInfo, {
                    hideLoginLink,
                }),
            });
        case actionTypes.DELETE_EVENT_SUCCESS:
            return initialState;
        case actionTypes.SET_USER_INITIAL_EVENT_ID:
            return updateObject(state, {
                userInitialEventId: action.payload,
            });
        case actionTypes.SET_UPDATED_AUDTITORIUM_IN_EVENT: {
            const {payload} = action;
            const {data} = state;

            const itemIndex = data.auditoriums.findIndex(({_id}) => _id === payload._id);
            const newAudtitoriumsArray = sliceHelper(data.auditoriums, itemIndex, payload);

            return updateObject(state, {
                data: {...data, auditoriums: newAudtitoriumsArray},
            });
        }

        case actionTypes.SET_UPDATED_AUDTITORIUM_IN_EVENT_HUB: {
            const {payload} = action;
            const {data} = state;

            const buildingIndex = data.buildings.findIndex((building) => building._id === payload.building);
            let building = {...data.buildings[buildingIndex]};

            const auditoriumIndex = building.auditoriums.findIndex((el) => el._id === payload._id);
            const newAudtitoriumsArray = sliceHelper(building.auditoriums, auditoriumIndex, payload);

            building.auditoriums = newAudtitoriumsArray;

            const newBuildingsArray = sliceHelper(data.buildings, buildingIndex, building);

            return updateObject(state, {
                data: {...data, buildings: newBuildingsArray},
            });
        }
        case actionTypes.GET_EVENT_FILTERS_PARTICIPANT_SUCCESS:
            return updateObject(state, {
                loading: false,
                eventParticipantFilters: action.payload.filter,
                eventParticipantFiltersEventId: action.payload.eventId,
            });
        case actionTypes.GET_EVENT_FILTERS_EXHIBITOR_SUCCESS:
            return updateObject(state, {
                loading: false,
                eventExhibitorFilters: action.payload,
                eventExhibitorFiltersEventId: state.eventId,
            });

        case actionTypes.SOCET_SET_ENABELED_EVENT_MATCHMAKING:
        case actionTypes.SET_ENABELED_EVENT_MATCHMAKING_SUCCESS: {
            const {payload} = action;
            return updateObject(state, {
                data: {...state.data, matchingEnabled: payload},
                loading: false,
            });
        }

        case actionTypes.SOCET_SET_EVENT_HAS_MATCHMAKING: {
            return updateObject(state, {
                data: {...state.data, hasMatching: action.payload},
                loading: false,
            });
        }

        case actionTypes.UPDATE_ACTIVE_CAMPAIGN_INTEGRATION_SUCCESS: {
            return updateObject(state, {
                data: {...state.data, activeCampaignIntegration: action.payload},
                loading: false,
            });
        }

        case actionTypes.GET_ON_DEMANT_VIDEOS_SUCCESS: {
            return updateObject(state, {
                onDemandVideos: action.payload,
                loading: false,
            });
        }

        case actionTypes.ADD_ON_DEMANT_VIDEO: {
            const isIncluded = state.onDemandVideos.find((timeslot) => timeslot._id === action.payload._id);
            if (isIncluded) return state;

            return updateObject(state, {
                onDemandVideos: [...state.onDemandVideos, action.payload],
            });
        }

        case actionTypes.RESET_ACTIVE_WALL_ID: {
            return updateObject(state, {
                sideNavigationDetails: {...state.sideNavigationDetails, activeWallId: null},
            });
        }

        default:
            return state;
    }
};

export default reducer;
