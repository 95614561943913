import React from 'react';

import Svg from '../Svg/Svg';

import './Layout.scss';

const IconLayout = () => (
    <Svg className="IconLayout">
        <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm-5 14H4v-4h11v4zm0-5H4V9h11v4zm5 5h-4V9h4v9z" />
    </Svg>
);

export default IconLayout;
