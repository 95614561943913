import React, {useState, useRef, useEffect} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {isIOS} from 'react-device-detect';
import {useSelector} from 'react-redux';
import {IconButton, makeStyles, Tooltip, withStyles} from '@material-ui/core';
import {ReactComponent as InfoIcon} from '../../../Images/info_icon.svg';
import {getIsRtlLanguage} from 'store/selectors';

const AUTO_CLOSE_DELAY = 7000;

const useStyles = makeStyles((theme) => ({
    tooltip: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: 10,
        paddingRight: 10,
    },
    icon: {
        lineHeight: 0,
        '&.isRtl': {
            marginRight: 0,
            marginLeft: 10,
        },
        marginRight: 10,
    },
    text: {
        color: theme.palette.text.primary,
        fontSize: 14,
        fontWeight: 500,
        maxWidth: 400,
        [theme.breakpoints.down(theme.breakpoints.values.laptop)]: {
            maxWidth: '80%',
        },
    },
}));

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.text.primary,
        maxWidth: 'none',
    },
    arrow: {
        color: theme.palette.primary.main,
    },
}))(Tooltip);

const InfoTooltip = ({
    text,
    placement,
    withInfoIcon,
    CustomIcon,
    InnerCustomIcon,
    iconClassName,
    isHover,
    tooltipProps,
    timeOut,
    timeOutEndCallback,
    children,
    isDisabled,
    disableHoverListener,
}) => {
    const classes = useStyles();
    const isRtlLanguage = useSelector(getIsRtlLanguage);
    const [isOpen, setIsOpen] = useState(false);

    const ref = useRef(null);

    const handleClickOpen = () => {
        setIsOpen((open) => !open);
        if (isIOS) ref.current.focus();
    };

    const handleOpenOnHover = () => {
        if (!isHover || isOpen) return;
        setIsOpen(true);
    };

    const handleCloseOnHover = () => {
        if (!isHover || !isOpen) return;
        setIsOpen(false);
    };

    useEffect(() => {
        // this is fix, to close tooltip if it lost hover and close method wasn't triggered
        if (isHover && isOpen && ref.current && !timeOut) {
            if (!ref.current.matches(':hover')) {
                handleCloseOnHover();
            }
        }
    });

    useEffect(() => {
        if (!timeOut) return;
        const openTimer = setTimeout(() => {
            setIsOpen(true);
        }, timeOut);

        const closeTimer = setTimeout(() => {
            setIsOpen(false);
            timeOutEndCallback && timeOutEndCallback();
        }, AUTO_CLOSE_DELAY + timeOut);

        return () => {
            clearTimeout(openTimer);
            clearTimeout(closeTimer);
        };
    }, [timeOut, timeOutEndCallback]);

    return (
        <LightTooltip
            open={isOpen && !isDisabled}
            onOpen={handleOpenOnHover}
            onClose={handleCloseOnHover}
            disableFocusListener
            title={
                <div className={classes.tooltip}>
                    {withInfoIcon &&
                        (InnerCustomIcon ? (
                            InnerCustomIcon
                        ) : (
                            <InfoIcon
                                className={classNames(classes.icon, 'primary-fill-svg', {isRtl: isRtlLanguage})}
                            />
                        ))}
                    <p className={classes.text}>{text}</p>
                </div>
            }
            disableHoverListener={!isHover}
            arrow
            placement={placement}
            {...tooltipProps}
            children={
                children ? (
                    <div ref={ref} className={classes.test}>
                        {children}
                    </div>
                ) : (
                    <IconButton
                        ref={ref}
                        onClick={handleClickOpen}
                        className={iconClassName}
                        data-testid="tooltip-button"
                    >
                        {CustomIcon ? CustomIcon : <InfoIcon className="primary-fill-svg" />}
                    </IconButton>
                )
            }
        />
    );
};

export default InfoTooltip;

InfoTooltip.propTypes = {
    text: PropTypes.string,
    placement: PropTypes.oneOf([
        'bottom-start',
        'bottom',
        'left-end',
        'left-start',
        'left',
        'right-end',
        'right-start',
        'right',
        'top-end',
        'top-start',
        'top',
    ]),
    withInfoIcon: PropTypes.bool,
    CustomIcon: PropTypes.node,
    InnerCustomIcon: PropTypes.node,
    iconClassName: PropTypes.string,
    isHover: PropTypes.bool,
    tooltipProps: PropTypes.object,
    timeOut: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    isDisabled: PropTypes.bool,
    children: PropTypes.node,
    timeOutEndCallback: PropTypes.func,
};

InfoTooltip.defaultProps = {
    placement: 'top',
    withInfoIcon: false,
    isHover: true,
    isDisabled: false,
};
