import {connect} from 'react-redux';
import HomepageEvent from './HomepageEvent';
import * as actions from '../../../store/actions';
import * as selectors from '../../../store/selectors';

export default connect(
    (state) => ({
        isMobile: selectors.getIsMobile(state),
        eventInfo: selectors.getEventInfo(state),
        eventSlug: selectors.getEventSlug(state),
        loadingEvent: selectors.getEventLoading(state),
        eventId: selectors.getEventId(state),
        user: selectors.getUserData(state),
        userLoading: selectors.getUserLoading(state),
        languages: selectors.getLanguages(state),
        eventLanguage: selectors.getEventLanguage(state),
        translation: selectors.getEventTranslation(state),
        eventShortLink: selectors.getEventShortLink(state),
        eventRegPackage: selectors.getEventRegPackage(state),
    }),
    {
        onSetEventPublicMenu: actions.setEventPublicMenu,
        setEventIdBySlug: actions.setEventIdBySlug,
        getEventInfo: actions.getEventInfo,
        setEventSlug: actions.setEventSlug,
        onGetEventUserRegistration: actions.getEventUserRegistration,
        getSocialsPromotion: actions.getSocialsPromotion,
    }
)(HomepageEvent);
