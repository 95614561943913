import React from 'react';
import {usePlatformTranslation} from '../../../../../../../../../../../../services/hooks';
import ColoredScrollbars from '../../../../../../../../../../ColoredScrollbars/ColoredScrollbars';
import RemovePerson from '../../../../../../../../../../../../Images/person_remove.svg';

const UsersToAddList = ({usersToAdd = [], handleRemoveUser = () => {}}) => {
    const translation = usePlatformTranslation();

    return (
        <div className="members-chat-wrapper">
            <h5>{translation?.chatsDropdown.usersToAdd}</h5>
            <ColoredScrollbars>
                {!usersToAdd.length ? (
                    <p>{translation?.chatsDropdown.noUsersToAdd}</p>
                ) : (
                    <ul className="members-list-event">
                        {usersToAdd.map((userToAdd) => {
                            return (
                                <React.Fragment key={userToAdd._id}>
                                    <li>
                                        <div
                                            className="user-avatar"
                                            style={{
                                                backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${userToAdd.avatarSmall}')`,
                                            }}
                                        />
                                        <div className="chat-member-details">
                                            <p>
                                                {userToAdd.first} {userToAdd.last}
                                            </p>
                                            {userToAdd.company !== '' ? <span>{userToAdd.company}</span> : null}
                                        </div>
                                        <button
                                            className="add-member member-control"
                                            onClick={() => handleRemoveUser(userToAdd._id)}
                                        >
                                            <img src={RemovePerson} alt="Remove Member" width="24" height="24" />
                                            <p>{translation?.generalText.remove}</p>
                                        </button>
                                    </li>
                                    <div className="members-list-event__user-wrapper-separator" />
                                </React.Fragment>
                            );
                        })}
                    </ul>
                )}
            </ColoredScrollbars>
        </div>
    );
};

export default React.memo(UsersToAddList);
