import React, {useEffect, useState} from 'react';
import {Divider, List, ListItem, ListItemIcon, ListItemText} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AppliedJobsMobileTable from './components/AppliedJobsMobileTable/AppliedJobsMobileTable';
import {useDispatch, useSelector} from 'react-redux';
import {getEventsWithAppliedJobs} from '../../../../../../../store/actions/eventsWithApplicationsActions';
import {useStyles} from './AppliedJobsSwitchMobile.styles';

const AppliedJobsSwitchMobile = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userId = useSelector((state) => state.user.data._id);
    const {eventList} = useSelector((state) => state.eventsWithApplications);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const backLink = useSelector((state) => state.account.backLink);

    useEffect(() => {
        dispatch(getEventsWithAppliedJobs(userId));
    }, [userId, dispatch]);

    useEffect(() => {
        if (!backLink.name) setSelectedEvent(null);
    }, [backLink]);

    if (eventList?.length === 0) {
        return null;
    }

    if (selectedEvent) {
        return <AppliedJobsMobileTable userId={userId} event={selectedEvent} />;
    }

    return (
        <div className="applied-jobs-switch-mobile">
            <List className={classes.list}>
                {eventList.map((event, i) => (
                    <React.Fragment key={i}>
                        <ListItem
                            onClick={() => setSelectedEvent(event)}
                            className={classes.listItem}
                            key={event.title + i}
                            button
                        >
                            <ListItemText classes={{primary: classes.listText}} primary={event.title} />
                            <ListItemIcon className={classes.listIcon}>
                                <ArrowForwardIosIcon />
                            </ListItemIcon>
                        </ListItem>
                        <Divider />
                    </React.Fragment>
                ))}
            </List>
        </div>
    );
};

export default AppliedJobsSwitchMobile;
