import React from 'react';
import {TextValidator} from 'react-material-ui-form-validator';
import {MenuItem} from '@material-ui/core';

import countryList from './country-list.json';
import {usePlatformTranslation} from '../../../../../services/hooks';

const countryNameList = countryList.map(({name}) => name);

const getCountryCodeByName = (countryName, countryList) => countryList.find(({name}) => name === countryName).code;

const getCountryNameByCode = (countryCode, countryList) => countryList.find(({code}) => code === countryCode)?.name;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const RegisterCountrySelect = ({value, onChange}) => {
    const translation = usePlatformTranslation();
    return (
        <div className="RegisterCountrySelect">
            <TextValidator
                id="filled-select-currency"
                className="field-container"
                label={translation?.paidRegistration?.countryText}
                value={getCountryNameByCode(value, countryList)}
                variant="filled"
                onChange={(e) => onChange(getCountryCodeByName(e.target.value, countryList))}
                select
                fullWidth
                SelectProps={{
                    MenuProps,
                }}
                validators={['required']}
                errorMessages={[translation?.errors.requiredCountry]}
            >
                {countryNameList.map((name) => (
                    <MenuItem key={name} value={name}>
                        {name}
                    </MenuItem>
                ))}
            </TextValidator>
        </div>
    );
};

export default RegisterCountrySelect;
