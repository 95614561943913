export const customFormType = {
    survey: 'survey',
    participantCustomFields: 'participant-custom-fields',
    exhibitorCustomFields: 'exhibitor-custom-fields',
};

export const customFormFieldTypes = {
    text: 'text',
    select: 'select',
    radio: 'radio',
    linearScale: 'linear-scale',
    checkbox: 'checkbox',
    checkboxInput: 'checkboxInput',
    file: 'file',
    textarea: 'text-area',
};

export const customFormTypeOptions = [
    {value: customFormFieldTypes.text, label: 'Text input'},
    {value: customFormFieldTypes.select, label: 'Dropdown'},
    {value: customFormFieldTypes.radio, label: 'Radio buttons'},
    {value: customFormFieldTypes.linearScale, label: 'Linear scale'},
    {value: customFormFieldTypes.checkbox, label: 'Checkbox'},
    {value: customFormFieldTypes.checkboxInput, label: 'Checkbox input'},
    {value: customFormFieldTypes.file, label: 'File'},
];

export const withOptionsTypes = [
    customFormFieldTypes.select,
    customFormFieldTypes.radio,
    customFormFieldTypes.checkbox,
    customFormFieldTypes.checkboxInput,
];

export const customFormActionTypes = {
    save: 'save',
    update: 'update',
    hidden: 'hidden',
    required: 'isRequired',
    filtered: 'isFiltered',
    delete: 'delete',
    textValidationSwitch: 'textValidationSwitch',
    textValidationOption: 'textValidationOption',
    label: 'label',
    fileValidation: 'fileValidation',
    fieldLabelType: 'fieldType',
    validation: 'validation',
};

export const switchCustomFormActionTypes = [
    customFormActionTypes.hidden,
    customFormActionTypes.required,
    customFormActionTypes.filtered,
    customFormActionTypes.textValidationSwitch,
    customFormActionTypes.fileValidation,
];

export const textValidationActionTypes = [
    customFormActionTypes.textValidationSwitch,
    customFormActionTypes.textValidationOption,
];

export const fileValidationTypes = [
    {value: '.png', label: 'PNG'},
    {value: '.jpeg', label: 'JPEG'},
    {value: '.pdf', label: 'PDF'},
    {value: '.docx', label: 'WORD'},
    {value: '.mov', label: 'MOV'},
    {value: '.mp4', label: 'MP4'},
    {value: '.avi', label: 'AVI'},
    {value: '.ogg', label: 'OGG'},
];

export const initialField = {
    _id: null,
    label: '',
    fieldType: customFormFieldTypes.text, // TODO: pass type
    isFormCreation: true,
    options: [],
    hidden: false,
    isRequired: false,
    isFiltered: false,
    validation: {
        label: false,
    },
    fileValidations: [],
    textValidation: {},
};

export const initialFieldOption = {
    _id: null,
    value: '',
    isFormCreation: true,
};

export const customFormContent = {
    addBtnText: 'Add custom field',
    fieldTitle: 'Custom field',
    fieldLabel: 'Question',
    withDeleteBtn: true,
    withShowBtn: false,
    withRequiredBtn: true,
    withFilteringBtn: true,
    withValidationTextInput: true,
    withTranslation: true,
    saveUrl: '',
    updateUrl: '',
    deleteUrl: '',
};
