import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './AccountGrid.scss';

const AccountGrid = ({menu, pagesScene, hideMenu}) => (
    <section className="AccountGrid">
        <div className="AccountGrid__menu">{menu}</div>

        <div className={classNames('AccountGrid__pagesScene', {hideMenu})}>{pagesScene}</div>
    </section>
);

AccountGrid.propTypes = {
    hideMenu: PropTypes.bool,
    menu: PropTypes.node,
    pagesScene: PropTypes.node,
};

export default AccountGrid;
