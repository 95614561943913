import React from 'react';
import {whiteLabel} from '../../../../../config';
import {mobileRectangle} from '../../config/config.homepagePlatform';

const SubRectangleText = (props) => {
    return (
        <div>
            <span
                style={{
                    backgroundImage: `url(${mobileRectangle[whiteLabel].sub}`,
                }}
                className="purple-rectangle-background"
            >
                {props.children}
            </span>
        </div>
    );
};

export default SubRectangleText;
