import React from 'react';

const TranslateIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.07 11.13L12.56 13.67L12.53 13.64C10.59 11.9 8.36 10.66 6 9.93L6 7L4 7L4 14L2 14L2 16L4 16L4 23L5.99 23L5.99 11.83C7.92 12.5 9.75 13.56 11.35 15C10.32 15.93 9.19 16.7 8 17.31L8 19.31C9.63 18.58 11.17 17.58 12.56 16.33L17.58 21.42L19 20L14 15L17.11 11.89L15.07 11.13ZM10 5.5L10 7.5L22 12L22 10L19 8.88L19 4.13L22 3L22 1L10 5.5ZM17 8.12L12.67 6.5L17 4.88L17 8.12Z"
            fill="#9C231F"
        />
    </svg>
);

export default TranslateIcon;
