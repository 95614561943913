import * as usersStatusesActionTypes from '../actionTypes/usersStatusesActionTypes';
import {userStatuses as userStatusesApi} from 'Api';
import {userStatuses} from 'constants/data';
import {getRelatedUsersStatuses, normalizeUserStatusesList} from 'services/helpers';

export const resetUsersStatusesState = () => ({
    type: usersStatusesActionTypes.RESET_USERS_STATUSES_STATE,
});

export const setUsersStatuses = (statuses) => ({
    type: usersStatusesActionTypes.SET_USERS_STATUSES,
    payload: {statuses},
});

export const requestAllUsersStatuses = (relatedUserIdList) => async (dispatch) => {
    try {
        const {requestOnlineUsers, requestBusyUsers} = userStatusesApi();

        const [onlineData, busyData] = await Promise.all([requestOnlineUsers(), requestBusyUsers()]);

        const statuses = getRelatedUsersStatuses(
            {
                ...normalizeUserStatusesList(onlineData.users, userStatuses.online),
                ...normalizeUserStatusesList(busyData.users, userStatuses.busy),
            },
            relatedUserIdList
        );
        dispatch(setUsersStatuses(statuses));
    } catch (error) {
        console.error(error);
    }
};
