import React, {useState, useEffect, useCallback, useMemo} from 'react';
import classNames from 'classnames';

import CloseTopNavButton from '../CloseTopNavButton/CloseTopNavButton';
import MyAccountDropdownComponent from '../MyAccountDropdownComponent';
import {BusinessCardDropdownLink} from './components/MyAccountDropdownLink/MyAccountDropdownLink';

import {disconnectFromNotifications, disconnectFromEvent} from 'Api/socketApi';

import LogoutIcon from 'Images/log-out.svg';
import {ReactComponent as LogOutIconMobile} from 'Images/log-out-mobile.svg';

import './MyAccount.scss';

export function MyAccount({
    languages,
    user,
    onLogoutUser,
    closeTopNavigation,
    history,
    eventSlug,
    mobile,
    isTablet,
    offsetLeft,
    offsetWidth,
    isRtlLanguage,
    eventId
}) {
    const [translation, setTranslation] = useState(null);
    const setLanguagesData = useCallback(() => {
        const translation = languages.translations[languages.platformLanguage];
        setTranslation(translation);
    }, [languages.platformLanguage, languages.translations]);

    useEffect(() => {
        setLanguagesData();
    }, [setLanguagesData]);

    const logoutUser = useCallback(() => {
        // if user logged out we should also disconnect him from notifications
        disconnectFromEvent({userId: user._id, eventId});
        disconnectFromNotifications({userId: user._id});
        onLogoutUser();
    }, [eventId, onLogoutUser, user._id]);

    const onClickMyAccount = useCallback(
        (e) => {
            e.preventDefault();
            closeTopNavigation();
            history.push(`/event/${eventSlug}/my-account`);
        },
        [closeTopNavigation, eventSlug, history]
    );

    const dropdownPosition = useMemo(
        () => (isRtlLanguage ? offsetLeft : offsetLeft - 323 + offsetWidth),
        [isRtlLanguage, offsetLeft, offsetWidth]
    );

    return (
        <MyAccountDropdownComponent
            className={mobile || isTablet ? 'my-account-mobile' : ''}
            style={{left: dropdownPosition}}
        >
            <CloseTopNavButton />
            <div className="page-title">
                <h3>{translation?.myAccountDropdown.title}</h3>
            </div>
            <div className={classNames('myaccount-container', {reversed: isRtlLanguage})}>
                <div className="row">
                    <div className="profile-image-container">
                        <img
                            src={process.env.REACT_APP_AVATAR_FOLDER + user.avatarSmall}
                            alt={user.avatar}
                            className="avatar"
                        />
                    </div>
                    <div>
                        <span>
                            {user.first} {user.last}
                        </span>
                        <a className="row__link" href="/" onClick={onClickMyAccount}>
                            {translation?.myAccountDropdown.viewProfileButton}
                        </a>
                    </div>
                </div>

                <div className="row">
                    <BusinessCardDropdownLink
                        onClick={closeTopNavigation}
                        eventSlug={eventSlug}
                        isDesktop={!mobile && !isTablet}
                    />
                </div>

                <div className="row">
                    {mobile || isTablet ? (
                        <div onClick={logoutUser} className="profile-image-container">
                            <p>{translation?.myAccountDropdown.logoutButton}</p>
                            <LogOutIconMobile className="secondary-fill-svg" />
                        </div>
                    ) : (
                        <>
                            <div className="profile-image-container">
                                <img src={LogoutIcon} alt={user.avatar} width="24" height="24" />
                            </div>
                            <div>
                                <span onClick={logoutUser}>{translation?.myAccountDropdown.logoutButton}</span>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </MyAccountDropdownComponent>
    );
}
