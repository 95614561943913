import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles({
    container: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        flexWrap: 'wrap',
    },
    videoWrapper: {
        display: 'flex',
        width: '100%',
        height: 252,
        maxHeight: '100%',
        justifyContent: 'center',
        overflow: 'hidden',
        position: 'relative',
        cursor: 'pointer',
        backgroundColor: '#000',
        '&.isFullScreen': {
            height: '100%',
        },
    },
    video: {
        width: '100%',
        objectFit: 'cover',
        backgroundColor: '#000',
        '&.isVerticalVideo': {
            width: 'unset',
        },
    },
    videoOff: {
        border: '1px solid #fff',
    },
    videoOffPlate: {
        position: 'absolute',
        display: 'flex',
        zIndex: 100,
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        backgroundColor: '#000',
    },
    hidden: {
        display: 'none',
    },
});
