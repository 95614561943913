import {connect} from 'react-redux';
import GroupChat from './GroupChat';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export default connect(
    (state) => ({
        user: selectors.getUserData(state),
        activeChatRoomId: selectors.getUserActiveChatRoomId(state),
        activeChatData: selectors.getUserActiveChatData(state),
        event: selectors.getEventData(state),
        eventId: selectors.getEventId(state),
        loading: selectors.getUserLoading(state),
        videoConference: selectors.getActiveCallTypeData(state),
        languages: selectors.getLanguages(state),
        eventChatsTab: selectors.getUserEventChats(state),
        isMobile: selectors.getIsMobile(state),
    }),
    {
        onCloseActiveChat: actions.closeActiveChat,
        onResetActiveChatId: actions.resetActiveChatId,
        onUpdateActiveChatData: actions.updateActiveChatData,
        onSetActiveChatData: actions.setActiveChatData,
        onOpenPrivateChat: actions.openPrivateChat,
        onCreatePrivateChat: actions.createPrivateChat,
        newGroupChatMessage: actions.newGroupChatMessage,
        readMessagesForGroupChat: actions.readMessagesForGroupChat,
        changePlaybacks: actions.changePlaybacks,
        openVideoConference: actions.openVideoConference,
        closeVideoConference: actions.closeVideoConference,
        stopCalling: actions.stopCalling,
        onUpdateActiveChatTab: actions.updateActiveChatTab,
        getEventUsersList: actions.getEventUsersList,
        setActiveVideoConferenceType: actions.setActiveVideoConferenceType,
        getGroupChatMessages: actions.getGroupChatMessages,
        removeUserFromGroupChat: actions.removeUserFromGroupChat,
        leaveGroupChat: actions.leaveGroupChat,
    }
)(GroupChat);
