import React from 'react';
import {useParams} from 'react-router-dom';
import {useSelector} from 'react-redux';
import classNames from 'classnames';

import ZoomSessionLinksModule from '../ZoomSessionLinksModule';
import ZoomStreamControls from './components/ZoomStreamControls';
import SessionDashboardBlock from './components/SessionDashboardBlock';
import AllocateResourcesDialog from '../AllocateResourcesDialog';
import StreamingVideoContainer from '../StreamingVideoContainer/StreamingVideoContainer';

import {getTimeslotData} from 'store/selectors';
import {useZoomSessionDashboard} from 'services/hooks/organizer/sessions/useZoomSessionDashboard';
import {useModal} from 'services/hooks';

import {
    ZOOM_SESSION_STREAM_URLS_DISABLED_MESSAGE,
    ZOOM_SESSION_NO_ACTIVE_STREAM_MESSAGE,
    ZOOM_SESSION_HOLDED_RESOURCES_MESSAGE,
    ZOOM_SESSION_STREAM_FINISHED_MESSAGE,
    ALLOCATE_RESOURCES_STATUSES,
} from 'constants/organizer/sessions';

import './ZoomSessionDashboard.scss';
import Thumbnail from './components/Thumbnail';

function ZoomSessionDashboard() {
    const session = useSelector(getTimeslotData);

    const {auditoriumId, sessionId} = useParams();
    const [resourcesModal, toggleResourcesModal] = useModal(false);

    useZoomSessionDashboard();

    let waitingStreamTitle;

    switch (true) {
        case session?.resourcesStatus === ALLOCATE_RESOURCES_STATUSES.holded:
        case session?.resourcesStatus === ALLOCATE_RESOURCES_STATUSES.holding:
            waitingStreamTitle = ZOOM_SESSION_HOLDED_RESOURCES_MESSAGE;
            break;
        case session?.resourcesStatus === ALLOCATE_RESOURCES_STATUSES.allocated && session?.streamFinished:
            waitingStreamTitle = ZOOM_SESSION_STREAM_FINISHED_MESSAGE;
            break;
        default:
            waitingStreamTitle = ZOOM_SESSION_NO_ACTIVE_STREAM_MESSAGE;
    }

    const isStreamingVideoAvailable = (session?.streamPublished && !session?.streamFinished) || session?.streamStarted;

    return (
        <div className="zoom_session_dashboard_page">
            <div className="zoom_session_dashboard_page-container">
                <div
                    className={classNames('zoom_session_dashboard_page-column', {
                        isNotActive: !!session && !session?.streamPublished,
                    })}
                >
                    <SessionDashboardBlock title={session?.title} fullWidth bordered />
                    <div className={classNames('videoWrapper', {centerAlign: isStreamingVideoAvailable})}>
                        {isStreamingVideoAvailable ? (
                            <StreamingVideoContainer
                                sessionId={sessionId}
                                sessionKey={session?.sessionKey}
                                gatewayName={session?.gatewayName}
                                className="video-container"
                            />
                        ) : (
                            <p className="waiting-stream-title">{waitingStreamTitle}</p>
                        )}
                    </div>
                    <ZoomStreamControls openAllocateResourcesDialog={toggleResourcesModal} />
                </div>
                <div className="zoom_session_dashboard_page-column">
                    <ZoomSessionLinksModule
                        sessionId={sessionId}
                        sessionKey={session?.sessionKey}
                        disabled={
                            session?.resourcesStatus === ALLOCATE_RESOURCES_STATUSES.holded ||
                            session?.resourcesStatus === ALLOCATE_RESOURCES_STATUSES.holding
                        }
                        disabledTextMessage={ZOOM_SESSION_STREAM_URLS_DISABLED_MESSAGE[session?.resourcesStatus]}
                    />
                    <Thumbnail auditoriumId={auditoriumId} sessionId={sessionId} />
                </div>
            </div>
            {resourcesModal && (
                <AllocateResourcesDialog
                    open={resourcesModal}
                    closeDialog={toggleResourcesModal}
                    auditoriumId={auditoriumId}
                />
            )}
        </div>
    );
}

export default ZoomSessionDashboard;
