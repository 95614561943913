import React from 'react';
import classNames from 'classnames';
import {MenuItem, Menu} from '@material-ui/core';
import {MoreVert as MoreVertIcon} from '@material-ui/icons';

import LikeIcon from 'Images/like.svg';
import {ReactComponent as PinIcon} from 'Images/pin.svg';
import {ReactComponent as UnpinIcon} from 'Images/unpin.svg';
import CommentsIcon from 'Images/comments.svg';
import {ReactComponent as CommentsIconActive} from 'Images/comment_active.svg';
import {ReactComponent as DeleteIcon} from 'Images/rubbish.svg';
import {ReactComponent as LikedIcon} from 'Images/like_active.svg';
import {ReactComponent as Plus} from 'Images/plus.svg';

import ModarateActions from './components/ModarateActions';

import {getTimeAgoString, urlifyString} from 'services/utils';
import {AbilityContext} from 'permissionsConfig';
import {POST_STATUSES} from 'constants/shared';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

import './SinglePost.scss';

class SinglePost extends React.PureComponent {
    state = {
        comment: '',
        showComments: false,
        anchorEl: null,
    };

    componentDidMount() {
        this.updateListRow(this.props.rowIndex);
        setTimeout(() => {
            // the only fix for size recalculating for first item in the list
            this.updateListRow(0);
        }, 1000);
    }

    componentWillUnmount() {
        this.state.showComments && this.updateListRow(this.props.rowIndex);
    }

    updateListRow = (rowIndex) => {
        try {
            const {cache, listRef, measureVirtualListSize} = this.props;
            cache && cache.clear(rowIndex);
            listRef && this.props.listRef.recomputeRowHeights(rowIndex);
            measureVirtualListSize && measureVirtualListSize();
        } catch (error) {}
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    onAddComment = () => {
        if (this.state.comment.trim().length) {
            this.props.addComment(this.state.comment);
            this.setState({comment: ''});
        }
    };

    handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.onAddComment();
        }
    };

    checkCanDeletePost = () => {
        const {user, sideNavigationDetails, isVideoWall, post} = this.props;

        const ability = this.context;
        const isOrganizerAccess = ability.can(
            ROLE_ABILITIES_TYPES.COMMON.DELETE_ABILITY,
            ROLE_FEATURES_TYPES.WALLS_SINGLE_POST
        );

        if (isVideoWall) {
            return (user._id === post.user._id && !post.isPinned) || isOrganizerAccess;
        } else {
            if (sideNavigationDetails.canModerateChat) {
                return (
                    (user._id === post.user._id && !post.isPinned) ||
                    sideNavigationDetails.highlightPostsForUsers.includes(user._id) ||
                    sideNavigationDetails.canModerateChat.includes(user._id) ||
                    isOrganizerAccess
                );
            } else {
                if (user) {
                    return (
                        (user._id === post.user._id && !post.isPinned) ||
                        sideNavigationDetails.highlightPostsForUsers?.includes(user._id) ||
                        isOrganizerAccess
                    );
                }
            }
        }
    };

    checkCanPinPost = () => {
        const {wallType, post} = this.props;
        const ability = this.context;
        const isPostPendding = post.status === POST_STATUSES.pending;

        const boothWallAccess = ability.can(
            ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY,
            ROLE_FEATURES_TYPES.BOOTH_WALL_PIN_MESSAGE
        );
        const organizerAccess = ability.can(
            ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY,
            ROLE_FEATURES_TYPES.WALLS_PIN_MESSAGE
        );

        return wallType === 'boothWall' ? boothWallAccess : organizerAccess && !isPostPendding;
    };

    handleDeletePost = () => {
        const {deleteWallPost, post} = this.props;

        deleteWallPost({postId: post._id, callback: () => this.handleCloseMenu()});
    };

    handlePinPost = () => {
        const {pinWallPost, post} = this.props;
        const pinActionType = post.isPinned ? 'unpin' : 'pin';

        pinWallPost({postId: post._id, pinActionType, callback: () => this.handleCloseMenu()});
    };

    checkCanDeleteComment = (comment) => {
        const {user, sideNavigationDetails} = this.props;
        const ability = this.context;
        const isOrganizerAccess = ability.can(
            ROLE_ABILITIES_TYPES.COMMON.DELETE_ABILITY,
            ROLE_FEATURES_TYPES.WALLS_COMMENT
        );

        if (!user) {
            return false;
        }

        if (this.props.sideNavigationDetails.canModerateChat) {
            return (
                user._id === comment.user._id ||
                sideNavigationDetails.highlightPostsForUsers.includes(user.id) ||
                sideNavigationDetails.canModerateChat.includes(user._id) ||
                isOrganizerAccess
            );
        } else {
            return user._id === comment.user._id || isOrganizerAccess;
        }
    };

    handleDeleteComment = (commentId) => () => {
        const {deleteWallPostComment, post} = this.props;

        deleteWallPostComment({postId: post._id, commentId, callback: () => this.handleCloseMenu()});
    };

    handleApprovePost = () => {
        const {activeWallId, post, onApproveWallPost} = this.props;
        onApproveWallPost({wallId: activeWallId, postId: post._id});
    };
    handleApproveComment = (commentId) => () => {
        const {onApproveWallComment, activeWallId, post} = this.props;
        onApproveWallComment({wallId: activeWallId, postId: post._id, commentId});
    };

    handleOpenMenu = (e) => {
        this.setState({anchorEl: e.currentTarget});
    };

    handleCloseMenu = (e) => {
        this.setState({anchorEl: null});
    };

    render() {
        const {
            isMobile,
            loggedIn,
            user,
            post,
            languages,
            hightlighPost,
            isRtlLanguage,
            allreadyCommented,
            allreadyLiked,
            unlikePost,
            likePost,
            userAvatarSmall,
            wallClosed,
            isModerator,
            rowIndex,
        } = this.props;
        const {createdAt, isPinned, comments, likes} = post;
        const timeAgoString = getTimeAgoString(createdAt, languages.platformLanguage);
        const isCanDelete = this.checkCanDeletePost();
        const isCanPin = this.checkCanPinPost();
        const text = urlifyString(post.text);
        const {showComments, comment, anchorEl} = this.state;

        const pendingCommentsCount = comments.reduce((count, comment) => {
            if (comment.status === POST_STATUSES.pending) return count + 1;
            return 0;
        }, 0);

        const isPostPending = post.status === POST_STATUSES.pending;
        const showPostPendingMessage = isModerator ? !isPostPending && pendingCommentsCount > 0 : isPostPending;
        const pendingMessage = isModerator
            ? `Comments pending approval (${pendingCommentsCount > 10 ? '10+' : pendingCommentsCount})`
            : 'Pending approval';

        return (
            <div
                className={classNames('single-post-container', {
                    highlight: hightlighPost,
                    isRtl: isRtlLanguage,
                    isPinned: isPinned,
                })}
            >
                <div className="post-header">
                    <div
                        className="user-avatar"
                        style={{
                            backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${post.user.avatarSmall}')`,
                        }}
                    />
                    <div className="user-name">
                        <p className="user-name__name">
                            {post.user.first} {post.user.last}
                        </p>
                        <p className="user-name__time-ago">{timeAgoString}</p>
                    </div>
                    <div className="postActions">
                        {isCanDelete && (
                            <button className="action-button">
                                <MoreVertIcon onClick={this.handleOpenMenu} className={'dotIcon'} />
                                <Menu
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={Boolean(anchorEl)}
                                    onClose={this.handleCloseMenu}
                                    classes={{paper: 'menuWrapper'}}
                                >
                                    {isCanPin ? (
                                        <MenuItem className={'menuItem'} onClick={this.handlePinPost}>
                                            {isPinned ? (
                                                <UnpinIcon className="wallIcon pin" />
                                            ) : (
                                                <PinIcon className="wallIcon pin" />
                                            )}
                                            {!isPinned
                                                ? languages?.translations?.[languages?.platformLanguage]?.generalText
                                                      ?.pin || 'PIN'
                                                : languages?.translations?.[languages?.platformLanguage]?.generalText
                                                      ?.unpin || 'UNPIN'}
                                        </MenuItem>
                                    ) : null}
                                    {!isPinned ? (
                                        <MenuItem onClick={this.handleDeletePost} className={'menuItem'}>
                                            <DeleteIcon className="wallIcon delete" />
                                            {languages?.translations?.[languages?.platformLanguage]?.generalText
                                                ?.delete || 'DELETE'}
                                        </MenuItem>
                                    ) : null}
                                </Menu>
                            </button>
                        )}
                        <button
                            disabled={isPostPending}
                            className="comments-button"
                            aria-label="Like"
                            onClick={() => {
                                this.setState({showComments: !showComments}, () => this.updateListRow(rowIndex));
                            }}
                        >
                            {allreadyCommented ? (
                                <CommentsIconActive className="primary-fill-svg" />
                            ) : (
                                <img src={CommentsIcon} alt="comments" />
                            )}
                            <div>
                                <span>{comments.length}</span>
                            </div>
                        </button>
                        {loggedIn && (
                            <button
                                className="like-button"
                                aria-label="Like"
                                onClick={allreadyLiked ? unlikePost : likePost}
                                disabled={isPostPending}
                            >
                                {allreadyLiked ? (
                                    <LikedIcon className="wallIcon primary-fill-svg" />
                                ) : (
                                    <img src={LikeIcon} className="wallIcon" alt="Like Button" />
                                )}

                                <div>
                                    <span>{likes.length}</span>
                                </div>
                            </button>
                        )}
                    </div>
                </div>
                <div className="post-container">
                    <div>{text}</div>
                    <ModarateActions
                        show={isPostPending || showPostPendingMessage}
                        showPendingMessage={showPostPendingMessage}
                        showModarateBtn={isPostPending && isModerator}
                        pendingMessage={pendingMessage}
                        btnTitle="APPROVE POST"
                        onApprove={this.handleApprovePost}
                    />
                </div>
                {showComments && (
                    <div className="post-comments">
                        {comments.length ? (
                            <ul className="comments-list-container">
                                {comments.map((comment) => {
                                    const timeAgoString = getTimeAgoString(
                                        comment.createdAt,
                                        languages.platformLanguage
                                    );
                                    const canDeleteComment = this.checkCanDeleteComment(comment);
                                    const isComentPending = comment.status === POST_STATUSES.pending;

                                    return (
                                        <li key={comment._id} className={'single-comment-container'}>
                                            <div className="comment-header">
                                                <div
                                                    className="user-avatar"
                                                    style={{
                                                        backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${comment.user.avatarSmall}')`,
                                                    }}
                                                />
                                                <div className="comment-container">
                                                    <span className="comment-container__user-name">{`${comment.user.first} ${comment.user.last}`}</span>
                                                    <p className="time-ago">
                                                        {timeAgoString}
                                                        {!isPinned && canDeleteComment && (
                                                            <button
                                                                className="delete-button"
                                                                aria-label="Delete"
                                                                onClick={this.handleDeleteComment(comment._id)}
                                                            >
                                                                <DeleteIcon className="wallIcon delete" />
                                                            </button>
                                                        )}
                                                    </p>
                                                </div>
                                            </div>
                                            <span className="single-comment-container__coment-text">
                                                {comment.text}
                                            </span>
                                            <ModarateActions
                                                show={isComentPending}
                                                showPendingMessage={isComentPending && !isModerator}
                                                showModarateBtn={isComentPending && isModerator}
                                                pendingMessage={'Pending approval'}
                                                btnTitle="APPROVE COMMENT"
                                                onApprove={this.handleApproveComment(comment._id)}
                                            />
                                        </li>
                                    );
                                })}
                            </ul>
                        ) : null}
                    </div>
                )}
                {showComments && user && !wallClosed && (
                    <div className="new-comment-container">
                        <div
                            className="user-avatar"
                            style={{
                                backgroundImage: `url(' ${process.env.REACT_APP_AVATAR_FOLDER}${userAvatarSmall}')`,
                            }}
                        />
                        <input
                            className="newComment"
                            placeholder={
                                languages?.translations?.[languages?.platformLanguage]?.wall?.newCommentInput ||
                                'Comment'
                            }
                            value={comment}
                            onChange={this.handleChange('comment')}
                            onKeyDown={this.handleKeyDown}
                        />
                        {isMobile && (
                            <button
                                className="add-new-comment"
                                onClick={this.onAddComment}
                                disabled={!comment.trim().length}
                            >
                                <Plus class="primary-fill-svg" />
                            </button>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

SinglePost.contextType = AbilityContext;

export default SinglePost;
