import {createSelector} from 'reselect';

const wall = (state) => state.wall;

export const getWallPosts = createSelector(wall, (state) => state.posts);

export const getWallPostsPage = createSelector(wall, (state) => state.page);

export const getWallPostsTotal = createSelector(wall, (state) => state.total);

export const getWallType = createSelector(wall, (state) => state.wallType);

export const getWallPostsLoading = createSelector(wall, (state) => state.loadingPosts);
