import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';

import {Select, MenuItem, InputLabel, FormControl, DialogActions, DialogContent} from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';
import {ValidatorForm} from 'react-material-ui-form-validator';
import {CustomDialog, CustomDialogButton, CustomDialogContentText, CustomDialogTitle} from '../CustomDialogComponents';
import {EXPECTED_PARTICIPANTS_OPTIONS, ALLOCATE_RESOURCES_DIALOG_TEXTS} from 'constants/organizer/sessions';

import {useStyles} from './AllocateResourcesDialog.styles';

export function AllocateResourcesDialog({open, closeDialog, loading, sessionStreamAllocateResources, auditoriumId}) {
    const classes = useStyles();
    const formRef = useRef();
    const [participants, setParticipants] = useState('');
    const [disabled, setDisabled] = useState(true);

    const handleChangeParticipants = ({target: {value}}) => setParticipants(value);

    const handleAllocationResources = () =>
        sessionStreamAllocateResources({
            auditoriumId,
            viewersCount: participants,
            callback: () => closeDialog(),
        });

    useEffect(() => {
        if (formRef.current) formRef.current.isFormValid().then((isValid) => setDisabled(!isValid));
    }, [participants]);

    return (
        <CustomDialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title" withRtl>
            <CustomDialogTitle disableTypography>Allocate Resources</CustomDialogTitle>
            <DialogContent>
                <CustomDialogContentText className={classes.descriptionText}>
                    {ALLOCATE_RESOURCES_DIALOG_TEXTS.allocate}
                </CustomDialogContentText>
                <CustomDialogContentText className={classes.warningText}>
                    <WarningIcon className={classes.warningIcon} />{' '}
                    <span>{ALLOCATE_RESOURCES_DIALOG_TEXTS.warningMessage}</span>
                </CustomDialogContentText>
                <ValidatorForm className={classes.form} ref={formRef} onSubmit={handleAllocationResources}>
                    <FormControl className={classes.participantsSelect}>
                        <InputLabel id="participantsLabel" variant="outlined">
                            Participants number
                        </InputLabel>
                        <Select
                            onChange={handleChangeParticipants}
                            value={participants}
                            variant="outlined"
                            labelId="participantsLabel"
                            labelWidth={170}
                        >
                            {EXPECTED_PARTICIPANTS_OPTIONS.map(({value, label}) => (
                                <MenuItem value={value} key={value}>
                                    {label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </ValidatorForm>
            </DialogContent>
            <DialogActions>
                <CustomDialogButton onClick={closeDialog} color="secondary">
                    CANCEL
                </CustomDialogButton>
                <CustomDialogButton onClick={handleAllocationResources} color="primary" disabled={loading || disabled}>
                    ALLOCATE RESOURCES
                </CustomDialogButton>
            </DialogActions>
        </CustomDialog>
    );
}

AllocateResourcesDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    closeDialog: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    sessionStreamAllocateResources: PropTypes.func.isRequired,
    auditoriumId: PropTypes.string,
};
