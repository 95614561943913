import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        boxShadow: '0px -4px 6px rgba(0, 0, 0, 0.07), 0px 0px 7px rgba(0, 0, 0, 0.12), 0px 5px 12px rgba(0, 0, 0, 0.2)',
        padding: '16px 22px',
        marginTop: 20,
    },
    button: {
        height: 54,
        fontSize: 14,
        lineHeight: '120%',
        textTransform: 'uppercase',
        margin: '0px 10px',
        boxShadow: 'none',
    },
    buttonOutlined: {
        border: `1px solid ${theme.palette.grey.A150}`,
    },
}));
