import {connect} from 'react-redux';
import {StreamControls} from './StreamControls';
import {sessionStreamStart, sessionStreamStop, toggleSelfAudio} from 'store/actions';
import {getTimeslotData, getIsAudioOff, getMediaSession} from 'store/selectors';

export default connect(
    (state) => ({
        timeslot: getTimeslotData(state),
        isAudioOff: getIsAudioOff(state),
        mediaSession: getMediaSession(state),
    }),
    {
        sessionStreamStart,
        sessionStreamStop,
        toggleSelfAudio,
    }
)(StreamControls);
