import React from 'react';
import {getPrivateChatId} from '../../../../../../../../../../../services/utils';
import OpenChatIcon from '../../../../../../../../../../../Images/open-chat.svg';
import AcceptRequestIcon from '../../../../../../../../../../../Images/add-button.svg';
import {connect} from 'react-redux';
import * as actions from '../../../../../../../../../../../store/actions';
import {NotificationManager} from 'react-notifications';
import {ReactComponent as BusinessCardIcon} from '../../../../../../../../../../../Images/business_card.svg';
import {ReactComponent as BcRequestIcon} from '../../../../../../../../../../../Images/bc_request.svg';

import ScheduleIcon from '@material-ui/icons/Schedule';
import CancelIcon from '@material-ui/icons/Cancel';

class SingleUserContact extends React.PureComponent {
    handleOpenPrivateChat = () => {
        const {user} = this.props;

        const targetUserId = user._id;
        const privateChatId = getPrivateChatId(this.props.privateChats, targetUserId);

        if (privateChatId) {
            this.props.onOpenPrivateChat(privateChatId);
        } else {
            if (targetUserId !== this.props.userLoggedIn._id && !this.props.loading) {
                this.props.onCreatePrivateChat(targetUserId);
            }
        }
    };

    handleAcceptFriendRequest = () => {
        this.props.onAcceptContactRequest(this.props.requestId);
    };

    handleDeclineFriendRequest = () => {
        this.props.onDeclineContactRequest(this.props.requestId);
    };

    // handleSendFriendConnection = () => {
    //     this.props.onSendContactRequest(this.props.user._id);
    // };

    handleCancelBusinessCardRequest = () => {
        this.props.onCancelBusinessCardRequest(this.props.requestId);
    };

    handleOpenBusinessCard = () => {
        this.props.onOpenWallet('card', '', this.props.user._id);
    };

    handleNewMeeting = () => {
        NotificationManager.info('TBA');
    };

    render() {
        const {user} = this.props;
        const NEW_MEETING_IS_NOT_AVAILIBLE_YET = false;

        return (
            <div className="wallet_user">
                <div
                    className={
                        'user-container wallet_user-section ' + (this.props.isOnline ? 'is-online' : 'is-offline')
                    }
                >
                    <div className="d-flex">
                        <div
                            className="participant-avatar"
                            style={{
                                backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${user.avatarSmall}')`,
                            }}
                        ></div>
                        <div className="">
                            <div className="wallet_user-name">
                                {user.first} {user.last}
                            </div>
                            <div className="wallet_user-company">company</div>
                        </div>
                    </div>

                    <div className={'connection ' + (this.props.friendConnectionReceived ? null : 'no-action')}>
                        <button
                            className="participant-button open-chat "
                            onClick={this.handleOpenPrivateChat}
                            disabled={this.props.loading}
                        >
                            <img src={OpenChatIcon} width="63" height="37" alt="Open Chat" />
                        </button>
                    </div>
                </div>
                <div className="wallet_user-section">
                    <div className="d-flex">
                        <div className="wallet_user-socials">
                            <img
                                className="wallet_user-icon"
                                src={AcceptRequestIcon}
                                width="22"
                                height="22"
                                alt="Accept Request"
                            />
                            <img
                                className="wallet_user-icon"
                                src={AcceptRequestIcon}
                                width="22"
                                height="22"
                                alt="Accept Request"
                            />
                            <img
                                className="wallet_user-icon"
                                src={AcceptRequestIcon}
                                width="22"
                                height="22"
                                alt="Accept Request"
                            />
                        </div>
                    </div>
                    <div className="d-flex">
                        {this.props.category === 'contacts' && (
                            <>
                                {!NEW_MEETING_IS_NOT_AVAILIBLE_YET && (
                                    <button type="button" className="wallet-button" onClick={this.handleNewMeeting}>
                                        <ScheduleIcon className="wallet-button-icon" />
                                        <span>new meeting</span>
                                    </button>
                                )}
                                {this.props.businessCardId && (
                                    <button
                                        type="button"
                                        className="wallet-button -colored"
                                        onClick={this.handleOpenBusinessCard}
                                    >
                                        <BusinessCardIcon className="wallet-button-icon" />
                                        <span>OPEN CARD</span>
                                    </button>
                                )}
                            </>
                        )}
                        {this.props.category === 'requests' && (
                            <>
                                <button
                                    type="button"
                                    className="wallet-button"
                                    onClick={this.handleDeclineFriendRequest}
                                >
                                    <CancelIcon className="wallet-button-icon" />
                                    <span>REJECT</span>
                                </button>
                                <button
                                    type="button"
                                    className="wallet-button -colored"
                                    onClick={this.handleAcceptFriendRequest}
                                >
                                    <BcRequestIcon className="wallet-button-icon" />
                                    <span>APPROVE</span>
                                </button>
                            </>
                        )}
                        {this.props.category === 'pending' && (
                            <>
                                <button
                                    type="button"
                                    className="wallet-button"
                                    onClick={this.handleCancelBusinessCardRequest}
                                >
                                    <CancelIcon className="wallet-button-icon" />
                                    <span>CANCEL REQUEST</span>
                                </button>
                            </>
                        )}
                        {/* {this.props.friendConnectionReceived ? (
                            <div>
                                <button className="accept-request" onClick={this.handleAcceptFriendRequest}>
                                    <img src={AcceptRequestIcon} width="22" height="22" alt="Accept Request" />
                                    <span>Accept</span>
                                </button>
                                <button className="reject-request" onClick={this.handleDeclineFriendRequest}>
                                    <img src={RejectRequestIcon} width="22" height="22" alt="Reject Request" />
                                    <span>Reject</span>
                                </button>
                            </div>
                        ) : null} */}
                        {/* {this.props.friendConnectionSent ? (
                            <div className="connection">
                                <button className="participant-button request-sent" disabled>
                                    <img src={RequestSentIcon} width="16" height="16" alt="Request Sent" />
                                    <span>Pending</span>
                                </button>
                            </div>
                        ) : null} */}
                        {/* {this.props.canConnectTo ? (
                            <div className="connection">
                                <button
                                    className="participant-button add-button"
                                    onClick={this.handleSendFriendConnection}
                                >
                                    <img src={Connect} width="24" height="24" alt="Connect" />
                                    <span>Add</span>
                                </button>
                            </div>
                        ) : null} */}
                    </div>
                </div>

                {/* OLD */}
                {/* <div
                    className={'user-container ' + (this.props.isOnline ? 'is-online' : 'is-offline')}
                    style={{display: 'none'}}
                >
                    <div
                        className="participant-avatar"
                        style={{backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${user.avatarSmall}')`}}
                    ></div>

                    <div className="user-details" style={{marginBottom: '-12px'}}>
                        <div style={{minHeight: 'auto'}}>
                            <div className="user-name">
                                <p className="user-name-top">
                                    {user.first} {user.last}
                                </p>
                            </div>
                        </div>
                        <div>
                            <div className={'connection ' + (this.props.friendConnectionReceived ? null : 'no-action')}>
                                <button
                                    className="participant-button open-chat"
                                    onClick={this.handleOpenPrivateChat}
                                    disabled={this.props.loading}
                                >
                                    <img src={OpenChatIcon} width="63" height="37" alt="Open Chat" />
                                </button>
                            </div>
                            {this.props.friendConnectionReceived ? (
                                <div>
                                    <button className="accept-request" onClick={this.handleAcceptFriendRequest}>
                                        <img src={AcceptRequestIcon} width="22" height="22" alt="Accept Request" />
                                        <span>Accept</span>
                                    </button>
                                    <button className="reject-request" onClick={this.handleDeclineFriendRequest}>
                                        <img src={RejectRequestIcon} width="22" height="22" alt="Reject Request" />
                                        <span>Reject</span>
                                    </button>
                                </div>
                            ) : null}
                            {this.props.friendConnectionSent ? (
                                <div className="connection">
                                    <button className="participant-button request-sent" disabled>
                                        <img src={RequestSentIcon} width="16" height="16" alt="Request Sent" />
                                        <span>Pending</span>
                                    </button>
                                </div>
                            ) : null}
                            {this.props.canConnectTo ? (
                                <div className="connection">
                                    <button
                                        className="participant-button add-button"
                                        onClick={this.handleSendFriendConnection}
                                    >
                                        <img src={Connect} width="24" height="24" alt="Connect" />
                                        <span>Add</span>
                                    </button>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div> */}
                {/* OLD */}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        privateChats: state.user.data.privateChats,
        loading: state.user.loading,
        userLoggedIn: state.user.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSendContactRequest: (to) => dispatch(actions.sendContactRequest(to)),
        onAcceptContactRequest: (requestId) => dispatch(actions.acceptContactRequest(requestId)),
        onCancelBusinessCardRequest: (requestId) => dispatch(actions.cancelBusinessCardRequest(requestId)),
        onDeclineContactRequest: (requestId) => dispatch(actions.declineContactRequest(requestId)),
        onOpenPrivateChat: (privateChatId) => dispatch(actions.openPrivateChat(privateChatId)),
        onCreatePrivateChat: (targetUserId) => dispatch(actions.createPrivateChat(targetUserId)),
        onOpenWallet: (walletView, walletTab, cardId) => dispatch(actions.openWallet(walletView, walletTab, cardId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleUserContact);
