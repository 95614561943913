import React, {useCallback, useEffect, useState} from 'react';
import classNames from 'classnames';
import {TextField} from '@material-ui/core';

import ColoredScrollbars from '../../../../../../../../../ColoredScrollbars/ColoredScrollbars';

import AddUserList from '../AddUserList/AddUserList';
import {usePlatformTranslation} from 'services/hooks';

import './EditGroupChat.scss';
import CustomCancelButton from 'Routes/components/CustomCancelButton';

const EditGroupChat = ({
    eventOwner,
    onGetGroupChats,
    activeChatRoomId,
    eventChatsTab,
    eventId,
    eventUsers,
    closeGroupChatDialog,
    onUpdateActiveChatTab,
    isRtlLanguage,
    getGroupChatData,
    updateGroupChat
}) => {
    const [search, setSearch] = useState('');
    const [chatName, setChatName] = useState('');
    const [chat, setChat] = useState(null);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [filterUsers, setFilterUsers] = useState([]);
    const [chatMembers, setChatMembers] = useState([]);
    const [usersToAdd, setUsersToAdd] = useState([]);
    const translation = usePlatformTranslation();

    const setInitialState = () => {
        setSearch('');
        setChatName('');
        setChat(null);
        setLoading(false);
        setUsers([]);
        setFilterUsers([]);
        setChatMembers([]);
        setUsersToAdd([]);
    };

    const getChatData = useCallback(
        (setInitialChatName) =>
            getGroupChatData({
                activeChatRoomId,
                callback: (groupChat) => {
                    if (setInitialChatName) setChatName(groupChat.name);

                    let chatMembers = groupChat.users.map((el) => el._id);
                    setChat(groupChat);
                    setChatMembers(chatMembers);
                }
            }),
        [activeChatRoomId, getGroupChatData]
    );

    const handleAddUserToGroupChat = (newUser) => {
        if (!usersToAdd.includes(newUser)) setUsersToAdd([...usersToAdd, newUser]);
    };

    const handleRemoveUserFromGroupChat = (userId) => {
        setUsersToAdd(usersToAdd.filter((userToAdd) => userToAdd._id !== userId));
    };

    const handleUpdateChat = () =>
        updateGroupChat({
            activeChatRoomId,
            usersToAdd,
            chatName,
            chat,
            callback: () => {
                setInitialState();
                closeGroupChatDialog();
                onUpdateActiveChatTab({eventChats: eventChatsTab}, eventId);
            }
        });

    const onSearchChange = ({target: {value}}) => setSearch(value);

    const handleFilterUsers = useCallback(() => {
        const filteredUsers = users.filter((obj) => {
            let fullName = `${obj.first} ${obj.last}`;
            if (fullName.toLowerCase().includes(search.toLowerCase()) && !chatMembers.includes(obj._id)) {
                return obj;
            }

            return false;
        });
        if (!search.length && users.length) setFilterUsers(users);

        setFilterUsers(filteredUsers);
    }, [setFilterUsers, chatMembers, search, users]);

    useEffect(() => {
        setLoading(true);
        const timer = setTimeout(() => {
            handleFilterUsers();
            setLoading(false);
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [search, setFilterUsers, handleFilterUsers]);

    useEffect(() => {
        handleFilterUsers();
    }, [users, handleFilterUsers]);

    useEffect(() => {
        getChatData(true);
        const users = eventUsers.concat(eventOwner);
        setUsers(users);

        return function cleanup() {
            onGetGroupChats();
        };
    }, [getChatData, eventOwner, onGetGroupChats, eventUsers]);

    return (
        <>
            <div className="group-chat-settings-container">
                {chat && (
                    <>
                        <div className="inputs-wrapper">
                            <div className="input-container">
                                <ColoredScrollbars>
                                    <TextField
                                        id="chatName"
                                        name="chatName"
                                        label={translation?.chatsDropdown.newChatNameInput}
                                        value={chatName}
                                        onChange={(e) => setChatName(e.target.value)}
                                        fullWidth
                                        margin="dense"
                                        variant="outlined"
                                    />
                                    <p>{translation?.chatsDropdown.editChatAddMembers}</p>
                                    <AddUserList
                                        translation={translation}
                                        searchValue={search}
                                        onSearch={onSearchChange}
                                        isLoading={loading}
                                        filteredUsers={filterUsers}
                                        usersToAdd={usersToAdd}
                                        onAddUser={handleAddUserToGroupChat}
                                        onRemoveUser={handleRemoveUserFromGroupChat}
                                    />
                                </ColoredScrollbars>
                            </div>
                        </div>
                        <div className="edit-group-chat">
                            <CustomCancelButton
                                text={translation?.chatsDropdown.newChatCancelButton}
                                onClick={closeGroupChatDialog}
                            />
                            <button
                                className={classNames('update-chat-name', {isRtl: isRtlLanguage})}
                                onClick={handleUpdateChat}
                                disabled={chatName === chat.name && !usersToAdd.length}
                            >
                                {translation?.chatsDropdown.membersUpdateButton}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default React.memo(EditGroupChat);
