import React from 'react';
import {useDispatch} from 'react-redux';

import Form from '../../../../../../../Form/Form';
import FormGroup from '../../../../../../../FormGroup/FormGroup';
import ColorPicker from './components/ColorPicker/ColorPicker';

import {changeBusinessCardField} from 'store/actions';
import {useBusinessCardData, useMedia, usePlatformTranslation} from 'services/hooks';

import './BusinessCardColors.scss';

const BusinessCardColorPicker = ({name, onChange, ...rest}) => (
    <ColorPicker
        getColor={({rgb}) => `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`}
        onChange={(color) => onChange({name, value: color})}
        {...rest}
    />
);

const ColorRow = ({title, description, ...rest}) => {
    const isTablet = useMedia('(max-width: 960px)');
    const isMobile = useMedia('(max-width: 840px)');

    return (
        <Form.Row>
            <div className="BusinessCardColors__titleGroup">
                <h2 className="BusinessCardColors__title">{title}</h2>

                {isTablet && !isMobile && <div className="BusinessCardColors__description">{description}</div>}
            </div>

            {isTablet ? (
                <BusinessCardColorPicker {...rest} />
            ) : (
                <FormGroup>
                    <FormGroup.HalfItem>
                        <BusinessCardColorPicker {...rest} />
                    </FormGroup.HalfItem>

                    <FormGroup.HalfItem>{description}</FormGroup.HalfItem>
                </FormGroup>
            )}
        </Form.Row>
    );
};

const BusinessCardColors = () => {
    const dispatch = useDispatch();
    const {businessCard} = useBusinessCardData();
    const {businessCard: businessCardTranslation} = usePlatformTranslation();

    return (
        <div className="BusinessCardColors">
            <Form>
                <ColorRow
                    title={businessCardTranslation.primaryColor}
                    description={businessCardTranslation.primaryColorDescription}
                    name="primaryColor"
                    color={businessCard?.primaryColor}
                    onChange={(...args) => dispatch(changeBusinessCardField(...args))}
                />

                <ColorRow
                    title={businessCardTranslation.backgroundColor}
                    description={businessCardTranslation.backgroundColorDescription}
                    name="backgroundColor"
                    color={businessCard?.backgroundColor}
                    onChange={(...args) => dispatch(changeBusinessCardField(...args))}
                />

                <ColorRow
                    title={businessCardTranslation.backgroundTextColor}
                    description={businessCardTranslation.backgroundTextColorDescription}
                    name="secondaryColor"
                    color={businessCard?.secondaryColor}
                    onChange={(...args) => dispatch(changeBusinessCardField(...args))}
                />
            </Form>
        </div>
    );
};

export default BusinessCardColors;
