import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as selectors from 'store/selectors';
import {BreadcrumbsNavigation} from './BreadcrumbsNavigation';

export default withRouter(
    connect(
        (state) => ({
            eventSlug: selectors.getEventSlug(state),
            building: selectors.getBuildingData(state),
            buildingId: selectors.getBuildingId(state),
            translation: selectors.getTranslation(state),
            isRtlLanguage: selectors.getIsRtlLanguage(state),
        }),
        null
    )(BreadcrumbsNavigation)
);
