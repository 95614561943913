import {IS_SCA_ENV} from '../../constants/shared';

import enEventLobbyImage768 from '../../Images/backgrounds/lobby/en_lobby_768.jpg';
import enEventLobbyImage900 from '../../Images/backgrounds/lobby/en_lobby_900.jpg';
import enEventLobbyImage1080 from '../../Images/backgrounds/lobby/en_lobby_1080.jpg';
import enEventLobbyImage1200 from '../../Images/backgrounds/lobby/en_lobby_1200.jpg';
import enEventLobbyImage1440 from '../../Images/backgrounds/lobby/en_lobby_1440.jpg';
import enEventLobbyImageFull from '../../Images/backgrounds/lobby/en_lobby.jpg';

import scaEventLobbyImageFull from '../../Images/backgrounds/lobby/sca-lobby-background.jpg';

const getLobbyBackground = (language) => {
    let EventLobbyImage;

    switch (true) {
        case window.innerHeight <= 768:
            //TODO:  need all Lobby images for all languages      P.s. commented by task EVX-3064    P.P.s. left a comment example
            // EventLobbyImage = isEngLanguage ? enEventLobbyImage768 : noEventLobbyImage768;
            EventLobbyImage = enEventLobbyImage768;
            break;
        case window.innerHeight <= 900:
            EventLobbyImage = enEventLobbyImage900;
            break;
        case window.innerHeight <= 1080:
            EventLobbyImage = enEventLobbyImage1080;
            break;
        case window.innerHeight <= 1200:
            EventLobbyImage = enEventLobbyImage1200;
            break;
        case window.innerHeight <= 1440:
            EventLobbyImage = enEventLobbyImage1440;
            break;
        default:
            EventLobbyImage = enEventLobbyImageFull;
    }

    return IS_SCA_ENV ? scaEventLobbyImageFull : EventLobbyImage;
};

export default getLobbyBackground;
