import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    controlleWrapper: {
        background: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'flex-end',
        height: '50px',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
        borderRadius: ' 0 0 6px 6px',
    },
    controlleBtn: {
        fontFamily: 'Montserrat',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '16px',
        letterSpacing: '0.0125em',
        textTransform: 'uppercase',
        color: ' #2B2B2B',
        '&:not(:last-child)': {
            marginLeft: '10px',
        },
        '&:disabled': {
            color: '#898989',
        },
    },
}));
