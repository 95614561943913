import React from 'react';
import {connect} from 'react-redux';

import * as actions from '../../../../../../../store/actions';
import GreaterArrow from '../../../../../../../Images/greater-arrow.svg';

class CloseTopNavButton extends React.Component {
    handleCloseTopNav = () => {
        this.props.onCloseTopNav();
    };

    render() {
        return (
            <div onClick={this.handleCloseTopNav} className="close-component">
                <img src={GreaterArrow} width="" height="" alt="Close button" />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseTopNav: () => dispatch(actions.topNavClose()),
    };
};

export default connect(null, mapDispatchToProps)(CloseTopNavButton);
