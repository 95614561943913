import EditGroupChat from './EditGroupChat';
import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export default connect(
    (state) => ({
        user: selectors.getUserData(state),
        activeChatRoomId: selectors.getUserActiveChatRoomId(state),
        eventId: selectors.getEventId(state),
        eventOwner: selectors.getEventOwner(state),
        eventChatsTab: selectors.getUserEventChats(state),
        eventUsers: selectors.getUsersList(state),
        isRtlLanguage: selectors.getIsRtlLanguage(state),
    }),
    {
        onGetGroupChats: actions.getGroupChats,
        onUpdateActiveChatTab: actions.updateActiveChatTab,
        getEventUsersList: actions.getEventUsersList,
        getGroupChatData: actions.getGroupChatData,
        updateGroupChat: actions.updateGroupChat,
    }
)(EditGroupChat);
