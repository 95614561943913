import {connect} from 'react-redux';
import * as selectors from 'store/selectors';
import BackToLiveBtn from './BackToLiveBtn';

export default connect(
    (state) => ({
        eventSlug: selectors.getEventSlug(state),
    }),
    null
)(BackToLiveBtn);
