import React from 'react';

import Svg from '../Svg/Svg';

import './Check.scss';

const IconCheck = () => (
    <Svg className="IconCheck">
        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
    </Svg>
);

export default IconCheck;
