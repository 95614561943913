import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import * as selectors from 'store/selectors';
import {SideNav} from './SideMenu';

export default connect(
    (state) => ({
        user: selectors.getUserData(state),
        event: selectors.getEventData(state),
        eventId: selectors.getEventId(state),
        sideNavigation: selectors.getSideNavigation(state),
        sideNavigationDetails: selectors.getSideNavigationDetails(state),
        resourcesAccess: selectors.getResourcesAccess(state),
        isLargeScreen: selectors.getIsLargeScreen(state),
    }),
    {
        seeNetworking: actions.sideNavSeeNetworking,
        seeLiveWall: actions.sideNavSeeLiveWall,
        seeVideoWall: actions.sideNavSeeVideoWall,
        closeSidebar: actions.sideNavClose,
        setActiveWall: actions.setActiveWall,
        seeInfoTab: actions.sideNavSeeInfoTab,
        seeSurvey: actions.sideNavSeeSurvey,
        seeSocialMedia: actions.sideNavSeeSocialMedia,
        seeInterpretation: actions.sideNavSeeInterpretation,
    }
)(SideNav);
