import {createSelector} from 'reselect';

const user = (state) => state.user;

export const getUser = createSelector(user, (state) => state);

export const getUserRoles = createSelector(user, (state) => state.userRoles);

export const getIsUserLoggedIn = createSelector(user, (state) => state.loggedIn);

export const getUserLoading = createSelector(user, (state) => state.loading);

export const getUserData = createSelector(user, (state) => state.data);

export const getUserEventRoles = createSelector(user, (state) => state.data?.eventRoles);

export const getUserLanguage = createSelector(user, (state) => state.data?.language);

export const getBooleanEventRolesList = createSelector(user, (state) => state.eventRoles);

export const getUserId = createSelector(user, (state) => state.data._id);

export const getUserError = createSelector(user, (state) => state.error);

export const getSideNavigation = createSelector(user, (state) => state.sideNavigation);

export const getResourcesAccess = createSelector(user, (state) => state.resourcesAccess);

export const getUserTopNavigation = createSelector(user, (state) => state.topNavigation);

export const getUserActiveChatRoomId = createSelector(user, (state) => state.topNavigation.activeChatRoomId);

export const getUserActiveChatData = createSelector(user, (state) => state.activeChatData);

export const getUserIsGroupChat = createSelector(user, (state) => state.topNavigation.isGroupChat);

export const getUserResourcesAccess = createSelector(user, (state) => state.resourcesAccess);

export const getUserMaximizeChats = createSelector(user, (state) => state.maximizeChats);

export const getUserEventChats = createSelector(user, (state) => state.chats.eventChats);

export const getUserPrivateChats = createSelector(user, (state) => state.data.privateChats);

export const getUserArchivedChats = createSelector(user, (state) => state.chats.archivedChats);

export const getUserNotReadChatMessages = createSelector(user, (state) => state.notReadChatMessages);

export const getServerDown = createSelector(user, (state) => state.serverDown);

export const getSessionExpired = createSelector(user, (state) => state.sessionExpired);

export const getCompanionDisconnected = createSelector(user, (state) => state.videoConferenceCompanionDisconnected);

export const getVisibilitySocialShareModal = createSelector(user, (state) => state.showSocialShareModal);

export const getUserMatchmaking = createSelector(user, (state) => state.matchMaking);

export const getUserMatchmakingAnswer = createSelector(user, (state) => state.matchMaking?.answers);

export const getUserNotifications = createSelector(user, (state) => state.data?.notifications);

export const getUserPaymentLink = createSelector(user, (state) => state.stripeSecret);

export const getUserPreselectedAccessPackageId = createSelector(user, (state) => state.preselectedAccessPackageId);
