import React, {useEffect} from 'react';
import PlatformMenu from '../PlatformMenu';
import HomepageBackground from '../../../Images/backgrounds/homepage-background.jpg';
import {usePlatformTranslation} from '../../../services/hooks';
import './NotAvailable.scss';

const NotAvailable = () => {
    const translation = usePlatformTranslation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <PlatformMenu />
            <div className="homepage">
                <div className="event-container" style={{backgroundImage: `url('${HomepageBackground}')`}}>
                    <div className="homepage-overlay">
                        <div className="smallContainer">
                            <div className="not-available">
                                <h1 className="not-available__error-title">
                                    {translation?.generalText.eventUpperETX}{' '}
                                    {translation?.notAvailable.notAvailableTitleTextSecond}
                                </h1>
                                <p className="not-available__error-text">
                                    {translation?.notAvailable.notAvailableSubtitleTextFirst}
                                    {translation?.generalText.eventETX}{' '}
                                    {translation?.notAvailable.notAvailableSubtitleTextSecond}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NotAvailable;
