import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';
import classNames from 'classnames';
import {useStyles} from './ConfirmDialog.styles';

const ConfirmDialog = ({
    mobile,
    //isBtnClick is for check if dialog was closed by button or by outside click
    closeConfirm = (isBtnClick) => {},
    dialogConfirmButtonLabel,
    handleConfirm,
    open,
    buttonColor,
    dialogTitle,
    btnVariant = 'contained',
    dialogDescription,
    dialogCancelButtonLabel = 'Cancel',
}) => {
    const classes = useStyles();
    return (
        <Dialog
            open={open}
            onClose={() => closeConfirm(false)}
            aria-labelledby="form-dialog-title"
            className={classNames({'dialog-mobile': mobile})}
        >
            <DialogTitle classes={{root: classes.dialogTitleRoot}} disableTypography>
                <h2 className={classes.dialogTitle} data-testid="confirmation-dialog-title">
                    {dialogTitle}
                </h2>
            </DialogTitle>
            <DialogContent>
                <DialogContentText className={classes.contentText}>{dialogDescription}</DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                {dialogCancelButtonLabel && (
                    <Button className={classes.button} onClick={() => closeConfirm(true)} color="primary">
                        {dialogCancelButtonLabel}
                    </Button>
                )}
                {dialogConfirmButtonLabel && (
                    <Button
                        classes={{contained: classes.buttonContained}}
                        className={classes.button}
                        variant={btnVariant}
                        color={buttonColor ? buttonColor : 'primary'}
                        onClick={handleConfirm}
                    >
                        {dialogConfirmButtonLabel}
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
