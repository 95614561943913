import React from 'react';

import Spinner from '../Spinner';
import NotificationPresentationStarts from '../NotificationPresentationStarts/NotificationPresentationStarts';
import Representatives from './components/Representatives/Representatives';
import Files from './components/Files/Files';
import Links from './components/Links/Links';
import Video from './components/Video/Video';
import BoothProductsDialog from '../BoothProductsDialog/BoothProductsDialog';
import BoothJobsDialog from '../BoothJobsDialog/BoothJobsDialog';

import {connectToBooth, disconnectFromBooth} from 'Api/socketApi';
import {AbilityContext} from 'permissionsConfig';

import {getPrivateChatId, getParticipantId, getUserIdFromParticipantId, getVideoIdFromYoutubeUrl} from 'services/utils';
import './ExhibitorBoothMobile.scss';

import {IS_CAREERS_ENV} from 'constants/shared';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

class ExhibitorBoothMobile extends React.Component {
    componentDidMount() {
        this.props.onGetExhibitor(this.props.activeExhibitorId);
        if (IS_CAREERS_ENV) this.props.onGetExhibitorJobs(this.props.activeExhibitorId);

        // emit socket event to let know that we have a visitor on booth
        connectToBooth({exhibitorId: this.props.activeExhibitorId, userId: this.props.user._id});

        this.props.getEventUsersList();

        this.setupBeforeUnloadListener();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.loadingExhibitor === true && this.props.loadingExhibitor === false) {
            // we got the exhibitor
            // we can now set up the booth Data
            this.handleSetUpBoothData();
        }

        if (prevProps.activeExhibitorId !== this.props.activeExhibitorId) {
            // if we changed the activeExhibitorId get the exhibitor data
            this.props.onGetExhibitor(this.props.activeExhibitorId);
            if (IS_CAREERS_ENV) this.props.onGetExhibitorJobs(this.props.activeExhibitorId);
            disconnectFromBooth({exhibitorId: prevProps.activeExhibitorId, userId: this.props.user._id});
            connectToBooth({exhibitorId: this.props.activeExhibitorId, userId: this.props.user._id});
        }
    }

    handleSetUpBoothData = () => {
        // get the exhibitor data from the event
        const exhibitorData = this.props.exhibitor;
        const boothData = exhibitorData.booth;

        let exhibitorRepresentatives = [];

        // set the active Wall to the booth Wall of this exhibitor
        // set up the array of users that we want to highlight the posts for
        // in the booth wall, we need to highlight the representatives posts

        let highlightUsers = [];
        let canModerateChat = [];

        // add booth owner to overseer users
        if (exhibitorData.user) {
            canModerateChat.push(exhibitorData.user);
        }

        boothData.medalionRepresentatives.forEach((representative) => {
            let userId = getUserIdFromParticipantId(this.props.eventUsersList, representative);

            if (userId) {
                highlightUsers.push(userId);
            }
            exhibitorRepresentatives.push(representative);

            // add additional representatives to overseer users
            canModerateChat.push(representative.user);
        });

        if (boothData.deskRepresentativeLeft.representative) {
            let userId = getUserIdFromParticipantId(
                this.props.eventUsersList,
                boothData.deskRepresentativeLeft.representative
            );

            if (userId) {
                highlightUsers.push(userId);
            }
        }

        if (boothData.deskRepresentativeRight.representative) {
            let userId = getUserIdFromParticipantId(
                this.props.eventUsersList,
                boothData.deskRepresentativeRight.representative
            );

            if (userId) {
                highlightUsers.push(userId);
            }
        }

        const wallData = {
            wallId: exhibitorData.booth.boothWall,
            highlightUsers: highlightUsers,
            canModerateChat: canModerateChat,
        };

        this.props.setActiveWall(wallData);

        let hasFacebookLink =
            exhibitorData.booth.socialMediaLinks.findIndex((socialLink) => socialLink.platform === 'Facebook') !== -1;
        let hasLinkedinLink =
            exhibitorData.booth.socialMediaLinks.findIndex((socialLink) => socialLink.platform === 'LinkedIn') !== -1;
        let hasTwitterLink =
            exhibitorData.booth.socialMediaLinks.findIndex((socialLink) => socialLink.platform === 'Twitter') !== -1;

        let exhibitorVideoId = getVideoIdFromYoutubeUrl(exhibitorData.booth.video);

        // save in state the exhibitor data (including booth data)
        this.setState({
            exhibitor: exhibitorData,
            exhibitorVideoId: exhibitorVideoId,
            hasFacebookSocialLink: hasFacebookLink,
            hasLinkedinSocialLink: hasLinkedinLink,
            hasTwitterSocialLink: hasTwitterLink,
        });
    };

    setupBeforeUnloadListener = () => {
        window.addEventListener('beforeunload', (ev) => {
            ev.preventDefault();
            const participant = this.props.eventUsersList.find((participant) => {
                return participant._id === this.props.user._id;
            });
            if (participant) {
                disconnectFromBooth({exhibitorId: this.state.exhibitor._id, userId: participant._id});
            }
        });
    };

    componentWillUnmount() {
        // emit socket event to let know that a visitor has left the booth
        disconnectFromBooth({exhibitorId: this.props.activeExhibitorId, userId: this.props.user._id});
        window.removeEventListener('resize', this.setActiveElementsPositions);
    }

    initialShow = {
        links: false,
        documents: false,
        video: false,
    };

    state = {
        exhibitor: null,
        currentTab: 'chat',
        exhibitorVideoId: '',
        hasFacebookSocialLink: false,
        hasLinkedinSocialLink: false,
        hasTwitterSocialLink: false,
        productsPopupShown: false,
        show: {...this.initialShow},
    };

    showLinks = () => {
        this.setState({show: {...this.initialShow, links: true}});
    };
    showDocuments = () => {
        this.setState({show: {...this.initialShow, documents: true}});
    };
    showVideo = () => {
        this.setState({show: {...this.initialShow, video: true}});
        this.sendStatistics('videoViews');
    };
    closeDialog = () => {
        this.setState({show: {...this.initialShow}});
    };

    openProductsPopup = () => {
        this.setState({productsPopupShown: true});
    };

    openJobsPopup = () => {
        this.setState({jobsPopupShown: true});
    };

    closeProductsPopup = () => {
        this.setState({currentTab: 'chat'});
        this.setState({productsPopupShown: false});
    };

    closeJobsPopup = () => {
        this.setState({currentTab: 'chat'});
        this.setState({jobsPopupShown: false});
    };

    handleClickOnLink = () => {
        this.sendStatistics('linkClicks');
    };

    handleClickOnDocument = () => {
        this.sendStatistics('documentClicks');
    };

    sendStatistics = async (actionType) => {
        const {eventId, eventRoles, sendExhibitorStatistic} = this.props;
        const {exhibitor} = this.state;
        const ability = this.context;

        const isParticipantAccess = ability.can(
            ROLE_ABILITIES_TYPES.PARTICIPANT.SEND_STATISTICS,
            ROLE_FEATURES_TYPES.STATISTICS.ALL_BOOTH_STATISTICS
        );

        if (!isParticipantAccess) return;

        const participantId = getParticipantId(eventRoles, eventId);

        const dataToSend = {
            participantId,
            actionType,
        };

        sendExhibitorStatistic({exhibitorId: exhibitor._id, payload: dataToSend});
    };

    handleOpenChatWithRepresentative = (userId) => {
        const {eventId} = this.props;
        const privateChatId = getPrivateChatId(this.props.user.privateChats, userId);

        // do not open chat with myself
        if (this.props.user._id !== userId) {
            if (privateChatId) {
                this.props.onOpenPrivateChat(privateChatId);
            } else {
                if (userId !== this.props.user._id && !this.props.loading) {
                    if (userId !== this.props.user._id) {
                        this.props.onCreatePrivateChat(userId, eventId);
                    }
                }
            }
        }
    };

    handleChangeTab = (tabName) => (e) => {
        this.setState({
            currentTab: tabName,
        });
    };

    render() {
        const {exhibitor, currentTab} = this.state;
        const {translation, jobs} = this.props;

        if (!exhibitor) {
            return <Spinner />;
        }

        return (
            <div className="exhibitor-booth-mobile">
                <div className="exhibitor-mobile-header">
                    {exhibitor.booth.logo ? (
                        <div className="booth-logo-container">
                            <img src={`${exhibitor.filesUrl}${exhibitor.booth.logo}`} alt={''} />
                        </div>
                    ) : (
                        <div className="exhibitor-company">
                            <span className="exhibitor-companyInner">{exhibitor.company}</span>
                        </div>
                    )}
                </div>
                <div className="tabs-container">
                    <div
                        className={`button-tab tab ${currentTab === 'chat' ? 'active' : ''}`}
                        onClick={this.handleChangeTab('chat')}
                    >
                        {translation?.showfloor?.mobileTabChat}
                    </div>
                    <div
                        className={`button-tab tab ${currentTab === 'files' ? 'active' : ''}`}
                        onClick={this.handleChangeTab('files')}
                    >
                        {translation?.showfloor?.mobileTabFiles}
                    </div>
                    <div
                        className={`button-tab tab ${currentTab === 'links' ? 'active' : ''}`}
                        onClick={this.handleChangeTab('links')}
                    >
                        {translation?.showfloor?.mobileTabLinks}
                    </div>
                    {exhibitor.products.length > 0 && (
                        <div
                            className={`button-tab tab ${currentTab === 'products' ? 'active' : ''}`}
                            onClick={() => {
                                this.handleChangeTab('products')();
                                this.openProductsPopup();
                            }}
                        >
                            {translation?.marketplace?.title?.uppercased}
                        </div>
                    )}
                    {jobs && jobs.length > 0 && (
                        <div
                            className={`button-tab tab ${currentTab === 'jobs' ? 'active' : ''}`}
                            onClick={() => {
                                this.handleChangeTab('jobs')();
                                this.openJobsPopup();
                            }}
                        >
                            {translation?.exhibitorDashboard?.tabJobs}
                        </div>
                    )}
                    {exhibitor.booth.video && (
                        <div
                            className={`button-tab tab ${currentTab === 'video' ? 'active' : ''}`}
                            onClick={this.handleChangeTab('video')}
                        >
                            {translation?.showfloor?.mobileTabVideo}
                        </div>
                    )}
                </div>
                {currentTab === 'chat' && <Representatives exhibitor={exhibitor} />}
                {currentTab === 'files' && <Files exhibitor={exhibitor} />}
                {currentTab === 'links' && <Links exhibitor={exhibitor} />}
                {currentTab === 'products' && (
                    <BoothProductsDialog
                        opened={this.state.productsPopupShown}
                        closeDialog={this.closeProductsPopup}
                        products={exhibitor.products}
                        exhibitorId={exhibitor._id}
                    />
                )}
                {currentTab === 'jobs' && (
                    <BoothJobsDialog
                        opened={this.state.jobsPopupShown}
                        closeDialog={this.closeJobsPopup}
                        onApplyToJob={this.props.onApplyToJob}
                        jobs={jobs}
                        exhibitorId={exhibitor._id}
                    />
                )}
                {currentTab === 'video' && <Video exhibitor={exhibitor} translation={translation} />}
                <NotificationPresentationStarts />
            </div>
        );
    }
}

ExhibitorBoothMobile.contextType = AbilityContext;

export default ExhibitorBoothMobile;
