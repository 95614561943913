import AuditoriumArhiveSingleVideo from './AuditoriumArhiveSingleVideo';
import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import {withRouter} from 'react-router';

export default withRouter(
    connect(
        (state) => ({
            event: selectors.getEventData(state),
            user: selectors.getUserData(state),
            languages: selectors.getLanguages(state),
            isLargeScreen: selectors.getIsLargeScreen(state),
            recordedZoomSessions: selectors.getTimeslotZoomRecordings(state),
            buildingId: selectors.getBuildingId(state),
            building: selectors.getBuildingData(state),
            resourcesAccess: selectors.getResourcesAccess(state),
        }),
        {
            closeSidebar: actions.sideNavClose,
            setHasVideoWall: actions.sideNavHasVideoWall,
            setTimeSlotVideoWall: actions.setTimeSlotVideoWall,
            setActiveWall: actions.setActiveWall,
            seeVideoWall: actions.sideNavSeeVideoWall,
            onSetEventProtectedMenu: actions.setEventProtectedMenu,
            getZoomStreamRecordings: actions.getZoomStreamRecordings,
            onGetOnDemantVideos: actions.getOnDemantVideos,
            getSessionRecordings: actions.getSessionRecordings,
        }
    )(AuditoriumArhiveSingleVideo)
);
