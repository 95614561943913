import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Menu, IconButton} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ConfirmDelete from '../../../../../../../../../../../ConfirmDeleteDialog/ConfirmDeleteDialog';
import {
    archiveChat,
    deleteChat,
    updateActiveChatTab,
    closeActiveChat,
} from '../../../../../../../../../../../../../store/actions/userActions';
import {useStyles} from '../MoreMenu.styles';
import {PRIVATE_CHAT_TYPE} from '../../../../../../../../../../../../../store/actionTypes/userTypes';
import {usePlatformTranslation} from '../../../../../../../../../../../../../services/hooks';
import {MenuItemArhive, MenuItemDelete, MenuItemExport} from '../MenuItems/index';
import {ReactComponent as MenuDropdownIcon} from '../../../../../../../../../../../../../Images/arrow_down_grey.svg';

const PrivateChatMoreMenu = ({chatId, chatName, isArchived}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {isMobile} = useSelector((state) => state.layout);
    const translation = usePlatformTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [openConfirmDeleteDialog, setConfirmDeleteDialog] = useState(false);
    const {eventId} = useSelector((state) => state.event);
    const chatTabs = useSelector((state) => state.user.chats);

    const handleArchive = () => {
        handleClose();

        dispatch(archiveChat(PRIVATE_CHAT_TYPE, chatId, isArchived));
    };

    const handleDelete = () => {
        handleClose();
        setConfirmDeleteDialog(true);
    };

    const closeConfirmDeleteDialog = () => {
        setConfirmDeleteDialog(false);
    };

    const handleConfirmDelete = () => {
        dispatch(deleteChat(PRIVATE_CHAT_TYPE, chatId));

        // in order to get updated chats list
        dispatch(updateActiveChatTab(chatTabs, eventId));

        // close deleted chat
        dispatch(closeActiveChat());

        closeConfirmDeleteDialog();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
                {isMobile ? <MenuDropdownIcon /> : <MoreVertIcon />}
            </IconButton>

            <Menu
                id="chat-more-menu"
                aria-label="more-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                className={classes.root}
            >
                <MenuItemDelete onClick={handleDelete} />
                <MenuItemExport isArchived={isArchived} onClick={handleClose} chatId={chatId} chatType="private" />
                <MenuItemArhive isArchived={isArchived} onClick={handleArchive} />
            </Menu>

            <ConfirmDelete
                open={openConfirmDeleteDialog}
                closeConfirmDeleteDialog={closeConfirmDeleteDialog}
                dialogTitle={translation.chatsDropdown.deleteChat}
                dialogDescription={`${translation.chatsDropdown.privateConfirmDeleteMessage} ${chatName}?`}
                dialogConfirmButtonLabel={translation.generalText.delete}
                handleConfirmDelete={handleConfirmDelete}
            />
        </>
    );
};

export default PrivateChatMoreMenu;
