import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import MyMatchmaking from './MyMatchmaking';

export default connect(
    (state) => ({
        matchingAnswers: selectors.getUserMatchmakingAnswer(state),
        matchingFormId: selectors.getMatchMakingFormId(state),
        matchingQuestions: selectors.getMatchMakingFormQuestions(state),
        userLoading: selectors.getUserLoading(state),
        matchingLoading: selectors.getMatchMakingFormLoading(state),
        isLargeScreen: selectors.getIsLargeScreen(state),
        eventId: selectors.getEventId(state),
    }),
    {
        onGetUserMatchingAnswers: actions.getUserMatchingAnswers,
        onGetMatchmakingForm: actions.getMatchmakingForm,
        onPostMatchingAnswers: actions.postMatchingAnswers,
        onDeleteMatchingAnswers: actions.deleteMatchingAnswers,
        onSetCompleteMatching: actions.setCompleteMatching,
    }
)(MyMatchmaking);
