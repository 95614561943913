import React from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

import Button from '../../../../../../../../../../../Button/Button';
import IconContainer from '../../../../../../../../../../../IconContainer/IconContainer';
import {IconAngleArrowRight} from '../../../../../../../../../../../Icons';

import {
    getBusinessCardEditLink,
    getBusinessCardColorsLink,
    getBusinessCardBrandingLink,
    getBusinessCardPreviewLink,
} from '../../../../../../../../../../../../../routes';
import {useBusinessCardData, usePlatformTranslation} from 'services/hooks';
import {requestBusinessCardUpdating} from 'store/actions';
import './MobileBusinessCardMenu.scss';

const MobileBusinessCardMenu = () => {
    const dispatch = useDispatch();
    const {businessCard, isSaveButtonDisabled} = useBusinessCardData();
    const {businessCard: businessCardTranslation, generalText} = usePlatformTranslation();
    const eventSlug = useSelector((state) => state.event?.eventSlug);
    const isCreating = !businessCard?.id;

    const linkList = [
        {title: businessCardTranslation.editText, linkFunc: getBusinessCardEditLink},
        {title: businessCardTranslation.colors, linkFunc: getBusinessCardColorsLink},
        {title: businessCardTranslation.branding, linkFunc: getBusinessCardBrandingLink},
        {title: businessCardTranslation.cardPreview, linkFunc: getBusinessCardPreviewLink},
    ];

    const _linkList = linkList.map(({title, linkFunc}) => ({title, link: linkFunc(eventSlug)}));

    return (
        <div className="MobileBusinessCardMenu">
            <div className="MobileBusinessCardMenu__linkList">
                {_linkList.map(({title, link}, index) => (
                    <div key={index} className="MobileBusinessCardMenu__linkItem">
                        <Link to={link} className="MobileBusinessCardMenu__link">
                            <span className="MobileBusinessCardMenu__linkTitle">{title}</span>

                            <span className="MobileBusinessCardMenu__linkIcon">
                                <IconContainer>
                                    <IconAngleArrowRight />
                                </IconContainer>
                            </span>
                        </Link>
                    </div>
                ))}
            </div>

            <div className="MobileBusinessCardMenu__message">* {businessCardTranslation.onceSaved}</div>

            <div className="MobileBusinessCardMenu__button">
                <Button
                    title={isCreating ? 'Set up business card*' : `${generalText.save}*`}
                    isDisabled={isSaveButtonDisabled}
                    onClick={() => dispatch(requestBusinessCardUpdating())}
                />
            </div>
        </div>
    );
};

export default MobileBusinessCardMenu;
