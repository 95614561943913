import React from 'react';

export const Wrapper = ({children, isInteractive, ...rest}) => {
    if (!isInteractive) {
        return <span className="UserPreview__el">{children}</span>;
    }

    return (
        <button className="UserPreview__el" {...rest}>
            {children}
        </button>
    );
};

// export const MediaPlaceholder = ({firstName = '', placeholderColor}) => (
//     <span className="UserPreview__mediaPlaceholder" style={{backgroundColor: placeholderColor}}>
//         {firstName[0]}
//     </span>
// );
