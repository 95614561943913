import openSocket from 'socket.io-client';
import store from 'store/store';
import * as actions from 'store/actions';
import {SOCKET_EVENTS} from 'constants/socketEvents';

let socket, socketOptions;
let token = localStorage.getItem('token');

socketOptions = {
    reconnect: true,
    reconnectionAttempts: 10,
    reconnectionDelay: 10000,
    reconnectionDelayMax: 15000,
    secure: true,
    query: {token},
    transports: ['websocket', 'polling'],
};

socket = openSocket.connect(process.env.REACT_APP_SOCKET_API, socketOptions);

socket.on(SOCKET_EVENTS.CONNECT, () => {
    const socketConnections = store.getState().socketConnections;

    if (socketConnections.length) {
        socketConnections.forEach(({connectionName, args}) => socketRoomsConnectionConfig[connectionName](args));
    }
    store.dispatch(actions.serverUp());
});

socket.on(SOCKET_EVENTS.DISCONNECT, () => {
    store.dispatch(actions.serverDown());
});

socket.on(SOCKET_EVENTS.RECONNECT_ATTEMPT, (data) => {
    let token = localStorage.getItem('token');
    socket.io.opts.query = {token};
});

function connectToEvent({userId, eventId}) {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_EVENT, {userId, eventId});
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_EVENT,
            args: {userId, eventId},
        })
    );
}

function disconnectFromEvent({userId, eventId}) {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_EVENT, {userId, eventId});
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_EVENT,
        })
    );
}

// one to one calls
export const p2pCallToUser = ({from, to}) => socket.emit(SOCKET_EVENTS.P2P_CALL_TO_USER, {from, to});

export const p2pEndCall = ({from, to}) => socket.emit(SOCKET_EVENTS.P2P_END_CALL, {from, to});

export const p2pCallOfferRequest = ({from, to, offer}) => socket.emit(SOCKET_EVENTS.P2P_CALL_OFFER, {from, to, offer});

export const p2pCallOfferReceived = (cb) => socket.on(SOCKET_EVENTS.P2P_CALL_OFFER, (offer) => cb(null, offer));

export const p2pCallAnswerRequest = ({from, to, answer}) =>
    socket.emit(SOCKET_EVENTS.P2P_CALL_ANSWER, {from, to, answer});

export const p2pCallAnswerReceived = (cb) => socket.on(SOCKET_EVENTS.P2P_CALL_ANSWER, (answer) => cb(null, answer));

export const p2pCallCandidateRequest = ({from, to, candidate}) =>
    socket.emit(SOCKET_EVENTS.P2P_CALL_CANDIDATE, {from, to, candidate});

export const p2pCallCandidateReceived = (cb) =>
    socket.on(SOCKET_EVENTS.P2P_CALL_CANDIDATE, (candidate) => cb(null, candidate));

export const iceServersReceived = (cb) =>
    socket.on(SOCKET_EVENTS.P2P_ICE_SERVERS, ({iceServers}) => cb(null, iceServers));

export const incomingCallReceived = (cb) => socket.on(SOCKET_EVENTS.P2P_INCOMING_CALL, (data) => cb(null, data));

export const enableSelfVideo = ({from, to}) => socket.emit(SOCKET_EVENTS.P2P_VIDEO_ENABLED, {from, to});

export const participantVideoEnabled = (cb) => socket.on(SOCKET_EVENTS.P2P_VIDEO_ENABLED, (data) => cb(null, data));

export const disableSelfVideo = ({from, to}) => socket.emit(SOCKET_EVENTS.P2P_VIDEO_DISABLED, {from, to});

export const participantVideoDisabled = (cb) => socket.on(SOCKET_EVENTS.P2P_VIDEO_DISABLED, (data) => cb(null, data));

export const endCall = ({from, to}) => socket.emit(SOCKET_EVENTS.P2P_END_CALL, {from, to});

export const participantHasLeft = (cb) => socket.on(SOCKET_EVENTS.P2P_END_CALL, (data) => cb(null, data));

// new message in Private chat
function privateMessageReceived(cb) {
    socket.on(SOCKET_EVENTS.NEW_PRIVATE_MESSAGE_SAVED, (message) => cb(null, message));
}

function connectToPrivateChat({chatId, userId}) {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_PRIVATE_CHAT, {chatId, userId});
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_PRIVATE_CHAT,
            args: {userId, chatId},
        })
    );
}

function disconnectFromPrivateChat({chatId, userId}) {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_PRIVATE_CHAT, {chatId, userId});
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_PRIVATE_CHAT,
        })
    );
}

// new message in Group chat
function groupMessageReceived(cb) {
    socket.on(SOCKET_EVENTS.NEW_GROUP_MESSAGE_SAVED, (message) => cb(null, message));
}

function connectToGroupChat({chatId, userId}) {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_GROUP_CHAT, {chatId, userId});
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_GROUP_CHAT,
            args: {userId, chatId},
        })
    );
}

function disconnectFromGroupChat({chatId, userId}) {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_GROUP_CHAT, {chatId, userId});
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_GROUP_CHAT,
        })
    );
}

export const handleGroupChatUpdate = (cb) => socket.on(SOCKET_EVENTS.GROUP_CHAT_UPDATED, cb);

function connectToWall({wallId}) {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_WALL, {wallId});
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_WALL,
            args: {wallId},
        })
    );
}

function disconnectFromWall({wallId, userId}) {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_WALL, {wallId, userId});
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_WALL,
        })
    );
}

function postReceived(cb) {
    socket.on(SOCKET_EVENTS.WALL_NEW_POST_SAVED, (post) => cb(null, post));
}
function wallClosed(cb) {
    socket.on(SOCKET_EVENTS.WALL_CLOSED, ({type, event}) => cb(null, {type, event}));
}

function wallOpened(cb) {
    socket.on(SOCKET_EVENTS.WALL_OPENED, ({type, event}) => cb(null, {type, event}));
}

function postDeleted(cb) {
    socket.on(SOCKET_EVENTS.WALL_POST_DELETED, (post) => cb(null, post));
}

function commentReceived(cb) {
    socket.on(SOCKET_EVENTS.WALL_NEW_POST_COMMENT_SAVED, (commentData) => cb(null, commentData));
}

function commentApproved(cb) {
    socket.on(SOCKET_EVENTS.WALL_NEW_PENDING_COMMENT_SAVED, (commentData) => cb(null, commentData));
}

function commentDeleted(cb) {
    socket.on(SOCKET_EVENTS.WALL_POST_COMMENT_DELETED, (commentData) => cb(null, commentData));
}

function postLikeReceived(cb) {
    socket.on(SOCKET_EVENTS.WALL_NEW_POST_LIKE_SAVED, (postLikeData) => cb(null, postLikeData));
}

function postApproved(cb) {
    socket.on(SOCKET_EVENTS.WALL_NEW_PENDING_POST_SAVED, (postData) => cb(null, postData));
}

function postUnlikeReceived(cb) {
    socket.on(SOCKET_EVENTS.WALL_POST_LIKE_REMOVED, (postUnlike) => cb(null, postUnlike));
}

function postPinReceived(cb) {
    socket.on(SOCKET_EVENTS.WALL_POST_PIN, (postLikeData) => cb(null, postLikeData));
}

function postUnpinReceived(cb) {
    socket.on(SOCKET_EVENTS.WALL_POST_UNPIN, (postUnlike) => cb(null, postUnlike));
}
// get notifications for user
function connectToNotifications({userId}) {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_USER_NOTIFICATIONS, {userId});
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_USER_NOTIFICATIONS,
            args: {userId},
        })
    );
}

function disconnectFromNotifications({userId}) {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_USER_NOTIFICATIONS, {userId});
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_USER_NOTIFICATIONS,
        })
    );
}

function notificationReceived(cb) {
    socket.on(SOCKET_EVENTS.NEW_USER_NOTIFICATION, (notificationData) => cb(null, notificationData));
}

function flashNotificationReceived(cb) {
    socket.on(SOCKET_EVENTS.NEW_FLASH_NOTIFICATION, (notificationData) => cb(null, notificationData));
}

export const callUsers = (userIds) => {
    socket.emit(SOCKET_EVENTS.CALL_USERS, userIds);
};

export const receiveCallUsers = (userIds) => {
    socket.on(SOCKET_EVENTS.RECEIVE_CALL_USERS, userIds);
};

// get notifications for user
function connectToBooth({exhibitorId, userId}) {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_BOOTH, {
        exhibitorId,
        userId,
    });
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_BOOTH,
            args: {exhibitorId, userId},
        })
    );
}

function disconnectFromBooth({exhibitorId, userId}) {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_BOOTH, {exhibitorId, userId});
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_BOOTH,
        })
    );
}

function connectAdminToBooth({exhibitorId}) {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_ADMIN_BOOTH, {exhibitorId});
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_ADMIN_BOOTH,
            args: {exhibitorId},
        })
    );
}

function disconnectAdminFromBooth({exhibitorId}) {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_ADMIN_BOOTH, {exhibitorId});
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_ADMIN_BOOTH,
        })
    );
}

function boothVisitorsUpdated(cb) {
    socket.on(SOCKET_EVENTS.BOOTH_PARTICIPANTS_UPDATED, (data) => cb(null, data));
}

function liveWallPostsDeleted(cb) {
    socket.on(SOCKET_EVENTS.ALL_WALL_POST_DELETED, (data) => cb(null, data));
}

function checkSocketConnection(cb) {
    socket.on(SOCKET_EVENTS.SEND_SERVER_TIMESTAMP, (data) => cb(null, data));
}

function removeCheckSocketConnectionListener() {
    socket.off(SOCKET_EVENTS.SEND_SERVER_TIMESTAMP);
}

// timeslot
export const connectToTimeslot = ({timeslotId}) => {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_TIMESLOT, {timeslotId});
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_TIMESLOT,
            args: {timeslotId},
        })
    );
};

export const disconnectFromTimeslot = ({timeslotId}) => {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_TIMESLOT, {timeslotId});
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_TIMESLOT,
        })
    );
};

export function connectToTimeslotChat({timeslotId}) {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_TIMESLOT_CHAT, {timeslotId});
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_TIMESLOT_CHAT,
            args: {timeslotId},
        })
    );
}

export function disconnectFromTimeslotChat({timeslotId}) {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_TIMESLOT_CHAT, {timeslotId});
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_TIMESLOT_CHAT,
        })
    );
}

export function timeslotMessageReceived(cb) {
    socket.on(SOCKET_EVENTS.TIMESLOT_NEW_MESSAGE_SAVED, ({message}) => cb(message));
}

export const timeslotStreamStarted = (cb) => {
    socket.on(SOCKET_EVENTS.TIMESLOT_STREAM_STARTED, ({timeslot}) => cb(null, timeslot));
};

export const timeslotStreamStopped = (cb) => {
    socket.on(SOCKET_EVENTS.TIMESLOT_STREAM_STOPPED, ({timeslot}) => cb(null, timeslot));
};

export const timeslotRecordingStarted = (cb) => {
    socket.on(SOCKET_EVENTS.TIMESLOT_RECORDING_STARTED, ({timeslot}) => cb(null, timeslot));
};

export const timeslotRecordingStopped = (cb) => {
    socket.on(SOCKET_EVENTS.TIMESLOT_RECORDING_STOPPED, ({timeslot}) => cb(null, timeslot));
};

export const timeslotStreamPublished = (cb) => {
    socket.on(SOCKET_EVENTS.TIMESLOT_STREAM_PUBLISHED, ({timeslot}) => cb(null, timeslot));
};

export const timeslotInterpreterStreamPublished = (cb) => {
    socket.on(SOCKET_EVENTS.TIMESLOT_INTERPRETER_STREAM_PUBLISHED, ({timeslot, interpretationLanguage}) =>
        cb(null, {timeslot, interpretationLanguage})
    );
};

export const timeslotInterpreterStreamUnpublished = (cb) => {
    socket.on(SOCKET_EVENTS.TIMESLOT_INTERPRETER_STREAM_UNPUBLISHED, ({timeslot, interpretationLanguage}) =>
        cb(null, {timeslot, interpretationLanguage})
    );
};

export const timeslotStreamUnpublished = (cb) => {
    socket.on(SOCKET_EVENTS.TIMESLOT_STREAM_UNPUBLISHED, ({timeslot}) => cb(null, timeslot));
};

export const timeslotResourcesAllocated = (cb) => {
    socket.on(SOCKET_EVENTS.TIMESLOT_RESOURCES_ALLOCATED, ({timeslot}) => cb(null, timeslot));
};

export const timeslotResourcesHold = (cb) => {
    socket.on(SOCKET_EVENTS.TIMESLOT_RESOURCES_HOLD, ({timeslot}) => cb(null, timeslot));
};

export const timeslotResourcesHolding = (cb) => {
    socket.on(SOCKET_EVENTS.TIMESLOT_RESOURCES_HOLDING, ({timeslot}) => cb(null, timeslot));
};

export const timeslotResourcesFailed = (cb) => {
    socket.on(SOCKET_EVENTS.TIMESLOT_RESOURCES_ALLOCATION_FAILED, ({timeslot}) => cb(null, timeslot));
};

// polls
export const connectToTimeslotPoll = ({userId, programId}) => {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_PROGRAM_POLLS, {
        programId,
        userId,
    });
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_PROGRAM_POLLS,
            args: {userId, programId},
        })
    );
};

export const disconnectFromTimeslotPoll = ({userId, programId}) => {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_PROGRAM_POLLS, {
        programId,
        userId,
    });
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_PROGRAM_POLLS,
        })
    );
};

export const handlePollUpdate = (cb) => {
    socket.on(SOCKET_EVENTS.POLL_UPDATED, (data) => cb(data));
};

export const handlePollCreate = (cb) => {
    socket.on(SOCKET_EVENTS.POLL_CREATED, (data) => cb(data));
};

export const handlePollDelete = (cb) => {
    socket.on(SOCKET_EVENTS.POLL_DELETED, (data) => cb(data));
};

// userStatuses
export const handleUserStatusUpdate = (cb) => {
    socket.on(SOCKET_EVENTS.USERS_STATUS_UPDATE, ({user}) => cb({id: user.userId, status: user.status}));
};

export const changeUserStatusToBusy = (userId) => {
    socket.emit(SOCKET_EVENTS.USERS_STATUS_CHANGE_TO_BUSY, {userId});
};

export const changeUserStatusToOnline = (userId) => {
    socket.emit(SOCKET_EVENTS.USERS_STATUS_CHANGE_TO_ONLINE, {userId});
};

// village / buildings
function connectToBuilding({userId, buildingId}) {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_BUILDING, {userId, buildingId});
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_BUILDING,
            args: {userId, buildingId},
        })
    );
}
function disconnectFromBuilding({userId, buildingId}) {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_BUILDING, {userId, buildingId});
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_BUILDING,
        })
    );
}

// hot news
export const connectToEventNews = ({eventId}) => {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_EVENT_NEWS, {
        eventId,
    });
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_EVENT_NEWS,
            args: {eventId},
        })
    );
};

export const disconnectFromEventNews = ({eventId}) => {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_EVENT_NEWS, {
        eventId,
    });
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_EVENT_NEWS,
        })
    );
};

export const connectToEventLobbyNews = ({eventId}) => {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_EVENT_LOBBY_NEWS, {
        eventId,
    });
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_EVENT_LOBBY_NEWS,
            args: {eventId},
        })
    );
};

export const disconnectFromEventLobbyNews = ({eventId}) => {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_EVENT_LOBBY_NEWS, {
        eventId,
    });
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_EVENT_LOBBY_NEWS,
        })
    );
};

export const connectToBuildingNews = ({eventId, buildingId}) => {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_BUILDING_NEWS, {
        eventId,
        buildingId,
    });
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_BUILDING_NEWS,
            args: {eventId, buildingId},
        })
    );
};

export const disconnectFromBuildingNews = ({eventId, buildingId}) => {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_BUILDING_NEWS, {
        eventId,
        buildingId,
    });
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_BUILDING_NEWS,
        })
    );
};

export const hotNewsStarted = (cb) => {
    socket.on(SOCKET_EVENTS.HOT_NEWS_STARTED, (data) => cb(data));
};

export const hotNewsStopped = (cb) => {
    socket.on(SOCKET_EVENTS.HOT_NEWS_STOPPED, (data) => cb(data));
};

// auditoriums
const connectToAuditorium = ({userId, eventId, auditoriumId}) => {
    socket.emit(SOCKET_EVENTS.CONNECT_TO_AUDITORIUM, {userId, eventId, auditoriumId});
    store.dispatch(
        actions.addSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_AUDITORIUM,
            args: {userId, eventId, auditoriumId},
        })
    );
};

const disconnectToAuditorium = ({userId, eventId, auditoriumId}) => {
    socket.emit(SOCKET_EVENTS.DISCONNECT_FROM_AUDITORIUM, {userId, eventId, auditoriumId});
    store.dispatch(
        actions.removeSocketRoomConnection({
            connectionName: SOCKET_EVENTS.CONNECT_TO_AUDITORIUM,
        })
    );
};

export const handleNewOnDemandVideo = (cb) => socket.on(SOCKET_EVENTS.NEW_ONDEMAND_VIDEO, (data) => cb(null, data));

// participants registration
const participantsRegistrationAccessUpdated = (cb) =>
    socket.on(SOCKET_EVENTS.HIDE_PARTICIPANT_LINK, (data) => cb(null, data));
// participants login
const usersLoginAccessUpdated = (cb) => socket.on(SOCKET_EVENTS.HIDE_LOGIN_LINK, (data) => cb(null, data));

//matchmaking
export const matchmakingEnabledUpdated = (cb) =>
    socket.on(SOCKET_EVENTS.EVENT_MATCHING_ENABLED_UPDATED, (data) => cb(null, data));
export const eventHasMatchingUpdated = (cb) =>
    socket.on(SOCKET_EVENTS.EVENT_HAS_MATCHING_UPDATED, (data) => cb(null, data));

const socketRoomsConnectionConfig = {
    [SOCKET_EVENTS.CONNECT_TO_EVENT]: connectToEvent,
    [SOCKET_EVENTS.CONNECT_TO_PRIVATE_CHAT]: connectToPrivateChat,
    [SOCKET_EVENTS.CONNECT_TO_GROUP_CHAT]: connectToGroupChat,
    [SOCKET_EVENTS.CONNECT_TO_WALL]: connectToWall,
    [SOCKET_EVENTS.CONNECT_TO_USER_NOTIFICATIONS]: connectToNotifications,
    [SOCKET_EVENTS.CONNECT_TO_BOOTH]: connectToBooth,
    [SOCKET_EVENTS.CONNECT_TO_ADMIN_BOOTH]: connectAdminToBooth,
    [SOCKET_EVENTS.CONNECT_TO_TIMESLOT]: connectToTimeslot,
    [SOCKET_EVENTS.CONNECT_TO_TIMESLOT_CHAT]: connectToTimeslotChat,
    [SOCKET_EVENTS.CONNECT_TO_PROGRAM_POLLS]: connectToTimeslotPoll,
    [SOCKET_EVENTS.CONNECT_TO_BUILDING]: connectToBuilding,
    [SOCKET_EVENTS.CONNECT_TO_EVENT_NEWS]: connectToEventNews,
    [SOCKET_EVENTS.CONNECT_TO_EVENT_LOBBY_NEWS]: connectToEventLobbyNews,
    [SOCKET_EVENTS.CONNECT_TO_BUILDING_NEWS]: connectToBuildingNews,
    [SOCKET_EVENTS.CONNECT_TO_AUDITORIUM]: connectToAuditorium,
};

export {
    connectToEvent,
    disconnectFromEvent,
    connectToPrivateChat,
    disconnectFromPrivateChat,
    privateMessageReceived,
    connectToGroupChat,
    disconnectFromGroupChat,
    groupMessageReceived,
    connectToWall,
    disconnectFromWall,
    postReceived,
    postDeleted,
    commentReceived,
    commentDeleted,
    postLikeReceived,
    postUnlikeReceived,
    postUnpinReceived,
    postPinReceived,
    connectToNotifications,
    disconnectFromNotifications,
    notificationReceived,
    flashNotificationReceived,
    connectToBooth,
    connectAdminToBooth,
    disconnectFromBooth,
    disconnectAdminFromBooth,
    boothVisitorsUpdated,
    liveWallPostsDeleted,
    checkSocketConnection,
    removeCheckSocketConnectionListener,
    connectToBuilding,
    disconnectFromBuilding,
    connectToAuditorium,
    disconnectToAuditorium,
    wallOpened,
    wallClosed,
    participantsRegistrationAccessUpdated,
    usersLoginAccessUpdated,
    commentApproved,
    postApproved,
};
