import React from 'react';

import Svg from '../Svg/Svg';

import './AngleArrowRight.scss';

const IconAngleArrowRight = () => (
    <Svg className="IconAngleArrowRight">
        <path
            className="IconAngleArrowRight__el"
            d="M8.405 21.33a1.09 1.09 0 11-1.52-1.563L14.875 12 6.886 4.233a1.09 1.09 0 011.52-1.563L18 12l-9.595 9.33z"
        />
    </Svg>
);

export default IconAngleArrowRight;
