export const TABS_LIST = {
    CURRENT: 'current',
    ARCHIVED: 'archived',
};

export const CURRENT_SUB_TABS_LIST = {
    OVERALL: 'overall',
    WALLS: 'walls',
    AUDITORIUMS: 'auditoriums',
    EXHIBITORS: 'exhibitors',
    POLLS: 'polls',
    NETWORKING: 'networking',
};

export const BUILDING_IDS = {
    ALL: 'all',
};

export const AUDITORIUMS_IDS = {
    ALL: 'all',
};

export const BUILDING_TYPES = {
    MIXED: 'mixed',
    SHOWFLOOR: 'showfloor',
    CONFERENCE: 'conference',
};

export const WALLS_STATS_TYPES = {
    liveWalls: {
        topPostLikes: 'Top post likes',
        topPostComments: 'Top post comments',
    },
    boothWalls: {
        topPostLikes: 'Top post likes',
        topPostComments: 'Top post comments',
    },
    videoWalls: {
        topPostLikes: 'Top post likes',
        topPostComments: 'Top post comments',
    },
};

export const AUDITORIUMS_STATS_TYPES = {
    sessionAttendeesCount: 'Session Attendees',
    auditoriumOnlineUsersCount: 'Online Users',
};

export const AUDITORIUMS_USERS_LISTS_TYPES = {
    sessionAttendeesCount: 'Total Session Attendees',
    auditoriumOnlineUsersCount: 'Auditorium Online Users',
};

export const AUDITORIUMS_INFO_ICONS_DATA = {
    sessionAttendeesCount: 'Sessions Attendees with timestamp',
};

export const POLLS_STATS_TYPES = {
    pollData: 'Poll Data',
};

export const EXHIBITORS_STATS_TYPES = {
    attendeesNowUsersCount: 'Attendees Now',
    linkClicksCount: 'Links Click',
    documentClicksCount: 'Document Views',
    representativeChatClicksCount: 'Representative Chat Clicks',
    videoViewsCount: 'Video Presentation Seen',
    visitorsCount: 'Visitors',
};

export const OVERALL_STATS_TYPES = {
    participantsCount: 'Event Participants',
    unregisteredUsersCount: 'Unregistered Users',
};

export const EXHIBITORS_USERS_LISTS_TYPES = {
    attendeesNowUsersCount: 'attendeesNowUsers',
    linkClicksCount: 'linkClicksUsers',
    documentClicksCount: 'documentClicksUsers',
    representativeChatClicksCount: 'representativeChatClicksUsers',
    videoViewsCount: 'videoViewsUsers',
    visitorsCount: 'visitorUsers',
};
