import React from 'react';
import {FormControlLabel, makeStyles, Switch} from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
    label: {
        textTransform: 'uppercase',
        fontWeight: 600,
    },
    labelChacked: {
        color: theme.palette.primary.main,
    },
    switchBase: {
        color: theme.palette.text.primary,
    },
}));

const CustomSwitch = React.forwardRef(
    ({label = '', disabledLabel = '', onClick, color = 'primary', className = '', ...props}, ref) => {
        const {checked} = props;
        const classes = useStyles();
        return (
            <FormControlLabel
                onClick={onClick}
                className={className}
                ref={ref}
                classes={{label: classNames(classes.label, {[classes.labelChacked]: checked})}}
                control={<Switch classes={{switchBase: classes.switchBase}} {...props} color={color} />}
                label={checked ? label : disabledLabel || label}
            />
        );
    }
);

export default CustomSwitch;
