import React from 'react';

const AccountLeftSideTabsPanel = ({children, index, isActive}) => (
    <div
        className="AccountLeftSideTabs__panel"
        role="tabpanel"
        hidden={!isActive}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
    >
        {children}
    </div>
);

export default AccountLeftSideTabsPanel;
