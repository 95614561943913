import {customFormFieldTypes} from 'constants/customForm';
import {getTextForBrandingTranslations} from './brandingLanguageHelper';
import {getFieldTranslatedOptions} from './registrationFieldsHelper';

const surveyCustomFormPairs = [
    ['question', 'label'],
    ['questionType', 'fieldType'],
];

const registrationCustomFormPairs = [['type', 'fieldType']];

export const transformSurveyToCustomForm = (surveyData) => {
    return surveyData.map((question) => {
        const validation = setValidationField(question.options);

        const formField = renameProperties(question, surveyCustomFormPairs);

        return {
            ...formField,
            validation,
        };
    });
};

export const transformRegFieldsToCustomForm = (regFields, lang) =>
    regFields.map((field) => transformRegFieldToCustomField(field, lang));

export const transformCustomFormToRegField = (customForm) => {
    // to save/update/delete one field
    const regField = renameProperties(customForm, registrationCustomFormPairs, true);

    return regField;
};

export const transformRegFieldToCustomField = (field, language) => {
    const validation = setValidationField(field.options);

    const formField = renameProperties(field, registrationCustomFormPairs);

    const translatedLabel = getTextForBrandingTranslations(formField.translations, language);
    if (translatedLabel) formField.label = translatedLabel;

    formField.options.forEach((option) => {
        const translatedOption = getTextForBrandingTranslations(option.translations, language);
        if (translatedOption) option.value = translatedOption;
    });

    return {
        ...formField,
        validation,
    };
};

function setValidationField(options) {
    // to check if the custom field and options are valid
    const validation = {
        field: true,
    };

    if (options.length) {
        options.map((option) => Object.assign(validation, {[option._id]: true}));
    }

    return validation;
}

function renameProperties(obj, namePairs = [], reverse = false) {
    const newObj = {...obj};

    namePairs.forEach((namePair) => {
        const oldName = namePair[reverse ? 1 : 0];
        const newName = namePair[reverse ? 0 : 1];

        newObj[newName] = newObj[oldName];

        delete newObj[oldName];
    });

    return newObj;
}

// Registration custom fields
export const transformRegistrationToCustomForm = (regFieldsData) => {};

//
export const getValueFromCustomField = (customField, userField, language) => {
    if (userField.value) return userField.value;

    const options = customField?.options;
    if (!options) return '';

    const valueArr = userField.values.reduce((acc, el) => {
        const translatedOptions = getFieldTranslatedOptions(options, language);

        const value = translatedOptions.find(({_id}) => _id === el.optionId)?.value;

        const answer =
            el.text && customField.type === customFormFieldTypes.checkboxInput
                ? `${value} ${el.text}`
                : el.text || value;

        return value ? [...acc, answer] : acc;
    }, []);

    return valueArr.join(', ');
};

export const getOptionIdFromCustomRadio = (customField, field) => {
    if (!field) return '';
    if (field.value) return field.value;

    const options = customField?.options;
    if (!options) return '';

    const optionId = field.values.reduce((acc, el) => {
        const value = el.text ? el.text : options.find(({_id}) => _id === el.optionId)?._id;
        return acc + value;
    }, '');
    return optionId;
};
