import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    root: {
        padding: '0 !important',
        marginBottom: '150px',
    },
    item: {
        color: theme.palette.text.primary,
        '& a': {
            color: theme.palette.text.primary,
        },
        '&:hover': {
            background: theme.palette.action.hover,
        },
        '&:active': {
            '& path': {
                fill: '#fff',
            },
            '& a': {
                color: '#fff',
            },
            color: '#fff',
            background: theme.palette.primary.main,
        },
    },
    itemText: {
        textTransform: 'uppercase',
        padding: '0 16px',
        fontWeight: 'bold',
        fontSize: '16px',
    },
}));
