import {allTranslations} from '../../constants/translations/all-translations';
import {monthKeyList} from '../../constants/shared';

const monthShortKeyList = monthKeyList.map((m) => `${m}Short`);

const getMonths = (dateString = '', selectedLanguageSlug = 'en', isShortVariant, isCapital) => {
    const monthNumber = new Date(dateString).getMonth();
    const translation = allTranslations[selectedLanguageSlug].content;
    const keyList = isShortVariant ? monthShortKeyList : monthKeyList;
    const monthKey = keyList[monthNumber];
    const result = translation?.time?.[monthKey] || allTranslations['en'].content?.time?.[monthKey];
    if (result && isCapital) return result.charAt(0).toUpperCase() + result.slice(1);
    return result;
};

export default getMonths;
