import {createSelector} from 'reselect';

const matchmaking = (state) => state.matchmaking;

export const getMatchMaking = createSelector(matchmaking, (state) => state);

export const getMatchMakingForm = createSelector(matchmaking, (state) => state.matchingForm);

export const getMatchMakingFormQuestions = createSelector(matchmaking, (state) => state.matchingForm.questions);

export const getMatchMakingFormLoading = createSelector(matchmaking, (state) => state.loading);

export const getMatchMakingFormId = createSelector(matchmaking, (state) => state.matchingForm._id);

export const getUserTopMatches = createSelector(matchmaking, (state) => state.matches);

export const getIsMatchBannerOpen = createSelector(matchmaking, (state) => state.isMatchBannerOpen);
