import React from 'react';
import {makeStyles} from '@material-ui/core';

import BackButtonIcon from '../../../Images/back.svg';

const useStyles = makeStyles(() => ({
    root: {
        border: 'none',
        outline: 'none',
        background: 'transparent',
        cursor: 'pointer',
        height: '24px',
    },
}));

const BackButton = ({onClickBack}) => {
    const classes = useStyles();

    return (
        <button className={classes.root}>
            <img src={BackButtonIcon} alt="Back Button" width="24" height="24" onClick={onClickBack} />
        </button>
    );
};

export default BackButton;
