export const prepareJobData = (job) => {
    return {
        title: job.title,
        description: job.description,
        location: job.location,
        fullTime: job.fullTime,
        partTime: job.partTime,
        industry: job.industry,
        salaryMin: job.salaryMin,
        salaryMax: job.salaryMax,
        currency: job.currency,
    };
};

export const findJobIndex = (jobList, job) => jobList.findIndex(({_id}) => _id === job._id);
