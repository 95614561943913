import React from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
} from '@material-ui/core';
import {usePlatformTranslation} from '../../../services/hooks';
import {useSelector} from 'react-redux';

const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        margin: 0,
        lineHeight: 1.2,
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#2B2B2B', // TODO: color
        marginBottom: 0,
    },
    text: {
        color: '#2B2B2B', // TODO: color
        '&.isRtl': {
            direction: 'rtl',
        },
    },
    button: {
        fontWeight: 'bold',
        color: '#2B2B2B', // TODO: color
        textTransform: 'uppercase',
    },
}));

const ConfirmDelete = ({
    open,
    closeConfirmDeleteDialog,
    dialogTitle,
    dialogDescription,
    handleConfirmDelete,
    dialogConfirmButtonLabel,
}) => {
    const isRtl = useSelector((state) => state.languages.isRtlLanguage);
    const {generalText} = usePlatformTranslation();
    const classes = useStyles();

    return (
        <Dialog
            className={classes.root}
            open={open}
            onClose={closeConfirmDeleteDialog}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle className={classes.title}>{dialogTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText className={`${classes.text} ${isRtl ? 'isRtl' : null}`}>
                    {dialogDescription}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button className={classes.button} onClick={closeConfirmDeleteDialog} color="primary">
                    {generalText.cancel}
                </Button>
                <Button className={classes.button} color="primary" onClick={handleConfirmDelete}>
                    {dialogConfirmButtonLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDelete;
