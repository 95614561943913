import React, {useContext, useMemo} from 'react';
import {NavLink} from 'react-router-dom';

import {usePlatformTranslation} from '../../../../../services/hooks';
import {AbilityContext} from '../../../../../permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from '../../../../../constants/ability';

import './AccountNavigationMenu.scss';
import {getAccountNavigationMenu} from '../../../../../services/helpers/myAccount';

const AccountNavigationMenu = ({eventSlug}) => {
    const ability = useContext(AbilityContext);
    const accountNavigationMenu = usePlatformTranslation(
        (translation) => translation.myAccountPage.accountNavigationMenu
    );
    const menu = useMemo(() => getAccountNavigationMenu(accountNavigationMenu), [accountNavigationMenu]);

    const isProfileShown = ability.can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.EVENT_PROFILE);
    const isAppliedJobsHidden = !ability.can(
        ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY,
        ROLE_FEATURES_TYPES.APPLIED_JOBS_TABLE
    );

    return (
        <div className="AccountNavigationMenu">
            {menu.map(({title, linkFunction, hidden}, index) => {
                const validationRules = {
                    appliedJobsHidden: isAppliedJobsHidden && title === accountNavigationMenu?.appliedJobs,
                    profileHidden: !isProfileShown && title === accountNavigationMenu?.eventProfile,
                    itemHidden: hidden,
                };

                if (Object.values(validationRules).some((rule) => Boolean(rule))) return null;

                return (
                    <div key={index} className="AccountNavigationMenu__item">
                        <NavLink
                            to={linkFunction(eventSlug)}
                            className="AccountNavigationMenu__link"
                            activeClassName="AccountNavigationMenu__link_active"
                        >
                            {title}
                        </NavLink>
                    </div>
                );
            })}
        </div>
    );
};

export default AccountNavigationMenu;
