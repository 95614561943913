import * as actionTypes from '../actions/actionTypes';
import * as eventActionTypes from '../actionTypes/eventActionTypes';
import * as userActionsTypes from '../actionTypes/userTypes';
import {updateObject} from '../utilityStateFunction';
import {cloneDeep} from 'lodash';
import {MATCHING_SKIPPED_KEY} from 'constants/matchMaking';

const initialTopNavigation = {
    seeContacts: false,
    seeProgram: false,
    seeWallet: false,
    walletConfig: {
        visible: false,
        walletTab: 'contacts',
        walletViewType: 'wallet',
    },
    agendaConfig: {
        visible: false,
        activeTab: 'program',
    },
    seeChats: false,
    seeMyEvents: false,
    seeMyBooths: false,
    seeNotifications: false,
    seeMyAccount: false,
    activeChatRoomId: null,
    isGroupChat: false,
    seeRooms: false,
    isScheduleBottomSheetOpened: false,
};

const initialSideNavigation = {
    isOpened: false,
    seeNetworking: false,
    seeLiveWall: false,
    seeVideoWall: false,
    seeInfoTab: false,
    seeSurvey: false,
    socialMedia: false,
    interpretation: false,
    wallConfig: {
        type: null,
        wallClosed: null,
        isMounted: false,
    },
    participantFilters: [],
    filteredParticipants: {
        //user in networking
        usersList: [],
        pagination: {
            page: 1,
            perPage: 50,
            total: null,
        },
    },
    loading: false,
    error: false,
};

const initialEventRoles = {
    isOrganizer: false,
    isOrganizerRep: false,
    isExhibitor: false,
    exhibitorId: null,
    isExhibitorRep: false,
    exhibitorRepIds: [],
    isParticipant: false,
    participantId: null,
};

const initialMyAccountTabs = {
    seeOnventProfile: false,
    seeEventProfile: false,
    seeEditUserInformation: false,
};

const initialChats = {
    allChats: true,
    eventChats: false,
    archivedChats: false,
};

const maximizeChatsComponent = {
    seeExpandedVideoConference: true,
};

const initialMatchmaking = {
    user: '',
    event: '',
    matchingForm: '',
    answers: [],
};

const initialState = {
    loggedIn: false,
    loading: localStorage.getItem('token') ? true : false,
    notReadChatMessages: 0,
    error: null,
    data: undefined,
    userRoles: null,
    topNavigation: {...initialTopNavigation},
    sideNavigation: {...initialSideNavigation},
    connectedToNotifications: false,
    serverDown: false,
    sessionExpired: false,
    videoConferenceCompanionDisconnected: false,
    stripeSecret: '',
    resourcesAccess: null,
    eventRoles: {...initialEventRoles},
    profileTabs: {...initialMyAccountTabs},
    maximizeChats: {...maximizeChatsComponent},
    chats: {...initialChats},
    activeChatData: null,
    preselectedAccessPackageId: null,
    showSocialShareModal: false,
    matchMaking: initialMatchmaking,
};

const reducer = (state = cloneDeep(initialState), action) => {
    let newState = cloneDeep(state);
    switch (action.type) {
        case actionTypes.CLEAR_ERROR:
            return updateObject(state, {error: null});
        case actionTypes.SERVER_DOWN:
            return updateObject(state, {serverDown: true});
        case actionTypes.SERVER_UP:
            return updateObject(state, {serverDown: false});
        case actionTypes.SESSION_EXPIRED:
            return updateObject(state, {sessionExpired: true});
        case actionTypes.VIDEO_CONFERENCE_COMPANION_DISCONNECTED:
            return updateObject(state, {
                videoConferenceCompanionDisconnected: action.payload ?? !state.videoConferenceCompanionDisconnected,
            });
        case actionTypes.LOGIN_USER_START:
            return updateObject(state, {loading: true, error: null});
        case actionTypes.LOGIN_USER_SUCCESS:
            return updateObject(state, {
                loggedIn: true,
                loading: false,
                error: null,
                sessionExpired: false,
                data: action.payload,
                serverDown: false,
            });
        case actionTypes.SET_USER_ROLES:
            return updateObject(state, {
                userRoles: action.payload,
            });
        case actionTypes.LOGIN_USER_FAIL:
            return updateObject(state, {loading: false, loggedIn: false, error: action.payload});

        case actionTypes.REGISTER_USER_START:
            return updateObject(state, {loading: true});
        case actionTypes.REGISTER_USER_SUCCESS:
            return updateObject(state, {
                loggedIn: true,
                loading: false,
                error: null,
                sessionExpired: false,
                data: action.payload,
            });
        case actionTypes.REGISTER_USER_FAIL:
            return updateObject(state, {loading: false, error: action.payload});

        case eventActionTypes.REGISTER_PARTICIPANT_START:
            return updateObject(state, {loading: true});
        case eventActionTypes.REGISTER_PARTICIPANT_SUCCESS:
            return updateObject(state, {loading: false, showSocialShareModal: true});
        case eventActionTypes.REGISTER_PARTICIPANT_FAIL:
            return updateObject(state, {loading: false});

        case eventActionTypes.REGISTER_EXHIBITOR_START:
            return updateObject(state, {loading: true});
        case eventActionTypes.REGISTER_EXHIBITOR_SUCCESS:
            return updateObject(state, {loading: false, showSocialShareModal: true});
        case eventActionTypes.REGISTER_EXHIBITOR_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.LOGOUT_USER_START:
            return updateObject(state, {loading: true});
        case actionTypes.LOGOUT_USER_SUCCESS:
            return updateObject(state, {...initialState, loading: false});
        case actionTypes.LOGOUT_USER_FAIL:
            return updateObject(state, initialState);

        case actionTypes.SEND_CONTACT_REQUEST_START:
            return updateObject(state, {loading: true});
        case actionTypes.SEND_CONTACT_REQUEST_SUCCESS:
            return updateObject(state, {loading: false});
        case actionTypes.SEND_CONTACT_REQUEST_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.ACCEPT_CONTACT_REQUEST_START:
            return updateObject(state, {loading: true});
        case actionTypes.ACCEPT_CONTACT_REQUEST_SUCCESS:
            return updateObject(state, {loading: false});
        case actionTypes.ACCEPT_CONTACT_REQUEST_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.GET_SENT_REQUESTS_START:
            return updateObject(state, {loading: true});
        case actionTypes.GET_SENT_REQUESTS_SUCCESS:
            let updatedUserSentRequests = {...state.data};
            updatedUserSentRequests = updateObject(updatedUserSentRequests, {sentRequests: action.payload});
            return updateObject(state, {loading: false, data: updatedUserSentRequests});
        case actionTypes.GET_SENT_REQUESTS_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.GET_RECEIVED_REQUESTS_START:
            return updateObject(state, {loading: true});
        case actionTypes.GET_RECEIVED_REQUESTS_SUCCESS:
            let updatedUserReceivedRequests = {...state.data};
            updatedUserReceivedRequests = updateObject(updatedUserReceivedRequests, {receivedRequests: action.payload});
            return updateObject(state, {loading: false, data: updatedUserReceivedRequests});
        case actionTypes.GET_RECEIVED_REQUESTS_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.GET_NOTIFICATIONS_START:
            return updateObject(state, {loading: true});
        case actionTypes.GET_NOTIFICATIONS_SUCCESS:
            let updatedUserNotifications = {...state.data};
            updatedUserNotifications = updateObject(updatedUserNotifications, {notifications: action.payload});
            return updateObject(state, {loading: false, data: updatedUserNotifications});
        case actionTypes.GET_NOTIFICATIONS_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.GET_CONTACTS_START:
            return updateObject(state, {loading: true});
        case actionTypes.GET_CONTACTS_SUCCESS:
            let updatedUserContacts = {...state.data};
            updatedUserContacts = updateObject(updatedUserContacts, {contacts: action.payload});
            return updateObject(state, {loading: false, data: updatedUserContacts});
        case actionTypes.GET_CONTACTS_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.SET_ACTIVE_CHAT_DATA:
            return updateObject(state, {activeChatData: {...action.payload}});
        case actionTypes.UPDATE_ACTIVE_CHAT_DATA:
            return updateObject(state, {activeChatData: {...state.activeChatData, ...action.payload}});
        case actionTypes.CLOSE_ACTIVE_CHAT:
            return updateObject(state, {
                activeChatData: null,
                topNavigation: {
                    ...state.topNavigation,
                    activeChatRoomId: null,
                    isGroupChat: false,
                },
            });
        case actionTypes.OPEN_CHAT_BUSINESS_CARD:
            return updateObject(state, {activeChatData: {...state.activeChatData, openBusinessCard: true}});
        case actionTypes.CLOSE_CHAT_BUSINESS_CARD:
            return updateObject(state, {activeChatData: {...state.activeChatData, openBusinessCard: false}});

        case actionTypes.CREATE_GROUP_CHAT_START:
            return updateObject(state, {loading: true});
        case actionTypes.CREATE_GROUP_CHAT_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: {...state.data, groupChats: [...state.data.groupChats, action.payload]},
            });
        case actionTypes.CREATE_GROUP_CHAT_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.CREATE_PRIVATE_CHAT_START:
            return updateObject(state, {loading: true});
        case actionTypes.CREATE_PRIVATE_CHAT_SUCCESS:
            return updateObject(state, {
                loading: false,
                data: {...state.data, privateChats: [...state.data.privateChats, action.payload]},
            });
        case actionTypes.CREATE_PRIVATE_CHAT_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.GET_GROUP_CHATS_START:
            return updateObject(state, {loading: true});
        case actionTypes.GET_GROUP_CHATS_SUCCESS:
            let updatedUserGroupChats = {...state.data};
            let receivedGroupChatsFromServer = cloneDeep(action.payload);

            updatedUserGroupChats = updateObject(updatedUserGroupChats, {groupChats: receivedGroupChatsFromServer});
            return updateObject(state, {loading: false, data: updatedUserGroupChats});

        case actionTypes.GET_GROUP_CHATS__FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.GET_PRIVATE_CHATS_START:
            return updateObject(state, {loading: true});

        case actionTypes.RESET_ACTIVE_CHAT_ID:
            return updateObject(state, {
                topNavigation: {
                    ...state.topNavigation,
                    activeChatRoomId: null,
                    isGroupChat: false,
                },
            });

        case actionTypes.GET_PRIVATE_CHATS_SUCCESS:
            let updatedUserPrivateChats = {...state.data};
            let receivedPrivateChatsFromServer = cloneDeep(action.payload);

            // fix for setting active chat Room Id chat messages to 0
            if (state.topNavigation.activeChatRoomId) {
                let updatedPrivateChatsIndex = receivedPrivateChatsFromServer.findIndex(
                    (chat) => chat.chat._id === state.topNavigation.activeChatRoomId
                );
                if (updatedPrivateChatsIndex !== -1) {
                    receivedPrivateChatsFromServer[updatedPrivateChatsIndex].unread = 0;
                }
            }
            updatedUserPrivateChats = updateObject(updatedUserPrivateChats, {
                privateChats: receivedPrivateChatsFromServer,
            });
            return updateObject(state, {loading: false, data: updatedUserPrivateChats});

        case actionTypes.GET_PRIVATE_CHATS__FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.OPEN_PRIVATE_CHAT:
            return updateObject(state, {
                topNavigation: {
                    ...initialTopNavigation,
                    seeChats: true,
                    activeChatRoomId: action.privateChatId,
                    isGroupChat: false,
                },
            });
        case actionTypes.OPEN_GROUP_CHAT:
            return updateObject(state, {
                topNavigation: {
                    ...initialTopNavigation,
                    seeChats: true,
                    activeChatRoomId: action.payload,
                    isGroupChat: true,
                },
            });

        case actionTypes.GET_ALL_CHATS_START:
            return updateObject(state, {loading: true});

        case actionTypes.GET_ALL_CHATS_SUCCESS:
            let updatedUserAllChats = {...state.data};
            let receivedAllChatsFromServer = cloneDeep(action.payload);

            updatedUserAllChats = updateObject(updatedUserAllChats, {
                allChats: receivedAllChatsFromServer,
            });

            return updateObject(state, {loading: false, data: updatedUserAllChats});

        case actionTypes.GET_ALL_CHATS__FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.GET_EVENT_CHATS_START:
            return updateObject(state, {loading: true});

        case actionTypes.GET_EVENT_CHATS_SUCCESS:
            let updatedUserEventChats = {...state.data};
            let receivedEventChatsFromServer = cloneDeep(action.payload);

            updatedUserEventChats = updateObject(updatedUserEventChats, {
                eventChats: receivedEventChatsFromServer,
            });

            return updateObject(state, {loading: false, data: updatedUserEventChats});

        case actionTypes.GET_EVENT_CHATS__FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.GET_ARCHIVED_CHATS_START:
            return updateObject(state, {loading: true});

        case actionTypes.GET_ARCHIVED_CHATS_SUCCESS:
            let updatedUserArchivedChats = {...state.data};
            let receivedArchivedChatsFromServer = cloneDeep(action.payload);

            updatedUserArchivedChats = updateObject(updatedUserArchivedChats, {
                archivedChats: receivedArchivedChatsFromServer,
            });

            return updateObject(state, {loading: false, data: updatedUserArchivedChats});

        case actionTypes.GET_ARCHIVED_CHATS__FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.TOP_NAV_SEE_CONTACTS:
            return updateObject(state, {
                topNavigation: {...initialTopNavigation, seeContacts: !state.topNavigation.seeContacts},
            });
        case actionTypes.TOP_NAV_SEE_PROGRAM:
            return updateObject(state, {
                topNavigation: {...initialTopNavigation, seeProgram: !state.topNavigation.seeProgram},
            });
        case actionTypes.TOP_NAV_SEE_WALLET:
            return updateObject(state, {
                topNavigation: {...initialTopNavigation, seeWallet: !state.topNavigation.seeWallet},
            });
        case actionTypes.OPEN_WALLET:
            return updateObject(state, {
                topNavigation: {
                    ...initialTopNavigation,
                    walletConfig: {
                        ...state.topNavigation.walletConfig,
                        walletTab: action.walletTab,
                        walletViewType: action.walletView,
                        visible: true,
                        cardId: action.cardId,
                        // visible: !state.topNavigation.walletConfig.visible,
                    },
                },
            });
        case actionTypes.TOP_NAV_SEE_CHATS:
            return updateObject(state, {
                topNavigation: {...initialTopNavigation, seeChats: !state.topNavigation.seeChats},
            });
        case actionTypes.TOP_NAV_SEE_MY_EVENTS:
            return updateObject(state, {
                topNavigation: {...initialTopNavigation, seeMyEvents: !state.topNavigation.seeMyEvents},
            });
        case actionTypes.TOP_NAV_SEE_MY_BOOTHS:
            return updateObject(state, {
                topNavigation: {...initialTopNavigation, seeMyBooths: !state.topNavigation.seeMyBooths},
            });
        case actionTypes.TOP_NAV_SEE_NOTIFICATIONS:
            return updateObject(state, {
                topNavigation: {...initialTopNavigation, seeNotifications: !state.topNavigation.seeNotifications},
            });
        case actionTypes.TOP_NAV_SEE_MY_ACCOUNT:
            return updateObject(state, {
                topNavigation: {...initialTopNavigation, seeMyAccount: !state.topNavigation.seeMyAccount},
            });
        case actionTypes.TOP_NAV_CLOSE:
            return updateObject(state, {topNavigation: {...initialTopNavigation}});
        case actionTypes.BOTTOM_SEE_ROOMS:
            return updateObject(state, {
                topNavigation: {...initialTopNavigation, seeRooms: !state.topNavigation.seeRooms},
            });

        case actionTypes.TOP_NAV_BOTTOM_SHEET: {
            return {
                ...state,
                topNavigation: {
                    ...initialTopNavigation,
                    isScheduleBottomSheetOpened: !state.topNavigation.isScheduleBottomSheetOpened,
                },
            };
        }
        case actionTypes.SIDE_NAV_SEE_NETWORKING:
            return updateObject(state, {
                sideNavigation: {...initialSideNavigation, isOpened: true, seeNetworking: true},
            });
        case actionTypes.SIDE_NAV_SEE_SURVEY:
            return updateObject(state, {
                sideNavigation: {...initialSideNavigation, isOpened: true, seeSurvey: true},
            });
        case actionTypes.SIDE_NAV_SEE_SOCIAL_MEDIA:
            return updateObject(state, {
                sideNavigation: {...initialSideNavigation, isOpened: true, socialMedia: true},
            });
        case actionTypes.SIDE_NAV_SEE_INTERPRETATION:
            return updateObject(state, {
                sideNavigation: {...initialSideNavigation, isOpened: true, interpretation: true},
            });

        case actionTypes.SIDE_NAV_CLOSE_SURVEY:
            return updateObject(state, {
                sideNavigation: {...initialSideNavigation, isOpened: true, seeLiveWall: true, seeSurvey: false},
            });
        case actionTypes.SIDE_NAV_SEE_LIVE_WALL:
            return updateObject(state, {sideNavigation: {...initialSideNavigation, isOpened: true, seeLiveWall: true}});
        case actionTypes.SIDE_NAV_SEE_VIDEO_WALL:
            return updateObject(state, {
                sideNavigation: {...initialSideNavigation, isOpened: true, seeVideoWall: true},
            });
        case actionTypes.SIDE_NAV_CLOSE:
            return updateObject(state, {sideNavigation: {...initialSideNavigation}});

        case actionTypes.SET_USER_EVENT_ROLES:
            return updateObject(state, {
                eventRoles: action.payload,
            });

        case actionTypes.SIDE_INFO_TAB:
            return updateObject(state, {sideNavigation: {...initialSideNavigation, isOpened: true, seeInfoTab: true}});

        case actionTypes.SET_PARTICIPANTS_FILTERS:
            return updateObject(state, {sideNavigation: {...state.sideNavigation, participantFilters: action.payload}});

        case actionTypes.SET_FILTERED_PARTICIPANTS: {
            const usersList =
                action.payload.page === 1
                    ? action.payload.users
                    : [...state.sideNavigation.filteredParticipants.usersList, ...action.payload.users];

            return updateObject(state, {
                sideNavigation: {
                    ...state.sideNavigation,
                    filteredParticipants: {
                        usersList,
                        pagination: {
                            page: action.payload.page,
                            perPage: action.payload.perPage,
                            total: action.payload.total,
                        },
                    },
                    error: false,
                    loading: false,
                },
            });
        }

        case actionTypes.SET_FILTERED_PARTICIPANTS_FAIL: {
            return updateObject(state, {
                sideNavigation: {...state.sideNavigation, error: true, loading: false},
            });
        }

        case actionTypes.SET_FILTERED_PARTICIPANTS_START: {
            return updateObject(state, {
                sideNavigation: {...state.sideNavigation, error: false, loading: true},
            });
        }

        case actionTypes.CHANGE_WALL_TYPE:
            return updateObject(state, {
                sideNavigation: {
                    ...state.sideNavigation,
                    wallConfig: updateObject(state.sideNavigation.wallConfig, {
                        type: action.payload,
                    }),
                },
            });

        case actionTypes.CHANGE_WALL_STATUS:
            return updateObject(state, {
                sideNavigation: {
                    ...state.sideNavigation,
                    wallConfig: updateObject(state.sideNavigation.wallConfig, {
                        wallClosed: action.payload,
                    }),
                },
            });

        case actionTypes.TOGGLE_WALL:
            return updateObject(state, {
                sideNavigation: {
                    ...state.sideNavigation,
                    wallConfig: updateObject(state.sideNavigation.wallConfig, {
                        isMounted: action.payload,
                    }),
                },
            });

        case actionTypes.CLOSE_WALL:
            return updateObject(state, {
                sideNavigation: {
                    wallConfig: updateObject(state.sideNavigation.wallConfig, {
                        isMounted: action.payload,
                    }),
                },
            });

        case actionTypes.SET_NOT_READ_CHAT_MESSAGES:
            return updateObject(state, {notReadChatMessages: action.payload});

        case actionTypes.NEW_PRIVATE_CHAT_MESSAGE:
            let updatedPrivateChats = cloneDeep(newState.data.privateChats);
            let updatedPrivateChatsIndex = updatedPrivateChats.findIndex(
                (chat) => chat.chat._id === action.payload.notification.objectId
            );
            updatedPrivateChats[updatedPrivateChatsIndex].chat.lastMessageAt = action.payload.message.createdAt;
            updatedPrivateChats[updatedPrivateChatsIndex].chat.lastMessageText = action.payload.message.text;
            if (action.payload.notification.incrementUnread) {
                updatedPrivateChats[updatedPrivateChatsIndex].unread++;
            }
            newState.data.privateChats = updatedPrivateChats;
            return newState;
        //return newState( newState, { data: {...state.data, privateChats: updatedPrivateChats} } );

        case actionTypes.NEW_GROUP_CHAT_MESSAGE:
            let updatedGroupChats = [...state.data.groupChats];
            let updatedGroupChatsIndex = updatedGroupChats.findIndex(
                (chat) => chat.chat._id === action.payload.notification.objectId
            );
            updatedGroupChats[updatedGroupChatsIndex].chat.lastMessageAt = action.payload.message.createdAt;
            updatedGroupChats[updatedGroupChatsIndex].chat.lastMessageText = action.payload.message.text;
            if (action.payload.notification.incrementUnread) {
                updatedGroupChats[updatedGroupChatsIndex].unread++;
            }
            return updateObject(state, {data: {...state.data, groupChats: updatedGroupChats}});

        case actionTypes.READ_MESSAGES_FOR_PRIVATE_CHAT:
            let updatedPrivateChatsUnreadMessages = [...state.data.privateChats];
            let updatedPrivateChatsUnreadMessagesIndex = updatedPrivateChatsUnreadMessages.findIndex(
                (chat) => chat.chat._id === action.payload
            );
            if (updatedPrivateChatsUnreadMessagesIndex !== -1) {
                updatedPrivateChatsUnreadMessages[updatedPrivateChatsUnreadMessagesIndex].unread = 0;
                return updateObject(state, {data: {...state.data, privateChats: updatedPrivateChatsUnreadMessages}});
            }
            return state;

        case actionTypes.READ_MESSAGES_FOR_GROUP_CHAT:
            let updatedGroupChatsUnreadMessages = [...state.data.groupChats];
            let updatedGroupChatsIndexUnreadMessages = updatedGroupChatsUnreadMessages.findIndex(
                (chat) => chat.chat._id === action.payload
            );
            if (updatedGroupChatsIndexUnreadMessages !== -1) {
                updatedGroupChatsUnreadMessages[updatedGroupChatsIndexUnreadMessages].unread = 0;
                return updateObject(state, {data: {...state.data, groupChats: updatedGroupChatsUnreadMessages}});
            }
            return state;

        case actionTypes.MARK_NOTIFICATION_AS_READ_START:
            return updateObject(state, {loading: true});
        case actionTypes.MARK_NOTIFICATION_AS_READ_SUCCESS:
            let updatedNotificationStatus = state.data.notifications.find(function (notification) {
                return notification._id === action.payload;
            });
            updatedNotificationStatus = {...updatedNotificationStatus, read: true};

            const updatedNotificationsArray = state.data.notifications.map((item) => {
                if (item._id === action.payload) {
                    return updatedNotificationStatus;
                } else {
                    return item;
                }
            });

            return updateObject(state, {
                notifications: updatedNotificationsArray,
            });
        case actionTypes.MARK_NOTIFICATION_AS_READ_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.SET_USER_CONNECTED_TO_NOTIFICATIONS:
            return updateObject(state, {connectedToNotifications: true});

        case actionTypes.CREATE_PAYMENT_INTENT_START:
            return updateObject(state, {loading: true});
        case actionTypes.CREATE_PAYMENT_INTENT_SUCCESS:
            return updateObject(state, {stripeSecret: action.payload});
        case actionTypes.RESET_EVENT_PAYMENT_LOADING:
            return updateObject(state, {loading: false});
        case actionTypes.CREATE_PAYMENT_INTENT_FAIL:
            return updateObject(state, {loading: false});

        case actionTypes.CANCEL_PAYMENT_INTENT:
            return updateObject(state, {loading: false, stripeSecret: ''});

        case actionTypes.SET_EVENT_ACCESS:
            return updateObject(state, {resourcesAccess: action.payload});

        case actionTypes.SET_PRESELECTED_PACKAGE_ID:
            return updateObject(state, {preselectedAccessPackageId: action.payload});

        case actionTypes.TOP_NAV_ONVENT_PROFILE:
            return updateObject(state, {profileTabs: {...initialMyAccountTabs, seeOnventProfile: true}});

        case actionTypes.TOP_NAV_EVENT_PROFILE:
            return updateObject(state, {profileTabs: {...initialMyAccountTabs, seeEventProfile: true}});
        case actionTypes.EXPANDED_CHATS_COMPONENT:
            return updateObject(state, {
                maximizeChats: {
                    ...maximizeChatsComponent,
                    seeExpandedVideoConference: !state.maximizeChats.seeExpandedVideoConference,
                },
            });

        case actionTypes.SET_ALL_CHATS_TAB:
            return updateObject(state, {
                chats: {...initialChats, allChats: true, eventChats: false, archivedChats: false},
            });
        case actionTypes.SET_EVENT_CHATS_TAB:
            return updateObject(state, {
                chats: {...initialChats, allChats: false, eventChats: true, archivedChats: false},
            });
        case actionTypes.SET_ARCHIVE_TAB:
            return updateObject(state, {
                chats: {...initialChats, archivedChats: true, allChats: false, eventChats: false},
            });
        case actionTypes.SET_NOT_ARCHIVE_TAB:
            return updateObject(state, {chats: {...initialChats, archivedChats: false}});

        case actionTypes.TOP_NAV_SEE_AGENDA:
            return updateObject(state, {
                topNavigation: {
                    ...initialTopNavigation,
                    agendaConfig: {
                        ...state.topNavigation.agendaConfig,
                        activeTab: action.payload.activeTab,
                        visible: !state.topNavigation.agendaConfig.visible,
                    },
                },
            });
        case eventActionTypes.HIDE_SOCIAL_SHARE_MODAL:
            return updateObject(state, {showSocialShareModal: false});

        case userActionsTypes.DELETE_MATCHING_ANSWERS_START:
        case userActionsTypes.RECALCULATE_USER_MATCHES_START:
        case userActionsTypes.POST_MATCHING_ANSWERS_START:
        case userActionsTypes.GET_USER_MATCHES_START:
        case userActionsTypes.GET_USER_MATCHING_ANSWERS_START: {
            return updateObject(state, {loading: true});
        }

        case userActionsTypes.POST_MATCHING_ANSWERS_SUCCESS: {
            return updateObject(state, {matchMaking: {answers: action.payload}, loading: false});
        }

        case userActionsTypes.GET_USER_MATCHING_ANSWERS_SUCCESS: {
            return updateObject(state, {matchMaking: action.payload, loading: false});
        }

        case userActionsTypes.DELETE_MATCHING_ANSWERS_SUCCESS: {
            const skippedEvents = JSON.parse(localStorage.getItem(MATCHING_SKIPPED_KEY));
            if (skippedEvents && skippedEvents.length) {
                const newSkippedEvents = skippedEvents.filter((id) => id !== action.payload);
                localStorage.setItem(MATCHING_SKIPPED_KEY, JSON.stringify(newSkippedEvents));
            }

            return updateObject(state, {matchMaking: {...state.matchMaking, answers: []}, loading: false});
        }

        case userActionsTypes.GET_USER_MATCHES_SUCCESS: {
            const newUsers = action.payload.matches || [];

            const usersList =
                action.payload.pagination.page === 1
                    ? action.payload.matches
                    : [...state.sideNavigation.filteredParticipants.usersList, ...newUsers];
            return updateObject(state, {
                sideNavigation: {
                    ...state.sideNavigation,
                    filteredParticipants: {
                        usersList,
                        pagination: action.payload.pagination,
                    },
                },
                error: false,
                loading: false,
            });
        }

        case userActionsTypes.RECALCULATE_USER_MATCHES_SUCCESS:
        case userActionsTypes.DELETE_MATCHING_ANSWERS_FAIL:
        case userActionsTypes.POST_MATCHING_ANSWERS_FAIL:
        case userActionsTypes.GET_USER_MATCHING_ANSWERS_FAIL:
        case userActionsTypes.RECALCULATE_USER_MATCHES_FAIL:
        case userActionsTypes.GET_USER_MATCHES_FAIL: {
            return updateObject(state, {loading: false});
        }

        case userActionsTypes.SET_COMPLETE_MATCHING: {
            const newEventsRoles = state.data.eventRoles.map((eventRole) => {
                if (eventRole.event._id !== action.payload.eventId) return eventRole;
                return {...eventRole, completeMatching: action.payload.completeMatching};
            });

            return updateObject(state, {
                loggedIn: true,
                loading: false,
                error: null,
                sessionExpired: false,
                data: {...state.data, eventRoles: newEventsRoles},
                serverDown: false,
            });
        }

        default:
            return state;
    }
};

export default reducer;
