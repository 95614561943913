import React, {Component} from 'react';
import {connect} from 'react-redux';

import * as actions from '../../../store/actions';

/**
 * Component that alerts if you click outside of it
 */
class DetectOutsideClick extends Component {
    constructor(props) {
        super(props);

        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            if (document.querySelector('#chat-more-menu')) return; //it is for chat menu modal that apears outside of body and cannot be wrapped in  DetectOutsideClick
            if (this.props.target === 'exhibitorsOverlay') {
                this.props.hideExhibitorsOverlay();
            } else if (this.props.target === 'auditoriumsSlider') {
                this.props.hideAuditoriumsSlider();
            } else {
                this.props.onCloseTopNav();
            }
        }
    }

    render() {
        return <div ref={this.setWrapperRef}>{this.props.children}</div>;
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseTopNav: () => dispatch(actions.topNavClose()),
    };
};

export default connect(null, mapDispatchToProps)(DetectOutsideClick);
