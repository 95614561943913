import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    socialButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    socialButton: {
        margin: 10,
    },
    socialIcon: {
        width: 48,
        height: 48,
    },
    socialButtonText: {
        fontStyle: 'normal',
        fontWeight: '500',
        fontSize: 12,
        textAlign: 'center',
        letterSpacing: -0.4,
        color: theme.palette.text.primary,
    },
}));
