import React from 'react';

const NetworkingIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_14591_112057)">
            <path
                d="M13 20C13 18.9 12.1 18 11 18C9.9 18 9 18.9 9 20C9 21.1 9.9 22 11 22C12.1 22 13 21.1 13 20ZM14.1 18.87C14.04 19.24 14 19.61 14 20C14 20.99 14.21 21.93 14.58 22.78C14.9 23.52 15.62 24 16.43 24L18 24L18 19.5L16.39 19.5C15.56 19.5 14.78 19.27 14.1 18.87ZM13 4C13 2.9 12.1 2 11 2C9.89999 2 8.99999 2.9 8.99999 4C8.99999 5.1 9.9 6 11 6C12.1 6 13 5.1 13 4ZM16.43 -7.50986e-08C15.62 -6.92664e-08 14.9 0.479999 14.58 1.22C14.21 2.07 14 3.01 14 4C14 4.39 14.04 4.76 14.1 5.13C14.78 4.73 15.56 4.5 16.39 4.5L18 4.5L18 -8.6403e-08L16.43 -7.50986e-08ZM13.65 7.76C13.13 8.93 12.75 10.37 12.75 12C12.75 13.63 13.14 15.07 13.65 16.24C14.13 17.32 15.21 18 16.39 18L18 18L18 6L16.39 6C15.21 6 14.13 6.68 13.65 7.76ZM16 15.93C15.77 15.84 15.61 15.8 15.31 15.02C14.93 14.05 14.75 13.03 14.75 12C14.75 10.97 14.93 9.95 15.31 8.98C15.61 8.21 15.77 8.17 16 8.07L16 15.93ZM8 12C8 11.45 8.45 11 9 11C9.55 11 10 11.45 10 12C10 12.55 9.55 13 9 13C8.45 13 8 12.55 8 12ZM6 12C6 13.66 7.34 15 9 15C10.66 15 12 13.66 12 12C12 10.34 10.66 9 9 9C7.34 9 6 10.34 6 12Z"
                fill="#9C231F"
            />
        </g>
        <defs>
            <clipPath id="clip0_14591_112057">
                <rect width="24" height="24" fill="white" transform="translate(0 24) rotate(-90)" />
            </clipPath>
        </defs>
    </svg>
);

export default NetworkingIcon;
