import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import MatchingDialogMatchedPage from './MatchingDialogMatchedPage';

export default connect(
    (state) => ({
        userLoading: selectors.getUserLoading(state),
        eventLoading: selectors.getEventLoading(state),
        matches: selectors.getUserTopMatches(state),
        user: selectors.getUserData(state),
        onlineUsers: selectors.getOnlineUsers(state),
    }),
    {
        onGetUserList: actions.getEventUsersList,
        onGetOnlineUsers: actions.getOnlineUsers,
    }
)(MatchingDialogMatchedPage);
