import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import clsx from 'clsx';
import {ReactComponent as ClockIcon} from '../../../../../../../../../../../Images/clock.svg';
import BackButtonIcon from '../../../../../../../../../../../Images/back_btn.svg';
import {ReactComponent as BcFilledIcon} from '../../../../../../../../../../../Images/business_card_filled.svg';
import {usePlatformTranslation} from '../../../../../../../../../../../services/hooks';
import {
    openChatBusinessCard,
    closeActiveChat,
    closeChatBusinessCard,
    setMeetingSetupUser,
    toggleMeetingSetup,
} from '../../../../../../../../../../../store/actions';
import {normalizeMeetingSetupUser} from '../../../../../../../../../../../services/utils/normalizers';
import UserPreviewPlateInteraction from '../../../../../../../../../SideMenu/components/Networking/components/UsersList/components/UserPreviewPlate/components/UserPreviewPlateInteraction/UserPreviewPlateInteraction';
import {moreMenuType} from '../../../ChatsHeader/chatsTypes';
import PrivateChatMoreMenu from '../../../ChatsHeader/components/ChatsMoreMenu/components/PrivateChatMoreMenu/PrivateChatMoreMenu';
import './PrivateChatHeader.scss';

const PrivateChatHeader = ({activeChatData}) => {
    const dispatch = useDispatch();
    const translation = usePlatformTranslation();
    const {isMobile} = useSelector((state) => state.layout);
    const {privateChatPartnerData, chatName, chatId, avatarSrc, showBusinessCardButton, openBusinessCard, isArchived} =
        activeChatData;

    const handleMeetingClick = () => {
        dispatch(
            setMeetingSetupUser(
                normalizeMeetingSetupUser({
                    _id: privateChatPartnerData._id,
                    first: privateChatPartnerData.first,
                    last: privateChatPartnerData.last,
                    avatarSmall: avatarSrc,
                })
            )
        );
        dispatch(toggleMeetingSetup(true));
    };

    const onOpenBusinessCard = () => {
        if (openBusinessCard) {
            dispatch(closeChatBusinessCard());
        } else {
            dispatch(openChatBusinessCard());
        }
    };

    const closeChat = () => {
        dispatch(closeActiveChat());
    };

    return (
        <div className="chat-settings">
            {isMobile && (
                <img
                    className="back-button desktop"
                    src={BackButtonIcon}
                    alt="Back Button"
                    width="24"
                    height="24"
                    onClick={closeChat}
                />
            )}
            <div
                className="user-avatar"
                style={{
                    backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${avatarSrc}')`,
                }}
            />
            <div className="private-chat-info">
                <p className="private-chat-name">{chatName}</p>
            </div>
            <div className="private-chat-btn-wrapper">
                {isMobile ? (
                    <ClockIcon className="chat-settings-icon" onClick={handleMeetingClick} />
                ) : (
                    <UserPreviewPlateInteraction
                        isDisabled={isArchived}
                        title={translation.meetings.newMeeting}
                        onClick={handleMeetingClick}
                    />
                )}
                {showBusinessCardButton && (
                    <BcFilledIcon
                        className={clsx('chat-settings-icon', openBusinessCard && 'chat-settings-icon--active')}
                        onClick={onOpenBusinessCard}
                    />
                )}
            </div>
            <PrivateChatMoreMenu
                type={moreMenuType.single}
                chatId={chatId}
                chatName={chatName}
                isArchived={isArchived}
            />
        </div>
    );
};

export default PrivateChatHeader;
