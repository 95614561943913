import React from 'react';
import {useDispatch} from 'react-redux';

import Button from '../../../../../../../Button/Button';
import {IconPlus} from '../../../../../../../Icons';
import BusinessCardEditingIconBox from '../IconBox/IconBox';
import MobileBusinessCardScene from './components/MobileBusinessCardScene/MobileBusinessCardScene';

import {useBusinessCardData, usePlatformTranslation} from 'services/hooks';
import {createBusinessCard} from 'store/actions';
import {emptyBusinessCard} from 'services/helpers';
import './MobileBusinessCardBuilder.scss';

const MobileBusinessCardBuilder = () => {
    const dispatch = useDispatch();
    const {businessCard, isLoading, isError, isReady} = useBusinessCardData();
    const {businessCard: businessCardTranslation, generalText, errors} = usePlatformTranslation();

    if (isLoading) {
        return (
            <div className="MobileBusinessCardBuilder">
                <div className="MobileBusinessCardBuilder__loader">{generalText.loading}</div>
            </div>
        );
    }

    if (isError) {
        return (
            <div className="MobileBusinessCardBuilder">
                <div className="MobileBusinessCardBuilder__error">{errors.somethingWentWrong}</div>
            </div>
        );
    }

    if (!isReady) {
        return null;
    }

    if (!businessCard) {
        return (
            <div className="MobileBusinessCardBuilder">
                <div className="MobileBusinessCardBuilder__creating">
                    <div className="MobileBusinessCardBuilder__creatingButton">
                        <Button
                            icon={<IconPlus />}
                            title={businessCardTranslation.cardSetYourUp}
                            isDisabled={!isReady}
                            onClick={() => dispatch(createBusinessCard({businessCard: emptyBusinessCard}))}
                        />
                    </div>

                    <div className="MobileBusinessCardBuilder__creatingContent">
                        <div className="MobileBusinessCardBuilder__creatingBoxIcon">
                            <BusinessCardEditingIconBox />
                        </div>

                        <div className="MobileBusinessCardBuilder__creatingMessage">
                            {businessCardTranslation.noCardAdded}
                        </div>

                        <div className="MobileBusinessCardBuilder__creatingMessage">
                            {businessCardTranslation.cardSetYourUp}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return <MobileBusinessCardScene />;
};

export default MobileBusinessCardBuilder;
