import {cloneDeep} from 'lodash';
import * as marketplaceActionTypes from '../actionTypes/exhibitorMarketplaceActionTypes';
import {updateObject} from '../utilityStateFunction';
import {loadStates} from 'constants/data';
import {findProductIndex} from 'services/helpers';

const initialState = {
    productList: [],
    loadState: '',
    editedProduct: null,
    createdProduct: null,
    isCreating: false,
    deletedProduct: null,
};

const reducer = (state = cloneDeep(initialState), action) => {
    switch (action.type) {
        case marketplaceActionTypes.LOAD_PRODUCT_LIST: {
            return updateObject(state, {productList: [], loadState: loadStates.loading});
        }

        case marketplaceActionTypes.SET_PRODUCT_LIST: {
            const {productList, error} = action.payload;
            const loadState = error ? loadStates.error : loadStates.ready;
            return updateObject(state, {productList, loadState});
        }

        case marketplaceActionTypes.CREATE_PRODUCT: {
            const {id} = action.payload;
            return updateObject(state, {
                productList: [...state.productList],
                editedProduct: null,
                createdProduct: {title: '', id},
            });
        }

        case marketplaceActionTypes.PUBLISH_PRODUCT: {
            const {product} = action.payload;
            let {productList} = state;
            const index = findProductIndex(productList, product);

            if (index > -1) {
                productList = [...productList.slice(0, index), product, ...productList.slice(index + 1)];
                return updateObject(state, {
                    productList,
                    editedProduct: null,
                });
            }

            return updateObject(state, {productList: [...state.productList, product], createdProduct: null});
        }

        case marketplaceActionTypes.EDIT_PRODUCT: {
            const {product} = action.payload;
            return updateObject(state, {editedProduct: product, createdProduct: null});
        }

        case marketplaceActionTypes.DELETE_PRODUCT: {
            const {product} = action.payload;
            let {productList} = state;
            const index = findProductIndex(productList, product);

            productList = [...productList.slice(0, index), ...productList.slice(index + 1)];
            return updateObject(state, {
                productList,
                deletedProduct: null,
            });
        }

        case marketplaceActionTypes.RESET_ACTIVE_PRODUCT: {
            return updateObject(state, {createdProduct: null, editedProduct: null});
        }

        case marketplaceActionTypes.SET_DELETED_PRODUCT: {
            const {product} = action.payload;
            return updateObject(state, {deletedProduct: product});
        }

        case marketplaceActionTypes.RESET_DELETED_PRODUCT: {
            return updateObject(state, {deletedProduct: null});
        }

        default:
            return state;
    }
};

export default reducer;
