import _ from 'lodash';
import * as actionTypes from '../../../actionTypes/organizerActionTypes/accessManagement';
import {Api} from '../../../../Api';
import {USER_ROLES} from '../../../../constants/shared';

// access groups
export const getGroupsList =
    ({userRole, isDefault, language = 'en'}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(getGroupsListStart());

        try {
            const url = `/event/${eventId}/registration-form/get-list-by-filter?type=${userRole}`;
            const response = await Api.request({
                url,
                method: 'get',
                loading,
                params: {...(_.isBoolean(isDefault) && {default: isDefault})},
            });

            const {registrationForms} = response;

            dispatch(
                userRole === USER_ROLES.participant
                    ? getParticipantsGroupsListSuccess(registrationForms)
                    : getExhibitorsGroupsListSuccess(registrationForms)
            );
        } catch (error) {
            dispatch(getGroupsListFail(error));
        }
    };

export const getGroupsListStart = () => {
    return {
        type: actionTypes.GET_GROUPS_LIST_START,
    };
};
export const getParticipantsGroupsListSuccess = (groupsList) => {
    return {
        type: actionTypes.GET_GROUPS_PARTICIPANTS_LIST_SUCCESS,
        payload: groupsList,
    };
};
export const getExhibitorsGroupsListSuccess = (groupsList) => {
    return {
        type: actionTypes.GET_GROUPS_EXHIBITORS_LIST_SUCCESS,
        payload: groupsList,
    };
};
export const getGroupsListFail = () => {
    return {
        type: actionTypes.GET_GROUPS_LIST_FAIL,
    };
};

export const setSelectedAccessGroup = (selectedGroup) => {
    return {
        type: actionTypes.SET_SELECTED_ACCESS_GROUP,
        payload: selectedGroup,
    };
};

export const addAccessGroup =
    ({accessGroup, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(addAccessGroupStart());

        try {
            const url = `/event/${eventId}/registration-form`;
            const response = await Api.request({
                url,
                method: 'post',
                payload: accessGroup,
                loading,
            });

            const data = response.accessGroup;
            dispatch(addAccessGroupSuccess(data));

            if (callback) callback();
        } catch (error) {
            dispatch(addAccessGroupFail(error));
        }
    };

export const addAccessGroupStart = () => {
    return {
        type: actionTypes.ADD_ACCESS_GROUP_START,
    };
};
export const addAccessGroupSuccess = (accessPackage) => {
    return {
        type: actionTypes.ADD_ACCESS_GROUP_SUCCESS,
        payload: accessPackage,
    };
};
export const addAccessGroupFail = () => {
    return {
        type: actionTypes.ADD_ACCESS_GROUP_FAIL,
    };
};

export const updateAccessGroup =
    ({formId, accessGroup, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(updateAccessGroupStart());

        try {
            const url = `/event/${eventId}/registration-form/${formId}`;
            const response = await Api.request({
                url,
                method: 'put',
                payload: {...accessGroup},
                loading,
            });

            const data = response.accessGroup;
            dispatch(updateAccessGroupSuccess(data));

            if (callback) callback();
        } catch (error) {
            dispatch(updateAccessGroupFail(error));
        }
    };

export const updateAccessGroupStart = () => {
    return {
        type: actionTypes.UPDATE_ACCESS_GROUP_START,
    };
};
export const updateAccessGroupSuccess = (accessGroup) => {
    return {
        type: actionTypes.UPDATE_ACCESS_GROUP_SUCCESS,
        payload: accessGroup,
    };
};
export const updateAccessGroupFail = () => {
    return {
        type: actionTypes.UPDATE_ACCESS_GROUP_FAIL,
    };
};

export const setAccessGroupImage =
    ({formId, image, callback, groupType}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(setAccessGroupImageStart());

        try {
            const url = `/event/${eventId}/registration-form/${formId}/image`;
            const {form} = await Api.request({
                url,
                method: 'put',
                payload: image,
                loading,
            });

            dispatch(setAccessGroupImageSuccess({form, groupType}));

            if (callback) callback();
        } catch (error) {
            dispatch(setAccessGroupImageFail(error));
        }
    };

export const setAccessGroupImageStart = () => {
    return {
        type: actionTypes.SET_ACCESS_GROUP_IMAGE_START,
    };
};
export const setAccessGroupImageSuccess = (data) => {
    return {
        type: actionTypes.SET_ACCESS_GROUP_IMAGE_SUCCESS,
        payload: data,
    };
};
export const setAccessGroupImageFail = () => {
    return {
        type: actionTypes.SET_ACCESS_GROUP_IMAGE_FAIL,
    };
};

export const resetAccessGroupImage =
    ({formId, callback, groupType}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(resetAccessGroupImageStart());

        try {
            const url = `/event/${eventId}/registration-form/${formId}/image`;
            const {form} = await Api.request({
                url,
                method: 'put',
                payload: {image: ''},
                loading,
            });

            dispatch(resetAccessGroupImageSuccess({form, groupType}));

            if (callback) callback();
        } catch (error) {
            dispatch(resetAccessGroupImageFail(error));
        }
    };

export const resetAccessGroupImageStart = () => {
    return {
        type: actionTypes.RESET_ACCESS_GROUP_IMAGE_START,
    };
};
export const resetAccessGroupImageSuccess = (data) => {
    return {
        type: actionTypes.RESET_ACCESS_GROUP_IMAGE_SUCCESS,
        payload: data,
    };
};
export const resetAccessGroupImageFail = (error) => {
    return {
        type: actionTypes.RESET_ACCESS_GROUP_IMAGE_FAIL,
        payload: error,
    };
};

export const duplicateAccessGroup =
    ({groupId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(duplicateAccessGroupStart());

        try {
            const url = `/event/${eventId}/registration-form/${groupId}/duplicate`;
            await Api.request({
                url,
                method: 'post',
                loading,
            });

            dispatch(duplicateAccessGroupSuccess());

            if (callback) callback();
        } catch (error) {
            dispatch(duplicateAccessGroupFail(error));
        }
    };

export const duplicateAccessGroupStart = () => {
    return {
        type: actionTypes.DUPLICATE_ACCESS_GROUP_START,
    };
};
export const duplicateAccessGroupSuccess = () => {
    return {
        type: actionTypes.DUPLICATE_ACCESS_GROUP_SUCCESS,
    };
};
export const duplicateAccessGroupFail = () => {
    return {
        type: actionTypes.DUPLICATE_ACCESS_GROUP_FAIL,
    };
};

export const deleteAccessGroup =
    ({groupId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(deleteAccessGroupStart());

        try {
            const url = `/event/${eventId}/registration-form/${groupId}`;

            await Api.request({
                url,
                method: 'delete',
                loading,
            });

            dispatch(deleteAccessGroupSuccess(groupId));

            if (callback) callback();
        } catch (error) {
            dispatch(deleteAccessGroupFail(error));
        }
    };

export const deleteAccessGroupStart = () => {
    return {
        type: actionTypes.DELETE_ACCESS_GROUP_START,
    };
};
export const deleteAccessGroupSuccess = (packageId) => {
    return {
        type: actionTypes.DELETE_ACCESS_GROUP_SUCCESS,
        payload: packageId,
    };
};
export const deleteAccessGroupFail = () => {
    return {
        type: actionTypes.DELETE_ACCESS_GROUP_FAIL,
    };
};

export const setDefaultAccessGroup =
    ({groupId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(deleteAccessGroupStart());

        try {
            const url = `/event/${eventId}/registration-form/${groupId}/toggle-default`;

            await Api.request({
                url,
                method: 'put',
                loading,
            });

            dispatch(deleteAccessGroupSuccess(groupId));

            if (callback) callback();
        } catch (error) {
            dispatch(deleteAccessGroupFail(error));
        }
    };

export const setDefaultAccessGroupStart = () => {
    return {
        type: actionTypes.SET_DEFAULT_ACCESS_GROUP_START,
    };
};
export const setDefaultAccessGroupSuccess = (groupId) => {
    return {
        type: actionTypes.SET_DEFAULT_ACCESS_GROUP_SUCCESS,
        payload: groupId,
    };
};
export const setDefaultAccessGroupFail = () => {
    return {
        type: actionTypes.SET_DEFAULT_ACCESS_GROUP_FAIL,
    };
};

// predefined fields
export const updatePredefinedFields =
    ({formId, predefinedFields, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(updatePredefinedFieldsStart());

        try {
            await Api.request({
                method: 'put',
                url: `/event/${eventId}/registration-form/${formId}/field`,
                payload: predefinedFields,
                loading,
            });

            dispatch(updatePredefinedFieldsSuccess());
            if (callback) callback();
        } catch (error) {
            dispatch(updatePredefinedFieldsFail());
        }
    };

const updatePredefinedFieldsStart = () => ({
    type: actionTypes.UPDATE_PREDEFINED_FIELDS_START,
});

const updatePredefinedFieldsSuccess = () => ({
    type: actionTypes.UPDATE_PREDEFINED_FIELDS_SUCCESS,
});

const updatePredefinedFieldsFail = () => ({
    type: actionTypes.UPDATE_PREDEFINED_FIELDS_FAIL,
});

// custom fields
export const addCustomField =
    ({groupId, customFieldData, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(addCustomFieldStart());

        try {
            const url = `/event/${eventId}/registration-form/${groupId}/custom-field`;
            const response = await Api.request({
                url,
                method: 'post',
                payload: customFieldData,
                loading,
            });

            // TODO: check
            const data = response.data;
            dispatch(addCustomFieldSuccess(data));
            if (callback) callback();
        } catch (error) {
            dispatch(addCustomFieldFail(error));
        }
    };

export const addCustomFieldStart = () => {
    return {
        type: actionTypes.ADD_CUSTOM_FIELD_START,
    };
};
export const addCustomFieldSuccess = (customFields) => {
    return {
        type: actionTypes.ADD_CUSTOM_FIELD_SUCCESS,
        payload: customFields,
    };
};
export const addCustomFieldFail = () => {
    return {
        type: actionTypes.ADD_CUSTOM_FIELD_FAIL,
    };
};

export const updateCustomFields =
    ({groupId, fieldId, customFieldData, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(updateCustomFieldsStart());

        try {
            const url = `/event/${eventId}/registration-form/${groupId}/custom-field/${fieldId}`;
            const response = await Api.request({
                url,
                method: 'put',
                payload: customFieldData,
                loading,
            });

            const data = response.data;
            dispatch(updateCustomFieldsSuccess(data));

            if (callback) callback();
        } catch (error) {
            dispatch(updateCustomFieldsFail(error));
        }
    };

export const updateCustomFieldsStart = () => {
    return {
        type: actionTypes.UPDATE_CUSTOM_FIELD_START,
    };
};
export const updateCustomFieldsSuccess = (customFields) => {
    return {
        type: actionTypes.UPDATE_CUSTOM_FIELD_SUCCESS,
        payload: customFields,
    };
};
export const updateCustomFieldsFail = () => {
    return {
        type: actionTypes.UPDATE_CUSTOM_FIELD_FAIL,
    };
};

export const deleteCustomField =
    ({groupId, fieldId}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(deleteCustomFieldStart());

        try {
            const url = `/event/${eventId}/registration-form/${groupId}/custom-field/${fieldId}`;
            await Api.request({
                url,
                method: 'delete',
                loading,
            });

            dispatch(deleteCustomFieldSuccess());
        } catch (error) {
            dispatch(deleteCustomFieldFail(error));
        }
    };

export const deleteCustomFieldStart = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_FIELD_START,
    };
};
export const deleteCustomFieldSuccess = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_FIELD_SUCCESS,
    };
};
export const deleteCustomFieldFail = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_FIELD_FAIL,
    };
};

// access packages
export const addAccessPackage =
    ({formId, accessPackage, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(addAccessPackageStart());

        try {
            const url = `/event/${eventId}/registration-form/${formId}/access`;
            const response = await Api.request({
                url,
                method: 'post',
                payload: accessPackage,
                loading,
            });

            const data = response.accessPackage;
            dispatch(addAccessPackageSuccess(data));

            callback && callback();
        } catch (error) {
            dispatch(addAccessPackageFail(error));
        }
    };

export const addAccessPackageStart = () => {
    return {
        type: actionTypes.ADD_ACCESS_PACKAGE_START,
    };
};
export const addAccessPackageSuccess = (accessPackage) => {
    return {
        type: actionTypes.ADD_ACCESS_PACKAGE_SUCCESS,
        payload: accessPackage,
    };
};
export const addAccessPackageFail = () => {
    return {
        type: actionTypes.ADD_ACCESS_PACKAGE_FAIL,
    };
};

export const updateAccessPackage =
    ({formId, packageId, accessPackage, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(updateAccessPackageStart());

        try {
            const url = `/event/${eventId}/registration-form/${formId}/access/${packageId}`;
            const response = await Api.request({
                url,
                method: 'put',
                payload: {
                    name: accessPackage.name,
                    description: accessPackage.description,
                    ...(!accessPackage.isFree && {price: accessPackage.price}),
                    isActive: accessPackage.isActive,
                    showOnRegistration: accessPackage.showOnRegistration,
                    isFree: accessPackage.isFree,
                    iframe: accessPackage.iframe,
                    type: accessPackage.type,
                    resourcesAccess: accessPackage.resourcesAccess,
                },
                loading,
            });

            callback && callback();

            const data = response.package;
            dispatch(updateAccessPackageSuccess(data));
        } catch (error) {
            dispatch(updateAccessPackageFail(error));
        }
    };

export const updateAccessPackageStart = () => {
    return {
        type: actionTypes.UPDATE_ACCESS_PACKAGE_START,
    };
};
export const updateAccessPackageSuccess = (accessPackage) => {
    return {
        type: actionTypes.UPDATE_ACCESS_PACKAGE_SUCCESS,
        payload: accessPackage,
    };
};
export const updateAccessPackageFail = () => {
    return {
        type: actionTypes.UPDATE_ACCESS_PACKAGE_FAIL,
    };
};

export const deleteAccessPackage =
    ({formId, packageId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(deleteAccessPackageStart());

        try {
            const url = `/event/${eventId}/registration-form/${formId}/access/${packageId}`;

            await Api.request({
                url,
                method: 'delete',
                loading,
            });

            dispatch(deleteAccessPackageSuccess(packageId));

            callback && callback();
        } catch (error) {
            dispatch(deleteAccessPackageFail(error));
        }
    };

export const deleteAccessPackageStart = () => {
    return {
        type: actionTypes.DELETE_ACCESS_PACKAGE_START,
    };
};
export const deleteAccessPackageSuccess = (packageId) => {
    return {
        type: actionTypes.DELETE_ACCESS_PACKAGE_SUCCESS,
        payload: packageId,
    };
};
export const deleteAccessPackageFail = () => {
    return {
        type: actionTypes.DELETE_ACCESS_PACKAGE_FAIL,
    };
};

// access
export const updateAccessResources =
    ({formId, resourcesData, groupType, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(updateAccessResourcesStart());

        try {
            const url = `/event/${eventId}/registration-form/${formId}/access/bulk-resources-update`;
            const {packages} = await Api.request({
                url,
                method: 'put',
                payload: resourcesData,
                loading,
            });

            dispatch(updateAccessResourcesSuccess({packages, groupType, formId}));

            callback && callback();
        } catch (error) {
            dispatch(updateAccessResourcesFail(error));
        }
    };

export const updateAccessResourcesStart = () => {
    return {
        type: actionTypes.UPDATE_ACCESS_RESOURCES_START,
    };
};
export const updateAccessResourcesSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_ACCESS_RESOURCES_SUCCESS,
        payload: data,
    };
};
export const updateAccessResourcesFail = () => {
    return {
        type: actionTypes.UPDATE_ACCESS_RESOURCES_FAIL,
    };
};

// badges
export const addCustomListPackage =
    ({eventId, data, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {loading},
        } = getState();

        dispatch(addCustomListStart());

        try {
            await Api.request({
                url: `/event/${eventId}/import-participants`,
                method: 'post',
                payload: data,
                loading,
            });

            dispatch(addCustomListSuccess());
            if (callback) callback();
        } catch (error) {
            dispatch(addCustomListFail());
        }
    };

const addCustomListStart = () => ({
    type: actionTypes.ADD_CUSTOM_LIST_START,
});

const addCustomListSuccess = () => ({
    type: actionTypes.ADD_CUSTOM_LIST_SUCCESS,
});

const addCustomListFail = () => ({
    type: actionTypes.ADD_CUSTOM_LIST_FAIL,
});

export const getCustomLists =
    ({eventId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {loading},
        } = getState();

        dispatch(getCustomListsStart());

        try {
            const {importedParticipants} = await Api.request({
                url: `/event/${eventId}/imported-lists`,
                method: 'get',
                loading,
            });

            dispatch(getCustomListsSuccess(importedParticipants));
            if (callback) callback(importedParticipants);
        } catch (error) {
            dispatch(getCustomListsFail());
        }
    };

const getCustomListsStart = () => ({
    type: actionTypes.GET_CUSTOM_LISTS_START,
});

const getCustomListsSuccess = (payload) => ({
    type: actionTypes.GET_CUSTOM_LISTS_SUCCESS,
    payload,
});

const getCustomListsFail = () => ({
    type: actionTypes.GET_CUSTOM_LISTS_FAIL,
});

export const deleteCustomListPackage =
    ({eventId, listId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {loading},
        } = getState();

        dispatch(deleteCustomListStart());

        try {
            await Api.request({
                url: `/event/${eventId}/imported-lists/${listId}`,
                method: 'delete',
                loading,
            });

            dispatch(deleteCustomListSuccess());
            if (callback) callback();
        } catch (error) {
            dispatch(deleteCustomListFail());
        }
    };

const deleteCustomListStart = () => ({
    type: actionTypes.DELETE_CUSTOM_LIST_START,
});

const deleteCustomListSuccess = () => ({
    type: actionTypes.DELETE_CUSTOM_LIST_SUCCESS,
});

const deleteCustomListFail = () => ({
    type: actionTypes.DELETE_CUSTOM_LIST_FAIL,
});

export const getRegistrantsList =
    ({callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(getRegistrantsListStart());

        try {
            const {registrants} = await Api.request({
                url: `/event/${eventId}/event-registrants`,
                method: 'get',
                loading,
            });

            dispatch(getRegistrantsListSuccess(registrants));
            if (callback) callback(registrants);
        } catch (error) {
            dispatch(getRegistrantsListFail());
        }
    };

const getRegistrantsListStart = () => ({
    type: actionTypes.GET_REGISTRANTS_LIST_START,
});

const getRegistrantsListSuccess = (payload) => ({
    type: actionTypes.GET_REGISTRANTS_LIST_SUCCESS,
    payload,
});

const getRegistrantsListFail = () => ({
    type: actionTypes.GET_REGISTRANTS_LIST_FAIL,
});

export const toggleBadgeField =
    ({data}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(toggleBadgeFieldStart());

        try {
            const {event} = await Api.request({
                url: `/event/${eventId}/toggle-badge-field`,
                method: 'put',
                payload: data,
                loading,
            });

            dispatch(toggleBadgeFieldSuccess(event));
        } catch (error) {
            dispatch(toggleBadgeFieldFail());
        }
    };

const toggleBadgeFieldStart = () => ({
    type: actionTypes.TOGGLE_BADGE_FIELD_START,
});

const toggleBadgeFieldSuccess = (payload) => ({
    type: actionTypes.TOGGLE_BADGE_FIELD_SUCCESS,
    payload,
});

const toggleBadgeFieldFail = () => ({
    type: actionTypes.TOGGLE_BADGE_FIELD_FAIL,
});

export const uploadBadgeImage =
    ({data}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId, loading},
        } = getState();

        dispatch(uploadBadgeImageStart());

        try {
            const {event} = await Api.request({
                url: `/event/${eventId}/badge-image`,
                method: 'put',
                payload: data,
                loading,
            });

            dispatch(uploadBadgeImageSuccess(event));
        } catch (error) {
            dispatch(uploadBadgeImageFail());
        }
    };

const uploadBadgeImageStart = () => ({
    type: actionTypes.UPLOAD_BADGE_IMAGE_START,
});

const uploadBadgeImageSuccess = (payload) => ({
    type: actionTypes.UPLOAD_BADGE_IMAGE_SUCCESS,
    payload,
});

const uploadBadgeImageFail = () => ({
    type: actionTypes.UPLOAD_BADGE_IMAGE_FAIL,
});
