import React from 'react';
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';

import {getEventParticipantRegistrationLink, getEventExhibitorRegistrationLink} from 'routes';
import {useCookiesAccept, useEventTranslation} from 'services/hooks';
import './RegisterForTheEventLink.scss';
import ArrowIcon from 'Images/arrow_left.svg';
import classNames from 'classnames';
import {USER_ROLES} from 'constants/shared';
import {reactGAEgyptRegistationTrackingEvent} from 'services/helpers/trackingHelper';

const RegisterForTheEventLink = () => {
    const eventSlug = useSelector(({event}) => event.eventSlug);
    const isLoggedIn = useSelector(({user}) => user?.loggedIn);
    const translation = useEventTranslation((translation) => translation.eventRegistration.registerForTheEvent);
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);
    const shortLink = useSelector(({event}) => event.eventShortLink);
    const packageId = useSelector(({event}) => event.eventRegPackage);
    const userRegistration = useSelector(({event}) => event.userRegistration);
    const cookiesAccepted = useCookiesAccept();

    const redirectLink =
        userRegistration && userRegistration.type === USER_ROLES.exhibitor
            ? getEventExhibitorRegistrationLink({eventSlug, isLoggedIn, shortLink, packageId})
            : getEventParticipantRegistrationLink({eventSlug, isLoggedIn, shortLink, packageId});

    const handleClick = () => reactGAEgyptRegistationTrackingEvent(cookiesAccepted);

    return (
        <Link
            onClick={handleClick}
            className={classNames('RegisterForTheEventLink', {isRtl: isRtlLanguage})}
            to={redirectLink}
        >
            {translation}
            <div>
                <img src={ArrowIcon} alt="arrow" />
            </div>
        </Link>
    );
};

export default RegisterForTheEventLink;
