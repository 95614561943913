import {createMuiTheme} from '@material-ui/core/styles';
import {mainPalette} from './mainTheme';

const egyptTheme = createMuiTheme({
    palette: {
        ...mainPalette.palette,
        primary: {
            main: '#9E7C01',
        },
        secondary: {
            main: '#00A498',
        },
        success: {
            main: '#808080',
        },
        warning: {
            main: '#FFC107',
        },
        action: {
            hover: '#ebf3ea',
            active: 'rgba(158, 124, 1, 0.1)',
        },
    },
});

export default egyptTheme;
