import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {usePlatformTranslation} from '../../../../../../../../../services/hooks';
import {getAppliedJobs} from '../../../../../../../../../store/actions';
import MobileTable from '../../../../../../../MobileTable/MobileTable';
import {getMobileTeableColoms} from '../../../../../../../../../services/helpers/AppliedJobs/appliedJobsMobileTable';
import {setAccountBackLink} from '../../../../../../../../../store/actions/accountActions';
import {getAppliedJobsLink} from '../../../../../../../../../routes';

const AppliedJobsMobileTable = ({event, userId}) => {
    const dispatch = useDispatch();

    const translation = usePlatformTranslation();
    const jobsList = useSelector((state) => state.appliedJobs.jobsList);
    const eventSlug = useSelector((state) => state.event.eventSlug);
    const columns = useMemo(() => getMobileTeableColoms(translation), [translation]);

    useEffect(() => {
        dispatch(setAccountBackLink({link: getAppliedJobsLink(eventSlug), name: event.title}));
    }, [dispatch, event.title, eventSlug]);

    useEffect(() => {
        dispatch(getAppliedJobs(userId, event.eventId));
    }, [event, userId, dispatch]);

    return (
        <div className="">
            <MobileTable fields={jobsList} titleKey={'jobTitle'} isWithStatusChip columns={columns} />
        </div>
    );
};

export default AppliedJobsMobileTable;
