import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import AnimatedTopSection from './AnimatedTopSection';
import {topNavClose} from 'store/actions';
import {getTranslation, getUserData, getIsUserLoggedIn, getEventData} from 'store/selectors';

export default connect(
    (state) => ({
        user: getUserData(state),
        loggedIn: getIsUserLoggedIn(state),
        translation: getTranslation(state),
        eventData: getEventData(state),
    }),
    {
        onCloseTopNav: topNavClose,
    }
)(withRouter(AnimatedTopSection));
