export const checkIsWebApp = () => Boolean(window.localStorage);

export const getDefaultValuesFromLocalStorage = ({key, defaultValue}) => {
    if (!checkIsWebApp()) {
        return defaultValue;
    }
    return localStorage.getItem(key) || defaultValue;
};

export const saveDefaultValuesFromLocalStorage = ({key, value}) => {
    if (!checkIsWebApp()) {
        return;
    }
    localStorage.setItem(key, value);
};

export const isMobileDevice = /Mobi/i.test(window.navigator.userAgent);

export const checkIfIsOnline = (onlineUsers, userId) => {
    if (onlineUsers && onlineUsers.length) {
        return onlineUsers.findIndex((user) => user.userId === userId) > -1;
    }
    return false;
};

export const isHaveSocialMedia = (event) => event.brandingData.socialMediaLinks.some(({link}) => link);
