import React from 'react';

import Svg from '../Svg/Svg';

import './Play.scss';

const IconPlay = () => (
    <Svg className="IconPlay">
        <path d="M8 5v14l11-7L8 5z" />
    </Svg>
);

export default IconPlay;
