import React, {useState, useCallback, useContext} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import StreamControls from './components/StreamControls';
import VideoPlayerSelfVideo from './components/VideoPlayerSelfVideo';
import VideoPlayerVideoRoom from './components/VideoPlayerVideoRoom';
import VideoPlayerButtons from './components/VideoPlayerButtons';
import VideoPlayerSpinner from './components/VideoPlayerSpinner/VideoPlayerSpinner';
import VideoPlayerFullScreenButton from './components/VideoPlayerFullScreenButton';

import {AbilityContext} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';
import {PARTICIPANT_TYPES} from 'constants/organizer/sessions';

import {useCreateRoom} from 'services/hooks/organizer/sessions/useCreateRoom';

import './VideoModule.scss';

export function VideoModule({
    toggleSessionFullScreen,
    videoCapture,
    user,
    sessionData: {sessions, client, dominantSpeakerOrder},
    mediaSession,
    isKicked,
    isFullScreen,
}) {
    const [fullScreenVideoConference, setFullScreenVideoConference] = useState(false);
    const ability = useContext(AbilityContext);
    const {loading} = useCreateRoom();

    const toggleFullScreen = useCallback(
        (value) => {
            setFullScreenVideoConference(value);
            toggleSessionFullScreen();
        },
        [toggleSessionFullScreen]
    );

    const isStreamControlsAccess = ability.can(
        ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY,
        ROLE_FEATURES_TYPES.ETX_SESSION_STREAM_CONTROLS
    );

    const isVideoStreamAccess = ability.can(
        ROLE_ABILITIES_TYPES.COMMON.CREATE_ABILITY,
        ROLE_FEATURES_TYPES.ETX_SESSION_VIDEO_STREAM
    );

    if (loading || !client) return <VideoPlayerSpinner fixedHeight="350px" />;

    if (isKicked) return null;

    const renderSessions =
        client.role === PARTICIPANT_TYPES.interpreter
            ? Object.values(sessions).find(({role}) => role === PARTICIPANT_TYPES.mixer)
            : sessions;

    return (
        <>
            <div
                className={classNames('video-module', {
                    '-fullScreen': fullScreenVideoConference,
                })}
            >
                {!!videoCapture && isVideoStreamAccess && (
                    <VideoPlayerSelfVideo videoCapture={videoCapture} user={user} sessions={sessions} />
                )}
                <VideoPlayerVideoRoom
                    sessions={renderSessions}
                    dominantSpeakerOrder={dominantSpeakerOrder}
                    isFullScreen={isFullScreen}
                />
                {mediaSession && isVideoStreamAccess && <VideoPlayerButtons withCallEndIcon={false} />}
                <VideoPlayerFullScreenButton
                    fullScreenVideoConference={fullScreenVideoConference}
                    setFullScreenVideoConference={toggleFullScreen}
                />
            </div>
            {isStreamControlsAccess && <StreamControls user={user?._id} />}
        </>
    );
}

VideoModule.propTypes = {
    videoCapture: PropTypes.object,
    toggleSessionFullScreen: PropTypes.func,
    user: PropTypes.object,
    sessionData: PropTypes.object,
    mediaSession: PropTypes.object,
    isKicked: PropTypes.bool,
    isFullScreen: PropTypes.bool,
};
