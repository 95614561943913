import {eventFolder, getPlatformBackground, whiteLabel} from 'config';

const BackgroundImage = getPlatformBackground(whiteLabel);

export const getBackgroundImageUrl = (eventId, image) => {
    if (!image || !image?.resize1080 || !image?.resize2160 || !image?.original || !eventId) return BackgroundImage;

    let backgroundImage;
    const filesUrl = `${eventFolder}${eventId}/`;

    if (window.innerHeight < 1081) {
        backgroundImage = image?.resize1080;
    } else if (window.innerHeight < 2161) {
        backgroundImage = image?.resize2160;
    } else {
        backgroundImage = image?.original;
    }

    return filesUrl + (backgroundImage || image?.original);
};
