import React from 'react';
import './MatchingDialogEmptyPage.scss';
import {IconMatchingSearch} from 'Routes/components/Icons';
import useTheme from '@material-ui/core/es/styles/useTheme';

const MatchingDialogEmptyPage = () => {
    const theme = useTheme();

    return (
        <div className="matching-dialog-empty-page">
            <div className="matching-dialog-empty-page__wrapper">
                <IconMatchingSearch className="matching-dialog-empty-page__icon" color={theme.palette.primary.main} />
                <div className="matching-dialog-empty-page__info">
                    <p className="matching-dialog-empty-page__info-text">
                        Thanks for trying the matchmaking function! We are searching for your best matches and will pair
                        your as soon as more participants join the event. Please check “Matches” tab in the “Networking”
                        section
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MatchingDialogEmptyPage;
