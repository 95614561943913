export const initialState = {
    generalInfo: {
        activeVideoCallType: 'oneToOne',
        incomingOneToOneCallUserId: null,
    },
    oneToOne: {
        isActive: false,
        isLoading: false,
        isCalling: false,
        isScreenShared: false,
        isParticipantStreamActive: false,
        peerConnection: null,
        capture: null,
        companionStream: null,
        shareScreenCapture: null,
        companionId: null,
        incomingCallOffer: null,
        iceCandidates: [],
        iceServers: null,
    },
    group: {
        isActive: false,
        isLoading: false,
        isCalling: false,
        isScreenShared: false,
        capture: null,
        selfPlayback: null,
        playbacks: [],
    },
};
