// access groups
export const GET_GROUPS_LIST_START = 'ACCESS_MANAGEMENT/GET_GROUPS_LIST_START';
export const GET_GROUPS_PARTICIPANTS_LIST_SUCCESS = 'ACCESS_MANAGEMENT/GET_GROUPS_PARTICIPANTS_LIST_SUCCESS';
export const GET_GROUPS_EXHIBITORS_LIST_SUCCESS = 'ACCESS_MANAGEMENT/GET_GROUPS_EXHIBITORS_LIST_SUCCESS';
export const GET_GROUPS_LIST_FAIL = 'ACCESS_MANAGEMENT/GET_GROUPS_LIST_FAIL';

export const ADD_ACCESS_GROUP_START = 'ACCESS_MANAGEMENT/ADD_ACCESS_GROUP_START';
export const ADD_ACCESS_GROUP_SUCCESS = 'ACCESS_MANAGEMENT/ADD_ACCESS_GROUP_SUCCESS';
export const ADD_ACCESS_GROUP_FAIL = 'ACCESS_MANAGEMENT/ADD_ACCESS_GROUP_FAIL';

export const SET_SELECTED_ACCESS_GROUP = 'SET_SELECTED_ACCESS_GROUP';

export const UPDATE_ACCESS_GROUP_START = 'ACCESS_MANAGEMENT/UPDATE_ACCESS_GROUP_START';
export const UPDATE_ACCESS_GROUP_SUCCESS = 'ACCESS_MANAGEMENT/UPDATE_ACCESS_GROUP_SUCCESS';
export const UPDATE_ACCESS_GROUP_FAIL = 'ACCESS_MANAGEMENT/UPDATE_ACCESS_GROUP_FAIL';

export const SET_ACCESS_GROUP_IMAGE_START = 'ACCESS_MANAGEMENT/SET_ACCESS_GROUP_IMAGE_START';
export const SET_ACCESS_GROUP_IMAGE_SUCCESS = 'ACCESS_MANAGEMENT/SET_ACCESS_GROUP_IMAGE_SUCCESS';
export const SET_ACCESS_GROUP_IMAGE_FAIL = 'ACCESS_MANAGEMENT/SET_ACCESS_GROUP_IMAGE_FAIL';

export const RESET_ACCESS_GROUP_IMAGE_START = 'ACCESS_MANAGEMENT/RESET_ACCESS_GROUP_IMAGE_START';
export const RESET_ACCESS_GROUP_IMAGE_SUCCESS = 'ACCESS_MANAGEMENT/RESET_ACCESS_GROUP_IMAGE_SUCCESS';
export const RESET_ACCESS_GROUP_IMAGE_FAIL = 'ACCESS_MANAGEMENT/RESET_ACCESS_GROUP_IMAGE_FAIL';

export const DUPLICATE_ACCESS_GROUP_START = 'ACCESS_MANAGEMENT/DUPLICATE_ACCESS_GROUP_START';
export const DUPLICATE_ACCESS_GROUP_SUCCESS = 'ACCESS_MANAGEMENT/DUPLICATE_ACCESS_GROUP_SUCCESS';
export const DUPLICATE_ACCESS_GROUP_FAIL = 'ACCESS_MANAGEMENT/DUPLICATE_ACCESS_GROUP_FAIL';

export const DELETE_ACCESS_GROUP_START = 'ACCESS_MANAGEMENT/DELETE_ACCESS_GROUP_START';
export const DELETE_ACCESS_GROUP_SUCCESS = 'ACCESS_MANAGEMENT/DELETE_ACCESS_GROUP_SUCCESS';
export const DELETE_ACCESS_GROUP_FAIL = 'ACCESS_MANAGEMENT/DELETE_ACCESS_GROUP_FAIL';

export const SET_DEFAULT_ACCESS_GROUP_START = 'ACCESS_MANAGEMENT/SET_DEFAULT_ACCESS_GROUP_START';
export const SET_DEFAULT_ACCESS_GROUP_SUCCESS = 'ACCESS_MANAGEMENT/SET_DEFAULT_ACCESS_GROUP_SUCCESS';
export const SET_DEFAULT_ACCESS_GROUP_FAIL = 'ACCESS_MANAGEMENT/SET_DEFAULT_ACCESS_GROUP_FAIL';

// predefined fields
export const UPDATE_PREDEFINED_FIELDS_START = 'ACCESS_MANAGEMENT/UPDATE_PREDEFINED_FIELDS_START';
export const UPDATE_PREDEFINED_FIELDS_SUCCESS = 'ACCESS_MANAGEMENT/UPDATE_PREDEFINED_FIELDS_SUCCESS';
export const UPDATE_PREDEFINED_FIELDS_FAIL = 'ACCESS_MANAGEMENT/UPDATE_PREDEFINED_FIELDS_FAIL';

// custom fields
export const ADD_CUSTOM_FIELD_START = 'ACCESS_MANAGEMENT/ADD_CUSTOM_FIELD_START';
export const ADD_CUSTOM_FIELD_SUCCESS = 'ACCESS_MANAGEMENT/ADD_CUSTOM_FIELD_SUCCESS';
export const ADD_CUSTOM_FIELD_FAIL = 'ACCESS_MANAGEMENT/ADD_CUSTOM_FIELD_FAIL';

export const UPDATE_CUSTOM_FIELD_START = 'ACCESS_MANAGEMENT/UPDATE_CUSTOM_FIELD_START';
export const UPDATE_CUSTOM_FIELD_SUCCESS = 'ACCESS_MANAGEMENT/UPDATE_CUSTOM_FIELD_SUCCESS';
export const UPDATE_CUSTOM_FIELD_FAIL = 'ACCESS_MANAGEMENT/UPDATE_CUSTOM_FIELD_FAIL';

export const DELETE_CUSTOM_FIELD_START = 'ACCESS_MANAGEMENT/DELETE_CUSTOM_FIELD_START';
export const DELETE_CUSTOM_FIELD_SUCCESS = 'ACCESS_MANAGEMENT/DELETE_CUSTOM_FIELD_SUCCESS';
export const DELETE_CUSTOM_FIELD_FAIL = 'ACCESS_MANAGEMENT/DELETE_CUSTOM_FIELD_FAIL';

// access packages
export const ADD_ACCESS_PACKAGE_START = 'ACCESS_MANAGEMENT/ADD_ACCESS_PACKAGE_START';
export const ADD_ACCESS_PACKAGE_SUCCESS = 'ACCESS_MANAGEMENT/ADD_ACCESS_PACKAGE_SUCCESS';
export const ADD_ACCESS_PACKAGE_FAIL = 'ACCESS_MANAGEMENT/ADD_ACCESS_PACKAGE_FAIL';

export const UPDATE_ACCESS_PACKAGE_START = 'ACCESS_MANAGEMENT/UPDATE_ACCESS_PACKAGE_START';
export const UPDATE_ACCESS_PACKAGE_SUCCESS = 'ACCESS_MANAGEMENT/UPDATE_ACCESS_PACKAGE_SUCCESS';
export const UPDATE_ACCESS_PACKAGE_FAIL = 'ACCESS_MANAGEMENT/UPDATE_ACCESS_PACKAGE_FAIL';

export const DELETE_ACCESS_PACKAGE_START = 'ACCESS_MANAGEMENT/DELETE_ACCESS_PACKAGE_START';
export const DELETE_ACCESS_PACKAGE_SUCCESS = 'ACCESS_MANAGEMENT/DELETE_ACCESS_PACKAGE_SUCCESS';
export const DELETE_ACCESS_PACKAGE_FAIL = 'ACCESS_MANAGEMENT/DELETE_ACCESS_PACKAGE_FAIL';

// access
export const UPDATE_ACCESS_RESOURCES_START = 'ACCESS_MANAGEMENT/UPDATE_ACCESS_RESOURCES_START';
export const UPDATE_ACCESS_RESOURCES_SUCCESS = 'ACCESS_MANAGEMENT/UPDATE_ACCESS_RESOURCES_SUCCESS';
export const UPDATE_ACCESS_RESOURCES_FAIL = 'ACCESS_MANAGEMENT/UPDATE_ACCESS_RESOURCES_FAIL';

// custom list
export const ADD_CUSTOM_LIST_START = 'ACCESS_MANAGEMENT/ADD_CUSTOM_LIST_START';
export const ADD_CUSTOM_LIST_SUCCESS = 'ACCESS_MANAGEMENT/ADD_CUSTOM_LIST_SUCCESS';
export const ADD_CUSTOM_LIST_FAIL = 'ACCESS_MANAGEMENT/ADD_CUSTOM_LIST_FAIL';

export const DELETE_CUSTOM_LIST_START = 'ACCESS_MANAGEMENT/DELETE_CUSTOM_LIST_START';
export const DELETE_CUSTOM_LIST_SUCCESS = 'ACCESS_MANAGEMENT/DELETE_CUSTOM_LIST_SUCCESS';
export const DELETE_CUSTOM_LIST_FAIL = 'ACCESS_MANAGEMENT/DELETE_CUSTOM_LIST_FAIL';

export const GET_CUSTOM_LISTS_START = 'ACCESS_MANAGEMENT/GET_CUSTOM_LISTS_START';
export const GET_CUSTOM_LISTS_SUCCESS = 'ACCESS_MANAGEMENT/GET_CUSTOM_LISTS_SUCCESS';
export const GET_CUSTOM_LISTS_FAIL = 'ACCESS_MANAGEMENT/GET_CUSTOM_LISTS_FAIL';

// registrants
export const GET_REGISTRANTS_LIST_START = 'ACCESS_MANAGEMENT/GET_REGISTRANTS_LIST_START';
export const GET_REGISTRANTS_LIST_SUCCESS = 'ACCESS_MANAGEMENT/GET_REGISTRANTS_LIST_SUCCESS';
export const GET_REGISTRANTS_LIST_FAIL = 'ACCESS_MANAGEMENT/GET_REGISTRANTS_LIST_FAIL';

// badge
export const TOGGLE_BADGE_FIELD_START = 'ACCESS_MANAGEMENT/TOGGLE_BADGE_FIELD_START';
export const TOGGLE_BADGE_FIELD_SUCCESS = 'ACCESS_MANAGEMENT/TOGGLE_BADGE_FIELD_SUCCESS';
export const TOGGLE_BADGE_FIELD_FAIL = 'ACCESS_MANAGEMENT/TOGGLE_BADGE_FIELD_FAIL';

export const UPLOAD_BADGE_IMAGE_START = 'ACCESS_MANAGEMENT/UPLOAD_BADGE_IMAGE_START';
export const UPLOAD_BADGE_IMAGE_SUCCESS = 'ACCESS_MANAGEMENT/UPLOAD_BADGE_IMAGE_SUCCESS';
export const UPLOAD_BADGE_IMAGE_FAIL = 'ACCESS_MANAGEMENT/UPLOAD_BADGE_IMAGE_FAIL';
