import React from 'react';
import PropTypes from 'prop-types';
import './MatchingCheckBox.scss';
import {Checkbox, FormControlLabel} from '@material-ui/core';

const MatchingCheckBox = ({question, value, onChange}) => {
    return (
        <>
            {question.options.map((option, i) => {
                const isSeleted = !!value.find(({optionId}) => optionId === option._id);
                return (
                    <FormControlLabel
                        key={option._id}
                        classes={{label: 'matching-dialog-questions-page__form-label'}}
                        control={
                            <Checkbox
                                color="primary"
                                checked={isSeleted}
                                value={option._id}
                                onChange={onChange}
                                name={question._id}
                            />
                        }
                        label={option.title}
                    />
                );
            })}
        </>
    );
};

MatchingCheckBox.propTypes = {
    onChange: PropTypes.func.isRequired,
    question: PropTypes.object.isRequired,
    value: PropTypes.array.isRequired,
    className: PropTypes.string,
};

MatchingCheckBox.defaultProps = {
    value: [],
};

export default MatchingCheckBox;
