import React, {useState} from 'react';
import {TextField} from '@material-ui/core';

const TextInput = ({fieldData, changeField}) => {
    const [value, setValue] = useState('');

    const onChange = (event) => {
        setValue(event.target.value);
        changeField({id: fieldData._id, value: event.target.value.trim()});
    };

    return (
        <TextField
            id={fieldData._id}
            type="text"
            size="small"
            value={value}
            onChange={onChange}
            variant="outlined"
            fullWidth={true}
            autoFocus={true}
        />
    );
};

export default TextInput;
