import * as actionTypes from '../../actionTypes/eventActionTypes';
import {Api} from 'Api';

export const addSpeaker = (eventId, participantId) => async (dispatch, getState) => {
    const {
        event: {loading},
    } = getState();

    dispatch(addSpeakerStart());

    try {
        await Api.request({
            method: 'post',
            url: `/event/${eventId}/add-speaker`,
            payload: {participantId},
            loading,
        });

        dispatch(addSpeakerSuccess(participantId));
    } catch (error) {
        dispatch(addSpeakerFail(error));
    }
};

export const addSpeakerStart = () => {
    return {
        type: actionTypes.ADD_SPEAKER_START,
    };
};
export const addSpeakerSuccess = (speaker) => {
    return {
        type: actionTypes.ADD_SPEAKER_SUCCESS,
        payload: speaker,
    };
};
export const addSpeakerFail = () => {
    return {
        type: actionTypes.ADD_SPEAKER_FAIL,
    };
};

export const removeSpeaker = (eventId, participantId) => async (dispatch, getState) => {
    const {
        event: {loading},
    } = getState();

    dispatch(removeSpeakerStart());

    try {
        await Api.request({
            method: 'delete',
            url: `/event/${eventId}/remove-speaker`,
            payload: {participantId: participantId},
            loading,
        });

        dispatch(removeSpeakerSuccess(participantId));
    } catch (error) {
        dispatch(removeSpeakerFail(error));
    }
};

export const removeSpeakerStart = () => {
    return {
        type: actionTypes.REMOVE_SPEAKER_START,
    };
};
export const removeSpeakerSuccess = (speaker) => {
    return {
        type: actionTypes.REMOVE_SPEAKER_SUCCESS,
        payload: speaker,
    };
};
export const removeSpeakerFail = () => {
    return {
        type: actionTypes.REMOVE_SPEAKER_FAIL,
    };
};

export const addTimeSlot = (auditoriumId, timeSlot) => async (dispatch) => {
    dispatch(addTimeSlotStart());

    try {
        const response = await Api.request({
            method: 'post',
            url: `/auditoriums/${auditoriumId}/program`,
            payload: timeSlot,
        });

        dispatch(addTimeSlotSuccess(response));
    } catch (error) {
        dispatch(addTimeSlotFail(error));
    }
};

export const addTimeSlotStart = () => {
    return {
        type: actionTypes.ADD_TIME_SLOT_START,
    };
};
export const addTimeSlotSuccess = (timeSlotData) => {
    return {
        type: actionTypes.ADD_TIME_SLOT_SUCCESS,
        payload: timeSlotData,
    };
};
export const addTimeSlotFail = () => {
    return {
        type: actionTypes.ADD_TIME_SLOT_FAIL,
    };
};

const createTimeSlotStart = () => ({
    type: actionTypes.CREATE_TIME_SLOT_START,
});

const createTimeSlotSuccess = (data) => ({
    type: actionTypes.CREATE_TIME_SLOT_SUCCESS,
    payload: data,
});

const createTimeSlotFail = (error) => ({
    type: actionTypes.CREATE_TIME_SLOT_FAIL,
    payload: error,
});

export const createTimeSlot = ({payload, auditoriumId, callback}) => {
    return async (dispatch, getState) => {
        dispatch(createTimeSlotStart());
        try {
            const {
                event: {eventId},
            } = getState();
            await Api.request({
                method: 'post',
                url: `/event/${eventId}/auditoriums/${auditoriumId}/program`,
                payload,
            });

            dispatch(createTimeSlotSuccess());
            callback && callback();
        } catch (error) {
            dispatch(createTimeSlotFail(error));
        }
    };
};

const addTimeslotProgramStart = () => ({
    type: actionTypes.ADD_TIME_SLOT_PROGRAM_START,
});

const addTimeslotProgramSuccess = (data) => ({
    type: actionTypes.ADD_TIME_SLOT_PROGRAM_SUCCESS,
    payload: data,
});

const addTimeslotProgramFail = (error) => ({
    type: actionTypes.ADD_TIME_SLOT_PROGRAM_FAIL,
    payload: error,
});

export const addTimeslotProgram = ({payload, auditoriumId, callback}) => {
    return async (dispatch, getState) => {
        dispatch(addTimeslotProgramStart());
        try {
            const {
                event: {eventId},
            } = getState();
            await Api.request({
                method: 'post',
                url: `/event/${eventId}/auditoriums/${auditoriumId}/add-display-program`,
                payload,
            });

            dispatch(addTimeslotProgramSuccess());
            callback && callback();
        } catch (error) {
            dispatch(addTimeslotProgramFail(error));
        }
    };
};

export const setEditedBuilding = (building) => {
    return {
        type: actionTypes.SET_EDITED_BUILDING,
        payload: building,
    };
};

export const setEditedBuildingId = (buildingId) => {
    return {
        type: actionTypes.SET_EDITED_BUILDING_ID,
        payload: buildingId,
    };
};

export const setEditedBuildingTab = (buildingTab) => {
    return {
        type: actionTypes.SET_EDITED_BUILDING_TAB,
        payload: buildingTab,
    };
};

export const setOrganizerBrandingLanguage = (language) => {
    return {
        type: actionTypes.SET_ORGANIZER_BRANDING_LANGUAGE,
        payload: language,
    };
};

export const getCustomUsers = (eventId) => async (dispatch, getState) => {
    const {
        event: {loading},
    } = getState();

    dispatch(getCustomUsersStart());

    try {
        const {importedParticipants} = await Api.request({
            method: 'get',
            url: `/event/${eventId}/imported-lists`,
            loading,
        });

        dispatch(getCustomUsersSuccess(importedParticipants));
    } catch (error) {
        dispatch(getCustomUsersFail(error));
    }
};

export const getCustomUsersStart = () => {
    return {
        type: actionTypes.GET_CUSTOM_USERS_START,
    };
};
export const getCustomUsersSuccess = (speaker) => {
    return {
        type: actionTypes.GET_CUSTOM_USERS_SUCCESS,
        payload: speaker,
    };
};
export const getCustomUsersFail = () => {
    return {
        type: actionTypes.GET_CUSTOM_USERS_FAIL,
    };
};

export const addCustomUsers = (eventId, data) => async (dispatch, getState) => {
    const {
        event: {loading},
    } = getState();

    dispatch(addCustomUsersStart());

    try {
        const response = await Api.request({
            method: 'post',
            url: `/event/${eventId}/import-participants`,
            payload: data,
            loading,
            headers: {
                'content-type': 'multipart/form-data',
            },
            errorObj: {
                show: true,
            },
        });

        dispatch(addCustomUsersSuccess(response));
    } catch (error) {
        dispatch(addCustomUsersFail(error));

        // handle backend validation error
        error.status === 400 && Api.handleError(error, {show: true, message: error.data.error});
    }
};

export const addCustomUsersStart = () => {
    return {
        type: actionTypes.ADD_CUSTOM_USERS_START,
    };
};
export const addCustomUsersSuccess = (list) => {
    return {
        type: actionTypes.ADD_CUSTOM_USERS_SUCCESS,
        payload: list,
    };
};
export const addCustomUsersFail = () => {
    return {
        type: actionTypes.ADD_CUSTOM_USERS_FAIL,
    };
};

export const deleteCustomUsers = (eventId, listId) => async (dispatch, getState) => {
    const {
        event: {loading},
    } = getState();

    dispatch(deleteCustomUsersStart());

    try {
        await Api.request({
            method: 'delete',
            url: `/event/${eventId}/imported-lists/${listId}`,
            loading,
        });

        dispatch(deleteCustomUsersSuccess(listId));
    } catch (error) {
        dispatch(deleteCustomUsersFail(error));
    }
};

export const deleteCustomUsersStart = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_USERS_START,
    };
};
export const deleteCustomUsersSuccess = (listId) => {
    return {
        type: actionTypes.DELETE_CUSTOM_USERS_SUCCESS,
        payload: {listId},
    };
};
export const deleteCustomUsersFail = () => {
    return {
        type: actionTypes.DELETE_CUSTOM_USERS_FAIL,
    };
};
