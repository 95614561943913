export const initialState = {
    editBuilding: {
        editedBuilding: null,
        editedBuildingId: null,
        editedBuildingTab: 'settings',
    },
    eventStatistics: {
        overall: {
            loadingStatistics: false,
            allUsageStatistics: [],
            totalsStatistics: [],
            eventActivityStatistics: [],
            reportRawData: null,
        },
        walls: {
            loadingStatistics: false,
            allWallsStatistics: [],
            liveWallsStatistics: [],
            boothWallsStatistics: [],
            videoWallsStatistics: [],
            reportRawData: {},
        },
        auditoriums: {
            loadingStatistics: false,
            usersLists: [],
            individualTypes: [],
            auditoriumTimeslots: [],
            timeslotsPagination: {
                page: 1,
                perPage: 4,
                total: null,
            },
        },
        exhibitors: {
            loadingStatistics: false,
            page: 1,
            pages: 1,
            perPage: 3,
            data: [],
            usersLists: [],
            individualTypes: [],
        },
        polls: {
            loadingStatistics: false,
            pollWallsStatistics: [],
            usersLists: [],
        },
        networking: {
            loadingStatistics: false,
            statistics: [],
            topPostsUsers: [],
        },
    },
    eventSetup: {
        uploadCustomLogoLoading: false,
        uploadWelcomeVideoLoading: false,
        uploadAuditoriumNoteLoading: false,
        uploadExhibitorsNoteLoading: false,
        closeMessageLoading: false,
        team: {
            loading: false,
            coOrganizersList: null,
            coOrganizersShortList: null,
            coOrganizerIsAdded: '',
            error: null,
        },
    },
    eventBranding: {
        survey: {
            loading: false,
            questions: [],
        },
    },
    auditoriumSetup: {
        loading: false,
        error: null,
        polls: [],
    },
    eventAccessManagement: {
        loading: false,
        participantsGroups: [],
        exhibitorsGroups: [],
        selectedGroup: {},
    },
    customForm: {
        fields: [],
        loading: false,
        type: null,
    },
};
