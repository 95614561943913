import Chats from './Chats';
import {connect} from 'react-redux';
import * as actions from '../../../../../../../store/actions';
import * as selectors from '../../../../../../../store/selectors';

export default connect(
    (state) => ({
        user: selectors.getUserData(state),
        activeChatRoomId: selectors.getUserActiveChatRoomId(state),
        activeChatData: selectors.getUserActiveChatData(state),
        isGroupChat: selectors.getUserIsGroupChat(state),
        resourcesAccess: selectors.getUserResourcesAccess(state),
        onlineUsers: selectors.getOnlineUsers(state),
        event: selectors.getEventData(state),
        topNavigation: selectors.getUserTopNavigation(state),
        maximizeChats: selectors.getUserMaximizeChats(state),
        isLargeScreen: selectors.getIsLargeScreen(state),
        isTablet: selectors.getIsTablet(state),
        isMobile: selectors.getIsMobile(state),
        videoConference: selectors.getActiveCallTypeData(state),
        languages: selectors.getLanguages(state),
        isRtlLanguage: selectors.getIsRtlLanguage(state),
    }),
    {
        onGetAllChats: actions.getAllChats,
        onGetEventChats: actions.getEventChats,
        onGetArchivedChats: actions.getArchivedChats,
        onGetPrivateChats: actions.getPrivateChats,
        onGetGroupChats: actions.getGroupChats,
        onOpenGroupChat: actions.openGroupChat,
        onOpenPrivateChat: actions.openPrivateChat,
        readMessagesForPrivateChat: actions.readMessagesForPrivateChat,
        readMessagesForGroupChat: actions.readMessagesForGroupChat,
        onCloseActiveChat: actions.closeActiveChat,
        onGetOnlineUsers: actions.getOnlineUsers,
        onCloseTopNav: actions.topNavClose,
        seeChats: actions.topNavSeeChats,
        seeExpandedVideoConference: actions.expandedChatsOnVideoConference,
        closeVideoConference: actions.closeVideoConference,
    }
)(Chats);
