import React, {useState, useRef, useContext, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useHistory, useLocation} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {
    NotificationsActiveOutlined,
    Close,
    Mic as MicIcon,
    MicOff as MicOffIcon,
    Videocam as VideocamIcon,
    VideocamOff as VideocamOffIcon,
} from '@material-ui/icons';
import classNames from 'classnames';

import {MEDIA_TYPES} from 'constants/organizer/sessions';
import {AbilityContext} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';
import testSpeakersAudio from 'assets/audio/test-speaker-audio.mp3';

import {useStyles} from './ControlButtons.styles';

export const ControlButtons = ({capture, joinSessionDashboard, clearCallState, toggleSelfVideo, toggleSelfAudio}) => {
    const history = useHistory();
    const location = useLocation();
    const classes = useStyles();
    const ability = useContext(AbilityContext);

    const audioRef = useRef(null);
    const redirectDataRef = useRef(null);

    useEffect(() => {
        if (location.state) redirectDataRef.current = location.state;
    }, [location]);

    const [micEnabled, setMicEnabled] = useState(true);
    const [camEnabled, setCamEnabled] = useState(true);

    const onToggleMediaDevice = (mediaKind) => () => {
        const enabled = mediaKind === MEDIA_TYPES.audio ? micEnabled : camEnabled;
        const setEnabled = mediaKind === MEDIA_TYPES.audio ? setMicEnabled : setCamEnabled;
        mediaKind === MEDIA_TYPES.audio ? toggleSelfAudio() : toggleSelfVideo();

        capture.getTracks().forEach((track) => {
            if (track.kind === mediaKind) {
                track.enabled = !enabled;
                setEnabled(!enabled);
            }
        });
    };

    const onTestSpeakerAudio = () => audioRef?.current.play();

    const onCancelJoinSession = () => {
        capture && capture.getTracks().forEach((track) => track.stop());
        clearCallState();
        if (redirectDataRef.current?.prevLocation) {
            return history.push({
                pathname: redirectDataRef.current.prevLocation,
                state: redirectDataRef.current,
            });
        }
        history.goBack();
    };

    const onJoinSession = () => joinSessionDashboard();

    const isInterpreterAccess = ability.can(
        ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY,
        ROLE_FEATURES_TYPES.INTERPRETER_DEVICES_CHECK
    );

    const interpreterButton = isInterpreterAccess ? (
        <Button
            variant="contained"
            startIcon={<NotificationsActiveOutlined />}
            className={classes.button}
            color="primary"
            onClick={onTestSpeakerAudio}
        >
            test speaker
        </Button>
    ) : (
        <Button
            variant="contained"
            startIcon={camEnabled ? <VideocamIcon /> : <VideocamOffIcon />}
            className={classes.button}
            color="primary"
            onClick={onToggleMediaDevice(MEDIA_TYPES.video)}
            disabled={!capture}
        >
            {`${camEnabled ? 'stop' : 'start'} video`}
        </Button>
    );

    return (
        <div className={classes.wrapper}>
            <div>
                <Button
                    variant="contained"
                    startIcon={micEnabled ? <MicIcon /> : <MicOffIcon />}
                    className={classes.button}
                    color="primary"
                    onClick={onToggleMediaDevice(MEDIA_TYPES.audio)}
                    disabled={!capture}
                >
                    {micEnabled ? 'mute' : 'unmute'}
                </Button>
                {interpreterButton}
            </div>
            <div>
                <Button
                    variant="outlined"
                    startIcon={<Close />}
                    color="primary"
                    onClick={onCancelJoinSession}
                    className={classNames(classes.button, classes.buttonOutlined)}
                >
                    cancel
                </Button>
                <Button
                    onClick={onJoinSession}
                    variant="contained"
                    className={classes.button}
                    color="primary"
                    disabled={!capture}
                >
                    join the session
                </Button>
            </div>
            <audio ref={audioRef} src={testSpeakersAudio} hidden />
        </div>
    );
};

ControlButtons.propTypes = {
    capture: PropTypes.object,
    joinSessionDashboard: PropTypes.func,
    clearCallState: PropTypes.func,
    toggleSelfVideo: PropTypes.func,
    toggleSelfAudio: PropTypes.func,
};
