import React from 'react';
import {MuiPickersUtilsProvider, KeyboardTimePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import PropTypes from 'prop-types';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const CustomKeyboardTimePicker = React.forwardRef(({inputVariant, ampm, ...props}, ref) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardTimePicker
                ampm={false}
                inputRef={ref}
                inputVariant={inputVariant}
                keyboardIcon={<AccessTimeIcon />}
                {...props}
            />
        </MuiPickersUtilsProvider>
    );
});

CustomKeyboardTimePicker.propTypes = {
    inputVariant: PropTypes.string,
    ampm: PropTypes.bool,
};

CustomKeyboardTimePicker.defaultProps = {
    inputVariant: 'outlined',
    ampm: false,
};

export default CustomKeyboardTimePicker;
