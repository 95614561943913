import React from 'react';
import {MenuItem, TextField} from '@material-ui/core';

import {usePlatformTranslation} from '../../../services/hooks';
import {getTimeZoneList} from '../../../services/utils';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const TimeZoneList = getTimeZoneList();
const TimeZoneSelect = ({value, onChange}) => {
    const {meetings} = usePlatformTranslation();

    return (
        <TextField
            className="field-container"
            label={meetings.createDialog.inputTimezone}
            value={value}
            variant="outlined"
            onChange={onChange}
            select
            fullWidth
            SelectProps={{
                MenuProps,
            }}
        >
            {TimeZoneList.map((tz) => (
                <MenuItem key={tz.utc} value={tz.utc}>
                    {tz.label}
                </MenuItem>
            ))}
        </TextField>
    );
};

export default TimeZoneSelect;
