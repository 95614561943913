import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import {AuditorumPollsOutput} from './AuditorumPollsOutput';

export default connect(
    (state) => ({
        timeslotPolls: selectors.getTimeslotPolls(state),
        isLoading: selectors.getTimeslotLoading(state),
        isError: selectors.getTimeslotError(state),
    }),
    {
        onGetTimeslotPolls: actions.getTimeslotPolls,
    }
)(AuditorumPollsOutput);
