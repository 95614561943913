import {FormControlLabel, Radio} from '@material-ui/core';
import classNames from 'classnames';
import React from 'react';
import {TextValidator, ValidatorForm} from 'react-material-ui-form-validator';
import {Link} from 'react-router-dom';

import {getTextForBrandingTranslations} from 'services/helpers';
import {openUrlInNewTab} from 'services/helpers/openUrlInNewTab';
import {getBackgroundImageUrl} from 'services/helpers/registrantionForm';
import {customFormFieldTypes} from 'constants/customForm';
import {defaultRegistrationFields, mimoTypeByExtenstion} from 'constants/data';
import {countries, IS_SCA_ENV} from 'constants/shared';
import {getFieldTranslatedLabel, getFieldTranslatedOptions} from 'services/helpers/registrationFieldsHelper';
import {
    facebookValidation,
    getCurrencySymbol,
    getUserRegistrationField,
    linkedinValidation,
    linkValidation,
    twitterValidation,
} from 'services/utils';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';
import Spinner from '../Spinner';
import RegisterCountrySelect from './components/RegisterCountrySelect/RegisterCountrySelect';
import SafariPopUpBlockerModal from '../SafariPopUpBlockerModal';

import './RegisterAsExhibitor.scss';
import {getCustomFieldsErrorMessages, getCustomFieldsValidators} from 'services/helpers/customFieldsHelpers';

const MAX_FILE_SIZE_IN_MEGABYTES = 10;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MEGABYTES * 1024 * 1024;

export class RegisterAsExhibitor extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
        this.addValidationRules();
        this.props.onSetEventPublicMenu();
        this.setLanguagesData();

        this.setPackageId();

        // update the required fields with the settings from the current event
        this.setRegistrationFields();
    }

    checkEventRegPackageId = () => {
        const {userRegistration, eventRegPackage} = this.props;

        const packageExists = userRegistration.accessPackages.some(
            (accessPackage) => accessPackage._id === eventRegPackage
        );

        if (!packageExists) {
            // if access package from url doesn't exist
            let redirectLink = `${window.location.origin}${window.location.pathname}`;
            window.location.replace(redirectLink);
        }
    };

    setPackageId = () => {
        const {userRegistration, eventRegPackage, eventInfo} = this.props;

        if (
            !eventInfo.hasAccessManagement ||
            !userRegistration?.accessPackages ||
            !userRegistration?.accessPackages.length
        )
            return;

        if (eventRegPackage) this.checkEventRegPackageId();

        return this.setState({
            selectedPackageId: userRegistration.accessPackages[0]._id,
            showAccessPackages: true,
        });
    };

    componentDidUpdate(prevProps) {
        const {userRegistration} = this.props;
        // TODO: exhibitors can register on SCA
        // if (IS_SCA_ENV) {
        //     this.props.history.push(`/event/${this.props.eventSlug}/participant-registration`);
        // }

        if (prevProps.userRegistration !== userRegistration && userRegistration) {
            this.setState({
                registrationFormId: userRegistration._id,
            });
        }
        if (prevProps.paymentLink === '' && this.props.paymentLink !== '') {
            openUrlInNewTab(this.props.paymentLink);
        }
        if (prevProps.languages.eventLanguage !== this.props.languages.eventLanguage) {
            this.setLanguagesData();
            this.setRegistrationFields();
        }
        if (prevProps.userRegistration !== this.props.userRegistration) {
            this.setRegistrationFields();
        }
        if (
            prevProps.userRegistration !== userRegistration ||
            prevProps.eventRegPackage !== this.props.eventRegPackage
        ) {
            this.setRegistrationFields();
            this.setPackageId();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.eventLanguage];

        this.setState({
            translation: translation,
        });
    };

    componentWillUnmount() {
        // remove rule when it is not needed
        this.removeValidationRules();
    }

    state = {
        user: {
            fields: defaultRegistrationFields,
            invitationCode: '',
            country: '',
            billingAddress: {address: '', city: '', countryCode: ''},
        },
        disabled: false,
        showCodeInvalidErrorMessage: false,
        errorMessage: '',
        agreeToEventPrivacyPolicy: false,
        displayAgreeToPrivacyPolicyMessage: false,
        countriesList: countries,
        openPaymentDialog: false,
        selectedPackageId: null,
        errorAccessPackageNotSelected: false,
        translation: null,
        brandingTranslation: null,
        showAccessPackages: false,
    };

    setRegistrationFields = () => {
        // update the required fields with the settings from the current event
        const {
            userRegistration,
            languages: {eventLanguage},
        } = this.props;

        let updatedFields = [...this.state.user.fields];
        updatedFields.forEach((field) => {
            let eventInfoField = userRegistration?.predefinedFields[field.name];
            if (!eventInfoField) return;
            field.label = getFieldTranslatedLabel(eventInfoField, eventLanguage);
            field.isEnabled = eventInfoField.isEnabled;
            if (eventInfoField.isRequired && !field.validators.includes('required')) {
                field.validators.push('required');
                field.errorMessages.push('required');
            }
        });

        if (userRegistration) {
            userRegistration.customFields.forEach((customField) => {
                const {_id, type, label, options, isRequired, fileValidations = [], textValidation} = customField;
                const field = {
                    _id,
                    isRequired,
                    isEnabled: true,
                    name: _id,
                    type: type,
                    value: '',
                    label: getFieldTranslatedLabel(customField, eventLanguage) || label,
                    multiline: 0,
                    validators: getCustomFieldsValidators({isRequired, textValidation}),
                    errorMessages: getCustomFieldsErrorMessages({isRequired, textValidation}),
                    options: getFieldTranslatedOptions(options, eventLanguage),
                    isFile: type === customFormFieldTypes.file,
                    fileValidations,
                };

                updatedFields.push(field);

                if (updatedFields.some((updatedField) => updatedField._id === field._id)) {
                    updatedFields[updatedFields.findIndex((item) => item._id === field._id)] = field;
                }
            });
        }

        const enabledUpdatedFields = updatedFields.filter(
            (value, index, arr) => arr.findIndex((t) => t.name === value.name) === index && value.isEnabled
        );
        this.setState({
            user: {...this.state.user, fields: enabledUpdatedFields},
        });
    };

    handleFieldChange = (fieldName, fieldValue) => {
        const fields = this.state.user.fields;
        const index = fields.findIndex(({name}) => name === fieldName);
        const updatedField = {
            ...fields[index],
            value: fieldValue,
        };

        if (updatedField.isFile) {
            updatedField.errorMessage = '';
        }

        this.setState(
            {
                user: {
                    ...this.state.user,
                    fields: [...fields.slice(0, index), updatedField, ...fields.slice(index + 1)],
                },
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleChangeInvitationCode = (e) => {
        this.setState(
            {
                user: {
                    ...this.state.user,
                    [e.target.name]: e.target.value,
                },
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleAddressChange = (e) => {
        this.setState(
            {
                user: {
                    ...this.state.user,
                    billingAddress: {...this.state.user.billingAddress, address: e.target.value},
                },
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleCityChange = (e) => {
        this.setState(
            {
                user: {
                    ...this.state.user,
                    billingAddress: {...this.state.user.billingAddress, city: e.target.value},
                },
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleCountryCodeChange = (countryCode) => {
        this.setState(
            {
                user: {
                    ...this.state.user,
                    billingAddress: {...this.state.user.billingAddress, countryCode},
                },
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleAgreeToEventPrivacyPolicy = (e) => {
        this.setState({
            agreeToEventPrivacyPolicy: e.target.checked,
            displayAgreeToPrivacyPolicyMessage: !e.target.checked,
        });
    };

    addValidationRules = () => {
        ValidatorForm.addValidationRule('isFacebookLink', facebookValidation);
        ValidatorForm.addValidationRule('isLinkedinLink', linkedinValidation);
        ValidatorForm.addValidationRule('isLTwitterLink', twitterValidation);
        ValidatorForm.addValidationRule('isLink', linkValidation);
    };

    removeValidationRules = () => {
        ValidatorForm.removeValidationRule('isFacebookLink');
        ValidatorForm.removeValidationRule('isLinkedinLink');
        ValidatorForm.removeValidationRule('isLTwitterLink');
        ValidatorForm.removeValidationRule('isLink');
    };

    showFileFieldError = (field, index, errorMessage) =>
        this.setState(({user}) => ({
            user: {
                ...user,
                fields: [...user.fields.slice(0, index), {...field, errorMessage}, ...user.fields.slice(index + 1)],
            },
            disabled: true,
        }));

    getAllowedExtensionsString = ({fileValidations}) => fileValidations.join(', ');

    validateFileMIME = ({fileValidations, value}) =>
        fileValidations.map((type) => mimoTypeByExtenstion[type]).includes(value.file.type);

    validateFileFields = () => {
        const {fields} = this.state.user;

        let hasError = false;

        fields.forEach((field, index) => {
            const {value, isFile, isRequired} = field;

            if (!isFile || hasError) {
                return;
            }

            if (!isRequired && !value) {
                return;
            }

            if (isRequired && !value) {
                hasError = true;
                this.showFileFieldError(field, index, 'Field is required');
                return;
            }

            if (!this.validateFileMIME(field)) {
                hasError = true;
                this.showFileFieldError(
                    field,
                    index,
                    `${this.state.translation?.errors?.imageFileNotSupported} ${this.getAllowedExtensionsString(field)}`
                );
                return;
            }

            if (value.file.size > MAX_FILE_SIZE_IN_BYTES) {
                hasError = true;
                this.showFileFieldError(field, index, 'File too large. 10mb max file size.');
                return;
            }
        });

        return hasError;
    };

    handleRegister = () => {
        let {eventInfo, eventId} = this.props;

        const hasFileError = this.validateFileFields();
        if (hasFileError) {
            return;
        }
        if (!this.state.agreeToEventPrivacyPolicy) {
            this.setState({displayAgreeToPrivacyPolicyMessage: true});
        } else {
            if (eventInfo && eventInfo.closedEvent) {
                this.props.onCheckInvitationCode(eventId, this.state.user.invitationCode).then(() => {
                    if (this.props.isInvitationCodeValid.isValid === false) {
                        this.setState({
                            showCodeInvalidErrorMessage: true,
                            errorMessage: this.props.isInvitationCodeValid.errorMessage,
                        });
                    } else {
                        this.registerUser();
                    }
                });
            } else {
                this.registerUser();
            }
        }
    };

    addRequestFieldsItemData = (dataObject, {type, name, value, isFile}) => {
        if (isFile && value) {
            dataObject.append(name, value.file, value.title);
            return;
        }

        if (type === customFormFieldTypes.checkbox) {
            dataObject.append(name, JSON.stringify(value));
            return;
        }

        if (type === customFormFieldTypes.checkboxInput) {
            dataObject.append(name, JSON.stringify(value));
            return;
        }

        dataObject.append(name, value);
    };

    addRequestFieldsData = (dataObject) =>
        this.state.user.fields.forEach((field) => this.addRequestFieldsItemData(dataObject, field));

    checkIsSelectedPackageFree = () => {
        const {userRegistration} = this.props;
        const {selectedPackageId} = this.state;

        return userRegistration.accessPackages?.find(({_id}) => _id === selectedPackageId).isFree;
    };

    addRequestPaymentData = (dataObject) => {
        const {user, eventId} = this.props;
        const {selectedPackageId} = this.state;

        const packageIdName = this.checkIsSelectedPackageFree() ? 'accessPackage' : 'packageId';

        dataObject.append('userId', user._id);
        dataObject.append('eventId', eventId);
        dataObject.append(packageIdName, selectedPackageId);
    };

    formRequestData = () => {
        const {
            eventInfo,
            languages: {eventLanguage},
        } = this.props;
        const {user, selectedPackageId} = this.state;
        const formData = new FormData();

        formData.append('invitationCode', user.invitationCode);
        this.addRequestFieldsData(formData);

        if (!eventInfo.hasAccessManagement || !selectedPackageId) {
            return formData;
        }

        this.addRequestPaymentData(formData);

        if (!this.checkIsSelectedPackageFree()) {
            formData.append('address1', user.billingAddress.address);
            formData.append('city', user.billingAddress.city);
            formData.append('countryCode', user.billingAddress.countryCode);
        }
        formData.append('language', eventLanguage);
        return formData;
    };

    registerUser = () => {
        const {eventId, eventInfo, onCreatePaymentIntent, userRegistration, eventLanguage} = this.props;
        const formData = this.formRequestData();
        formData.append('registrationFormId', userRegistration?._id || '');

        if (eventInfo.hasAccessManagement && !this.checkIsSelectedPackageFree()) {
            onCreatePaymentIntent(formData, 'exhibitor');
            return;
        }

        if (!this.state.agreeToEventPrivacyPolicy) {
            this.setState({displayAgreeToPrivacyPolicyMessage: true});
        } else {
            if (eventInfo.hasAccessManagement) {
                if (this.state.selectedPackageId) {
                    // fullData is an object containing the user data and payment data

                    // if the selectedPackage is Free, we should directly register the user
                    const selectedPackageData = userRegistration.accessPackages.find(
                        (accessPackage) => accessPackage._id === this.state.selectedPackageId
                    );
                    if (selectedPackageData.isFree) {
                        // automatically register the user
                        this.props
                            .onRegisterExhibitor(eventId, formData, eventLanguage)
                            .then(() => this.props.refreshEventData(eventId))
                            .then(() => {
                                this.props.refreshUserData();
                            });
                    } else {
                        const {address, city, countryCode} = this.state.user.billingAddress;

                        formData.append('userId', this.props.user._id);
                        formData.append('eventId', eventId);
                        formData.append('packageId', this.state.selectedPackageId);
                        formData.append(
                            'billingAddress',
                            JSON.stringify({
                                address1: address,
                                city,
                                countryCode,
                            })
                        );
                        this.props.onCreatePaymentIntent(formData, 'exhibitor');
                    }
                } else {
                    this.setState({
                        errorAccessPackageNotSelected: true,
                    });
                }
            } else {
                this.props
                    .onRegisterExhibitor(eventId, formData, eventLanguage)
                    .then(() => this.props.refreshEventData(eventId))
                    .then(() => this.props.refreshUserData());
            }
        }
    };

    cancelPaymentIntent = () => {
        this.props.onCancelPaymentIntent();
    };

    stripeResponse = (response) => {
        this.setState({loading: true});
    };

    selectPackage = (packageId) => (e) => {
        this.setState({selectedPackageId: packageId, errorAccessPackageNotSelected: false});
    };
    render() {
        const {agreeToEventPrivacyPolicy, displayAgreeToPrivacyPolicyMessage, translation, showAccessPackages, user} =
            this.state;
        const {fields, invitationCode} = user;

        const {
            eventInfo,
            eventId,
            userRegistration,
            loadingUser,
            loadingEvent,
            eventSlug,
            preselectedAccessPackageId,
            languages,
        } = this.props;
        const {isRtlLanguage, eventLanguage} = languages;

        let selectedPackageData = null;
        if (eventInfo.hasAccessManagement) {
            if (this.state.selectedPackageId) {
                selectedPackageData = userRegistration.accessPackages.find(
                    (accessPackage) => accessPackage._id === this.state.selectedPackageId
                );
            }
        }

        return (
            <>
                <div className="register-participant-step-2">
                    <div className="full-background-container p-relative d-flex">
                        <img
                            src={getBackgroundImageUrl(eventId, userRegistration?.image)}
                            className="full-background-img img-cover p-absolute w-100 h-100"
                            alt="background"
                        />
                        {!IS_SCA_ENV && <div className="full-background-overlay p-absolute w-100 h-100"></div>}
                        <div className="header-spacing-container">
                            <div className="form-wrapper w-100 h-100 d-flex justify-content-center align-items-center">
                                {eventInfo ? (
                                    <div className="form-container d-flex">
                                        <div className="left-form">
                                            <div className="register-header">
                                                <h1>
                                                    {userRegistration?.name
                                                        ? getTextForBrandingTranslations(
                                                              userRegistration.name,
                                                              eventLanguage
                                                          )
                                                        : ''}
                                                </h1>
                                                <p>
                                                    {userRegistration?.description
                                                        ? getTextForBrandingTranslations(
                                                              userRegistration.description,
                                                              eventLanguage
                                                          )
                                                        : ''}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="right-form">
                                            <div className="register-form-wrapper">
                                                <ValidatorForm ref="form" onSubmit={this.handleRegister}>
                                                    {fields.map((field) => {
                                                        const fieldInst = {...field};
                                                        fieldInst.errorMessages = field.errorMessages.map((el, i) => {
                                                            return translation?.errors?.[el] ?? el;
                                                        });

                                                        const FieldComponent = getUserRegistrationField(
                                                            fieldInst.type,
                                                            fieldInst.name
                                                        );

                                                        return (
                                                            <FieldComponent
                                                                field={fieldInst}
                                                                handleChange={this.handleFieldChange}
                                                                key={fieldInst.name}
                                                            />
                                                        );
                                                    })}

                                                    {eventInfo && eventInfo.closedEvent && (
                                                        <div className="invitation-code-wrapper">
                                                            <TextValidator
                                                                label="Invitation code"
                                                                onChange={this.handleChangeInvitationCode}
                                                                name="invitationCode"
                                                                value={invitationCode}
                                                                variant="outlined"
                                                                fullWidth
                                                                className="field-container invitation-code-input"
                                                                validators={['required']}
                                                                errorMessages={[
                                                                    `${translation?.errors?.noInvitationCode}`,
                                                                ]}
                                                            />
                                                            {this.state.showCodeInvalidErrorMessage && (
                                                                <p className="error-message">
                                                                    {this.state.errorMessage}
                                                                </p>
                                                            )}
                                                        </div>
                                                    )}

                                                    {eventInfo.hasAccessManagement && !preselectedAccessPackageId && (
                                                        <div className="choose-package">
                                                            <p className="choose-package__title">
                                                                {
                                                                    translation?.participantRegistration
                                                                        ?.choosePackageTitle
                                                                }
                                                            </p>
                                                            {showAccessPackages &&
                                                                userRegistration?.accessPackages.map(
                                                                    (accessPackage) => {
                                                                        const isSelected =
                                                                            accessPackage._id ===
                                                                            this.state.selectedPackageId;

                                                                        return (
                                                                            <div key={accessPackage._id}>
                                                                                <div
                                                                                    className={classNames(
                                                                                        'choose-package__package package',
                                                                                        {isSelected: 'package-selected'}
                                                                                    )}
                                                                                    key={accessPackage._id}
                                                                                    onClick={this.selectPackage(
                                                                                        accessPackage._id
                                                                                    )}
                                                                                >
                                                                                    <span className="package__name-container">
                                                                                        <Radio
                                                                                            checked={isSelected}
                                                                                            color={'primary'}
                                                                                        />
                                                                                        <span className="package__name">
                                                                                            {accessPackage.name}
                                                                                        </span>
                                                                                    </span>
                                                                                    {eventInfo.paidEvent && (
                                                                                        <span className="package__price">
                                                                                            {accessPackage.isFree ? (
                                                                                                translation
                                                                                                    ?.paidRegistration
                                                                                                    ?.freeText
                                                                                            ) : (
                                                                                                <>
                                                                                                    {getCurrencySymbol(
                                                                                                        eventInfo.currency
                                                                                                    )}
                                                                                                    {
                                                                                                        accessPackage.price
                                                                                                    }
                                                                                                </>
                                                                                            )}
                                                                                        </span>
                                                                                    )}
                                                                                </div>
                                                                                {isSelected && (
                                                                                    <p className="package__description">
                                                                                        {accessPackage.description}
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                        );
                                                                    }
                                                                )}
                                                        </div>
                                                    )}
                                                    {this.state.errorAccessPackageNotSelected && (
                                                        <p className="error-text">
                                                            {translation?.participantRegistration?.noPackageText}
                                                        </p>
                                                    )}

                                                    {eventInfo.paidEvent && !selectedPackageData?.isFree && (
                                                        <div className="set-billing-info">
                                                            <SafariPopUpBlockerModal />
                                                            <p className="set-billing-info__title">
                                                                {translation?.paidRegistration?.bilingInfoText}
                                                            </p>

                                                            <TextValidator
                                                                className="set-billing-info__field field-container"
                                                                label={translation?.paidRegistration?.addressText}
                                                                name="address"
                                                                variant="filled"
                                                                onChange={this.handleAddressChange}
                                                                value={this.state.user.billingAddress.address}
                                                                validators={['required']}
                                                                errorMessages={[translation?.errors?.requiredAddress]}
                                                                fullWidth
                                                            />

                                                            <TextValidator
                                                                className="set-billing-info__field field-container"
                                                                label={translation?.paidRegistration?.cityText}
                                                                name="city"
                                                                variant="filled"
                                                                onChange={this.handleCityChange}
                                                                value={this.state.user.billingAddress.city}
                                                                validators={['required']}
                                                                errorMessages={[translation?.errors?.requiredCity]}
                                                                fullWidth
                                                            />

                                                            <RegisterCountrySelect
                                                                value={this.state.user.billingAddress.countryCode}
                                                                onChange={this.handleCountryCodeChange}
                                                            />
                                                        </div>
                                                    )}

                                                    <div className="agree-terms">
                                                        <FormControlLabel
                                                            control={
                                                                <CustomCheckbox
                                                                    checked={agreeToEventPrivacyPolicy}
                                                                    onChange={this.handleAgreeToEventPrivacyPolicy}
                                                                    value="agreeToEventPrivacyPolicy"
                                                                />
                                                            }
                                                            label={
                                                                <span
                                                                    className={classNames(
                                                                        {isRtl: isRtlLanguage},
                                                                        'agree-terms-message'
                                                                    )}
                                                                >
                                                                    {translation?.exhibitorRegistration?.gdprTextFirst}
                                                                    <Link
                                                                        target="_blank"
                                                                        to={{
                                                                            pathname: `/event/${eventSlug}/event-privacy-policy/`,
                                                                        }}
                                                                    >
                                                                        <span>
                                                                            {` ${translation?.participantRegistration?.gdprTextPrivacy}`}
                                                                        </span>
                                                                    </Link>
                                                                    .
                                                                </span>
                                                            }
                                                        />
                                                        {displayAgreeToPrivacyPolicyMessage && (
                                                            <p className="error-text">
                                                                {translation?.exhibitorRegistration?.gdprTextAgree}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <div className="buttons-wrapper">
                                                        <button
                                                            type="submit"
                                                            disabled={
                                                                this.props.loadingUser ||
                                                                this.props.loadingInvitationCode
                                                            }
                                                            className="register-button"
                                                        >
                                                            {eventInfo.hasAccessManagement ? (
                                                                <>
                                                                    {selectedPackageData && selectedPackageData.isFree
                                                                        ? translation?.exhibitorRegistration
                                                                              ?.registerButtonFree
                                                                        : translation?.exhibitorRegistration
                                                                              ?.registerButtonPay}
                                                                </>
                                                            ) : (
                                                                translation?.exhibitorRegistration.registerButtonFree
                                                            )}
                                                        </button>
                                                    </div>
                                                </ValidatorForm>
                                            </div>
                                            {(loadingUser || (loadingEvent && eventInfo)) && <Spinner />}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
