import React, {useEffect, useContext, useMemo, useCallback, useState} from 'react';
import PropTypes from 'prop-types';
import {matchPath, useLocation} from 'react-router';
import {generatePath} from 'react-router';
import moment from 'moment';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import _ from 'lodash';

import {ROLE_FEATURES_TYPES, ROLE_ABILITIES_TYPES} from 'constants/ability';
import {USER_ADDITIONS_ROLES} from 'constants/shared';
import {AbilityContext} from 'permissionsConfig';
import {RouterConfig} from 'routerConfig';
import {checkIfUserUseAMPM} from 'services/utils';
import {SESSION_LIST_HEAD_CELLS} from 'constants/sessionsList';
import {ETX_LIVE_SESSION, YOUTUBE, ZOOM} from 'constants/data/streaming-engine';
import {DoorIcon} from 'Images/pages/interpretation';

import Table from '../Table/Table';

import {useStyles} from './SessionsList.styles';
import PrimaryColorSwitch from '../PrimaryColorSwitch/PrimaryColorSwitch';
import {usePlatformTranslation} from 'services/hooks';
import classNames from 'classnames';
import {TextField} from '@material-ui/core';

const SESSION_CELLS_WITH_SORTING = ['session-name', 'date'];

function SessionsList({
    onSetEventProtectedMenu,
    getSessionsListByRole,
    changeSessionListPage,
    clearSessionList,
    sessionListPagination,
    sessionList,
    sessionListError,
    eventTimeZone,
    slug,
    history,
    isRtlLanguage,
    event,
    getBuilding
}) {
    const classes = useStyles();
    const {pathname} = useLocation();
    const [showTheNearest, setShowTheNearest] = useState(false);
    const [orderBy, setOrderBy] = useState(null);
    const [orderDirection, setOrderDirection] = useState(null);
    const translation = usePlatformTranslation();
    const ability = useContext(AbilityContext);
    const isAmPm = checkIfUserUseAMPM();
    const [search, setSearch] = useState('');

    const isInterpreter =
        matchPath(pathname, {path: RouterConfig.event.interpreterSessionsList})?.isExact &&
        ability.can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.INTERPRETER_SESSION_LIST_BUTTON);

    const isSpeaker =
        matchPath(pathname, {path: RouterConfig.event.speakerSessionsList})?.isExact &&
        ability.can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.SPEAKER_SESSION_LIST_BUTTON);

    const isModerator =
        matchPath(pathname, {path: RouterConfig.event.moderatorSessionsList})?.isExact &&
        ability.can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.MODERATOR_SESSION_LIST_BUTTON);

    const userRole = useMemo(() => {
        switch (true) {
            case isInterpreter:
                return USER_ADDITIONS_ROLES.interpreter;
            case isSpeaker:
                return USER_ADDITIONS_ROLES.speaker;
            case isModerator:
                return USER_ADDITIONS_ROLES.moderator;
            default:
                return USER_ADDITIONS_ROLES.speaker;
        }
    }, [isInterpreter, isModerator, isSpeaker]);

    const toggleSwitch = () => {
        setOrderBy(null);
        setOrderDirection(null);
        setShowTheNearest(!showTheNearest);
    };

    useEffect(() => {
        onSetEventProtectedMenu();

        return () => clearSessionList();
    }, [onSetEventProtectedMenu, clearSessionList]);

    useEffect(() => {
        getSessionsListByRole({userRole, showTheNearest, search, orderBy, orderDirection});
    }, [getSessionsListByRole, userRole, sessionListPagination.page, showTheNearest, search, orderBy, orderDirection]);

    const onPaginate = useCallback((newPage) => changeSessionListPage({page: newPage}), [changeSessionListPage]);

    const redirectTo =
        ({program, auditoriumId, date, end}) =>
        () => {
            let building = null;

            if (event.hasVillage) {
                building = event.buildings.find((building) => {
                    return building.auditoriums.find((auditorium) => auditorium._id === auditoriumId);
                });
            }
            if (program?.streamingEngine === ETX_LIVE_SESSION)
                return history.push(
                    generatePath(RouterConfig.event.etxSessionDashboard, {
                        eventSlug: slug,
                        auditoriumId,
                        sessionId: program._id
                    })
                );

            if ([YOUTUBE, ZOOM].includes(program?.streamingEngine) && program?.meetingLink) {
                return window.open(program.meetingLink, '_blank');
            }

            if (moment(date).isBefore(moment(), 'day') || moment(end, 'h:mm').isBefore(moment(), 'hour minute')) {
                if (event.hasVillage) {
                    return getBuilding(event._id, building._id, () => {
                        history.push(
                            generatePath(RouterConfig.event.village.buildingAuditoriumArchive, {
                                eventSlug: slug,
                                buildingId: building._id
                            })
                        );
                    });
                }

                return history.push(
                    generatePath(RouterConfig.event.auditoriumArhiveSingleVideo, {
                        eventSlug: slug,
                        timeslotId: program._id
                    })
                );
            }

            if (event.hasVillage) {
                return getBuilding(event._id, building._id, () => {
                    history.push(
                        generatePath(RouterConfig.event.village.buildingAuditorium, {
                            eventSlug: slug,
                            buildingId: building._id
                        })
                    );
                });
            }

            return history.push(generatePath(RouterConfig.event.auditorium, {eventSlug: slug}));
        };

    const sortSessions = (orderBy, orderDirection) => {
        setShowTheNearest(false);
        setOrderBy(orderBy);
        setOrderDirection(orderDirection);
    };

    const onSearchChange = _.debounce(setSearch, 500);

    const tableCols = [
        {
            name: 'sessionName',
            title: 'session Name',
            width: 550,
            align: 'left',
            render: (item) => item.programTitle
        },
        {
            name: 'sessionType',
            title: 'session type',
            width: 150,
            render: (item) => item.program?.streamingEngine
        },
        {
            name: 'timezone',
            title: 'timezone',
            width: 130,
            render: () => `GMT${moment.tz(eventTimeZone).format('Z')}`
        },
        {
            name: 'date',
            title: 'date',
            width: 130,
            render: (item) => item.date
        },
        {
            name: 'start',
            title: 'start',
            width: 100,
            render: (item) => (isAmPm ? moment(`${item.date} ${item.start}`).format('hh:mm A') : item.start)
        },
        {
            name: 'end',
            title: 'end',
            width: 100,
            render: (item) => (isAmPm ? moment(`${item.date} ${item.end}`).format('hh:mm A') : item.end)
        },
        {
            name: 'join',
            title: 'join',
            width: 80,
            render: (item) => (
                <Button
                    // disabled={
                    //     item.program.streamingEngine === ETX_LIVE_SESSION &&
                    //     item.program.resourcesStatus !== ALLOCATE_RESOURCES_STATUSES.allocated
                    // }
                    classes={{root: classes.joinButton, disabled: classes.disabledBtn}}
                    onClick={redirectTo(item)}
                >
                    <DoorIcon />
                </Button>
            )
        }
    ];

    return (
        <>
            {!sessionListError && sessionList ? (
                <Container maxWidth="lg">
                    <div className={classes.row}>
                        <h1>{translation.sessions?.sessionList}</h1>
                        <div className={classes.filterSection}>
                            <div className={classNames(classes.switch, {isRtl: isRtlLanguage})}>
                                <PrimaryColorSwitch color="primary" onChange={toggleSwitch} checked={showTheNearest} />
                                {translation.sessions?.showTheNearest}
                            </div>
                            <TextField
                                label="Search session"
                                variant="outlined"
                                type="search"
                                name="search-filter"
                                margin="normal"
                                fullWidth
                                onChange={(e) => onSearchChange(e.target.value)}
                            />
                        </div>
                    </div>
                    <Table
                        data={sessionList}
                        cols={tableCols}
                        headCells={SESSION_LIST_HEAD_CELLS}
                        withEmptyRows={false}
                        withSelection={false}
                        withSorting={true}
                        width="100%"
                        rowsPerPage={sessionListPagination.perPage}
                        total={sessionListPagination.total}
                        onPaginate={onPaginate}
                        initPage={sessionListPagination.page}
                        onSort={sortSessions}
                        tableCellsAlign="right"
                        headCellsAlign="right"
                        cellsWithSorting={SESSION_CELLS_WITH_SORTING}
                        orderByProp={orderBy}
                    />
                </Container>
            ) : (
                <p>{`${sessionListError}`}</p>
            )}
        </>
    );
}

SessionsList.propTypes = {
    slug: PropTypes.string,
    onSetEventProtectedMenu: PropTypes.func,
    getSessionsListByRole: PropTypes.func,
    changeSessionListPage: PropTypes.func,
    clearSessionList: PropTypes.func,
    sessionList: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
    sessionListPagination: PropTypes.object,
    sessionListError: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
    history: PropTypes.object,
    event: PropTypes.object,
    getBuilding: PropTypes.func
};

export default SessionsList;
