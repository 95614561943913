import TextField from '../../Routes/components/TextField/TextField';
import RadioField from '../../Routes/components/RadioField/RadioField';
import SelectField from '../../Routes/components/SelectField/SelectField';
import CheckboxField from '../../Routes/components/CheckboxField/CheckboxField';
import CheckboxInputField from '../../Routes/components/CheckboxInputField/CheckboxInputField';
import TextareaField from '../../Routes/components/TextareaField/TextareaField';
import FileField from '../../Routes/components/FileField/FileField';

// TODO: combine with customFormFieldTypes in customForm component
export const userRegistrationCustomFieldTypes = {
    text: 'text',
    select: 'select',
    radio: 'radio',
    checkbox: 'checkbox',
    checkboxInput: 'checkboxInput',
    file: 'file',
    textArea: 'text-area',
};

export const userRegistrationCustomFieldByType = {
    [userRegistrationCustomFieldTypes.text]: TextField,
    [userRegistrationCustomFieldTypes.select]: SelectField,
    [userRegistrationCustomFieldTypes.radio]: RadioField,
    [userRegistrationCustomFieldTypes.checkbox]: CheckboxField,
    [userRegistrationCustomFieldTypes.checkboxInput]: CheckboxInputField,
    [userRegistrationCustomFieldTypes.file]: FileField,
    [userRegistrationCustomFieldTypes.textArea]: TextareaField,
};
