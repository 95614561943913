import React, {Component} from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import {connect} from 'react-redux';

import {ReactComponent as PartnerFacebookIcon} from '../../../../../Images/partner-facebook.svg';
import {ReactComponent as PartnerLinkedinIcon} from '../../../../../Images/partner-linkedin.svg';
import {ReactComponent as PartnerTwitterIcon} from '../../../../../Images/partner-twitter.svg';
import {ReactComponent as SliderArrow} from '../../../../../Images/slider-arrow.svg';

class ExhibitorSlider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            numberOfSlides: 5,
            slideIndex: 0,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
        this.calculateNumberOfSlides();
        window.addEventListener('resize', this.calculateNumberOfSlides);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.calculateNumberOfSlides);
    }

    calculateNumberOfSlides = () => {
        const {
            event: {brandingData},
        } = this.props;
        let numberOfSlides;

        if (window.innerWidth >= 1200) {
            numberOfSlides = 5;
        } else if (window.innerWidth >= 768) {
            numberOfSlides = 3;
        } else {
            numberOfSlides = 2;
        }

        if (brandingData.exhibitors.length < numberOfSlides) {
            numberOfSlides = brandingData.exhibitors.length;
        }
        this.setState({numberOfSlides});
    };

    goToPreviousSlide = () => {
        this.slider2.slickPrev();
    };

    goToNextSlide = () => {
        this.slider2.slickNext();
    };

    handleMirroredNavigation = (slideIndex) => {
        const {isRtlLanguage} = this.props;
        const {nav1} = this.state;

        if (!isRtlLanguage) return;

        nav1.slickGoTo(slideIndex);
    };

    render() {
        const {
            isRtlLanguage,
            event: {brandingData},
        } = this.props;
        const {numberOfSlides, slideIndex, nav1, nav2} = this.state;
        let sliderSettings1 = {
            dots: false,
            arrows: false,
            autoplay: false,
            draggable: true,
            pauseOnHover: false,
            swipe: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            focusOnSelect: !isRtlLanguage,
            adaptiveHeight: true,
            rtl: isRtlLanguage,
        };
        let sliderSettings2 = {
            className: 'navigation-slider',
            dots: false,
            arrows: false,
            autoplay: false,
            draggable: true,
            pauseOnHover: false,
            swipe: true,
            infinite: true,
            speed: 500,
            slidesToShow: numberOfSlides,
            slidesToScroll: 1,
            focusOnSelect: !isRtlLanguage,
            rtl: isRtlLanguage,
            beforeChange: (current, next) => this.setState({slideIndex: next}),
        };

        const topSliderStyles = {
            width: numberOfSlides * 208 + 'px',
            margin: 'auto',
        };

        const exhibitorsArray = brandingData.exhibitors;

        return (
            <div className="homepage-slider exhibitors">
                <div className="top-slider">
                    {this.props.event.brandingData.exhibitors.length > 1 && (
                        <div className="arrow previous" onClick={this.goToPreviousSlide}>
                            <SliderArrow />
                        </div>
                    )}
                    <div className="top-slider-container" style={topSliderStyles}>
                        <Slider
                            {...sliderSettings2}
                            asNavFor={nav1}
                            ref={(slider) => (this.slider2 = slider)}
                            swipeToSlide={true}
                            focusOnSelect={true}
                        >
                            {exhibitorsArray.map((partner, i) => {
                                return (
                                    <div key={partner._id}>
                                        <div
                                            className={classNames('slider-image-container', {
                                                isActive: i === slideIndex,
                                            })}
                                            onClick={() => this.handleMirroredNavigation(i)}
                                        >
                                            {partner.image ? (
                                                <img
                                                    src={this.props.event.brandingData.filesUrl + partner.image}
                                                    alt={partner.name}
                                                />
                                            ) : (
                                                <p className="logo-placeholder">{partner.name}</p>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                    {this.props.event.brandingData.exhibitors.length > 1 && (
                        <div className="arrow next" onClick={this.goToNextSlide}>
                            <SliderArrow />
                        </div>
                    )}
                </div>
                <div className="bottom-slider-container">
                    <div className="homepage-container">
                        <Slider
                            {...sliderSettings1}
                            className={'slider1'}
                            asNavFor={nav2}
                            ref={(slider) => (this.slider1 = slider)}
                        >
                            {exhibitorsArray.map((partner) => {
                                return (
                                    <div key={partner._id}>
                                        <div
                                            className={classNames('partner-details-container', {isRtl: isRtlLanguage})}
                                        >
                                            <div className="main-details">
                                                <div className="info">
                                                    {partner.webpage ? (
                                                        <a
                                                            href={partner.webpage}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <h2>{partner.name}</h2>
                                                        </a>
                                                    ) : (
                                                        <h2 className="no-hover">{partner.name}</h2>
                                                    )}
                                                    {partner.description && (
                                                        <p>
                                                            {partner.description.split('\n').map((item, key) => {
                                                                return (
                                                                    <span key={key}>
                                                                        {item}
                                                                        <br />
                                                                    </span>
                                                                );
                                                            })}
                                                        </p>
                                                    )}
                                                    <div className="contact">
                                                        {partner.webpage ? (
                                                            <a
                                                                href={partner.webpage}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="webpage-link partner-link-website"
                                                            >
                                                                <h2>{partner.name}</h2>
                                                            </a>
                                                        ) : (
                                                            <h2 className="no-hover">{partner.name}</h2>
                                                        )}
                                                        <div className="links-container">
                                                            {partner.twitter && (
                                                                <a
                                                                    href={partner.twitter}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <PartnerTwitterIcon />
                                                                </a>
                                                            )}
                                                            {partner.linkedin && (
                                                                <a
                                                                    href={partner.linkedin}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <PartnerLinkedinIcon />
                                                                </a>
                                                            )}
                                                            {partner.facebook && (
                                                                <a
                                                                    href={partner.facebook}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                >
                                                                    <PartnerFacebookIcon />
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="logo">
                                                <div className="partner-logo-container">
                                                    {partner.image ? (
                                                        <img
                                                            src={this.props.event.brandingData.filesUrl + partner.image}
                                                            alt="logo"
                                                        />
                                                    ) : (
                                                        <p className="logo-placeholder">{partner.name}</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Slider>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        isRtlLanguage: state.languages.isRtlLanguage,
    }),
    null
)(ExhibitorSlider);
