import React, {useEffect, useRef} from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import Form from '../../../../../../../Form/Form';
import FormGroup from '../../../../../../../FormGroup/FormGroup';

import {useMedia, usePlatformTranslation} from '../../../../../../../../../services/hooks';
import './BusinessCardEditingForm.scss';

const WEBSITE_VALIDATION_NAME = 'isValidLink';

const validateLink = (value) => {
    if (!value) {
        return true;
    }
    let rule = new RegExp(
        '(http|ftp|https):\\/\\/[\\w\\-_]+(\\.[\\w\\-_]+)+([\\w\\-\\.,@?^=%&amp;:/~\\+#]*[\\w\\-\\@?^=%&amp;/~\\+#])?',
        'i'
    );

    return rule.test(value);
};

const FormGroupItem = ({children}) => {
    const isMobile = useMedia('(max-width: 840px)');
    const Wrapper = isMobile ? FormGroup.WideItem : FormGroup.HalfItem;

    return <Wrapper>{children}</Wrapper>;
};
const BusinessCardTextField = ({...rest}) => <TextValidator variant="outlined" fullWidth {...rest} />;

const BusinessCardEditingForm = ({businessCard, onChange, setIsFormValid}) => {
    const formRef = useRef();
    const {businessCard: businessCardTranslation, myAccountPage, landingPage} = usePlatformTranslation();

    const handleChange = (e) => {
        const {name, value} = e.target;
        onChange({name, value});
    };

    useEffect(() => {
        if (!formRef.current) {
            return;
        }

        formRef.current.isFormValid().then(setIsFormValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formRef, businessCard]);

    useEffect(() => {
        ValidatorForm.addValidationRule(WEBSITE_VALIDATION_NAME, validateLink);
        return () => ValidatorForm.removeValidationRule(WEBSITE_VALIDATION_NAME);
    }, []);

    return (
        <div className="BusinessCardEditingForm">
            <ValidatorForm ref={formRef} onSubmit={() => console.log('submit!')}>
                <Form.Row>
                    <FormGroup>
                        <FormGroupItem>
                            <BusinessCardTextField
                                label={`${myAccountPage.firstName} (${businessCardTranslation.required})`}
                                name="firstName"
                                value={businessCard.firstName}
                                validators={['required']}
                                errorMessages={['Field is required']}
                                onChange={handleChange}
                            />
                        </FormGroupItem>
                    </FormGroup>
                </Form.Row>

                <Form.Row>
                    <FormGroup>
                        <FormGroupItem>
                            <BusinessCardTextField
                                label={`${myAccountPage.lastName} (${businessCardTranslation.required})`}
                                name="lastName"
                                value={businessCard.lastName}
                                validators={['required']}
                                errorMessages={['Field is required']}
                                onChange={handleChange}
                            />
                        </FormGroupItem>
                    </FormGroup>
                </Form.Row>

                <Form.Row>
                    <FormGroup>
                        <FormGroupItem>
                            <BusinessCardTextField
                                label={businessCardTranslation.designation}
                                name="designation"
                                value={businessCard.designation}
                                onChange={handleChange}
                            />
                        </FormGroupItem>
                    </FormGroup>
                </Form.Row>

                <Form.Row>
                    <FormGroup>
                        <FormGroupItem>
                            <BusinessCardTextField
                                label={businessCardTranslation.Mobile}
                                name="mobile"
                                type="tel"
                                value={businessCard.mobile}
                                validators={['matchRegexp:^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$']}
                                errorMessages={['Please enter a valid mobile number. E.g.: +447517663928']}
                                onChange={handleChange}
                            />
                        </FormGroupItem>
                    </FormGroup>
                </Form.Row>

                <Form.Row>
                    <FormGroup>
                        <FormGroupItem>
                            <BusinessCardTextField
                                label={businessCardTranslation.Phone}
                                name="phone"
                                type="tel"
                                value={businessCard.phone}
                                validators={['matchRegexp:^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$']}
                                errorMessages={['Please enter a valid phone number. E.g.: +447517663928']}
                                onChange={handleChange}
                            />
                        </FormGroupItem>
                    </FormGroup>
                </Form.Row>

                <Form.Row>
                    <FormGroup>
                        <FormGroupItem>
                            <BusinessCardTextField
                                label={`${landingPage.contactInputEmail} (${businessCardTranslation.required})`}
                                name="email"
                                value={businessCard.email}
                                validators={['required', 'isEmail']}
                                errorMessages={['Field is required', 'Email not valid']}
                                onChange={handleChange}
                            />
                        </FormGroupItem>
                    </FormGroup>
                </Form.Row>

                <Form.Row>
                    <FormGroup>
                        <FormGroupItem>
                            <BusinessCardTextField
                                label={businessCardTranslation.Website}
                                name="website"
                                value={businessCard.website}
                                validators={[WEBSITE_VALIDATION_NAME]}
                                errorMessages={['Please enter a valid link (https://site.com/)']}
                                onChange={handleChange}
                            />
                        </FormGroupItem>
                    </FormGroup>
                </Form.Row>

                <Form.Row>
                    <FormGroup>
                        <FormGroupItem>
                            <BusinessCardTextField
                                label={businessCardTranslation.companyName}
                                name="companyName"
                                value={businessCard.companyName}
                                onChange={handleChange}
                            />
                        </FormGroupItem>
                    </FormGroup>
                </Form.Row>
            </ValidatorForm>
        </div>
    );
};

export default BusinessCardEditingForm;
