import {connect} from 'react-redux';
import {NotFound} from './NotFound';
import {setEventId, setEventSlug, topNavClose, getEventInfo, setEventPublicMenu} from 'store/actions';
import * as selectors from 'store/selectors';

export default connect(
    (state) => ({
        loggedIn: selectors.getIsUserLoggedIn(state),
        user: selectors.getUserData(state),
        isMobile: selectors.getIsMobile(state),
    }),
    {
        topNavClose,
        setEventId,
        getEventInfo,
        setEventSlug,
        setEventPublicMenu,
    }
)(NotFound);
