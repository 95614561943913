import React from 'react';

const ModeratorMenuIcon = ({color}) => (
    <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.4 12.32a3 3 0 1 0-3.46 3 4.61 4.61 0 0 0-4.32 4.18v.41h9.54v-.41a4.61 4.61 0 0 0-4.33-4.15 3 3 0 0 0 2.57-3.03Zm-3 2.29a2.29 2.29 0 1 1 2.29-2.29 2.3 2.3 0 0 1-2.33 2.29h.04Zm-3.96 4.51a3.93 3.93 0 0 1 3.92-3.09 3.92 3.92 0 0 1 3.91 3.09H3.44ZM19.93 20.02a.79.79 0 0 1-.88.88h-1.93v.76h2.06a1.52 1.52 0 0 0 1.51-1.51V9.5l-.76.52v10ZM17.82 1.65h-3.95c-.812-.001-1.606.306-2.284.752a4.14 4.14 0 0 0-.717 6.318v2.46a.301.301 0 0 0 .16.27.28.28 0 0 0 .15 0 .31.31 0 0 0 .18-.05l2.07-1.45h.07c.133.01.267.01.4 0h3.92a4.15 4.15 0 1 0 0-8.3Zm3.37 4.22a3.4 3.4 0 0 1-3.4 3.4h-3.92a3.4 3.4 0 0 1 0-6.8h3.92a3.4 3.4 0 0 1 3.4 3.4Z"
            fill={color ? color : '#D62E2F'}
        />
        <path
            d="M14.33 19.34a1.94 1.94 0 0 0-1.89 1.56h-9.8a.79.79 0 0 1-.87-.88V8.65a1 1 0 0 1 1-1h6.58l-.22-.74H2.65A1.64 1.64 0 0 0 1.01 8.5v11.65a1.51 1.51 0 0 0 1.49 1.51h9.92a1.93 1.93 0 1 0 1.89-2.32h.02ZM16.62 4.78v2.17h-2.71V4.78h2.71Zm.27-.54h-3.25a.28.28 0 0 0-.28.27v2.72a.28.28 0 0 0 .28.27h3.25a.28.28 0 0 0 .27-.27v-1l1.09 1.08v-3L17.16 5.5v-1a.27.27 0 0 0-.27-.26Z"
            fill={color ? color : '#D62E2F'}
        />
    </svg>
);
export default ModeratorMenuIcon;
