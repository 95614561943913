import React from 'react';

const SurveyIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M13.0013 6L13.0013 6.68L15.0013 8.68L15.0013 6.77L17.0013 5L17.0013 19L15.0013 17.22L15.0013 15.17L13.0013 17.17L13.0013 18L16.0013 21L20.0013 21C21.1013 21 22.0013 20.11 22.0013 19.01L22.0013 5C22.0013 3.9 21.1113 3 20.0013 3L16.0013 3L13.0013 6ZM7.95126 7L12.9013 11.95L9.36126 15.49L4.41126 10.54L7.95126 7ZM2.29126 11.24L8.66126 17.61C9.05126 18 9.68126 18 10.0713 17.61L15.0213 12.66C15.4113 12.27 15.4113 11.64 15.0213 11.25L8.66126 4.89C8.27126 4.5 7.64126 4.5 7.25126 4.89L2.30126 9.84C1.90126 10.22 1.90126 10.85 2.29126 11.24Z"
            fill="#9C231F"
        />
    </svg>
);

export default SurveyIcon;
