import React from 'react';
import './AuditoriumNavigationMobile.scss';

import {ReactComponent as Arrow} from '../../../Images/showfloor-arrow-image.svg';

const AuditoriumNavigationMobile = (props) => {
    const {currentTimeSlotIndex, auditoriumTimeslots, setActiveAuditoriumIndex, auditoriums, auditoriumRoomIndex} =
        props;
    const getPreviousAuditoriumIndex = () => {
        let previousAuditoriumIndex = auditoriumRoomIndex - 1;
        if (auditoriumRoomIndex === 0) {
            previousAuditoriumIndex = auditoriums.length - 1;
        }
        return previousAuditoriumIndex;
    };

    const getNextAuditoriumIndex = () => {
        let nextAuditoriumIndex = auditoriumRoomIndex + 1;
        if (auditoriumRoomIndex === auditoriums.length - 1) {
            nextAuditoriumIndex = 0;
        }
        return nextAuditoriumIndex;
    };
    // Hide Because dont is not showing on desktop
    // let formatedTimezoneName = props.timezoneName.split(')');
    // formatedTimezoneName = formatedTimezoneName[0];
    // formatedTimezoneName = formatedTimezoneName.substring(1);

    return (
        <div
            className={
                'auditoriums-navigation-mobile ' +
                (currentTimeSlotIndex !== -1 && auditoriumTimeslots[currentTimeSlotIndex].video ? 'has-booth-wall' : '')
            }
        >
            <div className="button previous" onClick={setActiveAuditoriumIndex(getPreviousAuditoriumIndex())}>
                <div className="button-container">
                    <div className="details">
                        <Arrow className="primary-stroke-svg" />
                    </div>
                </div>
            </div>
            <div className="auditorium-title-container">
                <p className="name">{auditoriums[auditoriumRoomIndex].name}</p>
                <p>
                    {/* <span>{formatedTimezoneName}</span> */}
                    <span>
                        {auditoriumRoomIndex + 1}/{auditoriums.length} STAGES
                    </span>
                </p>
            </div>
            <div className="button next" onClick={setActiveAuditoriumIndex(getNextAuditoriumIndex())}>
                <div className="button-container">
                    <div className="details">
                        <Arrow className="primary-stroke-svg" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuditoriumNavigationMobile;
