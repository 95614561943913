import moment from 'moment';

export const getMobileTeableColoms = (translation) => {
    return [
        {id: 'jobTitle', name: 'jobTitle', label: translation.appliedJobs.jobTitle},
        {
            id: 'createdAt',
            name: 'createdAt',
            label: translation?.appliedJobs?.appliedOn,
            format: (value) => moment(value).format('DD MMM YYYY'),
        },
        {
            id: 'exhibitorCompany',
            name: 'exhibitorCompany',
            label: translation?.appliedJobs?.companyName,
        },
        {
            id: 'status',
            name: 'status',
            label: translation?.appliedJobs?.status,
        },
    ];
};
