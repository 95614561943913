import _ from 'lodash';
import * as actionTypes from '../actionTypes/sessionListTypes';
import {updateObject} from '../utilityStateFunction';

const initialState = {
    sessionList: [],
    pagination: {
        page: 1,
        perPage: 10,
        total: null,
    },
    error: null,
};

const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.GET_SESSIONS_BY_ROLE_SUCCESS: {
            const {sessions, page, perPage, total} = payload;

            let sessionList = [];
            if (payload.page <= state.page || payload.total !== state.total) {
                sessionList = _.uniqBy([...sessions], '_id');
            } else {
                sessionList = _.uniqBy([...state.sessionList, ...sessions], '_id');
            }

            return updateObject(state, {
                error: null,
                sessionList,
                pagination: {page, perPage, total},
            });
        }
        case actionTypes.GET_SESSIONS_BY_ROLE_FAIL: {
            return updateObject(state, {
                error: payload,
            });
        }
        case actionTypes.CHANGE_SESSION_LIST_PAGE: {
            const {page} = payload;
            return updateObject(state, {
                pagination: updateObject(state.pagination, {
                    page,
                }),
            });
        }
        case actionTypes.CLEAR_SESSION_LIST: {
            return initialState;
        }
        default:
            return state;
    }
};

export default reducer;
