import React from 'react';
import PropTypes from 'prop-types';
import {generatePath, useHistory} from 'react-router';
import {recordingsThumbnails, whiteLabel, eventFolder} from 'config';
import {RouterConfig} from 'routerConfig';
import BackButton from 'Routes/components/BackButton/BackButton';
import moment from 'moment';

import './OnDemandTab.scss';
import {YOUTUBE} from 'constants/data/streaming-engine';
import {getVideoIdFromYoutubeUrl} from 'services/utils';

const OnDemandTab = ({onDemandVideos, eventId, eventSlug, goBack}) => {
    const history = useHistory();

    const handleSetOnDemand = (timeslot) => () => {
        history.push({
            pathname: generatePath(RouterConfig.event.auditoriumArhiveSingleVideo, {
                eventSlug,
                timeslotId: timeslot?._id,
            }),
            state: {isRedirectedOnDemand: true, prevLocation: history.location.pathname},
        });
    };

    return (
        <div className="on-demand-tab">
            <div className="on-demand-tab__header">
                <BackButton onClickBack={goBack} />
                <p className="on-demand-tab__back-title">ON-DEMAND</p>
            </div>
            <div className="on-demand-tab__list">
                {onDemandVideos.map((timeslot) => {
                    const {thumbnail, video} = timeslot;

                    const defaultImg = recordingsThumbnails[whiteLabel] || recordingsThumbnails.main;

                    let imgSrc = defaultImg;
                    if (video.length && timeslot.streamingEngine === YOUTUBE)
                        imgSrc = `https://img.youtube.com/vi/${getVideoIdFromYoutubeUrl(video)}/mqdefault.jpg`;

                    if (thumbnail) imgSrc = `${eventFolder}${eventId}/${thumbnail}`;

                    return (
                        <div
                            key={`on-demand-video-item ${timeslot._id}`}
                            className=" on-demand-tab__list-item on-demand-tab-list-item"
                        >
                            <img
                                onClick={handleSetOnDemand(timeslot)}
                                src={imgSrc}
                                className="on-demand-tab-list-item__img"
                                alt="video-cover"
                            />
                            <p className="on-demand-tab-list-item__timeslot-title">{timeslot.title}</p>
                            <p className="on-demand-tab-list-item__auditorium-title">
                                {timeslot.auditoriumName}: {`${timeslot.start}-${timeslot.end}`}
                            </p>
                            <p className="on-demand-tab-list-item__auditorium-title">
                                {moment(timeslot.date).format('DD / MM / YYYY')}
                            </p>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

OnDemandTab.propTypes = {
    onDemandVideos: PropTypes.array,
    goBack: PropTypes.func,
    eventId: PropTypes.string,
    eventSlug: PropTypes.string,
};

export default OnDemandTab;
