import {nanoid} from 'nanoid';
import * as actionTypes from '../../../actionTypes/organizerActionTypes/eventBranding';
import {initialState} from '../initialState';
import {updateObject} from '../../../utilityStateFunction';
import {surveyQuestionFieldTypes, surveyQuestionActionTypes} from 'constants/organizer/eventBranding';

const eventBranding = (state, {type, payload}) => {
    if (state === undefined) {
        return initialState.eventBranding;
    }
    switch (type) {
        case actionTypes.REMOVE_BRANDING_IMAGES_REQUEST:
        case actionTypes.EDIT_BRANDING_IMAGES_REQUEST:
        case actionTypes.GET_SURVEY_QUESTIONS_REQUEST:
        case actionTypes.POST_THANK_YOU_MESSAGE_REQUEST: {
            return updateObject(state.eventBranding, {
                survey: updateObject(state.eventBranding.survey, {
                    loading: true,
                }),
            });
        }

        case actionTypes.GET_SURVEY_QUESTIONS_FAIL:
        case actionTypes.POST_THANK_YOU_MESSAGE_SUCCESS:
        case actionTypes.POST_THANK_YOU_MESSAGE_FAIL:
        case actionTypes.EDIT_BRANDING_IMAGES_FAIL:
        case actionTypes.REMOVE_BRANDING_IMAGES_FAIL: {
            return updateObject(state.eventBranding, {
                survey: updateObject(state.eventBranding.survey, {
                    loading: false,
                }),
            });
        }

        case actionTypes.GET_SURVEY_QUESTIONS_SUCCESS: {
            const questions = payload.map((question) => {
                const optionsValidation = {};

                if (question.options.length) {
                    question.options.map((option) => Object.assign(optionsValidation, {[option._id]: true}));
                }

                return {
                    ...question,
                    validation: {
                        question: true,
                        ...optionsValidation,
                    },
                };
            });
            return updateObject(state.eventBranding, {
                survey: updateObject(state.eventBranding.survey, {
                    questions,
                    loading: false,
                }),
            });
        }
        case actionTypes.CREATE_SURVEY_QUESTION_FORM: {
            const newQuestion = {
                _id: nanoid(),
                question: '',
                isFormCreation: true,
                isChanged: false,
                options: [],
                hidden: true,
                questionType: surveyQuestionFieldTypes.text,
                validation: {
                    question: false,
                },
            };

            return updateObject(state.eventBranding, {
                survey: updateObject(state.eventBranding.survey, {
                    questions: [...state.eventBranding.survey.questions, newQuestion],
                }),
            });
        }
        case actionTypes.DELETE_SURVEY_QUESTION_FORM: {
            const questions = state.eventBranding.survey.questions.filter(({_id}) => _id !== payload);

            return updateObject(state.eventBranding, {
                survey: updateObject(state.eventBranding.survey, {
                    questions,
                }),
            });
        }

        case actionTypes.CHANGE_SURVEY_QUESTION: {
            const {questionId, questionField, questionValue} = payload;

            const questions = state.eventBranding.survey.questions.map((question) => {
                if (question._id !== questionId) {
                    return question;
                } else {
                    if (questionField === surveyQuestionActionTypes.questionType) {
                        const initQuestionOption = {
                            _id: nanoid(),
                            value: '',
                            isFormCreation: true,
                        };
                        const createOptionValidation = [
                            questionField === surveyQuestionActionTypes.questionType,
                            questionValue === surveyQuestionFieldTypes.radio ||
                                questionValue === surveyQuestionFieldTypes.select,
                            question.isFormCreation,
                        ];
                        const isOptionCreated = createOptionValidation.every((rule) => !!rule);
                        const initQuestionOptionValidation = isOptionCreated ? {[initQuestionOption._id]: false} : {};

                        return updateObject(question, {
                            [questionField]: questionValue,
                            options: isOptionCreated ? [...question.options, initQuestionOption] : question.options,
                            validation: updateObject(question.validation, {
                                ...initQuestionOptionValidation,
                            }),
                            isChanged: true,
                        });
                    } else
                        return updateObject(question, {
                            [questionField]: questionValue,
                            isChanged: true,
                        });
                }
            });

            return updateObject(state.eventBranding, {
                survey: updateObject(state.eventBranding.survey, {
                    questions,
                }),
            });
        }
        case actionTypes.REMOVE_SURVEY_QUESTION_OPTION: {
            const {questionId, optionId} = payload;
            const removeProperty = (propKey, {[propKey]: propValue, ...rest}) => rest;

            const questions = state.eventBranding.survey.questions.map((question) => {
                if (question._id !== questionId) {
                    return question;
                } else {
                    const validation = removeProperty(optionId, question.validation);
                    return {
                        ...question,
                        isChanged: true,
                        options: question.options.filter((option) => option._id !== optionId),
                        validation,
                    };
                }
            });

            return updateObject(state.eventBranding, {
                survey: updateObject(state.eventBranding.survey, {
                    questions,
                }),
            });
        }
        case actionTypes.ADD_SURVEY_QUESTION_OPTION: {
            const initQuestionOption = {
                _id: nanoid(),
                value: '',
                isFormCreation: true,
            };

            const questions = state.eventBranding.survey.questions.map((question) => {
                if (question._id !== payload) {
                    return question;
                } else {
                    return {
                        ...question,
                        isChanged: true,
                        options: [...question.options, initQuestionOption],
                        validation: updateObject(question.validation, {
                            [initQuestionOption._id]: false,
                        }),
                    };
                }
            });

            return updateObject(state.eventBranding, {
                survey: updateObject(state.eventBranding.survey, {
                    questions,
                }),
            });
        }
        case actionTypes.CHANGE_SURVEY_QUESTION_OPTION: {
            const {questionId, optionId, optionValue} = payload;

            const updateOption = (arr, optionId, newValue) =>
                arr.map((el) => {
                    if (el._id !== optionId) {
                        return el;
                    } else
                        return {
                            ...el,
                            value: newValue,
                        };
                });

            const questions = state.eventBranding.survey.questions.map((question) => {
                if (question._id !== questionId) {
                    return question;
                } else {
                    return {
                        ...question,
                        isChanged: true,
                        options: updateOption(question.options, optionId, optionValue),
                    };
                }
            });

            return updateObject(state.eventBranding, {
                survey: updateObject(state.eventBranding.survey, {
                    questions,
                }),
            });
        }

        case actionTypes.EDIT_BRANDING_IMAGES_SUCCESS: {
            return updateObject(state.eventBranding, {...state.eventBranding, ...payload});
        }

        case actionTypes.REMOVE_BRANDING_IMAGES_SUCCESS: {
            return updateObject(state.eventBranding, {...state.eventBranding, [payload]: ''});
        }

        default:
            return state.eventBranding;
    }
};

export default eventBranding;
