import {allTranslations} from '../../constants/translations/all-translations';

export const setLanguageDirection = (language) => {
    // always make sure that we remove the attribute (ex when the user logs out from the event)
    // document.documentElement = <html> DOM element
    document.documentElement.removeAttribute('dir');

    // this function is also called from getEventInfo in eventActions
    // after we get the eventInfo we immediatelly set the default language that the organizer chose
    if (language === allTranslations.ar.slug) {
        document.documentElement.setAttribute('dir', 'rtl');
        document.documentElement.classList.add('lang-rtl');
    } else {
        document.documentElement.setAttribute('dir', 'ltr');
        document.documentElement.classList.remove('lang-rtl');
    }
};
