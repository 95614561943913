import MyAccountMobile from './MyAccountMobile';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import * as actions from '../../../../../../../../../store/actions';

export default withRouter(
    connect(
        (state) => ({
            user: state.user.data,
            eventRoles: state.user.eventRoles,
            event: state.event.data,
            eventId: state.event.eventId,
            eventSlug: state.event.eventSlug,
            eventProfile: state.event.profile,
            profileTabs: state.user.profileTabs,
            translation: state.languages.translations[state.languages.platformLanguage],
            platformLanguage: state.languages.platformLanguage,
        }),
        {
            onRefreshUserUserData: actions.refreshUserData,
            seeOnventProfile: actions.topNavOnventProfile,
            seeEventProfile: actions.topNavEventProfile,
            getEventProfile: actions.getEventProfile,
        }
    )(MyAccountMobile)
);
