import React, {useState, useEffect} from 'react';
import ConfirmDialog from 'Routes/components/ConfirmDialog/ConfirmDialog';
import {List, ListItem} from '@material-ui/core';
import {SAFARI_POP_UP_STORAGE_NAME, SAFARI_POP_UP_STATUS_LIST} from 'constants/SafariPopUpStorageName';
import {isSafari, isMobileSafari} from 'react-device-detect';
import './style.scss';

function SafariPopUpBlockerModal() {
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        try {
            let isShow = window.sessionStorage.getItem(SAFARI_POP_UP_STORAGE_NAME) !== SAFARI_POP_UP_STATUS_LIST.HIDE;
            setShowModal(isShow && isSafari);
        } catch (error) {
            setShowModal(false);
        }
    }, []);

    const doNotShowAgainModal = () => {
        window.sessionStorage.setItem(SAFARI_POP_UP_STORAGE_NAME, SAFARI_POP_UP_STATUS_LIST.HIDE);
        try {
            if (isMobileSafari) {
                window.location.href = 'prefs:root=Safari';
            }
        } catch (error) {}
    };

    const description = () => (
        <>
            {isMobileSafari ? (
                <List>
                    <ListItem>
                        <div>
                            Launch <span className={'bold'}>{` Settings`}</span>.
                        </div>
                    </ListItem>
                    <ListItem>
                        <div>
                            Tap <span className={'bold'}>{` Safari`}</span>.
                        </div>
                    </ListItem>
                    <ListItem>
                        <div>
                            Under the <span className={'bold'}>General</span> section, click the toggle next to{' '}
                            <span className={'bold'}>{` Block Pop-ups`}</span> to enable or disable the pop-up blocker.
                            A green toggle indicates an enabled pop-up blocker.
                        </div>
                    </ListItem>
                    <ListItem>
                        <div>
                            If you enable pop-ups but are unable to generate a pop-up, refresh the web page to
                            re-generate the pop-up.
                        </div>
                    </ListItem>
                </List>
            ) : (
                <List>
                    <ListItem>
                        <div>
                            {`In the Safari app  on your Mac, choose `}
                            <span className={'bold'}>{`Safari`}</span>
                            {` > `}
                            <span className={'bold'}>{`Preferences`}</span>
                            {`, then click`}
                            <span className={'bold'}>{`Websites`}</span>.
                        </div>
                    </ListItem>
                    <ListItem>
                        <div>Click Pop-up Windows.</div>
                    </ListItem>
                    <ListItem>
                        <div>
                            Allow pop-up windows for<span className={'bold'}>{` ${window?.location?.origin}`}</span>
                        </div>
                    </ListItem>
                    <ListItem>
                        <div>
                            If you enable pop-ups but are unable to generate a pop-up, refresh the web page to
                            re-generate the pop-up.
                        </div>
                    </ListItem>
                </List>
            )}
        </>
    );

    return (
        <ConfirmDialog
            open={showModal}
            dialogTitle={`Please disable the pop-up blocker on your ${isMobileSafari ? 'iOS device' : 'Mac'}`}
            dialogDescription={description()}
            handleConfirm={() => {
                setShowModal(false);
                doNotShowAgainModal();
            }}
            dialogConfirmButtonLabel={'Ok'}
            btnVariant={'text'}
            closeConfirm={() => {
                setShowModal(false);
            }}
        />
    );
}

export default SafariPopUpBlockerModal;
