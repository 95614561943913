import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {TextField} from '@material-ui/core';

import ColoredScrollbars from '../../../../../../../ColoredScrollbars/ColoredScrollbars';
import * as actions from '../../../../../../../../../store/actions';
import {usePlatformTranslation} from '../../../../../../../../../services/hooks';
import AddUserList from '../GroupChat/components/AddUserList/AddUserList';

import './CreateGroupChat.scss';
import classNames from 'classnames';
import CustomCancelButton from 'Routes/components/CustomCancelButton';

const CreateGroupChat = ({handleClose}) => {
    const dispatch = useDispatch();
    const {data: event, usersList} = useSelector((state) => state.event);
    const user = useSelector((state) => state.user.data);
    const {eventId} = useSelector((state) => state.event);
    const {eventChats} = useSelector((state) => state.user.chats);

    const [search, setSearch] = useState('');
    const [groupChatName, setGroupChatName] = useState('');
    const [participants, setParticipants] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);

    const translation = usePlatformTranslation();

    useEffect(() => {
        dispatch(actions.getEventUsersList());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event.owner]);

    const filterUsers = useCallback(() => {
        const filteredUsers = usersList.filter((userObj) => {
            const fullName = `${userObj.first} ${userObj.last}`;

            if (fullName.toLowerCase().includes(search.toLowerCase()) && userObj._id !== user._id) {
                return userObj;
            }

            return false;
        });

        if (!search.length && usersList.length) setFilteredUsers(usersList);

        setFilteredUsers(filteredUsers);
    }, [usersList, search, user._id]);

    useEffect(() => {
        if (!usersList?.length) return;

        setLoading(true);
        const timer = setTimeout(() => {
            filterUsers();
            setLoading(false);
        }, 500);

        return () => {
            clearTimeout(timer);
        };
    }, [search, filterUsers, usersList]);

    const handleAddUserToGroupChat = (participant) => {
        setParticipants([...participants, participant]);
    };

    const handleRemoveUserFromGroupChat = (userId) => {
        setParticipants(participants.filter((participant) => participant._id !== userId));
    };

    const handleCreateGroupChat = () => {
        const groupChatData = {
            name: groupChatName,
            event: event._id,
            users: participants.map((participant) => participant._id),
        };

        dispatch(actions.createGroupChat(groupChatData));

        handleClose();

        dispatch(actions.updateActiveChatTab({eventChats}, eventId));
    };

    const onSearchChange = ({target: {value}}) => setSearch(value);

    return (
        <div className="create-group-chat">
            <div>
                <div className="new-chat-wrapper">
                    <div className="input-container">
                        <ColoredScrollbars>
                            <p className="d-none">{translation?.chatsDropdown.newChatProvideText}</p>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="name"
                                label={translation?.chatsDropdown.newChatNameInput}
                                onChange={(e) => setGroupChatName(e.target.value)}
                                value={groupChatName}
                                type="text"
                                fullWidth
                                variant="outlined"
                            />
                            <p>{translation?.chatsDropdown.newChatAddMembers}</p>
                            {event && (
                                <AddUserList
                                    translation={translation}
                                    searchValue={search}
                                    onSearch={onSearchChange}
                                    isLoading={loading}
                                    filteredUsers={filteredUsers}
                                    usersToAdd={participants}
                                    onAddUser={handleAddUserToGroupChat}
                                    onRemoveUser={handleRemoveUserFromGroupChat}
                                />
                            )}
                        </ColoredScrollbars>
                    </div>
                </div>
                <div className="new-chat-buttons-container create-group">
                    <CustomCancelButton text={translation?.chatsDropdown.newChatCancelButton} onClick={handleClose} />
                    <button
                        className={classNames('create-button', {isRtl: isRtlLanguage})}
                        onClick={handleCreateGroupChat}
                        color={participants.length ? 'primary' : 'default'}
                        disabled={!participants.length}
                    >
                        {translation?.chatsDropdown.newChatCreateButton}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateGroupChat;
