import * as momentTz from 'moment-timezone';
import moment from 'moment';
import {timeZonesListWithUTC} from '../../constants/shared';

export const getCurrentTime = () => moment().format('HH:mm');

export const getUtcTimestamp = (date) => moment(date).format('x');

export const getCurrentDay = () => moment().format('YYYY-MM-DD');

export const getLocalTimeZone = () => momentTz.tz.guess();

export const normalizeTimeZone = (timeZoneStr) => `(GMT${momentTz.tz(timeZoneStr).format('Z')}) ${timeZoneStr}`;

export const denormalizeTimeZone = (timeZoneStr) => timeZoneStr.slice(12);

export const getTimeZoneList = () =>
    timeZonesListWithUTC.map((tz) => ({...tz, label: `(GMT ${momentTz.tz(tz.utc).format('Z')}) ${tz.label}`}));
export const getTimeZoneByUTC = (utc) => timeZonesListWithUTC.find((tz) => tz.utc === utc);

export const convertDateInTimeZone = (date, tzFrom, formatValue = '', tzTo = getLocalTimeZone()) => {
    return moment(date).tz(tzFrom, true).tz(tzTo).format(formatValue);
};

export const checkIfUserUseAMPM = () => {
    const date = new Date(Date.UTC(2012, 11, 12, 3, 0, 0));
    const dateString = date.toLocaleTimeString();

    if (dateString.match(/am|pm/i) || date.toString().match(/am|pm/i)) {
        //12 hour clock
        return true;
    }
    //24 hour clock
    return false;
};
