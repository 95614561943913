import React from 'react';

const SpeakerMenuIcon = ({color}) => (
    <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#a)">
            <g clipPath="url(#b)">
                <path
                    d="m20.1.5-.9.9c2.5 3.1 2.5 7.5 0 10.7l.9.9c3.2-3.5 3.2-8.8 0-12.5Z"
                    fill={color ? color : '#D62E2F'}
                />
                <path d="m18 3.9-1 1c.7 1.2.7 2.6 0 3.8l1 1c1.4-1.7 1.4-4 0-5.8Z" fill={color ? color : '#D62E2F'} />
                <circle cx="10" cy="8.5" r="4.5" stroke={color ? color : '#D62E2F'} />
            </g>
            <path
                d="M18 22.881v-2.54c-.064-1.436-.417-2.61-1.106-3.563-.689-.96-1.698-1.667-3.076-2.159l-.337-.119-.272.23a4.68 4.68 0 0 1-1.466.85 4.91 4.91 0 0 1-3.341 0 4.821 4.821 0 0 1-1.467-.866L6.68 14.5l-.32.095a6.13 6.13 0 0 0-3.15 2.183A5.984 5.984 0 0 0 2 20.373V23.5h16v-.619Zm-15-.373v-2.15c0-1.064.391-2.049 1-2.858.553-.73 1.51-1.587 2.407-1.92a5.99 5.99 0 0 0 1.562.856 6.093 6.093 0 0 0 2.1.373 6.077 6.077 0 0 0 3.638-1.214c.968.397 1.931 1.19 2.42 1.873.545.754.825 1.723.873 2.913V22.5H3.234L3 22.508Z"
                fill={color ? color : '#D62E2F'}
            />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" transform="translate(0 .5)" d="M0 0h24v24H0z" />
            </clipPath>
            <clipPath id="b">
                <path fill="#fff" transform="translate(0 .5)" d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);

export default SpeakerMenuIcon;
