import React from 'react';
import cn from 'classnames';

import {mediaTypes} from '../../../constants/data';

import './Media.scss';

const Media = ({media, isAdaptable, isIcon = false, ...rest}) => {
    return (
        <div className={cn('Media', {Media_adaptable: isAdaptable, isIcon})}>
            {media.type === mediaTypes.video ? (
                <video key={media.src} className="Media__image" alt={media.title} {...rest} controls>
                    <source src={media.src} type="video/webm" />
                    <source src={media.src} type="video/ogg" />
                    <source src={media.src} type="video/mp4" />
                    <source src={media.src} type="video/mov" />
                </video>
            ) : (
                <img
                    key={media.src}
                    className="Media__image"
                    src={media.src}
                    srcSet={`${media.srcSet || media.src} 2x`}
                    alt={media.title}
                    {...rest}
                />
            )}
        </div>
    );
};

export default Media;
