import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {MATCHING_QUESTION_TYPES, MATCHMAKING__QUESTION_TYPES, MATCHMAKING__SELECT_TYPES} from 'constants/matchMaking';
import {nozmalizeAnswerData} from 'services/helpers';
import {usePlatformTranslation} from 'services/hooks';
import {IconSave, IconTrash} from 'Routes/components/Icons';
import MatchingRadio from './components/MatchingRadio';
import MatchingCheckBox from './components/MatchingCheckBox';
import Button from 'Routes/components/Button/Button';
import ConfirmDialog from 'Routes/components/ConfirmDialog/ConfirmDialog';

import './MyMatchmaking.scss';

const MyMatchmaking = ({
    matchingAnswers,
    matchingQuestions,
    onGetMatchmakingForm,
    onGetUserMatchingAnswers,
    matchingFormId,
    onPostMatchingAnswers,
    onDeleteMatchingAnswers,
    userLoading,
    isLargeScreen,
    matchingLoading,
    onSetCompleteMatching,
    eventId,
}) => {
    const [formAnswers, setFormAnswers] = useState([]);
    const [opentDeleteDialog, setOpentDeleteDialog] = useState(false);
    const {matchmaking: t_matchmaking, generalText: t_generalText} = usePlatformTranslation();

    useEffect(() => {
        if (matchingFormId) onGetUserMatchingAnswers(matchingFormId);
    }, [matchingFormId, onGetUserMatchingAnswers]);

    useEffect(() => {
        onGetMatchmakingForm();
    }, [onGetMatchmakingForm]);

    useEffect(() => {
        if (!userLoading && !matchingLoading && matchingAnswers) setFormAnswers(matchingAnswers);
    }, [matchingAnswers, matchingLoading, userLoading]);

    const handleChangeRadio = ({target}) => {
        const questionId = target.name;
        const optionId = target.value;

        const isNewAnswer = !formAnswers.find((el) => el.question === questionId);

        if (isNewAnswer) {
            setFormAnswers([...formAnswers, {question: questionId, options: [{optionId}]}]);
            return;
        }

        const newAnswers = formAnswers.map((el) => {
            if (el.question !== questionId) return el;
            return {...el, options: [{optionId}]};
        });
        setFormAnswers(newAnswers);
    };

    const handleChangeCheckBox = ({target}) => {
        const questionId = target.name;
        const optionId = target.value;

        const questionInstans = formAnswers.find((el) => el.question === questionId);

        if (!questionInstans) {
            setFormAnswers([...formAnswers, {question: questionId, options: [{optionId}]}]);
            return;
        }

        const isNewOption = !questionInstans.options.find((el) => el.optionId === optionId);

        const newAnswers = formAnswers.map((answer) => {
            if (answer.question !== questionId) return answer;

            const options = !isNewOption
                ? answer.options.filter((el) => el.optionId !== optionId)
                : [...answer.options, {optionId}];

            return {...answer, options};
        });

        setFormAnswers(newAnswers);
    };

    const onSubmit = () => {
        const answers = nozmalizeAnswerData(formAnswers);
        const callback = () => {
            onSetCompleteMatching({eventId, completeMatching: true});
        };
        onPostMatchingAnswers({data: {answers}, matchingFormId, callback});
    };

    const onDelete = () => {
        onDeleteMatchingAnswers();
        setOpentDeleteDialog(false);
    };

    const handleOpenDeleteDialog = () => {
        setOpentDeleteDialog(true);
    };

    const isValid = React.useMemo(() => {
        if (!matchingQuestions) return;

        const hasRequired = matchingQuestions.some((question) => question.isRequired);
        const includesRequired = !!matchingQuestions.filter(
            (question) =>
                formAnswers.find((answer) => answer.question === question._id)?.options.length && question.isRequired
        ).length;

        return hasRequired ? includesRequired : true;
    }, [formAnswers, matchingQuestions]);

    const isDisabled = _.isEqual(formAnswers, matchingAnswers) || !isValid || userLoading || matchingLoading;
    const isDisabledDelete = _.isEmpty(formAnswers) || userLoading || matchingLoading;

    return (
        <div className="my-matchmaking">
            {!_.isEmpty(matchingQuestions) && (
                <>
                    <Button
                        className="my-matchmaking__delete-btn"
                        type="button"
                        icon={<IconTrash />}
                        title={isLargeScreen ? t_generalText.delete : ''}
                        isDisabled={isDisabledDelete}
                        onClick={handleOpenDeleteDialog}
                    />
                    <div className="my-matchmaking__answer-container">
                        {matchingQuestions.map((question, index) => {
                            const isCheckBox = question.type === MATCHING_QUESTION_TYPES.checkbox.value;
                            const answer = formAnswers.find((el) => el.question === question._id);
                            const value = isCheckBox
                                ? answer?.options?.map((optionId) => optionId)
                                : answer?.options[0]?.optionId;
                            return (
                                <div
                                    key={`question-wrapper${question._id}`}
                                    className="my-matchmaking__question-wrapper"
                                >
                                    <p className="my-matchmaking__question-type">
                                        <span className="my-matchmaking__question-count">
                                            {`${index + 1}/${matchingQuestions.length}`}
                                        </span>
                                        {question?.isRequired
                                            ? MATCHMAKING__QUESTION_TYPES.required
                                            : MATCHMAKING__QUESTION_TYPES.optional}
                                    </p>
                                    <p className="my-matchmaking__question-title">{question.title}</p>
                                    <div className="my-matchmaking__label-wrapper">
                                        <p className="my-matchmaking__label-title">
                                            {isCheckBox
                                                ? MATCHMAKING__SELECT_TYPES.multiSelect
                                                : MATCHMAKING__SELECT_TYPES.singleSelect}
                                        </p>

                                        {isCheckBox ? (
                                            <MatchingCheckBox
                                                question={question}
                                                value={value}
                                                onChange={handleChangeCheckBox}
                                            />
                                        ) : (
                                            <MatchingRadio
                                                question={question}
                                                value={value}
                                                onChange={handleChangeRadio}
                                            />
                                        )}
                                    </div>
                                </div>
                            );
                        })}

                        <Button
                            key={`${isDisabled}`}
                            type="button"
                            icon={<IconSave />}
                            title={t_generalText.save}
                            isDisabled={isDisabled}
                            onClick={onSubmit}
                        />
                    </div>
                </>
            )}
            <ConfirmDialog
                open={opentDeleteDialog}
                handleConfirm={onDelete}
                closeConfirm={() => {
                    setOpentDeleteDialog(false);
                }}
                dialogConfirmButtonLabel={t_generalText.delete}
                dialogCancelButtonLabel={t_generalText.cancel}
                btnVariant="text"
                dialogTitle={t_matchmaking.deleteMatchingTitle}
                dialogDescription={t_matchmaking.deleteMatchingText}
            />
        </div>
    );
};

MyMatchmaking.propTypes = {
    matchingAnswers: PropTypes.array,
    matchingQuestions: PropTypes.array,
    onGetMatchmakingForm: PropTypes.func,
    onGetUserMatchingAnswers: PropTypes.func,
    matchingFormId: PropTypes.string,
    onPostMatchingAnswers: PropTypes.func,
    onDeleteMatchingAnswers: PropTypes.func,
    userLoading: PropTypes.bool,
    matchingLoading: PropTypes.bool,
    isLargeScreen: PropTypes.bool,
    onSetCompleteMatching: PropTypes.func,
    eventId: PropTypes.string,
};

export default MyMatchmaking;
