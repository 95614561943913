import React from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {eventFolder, whiteLabel} from '../../../config';
import {logoConfig, LOGO_TYPES} from './config/logo.config';
import {getEvent} from '../../../store/selectors';
import {withRouter} from 'react-router';
import {RouterConfig} from 'routerConfig';
import {matchPath} from 'react-router';
import {useLocation} from 'react-router-dom';

const MainLogo = ({colored, className}) => {
    const {eventInfo, data} = useSelector(getEvent);
    const {pathname} = useLocation();
    const match = matchPath(pathname, {path: RouterConfig.event.homepage});

    const event = [data, eventInfo].find((event) => event?.slug === match?.params?.eventSlug);

    const customLogo = event?.customLogo;

    const usedEventId = event?._id;
    const eventCustomLogoUrl = `${eventFolder}${usedEventId}/${customLogo}`;

    const logoTypeKey = colored ? LOGO_TYPES.colored : LOGO_TYPES.white;
    const platformLogo = logoConfig[whiteLabel] ? logoConfig[whiteLabel][logoTypeKey] : logoConfig.main[logoTypeKey];

    const imgSrc = !!customLogo ? eventCustomLogoUrl : platformLogo;

    return <img src={imgSrc} className={classNames('', {[className]: className})} alt="Event10X Logo" />;
};

export default withRouter(MainLogo);
