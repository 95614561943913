import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    container: {
        width: 328,
        background: theme.palette.background.default,
        height: '100vh',
        textAlign: 'left',
    },
    btnWrapper: {
        width: '100%',
        boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.2)',
    },
    btn: {
        width: '100%',
        height: 48,
        fontWeight: 'bold',
        fontSize: 16,
        letterSpacing: '0.0125em',
        textTransform: 'uppercase',
        color: theme.palette.secondary.main,
        justifyContent: 'left',
    },
    formControl: {
        width: '100%',
    },
    radioGroup: {
        padding: 15,
        width: '100%',
    },
    labelRoot: {
        display: 'flex',
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
        margin: '0px 10px',
    },
    label: {
        fontWeight: 600,
        fontSize: 16,
        lineHeight: '20px',
        color: theme.palette.text.primary,
    },
}));
