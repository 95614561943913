/* eslint-disable */
import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {isMobile} from 'react-device-detect';
import {generatePath} from 'react-router';

import CustomHeader from '../CustomHeader';
import HeaderButtons from './components/HeaderButtons';

import {getEventRegistrationLink, getEventLandingLink} from 'routes';
import {useEventTranslation} from 'services/hooks';

import {whiteLabel} from 'config';
import {participantsRegistrationAccessUpdated, usersLoginAccessUpdated} from 'Api/socketApi';
import {redirectToRegistrationOrContinueLogin} from 'services/utils/redirectToRegistrationOrContinueLogin';
import {IS_MAIN_ENV} from 'constants/shared';
import {RouterConfig} from 'routerConfig';

const withEventTranlation =
    (Component) =>
    ({...rest}) => {
        const t_createAnAccount = useEventTranslation((translation) => translation.eventRegistration.createAnAccount);

        return <Component t_createAnAccount={t_createAnAccount} {...rest} />;
    };

class HeaderMenu extends React.Component {
    state = {
        mobileMenuVisible: false,
        headerScrolled: false,
        translation: null,
    };

    componentDidMount() {
        const {eventId, changeParticipantsRegistrationAccess, changeUsersLoginAccess} = this.props;

        window.addEventListener('scroll', this.setHeaderScrolled);
        this.setLanguagesData();
        participantsRegistrationAccessUpdated((err, data) => {
            if (data.eventId !== eventId) return;
            changeParticipantsRegistrationAccess(data);
        });
        usersLoginAccessUpdated((err, data) => {
            if (data.eventId !== eventId) return;
            changeUsersLoginAccess(data);
        });
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.eventLanguage];
        this.setState({
            translation: translation,
        });
    };

    componentDidUpdate(prevProps) {
        if (prevProps.languages.eventLanguage !== this.props.languages.eventLanguage) {
            this.setLanguagesData();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.setHeaderScrolled);
    }

    setHeaderScrolled = () => {
        if (window.scrollY > 0) {
            if (this.state.headerScrolled === false) {
                this.setState({headerScrolled: true});
            }
        } else {
            if (this.state.headerScrolled === true) {
                this.setState({headerScrolled: false});
            }
        }
    };

    onHandleLogout = (e) => {
        e.preventDefault();
        this.props.onLogoutUser();
    };

    handleChangeLanguage = (e) => {
        const selectedLanguage = e.target.value;
        this.props.onSetEventLanguage(selectedLanguage);
    };

    render() {
        const {
            loggedIn,
            eventSlug,
            eventInfo,
            isRtlLanguage,
            location: {search, pathname},
        } = this.props;
        const {translation} = this.state;

        const isEventHomepage =
            !loggedIn && (pathname === `/event/${eventSlug}/` || pathname === `/event/${eventSlug}`);

        const isRegisterButtonShowed =
            search !== '?registerAsParticipant=true' &&
            search !== '?registerAsExhibitor=true' &&
            !pathname.includes('/register') &&
            pathname !== getEventLandingLink(eventSlug) &&
            !isEventHomepage &&
            !eventInfo?.hideParticipantsLink;

        const hideLogin = eventInfo?.hideLoginLink && IS_MAIN_ENV;

        const isLoginButtonShowed = !pathname.includes('/login') && !hideLogin;

        const isShownPlatformLanguage = !this.props.disabledLanguageSelector && !pathname.includes('/welcome');

        return (
            <CustomHeader
                className={this.props.className}
                isRtlLanguage={isRtlLanguage}
                headerScrolled={this.state.headerScrolled}
                menuWrapperChildren={
                    <HeaderButtons
                        translation={translation}
                        isLoginButtonShowed={isLoginButtonShowed}
                        isRegisterButtonShowed={isRegisterButtonShowed}
                        eventSlug={eventSlug}
                        loggedIn={this.props.loggedIn}
                        onHandleLogout={this.onHandleLogout}
                    />
                }
                coloredLogo={this.props.coloredLogo || this.state.headerScrolled}
                linkTo={{pathname: generatePath(RouterConfig.event.homepage, {eventSlug})}}
                isEventLanding
                hidePlatformLanguage={!isShownPlatformLanguage}
            >
                <div
                    className={classNames('mobile-menu-wrapper', {
                        hide: location.pathname.includes('/welcome') && isMobile,
                    })}
                >
                    <div className="bottom-links-container">
                        <div className={classNames('menu-links', whiteLabel)}>
                            {this.props.loggedIn ? (
                                <a onClick={this.onHandleLogout}>
                                    <span>{translation?.menu.logOut}</span>
                                </a>
                            ) : (
                                <>
                                    {isLoginButtonShowed && (
                                        <Link to={redirectToRegistrationOrContinueLogin(`/event/${eventSlug}/login`)}>
                                            <span>{translation?.menu.logIn}</span>
                                        </Link>
                                    )}

                                    {isRegisterButtonShowed && (
                                        <Link to={getEventRegistrationLink(eventSlug)}>
                                            <span>{translation?.menu.participantRegistration}</span>
                                        </Link>
                                    )}
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </CustomHeader>
        );
    }
}

export default withEventTranlation(HeaderMenu);
