import React from 'react';

const MovieIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M4 6L8 4L8 7L4 9L4 11L8 9L8 12L4 14L4 16L8 14L8 17L4 19L4 20C4 21.1 4.9 21.99 6 21.99L18 22C19.1 22 20 21.1 20 20L20 4C20 2.9 19.1 2 18 2L3.99999 2L4 6Z"
            fill="#9C231F"
        />
    </svg>
);

export default MovieIcon;
