import React from 'react';
import classNames from 'classnames';
import {useDispatch, useSelector} from 'react-redux';

import Button from '../../../../../../../Button/Button';
import {IconPlus, IconSave, IconEdit} from '../../../../../../../Icons';

import {useBusinessCardData, usePlatformTranslation, useUserCompany} from 'services/hooks';
import {createBusinessCard, fillUpBusinessCardDataFromAccount, requestBusinessCardUpdating} from 'store/actions';
import {getIsRtlLanguage, getUserData} from 'store/selectors';
import {emptyBusinessCard} from 'services/helpers';

import './BusinessCardSetupHeader.scss';

const BusinessCardSetupHeader = () => {
    const dispatch = useDispatch();
    const {getCompanyByUserId} = useUserCompany();
    const {businessCard, isReady, isSaveButtonDisabled} = useBusinessCardData();
    const {businessCard: businessCardTranslation, generalText} = usePlatformTranslation();
    const isRtlLanguage = useSelector(getIsRtlLanguage);
    const userData = useSelector(getUserData);
    const userId = userData?._id;

    return (
        <div className={classNames('BusinessCardSetupHeader', {isRtl: isRtlLanguage})}>
            {businessCard && (
                <div className="BusinessCardSetupHeader__item">
                    <div className="BusinessCardSetupHeader__fillUpData">
                        <Button
                            icon={<IconEdit />}
                            title={businessCardTranslation.fillUpData}
                            onClick={() =>
                                dispatch(fillUpBusinessCardDataFromAccount(userData, getCompanyByUserId(userId)))
                            }
                        />
                    </div>
                </div>
            )}

            <div className="BusinessCardSetupHeader__item">
                <div className="BusinessCardSetupHeader__setUp">
                    {businessCard ? (
                        <Button
                            icon={<IconSave />}
                            title={`${generalText.save}*`}
                            isDisabled={isSaveButtonDisabled}
                            onClick={() => dispatch(requestBusinessCardUpdating())}
                        />
                    ) : (
                        <Button
                            icon={<IconPlus />}
                            title={businessCardTranslation.cardSetUp}
                            isDisabled={!isReady}
                            onClick={() => dispatch(createBusinessCard({businessCard: emptyBusinessCard}))}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default BusinessCardSetupHeader;
