const mimoTypeByExtenstion = {
    '.png': 'image/png',
    '.jpeg': 'image/jpeg',
    '.pdf': 'application/pdf',
    '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    '.mov': 'video/quicktime',
    '.mp4': 'video/mp4',
    '.avi': 'video/x-msvideo',
    '.ogg': 'video/ogg',
};

export default mimoTypeByExtenstion;
