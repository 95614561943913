import {createSelector} from 'reselect';

const exhibitors = (state) => state.exhibitors;
const exhibitor = (state) => state.exhibitor;

export const getExhibitors = createSelector(exhibitors, (state) => state);

export const getExhibitorsLoading = createSelector(exhibitors, (state) => state.loading);
export const getExhibitorsList = createSelector(exhibitors, (state) => state.exhibitors);
export const getApprovedExhibitors = createSelector(exhibitors, (state) => state.approvedExhibitors);
export const getActiveExhibitorId = createSelector(exhibitors, (state) => state.activeExhibitorId);
export const getFilteredExhibitors = createSelector(exhibitors, (state) => state.filteredExhibitors);
export const getExhibitorsFilters = createSelector(exhibitors, (state) => state.exhibitorsFilters);
export const getFeaturedExhibitors = createSelector(exhibitors, (state) => state.featuredExhibitors);

export const getExhibitorData = createSelector(exhibitor, (state) => state.data);
export const getExhibitorLoading = createSelector(exhibitor, (state) => state.loading);
export const getExhibitorId = createSelector(exhibitor, (state) => state.exhibitorId);
