import React from 'react';
import {useSelector} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import {Radio} from '@material-ui/core';
import {CheckCircle as CheckCircleIcon} from '@material-ui/icons';
import classNames from 'classnames';
import {getUserId} from 'store/selectors';

const CustomRadio = withStyles((theme) => ({
    root: {
        color: '#666666',
        '&$checked': {
            color: theme.palette.primary.main,
        },
    },
    checked: {},
}))((props) => <Radio {...props} />);

const SinglePollOption = ({
    option,
    handleSelect,
    userVoted,
    selectedOption,
    finished,
    voteResults,
    index,
    multiSelect,
    hideResults,
}) => {
    const userId = useSelector(getUserId);

    const optionVoted = option.votes.includes(userId);
    const canBeVoted = (!multiSelect && !userVoted) || (multiSelect && !optionVoted);

    return (
        <div
            className={classNames('poll_option', {
                '-is-checked': optionVoted,
            })}
            onClick={() => handleSelect(option._id)}
        >
            <div className="poll_option-container">
                <div>
                    <h1 className="poll_option-title">{option.text}</h1>
                    <p className="poll_option-text">
                        {voteResults && optionVoted && !hideResults && `${voteResults[index]}% participants voted`}
                    </p>
                </div>
                {!finished && canBeVoted && !selectedOption && (
                    <CustomRadio className="poll_option-icon" checked={selectedOption} />
                )}
                {option.votes.includes(userId) && <CheckCircleIcon className="poll_option-icon -colored" />}
            </div>

            {voteResults && optionVoted && !hideResults && (
                <div className="poll_option-progress-wrapper">
                    <div
                        className="poll_option-progress"
                        style={{
                            transform: `translateX(-${100 - (voteResults ? voteResults[index] : 0)}%)`,
                        }}
                    ></div>
                </div>
            )}
        </div>
    );
};

export default SinglePollOption;
