import React, {useEffect, useRef, useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import hark from 'hark';
import {
    Mic as MicIcon,
    MicOff as MicOffIcon,
    Videocam as VideocamIcon,
    VideocamOff as VideocamOffIcon
} from '@material-ui/icons';

import {getConsumers} from 'store/selectors';
import {MEDIA_TYPES} from 'constants/organizer/sessions';

import VideoAvatar from '../../../VideoAvatar';

import {useStyles} from './Session.styles';

export function Session({
    session: {pausedConsumers, consumers, displayName, avatarUrl},
    activeStreamsCount,
    isParticipantScreenShareEnabled
}) {
    const classes = useStyles({activeStreamsCount});
    const [videoTrack, setVideoTrack] = useState(null);
    const [audioTrack, setAudioTrack] = useState(null);
    const [isVideoPaused, setIsVideoPaused] = useState(false);
    const [isActiveSpeaker, setIsActiveSpeaker] = useState(false);
    const [isAudioPaused, setIsAudioPaused] = useState(false);
    const storeConsumers = useSelector(getConsumers);
    const videoEl = useRef();
    const audioEl = useRef();

    const enableTracks = useCallback(
        (newVideoTrack, newAudioTrack) => {
            if (audioTrack === newAudioTrack && videoTrack === newVideoTrack) return;

            if (newAudioTrack) {
                const stream = new MediaStream();

                stream.addTrack(newAudioTrack);
                audioEl.current.srcObject = stream;

                audioEl.current.play().catch((error) => console.warn('audioEl.play() failed:%o', error));
            } else {
                audioEl.current.srcObject = null;
            }

            if (newVideoTrack) {
                const stream = new MediaStream();
                stream.addTrack(newVideoTrack);
                videoEl.current.srcObject = stream;
                videoEl.current.id = newVideoTrack.id;

                videoEl.current.onplay = () => {
                    audioEl.current.play().catch((error) => console.warn('audioEl.play() failed:%o', error));
                };

                videoEl.current.play().catch((error) => console.warn('videoEl.play() failed:%o', error));
            } else {
                videoEl.current.srcObject = null;
            }
        },
        [audioTrack, videoTrack]
    );

    const getTracks = useCallback(() => {
        const consumersArray = consumers.map((consumerId) => storeConsumers[consumerId]);
        const audioConsumer = consumersArray.find((consumer) => consumer.track.kind === MEDIA_TYPES.audio);
        const videoConsumer = consumersArray.find((consumer) => consumer.track.kind === MEDIA_TYPES.video);
        const shareConsumer = consumersArray.find(
            (consumer) => consumer.appData.type === MEDIA_TYPES.consumerScreenShare
        );

        const videoTrack = shareConsumer ? shareConsumer?.track : videoConsumer?.track;
        const audioTrack = audioConsumer ? audioConsumer.track : null;

        setIsVideoPaused(!videoConsumer);
        setIsAudioPaused(audioConsumer?.paused); // TODO: remove question mark and need investigation

        setVideoTrack(videoTrack);
        setAudioTrack(audioTrack);

        enableTracks(videoTrack, audioTrack);
    }, [consumers, enableTracks, storeConsumers]);

    useEffect(() => {
        const videoRef = videoEl.current;

        getTracks();
        return () => {
            if (videoRef) {
                videoRef.oncanplay = null;
                videoRef.onplay = null;
                videoRef.onpause = null;
            }
        };
    }, [getTracks]);

    useEffect(() => {
        getTracks();
    }, [consumers, getTracks]);

    useEffect(() => {
        if (audioTrack) {
            const stream = new MediaStream();

            stream.addTrack(audioTrack);

            const speechEvents = hark(stream, {});

            speechEvents.on('speaking', () => {
                if (activeStreamsCount > 1) setIsActiveSpeaker(true);
            });

            speechEvents.on('stopped_speaking', () => setIsActiveSpeaker(false));
        }
    }, [activeStreamsCount, audioTrack]);

    useEffect(() => {
        if (!pausedConsumers) {
            return;
        }
        const consumersArray = consumers.map((consumerId) => storeConsumers[consumerId]);
        const audioConsumer = consumersArray.find((consumer) => consumer.appData.type === MEDIA_TYPES.audio);

        if (audioConsumer) {
            setIsAudioPaused(!!pausedConsumers[audioConsumer.id]);
        }
    }, [consumers, pausedConsumers, storeConsumers]);

    const sessionMember = {
        first: displayName.split(' ')[0],
        last: displayName.split(' ').pop(),
        avatarUrl
    };

    const activeScreenSharedId = Object.values(storeConsumers).find(
        (consumer) => consumer?.appData.type === MEDIA_TYPES.consumerScreenShare
    )?.id;
    const isActiveScreenShareSession = activeScreenSharedId && consumers.includes(activeScreenSharedId);

    const MicIndicator = ({className}) => {
        const IconName = isAudioPaused ? MicOffIcon : MicIcon;
        return <IconName className={className} />;
    };
    const VideoIndicator = ({className}) => {
        const IconName = consumers.length < 2 ? VideocamOffIcon : VideocamIcon;
        return <IconName className={className} />;
    };

    return (
        <div
            className={classNames(classes.videoWrapper, {
                [classes.videoWrapperSize]: !isParticipantScreenShareEnabled && !isActiveScreenShareSession,
                [classes.videoWrapperHidden]: isParticipantScreenShareEnabled && !isActiveScreenShareSession
            })}
        >
            {isActiveSpeaker && <div className={classes.isActiveSpeaker} />}
            <video
                className={classNames(classes.video, {
                    [classes.videoPaused]: isVideoPaused,
                    [classes.videoHidden]: isParticipantScreenShareEnabled && !isActiveScreenShareSession
                })}
                ref={videoEl}
                autoPlay
                playsInline
                muted
                controls={false}
            />
            {isVideoPaused && <VideoAvatar text={'name'} member={sessionMember} />}
            <audio ref={audioEl} className={classes.audioStream} autoPlay playsInline muted={false} controls={false} />
            <div className={classes.userInfo}>
                <MicIndicator className="primary-fill-svg" />
                <VideoIndicator className="primary-fill-svg" />
                <p className={classes.userName}>{displayName}</p>
            </div>
        </div>
    );
}

Session.propTypes = {
    session: PropTypes.object.isRequired,
    activeStreamsCount: PropTypes.number.isRequired,
    isParticipantScreenShareEnabled: PropTypes.bool
};

Session.defaultProps = {
    session: {}
};
