import {MEDIA_TYPES} from 'constants/organizer/sessions';

export const replaceStreamTracks = async ({stream, peer}) => {
    if (!peer) return;

    const videoTrack = stream.getVideoTracks()[0];
    const audioTrack = stream.getAudioTracks()[0];

    const videoSender = peer.getSenders().find((sender) => sender.track && sender.track.kind === MEDIA_TYPES.video);
    const audioSender = peer.getSenders().find((sender) => sender.track && sender.track.kind === MEDIA_TYPES.audio);

    await videoSender?.replaceTrack(videoTrack);
    await audioSender?.replaceTrack(audioTrack);
};

export const stopLocalStream = (localStream) => {
    if (localStream) {
        localStream.getTracks().forEach((track) => track.stop());
    }
};
