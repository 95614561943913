import React from 'react';
import cn from 'classnames';

import './IconContainer.scss';

const IconContainer = ({children, size = 'medium'}) => (
    <span className={cn('IconContainer', `IconContainer_size-${size}`)}>{children}</span>
);

export default IconContainer;
