import PlatformLanguage from './PlatformLanguage';
import {connect} from 'react-redux';
import * as actions from '../../../../../store/actions';
import {getEventInfo, getEventLanguages, getLanguages} from '../../../../../store/selectors';

export default connect(
    (state) => ({
        eventInfo: getEventInfo(state),
        languages: getLanguages(state),
        eventLanguages: getEventLanguages(state),
    }),
    {
        onSetEventLanguage: (languageCode) => actions.setEventLanguage(languageCode),
        onSetPlatformLanguage: (languageCode) => actions.setPlatformLanguage(languageCode),
    }
)(PlatformLanguage);
