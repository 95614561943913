import {AttachFile as AttachFileIcon} from '@material-ui/icons';
import {handleNewOnDemandVideo} from 'Api/socketApi';
import {wallTabsList} from 'constants/auditorium';
import {SESSION_TYPES} from 'constants/data/session-type';
import {streamingEngineTypes} from 'constants/data/streaming-engine';
import {LiveWallIcon, MovieIcon, PollIcon, SubtitlesIcon, TranslateIcon} from 'Images/pages/platform-homepage/wall';
import PropTypes from 'prop-types';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {isSafari} from 'react-device-detect';
import {usePlatformTranslation} from 'services/hooks';
import AuditoriumMobileTabs from '../AuditoriumMobileTabs';
import AuditoriumWall from '../AuditoriumWall';
import useTimeslotPollsSockets from '../../../../../services/hooks/useTimeslotPollsSockets';

const AuditoriumWallNavigation = ({
    // props
    currentTimeSlot,
    currentTab,
    auditorium,
    isInterpretationEnabled,
    onChangeTab,
    isOnDemandShowing,
    isArchive,
    // redux-state
    timeslotPolls,
    timslotLoading,
    isLargeScreen,
    onDemandVideos,
    // actions
    setActiveWall,
    addOnDemandVideo,
    getTimeslotPolls,
}) => {
    const translation = usePlatformTranslation();
    useTimeslotPollsSockets();

    const [isWallClosed, setWallClosed] = useState(false);
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);

    const handleCloseWall = (bool) => {
        bool !== isWallClosed && setWallClosed(bool);
    };

    const handleOpenWall = () => {
        setActiveWall({wallId: currentTimeSlot.videoWall});
        onChangeTab(wallTabsList.WALL);
    };

    const hidePollsTab = useMemo(() => {
        if (Array.isArray(timeslotPolls) && timeslotPolls.length) return !timeslotPolls.some((i) => !i.hidden);
        return true;
    }, [timeslotPolls]);

    const hideTransition =
        (currentTimeSlot?.session?.type === SESSION_TYPES.recorded && isSafari) ||
        currentTimeSlot?.streamingEngine === streamingEngineTypes[0].value;
    // Safari doesn't support interpretation on loaded videos

    const menuButtons = [
        {
            value: wallTabsList.VIDEO,
            name: translation?.auditorium.stageTab,
            action: () => {
                onChangeTab(wallTabsList.VIDEO);
                handleCloseWall(true);
            },
            icon: null,
            hide: isLargeScreen,
            index: 0,
        },
        {
            value: wallTabsList.WALL,
            name: translation?.auditorium.videoWallButton,
            action: () => {
                handleOpenWall();
                handleCloseWall(false);
            },
            icon: <LiveWallIcon />,
            hide: false,
            index: 1,
        },
        {
            value: wallTabsList.ON_DEMAND,
            name: translation?.sideMenu?.onDemand,
            action: () => {
                onChangeTab(wallTabsList.ON_DEMAND);
                handleCloseWall(false);
            },
            icon: <MovieIcon />,
            hide: !onDemandVideos.length,
            index: 2,
        },
        {
            value: wallTabsList.INTERPRETER,
            name: translation?.sideMenu?.interpretation,
            action: () => {
                onChangeTab(wallTabsList.INTERPRETER);
                handleCloseWall(false);
            },
            icon: <TranslateIcon />,
            hide: !currentTimeSlot?.interpreters?.length || !isLargeScreen || isArchive,
            index: 3,
        },
        {
            value: wallTabsList.TRANSLATIONS,
            name: translation?.sideMenu?.subtitles,
            action: () => {
                onChangeTab(wallTabsList.TRANSLATIONS);
                handleCloseWall(false);
            },
            icon: <SubtitlesIcon />,
            hide: !(isInterpretationEnabled && !hideTransition) || !isLargeScreen,
            index: 4,
        },
        {
            value: wallTabsList.POLLS,
            name: translation?.sideMenu?.polls,
            action: () => {
                onChangeTab(wallTabsList.POLLS);
                handleCloseWall(false);
            },
            icon: <PollIcon />,
            hide: hidePollsTab,
            index: 5,
        },
        {
            value: wallTabsList.RESOURCES,
            name: translation?.sideMenu?.resources,
            action: () => {
                onChangeTab(wallTabsList.RESOURCES);
                handleCloseWall(false);
            },
            icon: <AttachFileIcon />,
            hide: !(!!currentTimeSlot?.documents?.length || !!currentTimeSlot?.links?.length) || !isLargeScreen,
            index: 6,
        },
    ];

    useEffect(() => {
        const currentButton = menuButtons.find((btn) => btn.value === currentTab);
        const index = currentButton.index || 1;

        setActiveButtonIndex(index);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentTab]);

    useEffect(() => {
        handleNewOnDemandVideo((err, data) => {
            addOnDemandVideo(data);
        });
    }, [addOnDemandVideo]);

    useEffect(() => {
        if (!isLargeScreen) {
            onChangeTab(wallTabsList.VIDEO);
        } else {
            !currentTab && onChangeTab(wallTabsList.WALL);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onGetTimeslotPolls = useCallback(() => {
        if (!timslotLoading && currentTimeSlot._id) {
            getTimeslotPolls({auditoriumId: auditorium._id, timeslotId: currentTimeSlot?._id, toDeserialize: false});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getTimeslotPolls, auditorium, currentTimeSlot]);

    useEffect(() => {
        onGetTimeslotPolls();
    }, [onGetTimeslotPolls]);

    const hideWall = !isLargeScreen && currentTab === wallTabsList.VIDEO;
    const desktopReversedButtons = [...menuButtons].reverse();

    return (
        <>
            <AuditoriumMobileTabs currentTab={currentTab} onChangeTab={onChangeTab} menuButtons={menuButtons} />
            {!hideWall && (
                <AuditoriumWall
                    isOnDemandShowing={isOnDemandShowing}
                    activeButtonIndex={activeButtonIndex}
                    menuButtons={desktopReversedButtons}
                    isWallClosed={isWallClosed}
                    handleOpenWall={handleOpenWall}
                    handleCloseWall={handleCloseWall}
                    hidePollsTab={hidePollsTab}
                    currentTimeSlot={currentTimeSlot}
                    currentTab={currentTab}
                    auditorium={auditorium}
                    onChangeTab={onChangeTab}
                />
            )}
        </>
    );
};

AuditoriumWallNavigation.propTypes = {
    // props
    currentTimeSlot: PropTypes.object,
    currentTab: PropTypes.string,
    auditorium: PropTypes.object,
    isInterpretationEnabled: PropTypes.bool,
    onChangeTab: PropTypes.func,
    isOnDemandShowing: PropTypes.bool,
    isArchive: PropTypes.bool,
    // redux-state
    timeslotPolls: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    timslotLoading: PropTypes.bool,
    isLargeScreen: PropTypes.bool,
    // actions
    setActiveWall: PropTypes.func,
    addOnDemandVideo: PropTypes.func,
    getTimeslotPolls: PropTypes.func,
};

AuditoriumWallNavigation.defaultProps = {
    currentTimeSlot: {},
};

export default AuditoriumWallNavigation;
