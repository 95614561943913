import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {useStyles} from './SocialMediaTab.style';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {useSelector} from 'react-redux';
import {getIsRtlLanguage, getIsMobile} from 'store/selectors';
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import LinkedInBadge from 'Routes/components/LinkedInBadge';
import {getTwitterScreenName} from 'services/utils';
import {FacebookProvider, Page} from 'react-facebook';
import BackButton from 'Routes/components/BackButton/BackButton';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function SocialMediaTab({socials, closeSocial, title}) {
    const style = useStyles();
    const isRtl = useSelector(getIsRtlLanguage);
    const isMobile = useSelector(getIsMobile);

    const [value, setValue] = useState(0);
    const [socialsLink, setSocialsLink] = useState(null);

    useEffect(() => {
        if (socials) {
            const links = {};
            socials.map(({platform, link}) => {
                links[platform] = link;
                return link;
            });
            setSocialsLink(links);
            let index = 0;
            if (!links?.linkedin && !links?.facebook && links?.twitter) {
                index = 2;
            } else if (!links?.facebook && links?.linkedin) {
                index = 1;
            }
            setValue(index);
        }
    }, [socials]);

    function tabProps(index) {
        return {
            id: `wrapped-tab-${index}`,
            'aria-controls': `wrapped-tabpanel-${index}`,
        };
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const badgeSize = {
        width: isMobile ? 310 : 400,
        height: window.innerHeight - 210,
    };
    return (
        <div className={classNames(style.container)}>
            <div className={classNames(style.backBtn)}>
                <BackButton onClickBack={() => closeSocial()} />
                <p className={style.title}>{title}</p>
            </div>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab
                        className={classNames({[`${style.hide}`]: !socialsLink?.facebook})}
                        classes={{root: style.tab}}
                        label="Facebook"
                        {...tabProps(0)}
                    />
                    <Tab
                        className={classNames({[`${style.hide}`]: !socialsLink?.linkedin})}
                        classes={{root: style.tab}}
                        label="Linkedin"
                        {...tabProps(1)}
                    />
                    <Tab
                        className={classNames({[`${style.hide}`]: !socialsLink?.twitter})}
                        classes={{root: style.tab}}
                        label="Twitter"
                        {...tabProps(2)}
                    />
                </Tabs>
            </AppBar>

            <TabPanel value={value} index={0} dir={isRtl ? 'rtl' : 'ltr'}>
                {socialsLink?.facebook && (
                    <FacebookProvider appId="app-container">
                        <Page href={socialsLink?.facebook} tabs="timeline" height={badgeSize.height} />
                    </FacebookProvider>
                )}
            </TabPanel>
            <TabPanel value={value} index={1} dir={isRtl ? 'rtl' : 'ltr'}>
                <LinkedInBadge url={socialsLink?.linkedin} />
            </TabPanel>
            <TabPanel value={value} index={2} dir={isRtl ? 'rtl' : 'ltr'}>
                {socialsLink?.twitter && (
                    <TwitterTimelineEmbed
                        sourceType="profile"
                        screenName={getTwitterScreenName(socialsLink?.twitter)}
                        options={badgeSize}
                    />
                )}
            </TabPanel>
        </div>
    );
}

SocialMediaTab.propTypes = {
    socials: PropTypes.oneOfType([PropTypes.array, PropTypes.oneOf([null])]),
    closeSocial: PropTypes.func,
    title: PropTypes.string,
};

export default SocialMediaTab;
