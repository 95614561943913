import React from 'react';
import ReactPlayer from 'react-player';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

import AuditoriumOverlayPlayer from '../AuditoriumOverlayPlayer';

import FullscreenIcon from 'Images/f-screen.svg';
import MinimizeScreenIcon from 'Images/ef-screen.svg';
import MuteIcon from 'Images/mute.svg';
import UnmuteIcon from 'Images/unmute.svg';

import './BoothVideoDialog.scss';

export class BoothVideoDialog extends React.Component {
    constructor(props) {
        super(props);
        this.youtubePlayer = React.createRef();
    }

    componentDidMount() {
        document.addEventListener('keydown', this.escFunction, false);
        this.setState({loaded: true});
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    componentWillUnmount() {
        document.removeEventListener('keydown', this.escFunction, false);
    }

    state = {
        fullscreen: false,
        muteVideo: false,
        videoEnded: false,
        loaded: false,
        translation: null,
    };

    escFunction = (e) => {
        if (e.key === 'Escape') {
            this.setState({fullscreen: false});
        }
    };

    handleVideoReady = () => {
        this.youtubePlayer.current.getInternalPlayer().setLoop(true);
    };

    render() {
        const {translation} = this.state;
        return (
            <Dialog
                open={this.props.opened}
                onClose={this.props.closeDialog}
                fullScreen={this.state.fullscreen}
                aria-labelledby="form-dialog-title"
                disableEscapeKeyDown={true}
                className={
                    'booth-video-dialog-container booth-dialog ' +
                    (this.state.fullscreen ? 'fullscreen ' : '') +
                    this.props.classes
                }
            >
                {this.state.loaded ? (
                    <DialogContent className="booth-dialog-content booth-video-dialog-container">
                        <DialogTitle id="form-dialog-title">{translation?.showfloor.boothVideoDialogTitle}</DialogTitle>
                        <div className="booth-video-container">
                            <ReactPlayer
                                ref={this.youtubePlayer}
                                url={this.props.videoUrl}
                                onReady={this.handleVideoReady}
                                playing
                                className="react-player"
                                controls={false}
                                width="100%"
                                height="100%"
                                volume={this.state.muteVideo ? 0 : 1}
                                loop={true}
                            />
                            <AuditoriumOverlayPlayer />
                        </div>
                        <div className="booth-video-actions-container">
                            <button
                                onClick={() => this.setState({fullscreen: !this.state.fullscreen})}
                                className="fullscreen-button"
                            >
                                {this.state.fullscreen ? (
                                    <img src={MinimizeScreenIcon} width="" height="" alt="Minimize" />
                                ) : (
                                    <img src={FullscreenIcon} width="" height="" alt="Fullscreen" />
                                )}
                            </button>
                            <button
                                onClick={() => this.setState({muteVideo: !this.state.muteVideo})}
                                className="fullscreen-button mute-button"
                            >
                                {this.state.muteVideo ? (
                                    <img src={MuteIcon} width="" height="" alt="Unmute" />
                                ) : (
                                    <img src={UnmuteIcon} width="" height="" alt="Mute" />
                                )}
                            </button>
                        </div>
                    </DialogContent>
                ) : null}

                <DialogActions>
                    <Button onClick={this.props.closeDialog} color="secondary" className="booth-cancel-button">
                        {translation?.generalText.close}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
