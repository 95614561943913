import _ from 'lodash';
import {nanoid} from 'nanoid';
import {transformRegFieldToCustomField} from 'services/helpers/customFormHelpers';

import {
    customFormActionTypes,
    customFormFieldTypes,
    initialField,
    initialFieldOption,
    textValidationActionTypes,
} from '../../../../constants/customForm';
import * as actionTypes from '../../../actionTypes/organizerActionTypes/eventBranding';
import {updateObject, sliceHelper} from '../../../utilityStateFunction';
import {initialState} from '../initialState';

const customForm = (state, {type, payload}) => {
    if (state === undefined) {
        return initialState.customForm;
    }
    switch (type) {
        case actionTypes.UPDATE_CUSTOM_FORM_FIELD_STATUS_REQUEST:
        case actionTypes.POST_THANK_YOU_MESSAGE_REQUEST: {
            return updateObject(state.customForm, {
                loading: true,
            });
        }
        case actionTypes.SET_CUSTOM_FORM_FIELDS: {
            const {fields, type} = payload;

            return updateObject(state.customForm, {
                fields,
                type,
                loading: false,
            });
        }
        case actionTypes.CREATE_CUSTOM_FORM: {
            const newField = {...initialField, _id: nanoid()};

            return updateObject(state.customForm, {
                fields: [...state.customForm.fields, newField],
            });
        }
        case actionTypes.DELETE_CUSTOM_FORM: {
            const fields = state.customForm.fields.filter(({_id}) => _id !== payload);

            return updateObject(state.customForm, {
                fields,
            });
        }
        case actionTypes.UPDATE_CUSTOM_FORM_FIELD_STATUS_FAIL:
        case actionTypes.GET_SURVEY_QUESTIONS_FAIL:
        case actionTypes.POST_THANK_YOU_MESSAGE_SUCCESS:
        case actionTypes.POST_THANK_YOU_MESSAGE_FAIL: {
            return updateObject(state.customForm, {
                loading: false,
            });
        }
        case actionTypes.CHANGE_CUSTOM_FORM_FIELD: {
            const {fieldId, fieldType, fieldValue, fileValidation, textValidation} = payload;
            const fields = state.customForm.fields.map((field) => {
                if (field._id !== fieldId) {
                    return field;
                }

                if (fieldType === customFormActionTypes.fieldLabelType) {
                    const newOption = {...initialFieldOption, _id: nanoid()};

                    const createOptionValidation = [
                        fieldType === customFormActionTypes.fieldLabelType,
                        fieldValue === customFormFieldTypes.radio || fieldValue === customFormFieldTypes.select,
                        field.isFormCreation,
                    ];
                    const isOptionCreated = createOptionValidation.every((rule) => !!rule);
                    const initQuestionOptionValidation = isOptionCreated ? {[newOption._id]: false} : {};

                    return updateObject(field, {
                        [fieldType]: fieldValue,
                        options: isOptionCreated ? [...field.options, newOption] : field.options,
                        validation: updateObject(field.validation, {
                            ...initQuestionOptionValidation,
                        }),
                    });
                }

                if (fieldType === customFormActionTypes.fileValidation) {
                    if (!fieldValue)
                        return updateObject(field, {
                            fileValidations: field.fileValidations.filter((val) => val !== fileValidation),
                        });

                    return updateObject(field, {
                        fileValidations: [...field.fileValidations, fileValidation],
                    });
                }

                if (textValidationActionTypes.includes(fieldType)) {
                    const value = _.isBoolean(fieldValue) || fieldValue.length > 0 ? fieldValue : null;
                    return updateObject(field, {
                        textValidation: updateObject(field.textValidation, {
                            ...{[textValidation]: value},
                        }),
                    });
                }

                return updateObject(field, {
                    [fieldType]: fieldValue,
                });
            });

            return updateObject(state.customForm, {
                fields,
            });
        }
        case actionTypes.REMOVE_CUSTOM_FORM_FIELD_OPTION: {
            const {fieldId, optionId} = payload;
            const removeProperty = (propKey, {[propKey]: propValue, ...rest}) => rest;

            const fields = state.customForm.fields.map((field) => {
                if (field._id !== fieldId) {
                    return field;
                }

                const validation = removeProperty(optionId, field.validation);
                return {
                    ...field,
                    options: field.options.filter((option) => option._id !== optionId),
                    validation,
                };
            });

            return updateObject(state.customForm, {
                fields,
            });
        }
        case actionTypes.ADD_CUSTOM_FORM_FIELD_OPTION: {
            const newOption = {...initialFieldOption, _id: nanoid()};

            const fields = state.customForm.fields.map((field) => {
                if (field._id !== payload) {
                    return field;
                }

                return {
                    ...field,
                    options: [...field.options, newOption],
                    validation: updateObject(field.validation, {
                        [newOption._id]: false,
                    }),
                };
            });

            return updateObject(state.customForm, {
                fields,
            });
        }
        case actionTypes.CHANGE_CUSTOM_FORM_FIELD_OPTION: {
            const {fieldId, optionId, optionValue} = payload;

            const updateOption = (arr, optionId, newValue) =>
                arr.map((el) => {
                    if (el._id !== optionId) {
                        return el;
                    }

                    return {
                        ...el,
                        value: newValue,
                    };
                });

            const fields = state.customForm.fields.map((field) => {
                if (field._id !== fieldId) {
                    return field;
                }

                return {
                    ...field,
                    options: updateOption(field.options, optionId, optionValue),
                };
            });

            return updateObject(state.customForm, {
                fields,
            });
        }
        case actionTypes.UPDATE_CUSTOM_FORM_FIELD_STATUS_SUCCESS: {
            const {fields} = state.customForm;
            const {customField, fieldId, isDelete, language} = payload;
            const fieldIndex = fields.findIndex((field) => field._id === fieldId);
            const updateFields = isDelete
                ? fields.filter((field) => field._id !== fieldId)
                : sliceHelper(fields, fieldIndex, transformRegFieldToCustomField(customField, language));
            return updateObject(state.customForm, {
                loading: false,
                fields: updateFields,
            });
        }

        default:
            return state.customForm;
    }
};

export default customForm;
