import React from 'react';
import cn from 'classnames';

import {ConfirmedActionGroup, PendingActionGroup} from './components/ActionGroups/ActionGroups';

import './MobileAgendaMeetingItem.scss';

const MobileAgendaMeetingItem = ({
    title,
    description,
    statusString,
    status,
    date,
    timeZone,
    startTime,
    endTime,
    isConfirmed,
    isRequested,
    onCancel,
    onMeetingStart,
    onConfirm,
}) => (
    <div className={cn('MobileAgendaMeetingItem', `MobileAgendaMeetingItem_status-${status}`)}>
        <div className="MobileAgendaMeetingItem__mainRow">
            <div className="MobileAgendaMeetingItem__titleGroup">
                <div className="MobileAgendaMeetingItem__title">{title}</div>

                {description && <div className="MobileAgendaMeetingItem__description">{description}</div>}
            </div>

            <div className="MobileAgendaMeetingItem__timeGroup">
                <div className="MobileAgendaMeetingItem__timeItem">{startTime}-</div>
                <div className="MobileAgendaMeetingItem__timeItem">{endTime}</div>
            </div>

            <div className="MobileAgendaMeetingItem__actionGroup">
                {isConfirmed ? (
                    <ConfirmedActionGroup onCancel={onCancel} onMeetingStart={onMeetingStart} />
                ) : (
                    <PendingActionGroup isRequested={isRequested} onCancel={onCancel} onConfirm={onConfirm} />
                )}
            </div>
        </div>

        <div className="MobileAgendaMeetingItem__metaRow">
            <div className="MobileAgendaMeetingItem__metaItem">{statusString}</div>
            <div className="MobileAgendaMeetingItem__metaItem">{date}</div>
            <div className="MobileAgendaMeetingItem__metaItem">{timeZone}</div>
        </div>
    </div>
);

export default MobileAgendaMeetingItem;
