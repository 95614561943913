import React from 'react';

const EgyptEnglishContent = () => {
    return (
        <div className="header-spacing-container">
            <div className="gdpr-container">
                <h1>Privacy notice/policy</h1>
                <h4>Effective date: 01 Dec 2021</h4>
                <p>
                    This privacy policy of Events10x Ltd regulates privacy matters of the Event10X (Platform). By using
                    our Platform, you agree to the terms of processing of your personal data below.
                </p>
                <h2>Definitions</h2>
                <p>
                    The terms listed below have the meanings assigned to them in the Regulation (EU) 2016/679 (General
                    Data Protection Regulation):
                </p>
                <ul>
                    <li>
                        <p>
                            <b>Personal data</b> means an identifiable natural person is one who can be identified,
                            directly or indirectly, in particular by reference to an identifier such as a name, an
                            identification number, location data, etc.;
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Processing</b> means any operation or set of operations which is performed on personal
                            data, such as collection, recording, organisation, structuring, storage, adaptation or
                            destruction;
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Data subject (or you)</b> is an identified or identifiable natural person who can be
                            identified, directly or indirectly, based on particular Personal Data.
                        </p>
                    </li>
                </ul>
                <h2>Data Collected</h2>
                <p>
                    You agree that Events10x Ltd, a United Arab Emirates Corporation with offices at The Hive, Dubai
                    International Financial Center, Dubai, United Arab Emirates (subsequently referred to as “we,”
                    “our,” or “us”) will be data controller of your personal data and may collect, process, use, and
                    save your personal data. Personal data includes the following:
                </p>
                <ol>
                    <li>
                        <p>
                            registration data: your name, login name, email address, and password, third party account
                            identifier (when used for registration), image;
                        </p>
                    </li>
                    <li>
                        <p>profile data: company name, address, your address, job title and telephone number,</p>
                    </li>
                    <li>
                        <p>billing data: name, company name, address, telephone number and ordered items,</p>
                    </li>
                    <li>
                        <p>
                            technical data: log files, to include your computer’s IP address, browser version, pages you
                            visited on our website,
                        </p>
                    </li>
                    <li>
                        <p>
                            communication data: messages (including which you submit via the contact form), wall posts,
                            chat comments.
                        </p>
                    </li>
                </ol>
                <h2>Use of the Data</h2>
                <p>We use your data for the following purposes:</p>
                <ol>
                    <li>
                        <p>
                            To provide our services. Our Platform is designed as an environment where business
                            professionals interact with each other during specific events. For this purpose we process
                            registration, profile and billing data. For example, any profile data you provide to us may
                            be shared with other users, and that your profile data will be made searchable by other
                            users.
                        </p>
                    </li>
                    <li>
                        <p>
                            Marketing. We use registration and profile data for promoting our service and network growth
                            solely on our Platform. For example, we may send you e-mails informing you about future
                            events held on our platform.
                        </p>
                    </li>
                    <li>
                        <p>
                            Platform functioning and developing. We use data, including public feedback, to conduct
                            research and development for the further development of our Platform in order to provide you
                            and others with a better, more intuitive and personalized experience. For this purpose we
                            use your technical and communication data.
                        </p>
                    </li>
                    <li>
                        <p>
                            Security. We may use your registration data, profile data, communication data and technical
                            data if we think it’s necessary for Platform safety and security purposes or to investigate
                            possible fraud or other violations of our Terms and this Privacy Policy. For example we may
                            use your log files for technical analysis as well as for statistical evaluation.
                        </p>
                    </li>
                    <li>
                        <p>
                            For legal purpose. We may use any type of your personal data when processing is required by
                            the applicable law.
                        </p>
                    </li>
                </ol>
                <h2>Sharing of Data</h2>
                <p>
                    Service providers. Our service providers acting as processor or controller based in the EEA but also
                    around the world who provide - services and IT and system administration services.
                </p>
                <ol>
                    <li>
                        <p>
                            We use Microsoft Azure for our Platform hosting. Amazon AWS privacy policy is available
                            under this <a href="https://www.microsoft.com/en-us/trust-center/privacy">URL</a>
                        </p>
                    </li>
                    <li>
                        <p>
                            We outsource payment processing and all transaction handling to PayPal Services, please
                            therefore refer to PayPal’s own{' '}
                            <a href="https://www.paypal.com/us/webapps/mpp/ua/legalhub-full">User Agreement</a> and{' '}
                            <a href="https://www.paypal.com/us/webapps/mpp/ua/privacy-full">Privacy Policy</a>. We do
                            not store any bank card details
                        </p>
                    </li>
                    <li>
                        <p>Our websites use</p>
                    </li>
                    <ol>
                        <li>
                            <p>
                                <b>Google Analytics</b>, a web analysis service provided by Google Inc. (“Google”).
                                Google Analytics uses “cookies,” which are text files placed on your computer, to help
                                the website analyze how users use the site. The information generated by the cookie
                                about your use of the website will be transmitted to and stored by Google on servers in
                                the United States. Our websites have IP anonymization activated, so that your IP address
                                will be shortened beforehand by Google within member states of the European Union or in
                                other signatory states of the Treaty on the European Economic Area. The full IP address
                                will be transmitted to a Google server in the USA and shortened there only on an
                                exceptional basis. Google will use this information for the purpose of evaluating your
                                use of the website, compiling reports on website activity for website operators, and
                                providing other services to website operators relating to website activity and Internet
                                usage. Google will not associate the IP address transmitted under Google Analytics with
                                other data held by Google. You may prevent the storage of cookies by selecting the
                                appropriate settings on your browser software, but if you do so, not all functions of
                                our website will be usable to the full extent. You may prevent Google from recording
                                data by downloading and installing a browser plug-in available through the following
                                link:{' '}
                                <a href="https://tools.google.com/dlpage/gaoptout?hl=en">
                                    https://tools.google.com/dlpage/gaoptout?hl=en.
                                </a>
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Google Tag Manager</b>, a tag management system that includes the same features as
                                global site tags, and lets you configure and instantly deploy tags on the website or
                                mobile app from an easy to use web-based user interface. Find out more about their
                                Privacy Policy from here:{' '}
                                <a href="https://developers.google.com/terms">https://developers.google.com/terms</a>
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Facebook Pixel</b>, A tool that collects data that helps you track conversions from
                                Facebook ads, optimize ads, build targeted audiences for future ads and remarket to
                                people who have already taken some kind of action on the website. Learn more from their
                                Terms of Services and Privacy Policy. You can find it out here:{' '}
                                <a href="https://www.facebook.com/business/learn/facebook-ads-pixel">
                                    https://www.facebook.com/business/learn/facebook-ads-pixel
                                </a>
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Twitter Universal Tag</b>, Atool that allows you to track multiple site actions or
                                conversions. Find out more about it by clicking here:{' '}
                                <a href="https://business.twitter.com/en/help/campaign-measurement-and-analytics/conversion-tracking-for-websites.html">
                                    https://business.twitter.com/en/help/campaign-measurement-and-analytics/conversion-tracking-for-websites.html
                                </a>
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Yandex</b>, a web tool that allows you to Manage counters, their settings, and access
                                rights, without using the web interface, Get information about site traffic and other
                                data &amp; Generate reports, including segmented and parameterized reports. Find out
                                more from the following link:{' '}
                                <a href="https://yandex.com/dev/metrika/doc/api2/concept/about.html">
                                    https://yandex.com/dev/metrika/doc/api2/concept/about.html
                                </a>
                            </p>
                        </li>
                        <li>
                            <p>
                                <b>Active campaign</b>, CRM cloud based platform that combines the email marketing,
                                marketing automation, sales automation, and CRM categories. Find out more from here:{' '}
                                <a href="https://www.activecampaign.com">https://www.activecampaign.com</a>
                            </p>
                        </li>
                    </ol>
                    <li>
                        <p>
                            We use Mailchimp provided by Rocket Science Group LLC for our marketing e-mails sending.
                            Mailchimp Privacy Policy is available{' '}
                            <a href="https://mailchimp.com/legal/privacy/">here</a>. Mailchimp participates in and has
                            certified its compliance with the EU-U.S. Privacy Shield Framework and the Swiss-U.S.
                            Privacy Shield Framework. Mailchimp is using web beacons - a technology that allows
                            identifying whether the user has opened the e-mail. For this purpose may collect some
                            personal data like IP address, software, and hardware data. E-mail web beacons cannot be
                            turned off.
                        </p>
                    </li>
                    <li>
                        <p>
                            We may share data with our contractors and service providers, who acting either as: (1) as
                            processor or controller based in the EEA but also around the world who provide - services
                            and IT and system administration services or (2) acting as a processor or joint controllers
                            including lawyers, bankers, auditors and insurers.
                        </p>
                    </li>
                    <li>
                        <p>
                            We share data with event organizers, who may act as processors or joint controllers, based
                            in the EEA.
                        </p>
                    </li>
                </ol>
                <p>
                    We may share personal data with third parties to whom we may choose to sell, transfer, or merge
                    parts of business or assets.
                </p>
                <p>
                    We will never disclose or sell your personal data to third parties to enable them to provide you
                    with information regarding unrelated goods or services.
                </p>
                <p>
                    The information we provide to you may be transferred to and processed on our servers, or servers of
                    third-party providers, which may be located outside EEA.
                </p>
                <p>
                    As far as the transmitting of personal data to a third party is not explicitly permitted by law, you
                    agree that we may, if necessary, transmit personal data to law enforcement offices when relevant to
                    defending against a governmental and public safety threat or the prosecution of a criminal act.
                    Furthermore, you agree that we may, if necessary and related to legitimate law enforcement or
                    criminal prosecution interests, transmit personal data to a third party. A transmission of data is
                    not necessary and will not occur if a preliminary legal or law enforcement proceeding can be
                    initialized or has already been initialized.
                </p>
                <h2>Your Privacy Options</h2>
                <p>According to GDPR you have the following rights:</p>
                <ol>
                    <li>
                        <p>
                            <b>Right to rectification.</b> You have the right to request to rectify, without undue
                            delay, any incorrect data pertaining to you.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Right to limitation of processing</b> You can limit the use of personal data processed.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Right of access</b> You may request a copy of personal data collected during use of
                            Platform.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Objecting to or restricting the use of Personal Data.</b> You can ask to stop using all
                            or some portion of Personal Data or limit use thereof by requesting its erasure as described
                            above or sending us a request
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>The right to lodge a complaint with supervisory authority.</b> You have the right to
                            lodge a complaint with a competent data protection supervisory authority.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>The right to data portability.</b> You can receive personal data in a machine-readable
                            format by sending the respective request at{' '}
                            <a href="mailto:info@events10x.com">info@events10x.com.</a>
                        </p>
                    </li>
                </ol>
                <h2>Exercise of rights</h2>
                <p>
                    To exercise your right to access you have a right to send as a request. Upon request we provide
                    information free of charge. However, we may charge a reasonable fee if request is clearly unfounded,
                    repetitive or excessive. Alternatively, we may refuse to comply with your request in these
                    circumstances.
                </p>
                <p>
                    We shall review and pronounce on the request within 1 month as of its filing. This period may be
                    extended by further two months, if necessary, for example, if your request is particularly complex
                    or you have made a number of requests . We shall inform you as to any such extension within 1 month
                    as of receipt of the request, stating the reasons for the delay. When you file a request by
                    electronic means, the information is provided electronically, if possible, unless the you have
                    requested otherwise.
                </p>
                <p>
                    Upon filing of a request by an authorized person, the notarized power of attorney must be attached
                    to the request. In case of death of the natural person, his / her rights are exercised by his / her
                    heirs and the certificate of heirs shall be attached to the request. The heritage should be
                    confirmed by respective certificate, issued in the dead person’s jurisdiction.
                </p>
                <p>
                    Where data do not exist or their provision is forbidden by law, access of the requesting party to
                    such data is refused.
                </p>
                <h2>Retention</h2>
                <p>
                    We will store personal data for as long as it is reasonably necessary for achieving the purposes set
                    forth in this Privacy Policy and Terms and Conditions, which includes (but is not limited to) the
                    period during which you have an account with our Platform. We will also retain and use personal data
                    as necessary to comply with legal obligations, resolve disputes, and enforce agreements.
                </p>
                <h2>Age Limitation</h2>
                <p>
                    Our Platform is not for users who are under 18 years old. We do not knowingly process any personal
                    data from persons under 18 years of age, and any such data shall be immediately deleted upon
                    detection. If you learn that anyone younger than 18 has provided us with personal data, please
                    contact us at <a href="mailto:info@events10x.com">info@events10x.com</a>.
                </p>
                <h2>Privacy Policy Changes</h2>
                <p>
                    We may amend this Privacy Policy in the future, and we shall inform you about any such amendments in
                    the future. The use of data we collect, process, and save now is subject to the version of this
                    Privacy Policy that is in effect at the time users provide such data.
                </p>
                <h2>How To Contact Us</h2>
                <p>
                    If you have any questions or request regarding your policy, please send your request to{' '}
                    <a href="mailto:info@events10x.com">info@events10x.com</a>.
                </p>
            </div>
        </div>
    );
};

export default EgyptEnglishContent;
