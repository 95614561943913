import * as meetingSetupActionTypes from '../actionTypes/personalAgendaActionTypes';
import {cloneDeep} from 'lodash';
import {loadStates} from '../../constants/data';

const initialState = {
    isOpened: false,
    listByDate: [],
    loadState: '',
};

const reducer = (state = cloneDeep(initialState), action) => {
    switch (action.type) {
        case meetingSetupActionTypes.TOGGLE_AGENDA_CUSTOM_ELEMENT: {
            return {...state, isOpened: action.payload};
        }

        case meetingSetupActionTypes.START_LIST_LOAD: {
            return {...state, listByDate: [], loadState: loadStates.loading};
        }

        case meetingSetupActionTypes.END_LIST_LOAD: {
            const {error, listByDate} = action.payload;
            return {...state, listByDate, loadState: error ? loadStates.error : loadStates.ready};
        }

        case meetingSetupActionTypes.RESET_PERSONAL_AGENDA_STATE:
            return cloneDeep(initialState);

        default:
            return state;
    }
};

export default reducer;
