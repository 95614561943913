import React from 'react';
import classNames from 'classnames';
import {IconSchedule} from '../../../../../Icons';
import DetectOutsideClick from '../../../../../DetectOutsideClick/DetectOutsideClick';
import Chats from '../Chats';
import Notifications from '../Notifications';
import Program from '../Program/Program';
import MyEvents from '../MyEvents';
import MyAccount from '../MyAccount';
import BusinessWalletPopup from '../BusinessWalletPopup/BusinessWalletPopup';
import AgendaPopup from '../AgendaPopup';
import Contacts from './components/Contacts';
import Rooms from './components/Rooms/Rooms';
import BottomNavigationButton from './components/BottomNavigationButton/BottomNavigationButton';
import ScheduleBottomMenuBottomSheet from './ScheduleBottomMenuBottomSheet/ScheduleBottomMenuBottomSheet';

import LogoLobbyGrey from 'Images/lobby-mobile-grey.svg';
import {ReactComponent as LogoLobbyGreen} from 'Images/lobby-mobile-green.svg';
import {ReactComponent as WalletIcon} from 'Images/wallet.svg';
import {ReactComponent as ChatsActiveIcon} from 'Images/chat-active.svg';
import ChatsIcon from 'Images/chat.svg';
import RoomsIcon from 'Images/rooms-mobile-icon.svg';
import {ReactComponent as RoomsIconActive} from 'Images/rooms-mobile-green.svg';
import {ReactComponent as MenuIconGreen} from 'Images/arrow_up_green.svg';
import {ReactComponent as NotificationsIcon} from 'Images/ring.svg';
import {ReactComponent as MenuDropdownIcon} from 'Images/arrow_down_grey.svg';
import AccountIcon from 'Images/account.svg';
import {ReactComponent as NotificationsActiveIcon} from 'Images/ring-active.svg';
import {ReactComponent as AccountActiveIcon} from 'Images/account-active.svg';

import SocialMediaShareEvent from 'Routes/components/SocialMediaShareEvent';

import './EventMenuMobile.scss';

const SCHEDULE_BOTTOM_SHEET_CLASSNAME = 'ScheduleBottomMenuBottomSheet';

class EventMenuMobile extends React.Component {
    state = {
        translation: null,
    };

    componentDidMount() {
        this._addOverlayWhenMyEventsIsOpened();
        document.addEventListener('mousedown', this._addOverlayWhenMyEventsIsOpened);

        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    componentWillUnmount() {
        document.removeEventListener('mousedown', this._addOverlayWhenMyEventsIsOpened);
    }

    _addOverlayWhenMyEventsIsOpened = () => {
        setTimeout(function () {
            if (
                document.getElementById('account-drop-mobile') ||
                document.querySelector('.notifications-mobile') ||
                document.querySelector('.my-account-mobile') ||
                document.getElementById('rooms-dropdown') ||
                document.querySelector(`.${SCHEDULE_BOTTOM_SHEET_CLASSNAME}`)
            ) {
                if (document.querySelector('.position-background')) {
                    document.querySelector('.position-background').classList.add('overlay-grey');
                } else if (document.getElementById('my-account-mobile')) {
                    document.getElementById('my-account-mobile').classList.add('overlay-grey');
                }
            } else {
                if (document.querySelector('.position-background')) {
                    document.querySelector('.position-background').classList.remove('overlay-grey');
                } else if (document.getElementById('my-account-mobile')) {
                    document.getElementById('my-account-mobile').classList.remove('overlay-grey');
                }
            }
        }, 150);
    };

    redirectToLobby = () => {
        const {eventSlug} = this.props;
        this.props.onCloseTopNav();
        this.props.history.push(`/event/${eventSlug}/lobby`);
    };

    render() {
        const {
            refreshPage,
            notificationsNotRead,
            activeMyAccount,
            activeHome,
            topNavigation,
            sessionExpired,
            videoConference: {isActive},
            videoConferenceCompanionDisconnected,
            closeDisconnectedUserMessage,
        } = this.props;
        const {translation} = this.state;
        // fix for removing green lobby when one overlay is opened;
        let activeLobby =
            activeHome &&
            !topNavigation.seeRooms &&
            !topNavigation.seeContacts &&
            !topNavigation.seeChats &&
            !topNavigation.seeProgram;

        const isScheduleActive =
            topNavigation.isScheduleBottomSheetOpened || topNavigation.agendaConfig.visible || topNavigation.seeProgram;

        const isRtl = this.props.languages.isRtlLanguage;
        return (
            <DetectOutsideClick>
                <div className="top-container">
                    {isActive && videoConferenceCompanionDisconnected && (
                        <div className="server-down-info-container">
                            <p>
                                User was disconnected from the conference.
                                <span onClick={closeDisconnectedUserMessage}>Close</span>
                            </p>
                        </div>
                    )}
                    {sessionExpired && (
                        <div className="server-down-info-container">
                            <p>
                                {translation?.eventMenu.sessionProblemTextFirst}
                                <span onClick={refreshPage}>{translation?.eventMenu.connectionProblemButton}</span>
                                {translation?.eventMenu.sessionProblemTextSecond}
                            </p>
                        </div>
                    )}
                    <header id="header-mobile" className="header">
                        <div className="main-menu-container">
                            <div className="actions-container">
                                <div className="left-header-wrapper">
                                    <div
                                        id="my-events"
                                        className={classNames({isRtl: isRtl})}
                                        onClick={this.props.seeMyEvents}
                                    >
                                        <p>
                                            <span>{this.props.event.name}</span>
                                        </p>
                                        {this.props.topNavigation.seeMyEvents ? (
                                            <MenuIconGreen className="primary-fill-svg" />
                                        ) : (
                                            <MenuDropdownIcon className="primary-fill-svg" />
                                        )}
                                    </div>
                                </div>
                                <div className={classNames('right-header-wrapper', {isRtl: isRtl})}>
                                    <div id="notifications" onClick={this.props.seeNotifications}>
                                        {this.props.topNavigation.seeNotifications ? (
                                            <NotificationsActiveIcon className="primary-fill-svg" />
                                        ) : (
                                            <NotificationsIcon />
                                        )}

                                        {notificationsNotRead && (
                                            <div>
                                                <span>{notificationsNotRead}</span>
                                            </div>
                                        )}
                                    </div>

                                    <div className={classNames('social-media-share-event', {isRtlLanguage: isRtl})}>
                                        <SocialMediaShareEvent />
                                    </div>

                                    <div
                                        id="myaccount"
                                        onClick={this.props.seeMyAccount}
                                        className={
                                            this.props.topNavigation.seeMyAccount || activeMyAccount ? 'active' : ''
                                        }
                                    >
                                        {this.props.topNavigation.seeMyAccount || activeMyAccount ? (
                                            <AccountActiveIcon className="primary-fill-svg" />
                                        ) : (
                                            <img src={AccountIcon} width="24" height="24" alt="My account" />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    {this.props.topNavigation.seeContacts && <Contacts mobile />}
                    {this.props.topNavigation.walletConfig.visible && <BusinessWalletPopup />}
                    {this.props.topNavigation.agendaConfig.visible && (
                        <AgendaPopup activeTab={this.props.topNavigation.agendaConfig.activeTab} />
                    )}
                    {this.props.topNavigation.seeProgram && <Program mobile />}
                    {this.props.topNavigation.seeChats && <Chats />}
                    {this.props.topNavigation.seeNotifications && <Notifications mobile />}
                    {this.props.topNavigation.seeMyEvents && <MyEvents mobile />}
                    {this.props.topNavigation.seeMyAccount && <MyAccount mobile />}
                </div>
                <div id="menu-bottom">
                    <div className="menu-bottom-wrapper">
                        <div className="main-menu-container">
                            <div className="main-header-wrapper">
                                <div
                                    className={'home-button ' + (activeLobby ? 'active' : '')}
                                    onClick={this.redirectToLobby}
                                >
                                    {activeLobby ? (
                                        <LogoLobbyGreen className="primary-fill-svg primary-stroke-svg" />
                                    ) : (
                                        <img src={LogoLobbyGrey} width="24" height="24" alt="Lobby" />
                                    )}
                                    <p>{translation?.eventMenu.lobbyButton}</p>
                                </div>
                                <div
                                    id="rooms"
                                    onClick={this.props.seeRooms}
                                    className={this.props.topNavigation.seeRooms ? 'active' : ''}
                                >
                                    {this.props.topNavigation.seeRooms ? (
                                        <RoomsIconActive className="primary-fill-svg" />
                                    ) : (
                                        <img src={RoomsIcon} width="24" height="24" alt="Rooms" />
                                    )}
                                    <p>
                                        {this.props.event.hasVillage
                                            ? translation?.eventMenu.hubButton
                                            : translation?.eventMenu.roomsButton}
                                    </p>
                                </div>
                                <div
                                    id="wallet"
                                    onClick={() => {
                                        this.props.openWallet();
                                    }}
                                    className={this.props.topNavigation.walletConfig.visible ? 'active' : ''}
                                >
                                    <WalletIcon />
                                    <p>{translation?.businessWallet.short}</p>
                                </div>

                                <div
                                    id="chats"
                                    onClick={this.props.seeChats}
                                    className={this.props.topNavigation.seeChats ? 'active' : ''}
                                >
                                    {this.props.topNavigation.seeChats ? (
                                        <ChatsActiveIcon className="primary-fill-svg" />
                                    ) : (
                                        <img src={ChatsIcon} width="24" height="24" alt="Chats" />
                                    )}

                                    <p>{translation?.eventMenu.chatsButton}</p>
                                    {this.props.notReadChatMessages && (
                                        <div>
                                            <span>{this.props.notReadChatMessages}</span>
                                        </div>
                                    )}
                                </div>

                                <div id="schedule">
                                    <BottomNavigationButton
                                        title={translation?.personalAgenda.schedule}
                                        icon={<IconSchedule />}
                                        isActive={isScheduleActive}
                                        onClick={this.props.openScheduleBottomSheet}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.props.topNavigation.seeRooms && <Rooms />}
                    {this.props.topNavigation.isScheduleBottomSheetOpened && (
                        <ScheduleBottomMenuBottomSheet
                            onAgendaClick={this.props.seeAgenda}
                            onProgramClick={this.props.seeProgram}
                        />
                    )}
                </div>
            </DetectOutsideClick>
        );
    }
}

export default EventMenuMobile;
