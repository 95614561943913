import React from 'react';
import {connect} from 'react-redux';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from '@material-ui/core';

class BoothDocuments extends React.Component {
    state = {
        translation: null,
    };

    componentDidMount() {
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };
    render() {
        const {translation} = this.state;

        return (
            <Dialog
                open={this.props.opened}
                onClose={this.props.closeDialog}
                aria-labelledby="form-dialog-title"
                id="booth-files-dialog"
                className="booth-documents-dialog-container booth-dialog"
            >
                <DialogContent className="booth-dialog-content">
                    <DialogTitle id="form-dialog-title">{translation?.showfloor.boothFilesDialogTitle}</DialogTitle>
                    <div className="content-container">
                        <p className="subtitle">{translation?.generalText.download}</p>
                        {this.props.documents.map((document) => (
                            <p key={document._id} onClick={this.props.onClickOnDocument}>
                                <a href={this.props.filesUrl + document.file} target="_blank" rel="noopener noreferrer">
                                    <span className="link">{document.displayName}</span>
                                </a>
                            </p>
                        ))}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={this.props.closeDialog} className="booth-cancel-button">
                        {translation?.generalText.close}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(BoothDocuments);
