import * as actionTypes from '../actionTypes/adminActionTypes';
import {Api} from 'Api';

export const getOrganizers = (pagination) => async (dispatch) => {
    dispatch(getOrganizersStart());

    try {
        const {organizers, page, perPage, pages, total} = await Api.request({
            url: `/admin/platform-organizers`,
            method: 'get',
            params: {
                perPage: pagination.perPage,
                page: pagination.page,
            },
        });

        dispatch(getOrganizersSuccess({organizers, page: Number(page), perPage: Number(perPage), pages, total}));
    } catch (error) {
        let errorMessage = "We couldn't approve the organizer. Please try again.";
        dispatch(getOrganizersFail(errorMessage));
    }
};

const getOrganizersStart = () => {
    return {
        type: actionTypes.GET_ORGANIZERS_START,
    };
};
const getOrganizersSuccess = (data) => {
    return {
        type: actionTypes.GET_ORGANIZERS_SUCCESS,
        payload: data,
    };
};
const getOrganizersFail = (errorMessage) => {
    return {
        type: actionTypes.GET_ORGANIZERS_FAIL,
        payload: errorMessage,
    };
};

export const changeOrganizersListPage = (payload) => ({type: actionTypes.CHANGE_ORGANIZERS_LIST_PAGE, payload});

export const changeOrganizerStatus = (userId, data) => async (dispatch, getState) => {
    const {
        admin: {
            organizersList: {loading},
        },
    } = getState();

    dispatch(changeOrganizerStatusRequest());

    try {
        const {user} = await Api.request({
            url: `/users/${userId}`,
            method: 'put',
            payload: data,
            loading,
        });

        dispatch(changeOrganizerStatusSuccess(user));
    } catch (error) {
        dispatch(changeOrganizerStatusFail());
    }
};

const changeOrganizerStatusRequest = () => ({
    type: actionTypes.CHANGE_ORGANIZER_STATUS_REQUEST,
});

const changeOrganizerStatusSuccess = (payload) => ({
    type: actionTypes.CHANGE_ORGANIZER_STATUS_SUCCESS,
    payload,
});

const changeOrganizerStatusFail = () => ({
    type: actionTypes.CHANGE_ORGANIZER_STATUS_FAIL,
});

export const deleteOrganizer = (userId) => async (dispatch, getState) => {
    const {
        admin: {
            organizersList: {loading},
        },
    } = getState();

    dispatch(deleteOrganizerStart());

    try {
        await Api.request({
            url: `/users/${userId}`,
            method: 'delete',
            loading,
        });

        dispatch(deleteOrganizerSuccess());
    } catch (error) {
        let errorMessage = "We couldn't delete the organizer. Please try again.";
        dispatch(deleteOrganizerFail(errorMessage));
    }
};

const deleteOrganizerStart = () => {
    return {
        type: actionTypes.DELETE_ORGANIZER_START,
    };
};
const deleteOrganizerSuccess = () => {
    return {
        type: actionTypes.DELETE_ORGANIZER_SUCCESS,
    };
};
const deleteOrganizerFail = (errorMessage) => {
    return {
        type: actionTypes.DELETE_ORGANIZER_FAIL,
        payload: errorMessage,
    };
};

const getOnlineUsersAdminStart = () => ({
    type: actionTypes.GET_ONLINE_USERS_ADMIN_START,
});

const getOnlineUsersAdminSuccess = (data = 0) => ({
    type: actionTypes.GET_ONLINE_USERS_ADMIN_SUCCESS,
    payload: data,
});

const getOnlineUsersAdminFail = (error) => ({
    type: actionTypes.GET_ONLINE_USERS_ADMIN_FAIL,
    payload: error,
});

export const getOnlineUsersAdmin = () => async (dispatch, getState) => {
    const {
        admin: {
            organizersList: {loading},
        },
    } = getState();

    dispatch(getOnlineUsersAdminStart());

    try {
        const {onlineUsersCount} = await Api.request({
            url: '/admin/online-users',
            method: 'get',
            loading,
        });

        dispatch(getOnlineUsersAdminSuccess(onlineUsersCount));
    } catch (error) {
        const errorMessage = "We couldn't get online users. Please try again.";
        dispatch(getOnlineUsersAdminFail(errorMessage));
    }
};

export const getOrganizerEvents =
    ({organizerId, pagination, isFetchAfterDelete}) =>
    async (dispatch) => {
        dispatch(getOrganizerEventsRequest());

        try {
            const {events, page, perPage, pages, total} = await Api.request({
                method: 'get',
                url: `/organizer-events/${organizerId}`,
                params: {
                    perPage: pagination.perPage,
                    page: pagination.page,
                },
            });

            events.forEach((evt) => {
                evt.maxParticipantsCountDefault = evt.maxParticipantsCount;
                evt.maxExhibitorsCountDefault = evt.maxExhibitorsCount;
                evt.maxBothAvatarsCountDefault = evt.maxBothAvatarsCount;
                evt.listedCoOrganizersDefault = evt.listedCoOrganizers;
            });

            dispatch(
                getOrganizerEventsSuccess({
                    events,
                    page: Number(page),
                    perPage: Number(perPage),
                    pages,
                    total,
                    isFetchAfterDelete,
                })
            );
        } catch (error) {
            dispatch(getOrganizerEventsFail());
        }
    };

const getOrganizerEventsRequest = () => ({
    type: actionTypes.GET_ORGANIZER_EVENTS_REQUEST,
});

const getOrganizerEventsSuccess = (payload) => ({
    type: actionTypes.GET_ORGANIZER_EVENTS_SUCCESS,
    payload,
});

const getOrganizerEventsFail = () => ({
    type: actionTypes.GET_ORGANIZER_EVENTS_FAIL,
});

export const deleteOrganizerEvent =
    ({eventId, organizerId, callback}) =>
    async (dispatch, getState) => {
        const {
            admin: {
                organizerDetails: {
                    loading,
                    pagination: {page, perPage},
                },
            },
        } = getState();

        dispatch(deleteOrganizerEventRequest());

        try {
            await Api.request({
                method: 'delete',
                url: `/event/${eventId}`,
                loading,
            });

            dispatch(deleteOrganizerEventSuccess());

            if (callback) callback();

            dispatch(getOrganizerEvents({organizerId, pagination: {page, perPage}, isFetchAfterDelete: true}));
        } catch (error) {
            dispatch(deleteOrganizerEventFail());
        }
    };

const deleteOrganizerEventRequest = () => ({
    type: actionTypes.DELETE_ORGANIZER_EVENT_REQUEST,
});

const deleteOrganizerEventSuccess = (payload) => ({
    type: actionTypes.DELETE_ORGANIZER_EVENT_SUCCESS,
    payload,
});

const deleteOrganizerEventFail = () => ({
    type: actionTypes.DELETE_ORGANIZER_EVENT_FAIL,
});

export const updateOrganizerEvent =
    ({eventId, organizerId, payload, callback}) =>
    async (dispatch, getState) => {
        const {
            admin: {
                organizerDetails: {loading},
            },
        } = getState();

        dispatch(updateOrganizerEventRequest());

        try {
            const {event} = await Api.request({
                method: 'put',
                url: `/admin/${eventId}/event-settings`,
                payload,
                loading,
            });

            dispatch(updateOrganizerEventSuccess(event));

            if (callback) callback();
        } catch (error) {
            dispatch(updateOrganizerEventFail());
        }
    };

const updateOrganizerEventRequest = () => ({
    type: actionTypes.UPDATE_ORGANIZER_EVENT_REQUEST,
});

const updateOrganizerEventSuccess = (payload) => ({
    type: actionTypes.UPDATE_ORGANIZER_EVENT_SUCCESS,
    payload,
});

const updateOrganizerEventFail = () => ({
    type: actionTypes.UPDATE_ORGANIZER_EVENT_FAIL,
});

export const changeEventsListPage = (payload) => ({type: actionTypes.CHANGE_EVENTS_LIST_PAGE, payload});

export const clearEventsList = () => ({type: actionTypes.CLEAR_EVENTS_LIST});
