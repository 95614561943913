import React from 'react';
import {Button} from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {ReactComponent as SaveIcon} from '../../../../../Images/add-to-fav.svg';
import {usePlatformTranslation} from '../../../../../services/hooks';
import './ListedEventCardSaveBtn.scss';

const ListedEventCardSaveBtn = ({isSaved, onSave, onUnsave, className, preSaveEvent, redirect}) => {
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);
    const isLoggedIn = useSelector((state) => state.user.loggedIn);
    const {eventsListing} = usePlatformTranslation();

    const saveEvent = () => {
        if (isLoggedIn) {
            onSave && onSave();
        } else {
            preSaveEvent();
            redirect();
        }
    };

    return (
        <>
            {isSaved ? (
                <Button
                    data-testid="listed-card-un-save-btn"
                    onClick={onUnsave}
                    variant="text"
                    color="secondary"
                    classes={{
                        startIcon: classNames('listed-event-card-save-btn__startIcon', {isRtl: isRtlLanguage}),
                    }}
                    className={classNames('listed-event-card-save-btn', className)}
                    startIcon={<RemoveCircleOutlineIcon className="primary-fill-svg" />}
                >
                    {eventsListing.discardEvent.toUpperCase()}
                </Button>
            ) : (
                <Button
                    data-testid="listed-card-save-btn"
                    onClick={saveEvent}
                    variant="text"
                    color="secondary"
                    classes={{
                        startIcon: classNames('listed-event-card-save-btn__startIcon', {isRtl: isRtlLanguage}),
                    }}
                    className={classNames('listed-event-card-save-btn', className)}
                    startIcon={<SaveIcon className="primary-fill-svg" />}
                >
                    {eventsListing.saveEvent.toUpperCase()}
                </Button>
            )}
        </>
    );
};

export default ListedEventCardSaveBtn;
