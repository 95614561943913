import React from 'react';
import classNames from 'classnames';

import './FormGroup.scss';

const FormGroup = ({children, verticalAlignment = 'center', horizontalAlignment = 'left', className}) => (
    <div
        className={classNames(
            'FormGroup',
            `FormGroup_verticalAlignment-${verticalAlignment}`,
            `FormGroup_horizontalAlignmemnt-${horizontalAlignment}`,
            {[className]: className}
        )}
    >
        {children}
    </div>
);

FormGroup.HalfItem = ({className, children}) => (
    <div className={classNames('FormGroup__halfItem', {[className]: className})}>{children}</div>
);
FormGroup.NarrowItem = ({children}) => <div className="FormGroup__narrowItem">{children}</div>;
FormGroup.WideItem = ({children}) => <div className="FormGroup__wideItem">{children}</div>;

export default FormGroup;
