import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {CircularProgress} from '@material-ui/core';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
    },
    spinner: {
        color: '#fff',
    },
});

const VideoConferenceSpinner = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CircularProgress className={classes.spinner} />
        </div>
    );
};

export default VideoConferenceSpinner;
