import {AllocateResourcesDialog} from './AllocateResourcesDialog';
import {connect} from 'react-redux';
import {getTimeslotLoading} from 'store/selectors';
import {sessionStreamAllocateResources} from 'store/actions';

export default connect(
    (state) => ({
        loading: getTimeslotLoading(state),
    }),
    {
        sessionStreamAllocateResources,
    }
)(AllocateResourcesDialog);
