import React from 'react';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';

import {getPrivateChatId} from '../../../services/utils';
import LinkedinIcon from '../../../Images/linkedin-sign.svg';
import FacebookIcon from '../../../Images/facebook-logo.svg';
import TwitterIcon from '../../../Images/twitter.svg';

class SocialLinks extends React.Component {
    handleOpenPrivateChat = () => {
        if (this.props.restrictedNetwork) {
            return null;
        }

        const targetUserId = this.props.user.user._id;
        const privateChatId = getPrivateChatId(this.props.privateChats, targetUserId);
        const {eventId} = this.props;

        if (privateChatId) {
            this.props.onOpenPrivateChat(privateChatId);
        } else {
            if (targetUserId !== this.props.userLoggedIn._id && !this.props.loading) {
                this.props.onCreatePrivateChat(targetUserId, eventId);
            }
        }
    };

    handleAcceptFriendRequest = () => {
        this.props.onAcceptContactRequest(this.props.requestId);
    };

    handleDeclineFriendRequest = () => {
        this.props.onDeclineContactRequest(this.props.requestId);
    };

    handleSendFriendConnection = () => {
        if (this.props.restrictedNetwork) {
            return null;
        }
        const targetUserId = this.props.user.user._id;
        this.props.onSendContactRequest(targetUserId);
    };

    render() {
        const {user, restrictedNetwork} = this.props;
        return (
            <div>
                {user.linkedin && (
                    <a
                        href={!restrictedNetwork && user.linkedin}
                        target="_blank"
                        className="social-button"
                        rel="noopener noreferrer"
                    >
                        <div>
                            {restrictedNetwork ? (
                                <Tooltip title="Restricted">
                                    <img src={LinkedinIcon} width="16" height="16" alt="LinkedIn Logo" />
                                </Tooltip>
                            ) : (
                                <img src={LinkedinIcon} width="16" height="16" alt="LinkedIn Logo" />
                            )}
                        </div>
                    </a>
                )}
                {user.facebook && (
                    <a
                        href={!restrictedNetwork && user.facebook}
                        target="_blank"
                        className="social-button"
                        rel="noopener noreferrer"
                    >
                        <div>
                            {restrictedNetwork ? (
                                <Tooltip title="Restricted">
                                    <img src={FacebookIcon} width="16" height="16" alt="Facebook Logo" />
                                </Tooltip>
                            ) : (
                                <img src={FacebookIcon} width="16" height="16" alt="Facebook Logo" />
                            )}
                        </div>
                    </a>
                )}
                {user.twitter && (
                    <a
                        href={!restrictedNetwork && user.twitter}
                        target="_blank"
                        className="social-button"
                        rel="noopener noreferrer"
                    >
                        <div>
                            {restrictedNetwork ? (
                                <Tooltip title="Restricted">
                                    <img src={TwitterIcon} width="16" height="16" alt="Twitter Logo" />
                                </Tooltip>
                            ) : (
                                <img src={TwitterIcon} width="16" height="16" alt="Twitter Logo" />
                            )}
                        </div>
                    </a>
                )}
            </div>
        );
    }
}

export default SocialLinks;
