import {connect} from 'react-redux';
import {RingContainer} from './RingContainer';
import {startCalling, setNotReadChatMessages} from 'store/actions';
import {getUserNotReadChatMessages, getUserData, getActiveCallTypeData} from 'store/selectors';

export default connect(
    (state) => ({
        user: getUserData(state),
        notReadChatMessages: getUserNotReadChatMessages(state),
        videoConference: getActiveCallTypeData(state),
    }),
    {
        startCalling,
        setNotReadChatMessages,
    }
)(RingContainer);
