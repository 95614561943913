import {useSelector} from 'react-redux';
import {getEventData, getUsersList} from 'store/selectors';

const findEventUser = (memberList, userId) => memberList.find((member) => member._id === userId);

const useUserCompany = () => {
    const usersList = useSelector(getUsersList);
    const event = useSelector(getEventData);

    const ownerId = event?.owner?._id;

    const getCompanyByUserId = (userId) => {
        if (!event || !userId || userId === ownerId) {
            return '';
        }

        const eventUser = findEventUser(usersList, userId);

        return eventUser?.company;
    };

    return {getCompanyByUserId};
};

export default useUserCompany;
