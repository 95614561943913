import {useSelector} from 'react-redux';
import {
    getExhibitorCreatedJob,
    getExhibitorDeletedJob,
    getExhibitorEditedJob,
    getExhibitorJobsError,
    getExhibitorJobsList,
    getExhibitorJobsLoading,
} from 'store/selectors';

const useJob = () => {
    const jobList = useSelector(getExhibitorJobsList);
    const isLoading = useSelector(getExhibitorJobsLoading);
    const isError = useSelector(getExhibitorJobsError);
    const editedJob = useSelector(getExhibitorEditedJob);
    const createdJob = useSelector(getExhibitorCreatedJob);
    const deletedJob = useSelector(getExhibitorDeletedJob);

    return {
        isError,
        isLoading,
        jobList,
        editedJob,
        createdJob,
        deletedJob,
        activeJob: editedJob || createdJob,
        isEmpty: jobList.length === 0 && !createdJob,
    };
};

export default useJob;
