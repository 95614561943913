import React from 'react';

import Svg from '../Svg/Svg';

import './Email.scss';

const IconEmail = () => (
    <Svg className="IconEmail">
        <path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0l-8 4.99L4 6h16zm0 12H4V8l8 5 8-5v10z" />
    </Svg>
);

export default IconEmail;
