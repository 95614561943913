import React from 'react';

import Svg from '../Svg/Svg';

import './FilledTwitter.scss';

const IconFilledTwitter = () => (
    <Svg className="IconFilledTwitter">
        <path d="M19.5 0h-15A4.5 4.5 0 000 4.5v15A4.5 4.5 0 004.5 24h15a4.5 4.5 0 004.5-4.5v-15A4.5 4.5 0 0019.5 0zm-1.53 9.306l.009.382c0 3.906-2.971 8.406-8.406 8.406a8.362 8.362 0 01-4.529-1.327 5.928 5.928 0 004.374-1.223 2.96 2.96 0 01-2.76-2.052c.182.033.365.051.557.051.27 0 .53-.034.778-.101a2.957 2.957 0 01-2.37-2.897v-.037a2.95 2.95 0 001.338.37 2.954 2.954 0 01-.915-3.943 8.385 8.385 0 006.09 3.087 2.955 2.955 0 015.036-2.694 5.983 5.983 0 001.876-.717 2.962 2.962 0 01-1.299 1.635 5.922 5.922 0 001.697-.466 5.97 5.97 0 01-1.476 1.526z" />
    </Svg>
);

export default IconFilledTwitter;
