import MatchingDialogQuestionsPage from './MatchingDialogQuestionsPage';
import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export default connect(
    (state) => ({
        matchingFormId: selectors.getMatchMakingFormId(state),
        matchingForm: selectors.getMatchMakingForm(state),
    }),
    {
        onPostMatchingAnswers: actions.postMatchingAnswers,
    }
)(MatchingDialogQuestionsPage);
