import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {Grid} from '@material-ui/core';

import AuditoriumArchiveWrapper from '../AuditoriumArchiveWrapper';
import ColoredScrollbars from '../ColoredScrollbars/ColoredScrollbars';
import BreadcrumbsNavigation from '../BreadcrumbsNavigation';

import {getFormattedDate, getVideoIdFromYoutubeUrl} from 'services/utils';

import PlayIcon from 'Images/play.svg';
import {ReactComponent as Search} from 'Images/search-icon.svg';

import './EventArchive.scss';

export class EventArchive extends React.Component {
    state = {
        search: '',
        translation: null,
    };

    componentDidMount() {
        this.props.onSetEventProtectedMenu();

        const {eventArchive, loadingEventArchive, eventId} = this.props;

        if (!eventArchive && !loadingEventArchive) {
            this.props.onGetEventArchive(eventId);
        }

        this.props.closeTopNavigation();
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    getEventFilteredVideos = (videos) => {
        if (this.state.search.length < 2) {
            return videos;
        } else {
            let filteredVideos = videos.filter((video) => {
                if (video.title.toLowerCase().includes(this.state.search.trim().toLowerCase())) {
                    return video;
                }
                if (video.description.toLowerCase().includes(this.state.search.trim().toLowerCase())) {
                    return video;
                }

                let matchedHashtags = [];
                if (video.hashtags.length > 0) {
                    matchedHashtags = video.hashtags.filter((hashtag) =>
                        hashtag.toLowerCase().includes(this.state.search.trim().toLowerCase())
                    );
                }
                if (matchedHashtags.length > 0) {
                    return video;
                }

                let matchedSpeakersName = [];
                let matchedSpeakersCompany = [];
                if (video.speakers.length > 0) {
                    matchedSpeakersName = video.speakers.filter((speaker) =>
                        speaker.toLowerCase().includes(this.state.search.trim().toLowerCase())
                    );
                }
                if (matchedSpeakersName.length > 0) {
                    return video;
                }
                if (matchedSpeakersCompany.length > 0) {
                    return video;
                }

                return false;
            });
            return filteredVideos;
        }
    };

    render() {
        const {translation} = this.state;

        return (
            <AuditoriumArchiveWrapper className={classNames({isRtl: this.props.isRtlLanguage})}>
                <BreadcrumbsNavigation />
                <div className="program-dashboard">
                    <div className="program-section">
                        <div className="smallContainer">
                            <div className="page-title">
                                <span className="name">{translation?.eventArchive.title}</span>
                                <div className="search-container position-relative">
                                    <input
                                        id="search"
                                        placeholder={translation?.eventArchive.searchText}
                                        value={this.state.search}
                                        onChange={this.handleChange('search')}
                                    />
                                    <Search />
                                </div>
                            </div>
                            {this.props.eventArchive && this.props.eventArchive.length > 0 ? (
                                <div className="auditoriums-archive-container">
                                    <ColoredScrollbars>
                                        {this.props.eventArchive.map((event) => {
                                            const filteredEventVideos = this.getEventFilteredVideos(event.videos);

                                            return (
                                                <div className="single-auditorium-archive-container" key={event._id}>
                                                    <h4 className="auditorium-name">
                                                        <span>{event.eventName}</span>
                                                        <span className="break">|</span>
                                                        <span className="dates">
                                                            {event.eventStartDate === event.eventEndDate
                                                                ? getFormattedDate(event.eventStartDate, true)
                                                                : `${getFormattedDate(
                                                                      event.eventStartDate,
                                                                      true
                                                                  )} - ${getFormattedDate(event.eventEndDate, true)}`}
                                                        </span>
                                                    </h4>
                                                    <Grid container className="smallContainer">
                                                        {filteredEventVideos.length > 0 ? (
                                                            <>
                                                                {filteredEventVideos.map((video) => {
                                                                    let videoId = getVideoIdFromYoutubeUrl(video.url);
                                                                    return (
                                                                        <Grid
                                                                            item
                                                                            xs={6}
                                                                            md={4}
                                                                            lg={3}
                                                                            className="program-entry"
                                                                            key={video._id}
                                                                        >
                                                                            <div className="video-preview-container">
                                                                                <Link
                                                                                    to={{
                                                                                        pathname:
                                                                                            '/event/' +
                                                                                            this.props.event.slug +
                                                                                            '/event-archive/event/' +
                                                                                            event._id +
                                                                                            '/' +
                                                                                            video._id,
                                                                                    }}
                                                                                >
                                                                                    <div className="clickable-element">
                                                                                        <div className="image-preview-video">
                                                                                            <img
                                                                                                src={
                                                                                                    'https://img.youtube.com/vi/' +
                                                                                                    videoId +
                                                                                                    '/0.jpg'
                                                                                                }
                                                                                                alt="preview video"
                                                                                            />
                                                                                            {/*remove d-none to show duration*/}
                                                                                            <div className="video-duration d-none">
                                                                                                <span>40:16</span>
                                                                                            </div>
                                                                                            <div
                                                                                                className="play-button"
                                                                                                style={{
                                                                                                    backgroundImage: `url('${PlayIcon}')`,
                                                                                                }}
                                                                                            ></div>
                                                                                        </div>
                                                                                    </div>
                                                                                </Link>
                                                                            </div>
                                                                            <div className="video-details-container">
                                                                                <div className="speakers-container">
                                                                                    {video.speakers.length
                                                                                        ? video.speakers.map(
                                                                                              (speaker, index) => {
                                                                                                  return (
                                                                                                      <span
                                                                                                          className="speaker"
                                                                                                          key={index}
                                                                                                      >
                                                                                                          {speaker}
                                                                                                          {index !==
                                                                                                          video.speakers
                                                                                                              .length -
                                                                                                              1
                                                                                                              ? ', '
                                                                                                              : null}
                                                                                                      </span>
                                                                                                  );
                                                                                              }
                                                                                          )
                                                                                        : null}
                                                                                </div>
                                                                                <Link
                                                                                    to={{
                                                                                        pathname:
                                                                                            '/event/' +
                                                                                            this.props.event.slug +
                                                                                            '/event-archive/event/' +
                                                                                            event._id +
                                                                                            '/' +
                                                                                            video._id,
                                                                                    }}
                                                                                >
                                                                                    {video.title}
                                                                                </Link>
                                                                                <div className="program-date-hash">
                                                                                    <span className="date">
                                                                                        {event.eventDate}
                                                                                    </span>
                                                                                    <span className="hashtag">
                                                                                        {video.hashtags.length > 0 &&
                                                                                            video.hashtags.map(
                                                                                                (hashtag) =>
                                                                                                    `${hashtag} `
                                                                                            )}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </Grid>
                                                                    );
                                                                })}
                                                            </>
                                                        ) : (
                                                            <Grid item xs={12}>
                                                                <p>{translation?.eventArchive.noVideosText}</p>
                                                            </Grid>
                                                        )}
                                                    </Grid>
                                                </div>
                                            );
                                        })}
                                    </ColoredScrollbars>
                                </div>
                            ) : (
                                <p>{translation?.eventArchive.noArchivedEvents}</p>
                            )}
                        </div>
                    </div>
                </div>
            </AuditoriumArchiveWrapper>
        );
    }
}
