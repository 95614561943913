import {connect} from 'react-redux';
import * as actions from '../../../store/actions';
import {EventPublicComponent} from './EventPublicComponent';
import * as selectors from '../../../store/selectors';

export default connect(
    (state) => ({
        eventId: selectors.getEventId(state),
        eventSlug: selectors.getEventSlug(state),
        eventNotFound: selectors.getIsEventNotFound(state),
        eventInfo: selectors.getEventInfo(state),
        loadingEvent: selectors.getEventLoading(state),
        userLoading: selectors.getUserLoading(state),
        loggedIn: selectors.getIsUserLoggedIn(state),
        userRegistration: selectors.getEventUserRegistration(state),
        eventShortLink: selectors.getEventShortLink(state),
    }),
    {
        onGetEventInfo: actions.getEventInfo,
        setEventId: actions.setEventId,
        setEventSlug: actions.setEventSlug,
        setEventIdBySlug: actions.setEventIdBySlug,
        onGetEventUserRegistration: actions.getEventUserRegistration,
        onSetEventShortLink: actions.setEventShortLink,
        onSetEventRegPackage: actions.setEventRegPackage,
    }
)(EventPublicComponent);
