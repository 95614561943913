export const getImageLink = (imageName, exhibitorId) =>
    imageName && `${process.env.REACT_APP_EXHIBITORS_FOLDER}${exhibitorId}/${imageName}`;

export const createMarketplaceFormData = (product) => {
    const formData = new FormData();
    formData.append('name', product.title);
    formData.append('description', product.description);
    formData.append('link', product.link);

    if (product?.media?.file) {
        formData.append('image', product.media.file, product.media.file.title);
        return formData;
    }

    if (product?.media?.title) {
        formData.append('image', product.media.title);
        return formData;
    }

    return formData;
};

export const findProductIndex = (productList, product) => productList.findIndex(({id}) => id === product.id);
