import {useState, useEffect} from 'react';

const calcScrollBarWidth = () => window.innerWidth - document.documentElement.clientWidth;

const useScrollBarWidth = () => {
    const [width, setWidth] = useState('0');
    const [wasCalculated, setWasCalculated] = useState();

    useEffect(() => {
        if (typeof window === 'undefined' || wasCalculated) {
            return;
        }

        setWasCalculated(true);
        setWidth(calcScrollBarWidth());
    }, [wasCalculated]);

    useEffect(() => {
        if (typeof window === 'undefined') {
            return;
        }

        const handleResize = () => {
            setWidth(calcScrollBarWidth());
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    });

    return width;
};

export default useScrollBarWidth;
