import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Checkbox, DialogActions, DialogContent, FormControlLabel, Radio, Button} from '@material-ui/core';
import {
    MATCHING_QUESTION_TYPES,
    MATCHMAKING__QUESTION_TYPES,
    MATCHMAKING__SELECT_TYPES,
    TABS_INDEXS,
} from 'constants/matchMaking';
import './MatchingDialogQuestionsPage.scss';

const MatchingDialogQuestionsPage = ({
    classes,
    setTab,
    questionIndex,
    setQuestionIndex,
    setDialogTitle,
    matchingQuestions,
    onPostMatchingAnswers,
    matchingFormId,
    answerData,
    matchingForm,
    setAnswerData,
}) => {
    const question = matchingQuestions[questionIndex];
    const isEmptyAnswer = !answerData[questionIndex]?.optionIds?.length;

    useEffect(() => {
        setDialogTitle(matchingForm?.title);
    }, [setDialogTitle, matchingForm]);

    const handleChangeCheckBox = ({target}) => {
        const newAnswersData = answerData.map((answer) => {
            if (answer.questionId !== question._id) return answer;

            const optionIds = answer.optionIds.includes(target.value)
                ? answer.optionIds.filter((id) => id !== target.value)
                : [...answer.optionIds, target.value];

            return {...answer, optionIds};
        });
        setAnswerData(newAnswersData);
    };

    const handleChangeRadio = ({target}) => {
        const newAnswersData = answerData.map((answer) => {
            if (answer.questionId !== question._id) return answer;

            const optionIds = [target.value];
            return {...answer, optionIds};
        });
        setAnswerData(newAnswersData);
    };

    const handleNext = () => {
        if (questionIndex + 1 <= matchingQuestions.length - 1) setQuestionIndex((state) => state + 1);
    };

    const handleBack = () => {
        if (questionIndex - 1 >= 0) setQuestionIndex((state) => state - 1);
    };

    const handleSubmit = () => {
        onPostMatchingAnswers({matchingFormId, data: {answers: answerData}});
        setTab(TABS_INDEXS.matchedPage);
    };

    const isCheckBox = question.type === MATCHING_QUESTION_TYPES.checkbox.value;

    return (
        <>
            <DialogContent className={classes?.conntent}>
                <div className="matching-dialog-questions-page">
                    <p className="matching-dialog-questions-page__question-type">
                        {question?.isRequired
                            ? MATCHMAKING__QUESTION_TYPES.required
                            : MATCHMAKING__QUESTION_TYPES.optional}
                    </p>
                    <p className="matching-dialog-questions-page__title">{question?.title}</p>
                    <div className="matching-dialog-questions-page__label-wrapper">
                        <p className="matching-dialog-questions-page__label-title">
                            {isCheckBox
                                ? MATCHMAKING__SELECT_TYPES.multiSelect
                                : MATCHMAKING__SELECT_TYPES.singleSelect}
                        </p>
                        {question.options.map((option) => {
                            const isSelected = answerData[questionIndex]?.optionIds.includes(option._id);

                            const controler = isCheckBox ? (
                                <FormControlLabel
                                    key={option._id}
                                    classes={{label: 'matching-dialog-questions-page__form-label'}}
                                    control={
                                        <Checkbox
                                            checked={isSelected}
                                            value={option._id}
                                            onChange={handleChangeCheckBox}
                                            name="checkedA"
                                            color="primary"
                                        />
                                    }
                                    label={option.title}
                                />
                            ) : (
                                <FormControlLabel
                                    key={option._id}
                                    classes={{label: 'matching-dialog-questions-page__form-label'}}
                                    control={
                                        <Radio
                                            color="primary"
                                            checked={isSelected}
                                            value={option._id}
                                            onChange={handleChangeRadio}
                                            name="checkedA"
                                        />
                                    }
                                    label={option.title}
                                />
                            );

                            return controler;
                        })}
                    </div>
                </div>
            </DialogContent>
            <DialogActions className={classes?.actions}>
                <div className="matchmaking-dialog__action-tab">
                    {questionIndex !== 0 && (
                        <Button
                            onClick={handleBack}
                            className={'matchmaking-dialog__action-tab-btn'}
                            variant="outlined"
                        >
                            back
                        </Button>
                    )}
                    {matchingQuestions.length - 1 === questionIndex ? (
                        <Button
                            key={`finish-btn ${question.isRequired} ${isEmptyAnswer}`}
                            disabled={question.isRequired && isEmptyAnswer}
                            color="primary"
                            onClick={handleSubmit}
                            className={'matchmaking-dialog__action-tab-btn'}
                            variant="contained"
                        >
                            finish
                        </Button>
                    ) : (
                        <Button
                            key={`next-btn ${question.isRequired} ${isEmptyAnswer}`}
                            disabled={question.isRequired && isEmptyAnswer}
                            color="primary"
                            onClick={handleNext}
                            className={'matchmaking-dialog__action-tab-btn'}
                            variant="contained"
                        >
                            next
                        </Button>
                    )}
                </div>
            </DialogActions>
        </>
    );
};

MatchingDialogQuestionsPage.propTypes = {
    classes: PropTypes.object.isRequired,
    setTab: PropTypes.func.isRequired,
    setDialogTitle: PropTypes.func.isRequired,
    matchingForm: PropTypes.object,
    questionIndex: PropTypes.number,
    setQuestionIndex: PropTypes.func,
    matchingQuestions: PropTypes.array,
    onPostMatchingAnswers: PropTypes.func,
    matchingFormId: PropTypes.string,
    answerData: PropTypes.array,
    setAnswerData: PropTypes.func,
};

export default MatchingDialogQuestionsPage;
