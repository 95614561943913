import {createSelector} from 'reselect';

const videoConference = (state) => state.videoConference;

export const getVideoConferenceActiveType = createSelector(
    videoConference,
    (state) => state.generalInfo.activeVideoCallType
);

export const getVideoConferenceGroupCallData = createSelector(videoConference, (state) => state.group);

export const getVideoConferenceOneToOneCallData = createSelector(videoConference, (state) => state.oneToOne);

export const getActiveCallTypeData = createSelector(
    videoConference,
    getVideoConferenceActiveType,
    (data, type) => data[type]
);

export const getIncomingCallUserId = createSelector(
    videoConference,
    (state) => state.generalInfo.incomingOneToOneCallUserId
);

export const getIncomingCallOffer = createSelector(videoConference, (state) => state.oneToOne.incomingCallOffer);

export const getIceCandidates = createSelector(videoConference, (state) => state.oneToOne.iceCandidates);

export const getPeerConnection = createSelector(videoConference, (state) => state.oneToOne.peerConnection);

export const getCompanionStream = createSelector(videoConference, (state) => state.oneToOne.companionStream);
