import React, {Fragment, useState, useEffect} from 'react';
import {generatePath} from 'react-router';
import moment from 'moment';
import {Link} from 'react-router-dom';
import {Grid} from '@material-ui/core';

import ColoredScrollbars from '../ColoredScrollbars/ColoredScrollbars';
import BreadcrumbsNavigation from '../BreadcrumbsNavigation';
import NoBuildingAccess from '../NoBuildingAccess/NoBuildingAccess';
import AuditoriumArchiveWrapper from '../AuditoriumArchiveWrapper';

import {ReactComponent as Search} from 'Images/search-icon.svg';
import PlayIcon from 'Images/play.svg';
import {checkIfEventHasEnded, getVideoIdFromYoutubeUrl} from 'services/utils';
import {getMonth} from 'services/utils';
import {RouterConfig} from 'routerConfig';
import {ETX_LIVE_SESSION, YOUTUBE, ZOOM} from 'constants/data/streaming-engine';
import {whiteLabel, recordingsThumbnails, eventFolder} from 'config';
import {SESSION_TYPES} from 'constants/data/session-type';

import './AuditoriumArhive.scss';

function AuditoriumArhive({
    eventId,
    event,
    platformLanguage,
    buildingId,
    building,
    resourcesAccess,
    setHasVideoWall,
    closeTopNavigation,
    onSetEventProtectedMenu,
    history
}) {
    const [search, setSearch] = useState('');
    const dataSource = building || event;

    useEffect(() => {
        onSetEventProtectedMenu();

        const eventHasEnded = checkIfEventHasEnded(dataSource, event.utcTimezone);

        if (!eventHasEnded) {
            history.push(
                generatePath(
                    buildingId ? RouterConfig.event.village.buildingAuditorium : RouterConfig.event.auditorium,
                    {
                        eventSlug: event.slug,
                        buildingId
                    }
                )
            );
        }
        setHasVideoWall(false);
        closeTopNavigation();
    }, [closeTopNavigation, onSetEventProtectedMenu, history, event, dataSource, buildingId, setHasVideoWall]);

    const getDay = (dateToFormat) => {
        let date = new Date(dateToFormat);
        const d = date.getDate();
        const formatedDate = (d < 9 ? '0' + d : d) + ' - ';
        return formatedDate;
    };

    const getProgramDayString = (currentDay) => {
        let currentDate = moment(currentDay).startOf('day');
        let day = currentDate.date();
        let month = getMonth(currentDay, platformLanguage);
        let year = currentDate.year();

        return `${day} ${month} ${year}`;
    };

    const handleChange = ({target: {value}}) => setSearch(value);

    if (
        building &&
        event.hasAccessManagement &&
        resourcesAccess !== null &&
        !resourcesAccess.buildings.includes(building._id)
    ) {
        return <NoBuildingAccess />;
    }

    return (
        <AuditoriumArchiveWrapper>
            <BreadcrumbsNavigation />
            <div className="program-dashboard">
                <div className="program-section">
                    <div className="smallContainer">
                        <div className="page-title">
                            <span className="name">{event && event.brandingData.homepageEventName}</span>
                            {/*remove d-none to show the search input*/}
                            <div className="search-container position-relative d-none">
                                <input id="search" placeholder="Tap to search" value={search} onChange={handleChange} />
                                <Search />
                            </div>
                        </div>
                        {event ? (
                            <div className="auditoriums-archive-container">
                                <ColoredScrollbars>
                                    {dataSource.auditoriums.map((auditorium) => {
                                        let hasVideoTimeslots = false;
                                        auditorium.dailyProgram.forEach((dayProgram) => {
                                            dayProgram.program.forEach((timeslot) => {
                                                if (
                                                    timeslot.video ||
                                                    timeslot.session?.records?.length ||
                                                    (timeslot.session?.link &&
                                                        timeslot.session.type === SESSION_TYPES.recorded) ||
                                                    ((timeslot.streamingEngine === ZOOM ||
                                                        timeslot.streamingEngine === ETX_LIVE_SESSION) &&
                                                        timeslot.streamFinished)
                                                ) {
                                                    hasVideoTimeslots = true;
                                                }
                                            });
                                        });

                                        if (!hasVideoTimeslots) {
                                            return null;
                                        }

                                        return (
                                            <div className="single-auditorium-archive-container" key={auditorium._id}>
                                                <h4 className="auditorium-name">
                                                    <span>{auditorium.name}</span>
                                                    <span className="break">|</span>
                                                    {auditorium.dailyProgram.length > 1 ? (
                                                        <span className="dates">
                                                            {getDay(auditorium.dailyProgram[0].date)}
                                                            {getProgramDayString(
                                                                auditorium.dailyProgram[
                                                                    auditorium.dailyProgram.length - 1
                                                                ].date,
                                                                true
                                                            )}
                                                        </span>
                                                    ) : (
                                                        <span className="dates">
                                                            {getProgramDayString(auditorium.dailyProgram[0].date, true)}
                                                        </span>
                                                    )}
                                                </h4>
                                                <Grid container className="smallContainer">
                                                    {auditorium.dailyProgram.map((dayProgram) => (
                                                        <Fragment key={dayProgram._id}>
                                                            {dayProgram.program.map((timeslot) => {
                                                                if (
                                                                    !timeslot.video &&
                                                                    !(
                                                                        timeslot.session?.link &&
                                                                        timeslot.session.type === SESSION_TYPES.recorded
                                                                    ) &&
                                                                    !timeslot.session?.records?.length &&
                                                                    !(
                                                                        timeslot.streamingEngine === ZOOM ||
                                                                        timeslot.streamingEngine === ETX_LIVE_SESSION
                                                                    )
                                                                ) {
                                                                    return null;
                                                                }

                                                                const {thumbnail, video} = timeslot;

                                                                const defaultImg =
                                                                    recordingsThumbnails[whiteLabel] ||
                                                                    recordingsThumbnails.main;

                                                                let imgSrc = defaultImg;
                                                                if (
                                                                    video.length &&
                                                                    timeslot.streamingEngine === YOUTUBE
                                                                )
                                                                    imgSrc = `https://img.youtube.com/vi/${getVideoIdFromYoutubeUrl(
                                                                        video
                                                                    )}/mqdefault.jpg`;

                                                                if (thumbnail) {
                                                                    imgSrc = `${eventFolder}${eventId}/${thumbnail}`;
                                                                }

                                                                let timeslotLink = generatePath(
                                                                    building
                                                                        ? RouterConfig.event.village
                                                                              .buildingAuditoriumArchiveSingleVideo
                                                                        : RouterConfig.event
                                                                              .auditoriumArhiveSingleVideo,
                                                                    {
                                                                        eventSlug: event.slug,
                                                                        buildingId,
                                                                        timeslotId: timeslot._id
                                                                    }
                                                                );

                                                                return (
                                                                    <Grid
                                                                        item
                                                                        xs={6}
                                                                        md={4}
                                                                        lg={3}
                                                                        key={timeslot._id}
                                                                        className="program-entry"
                                                                    >
                                                                        <div className="video-preview-container">
                                                                            <Link
                                                                                to={{
                                                                                    pathname: timeslotLink
                                                                                }}
                                                                            >
                                                                                <div className="clickable-element">
                                                                                    <div className="image-preview-video">
                                                                                        <img
                                                                                            src={imgSrc}
                                                                                            alt="preview video"
                                                                                        />
                                                                                        {/*remove d-none to show duration*/}
                                                                                        <div className="video-duration d-none">
                                                                                            <span>40:16</span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="play-button"
                                                                                            style={{
                                                                                                backgroundImage: `url('${PlayIcon}')`
                                                                                            }}
                                                                                        ></div>
                                                                                    </div>
                                                                                </div>
                                                                            </Link>
                                                                        </div>
                                                                        <div className="video-details-container">
                                                                            <div className="speakers-container">
                                                                                {timeslot?.speakersExtended?.length
                                                                                    ? timeslot.speakersExtended.map(
                                                                                          (speaker, index) => {
                                                                                              return (
                                                                                                  <span
                                                                                                      className="speaker"
                                                                                                      key={speaker._id}
                                                                                                  >
                                                                                                      {speaker.fullName}
                                                                                                      {index !==
                                                                                                      timeslot
                                                                                                          .speakersExtended
                                                                                                          .length -
                                                                                                          1
                                                                                                          ? ', '
                                                                                                          : null}
                                                                                                  </span>
                                                                                              );
                                                                                          }
                                                                                      )
                                                                                    : null}
                                                                            </div>
                                                                            <Link
                                                                                to={{
                                                                                    pathname: timeslotLink
                                                                                }}
                                                                            >
                                                                                {timeslot.title}
                                                                            </Link>
                                                                            <div className="program-date-hash">
                                                                                <span className="date">
                                                                                    {getProgramDayString(
                                                                                        dayProgram.date
                                                                                    )}
                                                                                </span>
                                                                                {/*remove d-none to show hashtgas*/}
                                                                                <span className="hashtag d-none">
                                                                                    #test #123 #video
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </Grid>
                                                                );
                                                            })}
                                                        </Fragment>
                                                    ))}
                                                </Grid>
                                            </div>
                                        );
                                    })}
                                </ColoredScrollbars>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </AuditoriumArchiveWrapper>
    );
}

export default AuditoriumArhive;
