import React from 'react';

const LiveWallIcon = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8 16L8 18L15 18C16.1 18 17 17.1 17 16L17 7L15 7L15 16L8 16Z" fill="#9C231F" />
        <path
            d="M3 4L3 12C3 13.1 3.9 14 5 14L11 14C12.1 14 13 13.1 13 12L13 4C13 2.9 12.1 2 11 2L5 2C3.9 2 3 2.9 3 4ZM11 4L11 12L7 12L7 4L11 4Z"
            fill="#B31C1A"
        />
        <path d="M12 20L12 22L19 22C20.1 22 21 21.1 21 20L21 11L19 11L19 20L12 20Z" fill="#B31C1A" />
    </svg>
);

export default LiveWallIcon;
