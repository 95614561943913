import React from 'react';
import PropTypes from 'prop-types';

import './AuditoriumPageContainer.scss';

export function AuditoriumPageContainer({children}) {
    return (
        <div id="js-auditorium-page" className="auditorium-page mobile-page-container">
            {children}
        </div>
    );
}

AuditoriumPageContainer.propTypes = {
    children: PropTypes.node,
};
