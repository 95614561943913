import Api from './apiHandler';

export const personalAgenda = () => ({
    request: async (eventId) =>
        await Api.request({
            method: 'get',
            url: `/personal-agenda/${eventId}`,
        }),

    addProgram: async (eventId, auditoriumId, displayProgramId, programId) =>
        await Api.request({
            method: 'post',
            url: '/personal-agenda/add-from-program',
            payload: {eventId, auditoriumId, displayProgramId, programId},
        }),

    remove: async (agendaId, eventId) =>
        await Api.request({
            method: 'delete',
            url: `/personal-agenda/${agendaId}/${eventId}`,
        }),

    addCustom: async (data, eventId) =>
        await Api.request({
            method: 'post',
            url: `/personal-agenda/custom/${eventId}`,
            payload: data,
        }),

    cancelMeeting: async (agendaId) => await Api.request({method: 'post', url: `/personal-agenda/${agendaId}/cancel`}),

    confirmMeeting: async (agendaId) => await Api.request({method: 'post', url: `/personal-agenda/${agendaId}/accept`}),

    createGroupChat: async (chatData) => await Api.request({method: 'post', url: '/group-chat', payload: chatData}),

    addChatToAgenda: async (groupChatId, agendaId) =>
        await Api.request({method: 'post', url: `/personal-agenda/${agendaId}/add-chat`, payload: {groupChatId}}),

    sendVideoConferenceMessage: async (groupChatId) =>
        await Api.request({
            method: 'post',
            url: `/group-chat/${groupChatId}/messages`,
            payload: {text: "I've set up a Video Meeting room. Please click here to join it."},
        }),
});

export const userStatuses = () => ({
    requestOnlineUsers: async () =>
        await Api.request({
            url: '/event/get-online-users',
        }),

    requestBusyUsers: async () =>
        await Api.request({
            url: '/event/get-busy-users',
        }),
});

const eventStatisticsRequest = async (eventId, field = '') =>
    await Api.request({
        method: 'patch',
        url: `/event/${eventId}/eventStatistic`,
        payload: {field},
    });

export const eventStatistics = (eventId) => ({
    oneToOneCalls: () => eventStatisticsRequest(eventId, 'oneToOneCallsCount'),
    groupCalls: () => eventStatisticsRequest(eventId, 'groupCallsCount'),
});
