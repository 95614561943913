import * as actionTypes from '../../../actionTypes/organizerActionTypes/auditorium';
import {Api} from 'Api';
import {setUpdatedAudtitoriumInEvent, setUpdatedAudtitoriumInEventHub, getEvent} from '../../eventActions';

export const getAuditoriumPolls = (auditoriumId) => async (dispatch, getState) => {
    const {
        event: {eventId},
        organizer: {
            auditoriumSetup: {loading},
        },
    } = getState();

    dispatch({type: actionTypes.GET_AUDITORIUM_POLLS_START});

    try {
        const response = await Api.request({
            url: `/event/${eventId}/polls/auditoriums/${auditoriumId}`,
            method: 'get',
            loading,
        });

        dispatch({
            type: actionTypes.GET_AUDITORIUM_POLLS_SUCCESS,
            payload: response,
        });
    } catch (error) {
        dispatch({type: actionTypes.GET_AUDITORIUM_POLLS_FAIL});
    }
};

const updateAuditoriumStart = () => ({
    type: actionTypes.UPDATE_AUDITORIUM_DETAILS_START,
});

const updateAuditoriumSuccess = (survey = {}) => ({
    type: actionTypes.UPDATE_AUDITORIUM_DETAILS_SUCCESS,
    payload: survey,
});

const updateAuditoriumFail = (error) => ({
    type: actionTypes.UPDATE_AUDITORIUM_DETAILS_FAIL,
    payload: error,
});

export const createAuditorium =
    ({editedBuildingId, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        let data = {};
        if (editedBuildingId) {
            data.buildingId = editedBuildingId;
        }

        dispatch(createAuditoriumStart());

        try {
            await Api.request({
                method: 'post',
                url: `/event/${eventId}/auditoriums`,
                payload: data,
            });

            dispatch(createAuditoriumSuccess());
            dispatch(getEvent(eventId));
            callback && callback();
        } catch (error) {
            dispatch(createAuditoriumFail());
        }
    };

const createAuditoriumStart = () => ({
    type: actionTypes.CREATE_AUDITORIUM_START,
});

const createAuditoriumSuccess = () => ({
    type: actionTypes.CREATE_AUDITORIUM_SUCCESS,
});

const createAuditoriumFail = () => ({
    type: actionTypes.CREATE_AUDITORIUM_FAIL,
});

export const updateAuditorium = (payload, eventId, auditoriumId, callback) => {
    return async (dispatch, getState) => {
        const {
            event: {
                data: {hasVillage},
            },
        } = getState();
        dispatch(updateAuditoriumStart());

        try {
            const {auditorium} = await Api.request({
                method: 'put',
                url: `/event/${eventId}/auditoriums/${auditoriumId}`,
                payload,
            });
            callback && callback();
            dispatch(updateAuditoriumSuccess());
            if (!hasVillage) {
                dispatch(setUpdatedAudtitoriumInEvent(auditorium));
            } else {
                dispatch(setUpdatedAudtitoriumInEventHub(auditorium));
            }
        } catch (error) {
            dispatch(updateAuditoriumFail(error));
        }
    };
};

export const deleteAuditorium = (auditoriumId, callback) => async (dispatch, getState) => {
    const {
        event: {eventId},
    } = getState();
    dispatch(deleteAuditoriumStart());

    try {
        await Api.request({
            method: 'delete',
            url: `/event/${eventId}/auditoriums/${auditoriumId}`,
        });

        dispatch(deleteAuditoriumSuccess());
        dispatch(getEvent(eventId));

        callback && callback();
    } catch (error) {
        dispatch(deleteAuditoriumFail(error));
    }
};

const deleteAuditoriumStart = () => ({
    type: actionTypes.DELETE_AUDITORIUM_START,
});

const deleteAuditoriumSuccess = () => ({
    type: actionTypes.DELETE_AUDITORIUM_SUCCESS,
});

const deleteAuditoriumFail = () => ({
    type: actionTypes.DELETE_AUDITORIUM_FAIL,
});

export const updateAuditoriumThumbnail =
    ({auditoriumId, payload, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();
        dispatch(updateAuditoriumThumbnailStart());

        const requestData = {
            method: 'post',
            url: `/event/${eventId}/auditoriums/${auditoriumId}/image`,
        };

        if (payload) {
            requestData.payload = payload;
            requestData.headers = {
                'content-type': 'multipart/form-data',
            };
        }

        try {
            await Api.request(requestData);

            dispatch(updateAuditoriumThumbnailSuccess());
            dispatch(getEvent(eventId));

            callback && callback();
        } catch (error) {
            dispatch(updateAuditoriumThumbnailFail(error));
        }
    };

const updateAuditoriumThumbnailStart = () => ({
    type: actionTypes.UPDATE_AUDITORIUM_THUMBNAIL_START,
});

const updateAuditoriumThumbnailSuccess = () => ({
    type: actionTypes.UPDATE_AUDITORIUM_THUMBNAIL_SUCCESS,
});

const updateAuditoriumThumbnailFail = () => ({
    type: actionTypes.UPDATE_AUDITORIUM_THUMBNAIL_FAIL,
});

export const deleteProgramTimeslot =
    ({date, auditoriumId, timeslot, callback}) =>
    async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        dispatch(deleteProgramTimeslotRequest());

        try {
            await Api.request({
                method: 'delete',
                url: `event/${eventId}/auditoriums/${auditoriumId}/display-program/${timeslot}`,
                payload: {date},
            });

            dispatch(deleteProgramTimeslotSuccess());
            callback && callback();
        } catch (error) {
            dispatch(deleteProgramTimeslotFail());
        }
    };

const deleteProgramTimeslotRequest = () => ({
    type: actionTypes.DELETE_PROGRAM_TIMESLOT_REQUEST,
});

const deleteProgramTimeslotSuccess = () => ({
    type: actionTypes.DELETE_PROGRAM_TIMESLOT_SUCCESS,
});

const deleteProgramTimeslotFail = () => ({
    type: actionTypes.DELETE_PROGRAM_TIMESLOT_FAIL,
});
