import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@material-ui/core';

import {Api} from 'Api';
import * as actions from 'store/actions';

import './DeleteMyAccount.scss';

class DeleteMyAccount extends React.Component {
    componentDidMount() {
        this.checkCanDeleteAccount();
        this.setLanguagesData();
    }

    state = {
        userDeleted: false,
        canDeleteAccount: true,
        translation: null
    };

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation
        });
    };

    checkCanDeleteAccount = () => {
        const {user} = this.props;

        let canDeleteAccount = true;
        user.eventRoles.forEach((eventRole) => {
            if (eventRole.roles.includes('organizer')) {
                canDeleteAccount = false;
            }
        });

        this.setState({canDeleteAccount: canDeleteAccount});
    };

    handleDeleteMyAccount = async () => {
        try {
            await Api.request({
                method: 'delete',
                url: `/users/${this.props.user._id}`
            });

            this.setState({userDeleted: true});
        } catch (error) {}
    };

    closeDialog = () => {
        if (this.state.userDeleted) {
            this.props.onLogoutUser();
        } else {
            this.props.closeDialog();
        }
    };

    render() {
        const {mobile, isRtlLanguage} = this.props;
        const {translation} = this.state;
        return (
            <Dialog
                open={this.props.opened}
                onClose={this.closeDialog}
                aria-labelledby="form-dialog-title"
                className={classNames('delete-account-dialog', {
                    'dialog-mobile': mobile
                })}
            >
                <DialogTitle id="form-dialog-title">{translation?.myAccountPage.deleteTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText className={classNames('delete-account-dialog__text', {isRtl: isRtlLanguage})}>
                        {this.state.canDeleteAccount ? (
                            <span>
                                {this.state.userDeleted
                                    ? translation?.myAccountPage.deleteSuccess
                                    : translation?.myAccountPage.deleteConfirm}
                            </span>
                        ) : (
                            <span>{translation?.myAccountPage.deleteError}</span>
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions className={classNames('delete-account-dialog__buttons', {isRtl: isRtlLanguage})}>
                    <Button onClick={this.closeDialog} color="primary">
                        {translation?.generalText.close}
                    </Button>
                    {!this.state.userDeleted && this.state.canDeleteAccount && (
                        <Button variant="contained" color="secondary" onClick={this.handleDeleteMyAccount}>
                            {translation?.myAccountPage.deleteConfirm}
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(
    (state) => ({
        user: state.user.data,
        languages: state.languages,
        isRtlLanguage: state.languages.isRtlLanguage
    }),
    {
        onLogoutUser: actions.logoutUser
    }
)(DeleteMyAccount);
