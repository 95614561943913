export const transformFiltersToApply = (filters) => {
    const appliedFilters = {};

    filters
        .filter((item) => !item.isCountry)
        .forEach((filter) => {
            if (filter.formId in appliedFilters) {
                appliedFilters[filter.formId].customFields[filter._id] = [
                    ...filter.options.filter((option) => !!option.isChecked).map((option) => option._id),
                ];
            } else {
                appliedFilters[filter.formId] = {
                    customFields: {},
                };

                appliedFilters[filter.formId].customFields[filter._id] = filter.options
                    .filter((option) => !!option.isChecked)
                    .map((option) => option._id);
            }
        });

    const countries =
        filters
            .find((item) => item.isCountry)
            ?.options.filter((option) => !!option.isChecked)
            .map((option) => option.value) || [];

    return {countries, registrationForms: appliedFilters};
};
