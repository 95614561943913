import {useEventListener} from 'ahooks';
import {COOKIES_ACCEPTED_EVENT, COOKIES_ACCEPTED_KEY} from 'constants/shared';
import {useState} from 'react';

/**
 * @returns {boolean} cookiesAccepted
 */
const useCookiesAccept = () => {
    const [cookiesAccepted, setCookiesAccepted] = useState(JSON.parse(localStorage.getItem(COOKIES_ACCEPTED_KEY)));

    const handleEvent = (event) => {
        const cookie = JSON.parse(localStorage.getItem(COOKIES_ACCEPTED_KEY));
        setCookiesAccepted(cookie);
    };

    useEventListener(COOKIES_ACCEPTED_EVENT, handleEvent, {target: document});

    return cookiesAccepted;
};

export default useCookiesAccept;
