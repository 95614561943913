// main
import React from 'react';
import {whiteLabelsList} from 'config';
import {ReactComponent as MainFullLogo} from 'Images/pages/platform-homepage/animations/full-logo.svg';
import MainLogoWhite from 'Images/branding/logo-white.png';
import MianLogoColored from 'Images/branding/logo-half-white.png';
import MainSecondMainImage from 'Images/pages/platform-homepage/fullscreen-top.jpg';
import TopMainImage from 'Images/pages/platform-homepage/fullscreen-top.jpg';
import TopMainImageMobile from 'Images/pages/platform-homepage/fullscreen-top-mobile.jpg';
import TopMainImageTablet from 'Images/pages/platform-homepage/fullscreen-top-tablet.jpg';
import MainFooterImage from 'Images/pages/platform-homepage/event10x-footer-image.svg';

import MainReactangle from 'Images/pages/platform-homepage/animations/rectangle/main-rectangle.svg';
import SubReactangle from 'Images/pages/platform-homepage/animations/rectangle/sub-rectangle.svg';

// added
import AddedLogoWhite from 'Images/branding/added/added-logo-white-2.svg';
import {ReactComponent as AddedFullLogo} from 'Images/branding/added/added-full-logo.svg';
import AddedSecondMainImage from 'Images/pages/platform-homepage/added/fullscreen-top.jpg';

// SCA
import ScaLogo from 'Images/branding/sca/sca-logo.svg';
import ScaSecondMainImage from 'Images/pages/platform-homepage/sca/sca-fullscreen-top.jpg';
import {ReactComponent as ScaFullLogo} from 'Images/branding/sca/sca-full-logo.svg';
import ScaMainReactangle from 'Images/pages/platform-homepage/animations/rectangle/sca/main-rectangle.svg';
import ScaSubReactangle from 'Images/pages/platform-homepage/animations/rectangle/sca/sub-rectangle.svg';

// UNCTAD
import UnctadSecondMainImage from 'Images/pages/platform-homepage/unctad/unctad-fullscreen-top.jpg';

import UnctadLogoColored from 'Images/branding/unctad/unctad.png';
import {ReactComponent as UnctadSecondMainImageText} from 'Images/branding/unctad/title-text.svg';

import UnctadFooterImage from 'Images/pages/platform-homepage/unctad/unctad-footer-image.jpg';
import UnctadMainReactangle from 'Images/pages/platform-homepage/animations/rectangle/unctad/main-rectangle.svg';
import UnctadSubReactangle from 'Images/pages/platform-homepage/animations/rectangle/unctad/sub-rectangle.svg';

// EGYPT PAVILION
import EgyptSecondMainImage from 'Images/pages/platform-homepage/egypt/egypt-pavillion-fullscreen-top.jpg';

import {ReactComponent as EgyptSecondMainImageText} from 'Images/branding/egypt/title-text.svg';

import EgyptLogoColored from 'Images/branding/egypt/egypt-pavilion-logo.svg';
import EgyptMainReactangle from 'Images/pages/platform-homepage/animations/rectangle/egypt/main-rectangle.svg';
import EgyptSubReactangle from 'Images/pages/platform-homepage/animations/rectangle/egypt/sub-rectangle.svg';

const basicMobileRectangle = {
    main: MainReactangle,
    sub: SubReactangle,
};

export const mobileRectangle = {
    main: basicMobileRectangle,
    added: basicMobileRectangle,
    careers: basicMobileRectangle,
    sca: {
        sub: ScaMainReactangle,
        main: ScaSubReactangle,
    },
    unctad: {
        main: UnctadMainReactangle,
        sub: UnctadSubReactangle,
    },
    [whiteLabelsList.egyptPavilion]: {
        main: EgyptMainReactangle,
        sub: EgyptSubReactangle,
    },
};
const createLogoConfig = (logo, logoStyle = {}, wrapperStyle = {}) => {
    return {logo, logoStyle, wrapperStyle};
};

const BasicStyle = {
    SubLogo: createLogoConfig(MainFullLogo),
    SecondFrameLogo: createLogoConfig(MianLogoColored, {marginTop: '100px'}),
    FirstFrameLogo: createLogoConfig(MainLogoWhite, {top: '100px'}),
    logoContainer: {},

    SecondMainImage: MainSecondMainImage,
    TopImage: TopMainImage,
    TopImageMobile: TopMainImageMobile,
    TopImageTablet: TopMainImageTablet,

    gradientClass: 'image-gradient-overlay-purple',

    title: {
        text: 'Next generation',
        imgCoverStyle: {top: '-20px'},
        imgStyle: {transform: 'scale(0.8,1)', fill: 'var(--theme-primary-color)'},
        titleCoverStyle: {marginBottom: '15px'},
    },

    secondTitle: {
        text: 'online events',
        imgCoverStyle: {top: '7px'},
        imgStyle: {transform: 'scale(0.85, 1.7)', fill: 'var(--theme-secondary-color)'},
    },

    Banner: {
        show: true,
        firstParagraph: () => (
            <p>
                You are now inside EVENTS <span className="red">1</span>0<span className="red">X</span> Virtual Venue,
            </p>
        ),
        secondParagraph: () => <span>To visit the Website, please click </span>,
        linkClass: 'red',
    },
    startBtn: {},
    onlyFooter: false,
    FooterImage: MainFooterImage,
};

export const homepagePlatformConfig = {
    main: BasicStyle,
    added: {
        ...BasicStyle,
        SubLogo: createLogoConfig(AddedFullLogo),
        SecondFrameLogo: createLogoConfig(AddedLogoWhite, {marginTop: '15px'}),
        FirstFrameLogo: createLogoConfig(AddedLogoWhite, {top: '15px'}),
        SecondMainImage: AddedSecondMainImage,
        TopImage: AddedSecondMainImage,
        TopImageMobile: AddedSecondMainImage,
        TopImageTablet: AddedSecondMainImage,
        gradientClass: 'image-gradient-overlay-brown',
        title: {
            text: 'Abu Dhabi Department of Economic Development',
            textStyle: {fontSize: '40px'},
            imgStyle: {...BasicStyle.title.imgStyle, transform: 'scale(1.2,1)'},
            imgCoverStyle: {top: '-30px'},
            titleCoverStyle: {marginBottom: '35px'},
        },
        secondTitle: {
            text: 'Digital Platform',
            textStyle: {fontSize: '40px'},
            imgStyle: {...BasicStyle.secondTitle.imgStyle, transform: 'scale(0.7,1.2)'},
            imgCoverStyle: {top: '-15px'},
        },
        onlyFooter: true,
        Banner: {show: false},
    },
    careers: BasicStyle,
    sca: {
        ...BasicStyle,

        logoContainer: {style: {}},

        SubLogo: createLogoConfig(
            ScaFullLogo,
            {},
            {top: 'calc(100% + 250px)', transition: '10s', transform: 'scale(2)  translateX(-10%)', left: 'inherit'}
        ),
        SecondFrameLogo: createLogoConfig(ScaLogo, {marginTop: '15px'}),
        FirstFrameLogo: createLogoConfig(ScaLogo, {top: '15px'}),

        SecondMainImage: ScaSecondMainImage,

        TopImage: ScaSecondMainImage,
        TopImageMobile: ScaSecondMainImage,
        TopImageTablet: ScaSecondMainImage,

        startBtn: {wraperStyle: {bottom: '-50px', background: '#4BAF5C', padding: '1px'}},

        title: {
            text: 'Saudi Contractors Authority',
            textStyle: {fontSize: '55px'},
            imgStyle: {fill: '#005850'},
            imgCoverStyle: {top: '-25px'},
            titleCoverStyle: {marginBottom: '25px'},
        },
        secondTitle: {
            text: 'Building Future with Confidence',
            textStyle: {fontSize: '38px'},
            imgStyle: {fill: '#4BAF5C'},
            imgCoverStyle: {top: '-15px'},
        },
        onlyFooter: true,
        Banner: {
            show: true,
            firstParagraph: (translation) => <p>{translation}</p>,
            secondParagraph: (translation) => <span>{translation}</span>,
            linkClass: 'green',
        },
    },
    unctad: {
        ...BasicStyle,
        SubLogo: createLogoConfig(UnctadSecondMainImageText, {}, {width: '550px'}),
        SecondFrameLogo: createLogoConfig(UnctadLogoColored, {width: '300px', marginBottom: '100px'}),
        FirstFrameLogo: createLogoConfig(UnctadLogoColored, {top: '0', width: '300px'}),
        SecondMainImage: UnctadSecondMainImage,
        TopImage: UnctadSecondMainImage,
        TopImageMobile: UnctadSecondMainImage,
        TopImageTablet: UnctadSecondMainImage,
        title: {
            text: 'World Investment Forum',
            textStyle: {fontSize: '55px'},
            imgCoverStyle: {top: '-25px'},
            titleCoverStyle: {marginBottom: '25px'},
        },
        secondTitle: {
            text: 'Investing in Sustainable Development',
            textStyle: {fontSize: '34px'},
            imgCoverStyle: {top: '-15px'},
        },
        onlyFooter: true,
        Banner: {
            show: true,
            firstParagraph: () => <p>You are now inside WIF Virtual Venue,</p>,
            secondParagraph: () => <span>To visit the World Investment Forum Website, please click </span>,
            linkClass: 'red',
        },
        FooterImage: UnctadFooterImage,
    },
    [whiteLabelsList.egyptPavilion]: {
        ...BasicStyle,
        SubLogo: createLogoConfig(EgyptSecondMainImageText, {}, {width: '500px'}),
        SecondFrameLogo: createLogoConfig(EgyptLogoColored, {width: '450px'}),
        FirstFrameLogo: createLogoConfig(EgyptLogoColored, {top: '0px', width: '450px'}),
        SecondMainImage: EgyptSecondMainImage,
        TopImage: EgyptSecondMainImage,
        TopImageMobile: EgyptSecondMainImage,
        TopImageTablet: EgyptSecondMainImage,
        title: {
            text: 'Egypt Pavilion in Expo 2020',
            textStyle: {fontSize: '55px'},
            imgCoverStyle: {top: '-25px'},
            titleCoverStyle: {marginBottom: '25px'},
        },
        secondTitle: {
            text: 'Legacy Empowering the Future',
            textStyle: {fontSize: '34px'},
            imgCoverStyle: {top: '-15px'},
        },
        onlyFooter: true,
        Banner: {
            show: true,
            firstParagraph: () => <p>You are now inside Egypt Expo Virtual Venue,</p>,
            secondParagraph: () => <span>To visit the Website, please click </span>,
            linkClass: 'red',
        },
        FooterImage: MainFooterImage,
    },
};

export const siteConfig = {
    main: 'https://www.events10x.com/',
    sca: 'https://sca.sa/',
    unctad: 'https://worldinvestmentforum.unctad.org/#/ms-1/2',
    [whiteLabelsList.egyptPavilion]: 'https://egyptexpo.eg/',
};
