import {isMobile} from 'react-device-detect';
import {generatePath} from 'react-router';
import {RouterConfig} from '../../routerConfig';
import _ from 'lodash';
export const handleRedirect = ({user, event, eventSlug, URL, query, history, onCloseTopNav}) => {
    const registerAsExhibitor = query.get('registerAsExhibitor') === 'true';
    const registerAsParticipant = query.get('registerAsParticipant') === 'true';
    const iframe = query.get('iframe') === 'true';

    const welcomeVideoEnabled = event?.welcomeVideoEnabled;
    const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);
    const displayWelcomeVideo = userRolesInEvent?.event?.showWelcomeVideo;
    const isNotAdminUser = !user.isMasterAdmin && !user.isOrganizer;

    // on platform homepage, if he has a role at an event I redirect him to that event based on his role
    // if he doesn't have any role at any event, I redirect him to create event
    if (URL === '/') {
        const totalEventsUserRegistered = user.eventRoles.length;

        if (user.isMasterAdmin) {
            history.push('/dashboard');
        } else if (totalEventsUserRegistered === 0) {
            history.push('create-event');
        } else {
            redirectToLastEvent(user, history, onCloseTopNav);
        }
    }

    const userHasRoleInEvent = user.eventRoles.some((roles) => roles.event.slug === eventSlug);
    // if he is on an event homepage, and has a role at that event, I redirect him to the event
    // had to take into account that the page can end with '/' or not
    if (URL === `/event/${eventSlug}` || URL === `/event/${eventSlug}/`) {
        if (userHasRoleInEvent) {
            redirectToEventByRole(eventSlug, user, history);
        }
        // if he doesn't have any role, It means that he is just a user that wants to register at the event, read the presentation etc.
        // I don't redirect him anywhere
    }

    if (
        URL === `/event/${eventSlug}/participant-registration` ||
        URL === `/event/${eventSlug}/participant-registration/` ||
        URL === `/event/${eventSlug}/exhibitor-registration/` ||
        URL === `/event/${eventSlug}/exhibitor-registration/`
    ) {
        if (userHasRoleInEvent) {
            redirectToEventByRole(eventSlug, user, history);
        }
        // if he doesn't have any role, It means that he is just a user that wants to finishe the registration at the event
        // I don't redirect him anywhere
    }

    // if the user is on a log in page (platform or user level)
    if (URL.includes('/login') || URL.includes('/register')) {
        // he logs in from an event page (/event/eventSlug/login)
        // he creates an account from an event page (/event/eventSlug/register)

        if (eventSlug) {
            const userHasRoleInEvent = user.eventRoles.some((roles) => roles.event.slug === eventSlug);
            if (!userHasRoleInEvent) {
                // if he doesn't have any role, it means he is trying to register at this event
                if (registerAsExhibitor) {
                    history.push(`/event/${eventSlug}/exhibitor-registration`);
                } else if (registerAsParticipant) {
                    history.push(`/event/${eventSlug}/participant-registration${iframe ? '?iframe=true' : ''}`);
                } else {
                    history.push(`/event/${eventSlug}`);
                }
            } else {
                redirectToEventByRole(eventSlug, user, history);
            }
        } else {
            // he logs in at platform level (I don't have an eventSlug in URL);
            // he registers at platform level, it will get redirected to /create-event because he doesn't have any roles;
            const totalEventsUserRegistered = user.eventRoles.length;

            if (welcomeVideoEnabled && displayWelcomeVideo && isNotAdminUser) {
                history.push(`/event/${eventSlug}/welcome`);
            } else {
                if (user.isMasterAdmin) {
                    history.push('/dashboard');
                } else if (totalEventsUserRegistered === 0) {
                    history.push('create-event');
                } else {
                    redirectToLastEvent(user, history, onCloseTopNav);
                }
            }
        }
    }
};

export const redirectToEventByRole = (eventSlug, user, history) => {
    const userRolesInEvent = user?.eventRoles?.find((role) => role.event.slug === eventSlug);

    // all the users that login from mobile, are redirected to lobby
    if (isMobile) {
        history.push(`/event/${eventSlug}/lobby`);
    } else {
        if (userRolesInEvent?.roles?.includes('organizer')) {
            history.push(`/event/${eventSlug}/dashboard`);
        } else if (userRolesInEvent?.roles?.includes('exhibitor')) {
            history.push(`/event/${eventSlug}/exhibitor-dashboard`);
        } else if (userRolesInEvent?.roles?.includes('exhibitorRep')) {
            // this.props.history.push(`/event/${eventSlug}/exhibitor-booth-view`);
            history.push(`/event/${eventSlug}/lobby`);
        } else {
            history.push(`/event/${eventSlug}/lobby`);
        }
    }
};

/**
 *
 * @param {object} user
 * @param {object} history
 * @param {function} onCloseTopNav
 * @param {object} [eventData]
 */
export const redirectToLastEvent = (user, history, onCloseTopNav, eventData) => {
    const totalEventsUserRegistered = user?.eventRoles?.length;
    const lastEventRegistered = user?.eventRoles[totalEventsUserRegistered - 1];
    const eventRoles = lastEventRegistered?.roles;
    const eventSlug = lastEventRegistered?.event?.slug;
    const displayWelcomeVideo = lastEventRegistered?.event?.showWelcomeVideo;
    const isNotAdminUser = !user?.isMasterAdmin && !user?.isOrganizer;
    const welcomeVideoEnabled = lastEventRegistered?.event?.welcomeVideoEnabled;
    const welcomeVideoExist =
        lastEventRegistered?.event?.welcomeVideo || lastEventRegistered?.event?.welcomeVideoYoutube;

    if (!_.isEmpty(eventData)) {
        history.push(generatePath(RouterConfig.event.lobby, {eventSlug: eventData?.slug}));
        return;
    }

    // all the users that login from mobile, are redirected to lobby
    if (!eventRoles) return;
    // all the users that login from mobile, are redirected to lobby
    if (displayWelcomeVideo && isNotAdminUser && welcomeVideoExist && welcomeVideoEnabled) {
        history.push(generatePath(RouterConfig.event.welcome, {eventSlug}));
    } else {
        // all the users that login from mobile, are redirected to lobby
        if (isMobile) {
            history.push(generatePath(RouterConfig.event.lobby, {eventSlug}));
        } else {
            if (eventRoles.includes('organizer')) {
                onCloseTopNav();
                history.push(generatePath(RouterConfig.organizerDashboard.root, {eventSlug}));
            } else if (eventRoles.includes('exhibitor')) {
                history.push(generatePath(RouterConfig.event.exhibitorDashboard, {eventSlug}));
            } else if (eventRoles.includes('exhibitorRep')) {
                history.push(generatePath(RouterConfig.event.lobby, {eventSlug}));
            } else {
                history.push(generatePath(RouterConfig.event.lobby, {eventSlug}));
            }
        }
    }
};

export const handleLogoutRedirect = (URL, history) => {
    if (URL === '/create-event' || URL === '/create-event/') {
        history.push('/');
    }
};

export const getUserLastEventId = (user) => {
    const totalEventsUserRegistered = user?.eventRoles?.length;
    const lastEventRegistered = user?.eventRoles[totalEventsUserRegistered - 1];
    const eventId = lastEventRegistered?.event?._id;

    return eventId;
};

export const getUserEventIdBySlug = (user, eventSlug) => {
    if (!user?.eventRoles?.length) return;
    const {
        event: {_id: eventId},
    } = user.eventRoles.find(({event}) => event.slug === eventSlug);

    return eventId;
};
