export const RouterConfig = {
    root: '/',
    viewAll: '/view-all',
    notFound: '*',
    redirectedError: '/error',

    // authentication
    login: '/login',
    register: '/register',
    createEvent: '/create-event',
    passwordRecovery: '/password-recovery',
    newsletterUnsubscribe: '/unsubscribe',

    // public pages
    public: {
        eventCustomPrivacyPolicy: '/event/:eventSlug/event-privacy-policy',
        eventIframeRegistration: '/event/:eventSlug/iframe-registration',
        eventTermsAndConditions: '/event/:eventSlug/terms-and-conditions/:language?',
        eventPrivacyPolicy: '/event/:eventSlug/privacy-policy/:language?',
        eventCookies: '/event/:eventSlug/cookies/:language?',
        privacyPolicy: '/privacy-policy/:language?',
        cookies: '/cookies/:language?',
        termsAndConditions: '/terms-and-conditions/:language?',
        socialsPromotion: '/:eventId/social-media',
    },

    // event
    event: {
        homepage: '/event/:eventSlug',
        speakerSessionsList: '/event/:eventSlug/speaker-sessions-list',
        moderatorSessionsList: '/event/:eventSlug/moderator-sessions-list',
        interpreterSessionsList: '/event/:eventSlug/interpreter-sessions-list',
        login: '/event/:eventSlug/login',
        register: '/event/:eventSlug/register/:formId?/(package)?/:packageId?',
        registerSecondStep: '/event/:eventSlug/registrer-second-step/:formId',
        participantRegistration: '/event/:eventSlug/participant-registration/:formId?/(package)?/:packageId?',
        exhibitorRegistration: '/event/:eventSlug/exhibitor-registration/:formId?/(package)?/:packageId?',
        welcome: '/event/:eventSlug/welcome',
        lobby: '/event/:eventSlug/lobby',
        showfloor: '/event/:eventSlug/showfloor',
        auditorium: '/event/:eventSlug/auditorium',
        auditoriumArchive: '/event/:eventSlug/auditorium-archive',
        auditoriumArhiveSingleVideo: '/event/:eventSlug/auditorium-archive/timeslot/:timeslotId',
        archive: '/event/:eventSlug/event-archive',
        archiveVideo: '/event/:eventSlug/event-archive/event/:archivedEventId/:videoId',
        myAccount: '/event/:eventSlug/my-account',
        myEvents: '/event/:eventSlug/my-account/my-events',
        exhibitorDashboard: '/event/:eventSlug/exhibitor-dashboard',
        exhibitorBoothView: '/event/:eventSlug/exhibitor-booth-view/:exhibitorId?',
        village: {
            root: '/event/:eventSlug/village',
            buildingLobby: '/event/:eventSlug/village/building/:buildingId/lobby',
            buildingAuditorium: '/event/:eventSlug/village/building/:buildingId/auditorium',
            buildingShowfloor: '/event/:eventSlug/village/building/:buildingId/showfloor',
            buildingAuditoriumArchive: '/event/:eventSlug/village/building/:buildingId/auditorium-archive',
            buildingAuditoriumArchiveSingleVideo:
                '/event/:eventSlug/village/building/:buildingId/auditorium-archive/timeslot/:timeslotId',
        },
        zoomSessionDashboard: '/event/:eventSlug/zoom-session/:auditoriumId/:sessionId',
        etxSessionDashboard: '/event/:eventSlug/session/:auditoriumId/:sessionId',
    },

    // organizer dashboard
    organizerDashboard: {
        root: '/event/:eventSlug/dashboard',
        settings: '/event/:eventSlug/dashboard/settings',
        branding: '/event/:eventSlug/dashboard/branding',
        buildings: '/event/:eventSlug/dashboard/buildings',
        auditoriums: '/event/:eventSlug/dashboard/auditoriums',
        program: '/event/:eventSlug/dashboard/program',
        users: '/event/:eventSlug/dashboard/participants',
        booth: '/event/:eventSlug/dashboard/showfloor',
        boothView: '/event/:eventSlug/dashboard/showfloor/:exhibitorId',
        statistics: '/event/:eventSlug/dashboard/statistics',
        emails: '/event/:eventSlug/dashboard/newsletters',
        archive: '/event/:eventSlug/dashboard/archived-events',
        GDPR: '/event/:eventSlug/dashboard/event-gdpr',
        sessions: '/event/:eventSlug/dashboard/event-sessions',
        news: '/event/:eventSlug/dashboard/event-news',
        payment: '/event/:eventSlug/dashboard/event-payment',
        addAudience: '/event/:eventSlug/dashboard/newsletters/add-audience',
        editAudience: '/event/:eventSlug/dashboard/newsletters/edit-audience/:audienceId',
        customAudience: '/event/:eventSlug/dashboard/newsletters/edit-custom-audience/:audienceId',
        accessDenied: '/event/:eventSlug/dashboard/accessDenied',
    },

    // master admin
    masterAdmin: {
        dashboard: '/dashboard',
        organizerDetails: '/dashboard/organizer/:organizerId',
    },
};
