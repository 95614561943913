import React, {useEffect, useRef, useState} from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import moment from 'moment';
import Scrollbars from 'react-custom-scrollbars';
import {ValidatorForm} from 'react-material-ui-form-validator';

import {getChatMessages} from 'store/actions';

import {ReactComponent as Send} from 'Images/sendMessage.svg';

import './ChatModule.scss';

const Thumb = ({style, ...props}) => (
    <div className="grey-scroll" style={{...style, backgroundColor: `rgb(137,137,137)`, left: 0}} {...props} />
);

export const ChatModule = ({messages, sendMessage}) => {
    const dispatch = useDispatch();
    const {auditoriumId, sessionId} = useParams();
    const [inputValue, setInputValue] = useState('');
    const scrollbar = useRef(null);

    useEffect(() => {
        dispatch(getChatMessages({auditoriumId, timeslotId: sessionId}));
    }, [auditoriumId, dispatch, sessionId]);

    useEffect(() => {
        scrollbar.current.scrollToBottom();
    }, [messages]);

    const handleSendMessage = () => {
        if (!inputValue) return;
        sendMessage(inputValue);
        setInputValue('');
    };

    return (
        <div className="chat-module">
            <div className="module-container">
                <div className="module-header">
                    <span>SESSION CHAT</span>
                </div>

                <div className="module-body">
                    <div className="chat-container">
                        <Scrollbars
                            ref={scrollbar}
                            className="scrollbar"
                            renderThumbVertical={Thumb}
                            width="100%"
                            height="100%"
                        >
                            <div className="message-container">
                                {messages.length &&
                                    messages.map(({_id, text, createdAt, user}) => (
                                        <div className="message" key={_id}>
                                            <div className="message-avatar">{user?.first?.charAt(0)}</div>
                                            <div className="message-text">
                                                <h4 className="title">
                                                    {`${user?.first} ${user?.last}`}{' '}
                                                    <span className="role">{user?.role}</span>
                                                </h4>
                                                <span>{text}</span>
                                            </div>
                                            <div className="message-time">{moment(createdAt).format('hh:mm a')}</div>
                                        </div>
                                    ))}
                            </div>
                        </Scrollbars>
                    </div>
                    <ValidatorForm className="message-input" onSubmit={handleSendMessage}>
                        <input
                            id="newMessage"
                            placeholder="Type message here"
                            value={inputValue}
                            onChange={(e) => setInputValue(e.target.value)}
                        />

                        <button>
                            <Send className="primary-fill-svg" />
                        </button>
                    </ValidatorForm>
                </div>
            </div>
        </div>
    );
};

ChatModule.propTypes = {
    messages: PropTypes.arrayOf(PropTypes.object),
    users: PropTypes.arrayOf(PropTypes.object),
    sendMessage: PropTypes.func,
};

ChatModule.defaultProps = {
    messages: [],
};
