import React, {useState} from 'react';
import {FormControl, FormControlLabel, makeStyles, RadioGroup} from '@material-ui/core';
import CustomRadio from '../../../../../Radio/Radio';

const useStyles = makeStyles({
    root: {
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        width: '100%',

        '& .MuiFormControlLabel-root': {
            margin: 0,
        },
    },
});

const LinearScale = ({fieldData, changeField}) => {
    const scale = [1, 2, 3, 4, 5];
    const classes = useStyles();
    const [value, setValue] = useState(null);

    const handleRadioChange = (event) => {
        setValue(Number(event.target.value));
        changeField({id: fieldData._id, value: event.target.value});
    };

    return (
        <FormControl fullWidth component="fieldset">
            <RadioGroup
                row
                aria-label="linear-scale"
                className={classes.root}
                value={value}
                onChange={handleRadioChange}
            >
                {scale.map((item) => (
                    <FormControlLabel
                        id={`${fieldData._id}-${item}`}
                        key={`${fieldData._id}-${item}`}
                        value={item}
                        control={<CustomRadio />}
                        classsName={classes.label}
                        label={item}
                        labelPlacement="bottom"
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

export default LinearScale;
