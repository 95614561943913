import {VideoPlayerButtons} from './VideoPlayerButtons';
import {connect} from 'react-redux';
import {
    getMediaSession,
    getVideoCapture,
    getScreenShareCapture,
    getIsCameraOff,
    getIsAudioOff,
    getIsScreenShared
} from 'store/selectors';
import {
    getLocalStream,
    getScreenShareStream,
    toggleSelfVideo,
    toggleSelfAudio,
    toggleScreenShareVideo,
    disableScreenShare
} from 'store/actions';

export default connect(
    (state) => ({
        mediaSession: getMediaSession(state),
        localStream: getVideoCapture(state),
        shareScreenCapture: getScreenShareCapture(state),
        isScreenShared: getIsScreenShared(state),
        isMuted: getIsAudioOff(state),
        isVideoPaused: getIsCameraOff(state)
    }),
    {
        getLocalStream,
        getScreenShareStream,
        toggleSelfVideo,
        toggleSelfAudio,
        toggleScreenShareVideo,
        disableScreenShare
    }
)(VideoPlayerButtons);
