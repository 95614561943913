import React from 'react';
import PropTypes from 'prop-types';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Checkbox from '@material-ui/core/Checkbox';

function EnhancedTableHead({
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    cellAlign = 'left',
    withSelection,
    withSorting,
    cellsWithSorting
}) {
    const createSortHandler = (property) => (event) => withSorting ? onRequestSort(property) : false;

    return (
        <TableHead
            classes={{
                root: classes.header
            }}
        >
            <TableRow>
                {withSelection && (
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={rowCount > 0 && numSelected === rowCount}
                            onChange={onSelectAllClick}
                            inputProps={{'aria-label': 'select all desserts'}}
                            className={classes.tableRowCheckbox}
                            color="default"
                        />
                    </TableCell>
                )}
                {headCells.map((headCell) => {
                    const cellWithSorting =
                        withSorting &&
                        (cellsWithSorting && cellsWithSorting.length ? cellsWithSorting.includes(headCell.id) : true);
                    return (
                        <TableCell
                            key={headCell.id}
                            align={headCell.align || cellAlign}
                            padding={headCell.disablePadding ? 'none' : 'default'}
                            sortDirection={orderBy === headCell.id ? order : false}
                            className={classes.tableCell}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={cellWithSorting ? createSortHandler(headCell.id) : null}
                                hideSortIcon={!cellWithSorting}
                                classes={{
                                    icon: orderBy === headCell.id ? classes.activeSortIcon : classes.inactiveSortIcon
                                }}
                            >
                                {headCell?.label?.replace(/^./, headCell.label[0].toUpperCase())}
                                {orderBy === headCell.id ? (
                                    <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string,
    rowCount: PropTypes.number.isRequired,
    headCells: PropTypes.arrayOf(PropTypes.object),
    cellAlign: PropTypes.oneOf(['left', 'center', 'right']),
    withSelection: PropTypes.bool,
    withSorting: PropTypes.bool
};

export default EnhancedTableHead;
