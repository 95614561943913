import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ValidatorForm} from 'react-material-ui-form-validator';
import {DialogActions, DialogContent, InputAdornment} from '@material-ui/core';
import * as actions from '../../../store/actions';
import {ReactComponent as RecoverIcon} from '../../../Images/recover-password.svg';
import {usePlatformTranslation} from '../../../services/hooks';
import {getIsRtlLanguage} from '../../../store/selectors';
import {CustomTextValidator} from '../CustomInputComponents';
import {CustomDialog, CustomDialogButton, CustomDialogContentText, CustomDialogTitle} from '../CustomDialogComponents';
import classNames from 'classnames';

import './RecoverPasswordDialog.scss';

const RecoverPassword = ({opened, closeDialog}) => {
    const dispatch = useDispatch();
    const isRtlLanguage = useSelector(getIsRtlLanguage);
    const formRef = useRef();
    const [email, setEmail] = useState('');
    const [disabled, setDisabled] = useState(true);

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    useEffect(() => {
        if (formRef.current) formRef.current.isFormValid().then((isValid) => setDisabled(!isValid));
    }, [email]);

    const handleRecoverPassword = () => {
        dispatch(actions.sendPasswordResetEmail(email, closeDialog(translation?.recoverPassword?.recoverTextSend)));
    };
    const translation = usePlatformTranslation();
    return (
        <CustomDialog
            open={opened}
            onClose={() => closeDialog('')}
            aria-labelledby="form-dialog-title"
            className="recover-password"
            classes={{paperWidthSm: 'recover-password__wrapper'}}
            withRtl
        >
            <CustomDialogTitle disableTypography>{translation?.recoverPassword.recoverTitle}</CustomDialogTitle>
            <DialogContent>
                <CustomDialogContentText className="recover-password__text">
                    {translation?.recoverPassword.recocerText}
                </CustomDialogContentText>
                <ValidatorForm className="recover-password__form" ref={formRef} onSubmit={handleRecoverPassword}>
                    <CustomTextValidator
                        label={translation?.login.inputEmail}
                        onChange={handleChange}
                        name="email"
                        value={email}
                        variant="outlined"
                        fullWidth
                        withRtl
                        validators={['required', 'isEmail']}
                        errorMessages={[`${translation?.errors.required}`, `${translation?.errors.emailNotValid}`]}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <RecoverIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </ValidatorForm>
            </DialogContent>
            <DialogActions className={classNames('recover-password__actions', {isRtl: isRtlLanguage})}>
                <CustomDialogButton onClick={() => closeDialog('')} color="secondary">
                    {translation?.generalText.cancel}
                </CustomDialogButton>
                <CustomDialogButton onClick={handleRecoverPassword} color="primary" disabled={disabled}>
                    {translation?.recoverPassword.recoverButton}
                </CustomDialogButton>
            </DialogActions>
        </CustomDialog>
    );
};

export default RecoverPassword;
