import React from 'react';
import {useStyles} from './RenderEventList.style';
import ListedEventCard from 'Routes/components/ListedEventCard';
import EventListedCardProfile from 'Routes/components/EventListedCardProfile';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import * as actions from 'store/actions/eventListingActions';
import {NoResult} from '../';
import {usePlatformTranslation} from 'services/hooks';
import EventsHeader from '../EventsHeader';
import {createFilterByDate} from 'services/helpers/listedEvent';

export default function RenderEventList(props) {
    const style = useStyles();
    const loader = React.useRef(null);
    const dispatch = useDispatch();
    const {eventsListing} = usePlatformTranslation();

    const {
        eventListing: {searchText, eventsFilters, showPastEvents, pagination, loading},
        layout: {isLargeScreen},
    } = useSelector((state) => state);

    const handleObserver = React.useCallback(
        (entries) => {
            const target = entries[0];
            if (!loading && target.isIntersecting && props?.isInfiniteScroll && pagination?.page < pagination?.pages) {
                dispatch(
                    actions.getAllListedEvents(
                        {
                            ...pagination,
                            page: pagination.page + 1,
                            showPast: showPastEvents ? 'true' : '',
                        },
                        true
                    )
                );
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, loading, pagination, showPastEvents, props.isInfiniteScroll]
    );

    React.useEffect(() => {
        dispatch(
            actions.getAllListedEvents({
                showPast: showPastEvents ? 'true' : '',
                search: searchText,
                months: createFilterByDate(eventsFilters),
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText, eventsFilters, showPastEvents]);

    React.useEffect(() => {
        const option = {
            root: null,
            rootMargin: '50px',
            threshold: 0,
        };
        const observer = new IntersectionObserver(handleObserver, option);
        const target = loader.current;

        if (target) observer.observe(target);

        return () => observer.unobserve(target);
    }, [handleObserver]);

    const groups = props?.data?.reduce((groups, dates) => {
        const date = moment(dates.date).format('MMMM YYYY');
        if (!groups[date]) {
            groups[date] = [];
        }
        groups[date].push(dates);
        return groups;
    }, {});

    const renderCardItem = (item) => {
        return props?.isFromViewAll || isLargeScreen ? (
            <ListedEventCard typeToLoad={props.typeToLoad} key={item.event} cardData={item} {...props} />
        ) : (
            <EventListedCardProfile typeToLoad={props.typeToLoad} key={item.event} cardData={item} />
        );
    };

    const sortGroup = (group) => {
        return group
            .sort((left, right) => moment.utc(left.date).diff(moment.utc(right.date)))
            .sort((left, right) => right.featured - left.featured);
    };
    return (
        <div className={style.container}>
            {props.showSearch ? <EventsHeader /> : null}
            {props.data?.length ? (
                props?.isInfiniteScroll ? (
                    <>
                        {Object.keys(groups)
                            .sort((left, right) => moment.utc(left).diff(moment.utc(right)))
                            .map((i) => (
                                <React.Fragment key={i}>
                                    <div className={style.groupDateTitle}>
                                        {moment(i, 'MMMM YYYY').format('MMMM YYYY')}
                                    </div>
                                    {sortGroup(groups[i]).map((item) => renderCardItem(item))}
                                </React.Fragment>
                            ))}
                    </>
                ) : (
                    sortGroup(props.data).map((item) => renderCardItem(item))
                )
            ) : (
                <NoResult text={eventsListing.youHaveNoUpcomingEvents} />
            )}
            <div ref={loader} />
        </div>
    );
}
