import {MATCHING_QUESTION_TYPES, MATCHING_WEIGHT_TYPES} from 'constants/matchMaking';
import * as actionTypes from '../actionTypes/matchmakingActionTypes';
import {updateObject} from '../utilityStateFunction';
import {ObjectID} from 'bson';

const initialState = {
    loading: false,
    matchingForm: {
        title: 'Matchmaking questionnaire',
        description:
            'Let’s help you get connected to the most relevant people for you! Amazing things happen when the right minds meet, complete the questionnaire to get started',
        isFormCreation: true,
        questions: [],
    },
    isMatchBannerOpen: false,
    matches: [],
};

const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.INIT_MATCHMAKING_FORM: {
            const newForm = {
                title: 'Matchmaking questionnaire',
                description:
                    'Let’s help you get connected to the most relevant people for you! Amazing things happen when the right minds meet, complete the questionnaire to get started',
                isFormCreation: true,
                questions: [],
            };
            return updateObject(state, {matchingForm: newForm});
        }

        case actionTypes.RESET_MATCHMAKING_FORM:
            return updateObject(state, {...initialState, loading: true});

        case actionTypes.INIT_MATCHMAKING_QUESTION: {
            const maxOrderNumber = Math.max(...state.matchingForm.questions.map((ell) => ell.order), 0);

            const newQuestion = {
                _id: new ObjectID().toString(),
                title: '',
                type: MATCHING_QUESTION_TYPES.radio.value,
                weight: MATCHING_WEIGHT_TYPES.normal.value,
                isRequired: false,
                isAdvanced: false,
                order: maxOrderNumber + 1,
                options: [],
                isChanged: true,
                isQuestionCreation: true,
            };
            return updateObject(state, {
                matchingForm: updateObject(state.matchingForm, {
                    questions: [...state.matchingForm.questions, newQuestion],
                }),
            });
        }

        case actionTypes.CHANGE_MATCHMAKINK_QUESTION: {
            const {fieldName, value, questionId} = payload;
            const newQuestionArr = state.matchingForm.questions.map((question) => {
                if (question._id !== questionId) return question;

                if (fieldName === 'isAdvanced' && value) {
                    const newOption = question.options.map((option) => {
                        return {
                            ...option,
                            matchingWith: [{optionId: option._id}],
                        };
                    });

                    return {...question, options: newOption, [fieldName]: value, isChanged: true};
                }

                return {...question, [fieldName]: value, isChanged: true};
            });

            return updateObject(state, {matchingForm: {...state.matchingForm, questions: newQuestionArr}});
        }

        case actionTypes.ADD_MATCHMAKINK_QUESTION_OPTION: {
            const questionId = payload;
            const questionOptions = state.matchingForm.questions.find(({_id}) => questionId === _id)?.options;

            const maxOrderNumber = Math.max(...questionOptions.map((ell) => ell.order), 0);
            const _id = new ObjectID();
            const newOption = {
                _id: _id.toString(),
                title: '',
                matchingWith: [{optionId: _id.toString()}],
                isOptionCreation: true,
                order: maxOrderNumber + 1,
            };

            const newQuestionArr = state.matchingForm.questions.map((question) => {
                if (question._id !== questionId) return question;
                return {...question, isChanged: true, options: [...question.options, newOption]};
            });
            return updateObject(state, {matchingForm: {...state.matchingForm, questions: newQuestionArr}});
        }

        case actionTypes.REMOVE_MATCHMAKINK_QUESTION_OPTION: {
            const {optionId, questionId} = payload;
            const newQuestionArr = state.matchingForm.questions.map((question) => {
                if (question._id !== questionId) return question;
                const newOptions = question.options.filter((option) => option._id !== optionId);
                return {...question, isChanged: true, options: newOptions};
            });

            return updateObject(state, {matchingForm: {...state.matchingForm, questions: newQuestionArr}});
        }

        case actionTypes.CHANGE_MATCHMAKINK_QUESTION_OPTION: {
            const {fieldName, value, questionId, optionId} = payload;
            const newQuestionArr = state.matchingForm.questions.map((question) => {
                if (question._id !== questionId) return question;
                const newOptions = question.options.map((option) => {
                    if (option._id !== optionId) return option;
                    return {...option, [fieldName]: value};
                });
                return {...question, isChanged: true, options: newOptions};
            });

            return updateObject(state, {matchingForm: {...state.matchingForm, questions: newQuestionArr}});
        }

        case actionTypes.CHANGE_MATCHING_WITH: {
            const {matchingWith, questionId, changedOption} = payload;

            const newQuestionArr = state.matchingForm.questions.map((question) => {
                if (question._id !== questionId) return question;

                const newOptions = question.options.map((option) => {
                    const includesOption = option.matchingWith.find((ell) => ell.optionId === changedOption._id);
                    const isInMatching = matchingWith.find((el) => el.optionId === option._id);
                    // check if option is in [matchingWith] if yes we need to update booth , changed and that are selected in [matchWith]

                    if (isInMatching && !includesOption) {
                        //also add changed option id  to selected matching option
                        return {
                            ...option,
                            matchingWith: [...option.matchingWith, {optionId: changedOption._id}],
                        };
                    }

                    // add matchWith to changed option
                    if (changedOption._id === option._id) {
                        return {...option, matchingWith};
                    }
                    return option;
                });
                return {...question, isChanged: true, options: newOptions};
            });

            return updateObject(state, {matchingForm: {...state.matchingForm, questions: newQuestionArr}});
        }

        case actionTypes.REMOVE_MATCHING_WITH: {
            const {matchingWithId, questionId, optionId} = payload;
            const newQuestionArr = state.matchingForm.questions.map((question) => {
                if (question._id !== questionId) return question;

                const newOptions = question.options.map((option) => {
                    if (option._id === optionId) {
                        const newMatchingWith = option.matchingWith.filter((el) => el.optionId !== matchingWithId);
                        return {...option, matchingWith: newMatchingWith};
                    }
                    return option;
                });
                return {...question, isChanged: true, options: newOptions};
            });

            return updateObject(state, {matchingForm: {...state.matchingForm, questions: newQuestionArr}});
        }

        case actionTypes.SET_MATCHING_MATCHES: {
            return updateObject(state, {matches: payload});
        }

        case actionTypes.REORDER_MATCHING_QUESTION_OPTIONS: {
            const {questionId, startIndex, endIndex} = payload;
            const newQuestionArr = state.matchingForm.questions.map((question) => {
                if (question._id !== questionId) return question;
                const newOptions = [...question.options];
                const [removed] = newOptions.splice(startIndex, 1);
                newOptions.splice(endIndex, 0, removed);
                newOptions.forEach((option, i) => {
                    option.order = i;
                });
                return {...question, isChanged: true, options: newOptions};
            });

            return updateObject(state, {matchingForm: {...state.matchingForm, questions: newQuestionArr}});
        }

        // requests -- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- --- ---

        case actionTypes.CREATE_MATCHING_QUESTION_SUCCESS: {
            const {newQuestion, questionId} = payload;
            const newQuestionArr = state.matchingForm.questions.map((question) => {
                if (question._id !== questionId) return question;
                return newQuestion;
            });

            return updateObject(state, {
                matchingForm: {...state.matchingForm, questions: newQuestionArr},
                loading: false,
            });
        }
        case actionTypes.UPDATE_MATCHING_QUESTION_SUCCESS: {
            const {newQuestion} = payload;
            const newQuestionArr = state.matchingForm.questions.map((question) => {
                if (question._id !== newQuestion._id) return question;
                return newQuestion;
            });

            return updateObject(state, {
                matchingForm: {...state.matchingForm, questions: newQuestionArr},
                loading: false,
            });
        }
        case actionTypes.DELETE_MATCHING_QUESTION_SUCCESS: {
            const {questionId} = payload;
            const newQuestionArr = state.matchingForm.questions.filter((question) => question._id !== questionId);

            return updateObject(state, {
                matchingForm: {...state.matchingForm, questions: newQuestionArr},
                loading: false,
            });
        }

        case actionTypes.UPDATE_MATCHMAKING_FORM_SUCCESS: {
            const {title, description} = payload;
            const newForm = updateObject(state.matchingForm, {title, description, ...state.matchingForm});
            return updateObject(state, {matchingForm: updateObject(newForm), loading: false});
        }

        case actionTypes.CREATE_MATCHMAKING_FORM_SUCCESS: {
            const {questions, ...data} = payload;
            const newForm = updateObject(state.matchingForm, {...data, isFormCreation: false});
            return updateObject(state, {matchingForm: updateObject(newForm), loading: false});
        }
        case actionTypes.GET_MATCHMAKING_FORM_SUCCESS: {
            const newForm = payload
                ? updateObject(state.matchingForm, {...payload, isFormCreation: false})
                : state.matchingForm;
            return updateObject(state, {matchingForm: updateObject(newForm), loading: false});
        }

        case actionTypes.REORDER_MATHCING_QUESTIONS: {
            return updateObject(state, {
                matchingForm: {...state.matchingForm, questions: payload},
                loading: false,
            });
        }

        case actionTypes.REORDER_MATCHING_QUESTION_REQUEST_START:
        case actionTypes.DELETE_MATCHING_QUESTION_START:
        case actionTypes.UPDATE_MATCHING_QUESTION_START:
        case actionTypes.CREATE_MATCHING_QUESTION_START:
        case actionTypes.UPDATE_MATCHMAKING_FORM_START:
        case actionTypes.GET_MATCHMAKING_FORM_START:
        case actionTypes.CREATE_MATCHMAKING_FORM_START:
            return updateObject(state, {loading: true});

        case actionTypes.REORDER_MATCHING_QUESTION_REQUEST_SUCCESS:
        case actionTypes.REORDER_MATCHING_QUESTION_REQUEST_FAIL:
        case actionTypes.DELETE_MATCHING_QUESTION_FAIL:
        case actionTypes.UPDATE_MATCHING_QUESTION_FAIL:
        case actionTypes.CREATE_MATCHING_QUESTION_FAIL:
        case actionTypes.UPDATE_MATCHMAKING_FORM_FAIL:
        case actionTypes.CREATE_MATCHMAKING_FORM_FAIL:
        case actionTypes.GET_MATCHMAKING_FORM_FAIL:
            return updateObject(state, {loading: false});

        default:
            return state;
    }
};

export default reducer;
