import SendGroupChatMessage from './SendGroupChatMessage';
import {connect} from 'react-redux';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export default connect(
    (state) => ({
        activeChatRoomId: selectors.getUserActiveChatRoomId(state),
        isLargeScreen: selectors.getIsLargeScreen(state),
        languages: selectors.getLanguages(state),
        archivedChats: selectors.getUserArchivedChats(state),
    }),
    {
        onArchiveChat: actions.archiveChat,
        newGroupChatMessage: actions.newGroupChatMessage,
        sendGroupChatMessage: actions.sendGroupChatMessage,
    }
)(SendGroupChatMessage);
