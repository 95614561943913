import React, {useEffect, useState, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {CheckCircle as CheckCircleIcon, PlayArrow as PlayArrowIcon} from '@material-ui/icons';
import classNames from 'classnames';

import SinglePollOption from './components/SinglePollOption/SinglePollOption';

import {selectPollOption} from 'store/actions';

const SinglePoll = ({poll, userId, programId}) => {
    const dispatch = useDispatch();
    const {question, options, finished, multiSelect, hideResults} = poll;
    const [voteAvailible, setVoteAvailibility] = useState(false);
    const [readyToCalcVotes, setReadyToCalcVotes] = useState(false);
    const [voteResults, setVoteResults] = useState(null);
    const [userVoted, setUserVoted] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);

    const checkIfUserVoted = useCallback(() => {
        if (multiSelect) return;
        options.forEach((option) => {
            if (option.votes.includes(userId)) setUserVoted(option._id);
        });
    }, [multiSelect, options, userId]);

    const checkIfPollAvailible = useCallback(() => {
        const isCanBeVoted = !userVoted || multiSelect;

        if (isCanBeVoted && !finished) {
            setVoteAvailibility(true);

            multiSelect && setReadyToCalcVotes(true);
        } else {
            setReadyToCalcVotes(true);
            setVoteAvailibility(false);
        }
    }, [finished, multiSelect, userVoted]);

    const calcVotes = useCallback(
        (multiSelectOptions) => {
            const getOptions = multiSelectOptions || options;

            const totalVotes = getOptions.reduce((acc, option) => {
                return acc + option.votes.length;
            }, 0);
            const votes = getOptions.map((option) => {
                if (totalVotes === 0) return 0;
                return Math.round((option.votes.length * 100) / totalVotes);
            });
            setVoteResults(votes);
        },
        [options]
    );

    const handleSelect = (id) => {
        if (!voteAvailible) return false;

        setSelectedOptions((prevState) => [...prevState, id]);

        dispatch(selectPollOption({pollId: poll._id, optionId: id, programId, callback: (data) => calcVotes(data)}));
    };

    useEffect(() => {
        checkIfUserVoted();
        calcVotes();
    }, [calcVotes, checkIfUserVoted, poll]);

    useEffect(() => {
        checkIfPollAvailible();
    }, [checkIfPollAvailible, userVoted]);

    useEffect(() => {
        if (voteResults || !readyToCalcVotes) return;
        calcVotes();
    }, [readyToCalcVotes, calcVotes, voteResults]);

    const isHasResults = voteResults?.some((result) => !!result);

    return (
        <div
            className={classNames('poll', {
                '-vote-availible': voteAvailible,
            })}
        >
            <div className="poll_header">
                <p className="poll_title">{question}</p>
                <div className="poll_status">
                    {finished ? (
                        <>
                            <CheckCircleIcon className="poll_status-icon" />
                            <span>Poll closed</span>
                        </>
                    ) : (
                        isHasResults && (
                            <>
                                <PlayArrowIcon
                                    style={{color: 'var(--theme-primary-color)'}}
                                    className="poll_status-icon"
                                />
                                <span>Poll in progress</span>
                            </>
                        )
                    )}
                </div>
            </div>
            <div className="poll_body">
                {!!options.length &&
                    options.map((option, index) => (
                        <SinglePollOption
                            key={option._id}
                            option={option}
                            handleSelect={handleSelect}
                            index={index}
                            voteResults={voteResults}
                            selectedOption={selectedOptions.includes(option._id)}
                            userVoted={userVoted}
                            finished={finished}
                            multiSelect={multiSelect}
                            hideResults={hideResults}
                        />
                    ))}
            </div>
        </div>
    );
};

export default SinglePoll;
