import React from 'react';
import {generatePath} from 'react-router';
import {useHistory} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import classNames from 'classnames';
import {ReactComponent as BookMarkIcon} from '../../../Images/bookmark.svg';

import {RouterConfig} from '../../../routerConfig';
import * as actions from '../../../store/actions';
import {getIsRtlLanguage, getEventId, getUserData, getPlatformLanguage} from '../../../store/selectors';

import {getEventCardDate, getTextFromTranslation} from '../../../services/helpers/listedEvent';
import ListedEventCardSaveBtn from './components/ListedEventCardSaveBtn';
import ListedEventCardShareBtn from './components/ListedEventCardShareBtn';
import {eventFolder, listingLogosFolder, whiteLabel} from '../../../config';
import {isMobile} from 'react-device-detect';
import './ListedEventCard.scss';

const ListedEventCard = ({cardData, typeToLoad}) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isRtlLanguage = useSelector(getIsRtlLanguage);
    const user = useSelector(getUserData);
    const eventId = useSelector(getEventId);
    const platformLanguage = useSelector(getPlatformLanguage);

    const translateLanguage = user?.language || cardData.language;
    const dateLanguage = user?.language || platformLanguage;
    const title = getTextFromTranslation(cardData.title, translateLanguage, cardData.language);
    const description = getTextFromTranslation(cardData.description, translateLanguage, cardData.language);
    const dateText = getEventCardDate(cardData.date, dateLanguage);
    const eventSlug = cardData?.slug;
    const redirect = () => {
        if (cardData.event === eventId) return;

        dispatch(actions.setUserInitialEventId(eventId));
        history.push({
            pathname: generatePath(RouterConfig.event.homepage, {eventSlug}),
            state: {eventSlug},
        });
    };
    const imagePath = cardData.image
        ? `${eventFolder}${cardData.event}/${cardData.image}`
        : `${listingLogosFolder}${whiteLabel}-listing-logo.png`;
    return (
        <div className={'ListedEventCard'}>
            <div onClick={redirect}>
                {cardData.featured && (
                    <BookMarkIcon
                        data-testid="bookMarkIcon"
                        className={classNames('ListedEventCard__bookmark', 'primary-fill-svg')}
                    />
                )}
                <div className={'ListedEventCard__tooltip'}>Featured event</div>
                <div className={'ListedEventCard__overlayer'} />
                <div
                    className={classNames('ListedEventCard__imgWrapper', {isCurrentEvent: cardData.event === eventId})}
                >
                    <img className="ListedEventCard__img" src={imagePath} alt="event-card" />
                </div>
            </div>
            <div className={'ListedEventCard__infoWrapper'}>
                <div>
                    <p className={'ListedEventCard__title'}>{title}</p>
                    <div className={'ListedEventCard__infoText'}>
                        <span
                            dir="ltr"
                            data-testid="event-date"
                            className={classNames('ListedEventCard__date', {isRtl: isRtlLanguage})}
                        >
                            {dateText}
                        </span>
                        {cardData.featured && (
                            <p
                                data-testid="featured-status"
                                className={classNames('ListedEventCard__feautedStatus', {
                                    isRtl: isRtlLanguage,
                                    isMobile,
                                })}
                            >
                                {'FEATURED'}
                            </p>
                        )}
                    </div>
                    <p
                        data-testid="card-description"
                        className={classNames('ListedEventCard__description', {isRtl: isRtlLanguage})}
                    >
                        {description}
                    </p>
                </div>

                <div className={'ListedEventCard__btnWrapper'} data-testid="btn-wrapper">
                    <ListedEventCardShareBtn shareData={cardData} />

                    <ListedEventCardSaveBtn
                        onSave={() => {
                            dispatch(actions.saveEventToPersonalList(cardData.event, typeToLoad));
                        }}
                        onUnsave={() => {
                            dispatch(actions.discardEventFromPersonalList(cardData.event, typeToLoad));
                        }}
                        preSaveEvent={() => dispatch(actions.preSaveEvent({event: cardData.event, eventSlug}))}
                        isSaved={cardData.saved}
                        redirect={redirect}
                    />
                </div>
            </div>
        </div>
    );
};

export default ListedEventCard;
