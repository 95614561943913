import React, {useRef} from 'react';
import {CloudDownload as CloudDownloadIcon, Clear as ClearIcon} from '@material-ui/icons';

import './FileField.scss';
import classNames from 'classnames';
import {whiteLabel} from '../../../config';

const getAcceptedExtensions = (fileValidations) => fileValidations.join(', ');

const FileField = ({field, handleChange, userId = '', deleteFile}) => {
    const inputEl = useRef();
    const hasUploadedFile = !!field.value;

    const onReset = () => {
        deleteFile && deleteFile(field.value);
        handleChange(field.name, '');
    };

    const onChange = (e) => {
        const image = inputEl.current.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(inputEl.current.files[0]);

        reader.onload = () => {
            handleChange(field.name, {title: image.name, file: image});
        };
    };

    if (hasUploadedFile) {
        return (
            <div className="FileField">
                <span className={classNames('FileField__content', whiteLabel)}>
                    <span className="FileField__iconCol">
                        <button className="FileField__button" onClick={onReset}>
                            <ClearIcon />
                        </button>
                    </span>

                    {field.value.title ? (
                        <span className="FileField__label">{field.value.title}</span>
                    ) : (
                        <a
                            target={'blank'}
                            download
                            rel="noopener noreferrer"
                            href={`${process.env.REACT_APP_USERS_FOLDER}${userId}/${field.value}`}
                        >
                            {field.value}
                        </a>
                    )}
                </span>

                {field.errorMessage && <div className="FileField__error">{field.errorMessage}</div>}
            </div>
        );
    }

    return (
        <div className="FileField">
            <label className="FileField__wrapper">
                <span className={classNames('FileField__content', whiteLabel)}>
                    <span className="FileField__iconCol">
                        <CloudDownloadIcon />
                    </span>

                    <span className="FileField__label">{field.label}</span>
                </span>

                <input
                    className="FileField__input"
                    ref={inputEl}
                    type="file"
                    accept={getAcceptedExtensions(field.fileValidations)}
                    onChange={onChange}
                />
            </label>

            {field.errorMessage && <div className="FileField__error">{field.errorMessage}</div>}
        </div>
    );
};

export default FileField;
