import _ from 'lodash';
import * as actionTypes from '../../../actionTypes/adminActionTypes';
import {updateObject} from '../../../utilityStateFunction';
import {initialState} from '../initialState';

const organizerDetails = (state = initialState, {type, payload}) => {
    if (state === undefined) {
        return initialState.organizerDetails;
    }

    switch (type) {
        case actionTypes.GET_ORGANIZER_EVENTS_REQUEST:
        case actionTypes.DELETE_ORGANIZER_EVENT_REQUEST:
        case actionTypes.UPDATE_ORGANIZER_EVENT_REQUEST:
            return updateObject(state.organizerDetails, {
                loading: true,
            });
        case actionTypes.GET_ORGANIZER_EVENTS_FAIL:
        case actionTypes.DELETE_ORGANIZER_EVENT_FAIL:
        case actionTypes.UPDATE_ORGANIZER_EVENT_FAIL:
            return updateObject(state.organizerDetails, {
                loading: false,
            });
        case actionTypes.GET_ORGANIZER_EVENTS_SUCCESS: {
            const {events, page, perPage, pages, total, isFetchAfterDelete} = payload;

            const updatedEvents = isFetchAfterDelete
                ? events
                : _.uniqBy([...state.organizerDetails.events, ...events], '_id');
            return updateObject(state.organizerDetails, {
                loading: false,
                events: updatedEvents,
                pagination: {page, perPage, pages, total},
            });
        }
        case actionTypes.CHANGE_EVENTS_LIST_PAGE: {
            const {page} = payload;
            return updateObject(state.organizerDetails, {
                pagination: updateObject(state.organizerDetails.pagination, {
                    page,
                }),
            });
        }
        case actionTypes.UPDATE_ORGANIZER_EVENT_SUCCESS: {
            const updatedEvents = state.organizerDetails.events.map((event) =>
                event._id === payload._id ? {...state.organizerDetails.events, ...payload} : event
            );

            return updateObject(state.organizerDetails, {
                loading: false,
                events: updatedEvents,
            });
        }
        case actionTypes.CLEAR_EVENTS_LIST:
            return initialState.organizerDetails;
        default:
            return state.organizerDetails;
    }
};

export default organizerDetails;
