import {useSelector} from 'react-redux';

import {
    getUserEventRoles,
    getIsUserLoggedIn,
    getUserLoading,
    getEventId,
    getUserInitialEventId,
} from '../../store/selectors';

const useUnregisteredUser = () => {
    const isUserLoggedIn = useSelector(getIsUserLoggedIn);
    const userLoading = useSelector(getUserLoading);
    const userEventRoles = useSelector(getUserEventRoles);
    const eventId = useSelector(getEventId);
    const userInitialEventId = useSelector(getUserInitialEventId);

    if (!eventId || !userEventRoles) return;

    const isUserRegisteredForCurrentEvent = userEventRoles.some(
        ({event: {_id}}) => _id === eventId || _id === userInitialEventId
    );

    return isUserLoggedIn && !userLoading && !isUserRegisteredForCurrentEvent;
};

export default useUnregisteredUser;
