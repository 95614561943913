import React, {useRef, useContext, useCallback, createContext} from 'react';
import {isIOS, isMacOs} from 'react-device-detect';
import ringSound from 'assets/audio/ring.mp3';

const RingContext = createContext();

const RingProvider = ({children}) => {
    const audioRef = useRef();

    const play = useCallback(() => {
        //5.24.2021 don't support ring notification on IOS devices because of privicy policy of ios 14+
        if (isIOS || isMacOs) return;
        audioRef.current.play();
    }, []);

    return (
        <RingContext.Provider value={{play}}>
            {children}
            <audio ref={audioRef} src={ringSound} hidden />
        </RingContext.Provider>
    );
};

const useRing = () => useContext(RingContext);

export {useRing};
export default RingProvider;
