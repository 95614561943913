import React from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';

import WhiteRectangleText from './components/WhiteRectangleText/WhiteRectangleText';

class ContactForm extends React.Component {
    timerId = 0;
    state = {
        fields: [
            {
                name: 'name',
                type: 'text',
                value: '',
                label: 'Name',
                multiline: 0,
                validators: ['required', 'matchRegexp:^(?!\\s*$).+'],
                errorMessages: ['Field is required', 'Field is required'],
            },
            {
                name: 'email',
                type: 'text',
                value: '',
                label: 'Email',
                multiline: 0,
                validators: ['required', 'isEmail'],
                errorMessages: ['Field is required', 'Email not valid'],
            },
            {
                name: 'phone',
                type: 'text',
                value: '',
                label: 'Mobile phone number',
                multiline: 0,
                validators: ['required', 'matchRegexp:^[+]?[0-9 s]*$'],
                errorMessages: ['Field is required', 'Please enter a valid phone number'],
            },
            {
                name: 'message',
                type: 'text',
                value: '',
                label: 'Message',
                multiline: 8,
                validators: ['required', 'matchRegexp:^(?!\\s*$).+'],
                errorMessages: ['Field is required', 'Field is required'],
            },
        ],
        sendingMessage: false,
        messageSent: false,
    };

    componentWillUnmount() {
        clearTimeout(this.timerId);
        const mobileMenu = document.getElementsByClassName('platform mobile-menu-wrapper ');
        if (mobileMenu) {
            mobileMenu[0].classList.remove('hide');
        }
    }

    handleFieldChange = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].value = e.target.value;
        this.setState({fields: updatedFields});
    };

    sendEmail = () => {
        const {sendHomepageContactForm} = this.props;
        let data = {};
        this.state.fields.forEach((field) => {
            data[field.name] = field.value;
        });

        data.name = data.name.trim();
        data.message = data.message.trim();

        if (data.name && data.message) {
            this.setState({sendingMessage: true});

            sendHomepageContactForm({
                payload: data,
                callback: () => {
                    let fieldsReset = [...this.state.fields];
                    fieldsReset.forEach((field) => (field.value = ''));
                    this.setState({
                        fields: fieldsReset,
                        messageSent: true,
                        sendingMessage: false,
                    });
                },
                errorCallback: () => this.setState({sendingMessage: false}),
            });
        }
    };

    handleInputFocus = () => {
        clearTimeout(this.timerId);
        const mobileMenu = document.getElementsByClassName('platform mobile-menu-wrapper ');
        if (mobileMenu) {
            mobileMenu[0].classList.add('hide');
        }
    };

    handleInputBlur = () => {
        const mobileMenu = document.getElementsByClassName('platform mobile-menu-wrapper ');
        if (mobileMenu) {
            this.timerId = setTimeout(() => {
                mobileMenu[0].classList.remove('hide');
            }, 1000);
        }
    };

    render() {
        let {fields, messageSent, sendingMessage} = this.state;
        return (
            <div className={'form-container ' + (messageSent ? 'success' : '')}>
                <ValidatorForm ref="form" onSubmit={this.sendEmail}>
                    {fields.map((field, index) => {
                        return (
                            <TextValidator
                                key={field.name}
                                label={field.label}
                                type={field.type}
                                name={field.name}
                                index={index}
                                value={field.value}
                                variant="outlined"
                                onChange={this.handleFieldChange(index)}
                                validators={field.validators}
                                errorMessages={field.errorMessages}
                                multiline={field.multiline > 0}
                                rows={field.multiline}
                                fullWidth={true}
                                margin="normal"
                                className="field-container"
                                onFocus={this.handleInputFocus}
                                onBlur={this.handleInputBlur}
                            />
                        );
                    })}

                    <button className="send-button" type="submit" disabled={sendingMessage}>
                        {sendingMessage ? 'Sending' : 'Send'}
                    </button>
                </ValidatorForm>
                <div className="success-container">
                    <p className="title">Successfully sent.</p>
                    <p className="sub-title">We'll respond as quickly as possible.</p>
                    <p className="end-text">Get ready for your next</p>
                    <p className="end-text">
                        <WhiteRectangleText>BEST EVENT EVER</WhiteRectangleText>
                    </p>
                </div>
            </div>
        );
    }
}

export default ContactForm;
