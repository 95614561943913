export const formDesignation = (profession, company) => {
    if (profession && company) {
        return `${profession} at ${company}`;
    }

    return profession || company;
};

export const formBusinessCardShape = (isContact, isFriendConnectionSent, translation) => {
    if (isContact) {
        return {title: `${translation.meetings.request.openBCard}`, variant: 'primary'};
    }

    if (isFriendConnectionSent) {
        return {title: `${translation.meetings.request.pendingRequest}`, variant: 'accent'};
    }

    return {title: `${translation.meetings.request.requestBCard}`, variant: 'default'};
};

export const checkIsActionsRowShowed = (isCurrentUser, canConnectToParticipant, businessCardId, socialList) => {
    if (isCurrentUser) {
        return false;
    }

    return businessCardId || canConnectToParticipant || !!socialList.length;
};
