import React from 'react';
import cn from 'classnames';

import IconContainer from '../../../../../../../IconContainer/IconContainer';

import './BottomNavigationButton.scss';

const BottomNavigationButton = ({title, icon, isActive, ...rest}) => (
    <button className={cn('BottomNavigationButton', {BottomNavigationButton_active: isActive})} {...rest}>
        <span className="BottomNavigationButton__icon">
            <IconContainer>{icon}</IconContainer>
        </span>

        <span className="BottomNavigationButton__title">{title}</span>
    </button>
);

export default BottomNavigationButton;
