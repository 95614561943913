import SelectCountry from '../../Routes/components/SelectCountry/SelectCountry';
import {userRegistrationCustomFieldTypes, userRegistrationCustomFieldByType} from '../../constants/data';

const COUNTRY_NAME = 'country';

const getUserRegistrationFieldByType = (type) =>
    userRegistrationCustomFieldByType[type] || userRegistrationCustomFieldByType[userRegistrationCustomFieldTypes.text];

const getUserRegistrationField = (type, name) => {
    if (name === COUNTRY_NAME) {
        return SelectCountry;
    }

    return getUserRegistrationFieldByType(type);
};

export default getUserRegistrationField;
