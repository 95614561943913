import {createSelector} from 'reselect';

const sessionDashboard = (state) => state.etxSessionDashboard;

export const getSessionData = createSelector(sessionDashboard, (state) => state);

export const getRoomSessions = createSelector(sessionDashboard, (state) => state.sessions);

export const getRoomClient = createSelector(sessionDashboard, (state) => state.client);

export const getVideoCapture = createSelector(sessionDashboard, (state) => state.capture);

export const getVideoRoom = createSelector(sessionDashboard, (state) => state.room);

export const getScreenShareCapture = createSelector(sessionDashboard, (state) => state.shareScreenCapture);

export const getMediaInputSources = createSelector(sessionDashboard, (state) => state.mediaInputSources);

export const getFullScreen = createSelector(sessionDashboard, (state) => state.isFullScreen);

export const getDashboardReady = createSelector(sessionDashboard, (state) => state.isReady);

export const getIsUserKicked = createSelector(sessionDashboard, (state) => state.isKicked);

export const getIsCameraOff = createSelector(sessionDashboard, (state) => state.isVideoPaused);

export const getIsAudioOff = createSelector(sessionDashboard, (state) => state.isAudioPaused);

export const getIsScreenShared = createSelector(sessionDashboard, (state) => state.isScreenShared);

export const getMediaSession = createSelector(sessionDashboard, (state) => state.mediaSession);

export const getConsumers = createSelector(sessionDashboard, (state) => state.consumers);

export const getJoinedUsers = createSelector(sessionDashboard, (state) => state.joinedUsers);

export const getIsClosingMode = createSelector(sessionDashboard, (state) => state.isClosingMode);

export const getDominantSpeakers = createSelector(sessionDashboard, (state) => state.dominantSpeakerOrder);

export const getMediaCaptureError = createSelector(sessionDashboard, (state) => state.isMediaCaptureError);
