import React from 'react';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {connect} from 'react-redux';

import EditUserInfoWrapper from 'Routes/components/EditUserInfoWrapper';
import CustomCancelButton from 'Routes/components/CustomCancelButton';

import * as actions from 'store/actions';
import {Api} from 'Api';

class ChangePasswordMobile extends React.Component {
    state = {
        oldPassword: '',
        password: '',
        passwordConfirmation: '',
        disabled: true,
        error: false,
        translation: null
    };

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.password) {
                return false;
            }
            return true;
        });
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation
        });
    };

    componentWillUnmount() {
        // remove rule when it is not needed
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value,
                error: false
            },
            () => {
                this.refs.form.isFormValid().then((isValid) => {
                    this.setState({disabled: !isValid});
                });
            }
        );
    };

    handleUpdatePassword = async () => {
        try {
            await Api.request({
                method: 'put',
                url: '/users',
                payload: {oldPassword: this.state.oldPassword, password: this.state.password}
            });

            this.props.onRefreshUserUserData();
            this.props.closeDialog();
        } catch (error) {}
    };

    render() {
        const {opened} = this.props;
        const {translation} = this.state;
        return (
            <EditUserInfoWrapper className={`small-padding-top ${opened ? 'opened' : ''}`}>
                <div>
                    <div className="page-title">
                        <p>{translation?.changePassword.title}</p>
                    </div>
                    <div className="content-wrapper">
                        <div className="form-wrapper">
                            <ValidatorForm
                                ref="form"
                                onSubmit={this.handleUpdatePassword}
                                className="change-password-form"
                            >
                                <TextValidator
                                    label={translation?.changePassword.oldPassword}
                                    onChange={this.handleChange}
                                    name="oldPassword"
                                    value={this.state.oldPassword}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    validators={['required', 'minStringLength:8']}
                                    errorMessages={[
                                        `${translation?.errors.required}`,
                                        `${translation?.errors.passwordMinLength}`
                                    ]}
                                />
                                <TextValidator
                                    label={translation?.changePassword.inputPassword}
                                    onChange={this.handleChange}
                                    name="password"
                                    value={this.state.password}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    validators={['required', 'minStringLength:8']}
                                    errorMessages={[
                                        `${translation?.errors.required}`,
                                        `${translation?.errors.passwordMinLength}`
                                    ]}
                                />
                                <TextValidator
                                    label={translation?.changePassword.inputConfirmPassword}
                                    onChange={this.handleChange}
                                    name="passwordConfirmation"
                                    value={this.state.passwordConfirmation}
                                    variant="outlined"
                                    type="password"
                                    fullWidth
                                    validators={['isPasswordMatch', 'required', 'minStringLength:8']}
                                    errorMessages={[
                                        `${translation?.errors.passwordMismatch}`,
                                        `${translation?.errors.required}`,
                                        `${translation?.errors.passwordMinLength}`
                                    ]}
                                />
                                {this.state.error && (
                                    <p className="error-message">{translation?.changePassword.oldPasswordError}</p>
                                )}
                            </ValidatorForm>
                        </div>
                    </div>
                    <div className="buttons-actions">
                        <CustomCancelButton
                            text={translation?.generalText.close}
                            onClick={() => this.props.closeDialog('')}
                        />
                        <button
                            onClick={this.handleUpdatePassword}
                            className="update-button"
                            disabled={this.state.disabled}
                        >
                            {translation?.generalText.update}
                        </button>
                    </div>
                </div>
            </EditUserInfoWrapper>
        );
    }
}

export default connect(
    (state) => ({
        user: state.user.data,
        languages: state.languages
    }),
    {
        onRefreshUserUserData: actions.refreshUserData
    }
)(ChangePasswordMobile);
