import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {Notifications} from './Notifications';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export default withRouter(
    connect(
        (state) => ({
            user: selectors.getUserData(state),
            notifications: selectors.getUserNotifications(state),
            event: selectors.getEventData(state),
            eventSlug: selectors.getEventSlug(state),
            languages: selectors.getLanguages(state),
            isRtlLanguage: selectors.getIsRtlLanguage(state),
        }),
        {
            seeAllNotifications: actions.seeAllNotifications,
            seeContacts: actions.topNavSeeContacts,
            onOpenWallet: actions.openWallet,
            seeAgenda: actions.topNavSeeAgenda,
        }
    )(Notifications)
);
