import {DialogTitle, withStyles} from '@material-ui/core';

const CustomDialogTitle = withStyles((theme) => ({
    root: {
        padding: 24,
        color: theme.palette.text.primary,
        fontWeight: '600 !important',
        fontSize: '24px',
        margin: 0,
        letterSpacing: '0.005em',
    },
}))(DialogTitle);

export default CustomDialogTitle;
