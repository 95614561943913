import React from 'react';
import {usePlatformTranslation} from '../../../../../../../../../services/hooks';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {
    clipboardIcon,
    closeIcon,
    facebookIcon,
    linkedInIcon,
    mailIcon,
    twitterIcon,
} from '../../../../../../../../../Images/pages/eventListing';
import {EmailShareButton, FacebookShareButton, LinkedinShareButton, TwitterShareButton} from 'react-share';
import {eventFolder, listingLogosFolder, whiteLabel} from '../../../../../../../../../config';
import './Share.scss';

function Share({onClose, shareData, handleOpenSuccessSnackbar}) {
    const {eventsListing} = usePlatformTranslation();
    const {isRtlLanguage, eventLanguage} = useSelector((state) => state.languages);

    if (!shareData) return null;
    const eventLink = `${window.location.origin}/event/${shareData?.slug}`;
    const eventTitle = `${shareData?.title}`;
    const eventImage = shareData?.image
        ? `${eventFolder}${shareData?.event}${shareData?.image}`
        : `${listingLogosFolder}${whiteLabel}-listing-logo.png`;

    const eventText = `${shareData?.description?.find((i) => i.language === eventLanguage)?.text}`;

    return (
        <div className={classNames('share-container', {isRtl: isRtlLanguage})}>
            <div onClick={onClose} className={classNames('share-container__close')}>
                <img src={closeIcon} alt={'closeIcon'} />
            </div>
            <div className={classNames('share-container__title')}>{eventsListing.shareWithYourNetwork}</div>
            <div className={classNames('share-container__socials-buttons-container')}>
                <div
                    onClick={onClose}
                    className={classNames('share-container__socials-buttons-container__social-button')}
                >
                    <LinkedinShareButton
                        title={`${eventTitle}`}
                        summary={`${eventLink} ${eventText}`}
                        url={`${eventLink}`}
                        source={`${eventLink}`}
                    >
                        <div>
                            <img
                                className={classNames(
                                    'share-container__socials-buttons-container__social-button__social-button-icon'
                                )}
                                src={linkedInIcon}
                                alt={`linkedInIcon`}
                            />
                        </div>
                        <div
                            className={classNames(
                                'share-container__socials-buttons-container__social-button__social-button-text'
                            )}
                        >
                            {'LinkedIn'}
                        </div>
                    </LinkedinShareButton>
                </div>
                <div
                    onClick={onClose}
                    className={classNames('share-container__socials-buttons-container__social-button')}
                >
                    <TwitterShareButton
                        url={eventLink}
                        description={`${eventLink} ${eventImage} ${eventText}`}
                        title={eventTitle}
                    >
                        <div>
                            <img
                                className={classNames(
                                    'share-container__socials-buttons-container__social-button__social-button-icon'
                                )}
                                src={twitterIcon}
                                alt={`twitterIcon`}
                            />
                        </div>
                        <div
                            className={classNames(
                                'share-container__socials-buttons-container__social-button__social-button-text'
                            )}
                        >
                            {'Twitter'}
                        </div>
                    </TwitterShareButton>
                </div>
                <div
                    onClick={onClose}
                    className={classNames('share-container__socials-buttons-container__social-button')}
                >
                    <FacebookShareButton url={eventLink} description={`${eventLink} ${eventText}`} quote={eventLink}>
                        <div>
                            <img
                                className={classNames(
                                    'share-container__socials-buttons-container__social-button__social-button-icon'
                                )}
                                src={facebookIcon}
                                alt={`facebookIcon`}
                            />
                        </div>
                        <div
                            className={classNames(
                                'share-container__socials-buttons-container__social-button__social-button-text'
                            )}
                        >
                            {'Facebook'}
                        </div>
                    </FacebookShareButton>
                </div>
                <div
                    onClick={onClose}
                    className={classNames('share-container__socials-buttons-container__social-button')}
                >
                    <EmailShareButton url={eventLink} body={`${eventLink} ${eventText}`} subject={eventTitle}>
                        <div>
                            <img
                                className={classNames(
                                    'share-container__socials-buttons-container__social-button__social-button-icon'
                                )}
                                src={mailIcon}
                                alt={`mailIcon`}
                            />
                        </div>
                        <div
                            className={classNames(
                                'share-container__socials-buttons-container__social-button__social-button-text'
                            )}
                        >
                            {'Email'}
                        </div>
                    </EmailShareButton>
                </div>
                <CopyToClipboard text={eventLink} onCopy={handleOpenSuccessSnackbar}>
                    <div className={classNames('share-container__socials-buttons-container__social-button')}>
                        <div>
                            <img
                                className={classNames(
                                    'share-container__socials-buttons-container__social-button__social-button-icon'
                                )}
                                src={clipboardIcon}
                                alt={`clipboardIcon`}
                            />
                        </div>
                        <div
                            className={classNames(
                                'share-container__socials-buttons-container__social-button__social-button-text'
                            )}
                        >
                            {'Clipboard'}
                        </div>
                    </div>
                </CopyToClipboard>
            </div>
        </div>
    );
}

Share.propTypes = {
    onClose: PropTypes.func.isRequired,
    handleOpenSuccessSnackbar: PropTypes.func.isRequired,
    shareData: PropTypes.object,
};

Share.defaultProps = {
    onClose: () => {},
    handleOpenSuccessSnackbar: () => {},
    shareData: null,
};

export default Share;
