import React from 'react';
import {useCookiesAccept} from 'services/hooks';

const withCookiesAccept = (Component) => {
    return (props) => {
        const cookiesAccepted = useCookiesAccept();
        return <Component {...props} cookiesAccepted={cookiesAccepted} />;
    };
};

export default withCookiesAccept;
