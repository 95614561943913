import React from 'react';
import {usePlatformTranslation} from 'services/hooks';
import NextVideoTimer from '../../NextVideoTimer';

const AuditoriumNextVideo = ({
    currentTimeSlotIndex,
    auditoriumTimeslots,
    auditoriumProgramHasEnded,
    auditoriumHasVideos,
    totalSecondsTillNextVideo,
    loadCurrentTimeSlot,
}) => {
    const translation = usePlatformTranslation();
    return (
        <div className="next-video">
            <div className="next-video-container">
                {currentTimeSlotIndex !== -1 &&
                auditoriumTimeslots[currentTimeSlotIndex] &&
                auditoriumTimeslots[currentTimeSlotIndex].imageUrl ? (
                    <div className="timeslot-background-image">
                        <img src={auditoriumTimeslots[currentTimeSlotIndex].imageUrl} alt="current program" />
                    </div>
                ) : (
                    <>
                        {auditoriumProgramHasEnded ? (
                            <p className="auditorium-program-ended-text">{translation?.auditorium.noVideos}</p>
                        ) : (
                            <>
                                {auditoriumTimeslots.length > 0 && auditoriumHasVideos ? (
                                    <p>
                                        {currentTimeSlotIndex === auditoriumTimeslots.length - 1 ? (
                                            <span> {translation?.auditorium.noVideos}</span>
                                        ) : (
                                            <NextVideoTimer
                                                totalSecondsTillNextVideo={totalSecondsTillNextVideo}
                                                loadCurrentTimeSlot={loadCurrentTimeSlot}
                                            />
                                        )}
                                    </p>
                                ) : (
                                    <p>
                                        <span>{translation?.auditorium.noVideos}</span>
                                    </p>
                                )}
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default AuditoriumNextVideo;
