import {connect} from 'react-redux';
import * as selectors from 'store/selectors';
import OnDemandTab from './OnDemandTab';

export default connect(
    (state) => ({
        eventId: selectors.getEventId(state),
        eventSlug: selectors.getEventSlug(state),
    }),
    {}
)(OnDemandTab);
