import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {NotificationManager} from 'react-notifications';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {LinkOutlined as LinkOutlinedIcon} from '@material-ui/icons';

import './ZoomSessionLinksModule.scss';

export const ZoomSessionLinksModule = ({sessionId, sessionKey, disabled, disabledTextMessage}) => {
    const [loc, setLoc] = useState();
    const [links, setLinks] = useState([]);

    useEffect(() => {
        let location = window.location.href;
        if (location.slice(-1) === '/') {
            setLoc(location);
        } else setLoc(location + '/');
        const regLinks = [
            {
                title: 'Streaming URL',
                url: `rtmp://encoder-${sessionId}-${sessionKey}.uaenorth.azurecontainer.io:1935/zoom`,
                _id: 'rtmp-url',
            },
            {
                title: 'Streaming Key',
                url: `${sessionId}-${sessionKey}`,
                _id: 'session-id',
            },
            {
                title: 'Live streaming page URL',
                url: document.location.href,
                _id: 'streaming-page',
            },
        ];
        setLinks(regLinks);
    }, [loc, sessionId, sessionKey]);

    return (
        <>
            {!!links.length && (
                <div className="zoom-session-links-module">
                    <div className="zoom-session-links-module__container">
                        <div className="zoom-session-links-module__header">
                            <span>RTMP/RTMPS stream URLS</span>
                        </div>
                        <div className="zoom-session-links-module__body">
                            <div className="zoom-session-links-module__link_blocks">
                                {disabled ? (
                                    <div className="zoom-session-links-module__link_block disabled">
                                        <span>{disabledTextMessage}</span>
                                    </div>
                                ) : (
                                    links.map((link) => {
                                        return (
                                            <CopyToClipboard
                                                key={link._id}
                                                text={link.url}
                                                onCopy={() => NotificationManager.info('Copied to clipboard')}
                                            >
                                                <div className="zoom-session-links-module__link_block">
                                                    <span>{link.title}</span>
                                                    <button type="button" className="button" disabled={disabled}>
                                                        <LinkOutlinedIcon className="button_icon" />
                                                        <span>copy</span>
                                                    </button>
                                                </div>
                                            </CopyToClipboard>
                                        );
                                    })
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

ZoomSessionLinksModule.propTypes = {
    sessionId: PropTypes.string.isRequired,
    sessionKey: PropTypes.string,
    disabled: PropTypes.bool,
    disabledTextMessage: PropTypes.string,
};
