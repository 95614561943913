import {makeStyles} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: 24,
        background: theme.palette.background.default,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    row: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        fontSize: '32px',
        fontWeight: 'normal',
        color: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    filterSection: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            width: '100%',
        },
    },
    filter: {
        width: '350px',
        '& > .filters-list': {
            width: '350px',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            borderBottom: '1px solid #CACBD0',
            '& > .filters-list': {
                width: '100%',
            },
        },
    },
    switch: {
        marginRight: 20,
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        '&.isRtl': {
            marginRight: 0,
            marginLeft: 20,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
}));
