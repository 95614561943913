import {PermissionUser} from './PermissionUser';
import {connect} from 'react-redux';
import {getConsumers, getRoomSessions, getVideoRoom, getTimeslotData} from 'store/selectors';

export default connect((state) => ({
    consumersList: getConsumers(state),
    sessions: getRoomSessions(state),
    room: getVideoRoom(state),
    timeslot: getTimeslotData(state)
}))(PermissionUser);
