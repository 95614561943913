import React from 'react';
import {generatePath, useHistory} from 'react-router';
import {RouterConfig} from 'routerConfig';
import {ReactComponent as LiveIcon} from 'Images/live-icon.svg';

import './BackToLiveBtn.scss';

const BackToLiveBtn = ({
    // redux-state
    eventSlug,
}) => {
    const history = useHistory();
    const {prevLocation} = history.location.state;

    const handleOnBackToLive = () => {
        if (!prevLocation) return history.push(generatePath(RouterConfig.event.lobby, {eventSlug}));
        history.push(prevLocation);
    };
    return (
        <button onClick={handleOnBackToLive} className="back-to-live-btn">
            <LiveIcon />
            <span className="back-to-live-btn__text">BACK TO LIVE</span>
        </button>
    );
};

export default BackToLiveBtn;
