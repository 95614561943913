export const GET_ORGANIZER_EVENTS_REQUEST = 'ADMIN/GET_ORGANIZER_EVENTS_REQUEST';
export const GET_ORGANIZER_EVENTS_SUCCESS = 'ADMIN/GET_ORGANIZER_EVENTS_SUCCESS';
export const GET_ORGANIZER_EVENTS_FAIL = 'ADMIN/GET_ORGANIZER_EVENTS_FAIL';

export const DELETE_ORGANIZER_EVENT_REQUEST = 'ADMIN/DELETE_ORGANIZER_EVENT_REQUEST';
export const DELETE_ORGANIZER_EVENT_SUCCESS = 'ADMIN/DELETE_ORGANIZER_EVENT_SUCCESS';
export const DELETE_ORGANIZER_EVENT_FAIL = 'ADMIN/DELETE_ORGANIZER_EVENT_FAIL';

export const UPDATE_ORGANIZER_EVENT_REQUEST = 'ADMIN/UPDATE_ORGANIZER_EVENT_REQUEST';
export const UPDATE_ORGANIZER_EVENT_SUCCESS = 'ADMIN/UPDATE_ORGANIZER_EVENT_SUCCESS';
export const UPDATE_ORGANIZER_EVENT_FAIL = 'ADMIN/UPDATE_ORGANIZER_EVENT_FAIL';

export const CLEAR_EVENTS_LIST = 'ADMIN/CLEAR_EVENTS_LIST';
