import React from 'react';
import {isIE, isYandex} from 'react-device-detect';
import {Snackbar} from '@material-ui/core';

import FullOverlay from '../FullOverlay';
import PlatformMenu from '../PlatformMenu';
import HeaderMenu from '../HeaderMenu';
import CookieBanner from './components/CookieBanner';
import RedirectOnLogin from './components/RedirectOnLogin/RedirectOnLogin';
import AdminMenu from './components/AdminMenu';
import EventMenu from './components/EventMenu';

import {
    connectToNotifications,
    disconnectFromNotifications,
    notificationReceived,
    flashNotificationReceived,
    disconnectFromEvent,
} from 'Api/socketApi';

class Menu extends React.Component {
    clearCacheAndReloadAppTimer = null;

    state = {
        snackbar: {
            message: 'You have been removed from the group chat.',
            open: false,
        },
        showRemovedFromEventDialog: false,
    };

    componentDidMount() {
        // always connect to notifications at platform level
        localStorage.setItem('triggeredEventReload', 'false');
        notificationReceived((err, data) => {
            const notification = data.notification;

            if (notification.type === 'clearCacheAndReloadApp') {
                this.clearCacheAndReloadAppNotification();
            } else if (notification.type === 'reloadEventData') {
                this.reloadEventDataNotification(notification);
            } else if (notification.type === 'newPrivateChatMessage') {
                this.newPrivateChatMessageNotification(data);
            } else if (notification.type === 'newGroupChatMessage' || notification.type === 'addedToGroupChat') {
                this.newGroupChatMessageNotification(data);
            } else if (notification.type === 'removedFromGroupChat') {
                this.removedFromGroupChatNotification(notification);
            } else if (notification.type === 'removedFromEvent') {
                this.removedFromEventNotification(notification);
            } else if (
                notification.type === 'eventParticipantProfileCreated' ||
                notification.type === 'eventExhibitorProfileCreated'
            ) {
                this.props.onRefreshUserUserData();
                this.props.resetEventPaymentLoading();
            } else {
                this.props.onNewNotification(notification);
            }
        });

        flashNotificationReceived((err, data) => {
            this.props.onFlashNotificationReceived(data);
        });
    }

    componentDidUpdate(prevProps) {
        // we always connect the user to notifications AFTER HE LOGGED IN
        // WE CONNECT HIM ONLY ONCE
        if (prevProps.loggedIn === false && this.props.loggedIn === true) {
            if (!this.props.userConnectedToNotifications) {
                connectToNotifications({userId: this.props.user._id});
                this.props.onSetUserConnectedToNotifications();
            }
        }

        // if the user logged out we make sure we also disconnect him from Notifications
        if (prevProps.loggedIn === true && this.props.loggedIn === false) {
            if (prevProps.user && prevProps.user._id) {
                disconnectFromNotifications({userId: prevProps.user._id});
            }
        }
    }

    componentWillUnmount() {
        if (this.clearCacheAndReloadAppTimer) {
            clearTimeout(this.clearCacheAndReloadAppTimer);
        }
    }

    clearCacheAndReloadAppNotification = () => {
        // set a timeout in milliseconds (1 ms  ...  2 minutes)
        let randomIntervalInMilliseconds = Math.floor(Math.random() * (120000 - 1 + 1)) + 1;
        this.clearCacheAndReloadAppTimer = setTimeout(() => window.location.reload(true), randomIntervalInMilliseconds);
    };

    reloadEventDataNotification = (notification) => {
        const {eventId, onUpdateEventData} = this.props;
        // set a timeout in milliseconds (1 ms  ...  2 minutes)
        if (notification.eventId === eventId) {
            onUpdateEventData(notification.event);
        }
    };

    newPrivateChatMessageNotification = (data) => {
        // if we have the private chat, we update the last message of that chat
        // if we don't have the private chat, it means it is a newly created chat that we don't have
        // and we refresh all the user chats
        // we do the same for the group chats
        let privateChatExists = this.props.user.privateChats.find(
            (chat) => chat.chat._id === data.notification.objectId
        );
        if (privateChatExists) {
            this.props.newPrivateChatMessage(data);
        } else {
            this.props.onGetPrivateChats();
        }
    };

    newGroupChatMessageNotification = (data) => {
        let groupChatExists = this.props.user.groupChats.find((chat) => chat.chat._id === data.notification.objectId);
        if (groupChatExists) {
            this.props.newGroupChatMessage(data);
        } else {
            this.props.onGetGroupChats();
        }
    };

    removedFromGroupChatNotification = (notification) => {
        if (notification.objectId === this.props.activeChatRoomId) {
            this.props.onCloseTopNav();
            // show a snackbar to inform the user he has been removed from the group chat
            // Benji request: do not show snackbar notification, kept the rest of the functionality
            // this.setState({snackbar: {...this.state.snackbar, open: true}});
            this.props.onGetGroupChats();
        } else {
            this.props.onGetGroupChats();
        }
    };

    removedFromEventNotification = (notification) => {
        if (notification.eventId === this.props.eventId) {
            this.setState({showRemovedFromEventDialog: true});
        } else {
            this.props.onRefreshUserUserData();
        }
    };

    reloadEventData = () => {
        const {eventId, onGetEvent} = this.props;
        localStorage.setItem('triggeredEventReload', 'false');
        onGetEvent(eventId);
    };

    handleCloseSnackbar = () => {
        this.setState({snackbar: {...this.state.snackbar, open: false}});
    };

    handleLogoutUser = () => {
        // if user logged out we should also disconnect him from notifications
        this.setState({showRemovedFromEventDialog: false});
        const {user, event} = this.props;
        disconnectFromNotifications({userId: user._id});
        disconnectFromEvent({userId: user._id, eventId: event._id});
        this.props.onLogoutUser();
    };

    handleCloseModal = () => {
        this.props.onRefreshUserUserData();
        this.setState({showRemovedFromEventDialog: false});
    };

    render() {
        const {layout, loggedIn, eventId} = this.props;
        return (
            <div className="menu">
                {layout.menu.platformMenu && <PlatformMenu />}
                {layout.menu.eventPublicMenu && <HeaderMenu />}
                {layout.menu.eventProtectedMenu && loggedIn && eventId && !isYandex && !isIE && <EventMenu />}
                {layout.menu.adminMenu && loggedIn && <AdminMenu />}
                <RedirectOnLogin />
                <CookieBanner />
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                    key={this.state.snackbar.message}
                    open={this.state.snackbar.open}
                    onClose={this.handleCloseSnackbar}
                    autoHideDuration={10000}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{this.state.snackbar.message}</span>}
                />
                {this.state.showRemovedFromEventDialog && (
                    <FullOverlay
                        title="Event is unavailable"
                        text={'You have been removed from this event.'}
                        buttonText="Log out"
                        closeOnRedirect={true}
                        handleClose={this.handleCloseModal}
                        handleClick={this.handleLogoutUser}
                    />
                )}
            </div>
        );
    }
}

export default Menu;
