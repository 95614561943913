import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getEventsWithAppliedJobs} from '../../../../../../../store/actions/eventsWithApplicationsActions';

import AccountLeftSideTabs from '../AccountLeftSideTabs/AccountLeftSideTabs';
import AppliedJobs from './AppliedJobs';

import './AppliedJobsSwitch.scss';

const AppliedJobsSwitch = () => {
    const dispatch = useDispatch();
    const activeTabId = useSelector((state) => state.account.leftSideTabs.activeTabId);
    const userId = useSelector((state) => state.user.data._id);
    const {eventList} = useSelector((state) => state.eventsWithApplications);

    useEffect(() => {
        dispatch(getEventsWithAppliedJobs(userId));
    }, [userId, dispatch]);

    if (eventList?.length === 0) {
        return null;
    }

    return (
        <div className="appliedJobsSwitch">
            <AccountLeftSideTabs>
                {eventList.map(({eventId, title}) => (
                    <AccountLeftSideTabs.Item key={eventId} title={title} />
                ))}
            </AccountLeftSideTabs>
            <AppliedJobs eventId={eventList[activeTabId].eventId} eventName={eventList[activeTabId].title} />
        </div>
    );
};

export default AppliedJobsSwitch;
