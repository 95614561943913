import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    videoPlate: {
        width: '100%',
        height: '100%',
        backgroundColor: '#000',
        position: 'absolute',
        transition: '.2s linear',
        top: 0,
        right: 0,
        overflow: 'hidden',
    },
    activeStreamsVideoPlate: {
        width: 120,
        height: 80,
        zIndex: 150,
        overflow: 'hidden',
        border: '1px solid #fff',
        backgroundColor: '#000',
    },
    video: {
        height: '100%',
        objectFit: ({isMobile}) => (isMobile ? 'unset' : 'cover'),
    },
    userName: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    hidden: {
        display: 'none',
    },
    rotated: {
        transform: 'rotate(90deg)',
    },
}));
