import React, {Component, Suspense, lazy} from 'react';
import {connect} from 'react-redux';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TwitterConvTrkr from 'react-twitter-conversion-tracker';

import EventPublicRoute from './components/EventPublicRoute/EventPublicRoute';
import PrivateRoute from './components/PrivateRoute';

import Spinner from './components/Spinner';
import Menu from './components/Menu';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import LoginEvent from './components/LoginEvent/LoginEvent';
import RegisterEvent from './components/RegisterEvent';
import RegisterAsParticipant from './components/RegisterAsParticipant';
import RegisterAsExhibitor from './components/RegisterAsExhibitor';
import LoginPlatform from './components/LoginPlatform/LoginPlatform';
import RegisterPlatform from './components/RegisterPlatform';
import WelcomeVideo from './components/WelcomeVideo';
import HomepagePlatform from './components/HomepagePlatform/HomepagePlatform';
import ViewAllEvents from './components/HomepagePlatform/components/ViewAllEvents';
import PasswordRecovery from './components/PasswordRecovery';
import NewsletterUnsubscribe from './components/NewsletterUnsubscribe/NewsletterUnsubscribe';
import CreateEvent from './components/CreateEvent/CreateEvent';
import HomepageEvent from './components/HomepageEvent';
import Auditorium from './components/Auditorium';
import AuditoriumArhive from './components/AuditoriumArhive';
import AuditoriumArhiveSingleVideo from './components/AuditoriumArhiveSingleVideo';
import MyAccount from './components/MyAccount';
import NotFound from './components/NotFound';
import Lobby from './components/Lobby';
import Village from './components/Village';
import Showfloor from './components/Showfloor';
import PrivacyPolicy from './components/PrivacyPolicy/PrivacyPolicy';
import EventPrivacyPolicy from './components/EventPrivacyPolicy/EventPrivacyPolicy';

import Cookies from './components/Cookies/Cookies';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import EventArchive from './components/EventArchive';
import EventArchiveVideo from './components/EventArchiveVideo/EventArchiveVideo';
import SessionDashboard from './components/SessionDashboard';
import ZoomSessionDashboard from './components/ZoomSessionDashboard/ZoomSessionDashboard';
import SuccessIframeRegistration from './components/SuccessIframeRegistration/SuccessIframeRegistration';
import SocialsPromotion from './components/SocialsPromotion';

import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import {RouterConfig} from '../routerConfig';
import ForceResetPassword from './components/ForceResetPassword';
import {COOKIES_ACCEPTED_KEY, IS_EGYPT_PAVILION_ENV, IS_UNCTAD_ENV} from 'constants/shared';
import SessionsList from './components/SessionsList';
import {twitterPixelId} from 'config';

const ExhibitorDashboard = lazy(() => import('./components/ExhibitorDashboard/ExhibitorDashboard'));
const ExhibitorBoothView = lazy(() => import('./components/ExhibitorDashboard/components/ExhibitorBoothView'));
const OrganizerDashboard = lazy(() => import('./components/OrganizerDashboard'));

const Dashboard = lazy(() => import('./components/Dashboard'));
const OrganizerDetails = lazy(() => import('./components/Dashboard/components/OrganizerDetails'));

export const history = createBrowserHistory();
history.listen((location) => {
    if (localStorage.getItem(COOKIES_ACCEPTED_KEY)) {
        ReactGA.set({page: location.pathname});
        ReactGA.pageview(location.pathname);
        if (IS_EGYPT_PAVILION_ENV) {
            ReactPixel.pageView();
            TwitterConvTrkr.init(twitterPixelId);
            TwitterConvTrkr.pageView();
        }
    }
});

class Routes extends Component {
    componentDidMount() {
        if (localStorage.getItem(COOKIES_ACCEPTED_KEY)) {
            ReactGA.pageview(window.location.pathname);
            if (IS_EGYPT_PAVILION_ENV) {
                ReactPixel.pageView();
                TwitterConvTrkr.init(twitterPixelId);
                TwitterConvTrkr.pageView();
            }
        }
        this.props.onAutoLoginWithToken();

        this.unlisten = history.listen((location, action) => {
            const {eventSlug} = this.props;
            const urlEventSlug = location.pathname.replace('/event/', '').split('/')[0];

            if (eventSlug !== urlEventSlug) {
                this.props.setEventSlug(urlEventSlug);
            }
        });
    }

    componentDidUpdate() {
        if (
            history?.location?.pathname.includes('error') &&
            history?.location?.pathname !== RouterConfig.redirectedError
        ) {
            window.location.replace('/error');
        }
    }

    componentWillUnmount() {
        this.unlisten();
    }
    render() {
        return (
            <BrowserRouter history={history}>
                <Menu />
                <ScrollToTop />
                <Suspense fallback={<Spinner />}>
                    <Switch>
                        <Route path={RouterConfig.login} component={LoginPlatform} exact />
                        <Route path={RouterConfig.register} component={RegisterPlatform} exact />
                        <Route path={RouterConfig.passwordRecovery} component={PasswordRecovery} exact />
                        <Route path={RouterConfig.newsletterUnsubscribe} component={NewsletterUnsubscribe} exact />
                        <Route path={RouterConfig.public.privacyPolicy} component={PrivacyPolicy} exact />
                        <Route path={RouterConfig.public.cookies} component={Cookies} exact />
                        <Route path={RouterConfig.public.termsAndConditions} component={TermsAndConditions} />
                        <Route path={RouterConfig.public.eventPrivacyPolicy} component={PrivacyPolicy} exact />
                        <Route path={RouterConfig.public.eventCookies} component={Cookies} exact />
                        <Route
                            path={RouterConfig.public.eventTermsAndConditions}
                            component={TermsAndConditions}
                            exact
                        />

                        <PrivateRoute path={RouterConfig.event.welcome} component={WelcomeVideo} exact />
                        <PrivateRoute path={RouterConfig.createEvent} component={CreateEvent} exact />

                        <EventPublicRoute
                            path={RouterConfig.public.eventIframeRegistration}
                            component={SuccessIframeRegistration}
                            exact
                        />
                        <EventPublicRoute
                            path={RouterConfig.public.eventCustomPrivacyPolicy}
                            component={EventPrivacyPolicy}
                            exact
                        />
                        <EventPublicRoute path={RouterConfig.event.homepage} component={HomepageEvent} exact />
                        <EventPublicRoute path={RouterConfig.event.login} component={LoginEvent} exact />
                        <EventPublicRoute path={RouterConfig.event.register} component={RegisterEvent} exact />

                        <EventPublicRoute
                            path={RouterConfig.event.participantRegistration}
                            component={RegisterAsParticipant}
                            exact
                        />
                        <EventPublicRoute
                            path={RouterConfig.event.exhibitorRegistration}
                            component={RegisterAsExhibitor}
                            exact
                        />

                        <PrivateRoute path={RouterConfig.event.lobby} component={Lobby} exact />
                        <PrivateRoute path={RouterConfig.event.speakerSessionsList} component={SessionsList} exact />
                        <PrivateRoute path={RouterConfig.event.moderatorSessionsList} component={SessionsList} exact />
                        <PrivateRoute
                            path={RouterConfig.event.interpreterSessionsList}
                            component={SessionsList}
                            exact
                        />
                        <PrivateRoute path={RouterConfig.event.showfloor} component={Showfloor} exact />
                        <PrivateRoute path={RouterConfig.event.auditorium} component={Auditorium} exact />
                        <PrivateRoute path={RouterConfig.event.auditoriumArchive} component={AuditoriumArhive} exact />
                        <PrivateRoute
                            path={RouterConfig.event.auditoriumArhiveSingleVideo}
                            component={AuditoriumArhiveSingleVideo}
                            exact
                        />

                        <PrivateRoute path={RouterConfig.event.archive} component={EventArchive} exact />
                        <PrivateRoute path={RouterConfig.event.archiveVideo} component={EventArchiveVideo} exact />
                        <PrivateRoute path={RouterConfig.event.village.root} component={Village} exact />
                        <PrivateRoute path={RouterConfig.event.village.buildingLobby} component={Lobby} exact />
                        <PrivateRoute
                            path={RouterConfig.event.village.buildingAuditorium}
                            component={Auditorium}
                            exact
                        />
                        <PrivateRoute path={RouterConfig.event.village.buildingShowfloor} component={Showfloor} exact />
                        <PrivateRoute
                            path={RouterConfig.event.village.buildingAuditoriumArchive}
                            component={AuditoriumArhive}
                            exact
                        />
                        <PrivateRoute
                            path={RouterConfig.event.village.buildingAuditoriumArchiveSingleVideo}
                            component={AuditoriumArhiveSingleVideo}
                            exact
                        />
                        <PrivateRoute path={RouterConfig.event.myAccount} component={MyAccount} />

                        <PrivateRoute path={RouterConfig.event.exhibitorDashboard} component={ExhibitorDashboard} />
                        <PrivateRoute path={RouterConfig.event.exhibitorBoothView} component={ExhibitorBoothView} />

                        <PrivateRoute path={RouterConfig.organizerDashboard.root} component={OrganizerDashboard} />

                        <PrivateRoute
                            path={RouterConfig.event.zoomSessionDashboard}
                            component={ZoomSessionDashboard}
                            exact
                        />

                        <PrivateRoute path={RouterConfig.masterAdmin.dashboard} component={Dashboard} exact />
                        <PrivateRoute
                            path={RouterConfig.masterAdmin.organizerDetails}
                            component={OrganizerDetails}
                            exact
                        />

                        <PrivateRoute
                            strict
                            path={RouterConfig.event.etxSessionDashboard}
                            component={SessionDashboard}
                        />

                        <Route path={RouterConfig.root} component={HomepagePlatform} exact />
                        <Route path={RouterConfig.viewAll} component={ViewAllEvents} exact />
                        <Route path={RouterConfig.public.socialsPromotion} component={SocialsPromotion} exact />
                        <Route component={NotFound} />
                    </Switch>
                </Suspense>
                {IS_UNCTAD_ENV && <ForceResetPassword open={this.props.user?.forcePasswordUpdate} />}
            </BrowserRouter>
        );
    }
}

export default connect(
    (state) => ({
        loggedIn: selectors.getIsUserLoggedIn(state),
        eventSlug: selectors.getEventSlug(state),
        user: selectors.getUserData(state),
    }),
    {
        onAutoLoginWithToken: actions.autoLoginWithToken,
        setEventSlug: actions.setEventSlug,
    }
)(Routes);
