import {connect} from 'react-redux';

import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import {PasswordRecovery} from './PasswordRecovery';

export default connect(
    (state) => ({
        loggedIn: selectors.getIsUserLoggedIn(state),
    }),
    {
        onAutoLoginWithToken: actions.autoLoginWithToken,
    }
)(PasswordRecovery);
