import {useCallback, useEffect, useContext} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import {
    setActiveWall,
    setEventProtectedMenu,
    setEventSlug,
    setTimeSlotVideoWall,
    getTimeslotData,
    receiveChatMessage,
    getAuthTokenForVideoEngine,
} from 'store/actions';
import * as selectors from 'store/selectors';
import {RouterConfig} from 'routerConfig';
import {AbilityContext} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

import {
    connectToTimeslotChat,
    disconnectFromTimeslotChat,
    connectToTimeslot,
    disconnectFromTimeslot,
    timeslotMessageReceived,
} from 'Api/socketApi';

export const useSessionDashboard = () => {
    const dispatch = useDispatch();
    const {eventSlug, auditoriumId, sessionId} = useParams();
    const {push} = useHistory();
    const ability = useContext(AbilityContext);
    const isReady = useSelector(selectors.getDashboardReady);

    const isDashboardAccess = ability.can(
        ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY,
        ROLE_FEATURES_TYPES.ETX_SESSION_DASHBOARD
    );

    const getNewmessages = useCallback(() => {
        timeslotMessageReceived((message) => dispatch(receiveChatMessage(message)));
    }, [dispatch]);

    const setWall = useCallback(
        (wallId) => {
            let highlightUsers = [];
            // we set up the current wall Id to be the video Wall Id
            const wallData = {
                wallId,
                highlightUsers,
            };
            dispatch(setTimeSlotVideoWall(wallData));
            dispatch(setActiveWall(wallData));
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch(setEventSlug(eventSlug));
        dispatch(setEventProtectedMenu());

        if (!isDashboardAccess) push(RouterConfig.root);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [push, isDashboardAccess]);

    useEffect(() => {
        connectToTimeslotChat({timeslotId: sessionId});
        connectToTimeslot({timeslotId: sessionId});
        getNewmessages();

        return () => {
            disconnectFromTimeslotChat({timeslotId: sessionId});
            disconnectFromTimeslot({timeslotId: sessionId});
        };
    }, [getNewmessages, sessionId]);

    useEffect(() => {
        dispatch(
            getTimeslotData({
                auditoriumId,
                timeslotId: sessionId,
                callback: (sessionData) => setWall(sessionData.videoWall),
            })
        );

        isReady && dispatch(getAuthTokenForVideoEngine({auditoriumId, timeslotId: sessionId}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId, isReady]);
};
