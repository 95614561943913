import React from 'react';
import {useDispatch} from 'react-redux';

import BusinessCardEditingForm from '../BusinessCardEditingForm/BusinessCardEditingForm';
import BusinessCardEditingIconBox from '../IconBox/IconBox';
import BusinessCardEditingIconArrow from './components/IconArrow/IconArrow';

import {changeBusinessCardField, setBusinessCardIsFormValid} from 'store/actions';
import {useBusinessCardData, usePlatformTranslation} from 'services/hooks';
import './BusinessCardEditing.scss';

const BusinessCardEditing = () => {
    const dispatch = useDispatch();
    const {businessCard, isLoading, isError, isReady} = useBusinessCardData();
    const translation = usePlatformTranslation();

    if (isLoading) {
        return (
            <div className="BusinessCardEditing">
                <div className="BusinessCardEditing__loader">{translation?.generalText.loading}</div>
            </div>
        );
    }

    if (isError) {
        return (
            <div className="BusinessCardEditing">
                <div className="BusinessCardEditing__error">{translation?.errors.somethingWentWrong}</div>
            </div>
        );
    }

    if (!isReady) {
        return null;
    }

    if (!businessCard) {
        return (
            <div className="BusinessCardEditing">
                <div className="BusinessCardEditing__creating">
                    <div className="BusinessCardEditing__creatingBoxIcon">
                        <BusinessCardEditingIconBox />
                    </div>

                    <div className="BusinessCardEditing__creatingArrowIcon">
                        <BusinessCardEditingIconArrow />
                    </div>

                    <div className="BusinessCardEditing__creatingMessage">{translation.businessCard?.noCardAdded}</div>

                    <div className="BusinessCardEditing__creatingMessage BusinessCardEditing__creatingMessage_highlighted">
                        {translation.businessCard?.cardSetYourUp}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="BusinessCardEditing">
            <BusinessCardEditingForm
                businessCard={businessCard}
                onChange={(...args) => dispatch(changeBusinessCardField(...args))}
                setIsFormValid={(...args) => dispatch(setBusinessCardIsFormValid(...args))}
            />
        </div>
    );
};

export default BusinessCardEditing;
