import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {ValidatorForm, TextValidator} from 'react-material-ui-form-validator';
import {MenuItem, FormControl, Select, FormControlLabel} from '@material-ui/core';

import PlatformMenu from '../PlatformMenu';
import Spinner from '../Spinner';
import CustomCheckbox from '../CustomCheckbox/CustomCheckbox';

import * as actions from '../../../store/actions/index';
import {getCurrentDay} from '../../../services/utils';
import moment from 'moment';
import {getPlatformBackground, whiteLabel} from '../../../config';
import {IS_SCA_ENV} from '../../../constants/shared';

class CreateEvent extends React.Component {
    state = {
        fields: [
            {
                name: 'name',
                type: 'text',
                value: '',
                label: 'Event Name',
                multiline: 0,
                validators: ['required'],
                errorMessages: ['Field is required'],
                disabled: false,
                isDateInput: false,
            },
            {
                name: 'organizerCompanyName',
                type: 'text',
                value: this.props.orgName || '',
                label: 'Company Name',
                multiline: 0,
                validators: ['required'],
                errorMessages: ['Field is required'],
                disabled: this.props.orgName,
                isDateInput: false,
            },
            {
                name: 'organizerPhone',
                type: 'text',
                value: this.props.userPhone || '',
                label: 'Phone Number',
                multiline: 0,
                validators: ['required'],
                errorMessages: ['Field is required'],
                disabled: this.props.userPhone,
                isDateInput: false,
            },
            {
                name: 'eventStartDate',
                type: 'date',
                value: getCurrentDay(),
                label: 'Event Start Date',
                multiline: 0,
                validators: ['required'],
                errorMessages: ['Field is required'],
                disabled: false,
                isDateInput: true,
            },
            {
                name: 'eventEndDate',
                type: 'date',
                value: getCurrentDay(),
                label: 'Event End Date',
                multiline: 0,
                validators: ['required'],
                errorMessages: ['Field is required'],
                disabled: false,
                isDateInput: true,
            },
            {
                name: 'maxParticipantsCount',
                type: 'number',
                value: 1000,
                label: 'Max number of Participants',
                multiline: 0,
                validators: [],
                errorMessages: [],
                disabled: false,
                isDateInput: false,
                inputProps: {
                    min: 1,
                },
                isMasterAdmin: true,
            },
            {
                name: 'maxExhibitorsCount',
                type: 'number',
                value: 15,
                label: 'Max number of Exhibitors',
                multiline: 0,
                validators: [],
                errorMessages: [],
                disabled: false,
                isDateInput: false,
                inputProps: {
                    min: 1,
                },
                isMasterAdmin: true,
            },
            {
                name: 'maxBothAvatarsCount',
                type: 'number',
                value: 30,
                label: 'Max number of Avatars',
                multiline: 0,
                validators: [],
                errorMessages: [],
                disabled: false,
                isDateInput: false,
                inputProps: {
                    min: 1,
                },
                isMasterAdmin: true,
            },
        ],
        nrOfBuildings: 2,
        eventType: 'default',
        noDate: false,
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.onSetPlatformMenu();
    }

    componentWillUnmount() {
        this.props.onClearError();
    }

    handleFieldChange = (index) => (e) => {
        let updatedFields = [...this.state.fields];
        updatedFields[index].value = updatedFields[index].type === 'number' ? +e.target.value : e.target.value;
        this.setState({fields: updatedFields});
    };

    handleChangeNoDate = (e) => {
        this.setState({noDate: e.target.checked});
    };

    handleChangeEventType = (e) => {
        this.setState({eventType: e.target.value});
    };

    handleChangeNumberOfBuildings = (e) => {
        this.setState({nrOfBuildings: e.target.value});
    };

    /* eslint-disable */
    handleCreateEvent = () => {
        const {fields} = this.state;
        const {history} = this.props;
        const data = {};
        fields.forEach((field) => {
            data[field.name] = field.value;
        });

        if (moment().isDST()) {
            data.dst = 'isDst';
        }

        if (this.state.noDate) {
            data.eventStartDate = getCurrentDay();
            data.eventEndDate = getCurrentDay();
        }

        // if the eventType
        if (this.state.eventType === 'default') {
            data.hasVillage = false;
        } else {
            data.hasVillage = true;
            data.nrOfBuildings = this.state.nrOfBuildings;
        }

        this.props.onCreateEvent(data, () => this.props.refreshUserData());
    };
    /* eslint-enable */

    render() {
        let {fields, noDate} = this.state;
        let {loadingEvent, loadingUser, isRtlLanguage} = this.props;
        const numberOfBuildingsOption = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
        // validator dates
        let errorDate = false;
        const startDate = fields.find((el) => el.name === 'eventStartDate');
        const endDate = fields.find((el) => el.name === 'eventEndDate');
        if (!noDate && endDate.value < startDate.value) errorDate = true;
        const BackgroundImage = getPlatformBackground(whiteLabel);

        return (
            <div>
                <PlatformMenu />
                <div className="register-participant-step-1 create-new-event-page">
                    <div className="full-background-container p-relative d-flex">
                        <img
                            src={BackgroundImage}
                            className="full-background-img img-cover p-absolute w-100 h-100"
                            alt="background"
                        />
                        {!IS_SCA_ENV && <div className="full-background-overlay p-absolute w-100 h-100"></div>}
                        <div className="header-spacing-container">
                            <div className="form-wrapper w-100 h-100 d-flex justify-content-center align-items-center">
                                <div className="form-logo"></div>
                                <div className="form-container d-flex">
                                    <div className="right-form">
                                        <div className="form-header">
                                            <h1 className="form-title">SET UP Event</h1>
                                            <p>
                                                You will be able to add full event details in your organizer dashboard
                                            </p>
                                        </div>
                                        <div className="form-body">
                                            <div
                                                className={classNames('register-form-wrapper', {isRtl: isRtlLanguage})}
                                            >
                                                <ValidatorForm ref="form" onSubmit={this.handleCreateEvent}>
                                                    {fields.map((field, index) => {
                                                        if (field.isMasterAdmin && !this.props.user.data.isMasterAdmin)
                                                            return null;
                                                        return (
                                                            <TextValidator
                                                                key={field.name}
                                                                label={field.label}
                                                                type={field.type}
                                                                name={field.name}
                                                                index={index}
                                                                autoFocus={index === 0}
                                                                value={field.value}
                                                                style={{marginBottom: '17px'}}
                                                                InputProps={field.inputProp}
                                                                hidden={true}
                                                                disabled={
                                                                    !!field.disabled ||
                                                                    (field.isDateInput && this.state.noDate)
                                                                }
                                                                variant="filled"
                                                                onChange={this.handleFieldChange(index)}
                                                                validators={field.validators}
                                                                errorMessages={field.errorMessages}
                                                                multiline={field.multiline > 0}
                                                                rows={field.multiline}
                                                                fullWidth={true}
                                                                margin="normal"
                                                                className="field-container"
                                                                error={field.name === 'eventEndDate' && errorDate}
                                                                helperText={
                                                                    field.name === 'eventEndDate' && errorDate
                                                                        ? 'Please re-check the dates!'
                                                                        : null
                                                                }
                                                            />
                                                        );
                                                    })}
                                                    <FormControlLabel
                                                        labelPlacement={'start'}
                                                        control={
                                                            <CustomCheckbox
                                                                checked={noDate}
                                                                onChange={this.handleChangeNoDate}
                                                                name="date"
                                                                color="primary"
                                                            />
                                                        }
                                                        label={<span>Don't know the date yet</span>}
                                                    />
                                                    <div className="select-event-layout-container">
                                                        <span className="section-title">Event layout</span>
                                                        <div className="options-container">
                                                            <div className="single-option-container event-type-option">
                                                                <FormControl
                                                                    variant="outlined"
                                                                    fullWidth={true}
                                                                    className="field-container"
                                                                >
                                                                    <Select
                                                                        onChange={this.handleChangeEventType}
                                                                        value={this.state.eventType}
                                                                    >
                                                                        <MenuItem value={'default'}>DEFAULT</MenuItem>
                                                                        <MenuItem value={'village'}>THE HUB</MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </div>
                                                            {this.state.eventType === 'village' && (
                                                                <div className="single-option-container buildings-number-option">
                                                                    <FormControl
                                                                        variant="outlined"
                                                                        fullWidth={true}
                                                                        className="field-container"
                                                                    >
                                                                        <Select
                                                                            onChange={
                                                                                this.handleChangeNumberOfBuildings
                                                                            }
                                                                            value={this.state.nrOfBuildings}
                                                                        >
                                                                            {numberOfBuildingsOption.map(
                                                                                (nrOfBuildings) => {
                                                                                    return (
                                                                                        <MenuItem
                                                                                            key={nrOfBuildings}
                                                                                            value={nrOfBuildings}
                                                                                        >
                                                                                            {nrOfBuildings} Buildings
                                                                                        </MenuItem>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </Select>
                                                                    </FormControl>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <button
                                                        disabled={errorDate}
                                                        className={'register-button ' + (errorDate ? 'disabled' : '')}
                                                        type="submit"
                                                    >
                                                        Create Event
                                                    </button>
                                                </ValidatorForm>
                                            </div>
                                            {(loadingUser || loadingEvent) && <Spinner />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        orgName: state.user.data.organizerCompanyName,
        userPhone: state.user.data.organizerPhone,
        loggedIn: state.user.loggedIn,
        loadingUser: state.user.loading,
        error: state.user.error,
        loadingEvent: state.event.loading,
        user: state.user,
        eventSlug: state.event.eventSlug,
        isRtlLanguage: state.languages.isRtlLanguage,
    }),
    {
        onCreateEvent: actions.createEvent,
        onClearError: actions.clearError,
        refreshUserData: actions.autoLoginWithToken,
        setEventId: actions.setEventId,
        setEventSlug: actions.setEventSlug,
        onSetPlatformMenu: actions.setPlatformMenu,
    }
)(CreateEvent);
