import React from 'react';

import MobileBusinessCardSetupPanel from '../MobileBusinessCardSetupPanel/MobileBusinessCardSetupPanel';
import SetupBusinessCardPreview from '../../../../../SetupBusinessCardPreview/SetupBusinessCardPreview';

import {usePlatformTranslation} from '../../../../../../../../../../../../../services/hooks';

const MobileBusinessCardPreview = () => {
    const {businessCard: businessCardTranslation} = usePlatformTranslation();

    return (
        <MobileBusinessCardSetupPanel title={businessCardTranslation.cardPreview}>
            <SetupBusinessCardPreview />
        </MobileBusinessCardSetupPanel>
    );
};

export default MobileBusinessCardPreview;
