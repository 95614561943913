import classNames from 'classnames';
import React, {useState} from 'react';
import Scrollbars from 'react-custom-scrollbars';

const Building = ({building, number, onClick}) => {
    const [descriptionEl, setDescriptionEl] = useState(null);
    const [descriptionElHeight, setDescriptionElHeight] = useState(0);

    return (
        <div
            className={classNames('clickable-element', 'building', `building${number}`)}
            onMouseEnter={() => setDescriptionElHeight(descriptionEl?.clientHeight || 0)}
            onClick={onClick}
        >
            <div className="positioning-container">
                <div className="building-overlay" />
                <div className="building-data">
                    <div className="building-info-container">
                        <div className="building-data-name">{building.name}</div>
                        <div className="building-data-description" ref={setDescriptionEl}>
                            <Scrollbars autoHeight autoHeightMax={descriptionElHeight}>
                                {building.description}
                            </Scrollbars>
                        </div>
                    </div>
                    <span className="building-data-link" onClick={onClick}>
                        Click to open
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Building;
