import React from 'react';
import {Button} from '@material-ui/core';

import {IS_SCA_ENV} from '../../../constants/shared';

import './FullOverlay.scss';

export class FullOverlay extends React.Component {
    redirectToEvent = (eventSlug, eventId) => (e) => {
        if (eventSlug !== this.props.eventSlug) {
            const {user} = this.props;
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);
            if (userRolesInEvent.roles.includes('organizer')) {
                this.props.history.push(`/event/${eventSlug}/dashboard`);
            } else if (userRolesInEvent.roles.includes('exhibitor')) {
                this.props.history.push(`/event/${eventSlug}/exhibitor-dashboard`);
            } else if (userRolesInEvent.roles.includes('exhibitorRep')) {
                // this.props.history.push(`/event/${eventSlug}/exhibitor-booth-view`);
                this.props.history.push(`/event/${eventSlug}/lobby`);
            } else {
                this.props.history.push(`/event/${eventSlug}/lobby`);
            }
            this.props.setEventSlug(eventSlug);
            this.props.setEventId(eventId);

            // close modal when organizer unregisters user
            if (this.props.closeOnRedirect) {
                this.props.handleClose();
            }
        }
    };

    redirectToCreateEvent = () => {
        // close modal when organizer unregisters user
        if (this.props.closeOnRedirect) {
            this.props.handleClose();
        }

        this.props.history.push(`/create-event`);
    };

    render() {
        const {user, eventId} = this.props;
        return (
            <div className="full-overlay">
                <div className="full-overlay__content">
                    <h3 className="full-overlay__title">{this.props.title}</h3>
                    <p>{this.props.text}</p>
                    {this.props.loggedIn && user.eventRoles.length > 0 && (
                        <div className="full-overlay__events-dialog">
                            <p>
                                {user.eventRoles.length > 1 ? 'Check out your other events:' : 'No other events found'}
                            </p>
                            <ul className="my-events-list">
                                {user.eventRoles
                                    .slice(0)
                                    .reverse()
                                    .map((eventRole) => {
                                        if (eventId === eventRole.event._id) {
                                            return null;
                                        }
                                        return (
                                            <li
                                                className={'row ' + (eventId === eventRole.event._id ? 'active' : '')}
                                                key={eventRole.event._id}
                                                onClick={this.redirectToEvent(
                                                    eventRole.event.slug,
                                                    eventRole.event._id
                                                )}
                                            >
                                                <span>{eventRole.event.name}</span>
                                            </li>
                                        );
                                    })}
                                {!IS_SCA_ENV && (
                                    <li className="row" onClick={this.redirectToCreateEvent}>
                                        <span>+ Create event</span>
                                    </li>
                                )}
                            </ul>
                        </div>
                    )}
                    <Button className="full-overlay__button" variant="text" onClick={this.props.handleClick}>
                        {this.props.buttonText}
                    </Button>
                </div>
            </div>
        );
    }
}
