import {useSelector} from 'react-redux';
import {
    getBusinessCard,
    getBusinessCardIsValid,
    getBusinessCardLoadState,
    getBusinessCardWasChanged,
} from 'store/selectors';
import {checkIsError, checkIsReady, checkIsLoading} from 'services/utils';

const useBusinessCardData = () => {
    const businessCard = useSelector(getBusinessCard);
    const loadState = useSelector(getBusinessCardLoadState);
    const wasChanged = useSelector(getBusinessCardWasChanged);
    const isValid = useSelector(getBusinessCardIsValid);

    const isReady = checkIsReady(loadState);
    const isError = checkIsError(loadState);
    const isLoading = checkIsLoading(loadState);

    return {
        businessCard,
        isReady,
        isError,
        isLoading,
        isSaveButtonDisabled: !businessCard || !wasChanged || !isValid,
    };
};

export default useBusinessCardData;
