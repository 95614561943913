import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {makeStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';

import EnhancedTableHead from './components/TableHead/TableHead';

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: (props) => props?.width || 1100,
        overflowX: 'auto',
        marginBottom: theme.spacing(2),
        filter: (props) => (props?.withShadow ? 'drop-shadow(0px 1px 5px rgba(0, 0, 0, 0.2))' : 'unset')
    },
    container: {
        width: '100%'
    },
    tableRow: {
        cursor: (props) => (props?.collapsible ? 'pointer' : 'initial')
    },
    tableRowCheckbox: {
        color: theme.palette.text.primary
    },
    tableCell: {
        fontFamily: 'inherit',
        textTransform: 'capitalize'
    },
    expandedTableCell: {
        borderBottom: 'none'
    },
    header: {
        backgroundColor: '#EFEEF1'
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1
    },
    hiddenSortLabel: {
        cursor: 'initial',
        '&:hover': {
            color: 'initial'
        },
        '&:focus': {
            color: 'initial'
        }
    },
    activeSortIcon: {
        opacity: 1
    },
    inactiveSortIcon: {
        opacity: 0.2
    }
}));

function EnhancedTable({
    data,
    cols,
    headCells,
    rowsPerPage,
    rowsPerPageOptions = [10],
    total,
    initPage,
    onPaginate = () => {},
    orderByProp = '',
    orderDir = 'asc',
    width,
    headCellsAlign,
    tableCellsAlign = 'left',
    withSelection = true,
    passSelectedItems = () => {},
    withShadow,
    rowRenderer,
    collapsible = false,
    withEmptyRows = true,
    withSorting,
    className,
    cellsWithSorting,
    onSort
}) {
    const classes = useStyles({width, withShadow, collapsible});
    const [order, setOrder] = useState(orderDir);
    const [orderBy, setOrderBy] = useState(orderByProp);
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [perPage, setRowsPerPage] = useState(rowsPerPage);
    const [collapsedId, toggleCollapsedId] = useState(null);

    const validInitialPage = initPage - 1; // it means that the first page of the data list would be 0 which is valid initial state for pagination component

    useEffect(() => {
        if (page && !validInitialPage) setPage(0);
    }, [page, validInitialPage]);

    useEffect(() => {
        if (validInitialPage > page) setPage(validInitialPage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setOrderBy(orderByProp);
    }, [orderByProp]);

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        const orderDirection = isAsc ? 'desc' : 'asc';
        setOrder(orderDirection);
        setOrderBy(property);
        onSort(property, orderDirection);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = data.map((n) => n._id);
            setSelected(newSelecteds);
            return passSelectedItems(newSelecteds);
        }
        setSelected([]);
        passSelectedItems([]);
    };

    const handleClickCheckbox = (event, id) => {
        event.stopPropagation();
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        passSelectedItems(newSelected);
    };

    const handleClickRow = (id) => () => {
        if (!collapsible) return;

        toggleCollapsedId(id === collapsedId ? null : id);
    };

    const handleChangePage = (event, newPage) => {
        if (newPage > page) onPaginate(newPage + 1);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = ({target: {value}}) => {
        setRowsPerPage(parseInt(value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
        <Paper className={classNames(classes.root, {[className]: className})}>
            <TableContainer className={classes.container}>
                <Table aria-labelledby="tableTitle" size="medium" aria-label="enhanced table" data-testid="table">
                    <EnhancedTableHead
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={data.length}
                        headCells={headCells}
                        cellAlign={headCellsAlign}
                        withSelection={withSelection}
                        withSorting={withSorting}
                        cellsWithSorting={cellsWithSorting}
                    />
                    <TableBody>
                        {stableSort(data, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => {
                                const isItemSelected = isSelected(row._id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <React.Fragment key={row._id}>
                                        <TableRow
                                            onClick={handleClickRow(row._id)}
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            hover={collapsible}
                                            className={classes.tableRow}
                                            data-testid="table-row"
                                        >
                                            {withSelection && (
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={(event) => handleClickCheckbox(event, row._id)}
                                                        checked={isItemSelected}
                                                        inputProps={{'aria-labelledby': labelId}}
                                                        className={classes.tableRowCheckbox}
                                                        color="default"
                                                    />
                                                </TableCell>
                                            )}
                                            {cols.map((col) => (
                                                <TableCell
                                                    key={col.name + 1}
                                                    align={col.align || tableCellsAlign}
                                                    id={labelId}
                                                    scope="row"
                                                    className={classes.tableCell}
                                                    width={col.width}
                                                >
                                                    {col.render ? col.render(row) : row[col.name]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                        {collapsible && (
                                            <TableRow>
                                                {withSelection && (
                                                    <TableCell
                                                        padding="checkbox"
                                                        className={classNames({
                                                            [classes.expandedTableCell]: collapsedId !== row._id
                                                        })}
                                                    />
                                                )}
                                                <TableCell
                                                    padding="none"
                                                    className={classNames({
                                                        [classes.expandedTableCell]: collapsedId !== row._id
                                                    })}
                                                    colSpan={5}
                                                >
                                                    <Collapse in={collapsedId === row._id} timeout="auto" unmountOnExit>
                                                        <Box margin={1}>{rowRenderer(row, index)}</Box>
                                                    </Collapse>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                        {withEmptyRows && emptyRows > 0 && (
                            <TableRow style={{height: 52 * emptyRows}}>
                                <TableCell colSpan={cols.length + 1} />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={rowsPerPageOptions}
                component="div"
                count={total || data.length}
                rowsPerPage={perPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                classes={{
                    caption: classes.tableCell
                }}
            />
        </Paper>
    );
}

EnhancedTable.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    cols: PropTypes.arrayOf(PropTypes.object).isRequired,
    headCells: PropTypes.arrayOf(PropTypes.object).isRequired,
    orderByProp: PropTypes.string,
    orderDir: PropTypes.string,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    headCellsAlign: PropTypes.string,
    tableCellsAlign: PropTypes.oneOf(['left', 'center', 'right']),
    withSelection: PropTypes.bool,
    withShadow: PropTypes.bool,
    rowRenderer: PropTypes.func,
    collapsible: PropTypes.bool,
    withEmptyRows: PropTypes.bool,
    passSelectedItems: PropTypes.func,
    rowsPerPageOptions: PropTypes.array,
    rowsPerPage: PropTypes.number,
    total: PropTypes.number,
    initPage: PropTypes.number,
    onPaginate: PropTypes.func,
    className: PropTypes.string,
    withSorting: PropTypes.bool
};

export default EnhancedTable;
