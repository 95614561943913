import React from 'react';

import eventVillageImage768 from '../../../../../Images/backgrounds/village/AIM-Village.jpg';
import eventVillageImage900 from '../../../../../Images/backgrounds/village/AIM-Village.jpg';
import eventVillageImage1080 from '../../../../../Images/backgrounds/village/AIM-Village.jpg';
import eventVillageImage1200 from '../../../../../Images/backgrounds/village/AIM-Village.jpg';
import eventVillageImage1440 from '../../../../../Images/backgrounds/village/AIM-Village.jpg';
import eventVillageImageFull from '../../../../../Images/backgrounds/village/AIM-Village.jpg';

import './VillageBackgroundImage.scss';

const VillageBackgroundImage = (props) => {
    let EventVillageImage, customVillageRender;
    const {brandingData} = props;

    if (brandingData && 'villageRender' in brandingData && brandingData.villageRender) {
        customVillageRender = true;
    }

    if (customVillageRender) {
        EventVillageImage = brandingData.filesUrl;
        switch (true) {
            case window.innerHeight < 769:
                EventVillageImage += brandingData.villageRender.resize768;
                break;
            case window.innerHeight < 901:
                EventVillageImage += brandingData.villageRender.resize900;
                break;
            case window.innerHeight < 1081:
                EventVillageImage += brandingData.villageRender.resize1080;
                break;
            case window.innerHeight < 1201:
                EventVillageImage += brandingData.villageRender.resize1200;
                break;
            case window.innerHeight < 1441:
                EventVillageImage += brandingData.villageRender.resize1440;
                break;
            case window.innerHeight < 2161:
                EventVillageImage += brandingData.villageRender.resize2160;
                break;
            default:
                EventVillageImage += brandingData.villageRender.original;
        }
    } else {
        switch (true) {
            case window.innerHeight < 769:
                EventVillageImage = eventVillageImage768;
                break;
            case window.innerHeight < 901:
                EventVillageImage = eventVillageImage900;
                break;
            case window.innerHeight < 1081:
                EventVillageImage = eventVillageImage1080;
                break;
            case window.innerHeight < 1201:
                EventVillageImage = eventVillageImage1200;
                break;
            case window.innerHeight < 1441:
                EventVillageImage = eventVillageImage1440;
                break;
            default:
                EventVillageImage = eventVillageImageFull;
        }
    }

    return <img className="background-render" src={EventVillageImage} width="6000" height="3000" alt="Auditorium" />;
};

export default VillageBackgroundImage;
