import {useContext} from 'react';
import {useSelector} from 'react-redux';
import {getEventData} from 'store/selectors';
import {AbilityContext} from '../../permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

const nozmalizeOptinsData = (options) => {
    const nozmalizedOptins = options.map(({isOptionCreation, matchingWith, ...option}) => {
        const normalizedMatchingWith = matchingWith.map(({title, _id, ...data}) => ({...data}));
        return {matchingWith: normalizedMatchingWith, ...option};
    });
    return nozmalizedOptins;
};

export const normalizePostQuestionData = (question) => {
    const {_id, isQuestionCreation, __v, updatedAt, createdAt, matchingForm, isChanged, event, options, ...data} =
        question;
    const nozmalizedOptins = nozmalizeOptinsData(options);
    return {...data, options: nozmalizedOptins};
};

export const nozmalizeAnswerData = (answers) => {
    return answers.map(({question, ...answer}) => {
        const optionIds = answer.options.map(({optionId}) => optionId);
        return {questionId: question, optionIds};
    });
};

export const useShowMatchMaking = () => {
    const ability = useContext(AbilityContext);
    const event = useSelector(getEventData);
    const matchingAbility = ability.can(ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY, ROLE_FEATURES_TYPES.VIEW_MATHCING);
    const showMatching = matchingAbility && event?.hasMatching && event?.matchingEnabled;
    return showMatching;
};

export const reorderMatchingQuestionHelper = (list, startIndex, endIndex) => {
    const newQuestions = [...list];
    const [removed] = newQuestions.splice(startIndex, 1);
    newQuestions.splice(endIndex, 0, removed);

    const reorderIds = newQuestions.reduce((acc, question, i) => {
        question.order = i;
        if (!question.isQuestionCreation)
            return [
                ...acc,
                {
                    matchingQuestionId: question._id,
                    order: question.order,
                },
            ];
        return acc;
    }, []);
    return {newQuestions, reorderIds};
};

export const calculateChipsCount = (selectedItems, chipContainerRef) => {
    if (!chipContainerRef.current) return 2;

    let count = 0;
    let calculatedWidth = 0;

    const maxItemWidth = 160;
    const emptyChipWidth = 45;
    const emptyOwnChip = 24;
    const symbolWidth = 13;
    const containerWidth = chipContainerRef.current.offsetWidth * 0.9;

    selectedItems.forEach((el, i) => {
        if (i === 0) {
            // for first item
            const width = el.title.length * symbolWidth + emptyOwnChip;
            calculatedWidth += width > maxItemWidth ? maxItemWidth : width;
            count++;
            return;
        }

        if (containerWidth < calculatedWidth) return;

        const itemWidth = el.title.length * symbolWidth + emptyChipWidth;
        const width = itemWidth > maxItemWidth ? maxItemWidth : itemWidth;

        if (containerWidth < calculatedWidth + width) return;

        calculatedWidth += width;
        count++;
    });

    return count;
};
