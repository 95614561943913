import {AppBar, Tab, Tabs} from '@material-ui/core';
import React from 'react';
import {usePlatformTranslation} from '../../../../../../../services/hooks';
import {UpcomingEvents, PastEvents, ExploreMoreEvents} from '../MyEvents/components';
import './MyEventsMobile.scss';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </div>
    );
}

const MyEventsMobile = () => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const {eventsListing} = usePlatformTranslation();
    const tabProps = (index) => ({
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
        classes: {
            root: 'my-listed-events-mobile__tab',
            wrapper: 'my-listed-events-mobile__tab--wrapper',
            selected: 'my-listed-events-mobile__tab--selected',
        },
    });
    const handleChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const upcomingEventsProps = {
        exploreMore: () => setTabIndex(2),
    };
    const pastEventsProps = {
        exploreMore: () => setTabIndex(2),
    };
    const exploreMoreEventsProps = {
        exploreMore: () => setTabIndex(2),
    };
    return (
        <div className="my-listed-events-mobile">
            <AppBar
                classes={{root: 'my-listed-events-mobile__app-bar--root'}}
                color="primary"
                position="static"
                className="my-listed-events-mobile__app-bar"
            >
                <Tabs
                    indicatorColor="primary"
                    value={tabIndex}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                >
                    <Tab label={eventsListing.upcoming.toUpperCase()} {...tabProps(0)} />
                    <Tab label={eventsListing.past.toUpperCase()} {...tabProps(1)} />
                    <Tab label={eventsListing.explore.toUpperCase()} {...tabProps(2)} />
                </Tabs>
            </AppBar>
            <TabPanel value={tabIndex} index={0}>
                <UpcomingEvents {...upcomingEventsProps} />
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
                <PastEvents {...pastEventsProps} />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
                <ExploreMoreEvents {...exploreMoreEventsProps} className="my-listed-events-mobile__explore-more" />
            </TabPanel>
        </div>
    );
};

export default MyEventsMobile;
