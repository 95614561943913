import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import MyAccountDropdownComponent from '../MyAccountDropdownComponent';

import * as actions from 'store/actions';

class MyBooths extends React.Component {
    state = {
        translation: null
    };

    componentDidMount() {
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation
        });
    };

    redirectToBooth = (exhibitorId) => (e) => {
        const {eventSlug} = this.props;
        this.props.history.push(`/event/${eventSlug}/exhibitor-booth-view/${exhibitorId}`);
    };

    render() {
        const {user, eventId} = this.props;
        const userRolesInEvent = user.eventRoles.find((role) => role.event._id === eventId);
        const {translation} = this.state;
        return (
            <MyAccountDropdownComponent style={{left: document.getElementById('my-booths').offsetLeft}}>
                <div className="page-title">
                    <h3>{translation?.myBoothsDropdown.title}</h3>
                </div>
                <div className="myaccount-container">
                    <ul className="my-events-list">
                        {userRolesInEvent.exhibitorRepresentative.map((exhibitorId, index) => {
                            const isActive = this.props.history.location.pathname.includes(exhibitorId);
                            return (
                                <li
                                    className={'row ' + (isActive ? 'active' : '')}
                                    key={exhibitorId}
                                    onClick={this.redirectToBooth(exhibitorId)}
                                >
                                    {/* <span>{exhibitorData?.company}</span> */}
                                    {/* TODO: fix it */}
                                    <span>{`Go to booth ${index + 1}`}</span>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </MyAccountDropdownComponent>
        );
    }
}

export default withRouter(
    connect(
        (state) => ({
            user: state.user.data,
            eventId: state.event.eventId,
            eventSlug: state.event.eventSlug,
            languages: state.languages,
            usersList: state.event.usersList
        }),
        {
            setEventId: actions.setEventId,
            setEventSlug: actions.setEventSlug,
            seeMyBooths: actions.topNavSeeMyBooths
        }
    )(MyBooths)
);
