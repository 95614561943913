import {Api} from 'Api';
import * as actionTypes from 'store/actionTypes/matchmakingActionTypes';

//form
export const resetMatchmakingForm = () => ({
    type: actionTypes.RESET_MATCHMAKING_FORM,
});

export const initMatchmakingForm = () => ({
    type: actionTypes.INIT_MATCHMAKING_FORM,
});

const createMatchmakingFormStart = () => ({
    type: actionTypes.CREATE_MATCHMAKING_FORM_START,
});

const createMatchmakingFormSuccess = (data) => ({
    type: actionTypes.CREATE_MATCHMAKING_FORM_SUCCESS,
    payload: data,
});

const createMatchmakingFormFail = (error) => ({
    type: actionTypes.CREATE_MATCHMAKING_FORM_FAIL,
    payload: error,
});

export const createMatchmakingForm = ({data, callback}) => {
    return async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();

        dispatch(createMatchmakingFormStart());
        try {
            const {matchingForm} = await Api.request({
                url: `/event/${eventId}/questionnaires`,
                payload: data,
                method: 'post',
            });

            dispatch(createMatchmakingFormSuccess(matchingForm));
            callback && callback();

            return matchingForm._id;
        } catch (error) {
            dispatch(createMatchmakingFormFail(error));
        }
    };
};

const updateMatchmakingFormStart = () => ({
    type: actionTypes.UPDATE_MATCHMAKING_FORM_START,
});

const updateMatchmakingFormSuccess = (data) => ({
    type: actionTypes.UPDATE_MATCHMAKING_FORM_SUCCESS,
    payload: data,
});

const updateMatchmakingFormFail = (error) => ({
    type: actionTypes.UPDATE_MATCHMAKING_FORM_FAIL,
    payload: error,
});

export const updateMatchmakingForm = ({matchingFormId, callback, data}) => {
    return async (dispatch, getState) => {
        const {
            event: {eventId},
            matchmaking: {loading},
        } = getState();
        dispatch(updateMatchmakingFormStart());
        try {
            const {matchingForm} = await Api.request({
                url: `/event/${eventId}/questionnaires/${matchingFormId}`,
                method: 'put',
                payload: data,
                loading: loading,
            });

            dispatch(updateMatchmakingFormSuccess(matchingForm));
            callback && callback();
        } catch (error) {
            dispatch(updateMatchmakingFormFail(error));
        }
    };
};

const getMatchmakingFormStart = () => ({
    type: actionTypes.GET_MATCHMAKING_FORM_START,
});

const getMatchmakingFormSuccess = (data) => ({
    type: actionTypes.GET_MATCHMAKING_FORM_SUCCESS,
    payload: data,
});

const getMatchmakingFormFail = (error) => ({
    type: actionTypes.GET_MATCHMAKING_FORM_FAIL,
    payload: error,
});

export const getMatchmakingForm = () => {
    return async (dispatch, getState) => {
        const {
            event: {eventId},
        } = getState();
        dispatch(getMatchmakingFormStart());
        try {
            const {matchingForm} = await Api.request({
                url: `/event/${eventId}/questionnaires`,
                method: 'get',
                loading: true,
            });

            dispatch(getMatchmakingFormSuccess(matchingForm));
        } catch (error) {
            dispatch(getMatchmakingFormFail(error));
        }
    };
};

//question

export const initMatchmakingQuestion = () => ({
    type: actionTypes.INIT_MATCHMAKING_QUESTION,
});

const createMatchingQuestionStart = () => ({
    type: actionTypes.CREATE_MATCHING_QUESTION_START,
});

const createMatchingQuestionSuccess = (data) => ({
    type: actionTypes.CREATE_MATCHING_QUESTION_SUCCESS,
    payload: data,
});

const createMatchingQuestionFail = (error) => ({
    type: actionTypes.CREATE_MATCHING_QUESTION_FAIL,
    payload: error,
});

export const createMatchingQuestion = ({questionId, data}) => {
    return async (dispatch, getState) => {
        dispatch(createMatchingQuestionStart());
        const {
            event: {eventId},
            matchmaking: {matchingForm},
        } = getState();
        try {
            let matchingFormId = '';
            if (matchingForm?.isFormCreation) {
                // if form is  created only localy, we firstly save form , then save questions
                const {title, description} = matchingForm;
                matchingFormId = await createMatchmakingForm({data: {title, description}})(dispatch, getState);
            } else {
                matchingFormId = matchingForm._id;
            }
            const {matchingQuestion} = await Api.request({
                url: `/event/${eventId}/questionnaires/${matchingFormId}/question`,
                method: 'post',
                payload: data,
                errorObj: {show: true},
            });

            dispatch(createMatchingQuestionSuccess({newQuestion: matchingQuestion, questionId}));
        } catch (error) {
            dispatch(createMatchingQuestionFail(error));
        }
    };
};

const updateMatchingQuestionStart = () => ({
    type: actionTypes.UPDATE_MATCHING_QUESTION_START,
});

const updateMatchingQuestionSuccess = (data) => ({
    type: actionTypes.UPDATE_MATCHING_QUESTION_SUCCESS,
    payload: data,
});

const updateMatchingQuestionFail = (error) => ({
    type: actionTypes.UPDATE_MATCHING_QUESTION_FAIL,
    payload: error,
});

export const updateMatchingQuestion = ({questionId, data}) => {
    return async (dispatch, getState) => {
        dispatch(updateMatchingQuestionStart());
        try {
            const {
                event: {eventId},
                matchmaking: {matchingForm},
            } = getState();
            const {matchingQuestion} = await Api.request({
                url: `/event/${eventId}/questionnaires/${matchingForm._id}/question/${questionId}`,
                method: 'put',
                payload: data,
                errorObj: {show: true},
            });

            dispatch(updateMatchingQuestionSuccess({newQuestion: matchingQuestion}));
        } catch (error) {
            dispatch(updateMatchingQuestionFail(error));
        }
    };
};

const deleteMatchingQuestionStart = () => ({
    type: actionTypes.DELETE_MATCHING_QUESTION_START,
});

const deleteMatchingQuestionSuccess = (data) => ({
    type: actionTypes.DELETE_MATCHING_QUESTION_SUCCESS,
    payload: data,
});

const deleteMatchingQuestionFail = (error) => ({
    type: actionTypes.DELETE_MATCHING_QUESTION_FAIL,
    payload: error,
});

export const deleteMatchingQuestion = (questionId, isQuestionCreation) => {
    return async (dispatch, getState) => {
        dispatch(deleteMatchingQuestionStart());
        try {
            const {
                event: {eventId},
                matchmaking: {matchingForm},
            } = getState();

            if (!isQuestionCreation)
                await Api.request({
                    url: `/event/${eventId}/questionnaires/${matchingForm._id}/question/${questionId}`,
                    method: 'delete',
                });

            dispatch(deleteMatchingQuestionSuccess({questionId}));
        } catch (error) {
            dispatch(deleteMatchingQuestionFail(error));
        }
    };
};

export const changeMatchmakinkQuestion = (payload) => ({
    type: actionTypes.CHANGE_MATCHMAKINK_QUESTION,
    payload,
});

const reorderMatchingQuestionsRequestStart = () => ({
    type: actionTypes.REORDER_MATCHING_QUESTION_REQUEST_START,
});

const reorderMatchingQuestionsRequestSuccess = (data) => ({
    type: actionTypes.REORDER_MATCHING_QUESTION_REQUEST_SUCCESS,
    payload: data,
});

const reorderMatchingQuestionsRequestFail = (error) => ({
    type: actionTypes.REORDER_MATCHING_QUESTION_REQUEST_FAIL,
    payload: error,
});

export const reorderMatchingQuestionsRequest = (data) => {
    return async (dispatch, getState) => {
        dispatch(reorderMatchingQuestionsRequestStart());
        try {
            const {
                event: {eventId},
                matchmaking: {matchingForm},
            } = getState();
            const {matchingQuestions} = await Api.request({
                url: `/event/${eventId}/questionnaires/${matchingForm._id}/question/order`,
                method: 'post',
                loading: false,
                payload: data,
            });

            dispatch(reorderMatchingQuestionsRequestSuccess(matchingQuestions));
        } catch (error) {
            dispatch(reorderMatchingQuestionsRequestFail(error));
        }
    };
};

export const reorderMatchingQuestion = (payload) => ({
    type: actionTypes.REORDER_MATHCING_QUESTIONS,
    payload,
});

//options
export const addMatchmakinkQuestionOption = (payload) => ({
    type: actionTypes.ADD_MATCHMAKINK_QUESTION_OPTION,
    payload,
});

export const removeMatchmakinkQuestionOption = (payload) => ({
    type: actionTypes.REMOVE_MATCHMAKINK_QUESTION_OPTION,
    payload,
});

export const changeMatchmakinkQuestionOption = (payload) => ({
    type: actionTypes.CHANGE_MATCHMAKINK_QUESTION_OPTION,
    payload,
});

export const changeMatchingWith = (payload) => ({
    type: actionTypes.CHANGE_MATCHING_WITH,
    payload,
});

export const removeMatchingWith = (payload) => ({
    type: actionTypes.REMOVE_MATCHING_WITH,
    payload,
});

export const reorderMatchingQuestionOptions = (payload) => ({
    type: actionTypes.REORDER_MATCHING_QUESTION_OPTIONS,
    payload,
});

//matched

export const setMatchingMatches = (payload) => ({
    type: actionTypes.SET_MATCHING_MATCHES,
    payload,
});
