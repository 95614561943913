import {
    GET_NEWS_START,
    GET_NEWS_SUCCESS,
    GET_NEWS_FAIL,
    CREATE_NEWS_FAIL,
    CREATE_NEWS_SUCCESS,
    CREATE_NEWS_START,
    UPDATE_NEWS_FAIL,
    UPDATE_NEWS_SUCCESS,
    UPDATE_NEWS_START,
    DELETE_NEWS_FAIL,
    DELETE_NEWS_SUCCESS,
    DELETE_NEWS_START,
    START_NEWS_START,
    START_NEWS_SUCCESS,
    START_NEWS_FAIL,
    STOP_NEWS_START,
    STOP_NEWS_SUCCESS,
    STOP_NEWS_FAIL,
} from '../actions/actionTypes';

const initialState = {
    isLoading: false,
    news: [],
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_NEWS_SUCCESS:
            return {...state, news: action.news, isLoading: false};
        case GET_NEWS_START:
        case CREATE_NEWS_START:
        case UPDATE_NEWS_START:
        case DELETE_NEWS_START:
        case START_NEWS_START:
        case STOP_NEWS_START:
            return {...state, isLoading: true};
        case GET_NEWS_FAIL:
        case CREATE_NEWS_SUCCESS:
        case CREATE_NEWS_FAIL:
        case UPDATE_NEWS_SUCCESS:
        case UPDATE_NEWS_FAIL:
        case DELETE_NEWS_SUCCESS:
        case DELETE_NEWS_FAIL:
        case START_NEWS_SUCCESS:
        case START_NEWS_FAIL:
        case STOP_NEWS_SUCCESS:
        case STOP_NEWS_FAIL:
            return {...state, isLoading: false};
        default:
            return state;
    }
};

export default reducer;
