import {connect} from 'react-redux';
import * as selectors from 'store/selectors';
import * as actions from 'store/actions';
import MatchMaking from './MatchMaking';

export default connect(
    (state) => ({
        userLoading: selectors.getUserLoading(state),
        eventId: selectors.getEventId(state),
        isLargeScreen: selectors.getIsLargeScreen(state),
    }),
    {
        onSetCompleteMatching: actions.setCompleteMatching,
    }
)(MatchMaking);
