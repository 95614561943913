import {useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';

import {getLocalStream} from 'store/actions';
import {getVideoCapture, getMediaInputSources} from 'store/selectors';

import {MEDIA_DEVICES_KINDS, MEDIA_TYPES} from 'constants/organizer/sessions';

export const useActiveDevice = ({mediaType}) => {
    const dispatch = useDispatch();
    const capture = useSelector(getVideoCapture);
    const mediaInputSources = useSelector(getMediaInputSources);

    const inputMediaDeviceType =
        mediaType === MEDIA_TYPES.audio ? MEDIA_DEVICES_KINDS.audioinput : MEDIA_DEVICES_KINDS.videoinput;
    const devicesOptions = mediaInputSources.filter(
        (device) => device.kind === inputMediaDeviceType && device.deviceId
    );

    const activeAudioDeviceId = capture && capture.getAudioTracks()[0].getCapabilities().deviceId;
    const activeVideoDeviceId = capture && capture.getVideoTracks()[0].getCapabilities().deviceId;

    useEffect(() => {
        if (capture && !devicesOptions.length) dispatch(getLocalStream());
    }, [capture, devicesOptions, dispatch]);

    const handleChangeDevice = useCallback(
        ({target: {value}}) => {
            capture.getTracks().forEach((track) => track.stop());

            const constrains = {
                [mediaType]: {
                    deviceId: value,
                },
            };

            dispatch(getLocalStream(constrains));
        },
        [capture, dispatch, mediaType]
    );

    return {
        devicesOptions,
        activeAudioDeviceId,
        activeVideoDeviceId,
        handleChangeDevice,
    };
};
