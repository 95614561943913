import React, {forwardRef} from 'react';
// import {usePlatformTranslation} from '../../../../../../../../../../../../../services/hooks';
import {useStyles} from '../MoreMenu.styles';
import clsx from 'clsx';
import {MenuItem} from '@material-ui/core';
import IconNewChat from '../../Icons/IconNewChat';

const MenuItemNewChat = forwardRef(({onClick, className, ...props}, ref) => {
    const classes = useStyles();
    // const translation = usePlatformTranslation(); //TODO: user translation for new chat
    return (
        <MenuItem ref={ref} className={clsx(classes.item, className)} onClick={onClick} {...props}>
            <IconNewChat />
            <span className={classes.itemText}>{'NEW CHAT'}</span>
        </MenuItem>
    );
});

export default MenuItemNewChat;
