import React from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {closeActiveChat, updateActiveChatData} from '../../../../../../../../../../../store/actions';
import GroupChatMoreMenu from '../../../ChatsHeader/components/ChatsMoreMenu/components/GroupChatMoreMenu/GroupChatMoreMenu';
import {usePlatformTranslation} from '../../../../../../../../../../../services/hooks';
import BackButtonIcon from '../../../../../../../../../../../Images/back_btn.svg';
import {ReactComponent as SettingsIcon} from '../../../../../../../../../../../Images/settings_icon.svg';
import {ReactComponent as SettingsIconActive} from '../../../../../../../../../../../Images/settings_icon_green.svg';
import {ReactComponent as MembersIcon} from '../../../../../../../../../../../Images/members_icon_green.svg';
import {ReactComponent as MembersIconDefault} from '../../../../../../../../../../../Images/members_icon.svg';

const GroupChatHeader = ({activeChatData}) => {
    const dispatch = useDispatch();
    const translation = usePlatformTranslation();
    const {isMobile} = useSelector((state) => state.layout);
    const {
        displayChatMembers,
        editGroupChatDialog,
        groupChatAvatarString,
        chatName,
        isOwner,
        chatMembers = [],
    } = activeChatData;

    const onUpdateActiveChatData = (data) => {
        dispatch(updateActiveChatData(data));
    };

    const closeChat = () => {
        dispatch(closeActiveChat());
    };

    return (
        <div className="chat-settings">
            {isMobile && !displayChatMembers && !editGroupChatDialog && (
                <img
                    className="back-button"
                    src={BackButtonIcon}
                    alt="Back Button"
                    width="24"
                    height="24"
                    onClick={closeChat}
                />
            )}
            {chatName ? (
                <>
                    <div className="group-chat-button">
                        {displayChatMembers ? (
                            <img
                                className="back-button"
                                src={BackButtonIcon}
                                alt="Back Button"
                                width="24"
                                height="24"
                                onClick={() => onUpdateActiveChatData({displayChatMembers: false})}
                            />
                        ) : (
                            <>
                                {editGroupChatDialog ? (
                                    <img
                                        className="back-button"
                                        src={BackButtonIcon}
                                        alt="Back Button"
                                        width="24"
                                        height="24"
                                        onClick={() => onUpdateActiveChatData({editGroupChatDialog: false})}
                                    />
                                ) : (
                                    <div className="avatar">
                                        <span>{groupChatAvatarString}</span>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className="group-chat-info">
                        <p className="group-chat-name">{chatName}</p>
                        <span className="members">{chatMembers.length + ' ' + translation?.chatsDropdown.members}</span>
                    </div>
                </>
            ) : (
                <>
                    <div className="group-chat-button">
                        {displayChatMembers ? (
                            <img
                                className="back-button"
                                src={BackButtonIcon}
                                alt="Back Button"
                                width="24"
                                height="24"
                                onClick={() => onUpdateActiveChatData({displayChatMembers: false})}
                            />
                        ) : (
                            <div className="avatar">
                                <span>{groupChatAvatarString}</span>
                            </div>
                        )}
                    </div>
                    <div className="group-chat-info">
                        <p className="group-chat-name">
                            {chatMembers.map((user) => (
                                <span key={user._id}>
                                    {user.first} {user.last},{' '}
                                </span>
                            ))}
                        </p>
                        <span className="members">{chatMembers.length + ' ' + translation?.chatsDropdown.members}</span>
                    </div>
                </>
            )}
            {!isMobile && (
                <>
                    <div className="button-wrapper">
                        <button
                            className="see-members"
                            color={displayChatMembers ? 'see' : 'hide'}
                            onClick={() =>
                                onUpdateActiveChatData({
                                    displayChatMembers: !displayChatMembers,
                                    editGroupChatDialog: false,
                                })
                            }
                        >
                            {displayChatMembers ? <MembersIcon className="primary-fill-svg" /> : <MembersIconDefault />}

                            <p>{translation?.chatsDropdown.members}</p>
                        </button>
                    </div>
                    <div className="button-wrapper">
                        {isOwner && (
                            <button
                                className="manage-chat"
                                color={editGroupChatDialog ? 'see' : 'hide'}
                                onClick={() =>
                                    onUpdateActiveChatData({
                                        editGroupChatDialog: true,
                                        displayChatMembers: false,
                                    })
                                }
                            >
                                {editGroupChatDialog ? (
                                    <SettingsIconActive className="primary-fill-svg" />
                                ) : (
                                    <SettingsIcon />
                                )}
                                <p>{translation?.chatsDropdown.chatSettingsButton}</p>
                            </button>
                        )}
                    </div>
                </>
            )}
            <GroupChatMoreMenu {...activeChatData} />
        </div>
    );
};

export default GroupChatHeader;
