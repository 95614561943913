import React from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import {usePlatformTranslation} from '../../../../../../../../../../../services/hooks';
import {ChatIcon} from 'Images/chat';
import useTheme from '@material-ui/core/es/styles/useTheme';
import './ChatButton.scss';

const ChatButton = ({...rest}) => {
    const translation = usePlatformTranslation();
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);
    const theme = useTheme();

    return (
        <button className={classNames('ChatButton', {isRtl: isRtlLanguage})} {...rest}>
            <ChatIcon primary={theme.palette.primary.main} secondary={theme.palette.secondary.main} />
            <span className="ChatButton__title">{translation?.chatsDropdown.membersChatButton}</span>
        </button>
    );
};

export default ChatButton;
