import React, {useState} from 'react';
import {FormControl, FormControlLabel, makeStyles, RadioGroup} from '@material-ui/core';
import CustomRadio from '../../../../../Radio/Radio';
import {useSelector} from 'react-redux';
import classNames from 'classnames';

const useStyles = makeStyles({
    label: {
        fontWeight: 600,
        textAlign: 'left',
        '&.isRtl': {
            marginRight: 0,
            marginLeft: '16px',
            textAlign: 'right',
        },
    },
});

const CustomRadioGroup = ({fieldData, changeField}) => {
    const classes = useStyles();
    const [value, setValue] = useState(null);
    const isRtlLanguage = useSelector((state) => state.languages.isRtlLanguage);

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        changeField({id: fieldData._id, value: event.target.value});
    };

    return (
        <FormControl fullWidth component="fieldset">
            <RadioGroup aria-label="radio" value={value} onChange={handleRadioChange}>
                {fieldData &&
                    fieldData.options.map((option) => (
                        <FormControlLabel
                            id={option._id}
                            key={option._id}
                            className={classNames(classes.label, {isRtl: isRtlLanguage})}
                            value={option.value}
                            control={<CustomRadio />}
                            label={option.value}
                        />
                    ))}
            </RadioGroup>
        </FormControl>
    );
};

export default CustomRadioGroup;
