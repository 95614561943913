import React from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {Button} from '@material-ui/core';

import HeaderMenu from '../HeaderMenu';

import HomepageBackground from '../../../Images/backgrounds/homepage-background.jpg';
import {Api} from '../../../Api';
import './NewsletterUnsubscribe.scss';

class NewsletterUnsubscribe extends React.Component {
    state = {
        token: '',
        redirect: false,
        message: '',
        translation: null,
    };

    componentDidMount() {
        // get the token from url, save it to state so that we can send it with the request
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token');

        this.setState({token: token});
        this.setLanguagesData();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.languages.eventLanguage !== this.props.languages.eventLanguage) {
            this.setLanguagesData();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.eventLanguage];
        this.setState({
            translation: translation,
        });
    };

    handleUnsubscribe = async () => {
        try {
            const {message} = await Api.request({
                method: 'put',
                url: `/users/newsletter-unsubscribe?token=${this.state.token}`,
            });

            this.setState({
                message,
            });
        } catch (error) {}
    };

    handleCancel = () => {
        this.setState({
            redirect: true,
        });
    };

    render() {
        const {translation} = this.state;
        return (
            <div>
                <HeaderMenu />
                {this.state.redirect ? <Redirect to={'/'} /> : null}
                <div className="homepage login-page register-participant password-recovery-page">
                    <div className="event-container" style={{backgroundImage: `url('${HomepageBackground}')`}}>
                        <div className="homepage-overlay">
                            <div className="smallContainer">
                                <div className="single-event-container">
                                    <div className="right-form">
                                        {this.state.message.length ? (
                                            <>
                                                <p>{this.state.message}</p>
                                                <div className="register-form-wrapper">
                                                    <Button
                                                        onClick={this.handleCancel}
                                                        color="primary"
                                                        className="change-password-btn"
                                                        variant="outlined"
                                                    >
                                                        {translation?.unsubscribe.unsubscribeHomepage}
                                                    </Button>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <p>{translation?.unsubscribe.unsubscribeTitle}</p>
                                                <div className="register-form-wrapper">
                                                    <Button
                                                        onClick={this.handleUnsubscribe}
                                                        color="secondary"
                                                        className="change-password-btn"
                                                        variant="outlined"
                                                    >
                                                        {translation?.generalText.confirm}
                                                    </Button>
                                                    <Button
                                                        onClick={this.handleCancel}
                                                        color="primary"
                                                        className="change-password-btn"
                                                        variant="outlined"
                                                    >
                                                        {translation?.generalText.cancel}
                                                    </Button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        languages: state.languages,
    };
};

export default connect(mapStateToProps)(NewsletterUnsubscribe);
