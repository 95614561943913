import {initialState} from '../initialState';
import {updateObject} from '../../../utilityStateFunction';
import * as actionTypes from '../../../actionTypes/videoConferenceActionTypes';

const oneToOneHandler = (state, {type, payload}) => {
    if (state === undefined) {
        return initialState.oneToOne;
    }

    switch (type) {
        case actionTypes.OPEN_VIDEO_ROOM:
            return updateObject(state.oneToOne, {
                isActive: true,
                companionId: payload.companionId,
            });
        case actionTypes.GET_MEDIA_CAPTURE_SUCCESS:
            return updateObject(state.oneToOne, {
                capture: payload,
            });
        case actionTypes.GET_SCREEN_SHARE_CAPTURE_SUCCESS:
            return updateObject(state.oneToOne, {
                shareScreenCapture: payload,
            });
        case actionTypes.GET_COMPANION_STREAM:
            return updateObject(state.oneToOne, {
                companionStream: payload,
            });
        case actionTypes.CLOSE_VIDEO_ROOM:
            return updateObject(state.oneToOne, {
                isActive: false,
                isCalling: false,
                capture: null,
                companionStream: null,
                shareScreenCapture: null,
                incomingCallOffer: null,
                iceCandidates: [],
                iceServers: null,
            });
        case actionTypes.TOGGLE_SCREEN_SHARE:
            return updateObject(state.oneToOne, {
                isScreenShared: payload ?? !state.oneToOne.isScreenShared,
            });
        case actionTypes.SET_INCOMING_CALL_OFFER:
            return updateObject(state.oneToOne, {
                incomingCallOffer: payload,
            });
        case actionTypes.SET_ICE_CANDIDATE:
            return updateObject(state.oneToOne, {
                iceCandidates: [...state.oneToOne.iceCandidates, payload],
            });
        case actionTypes.SET_ICE_SERVERS:
            return updateObject(state.oneToOne, {iceServers: payload});
        case actionTypes.SET_PARTICIPANT_STREAM_STATUS:
            return updateObject(state.oneToOne, {
                isParticipantStreamActive: payload ?? !state.oneToOne.isParticipantStreamActive,
            });
        case actionTypes.SET_PEER_CONNECTION:
        case actionTypes.END_CALL: {
            return updateObject(state.oneToOne, {
                peerConnection: payload,
            });
        }
        case actionTypes.CLEAR_REMOTE_USER_INFO:
            return updateObject(state.oneToOne, {
                isParticipantStreamActive: false,
                companionStream: null,
                incomingCallOffer: null,
                iceCandidates: [],
                iceServers: null,
            });
        case actionTypes.START_CALL: {
            return updateObject(state.oneToOne, {
                isCalling: true,
                incomingCallOffer: null,
                iceCandidates: [],
                iceServers: null,
            });
        }
        default:
            return state.oneToOne;
    }
};

export default oneToOneHandler;
