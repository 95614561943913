import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles({
    avatar: {
        position: 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%)',
        border: '2px solid #fff',
        borderRadius: '50%',
        maxWidth: 100,
        maxHeight: 100,
        minWidth: 30,
        minHeight: 30,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 'bold',
        color: '#fff',
        overflow: 'hidden',
        zIndex: 11,
    },
    minified: {
        border: 0,
        borderRadius: 0,
        top: 0,
    },
    image: {
        width: '100%',
        height: '100%',
        borderRadius: '50%',
    },
    imageSmall: {
        width: '50%',
        height: '50%',
    },
    hidden: {
        display: 'none',
    },
});
