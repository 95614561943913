import React from 'react';

import {FacebookProvider, Page} from 'react-facebook';
import {TwitterTimelineEmbed} from 'react-twitter-embed';
import LinkedInBadge from 'Routes/components/LinkedInBadge';

import FullPageSpinner from '../FullPageSpinner';
import BoothVideoDialog from '../BoothVideoDialog';
import Footer from '../Footer';
import PartnersSlider from './components/PartnersSlider/PartnersSlider';
import ExhibitorSlider from './components/ExhibitorsSlider/ExhibitorsSlider';
import EventProgram from './components/EventProgram/EventProgram';
import RegisterForTheEventLink from './components/RegisterForTheEventLink/RegisterForTheEventLink';

import '../../../styles/organizerDashboard.scss';
import '../../../styles/landing-page.scss';
import '../../../styles/staticPages.scss';
import 'react-tabs/style/react-tabs.css';
import {ReactComponent as PlayIcon} from '../../../Images/play.svg';

import {getEventDaysArray, getVideoIdFromYoutubeUrl, getTwitterScreenName} from '../../../services/utils';
import {getPlatformBackground, whiteLabel, whiteLabelsList} from '../../../config';
import classNames from 'classnames';
import SocialsPromotion from '../SocialsPromotion';

import './HomepageEvent.scss';

class HomepageEvent extends React.Component {
    constructor(props) {
        super(props);
        this.tabsHeader = React.createRef();
        this.eventTitle = React.createRef();
    }

    state = {
        eventDays: [],
        breakpoint: 'desktop',
        showVideo: false,
        registerAsParticipant: false,
        registerAsExhibitor: false,
        activeProgramTabIndex: 0,
        activePartnersTabIndex: 0,
        eventNameFontSize: 72,
        facebook: '',
        twitter: '',
        linkedin: '',
        translation: null,
        brandingTranslation: null,
    };
    EMBEDED_CONFIG = {
        width: this.props.isMobile ? 310 : 400,
        height: 600,
    };
    eventNameWidth = whiteLabel === whiteLabelsList.added ? {maxWidth: '530px'} : {}; //make event name in to rows

    componentDidMount() {
        const {
            onSetEventPublicMenu,
            eventInfo,
            eventSlug,
            setEventIdBySlug,
            eventShortLink,
            setEventSlug,
            location,
            eventId,
            eventRegPackage,
            getSocialsPromotion,
        } = this.props;

        onSetEventPublicMenu();
        window.scrollTo(0, 0);

        this.setBreakpoints();
        this.setEventNameFontSize();
        window.addEventListener('resize', this.onResize);

        let eventDays = getEventDaysArray(eventInfo.eventStartDate, eventInfo.eventEndDate);
        this.setState({
            eventDays: eventDays,
        });
        if (this.props.eventInfo.brandingData.partners.length === 0) {
            this.setState({activePartnersTabIndex: 1});
        }
        this.setMediaLinks();
        this.setLanguagesData();

        if (location.state?.eventSlug) {
            setEventIdBySlug(location.state.eventSlug);
            setEventSlug(location.state.eventSlug);
        } else if (eventSlug?.replace('-', ' ') !== eventInfo.name) {
            setEventSlug(eventSlug);
            setEventIdBySlug(eventSlug);
        }

        getSocialsPromotion(eventInfo._id);

        if (eventId && eventShortLink)
            this.props.onGetEventUserRegistration({eventId, shortLink: eventShortLink, packageId: eventRegPackage});
    }

    componentDidUpdate(prevProps) {
        const {eventLanguage, eventId, getEventInfo} = this.props;
        if (prevProps.eventLanguage !== eventLanguage) {
            this.setLanguagesData();
        }

        if (prevProps.eventId !== eventId) {
            getEventInfo(eventId, () => this.setLanguagesData());
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.onResize);
    }

    setLanguagesData = () => {
        const {translation, eventInfo, eventLanguage} = this.props;

        const brandingTranslation = eventInfo.brandingTranslations.find(
            (translation) => translation.language === eventLanguage
        );
        this.setState({
            brandingTranslation: brandingTranslation,
            translation: translation,
        });
    };

    onResize = () => {
        this.setBreakpoints();
        this.setEventNameFontSize();
    };

    setBreakpoints = () => {
        if (window.innerWidth > 1199.9) {
            this.setState({breakpoint: 'desktop'});
        } else if (window.innerWidth > 767.9) {
            this.setState({breakpoint: 'tablet'});
        } else {
            this.setState({breakpoint: 'phone'});
        }
    };

    handleOpenHomepageVideo = () => {
        this.setState({showVideo: true});
    };

    closeDialog = () => {
        this.setState({showVideo: false});
    };

    setActiveProgramTabIndex = (index, automaticScroll) => () => {
        this.setState({activeProgramTabIndex: index});
        if (automaticScroll) {
            this.tabsHeader.current.scrollIntoView();
            window.scrollBy(0, -130);
        }
    };

    setActivePartnersTabIndex = (index) => () => {
        this.setState({activePartnersTabIndex: index});
    };

    setMediaLinks = () => {
        if (!this.props.eventInfo.brandingData.socialMediaLinks) {
            return;
        }

        this.props.eventInfo.brandingData.socialMediaLinks.forEach((media) => {
            this.setState({
                [media.platform]: media.link,
            });
        });
    };

    getTextWidth = (text, font) => {
        // re-use canvas object for better performance
        let canvas = this.getTextWidth.canvas || (this.getTextWidth.canvas = document.createElement('canvas'));
        let context = canvas.getContext('2d');
        context.font = `${font}px Montserrat`;
        context.textAlign = 'left';
        let metrics = context.measureText(text);
        return metrics.width;
    };

    setEventNameFontSize = () => {
        const {eventInfo} = this.props;
        const eventName = eventInfo.brandingData.homepageEventName;
        let fontSize = this.state.eventNameFontSize;
        const minFontSize = 29;
        const maxFontSize = 90;

        let eventNameContainerWidth = 700;
        if (this.eventTitle.current) {
            eventNameContainerWidth = this.eventTitle.current.offsetWidth;
        }
        if (eventName.length > 4) {
            const sortedWords = eventName.split(' ').sort((a, b) => b.length - a.length);
            const longestWord = sortedWords[0];
            for (let i = maxFontSize; i >= minFontSize; i--) {
                let longestWordWidth = this.getTextWidth(longestWord, i);
                if (longestWordWidth < eventNameContainerWidth) {
                    break;
                }

                // bug fix, althought the calculated word width on canvas is smaller than the eventNameContainerWidth
                // the word still got on the next row, so I forced it to make it 1px smaller than the calculated one
                fontSize = i - 1;
            }
        }

        this.setState({eventNameFontSize: fontSize});
    };

    getBackgroundImageUrl = () => {
        let {eventInfo} = this.props;
        // brandingData.socialMediaLinks
        let backgroundImage;

        let smallBackgroundImage =
            eventInfo.brandingData.filesUrl +
            (eventInfo.brandingData.homepageBackground.resize1080
                ? eventInfo.brandingData.homepageBackground.resize1080
                : eventInfo.brandingData.homepageBackground);
        let mediumBackgroundImage =
            eventInfo.brandingData.filesUrl +
            (eventInfo.brandingData.homepageBackground.resize2160
                ? eventInfo.brandingData.homepageBackground.resize2160
                : eventInfo.brandingData.homepageBackground);
        let originalBackgroundImage =
            eventInfo.brandingData.filesUrl +
            (eventInfo.brandingData.homepageBackground.original
                ? eventInfo.brandingData.homepageBackground.original
                : eventInfo.brandingData.homepageBackground);

        if (window.innerHeight < 1081) {
            backgroundImage = smallBackgroundImage;
        } else if (window.innerHeight < 2161) {
            backgroundImage = mediumBackgroundImage;
        } else {
            backgroundImage = originalBackgroundImage;
        }

        return backgroundImage;
    };

    checkHasSocialMediaLinks = (socialMediaLinks) =>
        socialMediaLinks && socialMediaLinks.filter(({link}) => link).length > 0;

    render() {
        const {eventInfo, languages, userLoading, loadingEvent} = this.props;
        const {translation, brandingTranslation} = this.state;
        let randomPartnerIndex = null;
        if (eventInfo.brandingData.banners.length > 0) {
            randomPartnerIndex = Math.floor(Math.random() * eventInfo.brandingData.banners.length);
        }
        let homepageVideoId = getVideoIdFromYoutubeUrl(eventInfo.brandingData.homepageVideo);

        if (userLoading || loadingEvent) {
            return (
                <div className="homepage-spinner-container">
                    <FullPageSpinner />
                </div>
            );
        }
        let overlayColor = {};
        if (eventInfo.brandingData.overlayColorHomepage) {
            overlayColor.background = eventInfo.brandingData.overlayColorHomepage;
        }
        const BackgroundImage = getPlatformBackground(whiteLabel);

        return (
            <div className={userLoading || loadingEvent ? 'loading' : null}>
                <SocialsPromotion eventId={this.props?.eventInfo?._id} />
                {eventInfo ? (
                    <div className="homepage">
                        <section className="top-section">
                            <div className="background-container">
                                {eventInfo.brandingData.homepageBackground ? (
                                    <img src={this.getBackgroundImageUrl()} className="background" alt="background" />
                                ) : (
                                    <img src={BackgroundImage} className="background" alt="background" />
                                )}
                                <div className="overlay" style={overlayColor}></div>
                            </div>
                            <div className="homepage-container">
                                <div className="event-details-container">
                                    <div className="main-details">
                                        <div className="details-information" id="eventNameContainer">
                                            <h1
                                                style={{
                                                    fontSize: `${this.state.eventNameFontSize}px`,
                                                    ...this.eventNameWidth,
                                                }}
                                            >
                                                {brandingTranslation?.homepageEventName}
                                            </h1>
                                            <p>
                                                {translation?.homepage.organizedBy}{' '}
                                                <span className="organizer-name">
                                                    {brandingTranslation?.homepageOrganizerName}
                                                </span>
                                            </p>
                                            <div style={{marginBottom: '32px'}}>
                                                {!eventInfo.hideParticipantsLink && <RegisterForTheEventLink />}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="extra-details-wrapper">
                                        <div className="extra-details">
                                            <div className="single-detail-container">
                                                <p className="label">{translation?.homepage.date}</p>
                                                <p className="information">{brandingTranslation?.homepageDateText}</p>
                                            </div>
                                            {brandingTranslation?.homepageLocationText && (
                                                <div className="single-detail-container">
                                                    <p className="label">{translation?.homepage.location}</p>
                                                    <p className="information">
                                                        {brandingTranslation?.homepageLocationText}
                                                    </p>
                                                </div>
                                            )}
                                            {brandingTranslation?.homepagePriceText && (
                                                <div className="single-detail-container">
                                                    <p className="label">{translation?.homepage.price}</p>
                                                    <p className="information">
                                                        {brandingTranslation?.homepagePriceText}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section className="program-section">
                            <div
                                className={
                                    'tabs-header ' + (this.state.activeProgramTabIndex === 1 ? 'secondary-colors' : '')
                                }
                                ref={this.tabsHeader}
                            >
                                <div className="homepage-container">
                                    {this.state.breakpoint === 'desktop' && (
                                        <div className="tabs-name-container">
                                            <div
                                                className={
                                                    'tab-name ' +
                                                    (this.state.activeProgramTabIndex === 0 ? 'active' : '')
                                                }
                                                onClick={this.setActiveProgramTabIndex(0)}
                                            >
                                                <span>{translation?.homepage.tabIntro}</span>
                                            </div>
                                            {eventInfo.showProgram && (
                                                <div
                                                    className={
                                                        'tab-name ' +
                                                        (this.state.activeProgramTabIndex === 1 ? 'active' : '')
                                                    }
                                                    onClick={this.setActiveProgramTabIndex(1)}
                                                >
                                                    <span>{translation?.homepage.tabProgram}</span>
                                                </div>
                                            )}
                                            {this.checkHasSocialMediaLinks(eventInfo.brandingData.socialMediaLinks) && (
                                                <div
                                                    className={
                                                        'tab-name ' +
                                                        (this.state.activeProgramTabIndex === 2 ? 'active' : '')
                                                    }
                                                    onClick={this.setActiveProgramTabIndex(2)}
                                                >
                                                    <span>{translation?.homepage.tabSocialMedia}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="tabs-content">
                                {(this.state.activeProgramTabIndex === 0 || this.state.breakpoint !== 'desktop') && (
                                    <div className="homepage-container">
                                        <div className="event-description-container">
                                            {this.state.breakpoint !== 'desktop' && (
                                                <p className="event-description-container-title">
                                                    {translation?.homepage.tabIntro}
                                                </p>
                                            )}
                                            <div
                                                className={classNames('description', {
                                                    isRtl: this.props.languages.isRtlLanguage,
                                                })}
                                            >
                                                <p>
                                                    {brandingTranslation?.homepageDescription
                                                        .split('\n')
                                                        .map((item, key) => {
                                                            return (
                                                                <span key={key}>
                                                                    {item}
                                                                    <br />
                                                                </span>
                                                            );
                                                        })}
                                                </p>
                                            </div>
                                            <div className="media-container">
                                                {eventInfo && eventInfo.brandingData.homepageVideo && (
                                                    <div
                                                        className="video-container"
                                                        onClick={this.handleOpenHomepageVideo}
                                                    >
                                                        <img
                                                            src={`https://img.youtube.com/vi/${homepageVideoId}/0.jpg`}
                                                            className="background"
                                                            alt="video background"
                                                        />
                                                        <div className="video-overlay">
                                                            <PlayIcon />
                                                        </div>
                                                    </div>
                                                )}
                                                {randomPartnerIndex !== null && (
                                                    <div className="random-banner">
                                                        <a
                                                            href={
                                                                eventInfo.brandingData.banners[randomPartnerIndex]
                                                                    .website
                                                            }
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                        >
                                                            <img
                                                                className="background"
                                                                src={
                                                                    eventInfo.brandingData.filesUrl +
                                                                    eventInfo.brandingData.banners[randomPartnerIndex]
                                                                        .image
                                                                }
                                                                alt="partner logo"
                                                            />
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                            {eventInfo.brandingData.homepageVideo && (
                                                <div className="homepage">
                                                    <BoothVideoDialog
                                                        opened={this.state.showVideo}
                                                        closeDialog={this.closeDialog}
                                                        videoUrl={eventInfo.brandingData.homepageVideo}
                                                        classes={'homepage'}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        {this.state.breakpoint === 'desktop' && eventInfo.showProgram && (
                                            <div
                                                className="go-to-tab-button"
                                                onClick={this.setActiveProgramTabIndex(1, true)}
                                                tabIndex={0}
                                            >
                                                <span>{translation?.homepage.tabIntroProgramButton}</span>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {eventInfo.showProgram && this.state.breakpoint !== 'desktop' && (
                                    <div className="tabs-header secondary-colors" />
                                )}
                                {(this.state.activeProgramTabIndex === 1 || this.state.breakpoint !== 'desktop') && (
                                    <EventProgram
                                        translation={translation}
                                        languages={languages}
                                        breakpoint={this.state.breakpoint}
                                        setActiveProgramTabIndex={this.setActiveProgramTabIndex}
                                    />
                                )}
                                {(this.state.activeProgramTabIndex === 2 || this.state.breakpoint !== 'desktop') && (
                                    <div className="homepage-container social">
                                        {this.state.facebook && (
                                            <FacebookProvider appId="app-container">
                                                <Page
                                                    href={this.state.facebook}
                                                    tabs="timeline"
                                                    height={this.EMBEDED_CONFIG.height}
                                                    width={this.EMBEDED_CONFIG.width}
                                                />
                                            </FacebookProvider>
                                        )}

                                        {this.state.twitter && (
                                            <TwitterTimelineEmbed
                                                sourceType="profile"
                                                screenName={getTwitterScreenName(this.state.twitter)}
                                                options={{
                                                    height: this.EMBEDED_CONFIG.height,
                                                    width: this.EMBEDED_CONFIG.width,
                                                }}
                                            />
                                        )}
                                        <LinkedInBadge url={this.state.linkedin} />
                                    </div>
                                )}
                            </div>
                        </section>
                        {(eventInfo.brandingData.partners.length > 0 ||
                            eventInfo.brandingData.exhibitors.length > 0) && (
                            <section className="partners-section">
                                <div
                                    className={
                                        'tabs-header ' +
                                        (this.state.activePartnersTabIndex === 1 ? 'secondary-colors' : null)
                                    }
                                >
                                    <div className="homepage-container">
                                        <div className="tabs-name-container">
                                            {eventInfo.brandingData.partners.length > 0 && (
                                                <div
                                                    className={
                                                        'tab-name ' +
                                                        (this.state.activePartnersTabIndex === 0 ? 'active' : null)
                                                    }
                                                    onClick={this.setActivePartnersTabIndex(0)}
                                                >
                                                    <span>{translation?.homepage.tabPartners}</span>
                                                </div>
                                            )}
                                            {eventInfo.brandingData.exhibitors.length > 0 && (
                                                <div
                                                    className={
                                                        'tab-name ' +
                                                        (this.state.activePartnersTabIndex === 1 ? 'active' : null)
                                                    }
                                                    onClick={this.setActivePartnersTabIndex(1)}
                                                >
                                                    <span>{translation?.homepage.tabExhibitors}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="tabs-content">
                                    {this.state.activePartnersTabIndex === 0 &&
                                        eventInfo.brandingData.partners.length > 0 && (
                                            <PartnersSlider event={eventInfo} />
                                        )}
                                    {this.state.activePartnersTabIndex === 1 &&
                                        eventInfo.brandingData.exhibitors.length > 0 && (
                                            <ExhibitorSlider event={eventInfo} />
                                        )}
                                </div>
                            </section>
                        )}
                        {eventInfo.brandingData.homepagePreviousEvents.length > 0 && (
                            <section className="previous-events-section">
                                <div className="homepage-container">
                                    <h3>{translation?.homepage.previousEventsSectionTitle}</h3>
                                    <div className="links-container">
                                        {eventInfo.brandingData.homepagePreviousEvents.map((previousEvent) => {
                                            return (
                                                <div className="link-container" key={previousEvent._id}>
                                                    <a
                                                        href={previousEvent.url}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {previousEvent.label}
                                                    </a>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </section>
                        )}
                    </div>
                ) : null}
                <Footer />
            </div>
        );
    }
}

export default HomepageEvent;
