import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import VideoAvatar from '../VideoAvatar/VideoAvatar';

import * as selectors from '../../../../../../../../../../../../../store/selectors';
import {useStyles} from './VideoChatPlayerSelfVideo.styles';

const VideoChatPlayerSelfVideo = ({activeStreamsCount, selfVidRef, selfPlayback, startVideo}) => {
    const classes = useStyles();
    const videoConference = useSelector(selectors.getActiveCallTypeData);
    const user = useSelector(selectors.getUserData);

    useEffect(() => {
        (async () => await startVideo(selfVidRef, selfPlayback))();
    }, [selfPlayback]); // eslint-disable-line react-hooks/exhaustive-deps

    const isCameraOff = !videoConference.selfPlayback.availableKinds.includes('video');
    const isActiveStreams = activeStreamsCount > 0;
    return (
        <div className={classNames(isActiveStreams ? classes.activeStreamsVideoPlate : classes.videoPlate)}>
            {isCameraOff && (
                <div className={classes.userName}>
                    <VideoAvatar member={user} isMinified={isActiveStreams} />
                </div>
            )}
            <video
                className={classNames(classes.video, isCameraOff && classes.hidden)}
                ref={selfVidRef}
                id="playback-video-self"
                muted={true}
                playsInline={true}
            ></video>
        </div>
    );
};

export default VideoChatPlayerSelfVideo;
