import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {useStyles} from './VerticalWallButtons.style';
import {Button, Tooltip} from '@material-ui/core';

function VerticalWallButtons({buttons, activeIndex, allClose}) {
    const style = useStyles();

    if (!buttons?.length) return null;

    return (
        <div className={classNames(style.container)}>
            {buttons.map(({name, action, icon, hide, index}, i) => {
                return (
                    <Tooltip
                        className={classNames({[style.hide]: hide})}
                        key={`${name}-${i}`}
                        title={
                            activeIndex !== index ? (
                                <div className={classNames(style.tooltipTitle)}>
                                    <div className={classNames(style.endIcon, style.tooltipIcon)}>{icon}</div> {name}
                                </div>
                            ) : (
                                ''
                            )
                        }
                        placement="left"
                        arrow
                        classes={{
                            arrow: style.arrow,
                            tooltip: style.tooltip,
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={() => {
                                action();
                            }}
                            className={classNames(style.button, {
                                [`${style.active}`]: index === activeIndex && allClose,
                            })}
                            endIcon={index === activeIndex && icon && allClose ? icon : ''}
                            classes={{
                                endIcon: classNames(style.endIcon),
                                label: classNames({[style.label]: !(activeIndex === index)}),
                            }}
                        >
                            {activeIndex === index && allClose ? `${name}` : icon || ''}
                        </Button>
                    </Tooltip>
                );
            })}
        </div>
    );
}
VerticalWallButtons.propTypes = {
    buttons: PropTypes.array.isRequired,
    activeIndex: PropTypes.number,
    allClose: PropTypes.bool,
};

export default VerticalWallButtons;
