import React, {useState, useEffect, useCallback} from 'react';
import classNames from 'classnames';

import MainLogo from '../../../MainLogo/MainLogo';
import {RouterConfig} from 'routerConfig';
import './AdminMenu.scss';
/* eslint-disable */

export function AdminMenu({history, onLogoutUser}) {
    const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
    const [topLinksContainerHeight, setTopLinksContainerHeight] = useState(0);

    useEffect(() => {
        if (mobileMenuVisible) setTopLinksContainerHeight(55);
    }, [mobileMenuVisible]);

    const toggleMenu = () => setMobileMenuVisible(!mobileMenuVisible);

    const onHandleLogout = useCallback(
        (e) => {
            e.preventDefault();
            onLogoutUser();
        },
        [onLogoutUser]
    );

    const proceedToHomePage = () => history.push(RouterConfig.root);

    return (
        <header>
            <div className="admin-menu-wrapper">
                <MainLogo colored className="brand-logo" onClick={proceedToHomePage} />
                <div className="menu-links">
                    <a onClick={onHandleLogout} className="logout-button">
                        Log out
                    </a>
                </div>
            </div>
            <div className={classNames('mobile-menu-wrapper', {opened: mobileMenuVisible})}>
                <div className="top-links-container" style={{height: `${topLinksContainerHeight}px`}}></div>
                <div className="bottom-links-container">
                    <div className="button-container" onClick={toggleMenu}>
                        <div className="menu-bar" />
                        <div className="menu-bar" />
                        <div className="menu-bar" />
                    </div>
                    <div className="menu-links">
                        <a onClick={onHandleLogout} className="logout-button">
                            Log out
                        </a>
                    </div>
                </div>
            </div>
        </header>
    );
}
