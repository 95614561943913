import * as meetingSetupActionTypes from '../actionTypes/meetingSetupActionTypes';

export const toggleMeetingSetup = (isOpened) => ({
    type: meetingSetupActionTypes.TOGGLE_MEETING_SETUP,
    payload: isOpened,
});

export const setMeetingSetupUser = (user) => ({
    type: meetingSetupActionTypes.SET_MEETING_SETUP_USER,
    payload: user,
});

export const resetMeetingSetupState = () => ({
    type: meetingSetupActionTypes.RESET_MEETING_SETUP_STATE,
});
