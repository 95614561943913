import React from 'react';

import IconContainer from '../../../../../../../../../IconContainer/IconContainer';
import {IconFilledLinkedin, IconFilledFacebook, IconFilledTwitter} from '../../../../../../../../../Icons';

import {socialsSlug} from '../../../../../../../../../../../constants/data';
import './UserPreviewPlateSocial.scss';

const ICON_BY_SLUG = {
    [socialsSlug.linkedin]: <IconFilledLinkedin />,
    [socialsSlug.facebook]: <IconFilledFacebook />,
    [socialsSlug.twitter]: <IconFilledTwitter />,
};

const UserPreviewPlateSocial = ({slug, link}) => (
    <a className="UserPreviewPlateSocial" href={link} target="_blank" rel="noopener noreferrer">
        <IconContainer>{ICON_BY_SLUG[slug]}</IconContainer>
    </a>
);

export default UserPreviewPlateSocial;
