import {useCallback, useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams, useHistory} from 'react-router-dom';
import {NotificationManager} from 'react-notifications';
import {
    SESSION_EVENTS,
    DSO_EVENTS,
    SIGNALING_TRANSPORT_STATES,
    SESSION_AUTHENTICATE_RESULT
} from 'streaming-client-sdk/src/constants';
import {
    PARTICIPANT_TYPES,
    VIDEO_ROOM_EVENTS,
    MS_EVENTS,
    MEDIA_TYPES,
    ETX_SESSION_KICKED_USER_ERROR_MESSAGE
} from 'constants/organizer/sessions';
import {
    clearCallState,
    setDisconnectedState,
    addSession,
    removeSession,
    closeConsumer,
    pauseConsumer,
    resumeConsumer,
    setRoom,
    setMediaSession,
    addConsumer,
    joinRoom,
    getLocalStream,
    getScreenShareStream,
    toggleSelfAudio,
    toggleSelfVideo,
    toggleScreenShareVideo,
    disableScreenShare,
    createRoomFail,
    newDominantSpeakerOrder
} from 'store/actions';
import * as selectors from 'store/selectors';

export const useCreateRoom = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {client: storeClient, room: storeRoom, capture: stream, isKicked} = useSelector(selectors.getSessionData);
    const etxSessionToken = useSelector(selectors.getETXSessionToken);
    const storeUser = useSelector(selectors.getUserData);
    const {sessionId} = useParams();
    const [loading, setLoading] = useState(false);

    const leaveRoom = useCallback(
        (isReconnect) => {
            dispatch(clearCallState(isReconnect));
            dispatch(disableScreenShare());
        },
        [dispatch]
    );

    useEffect(() => {
        window.addEventListener('beforeunload', leaveRoom);
        return () => {
            window.removeEventListener('beforeunload', leaveRoom);
        };
    }, [leaveRoom, storeClient]);

    useEffect(() => {
        return () => {
            leaveRoom();
        };
    }, [leaveRoom]);

    const createRoom = useCallback(async () => {
        if (!etxSessionToken || !storeClient || isKicked) return;
        setLoading(true);

        let client = storeClient;

        const audioTrack = stream.getAudioTracks()[0];
        const videoTrack = stream.getVideoTracks()[0];

        let room = storeRoom;
        try {
            if (!room) {
                room = await client.getRoom(sessionId);
            }
            if (room.roomId) {
                const mediaSession = await client.createMediaSession();

                mediaSession.on(MS_EVENTS.CONSUMER.CREATED, ({userId, consumer}) =>
                    dispatch(
                        addConsumer(
                            {
                                id: consumer.id,
                                rtpParameters: consumer.rtpParameters,
                                codec: consumer.rtpParameters.codecs[0].mimeType.split('/')[1],
                                track: consumer.track,
                                appData: consumer.appData,
                                ...consumer
                            },
                            userId
                        )
                    )
                );
                mediaSession.on(MS_EVENTS.CONSUMER.DESTROYED, ({consumerId, userId}) => {
                    dispatch(closeConsumer({userId, consumerId}));
                });
                mediaSession.on(MS_EVENTS.CONSUMER.PAUSED, ({consumerId, userId}) => {
                    dispatch(pauseConsumer({userId, consumerId}));
                });
                mediaSession.on(MS_EVENTS.CONSUMER.RESUMED, ({userId, consumerId}) => {
                    dispatch(resumeConsumer({userId, consumerId}));
                });

                room.on(VIDEO_ROOM_EVENTS.SESSION_JOINED, (session) => {
                    dispatch(addSession(session));
                    NotificationManager.info(`${session.displayName} has joined the room`, 'Info message', 3000);
                });
                room.on(VIDEO_ROOM_EVENTS.SESSION_LEFT, (session) => {
                    dispatch(removeSession(session));
                    if (client.role !== PARTICIPANT_TYPES.mixer) {
                        NotificationManager.info(`${session.displayName} has left the room`, 'Info message', 3000);
                    } else {
                        NotificationManager.error('Mixer server error', 'Error message', 3000);
                    }
                });
                room.on(VIDEO_ROOM_EVENTS.PRODUCER_TOGGLE, ({producerType}) => {
                    switch (producerType) {
                        case MEDIA_TYPES.audio:
                            mediaSession.isMuted() ? mediaSession.unmuteMic() : mediaSession.muteMic();
                            dispatch(toggleSelfAudio());
                            break;
                        case MEDIA_TYPES.video:
                            mediaSession.isVideoEnabled()
                                ? mediaSession.disableVideo(videoTrack)
                                : dispatch(getLocalStream());
                            dispatch(toggleSelfVideo());
                            break;
                        case MEDIA_TYPES.consumerScreenShare:
                            mediaSession.isScreenShared()
                                ? dispatch(disableScreenShare())
                                : dispatch(getScreenShareStream());
                            dispatch(toggleScreenShareVideo());

                            break;
                        default:
                            break;
                    }
                });

                const joinedSessions = await room.join(mediaSession);

                const joinedUserAccount = joinedSessions.find(({userId}) => userId === storeUser._id);

                if (joinedUserAccount) {
                    NotificationManager.error(
                        'Account already joined to the session',
                        'Error message',
                        3000,
                        history.goBack()
                    );

                    return;
                }

                client.events.on(SIGNALING_TRANSPORT_STATES.CONNECTED, async () => {
                    try {
                        const response = await client.authenticate();

                        if (response === SESSION_AUTHENTICATE_RESULT.CREATED) {
                            leaveRoom(true);

                            dispatch(setDisconnectedState(true));
                        }

                        dispatch(setDisconnectedState(false));
                    } catch (error) {
                        const isUserKicked = error?.data === ETX_SESSION_KICKED_USER_ERROR_MESSAGE;

                        dispatch(createRoomFail(isUserKicked));
                    }
                });

                client.events.on(SIGNALING_TRANSPORT_STATES.DISCONNECTED, () => dispatch(setDisconnectedState(true)));

                client.events.on(SESSION_EVENTS.KICKED, async () => dispatch(createRoomFail(true)));

                client.events.on(DSO_EVENTS.NEW_DOMINANT_SPEAKER_ORDER, (list) =>
                    dispatch(newDominantSpeakerOrder(list))
                );

                if (client.role !== PARTICIPANT_TYPES.interpreter) {
                    await mediaSession.enableMic(audioTrack);
                    !audioTrack.enabled && mediaSession.muteMic();

                    videoTrack.enabled && (await mediaSession.enableVideo(videoTrack));
                }

                if (client.role === PARTICIPANT_TYPES.interpreter) {
                    if (joinedSessions.length > 1) {
                        console.warn('SOMETHING WEIRD, TRANSLATOR SHOULD ONLY RECEIVE ONE MIXER SESSION');
                    }

                    const mixerSession = joinedSessions[0];

                    for (const consumer of mixerSession.consumers) {
                        if (consumer.kind === MEDIA_TYPES.audio) {
                            const mixedStream = mediaSession.createMixedStream(audioTrack, consumer.track);
                            await mediaSession.enableMic(mixedStream.getAudioTracks()[0]);

                            !audioTrack.enabled && mediaSession.muteMic();
                        } else {
                            await mediaSession.enableTranslatorVideo(consumer.track);
                        }
                    }
                }

                dispatch(joinRoom(joinedSessions));
                dispatch(setRoom(room));
                dispatch(setMediaSession(mediaSession));
            } else {
                console.error("The room doesn't exist");
            }
        } catch (error) {
            console.error(error);
        }

        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, etxSessionToken, sessionId, storeClient, storeRoom, storeUser, leaveRoom]);

    useEffect(() => {
        createRoom();
    }, [createRoom]);

    return {loading};
};
