import React from 'react';
import WarningRoundedIcon from '@material-ui/icons/WarningRounded';
import Button from '../../../../../Button/Button';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import './ModarateActions.scss';
import {useSelector} from 'react-redux';
import {getIsRtlLanguage} from '../../../../../../../store/selectors';

const ModarateActions = ({
    onApprove,
    className = '',
    showPendingMessage,
    showModarateBtn,
    pendingMessage,
    btnTitle,
    show,
}) => {
    const isRtlLanguage = useSelector(getIsRtlLanguage);
    if (!show) {
        return null;
    }

    return (
        <div className={classNames('moderation-action__action-wrapper', className, {isRtl: isRtlLanguage})}>
            {showModarateBtn && <Button onClick={onApprove} title={btnTitle} color="secondary" variant="outlined" />}
            {showPendingMessage && (
                <div className="moderation-action__pending-comment-msg">
                    <p className="moderation-action__pending-comment-msg-text">{pendingMessage}</p>
                    <WarningRoundedIcon className="moderation-action__pending-comment-msg-icon" />
                </div>
            )}
        </div>
    );
};

ModarateActions.propTypes = {
    className: PropTypes.string,
    showPendingMessage: PropTypes.bool,
    showModarateBtn: PropTypes.bool,
    pendingMessage: PropTypes.string,
    btnTitle: PropTypes.string,
    show: PropTypes.bool,
    onApprove: PropTypes.func,
};

export default ModarateActions;
