import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    wrapper: {
        width: 500,
        padding: 20,
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: 24,
        lineHeight: '30px',
        letterSpacing: '0.0015em',
        padding: '15px 0',
        margin: 0,
    },
    buttonWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
    },
    button: {
        height: 54,
        width: '50%',
        fontSize: 14,
        lineHeight: '120%',
        textTransform: 'uppercase',
        margin: '0px 10px',
        boxShadow: 'none',
    },
}));
