import React, {useCallback, useContext, useState, useEffect} from 'react';
import classNames from 'classnames';
import Tooltip from '@material-ui/core/es/Tooltip/Tooltip';
import {Lock as LockIcon} from '@material-ui/icons';
import {isIOS} from 'react-device-detect';
import Wall from '../Wall';
import Networking from './components/Networking';
import SideMenuMobile from './components/SideMenuMobile';
import usePlatformTranslation from 'services/hooks/usePlatformTranslation';
import GreaterArrow from '../../../Images/greater-arrow.svg';
import Survey from './components/Survey/Survey';

import {AbilityContext} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';
import {IS_MAIN_ENV} from 'constants/shared';
import SocialMediaTab from './components/SocialMediaTab';
import VerticalWallButtons from 'Routes/components/VerticalWallButtons';
import {
    TranslateIcon,
    LiveWallIcon,
    SurveyIcon,
    NetworkingIcon,
    SocialMediaIcon,
} from 'Images/pages/platform-homepage/wall';
import Interpretation from './components/Interpretation';

import './SideMenu.scss';

export function SideNav({
    event,
    seeNetworking,
    seeSurvey,
    setActiveWall,
    seeLiveWall,
    sideNavigationDetails,
    closeSidebar,
    resourcesAccess,
    sideNavigation,
    isLargeScreen,
    seeVideoWall,
    seeInfoTab,
    seeSocialMedia,
    seeInterpretation,
    hideSocialMedia,
    hideSurvey,
    hideInterpretation,
    hideNetworking,
    hideWall,
}) {
    const [activeButtonIndex, setActiveButtonIndex] = useState(0);
    useEffect(() => {
        const buttonIndex = [
            sideNavigation.socialMedia,
            sideNavigation.seeSurvey,
            sideNavigation.interpretation,
            sideNavigation.seeNetworking,
            sideNavigation.seeLiveWall,
        ].findIndex((i) => i);

        setActiveButtonIndex(buttonIndex === -1 ? 0 : buttonIndex);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        sideNavigation.socialMedia,
        sideNavigation.seeSurvey,
        sideNavigation.seeNetworking,
        sideNavigation.seeLiveWall,
    ]);

    const ability = useContext(AbilityContext);
    const translation = usePlatformTranslation();

    const showNetworking = useCallback(() => seeNetworking(), [seeNetworking]);

    const showSurvey = useCallback(() => seeSurvey(), [seeSurvey]);

    const handleSeeNetworking = () => {
        if (sideNavigation?.seeNetworking) return;

        if (event.remoteNetworking.enabled && IS_MAIN_ENV) {
            const target = isIOS ? '_blank' : 'NetworkingLink';
            window.open(event.remoteNetworking.link, target);
            return;
        }

        showNetworking();
    };

    const handleSeeSurvey = () => {
        !sideNavigation?.seeSurvey && showSurvey();
    };

    const showSocialMedia = useCallback(() => seeSocialMedia(), [seeSocialMedia]);

    const handleSeeSocialMedia = () => {
        !sideNavigation?.socialMedia && showSocialMedia();
    };
    const showInterpretation = useCallback(() => seeInterpretation(), [seeInterpretation]);
    const handleSeeInterpretation = () => {
        !sideNavigation?.interpretation && showInterpretation();
    };

    const handleSeeLiveWall = useCallback(() => {
        const wallData = {
            wallId: event.liveWall._id,
            highlightUsers: [],
        };

        setActiveWall(wallData);
        seeLiveWall();
    }, [event.liveWall._id, seeLiveWall, setActiveWall]);

    const handleSeeVideoWall = useCallback(() => {
        const wallData = {
            wallId: sideNavigationDetails.currentTimeSlotVideoWallId,
            highlightUsers: sideNavigationDetails.currentTimeSlotVideoWallSpeakers,
        };

        setActiveWall(wallData);

        seeVideoWall();
    }, [
        seeVideoWall,
        setActiveWall,
        sideNavigationDetails.currentTimeSlotVideoWallId,
        sideNavigationDetails.currentTimeSlotVideoWallSpeakers,
    ]);

    const handleSeeInfoTab = useCallback(() => seeInfoTab(), [seeInfoTab]);

    const handleCloseSidebar = useCallback(() => closeSidebar(), [closeSidebar]);

    const accessToNetwork = event.hasAccessManagement && resourcesAccess !== null ? resourcesAccess?.networking : true;

    const isModerator = ability.can(ROLE_ABILITIES_TYPES.COMMON.APPROVE_WALL_ABILITY, ROLE_FEATURES_TYPES.WALL_APPROVE);

    if (!isLargeScreen) {
        return (
            <SideMenuMobile
                handleSeeNetworking={handleSeeNetworking}
                handleSeeLiveWall={handleSeeLiveWall}
                handleSeeVideoWall={handleSeeVideoWall}
                handleSeeInfoTab={handleSeeInfoTab}
            />
        );
    }

    const menuButtons = [
        {
            name: translation?.sideMenu?.socialMedia,
            action: handleSeeSocialMedia,
            icon: <SocialMediaIcon />,
            hide: hideSocialMedia ?? false,
            index: 0,
        },
        {
            name: translation?.sideMenu?.survey,
            action: handleSeeSurvey,
            icon: <SurveyIcon />,
            hide: !event.survey.some(({hidden}) => !hidden) || hideSurvey,
            index: 1,
        },
        {
            name: translation?.sideMenu?.interpretation,
            action: handleSeeInterpretation,
            icon: <TranslateIcon />,
            hide: hideInterpretation ?? false,
            index: 2,
        },
        {
            name: translation?.sideMenu?.networking,
            action: handleSeeNetworking,
            icon: <NetworkingIcon />,
            hide: hideNetworking || !accessToNetwork || false,
            index: 3,
        },
        {
            name: translation?.sideMenu?.liveWall,
            action: handleSeeLiveWall,
            icon: <LiveWallIcon />,
            hide: hideWall || false,
            index: 4,
        },
    ];

    return (
        <>
            <div className={classNames('side-menu-container', {close: !sideNavigation.isOpened})}>
                <div className="event-actions-container swipeable-drawer">
                    <div className="buttons-wrapper">
                        <VerticalWallButtons
                            buttons={menuButtons}
                            activeIndex={activeButtonIndex}
                            allClose={sideNavigation.isOpened}
                        />
                    </div>
                </div>
                <div
                    className={classNames(
                        `wall-and-networking-container ${sideNavigation.isOpened ? 'open' : 'close'}`
                    )}
                >
                    {sideNavigation.isOpened && (
                        <div>
                            <div className="close-dropdown" onClick={handleCloseSidebar}>
                                <img src={GreaterArrow} alt="Close button" />
                            </div>
                            <div className="tabs-container">
                                {sideNavigationDetails.hasVideoWall ? (
                                    <>
                                        {!sideNavigation.seeLiveWall && (
                                            <div className="live-wall-tab button-tab tab" onClick={handleSeeLiveWall}>
                                                <div
                                                    className="video-wall-tab button-tab tab"
                                                    onClick={handleSeeLiveWall}
                                                >
                                                    {translation?.sideMenu.liveWall}
                                                </div>
                                            </div>
                                        )}
                                        {!sideNavigation.seeVideoWall && (
                                            <div className="video-wall-tab button-tab tab" onClick={handleSeeVideoWall}>
                                                {translation?.sideMenu.videoWall}
                                            </div>
                                        )}

                                        {!sideNavigation.seeNetworking && (
                                            <div
                                                className="networking-tab button-tab tab"
                                                onClick={
                                                    resourcesAccess !== null && !accessToNetwork
                                                        ? undefined
                                                        : handleSeeNetworking
                                                }
                                            >
                                                {translation?.sideMenu.networking}
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div
                                            className={classNames('live-wall-tab button-tab tab', {
                                                active: sideNavigation.seeLiveWall,
                                            })}
                                            onClick={handleSeeLiveWall}
                                        >
                                            {translation?.sideMenu.liveWall}
                                        </div>
                                        <div
                                            className={classNames('networking-tab button-tab tab', {
                                                active: sideNavigation.seeNetworking,
                                            })}
                                            onClick={
                                                resourcesAccess !== null && !accessToNetwork
                                                    ? undefined
                                                    : handleSeeNetworking
                                            }
                                        >
                                            {resourcesAccess !== null && !accessToNetwork ? (
                                                <Tooltip title={translation?.sideMenu.networkingTooltip}>
                                                    <div className="restricted-side-tab">
                                                        <LockIcon fontSize="small" /> {translation?.sideMenu.networking}
                                                    </div>
                                                </Tooltip>
                                            ) : (
                                                <>{translation?.sideMenu.networking}</>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div>
                                {sideNavigation.seeLiveWall && (
                                    <Wall withModeration={event.liveWallModeration} isModerator={isModerator} />
                                )}
                                {sideNavigation.interpretation && <Interpretation />}
                                {sideNavigation.seeNetworking && <Networking />}
                                {sideNavigation.seeSurvey && <Survey />}
                                {sideNavigation.socialMedia && (
                                    <SocialMediaTab
                                        closeSocial={handleSeeLiveWall}
                                        socials={event.brandingData.socialMediaLinks}
                                        title={translation?.sideMenu?.socialMedia}
                                    />
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
