import React from 'react';
import {usePlatformTranslation} from 'services/hooks';
import {ReactComponent as WallEmptyIcon} from 'Images/chat-default.svg';
import './NoChatsInfo.scss';

const NoChatsInfo = () => {
    const translation = usePlatformTranslation();

    return (
        <div className="no-chats-info-container">
            <div className="page-title">
                <p>{translation?.chatsDropdown.newChatStart}</p>
            </div>
            <div>
                <p>{translation?.chatsDropdown.noChatsError}</p>
                <WallEmptyIcon className="primary-fill-svg" />
            </div>
        </div>
    );
};

export default NoChatsInfo;
