import React from 'react';

import {checkIsFunction} from '../../../services/utils';

import './Form.scss';

const Form = ({children, onSubmit, ...rest}) => {
    const handleSubmit = (e) => {
        e.preventDefault();

        if (checkIsFunction(onSubmit)) {
            onSubmit(e);
        }
    };

    return (
        <form className="Form" onSubmit={handleSubmit} {...rest}>
            {children}
        </form>
    );
};

Form.Row = ({children}) => <div className="Form__row">{children}</div>;

export default Form;
