import React from 'react';
import {Link} from 'react-router-dom';
import classNames from 'classnames';
import {generatePath} from 'react-router';

import DetectOutsideClick from '../../../DetectOutsideClick/DetectOutsideClick';
import IconContainer from '../../../IconContainer/IconContainer';
import {IconAgenda} from '../../../Icons';
import Chats from './components/Chats';
import MyEvents from './components/MyEvents';
import BackButton from './components/BackButton/BackButton';
import MyBooths from './components/MyBooths/MyBooths';
import BusinessWalletPopup from './components/BusinessWalletPopup/BusinessWalletPopup';
import AgendaPopup from './components/AgendaPopup';
import Notifications from './components/Notifications';
import Program from './components/Program/Program';
import MyAccount from './components/MyAccount';
import EventMenuMobile from './components/EventMenuMobile';
import {withTheme} from '@material-ui/core/styles';

import {
    connectToEvent,
    disconnectFromEvent,
    connectToBuilding,
    disconnectFromBuilding,
    wallClosed,
    wallOpened,
    incomingCallReceived,
    p2pCallOfferReceived,
    p2pCallCandidateReceived,
    participantHasLeft,
    iceServersReceived,
    connectToWall,
    disconnectFromWall,
} from 'Api/socketApi';
import {getNotReadChatMessages, getSortedNotifications} from 'services/utils';

import {ReactComponent as HomeIcon} from 'Images/home.svg';
import {ReactComponent as ChatsIcon} from 'Images/chat.svg';
import {ReactComponent as ArchiveIcon} from 'Images/archive-icon.svg';
import {ReactComponent as MenuDropdownIcon} from 'Images/menu-dropdown-icon.svg';
import {ReactComponent as ArchiveIconActive} from 'Images/archive-icon-active.svg';
import {ReactComponent as NotificationsIcon} from 'Images/ring.svg';
import {ReactComponent as WalletIcon} from 'Images/wallet.svg';
import {ReactComponent as ProgramIcon} from 'Images/checklist.svg';
import {ReactComponent as AccountIcon} from 'Images/account.svg';
import {ReactComponent as DashboardIcon} from 'Images/dashboard.svg';
import {ReactComponent as DashboardActiveIcon} from 'Images/dashboard-active.svg';
import {ReactComponent as ChatsActiveIcon} from 'Images/chat-active.svg';
import {ReactComponent as NotificationsActiveIcon} from 'Images/ring-active.svg';
import {ReactComponent as AccountActiveIcon} from 'Images/account-active.svg';
import {ReactComponent as HomeActiveIcon} from 'Images/home-active.svg';
import InterpreterMenuIcon from 'Images/pages/platform-homepage/interpreterMenuIcon';
import SpeakerMenuIcon from 'Images/pages/platform-homepage/speakerMenuIcon';
import ModeratorMenuIcon from 'Images/pages/platform-homepage/moderatorMenuIcon';

import MainLogo from '../../../MainLogo/MainLogo';

import {AbilityContext, Can} from 'permissionsConfig';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from 'constants/ability';

import SocialMediaShareEvent from 'Routes/components/SocialMediaShareEvent';
import {RouterConfig} from 'routerConfig';

import './EventMenu.scss';

class EventMenu extends React.Component {
    state = {
        notReadChatMessages: 0,
        contactsOffset: null,
        programOffsetLeft: null,
        programOffsetWidth: null,
        notificationsOffset: null,
        myAccountOffsetLeft: null,
        myAccountOffsetWidth: null,
        agendaOffsetLeft: null,
        agendaOffsetWidth: null,
        active: false,
        translation: null,
        wallId: null,
    };

    componentDidMount() {
        // only for pages that are in event
        // add a class to app-container <div>
        // to force the screen to be full height
        document.getElementById('app-container').classList.add('event-page');

        const {
            event,
            user,
            buildingId,
            setIncomingCallUserId,
            setIncomingCallOffer,
            setIceCandidate,
            setIceServers,
            clearRemoteUserInfo,
            activeWallId,
        } = this.props;

        if (event?._id) connectToEvent({userId: user._id, eventId: event._id});
        this.setUserEventRoles();
        this.setUserEventAccess();
        this.calculateNotReadChatMessages();

        this.calculateButtonHeaderDistances();
        window.addEventListener('resize', this.calculateButtonHeaderDistances);

        // get event Archive

        if (buildingId) {
            connectToBuilding({userId: user._id, buildingId});
        }

        if (activeWallId) {
            this.setState({wallId: activeWallId}, () => connectToWall({wallId: activeWallId}));
        }

        this.setLanguagesData();

        iceServersReceived((err, iceServers) => setIceServers(iceServers));
        incomingCallReceived((err, {userId}) => setIncomingCallUserId(userId));
        p2pCallOfferReceived((err, data) => setIncomingCallOffer(data));
        p2pCallCandidateReceived((err, candidate) => setIceCandidate(candidate));
        participantHasLeft((err, data) => clearRemoteUserInfo(data));
    }

    componentDidUpdate(prevProps) {
        const {event, user, buildingId, isRtlLanguage, sideNavigation, activeWallId} = this.props;
        const {wallId} = this.state;

        if (
            prevProps.sideNavigation.wallConfig.isMounted !== sideNavigation.wallConfig.isMounted ||
            prevProps.wallType !== sideNavigation.wallType
        )
            this.subscribeWallConfig();

        if (prevProps.event && prevProps.event._id !== event._id && user) {
            this.setUserEventRoles();
            this.setUserEventAccess();
        }

        if (
            (prevProps.user && prevProps.user.privateChats !== user.privateChats) ||
            prevProps.user.groupChats !== user.groupChats
        ) {
            this.calculateNotReadChatMessages();
        }

        if (prevProps?.event?._id !== event?._id) {
            disconnectFromEvent({userId: user._id, eventId: prevProps.event._id});
            connectToEvent({userId: user._id, eventId: event._id});
        }

        if (prevProps.buildingId !== buildingId) {
            if (prevProps.buildingId) {
                disconnectFromBuilding({userId: user._id, buildingId: prevProps.buildingId});
            }
            if (buildingId) {
                connectToBuilding({userId: user._id, buildingId});
            }
        }

        if (wallId !== activeWallId && activeWallId) {
            wallId && disconnectFromWall({wallId, userId: user._id});

            this.setState({wallId: activeWallId}, () => connectToWall({wallId: activeWallId}));
        }

        if (prevProps.languages.platformLanguage !== this.props.languages.platformLanguage) {
            this.setLanguagesData();
        }

        if (prevProps.isRtlLanguage !== isRtlLanguage) {
            this.calculateButtonHeaderDistances();
        }
    }

    setLanguagesData = () => {
        const translation = this.props.languages.translations[this.props.languages.platformLanguage];
        this.setState({
            translation: translation,
        });
    };

    componentWillUnmount() {
        // remove the class that forced the screen to be full height on event pages
        document.getElementById('app-container').classList.remove('event-page');
        window.removeEventListener('resize', this.calculateButtonHeaderDistances);
        this.setState({wallId: null});
    }

    subscribeWallConfig = () => {
        const {getEventSuccess, changeWallStatus, wallType} = this.props;

        wallClosed((err, {type, event}) => {
            if (type === wallType) {
                changeWallStatus(true);
                getEventSuccess(event);
            }
        });

        wallOpened((err, {type, event}) => {
            if (type === wallType) {
                changeWallStatus(false);
                getEventSuccess(event);
            }
        });
    };

    calculateButtonHeaderDistances = () => {
        this.setState({
            // contactsOffset: document.getElementById('contacts').offsetLeft,
            programOffsetLeft: document.getElementById('program')?.offsetLeft || 0,
            programOffsetWidth: document.getElementById('program')?.offsetWidth || 0,
            notificationsOffset: document.getElementById('notifications').offsetLeft,
            notificationsOffsetWidth: document.getElementById('notifications').offsetWidth,
            myAccountOffsetLeft: document.getElementById('myaccount').offsetLeft,
            myAccountOffsetWidth: document.getElementById('myaccount').offsetWidth,
            agendaOffsetLeft: document.getElementById('agenda')?.offsetLeft || 0,
            agendaOffsetWidth: document.getElementById('agenda')?.offsetWidth || 0,
        });
    };

    setUserEventRoles = () => {
        const {eventId, eventSlug, user} = this.props;
        if (eventId && user) {
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);
            const userIsExhibitor = userRolesInEvent && userRolesInEvent.roles.includes('exhibitor');
            const userIsExhibitorRep = userRolesInEvent && userRolesInEvent.roles.includes('exhibitorRep');
            const userIsParticipant = userRolesInEvent && userRolesInEvent.roles.includes('participant');
            const userIsOrganizer = userRolesInEvent && userRolesInEvent.roles.includes('organizer');
            const userIsOrganizerRep = userRolesInEvent && userRolesInEvent.roles.includes('organizerRep');

            // when you update the eventRoles here, please make sure that you also update
            // in userReducer the initialEventRoles to be exactly the same
            const eventRoles = {
                isOrganizer: userIsOrganizer,
                isOrganizerRep: userIsOrganizerRep,
                isExhibitor: userIsExhibitor,
                exhibitorId: userIsExhibitor ? userRolesInEvent.exhibitor : null,
                isExhibitorRep: userIsExhibitorRep,
                exhibitorRepIds: userIsExhibitorRep ? userRolesInEvent.exhibitorRepresentative : [],
                isParticipant: userIsParticipant,
                participantId: userIsParticipant ? userRolesInEvent.participant : null,
            };
            this.props.onSetUserEventRoles(eventRoles);
        }
    };

    getEventArchive = () => {
        // we don't have access to this.props.eventRoles
        // we don't have access to this.props.resourcesAccess
        // so we recalculate them
        // I prefered this way instead of listening in componentDidMount to all kind of changes

        const {eventId, event, user, eventSlug} = this.props;
        const ability = this.context;
        let hasAccessToEventArchive = true;

        // check if the user is participant
        const archiveEventAccess = ability.can(
            ROLE_ABILITIES_TYPES.PARTICIPANT.GET_INFO,
            ROLE_FEATURES_TYPES.EVENT_ARCHIVE
        );

        // if it is an event with access management, check if the user has access to event archive
        if (event?.hasAccessManagement) {
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);
            const packageId = userRolesInEvent.accessPackage;

            if (packageId) {
                const userPurchasedAccessPackage = event.userAccessPackages.find(
                    (accessPackage) => accessPackage._id === packageId
                );
                const resourcesAccess = userPurchasedAccessPackage.resourcesAccess;
                hasAccessToEventArchive = resourcesAccess.archive;
            }
        }

        if (event?.hasAccessManagement) {
            if (archiveEventAccess && hasAccessToEventArchive) {
                this.props.onGetEventArchive(eventId);
            } else {
                // we don't do anything
                // we don't make the API call to get the event Archive
            }
        } else {
            this.props.onGetEventArchive(eventId);
        }
    };

    setUserEventAccess = () => {
        const {eventSlug, event, user} = this.props;
        let resourcesAccess = null;

        if (event?.hasAccessManagement) {
            const userRolesInEvent = user.eventRoles.find((role) => role.event.slug === eventSlug);

            const packageId = userRolesInEvent.accessPackage;

            if (packageId) {
                const userPurchasedAccessPackage = event.userAccessPackages.find(
                    (accessPackage) => accessPackage._id === packageId
                );
                resourcesAccess = userPurchasedAccessPackage.resourcesAccess;
            }
        }
        this.props.onSetEventAccess(resourcesAccess);
    };

    getNotReadNotifications = () => {
        const filteredNotifications = getSortedNotifications(this.props.user?.notifications, this.props.user?._id);
        return filteredNotifications.filter((notification) => notification.seenAt === null).length;
    };

    calculateNotReadChatMessages = () => {
        let privateChats = [...this.props.user.privateChats];
        let groupChats = [...this.props.user.groupChats];
        let totalMessagesUnread = getNotReadChatMessages(privateChats, groupChats);
        this.props.onSetNotReadChatMessages(totalMessagesUnread);
    };

    isActive = () => {
        this.setState({
            active: true,
        });
    };

    refreshPage = () => {
        window.location.reload(true);
    };

    closeDisconnectedUserMessage = () => this.props.videoConferenceCompanionStatusChange();
    render() {
        const {
            eventSlug,
            eventRoles,
            isLargeScreen,
            isRtlLanguage,
            topNavigation,
            sessionExpired,
            videoConference: {isActive},
            videoConferenceCompanionDisconnected,
            history: {
                location: {pathname},
            },
        } = this.props;
        const {translation} = this.state;
        const ability = this.context;

        let notificationsNotRead = this.getNotReadNotifications();
        let activeHome = this.props.location.pathname.indexOf('lobby') > -1 ? true : false;
        let activeExhibitorDashboard = this.props.location.pathname.indexOf('exhibitor-dashboard') > -1 ? true : false;
        let activeExhibitorRepDashboard =
            this.props.location.pathname.indexOf('exhibitor-booth-view') > -1 ? true : false;
        let activeOrganizerDashboard = this.props.location.pathname.indexOf('/dashboard/') > -1 ? true : false;
        let activeInterpreterSessionList =
            this.props.location.pathname.indexOf('/interpreter-sessions-list') > -1 ? true : false;
        let activeSpeakerSessionList =
            this.props.location.pathname.indexOf('/speaker-sessions-list') > -1 ? true : false;
        let activeModeratorSessionList =
            this.props.location.pathname.indexOf('/moderator-sessions-list') > -1 ? true : false;
        let activeEventArchive = this.props.location.pathname.indexOf('/event-archive') > -1 ? true : false;
        let activeMyAccount = this.props.location.pathname.indexOf('/my-account') > -1 ? true : false;

        if (!isLargeScreen) {
            return (
                <EventMenuMobile
                    refreshPage={this.refreshPage}
                    notificationsNotRead={notificationsNotRead}
                    activeMyAccount={activeMyAccount}
                    activeHome={activeHome}
                    closeDisconnectedUserMessage={this.closeDisconnectedUserMessage}
                />
            );
        }

        const eventRoot = `/event/${eventSlug}/lobby`;
        const eventOwnerId = this.props.event?.owner._id || this.props.event?.owner;
        const exhibitorBoothAccess = ability.can(
            ROLE_ABILITIES_TYPES.EXHIBITOR_REP.NAVIGATE,
            ROLE_FEATURES_TYPES.EXHIBITOR_BOOTH
        );
        const organizerDashboardAccess = ability.can(
            ROLE_ABILITIES_TYPES.COMMON.MANAGE_ABILITY,
            ROLE_FEATURES_TYPES.ORGANIZER_DASHBOARD
        );
        const coOrganizerDashboardAccess = ability.can(
            ROLE_ABILITIES_TYPES.CO_ORGANIZER.MANAGE_ABILITY,
            ROLE_FEATURES_TYPES.ORGANIZER_DASHBOARD_NAVIGATION
        );

        const isInterpreter = ability.can(
            ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY,
            ROLE_FEATURES_TYPES.INTERPRETER_SESSION_LIST_BUTTON
        );

        const isModerator = ability.can(
            ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY,
            ROLE_FEATURES_TYPES.MODERATOR_SESSION_LIST_BUTTON
        );

        const isSpeaker = ability.can(
            ROLE_ABILITIES_TYPES.COMMON.VIEW_ABILITY,
            ROLE_FEATURES_TYPES.SPEAKER_SESSION_LIST_BUTTON
        );

        return (
            <div className="top-container">
                {sessionExpired && (
                    <div className="server-down-info-container">
                        <p>
                            {translation?.eventMenu.sessionProblemTextFirst}
                            <span onClick={this.refreshPage}>{translation?.eventMenu.sessionProblemButton}</span>
                            {translation?.eventMenu.sessionProblemTextSecond}
                        </p>
                    </div>
                )}
                {isActive && videoConferenceCompanionDisconnected && (
                    <div className="server-down-info-container">
                        <p>
                            User was disconnected from the conference.
                            <span onClick={this.closeDisconnectedUserMessage}>Close</span>
                        </p>
                    </div>
                )}
                <header id="header" className="header event-menu-header">
                    <div className="main-menu-container">
                        <div className="actions-container">
                            <div className="left-header-wrapper">
                                <Link
                                    to={{
                                        pathname: `/event/${eventSlug}/lobby`,
                                    }}
                                >
                                    <MainLogo colored />
                                </Link>
                                <div id="my-events" onClick={this.props.seeMyEvents}>
                                    <p>
                                        <span>{this.props.event?.name}</span>
                                        <MenuDropdownIcon className="primary-fill-svg" />
                                    </p>
                                </div>
                            </div>
                            <div className={classNames('main-header-wrapper', {reversed: isRtlLanguage})}>
                                {pathname !== eventRoot && <BackButton />}
                                {isSpeaker && (
                                    <div
                                        className={classNames('event-menu-header__button-holder', {
                                            active: activeSpeakerSessionList,
                                        })}
                                    >
                                        <Link
                                            to={{
                                                pathname: generatePath(RouterConfig.event.speakerSessionsList, {
                                                    eventSlug: eventSlug,
                                                }),
                                            }}
                                        >
                                            <SpeakerMenuIcon
                                                color={
                                                    activeSpeakerSessionList
                                                        ? this.props.theme.palette.primary.main
                                                        : this.props.theme.palette.grey.A400
                                                }
                                            />

                                            <p>{'speaker'}</p>
                                        </Link>
                                    </div>
                                )}
                                {isModerator && (
                                    <div
                                        className={classNames('event-menu-header__button-holder', {
                                            active: activeModeratorSessionList,
                                        })}
                                    >
                                        <Link
                                            to={{
                                                pathname: generatePath(RouterConfig.event.moderatorSessionsList, {
                                                    eventSlug: eventSlug,
                                                }),
                                            }}
                                        >
                                            <ModeratorMenuIcon
                                                color={
                                                    activeModeratorSessionList
                                                        ? this.props.theme.palette.primary.main
                                                        : this.props.theme.palette.grey.A400
                                                }
                                            />

                                            <p>{'moderate'}</p>
                                        </Link>
                                    </div>
                                )}
                                {isInterpreter && (
                                    <div
                                        className={classNames('event-menu-header__button-holder', {
                                            active: activeInterpreterSessionList,
                                        })}
                                    >
                                        <Link
                                            to={{
                                                pathname: generatePath(RouterConfig.event.interpreterSessionsList, {
                                                    eventSlug: eventSlug,
                                                }),
                                            }}
                                        >
                                            <InterpreterMenuIcon
                                                color={
                                                    activeInterpreterSessionList
                                                        ? this.props.theme.palette.primary.main
                                                        : this.props.theme.palette.grey.A400
                                                }
                                            />

                                            <p>{'interpreter'}</p>
                                        </Link>
                                    </div>
                                )}
                                {this.props.event ? (
                                    <>
                                        {coOrganizerDashboardAccess ? (
                                            <div
                                                className={classNames('event-menu-header__button-holder', {
                                                    active: activeOrganizerDashboard,
                                                })}
                                            >
                                                <Link to={{pathname: `/event/${eventSlug}/dashboard`}}>
                                                    {activeOrganizerDashboard ? (
                                                        <DashboardActiveIcon className="primary-fill-svg" />
                                                    ) : (
                                                        <DashboardIcon />
                                                    )}
                                                    <p>{'Co Organizer'}</p>
                                                </Link>
                                            </div>
                                        ) : null}
                                    </>
                                ) : null}
                                {this.props.event ? (
                                    <>
                                        {organizerDashboardAccess && this.props.user._id === eventOwnerId ? (
                                            <div
                                                className={classNames('event-menu-header__button-holder', {
                                                    active: activeOrganizerDashboard,
                                                })}
                                            >
                                                <Link to={{pathname: `/event/${eventSlug}/dashboard`}}>
                                                    {activeOrganizerDashboard ? (
                                                        <DashboardActiveIcon className="primary-fill-svg" />
                                                    ) : (
                                                        <DashboardIcon />
                                                    )}
                                                    <p>{translation?.eventMenu.organizerButton}</p>
                                                </Link>
                                            </div>
                                        ) : null}
                                    </>
                                ) : null}

                                <Can
                                    do={ROLE_ABILITIES_TYPES.EXHIBITOR.NAVIGATE}
                                    on={ROLE_FEATURES_TYPES.EXHIBITOR_DASHBOARD}
                                    ability={ability}
                                >
                                    <div
                                        className={classNames(
                                            'exhibitor-dashboard-button',
                                            'event-menu-header__button-holder',
                                            {
                                                active: activeExhibitorDashboard,
                                            }
                                        )}
                                    >
                                        <Link to={{pathname: `/event/${eventSlug}/exhibitor-dashboard`}}>
                                            {activeExhibitorDashboard ? (
                                                <DashboardActiveIcon className="primary-fill-svg" />
                                            ) : (
                                                <DashboardIcon />
                                            )}
                                            <p>{translation?.eventMenu.exhibitorButton}</p>
                                        </Link>
                                    </div>
                                </Can>
                                {exhibitorBoothAccess &&
                                eventRoles.exhibitorRepIds &&
                                eventRoles.exhibitorRepIds.length === 1 ? (
                                    <div
                                        className={classNames(
                                            'exhibitor-dashboard-button',
                                            'event-menu-header__button-holder',
                                            {
                                                active: activeExhibitorRepDashboard,
                                            }
                                        )}
                                    >
                                        <Link
                                            to={{
                                                pathname: `/event/${eventSlug}/exhibitor-booth-view/${eventRoles.exhibitorRepIds[0]}`,
                                            }}
                                        >
                                            {activeExhibitorRepDashboard ? (
                                                <DashboardActiveIcon className="primary-fill-svg" />
                                            ) : (
                                                <DashboardIcon />
                                            )}
                                            <p>{translation?.eventMenu.boothButton}</p>
                                        </Link>
                                    </div>
                                ) : null}
                                {exhibitorBoothAccess &&
                                eventRoles.exhibitorRepIds &&
                                eventRoles.exhibitorRepIds.length > 1 ? (
                                    <div
                                        className={classNames(
                                            'exhibitor-dashboard-button',
                                            'event-menu-header__button-holder',
                                            {
                                                active: activeExhibitorRepDashboard || topNavigation.seeMyBooths,
                                            }
                                        )}
                                        id="my-booths"
                                        onClick={this.props.seeMyBooths}
                                    >
                                        {activeExhibitorRepDashboard || topNavigation.seeMyBooths ? (
                                            <DashboardActiveIcon className="primary-fill-svg" />
                                        ) : (
                                            <DashboardIcon />
                                        )}

                                        <p>{translation?.eventMenu.boothsButton}</p>
                                    </div>
                                ) : null}

                                <div
                                    className={classNames('home-button', 'event-menu-header__button-holder', {
                                        active: activeHome,
                                    })}
                                >
                                    <Link to={{pathname: `/event/${eventSlug}/lobby`}}>
                                        {activeHome ? <HomeActiveIcon className="primary-fill-svg" /> : <HomeIcon />}
                                        <p>{translation?.eventMenu.homeButton}</p>
                                    </Link>
                                </div>
                                <div
                                    id="wallet"
                                    onClick={() => {
                                        this.props.openWallet();
                                    }}
                                    className={classNames('event-menu-header__button-holder', {
                                        active: topNavigation.walletConfig.visible,
                                    })}
                                >
                                    <WalletIcon />
                                    <p>{translation?.businessWallet.short}</p>
                                </div>

                                <div
                                    id="chats"
                                    onClick={this.props.seeChats}
                                    className={classNames('event-menu-header__button-holder', {
                                        active: topNavigation.seeChats,
                                    })}
                                >
                                    {topNavigation.seeChats ? (
                                        <ChatsActiveIcon className="primary-fill-svg" />
                                    ) : (
                                        <ChatsIcon />
                                    )}

                                    <p>{translation?.eventMenu.chatsButton}</p>
                                    {this.props.notReadChatMessages && (
                                        <div className="unread-messages">
                                            <span>{this.props.notReadChatMessages}</span>
                                        </div>
                                    )}
                                </div>

                                <div
                                    id="agenda"
                                    onClick={this.props.seeAgenda}
                                    className={classNames('event-menu-header__button-holder', {
                                        active: topNavigation.agendaConfig.visible,
                                    })}
                                >
                                    <IconContainer>
                                        <IconAgenda />
                                    </IconContainer>

                                    <p>{translation?.personalAgenda.agenda}</p>
                                </div>

                                <div
                                    id="program"
                                    onClick={this.props.seeProgram}
                                    className={classNames('event-menu-header__button-holder', {
                                        active: topNavigation.seeProgram,
                                    })}
                                >
                                    <ProgramIcon />
                                    <p>{translation?.eventMenu.programButton}</p>
                                </div>
                                {this.props.event.archiveEventCount && (
                                    <div
                                        id="event-archive"
                                        className={classNames('event-menu-header__button-holder', {
                                            active: activeEventArchive,
                                        })}
                                    >
                                        <Link to={{pathname: `/event/${eventSlug}/event-archive`}}>
                                            <div>
                                                <IconContainer>
                                                    {activeEventArchive ? (
                                                        <ArchiveIconActive className="primary-fill-svg" />
                                                    ) : (
                                                        <ArchiveIcon />
                                                    )}
                                                </IconContainer>
                                                <p>{translation?.eventMenu.archiveButton}</p>
                                            </div>
                                        </Link>
                                    </div>
                                )}
                            </div>
                            <div className="right-header-wrapper">
                                <div id="notifications" onClick={this.props.seeNotifications}>
                                    {topNavigation.seeNotifications ? (
                                        <NotificationsActiveIcon className="primary-fill-svg" />
                                    ) : (
                                        <NotificationsIcon />
                                    )}

                                    {notificationsNotRead && (
                                        <div>
                                            <span>{notificationsNotRead}</span>
                                        </div>
                                    )}
                                </div>

                                <div className={classNames('social-media-share-event', {isRtlLanguage})}>
                                    <SocialMediaShareEvent />
                                </div>

                                <div
                                    id="myaccount"
                                    onClick={this.props.seeMyAccount}
                                    className={classNames('event-menu-header__button-holder', {
                                        active: topNavigation.seeMyAccount || activeMyAccount,
                                    })}
                                >
                                    {topNavigation.seeMyAccount || activeMyAccount ? (
                                        <AccountActiveIcon className="primary-fill-svg" />
                                    ) : (
                                        <AccountIcon />
                                    )}
                                    <p>{translation?.eventMenu.myAccountButton}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>

                {topNavigation.seeProgram && (
                    <DetectOutsideClick>
                        <Program
                            offsetLeft={this.state.programOffsetLeft}
                            offsetWidth={this.state.programOffsetWidth}
                        />
                    </DetectOutsideClick>
                )}
                {topNavigation.walletConfig.visible && (
                    <DetectOutsideClick>
                        <BusinessWalletPopup />
                    </DetectOutsideClick>
                )}
                {topNavigation.seeChats && (
                    <Chats offset={this.state.chatsOffset} offsetWidth={this.state.chatsOffsetWidth} />
                )}
                {topNavigation.agendaConfig.visible && (
                    <DetectOutsideClick>
                        <AgendaPopup
                            offsetLeft={this.state.agendaOffsetLeft}
                            offsetWidth={this.state.agendaOffsetWidth}
                            activeTab={topNavigation.agendaConfig.activeTab}
                        />
                    </DetectOutsideClick>
                )}
                {topNavigation.seeNotifications && (
                    <DetectOutsideClick>
                        <Notifications
                            displayContacts={this.handleSeeContacts}
                            offset={this.state.notificationsOffset}
                            offsetWidth={this.state.notificationsOffsetWidth}
                        />
                    </DetectOutsideClick>
                )}
                {topNavigation.seeMyEvents && (
                    <DetectOutsideClick>
                        <MyEvents />
                    </DetectOutsideClick>
                )}
                {topNavigation.seeMyBooths && (
                    <DetectOutsideClick>
                        <MyBooths />
                    </DetectOutsideClick>
                )}
                {topNavigation.seeMyAccount && (
                    <DetectOutsideClick>
                        <MyAccount
                            eventId={this.props.eventId}
                            offsetLeft={this.state.myAccountOffsetLeft}
                            offsetWidth={this.state.myAccountOffsetWidth}
                        />
                    </DetectOutsideClick>
                )}
            </div>
        );
    }
}

EventMenu.contextType = AbilityContext;

export default withTheme(EventMenu);
