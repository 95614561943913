import React from 'react';
import {useSelector} from 'react-redux';

import CloseTopNavButton from '../CloseTopNavButton/CloseTopNavButton';
import BusinessWallet from './components/BusinessWallet';
import BusinessCardContainer from './components/BusinessCard/BusinessCardContainer';

import {usePlatformTranslation} from '../../../../../../../services/hooks';
import './BusinessWalletPopup.scss';

const BusinessWalletPopup = () => {
    const {walletTab, walletViewType} = useSelector((state) => state.user.topNavigation.walletConfig);
    const {businessWallet} = usePlatformTranslation();

    return (
        <div className="wallet-popup">
            <CloseTopNavButton />
            <div className="page-title">
                <h3 className="page-title">{businessWallet.full}</h3>
            </div>
            <div>
                {walletViewType === 'wallet' ? <BusinessWallet activeTab={walletTab} /> : <BusinessCardContainer />}
            </div>
        </div>
    );
};

export default BusinessWalletPopup;
