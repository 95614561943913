import React, {useEffect, useState, useCallback} from 'react';
import {isMobile} from 'react-device-detect';
import {IconButton, TextField, InputAdornment, Accordion, AccordionSummary, AccordionDetails} from '@material-ui/core';
import {FilterList as FilterListIcon} from '@material-ui/icons';
import {useSelector} from 'react-redux';
import classNames from 'classnames';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {mergeCheckedFilters, getFilterTranslation, mergeFilters} from 'services/helpers';
import _ from 'lodash';
import {useStyles} from './Filter.styles';
import ActionButtons from './components/ActionButtons';
import {PrimaryGreyCheckbox} from '../CustomCheckbox/CustomCheckbox';
import {usePlatformTranslation} from 'services/hooks';
import InfoTooltip from '../InfoTooltip/InfoTooltip';
import {getIsRtlLanguage, getPlatformLanguage} from 'store/selectors';

// const COUNTRY_FILTER_ID = 'country-filter';

const Filter = (props) => {
    const {
        searchInputText = 'Search',
        setSearch,
        usingFilters,
        applyFilter,
        isOpened,
        setIsOpened,
        filterFields,
        className,
        tooltipText,
    } = props;
    const translation = usePlatformTranslation();
    const isRtlLanguage = useSelector(getIsRtlLanguage);
    const platformLanguage = useSelector(getPlatformLanguage);

    const classes = useStyles();
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        return () => {
            applyFilter([]);
            setSearch('');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addFilters = useCallback(() => {
        if (!filterFields) return;

        if (!_.isEmpty(usingFilters)) return setFilters(usingFilters);

        setFilters(filterFields);
        // setCountryFilter();
    }, [filterFields, usingFilters]);

    useEffect(() => {
        addFilters();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [addFilters]);

    const handleCheck = (filterId, optionId, newCheckedVal) => {
        const updatedFilters = [...filters];

        updatedFilters.forEach((filter) => {
            if (filter._id !== filterId) return;

            filter.options.forEach((option) => {
                if (option._id !== optionId) return;

                option.isChecked = newCheckedVal;
            });
        });

        setFilters(updatedFilters);
    };

    const handleCheckAll = (filterId, newCheckedVal) => {
        const updatedFilters = [...filters];

        updatedFilters.forEach((filter) => {
            if (filter._id !== filterId) return;

            filter.options.forEach((option) => {
                option.isChecked = newCheckedVal;
            });
        });

        setFilters(updatedFilters);
    };

    const checkIfAtLeastOneSelected = () => {
        return filters.some((filter) => Object.values(filter.options).some((v) => v));
    };

    const handleUncheckAll = () => {
        const updatedFilters = [...filters];

        updatedFilters.forEach((filter) => {
            filter.options.forEach((option) => {
                option.isChecked = false;
            });
        });

        setFilters(updatedFilters);
    };

    const isFilterChanged = () => {
        if (_.isEmpty(usingFilters) && !checkIfAtLeastOneSelected()) {
            return false;
        }
        return filters !== usingFilters;
    };

    const handleApply = () => {
        const mergedFilters = mergeCheckedFilters(filters);

        if (mergedFilters.length < 1) {
            applyFilter([]);
            return setIsOpened(false);
        }

        applyFilter(filters);
        setIsOpened(false);
    };

    const handleDiscard = () => {
        handleUncheckAll();

        applyFilter([]);
        setIsOpened(false);
    };

    const onSearchChange = _.debounce(setSearch, 500);

    const isDiscardDisabled = !checkIfAtLeastOneSelected();
    const isApplyDisabled = !isFilterChanged();

    return (
        <div className={classNames(classes.root, className, {isRtl: isRtlLanguage})}>
            <TextField
                className={classNames(classes.searchInput, 'field-container')}
                label={isOpened ? `${translation?.showfloor.filterBy}:` : searchInputText}
                disabled={isOpened}
                variant="filled"
                type="search"
                name="search-filter"
                fullWidth
                onChange={(e) => onSearchChange(e.target.value)}
                InputProps={{
                    style: {borderRadius: 0, paddingRigth: 0},
                    classes: {
                        root: classes.inputContainer,
                    },
                    endAdornment: (
                        <InputAdornment position="end">
                            {isOpened && tooltipText && <InfoTooltip withInfoIcon text={tooltipText} />}
                            {isOpened && (
                                <IconButton onClick={handleUncheckAll}>
                                    <PrimaryGreyCheckbox indeterminate checked={false} />
                                    <span className={classes.clearAll}>{`${translation?.filter?.clearAll}`}</span>
                                </IconButton>
                            )}
                            {!!Object.keys(filters).length && (
                                <IconButton onClick={() => setIsOpened(!isOpened)} data-testid="show-filter-btn">
                                    <FilterListIcon className={isOpened ? classes.dropdownOpen : undefined} />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
            {isOpened && (
                <div
                    className={classNames(classes.filtersList, 'filters-list', {
                        isRtl: isRtlLanguage,
                        isDesktop: !isMobile,
                    })}
                >
                    {filters.map((filter) => {
                        const selectedCount = mergeCheckedFilters(filters, filter._id).length;
                        const totalFieldsCount = mergeFilters(filters, filter._id).length;
                        const filterLabel = getFilterTranslation(filter, platformLanguage) || filter.label;

                        return (
                            <Accordion
                                classes={{expanded: classes.accordionExpanded}}
                                className={classes.accordion}
                                key={filter._id}
                                square
                            >
                                <AccordionSummary
                                    classes={{
                                        content: classes.summeryContent,
                                        expanded: classes.filterLabelActive,
                                        expandIcon: classNames(classes.ExpendIcon, {
                                            isRtl: isRtlLanguage,
                                        }),
                                    }}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <p className={classes.filterLabel}>{filterLabel.toUpperCase()}</p>
                                </AccordionSummary>
                                <AccordionDetails className={classes.accordionDetails}>
                                    <div
                                        className={classes.selectItem}
                                        onClick={() =>
                                            handleCheckAll(
                                                filter._id,
                                                !selectedCount || selectedCount !== totalFieldsCount
                                            )
                                        }
                                    >
                                        <PrimaryGreyCheckbox
                                            className={classes.checkbox}
                                            indeterminate={selectedCount === totalFieldsCount}
                                            checked={false}
                                        />
                                        {selectedCount === totalFieldsCount ? (
                                            <span>{`${translation?.filter?.deselectAll}`}</span>
                                        ) : (
                                            <span>{`${translation?.filter?.selectAll}`}</span>
                                        )}
                                    </div>

                                    {filter.options.map((option) => {
                                        const optionVal =
                                            getFilterTranslation(option, platformLanguage) || option.value;

                                        return (
                                            <div
                                                className={classes.selectItem}
                                                key={option._id}
                                                onClick={() => handleCheck(filter._id, option._id, !option.isChecked)}
                                            >
                                                <PrimaryGreyCheckbox
                                                    className={classes.checkbox}
                                                    checked={option.isChecked}
                                                />
                                                {optionVal}
                                            </div>
                                        );
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        );
                    })}
                    <ActionButtons
                        isDiscardDisabled={isDiscardDisabled}
                        handleDiscard={handleDiscard}
                        isApplyDisabled={isApplyDisabled}
                        handleApply={handleApply}
                    />
                </div>
            )}
        </div>
    );
};

export default Filter;
