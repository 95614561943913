import {connect} from 'react-redux';

import * as actions from 'store/actions';
import * as selectors from 'store/selectors';
import {RegisterAsExhibitor} from './RegisterAsExhibitor';

export default connect(
    (state) => ({
        eventId: selectors.getEventId(state),
        eventSlug: selectors.getEventSlug(state),
        loadingEvent: selectors.getEventLoading(state),
        eventInfo: selectors.getEventInfo(state),
        loggedIn: selectors.getIsUserLoggedIn(state),
        eventData: selectors.getEventData(state),
        loadingUser: selectors.getUserLoading(state),
        user: selectors.getUserData(state),
        paymentLink: selectors.getUserPaymentLink(state),
        isInvitationCodeValid: selectors.getInvitationCodeValid(state),
        loadingInvitationCode: selectors.getInvitationsLoading(state),
        preselectedAccessPackageId: selectors.getUserPreselectedAccessPackageId(state),
        languages: selectors.getLanguages(state),
        eventLanguage: selectors.getEventLanguage(state),
        userRegistration: selectors.getEventUserRegistration(state),
        eventRegPackage: selectors.getEventRegPackage(state),
    }),
    {
        onRegisterExhibitor: actions.registerExhibitor,
        onCheckInvitationCode: actions.checkInvitationCode,
        onCreatePaymentIntent: actions.createPaymentIntent,
        refreshUserData: actions.autoLoginWithToken,
        refreshEventData: actions.getEvent,
        onSetEventPublicMenu: actions.setEventPublicMenu,
    }
)(RegisterAsExhibitor);
