import React, {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import VideoAvatar from '../../../VideoAvatar';

import {endOneToOneCall} from 'store/actions';
import {getCompanionStream} from 'store/selectors';

import {useStyles} from './ParticipantVideo.styles';

import {usePeerConnection} from 'services/hooks';

export const ParticipantVideo = ({participant, isParticipantStreamActive, callEnded, isFullScreen}) => {
    const dispatch = useDispatch();
    const companionStream = useSelector(getCompanionStream);
    const classes = useStyles();
    const videoRef = useRef();

    const {isCameraOff} = usePeerConnection(videoRef);

    useEffect(() => {
        if (videoRef.current && companionStream) videoRef.current.srcObject = companionStream;

        if (callEnded) dispatch(endOneToOneCall());
    }, [callEnded, companionStream, dispatch]);

    const handleCanPlay = () => videoRef.current.play();

    const isVerticalVideo = videoRef.current && videoRef.current?.videoWidth < videoRef.current?.videoHeight;

    return (
        <div className={classNames(classes.container, {[classes.hidden]: !isParticipantStreamActive})}>
            <div
                className={classNames(classes.videoWrapper, {
                    [classes.hidden]: !isParticipantStreamActive,
                    isFullScreen,
                })}
            >
                <video
                    className={classNames(classes.video, isCameraOff && classes.videoOff, {isVerticalVideo})}
                    ref={videoRef}
                    autoPlay
                    playsInline
                    onCanPlay={handleCanPlay}
                />
                {isCameraOff && (
                    <div className={classes.videoOffPlate}>
                        <VideoAvatar text={'name'} member={participant} />
                    </div>
                )}
            </div>
        </div>
    );
};

ParticipantVideo.propTypes = {
    participant: PropTypes.object.isRequired,
    isParticipantStreamActive: PropTypes.bool.isRequired,
    isFullScreen: PropTypes.bool.isRequired,
    callEnded: PropTypes.bool.isRequired,
};
