import _ from 'lodash';
import * as actionTypes from '../../../actionTypes/organizerActionTypes/statistics';
import {initialState} from '../initialState';
import {EXHIBITORS_STATS_TYPES} from '../../../../constants/organizer/eventStatistics';

const statistics = (state, {type, payload}) => {
    if (state === undefined) {
        return initialState.eventStatistics;
    }
    switch (type) {
        case actionTypes.GET_OVERALL_REPORT_START: {
            return {...state.eventStatistics, overall: {...state.eventStatistics.overall, loadingStatistics: true}};
        }
        case actionTypes.GET_OVERALL_REPORT_SUCCESS: {
            return {
                ...state.eventStatistics,
                overall: {...state.eventStatistics.overall, ...payload, loadingStatistics: false},
            };
        }
        case actionTypes.GET_OVERALL_REPORT_FAIL: {
            return {...state.eventStatistics, overall: {...state.eventStatistics.overall, loadingStatistics: false}};
        }
        case actionTypes.GET_WALLS_REPORT_START: {
            return {...state.eventStatistics, walls: {...state.eventStatistics.walls, loadingStatistics: true}};
        }
        case actionTypes.GET_WALLS_REPORT_SUCCESS: {
            return {
                ...state.eventStatistics,
                walls: {...state.eventStatistics.walls, ...payload, loadingStatistics: false},
            };
        }
        case actionTypes.GET_WALLS_REPORT_FAIL: {
            return {...state.eventStatistics, walls: {...state.eventStatistics.walls, loadingStatistics: false}};
        }
        case actionTypes.GET_AUDITORIUMS_REPORT_START: {
            return {
                ...state.eventStatistics,
                auditoriums: {...state.eventStatistics.auditoriums, loadingStatistics: true},
            };
        }
        case actionTypes.GET_AUDITORIUMS_REPORT_SUCCESS: {
            const {auditoriumTimeslots, usersLists, individualTypes, pagination} = payload;

            return {
                ...state.eventStatistics,
                auditoriums: {
                    ...state.eventStatistics.auditoriums,
                    auditoriumTimeslots: [
                        ...state.eventStatistics.auditoriums.auditoriumTimeslots,
                        ...auditoriumTimeslots,
                    ],
                    usersLists: [...state.eventStatistics.auditoriums.usersLists, ...usersLists],
                    individualTypes: [...state.eventStatistics.auditoriums.individualTypes, ...individualTypes],
                    loadingStatistics: false,
                    timeslotsPagination: pagination,
                },
            };
        }
        case actionTypes.GET_AUDITORIUMS_REPORT_FAIL: {
            return {
                ...state.eventStatistics,
                auditoriums: {...state.eventStatistics.auditoriums, loadingStatistics: false},
            };
        }
        case actionTypes.GET_POLLS_STATISTICS_START: {
            return {
                ...state.eventStatistics,
                polls: {...state.eventStatistics.polls, loadingStatistics: true},
            };
        }
        case actionTypes.GET_POLLS_STATISTICS_SUCCESS: {
            return {
                ...state.eventStatistics,
                polls: {...state.eventStatistics.polls, ...payload, loadingStatistics: false},
            };
        }
        case actionTypes.GET_POLLS_STATISTICS_FAIL: {
            return {
                ...state.eventStatistics,
                polls: {...state.eventStatistics.polls, loadingStatistics: false},
            };
        }
        case actionTypes.GHANGE_POLL_TIMESLOT_CURRENT_STAT_TYPE: {
            const {type, id} = payload;
            const updatedTypes = state.eventStatistics.polls.individualTypes.map((item) => {
                if (item._id === id)
                    return {
                        ...item,
                        currentType: type,
                    };

                return item;
            });

            return {
                ...state.eventStatistics,
                polls: {...state.eventStatistics.polls, individualTypes: updatedTypes},
            };
        }
        case actionTypes.GHANGE_TIMESLOT_CURRENT_STAT_TYPE: {
            const {type, id} = payload;
            const updatedTypes = state.eventStatistics.auditoriums.individualTypes.map((item) => {
                if (item._id === id)
                    return {
                        ...item,
                        currentType: type,
                    };

                return item;
            });

            return {
                ...state.eventStatistics,
                auditoriums: {...state.eventStatistics.auditoriums, individualTypes: updatedTypes},
            };
        }
        case actionTypes.CLEAR_AUDITORIUM_TIMESLOTS: {
            return {
                ...state.eventStatistics,
                auditoriums: initialState.eventStatistics.auditoriums,
            };
        }
        case actionTypes.GET_EXHIBITORS_REPORT_START: {
            return {
                ...state.eventStatistics,
                exhibitors: {...state.eventStatistics.exhibitors, loadingStatistics: true},
            };
        }
        case actionTypes.GET_EXHIBITORS_REPORT_SUCCESS: {
            const {data, page, pages} = payload;

            const usersLists =
                data.length &&
                data.map(
                    ({
                        _id,
                        linkClicksUsers,
                        documentClicksUsers,
                        representativeChatClicksUsers,
                        videoViewsUsers,
                        visitorUsers,
                    }) => ({
                        _id,
                        [EXHIBITORS_STATS_TYPES.attendeesNowUsersCount]: [],
                        [EXHIBITORS_STATS_TYPES.linkClicksCount]: linkClicksUsers,
                        [EXHIBITORS_STATS_TYPES.documentClicksCount]: documentClicksUsers,
                        [EXHIBITORS_STATS_TYPES.representativeChatClicksCount]: representativeChatClicksUsers,
                        [EXHIBITORS_STATS_TYPES.videoViewsCount]: videoViewsUsers,
                        [EXHIBITORS_STATS_TYPES.visitorsCount]: visitorUsers,
                    })
                );

            const getIndividualTypes =
                data.length &&
                data.map(({_id}) => ({
                    _id,
                    currentType: EXHIBITORS_STATS_TYPES.attendeesNowUsersCount,
                }));

            return {
                ...state.eventStatistics,
                exhibitors: {
                    ...state.eventStatistics.exhibitors,
                    data: +page === 1 ? data : _.uniqBy([...state.eventStatistics.exhibitors.data, ...data], '_id'),
                    page: +page,
                    pages: +pages,
                    usersLists:
                        +page === 1
                            ? usersLists
                            : _.uniqBy([...state.eventStatistics.exhibitors.usersLists, ...usersLists], '_id'),
                    individualTypes:
                        +page === 1
                            ? getIndividualTypes
                            : _.uniqBy(
                                  [...state.eventStatistics.exhibitors.individualTypes, ...getIndividualTypes],
                                  '_id'
                              ),
                    loadingStatistics: false,
                },
            };
        }
        case actionTypes.GET_EXHIBITOR_ATTENDEES: {
            const {attendeesNow} = payload;

            const getBoothOnlineUsers = (id) => (attendeesNow.exhibitorId === id ? attendeesNow.boothOnlineUsers : []);

            const updatedData = state.eventStatistics.exhibitors.data.map((item) => {
                const getUpdatedStatistics = item.statistics.map((el) => {
                    if (
                        el.name === EXHIBITORS_STATS_TYPES.attendeesNowUsersCount &&
                        item._id === attendeesNow.exhibitorId
                    )
                        el.value = getBoothOnlineUsers(item._id).length;

                    return el;
                });

                return {
                    ...item,
                    statistics: getUpdatedStatistics,
                };
            });

            const updatedUsersLists = state.eventStatistics.exhibitors.usersLists.map((user) => {
                if (user._id === attendeesNow.exhibitorId)
                    return {
                        ...user,
                        [EXHIBITORS_STATS_TYPES.attendeesNowUsersCount]: getBoothOnlineUsers(user._id),
                    };

                return user;
            });

            return {
                ...state.eventStatistics,
                exhibitors: {
                    ...state.eventStatistics.exhibitors,
                    usersLists: updatedUsersLists,
                    data: updatedData,
                },
            };
        }
        case actionTypes.GET_EXHIBITORS_REPORT_FAIL: {
            return {
                ...state.eventStatistics,
                exhibitors: {...state.eventStatistics.exhibitors, loadingStatistics: false},
            };
        }
        case actionTypes.GHANGE_EXHIBITOR_CURRENT_STAT_TYPE: {
            const {type, id} = payload;

            const updatedTypes = state.eventStatistics.exhibitors.individualTypes.map((item) => {
                if (item._id === id)
                    return {
                        ...item,
                        currentType: type,
                    };

                return item;
            });

            return {
                ...state.eventStatistics,
                exhibitors: {...state.eventStatistics.exhibitors, individualTypes: updatedTypes},
            };
        }
        case actionTypes.CHANGE_EXHIBITOR_PAGINATION_PAGE: {
            const {page} = payload;

            return {
                ...state.eventStatistics,
                exhibitors: {
                    ...state.eventStatistics.exhibitors,
                    page,
                },
            };
        }
        case actionTypes.GET_NETWORKING_REPORT_START: {
            return {
                ...state.eventStatistics,
                networking: {...state.eventStatistics.networking, loadingStatistics: true},
            };
        }
        case actionTypes.GET_NETWORKING_REPORT_SUCCESS: {
            const {statistics, topPostsUsers} = payload;

            return {
                ...state.eventStatistics,
                networking: {...state.eventStatistics.networking, statistics, topPostsUsers, loadingStatistics: false},
            };
        }
        case actionTypes.GET_NETWORKING_REPORT_FAIL: {
            return {
                ...state.eventStatistics,
                networking: {...state.eventStatistics.networking, loadingStatistics: false},
            };
        }
        default:
            return state.eventStatistics;
    }
};

export default statistics;
