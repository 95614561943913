import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    disabledBtn: {
        '& svg': {
            fill: theme.palette.disabled.main,
        },
    },
    row: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    filterSection: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            width: '100%',
        },
    },
    switch: {
        marginRight: 20,
        color: theme.palette.text.primary,
        fontWeight: 'bold',
        display: 'flex',
        alignItems: 'center',
        '&.isRtl': {
            marginRight: 0,
            marginLeft: 20,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
}));
