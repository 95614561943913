import * as actionTypes from '../actionTypes/appliedJobsActionTypes';
import {Api} from 'Api';

export const getAppliedJobs = (userId, eventId) => async (dispatch, getState) => {
    const {
        appliedJobs: {loading},
    } = getState();

    dispatch(getAppliedJobsStart());

    try {
        const {jobs} = await Api.request({
            url: `/users/${userId}/jobs/${eventId}`,
            method: 'get',
            loading,
        });

        dispatch(getAppliedJobsSuccess(jobs));
    } catch (error) {
        dispatch(getAppliedJobsFail(error));
    }
};

export const getAppliedJobsStart = () => ({
    type: actionTypes.GET_JOBS_START,
});

export const getAppliedJobsSuccess = (event) => ({
    type: actionTypes.GET_JOBS_SUCCESS,
    payload: event,
});

export const getAppliedJobsFail = () => ({
    type: actionTypes.GET_JOBS_FAIL,
});
