import {AppBar, Box, makeStyles, Toolbar, Typography} from '@material-ui/core';
import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Button from '../../../../../../../../../Button/Button';
import {IconCross} from '../../../../../../../../../Icons';
import {CloudDownload as CloudDownloadIcon} from '@material-ui/icons';
import BusinessCard from './BusinessCard';
import {closeChatBusinessCard} from 'store/actions';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
    },
    header: {
        background: theme.palette.primary.main,
        position: 'static',
        display: 'flex',
        justifyContent: 'space-between',
    },
    title: {
        fontWeight: '600',
        textTransform: 'uppercase',
        color: theme.palette.grey['0'],
        display: 'inline-block',
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    downoalIcon: {
        color: '#fff',
    },
    btnContainer: {
        minWidth: '70px',
        justifyContent: 'space-between',
        display: 'flex',
    },
}));

const ChatBusinessCard = ({cardId}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {isMobile} = useSelector((state) => state.layout);
    const [card, setChatCard] = useState(null);
    const onOpenBusinessCard = () => {
        dispatch(closeChatBusinessCard());
    };

    return (
        <Box className={classes.root} display="flex" flexDirection="column">
            <AppBar className={classes.header}>
                <Toolbar className={classes.toolbar}>
                    <Typography className={classes.title}>Business Card</Typography>
                    <Box className={classes.btnContainer}>
                        {isMobile && (
                            <a
                                type="button"
                                className={classes.downoalIcon}
                                download={true}
                                target={'_blank'}
                                href={`${process.env.REACT_APP_REQUESTS_API}/business-cards/${card?._id}/download`}
                                rel="noopener noreferrer"
                            >
                                <CloudDownloadIcon />
                            </a>
                        )}
                        <Button title={!isMobile && 'CLOSE'} icon={<IconCross />} onClick={onOpenBusinessCard} />
                    </Box>
                </Toolbar>
            </AppBar>
            <BusinessCard setChatCard={setChatCard} cardId={cardId} withHeader={false} />
        </Box>
    );
};

export default ChatBusinessCard;
