export const SAVE_EVENT_BRANDING_REQUEST = 'ORGANIZER_EVENT_INFO/SAVE_EVENT_BRANDING_REQUEST';
export const SAVE_EVENT_BRANDING_SUCCESS = 'ORGANIZER_EVENT_INFO/SAVE_EVENT_BRANDING_SUCCESS';
export const SAVE_EVENT_BRANDING_FAIL = 'ORGANIZER_EVENT_INFO/SAVE_EVENT_BRANDING_FAIL';

export const EDIT_BRANDING_TRANSLATION_REQUEST = 'ORGANIZER_EVENT_INFO/EDIT_BRANDING_TRANSLATION_REQUEST';
export const EDIT_BRANDING_TRANSLATION_SUCCESS = 'ORGANIZER_EVENT_INFO/EDIT_BRANDING_TRANSLATION_SUCCESS';
export const EDIT_BRANDING_TRANSLATION_FAIL = 'ORGANIZER_EVENT_INFO/EDIT_BRANDING_TRANSLATION_FAIL';

export const EDIT_BRANDING_IMAGES_REQUEST = 'EDIT_BRANDING_IMAGES_REQUEST';
export const EDIT_BRANDING_IMAGES_SUCCESS = 'EDIT_BRANDING_IMAGES_SUCCESS';
export const EDIT_BRANDING_IMAGES_FAIL = 'EDIT_BRANDING_IMAGES_FAIL';

export const REMOVE_BRANDING_IMAGES_REQUEST = 'REMOVE_BRANDING_IMAGES_REQUEST';
export const REMOVE_BRANDING_IMAGES_SUCCESS = 'REMOVE_BRANDING_IMAGES_SUCCESS';
export const REMOVE_BRANDING_IMAGES_FAIL = 'REMOVE_BRANDING_IMAGES_FAIL';

// Survey
export const GET_SURVEY_QUESTIONS_REQUEST = 'ORGANIZER_EVENT_INFO/GET_SURVEY_QUESTIONS_REQUEST';
export const GET_SURVEY_QUESTIONS_SUCCESS = 'ORGANIZER_EVENT_INFO/GET_SURVEY_QUESTIONS_SUCCESS';
export const GET_SURVEY_QUESTIONS_FAIL = 'ORGANIZER_EVENT_INFO/GET_SURVEY_QUESTIONS_FAIL';

export const CREATE_SURVEY_QUESTION_FORM = 'ORGANIZER_EVENT_INFO/CREATE_SURVEY_QUESTION_FORM';
export const DELETE_SURVEY_QUESTION_FORM = 'ORGANIZER_EVENT_INFO/DELETE_SURVEY_QUESTION_FORM';

export const CHANGE_SURVEY_QUESTION = 'ORGANIZER_EVENT_INFO/CHANGE_SURVEY_QUESTION';
export const UPDATE_SURVEY_QUESTION_STATUS_REQUEST = 'ORGANIZER_EVENT_INFO/UPDATE_SURVEY_QUESTION_STATUS_REQUEST';
export const UPDATE_SURVEY_QUESTION_STATUS_SUCCESS = 'ORGANIZER_EVENT_INFO/UPDATE_SURVEY_QUESTION_STATUS_SUCCESS';
export const UPDATE_SURVEY_QUESTION_STATUS_FAIL = 'ORGANIZER_EVENT_INFO/UPDATE_SURVEY_QUESTION_STATUS_FAIL';

export const REMOVE_SURVEY_QUESTION_OPTION = 'ORGANIZER_EVENT_INFO/REMOVE_SURVEY_QUESTION_OPTION';
export const ADD_SURVEY_QUESTION_OPTION = 'ORGANIZER_EVENT_INFO/ADD_SURVEY_QUESTION_OPTION';
export const CHANGE_SURVEY_QUESTION_OPTION = 'ORGANIZER_EVENT_INFO/CHANGE_SURVEY_QUESTION_OPTION';

export const POST_THANK_YOU_MESSAGE_REQUEST = 'ORGANIZER_EVENT_INFO/POST_THANK_YOU_MESSAGE_REQUEST';
export const POST_THANK_YOU_MESSAGE_SUCCESS = 'ORGANIZER_EVENT_INFO/POST_THANK_YOU_MESSAGE_SUCCESS';
export const POST_THANK_YOU_MESSAGE_FAIL = 'ORGANIZER_EVENT_INFO/POST_THANK_YOU_MESSAGE_FAIL';

// ccustom form
export const SET_CUSTOM_FORM_FIELDS = 'SET_CUSTOM_FORM_FIELDS';

export const CREATE_CUSTOM_FORM = 'ORGANIZER_EVENT_INFO/CREATE_CUSTOM_FORM';
export const DELETE_CUSTOM_FORM = 'ORGANIZER_EVENT_INFO/DELETE_CUSTOM_FORM';

export const CHANGE_CUSTOM_FORM_FIELD = 'ORGANIZER_EVENT_INFO/CHANGE_CUSTOM_FORM_FIELD';
export const UPDATE_CUSTOM_FORM_FIELD_STATUS_REQUEST = 'ORGANIZER_EVENT_INFO/UPDATE_CUSTOM_FORM_FIELD_STATUS_REQUEST';
export const UPDATE_CUSTOM_FORM_FIELD_STATUS_SUCCESS = 'ORGANIZER_EVENT_INFO/UPDATE_CUSTOM_FORM_FIELD_STATUS_SUCCESS';
export const UPDATE_CUSTOM_FORM_FIELD_STATUS_FAIL = 'ORGANIZER_EVENT_INFO/UPDATE_CUSTOM_FORM_FIELD_STATUS_FAIL';

export const REMOVE_CUSTOM_FORM_FIELD_OPTION = 'ORGANIZER_EVENT_INFO/REMOVE_CUSTOM_FORM_FIELD_OPTION';
export const ADD_CUSTOM_FORM_FIELD_OPTION = 'ORGANIZER_EVENT_INFO/ADD_CUSTOM_FORM_FIELD_OPTION';
export const CHANGE_CUSTOM_FORM_FIELD_OPTION = 'ORGANIZER_EVENT_INFO/CHANGE_CUSTOM_FORM_FIELD_OPTION';

export const DOWNLOAD_SURVEY_DATA_START = 'DOWNLOAD_SURVEY_DATA_START';
export const DOWNLOAD_SURVEY_DATA_SUCCESS = 'DOWNLOAD_SURVEY_DATA_SUCCESS';
export const DOWNLOAD_SURVEY_DATA_FAIL = 'DOWNLOAD_SURVEY_DATA_FAIL';
