import React from 'react';
import classNames from 'classnames';

import SideMenu from '../SideMenu';
import NotificationPresentationStarts from '../NotificationPresentationStarts/NotificationPresentationStarts';
import InformDialog from '../InformDialog/InformDialog';
import BreadcrumbsNavigation from '../BreadcrumbsNavigation';
import VillageBackgroundImage from './components/VillageBackgroundImage/VillageBackgroundImage';
import Building from './components/Building/Building';

import {disconnectFromEvent, disconnectFromNotifications} from 'Api/socketApi';
import {isHaveSocialMedia} from 'services/helpers';

import 'styles/village.scss';
import {BUILDING_TYPES} from 'constants/shared';
import {RouterConfig} from 'routerConfig';
import {generatePath} from 'react-router';

class Village extends React.Component {
    state = {
        showClosedBuildingDialog: false,
        showNoBuildingAccessDialog: false,
    };

    componentDidMount() {
        this.props.onSetEventProtectedMenu();
        this.handleSeeLiveWall();
        this.props.setHasVideoWall(false);
        // reset the buildingId data
        this.props.onSetBuildingId(null);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.event._id !== this.props.event._id) {
            this.handleSeeLiveWall();
        }
    }

    handleSeeLiveWall = () => {
        const wallData = {
            wallId: this.props.event.liveWall._id,
            highlightUsers: [],
        };

        this.props.setActiveWall(wallData);
        this.props.seeLiveWall();
    };

    closeDialog = () => {
        this.setState({
            showClosedBuildingDialog: false,
            showNoBuildingAccessDialog: false,
        });
    };

    handleLogoutUser = () => {
        // if user logged out we should also disconnect him from notifications
        const {user, event} = this.props;
        disconnectFromNotifications({userId: user._id});
        disconnectFromEvent({userId: user._id, eventId: event._id});
        this.props.onLogoutUser();
    };

    handleRedirectToBuilding = (building) => (e) => {
        const {event, resourcesAccess} = this.props;

        if (building.restrictedAccess) {
            this.setState({showClosedBuildingDialog: true});
        } else {
            if (event.hasAccessManagement && resourcesAccess) {
                // check if the user has access to the current building
                const hasAccessToBuilding = resourcesAccess.buildings.includes(building._id);
                if (hasAccessToBuilding) {
                    this.redirectToBuilding(building);
                } else {
                    this.setState({showNoBuildingAccessDialog: true});
                }
            } else {
                this.redirectToBuilding(building);
            }
        }
    };

    redirectToBuilding = (building) => {
        const {eventSlug} = this.props;
        const {type: buildingType} = building;

        this.props.onSetBuildingId(building._id);

        // redirect to the building lobby only in case of the mixed type of the building
        if (buildingType === BUILDING_TYPES.auditorium)
            return this.props.history.push(
                generatePath(RouterConfig.event.village.buildingAuditorium, {
                    eventSlug,
                    buildingId: building._id,
                })
            );

        if (buildingType === BUILDING_TYPES.showfloor)
            return this.props.history.push(
                generatePath(RouterConfig.event.village.buildingShowfloor, {
                    eventSlug,
                    buildingId: building._id,
                })
            );

        this.props.history.push(
            generatePath(RouterConfig.event.village.buildingLobby, {
                eventSlug,
                buildingId: building._id,
            })
        );
    };

    render() {
        const {event, isLargeScreen} = this.props;
        const {showClosedBuildingDialog, showNoBuildingAccessDialog} = this.state;

        return (
            <>
                <div
                    className={classNames('position-background', {
                        'village-mobile': !isLargeScreen,
                    })}
                >
                    <BreadcrumbsNavigation />
                    <SideMenu hideInterpretation hideSocialMedia={!isHaveSocialMedia(event)} />
                    <div className={classNames('village-page', `setup${event.buildings.length}`)}>
                        {isLargeScreen && (
                            <VillageBackgroundImage
                                filesUrl={this.props.event.brandingData.filesUrl}
                                brandingData={this.props.event.brandingData}
                            />
                        )}
                        <div className="centerOfPage">
                            <div className="relativeCenter">
                                {event.buildings.map((building, buildingIndex) => (
                                    <Building
                                        key={building._id}
                                        building={building}
                                        number={buildingIndex + 1}
                                        onClick={this.handleRedirectToBuilding(building)}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {showClosedBuildingDialog && (
                    <InformDialog
                        open={showClosedBuildingDialog}
                        onClose={this.closeDialog}
                        dialogTitle={'Building information'}
                        dialogDescription={'Access to the Building has been restricted.'}
                        dialogButtonLabel={'OK'}
                    />
                )}
                {showNoBuildingAccessDialog && (
                    <InformDialog
                        open={showNoBuildingAccessDialog}
                        onClose={this.closeDialog}
                        dialogTitle={'Building information'}
                        dialogDescription={"Your access package doesn't have access to this building."}
                        dialogButtonLabel={'OK'}
                    />
                )}
                <NotificationPresentationStarts />
            </>
        );
    }
}

export default Village;
