import React, {useState, useEffect, useCallback, useRef} from 'react';
import {useHistory} from 'react-router-dom';
import {isMobileSafari, isOpera, isSafari, isMobile, isIOS} from 'react-device-detect';
import ReactPlayer from 'react-player';
import {
    VolumeMute as VolumeMuteIcon,
    VolumeUp as VolumeUpIcon,
    Cached as CachedIcon,
    Fullscreen as FullscreenIcon,
    FullscreenExit as FullscreenExitIcon,
} from '@material-ui/icons';
import {Box, Tooltip} from '@material-ui/core';
import classNames from 'classnames';

import AuditoriumOverlayPlayer from '../AuditoriumOverlayPlayer';
import AuditoriumVideojsPlayer from './AuditoriumVideojsPlayer/AuditoriumVideojsPlayer';
import StreamingVideoContainer from '../StreamingVideoContainer/StreamingVideoContainer';
import EndedVideoQA from './components/EndedVideoQA';
import WaitForStreamStart from './components/WaitForStreamStart';

import {useInterpretator} from '../StreamingVideoContainer/StreamingVideoContainer.hooks';
import {checkIsM3U8Format} from 'services/utils';

import {ZOOM, ETX_LIVE_SESSION} from 'constants/data/streaming-engine';
import {DEFAULT_INTERPRETATION_LANGUAGE} from 'constants/shared';
import {
    connectToTimeslot,
    disconnectFromTimeslot,
    timeslotStreamStarted,
    timeslotStreamStopped,
    timeslotStreamPublished,
    timeslotStreamUnpublished,
    timeslotInterpreterStreamPublished,
    timeslotInterpreterStreamUnpublished,
    connectToTimeslotPoll,
    disconnectFromTimeslotPoll,
} from 'Api/socketApi';

import './AuditoriumVideoPlayer.scss';

const AuditoriumVideoPlayer = ({
    videoStreamingUrl,
    videoStreamingStartAt,
    interpretationSourceLanguage,
    withInterpretation,
    updateTimeslots,
    //state
    translation,
    currentTimeSlot,
    userId,
    interpretation,
    // actions
    updateTimeSlotData,
    getTimeslotDataSuccess,
    setAvailableInterpretationLanguage,
    removeAvailableInterpretationLanguage,
    setActiveInterpretationLanguage,
}) => {
    const history = useHistory();

    const fullscreenTimerId = useRef();
    const session = currentTimeSlot?.session;
    const sessionId = currentTimeSlot?._id;
    const videoRef = useRef(null);

    const isPageReloaded = history && history.action === 'POP';
    const isSessionEngine =
        currentTimeSlot?.streamingEngine === ZOOM || currentTimeSlot?.streamingEngine === ETX_LIVE_SESSION;
    const isSessionStream = currentTimeSlot?.isLiveStreaming && isSessionEngine;

    const isInterpritationAllowed = withInterpretation && session && session?.link;

    const setInterpretationMediaStream = useInterpretator(
        videoRef.current?.getInternalPlayer(),
        interpretationSourceLanguage,
        isInterpritationAllowed
    );

    const [fullscreen, setFullscreen] = useState(false);
    const [videoButtonsBar, toggleVideoButtonsBar] = useState(false);
    const [showRefreshButton, setShowRefreshButton] = useState(false);
    const [muteVideo, setMuteVideo] = useState(isMobile ? isMobile : isPageReloaded);
    const [videoEnded, setVideoEnded] = useState(false);
    const [showAudioOverlay, setShowAudioOverlay] = useState(isPageReloaded);
    const [isStreamStoped, setStreamStoped] = useState(false);

    const mouseMove = useCallback(() => {
        clearTimeout(fullscreenTimerId);
        showVideoButtons();
        fullscreenTimerId.current = setTimeout(hideVideoButtons, 3000);
    }, [fullscreenTimerId]);

    const exitFullscreen = useCallback(() => {
        setFullscreen(false);

        showVideoButtons();
        clearTimeout(fullscreenTimerId);

        document.body.classList.remove('fullscreen-video');
        document.getElementById('js-auditorium-page').classList.remove('fullscreen');
        window.removeEventListener('mousemove', mouseMove);
    }, [fullscreenTimerId, mouseMove]);

    const toggleFullScreenEscKey = useCallback(() => {
        const fullscreenElement =
            document.fullscreenElement ||
            document.webkitFullscreenElement ||
            document.mozFullscreenElement ||
            document.msFullscreenElement;
        if (!fullscreenElement) {
            exitFullscreen();
        }
    }, [exitFullscreen]);

    const streamHasStarted = () => {
        setVideoEnded(false);
        setStreamStoped(false);
    };

    const timeslotStatusUpdated = useCallback(() => {
        timeslotStreamStarted((err, data) => {
            updateTimeSlotData(data);
            streamHasStarted();
        });
        timeslotStreamStopped((err, data) => {
            updateTimeSlotData(data);

            setStreamStoped(true);
        });
        timeslotStreamPublished((err, data) => {
            updateTimeSlotData(data);

            setStreamStoped(false);
        });
        timeslotStreamUnpublished((err, data) => {
            updateTimeSlotData(data);

            setStreamStoped(true);
        });
        timeslotInterpreterStreamPublished((err, {timeslot, interpretationLanguage}) => {
            updateTimeslots(timeslot);
            getTimeslotDataSuccess(timeslot);
            setAvailableInterpretationLanguage(interpretationLanguage);
        });
        timeslotInterpreterStreamUnpublished((err, {timeslot, interpretationLanguage}) => {
            if (interpretationLanguage === interpretation?.activeLanguage) {
                setActiveInterpretationLanguage(DEFAULT_INTERPRETATION_LANGUAGE);
            }

            updateTimeslots(timeslot);
            getTimeslotDataSuccess(timeslot);
            removeAvailableInterpretationLanguage(interpretationLanguage);
        });
    }, [
        getTimeslotDataSuccess,
        interpretation?.activeLanguage,
        removeAvailableInterpretationLanguage,
        setActiveInterpretationLanguage,
        setAvailableInterpretationLanguage,
        updateTimeSlotData,
        updateTimeslots,
    ]);

    useEffect(() => {
        if (!sessionId) return;
        connectToTimeslot({timeslotId: sessionId});
        connectToTimeslotPoll({userId, programId: sessionId});
        setShowRefreshButton(false);

        return () => {
            disconnectFromTimeslot({timeslotId: sessionId});
            disconnectFromTimeslotPoll({userId, programId: sessionId});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sessionId]);

    useEffect(() => {
        timeslotStatusUpdated();
    }, [timeslotStatusUpdated]);

    useEffect(() => {
        document.addEventListener('webkitfullscreenchange', toggleFullScreenEscKey, false);
        document.addEventListener('mozfullscreenchange', toggleFullScreenEscKey, false);
        document.addEventListener('fullscreenchange', toggleFullScreenEscKey, false);
        document.addEventListener('MSFullscreenChange', toggleFullScreenEscKey, false);

        return () => {
            if (document.fullscreenElement) {
                document.exitFullscreen().then(exitFullscreen());
            }

            document.removeEventListener('webkitfullscreenchange', toggleFullScreenEscKey, false);
            document.removeEventListener('mozfullscreenchange', toggleFullScreenEscKey, false);
            document.removeEventListener('fullscreenchange', toggleFullScreenEscKey, false);
            document.removeEventListener('MSFullscreenChange', toggleFullScreenEscKey, false);
        };
    }, [exitFullscreen, toggleFullScreenEscKey]);

    const toggleFullScreen = () => {
        if (document.fullscreenElement) {
            document
                .exitFullscreen()
                .then(() => exitFullscreen())
                .catch(console.error);
            return;
        }
        if (document.documentElement?.requestFullscreen)
            document.documentElement.requestFullscreen().then(() => startFullscreen());
    };

    const startFullscreen = () => {
        setFullscreen(true);
        hideVideoButtons();

        document.body.classList.add('fullscreen-video');
        document.getElementById('js-auditorium-page').classList.add('fullscreen');
        window.addEventListener('mousemove', mouseMove);
    };

    const videoHasEnded = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen().then(exitFullscreen());
        }
        setVideoEnded(true);
        setMuteVideo(false);
    };

    const showVideoButtons = () => toggleVideoButtonsBar(true);

    const hideVideoButtons = () => toggleVideoButtonsBar(false);

    const refreshPage = () => {
        window.location.reload(true);
    };

    const isPlayingError = () => {
        setShowRefreshButton(true);
    };

    const toggleSound = () => {
        setMuteVideo(!muteVideo);
        if (showAudioOverlay) {
            setShowAudioOverlay(false);
        }
    };

    const unmuteVideo = () => {
        if (showAudioOverlay) {
            setMuteVideo(false);
            setShowAudioOverlay(false);
            if (!isMobile) {
                history.action = 'PUSH'; //clean reloaded flag
            }
        }
    };

    const getVideoUrl = () => {
        if (!session || session.link) {
            return `${videoStreamingUrl}#t=${videoStreamingStartAt}`;
        }

        return `${videoStreamingUrl}?t=${videoStreamingStartAt}s`;
    };

    const handleVideoReady = () => {};
    const handleVideoDuration = () => {};
    const videoHasStarted = () => {
        setVideoEnded(false);
        if (withInterpretation) {
            const videoElement = videoRef.current.getInternalPlayer();
            try {
                if (!videoElement.captureStream) return;
                const stream = videoElement.captureStream();
                stream.onactive = (mediaStreamEvent) => {
                    const audioTracks = mediaStreamEvent.currentTarget.getAudioTracks();
                    if (audioTracks.length) setInterpretationMediaStream(mediaStreamEvent.currentTarget);
                };
            } catch (e) {
                console.error(e);
            }
        }
    };

    // 31.08.2020 currently the fullscreen api is not supported on Safari iPhone
    const hideFullscreenButton = isMobileSafari || isSafari || isOpera || isIOS;
    const videoWrapperClassname = classNames('videoWrapper', {fullscreen});

    if (!currentTimeSlot) {
        return null;
    }

    if ((!isSessionStream && videoEnded) || isStreamStoped || currentTimeSlot?.streamFinished) {
        return (
            <div data-testid="video-wrapper" className={videoWrapperClassname}>
                <EndedVideoQA translation={translation} imageUrl={currentTimeSlot?.imageUrl} />
            </div>
        );
    } else if (
        isSessionStream &&
        ((!currentTimeSlot.streamStarted && !isStreamStoped) || !currentTimeSlot.streamPublished)
    ) {
        return (
            <div data-testid="video-wrapper" className={videoWrapperClassname}>
                <WaitForStreamStart />
            </div>
        );
    }

    if (checkIsM3U8Format(videoStreamingUrl)) {
        return (
            <div data-testid="video-wrapper" className={videoWrapperClassname}>
                <AuditoriumVideojsPlayer
                    sourceLink={videoStreamingUrl}
                    interpretationSourceLanguage={interpretationSourceLanguage}
                    withInterpretation={withInterpretation}
                />
            </div>
        );
    }

    return (
        <div data-testid="video-wrapper" className={videoWrapperClassname}>
            <div className="video-container">
                {isSessionStream ? (
                    <>
                        <EndedVideoQA
                            isShown={isStreamStoped || currentTimeSlot?.streamFinished}
                            translation={translation}
                            imageUrl={currentTimeSlot?.imageUrl}
                        />
                        <Box
                            className="video-container"
                            height="100%"
                            width="100%"
                            display={isStreamStoped ? 'none' : ''}
                        >
                            <StreamingVideoContainer
                                muted={muteVideo}
                                sessionId={sessionId}
                                sessionKey={currentTimeSlot?.sessionKey}
                                gatewayName={currentTimeSlot?.gatewayName}
                                className={classNames('video-container', {isFullScreen: fullscreen})}
                                interpretationSourceLanguage={interpretationSourceLanguage}
                                withInterpretation={withInterpretation}
                                controlsOn={false}
                            />
                        </Box>
                    </>
                ) : (
                    <ReactPlayer
                        url={getVideoUrl()}
                        playsinline={!!isIOS}
                        ref={videoRef}
                        playing={true}
                        className="react-player"
                        controls={false}
                        config={{
                            file: {
                                attributes: {
                                    crossOrigin: 'anonymous',
                                },
                            },
                        }}
                        width="100%"
                        height="100%"
                        volume={muteVideo ? 0 : 1}
                        muted={muteVideo}
                        onReady={handleVideoReady}
                        onContextMenu={(e) => e.preventDefault()}
                        onEnded={videoHasEnded}
                        onPlay={videoHasStarted}
                        onDuration={handleVideoDuration}
                        onError={isPlayingError}
                    />
                )}
                {!isStreamStoped && (
                    <AuditoriumOverlayPlayer className={showAudioOverlay ? 'audio-overlay' : ''} onClick={unmuteVideo}>
                        {showAudioOverlay && <VolumeUpIcon fontSize="inherit" />}
                    </AuditoriumOverlayPlayer>
                )}
            </div>
            {showRefreshButton && (
                <div className="video-error-container">
                    <p onClick={refreshPage}>{translation?.videoPlayer.videoNotPlayingError}</p>
                    <button onClick={refreshPage}>
                        <CachedIcon fontSize="inherit" />
                    </button>
                </div>
            )}
            {!isStreamStoped && (
                <div className={classNames('video-actions-container', {show: videoButtonsBar})} id="videoButtons">
                    <button
                        data-testid="fullscreen-toggler"
                        onClick={toggleFullScreen}
                        className={`${hideFullscreenButton ? 'd-none' : ''}`}
                    >
                        {fullscreen ? (
                            <Tooltip title={translation?.videoPlayer.tooltipMinimize}>
                                <FullscreenExitIcon fontSize="inherit" />
                            </Tooltip>
                        ) : (
                            <Tooltip title={translation?.videoPlayer.tooltipFullscreen}>
                                <FullscreenIcon fontSize="inherit" />
                            </Tooltip>
                        )}
                    </button>
                    <button onClick={toggleSound}>
                        {muteVideo ? (
                            <Tooltip title={translation?.videoPlayer.tooltipUnmute}>
                                <VolumeMuteIcon fontSize="inherit" />
                            </Tooltip>
                        ) : (
                            <Tooltip title={translation?.videoPlayer.tooltipMute}>
                                <VolumeUpIcon fontSize="inherit" />
                            </Tooltip>
                        )}
                    </button>
                </div>
            )}
        </div>
    );
};

export default AuditoriumVideoPlayer;
