import React from 'react';
import {AbilityBuilder, defineAbility} from '@casl/ability';
import {createContext} from 'react';
import {createContextualCan} from '@casl/react';
import {USER_ROLES} from './constants/shared';
import {ROLE_ABILITIES_TYPES, ROLE_FEATURES_TYPES} from './constants/ability';
import * as executors from './services/ability';

export const ability = defineAbility((can) => {
    can(ROLE_ABILITIES_TYPES.MANAGE_ABILITY, ROLE_FEATURES_TYPES.ORGANIZER_DASHBOARD);
});

export const updateAbility = (ability, userRoles) => {
    const {can, cannot, rules} = new AbilityBuilder();

    if (!Array.isArray(userRoles)) return;

    for (const role of userRoles) {
        if (role === USER_ROLES.coOrganizer) {
            executors.coOrganizer(can, cannot);
        } else {
            executors[role](can, cannot);
        }
    }

    if (rules.length) {
        ability.update(rules);
    }
};

export const AbilityContext = createContext(ability);
export const Can = createContextualCan(AbilityContext.Consumer);
export const AbilityProvider = ({children}) => (
    <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
);
