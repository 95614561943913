import React, {useEffect} from 'react';
import {Box} from '@material-ui/core';
import {useStyles} from './styles';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'store/selectors';
import * as actions from 'store/actions';

export const PanelWrapper = ({children}) => {
    const style = useStyles();
    const dispatch = useDispatch();
    const isLargeScreen = useSelector(selectors.getIsLargeScreen);
    useEffect(() => {
        return () => {
            if (!isLargeScreen) dispatch(actions.hideAccountNavigation(false));
        };
    }, [dispatch, isLargeScreen]);
    return <Box className={style.container}>{children}</Box>;
};

PanelWrapper.propTypes = {
    children: PropTypes.node,
};
