import * as actionTypes from './actionTypes';
import {Api} from 'Api';

// Not used, probably we will use it sometime in the future to get additional building details

/**
 *
 * @param {string} eventId
 * @param {string} buildingId
 * @param {function} [callback]
 * @returns {(function(*, *): Promise<void>)|*}
 */
export const getBuilding = (eventId, buildingId, callback) => async (dispatch, getState) => {
    const {
        building: {loading},
    } = getState();

    dispatch(getBuildingStart());

    try {
        const {building} = await Api.request({
            url: `/event/${eventId}/building/${buildingId}`,
            method: 'get',
            loading,
        });

        dispatch(getBuildingSuccess(building));
        callback && callback();
    } catch (error) {
        dispatch(getBuildingFail());
    }
};

export const getBuildingStart = () => ({
    type: actionTypes.GET_BUILDING_START,
});

export const getBuildingSuccess = (building) => ({
    type: actionTypes.GET_BUILDING_SUCCESS,
    payload: building,
});

export const getBuildingFail = () => ({
    type: actionTypes.GET_BUILDING_FAIL,
});

export const setBuildingId = (buildingId) => ({
    type: actionTypes.SET_BUILDING_ID,
    payload: buildingId,
});

export const clearBuildingData = () => ({type: actionTypes.CLEAR_BUILDING_DATA});
