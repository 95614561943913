import React from 'react';

import Svg from '../Svg/Svg';

import './Edit.scss';

const IconEdit = () => (
    <Svg className="IconEdit">
        <path d="M19.8341 4.16509C18.2846 2.61163 15.7649 2.61163 14.2154 4.16509L3.91397 14.464C3.83343 14.5445 3.78357 14.6481 3.76823 14.7593L3.00501 20.4131C2.982 20.5742 3.03953 20.7353 3.15075 20.8466C3.24663 20.9425 3.38087 21 3.5151 21C3.53811 21 3.56112 21 3.58413 20.9962L6.98984 20.5359C7.27364 20.4975 7.47308 20.2367 7.43472 19.9529C7.39637 19.669 7.13558 19.4696 6.85177 19.5079L4.12107 19.8761L4.65417 15.933L8.80391 20.0833C8.89979 20.1792 9.03402 20.2367 9.16826 20.2367C9.30249 20.2367 9.43672 20.183 9.53261 20.0833L19.8341 9.7844C20.5858 9.0326 21 8.03532 21 6.97283C21 5.91034 20.5858 4.91306 19.8341 4.16509ZM14.4149 5.43088L16.1446 7.16078L6.74438 16.5621L5.01468 14.8322L14.4149 5.43088ZM9.17209 18.9863L7.48075 17.2947L16.8809 7.8934L18.5723 9.58494L9.17209 18.9863ZM19.2933 8.84465L15.1551 4.70593C15.6805 4.27249 16.3363 4.03468 17.0267 4.03468C17.8129 4.03468 18.5493 4.34154 19.1054 4.89388C19.6615 5.44622 19.9645 6.18651 19.9645 6.97283C19.9645 7.66709 19.7267 8.31916 19.2933 8.84465Z" />
    </Svg>
);

export default IconEdit;
