import {connect} from 'react-redux';
import ExhibitorBoothMobile from './ExhibitorBoothMobile';
import * as actions from 'store/actions';
import * as selectors from 'store/selectors';

export default connect(
    (state) => ({
        eventId: selectors.getEventId(state),
        event: selectors.getEventData(state),
        user: selectors.getUserData(state),
        eventUsersList: selectors.getUsersList(state),
        eventRoles: selectors.getUserEventRoles(state),
        loading: selectors.getUserLoading(state),
        activeExhibitorId: selectors.getActiveExhibitorId(state),
        exhibitor: selectors.getExhibitorData(state),
        exhibitors: selectors.getExhibitorsList(state),
        jobs: selectors.getExhibitorJobsList(state),
        loadingExhibitor: selectors.getExhibitorLoading(state),
        translation: selectors.getTranslation(state),
    }),
    {
        setActiveWall: actions.setActiveWall,
        onOpenPrivateChat: actions.openPrivateChat,
        onCreatePrivateChat: actions.createPrivateChat,
        onGetExhibitor: actions.getExhibitor,
        onGetExhibitorJobs: actions.getExhibitorJobs,
        onApplyToJob: actions.applyToExhibitorJob,
        getEventUsersList: actions.getEventUsersList,
        sendExhibitorStatistic: actions.sendExhibitorStatistic,
    }
)(ExhibitorBoothMobile);
