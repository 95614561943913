import classNames from 'classnames';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RenderEventList} from '../';
import * as actions from '../../../../../../../../../store/actions';
import {useStyles} from './ExploreMoreEvents.style';

export default function ExploreMoreEvents({className = ''}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const {allListedEvents} = useSelector((state) => state.eventListing);

    useEffect(() => {
        dispatch(actions.getAllListedEvents());
        dispatch(actions.getEventsListingDates());
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => dispatch(actions.resetEventListingParams());
    }, [dispatch]);

    return (
        <div className={classNames(classes.content, className)}>
            <RenderEventList data={allListedEvents} isInfiniteScroll showSearch />
        </div>
    );
}
