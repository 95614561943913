import {
    SET_ACTIVE_VIDEO_CONFERENCE_TYPE,
    SET_INCOMING_CALL_USER_ID,
    CLEAR_REMOTE_USER_INFO,
    CLOSE_VIDEO_ROOM,
} from '../../../actionTypes/videoConferenceActionTypes';
import {initialState} from '../initialState';
import {updateObject} from '../../../utilityStateFunction';

const generalInfoHandler = (state, {type, payload}) => {
    if (state === undefined) {
        return initialState.generalInfo;
    }

    switch (type) {
        case SET_ACTIVE_VIDEO_CONFERENCE_TYPE:
            return updateObject(state.generalInfo, {
                activeVideoCallType: payload,
            });
        case SET_INCOMING_CALL_USER_ID:
            return updateObject(state.generalInfo, {
                incomingOneToOneCallUserId: payload,
            });
        case CLEAR_REMOTE_USER_INFO:
        case CLOSE_VIDEO_ROOM:
            return updateObject(state.generalInfo, {
                incomingOneToOneCallUserId: null,
            });
        default:
            return state.generalInfo;
    }
};

export default generalInfoHandler;
