import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {DialogActions, DialogContent, Button} from '@material-ui/core';
import {MATCHING_LEVELS, TABS_INDEXS} from 'constants/matchMaking';
import UserPreviewPlate from 'Routes/components/SideMenu/components/Networking/components/UsersList/components/UserPreviewPlate/UserPreviewPlate';
import {avatarFolder} from 'config';

import './MatchingDialogInitialPage.scss';

const socialListFake = [
    {slug: 'facebook', link: 'https://www.facebook.com/'},
    {slug: 'twitter', link: 'https://twitter.com/'},
    {slug: 'linkedin', link: 'https://www.linkedin.com/'},
];

const MatchingDialogInitialPage = ({classes, matchingForm, eventId, setDialogTitle, setTab, user}) => {
    useEffect(() => {
        setDialogTitle(matchingForm?.title);
    }, [setDialogTitle, matchingForm]);
    const handleReject = () => setTab(TABS_INDEXS.rejectPage);
    const handleAgree = () => setTab(TABS_INDEXS.questionPage);

    const eventRole = user.eventRoles.find((role) => role.event._id === eventId);

    return (
        <>
            <DialogContent className={classes?.conntent}>
                <div className="matching-dialog-initial-page">
                    <p className="matching-dialog-initial-page__text">{matchingForm?.description}</p>
                    <div className="matching-dialog-initial-page__image-wrapper">
                        <UserPreviewPlate
                            userId={user._id}
                            firstName={user.first}
                            lastName={user.last}
                            media={{
                                title: 'User avatar',
                                src: `${avatarFolder}${user.avatarSmall}`,
                            }}
                            profession={eventRole?.title}
                            company={eventRole?.company}
                            socialList={socialListFake}
                            businessCardId={user?.businessCard?._id}
                            isContact
                            matchLevel={MATCHING_LEVELS.high}
                            canConnectToParticipant
                            online
                            readOnly
                            className="matching-dialog-initial-page__image"
                        />
                        <div className="matching-dialog-initial-page__img-shadow matching-dialog-initial-page__img-shadow--1"></div>
                        <div className="matching-dialog-initial-page__img-shadow matching-dialog-initial-page__img-shadow--2"></div>
                    </div>

                    <p className="matching-dialog-initial-page__text">
                        By skipping the questionnaire, we will not able to match you with the rest of the attendees at
                        today’s event. You can always complete the questionnaire later in the Networking section. All
                        answers are editable from My Account.
                    </p>
                </div>
            </DialogContent>
            <DialogActions className={classes?.actions}>
                <div className="matchmaking-dialog__action-tab">
                    <Button onClick={handleReject} className={'matchmaking-dialog__action-tab-btn'} variant="outlined">
                        do it later
                    </Button>
                    <Button
                        color="primary"
                        onClick={handleAgree}
                        className={'matchmaking-dialog__action-tab-btn'}
                        variant="contained"
                    >
                        I am in
                    </Button>
                </div>
            </DialogActions>
        </>
    );
};

MatchingDialogInitialPage.propTypes = {
    classes: PropTypes.object,
    setTab: PropTypes.func,
    matchingForm: PropTypes.object,
    setDialogTitle: PropTypes.func,
    user: PropTypes.object,
    eventId: PropTypes.string,
};

export default MatchingDialogInitialPage;
