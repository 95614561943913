import React from 'react';

import {urlifyString} from 'services/utils';
import {usePlatformTranslation} from 'services/hooks';

import './ChatMessage.scss';

const ChatMessage = ({openVideoConference, message, showUserDetails, isGroupChat, isUserOnline}) => {
    const handleOpenConference = () => {
        openVideoConference();
    };
    const text = urlifyString(message.text);
    const translation = usePlatformTranslation();

    return (
        <>
            <div className="avatar-wrapper">
                {showUserDetails ? (
                    <div
                        className="user-avatar"
                        style={{
                            backgroundImage: `url('${process.env.REACT_APP_AVATAR_FOLDER}${message.user.avatarSmall}')`,
                        }}
                    ></div>
                ) : null}
            </div>
            <div className="messages-wrapper">
                {showUserDetails ? (
                    <p className="messages-wrapper__user-details">
                        {message.user.first} {message.user.last}
                    </p>
                ) : null}
                {message.text === translation.chatsDropdown.setVideoMeetingRoomMessage &&
                !isGroupChat &&
                isUserOnline ? (
                    <p className="messages-wrapper__text">
                        {translation.chatsDropdown.setVideoMeeting}
                        <span onClick={handleOpenConference} className="messages-wrapper__conference-message">
                            {` ${translation?.chatsDropdown?.clickHere} `}
                        </span>
                        {translation.chatsDropdown.toJoin}
                    </p>
                ) : (
                    <p className="messages-wrapper__text" dangerouslySetInnerHTML={{__html: text}} />
                )}
            </div>
        </>
    );
};

export default ChatMessage;
