import {useEffect, useState} from 'react';
import {isMobile} from 'react-device-detect';

export default function useMobileSelfVideo(videoEnabled) {
    const [width, setWidth] = useState(100);
    const [rotated, setRotated] = useState(false);

    useEffect(() => {
        if (videoEnabled && width === 100) {
            return;
        } else if (videoEnabled && width < 100) {
            setWidth(100);
        } else if (!videoEnabled && width === 100) {
            setWidth(99);
            isMobile && setRotated(true);
        }
    }, [videoEnabled, width]);

    return [width, rotated];
}
