import * as actionTypes from '../actions/actionTypes';
import * as eventActionTypes from '../actionTypes/eventActionTypes';
import {updateObject} from '../utilityStateFunction';
import ar from '../../constants/translations/ar';
import ch from '../../constants/translations/ch';
import de from '../../constants/translations/de';
import en from '../../constants/translations/en';
import es from '../../constants/translations/es';
import fr from '../../constants/translations/fr';
import no from '../../constants/translations/no';
import pt from '../../constants/translations/pt';
import ru from '../../constants/translations/ru';
import tr from '../../constants/translations/tr';
import {allTranslations} from '../../constants/translations/all-translations';
import {IS_SCA_ENV} from '../../constants/shared';

// eventLanguage is set up on getEventInfo and can be changed by the user
// platformLanguage is set up after getUser(register, Login, autoLogin etc)
// organizerBrandingLanguage is set up on Organizer => Event Info and is the one that controls the organizerDashboard Event Info languages
const initialState = {
    eventLanguage: IS_SCA_ENV ? 'ar' : 'en', // event public pages (homepage, login, register etc.)
    platformLanguage: IS_SCA_ENV ? 'ar' : 'en', // event private pages (lobby, auditorium, chats, myAccount etc),
    organizerBrandingLanguage: null,
    isRtlLanguage: IS_SCA_ENV,
    translations: {
        ar,
        ch,
        de,
        en,
        es,
        fr,
        no,
        pt,
        ru,
        tr,
    },
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_EVENT_LANGUAGE:
            return updateObject(state, {
                eventLanguage: action.payload,
                platformLanguage: action.payload,
                isRtlLanguage: action.payload === allTranslations.ar.slug,
            });
        case actionTypes.SET_PLATFORM_LANGUAGE:
            return updateObject(state, {
                platformLanguage: action.payload,
                isRtlLanguage: action.payload === allTranslations.ar.slug,
            });
        case eventActionTypes.SET_ORGANIZER_BRANDING_LANGUAGE:
            return updateObject(state, {organizerBrandingLanguage: action.payload});

        default:
            return state;
    }
};

export default reducer;
