import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {Provider} from 'react-redux';
import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import {ThemeProvider, StylesProvider} from '@material-ui/styles';

import HTMLHead from './htmlHead';
import App from './App';

import store from './store/store';
import * as serviceWorker from './serviceWorker';
import {AbilityProvider} from './permissionsConfig';
import './index.scss';
import {env, envList, whiteLabel, sentryDsn} from './config';
import {IS_SCA_ENV, IS_UNCTAD_ENV, IS_EGYPT_PAVILION_ENV} from './constants/shared';
import mainTheme from './theme/mainTheme';
import scaTheme from './theme/scaTheme';
import unctadTheme from './theme/unctadTheme';
import egyptTheme from 'theme/egyptTheme';

const app = (
    <>
        <Provider store={store}>
            <BrowserRouter>
                <HTMLHead />
                <AbilityProvider>
                    <StylesProvider injectFirst>
                        <ThemeProvider
                            theme={
                                IS_SCA_ENV
                                    ? scaTheme
                                    : IS_UNCTAD_ENV
                                    ? unctadTheme
                                    : IS_EGYPT_PAVILION_ENV
                                    ? egyptTheme
                                    : mainTheme
                            }
                        >
                            <App />
                        </ThemeProvider>
                    </StylesProvider>
                </AbilityProvider>
            </BrowserRouter>
        </Provider>
    </>
);

if (env !== envList.develop) {
    Sentry.init({
        dsn: sentryDsn,
        integrations: [new Integrations.BrowserTracing()],
        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'RangeError',
            'AbortError',
            "Cannot read properties of undefined (reading 'availableOutgoingBitrate')",
        ],
        tracesSampleRate: 1.0,
        environment: `${whiteLabel}-${env}`,
        beforeSend: (event, hint) => {
            window.location.replace('error');
            return event;
        },
    });
}

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
